import React, { Component } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getAPI, postAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { SendMessageModal } from './SendMessageModal';
import CustomSelect from '../Base/CustomSelect';
import { PhoneFrame } from '../Base/CommonUIComponents';

export class SendTwillioSmsModal extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            blockSenders: true,
            blockTemplates: true,
            senders: [],
            templates: [],
            sender: {},
            templateId: null,
            contact: null,
            template: {}
        }
    }

    componentDidMount() {
        if (this.props.contacts && this.props.contacts.length > 0) {
            const contact = this.props.contacts.some(el => el.main) ? this.props.contacts.find(el => el.main) : this.props.contact ? this.props.contact[0] : '';

            this.setState({ contact }, () => {
                this.getSenders();
                this.getTemplates();
            })
        }
    }

    getSenders = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorSenders: errorMessage, blockSenders: false });
                return;
            }
            if (data && data.response && data.response.services && data.response.services.length > 0) {
                const senders = data.response.services
                    .map(resp => {
                        const sender = {
                            value: resp.sid,
                            label: resp.friendly_name
                        };
                        return sender;
                    });
                this.setState({ errorSenders: errorMessage, blockSenders: false, senders: senders });
            }
            else {
                this.setState({ errorSenders: errorMessage, blockSenders: false });
            }
        }, `/api/gms/Twilio/v1/service`);
    }

    getTemplates = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorTemplates: errorMessage, blockTemplates: false });
                return;
            }
            if (data && data.response && data.response.contents && data.response.contents.length > 0) {
                const templates = data.response.contents
                    .map(resp => {
                        const template = {
                            value: resp.sid,
                            label: resp.friendly_name,
                            text: resp.types["twilio/text"].body
                        }

                        return template;
                    });

                this.setState({ errorTemplates: errorMessage, blockTemplates: false, templates: templates });
            }
            else {
                this.setState({ errorTemplates: errorMessage, blockTemplates: false });
            }
        }, `/api/gms/Twilio/v1/template`);
    }

    handleChange = (combo, evt) => {
        if (evt) {
            const { name } = evt;
            this.setState({ [name]: combo ? combo : {} });
        }
    }

    sendMessage = () => {
        const { type, profileId } = this.props;
        const { sender, contact, template } = this.state;

        if (!this.form.current.reportValidity()) {
            return;
        }

        const request = {
            request: {
                service: sender.value,
                template: template.value,
                to: contact.contact
            }
        };

        this.setState({ blockSenders: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorSenders: errorMessage, blockSenders: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="SendEmailModal.MessageSentWithSuccess" />, <FormattedMessage id="generic.success" />);
                }
            }
            this.setState({ errorSenders: errorMessage, blockSenders: false });
           ///api/gms/Twilio/v1/sms/send/
        }, `/api/gms/Twilio/v1/${type}/send/${profileId}`, request);
    }

    render() {
        const { isOpen, toggle, buttonLabel } = this.props;
        const { blockSenders, blockTemplates, errorSenders, errorTemplates, senders, sender, templates, contact, template } = this.state;

        return (
            <SendMessageModal
                blockSenders={blockSenders}
                blockTemplates={blockTemplates}
                buttonLabel={buttonLabel}
                contact={contact}
                errorSenders={errorSenders}
                errorTemplates={errorTemplates}
                form={this.form}
                handleChange={this.handleChange}
                isOpen={isOpen}
                sender={sender}
                senders={senders}
                sendMessage={this.sendMessage}
                toggle={toggle}
            >
                <Row className="mt-2">
                    <Col>
                        <CustomSelect name="template" options={templates} value={templates.find(t => t.value === template.value)} onChange={this.handleChange} required />
                    </Col>
                </Row>
                {
                    template && template.text ?
                        <Row className="mt-2">
                            <Col>
                                <PhoneFrame>
                                    <div className='p-4 veryCoolScrollBar' style={{ display: 'flex', flexDirection: 'column', maxHeight: '90%', overflow: 'auto' }}>
                                        <div className='iMessage' style={{
                                            borderRadius: '20px', padding: '8px 15px', marginTop: '5px', marginBottom: '5px', marginRight: '20%',
                                            backgroundColor: '#eee', position: 'relative'
                                        }}>
                                            {template.text}
                                        </div>
                                    </div>
                                </PhoneFrame>
                            </Col>
                        </Row>
                        :
                        <div />
                }
            </SendMessageModal>
        );
    }
}