import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Modal, ModalBody } from 'reactstrap';
import { handleNotification } from "../Base/Notification";
import { BlankCard } from '../Base/CommonUIComponents';
import CustomSelect from "../Base/CustomSelect";
import { putAPI, getAPI } from "../Base/API";
import Authorization from '../Base/Authorization';

export class AddRule extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            rulesOptions: [],
            selectedRules: [],
        };
    }

    componentDidMount() {
        this.getRules();
    }
       
    getRules() {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                var list = [];

                if (data.response && data.response.length > 0) {
                    data.response.map(el => {
                        var rule = {
                            value: el.id,
                            label: el.name
                        }
                        list.push(rule);
                    });
                }

                this.setState({ rulesOptions: list });
            }
            this.setState({ error: errorMessage, block: false });
        }, '/api/gms/DataQuality/v1/Rule');
    }

    handleChange = (e, type) => {
        const { name, value, checked } = e.target;

        this.setState({
            rule: {
                ...this.state.rule,
                [name]: (type === 'status' ? checked : value)
            }
        })
    }

    handleChangeSelect = (combo, name) => {
        var targetValue = '';

        if (Array.isArray(combo)) {
            targetValue = combo.map(el => el.value);
        }

        this.setState({ [name]: targetValue })
    };

    addRule = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var rules = [...this.state.selectedRules];

        this.props.rules && this.props.rules.map(el => {
            if (!rules.includes(el.id)) {
                rules.push(el.id);
            }
        })

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="AddRule.RuleAddedSuccessfully" />, <FormattedMessage id="generic.success" />);
                    this.props.updateTable(data.response[0].rules, 'rules');
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/DataQuality/v1/DataQualityTemplateRules/${this.props.templateId}`, rules);
    }

    render() {
        const { block, error, rulesOptions } = this.state;

        return (
            <Modal isOpen={this.props.modal} fade={false} size={"md"} style={{ minWidth: 0 }} >
                <Button onClick={this.props.toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <Form onSubmit={this.addRule}>
                            <Row className="mb-3">
                                <Col>
                                    <h5><FormattedMessage id="AddRule.AddRule" /></h5>
                                </Col>
                                <Authorization
                                    perform="dataQualityTemplateDetail:rules:add"
                                    yes={() => (
                                        <Col className="text-right">
                                            <Button className="btn-sm btn-host" type="submit"> <i className="fas fa-save" /></Button>
                                        </Col>
                                    )}
                                    no={() => <div></div>}
                                />
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={3}> <FormattedMessage id="AddRule.Rules" /></Label>
                                        <Col sm={9}>
                                            <CustomSelect
                                                options={rulesOptions}
                                                required={true}
                                                isClearable
                                                isSearchable
                                                placeholder={""}
                                                onChange={(e) => this.handleChangeSelect(e, "selectedRules")}
                                                isMulti
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(AddRule)