import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import moment from 'moment-timezone';
import { CoolTooltip, FormatAmountNumber, KebabMenu } from '../Base/CommonUIComponents';
import { ErrorAlert } from '../Base/ErrorAlert';
import { getSalesDealActionStatusOptions, getSalesDealActionTypes } from '../Base/ReferenceDataFunctions';
import FocusActionCard from './FocusActionCard';
import InfiniteScroll from 'react-infinite-scroller';
import SalesActionModal from './SalesActionModal';
import BlockUi from 'react-block-ui';
import { getInitiativeActionType, getInitiativeType } from './SalesProcessFunctions';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getAPIBlob } from '../Base/API';
import { handleNotification } from '../Base/Notification';

class SalesInitiativeActions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            blockScroll: false,
            error: false,
            actions: [],
            total: 0,
            type: null,
            status: null,
            orderByOld: false,
            actionStatusOptions: getSalesDealActionStatusOptions(this.props.intl),
            actionTypesOptions: getSalesDealActionTypes(this.props.intl, true),
            actionModal: false,
            selectedAction: null,
            changeToStatus: null,
            actions: this.props.actions
        };
    }

    componentWillReceiveProps(nextProps) {
        const { actions } = this.state;

        if (actions != nextProps.actions) {
            this.setState({ actions: nextProps.actions })
        }
    }

    toggleType = (value) => {
        this.setState({ type: value }, () => this.runFilters());
    }

    toggleStatus = (value) => {
        this.setState({ status: value === this.state.status ? null : value }, () => this.runFilters());
    }

    toggleOrderByOld = () => {
        this.setState({ orderByOld: !this.state.orderByOld }, () => this.runFilters());
    }

    runFilters = () => {
        const { status, type, orderByOld } = this.state;
        var newActionsList = this.props.actions??[];

        newActionsList = newActionsList.filter(x =>
            (type == null || x.type === type) &&
            (status == null || x.status === status)
        );

        if (newActionsList.length > 0) {
            newActionsList = newActionsList.sort((a, b) => {
                return new Date(orderByOld ? b.createdAt : a.createdAt) - new Date(orderByOld ? a.createdAt : b.createdAt);
            })
        }

        this.setState({ actions: newActionsList });
    }

    loadNextActions = () => {
        const { blockScroll, pageIndex } = this.state;

        if (!blockScroll)
            this.setState({ pageIndex: pageIndex + 1, blockScroll: true });
    }

    downloadAction = (e, action) => {
        if (e) e.preventDefault();

        this.setState({ block: true }, () => {
            getAPIBlob(result => {
                const { data, error, headers } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    if (data) {
                        const a = document.createElement("a");
                        //a.href = `${data.response[0].contentType}${data.response[0].fileBase64}`;
                        var blob = new Blob([data], { type: headers["content-type"] });
                        var url = window.URL.createObjectURL(blob);
                        a.href = url;
                        var f = headers["content-disposition"].match("filename=([^;]*);")
                        a.download = f != null ? f[1] : "file";
                        a.click();

                        this.setState({ block: false });
                        return;
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/deals/action/${action.id}/file`);
        });
    }

    render() {
        const { changeActionPin, toggleEdit, deal, toggleActionModal, intl, salesUsers, customers, focusActions, deleteFunction, finishFunction, onGoingFunction } = this.props;
        const { error, block, actions, type, status, actionStatusOptions, actionTypesOptions, orderByOld, total, actionModal, selectedAction } = this.state;

        return (
            <div>
                <ErrorAlert error={error} />
                <Row className='h-100'>
                    <Col className='pt-2 col-9'>
                        <div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div>
                                    <h5 className='m-0'>
                                        <FormattedMessage id="SalesProcess.Actions" />
                                    </h5>
                                </div>
                                <div>
                                    <Button onClick={_ => toggleActionModal()} className="btn btn-host btn-sm">
                                        <FormattedMessage id="SalesProcess.NewAction" />
                                        <i className="fas fa-play ml-2" />
                                    </Button>
                                </div>
                            </div>
                            {focusActions && focusActions.length > 0 ?
                                <div className='mt-3'>
                                    <div className="text-muted pb-3" style={{ fontSize: '1.1em' }}>
                                        <i className="fas fa-briefcase mr-2" />
                                        <FormattedMessage id="SalesDeal.FocusedActions" />
                                    </div>
                                    <div>
                                        {focusActions.map((action, key, arr) =>
                                            <div key={key}>
                                                <FocusActionCard
                                                    bigAction={true}
                                                    action={action}
                                                    intl={intl}
                                                    focusActions={arr}
                                                    editFunction={_ => toggleActionModal(action)}
                                                    deleteFunction={_ => deleteFunction(action, 'Cancelled')}
                                                    finishedFunction={_ => finishFunction(action, 'Finished')}
                                                    onGoingFunction={_ => onGoingFunction(action, 'OnGoing')}
                                                    pinAction={(e) => changeActionPin(e, action)}
                                                    downloadFunction={(e) => this.downloadAction(e, action)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                : ''}
                            {focusActions && focusActions.length > 0 ?
                                <div className="text-muted mb-3 mt-3" style={{ fontSize: '1.1em' }}>
                                    <i className="far fa-check-circle mr-2" />
                                    <FormattedMessage id="SalesDeal.History" />
                                </div>
                                : ''}
                            <div className='mt-3 d-flex align-items-start justify-content-between' style={{ borderBottom: '1px solid lightgrey' }}>
                                <div>
                                    <Nav tabs className='border-0'>
                                        <NavItem>
                                            <NavLink className={!type ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleType(null)} style={{ cursor: "pointer" }}>
                                                <FormattedMessage id="SalesDeal.All" />
                                            </NavLink>
                                        </NavItem>
                                        {actionTypesOptions.map((op, key) =>
                                            <NavItem key={key}>
                                                <NavLink className={type === op.value ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleType(op.value)} style={{ cursor: "pointer" }}>
                                                    <i className={`mr-2 ${op.icon}`} />
                                                    {op.label}
                                                </NavLink>
                                            </NavItem>
                                        )}
                                    </Nav>
                                </div>
                                <div className='d-flex align-items-start justify-content-end'>
                                    <div className='d-flex align-items-center justify-content-end' style={{ fontSize: '0.75em' }}>
                                        {actionStatusOptions.map((op, key) =>
                                            <div onClick={() => this.toggleStatus(op.value)} key={key} className={`deal-action-badge-${op.value} ${status === op.value ? 'selected-deal-action-badge' : ''} ml-2 p-2`}>
                                                {op.label}
                                            </div>
                                        )}
                                    </div>
                                    <div className='ml-4'>
                                        <Button className="ml-2 btn btn-host btn-sm" onClick={this.toggleOrderByOld}>
                                            {orderByOld ?
                                                <i className="fas fa-sort-amount-up" />
                                                :
                                                <i className="fas fa-sort-amount-down" />
                                            }
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-4'>
                                <BlockUi tag="div" blocking={block}>
                                    {actions && actions.length > 0 ?
                                        <InfiniteScroll
                                            pageStart={0}
                                            loadMore={this.loadNextActions}
                                            element='div'
                                            hasMore={actions.length < total}
                                            loader={<div style={{ width: '100%' }} key="loader"><BlockUi tag="div" blocking={true} /></div>}
                                        >
                                            {actions.map((action, key, arr) =>
                                                <div key={key}>
                                                    <ActionCard
                                                        action={action}
                                                        intl={intl}
                                                        actionsList={arr}
                                                        editFunction={() => toggleActionModal(action)}
                                                        deleteFunction={() => deleteFunction(action, 'Cancelled')}
                                                        downloadFunction={(e) => this.downloadAction(e, action)}
                                                        k={key}
                                                        salesUsers={salesUsers}
                                                        pinAction={(e) => changeActionPin(e, action)}
                                                        finishedFunction={_ => finishFunction(action, 'Finished')}
                                                        onGoingFunction={_ => onGoingFunction(action, 'OnGoing')}
                                                    />
                                                </div>
                                            )}
                                        </InfiniteScroll>
                                        :
                                        <div className='text-muted d-flex align-items-center justify-content-center w-100' style={{ height: '150px' }}>
                                            {!block ?
                                                <FormattedMessage id="SalesProcess.NoActions" />
                                                : ''}
                                        </div>
                                    }
                                </BlockUi>
                            </div>
                        </div>
                    </Col>
                    <Col className='pt-2 col-3 px-0 d-flex justify-content-between h-100 flex-column' style={{ textAlign: 'center', top: '0', position: 'sticky' }}>
                        <div>
                            <div className='d-flex aling-items-center justify-content-between px-3'>
                                <div className=''>
                                    <h5 className='m-0'>
                                        <FormattedMessage id="SalesProcess.InitiativeResume" />
                                    </h5>
                                </div>
                                <div>
                                    <Button onClick={toggleEdit} className="btn btn-host btn-sm">
                                        <i className="fas fa-edit" />
                                    </Button>
                                </div>
                            </div>
                            <div className='px-3 pt-3 text-left'>
                                <div className='shadow w-100 bg-white p-3'>
                                    <div className='text-muted'>
                                        <i className="mr-2 fas fa-suitcase-rolling" />
                                        <FormattedMessage id="SalesProcess.InitiativeDetails" />
                                    </div>
                                    <div className='px-2'>
                                        <Row className='w-100'>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.Type" />
                                                </div>
                                                <div>
                                                    {deal.type ?
                                                        getInitiativeType(intl).find(({ value }) => value === deal.type)?.label ?? '-'
                                                        : '-'}
                                                </div>
                                            </Col>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.ActionType" />
                                                </div>
                                                <div>
                                                    {deal.actionType ?
                                                        getInitiativeActionType(intl).find(({ value }) => value === deal.actionType)?.label ?? '-'
                                                        : '-'}
                                                </div>
                                            </Col>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.Cost" />
                                                </div>
                                                {deal.cost ?
                                                    <div>
                                                        <FormatAmountNumber value={deal.cost} />
                                                    </div>
                                                : '-'}
                                            </Col>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.Location" />
                                                </div>
                                                {deal.location ?
                                                    <div>
                                                        {deal.location}
                                                    </div>
                                                    : '-'}
                                            </Col>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.Budget" />
                                                </div>
                                                {deal.budget ?
                                                    <div>
                                                        <FormatAmountNumber value={deal.budget} />
                                                    </div>
                                                    : '-'}
                                            </Col>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.PaymentDueDate" />
                                                </div>
                                                {deal.paymentDueDate ?
                                                    <div>
                                                        {moment(deal.paymentDueDate).format('YYYY-MM-DD')}
                                                    </div>
                                                    : '-'}
                                            </Col>
                                            <Col className='col-12 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.RelatedHotels" />
                                                </div>
                                                <div>
                                                    {deal.hotelIds ?
                                                        <div style={{ maxHeight: '25px' }} className='overflow-auto veryCoolScrollBar'>
                                                            {deal.hotelIds?.map((hotelId, key) => {
                                                                const hotel = global.hotelList?.find(({ value }) => value === hotelId);
                                                                return (
                                                                    <React.Fragment key={key}>
                                                                        {key ?
                                                                            <span>,</span>
                                                                            : ''}
                                                                        <span className={key ? 'ml-1' : ''}>
                                                                            {hotel?.label || hotelId}
                                                                        </span>
                                                                    </React.Fragment>
                                                                )
                                                            })}
                                                        </div>
                                                        : ''}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            <div className='px-3 pt-3 text-left'>
                                {deal.salesInitiativeProfile && 
                                    <div className='shadow w-100 bg-white p-3'>
                                        <div className='text-muted'>
                                            <i className="fas fa-users mr-2" />
                                            <FormattedMessage id="SalesProcess.PotencialClient" />
                                        </div>

                                        <Row className='px-2 w-100'>
                                            {deal.salesInitiativeProfile?.map((u, k) => {
                                                return (
                                                    <Col key={k} className={`col-6 mt-2`}>
                                                        <div className='title-sm'>{u.role}</div>
                                                        {u.name ?
                                                            <Link to={{ pathname: "/ProfileDetails/" + u.profileId }}>
                                                                {u.name}
                                                            </Link>
                                                            : '-'}
                                                    </Col>
                                                )
                                            }
                                            )}
                                        </Row>
                                    </div>
                                }
                            </div>
                            <div className='px-3 pt-3 text-left'>
                                <div className='shadow w-100 bg-white p-3'>
                                    <div className='text-muted'>
                                        <i className="fas fa-users mr-2" />
                                        <FormattedMessage id="SalesProcess.Staff" />
                                    </div>
                                    <Row className='px-2 w-100'>
                                        {deal.salesInitiativeUsers?.map((u, k) => {
                                            return (
                                                <Col key={k} className={`col-6 mt-2`}>
                                                    <div className='title-sm'>{u.role}</div>
                                                    {u.name ?
                                                        <div>{u.name}</div>
                                                        : '-'}
                                                </Col>
                                            )
                                        }
                                        )}
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

const ActionCard = ({ action, intl, actionsList, editFunction, deleteFunction, downloadFunction, k, salesUsers, pinAction, finishedFunction, onGoingFunction }) => {
    const isActionable = action.type !== 'Status' && action.type !== 'Create' && action.type !== 'Stage' && action.type !== 'SavedValue';
    const lastItem = (k + 1) === actionsList.length;
    const renderActionsUsers = () => {
        const firstUser = salesUsers?.find(({ id }) => id === action.salesDealActionUsers[0]?.userId);
        return (
            <>
                <div
                    className={`emailDescription smallDescription text-muted ${action.salesDealActionUsers.length > 1 ? 'cursor-pointer' : ''}`}
                    style={{ fontSize: '0.9em', width: 'fit-content' }}
                >
                    {firstUser?.firstName && firstUser?.lastName && (
                        <div className='d-flex align-items-center mr-2'>
                            <div>
                                <i id={`interveningHelp-${k}`} className="far fa-user" />
                            </div>
                            <div id={`interveningUsers-${k}`} className='ml-2'>
                                {`${firstUser.firstName} ${firstUser.lastName} ${action.salesDealActionUsers.length > 1 ? '...' : ''}`}
                            </div>
                        </div>
                    )}
                </div>
                {action.salesDealActionUsers.length > 1 && (
                    <CoolTooltip placement="right" target={`interveningUsers-${k}`}>
                        {action.salesDealActionUsers.map((u, key) => {
                            const user = salesUsers?.find(({ id }) => id === u.userId);
                            return user ? (
                                <Col key={key} className={`col-12 ${key !== action.salesDealActionUsers.length - 1 ? 'mb-2' : ''} p-0`}>
                                    <div className='mt-1'>
                                        <div className='emailDescription smallDescription'>
                                            <div className='d-flex align-items-center'>
                                                <div>
                                                    <i className="far fa-user" />
                                                </div>
                                                <div className='ml-2'>
                                                    {`${user.firstName} ${user.lastName}`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ) : null;
                        })}
                    </CoolTooltip>
                )}
                <CoolTooltip placement="top" target={`interveningHelp-${k}`}>
                    <FormattedMessage id="SalesProcess.Interveners" />
                </CoolTooltip>
            </>
        );
    };

    return (
        <>
            {!k ?
                <Row className='w-100'>
                    <Col className='col-1' />
                    <Col className='col-11'>
                        <div className='text-center w-100 text-muted mb-3'>
                            {moment(action.createdAt).format('dddd, DD-MM')}
                        </div>
                    </Col>
                </Row>
                : ''}
            <Row className='w-100'>
                <Col className={`col-1 d-flex align-items-center justify-content-center flex-column pr-0`}>
                    <div>
                        <div
                            title={intl.formatMessage({ id: `SalesProcess.${action.type}` })}
                            style={{
                                fontSize: isActionable ? '1.1em' : '0.85em',
                                background: isActionable ? '#0665ff' : '#bcbcbc',
                                color: 'white',
                                width: isActionable ? '40px' : '30px',
                                height: isActionable ? '40px' : '30px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '100%'
                            }}
                        >
                            {action.type === 'Email' ?
                                <i style={{ marginTop: '1px' }} className="far fa-envelope"></i>
                                : action.type === 'Call' ?
                                    <i className="fas fa-phone"></i>
                                    : action.type === 'Note' ?
                                        <i style={{ marginTop: '1px' }} className="far fa-sticky-note"></i>
                                        : action.type === 'File' ?
                                            <i style={{ marginTop: '1px' }} className="fas fa-file"></i>
                                            : action.type === 'Meeting' ?
                                                <i style={{ marginTop: '1px' }} className="far fa-building"></i>
                                                : action.type === 'Status' ?
                                                    <i style={{ marginTop: '1px' }} className="fas fa-power-off"></i>
                                                    : action.type === 'Create' ?
                                                        <i style={{ marginTop: '1px' }} className="fas fa-briefcase" />
                                                        : action.type === 'Stage' ?
                                                            <i style={{ marginTop: '1px' }} className="fas fa-level-up-alt" />
                                                            : action.type === 'Task' ?
                                                                <i className="far fa-clipboard" />
                                                                : action.type === 'SavedValue' ?
                                                                    <i className="fas fa-camera" />
                                                                    : ''}
                        </div>
                    </div>
                    <div style={{
                        width: '1px',
                        height: '100%',
                        borderLeft: k < (actionsList.length - 1) ? '1px dashed lightgrey' : '',
                        borderRight: k < (actionsList.length - 1) ? '1px dashed lightgrey' : ''
                    }} />
                </Col>
                {isActionable ?
                    <Col className='col-11 pr-0'>
                        <div className='pb-2 px-3 card shadow border-0'>
                            <Row className='py-3'>
                                <Col className='col-8 d-flex align-items-center'>
                                    <div className='text-truncate'>
                                        {action.title ?
                                            action.type === 'Task' ?
                                                <div className='d-flex flex-column align-items-start'>
                                                    <div>
                                                        <b style={{ fontSize: '1.1em' }}>{action.title} </b>
                                                    </div>
                                                    <div className='mt-1'>
                                                        {renderActionsUsers()}
                                                    </div>
                                                </div>
                                                :
                                                <b style={{ fontSize: '1.1em' }}>
                                                    {action.title}
                                                </b>
                                            :
                                            action.type === 'Note' ?
                                                <b style={{ fontSize: '1.1em' }}><FormattedMessage id={`SalesProcess.${action.type}`} /></b>
                                                : ''
                                        }
                                    </div>
                                </Col>
                                <Col className='col-4 d-flex align-items-center justify-content-between'>
                                    <div className='d-flex align-items-center'>
                                        {action.status === 'Scheduled' ?
                                            <i style={{ color: 'lightgrey' }} className="mr-2 fas fa-minus-circle" />
                                            : action.status === 'Cancelled' ?
                                                <i className="mr-2 fas fa-times-circle color-light-red" />
                                                : action.status === 'OnGoing' ?
                                                    <i className="mr-2 fas fa-arrows-alt-h" style={{ color: "#0665ff" }} />
                                                    : action.status === 'Finished' ?
                                                        <i className="mr-2 fas fa-check-circle text-success" />
                                                        : ''}
                                        <div style={{ fontSize: '0.9em', marginTop: '1px' }} className={`deal-action-status-${action.status}`}>
                                            <FormattedMessage id={`SalesActions.${action.status}`} />
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        <div className='text-muted mr-3' style={{ fontSize: '0.9em' }}>
                                            {action.type === "Task" ?
                                                <>
                                                    <div id={`actionDueDateTitle-${k}`}>
                                                        {moment(action.dueDate).format('YYYY-MM-DD HH:mm')}
                                                    </div>
                                                    <CoolTooltip placement="top" target={`actionDueDateTitle-${k}`}>
                                                        <FormattedMessage id="SalesProcess.DeadLine" />
                                                    </CoolTooltip>
                                                </>
                                                :
                                                <>
                                                    <div id={`actionStartDateTitle-${k}`}>
                                                        {moment(action.startDate).format('YYYY-MM-DD HH:mm')}
                                                    </div>
                                                    <CoolTooltip placement="top" target={`actionStartDateTitle-${k}`}>
                                                        <FormattedMessage id="SalesProcess.StartDate" />
                                                    </CoolTooltip>
                                                </>
                                            }
                                        </div>
                                        <div>

                                            {action.type === 'File' || action.type === 'Note' ?
                                                <KebabMenu
                                                    editFunction={editFunction}
                                                    deleteText={"SalesDeal.Cancel"}
                                                    deleteFunction={deleteFunction}
                                                    downloadFunction={action.type === 'File' ? downloadFunction : null}
                                                    extraFields={[
                                                        { text: action.pinned ? "SalesProcess.Unpin" : "SalesProcess.Pin", function: pinAction, icon: "fas fa-thumbtack mr-2" }
                                                    ]}
                                                />
                                                : action.type === 'Task' ?
                                                    <KebabMenu
                                                        editFunction={editFunction}
                                                        deleteFunction={deleteFunction}
                                                        deleteText={"SalesDeal.Cancel"}
                                                        extraFields={[
                                                            action.status == "Scheduled" ? {
                                                                text: "SalesProcess.OnGoing",
                                                                textClass: "onGoingColorIcon",
                                                                function: onGoingFunction,
                                                                icon: "fas fa-arrows-alt-h mr-2 onGoingColorIcon",
                                                            } : null,
                                                            action.status == "OnGoing" ? {
                                                                text: "SalesActions.Finished",
                                                                textClass: "text-success",
                                                                function: finishedFunction,
                                                                icon: "mr-2 fas fa-check-circle text-success"
                                                            } : null,
                                                        ].filter(Boolean)}
                                                    />
                                                    : <KebabMenu
                                                        editFunction={editFunction}
                                                        deleteFunction={deleteFunction}
                                                        deleteText={"SalesDeal.Cancel"}
                                                    />
                                            }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {action.summary ?
                                <>
                                    <hr className='m-0' />
                                    <div className='py-2'>
                                        <div dangerouslySetInnerHTML={{ __html: action.summary }} />
                                    </div>
                                </>
                                : ''}
                            <div className={`d-flex align-items-center justify-content-${action.type === 'Task' ? 'between' : 'end'} mt-1`}>
                                <div className={`mt-1 ${action.type === 'Task' ? 'd-flex align-items-center' : ''}`}>
                                    {action.type === 'Task' && salesUsers?.find(({ userId }) => userId === action.createdBy) && (
                                        <div className='emailDescription smallDescription text-muted' style={{ fontSize: '0.9em', width: 'fit-content' }}>
                                            <div className='title-sm'>
                                                <FormattedMessage id="SalesProcess.CreatedBy" />
                                            </div>
                                            <div>
                                                {salesUsers.find(({ userId }) => userId === action.createdBy)?.firstName} {salesUsers.find(({ userId }) => userId === action.createdBy)?.lastName}
                                            </div>
                                        </div>
                                    )}
                                    {action.salesDealActionUsers?.length && isActionable && action.type !== 'Task' ?
                                        <div>
                                            {renderActionsUsers()}
                                        </div>
                                        : ''}
                                </div>

                                {action.type === 'Task' && (
                                    <div>
                                        {action.startDate && !action.endDate && (
                                            <div className='d-flex flex-column align-items-start text-muted' style={{ fontSize: '0.9em' }}>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.StartDate" />
                                                </div>
                                                <div>
                                                    {moment(action.startDate).format('YYYY-MM-DD HH:mm')}
                                                </div>
                                            </div>
                                        )}
                                        {action.endDate && (
                                            <div className='d-flex flex-column align-items-start text-muted' style={{ fontSize: '0.9em' }}>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.EndDate" />
                                                </div>
                                                <div className='ml-2'>
                                                    {moment(action.endDate).format('YYYY-MM-DD HH:mm')}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        {!lastItem && actionsList[k + 1]?.createdAt && !moment(actionsList[k + 1].createdAt).isSame(action.createdAt, 'date') ?
                            <div className='text-center w-100 text-muted mt-4 mb-3'>
                                {moment(actionsList[k + 1].createdAt).format('dddd, DD-MM')}
                            </div>
                            :
                            <div className='mb-4'></div>
                        }
                    </Col>
                    :
                    <Col className='text-center pb-3 col-11'>
                        <div className='text-truncate'>
                            {action.title}
                        </div>
                        <div className='text-muted' style={{ fontSize: '0.9em' }}>
                            <div dangerouslySetInnerHTML={{ __html: action.summary }} />
                        </div>
                    </Col>
                }
            </Row>
        </>
    )
}

export default injectIntl(SalesInitiativeActions);