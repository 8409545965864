import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Input } from 'reactstrap';
import { handleNotification } from "../../../Base/Notification";
import { BlankCard, StyledModal } from '../../../Base/CommonUIComponents';
import { postAPI, deleteAPI } from "../../../Base/API";
import ConfirmActionModal from '../../../Base/ConfirmActionModal';
import CustomSelect from '../../../Base/CustomSelect';
import RenderHtmlAsImage from '../../../Base/RenderHtmlAsImage';
import moment from 'moment';

class ReportsDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            report: this.props.report,
            deleteModal: false,
        };
    }

    componentDidMount() {
        if(this.props.report && this.props.report.id) {
            this.setState({ block: true }, () => {
                window.setTimeout(
                    () => {
                        this.setState({ block: false });
                    },
                    1500
                );
            });
        }
    }

    saveReport = (e) => {
        if(e) e.preventDefault();
        const { report } = this.state;

        this.setState({ block: true }, () => {
            if(report && !report.id) {
                report.createdAt = moment();
                report.updatedAt = moment();
            }
            const body = { request: report };

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    else if(data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="Reports.ReportSaved" />, <FormattedMessage id="generic.success" />);
                        this.setState({ report: data.response[0], block: false }, () => this.props.getReports());
                        return;
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/DataQuality/v1/NotifyGenericTemplate`, body)
        });
    }

    deleteReport = () => {
        const { report } = this.state;
        this.setState({ block: true }, () => {

            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    else {
                        handleNotification(data, <FormattedMessage id="Reports.ReportDeleted" />, <FormattedMessage id="generic.success" />);
                        this.setState({ deleteModal: false, block: false }, () => this.props.getReports());
                        return;
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/DataQuality/v1/NotifyGenericTemplate/${report && report.id}`)
        });
    }

    toggleDeleteReport = () => {
        this.setState({ deleteModal: !this.state.deleteModal });
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ report: { ...this.state.report, [name]: value } });
    }

    handleChangeSelect = (name, combo) => {
        this.setState({report: { 
            ...this.state.report,
            [name]: combo ? combo.value : null 
        }})
    }


    render() {
        const { report, deleteModal, block, error } = this.state;
        const { hotelGroupList, eventTypeList, toggleModal, modal } = this.props;
        
        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="xl">
                <BlankCard block={block} error={error}>
                    <Form onSubmit={this.saveReport}>
                        <Row className="mb-3">
                            <Col className='d-flex flex-column justify-content-start'>
                                <h5 className='mb-1'>{report && report.id ? <FormattedMessage id="Reports.EditReport" /> : <FormattedMessage id="Reports.CreateReport" />}</h5>
                                {report && report.updatedAt ?
                                    <span style={{ fontSize: '0.9em' }} className="text-muted">
                                        <FormattedMessage id="generic.UpdatedAt" />: {moment(report.updatedAt).format('DD/MM/YYYY - HH:mm')}
                                    </span>
                                : ''}
                            </Col>
                            <Col className="text-right">
                                {report && report.id ?
                                    <Button className="btn-sm btn-host mr-2" onClick={() => this.toggleDeleteReport()}>
                                        <i className="fas fa-trash-alt" />
                                    </Button>
                                : ''}
                                <Button className="btn-sm btn-host" type="submit">
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Col sm={4}>
                                <Label><FormattedMessage id="Reports.Name" /></Label>
                                <Input
                                    type="text"
                                    name="name"
                                    onChange={(e) => this.handleChange(e)}
                                    value={report && report.name ? report.name : ''}
                                    required
                                />
                            </Col>
                            <Col sm={4}>
                                <Label><FormattedMessage id="generic.HotelGroup" /></Label>
                                <CustomSelect
                                    className="w-100"
                                    options={hotelGroupList}
                                    isSearchable
                                    value={report ? hotelGroupList.find(el => el.value === report.hotelGroupId) : ''}
                                    icon={'fas fa-hotel'}
                                    placeholder={this.props.intl.formatMessage({ id: "generic.HotelGroup" })}
                                    onChange={(combo) => this.handleChangeSelect('hotelGroupId', combo)}
                                    required
                                />
                            </Col>
                            <Col sm={4}>
                                <Label><FormattedMessage id="Reports.EventType" /></Label>
                                <CustomSelect
                                    className="w-100"
                                    options={eventTypeList}
                                    isSearchable
                                    value={report ? eventTypeList.find(el => el.value ===  report.eventType) : ''}
                                    icon={'fas fa-tag'}
                                    placeholder={this.props.intl.formatMessage({ id: "Reports.EventType" })}
                                    onChange={(combo) => this.handleChangeSelect('eventType', combo)}
                                    name="eventType"
                                    required
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row className='mt-2'>
                            <Label className='pt-0' sm={12}> <FormattedMessage id="Reports.HtmlTemplate" /></Label>
                            <Col sm={12}>
                                <Input
                                    type="textarea"
                                    className='verticalScroll'
                                    name="htmlTemplate"
                                    onChange={(e) => this.handleChange(e)}
                                    value={report && report.htmlTemplate ? report.htmlTemplate : ''}
                                    style={{ minHeight: '200px' }}
                                    required
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                    {report && report.htmlTemplate ?
                        <Row className='mt-4'>
                            <Col>
                                <RenderHtmlAsImage
                                    html={report.htmlTemplate}
                                    id="reportImage"
                                    isFromReports={true}
                                />
                            </Col>
                        </Row>
                    : ''}
                </BlankCard>
                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={_ => this.toggleDeleteReport()}
                        actionFunction={() => this.deleteReport()}
                        text={<FormattedMessage id="Reports.ConfirmAction" values={{ name: report && report.name}} />}
                        block={block}
                    />
                : ''}
            </StyledModal>
        )
    }
}

export default injectIntl(ReportsDetails);
