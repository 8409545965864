import React, { Component } from 'react'
import { Card, Col, Button, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import CustomSelect from '../../Base/CustomSelect';
import { StyledCard } from '../../Base/CommonUIComponents';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { Doughnut, Line, Bar } from 'react-chartjs-2';
import { getColorPallete, getOpenAICallTypes } from '../../Base/ReferenceDataFunctions';
import { Pagination, TotalResults } from '../../Base/PaginationComponents';
import { ChartLegend } from '../../Base/CustomCharts';


class APIUsage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageSize: 10,
            pageIndex: 0,
            totalItems: 0,
            fromDate: moment().subtract(15, 'days'),
            toDate: moment(),
            usageByDay: { labels: [], datasets: [] },
            callsByType: { labels: [], datasets: [] },
            openAICalls: [],
            callTypes: getOpenAICallTypes(this.props.intl)
        };
    }

    componentDidMount() {
        this.getOpenAICalls();
        this.getOpenAIUsage();
    }

    getOpenAICalls = () => {
        this.setState({ block: true });

        var params = `?fromDate=${moment(this.state.fromDate).format("YYYY-MM-DD")}&toDate=${moment(this.state.toDate).format("YYYY-MM-DD")}&pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}`;
        if (this.state.callType) params += `&callType=${this.state.callType}`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ openAICalls: data.response || [], totalItems: data.totalItems, block: false })
            }
            else this.setState({ block: false, error: errorMessage })
        }, `/api/gms/OpenAI/v1/OpenAICalls` + params);
    }

    getOpenAIUsage = () => {
        this.setState({ block: true });

        var params = `?fromDate=${moment(this.state.fromDate).format("YYYY-MM-DD")}&toDate=${moment(this.state.toDate).format("YYYY-MM-DD")}`;
        if (this.state.callType) params += `&callType=${this.state.callType}`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.organizeChartData(data.response);

            }
            else this.setState({ block: false })
        }, `/api/gms/OpenAI/v1/OpenAIUsageByDay` + params);
    }

    organizeChartData = (data) => {
        const { callTypes } = this.state;
        const formatDate = 'YYYY-MM-DD', type = 'days';
        const totalTypes = callTypes.length;

        var dates = new Array(moment(this.state.toDate).diff(moment(this.state.fromDate), type)).fill(undefined)
            .reduce((acc, cur) => {
                acc.push(moment(this.state.fromDate).add(acc.length, type).format(formatDate));

                return acc;
            }, [moment(this.state.fromDate).format(formatDate)]);

        var usageByDay = {
            labels: dates,
            datasets: callTypes
                .map((type, idx) => ({
                    type: 'line',
                    yAxisID: 'calls',
                    label: `${type.label} ${this.props.intl.formatMessage({ id: "AIConfig.Calls" }) }`,
                    data: Array(dates.length).fill(0),
                    backgroundColor: getColorPallete()[idx],
                    borderColor: getColorPallete()[idx],
                    borderWidth: 1,
                    pointRadius: 3
                }))
                .concat(callTypes.map((type, idx) => ({
                    type: 'bar',
                    yAxisID: 'tokens',
                    label: `${type.label} Tokens`,
                    data: Array(dates.length).fill(0),
                    backgroundColor: `${getColorPallete()[idx]}98`,
                    borderColor: getColorPallete()[idx],
                    borderWidth: 2
                }))) 
        };

        var callsByType = {
            labels: callTypes
                .map(type => `${this.props.intl.formatMessage({ id: ("AIConfig." + type.value) })}`)
                .concat(callTypes.map(type => `${this.props.intl.formatMessage({ id: ("AIConfig." + type.value) })}`)),
            datasets: [
                { label: "Calls by Type", data: Array(totalTypes).fill(0), labels: callTypes.map(type => `${type.value}`), backgroundColor: getColorPallete().splice(0, totalTypes)  },
                { label: "Tokens by Type", data: Array(totalTypes).fill(0), labels: callTypes.map(type => `${type.value}`), backgroundColor: getColorPallete().splice(0, totalTypes).map(el => `${el}98`), borderColor: getColorPallete().splice(0, totalTypes) }
            ]
        };


        data && data.forEach(date => {
            date.usageByCallType && date.usageByCallType.forEach(usage => {
                const callTypeIndex = callTypes.findIndex(ct => ct.value === usage.callType);
                const dateIndex = dates.findIndex(d => d === moment(date.date).format(formatDate));


                //Bar Chart
                if (dateIndex !== -1) {
                    usageByDay.datasets[callTypeIndex].data[dateIndex] += usage.totalCalls;
                    usageByDay.datasets[callTypeIndex + callTypes.length].data[dateIndex] += usage.totalCompletionTokens + usage.totalPromptTokens;
                }


                //Doughnut Chart
                if (callTypeIndex !== -1) {
                    callsByType.datasets[0].data[callTypeIndex] += usage.totalCalls;
                    callsByType.datasets[1].data[callTypeIndex] += usage.totalPromptTokens + usage.totalCompletionTokens;
                }
            })
        })

        this.setState({ block: false, usageByDay, callsByType });
    }

    doSearch = (e) => {
        if (e) e.preventDefault();
        this.setState({
            pageIndex: 0
        }, () => {
            this.getOpenAICalls();
            this.getOpenAIUsage();
        })
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.getOpenAICalls());
    }

    render() {
        const { block, error, openAICalls, callTypes, totalItems, pageIndex, pageSize } = this.state;

        const optionsBar = {
            type: 'line',
            maintainAspectRatio: false,
            legend: {
                display: true,
                position: "top"
            },
            scales: {
                x: {
                    display: true,
                    stacked: true
                },
                tokens: {
                    stacked: true,
                    display: true,
                    position: 'left',
                    beginAtZero: true, min: 0,
                    title: {
                        display: true,
                        text: 'Total Tokens'
                    },
                },
                calls: {
                    type: 'linear',
                    position: 'right',
                    beginAtZero: true, min: 0,
                    stacked: true,
                    title: {
                        display: true,
                        text: 'Total Calls'
                    },
                },
            },
            plugins: {
                datalabels: { display: false }
            }
        }

        const optionsPie = {
            cutout: '60%',
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                datalabels: {
                    display: false
                }
            }
        }

        return (
            <StyledCard block={block} error={error} icon="fas fa-history" title="AIConfig.APIUsage">
                <Row className="mb-3 mt-3 pt-1 align-items-center">
                    <Col className="col-3 pr-0">
                        <CustomSelect
                            options={callTypes}
                            isSearchable isClearable
                            placeholder={<FormattedMessage id="AIConfig.CallType" />}
                            onChange={(e) => this.handleSelect('callType', e)}
                        />
                    </Col>
                    <Col>
                        <DateRangePicker
                            startDate={moment(this.state.fromDate)}
                            endDate={moment(this.state.toDate)}
                            onDatesChange={({ startDate, endDate }) => this.setState({ fromDate: startDate, toDate: endDate })}
                            isOutsideRange={day => day.isAfter(moment(), 'days')}
                            endDateId="your_unique_end_date_id_announcement"
                            startDateId="your_unique_start_date_id_announcement"
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                        />
                    </Col>
                    <Col className="text-right" >
                        <Button className="btn btn-sm btn-host" onClick={this.doSearch}>
                            <i className="fa fa-search" />
                        </Button>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col sm={5}>
                        <Card body className="shadow border-0 h-100">
                            <Row className="mb-2">
                                <Col><h5 className="text-muted"><FormattedMessage id="AIConfig.CallsByType" /></h5></Col>
                            </Row>
                            <Row className="align-items-center h-100">
                                <Col sm={6}>
                                    <div className="text-muted font_size_xxs" style={{ fontSize: '11px' }}>
                                        <FormattedMessage id="AIConfig.Calls" />
                                    </div>
                                    <div>
                                        {callTypes.map((type, key) =>
                                            <ChartLegend color={getColorPallete()[key]} label={type.label || 'N/A'} key={`${key}Token`} />
                                        )}
                                    </div>

                                    <div className="text-muted font_size_xxs mt-4" style={{ fontSize: '11px' }}>
                                        Tokens
                                    </div>
                                    <div>
                                        {callTypes.map((type, key) =>
                                            <ChartLegend color={`${getColorPallete()[key]}98`} borderColor={getColorPallete()[key]} label={type.label || 'N/A'} key={`${key}Token`} />
                                        )}
                                    </div>
                                </Col>

                                <Col sm={6}>
                                    <Doughnut id="pieChart" data={this.state.callsByType} height={200} options={optionsPie} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col sm={7}>
                        <Card body className="shadow border-0 h-100">
                            <Row className="mb-2">
                                <Col><h5 className="text-muted"><FormattedMessage id="AIConfig.Calls" /></h5></Col>
                            </Row>

                            <Row className="mb-3 text-muted align-items-center" >
                                <Col className="pr-0"> <FormattedMessage id="AIConfig.Model" /> </Col>
                                <Col> <FormattedMessage id="AIConfig.Date" /> </Col>
                                <Col className="px-0"> <FormattedMessage id="AIConfig.CallType" /> </Col>
                                <Col className="col-2 text-center"> <FormattedMessage id="AIConfig.InputTokens" /> </Col>
                                <Col className="col-2 text-center"> <FormattedMessage id="AIConfig.OutputTokens" /> </Col>
                            </Row> 

                            {openAICalls?.map((call, key) =>
                                <Row className="overbglight py-1" key={key}>
                                    <Col className="text-ellipsis pr-0" title={call.model}> {call.model} </Col>
                                    <Col className="text-ellipsis" title={call.createdAt ? moment(call.createdAt).format("YYYY-MM-DD") : ''}>
                                        {call.createdAt ? moment(call.createdAt).format("YYYY-MM-DD") : ''}
                                    </Col>
                                    <Col className="text-ellipsis px-0" title={this.props.intl.formatMessage({ id: `AIConfig.${call.callType}` })}>
                                        <FormattedMessage id={`AIConfig.${call.callType}`} />
                                    </Col>
                                    <Col className="text-ellipsis text-center" sm={2}> {call.promptTokens} </Col>
                                    <Col className="text-ellipsis text-center" sm={2}> {call.completionToken} </Col>
                                </Row>
                            )}

                            {totalItems > 0 ?
                                <Row className="mt-3">
                                    <Col>
                                        <TotalResults
                                            end={pageIndex * pageSize + openAICalls.length}
                                            start={pageIndex * pageSize + 1}
                                            total={totalItems}
                                        />
                                    </Col>

                                    <Col className="text-right mr-3">
                                        <Pagination
                                            isPrevDisabled={pageIndex === 0}
                                            isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                            currentPage={pageIndex + 1}
                                            handlePrevButton={() => this.handlePagination(-1)}
                                            handleNextButton={() => this.handlePagination(1)}
                                        />
                                    </Col>
                                </Row>
                            : ''}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card body className="shadow border-0 h-100">
                            <Row className="mb-2">
                                <Col><h5 className="text-muted"><FormattedMessage id="AIConfig.UsageByDay" /></h5></Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Bar id="lineChart" data={this.state.usageByDay} height={350} options={optionsBar} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}

export default injectIntl(APIUsage);