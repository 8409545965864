import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { Button, Card, CardBody, Col, Row, Collapse, UncontrolledTooltip } from 'reactstrap';
import { getAPI, postAPI } from "../Base/API";
import { handleNotification } from "../Base/Notification";
import { injectIntl } from 'react-intl';

class QueueMessage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            isOpen: false,
            showDecryptMsg: false,
            decryptMessage: null
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ showDecryptMsg: false, decryptMessage: null, isOpen: false })
    }

    decryptMessage = (tag, encryptedData) => {
        this.setState({ block: true });

        postAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                }
                else if (data.response && data.response.length > 0) {
                    this.setState({ decryptMessage: data.response[0], showDecryptMsg: true, block: false });
                }
            }
            else this.setState({ block: false });
        }, `/api/gms/SyncEvent/v1/DecryptMessage?tag=${tag}`, encryptedData);
    }

    toggleCollapse = (collapse) => {
        this.setState(prevState => ({
            [collapse]: !prevState[collapse]
        }));
    }

    render() {
        const { isOpen, showDecryptMsg, decryptMessage } = this.state;
        const { item, handleMsgClick, selectedMessages, selectedQueue, isDeadletter } = this.props;

        let queueJson = null;

        try {
            queueJson = JSON.parse(this.props.item.body);
        } catch (e) {
            queueJson = this.props.item.body
        }

        return (
            <BlockUi tag="div" blocking={this.state.block} className="overbglight">
                <Row className={selectedMessages.find(el => el === item.messageId) != undefined ? 'bg-light-grey' : ''}>
                    <Col sm={10}>
                        <Row onClick={() => handleMsgClick(item.messageId)} className={isDeadletter ? "pointer " : ''}>
                            <Col>
                                <span className="float-left ml-2"> {item.hotelId}</span>
                            </Col>
                            <Col className="col-2 px-0">
                                <span className="float-center"> <strong>{queueJson ? queueJson.Tag : ''} </strong></span>
                            </Col>
                            <Col>
                                <span className="float-right">{item.receiveDate}</span>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="col-2 text-right ">
                        {selectedQueue === 'profile' ?
                            <Button className="btn btn-host btn-sm m-1" onClick={() => decryptMessage ? this.toggleCollapse('showDecryptMsg') : queueJson ? this.decryptMessage(queueJson.Tag, queueJson.Data.EncryptedData) : ''}>
                                <i className="fas fa-envelope " />
                            </Button>
                        : ''}
                        <Button className="btn btn-host btn-sm m-1" onClick={() => this.toggleCollapse('isOpen')}>
                            <i className="fas fa-caret-down "></i>
                        </Button>
                    </Col>
                </Row>

                {isOpen &&
                    <Collapse isOpen={isOpen} >
                        <Card>
                            <CardBody>
                                <pre>
                                    <code>
                                        {item.body}
                                    </code>
                                </pre>
                            </CardBody>
                        </Card>
                    </Collapse>
                }

                {showDecryptMsg &&
                    <Collapse isOpen={showDecryptMsg} >
                        <Card>
                        <CardBody>
                            <b className="mb-2"> Decrypted Message </b>
                            <pre className="mb-0 mt-2">
                                    <code>
                                        {decryptMessage && JSON.stringify(decryptMessage, null, 2)}
                                    </code>
                                </pre>
                            </CardBody>
                        </Card>
                    </Collapse>
                }
            </BlockUi>
        );
    }
};

export default injectIntl(QueueMessage)