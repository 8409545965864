import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Badge, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';

import Authorization, { CheckAuthorization } from '../Base/Authorization';
import ConsentDetails from './ConsentDetails';
import { ProfileHistory } from '../Base/HistoryComponents';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import { DefaultPage } from '../Base/CommonUIComponents';
import { getAPI } from '../Base/API';
import { getConsentMethods } from '../Base/ReferenceDataFunctions';


class ProfileConsents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            consentsModal: false,
            availableConsents: [],
            deleteModal: false,
            consentsList: []
        }
    }

    componentDidMount() {
        this.getConfiguredConsents();
    }

    getConfiguredConsents = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                var combo = [];

                if (data.response && data.response.length > 0) {
                    data.response.forEach(el => {
                        combo.push({ value: el.code, label: el.description });
                    })
                }

                this.setState({ consentsList: combo, error: errorMessage, block: false });
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Hotel/v1/consents`);
    }

    toggleModal = (data) => {
        const availableConsents = this.props.profile.consents ? this.state.consentsList.filter(ct => data && data.type === ct.value || this.props.profile.consents.some(pc => pc.type === ct.value) === false) : this.state.consentsList;

        this.setState(prevState => ({
            consentsModal: !prevState.consentsModal,
            selectedConsent: data,
            availableConsents
        }))
    }

    toggleDeleteModal = (selectedConsent) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
            selectedConsent
        }));
    }

    removeConsent = () => {
        this.props.removeElement(this.props.profile.id, this.state.selectedConsent.id, 'consent', 'consents');

        this.toggleDeleteModal();
    }


    render() {
        const { profile, profileId, removeElement, updateTable, canEditProfile } = this.props;
        const { deleteModal } = this.state;

        return (
            <div className="m-2 p-2">
                <Row className="mb-2">
                    <Col>
                        <h5><i className="icon-icon-consents mr-1"> </i><FormattedMessage id="ProfileDetails.ProfileConsent" /></h5>
                    </Col>
                    <Col className="text-right">
                        <Authorization
                            perform="profileConsent:add"
                            yes={() => (
                                <>
                                    <ProfileHistory
                                        component={"consent"}
                                        profileId={this.props.profileId}
                                    />

                                    {canEditProfile &&
                                        <Button className="btn-sm btn-host ml-2" onClick={() => this.toggleModal(null)} disabled={profile.consents && this.state.consentsList.every(cl => profile.consents.some(pc => pc.type === cl.value))}>
                                            <i className="fas fa-plus" />
                                        </Button>
                                    }
                                </>
                            )}
                            no={() => <div></div>}
                        />
                    </Col>
                </Row>
                {profile.consents && profile.consents.map((consent, key) => {
                    const configuredConsent = this.state.consentsList.find(el => consent.type === el.value);

                    return <Card className="border-0 mb-2 shadow" key={key}>
                        <CardBody className="p-2">
                            <Row>
                                <Col>
                                    <div style={{ cursor: 'pointer' }} onClick={() => { if (CheckAuthorization("profileConsent:add", null) && canEditProfile) this.toggleModal(consent) }}>
                                        <Row>
                                            <Col className="col-3">
                                                <div className="mb-0 mr-2">
                                                    <i className="fas fa-file-contract mr-2"></i>
                                                    {configuredConsent ?
                                                        configuredConsent.label
                                                        :
                                                        <FormattedMessage id={`ReferenceDataFunctions.${consent.type}`} />
                                                    }
                                                </div>
                                            </Col>
                                            <Col className="col-1" id={`Type-${consent.id}`}>
                                                {consent.active ? <Badge color="primary" className="ml-2 p-1"><FormattedMessage id="ProfileDetails.Active" /></Badge> : <Badge color="secondary" className="ml-2"><FormattedMessage id="ProfileDetails.Inactive" /> </Badge>}
                                            </Col>
                                            <Col id={`Method-${consent.id}`}><b><FormattedMessage id="ProfileDetails.Method" />: </b> {getConsentMethods().some(mtd => mtd.value === consent.method) ? getConsentMethods().find(mtd => mtd.value === consent.method).label : consent.method}</Col>
                                            <Col id={`ConsentBegin-${consent.id}`}><b><FormattedMessage id="ProfileDetails.ConsentBegin" />: </b> {moment(consent.consentBegin).format("YYYY-MM-DD")}</Col>
                                            <Col id={`ConsentEnd-${consent.id}`}><b><FormattedMessage id="ProfileDetails.ConsentEnd" />: </b>  {moment(consent.consentEnd).format("YYYY-MM-DD")}</Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Authorization
                                    perform="profileConsent:delete"
                                    yes={() => canEditProfile && (
                                        <Col className="text-right col-1">
                                            <span onClick={() => this.toggleDeleteModal(consent)} style={{ cursor: 'pointer' }}>
                                                <i className="fas fa-trash-alt" />
                                            </span>
                                        </Col>
                                    )}
                                    no={() => <div></div>}
                                />
                            </Row>
                        </CardBody>
                    </Card>
                })}

                {profile.consents == null || profile.consents.length == 0 ?
                    <DefaultPage text="ProfileDetails.NoConsentsAvailable" icon="icon-icon-consents" />
                : ''}

                {this.state.consentsModal ?
                    <ConsentDetails
                        modal={this.state.consentsModal}
                        toggleModal={() => this.toggleModal()}
                        consent={this.state.selectedConsent}
                        profileId={profileId}
                        updateTable={updateTable}
                        availableConsents={this.state.availableConsents}
                    />
                : ''}


                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={this.toggleDeleteModal}
                        actionFunction={this.removeConsent}
                        text={<FormattedMessage id="ProfileConsents.ConfirmDeleteText" />}
                    />
                : ''}
            </div>
        );
    }
}

export default injectIntl(ProfileConsents);
