import React, { Component } from 'react';
import { Button, Card, CardBody, Col, CustomInput, Input, Row } from 'reactstrap';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { getAPI, postAPI } from '../Base/API';
import { StyledCard } from '../Base/CommonUIComponents';
import { handleNotification } from '../Base/Notification';

export class RetentionPolicyRule extends Component {

    constructor(props) {
        super(props);
        this.form0 = React.createRef();
        this.form1 = React.createRef();
        this.form2 = React.createRef();
        this.form3 = React.createRef();
        this.form4 = React.createRef();

        this.state = {
            block: true,
            rules: [
                {
                    active: false,
                    ruleType: 'CreationNoData'
                },
                {
                    active: false,
                    ruleType: 'NoShow'
                },
                {
                    active: false,
                    ruleType: 'NoDataWithConsent'
                },
                {
                    active: false,
                    ruleType: 'RemoveDeleted'
                },
                {
                    active: false,
                    ruleType: 'RemoveOwnerProfiles'
                }
            ]
        }
    }

    componentDidMount() {
        getAPI(result => {
            const { error, data } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const { rules } = this.state;
                rules.forEach(rule => {
                    const idx = data.response.findIndex(resp => resp.ruleType === rule.ruleType);
                    if (idx >= 0) {
                        Object.assign(rule, data.response[idx]);
                    }
                })

                this.setState({ error: errorMessage, block: false, rules });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }

        }, `api/gms/Hotel/v1/RetentionRules`)
    }

    handleValue = (evt, idx) => {
        if (evt && evt.target) {
            const { rules } = this.state;
            rules[idx].value = evt.target.value;
            this.setState({ rules });
        }
    }

    handleActive = (evt, idx) => {
        if (evt && evt.target) {
            const { rules } = this.state;
            rules[idx].active = evt.target.checked;
            this.setState({ rules });
        }
    }

    handleSave = (evt, idx) => {
        evt.preventDefault();

        if (!this[`form${idx}`].current.reportValidity()) {
            return;
        }

        const { rules } = this.state;
        const request = {
            request: rules[idx]
        };

        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="RetentionPolicyRule.RuleSuccessSaved" />, <FormattedMessage id="generic.success" />);
                    Object.assign(rules[idx], data.response[0]);
                    this.setState({ error: errorMessage, block: false, rules });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, 'api/gms/Hotel/v1/RetentionRule', request);

    }

    render() {
        const { block, error, rules } = this.state;

        return (
            <StyledCard block={block} error={error} icon="fas fa-cog" title="NavMenu.RetentionPolicyRule">
                <Row className="mb-2">
                    <Col>
                        <Card className="border-0 shadow" body>
                            <h5>
                                <FormattedMessage id="RetentionPolicyRule.Info" />:
                            </h5>
                            <li>
                                <FormattedMessage id="RetentionPolicyRule.Text1" />
                            </li>
                            <li>
                                <FormattedMessage id="RetentionPolicyRule.Text2" />
                            </li>
                            <li>
                                <FormattedMessage id="RetentionPolicyRule.Text3" />
                            </li>
                        </Card>
                    </Col>
                </Row>
                {
                    rules.map((rule, idx) =>
                        <Row key={idx} className="mt-3">
                            <Col>
                                <Card className="border-0 shadow">
                                    <CardBody>
                                        <form ref={this[`form${idx}`]}>
                                            <Row className="align-items-center">
                                                <Col className="col-5">
                                                    <b>
                                                        <FormattedHTMLMessage id={`RetentionPolicyRule.Type${rule.ruleType}`} />
                                                    </b>
                                                    <div className="mt-1">
                                                        <FormattedHTMLMessage id={`RetentionPolicyRule.${rule.ruleType}Text`} values={{ months: (rule.value || 'X') }} />
                                                    </div>
                                                </Col>
                                                <Col className="text-right">
                                                    <b>
                                                        <FormattedMessage id="RetentionPolicyRule.Active" />
                                                    </b>
                                                </Col>
                                                <Col className="text-center">
                                                    <CustomInput type="switch" id={`active${idx}`} name={`active${idx}`} checked={rule.active} onChange={evt => this.handleActive(evt, idx)} />
                                                </Col>
                                                <Col className="text-center">
                                                    <b>
                                                        <FormattedMessage id="RetentionPolicyRule.Month" />
                                                    </b>
                                                </Col>
                                                <Col>
                                                    <Input type="number" id={`value${idx}`} name={`value${idx}`} value={rule.value} onChange={evt => this.handleValue(evt, idx)} min="1" step="1" required />
                                                </Col>
                                                <Col className="col-1">
                                                    <Button className="btn btn-host btn-sm float-right mr-1" onClick={evt => this.handleSave(evt, idx)}>
                                                        <i className="fas fa-save" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        
                    )
                }
            </StyledCard>
        );
    }
}