import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, Col, Form, Input, Modal, ModalBody, Row } from 'reactstrap';
import { postAPI } from '../../Base/API';
import { CommonHelper, StyledCard } from '../../Base/CommonUIComponents';
import CustomSelect from '../../Base/CustomSelect';
import { handleNotification } from '../../Base/Notification';
import { getConsentTypeList } from '../../Base/ReferenceDataFunctions';

class OpenAi extends Component {

    constructor (props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            promptAI: [{
                tone: '0'
            }],
            contentAI: null
        }
    }

    handlePromptChange = (e) => {
        const { value, name } = e.target;
        const { promptAI } = this.state;
        const copy = [...promptAI]
        let prompt = copy[copy.length - 1];

        if(!prompt) {
            copy.push({
                tone: '0'
            });
            prompt = copy[copy.length - 1];
        };

        prompt[name] = value;

        this.setState({ promptAI: copy });
    }

    copyContent = (e) => {
        e.preventDefault();
        const { contentAI } = this.state;
        navigator.clipboard.writeText(contentAI);
    }
    
    getAIContent = (e) => {
        e.preventDefault();
        const { promptAI } = this.state;
        this.setState({ block: true }, () => {

            const text = promptAI[promptAI?.length - 1].subject ? (promptAI[promptAI?.length - 1].subject + ' ' + promptAI[promptAI?.length - 1].bullets) : promptAI[promptAI?.length - 1].bullets;

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.errors && data.errors.length > 0) {
                    this.setState({ block: false, error: data.errors }, () => handleNotification(data));
                    return;
                };
                if (data.response[0].choices[0].text) {
                    this.setState({ block: false, contentAI: data.response[0].choices[0].text, error: null });
                }
                else{
                    this.setState({ block: false, error: null }, () => handleNotification(data));
                }
            }, `/api/gms/OpenAI/v1/completions?prompt=${text}&Temperature=${parseFloat(promptAI[promptAI?.length - 1].tone)}`);
        });
    }

    render() {
        const { contentAI, promptAI, block, error } = this.state;
        const { openAIModal, toggleOpenAIModal, intl } = this.props;

        const toneOptions = [
            { value: '0', label: intl.formatMessage({ id: "OpenAI.Professional" }) },
            { value: '0.2', label: intl.formatMessage({ id: "OpenAI.Serious" }) },
            { value: '0.4', label: intl.formatMessage({ id: "OpenAI.Casual" }) },
            { value: '0.6', label: intl.formatMessage({ id: "OpenAI.Relaxed" }) },
            { value: '0.8', label: intl.formatMessage({ id: "OpenAI.Fun" }) },
            { value: '1', label: intl.formatMessage({ id: "OpenAI.Crazy" }) },
        ];

        return (
            <Modal style={{ display: 'flex', maxWidth: '50vw', marginTop: '0', height: '90%', alignItems: 'center', justifyContent: 'center' }} toggle={toggleOpenAIModal} isOpen={openAIModal}>
                <Button onClick={toggleOpenAIModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody className='p-4' style={{ borderRadius: '8px' }}>
                    <StyledCard block={block} error={error}>
                        <Form className='px-5' onSubmit={this.getAIContent}>
                            <Row>
                                <Col className='col-4'>
                                    <h5><FormattedMessage id="OpenAI.Title"/></h5>
                                </Col>
                                <Col className='text-right'>
                                    <CommonHelper
                                        help={intl.formatMessage({ id: "OpenAI.ExplainationText" })}
                                        id="OpenAIHelp"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className='my-2'>
                                    <Row>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <FormattedMessage id="OpenAI.Subject"/>
                                                </Col>
                                            </Row>
                                            <Row className='mt-2'>
                                                <Col>
                                                    <Input
                                                        type="textarea"
                                                        onChange={this.handlePromptChange}
                                                        style={{ maxHeight: '150px', minHeight: '100px' }}
                                                        placeholder={intl.formatMessage({ id: "OpenAI.SubjectPlaceholder" })}
                                                        value={promptAI[promptAI?.length - 1]?.subject}
                                                        name={'subject'}
                                                        required={true}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <FormattedMessage id="OpenAI.Bullets"/>
                                                </Col>
                                            </Row>
                                            <Row className='mt-2'>
                                                <Col>
                                                    <Input
                                                        type="textarea"
                                                        style={{ maxHeight: '150px', minHeight: '100px' }}
                                                        onChange={this.handlePromptChange}
                                                        placeholder={intl.formatMessage({ id: "OpenAI.BulletsPlaceholder" })}
                                                        value={promptAI[promptAI?.length - 1]?.bullets}
                                                        name={'bullets'}
                                                        required
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <FormattedMessage id="OpenAI.Tone"/>
                                                    <span className='text-muted ml-2' style={{ fontSize: '0.8em' }}>
                                                        <FormattedMessage id="OpenAI.Optional"/>
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row className='mt-2'>
                                                <Col>
                                                    <CustomSelect
                                                        type="text"
                                                        onChange={e => this.handlePromptChange({target:{value:e.value, name: 'tone'}})}
                                                        value={promptAI[promptAI?.length - 1]?.tone ? toneOptions.find(({value}) => value === promptAI[promptAI?.length - 1].tone) : ''}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        options={toneOptions}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className='mt-5'>
                                        <Col>
                                            <Button style={{ width: '100%', height: '100%' }} className="btn btn-sm btn-host py-2">
                                                <b>
                                                    <FormattedMessage id="OpenAI.Generate"/>
                                                    <i className="fas fa-paper-plane pointer ml-2"/>
                                                </b>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row className='my-2 h-100'>
                                        <Col className='text-center h-100'>
                                            <Row>
                                                <Col className='text-right'>
                                                    <Button type="button" onClick={this.copyContent} className="btn btn-sm btn-host" disabled={!contentAI}>
                                                        <i className="fas fa-copy fa-sm"/>
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row className='text-left mt-2' style={{ height: '90%' }}>
                                                <Col>
                                                    <Input
                                                        type='textarea'
                                                        value={contentAI}
                                                        onChange={this.onContentChange}
                                                        disabled={!contentAI}
                                                        style={{ height: '100%', maxHeight: '390px' }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </StyledCard>
                </ModalBody>
            </Modal>
        )
  }
}

export default injectIntl(OpenAi)