import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Row, Button, Badge } from 'reactstrap';
import { StyledCard } from "../Base/CommonUIComponents";
import { FormattedMessage } from 'react-intl';
import GoogleBusinessImg from '../../img/GoogleBusiness.ico';

import { getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import GoogleBusinessBreadcrumb from './GoogleBusinessBreadcrumb';

class GoogleBusinessLocations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            locationList: [],
            accountId: null,
            badgeList: [
                "canDelete",
                "canHaveBusinessCalls",
                "canHaveFoodMenus",
                "canModifyServiceList",
                "canOperateHealthData",
                "canOperateLocalPost",
                "canOperateLodgingData",
                "hasGoogleUpdated",
                "hasPendingEdits",
                "hasVoiceOfMerchant"
            ]
        };
    }

    componentDidMount() {
        const accountId = this.props.accountId//.match.params?.accountId;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false, accountId });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    this.setState({ block: false, accountId }, () => handleNotification(data));
                }
                else {
                    this.setState({ locationList: data.response, error: errorMessage, block: false, accountId });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false, accountId });
            }
        }, `/api/gms/Google/v1/accounts/${accountId}/location`)
    }

    render() {
        const { block, error, locationList, accountId, badgeList } = this.state;

        return (
            <StyledCard block={block} error={error} >
                <Row>
                    {locationList?.map((el, key) =>{
                        const locationId = el.name?.substring(el.name.indexOf('/'), el.name.length);
                        return(
                            <Col className='col-4'>
                                <Card key={key}>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <Link to={`/GoogleBusinessLocationDetail/${accountId}${locationId}`}>
                                                    <h5>{el.title}</h5>
                                                </Link>
                                            </Col>
                                            <Col className="col-2 text-right">
                                                <Button className="btn btn-sm btn-host" href={el.metadata.mapsUri} target="_blank" rel="noopener noreferrer">  
                                                    <i className="fas fa-map-marker-alt"/>
                                                </Button>
                                            </Col>
                                        </Row>
                                        <div className='mt-2'>
                                            {badgeList.map((badge, k) => 
                                                <Badge key={k} className="mr-2" color={el.metadata[badge] ? "primary" : "secondary"}>
                                                    <FormattedMessage id={"GoogleBusinessLocations." + badge}/>
                                                </Badge>
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            </StyledCard>
        );
    }
}

export default injectIntl(GoogleBusinessLocations);