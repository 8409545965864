import React, { Component } from 'react';
import { Button, Card, Col, Row, Badge } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ActiveInactiveStatusBadge, DefaultPage, KebabMenu, StyledCard, CommonHelper } from '../../../../Base/CommonUIComponents';
import { deleteAPI, getAPI, patchAPI, postAPI } from '../../../../Base/API';
import { handleNotification } from '../../../../Base/Notification';
import SendGridWebhookModal from './SendGridWebhookModal';
import { getWebhookActions } from '../../SendGridFunctions';
import ConfirmActionModal from '../../../../Base/ConfirmActionModal';
import Authorization from '../../../../Base/Authorization';

class SendGridWebhooks extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            webhooks: [],
            webhookModal: false,
            webhookActions: getWebhookActions(this.props.intl)
        };
    }

    componentDidMount() {
        this.getWebhooks();
    }

    getWebhooks = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });

                }
                else if (data.response.webhooks) {
                    this.setState({ error: errorMessage, block: false, webhooks: data.response.webhooks });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/SendGrid/v1/webhooks`);
    }

    testWebhook = (webhookId) => {
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });

                }
                else {
                    handleNotification(data, <FormattedMessage id="SendGridWebhooks.WebhookCorrectlyConfigured" />, <FormattedMessage id="generic.success" />);

                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/SendGrid/v1/webhooks/${webhookId}/test`);
    }

    activateVerification = (webhookId) => {
        this.setState({ block: true });

        patchAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="SendGridWebhooks.WebhookCorrectlySignatureVerification" />, <FormattedMessage id="generic.success" />);
                }
            }
            
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SendGrid/v1/webhooks/${webhookId}/toggle?enabled=true`);
    }

    deleteSender = (e, id) => {
        if (e) e.preventDefault();

        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="SendGridWebhooks.WebhookRemoved" />, <FormattedMessage id="generic.success" />);
                }

                this.setState({ deleteModal: false }, this.getWebhooks);
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SendGrid/v1/webhooks/${id}`);
    }

    getExtraFields = (webhook) => {
        var options = [];

        if (webhook) {
            options.push(
                { text: "SendGridWebhooks.TestWebhook", function: _ => this.testWebhook(webhook.id), icon: "fas fa-bell mr-2" },
                { text: "SendGridWebhooks.ActivateSignatureVerification", function: _ => this.activateVerification(webhook.id), icon: "fas fa-power-off mr-2" }
            )
        }

        return options;
    }

    toggleModal = (modal, webhook) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            selectedWebhook: webhook
        }));
    }

    render() {
        const { block, error, webhookModal, webhooks, webhookActions, deleteModal, selectedWebhook } = this.state;
        const { page } = this.props;

        return (
            <StyledCard block={block} error={error}>
                {webhookModal ?
                    <SendGridWebhookModal
                        isOpen={webhookModal}
                        toggleModal={() => this.toggleModal("webhookModal")}
                        webhookActions={webhookActions}
                        selectedWebhook={selectedWebhook}
                        getWebhooks={this.getWebhooks}
                    />
                    : ''}

                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={() => this.toggleModal('deleteModal')}
                        actionFunction={(e) => this.deleteSender(e, selectedWebhook.id)}
                        text={<FormattedMessage id="SendGridWebhooks.ConfirmDeleteText" values={{ name: selectedWebhook.friendly_name }} />}
                        block={block}
                    />
                    : ''}

                <Row className="mb-4 py-1">
                    <Col>
                        <h5><i className={page?.icon} /> {page?.name}</h5>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center">
                        <Button className="btn btn-sm btn-host mr-2" onClick={() => this.toggleModal("webhookModal")}>
                            <i className="fa fa-plus"></i>
                        </Button>
                        <Authorization
                            perform="admin:view"
                            yes={() => (
                                <CommonHelper help={<FormattedMessage id="SendGridWebhooks.Help" />} id={'webHooksHelp'}  /> 
                            )}
                            no={() => <div></div>}
                        />
                    </Col>
                </Row>

                {webhooks && webhooks.length > 0 ?
                    <div className="pt-1">
                        {webhooks.map((webhook, key) =>
                            <Card className="border-0 shadow py-2 px-3 overbglight mb-2" key={key}>
                                <Row>
                                    <Col> <b>{webhook.friendly_name} </b></Col>
                                    <Col className="col-2 text-right d-flex justify-content-end">
                                        <span className="mr-3">
                                            <ActiveInactiveStatusBadge status={webhook.enabled} />
                                        </span>

                                        <KebabMenu
                                            editFunction={() => this.toggleModal('webhookModal', webhook)}
                                            deleteFunction={() => this.toggleModal('deleteModal', webhook)}
                                            extraFields={this.getExtraFields(webhook)}
                                        />
                                    </Col>
                                </Row>

                                <hr className="my-2" />

                                <Row className="my-1">
                                    <Col>
                                        <div className="title-sm"> URL </div>
                                        {webhook.url}
                                    </Col>
                                </Row>
                                <Row className="my-1">
                                    <Col className="pr-0">
                                        <div className="title-sm"> <FormattedMessage id="SendGridWebhooks.Actions" /> </div>
                                        {webhookActions.map((action, idx) => webhook[action.value] &&
                                            <Badge className="mr-2 p-2 mb-2" color="primary" key={idx}> {action.label} </Badge>
                                        )}
                                    </Col>
                                </Row>
                            </Card>
                        )}
                    </div>
                : <DefaultPage text="SendGrid.ThereAreNoWebhooks" icon={page?.icon} />}
            </StyledCard>
        );
    }
}

export default injectIntl(SendGridWebhooks)