import React, { Component } from 'react'
import { StyledCard } from '../../Base/CommonUIComponents'
import { Card, Col, Row, Button, Form } from 'reactstrap';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { getTwilioStatus, SelectAggregatedBy } from '../CommunicationJourney/CommonFunctions';
import { FormattedNumber, injectIntl } from 'react-intl';
import { DateRangePicker } from 'react-dates';

export class TwilioDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            lineChart: { labels: [], datasets: [] },
            startDate: moment().subtract(6, 'days'),
            endDate: moment(),
            aggregatedBy: 'Daily',
            offset: 0,
            cards: [],
            error: null
        };
    }

    componentDidMount() {
        this.getUsage();
    }

    getUsage = (e) => {
        if (e) e.preventDefault();

        const { startDate, endDate, aggregatedBy } = this.state;

        let params = `?startDate=${moment(startDate).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}&aggregation=${aggregatedBy}`;

        this.setState({ block: true }, () => getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }

                if (data.response && data.response.length > 0) {
                    this.buildChartsData(data.response)
                }

                this.setState({ block: false });
            }
        }, `/api/gms/Twilio/v1/account/usage` + params))
    }

    buildChartsData = (results) => {
        let { lineChart, aggregatedBy, cards } = this.state;
        const formatDate = aggregatedBy === 'month' ? "YYYY-MMM" : "YYYY-MM-DD";

        lineChart.datasets = [];
        lineChart.labels = [];
        cards = [];

        //datasets
        lineChart.datasets = getTwilioStatus(this.props.intl).map(el => (
            {
                value: el.value,
                label: el.label,
                data: Array(results.length).fill(0),
                backgroundColor: el.color,
                borderColor: el.color
            }
        ))

        //cards
        cards = getTwilioStatus(this.props.intl).map(el => (
            { ...el, value: 0 }
        ))

        results && results.forEach(date => {
            date.stats && date.stats.forEach(stats => {
                let dataIndex = lineChart.labels.findIndex(el => el === moment(date.date).format(formatDate));

                if (stats.metrics) {
                    //Cards
                    cards[0].value += stats.metrics.phoneNumbers;
                    cards[1].value += stats.metrics.outBoundSms;
                    cards[2].value += stats.metrics.inBoundSms;

                    //Line chart
                    if (dataIndex === -1) {
                        lineChart.labels.push(moment(date.date).format(formatDate));

                        dataIndex = lineChart.labels.length - 1;
                    }

                    lineChart.datasets.forEach(dataset => {
                        dataset.data[dataIndex] += stats.metrics[dataset.value];
                    })

                }
            })
        })

        this.setState({ cards, lineChart, block: false });
    }

    changeDates = (startDate, endDate) => {
        const { aggregatedBy } = this.state;

        if (aggregatedBy == "Daily" && startDate != this.state.startDate && moment(this.state.endDate).isAfter(moment(startDate).add(1, "month"))) {
            this.setState({ startDate, endDate: null })
        }
        else {
            this.setState({ startDate, endDate })
        }
    }

    handleChangeCombo = (name, combo) => {
        const { startDate, endDate } = this.state;
        this.setState({
            [name]: combo ? combo.value : null
        }, () => this.checkDates(startDate, endDate) )
    }

    checkDates = (startDate, endDate) => {
        const { aggregatedBy } = this.state;

        if (aggregatedBy == "Daily" && moment(endDate).isAfter(moment(startDate).add(1, "month"))) {
            this.setState({ startDate, endDate: moment(startDate).add(6, "day") })
        }
        else if (aggregatedBy == "Monthly" && moment(endDate).isAfter(moment(startDate).add(2, "year"))) {
            this.setState({ startDate, endDate: moment(startDate).add(1, "year") })
        }
    }

    renderMonthElement = ({ month, onYearSelect }, startDate) => {

        const getYearOptions = (startDate) => {
            let yearOptions = [];

            if (startDate != null) {
                const currentYear = moment().year();
                const startDateYear = startDate ? moment(startDate).year() : null;

                const initialLength = 5;
                yearOptions = yearOptions.concat(getPastYears(initialLength, currentYear));

                yearOptions.push(currentYear);

                const index = yearOptions.findIndex(el => el > startDateYear);

                yearOptions.splice((index === -1 ? 0 : index), 0, startDateYear);
            }

            return yearOptions;

            function getPastYears(initialLength, currentYear) {
                return new Array(initialLength).fill(undefined).map(_ => currentYear - initialLength--);
            }
        }

        let yearOptions = getYearOptions(startDate);

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Row>
                    <Col className="pr-1">
                        {moment(month).locale(this.props.intl.locale).format('MMMM')}
                    </Col>
                    <Col className="pl-1">
                        <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)} style={{ border: '0' }}>
                            {yearOptions.map((year, idx) =>
                                <option key={idx} value={year}>{year}</option>
                            )}
                        </select>
                    </Col>
                </Row>
            </div>
        );
    }

    render() {
        const { block, error, lineChart, cards } = this.state;
        const { page } = this.props;

        const optionsLine = {
            type: 'line',
            maintainAspectRatio: false,
            legend: {
                display: true,
                position: "top"
            },
            scales: {
                x: { display: true },
                y: {
                    beginAtZero: true, min: 0,
                    grid: {
                        display: false
                    },
                }
            },
            plugins: {
                datalabels: { display: false }
            }
        }
        return (
            <StyledCard block={block} error={error}>
                <Row className="mb-4 py-1">
                    <Col>
                        <h5> <i className={page?.icon} /> {page?.name} </h5>
                    </Col>
                </Row>

                <Form onSubmit={this.getUsage}>
                    <Row className="align-items-center mb-3">
                        <Col sm={2}>
                            <SelectAggregatedBy
                                value={this.state.aggregatedBy}
                                handleChange={this.handleChangeCombo}
                                intl={this.props.intl}
                                required={true}
                            />
                        </Col>
                        <Col>
                            <DateRangePicker
                                startDate={this.state.startDate}
                                startDateId="startDate"
                                isOutsideRange={day => day.isAfter(moment(), "day") ||
                                    (this.state.aggregatedBy == "Daily" && this.state.focusedInput == "endDate" && day.isAfter(moment(this.state.startDate).add(1, 'month'))) || 
                                    (this.state.aggregatedBy == "Monthly" && this.state.focusedInput == "endDate" && day.isAfter(moment(this.state.startDate).add(2, 'year')))
                                }
                                endDate={this.state.endDate}
                                endDateId="endDate"
                                onDatesChange={({ startDate, endDate }) => this.changeDates(startDate, endDate)}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                renderMonthElement={props => this.renderMonthElement(props, this.state.startDate)}
                                required
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className=" btn btn-host btn-sm" type="submit">
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <Row>
                    {cards.map((card, key) =>
                        <Col key={key}>
                            <Card className="bg-white shadow border-0 py-2 pb-2" body>
                                <div className="text-muted">{card.label}</div>
                                <b className="text-right" style={{ color: card.color }}>
                                    <FormattedNumber value={card.value} />
                                </b>
                            </Card>
                        </Col>
                    )}
                </Row>

                <Row className="mt-3">
                    <Col>
                        <Card className="bg-white shadow border-0" body style={{ height: '60vh' }}>
                            <Line data={lineChart} options={optionsLine} />
                        </Card>
                    </Col>
                </Row>
            </StyledCard>
        )
    }
}

export default injectIntl(TwilioDashboard)
