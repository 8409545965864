import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Button, Card, FormGroup, UncontrolledTooltip } from 'reactstrap';
import { ActiveInactiveStatusBadge } from "../../Base/CommonUIComponents";
import CustomSelect from "../../Base/CustomSelect";
import { ErrorAlert } from '../../Base/ErrorAlert';
import { getAPI } from '../../Base/API';
import { CampaignStats } from './MarketingFunctions';
import BlockUi from 'react-block-ui';
import moment from 'moment';
import FormSelection from './FormSelection';
import SelectCampaignModal from './SelectCampaignModal';

class LinkedCampaignsConfig extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            modal: false,
            tagTab: 0,
            selectedRegion: [],
            profiles: [],
            showEvents: false,
            linksOptions: []
        };
    }

    componentDidMount() {
        if (this.props.filters.linkMarketingSendId) {
            this.getSelectedCampaign(this.props.filters.linkMarketingSendId);
        }
        else if (this.props.filters.linkCampaignCriteria) {
            this.setState({ showEvents: true }, this.getCampaigns);
        }
    }

    getSelectedCampaign = (campaignId) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.response && data.response.length > 0) {
                    const linksOptions = data.response[0].marketingType !== 'CustomAB' && data.response[0].marketingSendKpis ? this.getLinkOptions(data.response[0].marketingSendKpis) : [];

                    this.setState({
                        showEvents: data.response[0].marketingType !== 'CustomAB' ? true : false,
                        linksOptions,
                        selectedCampaign: data.response[0],
                        totalItems: 1,
                        block: false
                    });
                }
                else this.setState({ block: false });
            }
            else this.setState({ block: false });
        }, `/api/gms/Marketing/v1/marketingsend/${campaignId}`);
    }

    getLinkOptions = (marketingSendKpis) => {
        return marketingSendKpis.reduce((acc, cur) => {
            cur.marketingSendUrlKpis && cur.marketingSendUrlKpis.forEach(kpi => {
                acc.push({ value: kpi.url, label: kpi.url });
            })
            return acc;
        }, []);
    }

    selectCampaign = (campaign) => {
        const linksOptions = campaign.marketingSendKpis ? this.getLinkOptions(campaign.marketingSendKpis) : [];

        this.setState({
            showEvents: campaign.marketingType !== 'CustomAB' ? true : false,
            linksOptions,
            selectedCampaign: campaign
        }, () => this.props.handleLinkMarketing(campaign.id));
    }

    handleEvent = (combo) => {
        if (combo && (combo.value === 'HasForm') || combo.value === 'HasNoForm') {
            this.props.handleLinkCampCriteria(combo);
        }
        else {
            this.props.handleSelectFilters("linkCampaignCriteria", combo);
        }
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }))
    }

    doSearch = () => {
        this.setState({
            pageIndex: 0
        }, () => this.getCampaigns())
    }

    render() {
        const { filters, handleSelectFilters, handleMultiSelect, campaign, children } = this.props;
        const { modal, showEvents, linksOptions, selectedCampaign } = this.state;

        const events = [
            { value: 'Delivered', label: this.props.intl.formatMessage({ id: "Campaigns.Delivered" }) },
            { value: 'NotDelivered', label: this.props.intl.formatMessage({ id: "Campaigns.NotDelivered" }) },
            { value: 'Viewed', label: this.props.intl.formatMessage({ id: "Campaigns.Viewed" }) },
            { value: 'NotViewed', label: this.props.intl.formatMessage({ id: "Campaigns.NotViewed" }) },
            { value: 'Clicked', label: this.props.intl.formatMessage({ id: "Campaigns.Clicked" }) },
            { value: 'NotClicked', label: this.props.intl.formatMessage({ id: "Campaigns.NotClicked" }) },
            { value: 'HasForm', label: this.props.intl.formatMessage({ id: "Campaigns.HasForm" }) },
            { value: 'HasNoForm', label: this.props.intl.formatMessage({ id: "Campaigns.HasNoForm" }) }
        ]

        const showDates = selectedCampaign?.marketingType === 'Custom' || selectedCampaign?.marketingType === 'CustomInquiry' || selectedCampaign?.marketingType === 'Campaign';

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />

                <Row>
                    <Col>
                        <h5><FormattedMessage id="Campaigns.LinkedTo" /></h5>
                    </Col>

                    {selectedCampaign ?
                        <Col className="text-right">
                            <Button className="btn btn-sm btn-host ml-2" onClick={this.toggleModal} id="changeCampaign">
                                <i className="fas fa-exchange-alt" />
                            </Button>

                            <UncontrolledTooltip target="changeCampaign" placement="left">
                                <FormattedMessage id="CustomStep3Campaigns.ChangeCampaign" />
                            </UncontrolledTooltip>
                        </Col>
                    : ''}
                </Row>


                {selectedCampaign ?
                    <Row className="my-3">
                        <Col className=" mb-3">
                            <Card className="border-0 shadow p-3 h-100">
                                <Row className="mb-2">
                                    <Col>
                                        <h5><span className="bg-light-grey circle-sm mr-2">
                                            {selectedCampaign.senderChannel === "Email" ? <i className="icon-icon-mail px-1" /> : <i className="icon-icon-sms px-1" />}
                                        </span><b>{selectedCampaign.name}</b> </h5>
                                    </Col>
                                    <Col className="text-right col-2">
                                        <ActiveInactiveStatusBadge status={selectedCampaign.active} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <div> <FormattedMessage id={`Campaigns.${selectedCampaign.marketingType}`} /> </div>
                                        <div> <b>Notify at </b> {selectedCampaign.scheduleDay ? moment(selectedCampaign.scheduleDay).format("YYYY-MM-DD HH:mm") : ''} </div>
                                        <div> <b>Sender</b> {selectedCampaign.sender} </div>
                                    </Col>
                                    <Col >
                                        <div className="d-flex align-items-center justify-content-center" style={{ height: '72px' }}>
                                            {selectedCampaign.marketingSendKpis && moment(moment(selectedCampaign.scheduleDay).format("YYYY-MM-DD HH:mm")).isBefore(moment().format("YYYY-MM-DD HH:mm")) ?
                                                <CampaignStats marketingSendKpis={selectedCampaign.marketingSendKpis} />
                                                : <div className="text-center"> <FormattedMessage id="Campaigns.NoStatsAvailable" /> </div>}
                                        </div>
                                    </Col>
                                    <Col sm={2} />
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    :
                    <Row className="my-3">
                        <Col className="mb-3">
                            <Button className="btn btn-sm bg-host-gradient" onClick={this.toggleModal}>
                                <FormattedMessage id="CustomStep3Campaigns.SelectCampaign" />
                            </Button>
                        </Col>
                    </Row>
                }


                {showEvents &&
                    <>
                        <h5><FormattedMessage id="CustomStep3Campaigns.WhenEvent" /></h5>

                        <FormGroup row>
                            <Col className="col-2 mb-2">
                                <CustomSelect
                                    isSearchable
                                    placeholder={<FormattedMessage id="Campaigns.Event" />}
                                    options={events}
                                    onChange={(combo) => this.handleEvent(combo)}
                                    value={filters.linkCampaignCriteria ? events.find((op) => op.value === filters.linkCampaignCriteria) : ''}
                                />
                            </Col>
                        </FormGroup>


                        {filters.linkCampaignCriteria && filters.linkCampaignCriteria === 'Clicked' &&
                            <>
                                <h5>Links</h5>

                                <FormGroup row>
                                    <Col sm={4}>
                                        <CustomSelect
                                            isSearchable isMulti
                                            placeholder={''}
                                            options={linksOptions}
                                            onChange={(combo) => handleMultiSelect("linkCampaignUrls", combo)}
                                            value={filters.linkCampaignUrls ? linksOptions.filter(lo => filters.linkCampaignUrls.find(f => f === lo.value)) : ''}
                                        />
                                    </Col>
                                </FormGroup>
                            </>
                        }
                    </>
                }

                {filters.linkCampaignCriteria === 'HasForm' || filters.linkCampaignCriteria === 'HasNoForm' ?
                    <FormSelection
                        filters={filters}
                        cleanFormData={this.props.cleanFormData}
                        handleSelectFilters={handleSelectFilters}
                    />
                : ''}


                {showDates ?
                    <div className="mt-4">
                        {children}
                    </div>
                : ''}


                {modal ?
                    <SelectCampaignModal
                        modal={modal}
                        toggleModal={this.toggleModal}
                        selectCampaign={this.selectCampaign}
                        filters={filters}
                        campaign={campaign}
                        selectedCampaign={{ id: filters.linkMarketingSendId }}
                        typeOptions={this.props.campaignsOptions}
                    />
                : ''}

            </BlockUi>
        );
    }
}
export default injectIntl(LinkedCampaignsConfig)