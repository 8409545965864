import React, { Component } from 'react';

class DragAndDrop extends Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: this.props.msg !== undefined ? this.props.msg : 'Drop Here'
        };
    }

    dropRef = React.createRef()

    handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.props.handleDrop(e.dataTransfer.files)
            e.dataTransfer.clearData();
        } else {
            this.props.handleDrop(e.dataTransfer);
            e.dataTransfer.clearData();
        }
    }

    componentDidMount() {
        let div = this.dropRef.current
        div.addEventListener('dragenter', this.handleDragIn)
        div.addEventListener('dragleave', this.handleDragOut)
        div.addEventListener('dragover', this.handleDrag)
        div.addEventListener('drop', this.handleDrop)
    }

    componentWillUnmount() {
        let div = this.dropRef.current
        div.removeEventListener('dragenter', this.handleDragIn)
        div.removeEventListener('dragleave', this.handleDragOut)
        div.removeEventListener('dragover', this.handleDrag)
        div.removeEventListener('drop', this.handleDrop)
    }


    render() {
        const { msg } = this.state;

        return (
            <div
                style={{ display: 'inline-block', position: 'relative', width: '100%' }}
                ref={this.dropRef}
            >
                <div
                    style={{
                        border: 'dashed #cecdcd 2px',
                        backgroundColor: 'rgba(255,255,255,.8)',
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 999
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: '10%',
                            right: 0,
                            left: 0,
                            textAlign: 'center',
                            color: '#cecdcd',
                            fontSize: 15
                        }}
                    >
                        <div>
                            {msg}
                        </div>
                    </div>
                </div>
                {this.props.children}
            </div>
        )
    }
}
export default DragAndDrop