import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import { ActiveInactiveStatusCombo, BlankCard, SelectHotelGroup, StyledCard } from "../../../Base/CommonUIComponents";
import { handleNotification } from "../../../Base/Notification";
import { getAPI, postAPI } from "../../../Base/API";
import CustomSelect from '../../../Base/CustomSelect';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { getMLModelType } from '../../../Base/ReferenceDataFunctions';

class ModelDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            model: this.props.model || { modelDate: moment().format("YYYY-MM-DD") }
        };
    }

    saveModel = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const body = { ...this.state.model };

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="MachineLearningConfig.ModelSaved" />, <FormattedMessage id="generic.success" />);

                    if (data.response && data.response.length > 0) {
                        this.props.updateModelList(data.response[0]);
                    }
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/MLModel/v1`, JSON.stringify({ request: body }));
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            survey: {
                ...this.state.survey,
                [name]: value
            }
        })
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            model: {
                ...this.state.model,
                [name]: combo ? combo.value : null
            }
        })
    }

    render() {
        const { block, error, model } = this.state;
        const { modal, toggleModal, hotelGroupList } = this.props;

        return (
            <Modal isOpen={modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <Form onSubmit={this.saveModel}>
                            <Row className="mb-3">
                                <Col>
                                    <h5>{model?.modelId ? <FormattedMessage id="MachineLearningConfig.EditModel" /> : <FormattedMessage id="MachineLearningConfig.CreateModel" />}</h5>
                                </Col>
                                <Col className="text-right col-3">
                                    <Button className="btn-sm btn-host" type="submit">
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>

                            <FormGroup row className="pt-2">
                                <Label sm={2}> <FormattedMessage id="generic.HotelGroup" /> </Label>
                                <Col sm={4}>
                                    <CustomSelect
                                        icon={'icon-icon-hotel'}
                                        options={hotelGroupList}
                                        isClearable isSearchable
                                        placeholder={<FormattedMessage id="generic.HotelGroup" />}
                                        onChange={(e) => this.handleChangeSelect('hotelGroupId', e)}
                                        value={hotelGroupList?.find(el => el.value === model.hotelGroupId) || ''}
                                        isDisabled={model?.modelId ? true : false}
                                        required
                                    />
                                </Col>
                                <Label sm={2}> <FormattedMessage id="generic.status" /> </Label>
                                <Col sm={4}>
                                    <ActiveInactiveStatusCombo
                                        name='active'
                                        onChangeFunc={this.handleChangeSelect}
                                        value={model ? model.active : ''}
                                        placeholder={''}
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2}> <FormattedMessage id="MachineLearningConfig.Type" /></Label>
                                <Col sm={4} >
                                    <CustomSelect
                                        options={getMLModelType(this.props.intl)}
                                        isClearable isSearchable
                                        placeholder={<FormattedMessage id="MachineLearningConfig.Type" />}
                                        onChange={(e) => this.handleChangeSelect('type', e)}
                                        value={getMLModelType(this.props.intl).find(el => el.value === model.type) || ''}
                                        required
                                    />
                                </Col>
                                <Label sm={2}> <FormattedMessage id="MachineLearningConfig.ModelDate" /></Label>
                                <Col sm={4} >
                                    <SingleDatePicker
                                        id="modelDate"
                                        isOutsideRange={day => false}
                                        date={model.modelDate ? moment(model.modelDate) : ''}
                                        focused={this.state.focused}
                                        onFocusChange={({ focused }) => this.setState({ focused })}
                                        small={true}
                                        numberOfMonths={1}
                                        showDefaultInputIcon={true}
                                        onDateChange={date => this.setState({ model: { ...this.state.model, modelDate: moment(date).format("YYYY-MM-DD") } })}
                                        showClearDate={true}
                                        required
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(ModelDetails)