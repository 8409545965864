import React, { Component } from 'react'
import BlockUi from 'react-block-ui';
import { FormattedMessage } from 'react-intl';
import { Badge, Button, Card, CardBody, Col, Modal, ModalBody, Row } from 'reactstrap';
import { ErrorAlert } from '../../Base/ErrorAlert';
import { handleNotification } from '../../Base/Notification';
import { getAPI } from '../../Base/API';
import moment from 'moment-timezone';

export default class CampaignTargetModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            campaignTargets: [],
            selectedTarget: null
        };
    }

    componentDidMount() {
        if(!this.props.campaignTargets){
            this.getTargets();
        }else{
            this.setState({ campaignTargets: this.props.campaignTargets });
        }
    }

    getTargets = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ campaignTargets: data.response ? data.response : [], block: false });
                return;
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/CampaignTargets?excludeInactive=true`);
    }

    selectTarget = (id) => {
        this.setState(prev => ({ selectedTarget: prev.selectedTarget === id ? null : id }));
    }

    applyCampaignTarget = () => {
        const { selectedTarget, campaignTargets } = this.state;
        const campaignTargetDetails = campaignTargets.find(t => t.id === selectedTarget);
        const params = campaignTargetDetails?.params;
        
        this.props.applyCampaignTarget(params, campaignTargetDetails);
    }

    render() {
        const { toggle } = this.props;
        const { block, error, campaignTargets, selectedTarget } = this.state;

        return (
            <Modal isOpen={true} fade={false}>
                <Button onClick={toggle} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody className="p-3">
                    <BlockUi tag="div" blocking={block}>
                    <ErrorAlert error={error} />
                        <div className='px-2'>
                            <Row className='mt-2'>
                                <Col>
                                    <h5 className='m-0'>
                                        <FormattedMessage id="CustomCampaign.SelectCampaignTarget"/>
                                    </h5>
                                </Col>
                                <Col className='text-right'>
                                    <Button className="btn-sm btn-host mr-2" onClick={this.applyCampaignTarget} disabled={!selectedTarget}>
                                        <i className="fas fa-upload"/>
                                    </Button>
                                </Col>
                            </Row>
                            {campaignTargets && campaignTargets.length > 0 ?
                                <Row className='mt-2'>
                                    {campaignTargets?.map((t, k) =>
                                        <Col className='col-4 mb-2' key={k}>
                                            <Card className='cursor-pointer' onClick={_ => this.selectTarget(t.id)} style={{ background: selectedTarget === t.id ? '#d3eaff' : ''  }}>
                                                <CardBody className='p-3'>
                                                    <Row>
                                                        <Col className='col-10'>
                                                            <div>
                                                                <b>{t.name}</b>
                                                            </div>
                                                            <div className='text-muted' style={{ fontSize: '0.8em' }}>
                                                                {t.lastRun ?
                                                                    moment(t.lastRun).format('DD/MM/YYYY HH:mm:ss')
                                                                :
                                                                    <FormattedMessage id="CampaignTargets.NotRun"/>
                                                                }
                                                            </div>
                                                        </Col>
                                                        <Col className='col-2 text-right'>
                                                            <Badge className='ml-2' color={t.active ? 'primary' : 'secondary'}>
                                                                {t.active ?
                                                                    <FormattedMessage id="CampaignTargets.Active"/>
                                                                :
                                                                    <FormattedMessage id="CampaignTargets.Inactive"/>
                                                                }
                                                            </Badge>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )}
                                </Row>
                            : !block ?
                                <Row className='mt-2 text-muted text-center'>
                                    <Col>
                                        <FormattedMessage id="CampaignTargets.NoData"/>
                                    </Col>
                                </Row>
                            : ''}
                        </div>
                    </BlockUi>
                </ModalBody>
            </Modal>
        )
    }
}
