import React, { Component } from 'react'
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl } from 'react-intl'
import { Button, Card, Col, Form, Row } from 'reactstrap'
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import CustomSelect from '../../Base/CustomSelect';
import { Pagination, TotalResults } from '../../Base/PaginationComponents';
import { ErrorAlert } from '../../Base/ErrorAlert';
import { DefaultPage } from '../../Base/CommonUIComponents';

class StripoTemplatesLibrary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            blockTypes: false,
            typeOptions: [],
            blockSeasons: false,
            seasonsOptions: [],
            blockIndustries: false,
            industriesOptions: [],
            categoryOptions: [{ value: 'BASIC', label: <FormattedMessage id="stripo.Basic" /> }, { value: 'FREE', label: <FormattedMessage id="stripo.Free" /> }],
            selectedCategory: 'FREE',
            stripoTemplates: [],
            pageSize: 12,
            pageIndex: 0,
            totalItems: 0,
        };
    }

    componentDidMount() {
        this.getStripoTemplates();

        this.getStripoTypes();
        this.getStripoSeasons();
        this.getStripoIndustries();
    }

    getStripoTypes = () => {
        this.setState({ blockTypes: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if(error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockTypes: false });
                    return;
                }
                if(data) {
                    let typeOptions = [];

                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    else if (data.response?.length > 0 && data.response[0].data?.length > 0) {
                        typeOptions = data.response[0].data.map(el => ({ value: el.id, label: el.name }));
                    }
                    this.setState({ typeOptions, blockTypes: false });
                }
                else this.setState({ blockTypes: false });
            }, `/api/gms/Template/v1/stripo/types`);
        })
    }

    getStripoSeasons = () => {
        this.setState({ blockSeasons: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockSeasons: false });
                    return;
                }
                if (data) {
                    let seasonsOptions = [];

                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    else if (data.response?.length > 0 && data.response[0].data?.length > 0) {
                        seasonsOptions = data.response[0].data.map(el => ({ value: el.id, label: el.name }));
                    }

                    this.setState({ seasonsOptions, blockSeasons: false });
                }
                else this.setState({ blockSeasons: false });
            }, `/api/gms/Template/v1/stripo/seasons`);
        })
    }
    
    getStripoIndustries = () => {
        this.setState({ blockIndustries: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockIndustries: false });
                    return;
                }
                if (data) {
                    let industriesOptions = [];

                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    else if (data.response?.length > 0 && data.response[0].data?.length > 0) {
                        industriesOptions = data.response[0].data.map(el => ({ value: el.id, label: el.name }));
                    }

                    this.setState({ industriesOptions, blockIndustries: false });
                }
                else this.setState({ blockIndustries: false });
            }, `/api/gms/Template/v1/stripo/industries`);
        })
    }

    getStripoTemplates = () => {
        this.setState({ block: true }, () => {
            const { selectedTypes, selectedSeasons, selectedIndustries, pageIndex, pageSize, selectedCategory } = this.state;
            let params = `?type=${selectedCategory}&sort=ACTUAL&page=${pageIndex}&pageSize=${pageSize}`;
            if (selectedTypes?.length > 0) selectedTypes.forEach(el => { params += `&templateTypes=${el.value}` });
            if (selectedSeasons?.length > 0) selectedSeasons.forEach(el => { params += `&templateSeasons=${el.value}` });
            if (selectedIndustries?.length > 0) selectedIndustries.forEach(el => { params += `&templateIndustries=${el.value}` });


            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    this.setState({ stripoTemplates: data.response, totalItems: data.totalItems, block: false });
                }
                else this.setState({ block: false });
            }, `/api/gms/Template/v1/stripo` + params);
        })
    }

    handleChangeSelect = (name, combo) => {
        this.setState({ 
            [name]: combo
        })
    }

    handlePagination = (value) => {
        this.setState({
            pageIndex: this.state.pageIndex + value
        }, this.getStripoTemplates);
    }

    doSearch = (e) => {
        if (e) e.preventDefault();

        this.setState({
            block: true,
            pageIndex: 0
        }, this.getStripoTemplates);
    }

    render() {
        const { blockTypes, typeOptions, blockSeasons, seasonsOptions, blockIndustries, industriesOptions, selectedTypes, selectedSeasons, selectedIndustries,
            categoryOptions, selectedCategory, stripoTemplates, pageIndex, pageSize, totalItems } = this.state;

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />
                <Form onSubmit={this.doSearch}>
                    <Row className="align-items-end mb-4">
                        <Col>
                            <div className="title-sm"><FormattedMessage id="stripo.Category" /></div>
                            <CustomSelect
                                className="small"
                                options={categoryOptions}
                                isSearchable
                                value={categoryOptions.find(el => el.value === selectedCategory) || ''}
                                placeholder={''}
                                onChange={(combo) => this.setState({ selectedCategory: combo ? combo.value : null })}
                                isLoading={blockTypes}
                                isRequired
                            />
                        </Col>
                        <Col>
                            <div className="title-sm"><FormattedMessage id="stripo.Type" /></div>
                            <CustomSelect
                                className="small"
                                options={typeOptions}
                                isClearable isSearchable
                                value={selectedTypes || ''}
                                placeholder={''}
                                onChange={(combo) => this.handleChangeSelect('selectedTypes', combo)}
                                isMulti
                                isLoading={blockTypes}
                            />
                        </Col>
                        <Col>
                            <div className="title-sm"><FormattedMessage id="stripo.Seasons" /></div>
                            <CustomSelect
                                className="small"
                                options={seasonsOptions}
                                isClearable isSearchable
                                value={selectedSeasons || ''}
                                placeholder={''}
                                onChange={(combo) => this.handleChangeSelect('selectedSeasons', combo)}
                                isMulti
                                isLoading={blockSeasons}
                            />
                        </Col>
                        <Col>
                            <div className="title-sm"><FormattedMessage id="stripo.Industries" /></div>
                            <CustomSelect
                                className="small"
                                options={industriesOptions}
                                isClearable isSearchable
                                value={selectedIndustries || ''}
                                placeholder={''}
                                onChange={(combo) => this.handleChangeSelect('selectedIndustries', combo)}
                                isMulti
                                isLoading={blockIndustries}
                            />
                        </Col>
                        <Col sm={1} className="text-right">
                            <Button className="btn btn-host btn-sm mb-2" type="submit">
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>

                    {stripoTemplates?.length > 0 ?
                        <div>
                            <Row>
                                {stripoTemplates.map((temp, key) =>
                                    <Col className='col-2 mb-3' key={key}>
                                        <Card onClick={_ => this.props.previewTemplate({ ...temp, fromStripoLibrary: true })} className='shadow border-0 p-2 cursor-pointer h-100'>
                                            <Row>
                                                <Col style={{ height: "200px", overflow: "hidden" }}>
                                                    <img alt={temp.name} style={{ width: '100%' }} src={temp.logo} />
                                                </Col>
                                            </Row>
                                            <Row className='mt-2 py-1'>
                                                <Col>{temp.name}</Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                )}
                            </Row>

                            {totalItems > 0 ?
                                <Row className="align-items-center mt-3">
                                    <Col>
                                        <TotalResults
                                            end={pageIndex * pageSize + stripoTemplates.length}
                                            start={pageIndex * pageSize + 1}
                                            total={totalItems}
                                        />
                                    </Col>

                                    <Col className="text-right">
                                        <Pagination
                                            isPrevDisabled={pageIndex === 0}
                                            isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                            currentPage={pageIndex + 1}
                                            handlePrevButton={() => this.handlePagination(-1)}
                                            handleNextButton={() => this.handlePagination(1)}
                                        />
                                    </Col>
                                </Row>
                                : ''}
                        </div>
                        :
                        <DefaultPage text="ProfileCredit.NoProfiles" height="20vh" />
                    }
                </Form>
            </BlockUi>
        )
    }
}

export default injectIntl(StripoTemplatesLibrary);