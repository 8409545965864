import React, { Component } from 'react';
import { Row, Col, Nav, NavItem, NavLink, TabContent, CustomInput, Card, Badge, TabPane } from 'reactstrap';
import { getCountriesByRegion, getConsentTypeList, getDimensions, getMetrics, getProfileType } from '../../Base/ReferenceDataFunctions';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI, postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import CustomSelect from "../../Base/CustomSelect";
import { DateRangePicker } from 'react-dates';
import SelectedFilters from './SelectedFilters';
import { ErrorAlert } from '../../Base/ErrorAlert';
import Results from './Results';
import moment from "moment";
import BlockUi from 'react-block-ui';

import cleverLogo from '../../../img/CLEVER-LOGO_website.png';

class ProfileAnalysis extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            activeTab: '0',
            tagTab: 0,
            tagList: [],
            dimensionOrderAsc: true,
            metricOrderAsc: null,
            expanded :false,
            selectedGraph: 'Table',
            reservationStartDate: moment().subtract(6, 'month'),
            reservationEndDate: moment().add(6, 'month'),
            revenueStartDate: moment().subtract(6, 'month'),
            revenueEndDate: moment().add(6, 'month'),
            selectedTags: [],
            selectedRegion: [],
            selectedCountries: [],
            selectedConsents: [],
            dataOutputs: [{ dimensions: [], metrics: [] }],
            resultsData: null,
            reviews: {
                //score: 1,
                scoreBiggerOrEqualThan: true,
                //staff: 1,
                staffBiggerOrEqualThan: true,
                //comfort: 1,
                comfortBiggerOrEqualThan: true,
                //facilities: 1,
                facilitiesBiggerOrEqualThan: true,
                //cleanliness: 1,
                cleanlinessBiggerOrEqualThan: true,
                //location: 1,
                locationBiggerOrEqualThan: true,
                //valueForMoney: 1,
                valueForMoneyBiggerOrEqualThan: true
            },
            profileType: null
        }
       
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.getTags();
    }

    toggle(id) {
        this.setState({ activeTab: id })
    }

    getTags = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    this.setState({ tagList: data.response });
                }

                this.setState({ block: false });
            }
        }, `/api/gms/Profile/v1/tag/groupDetails?tagType=all`)
    }

    expand = () => {
        this.setState(prevState => ({ expanded: !prevState.expanded }));
    }

    changeDimensionOrder = () => {
        this.setState(prevstate => ({ dimensionOrderAsc: prevstate.dimensionOrderAsc === null ? true : !prevstate.dimensionOrderAsc, metricOrderAsc : null }));
    }

    changeMetricsOrder = () => {
        this.setState(prevstate => ({ metricOrderAsc: prevstate.metricOrderAsc ===null ? true : !prevstate.metricOrderAsc, dimensionOrderAsc: null }));
    }

    getAnaliticsData = () => {
        this.setState({ block: true });
        
        const body = {
            filters: {
                ...this.state.reviews,
                reservationsFromDate: moment(this.state.reservationStartDate).format('YYYY-MM-DD'),
                reservationsToDate: moment(this.state.reservationEndDate).format('YYYY-MM-DD'),
                revenueFromDate: moment(this.state.revenueStartDate).format('YYYY-MM-DD'),
                revenueToDate: moment(this.state.revenueEndDate).format('YYYY-MM-DD'),
                tags: this.state.selectedTags.map(el => el.id),
                countries: this.state.selectedCountries.map(el => el.value),
                consentTypes: this.state.selectedConsents,
                profileType: this.state.profileType
            },
            dataOutputs: this.state.dataOutputs
        }

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    this.setState({ resultsData: data.response });
                }

                this.setState({ block: false });
            }
        }, `/api/gms/Analytics/ProfileData`, JSON.stringify(body))
    }

    selectTag = (tag) => {
        let { selectedTags } = this.state;
        const index = selectedTags && selectedTags.indexOf(selectedTags.find(el => el.id === tag.id));

        if (index === -1) {
            selectedTags.push(tag);
        }
        else {
            selectedTags = selectedTags.filter(el => el.id !== tag.id);
        }

        this.setState({ selectedTags })
    }

    handleSelectedBadges = (data, arrayName) => {
        let array = this.state[arrayName] ? this.state[arrayName] : [];
        const index = array && array.indexOf(array.find(el => el === data));

        if (index === -1) {
            array.push(data);
        }
        else {
            array = array.filter(el => el !== data);
        }

        this.setState({ [arrayName]: array })
    }

    handleRegion = (data) => {
        let array = this.state.selectedRegion ? this.state.selectedRegion : [];
        const index = array && array.indexOf(array.find(el => el === data));
        let { selectedCountries } = this.state;
        let countries = getCountriesByRegion().find(region => region.region === data);
        
        if (index === -1) {
            array.push(data);
            selectedCountries = selectedCountries.concat(countries.countries.map(el => el));
        }
        else {
            array = array.filter(el => el !== data);
            selectedCountries = selectedCountries.filter(sc => countries.countries.find(el => el.value === sc.value) === undefined);
        }

        this.setState({ selectedRegion: array, selectedCountries });
    }

    handleDataOutputs = (data, arrayName) => {
        let array = this.state.dataOutputs[0][arrayName] ? this.state.dataOutputs[0][arrayName] : [];
        const index = array && array.indexOf(array.find(el => el === data));

        if (index === -1) {
            array.push(data);
        }
        else {
            array = array.filter(el => el !== data);
        }

        this.setState(prevState => ({
            dataOutputs: [{
                ...prevState.dataOutputs[0],
                [arrayName]: array
            }]
        }));
    }

    handleCountries = (combo) => {
        let { selectedCountries } = this.state;
        selectedCountries = combo !== null ? combo : [];
        this.setState({ selectedCountries });
    }

    getAllSelectedCountries = () => {
        var countries = [];
        var allOptions = getCountriesByRegion().reduce((acc, val) => acc.concat(val.countries), []);

        this.state.selectedCountries && this.state.selectedCountries.forEach(c => {
            const country = allOptions.find(o => o.value === c.value);

            if (country) {
                countries.push(country);
            }
        })
        return countries;
    }

    handleRangeInput = (e) => {
        const { id, value } = e.target;

        this.setState(prevState => ({
            reviews: {
                ...prevState.reviews,
                [id]: value
            }
        }));
    }

    handleBiggerThan = (name) => {
        this.setState(prevState => ({
            reviews: {
                ...prevState.reviews,
                [name]: !prevState.reviews[name]
            }
        }));
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }


    render() {
        const Badges = ({ text, isSelected, onClickFunction, key }) => {
            return (
                <Badge className="p-2 my-1 mr-2" key={key}
                    color={isSelected ? "clever" : "secondary"}
                    onClick={onClickFunction}
                    style={{ cursor: 'pointer' }}
                >
                    {text}
                </Badge>
            );
        }

        const Review = ({ reviewTitle, biggerThanField, id }) => {
            return <Row className="my-1 d-flex align-items-center">
                <Col className="col-3">
                    <div className="text-muted" color="primary"><b><FormattedMessage id={reviewTitle} /></b></div>
                </Col>
                <Col className="col-1">
                    <Badge className="p-2 my-1 mr-2 pointer" color="clever" onClick={() => this.handleBiggerThan(biggerThanField)}>
                        {/*this.state.reviews[biggerThanField] ? <FormattedMessage id="ProfileAnalysis.BiggerOrEqualThan" /> : <FormattedMessage id="ProfileAnalysis.SmallerOrEqualThan" />*/}
                        {this.state.reviews[biggerThanField] ? ' >= ' : ' <= '}
                    </Badge>
                </Col>
                <Col>
                    <CustomInput type="range" className="clever" id={id} min="1" max="100" step="1"
                        value={this.state.reviews[id]}
                        onChange={e => this.handleRangeInput(e)} />
                </Col>
                <Col className="text-center text-muted sm text-sm col-1 px-1">
                    {this.state.reviews[id]}
                </Col>
            </Row>
        }

        return (
            <div className="container-fluid h-100 p-0">
                <ErrorAlert error={this.state.error} />
                <Row className="mt-4">
                    <Col>
                        <h5><i className="fas fa-brain mx-2 mb-3" /><FormattedMessage id="NavMenu.analisys" /></h5>
                    </Col>
                </Row>
                <Row className="h-100">
                    {this.state.expanded === false ?  
                    <Col className="col-4 pr-1">
                        <Nav tabs className="border-0">
                            <NavItem >
                                    <NavLink className={this.state.activeTab === '0' ? 'tab-border-clever text-clever border-0 ' : 'border-0'} onClick={() => { this.toggle('0'); }}>
                                    <b> <FormattedMessage id="ProfileAnalysis.Filter" /></b>
                                </NavLink>
                            </NavItem>
                            <NavItem> 
                                    <NavLink className={this.state.activeTab === '1' ? 'tab-border-clever text-clever  border-0' : 'border-0'} onClick={() => { this.toggle('1'); }}>
                                    <b> <FormattedMessage id="ProfileAnalysis.Data" /></b>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                    <NavLink className={this.state.activeTab === '2' ? 'tab-border-clever text-clever  border-0' : 'border-0'} onClick={() => { this.toggle('2'); }}>
                                    <b>  <FormattedMessage id="ProfileAnalysis.Layout" /></b>
                                </NavLink>
                                </NavItem>
                                <div className="tex-right" style={{ position: "absolute", right: '5px', fontWeight: "bolder" }} >Powered by <img style={{ maxHeight: '30px' }} src={cleverLogo}></img></div>
                                
                        </Nav>

                        <TabContent activeTab={this.state.activeTab} className="h-100"> 
                            <TabPane tabId="0" className="border-0 my-0 p-0 ">
                                <Card className="p-2 border-0 bg-light">
                                    <Row className="my-2">
                                        <Col>
                                            <b> <FormattedMessage id="ProfileAnalysis.Dates" /></b>
                                        </Col>
                                    </Row>

                                    
                                        <Row className="my-2">
                                            <Col className="col-6">
                                                <b className="text-muted"><FormattedMessage id="ProfileAnalysis.Reservations" /></b>
                                            </Col>
                                            <Col className="col-6">
                                                <b className="text-muted"><FormattedMessage id="ProfileAnalysis.Revenue" /></b>
                                            </Col>
                                           
                                        </Row>
                                        <Row className="my-1">
                                            <Col>
                                                <DateRangePicker
                                                    startDate={this.state.reservationStartDate}
                                                    startDateId="reservations_start_date_id"
                                                    isOutsideRange={day => day <= moment().subtract(24, 'month') || day > moment().add(6, 'month')}
                                                    endDate={this.state.reservationEndDate}
                                                    endDateId="reservations_end_date_id"
                                                    onDatesChange={({ startDate, endDate }) => this.setState({ reservationStartDate: startDate, reservationEndDate: endDate })}
                                                    focusedInput={this.state.focusedInput}
                                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                                    small={true}
                                                    numberOfMonths={2}
                                                    showDefaultInputIcon={true}
                                                />
                                            </Col>
                                            <Col>
                                                <DateRangePicker
                                                    startDate={this.state.revenueStartDate}
                                                    startDateId="your_unique_start_date_id"
                                                    isOutsideRange={day => day <= moment().subtract(24, 'month') || day > moment().add(6, 'month')}
                                                    endDate={this.state.revenueEndDate}
                                                    endDateId="your_unique_end_date_id"
                                                    onDatesChange={({ startDate, endDate }) => this.setState({ revenueStartDate: startDate, revenueEndDate: endDate })}
                                                    focusedInput={this.state.focusedInput1}
                                                    onFocusChange={focusedInput1 => this.setState({ focusedInput1 })}
                                                    small={true}
                                                    numberOfMonths={2}
                                                    showDefaultInputIcon={true}
                                                />
                                            </Col>
                                        </Row>
                                    
                                   
                                    <Row className="mb-2 mt-3">
                                        <Col>
                                            <b> Paises / regiões</b>
                                        </Col>
                                    </Row>

                                    <div>
                                        <Row className="my-1">
                                            <Col>
                                                <b className="text-muted"> Regiões</b>
                                            </Col>
                                        </Row>
                                        
                                        {getCountriesByRegion().map((region, key) =>
                                            <span key={key}>
                                            <Badges
                                                
                                                isSelected={this.state.selectedRegion && this.state.selectedRegion.find(t => t === region.region) !== undefined ? true : false}
                                                onClickFunction={() => this.handleRegion(region.region)}
                                                text={region.region}
                                            />
                                                </span>
                                        )}

                                    </div>
                                        <Row className="my-1">
                                            <Col>
                                                <b className="text-muted"> Países</b>
                                            </Col>
                                        </Row>
                                        <CustomSelect
                                            icon={"fas fa-globe"}
                                            options={getCountriesByRegion().map(o => o.region !== 'CPLP' ? o.countries : '').flat()}
                                            required={true}
                                            isClearable
                                            isMulti={true}
                                            isSearchable
                                            value={this.getAllSelectedCountries()}
                                            onChange={this.handleCountries.bind(this)}
                                        />
                                    

                                        <Row className="mb-2 mt-3">
                                            <Col>
                                                <b> Tags</b>
                                            </Col>
                                        </Row>
                                        <div >
                                            <Nav tabs className="border-0">
                                                    {this.state.tagList && this.state.tagList.map((el, k) =>


                                                        <NavItem key={k}>
                                                    <NavLink className={this.state.tagTab === k ? 'tab-border-clever text-clever border-0 ' : 'border-0'} onClick={() => { this.setState({ tagTab :  k }); }}>
                                                        <b > {el.code}</b>
                                                </NavLink>
                                            </NavItem>
                                                )}    
                                                </Nav>
                                            <TabContent activeTab={this.state.tagTab} className="h-100">
                                            
                                                    {this.state.tagList && this.state.tagList.map((el, k) =>
                                                        <TabPane tabId={k} className="border-0 my-0 p-0 " key={k}>
                                                    
                                                            {el.tag && el.tag.map((tag, k1) =>
                                                                <span key={k1}>
                                                            <Badges
                                                                isSelected={this.state.selectedTags && this.state.selectedTags.find(t => t.id === tag.id) !== undefined ? true : false}
                                                                onClickFunction={() => this.selectTag(tag)}
                                                                text={tag.code}
                                                                    />
                                                                    </span>
                                                )}

                                            </TabPane>
                                            )}
                                        </TabContent>
                                           
                                    </div>


                                        <Row className="mb-2 mt-3">
                                            <Col>
                                                <b>Profile Type</b>
                                            </Col>
                                        </Row>
                                        <CustomSelect
                                            icon={"fas fa-user"}
                                            options={getProfileType(this.props.intl)}
                                            isClearable
                                            isSearchable
                                            onChange={this.handleSelect.bind(this, 'profileType')}
                                        />


                                        <Row className="mb-2 mt-3">
                                            <Col>
                                                <b>Profile Score</b>
                                            </Col>
                                        </Row>
                                        <Row className="my-1 d-flex align-items-center">
                                            <Col className="col-3">
                                                <div className="text-muted" color="primary"><b>Score</b></div>
                                            </Col>
                                            <Col className="col-1">
                                                <Badge className="p-2 my-1 mr-2 pointer" color="clever" onClick={() => this.handleBiggerThan('scoreBiggerOrEqualThan')}>
                                                    {this.state.reviews.scoreBiggerOrEqualThan === true ? ' >= ' : ' <= '}
                                                </Badge>
                                            </Col>
                                            <Col>
                                                <CustomInput type="range" className="clever" id="score" min="1" max="5" step="1"
                                                    value={this.state.reviews.score || ''}
                                                    onChange={e => this.handleRangeInput(e)} />
                                            </Col>
                                            <Col className="text-center text-muted sm text-sm col-1 px-1">
                                                {this.state.reviews.score}
                                            </Col>
                                        </Row>


                                        <Row className="mb-2 mt-3">
                                            <Col>
                                                <b> Reviews</b>
                                            </Col>
                                        </Row>

                                        <Review reviewTitle="ProfileReview.Staff" biggerThanField='staffBiggerOrEqualThan' id='staff' />
                                        <Review reviewTitle="ProfileReview.Comfort" biggerThanField='comfortBiggerOrEqualThan' id='comfort' />
                                        <Review reviewTitle="ProfileReview.Cleanliness" biggerThanField='cleanlinessBiggerOrEqualThan' id='cleanliness' />
                                        <Review reviewTitle="ProfileReview.Facilities" biggerThanField='facilitiesBiggerOrEqualThan' id='facilities' />
                                        <Review reviewTitle="ProfileReview.Location" biggerThanField='locationBiggerOrEqualThan' id='location' />
                                        <Review reviewTitle="ProfileReview.ValueForMoney" biggerThanField='valueForMoneyBiggerOrEqualThan' id='valueForMoney' />

                                        <Row className="mb-2 mt-3">
                                            <Col>
                                                <b>Consents</b>
                                            </Col>
                                        </Row>
                                        <div >
                                            {getConsentTypeList().map((consent, key) =>
                                                <span key={key}>
                                            <Badges
                                                isSelected={this.state.selectedConsents && this.state.selectedConsents.find(c => c === consent.value) !== undefined ? true : false}
                                                onClickFunction={() => this.handleSelectedBadges(consent.value, 'selectedConsents')}
                                                text={consent.label}
                                                    />
                                                    </span>
                                        )}

                                    </div>
                                </Card>
                            </TabPane>

                            <TabPane tabId="1" className="border-0 my-0 p-0 "  >
                                <Card className="p-2  border-0 bg-light">
                                    <b className="my-2"> <FormattedMessage id="ProfileAnalysis.Dimensions" /></b>
                                        {getDimensions().map((el, key) =>
                                            <div key={key}>
                                                <Row className="mb-0 mt-2">
                                                    <Col>
                                                        <b className="text-muted">{el.type}</b>
                                                    </Col>
                                                </Row>
                                           
                                                {el.dimensions.map((dimension, k2) =>
                                                    <span key={k2}>
                                                        <Badges                                                       
                                                            isSelected={this.state.dataOutputs[0].dimensions && this.state.dataOutputs[0].dimensions.find(d => d === dimension.value) !== undefined ? true : false}
                                                            onClickFunction={() => this.handleDataOutputs(dimension.value, 'dimensions')}
                                                            text={dimension.label}
                                                        />
                                                    </span>
                                                )}
                                            </div>
                                        )}


                                    <b className="mt-3 mb-2"><FormattedMessage id="ProfileAnalysis.Metrics" /></b>

                                    {getMetrics().map((el, key) =>
                                        <div key={key}>
                                            <Row className="mb-0 mt-2 ">
                                                <Col>
                                                    <b className="text-muted">{el.type}</b>
                                                </Col>
                                            </Row>
                                            
                                            {el.metrics.map((metric, k2) =>
                                                <span key={k2}>
                                                    <Badges
                                                       
                                                        isSelected={this.state.dataOutputs[0].metrics && this.state.dataOutputs[0].metrics.find(d => d === metric.value) !== undefined ? true : false}
                                                        onClickFunction={() => this.handleDataOutputs(metric.value, 'metrics')}
                                                        text={metric.label}
                                                    />
                                                    </span>
                                                )}
                                            </div>
                                    )}
                                </Card>
                            </TabPane>

                            <TabPane tabId="2" className="border-0 my-0 p-0 "  >
                                <Card className="p-2 border-0 bg-light">
                                    <Row className="my-3">
                                        <Col>
                                            <Card className={"text-center p-4 border-0 shadow " + (this.state.selectedGraph === 'Table' ? ' bg-clever text-white' : '')} style={{ cursor: 'pointer' }} onClick={()=>this.setState({ selectedGraph: 'Table' })}>
                                                <i className="fas fa-border-all fa-2x"></i>
                                                <div className="mt-2">Table</div>
                                            </Card>
                                        </Col>
                                        <Col>
                                                <Card className={"text-center p-4 border-0 shadow " + (this.state.selectedGraph === 'Pie_Chart' ? ' bg-clever text-white' : '')} style={{ cursor: 'pointer' }} onClick={() => this.setState({ selectedGraph: 'Pie_Chart' })}>
                                                <i className="fas fa-chart-pie fa-2x"></i>
                                                <div className="mt-2">Pie Chart</div>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                        <Col>
                                                <Card className={"text-center p-4 border-0 shadow " + (this.state.selectedGraph === 'Line_Chart' ? ' bg-clever text-white' : '')} style={{ cursor: 'pointer' }} onClick={() => this.setState({ selectedGraph: 'Line_Chart' })}>
                                                <i className="fas fa-chart-line fa-2x"></i>
                                                <div className="mt-2">Line graph</div>
                                            </Card>
                                        </Col>
                                        <Col>
                                                <Card className={"text-center p-4 border-0 shadow " + (this.state.selectedGraph === 'Bar_Chart' ? ' bg-clever text-white' : '')} style={{ cursor: 'pointer' }} onClick={() => this.setState({ selectedGraph: 'Bar_Chart' })}>
                                                <i className="fas fa-chart-bar fa-2x"></i>
                                                <div className="mt-2">Bar Chart</div>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                        <Col>
                                                <Card className={"text-center p-4 border-0 shadow " + (this.state.selectedGraph === 'World_Map' ? ' bg-clever text-white' : '')} style={{ cursor: 'pointer' }} onClick={() => this.setState({ selectedGraph: 'World_Map' })}>
                                                <i className="fas fa-globe-europe fa-2x"></i>
                                                <div className="mt-2">World Map</div>
                                            </Card>
                                        </Col>
                                        <Col>
                                                <Card className={"text-center p-4 border-0 shadow " + (this.state.selectedGraph === 'Three_Map' ? ' bg-clever text-white' : '')} style={{ cursor: 'pointer' }} onClick={() => this.setState({ selectedGraph: 'Three_Map' })}>
                                                <i className="fas fa-th-large fa-2x"></i>
                                                <div className="mt-2">Three Map</div>
                                            </Card>
                                        </Col>
                                    </Row>

                                </Card>
                            </TabPane>
                        </TabContent>
                        </Col>
                        : ''}
                    <Col className={this.state.expanded ? 'pr-1  col-12 ' : 'pl-1  col-8 '}>
                        <div className="h-100">
                            
                            <Row className="mb-1 ">
                                <Col>
                                    <SelectedFilters
                                        tags={this.state.selectedTags}
                                        getAnaliticsData={this.getAnaliticsData}
                                        consents={this.state.selectedConsents}
                                        countries={this.state.selectedCountries}
                                        selectedGraph={this.state.selectedGraph}
                                        dataOutputs={this.state.dataOutputs}
                                        dimensionOrderAsc={this.state.dimensionOrderAsc}
                                        metricOrderAsc={this.state.metricOrderAsc}
                                        changeDimensionOrder={this.changeDimensionOrder}
                                        changeMetricsOrder={this.changeMetricsOrder}
                                        reviews={this.state.reviews}
                                        profileType={this.state.profileType}
                                    />
                                </Col>
                            </Row> 

                            <Row className="h-100">
                                <Col>
                                    <BlockUi tag="div" blocking={this.state.block}>
                                        <Results
                                            expand={this.expand}
                                            isExpanded={this.state.expanded}
                                            results={this.state.resultsData}
                                            selectedGraph={this.state.selectedGraph}
                                            dimensionsCount={this.state.dataOutputs[0].dimensions.length}
                                            metrics={this.state.dataOutputs[0].metrics}
                                            dimensionOrderAsc={this.state.dimensionOrderAsc}
                                            metricOrderAsc={this.state.metricOrderAsc}
                                        />
                                        </BlockUi>
                                    </Col>
                                </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}
export default injectIntl(ProfileAnalysis);