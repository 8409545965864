import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Col, Row, Form, Button, Input, Modal, ModalBody, FormGroup, Label } from 'reactstrap';
import { StyledCard, BlankCard } from '../Base/CommonUIComponents';
import { handleNotification } from '../Base/Notification';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI, deleteAPI, postAPI } from '../Base/API';
import { CustomTable } from '../Base/CustomTable';
import CustomSelect from "../Base/CustomSelect";
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import Authorization, { CheckAuthorization } from '../Base/Authorization';

class SuppressionList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            blockModal: false,
            modal: false,
            campaigns: [],
            offset: 0,
            limit: 10,
            totalItems: 0,
            newItem: { type: null, supressionList: [] },
            typeOptions: [
                { value: "email", label: "Email" },
                { value: "email_domain", label: "Email Domain" },
                { value: "email_user", label: "Email User" },
                { value: "cellphone", label: "Cellphone" },
                { value: "phone", label: "Phone" }
            ],
            methodOptions: [
                { value: "unsubscribe", label: "Unsubscribe" },
                { value: "bounce", label: "Bounce" },
                { value: "manual", label: "Manual" },
                { value: "other", label: "Other" },
                { value: "forgotten", label: "Forgotten" }
            ],
            pageIndex: 0
        }
    }

    componentDidMount() {
        this.getCampaigns();
    }

    getCampaigns = () => {
        this.setState({ block: true });

        var list = [];

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }

                data.items && data.items.forEach(el => {
                    list.push({ value: el.campaign_hash, label: el.title })
                })
            }

            this.setState({ block: false, campaigns: list }, this.getSuppressionList);
        }, `/api/gms/Marketing/v1/egoi/campaigns?limit=100&offset=0`);
    }

    getSuppressionList = () => {
        this.setState({ block: true });

        var params = '';
        if (this.state.type) params += `&type=${this.state.type}`;
        if (this.state.method) params += `&method=${this.state.method}`;
        if (this.state.campaignId) params += `&campaignId=${this.state.campaignId}`;
        if (this.state.createdMin) params += `&createdMin=${moment(this.state.createdMin).format("YYYY-MM-DD")}`;
        if (this.state.createdMax) params += `&createdMax=${moment(this.state.createdMax).format("YYYY-MM-DD")}`;
        if (this.state.value) params += `&value=${this.state.value}`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if ((data.Errors && data.Errors.length > 0) || (data.errors && data.errors.length > 0)) {
                    handleNotification(data);
                }

                this.setState({
                    suppressionList: data.items,
                    totalItems: data.total_items,
                    block: false
                });
            }
            else this.setState({ block: false })
        }, `/api/gms/Marketing/v1/egoi/suppressionList?limit=${this.state.limit}&offset=${this.state.offset}` + params);
    }

    addItem = (e) => {
        if (e) e.preventDefault();

        this.setState({ blockModal: true });
        const { newItem } = this.state;

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorModal: errorMessage, blockModal: false });
                return;
            }
            if (data) {
                if ((data.Errors && data.Errors.length > 0) || (data.errors && data.errors.length > 0)) {
                    handleNotification(data);
                    this.setState({ blockModal: false });
                }
                else {
                    handleNotification(data, <FormattedMessage id="SuppressionList.ItemAdded" />, <FormattedMessage id="generic.success" />);
                    this.setState({ blockModal: false, modal: false, newItem: { type: null, supressionList: [] } });
                    this.doSearch();
                }
            }
        }, `/api/gms/Marketing/v1/egoi/suppressionList/${newItem.type}`, newItem.supressionList);
    }

    deleteItem = (e, itemId) => {
        if (e) e.preventDefault();

        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if ((data.Errors && data.Errors.length > 0) || (data.errors && data.errors.length > 0)) {
                    handleNotification(data);
                    this.setState({ block: false });
                }
                else {
                    this.doSearch();
                    handleNotification(data, <FormattedMessage id="SuppressionList.ItemRemoved" />, <FormattedMessage id="generic.success" />); 
                }
            }
        }, `/api/gms/Marketing/v1/egoi/suppressionList/${itemId}`);
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        })
    }

    handleNewItemType = (combo) => {
        this.setState(prevState => ({
            newItem: {
                ...prevState.newItem,
                type: combo? combo.value : null
            }
        }));
    }

    handleNewItemValues = (value, addItem, idx) => {
        let { newItem, newValue } = this.state;

        if (addItem) {
            newItem.supressionList.push(value);
            newValue = null;
        }
        else {
            newItem.supressionList.splice(idx, 1);
        }

        this.setState({ newItem, newValue });
    }
    
    toggleModal = (e) => {
        e.preventDefault();

        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    handleTableChange = (_, { sizePerPage, page }) => {
        const pages = page > 0 ? page - 1 : 0;

        this.setState({
            block: true,
            pageIndex: pages,
            limit: sizePerPage,
            offset: sizePerPage * pages
        }, () => this.getSuppressionList());
    }

    doSearch = (e) => {
        if (e) e.preventDefault();

        this.setState({
            block: true,
            offset: 0,
            totalItems: 0
        }, () => this.getSuppressionList());
    }

    render() {
        const { block, error, suppressionList, typeOptions, methodOptions, createdMin, createdMax, campaigns, newItem } = this.state;

        const columns = [
            {
                dataField: 'campaign_hash',
                text: this.props.intl.formatMessage({ id: "SuppressionList.Campaign" }),
                formatter: (cell, row) => {
                    const campaign = campaigns.find(el => el.value === cell);

                    if (cell && campaign) {
                        return campaign.label;
                    }
                    else return cell;
                }
            },
            {
                dataField: 'type',
                text: this.props.intl.formatMessage({ id: "SuppressionList.Type" })
            },
            {
                dataField: 'value',
                text: this.props.intl.formatMessage({ id: "SuppressionList.Value" })
            },
            {
                dataField: 'method',
                text: this.props.intl.formatMessage({ id: "SuppressionList.Method" })
            },
            {
                dataField: 'created',
                text: this.props.intl.formatMessage({ id: "SuppressionList.Created" })
            },
            {
                dataField: 'delete',
                text: this.props.intl.formatMessage({ id: "SuppressionList.Delete" }),
                formatter: (cell, row) => {
                    return (
                        <i className="fas fa-trash-alt color-red pl-4 pointer" onClick={(e) => this.deleteItem(e, row.id)} />
                    )
                },
                hidden: !CheckAuthorization("suppressionList:delete", null)
            }
        ];

        return (
            <StyledCard block={block} error={error}>
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/MarketingConfigEgoi"> Config</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Suppression List</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Form onSubmit={this.doSearch}>
                    <Row className="mb-3 align-items-center">
                        <Col>
                            <CustomSelect
                                options={typeOptions}
                                onChange={this.handleSelect.bind(this, 'type')}
                                isClearable isSearchable
                                placeholder={<FormattedMessage id="SuppressionList.Type" />}
                            />
                        </Col>
                        <Col>
                            <CustomSelect
                                options={methodOptions}
                                name='method'
                                onChange={this.handleSelect.bind(this, 'method')}
                                isClearable isSearchable
                                placeholder={<FormattedMessage id="SuppressionList.Method" />}
                            />
                        </Col>
                        <Col>
                            <CustomSelect
                                options={campaigns}
                                name='campaignId'
                                onChange={this.handleSelect.bind(this, 'campaignId')}
                                isClearable isSearchable
                                placeholder={<FormattedMessage id="SuppressionList.Campaign" />}
                            />
                        </Col>
                        <Col>
                            <SingleDatePicker
                                id="createdMin"
                                isOutsideRange={day => false}
                                date={createdMin ? moment(createdMin) : null}
                                focused={this.state.startDateFocused}
                                onFocusChange={({ focused }) => this.setState({ startDateFocused: focused })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                onDateChange={(date) => this.setState({ createdMin: date })}
                                placeholder={this.props.intl.formatMessage({ id: "SuppressionList.CreatedMin" })}
                            />
                        </Col>
                        <Col>
                            <SingleDatePicker
                                id="createdMax"
                                isOutsideRange={day => false}
                                date={createdMax ? moment(createdMax) : null}
                                focused={this.state.endDateFocused}
                                onFocusChange={({ focused }) => this.setState({ endDateFocused: focused })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                onDateChange={(date) => this.setState({ createdMax: date })}
                                placeholder={this.props.intl.formatMessage({ id: "SuppressionList.CreatedMax" })}
                            />
                        </Col>
                        <Col>
                            <Input
                                type="text"
                                name="value"
                                onChange={(e) => this.handleChange(e)}
                                placeholder={this.props.intl.formatMessage({ id: "SuppressionList.Value" })}
                            />
                        </Col>
                        <Col className="text-right" sm={1}>
                            <Authorization
                                perform="suppressionList:add"
                                yes={() => (
                                    <Button className="btn-sm btn-host mr-2" onClick={this.toggleModal}>
                                        <i className="fas fa-plus" />
                                    </Button>
                                )}
                                no={() => <div></div>}
                            />

                            <Button className="btn-sm btn-host" type="submit">
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <CustomTable
                    data={suppressionList || []}
                    columns={columns}
                    page={this.state.pageIndex + 1}
                    sizePerPage={this.state.limit}
                    totalSize={this.state.totalItems}
                    onTableChange={this.handleTableChange}
                    hideSizePerPage={false}
                    shadow={false}
                    showTotal={true}
                    remote={true}
                />


                {this.state.modal ?
                    <Modal isOpen={this.state.modal} fade={false} className="modal-md" style={{ minWidth: '35%' }}>
                        <Button onClick={this.toggleModal} className="closeModal">
                            <i className="fas fa-times fa-sm"></i>
                        </Button>
                        <ModalBody className="pb-0 ">
                            <BlankCard block={this.state.blockModal} error={this.state.errorModal}>
                                <Form onSubmit={this.addItem}>
                                    <Row>
                                        <Col>
                                            <h5 className="mb-4"><FormattedMessage id="SuppressionList.AddItem" /></h5>
                                        </Col>
                                        <Col className="text-right col-3">
                                            <Button className="btn-sm btn-host" type="submit" disabled={!newItem || (newItem.supressionList && newItem.supressionList.length === 0)}>
                                                <i className="fas fa-save" />
                                            </Button>
                                        </Col>
                                    </Row>

                                    <FormGroup row>
                                        <Label sm={3}> <FormattedMessage id="SuppressionList.Type" /></Label>
                                        <Col sm={9} >
                                            <CustomSelect
                                                options={typeOptions}
                                                onChange={this.handleNewItemType.bind(this)}
                                                isClearable isSearchable
                                                placeholder={<FormattedMessage id="SuppressionList.Type" />}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className="align-items-center">
                                        <Label sm={3}> <FormattedMessage id="SuppressionList.Values" /></Label>
                                        <Col className="col-7 pr-0" >
                                            <Input
                                                type="text"
                                                name="value"
                                                onChange={(e) => { if (e.target) this.setState({ newValue: e.target.value }) }}
                                                value={this.state.newValue || ''}
                                            />
                                        </Col>
                                        <Col className="text-right" sm={2}>
                                            <Button className="btn-sm btn-host" onClick={() => this.handleNewItemValues(this.state.newValue, true)} disabled={!this.state.newValue}>
                                                <i className="fas fa-plus" />
                                            </Button>
                                        </Col>
                                    </FormGroup>

                                    <Row>
                                        <Col className="col-3" />
                                        <Col className="col-9">
                                            <ul className="pl-3">
                                                {newItem && newItem.supressionList.map((value, key) =>
                                                    <li key={key}>
                                                        <Row className="overbglight">
                                                            <Col> {value} </Col>
                                                            <Col className="col-2 text-right mr-3 color-red pointer" onClick={() => this.handleNewItemValues(value, false, key)}>
                                                                <i className="fas fa-times" />
                                                            </Col>
                                                        </Row>
                                                    </li>
                                                )}
                                            </ul>
                                        </Col>
                                    </Row>
                                </Form>
                            </BlankCard>
                        </ModalBody>
                    </Modal>
                    : ''}
            </StyledCard>
        );
    }
}

export default injectIntl(SuppressionList);