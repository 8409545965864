import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import CustomSelect from '../../Base/CustomSelect';

export default class SelectNewNodeMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedNodeType: null,
        }
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo && combo.value
        });
    };
    render() {
        const { selectedNodeTypeMenu, nodeTypes, onNodeTypeSelect } = this.props;
        return (
            <div className="context-menu d-flex flex-column align-items-center justify-content-center" style={{ position: 'absolute', zIndex: '10', top: selectedNodeTypeMenu.y, left: selectedNodeTypeMenu.x, background: '#fff', padding: '15px', width: '20%' }}>
                <p className='d-flex justify-content-center' style={{ margin: '0.5em', borderBottom: '1px solid rgb(204, 204, 204)', width: '100%', paddingBottom: '9px' }}>
                    <FormattedMessage id="CustomerJourney.SelectNewNodeElement" />
                </p>
                <div className="mx-3 shadow w-100" style={{ margin: '0.5em' }}>
                    <CustomSelect
                        isSearchable
                        placeholder={"Select Node Type"}
                        options={nodeTypes}
                        value={selectedNodeTypeMenu}
                        onChange={(type) => onNodeTypeSelect(type ? type.value : 'default')}
                    />
                </div>
            </div>
        )
    }
}
