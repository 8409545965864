import React, { Component } from 'react';
import { Button, Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import CreatePrompt from './CreatePrompt';
import ChatGPT from '../../../img/chatgpt.png';

class PromptConfig extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            typeList : {},
            createModal: false,
            deleteModal: false,
            deleteItem: null,
            types: [ "Review", "MetaAnalysis" ],
            editableId: null,
            editableType: null
        };
    }

    componentWillUpdate(newProps){
        this.updateTypeList(newProps);
    }

    componentDidMount(){
        this.updateTypeList();
    }

    updateTypeList = (newProps) => {
        if(!newProps || (newProps && newProps.promptConfigList !== this.props.promptConfigList)){
            this.aggregateByType(newProps?.promptConfigList||this.props.promptConfigList);
        }
    }

    aggregateByType = (promptList) => {
        let { types } = this.state;
        const typeList = [];
        
        if (!promptList || promptList.length === 0) {
            return typeList;
        }

        types = types.sort((a, b) => promptList.filter((el) => el.type === a).length > promptList.filter((el) => el.type === b).length ? -1 : 1);

        types.forEach(type => {
            typeList[type] = promptList.filter((el) => el.type === type);
        });

        this.setState({ types, typeList });
    }

    toggleCreatePromptModal = (id, type) => {
        this.setState({ editableId: id, editableType: type, createModal: !this.state.createModal });
    }

    toggleCreateModal = (editableType, cb) => {
        if(cb)
            this.setState({ createModal: !this.state.createModal, editableType, editableId: null }, () => this.props.getPromptConfigList());
        else
            this.setState({ createModal: !this.state.createModal, editableType, editableId: null });
    }

    render() {
        const { block, error, typeList, createModal, deleteModal, types, editableId, editableType, deleteItem } = this.state;
        const { intl } = this.props;

        return (
            <>
                {createModal ?
                    <CreatePrompt
                        updatePromptConfigList={this.props.updatePromptConfigList}
                        types={types}
                        type={editableType}
                        newPrompt={editableId && editableType && typeList[editableType].find((el) => el.id === editableId)}
                        toggleCreateModal={this.toggleCreateModal}
                    />
                :''}
                <Row>
                    <Col className='d-flex align-items-center'>
                        <img alt="Prompt Config" className='mr-2' width='18' height='18' src={ChatGPT}/>
                        <h5 className='mb-0'>
                            <FormattedMessage id="PromptConfig.PromptConfig"/>
                        </h5>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    {types.map((type, key) => {
                        const typeArr = typeList[type];
                        
                        return(
                            <Col key={key} className="my-3 col-6">
                                <Card className="border-0 shadow p-3">
                                    <Row>
                                        <Col className='d-flex align-items-center'>
                                            <h5 className='mb-0'>
                                                <FormattedMessage id={`AIConfig.${type}`}/>
                                            </h5>
                                        </Col>
                                        <Col className="text-right">
                                            <Button className="btn btn-host btn-sm" onClick={_ => this.toggleCreateModal(type)}>
                                                <i className="fas fa-plus"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                    {typeArr && typeArr.length > 0 ?
                                        <>
                                            <hr className='mx-2 my-3'/>
                                            <Row className='px-2'>
                                                <Col>
                                                    {typeArr.sort((a, b) => a.isMain > b.isMain ? -1 : 1).map((item, key) =>
                                                        <div key={key} className='mb-2'>
                                                            <Row>
                                                                <Col className='d-flex align-items-center'>
                                                                    <div className='mr-2' style={{ color: '#0665ff' }}>
                                                                        <div>
                                                                            {item.isMain ?
                                                                                <i className="fas fa-star"/>
                                                                            :
                                                                                <i className="far fa-star"/>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <b>{item.name}</b>
                                                                    </div>
                                                                </Col>
                                                                {item.hotelGroupId !== "00000000-0000-0000-0000-000000000000" ?
                                                                    <Col className="text-right">
                                                                        <UncontrolledDropdown>
                                                                            <DropdownToggle style={{ background: 'none', border: 'none', color: 'unset', padding: 'unset', width: '10px' }}>
                                                                                <i className="fas fa-ellipsis-v"/>
                                                                            </DropdownToggle>
                                                                            <DropdownMenu>
                                                                                <DropdownItem onClick={_ => this.toggleCreatePromptModal(item.id, type)}>
                                                                                    <i className="fas fa-edit mr-2"></i>
                                                                                    <FormattedMessage id="PromptConfig.Edit"/>
                                                                                </DropdownItem>
                                                                                <DropdownItem onClick={_ => this.props.deletePromptConfig(item.id)}>
                                                                                    <div style={{ color: '#A80C19' }}>
                                                                                        <i className="mr-2 far fa-trash-alt"/>
                                                                                        <FormattedMessage id="stripo.delete"/>
                                                                                    </div>
                                                                                </DropdownItem>
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    </Col>
                                                                :''}
                                                            </Row>
                                                            <Row className='mt-2 text-muted'>
                                                                <Col style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                                    {item.description}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                        </>
                                    :''}
                                </Card>
                            </Col>
                        )
                    }
                    )}
                </Row>
            </>
        )
    }
}

export default injectIntl(PromptConfig)