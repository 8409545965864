import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { injectIntl } from 'react-intl';
import { Button, Card, Col, Row, Breadcrumb, BreadcrumbItem, Badge } from 'reactstrap';
import { getEgoiSenderStatus } from "../Base/ReferenceDataFunctions";
import { StyledCard } from "../Base/CommonUIComponents";
import { getAPI, deleteAPI } from "../Base/API";
import { handleNotification } from "../Base/Notification";
import { FormattedMessage } from "react-intl";
import SenderModal from "./SenderModal";

import Authorization, { CheckAuthorization } from '../Base/Authorization';

export class SendersList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            cellphoneList: [],
            emailList: [],
            phoneList: [],
            selectedSender: null,
            limit: 10,
            cellphoneOffset: 0,
            cellphoneTotalItems: 0,
            emailOffset: 0,
            emailTotalItems: 0,
            //phoneOffset: 0,
            //phoneTotalItems: 0
        };
    }

    componentDidMount() {
        this.getEmailSendersList();
        //this.getPhoneSendersList();
        this.getCellphoneSendersList();
    }

    getEmailSendersList = () => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                this.setState({
                    emailList: data.items ? data.items : [],
                    emailTotalItems: data.total_items ? data.total_items : 0
                });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/senders/email?offset=${this.state.emailOffset}&limit=${this.state.limit}`);
    }

    //getPhoneSendersList = () => {
    //    this.setState({ block: true });
    //    getAPI(result => {
    //        const { data, error } = result;
    //        const errorMessage = [];

    //        if (error) {
    //            errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
    //            this.setState({ error: errorMessage, block: false });
    //            return;
    //        }
    //        if (data) {
    //            this.setState({
    //                phoneList: data.items ? data.items : [],
    //                phoneTotalItems: data.total_items ? data.total_items : 0
    //            });
    //        }
    //        this.setState({ error: errorMessage, block: false });
    //    }, `/api/gms/Marketing/v1/egoi/senders/phone?offset=${this.state.phoneOffset}&limit=${this.state.limit}`);
    //}

    getCellphoneSendersList = () => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                this.setState({
                    cellphoneList: data.items ? data.items : [],
                    cellphoneTotalItems: data.total_items ? data.total_items : 0 });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/senders/cellphone?offset=${this.state.cellphoneOffset}&limit=${this.state.limit}`);
    }

    removeSender = (e, senderId, type) => {
        e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.detail) {
                    handleNotification({ errors: [{ code: data.title, message: data.detail }] });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
            }
            else {
                handleNotification(data, <FormattedMessage id="SendersList.SenderRemoved" />, <FormattedMessage id="generic.success" />);

                if (type === 'email') { this.setState({ emailOffset: 0 }, () => this.getEmailSendersList()); }
                else if (type === 'cellphone') { this.setState({ cellphoneOffset: 0 }, () => this.getCellphoneSendersList());}
                else { this.setState({ phoneOffset: 0 }, () => this.getPhoneSendersList()); }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/senders/${type}/${senderId}`);
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            offset: this.state.offset + value
        }, () => this.getSendersList());
    }

    toggleModal = (data, type) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedSender: data ? data : null,
            selectedSenderType: type ? type : null
        }));
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            selectedSender: {
                ...this.state.selectedSender,
                [name]: value
            }
        });
    }

    getSenderStatus = (status) => {
        const senderStatus = getEgoiSenderStatus().find(s => s.value === status);

        return senderStatus ? senderStatus : {label: status};
    }

    render() {
        const { block, error, emailList, phoneList, cellphoneList, emailTotalItems, phoneTotalItems, cellphoneTotalItems, emailOffset, phoneOffset, cellphoneOffset, limit, selectedSender } = this.state;

        const typeOptions = [
            {
                value: 'numeric',
                label: <FormattedMessage id="SendersList.Numeric" />
            },
            {
                value: 'alpha_numeric',
                label: <FormattedMessage id="SendersList.AlphaNumeric" />
            }
        ]

        return (
            <StyledCard block={block} error={error} >
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/MarketingConfigEgoi"> Config</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Senders</BreadcrumbItem>
                    </Breadcrumb>
                </div>

                <Card className="border-0 p-3 mb-4 shadow">
                    <Row>
                        <Col><h4><FormattedMessage id="SendersList.EmailSenders" /></h4></Col>

                        <Authorization
                            perform="sendersList:add"
                            yes={() => (
                                <Col className="text-right">
                                    <Button className="btn btn-sm btn-host" onClick={() => this.toggleModal(null, 'email')}>
                                        <i className="fa fa-plus"></i>
                                    </Button>
                                </Col>
                            )}
                            no={() => <div></div>}
                        />
                    </Row>
                    <Row>
                        <Col><b><FormattedMessage id="SendersList.Name" /> </b></Col>
                        <Col><b><FormattedMessage id="SendersList.Mail" /> </b></Col>
                        <Col><b><FormattedMessage id="SendersList.Status" /> </b></Col>
                        <Authorization
                            perform="sendersList:delete"
                            yes={() => (
                                <Col className="col-1"></Col>
                            )}
                            no={() => <div></div>}
                        />
                    </Row>

                    {emailList && emailList.map((el, k) =>
                        <Row key={k} className="overbglight pt-2">
                            <Col className="pointer" onClick={() => { if (CheckAuthorization("sendersList:edit", null)) this.toggleModal(el, 'email') }}>
                                <Row>
                                    <Col>{el.name}</Col>
                                    <Col>{el.email}</Col>
                                    <Col> <Badge className="p-1" color={this.getSenderStatus(el.status).color}> {this.getSenderStatus(el.status).label} </Badge></Col>
                                </Row>
                            </Col>

                            <Authorization
                                perform="sendersList:delete"
                                yes={() => (
                                    <Col className="text-right col-1">
                                        <Button className="btn btn-sm btn-host" onClick={(e) => this.removeSender(e, el.sender_id, 'email')}>
                                            <i className="far fa-trash-alt"></i>
                                        </Button>
                                    </Col>
                                )}
                                no={() => <div></div>}
                            />
                        </Row>
                    )}

                    {emailTotalItems > 0 && emailTotalItems >= limit ?
                        <Row className="mt-3 px-3">
                            <Col>
                                <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.handlePagination(-limit)} disabled={emailOffset === 0}>
                                    <i className="fas fa-angle-left" />
                                </Button>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.handlePagination(limit)} disabled={emailTotalItems <= emailOffset + limit}>
                                    <i className="fas fa-angle-right" />
                                </Button>
                            </Col>
                        </Row>
                    : ''}
                </Card>


                <Card className="border-0 p-3 mb-4 shadow">
                    <Row>
                        <Col><h4><FormattedMessage id="SendersList.CellphoneSenders" /></h4></Col>
                        <Authorization
                            perform="sendersList:add"
                            yes={() => (
                                <Col className="text-right">
                                    <Button className="btn btn-sm btn-host" onClick={() => this.toggleModal(null, 'cellphone') }>
                                        <i className="fa fa-plus" />
                                    </Button>
                                </Col>
                            )}
                            no={() => <div></div>}
                        />
                    </Row>
                    <Row>
                        <Col><b><FormattedMessage id="SendersList.Type" /> </b></Col>
                        <Col><b><FormattedMessage id="SendersList.Number" /> </b></Col>
                        <Col><b><FormattedMessage id="SendersList.Status" /> </b></Col>
                        <Authorization
                            perform="sendersList:delete"
                            yes={() => (
                                <Col className="col-1"></Col>
                            )}
                            no={() => <div></div>}
                        />
                    </Row>

                    {cellphoneList && cellphoneList.map((el, k) =>
                        <Row key={k} className="overbglight pt-2">
                            <Col className="pointer" onClick={() => { if (CheckAuthorization("sendersList:edit", null)) this.toggleModal(el, 'cellphone') }}>
                                <Row>
                                    <Col>{typeOptions.find(t => t.value === el.type) ? typeOptions.find(t => t.value === el.type).label : el.type}</Col>
                                    <Col>{el.cellphone}</Col>
                                    <Col> <Badge className="p-1" color={this.getSenderStatus(el.status).color}> {this.getSenderStatus(el.status).label} </Badge></Col>
                                </Row>
                            </Col>

                            <Authorization
                                perform="sendersList:delete"
                                yes={() => (
                                    <Col className="text-right col-1">
                                        <Button className="btn btn-sm btn-host">
                                            <i className="far fa-trash-alt"></i>
                                        </Button>
                                    </Col>
                                )}
                                no={() => <div></div>}
                            />
                        </Row>
                    )}

                    {cellphoneTotalItems > 0 && cellphoneTotalItems >= limit ?
                        <Row className="mt-3 px-3">
                            <Col>
                                <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.handlePagination(-limit)} disabled={cellphoneOffset === 0}>
                                    <i className="fas fa-angle-left" />
                                </Button>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.handlePagination(limit)} disabled={cellphoneTotalItems <= cellphoneOffset + limit}>
                                    <i className="fas fa-angle-right" />
                                </Button>
                            </Col>
                        </Row>
                    : ''}
                </Card>


                {/*<Card className="border-0 p-3 shadow">
                    <Row>
                        <Col><h4><FormattedMessage id="SendersList.PhoneSenders" /></h4></Col>
                        <Col className="text-right">
                            <Button className="btn btn-sm btn-host" onClick={() => this.toggleModal(null, 'phone')}>
                                <i className="fa fa-plus" />
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col><b><FormattedMessage id="SendersList.Number" /> </b></Col>
                        <Col><b><FormattedMessage id="SendersList.Status" /> </b></Col>
                        <Col className="col-1"></Col>
                    </Row>

                    {phoneList && phoneList.map((el, k) =>
                        <Row key={k} className="overbglight pt-2">
                            <Col className="pointer" onClick={() => this.toggleModal(el, 'phone')}>
                                <Row>
                                    <Col>{el.cellphone}</Col>
                                    <Col></Col>
                                    <Col> <Badge className="p-1" color={this.getSenderStatus(el.status).color}> {this.getSenderStatus(el.status).label} </Badge></Col>
                                </Row>
                            </Col>

                            <Col className="text-right col-1">
                                <Button className="btn btn-sm btn-host">
                                    <i className="far fa-trash-alt"></i>
                                </Button>
                            </Col>
                        </Row>
                    )}

                    {phoneTotalItems > 0 && phoneTotalItems >= limit ?
                        <Row className="mt-3 px-3">
                            <Col>
                                <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.handlePagination(-limit)} disabled={phoneOffset === 0}>
                                    <i className="fas fa-angle-left" />
                                </Button>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.handlePagination(limit)} disabled={phoneTotalItems <= phoneOffset + limit}>
                                    <i className="fas fa-angle-right" />
                                </Button>
                            </Col>
                        </Row>
                    : ''}
                </Card>*/}

                {this.state.modal ?
                    <SenderModal
                        modal={this.state.modal}
                        toggleModal={this.toggleModal}
                        selectedSender={selectedSender}
                        selectedSenderType={this.state.selectedSenderType}
                        getEmailSendersList={this.getEmailSendersList}
                        getCellphoneSendersList={this.getCellphoneSendersList}
                        getPhoneSendersList={this.getPhoneSendersList}
                        typeOptions={typeOptions}
                    />
                : ''}
            </StyledCard>
        );
    }
}
export default injectIntl(SendersList)
