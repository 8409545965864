import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Badge, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CustomTable } from '../../Base/CustomTable';
import { Pie, Line, Bar } from 'react-chartjs-2';
import Chart from "react-google-charts";
//import Datamap from 'datamaps';
import moment from 'moment';

class Results extends Component {
    ProfileReservations = false;
    constructor(props) {
        super(props);
        
        this.state = {
            block: false,
            columns: [],
            tableHeader:[],
            tableData: [],
            //colorPalette: ["#0099ff", "#00d6ff", "#00b1db", "#028db6", "#026c91", "#004c6d", "#196666", "#008081", "#009999", "#02c5c5", "#66d9dc", "#79a3f9", "#6699ff", "#6666ff"],
            colorPalette: ["#00c4b3", "#51d6cb", "#51d6cb", "#f5e74e", "#e3d434", "#22243c", "#196666", "#008081", "#009999", "#02c5c5", "#66d9dc", "#79a3f9", "#6699ff", "#6666ff"],
            pieData: { "datasets": [{ "data": [], "backgroundColor": ['#0099ff', '#00d6ff', '#00b1db', '#028db6', "#026c91", "#004c6d", "#196666"] }], "labels": [] },
            lineData: { "datasets": [], "labels": [] },
            barChart: { "datasets": [], "labels": [] },
            treeMap: [],
            chartWrapper: null,
            region: null
        }

        this.element = React.createRef();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.setState(nextProps, () => this.buildArray());
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    getHeader(el,arr) {
        arr.push(el.group);
        if (el.subdimensions && el.subdimensions.length > 0) {
            arr = this.getHeader(el.subdimensions[0],arr)
        } 

        if (el.metrics && el.metrics.length > 0) {
            el.metrics.forEach((l, k) => {
                arr.push(l.name);
            });
        }
        

        
        return arr;
    }

    getValues(el, arr, auxarr, depth) {

        if (Array.isArray(el)) {
            el && el.forEach((e, i) => {

                if (e != undefined && e.metrics != undefined && e.metrics.length > 0) {
                    if (i > 0) auxarr.pop();
                    e && auxarr.push(e.name);
                    
                        
                    arr = arr.concat(auxarr); 
                    e.metrics.forEach((l, k) => {
                       arr.push(l.total);
                    });
                }
                else {
                    var haspop = false;
                    if ( auxarr.length > depth ) {

                        var size = auxarr.length;
                        for (var x = size; x > depth; x--) {
                            haspop = true;
                            auxarr.pop();
                        }
                    }  
                    
                    if (i > 0 && !haspop) auxarr.pop();
                    e && auxarr.push(e.name);
                    arr = this.getValues(e.subdimensions, arr, auxarr, depth+1);
                }

            });

        } else {
            el && auxarr.push(el.name);

        }

        

        if (el != undefined && el.subdimensions && el.subdimensions.length > 0) {
            arr = this.getValues(el.subdimensions, arr, auxarr, depth + 1)
        }

        if (el != undefined && el.metrics && el.metrics.length > 0) {
            arr = arr.concat(auxarr);
            el.metrics.forEach((l, k) => {
                arr.push(l.total);
                

            });
        }
        

        return arr;
    }

    buildArray = () => {

        let header = [];
        let values = [];
        let tableCol = [];
        if (this.props.results && this.props.results.length > 0) {
            header = this.getHeader(this.props.results[0], header);
        }

        header.forEach((el) =>{
            tableCol.push({
                dataField: el,
                text: el,
                sort: true
            })
        })


        this.props.results && this.props.results.forEach((g, dimensionIndex) => {
            let aux = this.getValues(g, [], [],0);
            var i, j;
            for (i = 0, j = aux.length; i < j; i += header.length) {
                values.push(aux.slice(i, i + header.length));
            }
        });


        if (this.props.dimensionOrderAsc === true) {
            values = values.sort();
        } else if (this.props.dimensionOrderAsc === false) {
            values = values.sort().reverse();
        } else if (this.props.metricOrderAsc === true) {
            values = values.sort((a, b) => a[a.length - this.props.metrics.length] - b[b.length - this.props.metrics.length]);
        } else if (this.props.metricOrderAsc === false){
            values = values.sort((a, b) => b[b.length - this.props.metrics.length] - a[a.length - this.props.metrics.length]);
        }

        this.setState({ results: values, columns: header, tableHeader: tableCol }, () => {
            if (header.length === this.state.dimensionsCount + this.state.metrics.length) {
                if (this.props.selectedGraph === 'Pie_Chart') {
                    this.pieChartData();
                    return;
                }
                if (this.props.selectedGraph === 'Line_Chart') {
                    this.lineChartData();
                    return;
                }
                if (this.props.selectedGraph === 'Bar_Chart') {
                    this.barChartData();
                    return;
                }
                if (this.props.selectedGraph === 'World_Map') {
                    this.renderDataMaps('world')
                    return;
                }
                if (this.props.selectedGraph === 'Three_Map') {
                    this.treeMapData();
                    return;
                }
                if (this.props.selectedGraph === 'Table') {
                    this.tableData();
                    return;
                }
            }
        });

    }

    tableData = () => {

        let arr = [];
        this.state.results && this.state.results.forEach((e, c) => {

            let aux = {};
            this.state.columns && this.state.columns.map((el, k) => {
                aux[el] = e[k];
            });

            arr.push(aux);

        });
        this.setState({ tableData: arr });

    }

    pieChartData = () => {
        const { pieData } = this.state;
        let data = new Map()

        this.state.results && this.state.results.forEach(group => {

            var gName = "";
            for (var x = 0; x < group.length - 1; x++) {
                if (gName != "")
                    gName += "-";
                gName += group[x];
            }

            data.set(gName, group[group.length-1]);

        })


        pieData.labels = [...data.keys()];

        var values = [];
        pieData.labels.forEach(item => { values.push(data.get(item)); });
        pieData.datasets[0].data = values;

        this.setState({ pieData });
    }

    lineChartData = () => {
        let line = { datasets: [], labels: [] };

        let data = new Map()

        this.state.results && this.state.results.forEach(group => {

            var gName = "";
            for (var x = 0; x < group.length - (this.props.metrics.length); x++) {
                if (gName != "")
                    gName += "-";
                gName += group[x];
            }

            data.set(gName, group.slice(group.length - this.props.metrics.length, group.length));

        })
        
        line.labels = [...data.keys()];


        this.props.metrics && this.props.metrics.forEach((e, c) => {
            line.datasets.push({ label: e, data: Array(line.labels.length).fill(0), borderColor: this.state.colorPalette[c], backgroundColor: this.state.colorPalette[c], fill: false });
            line.labels.forEach((item, i) => {
                line.datasets[c].data[i] = data.get(item)[c];

            });
        }
        );

        this.setState({ lineData: line });
    }

    treeMapData = () => {
        var treeMap = [["Location", "Parent", "Market trade volume (size)", "Market increase/decrease (color)"],
        ["Global", null, 0, 0]];

        //this.props.metrics && this.props.metrics.forEach((e, c) => {
        //    treeMap.push([e, "Global", 0, 0]);
        //});

        let data = new Map()
        this.state.results && this.state.results.forEach(group => {
            var gName = "";
            for (var x = 0; x < group.length - (this.props.metrics.length); x++) {
                if (gName != "")
                    gName += "-";
                gName += group[x];
            }

            data.set(gName, group.slice(group.length - this.props.metrics.length, group.length));
        });
        let k = [...data.keys()];

        
        k.forEach((item, i) => {
            treeMap.push([item, 'Global', 0, data.get(item).reduce((a, b) => a + b, 0)]);
            this.props.metrics && this.props.metrics.forEach((e, c) => {
                treeMap.push([item + ' ' + e, item, data.get(item)[c], data.get(item)[c]]);
            });
        });


        this.setState({ treeMap });
    }

    barChartData = () => {
        let bar = { datasets: [], labels: [] };
        
        let data = new Map()

        this.state.results && this.state.results.forEach(group => {

            var gName = "";
            for (var x = 0; x < group.length - (this.props.metrics.length); x++) {
                if (gName != "")
                    gName += "-";
                gName += group[x];
            }

            data.set(gName, group.slice(group.length - this.props.metrics.length, group.length));

        });
        

            let key = [...data.keys()];
            bar.labels=[...data.keys()];

            this.props.metrics && this.props.metrics.forEach((e, c) => {

                bar.datasets.push({ label: e, data: [], borderColor: this.state.colorPalette[c], backgroundColor: this.state.colorPalette[c], fill: false });

              
                    key.forEach((el, k) => {
                        bar.datasets[c].data.push(data.get(el)[c]);

               
                });
            }
            );


        this.setState({ barChart: bar });
    }
    
    renderDataMaps = (scope) => {
       
        var arr = [];
        let data = new Map()
        this.state.results && this.state.results.forEach(group => {

            var gName = "";
            for (var x = 0; x < group.length - (this.props.metrics.length); x++) {
                if (gName != "")
                    gName += "-";
                gName += group[x];
            }

            data.set(gName, group.slice(group.length - this.props.metrics.length, group.length));

        });

        let key = [...data.keys()];

        let header = ['Country'];
        this.props.metrics && this.props.metrics.forEach((e, c) => {
            header.push(e);
        })
        arr.push(header);

        
        key.forEach((el, k) => {
            let arraux = [];
            arraux.push(el);
            this.props.metrics && this.props.metrics.forEach((e, c) => {
                arraux.push(data.get(el)[c]);
            });
            arr.push(arraux);
        });



        this.setState({
            worldMap: arr
        });

    }


    downloadCharImg = (e) => {        
        const bt = document.getElementById('downloadbtn');

        switch (this.props.selectedGraph) {
            case 'Table':
                //const canv = document.getElementById('pieChart');
                //bt.href = canv.toDataURL("image/jpg");
                break;

            case 'Pie_Chart':
                bt.href = document.getElementById('pieChart').toDataURL("image/png");
                bt.download = 'Chart.png';
                break;

            case 'Line_Chart':
                bt.href = document.getElementById('lineChart').toDataURL("image/png");
                bt.download = 'Chart.png';
                break;

            case 'Bar_Chart':
                bt.href = document.getElementById('barChart').toDataURL("image/png");
                bt.download = 'Chart.png';
                break;

            case 'Three_Map':
            case 'World_Map':
                var svgString = new XMLSerializer().serializeToString(document.querySelector('[aria-label="A chart."]'));
                var DOMURL = window.self.URL || window.self.webkitURL || window.self;
                var svg = new Blob([svgString], { type: "image/svg+xml;" });
                var url = DOMURL.createObjectURL(svg);

                bt.href = url;
                bt.download = 'Chart.svg';
                break;
        }
    }

    render() {
        const { selectedGraph } = this.props;

        const optionsPie = {
            plugins: {
                datalabels: { display: false },
                legend: {
                    position: "top"
                }
            }
        }

        const optionsLine = {
            responsive: true,
            plugins: {
                datalabels: { display: false },
                legend: {
                    position: "top"
                }
            }
        }
        
        const optionsBar = {
            plugins: { datalabels: { display: false } },
            elements: {
                point: {
                    radius: 0
                }
            },
            scales: {
                y: {
                    display: true,
                    stacked: false,
                    beginAtZero: true
                },                
                x: {
                    stacked: false,
                },                
            },
        }

        const treeMapOptions = {

            minColor: "#e3d434",
            midColor: "#53fcee",
            maxColor: "#00c4b3",
            headerHeight: 15,
            fontColor: "black",
            showScale: true,
            generateTooltip: (row, size, value) => {
                return (
                    '<div style="background:#fd9; padding:10px; border-style:solid"> Total Amount: ' + parseFloat(size).toFixed(2) + '</div>'
                )
            },
        };


        var worldMapoptions = {
            region: this.state.region,
            colorAxis: { colors: ['#00c4b3', '#e3d434'] }
        };
        return (
           <>
                <Row className="mb-2">
                    <Col className="text-center">
                        <b> <FormattedMessage id="ProfileAnalysis.Results" /> </b>
                    </Col>
                </Row>
                <Card className="p-2 h-100 border-0 bg-white shadow">
                    <Row className="h-100">


                    {this.props.results ?
                        <Col>
                            <Row>
                                <Col className="text-right">
                                    {selectedGraph !== 'Table'  ?
                                    <a className=" btn btn-clever btn-sm text-white mr-2" id="downloadbtn" onClick={this.downloadCharImg} >
                                        <i className="fas fa-file-download "></i>
                                    </a>
                                        :''}
                                    <Button className=" btn btn-clever btn-sm" onClick={this.props.expand} >
                                        <i className={this.props.isExpanded ? "fas fa-compress-alt" : "fas fa-expand-arrows-alt" }></i>
                                    </Button>
                                </Col>
                            </Row>
                            {selectedGraph === 'Table' && this.state.columns && this.state.columns.length > 0 ?
                                <div className="my-1 tableResults">
                                    <CustomTable
                                        data={this.state.tableData ? this.state.tableData : []}
                                        columns={this.state.tableHeader}
                                        showTotal={true}
                                        totalSize={this.state.results.length}
                                        sizePerPage={25}
                                        exportCSV={true}
                                        search={true}
                                    />   
                                </div>
                                : selectedGraph === 'Pie_Chart' ?
                                <Row>
                                    <Col className="col-11">
                                        <Pie id="pieChart" data={this.state.pieData} height={'90px'} options={optionsPie} />
                                    </Col>
                                </Row>
                                    : selectedGraph === 'Line_Chart' ?
                                    <Row>
                                        <Col className="col-11">
                                                <Line id="lineChart" data={this.state.lineData} height={90} options={optionsLine} />
                                        </Col>
                                    </Row>
                                        : selectedGraph === 'Bar_Chart' ?
                                        <Row>
                                            <Col className="col-11">
                                                    <Bar id="barChart" data={this.state.barChart} height={'90px'} options={optionsBar} />
                                            </Col>
                                        </Row>
                                            : selectedGraph === 'World_Map' ?
                                            <Row>
                                                <Col className="col-11">
                                                    <Chart id="mapChart"
                                                        chartType="GeoChart"
                                                        width="100%"
                                                        data={this.state.worldMap}
                                                        options={worldMapoptions}
                                                    />
                                                </Col>
                                                <Col>
                                                    <div className="text-center"><b>Zoom</b></div>
                                                    <div className="my-1">
                                                        <Button className="btn-sm btn-clever w-100" onClick={() => this.setState({ region: '150' })}> Europa</Button>
                                                    </div>
                                                    <div className="my-1">
                                                            <Button className="btn-sm btn-clever w-100" onClick={() => this.setState({ region: '019' })}> Americas</Button>
                                                    </div>
                                                    <div className="my-1">
                                                            <Button className="btn-sm btn-clever w-100" onClick={() => this.setState({ region: '142' })}> Asia</Button>
                                                    </div>
                                                    <div className="my-1">
                                                            <Button className="btn-sm btn-clever w-100" onClick={() => this.setState({ region: '002' })}> Africa</Button>
                                                    </div>
                                                    <div className="my-1">
                                                            <Button className="btn-sm btn-clever w-100" onClick={() => this.setState({ region: '009' })}> Oceania</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                                : selectedGraph === 'Three_Map' ?
                                            <Row>
                                                <Col className="col-11" id="treeChart">
                                                    <Chart 
                                                        chartType="TreeMap"
                                                        width="100%"
                                                        height="400px"
                                                        data={this.state.treeMap}
                                                        options={treeMapOptions}
                                                        getChartWrapper={chartWrapper => {
                                                            this.setState({ chartWrapper });
                                                        }}

                                                    />
                                                </Col>
                                            </Row>
                                            :''
                            }
                        </Col>
                        :
                        <Col className="text-center">
                            <div className="my-3"><b>No Results</b></div>
                        </Col>
                    }
                </Row>
                </Card>
            </>
        );
    }
}
export default injectIntl(Results)