import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Card } from 'reactstrap';
import { KebabMenu, StyledCard, CommonHelper } from "../../../../Base/CommonUIComponents";
import { handleNotification } from '../../../../Base/Notification';
import { getAPI } from '../../../../Base/API';
import { SettingStatus } from '../../SendGridFunctions';
import ForwardModal from './ForwardModal';
import BouncePurgeModal from './BouncePurgeModal';
import AddressModal from './AddressModal';
import Authorization from '../../../../Base/Authorization';


export class MailSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            mailSettings: [],
            settingsDefinitions: [
                { title: 'EventSettings', options: [{ name: "forward_bounce", modal: "forwardModal" }, { name: "forward_spam", modal: "forwardModal" }] },
                { title: 'SuppressionSettings', options: [{ name: "address_whitelist", modal: "addressModal" }, { name: "bounce_purge", modal: "bounceModal" }] },
            ]
        };
    }

    componentDidMount() {
        this.getMailSettings();
    }

    getMailSettings = (e) => {
        if (e) e.preventDefault();

        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ mailSettings: data.response?.result, block: false });
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SendGrid/v1/mailsettings`);
    }

    updateSettings = (modalName) => {
        this.setState({
            [modalName]: false,
            selectedSetting: null
        }, this.getMailSettings)
    }

    handleChangeCombo = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    toggleModal = (modal, selectedSetting) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            selectedSetting
        }))
    }

    render() {
        const { block, error, settingsDefinitions, mailSettings, selectedSetting } = this.state;
        const { page } = this.props;

        return (
            <StyledCard block={block} error={error} >
                <Row className="mb-4 py-1">
                    <Col>
                        <h5><i className={page?.icon} /> {page?.name}</h5>
                    </Col>
                    <Col>
                        <Authorization
                            perform="admin:view"
                            yes={() => (
                                <CommonHelper help={<FormattedMessage id="MailSettings.Help" />} id={'mailHelp'}  />
                            )}
                            no={() => <div></div>}
                        />
                    </Col>
                </Row>

                {mailSettings && mailSettings.length > 0 ?
                    <>
                        {settingsDefinitions.map((definition, key) =>
                            <div className="mb-5 pb-3" key={key}>
                                <h5 className="mb-3 pb-1"><FormattedMessage id={`MailSettings.${definition.title}`} /></h5>

                                <Row className="px-4 text-muted">
                                    <Col className='col-1 pl-3'>
                                        <FormattedMessage id="SendGrid.Status" />
                                    </Col>
                                    <Col className='pl-3 col-2'>
                                        <FormattedMessage id="SendGrid.Setting" />
                                    </Col>
                                    <Col className='pl-3'>
                                        <FormattedMessage id="SendGrid.Description" />
                                    </Col>
                                    <Col zm={1}/>
                                </Row>

                                {definition.options.map((option, idx) => {
                                    const setting = mailSettings.find(ms => ms.name === option.name);

                                    return setting && <Card className="mt-2 p-4 shadow" key={`${key}Option${idx}`}>
                                        <Row>
                                            <Col className="col-1 pr-0">
                                                <SettingStatus status={setting.enabled} />
                                            </Col>
                                            <Col sm={2}>{setting.title}</Col>
                                            <Col><FormattedMessage id={`MailSettings.${setting.title.replace(' ', '_')}Desc`} /></Col>
                                            <Col className="col-1 text-right">
                                                <KebabMenu
                                                    editFunction={() => this.toggleModal(option.modal, option.name)}
                                                />
                                            </Col>
                                        </Row>
                                    </Card>
                                })}
                            </div>
                        )}
                    </>
                : ''}



                {this.state.forwardModal &&
                    <ForwardModal
                        toggleModal={() => this.toggleModal("forwardModal")}
                        modal={this.state.forwardModal}
                        updateSettings={() => this.updateSettings("forwardModal")}
                        selectedSetting={selectedSetting}
                    />
                }

                {this.state.addressModal &&
                    <AddressModal
                        toggleModal={() => this.toggleModal("addressModal")}
                        modal={this.state.addressModal}
                        updateSettings={() => this.updateSettings("addressModal")}
                    />
                }

                {this.state.bounceModal &&
                    <BouncePurgeModal
                        toggleModal={() => this.toggleModal("bounceModal")}
                        modal={this.state.bounceModal}
                        updateSettings={() => this.updateSettings("bounceModal")}
                    />
                }
            </StyledCard>
        );
    }
}

export default injectIntl(MailSettings)