import moment from 'moment-timezone'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import { Card, Col, Row } from 'reactstrap'
import { FormAnswers } from '../../Marketing/Common/MarketingFunctions';
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import BlockUi from 'react-block-ui';
import ChatGPT from '../../../img/chatgpt.png';

export default class SelectedProfileForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            formTags: [],
            formName: null
        }
    }

    componentDidMount(){
        this.getFormQuestions();
    }

    getFormQuestions = () => {
        const { selectedForm } = this.props;

        if(!selectedForm?.externalFormId) {
            this.setState({ block: false });
            return;
        }

        let link = `/api/gms/Survey/v1/survey/${selectedForm.externalFormId}`;
        if(selectedForm.formSource === "TypeForm") link = `/api/gms/TypeForm/Forms/${selectedForm.externalFormId}`;
        if(selectedForm.formSource === "ReviewPro") link = `/api/gms/ReviewPro/v1/Surveys/${selectedForm.externalFormId}`;

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    return;
                }
                
                this.setState({ formName: data.response[0].name || data.response[0].title }, () => this.getFormTags());
            }
            else this.setState({ block: false });
        }, link);
    }

    getFormTags = () => {
        const { selectedForm } = this.props;

        if(!selectedForm.id) return;

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    return;
                }
                
                this.setState({ formTags: data.response, block: false });
            }
            else this.setState({ block: false });
        }, `/api/gms/Profile/v1/profile/formTags/${selectedForm.id}`)
    }

    focusQuestion = (questionId) => {
        const question = document.getElementById('question' + questionId);
        if(question){
            question.focus();
        }
    }

    render() {
        const { selectedForm } = this.props;
        const { formTags, block, formName } = this.state;
        if (!selectedForm) {
            return null;
        }
        
        return (
            <BlockUi blocking={block}>
                <Row className='mt-2' id="FormDetails">
                    <Col>
                        <FormAnswers answers={selectedForm.answers} isPNForm={true}>
                            <Row>
                                <Col>
                                    <h5 className="m-0 text-host">
                                        {formName}
                                    </h5>
                                    <div className='text-muted'>
                                        {selectedForm.formSource}
                                    </div>
                                </Col>
                                <Col className="text-right">
                                    {selectedForm.responseDate ? moment(selectedForm.responseDate).format("YYYY-MM-DD") : ''}
                                </Col>
                            </Row>
                            {formTags && formTags.length > 0 ?
                                <>
                                    <hr/>
                                    <Row>
                                        <Col>
                                            <div>
                                                <h5><FormattedMessage id="ProfileDetails.MetaTags"/></h5>
                                            </div>
                                        </Col>
                                        <Col className='col-2 text-right'>
                                            <span className='mr-2'>
                                                <img src={ChatGPT} alt="GPT Generation" width='18' height='18'/>
                                            </span>
                                            <FormattedMessage id="ProfileDetails.PoweredByAI"/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {formTags?.map((tag, idx) => 
                                                <div key={idx} className='mr-2 mt-1 cursor-pointer' onClick={_ => this.focusQuestion(tag.questionId)}>
                                                    <div>
                                                        {tag.sentiment === "Positive" ?
                                                            <i className="far fa-smile text-success"/>
                                                        :tag.sentiment === "Negative" ?
                                                            <i className="far fa-frown text-danger"/>
                                                        :
                                                            <i className="far fa-meh-blank"/>
                                                        }
                                                        <span className='ml-1'>
                                                            <b>{tag.formTagName}</b>
                                                        </span>
                                                    </div>
                                                    <div className='ml-1'>
                                                        <i style={{ visibility: 'hidden' }} className="far fa-smile text-success"/>
                                                        {tag.explanation.charAt(0).toUpperCase() + tag.explanation.slice(1)}
                                                    </div>
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                    {/* <div>
                                        {formTags?.filter((tag) => tag.sentiment === "Positive").map((tag, idx) => 
                                            <div key={idx}>
                                                {idx > 0 ?
                                                    <span>
                                                        , 
                                                    </span>
                                                :''}
                                                <span className='ml-1'>
                                                    {tag.formTagName}
                                                </span>
                                            </div>
                                        )}
                                    </div> */}
                                </>
                            :''}
                            <>
                            {/* {form.formTags && form.formTags.length > 0 ?
                                <Row className='mt-1 cursor-pointer' style={{ fontSize: '0.8em' }}>
                                    <Col>
                                        <div className='d-flex'>
                                            <div className='mr-2'>
                                                <FormattedMessage id="ProfileDetails.Positive"/>:
                                            </div>
                                            <div className='d-flex'>
                                                {form.formTags?.filter((tag) => tag.sentiment === "Positive").map((tag, idx) => 
                                                    <div key={idx}>
                                                        {idx > 0 ?
                                                            <span>
                                                                , 
                                                            </span>
                                                        :''}
                                                        <span className='ml-1'>
                                                            {tag.formTagName}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='mt-1 d-flex'>
                                            <div className='mr-2 color-light-red'>
                                                <FormattedMessage id="ProfileDetails.Negative"/>:
                                            </div>
                                            <div className='d-flex'>
                                                {form.formTags?.filter((tag) => tag.sentiment === "Negative").map((tag, idx) => 
                                                    <div key={idx}>
                                                        {idx > 0 ?
                                                            <span>
                                                                , 
                                                            </span>
                                                        :''}
                                                        <span className='ml-1'>
                                                            {tag.formTagName}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            :''} */}
                            </>
                            <hr/>
                        </FormAnswers>
                    </Col>
                </Row>
            </BlockUi>
        )
    }

}
