import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, Row, UncontrolledDropdown } from 'reactstrap'
import { patchAPI } from '../Base/API';
import GenericTextArea from '../Base/GenericTextArea';
import BlockUi from 'react-block-ui';
import { handleNotification } from '../Base/Notification';
import CustomSelect from '../Base/CustomSelect';
import moment from 'moment-timezone';
import { SingleDatePicker } from 'react-dates';
import AsyncSelect from 'react-select/async';

export default class EditLocationModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            numberOfDays: 31,
            filteredCategoryOptions: this.props.categoryOptions?.slice(0, 10),
            editableFields: [
                {
                    type: 'AboutYourBusiness',
                    icon: <i className="mr-2 fas fa-store"/>,
                    fields: [
                        { label: 'Title', value: '', type: 'text' },
                        { label: 'Categories', value: '', type: 'category', additionalCategories: [] },
                        { label: 'Description', value: '', type: 'textarea' },
                        { label: 'OpeningDate', value: null, type: 'date' },
                    ]
                },
                {
                    type: 'ContactInformation',
                    icon: <i className="mr-2 fas fa-phone"/>,
                    fields: [
                        { label: 'Phone', value: '', type: 'phone', additionalPhones: [] },
                        { label: 'websiteUri', value: '', type: 'text' }
                    ]
                },
                {
                    type: 'LocationAndAreas',
                    icon: <i className="mr-2 fas fa-map-marked-alt"/>,
                    fields: [
                        { label: 'StreetAddress', value: '', type: 'text' },
                        { label: 'PostCode', value: '', type: 'text', noDesc: true },
                        { label: 'Town', value: '', type: 'text', noDesc: true }
                    ]
                },
                {
                    type: 'TimeTable',
                    icon: <i className="mr-2 fas fa-clock"/>,
                    fields: [
                        {
                            label: 'TimeOptions',
                            type: 'radio',
                            value: '',
                            options: [
                                'OPEN',
                                'CLOSED_TEMPORARILY',
                                'CLOSED_PERMANENTLY'
                            ]
                        },
                        {
                            label: 'WeekDays',
                            value: [
                                { day:'SUNDAY', openTime: [], closeTime: [], isOpen: false },
                                { day: 'MONDAY', openTime: [], closeTime: [], isOpen: false },
                                { day: 'TUESDAY', openTime: [], closeTime: [], isOpen: false },
                                { day:'WEDNESDAY', openTime: [], closeTime: [], isOpen: false },
                                { day:'THURSDAY', openTime: [], closeTime: [], isOpen: false },
                                { day:'FRIDAY', openTime: [], closeTime: [], isOpen: false },
                                { day:'SATURDAY', openTime: [], closeTime: [], isOpen: false },
                            ],
                            type: 'weekDays',
                            noDesc: true
                        },
                        { label: 'specialHours', value: null, type: 'specialHours' },
                    ]
                }
            ]
        };
    }

    componentDidMount() {
        this.recalculateValues();
    }

    submitChange = (catKey, fieldKey) => {
        const { location, selectedLocation, getLocationDetail } = this.props;
        const { editableFields } = this.state;
        
        const copy = {...location};
        const curr = editableFields[catKey].fields[fieldKey];
        if(!curr.value) return;

        if(curr.label === "Description") {
            if(!copy.profile) copy.profile = {};
            copy.profile.description = curr.value;
        }
        else if(curr.label === "OpeningDate") {
            if(!copy.profile) copy.profile = {};
            copy.openInfo.openingDate = curr.value;
        }
        else if(curr.label === "Phone") {
            if(!copy.phoneNumbers) {
                copy.phoneNumbers = {};
            }
            copy.phoneNumbers.primaryPhone = curr.value;
            if(curr.additionalPhones){
                copy.phoneNumbers.additionalPhones = curr.additionalPhones;
            }
        }
        else if(curr.label === "StreetAddress") copy.storefrontAddress.addressLines[0] = curr.value;
        else if(curr.label === "PostCode") copy.storefrontAddress.postalCode = curr.value;
        else if(curr.label === "Town") copy.storefrontAddress.locality = curr.value;
        else if(curr.label === "TimeOptions") {
            if(!copy.openInfo) copy.openInfo = {};
            copy.openInfo.status = curr.value;
        }
        else if(curr.label === "WeekDays") {
            if(!location.regularHours){
                location.regularHours = {};
            }
            location.regularHours.periods = curr.value;
        }
        else if(curr.label === "specialHours"){
            if(!location.specialHours){
                location.specialHours = {};
            }
            location.specialHours.specialHourPeriods = curr.value;
        }
        else if(curr.label === "Categories"){
            if(curr.value){
                if(!location.categories?.primaryCategory){
                    location.categories.primaryCategory = {}
                }
                location.categories.primaryCategory.name = curr.value;
            }
            if(curr.additionalCategories && curr.additionalCategories.length > 0){
                location.categories.additionalCategories = curr.additionalCategories
            }
        }
        else{
            copy[curr.label.toLowerCase()] = curr.value;
        }

        this.setState({ block: true }, () =>
            patchAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        errorMessage.push({ message: data.errors[0].message, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false }, handleNotification(data));
                    }
                    else {
                        const clearedEditableFields = editableFields.map((cat) => {
                            cat.fields = cat.fields.map((field) => {
                                field.editable = false;
                                return field;
                            });
                            return cat;
                        });

                        this.setState({ block: false, editableFields: clearedEditableFields }, () => getLocationDetail());
                    }
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/Google/v1/accounts/location/${selectedLocation}?updateMask=${curr.label.toLowerCase()}`, copy)
        );
    }

    getValue = (location, field) => {
        let value = location[field.label.toLowerCase()];
        if(field.label === "Description") value = location.profile?.description;
        if(field.label === "OpeningDate") value = location.openInfo?.openingDate;
        if(field.label === "Phone") {
            value = location.phoneNumbers?.primaryPhone;
            if(location.phoneNumbers?.additionalPhones){
                field.additionalPhones = location.phoneNumbers?.additionalPhones;
            }
        }
        if(field.label === "StreetAddress") value = location.storefrontAddress?.addressLines[0];
        if(field.label === "PostCode") value = location.storefrontAddress?.postalCode;
        if(field.label === "Town") value = location.storefrontAddress?.locality;
        if(field.label === "TimeOptions") value = location.openInfo?.status;
        if(field.label === "WeekDays") {
            if(location.regularHours?.periods){
                value = field.value;
                value.forEach((day) => {
                    const weekDay = location.regularHours?.periods.filter(({openDay}) => openDay === day.day);
                    if(weekDay) {
                        weekDay.forEach((weekD) => {
                            day.openTime.push(weekD.openTime)
                            day.closeTime.push(weekD.closeTime)
                            day.isOpen = true;
                        })
                    }
                })
            };
        }
        if(field.label === "specialHours"){
            if(location.specialHours?.specialHourPeriods){
                value = [];
                location.specialHours.specialHourPeriods.forEach((special) => {
                    value.push({...special, isOpen: special.closed})
                });
            }
        }
        if(field.label === "Categories"){
            if(location.categories.primaryCategory?.name){
                value = location.categories.primaryCategory.name;
            }
            if(location.categories?.additionalCategories && location.categories.additionalCategories.length > 0){
                location.categories.additionalCategories.forEach((cat) => {
                    if(cat.name){
                        field.additionalCategories.push(cat.name);
                    }
                });
            }
        }
        return value;
    }

    recalculateValues = (fieldLabel) => {
        const { location } = this.props;
        const { editableFields } = this.state;

        if(!fieldLabel){
            editableFields.map((cat) => {
                cat.fields.map((field) => {
                    const value = this.getValue(location, field);
                    if(value) field.value = value;
                    return field;
                });
                return cat;
            });
        }
        else{
            const currentField = editableFields.find(({fields}) => fields.find(({label}) => label === fieldLabel)).fields.find(({label}) => label === fieldLabel);
            const value = this.getValue(location, currentField);
            currentField.value = value;
        }

        this.setState({ editableFields });
    }
    
    toggleEditableField = (catKey, fieldKey) => {
        const { editableFields } = this.state;
        editableFields[catKey].fields[fieldKey].editable = !editableFields[catKey].fields[fieldKey].editable;
        this.setState({ editableFields });
    }

    handleChange = (value, catKey, fieldKey, isAddPhone) => {
        const { editableFields } = this.state;
        editableFields[catKey].fields[fieldKey].value = value;
        this.setState({ editableFields });
    }

    applyChanges = (catKey, fieldKey) => {
        const { editableFields } = this.state;
        editableFields[catKey].fields[fieldKey].editable = false;
        this.setState({ editableFields });
    }

    cancelEdit = (catKey, fieldKey) => {
        const { editableFields } = this.state;
        editableFields[catKey].fields[fieldKey].editable = false;
        this.setState({ editableFields }, () => this.recalculateValues(editableFields[catKey].fields[fieldKey].label));
    }

    getArray(size) {
        const array = [];
        for (let i = 0; i < size; i++) {
            array.push(i);
        }
        return array;
    }

    handleDateChange = (value, field) => {
        const { editableFields } = this.state;

        const fieldValue = editableFields[0].fields[3].value;

        fieldValue.value[field] = value;
        
        const selectedYear = fieldValue.value ? fieldValue.value.year : null;
        const selectedMonth = fieldValue.value ? fieldValue.value.month : null;
        const numberOfDays = selectedMonth && selectedYear ? moment(`${selectedYear}-${selectedMonth}`, "YYYY-MM").daysInMonth() : 31;
        
        if(fieldValue.days > numberOfDays){
            fieldValue.value.days = null;            
        };

        this.setState({ editableFields, numberOfDays });
    }

    addPhone = (key) => {
        const { editableFields } = this.state;

        if (key === 0) {
            editableFields[1].fields[0].additionalPhones = [];
        }
        else if (key) {
            editableFields[1].fields[0].additionalPhones = editableFields[1].fields[0].additionalPhones.splice(0, 1);
        }
        else {
            editableFields[1].fields[0].additionalPhones.push('');
        }

        this.setState({ editableFields });
    }

    handleCheck = (value) => {
        const { editableFields } = this.state;

        editableFields[3].fields[0].value = value;

        this.setState({ editableFields });
    }

    handleTime = (value, weekDayKey, timeKey, type) => {
        const { editableFields } = this.state;

        const fieldValue = editableFields[3].fields[1].value;

        if(!timeKey && timeKey !== 0){
            fieldValue[weekDayKey][type] = !fieldValue[weekDayKey][type];
        }
        else{
            const hours = value[0] + value[1];
            const minutes = value[3] + value[4];
            fieldValue[weekDayKey][type][timeKey].hours = hours;
            fieldValue[weekDayKey][type][timeKey].minutes = minutes;
        }

        this.setState({ editableFields });
    }

    addTime = (weekDayKey, timeKey, remove) => {
        const { editableFields } = this.state;

        const value = editableFields[3].fields[1].value[weekDayKey];

        if(value.openTime.length === 0) return;

        if(remove){
            value.openTime = value.openTime.splice(timeKey - 1, 1);
            value.closeTime = value.closeTime.splice(timeKey - 1, 1);
        }
        else{
            if(value.openTime.length > 4) return;
            value.openTime.push({
                open: null,
                close: null,
            });
            value.closeTime.push({
                open: null,
                close: null,
            });
        }

        this.setState({ editableFields });
    }

    addSpecialHours = (remove, key) => {
        const { editableFields } = this.state;

        const field = editableFields[3].fields[2];

        if(!field.value || (remove && key === 0)) field.value = [];

        if(remove){
            if(key !== 0){
                field.value = field.value.splice(key - 1, 1);
                field.value = field.value.splice(key - 1, 1);
            }
        }
        else{
            field.value.push({
                startDate: null,
                openTime: [{}],
                closeTime: [{}],
                isOpen: true
            });
        }

        this.setState({ editableFields });
    }

    handleSpecialHours = (value, key, timeKey, type) => {
        const { editableFields } = this.state;

        const fieldValue = editableFields[3].fields[2].value[key];

        if(type === "isOpen"){
            fieldValue.isOpen = !fieldValue.isOpen;
        }
        else if(type === "startDate"){
            fieldValue.startDate = {
                year: value?.year(),
                month: value?.month(),
                day: value?.day()
            };
        }
        else{
            const hours = value[0] + value[1];
            const minutes = value[3] + value[4];
            fieldValue[type][timeKey].hours = hours;
            fieldValue[type][timeKey].minutes = minutes;
        }

        this.setState({ editableFields });
    }

    handleCategoryChange = (combo, key) => {
        const { editableFields } = this.state;
        const value = combo.value;

        if(key || key === 0){
            editableFields[0].fields[1].additionalCategory[key] = value;
        }
        else{
            editableFields[0].fields[1].value = value;
        }

        this.setState({ editableFields });
    }    
    
    addAdditionalCat = (key) => {
        const { editableFields } = this.state;
        const additionalCategories = editableFields[0].fields[1].additionalCategories;

        if (key || key === 0) {
            additionalCategories.splice(key, 1);
        }
        else if(additionalCategories.length < 10){
            additionalCategories.push('');
        }
        this.setState({ editableFields });
    }

    getOptions = (search) => {
        const { categoryOptions } = this.props;

        if(!search) return;

        let value = search.trim();
        value = value.toLowerCase();

        return categoryOptions.filter(({label}) => label && label.toLowerCase().includes(value)).slice(0, 15);
    };
    
    loadOptions = (inputValue) =>
      new Promise((resolve) => {
        resolve(this.getOptions(inputValue));
    });

    render() {
        const { editableFields, block, numberOfDays, filteredCategoryOptions } = this.state;
        const { isOpen, toggle, categoryOptions } = this.props;

        const monthsOption = moment.monthsShort().map((month, index) => ({
            label: month,
            value: index + 1,
        }));

        const daysOption = this.getArray(numberOfDays).map((day, index) => ({
            label: index + 1,
            value: index + 1,
        }));

        const weekDaysOptions = moment.weekdays().map((day, index) => ({
            label: day,
            value: day.toUpperCase(),
        }));
        
        const timeOptions = this.getArray(24).flatMap((d, index) => {
            const hour = index.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false
              })
            return(
                [{
                    label: `${hour}:00`,
                    value: `${hour}:00`,
                },{
                    label: `${hour}:30`,
                    value: `${hour}:30`,
                }]
            );
        });

        return (
            <Modal isOpen={isOpen} toggle={toggle} size="sm" style={{ width: '40%', minWidth: '40%' }}>
                <BlockUi tag="div" blocking={block}>
                    <Button onClick={toggle} className="closeModal">
                        <i className="fas fa-times fa-sm" />
                    </Button>
                    <ModalBody className='p-4' style={{ overflow: 'auto', maxHeight: '90vh' }}>
                        <div className='mb-2'>
                            <h5>
                                <FormattedMessage id="GoogleBusinessConfig.EditLocation"/>
                            </h5>
                        </div>
                        {editableFields.map((cat, catKey) =>
                            <div key={catKey} className='py-2'>
                                <div className='mb-2'>
                                    {cat.icon}
                                    <b><FormattedMessage id={`GoogleBusinessConfig.${cat.type}`}/></b>
                                </div>
                                <div className='px-2'>
                                    {cat.fields.map((field, fieldKey) =>
                                        !field.editable ?
                                            <div key={fieldKey} className='my-2 cursor-pointer hoverShow' onClick={_ => this.toggleEditableField(catKey, fieldKey)}>
                                                <div className='d-flex'>
                                                    <div className='mb-2'>
                                                        <FormattedMessage id={`GoogleBusinessConfig.${field.label}`}/>
                                                    </div>
                                                    <div className='ml-2 hoverShowIcon' style={{ display: 'none', fontSize: 'smaller', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                        <i className="fas fa-pen"/>
                                                    </div>
                                                </div>
                                                <div className='text-muted'>
                                                    {field.value ?
                                                        <div>
                                                            {field.type === 'weekDays' ?
                                                                weekDaysOptions.map((day, key) => {
                                                                    const time = field.value[key];
                                                                    return(
                                                                        <div className='text-muted w-50' key={key}>
                                                                            <div className="py-1" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '10px' }}>
                                                                                <div className="text-muted">{day.label}:</div>
                                                                                <div className='text-right'>
                                                                                    {time && time.openTime && time.openTime.length > 0 && time.openTime[0].hours && time.closeTime
                                                                                    && time.closeTime.length > 0 && time.closeTime[0].hours ?
                                                                                        <b>
                                                                                            {time.openTime.map((open, index) =>{
                                                                                                const close = time.closeTime[index];
                                                                                                return(
                                                                                                    <div key={index}>
                                                                                                        <span>{`${open.hours}:${open.minutes??'00'}`}</span>
                                                                                                        <span>-</span>
                                                                                                        <span>{`${close.hours}:${close.minutes??'00'}`}</span>
                                                                                                    </div>
                                                                                                )}
                                                                                            )}
                                                                                        </b>
                                                                                    :
                                                                                        <FormattedMessage id={"GoogleBusinessConfig.Closed"}/>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                )
                                                            : field.type === 'specialHours' ?
                                                                <div>
                                                                    {field.value?.map((hour, key) => 
                                                                        <div key={key}>
                                                                            <span className='mr-2'>{`${hour.startDate.day}/${hour.startDate.month}/${hour.startDate.year}`}</span>
                                                                            <span>
                                                                                {!hour.isOpen || !(hour.isOpen && hour.openTime && hour.openTime.length > 0 && hour.closeTime && hour.closeTime.length > 0) ?
                                                                                    <FormattedMessage id="GoogleBusinessConfig.Closed"/>
                                                                                :
                                                                                    <span>
                                                                                        {hour.openTime.map((open, index) =>{
                                                                                            const close = hour.closeTime[index];
                                                                                            return(
                                                                                                <div>
                                                                                                    <span>{`${open.hours}:${open.minutes??'00'}`}</span>
                                                                                                    <span>-</span>
                                                                                                    <span>{`${close.hours}:${close.minutes??'00'}`}</span>
                                                                                                </div>
                                                                                            )}
                                                                                        )}
                                                                                    </span>
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            : field.type === "date" ?
                                                                <div>{`${field.value.day}/${field.value.month}/${field.value.year}`}</div>
                                                            : field.type === "radio" ?
                                                                <FormattedMessage id={`GoogleBusinessConfig.${field.value}`}/>
                                                            : field.type === 'category' ?
                                                                <span>
                                                                    {categoryOptions?.find(option => option.value === field.value)?.label}
                                                                </span>
                                                            :
                                                                <span>{field.value}</span>              
                                                            }
                                                        </div>
                                                    :
                                                        <FormattedMessage id={`GoogleBusinessConfig.Add`}/>
                                                    }
                                                </div>
                                            </div>
                                        :
                                            <div key={fieldKey} className='my-2'>
                                                <div className='d-flex'>
                                                    <div>
                                                        <FormattedMessage id={`GoogleBusinessConfig.${field.label}`}/>
                                                    </div>
                                                </div>
                                                {!field.noDesc ?
                                                    <div className='text-muted my-1' style={{ fontSize: 'smaller' }}>
                                                        <FormattedMessage id={`GoogleBusinessConfig.Desc.${field.label}`} defaultMessage=""/>
                                                    </div>
                                                :''}
                                                <div>
                                                    {field.type === "text" ?
                                                        <Input
                                                            onChange={e => this.handleChange(e?.target?.value, catKey, fieldKey)}
                                                            value={field.value}
                                                        />
                                                    : field.type === "textarea" ?
                                                        <>
                                                            <GenericTextArea
                                                                backgroundColor={"white"}
                                                                onChange={e => this.handleChange(e?.target?.value, catKey, fieldKey)}
                                                                name={field.label}
                                                                value={field.value}
                                                                placeholder={''}
                                                                maxLength='750'
                                                                className="py-1"
                                                                style={{
                                                                    width: '100%',
                                                                    background: 'transparent',
                                                                    boxShadow: 'unset',
                                                                    outline: '0',
                                                                    maxHeight : '500px',
                                                                    minHeight: '50px',
                                                                    height: '50px',
                                                                    padding: '5px 0',
                                                                    resize: 'none',
                                                                    color: '#07294C'
                                                                }}
                                                            />
                                                        </>
                                                    : field.type === "date" ?
                                                        <Row>
                                                            <Col>
                                                                <div>
                                                                    <FormattedMessage id="GoogleBusinessConfig.Year"/>
                                                                </div>
                                                                <div>
                                                                    <Input
                                                                        onChange={e => this.handleDateChange(e?.target?.value, "year")}
                                                                        value={field.value?.year}
                                                                        max="9999"
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div>
                                                                    <FormattedMessage id="GoogleBusinessConfig.Month"/>
                                                                </div>
                                                                <div>
                                                                    <CustomSelect
                                                                        options={monthsOption}
                                                                        onChange={e => this.handleDateChange(e?.value, "month")}
                                                                        value={monthsOption.find(({value}) => value === field.value?.month)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div>
                                                                    <FormattedMessage id="GoogleBusinessConfig.Day"/>
                                                                </div>
                                                                <div>
                                                                    <CustomSelect
                                                                        options={daysOption}
                                                                        onChange={e => this.handleDateChange(e?.value, "day")}
                                                                        value={daysOption.find(({value}) => value === field.value?.day)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    : field.type === "phone" ?
                                                        <div>
                                                            <Row>
                                                                <Col className='col-8'>
                                                                    <Input
                                                                        onChange={e => this.handleChange(e?.target?.value, catKey, fieldKey)}
                                                                        value={field.value}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            {field.additionalPhones.map((phone, key) =>
                                                                <Row key={key} className='my-2'>
                                                                    <Col>
                                                                        <Input
                                                                            onChange={e => this.handleChange(e?.target?.value, catKey, fieldKey, true)}
                                                                            value={phone}
                                                                        />
                                                                    </Col>
                                                                    <Col className='d-flex align-items-center col-4'>
                                                                        <div style={{
                                                                            width: '30px',
                                                                            aspectRatio: '1/1',
                                                                            backgroundColor: '#d9d9d9',
                                                                            borderRadius: '50%',
                                                                            cursor: 'pointer',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center'
                                                                        }} className='AddPhoneNumber' onClick={_ => this.addPhone(key)}>
                                                                            <i className="fas fa-times"/>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            )}
                                                            {field.additionalPhones.length < 2 ?
                                                                <div className='py-2'>
                                                                    <div className='d-flex align-items-center cursor-pointer AddPhoneNumber' onClick={_ => this.addPhone()}>
                                                                        <i className="fas fa-plus mr-2"/>
                                                                        <FormattedMessage id="GoogleBusinessConfig.AddPhoneNumber"/>
                                                                    </div>
                                                                </div>
                                                            :''}
                                                        </div>
                                                    : field.type === "radio" ?
                                                        <FormGroup className='px-4'>
                                                            {field.options.map((option, key) =>
                                                                <FormGroup key={key} className="mb-1">
                                                                    <Input
                                                                        onChange={e => this.handleCheck(e?.target?.checked ? option : null)}
                                                                        checked={field.value === option}
                                                                        name={`radio1`}
                                                                        type="radio"
                                                                    />
                                                                    <Label>
                                                                        <FormattedMessage id={`GoogleBusinessConfig.${option}`}/>
                                                                    </Label>
                                                                </FormGroup>
                                                            )}
                                                        </FormGroup>
                                                    : field.type === "weekDays" ?
                                                        <div>
                                                            {weekDaysOptions.map((day, key) => {
                                                                const time = field.value[key];
                                                                return(
                                                                    <div style={{ gap: '1rem' }} key={key} className="d-flex align-items-start my-2">
                                                                        <div>
                                                                            <div className="text-muted">{day.label}:</div>
                                                                            <FormGroup className='pl-4 mt-1 mb-0'>
                                                                                <Input
                                                                                    type="checkbox"
                                                                                    checked={!time.isOpen}
                                                                                    onChange={e => this.handleTime(e?.target?.checked, key, null, 'isOpen')}
                                                                                />
                                                                                <Label>
                                                                                    <FormattedMessage id={"GoogleBusinessConfig.IsClosed"}/>
                                                                                </Label>
                                                                            </FormGroup>
                                                                        </div>
                                                                        {time.isOpen ?
                                                                            <div className='w-100'>
                                                                                {time.openTime?.map((open, timeKey) => {
                                                                                    const close = time.closeTime && time.closeTime[timeKey];
                                                                                    const openHour = open?.hours ? open?.hours > 9 ? open?.hours : `0${open?.hours}` : '00';
                                                                                    return(
                                                                                        <div key={timeKey} style={{ gap: '2rem' }} className="d-flex align-items-center w-100 mt-2">
                                                                                            <div style={{ width: '45%' }}>
                                                                                                <CustomSelect
                                                                                                    options={timeOptions}
                                                                                                    value={open?.hours ? timeOptions.find(({value}) => value === `${openHour}:${open?.minutes??'00'}`) : null}
                                                                                                    onChange={e => this.handleTime(e?.value, key, timeKey, 'openTime')}
                                                                                                />
                                                                                            </div>
                                                                                            <div>-</div>
                                                                                            <div style={{ width: '45%' }}>
                                                                                                <CustomSelect
                                                                                                    options={timeOptions}
                                                                                                    value={close?.hours ? timeOptions.find(({value}) => value === `${close?.hours}:${close?.minutes??'00'}`) : null}
                                                                                                    onChange={e => this.handleTime(e?.value, key, timeKey, 'closeTime')}
                                                                                                />
                                                                                            </div>
                                                                                            {timeKey === 0 ?
                                                                                                <div className='AddPhoneNumber' onClick={_ => this.addTime(key, timeKey, false)} style={{ width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                                                                                    <i className="fas fa-plus"/>
                                                                                                </div>
                                                                                            :
                                                                                                <div style={{ width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} className='AddPhoneNumber' onClick={_ => this.addTime(key, timeKey, true)}>
                                                                                                    <i className="fas fa-times"/>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        :''}
                                                                    </div>
                                                                )}
                                                            )}
                                                        </div>
                                                    : field.type === "specialHours" ?
                                                        <div>
                                                            {field.value?.map((hour, key) => 
                                                                <div key={key} style={{ gap: '2rem' }} className="d-flex align-items-start my-2">
                                                                    <FormGroup className='pl-4 mt-1 mb-0'>
                                                                        <Input
                                                                            type="checkbox"
                                                                            checked={!hour.isOpen}
                                                                            onChange={e => this.handleSpecialHours(e?.target?.checked, key, null, 'isOpen')}
                                                                        />
                                                                        <Label>
                                                                            <FormattedMessage id={"GoogleBusinessConfig.IsClosed"}/>
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <div style={{ width: '50%' }}>
                                                                        <SingleDatePicker
                                                                            id={`modelDate${key}`}
                                                                            date={hour.startDate && moment(`${hour.startDate.year}-${hour.startDate.month}-${hour.startDate.day}`, 'YYYY-MM-DD')}
                                                                            focused={this.state[`focused${key}`]}
                                                                            onFocusChange={({ focused }) => this.setState({ [`focused${key}`]: focused })}
                                                                            small={true}
                                                                            numberOfMonths={1}
                                                                            showDefaultInputIcon={true}
                                                                            readOnly={true}
                                                                            onDateChange={date => this.handleSpecialHours(date, key, null, 'startDate')}
                                                                            showClearDate={true}
                                                                        />
                                                                    </div>
                                                                    <div style={{ width: '70%' }}>
                                                                        {hour.isOpen ?
                                                                            hour.openTime?.map((open, timeKey) => {
                                                                                const close = hour.closeTime && hour.closeTime[timeKey];
                                                                                return(
                                                                                    <div key={timeKey} className='d-flex align-items-center' style={{ gap: '2rem' }}>
                                                                                        <div style={{ width: '47.5%' }}>
                                                                                            <CustomSelect
                                                                                                options={timeOptions}
                                                                                                value={open?.hours ? timeOptions.find(({value}) => value === `${open?.hours}:${open?.minutes??'00'}`) : null}
                                                                                                onChange={e => this.handleSpecialHours(e?.value, key, timeKey, 'openTime')}
                                                                                            />
                                                                                        </div>
                                                                                        <div style={{ width: '5%' }} className="d-flex align-items-center justify-content-center"> - </div>
                                                                                        <div style={{ width: '47.5%' }}>
                                                                                            <CustomSelect
                                                                                                options={timeOptions}
                                                                                                value={close?.hours ? timeOptions.find(({value}) => value === `${close?.hours}:${close?.minutes??'00'}`) : null}
                                                                                                onChange={e => this.handleSpecialHours(e?.value, key, timeKey, 'closeTime')}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                )})
                                                                        :''}
                                                                    </div>
                                                                    <div style={{ alignSelf: 'center' }}>
                                                                        <div className='cursor-pointer AddPhoneNumber' onClick={_ => this.addSpecialHours(true, key)}>
                                                                            <i className="fas fa-times"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div className='py-2'>
                                                                <div className='d-flex align-items-center cursor-pointer AddPhoneNumber' onClick={_ => this.addSpecialHours()}>
                                                                    <i className="fas fa-plus mr-2"/>
                                                                    <FormattedMessage id="GoogleBusinessConfig.AddSpecialHour"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    : field.type === "category" ?
                                                        <div>
                                                            <div>
                                                                <AsyncSelect
                                                                    cacheOptions
                                                                    loadOptions={this.loadOptions}
                                                                    defaultOptions={filteredCategoryOptions}
                                                                    value={field.value && categoryOptions ? categoryOptions.find(({value}) => value === field.value) : null}
                                                                    onChange={e => this.handleCategoryChange(e)}
                                                                />
                                                            </div>
                                                            <div>
                                                                {field.additionalCategories.length > 0 ?
                                                                    <div className='mt-2'>
                                                                        <div>
                                                                            <FormattedMessage id="GoogleBusinessConfig.AdditionalCategories"/>
                                                                        </div>
                                                                        <div>
                                                                            {field.additionalCategories?.map((cat, catKey) =>
                                                                                <Row key={catKey} className="mt-1">
                                                                                    <Col className='col-10'>
                                                                                        <AsyncSelect
                                                                                            cacheOptions
                                                                                            loadOptions={this.loadOptions}
                                                                                            defaultOptions={filteredCategoryOptions}
                                                                                            value={cat && categoryOptions ? categoryOptions.find(({value}) => value === cat) : null}
                                                                                            onChange={e => this.handleCategoryChange(e, catKey)}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col className='d-flex align-items-center justify-content-center'>
                                                                                        <div className='cursor-pointer AddPhoneNumber' onClick={_ => this.addAdditionalCat(catKey)}>
                                                                                            <i className="fas fa-times"/>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                :''}
                                                                <div className='py-2'>
                                                                    <div className='d-flex align-items-center cursor-pointer AddPhoneNumber' onClick={_ => this.addAdditionalCat()}>
                                                                        <i className="fas fa-plus mr-2"/>
                                                                        <FormattedMessage id="GoogleBusinessConfig.AddAdditionalCategory"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :''}
                                                </div>
                                                <div className='my-2'>
                                                    <Button className="btn btn-host btn-sm" onClick={_ => this.submitChange(catKey, fieldKey)}>
                                                        <FormattedMessage id="GoogleBusinessConfig.Save"/>
                                                    </Button>
                                                    <Button className="btn btn-sm ml-2" onClick={_ => this.cancelEdit(catKey, fieldKey)}>
                                                        <FormattedMessage id="GoogleBusinessConfig.Cancel"/>
                                                    </Button>
                                                </div>
                                            </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </ModalBody>
                </BlockUi>
            </Modal>
        )
    }
}
