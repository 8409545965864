import React, { Component } from 'react';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import { Button, Card, Col, Collapse, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import { deleteAPI, getAPI, postAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { Chart } from "react-google-charts";
import SalesProcessUserModal from './SalesProcessUserModal';
import microsoft365 from '../../img/Microsoft365.png';
import SalesProcessDefaultPage from './SalesProcessDefaultPage';
import { CommonHelper} from '../Base/CommonUIComponents';
import { checkSalesProcessPermission, getAllowedSalesProcessUsers, getCurrentUser } from './SalesProcessFunctions';

class SalesDealUsers extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            adalUsersOptions: [],
            selectedUser: null,
            deleteModal: false,
            modal: false,
            salesUsers: [],
            blockModal: false,
            errorModal: null,
            blockAdal: false,
            hasOffice365: global?.modules?.some(m => m === 'MicrosoftOffice365'),
            managers: [],
            teams: [],
            unassociatedUsers: [],
            teamsOpened: [],
            currentUser: getCurrentUser()
        };
    }

    componentDidMount() {
        this.getSeparetadeUsers(true);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.salesUsers !== this.props.salesUsers) {
            this.getSeparetadeUsers();
        }
    }

    scrollToCenter = () => {
        const elements = document.querySelectorAll('.scrollableDiv');
        if (elements?.length > 0) {
            elements.forEach((el) => {
                const centerPosition = (el.scrollWidth - el.clientWidth) / 2;
                
                el.scrollTo({
                    left: centerPosition,
                    behavior: 'smooth',
                });
            });
        }
    }

    getSeparetadeUsers = (centerTeams) => {
        const { salesUsers } = this.props;
        const { hasOffice365 } = this.state;
        let teams = [['Name', 'Manager', 'ToolTip', 'Id', 'ManagerId']];
        let unassociatedUsers = [];
        let managers = [];
    
        if (salesUsers?.length) {
            managers = Array.from(new Set(
                salesUsers
                  .filter(user => user.managerId)
                  .map(user => salesUsers.find(u => u.id === user.managerId))
                  .filter(manager => manager !== null && manager !== undefined)
            ));

            unassociatedUsers = salesUsers.filter(u => !u.managerId && !managers.some(m => m.id === u.id));

            salesUsers.forEach(user => {
                if (user && unassociatedUsers && !unassociatedUsers.some(u => u.id === user.id)) {
                    
                    const userHtml = (
                        `<div class="row">
                            <div class="cursor-pointer col-12 col">
                                <div class="d-flex align-items-center">
                                    <b class="text-truncate" style="font-size: 14px;">${user.label}</b>
                                </div>
                                <div class="text-muted text-left text-truncate mt-1" style="color:#6F7388 !important;font-size: 11.2px; overflow: visible">     
                                    ${user.hotelIds?.length > 0 ?
                                        `<i class="icon-icon-hotel text-muted cutomTooltip" data-toggle="tooltip"> 
                                            <span class="customTooltipBottomText" >
                                                ${user.hotelIds?.map(hotel =>
                                                    `<div style="margin: 8px 0">${global.hotelList.find(el => el.value === hotel)?.label || hotel}</div>`
                                                ).join('')}
                                            </span>
                                        </i>`
                                    : ''}
                                    ${this.props.intl.formatMessage({ id: `ReferenceDataFunctions.${user.role}` })}
                                    
                                </div>
                            </div>
                        </div>`
                    );

                    const userHtml365 = (
                        `<div class="row">
                            <div class="cursor-pointer col-12 col">
                                <div class="d-flex align-items-center">
                                    <b class="text-truncate" style="font-size: 14px;">${user.label}</b>
                                    <div>
                                        <img alt="" src=${microsoft365} height="20px" />
                                    </div>
                                </div>
                                <div class="text-muted text-left text-truncate mt-1" style="color:#6F7388 !important;font-size: 11.2px;">
                                    ${user.hotelIds?.length > 0 ?
                                        `<i class="icon-icon-hotel text-muted cutomTooltip" data-toggle="tooltip"> 
                                            <span class="customTooltipBottomText" >
                                                ${user.hotelIds?.map(hotel =>
                                                    `<div style="margin: 8px 0">${global.hotelList.find(el => el.value === hotel)?.label || hotel}</div>`
                                                ).join('')}
                                            </span>
                                        </i>`
                                    : ''}
                                    ${this.props.intl.formatMessage({ id: `ReferenceDataFunctions.${user.role}` })}
                                </div>
                            </div>
                        </div>`
                    );

                    teams.push([
                        {'v': user.label, 'f': hasOffice365 && user.hasExternalAccess ? userHtml365 : userHtml}, 
                        {'v': managers.find(m => m.id === user.managerId)?.label ?? '', 'f': hasOffice365 && user.hasExternalAccess ? userHtml365 : userHtml},
                        '',
                        user.id,
                        user.managerId
                    ]);
                }
            })
        }

        this.setState({ teams, unassociatedUsers, managers }, () => { 
            if(centerTeams){
                window.setTimeout(() => this.scrollToCenter(), 400) 
            }
        });
    };
    
    createUser = (e) => {
        e.preventDefault();
        this.setState({ blockModal: true }, () => {
            const { selectedUser } = this.state;

            if (selectedUser.role === "Admin" || selectedUser.role === "HelpDeskUser" || selectedUser.role === "CentralUser")
                selectedUser.hotelIds = []

            postAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ errorModal: errorMessage, blockModal: false });
    
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
    
                    handleNotification(data, <FormattedMessage id="SalesDeal.UserCreated" />, <FormattedMessage id="generic.success" />);
                    this.setState({ modal: false, blockModal: false }, () => this.props.getUsers());
                }
            }, '/api/gms/SalesProcess/v1/salesprocessusers', selectedUser);
        });
    }
    
    logInOutUserExternal = () => {
        this.setState({ blockModal: true }, () => {
            const { selectedUser, hasOffice365 } = this.state;
            if(hasOffice365){
                if(selectedUser.hasExternalAccess)
                    this.logoutOffice(selectedUser.id);
                else
                    this.getOfficeURL(selectedUser.id);
            }
        });
    }

    logoutOffice = (id) => {
        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorModal: errorMessage, blockModal: false });

                return;
            }
            if (data && data.response && data.response[0]) {
                this.setState({ blockModal: false }, () => window.open(data.response[0], '_blank').focus());
            }
        }, `/api/gms/SalesProcess/v1/m365/${id}/logout`);
    }

    getOfficeURL = (id) => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorModal: errorMessage, blockModal: false });

                return;
            }
            if (data && data.response && data.response[0]) {
                this.setState({ blockModal: false }, () => window.open(data.response[0], '_blank').focus());
            }
        }, `/api/gms/SalesProcess/v1/m365/${id}/loginurl`);
    }
    
    deleteUser = (id) => {
        this.setState({ block: true }, () =>
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        this.setState({ deleteModal: false, block: false }, () =>
                            handleNotification(data)
                        );
                        return;
                    }
                    else {
                        this.setState({ deleteModal: false, modal: false, block: false, selectedUser: null }, () => {
                            handleNotification(data, <FormattedMessage id="SalesDeal.UserRemoved" />, <FormattedMessage id="generic.success" />);
                            this.props.getUsers();
                        });
                        return;
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/salesprocessusers/${id}`)
        );
    }

    handleUserChange = (e) => {
        const { selectedUser } = this.state;
        const { name, value } = e?.target;

        this.setState({ selectedUser: {...selectedUser, [name]: value} });
    }

    handleComboChange = (combo, name) => {
        const { selectedUser } = this.state;

        if(combo?.value){
            if(!selectedUser.email)
                selectedUser.email = combo.email;
            if(!selectedUser.firstName)
                selectedUser.firstName = combo.firstName;
            if(!selectedUser.lastName)
                selectedUser.lastName = combo.lastName;
        }
        
        this.setState({ selectedUser: {...selectedUser, [name]: combo?.value} });
    }

    handleHotel = (name, combo) => {
        const { selectedUser } = this.state;
        this.setState({selectedUser: {...selectedUser, [name]: combo ? combo?.map(cmb => cmb.value) : [] }});
    }

    getAdalUsers = () => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockAdal: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({
                    adalUsersOptions: data.users?.map(adUser => ({
                        label: (
                            <div>
                                <div>
                                    {`${adUser.name} ${adUser.surname}`}
                                </div>
                                <div className='title-sm'>
                                    {adUser.email}
                                </div>
                            </div>
                        ),
                        value: adUser.id,
                        firstName: adUser.name,
                        lastName: adUser.surname,
                        email: adUser.email
                    })),
                    blockAdal: false
                });
            }
        }, '/api/User/v1/User/getUsers?accessGroup=e02b5dfc-ee17-4dbd-aeba-699ab14ad35c');
    }

    toggleModal = (user) => {
        const { modal, adalUsersOptions } = this.state;
        this.setState({ modal: !modal, selectedUser: user ?? {}, blockAdal: !modal && adalUsersOptions.length === 0 }, () => {
            if (!modal && adalUsersOptions.length === 0)
                this.getAdalUsers();
        });
    }

    toggleDeleteModal = (selectedUser) => {
        this.setState(prevState => ({ deleteModal: !prevState.deleteModal, selectedUser }));
    }

    renderUserCard = (user, key, customStyle = 'col-9 mb-3', type) => {
        const { hasOffice365 } = this.state;
        
        return (
            <Col className={customStyle} key={key}>
                <Card body className='p-3' style={{ borderRadius: type === 'noAssociation' ? '' : '0px' }}>
                    <Row>
                        <Col onClick={() => this.toggleModal(user)} className='cursor-pointer'>
                            <div style={{ fontSize: '0.8em' }} className='text-muted'>
                                {user.hotelIds?.length > 0 ?
                                    <i class="icon-icon-hotel text-muted cutomTooltip mr-1" data-toggle="tooltip">
                                        <span class="customTooltipBottomText" >
                                            {user.hotelIds?.map((hotel, index) => {
                                                return <div key={index} style={{ margin: '8px 0' }} >{global.hotelList.find(el => el.value === hotel)?.label || hotel}</div>
                                            })}
                                        </span>
                                    </i>
                                : ''}
                                <FormattedMessage id={`ReferenceDataFunctions.${user.role}`}/>
                            </div>
                            <div className='d-flex align-items-center text-truncate'>
                                <b>{`${user.firstName} ${user.lastName}`}</b>
                                {hasOffice365 && user.hasExternalAccess && (
                                    <div>
                                        <img alt="" src={microsoft365} height="20px" />
                                    </div>
                                )}
                            </div>
                            <div className='text-muted title-sm text-truncate'>
                                {user.email}
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
        );
    };

    toggleAssociatedUsersCollapse = (team) => {
        const { teamsOpened } = this.state;
        if(!team || !team.manager || !teamsOpened ){
            return;
        }

        const index = teamsOpened.indexOf(team.manager.id);

        if (index === -1) {
            this.setState({ teamsOpened: [...teamsOpened, team.manager.id] });
        } else {
            this.setState({ teamsOpened: teamsOpened.filter((_, i) => i !== index) });
        }
    }

    handleSelect = (chartWrapper) => {
        if(!chartWrapper || !chartWrapper.chartWrapper){
            return;
        }
        const { salesUsers } = this.props;

        const chart = chartWrapper.chartWrapper.getChart();
        const selection = chart?.getSelection();
        if (selection?.length > 0) {
            const selectedItem = selection[0];

            if(selectedItem && selectedItem.row !== null && selectedItem.row !== undefined){
                const values = chartWrapper.chartWrapper.getDataTable();
                
                if(values){
                    const selectedValueId = values.getValue(selectedItem.row, 3);
                    const selectedUser = salesUsers.find(u => u.id === selectedValueId);
                    if(selectedUser){
                        chart.setSelection([]);
                        this.setState({ selectedUser }, () => this.toggleModal(selectedUser));
                    }
                }
            }
        }
    }
    
    chartEvents() {
        return [
            {
                eventName: 'select',
                callback: this.handleSelect,
            },
        ];
    }

    getHierarchy = (userId) => {
        const { teams } = this.state;

        let hierarchy = [];
        let subordinates = teams.filter(u => u[4] === userId);

        if(subordinates && subordinates.length > 0){
            hierarchy = subordinates;
            subordinates.forEach(subordinate => {
                const subHierarchy = this.getHierarchy(subordinate[3]);
                hierarchy = hierarchy.concat(subHierarchy);
            });
        }
        return hierarchy;
    };

    render() {
        const { block, error, selectedUser, deleteModal, modal, adalUsersOptions, blockModal, blockAdal, errorModal, hasOffice365, unassociatedUsers, teams, currentUser } = this.state;
        const { salesUsers } = this.props;

        const chartOptions = {
            allowHtml: true,
            nodeClass: 'p-3 card-body customNodeStyle cursor-pointer rounded',
            size: 'medium',
        };

        return (
            <div className="border-0 h-100 pb-2">
                <ErrorAlert error={error}/>
                <BlockUi tag="div" blocking={block}>
                    <div className='mb-4'>
                        <Row>
                            <Col>
                                <h4 className='m-0'>
                                    <i className={`${this.props.icon} mr-2`} />
                                    <FormattedMessage id="SalesDeal.SalesProcessUser" />
                                </h4>
                            </Col>
                            <Col className='col-2 text-right'>
                                {checkSalesProcessPermission('SalesDealUsers:manageAll', currentUser?.role) ?
                                    <Button className="btn-sm btn-host mr-2" onClick={_ => this.toggleModal()}>
                                        <i className="fas fa-plus" />
                                    </Button>
                                    : ''}
                                <CommonHelper help={<FormattedMessage id="SalesProcess.SalesDealUsersHelper" />} id={'SalesProcessUsersHelp'} />
                                
                            </Col>
                        </Row>
                    </div>
                    <SalesProcessDefaultPage
                        mainValue={salesUsers?.length}
                        noValueText={"SalesProcess.NoUsers"}
                        icon={this.props.icon}
                    >
                            {teams?.length === 0 ?
                                <Row className='mt-2'>
                                    {salesUsers?.map((user, k) => this.renderUserCard(user, `unassociated-${k}`, 'col-4 mb-3', 'noAssociation'))}
                                </Row>
                            :
                                <div className='mt-2 d-flex align-items-start justify-content-between'>
                                    <div className={`d-flex flex-column align-items-start px-3`} style={{ width: unassociatedUsers?.length === 0 ? '100%' : '85%' }}>
                                        <Col className='d-flex align-items-center text-muted pl-3 pb-2 col-12'>
                                            <i className="fas fa-users mr-2" /><FormattedMessage id="SalesDeal.Teams" />
                                        </Col>
                                        {teams.filter((user) => user[4] === null || user[4] === undefined).map((manager, k) => {
                                            const currentTeam = [teams[0], manager, ...this.getHierarchy(manager[3])];
                                            return(
                                                <React.Fragment key={k}>
                                                    {k !== 0 && 
                                                        <Col className='col-12' key={`chartDivisor-${k}`}>
                                                            <hr className='my-2' />
                                                        </Col>
                                                    }
                                                    <Col className={`col-12 scrollableDiv thinScrollBar ${k !== 0 ? 'pt-2' : ''}`} key={`chart-${k}`}>
                                                        <Chart
                                                            chartType="OrgChart"
                                                            data={currentTeam}
                                                            options={chartOptions}
                                                            width="100%"
                                                            height="100%"
                                                            chartEvents={this.chartEvents()}
                                                        />
                                                    </Col>
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                    {unassociatedUsers?.length > 0 ?
                                        <div className='d-flex flex-column px-0' style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.2)', overflow: 'visible', width: '15%' }}>
                                            <Col className='d-flex align-items-center text-muted pb-2 col-12'>
                                                <i className="fas fa-users-slash mr-2" /><FormattedMessage id="SalesDeal.UsersWithoutManager" />
                                            </Col>
                                            {unassociatedUsers?.map((user, k) => this.renderUserCard(user, `unassociated-${k}`, 'col-12 mb-3'))}
                                        </div>
                                    :''}
                                </div>
                            }
                    </SalesProcessDefaultPage>

                    {modal ?
                        <SalesProcessUserModal
                            toggleModal={this.toggleModal}
                            salesUsers={salesUsers}
                            block={blockModal}
                            blockAdal={blockAdal}
                            user={selectedUser}
                            handleUserChange={this.handleUserChange}
                            handleComboChange={this.handleComboChange}
                            handleHotel={this.handleHotel}
                            createUser={this.createUser}
                            adalUsersOptions={adalUsersOptions}
                            errorModal={errorModal}
                            logInOutUserExternal={this.logInOutUserExternal}
                            hasOffice365={hasOffice365}
                            toggleDeleteModal={this.toggleDeleteModal}
                        />
                    :''}

                    {deleteModal ?
                        <ConfirmActionModal
                            modal={deleteModal}
                            toggleModal={_ =>this.toggleDeleteModal(selectedUser)}
                            actionFunction={_ => this.deleteUser(selectedUser?.id)}
                            text={<FormattedMessage id="SalesDeal.ConfirmDeleteUser" values={{ Name: `${selectedUser.firstName} ${selectedUser.lastName}` }} />}
                            block={block}
                        />
                    :''}
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(SalesDealUsers);