import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Card, Col, Row, Modal, ModalBody, Badge, Button } from 'reactstrap';
import BlockUi from 'react-block-ui';
import { getAPI } from "../Base/API";
import { ErrorAlert } from '../Base/ErrorAlert';

class PreviewTemplatesByCountry extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            previewTemplates: []
        };
    }

    componentDidMount() {
        if (this.props.templates && this.props.templates.length > 0) {
            this.props.templates.forEach((template, i) => {
                this.getTemplate(this.props.channel, template.templateId, template.countries, template.isDefault, ((this.props.templates.length - 1 === i) ? false : true));
            })
        }
        else {
            this.setState({ block: false });
        }
    }

    getTemplate = (type, templateId, countries, isDefault, block) => {
        if (parseInt(templateId)) {
            this.getEgoiTemplate(type, templateId, countries, isDefault, block);
        }
        else {
            this.getSendGridTemplate(templateId, countries, isDefault);
        }
    }

    getEgoiTemplate = (type, templateId, countries, isDefault, block) => {

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const { previewTemplates } = this.state;
                data.countries = countries;
                data.isDefault = isDefault;

                previewTemplates.push(data);
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoitransactional/template/${type}/${templateId}`);
    }

    getSendGridTemplate = (templateId, countries, isDefault) => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.versions && data.response.versions.length > 0) {
                const { previewTemplates } = this.state;

                const currentVersion = data.response.versions
                    .filter(vrs => vrs.active === 1)
                    .reduce((acc, curr) => acc ? acc : curr, null);

                const template = {
                    templateName: data.response.name,
                    countries: countries,
                    isDefault: isDefault,
                    thumbnail: currentVersion.thumbnail_url,
                    htmlBody: currentVersion.html_content,
                    subject: currentVersion.subject
                }

                previewTemplates.push(template);

                this.setState({ error: errorMessage, previewTemplates, block: false });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/SendGrid/v1/templates/${templateId}`);
    }

    render() {
        const { error, previewTemplates } = this.state;

        return (
            <Modal isOpen={this.props.modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={this.props.toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlockUi tag="div" blocking={this.state.block}>
                        <ErrorAlert error={error} />

                        <Row>
                            {previewTemplates && previewTemplates.length > 0 ?
                                previewTemplates.map((template, key) =>
                                    <Col className={(previewTemplates < 4 ? 'col-3' : '') + ' px-2'} key={key} /*style={{ height: '500px' }}*/>
                                        <Card className="p-2 border-0 mb-3 shadow h-100">
                                            <div>
                                                <h5>{template.templateName}</h5>
                                                <div className="mb-2 ">{template.subject}</div><div>
                                                    {template.isDefault ? <span className="text-muted">Default Template</span> :
                                                        template.countries && template.countries.map((country, k) =>
                                                            <span className={`flag-icon flag-icon-${country.toLowerCase()} mb-2 px-2 mr-2`} key={k} />
                                                        )}
                                                </div>
                                                <div>
                                                    {template.clickTracking || template.openTracking ?
                                                        <>
                                                            {template.clickTracking ? <Badge className="my-2 p-2 mr-2" color="primary">Click Tracking</Badge> : ''}
                                                            {template.openTracking ? <Badge className="my-2 p-2" color="primary">Open Tracking</Badge> : ''}
                                                        </>
                                                        : <><br /><br/></>}
                                                </div>
                                            </div>
                                            {template.textBody ?
                                                <div>
                                                    {template.textBody}
                                                </div>
                                            :
                                                <>
                                                    {previewTemplates.length > 1 ?
                                                        <div style={{ width: '100%', height: 'auto' }}>
                                                            <img src={template.thumbnail || template.thumbNail} style={{ width: 'inherit' }} alt={template.templateName} />
                                                        </div>
                                                        :
                                                        <div>
                                                            <div dangerouslySetInnerHTML={{ __html: template.htmlBody }} />
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </Card>
                                    </Col>
                                )
                            : <Col className="text-center my-3"> <FormattedMessage id="PreviewTemplate.ThereIsNoTemplates" /> </Col>}
                        </Row>
                    </BlockUi>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(PreviewTemplatesByCountry)
