import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { handleNotification } from "../../Base/Notification";
import { StyledCard } from "../../Base/CommonUIComponents";
import { CustomTable } from '../../Base/CustomTable';
import { getAPI } from "../../Base/API";
import moment from 'moment';

import ExportFilters from './ExportFilters';

export class ExportProfiles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            lists: [],
            tags: [],
            selectedTags: [],
            profiles: [],
            pageSize: 15,
            pageIndex: 0,
            totalItems: 0,
            requiredFieldsOptions: [
                { label: this.props.intl.formatMessage({ id: 'ExportProfiles.Email' }), field: 'mustHaveEmail', isSelected: false },
                { label: this.props.intl.formatMessage({ id: 'ExportProfiles.Cellphone' }), field: 'mustHaveMobile', isSelected: false }
            ]
        };
    }

    componentDidMount() {
        this.getLists();
        this.getTags();
    }

    getLists = () => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                let list = [];

                data.items && data.items.forEach(el => {
                    list.push({ value: el.list_id, label: el.public_name });
                })
                this.setState({ lists: list });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/lists?limit=100&offset=0`);
    }

    getTags = () => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    const list = [];

                    data.response && data.response.forEach(tag => {
                        list.push({ value: tag.id, label: tag.code });
                    })

                    this.setState({ tags: data.response });
                }
                this.setState({ block: false });
            }
        }, `/api/gms/Profile/v1/tag/groupDetails?tagType=all`)
    }

    exportData = (e, isToExport) => {
        if(e) e.preventDefault();
        this.setState({ block: true });

        let params = '';
        if (this.state.birthFrom) params += `&birthFrom=${moment(this.state.birthFrom).format("YYYY-MM-DD")}`;
        if (this.state.birthTo) params += `&birthTo=${moment(this.state.birthTo).format("YYYY-MM-DD")}`;
        if (this.state.checkinDate) params += `&checkinDate=${moment(this.state.checkinDate).format("YYYY-MM-DD")}`;
        if (this.state.checkoutDate) params += `&checkoutDate=${moment(this.state.checkoutDate).format("YYYY-MM-DD")}`;
        if (this.state.stayFromDate) params += `&stayFromDate=${moment(this.state.stayFromDate).format("YYYY-MM-DD")}`; 
        if (this.state.stayToDate) params += `&stayToDate=${moment(this.state.stayToDate).format("YYYY-MM-DD")}`;
        if (this.state.country) params += `&country=${this.state.country}`;
        if (this.state.consentType) params += `&consentType=${this.state.consentType}`;
        if (this.state.profileType) params += `&profileType=${this.state.profileType}`;
        if (this.state.selectedTags) this.state.selectedTags.forEach(el => params += `&tagIds=${el}`)
        if (this.state.selectedHotels) this.state.selectedHotels.forEach(el => params += `&hotelIds=${el}`)
        this.state.requiredFieldsOptions.forEach(el => params += `&${el.field}=${el.isSelected}`);

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else if (isToExport && data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="ExportProfiles.ProfilesExported" />, <FormattedMessage id="generic.success" />);
                }

                this.setState({
                    profiles: data.response ? data.response : [],
                    totalItems: data.totalItems
                });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/marketing/egoi/${this.state.listId}/export?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}&export=${isToExport}` + params);
    }

    handleSelect = (name, combo) => {
        let value = null

        if (Array.isArray(combo)) {
            value = combo.map(el => el.value);
        } else {
            value = combo ? combo.value : null;
        }

        this.setState({
            [name]: value
        })
    }

    handleDates = (startDate, endDate, dates) => {
        this.setState({
            [startDate]: dates.startDate,
            [endDate]: dates.endDate
        })
    }

    handleTags = (tagId, isToRemove) => {
        let selectedTags = [...this.state.selectedTags];

        if (isToRemove) {
            selectedTags = selectedTags.filter(el=> el !== tagId);
        }
        else {
            selectedTags.push(tagId);
        }

        this.setState({ selectedTags });
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        this.setState({
            pageIndex: page - 1,
            pageSize: sizePerPage,
        }, (e) => this.exportData(e, false) );
    }

    handleRequiredField = (field) => {
        const { requiredFieldsOptions } = this.state;

        let index = requiredFieldsOptions.findIndex(el => el.field === field);

        if (index > -1) {
            requiredFieldsOptions[index].isSelected = !requiredFieldsOptions[index].isSelected;

            this.setState({ requiredFieldsOptions });
        }
    }

    render() {
        const { block, error, lists, tags, totalItems, pageIndex, pageSize } = this.state;

        const columns = [
            {
                dataField: 'firstName',
                text: <FormattedMessage id="ApiGatewayUserManagement.FirstName" />,
                sort: true,
                formatter: (cell, row) => <span> {cell} {row.lastName} </span>
            },
            {
                dataField: 'birthdate',
                text: <FormattedMessage id="ExportProfiles.Birthdate" />,
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : ''
            },
            {
                dataField: 'email',
                text: <FormattedMessage id="ExportProfiles.Email" />,
                sort: true
            },
            {
                dataField: 'cellphone',
                text: <FormattedMessage id="ExportProfiles.Cellphone" />,
                sort: true
            },
            {
                dataField: 'phone',
                text: <FormattedMessage id="ExportProfiles.Phone" />,
                sort: true
            },
            {
                dataField: 'language',
                text: <FormattedMessage id="ExportProfiles.Language" />,
                sort: true
            },
            {
                dataField: 'isOnEgoi',
                text: <FormattedMessage id="ExportProfiles.IsOnEgoi" />,
                formatter: cell => cell ? <FormattedMessage id="generic.yes" /> : <FormattedMessage id="generic.no" />
            }
        ]


        return (
            <StyledCard block={block} error={error} icon={'fa-lg icon-icon-export-profiles'} title={'NavMenu.ExportProfiles'}>
                <Row className="mt-4 mb-2">
                    <Col className="col-4">
                        <ExportFilters
                            exportData={this.exportData}
                            handleSelect={this.handleSelect}
                            lists={lists}
                            tags={tags}
                            selectedHotels={this.state.selectedHotels}
                            selectedTags={this.state.selectedTags}
                            handleDates={this.handleDates}
                            handleTags={this.handleTags}
                            birthFrom={this.state.birthFrom} birthTo={this.state.birthTo}
                            checkinDate={this.state.checkinDate} checkoutDate={this.state.checkoutDate}
                            stayFromDate={this.state.stayFromDate} stayToDate={this.state.stayToDate}
                            requiredFieldsOptions={this.state.requiredFieldsOptions}
                            handleRequiredField={this.handleRequiredField}
                        />
                    </Col>
                    <Col className="col-8">
                        <Row>
                            <Col className=""> <h5><b> <FormattedMessage id="ExportProfiles.Results" /> </b></h5></Col>
                            <Col className="text-right mb-3">
                                <Button className="btn-host btn-sm" onClick={(e) => this.exportData(e, true)} id="exportToEgoi" disabled={this.state.profiles && this.state.profiles.length > 0 ? false : true}>
                                    <i className="fas fa-file-export" />
                                </Button>
                                <UncontrolledTooltip target="exportToEgoi" placement="bottom">
                                    <FormattedMessage id="ExportProfiles.ExportProfilesToEgoi" />
                                </UncontrolledTooltip>
                            </Col>
                        </Row>
                                  
                        <Row>
                            <Col>
                                <CustomTable
                                    data={this.state.profiles}
                                    columns={columns}
                                    shadow={false}
                                    showTotal={true}
                                    remote={true}
                                    search={false}
                                    page={pageIndex + 1}
                                    sizePerPage={pageSize}
                                    totalSize={totalItems}
                                    onTableChange={this.handleTableChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}
export default injectIntl(ExportProfiles)
