import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import Authorization from "./Base/Authorization";
import NoAccessPage from './NoAccessPage';
import { Route } from 'react-router-dom';
import { SearchButton } from './SearchButton';
import ErrorBoundary from '../Utils/ErrorBoundary';
import GoogleAnalytics from '../Utils/GoogleAnalytics';
import { BrowserVersionNotSupported, isBrowserVersionSupported } from './BrowserVersion';
import { getAPI } from './Base/API';
import { getSalesProcessUsers } from '../components/SalesProcess/SalesProcessFunctions';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from './Base/ErrorAlert';

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);

        this.state = {
            isBrowserVersionSupported: true,
            block: true,
            hasError: false
        };
    }

    componentDidMount() {
        this.setState({ isBrowserVersionSupported: isBrowserVersionSupported() })

        this.getUserData();
    }

    getUserData = () => {
        getAPI((result) => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message,stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false, hasError: true });
                return;
            }
            if (data) {
                global.modules = data.modules;
                global.hotelCountryCode = data.hotelCountryCode;
                global.hotelTimezone = data.hotelTimezone;
                global.hotelId = data.defaultProperty;
                global.toMasterProperties = data.toMasterProfile;
                global.showComplexes = data.showComplexes;
                global.hotelCurrency = data.hotelCurrency || 'EUR';
                global.hotelGroupId = data.hotelGroupId;
                global.hotelName = data.hotelName;
                global.userId = data.id;
                global.populateCoordinates = data.populateCoordinates;
                global.userAllowedProperties = data.userAllowedProperties;

                this.setState({ user: data, property: data.defaultProperty, error: errorMessage, block: false, hasError: false }, () => {
                    if(data.modules && data.modules.includes("SalesProcess")){
                        getSalesProcessUsers()
                    }
                });
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/user`);
    }

    render() {
        return (
            this.state.isBrowserVersionSupported ?
                <ErrorBoundary hasError={this.state.hasError}>
                    <BlockUi tag="div" blocking={this.state.block} style={{ height: '100vh' }}>
                        <ErrorAlert error={this.state.error} />

                        <GoogleAnalytics />

                        <div className="bg-light">
                            {this.state.user ?
                                <Authorization
                                    perform="GmsClient"
                                    yes={() => (
                                        <>
                                            <NavMenu
                                                user={this.state.user}
                                                property={this.state.property}
                                            />
                                            <Container className="bg-light h-100 container-fluid">
                                                {this.props.children}

                                                <SearchButton />
                                            </Container>
                                        </>
                                    )}
                                    no={() => (
                                        <Route path='/' component={NoAccessPage} />
                                    )}
                                />
                               : ''}
                        </div>
                    </BlockUi>
                </ErrorBoundary>
                :
                <BrowserVersionNotSupported />
        );
    }
}
