import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Card, Col, Row, Badge, Modal, ModalBody, Form, Label } from 'reactstrap';
import { getEgoiWebhookActions } from "../../Base/ReferenceDataFunctions";
import { BlankCard, StyledCard } from "../../Base/CommonUIComponents";
import { handleNotification } from "../../Base/Notification";
import { getAPI, postAPI, deleteAPI } from "../../Base/API";
import CustomSelect from '../../Base/CustomSelect';

import Authorization from '../../Base/Authorization';

export class WebhookForLists extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            modal: false,
            webhookList: [],
            lists: [],
            limit: 10,
            offset: 0,
            totalItems: 0,
            newWebhook: null
        };
    }

    componentDidMount() {
        this.getLists();
        this.getWebhooks();
    }

    getLists = () => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }

                let list = [];

                data.items && data.items.forEach(el => {
                    list.push({ value: el.list_id, label: el.public_name });
                })
                this.setState({ lists: list });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/lists?limit=100&offset=0`);
    }

    getWebhooks = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                this.setState({
                    webhookList: data.items ? data.items : [],
                    totalItems: data.total_items
                });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/webhooks?limit=${this.state.limit}&offset=${this.state.offset}`);
    }

    createList = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        let actions = '';
        this.state.newWebhook.actions.forEach(a => {
            actions += `actions=${a}&`;
        });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.webhook_id) {
                    handleNotification(data, <FormattedMessage id="EgoiWebhookList.WebhookCreated" />, <FormattedMessage id="generic.success" />);

                    this.setState({
                        modal: false,
                        newWebhook: null,
                        offset: 0
                    }, () => this.getWebhooks());
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/${this.state.newWebhook.list_id}/webhooks?` + actions);
    }

    removeWebhook = (e, webhookId) => {
        e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.response && data.response === 'success') {
                    handleNotification(data, <FormattedMessage id="EgoiWebhookList.WebhookRemoved" />, <FormattedMessage id="generic.success" />);
                    this.setState({ offset: 0 }, () => this.getWebhooks());
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/webhooks/${webhookId}`);
    }

    toggleModal = (data) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            newWebhook: data ? data : null
        }));
    }

    getListName = (listId) => {
        const list = this.state.lists.find(l => l.value === listId);

        return list ? list.label : listId;
    }

    handleSelect = (name, combo) => {
        let value = null;

        if (Array.isArray(combo)) {
            value = combo.map(el => el.value);
        } else {
            value = combo ? combo.value : [];
        }

        this.setState({
            newWebhook: {
                ...this.state.newWebhook,
                [name]: value
            }
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            newWebhook: {
                ...this.state.newWebhook,
                [name]: value
            }
        });
    }

    toggleTab = (val) => {
        this.setState({ activeTab: val })
    }

    render() {
        const { block, error, webhookList, totalItems, limit, offset, newWebhook } = this.state;
        const { intl } = this.props;

        const actions = getEgoiWebhookActions(intl);

        return (
            <StyledCard block={block} error={error}>
                <Authorization
                    perform="webhookForLists:add"
                    yes={() => (
                        <Row>
                            <Col className="text-right">
                                <Button className="btn btn-sm btn-host mr-2" onClick={() => this.toggleModal(null)}>
                                    <i className="fa fa-plus"></i>
                                </Button>
                            </Col>
                        </Row>
                    )}
                    no={() => <div></div>}
                />

                <Card className="border-0 p-2 mb-4 mt-2">
                    <Row>
                        <Col><b><FormattedMessage id="EgoiWebhookList.List" /></b></Col>
                        <Col><b><FormattedMessage id="EgoiWebhookList.Callback" /></b></Col>
                        <Col><b><FormattedMessage id="EgoiWebhookList.Actions" /></b></Col>
                        <Authorization
                            perform="webhookForLists:delete"
                            yes={() => (
                                <Col className="col-1"></Col>
                            )}
                            no={() => <div></div>}
                        />
                    </Row>

                    {webhookList && webhookList.map((el, k) =>
                        <Row key={k} className="mt-2 overbglight align-items-center">
                            <Col> {this.getListName(el.list_id)} </Col>
                            <Col> {el.url} </Col>
                            <Col>
                                {el.actions && el.actions.map((action, key) =>
                                    <Badge color='primary' className="m-1 p-2" key={key}>{actions.find(a => a.value === action).label}</Badge>
                                )}
                            </Col>

                            <Authorization
                                perform="webhookForLists:delete"
                                yes={() => (
                                    <Col className="text-right col-1">
                                        <Button className="btn btn-sm btn-host" onClick={(e) => this.removeWebhook(e, el.webhook_id)}>
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                    </Col>
                                )}
                                no={() => <div></div>}
                            />
                        </Row>
                    )}
                </Card>

                {totalItems > 0 && totalItems >= limit ?
                    <Row className="mt-3 px-3">
                        <Col>
                            <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.handlePagination(-limit)} disabled={offset === 0}>
                                <i className="fas fa-angle-left" />
                            </Button>
                        </Col>
                        <Col className="text-right">
                            <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.handlePagination(limit)} disabled={totalItems <= offset + limit}>
                                <i className="fas fa-angle-right" />
                            </Button>
                        </Col>
                    </Row>
                : ''}

                <Modal isOpen={this.state.modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                    <Button onClick={this.toggleModal} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    <ModalBody>
                        <BlankCard block={block} error={error}>
                            <Form onSubmit={(e) => this.createList(e)}>
                                <Row className="mb-3">
                                    <Col>
                                        <h5> <FormattedMessage id="EgoiWebhookList.CreateWebhook" /></h5>
                                    </Col>
                                    <Col className="text-right">
                                        <Button className="btn-sm btn-host" type="submit"> <i className="fas fa-save" /></Button>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Label sm={3}><FormattedMessage id="EgoiWebhookList.List" /></Label>
                                    <Col sm={9}>
                                        <CustomSelect
                                            options={this.state.lists}
                                            onChange={this.handleSelect.bind(this, 'list_id')}
                                            value={newWebhook && newWebhook.list_id && this.state.lists ? this.state.lists.find(l => l.value === newWebhook.list_id) : ''}
                                            placeholder={""}
                                            isClearable
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Label sm={3}><FormattedMessage id="EgoiWebhookList.Actions" /></Label>
                                    <Col sm={9}>
                                        <CustomSelect
                                            options={actions}
                                            onChange={this.handleSelect.bind(this, 'actions')}
                                            value={newWebhook && newWebhook.actions ? actions.filter(opt => newWebhook.actions.find(m => opt.value === m) !== undefined) : ''}
                                            placeholder={""}
                                            isClearable isMulti
                                            required
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </BlankCard>
                    </ModalBody>
                </Modal>
            </StyledCard>
        );
    }
}
export default injectIntl(WebhookForLists)
