import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';


export function getVoucherTypesOptions() {
    let opt = [
        { value: true, label: <FormattedMessage id="ReferenceDataFunctions.Payment" /> },
        { value: false, label: <FormattedMessage id="ReferenceDataFunctions.Service" /> }
    ];
    return opt;
}


export function getVoucherLanguageOptions() {
    const languageOptions = [
        {
            value: 'PT',
            label: <span> <span className="flag-icon flag-icon-pt mb-2 px-2 mr-1" /> <FormattedMessage id="generic.Pt" /></span>
        },
        {
            value: 'EN',
            label: <span> <span className="flag-icon flag-icon-gb mb-2 px-2 mr-1" /> <FormattedMessage id="generic.En" /></span>
        },
        {
            value: 'FR',
            label: <span> <span className="flag-icon flag-icon-fr mb-2 px-2 mr-1" /> <FormattedMessage id="generic.Fr" /></span>
        },
        {
            value: 'DE',
            label: <span> <span className="flag-icon flag-icon-de mb-2 px-2 mr-1" /> <FormattedMessage id="generic.De" /></span>
        }
    ];
    return languageOptions;
}

export const getVoucherCountries = [
    'PT',
    'GB',
    'FR',
    'DE',
];


export function getMultiLangEmpty() {
    const multiLangEmpty = [
        {
            languageCode: "PT",
            language: "PT",
            title: null,
            description: null
        },
        {
            languageCode: "EN",
            language: "GB",
            title: null,
            description: null
        },
        {
            languageCode: "FR",
            language: "FR",
            title: null,
            description: null
        },
        {
            languageCode: "DE",
            language: "DE",
            title: null,
            description: null
        }
    ];
    return multiLangEmpty;
}