import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Col, Row, Form, Nav, NavItem, NavLink, TabContent, TabPane, Badge } from 'reactstrap';
import { SelectHotel } from "../../Base/CommonUIComponents";
import { getConsentTypeList, getProfileType } from '../../Base/ReferenceDataFunctions';
import countryList from "react-select-country-list";
import CustomSelect from "../../Base/CustomSelect";
import { DateRangePicker } from "react-dates";
import moment from 'moment';

export class ExportFilters extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            lists: [],
            tags: [],
            profiles: [],
            pageSize: 10,
            pageIndex: 0,
            totalItems: 0,
            tagTab: 0
        };
    }

    componentDidMount() {
    }

    renderMonthElement = ({ month, onYearSelect }) => {

        let i;
        let years = [];
        for(i = moment().year(); i >= moment().year() - 122; i--) {
            years.push(<option onClick={(e) => onYearSelect(month, e.target.id)} id={i} key={`year-${i}`}>{i}</option>);
        };

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Row>
                    <Col>
                        {moment(month).locale(this.props.intl.locale).format('MMMM')}
                    </Col>
                    <Col>
                        <select onChange={(e) => onYearSelect(month, e.target.value)} value={month.year()}>
                            {years}
                        </select>
                    </Col>
                </Row>
            </div>
        );
    }

    render() {
        const { lists, tags, selectedHotels, selectedTags, handleTags, handleDates, birthFrom, birthTo, checkinDate, checkoutDate, stayFromDate, stayToDate, handleRequiredField } = this.props;

        const Badges = ({ text, isSelected, tagId }) => {
            return (
                <Badge className="p-2 my-1 mr-2 cursor-pointer" color={isSelected ? "host" : "secondary"} style={{ cursor: 'pointer' }}
                    onClick={() => handleTags(tagId, isSelected)}
                >
                    {text}
                </Badge>
            );
        }

        return (
            <Form onSubmit={(e) => this.props.exportData(e, false)}>
                <Row>
                    <Col> <h5> <b> <FormattedMessage id="ExportProfiles.Filters" /> </b></h5></Col>
                    <Col className="text-right">
                        <Button className="btn-host btn-sm" type="submit">
                            <i className="icon-icon-search" />
                        </Button>
                    </Col>
                </Row>                

                <Row className="mt-3">
                    <Col>                        
                        <b><FormattedMessage id="EgoiWebhookList.List" /></b>
                        <CustomSelect
                            options={lists}
                            onChange={this.props.handleSelect.bind(this, 'listId')}
                            placeholder={<FormattedMessage id="EgoiWebhookList.List" />}
                            isClearable
                            required
                        />
                    </Col>
                    
                    <Col>
                        <b><FormattedMessage id="ExportProfiles.ProfileType" /></b>
                        <CustomSelect
                            options={getProfileType(this.props.intl)}
                            placeholder={<FormattedMessage id="ExportProfiles.ProfileType" />}
                            onChange={this.props.handleSelect.bind(this, 'profileType')}
                            isClearable isSearchable
                        />
                    </Col>
                </Row>

                <Row className="py-4">
                    <Col>
                        <b><FormattedMessage id="ExportProfiles.Hotels" /></b>
                        <SelectHotel
                            name={'selectedHotels'}
                            onChangeFunc={this.props.handleSelect.bind(this)}
                            placeholder={<FormattedMessage id="ExportProfiles.Hotels" />}
                            value={selectedHotels ? selectedHotels : ''}
                            isMulti
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Row><Col>
                            <b><FormattedMessage id="ExportProfiles.BirthDateRange" /></b>
                        </Col></Row>
                        <DateRangePicker
                            startDate={birthFrom ? moment(birthFrom) : null}
                            endDate={birthTo ? moment(birthTo) : null}
                            isOutsideRange={day => false}
                            startDateId="birthFrom"
                            endDateId="birthTo"
                            onDatesChange={handleDates.bind(this, 'birthFrom', 'birthTo')}
                            focusedInput={this.state.focusedInput3}
                            onFocusChange={focusedInput => this.setState({ focusedInput3: focusedInput })}
                            small={true} numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={props => this.renderMonthElement(props)}
                        />
                    </Col>
                    <Col>
                        <b><FormattedMessage id="ExportProfiles.Nationality" /></b>
                        <CustomSelect
                            options={countryList().getData()}
                            placeholder={<FormattedMessage id="ExportProfiles.Nationality" />}
                            onChange={this.props.handleSelect.bind(this, 'country')}
                            isClearable isSearchable
                        />
                    </Col>
                </Row>

                <Row className="py-4">
                    <Col>
                        <Row><Col><b> Check-in & Check-out</b></Col></Row>
                        <DateRangePicker
                            startDate={checkinDate ? moment(checkinDate) : null}
                            endDate={checkoutDate ? moment(checkoutDate) : null}
                            isOutsideRange={day => false}
                            startDateId="checkinDate"
                            endDateId="checkoutDate"
                            onDatesChange={handleDates.bind(this, 'checkinDate', 'checkoutDate')}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                            small={true} numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </Col>
                    <Col>
                        <b><FormattedMessage id="ExportProfiles.ConsentType" /></b>
                        <CustomSelect
                            options={getConsentTypeList()}
                            isClearable
                            isSearchable
                            placeholder={<FormattedMessage id="ExportProfiles.ConsentType" />}
                            onChange={this.props.handleSelect.bind(this, 'consentType')}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <b><FormattedMessage id="ExportProfiles.StayDateRange" /></b>
                            </Col>
                        </Row>
                        <DateRangePicker
                            startDate={stayFromDate ? moment(stayFromDate) : null}
                            endDate={stayToDate ? moment(stayToDate) : null}
                            isOutsideRange={day => false}
                            startDateId="stayFromDate"
                            endDateId="stayToDate"
                            onDatesChange={handleDates.bind(this, 'stayFromDate', 'stayToDate')}
                            focusedInput={this.state.focusedInput2}
                            onFocusChange={focusedInput => this.setState({ focusedInput2: focusedInput })}
                            small={true} numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </Col>
                    <Col>
                        <b> <FormattedMessage id="ExportProfiles.RequiredFields" /></b>

                        <div>
                            {this.props.requiredFieldsOptions.map((field, key) =>
                                <Badge className="p-2 my-1 cursor-pointer mr-2" color={field.isSelected ? "host" : "secondary"} onClick={() => handleRequiredField(field.field)} key={key} >
                                    {field.label}
                                </Badge>
                            )}
                        </div>
                    </Col>
                </Row>


                <div className="py-4">
                    <Row>
                        <Col>
                            <b><FormattedMessage id="ExportProfiles.Tags" /></b>
                        </Col>
                    </Row>

                    <Nav tabs className="border-0">
                        {tags && tags.map((el, k) =>
                            <NavItem key={k}>
                                <NavLink className={this.state.tagTab === k ? 'tab-border-host text-host border-0 cursor-pointer' : 'text-muted border-0 cursor-pointer'} onClick={() => { this.setState({ tagTab: k }); }}>
                                    <b> {el.code}</b>
                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                    <TabContent activeTab={this.state.tagTab} className="h-100">
                        {tags && tags.map((el, k) =>
                            <TabPane tabId={k} className="border-0 my-0 p-0 pl-3 " key={k}>
                                {el.tag && el.tag.map((tag, k1) =>
                                    <span key={k1}>
                                        <Badges
                                            isSelected={selectedTags && selectedTags.find(tagId => tagId === tag.id) !== undefined ? true : false}
                                            text={tag.code}
                                            tagId={tag.id}
                                        />
                                    </span>
                                )}

                            </TabPane>
                        )}
                    </TabContent>

                </div>
            </Form>
        );
    }
}
export default injectIntl(ExportFilters)
