import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Card, Col, Modal, ModalBody, Row, UncontrolledTooltip } from 'reactstrap';
import { ActiveInactiveStatusBadge, StyledCard, DefaultPage } from "../../../Base/CommonUIComponents";
import SurveyJS from '../../../Base/SurveyJS';
import { getAPI, postAPI } from "../../../Base/API";
import PNFormDetails from './PNFormDetails';
import { isAValidJson } from '../../../Marketing/CommunicationJourney/CommonFunctions';
import CustomSelect from "../../../Base/CustomSelect";
import { handleNotification } from '../../../Base/Notification';
import ConfirmActionModal from '../../../Base/ConfirmActionModal';

class ConfigPNForms extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            pnForms: [],
            modal: false,
            previewModal: false,
            hotelGroupId: null,
            hotelList: [],
            deleteModal: false,
            formToDelete: null,
        };
    }

    componentDidMount() {
        this.getHotelList();
    }

    getPNForms = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ pnForms: (data.response || []) });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Survey/v1/survey?${this.state.hotelGroupId !== null ? `hotelGroup=${this.state.hotelGroupId}&` : ''}all=true`);
    }

    getFormDetails = (id, modal) => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ formDetails: data.response ? data.response[0] : null, [modal]: true });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Survey/v1/survey/${id}`);
    }

    deleteForm = (id) => {
        this.setState({ block: true }, () =>
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="ConfigPNForms.SuccessDelete" />, <FormattedMessage id="generic.success" />);
                    this.setState({ deleteModal: false, formToDelete: null }, () => this.getPNForms());
                } else
                    this.setState({ block: false, error: errorMessage })

            }, `/api/gms/Survey/v1/survey/${id}/delete`)
        );
    }

    getHotelList = () => {
        this.setState({ block: true }, () =>
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data && data.response && data.response.length > 0) {
                    var hotels = [{value: '00000000-0000-0000-0000-000000000000', label: <FormattedMessage id="ConfigPNForms.AllAvailableForms" />}];

                    data.response.map((item) =>
                        hotels.push({ 'value': item.hotelGroupId, 'label': item.name })
                    )

                    this.setState({ hotelList: hotels, block: false });
                }
            }, '/api/gms/Hotel/v1/hotelgroup/list?pageSize=1000&pageIndex=0')
        );
    }

    updateTable = (survey) => {
        this.setState({ block: true });

        var array = this.state.pnForms ? [...this.state.pnForms] : [];

        const index = array && array.indexOf(array.find(el => el.id === survey.id));

        if (index === -1) {
            array.push(survey);
        }
        else {
            array[index] = survey;
        }

        this.setState({ pnForms: array, block: false });
    }

    toggleModal = (modal) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            formDetails: {}
        }))
    }

    toggleDeleteModal = (id) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
            formToDelete: id
        }));
    }

    handleChangeComboSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    render() {
        const { block, error, previewModal, formDetails, pnForms, hotelGroupId, hotelList, deleteModal, formToDelete } = this.state;
        return (
            <StyledCard block={block} error={error}>
                <Row className="mb-3">
                    <Col>
                        <h5><i className="fas fa-file-signature" /> <FormattedMessage id="ConfigPNForms.ConfigPNForms" /></h5>
                    </Col>
                    <Col className="text-right">
                        <Button className="btn-sm btn-host" onClick={() => this.toggleModal('modal')}>
                            <i className="fas fa-plus" />
                        </Button>
                    </Col>
                </Row>
                <Row className='align-items-center mb-3'>
                    <Col sm={3}>
                        <CustomSelect
                            icon={"icon-icon-hotel"}
                            options={hotelList}
                            required
                            isClearable 
                            isSearchable
                            value={hotelList ? hotelList.filter(el => el.value == hotelGroupId) : null}
                            placeholder={this.props.intl.formatMessage({ id: "generic.HotelGroup" })}
                            onChange={this.handleChangeComboSelect.bind(this, "hotelGroupId")}
                        />
                    </Col>
                    <Col className="text-right">
                        <Button className="btn btn-host btn-sm" onClick={this.getPNForms}>
                            <i className="icon-icon-search" />
                        </Button>
                    </Col>
                </Row>
                {pnForms.length > 0 ? 
                    <Row>
                        {pnForms.map((form, idx) =>
                            <Col sm={6} key={idx}>
                                <Card className="border-0 shadow p-3 mb-3">
                                    <Row className="align-items-center">
                                        <Col style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                            <div className='d-flex align-items-center'>
                                                <h5 className='d-flex mb-0'> {form.name} </h5>
                                                {form.anonymous ?
                                                    <>
                                                        <i className="fas fa-user-secret ml-2" id={`anonymous${idx}`}/>
                                                        <UncontrolledTooltip target={`anonymous${idx}`} placement="bottom">
                                                            <FormattedMessage id="ProfileNowMenu.Anonymous" />
                                                        </UncontrolledTooltip>
                                                    </>
                                                :''}
                                                {form.createProfile ?
                                                    <>
                                                        <i className="fas fa-user-plus ml-2" id={`createProfile${idx}`}/>
                                                        <UncontrolledTooltip target={`createProfile${idx}`} placement="bottom">
                                                            <FormattedMessage id="ProfileNowMenu.CreatesUsers" />
                                                        </UncontrolledTooltip>
                                                    </>
                                                :''}
                                                {form.type === 'LeadForm' ?
                                                    <>
                                                        <i className="fas fa-bullseye ml-2" id={`LeadForm${idx}`}/>
                                                        <UncontrolledTooltip target={`LeadForm${idx}`} placement="bottom">
                                                            <FormattedMessage id="ProfileNowMenu.LeadForm" />
                                                        </UncontrolledTooltip>
                                                    </>
                                                :''}
                                                {form.type === 'DealSatisfactionForm' ?
                                                    <>
                                                        <i className="fas fa-briefcase ml-2" id={`DealSatisfactionForm${idx}`}/>
                                                        <UncontrolledTooltip target={`DealSatisfactionForm${idx}`} placement="bottom">
                                                            <FormattedMessage id="ProfileNowMenu.DealSatisfaction" />
                                                        </UncontrolledTooltip>
                                                    </>
:''}
                                                <span className='ml-2 d-flex'>
                                                    <ActiveInactiveStatusBadge status={form.active} />
                                                </span>
                                                
                                            </div>
                                            {hotelList.length > 0 ? <h6 className='pt-1 m-0'><FormattedMessage id="ConfigPNForms.GroupLabel" />: {hotelList.find(el => el.value === form.hotelGroupId)?.label}</h6>  : '' }
                                        </Col>
                                        <Col className="text-right col-3">
                                            <Button className="btn-sm btn-host mr-2" onClick={() => this.getFormDetails(form.id, 'previewModal')}>
                                                <i className="icon-icon-preview" />
                                            </Button>
                                            <Button className="btn-sm btn-host mr-2" onClick={() => this.getFormDetails(form.id, 'modal')}>
                                                <i className="far fa-edit" />
                                            </Button>
                                            <Button className="btn-sm btn-host" onClick={() => this.toggleDeleteModal(form.id)}>
                                                <i className="fas fa-trash"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        )}
                    </Row>
                : 
                    <DefaultPage text="generic.SelectSearchCriteria" icon="icon-icon-search" />
                }

                <Modal isOpen={previewModal} fade={false} size={"lg"} style={{ minWidth: '80vw' }}>
                    <Button onClick={() => this.toggleModal('previewModal')} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    <ModalBody>
                        {formDetails?.rawForm && isAValidJson(formDetails.rawForm) ?
                            <SurveyJS
                                formId={formDetails.id}
                            />
                            : <div className="text-center"> <FormattedMessage id="ConfigPNForms.SurveyUnavailable" /> </div>}
                    </ModalBody>
                </Modal>

                {this.state.modal ?
                    <PNFormDetails
                        modal={this.state.modal}
                        toggleModal={() => this.toggleModal('modal')}
                        updateTable={this.updateTable}
                        survey={formDetails}
                        isAValidJson={isAValidJson}
                    />
                : ''}

                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={_ => this.toggleDeleteModal()}
                        actionFunction={() => this.deleteForm(formToDelete)}
                        text={<FormattedMessage id="ConfigPNForms.DeleteFormQuestion" />}
                        block={block}
                    />
                    : ''}
            </StyledCard>
        );
    }
}
export default injectIntl(ConfigPNForms)