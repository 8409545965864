import React, { useEffect, useState } from 'react';
import { UncontrolledTooltip, FormGroup, Col, CustomInput, Label, Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import CustomSelect from '../Base/CustomSelect';



export const ShowGuestNumber = ({ searchableGuestNumber, pmsId, idColor }) => (
    
    searchableGuestNumber ?
        <div>
            <span style={{ fontSize: '12px' }} className="pr-3" id={`GuestNumber-${searchableGuestNumber}`}>
                <i className="icon-icon-profile mr-1 " /> {searchableGuestNumber}
            </span>

            <UncontrolledTooltip placement="bottom" target={`GuestNumber-${searchableGuestNumber}`}>
                <FormattedMessage id="Profile.GuestNumber" />
            </UncontrolledTooltip>
        </div>

        : pmsId ?
            <div>
                <span style={{ fontSize: '12px' }} className={`pr-3 ${idColor}`} id={`PmsId-${pmsId}`}>
                    <i className="icon-icon-profile mr-1 " /> {pmsId}
                </span>

                <UncontrolledTooltip placement="bottom" target={`PmsId-${pmsId}`}>
                    <FormattedMessage id="Profile.InternalId" />
                </UncontrolledTooltip>
            </div>
    : ''
);



//#region Profile Credit
const showAllFields = {
    hasCityLedger: true,
    financeAccount: true,
    paymentDueDays: true,
    creditLimit: true,
    creditLimitType: true,
    creditLimitPOS: true,
    creditLimitTypePOS: true
}

export const ProfileCreditFields = ({ credit, handleSwitch, handleChange, setCombo, creditLimitTypeOptions, showFields = showAllFields }) => {

    return <div>
        {showFields.hasCityLedger || showFields.financeAccount || showFields.paymentDueDays ?
            <div>
                <b>Ledger</b>
                <hr className="mt-1" />

                {showFields.hasCityLedger &&
                    <FormGroup row>
                        <Col className="col-6 col-lg-2">
                            <FormattedMessage id="ProfileDetails.HasCityLedger" />
                        </Col>
                        <Col className="col-6 col-lg-4 text-right text-lg-left">
                            <CustomInput
                                type="switch"
                                id="hasCityLedger"
                                name="hasCityLedger"
                                onChange={handleSwitch}
                                checked={credit.hasCityLedger}
                            />
                        </Col>
                        <Col sm={6} />
                    </FormGroup>
                }
                <FormGroup row className="align-items-center">
                    {showFields.financeAccount &&
                        <>
                            <Label sm={2}>
                                <FormattedMessage id="ProfileDetails.FinanceAccount" />
                            </Label>
                            <Col sm={4}>
                                <Input
                                    type="number"
                                    id="financeAccount"
                                    name="financeAccount"
                                    value={credit.financeAccount}
                                    onChange={handleChange}
                                />
                            </Col>
                        </>
                    }
                    {showFields.paymentDueDays &&
                        <>
                            <Label sm={2}>
                                <FormattedMessage id="ProfileDetails.PaymentDueDays" />
                            </Label>
                            <Col className="col-10 col-lg-3 pr-lg-0">
                                <Input
                                    type="number"
                                    id="paymentDueDays"
                                    name="paymentDueDays"
                                    min="0"
                                    step="1"
                                    value={credit.paymentDueDays}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col className="col-2 col-lg-1 text-right">
                                <FormattedMessage id="ProfileCredit.days" />
                            </Col>
                        </>
                    }
                </FormGroup>
            </div>
        : ''}

        {showFields.creditLimit || showFields.creditLimitType || showFields.creditLimitPOS || showFields.creditLimitTypePOS ?
            <div className="mt-4">
                <b><FormattedMessage id="ProfileDetails.CreditLimit" /></b>
                <hr className="mt-1" />

                <FormGroup row>
                    {showFields.creditLimit &&
                        <>
                            <Label sm={2}>
                                <FormattedMessage id="ProfileDetails.CreditLimit" />
                            </Label>
                            <Col sm={4}>
                                <Input
                                    type="number"
                                    id="creditLimit"
                                    name="creditLimit"
                                    min="0"
                                    step="0.01"
                                    value={credit.creditLimit}
                                    onChange={handleChange}
                                />
                            </Col>
                        </>
                    }
                    {showFields.creditLimitType &&
                        <>
                            <Label sm={2}>
                                <FormattedMessage id="ProfileDetails.Type" />
                            </Label>
                            <Col sm={4}>
                                <CustomSelect
                                    id="creditLimitType"
                                    name="creditLimitType"
                                    options={creditLimitTypeOptions}
                                    value={creditLimitTypeOptions.find(opt => opt.value === credit.creditLimitType) || ''}
                                    onChange={setCombo}
                                    placeholder={""}
                                    required
                                />
                            </Col>
                        </>
                    }
                </FormGroup>
                <FormGroup row>
                    {showFields.creditLimitPOS &&
                        <>
                            <Label sm={2}>
                                <FormattedMessage id="ProfileDetails.CreditLimitPOS" />
                            </Label>
                            <Col sm={4}>
                                <Input
                                    type="number"
                                    id="creditLimitPOS"
                                    name="creditLimitPOS"
                                    min="0"
                                    step="0.01"
                                    value={credit.creditLimitPOS}
                                    onChange={handleChange}
                                />
                            </Col>
                        </>
                    }
                    {showFields.creditLimitTypePOS &&
                        <>
                            <Label sm={2}>
                                <FormattedMessage id="ProfileDetails.TypePOS" />
                            </Label>
                            <Col sm={4}>
                                <CustomSelect
                                    id="creditLimitTypePOS"
                                    name="creditLimitTypePOS"
                                    options={creditLimitTypeOptions}
                                    value={creditLimitTypeOptions.find(opt => opt.value === credit.creditLimitTypePOS)}
                                    onChange={setCombo}
                                    placeholder={""}
                                    required
                                />
                            </Col>
                        </>
                    }
                </FormGroup>
            </div>
        : ''}
    </div>
};

//#endregion