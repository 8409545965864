import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Modal, ModalBody, Input } from 'reactstrap';
import { getPMSReferenceDataType, getReferenceDataType } from '../../Base/ReferenceDataFunctions';
import { handleNotification } from '../../Base/Notification';
import { BlankCard } from '../../Base/CommonUIComponents';
import CreatableSelect from 'react-select/creatable';
import CustomSelect from "../../Base/CustomSelect";
import { postAPI, getAPI } from '../../Base/API';

class AddMapping extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            options: [],
            tagMapping: {},
            codeOptions: [],
            referenceTypes: getReferenceDataType().concat(getPMSReferenceDataType(), [{ value: 'Charges', label: <FormattedMessage id="ReferenceDataFunctions.Revenue" />, tagType: 'ReservationService', endpoint: 'ReferenceData' }])
        };
    }

    componentDidMount() {
        if (this.props.options) {
            let referenceDataType = null;

            const options = this.props.options.map(tag => ({
                value: tag.id,
                label: tag.code,
                parameterized: tag.parameterized,
                parameterOperator: tag.parameterOperator,
                type: tag.type
            }));

            if (this.props.selectedTag) {
                const currentTag = options.find(el => el.value === this.props.selectedTag.tagId);
                const type = this.state.referenceTypes.find(el => el.tagType === currentTag.type);

                if (type) {
                    this.getReferenceData(type.value, type.endpoint);
                }
                else {
                    referenceDataType = currentTag.type;
                }
            }

            this.setState({
                options,
                referenceDataType,
                tagMapping: this.props.selectedTag ? this.props.selectedTag : {}
            });
        }
    }

    getReferenceData = (type, apiName) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                let list = [];

                data.response && data.response.forEach(el => {
                    list.push({ value: el.code, label: <span>{el.description}<b className='ml-1'>({el.code})</b></span>, description: el.description, hotelId: el.hotelId });
                })

                if (this.state.tagMapping.chargeCode && !list.some(el => el.value === this.state.tagMapping.chargeCode)) {
                    list.push({ value: this.state.tagMapping.chargeCode, label: this.state.tagMapping.chargeCode });
                }

                this.setState({ codeOptions: list, block: false, referenceDataType: type })
            }
            else this.setState({ block: false })
        }, `/api/gms/Hotel/v1/${apiName}?type=${type}`);
    }

    saveTagMapping = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="TagMapping.TagMappingSaved" />, <FormattedMessage id="generic.success" />);

                    this.props.updateMapping(data.response[0], false);
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/tag/mapping`, JSON.stringify({ request: this.state.tagMapping }));
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            tagMapping: {
                ...this.state.tagMapping,
                [name]: value
            }
        })
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
            tagMapping: {
                ...this.state.tagMapping,
                [name]: combo ? combo.value : null
            }
        })
    };

    handleChangeHotel = (combo) => {
        this.setState({
            tagMapping: {
                ...this.state.tagMapping,
                hotelId: combo ? combo.value : null,
                chargeCode: null
            }
        })
    };

    handleChangeTag = (combo) => {
        this.setState({
            tagMapping: {
                ...this.state.tagMapping,
                tagId: combo ? combo.value : null,
                codeOptions: [],
                referenceDataType: null
            }
        }, () => {
            if (combo && combo.parameterized === false) {
                const type = this.state.referenceTypes.find(el => el.tagType === combo.type);

                if (type) {
                    this.getReferenceData(type.value, type.endpoint);
                }
                else {
                    this.setState({ referenceDataType: combo.type });
                }
            }
        })
    };

    handleCreate = (label) => {
        const { codeOptions } = this.state;

        codeOptions.push({ value: label, label: label });

        this.setState({
            codeOptions,
            tagMapping: {
                ...this.state.tagMapping,
                chargeCode: label
            }
        });
    };

    getChargeCode = (chargeCode) => {
        if (chargeCode) {
            const code = this.state.codeOptions.find(el => el.value === this.state.tagMapping.chargeCode &&
                (this.state.tagMapping.hotelId ? el.hotelId === this.state.tagMapping.hotelId : true)
            );

            return code || { value: this.state.tagMapping.chargeCode, label: this.state.tagMapping.chargeCode }
        }

        return null;
    }
    
    render() {
        const { modal, toggleModal, hotels } = this.props;

        const selectedTag = this.state.tagMapping.tagId && this.state.options.find(c => c.value === this.state.tagMapping.tagId);

        const chargeCode = this.getChargeCode(this.state.tagMapping.chargeCode);


        return (
            <Modal isOpen={modal} fade={false} size={"md"} style={{ minWidth: 0 }} >
                <Button onClick={toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={this.state.block} error={this.state.error}>
                        <Form onSubmit={this.saveTagMapping}>
                            <Row className="mb-3">
                                <Col>
                                    <h5>{this.state.tagMapping.id ? <FormattedMessage id="TagMapping.EditMapping" /> : <FormattedMessage id="TagMapping.AddMapping" />}</h5>
                                </Col>
                                <Col className="text-right col-3">
                                    {this.state.tagMapping.id ?
                                        <Button className="btn-sm btn-host mr-2" onClick={(e) => this.props.removeTagMapping(e, this.state.tagMapping, true)}>
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                        : ''}
                                    <Button className="btn-sm btn-host" type="submit">
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}> <FormattedMessage id="TagMapping.Tag" /></Label>
                                        <Col sm={8} >
                                            <CustomSelect
                                                options={this.state.options}
                                                required
                                                isClearable
                                                isSearchable
                                                isDisabled={this.state.tagMapping.id ? true : false}
                                                placeholder={<FormattedMessage id="TagMapping.Tag" />}
                                                onChange={(e) => this.handleChangeTag(e)}
                                                value={selectedTag || ''}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row className="pt-2">
                                        <Label sm={4}> <FormattedMessage id="generic.Hotel" /> </Label>
                                        <Col sm={8}>
                                            <CustomSelect
                                                options={hotels}
                                                name='hotelId'
                                                onChange={(e) => this.handleChangeHotel(e, "hotelId")}
                                                isClearable isSearchable
                                                required
                                                placeholder={<FormattedMessage id="generic.Hotel" />}
                                                value={this.state.tagMapping.hotelId ? hotels.find(c => c.value === this.state.tagMapping.hotelId) : ''}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>

                            {selectedTag ?
                                selectedTag.parameterized ?
                                    <FormGroup row className="align-items-center">
                                        <Label sm={4}> <FormattedMessage id={`ReferenceDataFunctions.${selectedTag.parameterOperator}`} /> </Label>
                                        <Col sm={8}>
                                            <Input
                                                type="number"
                                                name="parameterValue"
                                                required
                                                onChange={(e) => this.handleChange(e, 'parameterValue')}
                                                value={this.state.tagMapping.parameterValue || ''}
                                                placeholder={this.props.intl.formatMessage({ id: "TagMapping.Days" })}
                                            />
                                        </Col>
                                    </FormGroup>
                                    : this.state.tagMapping.hotelId ?
                                        <>
                                            <Row className="align-items-center">
                                                <Label sm={4}>
                                                    <FormattedMessage id="TagMapping.ChargeCode" />
                                                    {this.state.referenceDataType ?
                                                        <span className="text-muted font_size_xxs">
                                                            <br/> <FormattedMessage id={`TagsList.${this.state.referenceDataType}`} />
                                                        </span>
                                                    : ''}
                                                </Label>
                                                <Col sm={8}>
                                                    <CreatableSelect
                                                        isClearable
                                                        options={this.state.codeOptions.filter(el => el.hotelId === this.state.tagMapping.hotelId || !el.hotelId)}
                                                        onChange={(e) => this.handleChangeSelect(e, 'chargeCode')}
                                                        onCreateOption={this.handleCreate}
                                                        formatCreateLabel={userInput => <span><FormattedMessage id="generic.Create" /> "{userInput}"</span>}
                                                        placeholder={<FormattedMessage id="TagMapping.ChargeCode" />}
                                                        required
                                                        value={chargeCode || ''}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={4} />
                                                <Col className="ml-3 font_size_xxs">
                                                    {chargeCode && chargeCode.description && chargeCode.value.toLowerCase() !== chargeCode.description.toLowerCase() ?
                                                        <span> {chargeCode.description} </span>
                                                    : ''}
                                                </Col>
                                            </Row>
                                        </>
                                    : ''
                            : ''}
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(AddMapping)