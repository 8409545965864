import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { getAPI, postAPI } from "../Base/API";
import { StyledCard } from "../Base/CommonUIComponents";
import { CustomTable } from '../Base/CustomTable';
import { CheckAuthorization } from '../Base/Authorization';
import Authorization from '../Base/Authorization';

export class InquiryList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            hotelList: [],
            hotel: null
        };
    }

    componentDidMount() {
        
    }

   



    render() {
        const { block, error, hotelList, currentPage, resultsPerPage, hotel } = this.state;
       
        return (

            <StyledCard block={block} error={error} >
               
                <Row className="mb-2">
                    <Col>
                            <h5 ><i className="fas fa-list-alt mr-2"></i>Inquiry</h5>
                    </Col>
                    <Col className="text-right">
                        <Button className="btn btn-host btn-sm">
                            <i className="fas fa-plus"></i>
                        </Button>

                    </Col>
                </Row>

                <Card className="border-0 py-3">
                   
                <Row>
                            <Col>
                                <h5><b>Satisfation Enquiry</b> </h5>
                                <div><b>Send:</b> 1 day after departure</div>
                            </Col>
                            <Col>
                                <b>Hotel:</b> Hotel da praça
                            </Col>
                    <Col>
                        <div><b>Room:</b> xxxx</div>
                                <div><b>Rate:</b>: xxx</div>
                                <div><b>Channel:</b>: xxx</div>
                            </Col>
                            <Col className="text-right">
                               
                                <Button className="btn btn-host btn-sm mr-2">
                                    <i className="icon-icon-preview"></i>
                                    </Button>

                                    <Button className="btn btn-host btn-sm mr-2">
                                        <i className="far fa-edit"></i>
                                    </Button>
                                
                                    <Button className="btn btn-host btn-sm">
                                    <i className="icon-icon-statistics-campaigns"></i>
                                    </Button>
                                  
                                </Col>
                        </Row>
                    </Card>
            </StyledCard>
        );
    }
}
export default injectIntl(InquiryList)
