import React, { Component } from 'react';
import { Col, Row, Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { SingleDatePicker } from "react-dates";
import moment from 'moment-timezone';
import CustomSelect from "../../Base/CustomSelect";
import { handleNotification } from '../../Base/Notification';
import { getAPI } from '../../Base/API';

export class VoucherConfiguration extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            vouchers:[]
        };
    }

    componentDidMount() {
        this.getVoucherTypes();
    }

    getVoucherTypes = () => {
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false }));
                    return;
                }
                else {
                    const activeVouchers = data.response
                        .filter(config => config.active);

                    const cash = activeVouchers
                        .filter(config => config.isPaymentMode)
                        .map(config => {
                            const option = {
                                value: config.id,
                                label: config.code,
                                voucherEndDate: moment().add(config.vouchersValidUntil, 'month'),
                                voucherAmount: config.payedAmount
                            }
                            return option;
                        });
                    const rebates = activeVouchers
                        .filter(config => !config.isPaymentMode)
                        .map(config => {
                            const option = {
                                value: config.id,
                                label: config.code,
                                voucherEndDate: moment().add(config.vouchersValidUntil, 'month'),
                                voucherAmount: config.payedAmount
                            }
                            return option;
                        });

                    const vouchers = [
                        {
                            label: <div className="d-flex justify-content-between" > <span><FormattedMessage id="AvailableCards.TypeCash" /></span> <span className="px-2" style={{ backgroundColor: '#EBECF0', borderRadius: '20px' }}>{cash.length}</span> </div >,
                            options: cash
                        },
                        {
                            label: <div className="d-flex justify-content-between" > <span><FormattedMessage id="AvailableCards.TypeRebates" /></span> <span className="px-2" style={{ backgroundColor: '#EBECF0', borderRadius: '20px' }}>{rebates.length}</span> </div >,
                            options: rebates
                        },
                    ];

                    this.setState({ block: false, vouchers });
                }
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/Voucher/V1/Config`);
    }

    render() {
        const { alert, handleChange, handleVoucherChange, handleVoucherEndDate } = this.props;
        const { block, vouchers } = this.state;

        return (
            <div>
                <Row className="mt-4">
                    <Col className='col-3'>
                        <h5><FormattedMessage id="MarketingAlert.Voucher" /></h5>
                    </Col>
                    <Col className='col-3'>
                        <h5><FormattedMessage id="MarketingAlert.VoucherEndDate" /></h5>
                    </Col>
                    <Col className='col-3'>
                        <h5><FormattedMessage id="MarketingAlert.VoucherAmount" /></h5>
                    </Col>
                    <Col className='col-3'>
                        <h5><FormattedMessage id="MarketingAlert.VoucherIssueCode" /></h5>
                    </Col>
                </Row>
                <Row className="px-2">
                    <Col className="col-3">
                        <CustomSelect
                            options={vouchers}
                            value={vouchers.flatMap(vch => vch.options).find(vch => vch.value === alert.voucherTypeId)}
                            isClearable={true}
                            placeholder={''}
                            isLoading={block}
                            onChange={handleVoucherChange}
                        />
                    </Col>
                    <Col className="col-3">
                        <SingleDatePicker
                            id="VoucherEndDate"
                            isOutsideRange={day => day <= moment()}
                            date={alert.voucherEndDate ? moment(alert.voucherEndDate) : null}
                            focused={this.state.focused}
                            onFocusChange={({ focused }) => this.setState({ focused })}
                            small={true}
                            numberOfMonths={1}
                            onDateChange={date => handleVoucherEndDate(date, 'voucherEndDate')}
                            showDefaultInputIcon={true}
                        />
                    </Col>
                    <Col className="col-3">
                        <Input type="number" name="voucherAmount" min="0" onChange={(e) => handleChange(e)} value={alert.voucherAmount} />
                    </Col>
                    <Col className="col-3">
                        <Input type="text" name="voucherIssueCode" maxLength="50" onChange={(e) => handleChange(e)} value={alert.voucherIssueCode ? alert.voucherIssueCode : ''} />
                    </Col>
                </Row>
            </div>
        );
    }
}