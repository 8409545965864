import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Collapse, Card, CardBody } from 'reactstrap';

class JourneyBuilderSideBar extends Component {
    constructor(props) {
        super(props);
        this.onDragStart = this.onDragStart.bind(this);
        this.state = {
            isEventSourcesOpen: true,
            isActionsOpen: true,
            isConditionsOpen: true,
            hasEgoi: false,
            hasSendGrid: false,
            hasTwilio: false
        };
    }
    
    componentDidMount() {
        window.setTimeout(() => {
            const globalModules = global.modules || [];
            const hasModule = (name) => globalModules.some(m => m === name);
    
            const hasEgoi = hasModule('Egoi');
            const hasSendGrid = hasModule('SendGrid');
            const hasTwilio = hasModule('TwilioMessaging');
    
            this.setState({ hasEgoi, hasSendGrid, hasTwilio });
        }, global.modules ? 0 : 2500);
    }

    onDragStart(event, nodeType) {
        if(event && event.dataTransfer){
            event.dataTransfer.effectAllowed = 'move';
            event.dataTransfer.setData('application/reactflow', nodeType);
        }else{
            return;
        }
    }

    toggleCollapse = (type) => {
        this.setState(prevState => ({ [type]: !prevState[type] }));
    }

    render() {
        const { isEventSourcesOpen, isActionsOpen, isConditionsOpen } = this.state;
        
        return (
            <Card className='h-100'>
                <CardBody className='py-0'>
                    <div className='d-flex flex-column w-100 my-2'>
                        <div className="d-flex align-items-center justify-content-between descriptionEventSources cursor-pointer mb-2 mt-3 w-100" onClick={() => this.toggleCollapse('isEventSourcesOpen')}>
                            <FormattedMessage id="CustomerJourney.EventSources" />
                            <i className={`fas fa-chevron-${isEventSourcesOpen ? 'up' : 'down'} mx-2`}></i>
                        </div>
                        <Collapse isOpen={isEventSourcesOpen}>
                            <div className='d-flex flex-wrap'>
                                <div className='d-flex flex-column align-items-center'>
                                    <div className="d-flex align-items-center justify-content-center m-3 cursor-pointer" style={{ width: '50px', height: '50px', borderRadius: '100%', backgroundColor: 'lightgreen' }} onDragStart={(event) => this.onDragStart(event, 'input-event')} draggable>
                                        <i className="fas fa-sitemap" style={{ color: 'white'}}></i>
                                    </div>
                                    <div className='text-center text-wrap' style={{ maxWidth: '90%', fontSize: '10px' }}>
                                        <FormattedMessage id="CustomerJourney.EventSource" />
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                    <div className='d-flex flex-column w-100 my-2'>
                        <div className="d-flex align-items-center justify-content-between descriptionEventSources cursor-pointer mb-2 mt-3 w-100" onClick={() => this.toggleCollapse('isActionsOpen')}>
                            <FormattedMessage id="CustomerJourney.Actions" />
                            <i className={`fas fa-chevron-${isActionsOpen ? 'up' : 'down'} mx-2`}></i>
                        </div>
                        <Collapse isOpen={isActionsOpen}>
                            <div className='d-flex flex-wrap'>
                                {this.state.hasEgoi || this.state.hasTwilio ? 
                                    <div className='d-flex flex-column align-items-center'>
                                        <div className="d-flex align-items-center justify-content-center m-3 cursor-pointer" style={{ width: '50px', height: '50px', borderRadius: '20%', backgroundColor: 'lightseagreen' }} onDragStart={(event) => this.onDragStart(event, 'default-sms')} draggable>
                                            <i className="fas fa-comments" style={{ color: 'white'}}></i>
                                        </div>
                                        <div className='text-center text-wrap' style={{ maxWidth: '90%', fontSize: '10px' }}>
                                            <FormattedMessage id="CustomerJourney.smsElement" />
                                        </div>
                                    </div>
                                : ''}
                                {this.state.hasEgoi || this.state.hasSendGrid ?
                                    <div className='d-flex flex-column align-items-center'>
                                        <div className="d-flex align-items-center justify-content-center m-3 cursor-pointer" style={{ width: '50px', height: '50px', borderRadius: '20%', backgroundColor: 'lightseagreen' }} onDragStart={(event) => this.onDragStart(event, 'default-email')} draggable>
                                            <i className="fas fa-envelope-open-text" style={{ color: 'white'}}></i>
                                        </div>
                                        <div className='text-center text-wrap' style={{ maxWidth: '90%', fontSize: '10px' }}>
                                            <FormattedMessage id="CustomerJourney.emailElement" />
                                        </div>
                                    </div>
                                : ''}
                                {/* <div className='d-flex flex-column align-items-center'>
                                    <div className="d-flex align-items-center justify-content-center m-3 cursor-pointer" style={{ width: '50px', height: '50px', borderRadius: '20%', backgroundColor: 'lightseagreen' }} onDragStart={(event) => this.onDragStart(event, 'default-whatsApp')} draggable>
                                        <i className="fab fa-whatsapp"></i>
                                    </div>
                                    <div className='text-center text-wrap' style={{ maxWidth: '90%', fontSize: '10px' }}>
                                        <FormattedMessage id="CustomerJourney.WhatsAppElement" />
                                    </div>
                                </div> */}
                            </div>
                        </Collapse>
                    </div>
                    <div className='d-flex flex-column w-100 my-2'>
                        <div className="d-flex align-items-center justify-content-between descriptionEventSources cursor-pointer mb-2 mt-3 w-100" onClick={() => this.toggleCollapse('isConditionsOpen')}>
                            <FormattedMessage id="CustomerJourney.Conditions" />
                            <i className={`fas fa-chevron-${isConditionsOpen ? 'up' : 'down'} mx-2`}></i>
                        </div>
                        <Collapse isOpen={isConditionsOpen}>
                            <div className='d-flex flex-wrap'>
                                <div className='d-flex flex-column align-items-center'>
                                    <div className="d-flex align-items-center justify-content-center m-3 cursor-pointer" style={{ width: '50px', height: '50px', borderRadius: '8%', backgroundColor: 'orange', transform: 'rotate(45deg)' }} onDragStart={(event) => this.onDragStart(event, 'default-condition-waitUntil')} draggable>
                                        <i className="far fa-clock" style={{ transform: 'rotate(-45deg)', color: 'white' }}></i>
                                    </div>
                                    <div className='text-center text-wrap' style={{ maxWidth: '90%', fontSize: '10px' }}>
                                        <FormattedMessage id="CustomerJourney.waitUntilElement" />
                                    </div>
                                </div>
                                <div className='d-flex flex-column align-items-center'>
                                    <div className="d-flex align-items-center justify-content-center m-3 cursor-pointer" style={{ width: '50px', height: '50px', borderRadius: '8%', backgroundColor: 'orange', transform: 'rotate(45deg)' }} onDragStart={(event) => this.onDragStart(event, 'default-condition-waitDuration')} draggable>
                                        <i className="fas fa-stopwatch" style={{ transform: 'rotate(-45deg)', color: 'white' }}></i>
                                    </div>
                                    <div className='text-center text-wrap' style={{ maxWidth: '90%', fontSize: '10px' }}>
                                        <FormattedMessage id="CustomerJourney.waitDurationElement" />
                                    </div>
                                </div>
                                <div className='d-flex flex-column align-items-center'>
                                    <div className="d-flex align-items-center justify-content-center m-3 cursor-pointer" style={{ width: '50px', height: '50px', borderRadius: '8%', backgroundColor: 'orange', transform: 'rotate(45deg)' }} onDragStart={(event) => this.onDragStart(event, 'default-condition-randomSplit')} draggable>
                                        <i className="fas fa-random" style={{ transform: 'rotate(-45deg)', color: 'white' }}></i>
                                    </div>
                                    <div className='text-center text-wrap' style={{ maxWidth: '90%', fontSize: '10px' }}>
                                        <FormattedMessage id="CustomerJourney.randomSplitElement" />
                                    </div>
                                </div>
                                <div className='d-flex flex-column align-items-center'>
                                    <div className="d-flex align-items-center justify-content-center m-3 cursor-pointer" style={{ width: '50px', height: '50px', borderRadius: '8%', backgroundColor: 'orange', transform: 'rotate(45deg)' }} onDragStart={(event) => this.onDragStart(event, 'default-condition-decisionSplit')} draggable>
                                        <i className="fas fa-divide" style={{ transform: 'rotate(-45deg)', color: 'white' }}></i>
                                    </div>
                                    <div className='text-center text-wrap' style={{ maxWidth: '90%', fontSize: '10px' }}>
                                        <FormattedMessage id="CustomerJourney.decisionSplitElement" />
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

export default injectIntl(JourneyBuilderSideBar);