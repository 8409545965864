import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, FormGroup, Label, Row, Button } from 'reactstrap'
import { SelectHotel, StyledCard } from '../../../Base/CommonUIComponents';
import { CustomTable } from '../../../Base/CustomTable';
import moment from 'moment';
import { postAPI } from '../../../Base/API';
import { handleNotification } from '../../../Base/Notification';
import { errorHandler } from '../../../Marketing/CommunicationJourney/CommonFunctions';


class DeleteProfiles extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            profiles: [],
            hotelId: null
        };
    }

    componentDidMount() {
    }

    deleteProfiles = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const body = {
            pmsProfiles: [{
                hotelId: this.state.hotelId,
                pmsProfileId: this.state.profiles
            }]
        }

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    this.setState({ profiles: data.response[0].pmsProfiles[0].pmsProfileId })
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/Profile/Remove`, JSON.stringify({ request: body }));
    }

    transformCSV = (CSV) => {
        var reader = new FileReader();

        reader.readAsText(CSV);


        reader.onload = (e) => {
            var allTextLines = e.target.result.split(/\r\n|\n/);
            var lines = allTextLines.map(data => data.split(/,|;/));
            var list = [];

            lines.forEach((line, idx) => {
                if (idx !== 0 && line[0]) {
                    list.push({ pmsId: line[0] });
                }
            })

            this.setState({
                profiles: list,
                error: null
            });
        }
    }

    isValidDate = (string) => {
        let isValid = false;
        const dateValues = string.includes('-') ? string.split('-') : string.split('/');

        if (dateValues.length === 3 && dateValues[0].length === 4 && dateValues[1].length === 2 && dateValues[2].length === 2) {
            const month = parseInt(dateValues[1]);
            const day = parseInt(dateValues[2]);
            const daysInMonth = moment(`${dateValues[0]}-${month}`, "YYYY-MM").daysInMonth();

            if (month > 0 && month < 12 && day > 0 && day <= daysInMonth) {
                isValid = true;
            }
        }

        return isValid;
    }

    validateBoolean = (field, object, fieldName) => {
        const booleanOptions = ["true", "false"];

        if (booleanOptions.find(bolOpt => bolOpt === field.toLowerCase()) === undefined) {
            object.MissingFields.push(fieldName)
            object.Status = false;
        }
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    handleFiles = (event) => {
        var files = event.target.files;

        if (window.FileReader && files[0].size < 2097152) {
            this.transformCSV(files[0]);
        }
        else {
            errorHandler("FileReader is not supported in this browser.")
        }
    };

    fileChanged = (e) => {
        e.target.value = null;
    };

    getResult = (data) => {
        if (data === 'Not found') {
            return <span>
                <i className="fas fa-times-circle text-danger mr-2" />
                <FormattedMessage id="DeleteProfiles.NotFound" />
            </span>
        }
        else if (data === 'Removed with master') {
            return <span>
                <i className="fas fa-users-slash text-success mr-2" />
                <FormattedMessage id="DeleteProfiles.RemovedWithMaster" />
            </span>
        }
        else if (data === 'Solo removed') {
            return <span>
                <i className="fas fa-user-times text-success mr-2" />
                <FormattedMessage id="DeleteProfiles.SoloRemoved" />
            </span>
        }
        else {
            return <span className="ml-4">
                <FormattedMessage id="DeleteProfiles.SoloRemoved" />
            </span>
        }
    }

    render() {
        const columnsCSV = [
            {
                dataField: 'pmsId',
                text: "PMS ID",
                sort: true
            },
            {
                dataField: 'result',
                text: this.props.intl.formatMessage({ id: "DeleteProfiles.Result" }),
                sort: true,
                hidden: !(this.state.profiles?.length > 0 && this.state.profiles[0].result),
                formatter: (cell, row, idx) => this.getResult(cell),
                style: { width: '50%' }
            }
        ];

        return (
            <StyledCard block={this.state.block} error={this.state.error}>
                <Row>
                    <Col>
                        <h5><i className="fas fa-user-times mr-2" /><FormattedMessage id="DeleteProfiles.Title" /></h5>
                    </Col>
                </Row>

                <FormGroup>
                    <Row className="align-items-end">
                        <Col className='col-4'>
                            <Label className='px-0' sm={4}> <FormattedMessage id="generic.Hotel" /></Label>
                            <SelectHotel
                                className='shadow-none'
                                name={'hotelId'}
                                onChangeFunc={this.handleChangeSelect}
                                placeholder={<FormattedMessage id="generic.Hotel" />}
                                value={this.state.hotelId || ''}
                            />
                        </Col>
                        <Col className='col-4'>
                            <Label className='px-0' sm={4}> <FormattedMessage id="Profile.ImportFile" /></Label>
                            <div className="custom-file">
                                <input
                                    type="file"
                                    className="custom-file-input"
                                    id="customFile"
                                    disabled={this.state.hotelId ? false : true}
                                    onChange={(e) => this.handleFiles(e)}
                                    accept=".csv"
                                    required
                                    onClick={(e) => this.fileChanged(e)}
                                />
                                <label className="custom-file-label" htmlFor="customFile">
                                    <FormattedMessage id="generic.ChooseFile" />
                                </label>
                            </div>
                        </Col>
                        <Col className="text-right">
                            <Button className="btn btn-sm btn-host mb-1" onClick={this.deleteProfiles} disabled={!this.state.hotelId || this.state.profiles.length < 1}>
                                <i className="fas fa-save"/>
                            </Button>
                        </Col>
                    </Row>
                </FormGroup>

                <div>
                    <Row>
                        <Col className="col-12">
                            <CustomTable
                                keyField='pmsId'
                                data={this.state.profiles}
                                columns={columnsCSV}
                                totalSize={this.state.profiles.length}
                                shadow={false}
                                showTotal={this.state.profiles.length > 0}
                                search={this.state.profiles.length > 0}
                                hideSizePerPage={this.state.Profiles && this.state.profiles.length > 10 ? false : true}
                                defaultSorted={[{
                                    dataField: 'Status',
                                    order: 'asc'
                                }]}
                            />
                        </Col>
                    </Row>
                </div>
            </StyledCard>
        )
    }
}

export default injectIntl(DeleteProfiles)