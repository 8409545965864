import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Col, Row, Breadcrumb, BreadcrumbItem, UncontrolledTooltip } from 'reactstrap';
import { Pagination, TotalResults } from '../../Base/PaginationComponents';
import { getAPI } from "../../Base/API";
import { handleNotification } from "../../Base/Notification";
import { StyledCard } from "../../Base/CommonUIComponents";
import CustomSelect from '../../Base/CustomSelect';
import { DateRangePicker } from 'react-dates';
import moment from "moment";

import Authorization from '../../Base/Authorization';
import { FormAnswers } from '../../Marketing/Common/MarketingFunctions';

export class reviewProInquiry extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            modal: false,
            inquiryList: [],
            datei: moment().add(-7,"days"),
            datef: moment(),
            inquiry: null,
            lodgings: [],
            flagged: 'ALL',
            offset: 0,
            limit: 9,
            totalItems: 0,
            surveyName: null
        };
    }

    componentDidMount() {
        this.getProducts();

        if (this.props.history.location && this.props.history.location.state && this.props.history.location.state.surveyName) {
            this.setState({ surveyName: this.props.history.location.state.surveyName });
        }
        else {
            this.getReviwProForms()
        }
    }

    getReviwProForms = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                const surveyName = data.response && data.response.find(el => el.id === this.props.match.params.id);

                this.setState({ surveyName: surveyName && surveyName.name });
            }
            this.setState({ error: errorMessage });
        }, '/api/gms/ReviewPro/v1/Surveys');
    }

    getReviewProForms = () => {
        this.setState({ block: true });
        var params = this.state.pid ? `&pid=${this.state.pid}` : '';
        params += this.state.offset ? `&offset=${this.state.offset}` : '';
        params += this.state.limit ? `&limit=${this.state.limit}` : '';

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({
                    inquiryList: data.response ? data.response[0] : [],
                    totalItems: data.response ? data.response[0].total : 0
                });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/ReviewPro/v1/Surveys/${this.props.match.params.id}?fromDate=${moment(this.state.datei).format('YYYY-MM-DD')}&toDate=${moment(this.state.datef).format('YYYY-MM-DD')}&flagged=${this.state.flagged}&onlyPublished=false&dateField=RESPONSE_DATE` + params);
    }

    getProducts = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    var list = [];

                    data.response.forEach(el => { list.push({ label: el.name, value: el.id }) });

                    this.setState({ lodgings: list });
                }
            }
            this.setState({ error: errorMessage }, () => this.getReviewProForms());
        }, '/api/gms/ReviewPro/v1/Lodgings');
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    };

    handlePagination = (value) => {
        this.setState({
            block: true,
            offset: this.state.offset + value
        }, () => this.getReviewProForms());
    }

    render() {
        const { block, error, totalItems, offset, limit, surveyName, lodgings } = this.state;

        const flagged = [
            { value: 'NOT_FLAGGED', label: 'Not Flagged' },
            { value: 'ONLY_FLAGGED', label: 'Only Flagged' },
            { value: 'ALL', label: 'All' },
        ]
        
        return (
            <StyledCard block={block} error={error} >
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/ReviewProMenu"> ReviewPro Config</Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to="/reviewProConfig"> Surveys </Link></BreadcrumbItem>
                        <BreadcrumbItem active> {surveyName} </BreadcrumbItem>
                    </Breadcrumb>
                </div>

                <Row>
                    <Col>
                        <h5>
                            <i className="icon-icon-hotel mr-2"></i> <FormattedMessage id="reviewProConfig.title" />
                        </h5>
                    </Col>

                    <Authorization
                        perform="reviewProInquiry:map"
                        yes={() => (
                            <Col className="text-right mb-3">
                                <Button className="btn- btn-host btn-small btn-sm" id="mapForm" tag={Link} to={{ pathname: '/ReviewProMapForm', search: `?form=${this.props.match.params.id}`, state: { surveyName } }}>
                                    <i className="fas fa-random" />
                                </Button>
                                <UncontrolledTooltip placement="bottom" target="mapForm">
                                    <FormattedMessage id="MapFormModal.Map" />
                                </UncontrolledTooltip>
                            </Col>
                        )}
                        no={() => <div></div>}
                    />

                </Row>

                <Row className="mb-3">
                    <Col sm={3}>
                        <CustomSelect
                            isSearchable
                            placeholder={<FormattedMessage id="generic.Product" />}
                            options={this.state.lodgings}
                            onChange={(e) => this.handleChangeSelect(e, "pid")}
                            isClearable
                        />
                    </Col>
                    <Col sm={3} className="px-1">
                        <CustomSelect
                            isSearchable
                            placeholder={""}
                            options={flagged}
                            onChange={(e) => this.handleChangeSelect(e, "flagged")}
                            value={flagged.find(el => el.value === this.state.flagged)}
                        />
                    </Col>
                    <Col sm={3}>
                        <DateRangePicker
                            startDate={this.state.datei ? moment(this.state.datei) : null}
                            startDateId="startDate"
                            isOutsideRange={day => day > moment()}
                            endDate={this.state.datef ? moment(this.state.datef) : null}
                            endDateId="endDate"
                            onDatesChange={({ startDate, endDate }) => this.setState({ datei: startDate, datef: endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                            required
                        />
                    </Col>
                    <Col className="text-right">
                        <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.getReviewProForms()}>
                            <i className="icon-icon-search" />
                        </Button>
                    </Col>
                </Row>

                <Row>
                    {this.state.inquiryList && this.state.inquiryList.responses && this.state.inquiryList.responses.map((el, k) => {
                        const date = moment.unix(el.date / 1000).format("YYYY-MM-DD");
                        const product = lodgings.find(l => l.value === el.productId);

                        return <Col className="col-4 mb-4" key={k}>
                            <FormAnswers answers={el.answers}>
                                <Row className="mb-2">
                                    <Col> <b>{product && product.label || '-'}</b> </Col>
                                    <Col className="text-right col-3"> {date} </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h5 className="text-host"> {el.guest.firstName !== 'Anonymous' ? <span> {el.guest.firstName} {el.guest.lastName} </span> : '-'}</h5>
                                    </Col>
                                    <Col className="text-right col-2 pl-0">{el.guest.language}</Col>
                                </Row>

                                <hr />

                                {el.guest.email &&
                                    <>
                                        <div className="mb-1 text-muted">Email </div>
                                        <div className="mb-3 pl-2"> <b> {el.guest.email}</b> </div>
                                    </>
                                }

                                {el.guest.company &&
                                    <>
                                        <div className="mb-1 text-muted"> Company </div>
                                        <div className="mb-3 pl-2"><b> {el.guest.company} </b></div>
                                    </>
                                }
                            </FormAnswers>
                        </Col>
                    })}
                </Row>


                {totalItems > 0 ?
                    <Row className="mb-4">
                        <Col>
                            <span className="ml-2">
                                <TotalResults
                                    start={offset + 1}
                                    end={offset ? (offset + limit) > totalItems ? totalItems : offset + limit : limit}
                                    total={totalItems}
                                />
                            </span>
                        </Col>

                        <Col className="text-right">
                            <Pagination
                                isPrevDisabled={offset === 0}
                                isNextDisabled={totalItems <= (offset + limit)}
                                currentPage={(offset / limit) + 1}
                                handlePrevButton={() => this.handlePagination(-limit)}
                                handleNextButton={() => this.handlePagination(limit)}
                            />
                        </Col>
                    </Row>
                : <div className="text-center mt-4"> <FormattedMessage id="FormList.ThereAreNoSubmittedForms" /> </div>}
            </StyledCard>
        );
    }
}
export default injectIntl(reviewProInquiry)
