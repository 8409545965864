import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Col, Row, Modal, ModalBody, Label, FormGroup, Input, Form, CustomInput, ModalHeader, UncontrolledTooltip } from "reactstrap";
import { BlankCard, PrettyTitle } from "../../Base/CommonUIComponents";
import CustomSelect from "../../Base/CustomSelect";
import { SingleDatePicker } from "react-dates";
import moment from 'moment';
import { CheckAuthorization } from "../../Base/Authorization";
import { getAPI } from "../../Base/API";

class ConfigModal extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: true,
            modalData: this.props.modalData ? this.props.modalData : {},
            calculateFrom: [
                { value: 'FixedDate', label: this.props.intl.formatMessage({ id: "ConfigCardPoints.FixedDate" }) },
                { value: 'CreationDate', label: this.props.intl.formatMessage({ id: "ConfigCardPoints.CreationDate" }) }
            ],
            profileType: this.props.profileType,
            activeAccrual: false
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        const { modalData } = this.state;
        if(!isNaN(modalData?.accrualNightPoints) || !isNaN(modalData?.accrualResPoints) || !isNaN(modalData?.accrualRevenuePoints))
            this.setActiveAccrual();
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        const el = document.getElementById(name);
        
        if(el){
            el.setCustomValidity('');
        }

        this.setState(prevState => ({
            modalData: {
                ...prevState.modalData,
                [name]: parseInt(value)
            }
        }));
    }

    handleSelect = (combo, name) => {
        const { profileType, modalData } = this.state;

        if(name === "profileType" && modalData?.profileType){
            profileType.find(({value}) => value === modalData?.profileType).disabled = false;
            profileType.find(({value}) => value === combo?.value).disabled = true;
        }

        this.setState(prevState => ({
            modalData: {
                ...prevState.modalData,
                [name]: combo ? combo.value : null
            }
        }));
    }

    setActiveAccrual = () => {
        this.setState({ activeAccrual: !this.state.activeAccrual })
    }

    render() {
        const { modal, addConfig, toggleModal, intl, loyaltyCardTypes } = this.props;
        const { modalData, profileType, activeAccrual } = this.state;
        const isAdmin = CheckAuthorization("adminOperation:view", null);

        const cardValidityOptions = [
            { label: intl.formatMessage({ id: "ConfigCardPoints.1Years" }), value: 1 },
            { label: intl.formatMessage({ id: "ConfigCardPoints.2Years" }), value: 2 },
            { label: intl.formatMessage({ id: "ConfigCardPoints.3Years" }), value: 3 },
            { label: intl.formatMessage({ id: "ConfigCardPoints.4Years" }), value: 4 },
            { label: intl.formatMessage({ id: "ConfigCardPoints.5Years" }), value: 5 },
        ];

        return (
            <Modal isOpen={modal} fade={false} size={"xl"} toggle={toggleModal} style={{ minWidth: 0 }} >
                <Button type="button" onClick={toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard>
                        <Form onSubmit={(e) => addConfig(e, this.state.modalData)}>
                            <Row className="mb-1">
                                <Col className="d-flex">
                                    <h5><FormattedMessage id="ConfigCardPoints.ProgramConfig" /></h5>
                                    <CustomInput
                                        type="switch"
                                        id="1"
                                        name="active"
                                        className="ml-2"
                                        onChange={(e) => { if (e.target) this.setState({ modalData: { ...this.state.modalData, active: e.target.checked } }) } }
                                        checked={modalData.active ? modalData.active : false}
                                    />
                                </Col>
                                <Col className="text-right col-3">
                                    <Button className="btn-sm btn-host" type="submit">
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6 mt-2">
                                    <Label>
                                        <FormattedMessage id="ConfigCardPoints.ProfileType"/>
                                    </Label>
                                    <CustomSelect
                                        isSearchable={false}
                                        placeholder={""}
                                        isDisabled={!isAdmin}
                                        options={profileType}
                                        onChange={(e) => this.handleSelect(e, 'profileType')}
                                        value={modalData.profileType ? profileType.find(el => el.value === modalData.profileType) : profileType.find(el => el.value === "Person")}
                                    />
                                </Col>
                                {global.modules && global.modules.some(m => m === 'OneCardByType') ?
                                    <Col className="col-6 mt-2">
                                        <Label>
                                            <FormattedMessage id="ConfigCardPoints.LoyaltyCardType"/>
                                        </Label>
                                        <CustomSelect
                                            isSearchable={false}
                                            required={true}
                                            placeholder={""}
                                            options={loyaltyCardTypes}
                                            onChange={(e) => this.handleSelect(e, 'loyaltyCardTypeConfigCode')}
                                            value={modalData.loyaltyCardTypeConfigCode ? loyaltyCardTypes.find(el => el.value === modalData.loyaltyCardTypeConfigCode) : null}
                                            isDisabled={!(modalData.id === null || modalData.id === undefined)}
                                        />
                                    </Col>
                                :''}
                                <Col className="col-6 mt-2">
                                    <Label>
                                        <PrettyTitle
                                            name={"CardValidity"}
                                            id={"CardValidityHelp"}
                                            isHelp={true}
                                            size={'small'}
                                        />
                                    </Label>
                                    <CustomSelect
                                        isSearchable={true}
                                        isClearable={true}
                                        placeholder={""}
                                        options={cardValidityOptions}
                                        onChange={(e) => this.handleSelect(e, 'cardValidity')}
                                        value={modalData.cardValidity ? cardValidityOptions.find(el => el.value === modalData.cardValidity) : null}
                                    />
                                </Col>
                                <Col className="col-6 mt-2">
                                    <Label>
                                        <PrettyTitle
                                            name={"CalculateFrom"}
                                            id={"CalculateFromHelp"}
                                            isHelp={true}
                                            size={'small'}
                                        />
                                    </Label>
                                    <CustomSelect
                                        isSearchable={false}
                                        placeholder={""}
                                        options={this.state.calculateFrom}
                                        onChange={(e) => this.handleSelect(e, 'calculateFrom')}
                                        value={modalData.calculateFrom ? this.state.calculateFrom.find(el => el.value === modalData.calculateFrom) : ''}
                                    />
                                </Col>
                                {modalData.calculateFrom && modalData.calculateFrom === 'FixedDate' ?
                                    <Col className="col-6 mt-2">
                                        <Label>
                                            <FormattedMessage id="ConfigCardPoints.BeginDate"/>
                                        </Label>
                                        <SingleDatePicker
                                            id="BeginDate"
                                            date={modalData.beginDate ? moment(modalData.beginDate) : ''}
                                            isOutsideRange={() => false}
                                            focused={this.state.focused}
                                            onFocusChange={({ focused }) => this.setState({ focused })}
                                            small={true}
                                            numberOfMonths={1}
                                            onDateChange={date => this.setState({ modalData: { ...this.state.modalData, beginDate: date } })}
                                            showDefaultInputIcon={true}
                                        />
                                    </Col>
                                :''}
                            </Row>
                            <div className="mt-4">
                                <Row>
                                    <Col>
                                        <h5><FormattedMessage id="ConfigCardPoints.PointsConfig"/></h5>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <PrettyTitle
                                            name={"TierPoints"}
                                            id={"tierPointsHelp"}
                                            isHelp={true}
                                        />
                                    </Col>
                                </Row>
                                <FormGroup row className="pl-3">
                                    <Label sm={2}> <FormattedMessage id="ConfigCardPoints.RevenueAmountPoints" /> </Label>
                                    <Col sm={2} >
                                        <Input
                                            type="number"
                                            name="tierRevenuePoints"
                                            id="tierRevenuePoints"
                                            onChange={(e) => this.handleChange(e)}
                                            min={0}
                                            value={modalData.tierRevenuePoints || '0'}
                                        />
                                    </Col>
                                    <Label sm={2}> <FormattedMessage id="ConfigCardPoints.ResPoints" /> </Label>
                                    <Col sm={2} >
                                        <Input
                                            type="number"
                                            name="tierResPoints"
                                            id="tierResPoints"
                                            onChange={(e) => this.handleChange(e)}
                                            min={0}
                                            value={modalData.tierResPoints || '0'}
                                        />
                                    </Col>
                                    <Label sm={2}><FormattedMessage id="ConfigCardPoints.NightPoints" /> </Label>
                                        <Col sm={2} >
                                            <Input
                                                type="number"
                                                name="tierNightPoints"
                                                id="tierNightPoints"
                                                onChange={(e) => this.handleChange(e)}
                                                min={0}
                                                value={modalData.tierNightPoints || '0'}
                                            />
                                        </Col>
                                </FormGroup>
                            </div>
                            {/* {isAdmin ?
                                <div>
                                    <Row>
                                        <Col>
                                            <b><FormattedMessage id="ConfigCardPoints.AccrualPoints"/></b>
                                        </Col>
                                        {!activeAccrual ? 
                                            <Col className="col-2 d-flex align-items-start justify-content-end">
                                                <div className="text-muted cursor-pointer" onClick={this.setActiveAccrual}>
                                                    <i className="fas fa-plus" />
                                                </div>
                                            </Col>
                                        :''}
                                    </Row>
                                    {activeAccrual ?
                                        <FormGroup row className="pl-3">
                                            <Label sm={2}> <FormattedMessage id="ConfigCardPoints.RevenueAmountPoints" /> </Label>
                                            <Col sm={2} >
                                                <Input
                                                    type="number"
                                                    name="accrualRevenuePoint"
                                                    onChange={(e) => this.handleChange(e)}
                                                    min={0}
                                                    value={modalData.accrualRevenuePoint || ''}
                                                />
                                            </Col>
                                            <Label sm={2}> <FormattedMessage id="ConfigCardPoints.ResPoints" /> </Label>
                                            <Col sm={2} >
                                                <Input
                                                    type="number"
                                                    name="accrualResPoint"
                                                    onChange={(e) => this.handleChange(e)}
                                                    min={0}
                                                    value={modalData.accrualResPoint || ''}
                                                />
                                            </Col>
                                            <Label sm={2}><FormattedMessage id="ConfigCardPoints.NightPoints" /> </Label>
                                                <Col sm={2} >
                                                    <Input
                                                        type="number"
                                                        name="accrualNightPoints"
                                                        onChange={(e) => this.handleChange(e)}
                                                        min={0}
                                                        value={modalData.accrualNightPoints || ''}
                                                    />
                                                </Col>
                                        </FormGroup>
                                    :''}
                                </div>
                            :''} */}
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(ConfigModal)