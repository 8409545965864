import React, { Component } from 'react'

export default class GoogleAnonymousProfile extends Component {
    render() {
        const { small, profilePhotoUri, isAnonymous, isMerchant } = this.props;

        return (
            <span className='mr-2'>
                {isAnonymous || isMerchant ?
                    <div style={{
                        background: '#78909c',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: small ? '20px' : '30px',
                        width: small ? '20px' : '30px',
                        color: 'white',
                        fontSize: '0.8em',
                        justifyContent: 'center',
                        borderRadius: '50px'
                    }}>
                        {isMerchant ?
                            <i className="far fa-building"/>
                        :
                            <i className="fas fa-user"/>
                        }
                    </div>
                : profilePhotoUri ?
                    <img src={profilePhotoUri} crossorigin=""  alt="profile picture" height={small ? '20' : '30'} width={small ? '20' : '30'}/>
                :''}
            </span>
        )
    }
}
