import React, { Component } from 'react';
import CustomBlockUI from '../../Base/CustomBlockUI';
import { createPortal } from 'react-dom';

class SalesProcessGridSpaceCell extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tooltip: false
        };
    }

    componentDidMount(){
        document.getElementById('MainContentCoolDataGrid').addEventListener('scroll', this.handleParentScroll);
    }

    componentWillUnmount() {
        try {
            document.getElementById('MainContentCoolDataGrid').removeEventListener('scroll');
        } catch (error) { }
    }

    handleParentScroll = () => {
        if(this.state.tooltip){
            this.setState({ tooltip: false });
        }
    }

    toggleTooltip = () => {
        const { dealList, d, r, hotelId } = this.props;
        this.setState({ tooltip: !this.state.tooltip }, () => {
            if(this.state.tooltip && dealList?.length){
            }
        });
    }

    render() {
        const { dailyOcc, blockSpaces, hotelId, customClasses, dealList, first, sec, third, fourth, d, r } = this.props;
        const { tooltip } = this.state;
        
        return (
            <CustomBlockUI blocking={!dailyOcc || blockSpaces[hotelId]}>
                <div style={{ border: '1px solid', borderColor: 'transparent' }} className={'py-1 px-2 w-100 h-100 d-flex align-items-center justify-content-center ' + customClasses} onClick={() => this.props.toggleFocusedDeals(null, false)}>
                    {dailyOcc ?
                        <>
                            <div className={"w-100 h-100 d-flex align-items-center"} style={{ gap: '5%' }} onClick={this.toggleTooltip} id={`space-daily-cell-${d}-${r.data.id}-${hotelId}`}>
                                <div className="h-100"
                                    style={{
                                        width: '25%',
                                        borderRadius: '3px',
                                        border: dailyOcc[0]?.value > 0 ? `1px solid ${
                                            dailyOcc[0]?.value > 1 ?
                                                dailyOcc[0]?.dealList.filter(({isDEF}) => isDEF)?.length > 1 ?
                                                    '#3b3434'
                                                :
                                                    '#f38375'
                                            : dailyOcc[0]?.value === 1 ?
                                                '#5cb85c'
                                            :''
                                        }` : '',
                                        background:
                                            first?.length ?
                                                dailyOcc[0]?.value > 1 ?
                                                    dailyOcc[0]?.dealList.filter(({isDEF}) => isDEF)?.length > 1 ?
                                                        '#3b3434'
                                                    :
                                                        '#f38375'
                                                : dailyOcc[0]?.value === 1 ?
                                                    '#5cb85c'
                                                :''
                                            : dailyOcc[0]?.value > 1 ?
                                                    dailyOcc[0]?.dealList.filter(({isDEF}) => isDEF)?.length > 1 ?
                                                        'repeating-linear-gradient(45deg, #3b3434, #3b3434 4px, #ffffff 5px, #ffffff 9px)'
                                                    :
                                                        'repeating-linear-gradient(45deg, #f38375, #f38375 4px, #ffffff 5px, #ffffff 9px)'
                                            : dailyOcc[0]?.value === 1 ?
                                                    'repeating-linear-gradient(45deg, #5cb85c, #5cb85c 4px, #ffffff 5px, #ffffff 9px)'
                                            :''
                                    }}
                                />
                                <div className="h-100"
                                    style={{
                                        width: '25%',
                                        borderRadius: '3px',
                                        border: dailyOcc[1]?.value > 0 ? `1px solid ${
                                            dailyOcc[1]?.value > 1 ?
                                                dailyOcc[1]?.dealList.filter(({isDEF}) => isDEF)?.length > 1 ?
                                                    '#3b3434'
                                                :
                                                    '#f38375'
                                            : dailyOcc[1]?.value === 1 ?
                                                '#5cb85c'
                                            :''
                                        }`:'',
                                        background:
                                            sec?.length ?
                                                dailyOcc[1]?.value > 1 ?
                                                    dailyOcc[1]?.dealList.filter(({isDEF}) => isDEF)?.length > 1 ?
                                                        '#3b3434'
                                                    :
                                                        '#f38375'
                                                : dailyOcc[1]?.value === 1 ?
                                                    '#5cb85c'
                                                :''
                                            :
                                                dailyOcc[1]?.value > 1 ?
                                                    dailyOcc[0]?.dealList.filter(({isDEF}) => isDEF)?.length > 1 ?
                                                        'repeating-linear-gradient(45deg, #3b3434, #3b3434 4px, #ffffff 5px, #ffffff 9px)'
                                                    :
                                                        'repeating-linear-gradient(45deg, #f38375, #f38375 4px, #ffffff 5px, #ffffff 9px)'
                                                : dailyOcc[1]?.value === 1 ?
                                                    'repeating-linear-gradient(45deg, #5cb85c, #5cb85c 4px, #ffffff 5px, #ffffff 9px)'
                                                :''
                                    }}
                                />
                                <div className="h-100"
                                    style={{
                                        width: '25%',
                                        borderRadius: '3px',
                                        border: dailyOcc[2]?.value > 0 ? `1px solid ${
                                            dailyOcc[2]?.value > 1 ?
                                                dailyOcc[2]?.dealList.filter(({isDEF}) => isDEF)?.length > 1 ?
                                                    '#3b3434'
                                                :
                                                    '#f38375'
                                            : dailyOcc[2]?.value === 1 ?
                                                '#5cb85c'
                                            :''
                                        }`:'',
                                        background:
                                            third?.length ?
                                                dailyOcc[2]?.value > 1 ?
                                                    dailyOcc[2]?.dealList.filter(({isDEF}) => isDEF)?.length > 1 ?
                                                        '#3b3434'
                                                    :
                                                        '#f38375'
                                                : dailyOcc[2]?.value === 1 ?
                                                    '#5cb85c'
                                                :''
                                            :
                                                dailyOcc[2]?.value > 1 ?
                                                    dailyOcc[0]?.dealList.filter(({isDEF}) => isDEF)?.length > 1 ?
                                                        'repeating-linear-gradient(45deg, #3b3434, #3b3434 4px, #ffffff 5px, #ffffff 9px)'
                                                    :
                                                        'repeating-linear-gradient(45deg, #f38375, #f38375 4px, #ffffff 5px, #ffffff 9px)'
                                                : dailyOcc[2]?.value === 1 ?
                                                    'repeating-linear-gradient(45deg, #5cb85c, #5cb85c 4px, #ffffff 5px, #ffffff 9px)'
                                                :''
                                    }}
                                />
                                <div className="h-100"
                                    style={{
                                        width: '25%',
                                        borderRadius: '3px',
                                        border: dailyOcc[3]?.value > 0 ? `1px solid ${
                                            dailyOcc[3]?.value > 1 ?
                                                dailyOcc[3]?.dealList.filter(({isDEF}) => isDEF)?.length > 1 ?
                                                    '#3b3434'
                                                :
                                                    '#f38375'
                                            : dailyOcc[3]?.value === 1 ?
                                                '#5cb85c'
                                            :''
                                        }`:'',
                                        background:
                                            fourth?.length ?
                                                dailyOcc[3]?.value > 1 ?
                                                    dailyOcc[3]?.dealList.filter(({isDEF}) => isDEF)?.length > 1 ?
                                                        '#3b3434'
                                                    :
                                                        '#f38375'
                                                : dailyOcc[3]?.value === 1 ?
                                                    '#5cb85c'
                                                :''
                                            :
                                                dailyOcc[3]?.value > 1 ?
                                                    dailyOcc[0]?.dealList.filter(({isDEF}) => isDEF)?.length > 1 ?
                                                        'repeating-linear-gradient(45deg, #3b3434, #3b3434 4px, #ffffff 5px, #ffffff 9px)'
                                                    :
                                                        'repeating-linear-gradient(45deg, #f38375, #f38375 4px, #ffffff 5px, #ffffff 9px)'
                                                : dailyOcc[3]?.value === 1 ?
                                                    'repeating-linear-gradient(45deg, #5cb85c, #5cb85c 4px, #ffffff 5px, #ffffff 9px)'
                                                :''
                                    }}
                                />
                            </div>
                            {tooltip && dealList?.length ?
                                (() => {
                                    const element = document.getElementById(`space-daily-cell-${d}-${r.data.id}-${hotelId}`);
                                    const rect = element.getBoundingClientRect();
                                    
                                    return createPortal(
                                        <div style={{
                                            position: 'absolute',
                                            zIndex: '999',
                                            top: (rect.top - 70),
                                            left: (rect.left - 50),
                                            background: 'blue',
                                            width: "200px",
                                            background: "#767679",
                                            color: "white",
                                            padding: "0.4rem",
                                            fontSize: "0.9em",
                                            borderRadius: "4px"
                                        }}>
                                            <div className='text-center'>
                                                <div className=''>
                                                    <b>{d}</b>
                                                </div>
                                                <div className='mt-1'>
                                                    {dealList.map((deal, key) =>
                                                        <div key={key} className='d-flex align-items-center justify-content-between text-truncate'>
                                                            <div className='text-truncate'>
                                                                <span>{deal.name}</span>
                                                                <span className='ml-1'>({deal.pmsStatus})</span>
                                                            </div>
                                                            <div className='ml-2'>
                                                                <span>{deal.start}h</span>
                                                                <span className='mx-1'>-</span>
                                                                <span>{deal.end}h</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    , document.body);
                                })()
                            :''}
                        </>
                    :
                        <div style={{ visibility: 'hidden' }}>
                            -
                        </div>
                    }
                </div>
            </CustomBlockUI>
        );
    }
}

export default SalesProcessGridSpaceCell;
