import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { injectIntl, FormattedMessage } from 'react-intl';
import { Card, Col, Row, Breadcrumb, BreadcrumbItem, Form, Button, Modal, ModalBody, Badge, Input } from 'reactstrap';
import { BlankCard, StyledCard } from "../../Base/CommonUIComponents";
import { handleNotification } from "../../Base/Notification";
import { CustomTable } from '../../Base/CustomTable';
import CustomSelect from '../../Base/CustomSelect';
import { getAPI, postAPI } from "../../Base/API";
import { DateRangePicker } from 'react-dates';
import MentionDetails from './MentionDetails';
import TagCloud from 'react-tag-cloud';
import moment from 'moment';
import countryList from "react-select-country-list";

class Stats extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            blockTable: true,
            modal: false,
            products: [],
            negativeWords: [],
            positiveWords: [],
            selectedProduct: null,
            fromDate: moment().subtract(15, 'days'),
            toDate: moment(),
            allWords: [],
            negativePalette: ['#ed0000', '#FF9827', '#FFBE2D', '#fb5f5f', '#bb7825'],
            positivePalette: ['#63a0ff', '#48d7ea', '#b19fff', '#15e9ae', '#e86cf7'],
            mentionDetails: [],
            mentionDetailsFiltered: [],
            limit: 1000,
            offset: 0
        };
    }

    componentDidMount() {
        this.getProducts();
    }

    getProducts = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                    return;
                }

                var list = [];

                if (data.response && data.response.length > 0) {
                    data.response.forEach(el => { list.push({ label: el.name, value: el.id }) });
                }

                this.setState({ products: list, selectedProduct: list[0].value }, () => this.getAllWords());
            }
        }, '/api/gms/ReviewPro/v1/Lodgings');
    }

    getAllWords = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                    return;
                }

                this.setState({ allWords: data.response }, () => {
                    if (this.state.selectedProduct) {
                        this.getSemanticMentions();
                        this.getMentionDetails();
                    }
                });
            }
        }, `/api/gms/ReviewPro/v1/SemantiConcepts`);
    }

    getSemanticMentions = (evt) => {
        if (evt) evt.preventDefault();

        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                    return;
                }

                let negative = [], positive = [];

                if (data.response && data.response.length > 0) {
                    negative = this.getCalculatedData(data.response[0].data.value.negative, negative, this.state.negativePalette);
                    positive = this.getCalculatedData(data.response[0].data.value.positive, positive, this.state.positivePalette);
                }

                this.setState({
                    negativeWords: negative,
                    positiveWords: positive
                });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/ReviewPro/v1/SemanticMentions/${this.state.selectedProduct}?fromDate=${moment(this.state.fromDate).format("YYYY-MM-DD")}&toDate=${moment(this.state.toDate).format("YYYY-MM-DD")}`);
    }

    getCalculatedData = (list, array, colorPalette) => {
        if (list && list.length > 0) {
            const sortedList = list.sort((a, b) => a[Object.keys(a)] - b[Object.keys(b)]);
            const min = sortedList[0][Object.keys(sortedList[0])[0]], max = sortedList[sortedList.length - 1][Object.keys(sortedList[sortedList.length - 1])[0]];
            //max font sixe: 50
            //min font size: 12
            sortedList.forEach((word, index) => {
                const currentId = Object.keys(sortedList[index])[0];
                
                array.push({
                    word: this.state.allWords.find(el => el.id.toString() === currentId.toString()).name,
                    fontSize: (((sortedList[index][currentId] - min) / (max - min)) * (50 - 12)) + 12,
                    color: colorPalette[Math.floor(Math.random() * colorPalette.length)]
                })
            })

        }

        return array;
    }

    getMentionDetails = (evt) => {
        if (evt) evt.preventDefault();

        this.setState({ blockTable: true });
        const { fromDate, toDate, offset, limit, selectedProduct } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockTable: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ blockTable: false });
                    return;
                }

                this.setState({ mentionDetails: data.response, mentionDetailsFiltered: data.response });
            }
            this.setState({ blockTable: false });
        }, `/api/gms/ReviewPro/v1/SemanticMentionsDetail/${selectedProduct}?fromDate=${moment(fromDate).format("YYYY-MM-DD")}&toDate=${moment(toDate).format("YYYY-MM-DD")}&offset=${offset}&limit=${limit}`);
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    };

    toggleModal = (review) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedReview: review || null
        }))
    }

    doSearch = (evt) => {
        evt.preventDefault();

        this.getSemanticMentions();
        this.getMentionDetails();
    }

    filterTable = (value) => {
        let mentions = [...this.state.mentionDetails];
        let list = [];

        if (value) {
            mentions.forEach(mention => {
                if (mention.concepts.some(concept => concept.name.includes(value))) {
                    list.push(mention);
                }
            })
        }
        else {
            list = mentions
        }

        this.setState({ searchText: value, mentionDetailsFiltered: list })
    }

    render() {
        const { block, error, products, selectedProduct, positiveWords, negativeWords, mentionDetailsFiltered } = this.state;
        const columns = [
            {
                dataField: 'country',
                text: <FormattedMessage id="ReviewProStats.Country" />,
                sort: true,
                formatter: (cell) => cell ? <div><i className={('mr-2 flag-icon flag-icon-') + (cell.toLowerCase())}></i> {countryList().getLabel(cell)} </div> : ''
            },
            {
                dataField: 'reviewer',
                text: <FormattedMessage id="ReviewProStats.Reviewer" />,
                sort: true
            },
            {
                dataField: 'source',
                text: <FormattedMessage id="ReviewProStats.Source" />,
                sort: true
            },
            {
                dataField: 'score',
                text: <FormattedMessage id="ReviewProStats.Score" />,
                sort: true
            },
            {
                dataField: 'sentimentPositive',
                text: <FormattedMessage id="ReviewProStats.Sentiment" />,
                sort: true,
                formatter: (cell, row) => <div>{cell ? <Badge color="success" className='p-1'><FormattedMessage id="ReviewProStats.Positive" /></Badge> : <Badge color="danger" className='p-1'><FormattedMessage id="ReviewProStats.Negative" /></Badge>} </div>
            },
            {
                dataField: 'date',
                text: <FormattedMessage id="ReviewProStats.Date" />,
                sort: true
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.toggleModal(row);
            }
        };

        return (
            <BlankCard block={block} error={error} >
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/ReviewProMenu"> ReviewPro Config</Link></BreadcrumbItem>
                        <BreadcrumbItem active> Stats </BreadcrumbItem>
                    </Breadcrumb>
                </div>

                <Form onSubmit={this.doSearch}>
                    <Row className=" align-items-center">
                        <Col sm={3}>
                            <CustomSelect
                                isSearchable
                                placeholder={""}
                                options={products}
                                onChange={(e) => this.handleChangeSelect(e, "selectedProduct")}
                                value={selectedProduct ? products.find(el => el.value === selectedProduct) : ''}
                                required
                            />
                        </Col>
                        <Col sm={3} className="px-1">
                            <DateRangePicker
                                startDate={this.state.fromDate ? moment(this.state.fromDate) : null}
                                startDateId="startDate"
                                isOutsideRange={day => day > moment()}
                                endDate={this.state.toDate ? moment(this.state.toDate) : null}
                                endDateId="endDate"
                                onDatesChange={({ startDate, endDate }) => this.setState({ fromDate: startDate, toDate: endDate })}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                required
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className="btn- btn-host btn-small btn-sm"type="submit">
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <Row className="mt-3 mb-5">
                    <Col>
                        <Card className="border-0 shadow" body>
                            <h4 className="text-center"> <b> <FormattedMessage id="ReviewProStats.PositiveWords" /></b></h4>
                            <TagCloud style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 'bold', padding: 5, width: '100%', height: '400px' }}>
                                {positiveWords && positiveWords.map((word, key) =>
                                    <div className="cursor-pointer" onClick={() => this.filterTable(word.word)} key={key} style={{ fontSize: word.fontSize, color: word.color }}>
                                        {word.word}
                                    </div>
                                )}
                            </TagCloud>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="border-0 shadow" body>
                            <h4 className="text-center"> <b> <FormattedMessage id="ReviewProStats.NegativeWords" /> </b></h4>
                            <TagCloud style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 'bold', padding: 5, width: '100%', height: '400px' }}>
                                {negativeWords && negativeWords.map((word, key) =>
                                    <div className="cursor-pointer" onClick={() => this.filterTable(word.word)} key={key} style={{ fontSize: word.fontSize, color: word.color }}>
                                        {word.word}
                                    </div>
                                )}
                            </TagCloud>
                        </Card>
                    </Col>
                </Row>

                <StyledCard block={this.state.blockTable}>
                    <Row className="mb-2">
                        <Col sm={2}>
                            <Input
                                type="text"
                                value={this.state.searchText || ''}
                                onChange={(e) => this.filterTable(e.target.value)}
                                placeholder={this.props.intl.formatMessage({ id: "ReviewProStats.SearchWord" })}
                            />
                        </Col>
                        <Col className="text-muted text-righpt-2">
                            {this.state.mentionDetails.length === 1000 ? "*Only 1000 results can be displayed" : ''}
                        </Col>
                    </Row>

                    <CustomTable
                        data={mentionDetailsFiltered ? mentionDetailsFiltered : []}
                        columns={columns}
                        shadow={false}
                        showTotal={true}
                        //search={{ searchFormatted: true }}
                        selectRow={selectRow}
                    />
                </StyledCard>

                {this.state.modal ?
                    <Modal isOpen={this.state.modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                        <Button onClick={this.toggleModal} className="closeModal">
                            <i className="fas fa-times fa-sm"></i>
                        </Button>
                        <ModalBody className="p-0">
                            <MentionDetails
                                selectedReview={this.state.selectedReview}
                            />
                        </ModalBody>
                    </Modal>
                : ''}
            </BlankCard>
        );
    }
}
export default injectIntl(Stats)
