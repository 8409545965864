import React, { Component } from 'react';
import { Button, Col, Badge, Input, Row, Form } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import {  DefaultPage, SelectIsMaster } from '../../Base/CommonUIComponents';
import { handleNotification } from '../../Base/Notification';
import { getAPI, putAPI } from '../../Base/API';
import { StyledCard } from '../../Base/CommonUIComponents';
import CustomSelect from '../../Base/CustomSelect';
import { getContactTypeList, getDocumentList, getProfileType } from '../../Base/ReferenceDataFunctions';
import countryList from "react-select-country-list";
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import { CustomTable } from '../../Base/CustomTable';
import { Pagination, ResultsPerPage } from '../../Base/PaginationComponents';
import ConfirmActionModal from '../../Base/ConfirmActionModal';


export class DeletedProfiles extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            countries: countryList().getData(),
            pageIndex: 0,
            pageSize: 10,
            recoverModal: false
        };
    }

    componentDidMount() {
        this.getHotels();
    }

    getHotels = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ error: errorMessage, block: false }));
                }
                if (data.response && data.response.length > 0) {
                    const hotels = data.response.map(resp => {
                        const hotel = {
                            value: resp.hotelId,
                            label: resp.name2
                        };
                        return hotel;
                    });
                    this.setState({ hotels, error: errorMessage, block: false });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Hotel/v1`)
    }

    recoverProfile = (e, profileId) => {
        if (e) e.preventDefault();
        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="DeletedProfiles.ProfileRecovered" />, <FormattedMessage id="generic.success" />);
                    this.getProfiles();
                    this.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/profile/${profileId}/undelete`);
    }

    getProfiles = () => {
        this.setState(({ block: true }));

        const params = this.updateAndGetUrlParams();

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ error: errorMessage, block: false }));
                }
                else {
                    data.response.filter(profile => !profile.isMaster && profile.masterId).map(profile => profile.linked = true);
                    this.setState({ profiles: data.response, error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Profile/v1/Profiles?deleted=true` + params)
    }

    updateAndGetUrlParams = () => {
        const { pageSize, pageIndex, hotelId, name, otherName, country, type, birthDate, contactType, docType, docNumber, isMaster, cardNumber, searchableGuestNumber } = this.state;

        let contact = this.state.contact;

        if (contact && contact.includes('@') && contact.includes(' ')) {
            contact = contact.replace(" ", "");
        }

        var params = `&pageSize=${pageSize}&pageIndex=${pageIndex}`;

        if (hotelId) params += `&hotelId=${hotelId}`;
        if (name) params += `&name=${encodeURIComponent(name)}`;
        if (otherName) params += `&middleName=${encodeURIComponent(otherName)}`;
        if (country) params += `&country=${country}`;
        if (type) params += `&type=${type}`;
        if (birthDate) params += `&dateOfBirth=${moment(birthDate).format("YYYY-MM-DD")}`;
        if (contactType) params += `&contactType=${contactType}`;
        if (contact) params += `&contact=${encodeURIComponent(contact.toLowerCase())}`;
        if (docType) params += `&docType=${docType}`;
        if (docNumber) params += `&docNumber=${encodeURIComponent(docNumber)}`;
        if (isMaster) params += `&isMaster=${isMaster}`;
        if (cardNumber) params += `&cardNumber=${cardNumber}`;
        if (searchableGuestNumber) params += `&searchableGuestNumber=${searchableGuestNumber}`;
        if (this.state.isMaster !== undefined && this.state.isMaster !== null) params += `&isMaster=${this.state.isMaster}`

        return params;
    }

    doSearch = (e) => {
        if(e) e.preventDefault();

        this.setState({
            pageIndex: 0,
            block: true
        }, this.getProfiles);
    }

    setCombo = (name, combo) => {
        this.setState({ [name]: combo && combo.value });
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value
        })
    }

    setIsMater = (name, combo) => {
        const value = combo && combo.value;

        this.setState(prevState => ({
            [name]: value,
            searchableGuestNumber: value ? null : prevState.searchableGuestNumber
        }));
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.getProfiles());
    }

    changePageSize = (evt) => {
        const { value } = evt.target;

        if (this.state.pageSize !== value) {
            this.setState({ pageSize: value, pageIndex: 0 }, () => this.getProfiles());
        }
    }

    toggleModal = (profile) => {
        this.setState(prevState => ({
            recoverModal: !prevState.recoverModal,
            selectedProfile: profile
        }));
    }


    render() {
        const { block, hotels, hotelId, name, otherName, countries, country, type, birthDate, focused, contactType, contact, docType, docNumber, isMaster, error, profiles, pageIndex, pageSize, cardNumber, searchableGuestNumber, recoverModal, selectedProfile } = this.state;

        const columns = [
            {
                dataField: 'type',
                text: this.props.intl.formatMessage({ id: "SearchProfile.Type" }),
                formatter: (cell, row) => {
                    if (cell) {
                        const type = getProfileType(this.props.intl).find(el => el.value === cell);

                        return <div className="text-muted text-center"> {type ? <i className={type.icon} /> : '-'} </div>
                    }
                    return '';
                },
                style: { width: '50px' }
            },
            {
                dataField: 'firstName',
                text: this.props.intl.formatMessage({ id: "SearchProfile.FirstName" }) + (this.props.showHotel ? ` / ${this.props.intl.formatMessage({ id: "generic.Hotel" })}` : ''),
                formatter: (cell, row) => {
                    return <div>
                        <div> {cell} {row.lastName}</div>
                        {this.props.showHotel && row.linked ? <span className="text-muted font_size_xxs"> Hotel {row.hotelName} </span> : ''}
                    </div>
                }
            },
            {
                dataField: 'middleName',
                text: this.props.intl.formatMessage({ id: "ProfileDetails.OtherNames" })
            },
            {
                dataField: 'birthDate',
                text: this.props.intl.formatMessage({ id: "SearchProfile.BirthDate" }),
                formatter: (cell, row) => cell ? moment(cell).format("YYYY-MM-DD") : '',
                style: { width: '180px' }
            },
            {
                dataField: 'gender',
                text: this.props.intl.formatMessage({ id: "SearchProfile.Gender" }),
                style: { width: '90px' }
            },
            {
                dataField: 'language',
                text: this.props.intl.formatMessage({ id: "SearchProfile.Language" }),
                style: { width: '90px' }
            },
            {
                dataField: 'nationality',
                text: this.props.intl.formatMessage({ id: "SearchProfile.Nationality" }),
                formatter: (cell, row) => cell ? <div><i className={('ml-2 mr-2 flag-icon flag-icon-') + (cell.toLowerCase())}></i> {countryList().getLabel(cell)} </div> : '',
                style: { width: '250px', paddingRight: '30px' }
            },
            {
                dataField: 'membershipCard',
                text: this.props.intl.formatMessage({ id: "SearchProfile.MembershipCard" }),
                formatter: (cell, row) => {
                    return cell && cell.map(el =>
                        <div className="text-ellipsis" style={{ fontSize: '13px' }}>
                            {el.cardTypeCode} - {el.cardNumber}
                        </div>
                    )
                }
            },
            {
                dataField: 'isMaster',
                text: 'Master',
                formatter: (cell, row) => cell ? <Badge color='primary'> Master </Badge> : row.linked ? <Badge className=' color-white bg-cyan'> Linked </Badge> : ''
            },
            {
                dataField: '',
                text: this.props.intl.formatMessage({ id: "DeletedProfiles.Recover" }),
                style: { width: '90px' },
                formatter: (cell, row) => <div className="w-100 text-center">
                    <Button className="btn btn-host btn-sm" onClick={() => this.toggleModal({ id: row.id, name: `${row.firstName} ${row.lastName}` })} title={this.props.intl.formatMessage({ id: "DeletedProfiles.RecoverProfile" })}>
                        <i className="fas fa-undo-alt" />
                    </Button>
                </div>
            }
        ];


        return (
            <StyledCard title="NavMenu.DeletedProfiles" icon="fas fa-user-times" block={block} error={error} tabIndex="0">
                {recoverModal ?
                    <ConfirmActionModal
                        modal={recoverModal}
                        toggleModal={this.toggleModal}
                        actionFunction={(e) => this.recoverProfile(e, selectedProfile.id)}
                        text={<FormattedMessage id="DeletedProfiles.ConfirmRecoverText" values={{ profileName: selectedProfile.name }} />}
                        block={block}
                    />
                : ''}

                <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr', height: '88vh' }}>
                    <div style={{ zIndex: '999' }}>
                        <div className="my-4 smallInput">
                            <Form onSubmit={this.doSearch}>
                                <Row>
                                    <Col className="col-3">
                                        <CustomSelect
                                            placeholder={<FormattedMessage id="SearchProfile.Hotel" />}
                                            options={hotels}
                                            value={hotels?.find(h => h.value === hotelId)}
                                            onChange={this.setCombo.bind(this, 'hotelId')}
                                            isClearable
                                            isSearchable
                                        />
                                    </Col>
                                    <Col className="text-right">
                                        <Button className="btn btn-host btn-sm float-right" type="submit">
                                            <i className="icon-icon-search" />
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className="my-2">
                                    <Col className="col-3">
                                        <Input
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder={this.props.intl.formatMessage({ id: "SearchProfile.Name" })}
                                            value={name ? name : ''}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col className="pr-2" style={{ maxWidth: '15vw' }}>
                                        <CustomSelect
                                            placeholder={<FormattedMessage id="SearchProfile.ContactType" />}
                                            options={getContactTypeList(this.props.intl)}
                                            value={getContactTypeList(this.props.intl).find(co => co.value === contactType)}
                                            onChange={this.setCombo.bind(this, 'contactType')}
                                            isClearable
                                            isSearchable
                                        />
                                    </Col>
                                    <Col className="pl-0">
                                        <Input
                                            type="text"
                                            id="contact"
                                            name="contact"
                                            placeholder={this.props.intl.formatMessage({ id: "SearchProfile.Contact" })}
                                            value={contact ? contact : ''}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col className="px-2" style={{ maxWidth: '15vw' }}>
                                        <CustomSelect
                                            placeholder={<FormattedMessage id="SearchProfile.EntityType" />}
                                            options={getProfileType(this.props.intl)}
                                            value={getProfileType(this.props.intl).find(t => t.value === type)}
                                            onChange={this.setCombo.bind(this, 'type')}
                                            isClearable
                                            isSearchable
                                            isDisabled={this.state.disableProfileType}
                                        />
                                    </Col>
                                    <Col className="px-2" style={{ maxWidth: '13vw' }}>
                                        <Input
                                            type="text"
                                            id="cardNumber"
                                            name="cardNumber"
                                            placeholder={this.props.intl.formatMessage({ id: "SearchProfile.CardNumber" })}
                                            value={cardNumber ? cardNumber : ''}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col className="birthDate" style={{ maxWidth: '13vw' }}>
                                        <SingleDatePicker
                                            id="dateOfBirthSearchProfile"
                                            isOutsideRange={day => day > moment()}
                                            date={birthDate ? moment(birthDate) : null}
                                            focused={focused}
                                            onFocusChange={({ focused }) => this.setState({ focused })}
                                            small={true}
                                            numberOfMonths={1}
                                            showDefaultInputIcon={true}
                                            onDateChange={date => this.setState({ birthDate: date })}
                                            placeholder={this.props.intl.formatMessage({ id: "SearchProfile.DateOfBirth" })}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-3">
                                        <Input
                                            type="text"
                                            id="otherName"
                                            name="otherName"
                                            placeholder={this.props.intl.formatMessage({ id: "ProfileDetails.OtherNames" })}
                                            value={otherName ? otherName : ''}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col className="pr-2" style={{ maxWidth: '15vw' }}>
                                        <CustomSelect
                                            placeholder={<FormattedMessage id="SearchProfile.DocType" />}
                                            options={getDocumentList(this.props.intl)}
                                            value={getDocumentList(this.props.intl).find(doc => doc.value === docType)}
                                            onChange={this.setCombo.bind(this, 'docType')}
                                            isClearable
                                            isSearchable
                                        />
                                    </Col>
                                    <Col className="pl-0">
                                        <Input
                                            type="text"
                                            id="docNumber"
                                            name="docNumber"
                                            placeholder={this.props.intl.formatMessage({ id: "SearchProfile.DocIdentification" })}
                                            value={docNumber ? docNumber : ''}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col className="px-2" style={{ maxWidth: '15vw' }}>
                                        <CustomSelect
                                            placeholder={<FormattedMessage id="SearchProfile.Country" />}
                                            options={countries}
                                            value={countries.find(c => c.value === country)}
                                            onChange={this.setCombo.bind(this, 'country')}
                                            isClearable
                                            isSearchable
                                        />
                                    </Col>
                                    <Col className="px-2" style={{ maxWidth: '13vw' }}>
                                        <SelectIsMaster
                                            name="isMaster"
                                            onChangeFunc={this.setIsMater}
                                            value={isMaster}
                                            placeholder={<FormattedMessage id="SearchProfile.IsMaster" />}
                                            isDisabled={this.state.disableMasterFilter}
                                        />
                                    </Col>
                                    <Col style={{ maxWidth: '13vw' }}>
                                        <Input
                                            type="number"
                                            id="searchableGuestNumber"
                                            name="searchableGuestNumber"
                                            placeholder={this.props.intl.formatMessage({ id: "SearchProfile.GuestNumber" })}
                                            value={searchableGuestNumber ? searchableGuestNumber : ''}
                                            onChange={this.handleChange}
                                            disabled={isMaster ? true : false}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>

                    {profiles ?
                        <div style={{ display: 'grid', gridTemplateRows: '1fr auto', overflow: 'hidden' }}>
                            <div className="veryCoolScrollBar tableWithScroll">
                                <CustomTable
                                    data={profiles}
                                    columns={columns}
                                    page={pageIndex + 1}
                                    sizePerPage={pageSize}
                                    rowStyle={{ cursor: 'pointer' }}
                                    hasPagination={false}
                                />
                            </div>

                            <Row className="mt-3">
                                <Col>
                                    <ResultsPerPage
                                        changeSizePerPage={this.changePageSize}
                                        pageSize={pageSize}
                                    />
                                </Col>

                                <Col className="text-right mr-3">
                                    <Pagination
                                        isPrevDisabled={pageIndex === 0}
                                        isNextDisabled={profiles && profiles.length < pageSize}
                                        currentPage={pageIndex + 1}
                                        handlePrevButton={() => this.handlePagination(-1)}
                                        handleNextButton={() => this.handlePagination(1)}
                                    />
                                </Col>
                            </Row>
                        </div>
                        :
                        <DefaultPage icon="icon-icon-search text-muted" text="generic.SelectSearchCriteria"/>
                    }
                </div>
            </StyledCard>
        );
    }
}

export default injectIntl(DeletedProfiles);