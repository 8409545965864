import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {  Col, CustomInput, Row } from 'reactstrap';
import { StyledCard } from "../Base/CommonUIComponents";
import { deleteAPI, getAPI, postAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import CustomSelect from "../Base/CustomSelect";
import GoogleBusinessCard from './GoogleBusinessCard';
import { Pagination } from '../Base/PaginationComponents';
import QAModal from './QAModal';
import moment from 'moment-timezone';
import { getContextPrompts, googleTextTreatment } from '../Base/ReferenceDataFunctions';

class GoogleBusinessQA extends Component {

    constructor(props) {
        super(props);
        this.state = {
            questionList: [],
            pageTokens: [null],
            pageIndex: 0,
            pageSize: 10,
            orderBy: null,
            orderByOptions: ["upvoteCount desc"],
            ignoreUnanswered: true,
            questionReply: '',
            openInput: true,
            modal: false,
            selectedQuestion: null,
            error: null,
            block: true
        };
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.selectedLocation !== nextProps.selectedLocation){
            this.setState({
                pageTokens: [null],
                pageIndex: 0,
                orderBy: null,
                questionList: [],
                error: null,
                block: true
            }, () => this.getQA());
        }
    }

    componentDidMount(){
        this.getQA();
    }

    getQA = () => {
        const { selectedLocation, handleQuestionLength } = this.props;
        const { ignoreUnanswered, orderBy, pageTokens, pageIndex, pageSize, pages } = this.state;
        let urlParams = "";
        if(pageTokens[pageIndex]) urlParams += `&pageToken=${pageTokens[pageIndex]}`;
        if(orderBy) urlParams += `&orderBy=${orderBy}`;
        if(ignoreUnanswered) urlParams += `&ignore_answered=${ignoreUnanswered}`;
        
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response) {
                if (data.errors && data.errors.length > 0) {
                    this.setState({ block: false }, () => handleNotification(data));
                }
                else {
                    if(data.response[0]?.nextPageToken) pageTokens[pageIndex + 1] = data.response[0]?.nextPageToken;
                    const questionList = data.response[0]?.questions??[];

                    if(ignoreUnanswered){
                        handleQuestionLength(questionList.length)
                    }


                    this.setState({ pages, questionList, error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Google/v1/qa/locations/${selectedLocation}/list?pageSize=${pageSize}` + urlParams);
    }

    handlePagination = (value) => {
        this.setState(prev => ({ pageIndex: prev.pageIndex + value, block: true }), () => this.getQA());
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null, block: true, 
            pageTokens: [null],
            pageIndex: 0
        }, () => this.getQA());
    }

    toggleBool = (e) => {
        const { name, checked } = e.target;
        this.setState({ [name]: checked, block: true }, () => this.getQA());
    }

    toggleDetailModal = (questionKey) => {
        const { modal, questionList } = this.state;
        
        if(!questionList) return;

        if(modal){
            this.setState({ modal: false });
        }

        const selectedQuestion = questionList[questionKey];
        if(!selectedQuestion) return;
        
        const openInput = !selectedQuestion.topAnswers?.find(({author}) => author?.type === "MERCHANT");

        this.setState({ modal: true, selectedQuestion, openInput, questionReply: '' });
    }

    submitReply = () => {
        const { selectedQuestion, questionReply } = this.state;
        const { hotelName } = this.props;

        if(!questionReply) return;
        this.setState({ block: true }, () => {
            const body = {
                parentId: selectedQuestion.name,
                upsertAnswerRequest: {
                  answer: {
                    text: questionReply,
                  }
                }
            };

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data && data.response) {
                    if (data.errors && data.errors.length > 0) {
                        this.setState({ block: false }, () => handleNotification(data));
                    }
                    else {
                        if(!selectedQuestion.topAnswers) selectedQuestion.topAnswers = [];
                        const answer = data.response[0];

                        answer.author = {
                            profilePhotoUri: '',
                            isAnonymous: true,
                            displayName: hotelName,
                            updateTime: moment().format('YYYY-MM-DD')
                        };

                        selectedQuestion.topAnswers.push(answer);
                        this.setState({ selectedQuestion, error: errorMessage, questionReply: '', block: false });
                    }
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/Google/v1/qa/locations/answer`, body);
        });
    }
    
    onChange = (e) => {
        const { value, name } = e?.target;
        this.setState({ [name]: value });
    }

    editReply = () => {
        const { selectedQuestion } = this.state;
        const currentReply = selectedQuestion.topAnswers.find(({author}) => author?.type === "MERCHANT");
        if(!currentReply) return;
        this.setState({ questionReply: currentReply.text, openInput: true });
    }
    
    deleteReply = () => {
        const { selectedQuestion } = this.state;
        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response) {
                if (data.errors && data.errors.length > 0) {
                    this.setState({ block: false }, () => handleNotification(data));
                }
                else {
                    const originalAnswerIndex = selectedQuestion.topAnswers.findIndex(({author}) => author?.type === "MERCHANT");
                    selectedQuestion.topAnswers.splice(originalAnswerIndex, 1);
                    this.setState({ selectedQuestion, error: errorMessage, questionReply: '', block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Google/v1/qa/locations/delete/${selectedQuestion.name}`);
    }

    getGPTPrompt = () => {
        const { selectedQuestion } = this.state;
        
        const slicedQuestion = googleTextTreatment(selectedQuestion.text); //question.text.indexOf('(Translated by Google)') > -1 ? question.text.slice(0, (question.text.indexOf('(Translated by Google)'))) : question.text;
        
        return slicedQuestion;
    }

    render() {
        const { orderByOptions, pageIndex, pageTokens, orderBy, ignoreUnanswered, questionList, modal, questionReply, openInput, block, error, selectedQuestion } = this.state;
        const { hotelName, intl } = this.props;
        
        const orderBySelectOptions = orderByOptions.map((value) => {
            return({
                value, label: intl.formatMessage({ id: `GoogleBusinessConfig.${value}` })
            });
        });

        return (
            <StyledCard block={block} error={error}>
                <Row className="mb-2">
                    <Col className='text-right d-flex align-items-center justify-content-end'>
                        <div className='d-flex h-100 align-items-center'>
                            <span className='mr-2'>
                                <FormattedMessage id="GoogleBusinessConfig.ignoreUnanswered"/>
                            </span>
                            <CustomInput
                                type="switch"
                                checked={ignoreUnanswered}
                                id="ignoreUnanswered"
                                name="ignoreUnanswered"
                                onChange={this.toggleBool}
                            />
                        </div>
                        <div style={{ width: '20%', marginLeft: '2rem', textAlign: 'left' }}>
                            <CustomSelect
                                options={orderBySelectOptions}
                                isClearable={true}
                                isSearchable={false}
                                isDisabled={!questionList || questionList.length === 0}
                                value={orderBySelectOptions?.find(m => m.value === orderBy)}
                                placeholder={intl.formatMessage({ id: "GoogleBusinessConfig.OrderBy" })}
                                onChange={this.handleSelect.bind(this, 'orderBy')}
                            />
                        </div>
                    </Col>
                </Row>
                <div style={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
                    {questionList?.map((question, key) =>
                        <GoogleBusinessCard
                            key={key}
                            questionKey={key}
                            toggleDetailModal={this.toggleDetailModal}
                            profileURI={question.author.profilePhotoUri}
                            displayName={question.author.displayName}
                            isAnonymous={question.author.isAnonymous}
                            upvoteCount={question.upvoteCount}
                            createTime={question.updateTime}
                            question={question.text}
                            answerCount={question.totalAnswerCount}
                            answers={question.topAnswers}
                        />
                    )}
                </div>
                {pageTokens.length > 1 ?
                    <Row className="my-4">
                        <Col className="text-right mr-3">
                            <Pagination
                                isPrevDisabled={pageIndex === 0}
                                isNextDisabled={!pageTokens[pageIndex + 1]}
                                currentPage={pageIndex + 1}
                                handlePrevButton={() => this.handlePagination(-1)}
                                handleNextButton={() => this.handlePagination(1)}
                            />
                        </Col>
                    </Row>
                :''}
                <QAModal
                    hotelName={hotelName}
                    toggle={this.toggleDetailModal}
                    open={modal}
                    question={selectedQuestion}
                    questionReply={questionReply}
                    submitReply={this.submitReply}
                    onChange={this.onChange}
                    getGPTPrompt={this.getGPTPrompt}
                    openInput={openInput}
                    editReply={this.editReply}
                    deleteReply={this.deleteReply}
                    configType="QA"
                />
            </StyledCard>
        );
    }
}
export default injectIntl(GoogleBusinessQA)
