import React, { Component } from 'react';
import { Card, FormGroup, Col, Row, Form, Button, Input } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CardStatusBadge, FormatAmountNumber, StyledCard, cardStatus } from '../../Base/CommonUIComponents';
import { handleNotification } from '../../Base/Notification';
import Authorization, { CheckAuthorization } from '../../Base/Authorization';
import PrintVoucherModal from '../PrintVoucherModal';
import { getAPI, putAPI } from '../../Base/API';
import RefundModal from './RefundModal';
import AddRedeem from './AddRedeem';
import moment from 'moment';
import { AssociateProfile, AssociateReservation } from './AssociationModals';
import { DateRangePicker } from 'react-dates';
import CustomSelect from "../../Base/CustomSelect";


class VoucherDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            addRedeemModal: false,
            refundModal: false,
            editProfileModal: false,
            editReservationModal: false
        }
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.getVoucherDetails();
        }
    }

    getVoucherDetails = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                const voucher = data.response && data.response.length > 0 ? data.response[0] : null;

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                
                this.setState({ voucher, block: false });
            }
            else this.setState({ block: false });
        }, `/api/Voucher/Voucher/v1/voucher/${this.props.match.params.id}`);
    }

    associateProfileOrReservation = (event, associateProfile, reservationData) => {
        if (event) event.preventDefault();

        this.setState({ blockAssociation: true });

        var body = {
            voucherId: this.state.voucher.id
        }

        if (associateProfile) {
            body.externalId = this.state.profile.externalId;
            body.firstName = this.state.profile.firstName;
            body.lastName = this.state.profile.lastName;
            body.email = this.state.profile.email
        }

        if (reservationData) {
            Object.assign(body, reservationData);
        }


        putAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorAssociation: errorMessage, blockAssociation: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ blockAssociation: false });
                }
                else if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="VoucherDetails.AssociatedSuccessfully" />, <FormattedMessage id="generic.success" />);

                    const voucher = { ...this.state.voucher };

                    if (associateProfile) {
                        voucher.externalId = body.externalId;
                        voucher.name = body.firstName + ' ' + body.lastName;
                        voucher.email = body.email;
                    }

                    if (reservationData) {
                        voucher.voucherReservation = {
                            resIdValue: reservationData.reservationNumber,
                            hotelId: reservationData.reservationHotelId,
                            resIdDate: reservationData.reservationDate
                        }
                    }
                        

                    this.setState({
                        voucher, profile: null,
                        editProfileModal: false, editReservationModal: false,
                        blockAssociation: false, errorAssociation: null
                    });
                }
            }
            else this.setState({ blockAssociation: false });
        }, `/api/Voucher/Voucher/v1/Voucher/AssociateProfileOrReservation`, body)
    }

    saveVoucher = (e) => {
        e.preventDefault();

        this.setState({ block: true });

        const req = {
            request: {
                id: this.state.voucher.id,
                status: this.state.voucher.status,
                validFrom: this.state.voucher.validFrom,
                validUntil: this.state.voucher.validUntil,
                notes: this.state.voucher.notes,
                email: this.state.voucher.email
            }
        };

        putAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="VoucherDetails.VoucherUpdatedSuccessfully" />, <FormattedMessage id="generic.success" />);
                this.setState({ block: false });
            }
        }, '/api/Voucher/Voucher/v1/voucher', req);
    }


    disableServiceRefund = (redeem) => {
        let disable = true;
        const rebate = this.state.voucher.voucherType.voucherRebates.find(el => el.id === redeem.rebateId);

        if (redeem.quantity > 0 && rebate) {
            const usage = this.state.voucher.redeems.filter(x => x.rebateId === rebate.id).reduce((acc, cur) => acc + cur.quantity, 0)

            if (usage > 0) {
                disable = false
            }
        }

        return disable;
    }

    toggleRefundModal = (redeem) => {
        let maxQuantity = null;

        if (redeem) {
            const usage = this.state.voucher.redeems.filter(x => x.rebateId === redeem.rebateId).reduce((acc, cur) => acc + cur.quantity, 0);
            maxQuantity = usage < redeem.quantity ? usage : redeem.quantity;
        }

        this.setState(prevState => ({
            refundModal: !prevState.refundModal,
            redeem: redeem,
            maxQuantity
        }));
    }
    
    toggleModal = (modal) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }));
    }
    
    selectProfile = (profile) => {
        const email = profile.contacts?.find(el => el.type === "Email");

        this.setState({
            profile: {
                externalId: profile.id,
                firstName: profile.firstName,
                lastName: profile.lastName,
                email: email ? email.contact : null
            }
        })
    }

    handleDateRangeChange = (startDate, endDate) => {
        this.setState(prevState => ({
            ...prevState,
            voucher: {
                ...prevState.voucher,
                validFrom: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
                validUntil: endDate ? moment(endDate).format("YYYY-MM-DD") : null

            },
        }));
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState(prevState => ({
            ...prevState,
            voucher: {
                ...prevState.voucher,
                [name]: value

            },
        }));
    }

    handleSelect = (combo, name) => {
        this.setState(prevState => ({
            ...prevState,
            voucher: {
                ...prevState.voucher,
                [name]: combo ? combo.value : null
            },
        }));
    }


    render() {
        const { block, error, voucher, addRedeemModal, refundModal, printVoucherModal, editProfileModal, editReservationModal } = this.state;

        let refund = voucher?.refunds?.reduce((acc, cur) => acc + cur.amount, 0).toFixed(2) || 0;
        let redeems = voucher?.redeems?.reduce((acc, cur) => acc + cur.amount, 0).toFixed(2) || 0;
        let left = (voucher?.voucherValue - refund - redeems).toFixed(2) || 0;

        return (
            <StyledCard block={block} error={error}>
                {voucher ?
                    <div>
                        <Form className="mb-3" onSubmit={this.saveVoucher}>
                            <Card className="shadow border-0 h-100" body>
                                <Row className="mb-3 border-bottom pb-3">
                                    <Col>
                                        <i className="icon-icon-vouchers mr-2" /> <FormattedMessage id="VoucherDetails.VoucherDetails" />
                                    </Col>
                                    <Col className="text-right">
                                        {!CheckAuthorization("voucherDetails:edit") ?
                                            <CardStatusBadge status={voucher.status} />
                                        : ''}

                                        <Button className="btn-host btn-sm ml-3" id="printTooltip" onClick={() => this.toggleModal("printVoucherModal")}>
                                            <i className="fas fa-print " />
                                        </Button>

                                        <Button className="btn-host btn-sm ml-2" type="submit">
                                            <i className="fas fa-save " />
                                        </Button>
                                    </Col>
                                </Row>

                                <div>
                                    <FormGroup row className="mb-5">
                                        <Col>   
                                            <div className="title-sm">
                                                <FormattedMessage id="VoucherDetails.Name" />

                                                <Authorization
                                                    perform="admin:view"
                                                    yes={() => (
                                                        <i className="fas fa-edit pointer ml-2" onClick={() => this.toggleModal("editProfileModal")} />
                                                    )}
                                                    no={() => <div></div>}
                                                />   
                                            </div>
                                            <span className={voucher.externalId ? "hrefStyle" : ''} onClick={() => { if (voucher.externalId) this.props.history.push({ pathname: `/ProfileDetails/${voucher.externalId}` }) }} >
                                                {voucher.name?.replace(/\s/g, '').length > 0 ? voucher.name : '-'}
                                            </span>                                       
                                        </Col>
                                        <Col>
                                            <div className="title-sm"><FormattedMessage id="VoucherDetails.Email" /></div>

                                            {(voucher.externalId || voucher.externalCrmId) && voucher.email ?
                                                voucher.email
                                                :
                                                <Input
                                                    type="text"
                                                    placeholder=''
                                                    value={voucher.email || ''}
                                                    name="email"
                                                    onChange={this.handleChange}
                                                />
                                            }
                                        </Col>
                                        {CheckAuthorization("voucherDetails:edit") ?
                                            <Col>
                                            <div className="title-sm"><FormattedMessage id="generic.status" /></div>
                                                <CustomSelect
                                                    isSearchable
                                                    placeholder=''
                                                    options={cardStatus}
                                                    required
                                                    onChange={(combo) => this.handleSelect(combo, "status")}
                                                    value={voucher.status ? cardStatus.find((op) => op.value === voucher.status) : ''}
                                                />
                                            </Col>
                                        : <Col/>}
                                    </FormGroup>

                                    <FormGroup row>
                                        <Col>
                                            <div className="title-sm"><FormattedMessage id="generic.Hotel" /></div>
                                            {voucher.hotelName}
                                        </Col>
                                        <Col>
                                            <div className="title-sm"><FormattedMessage id="VoucherDetails.CardType" /></div>
                                            {voucher.voucherType?.code}
                                        </Col>
                                        <Col>
                                            <div className="title-sm"><FormattedMessage id="VoucherDetails.CreatedAt" /></div>
                                            {voucher.createdAt && moment(voucher.createdAt).format("YYYY-MM-DD HH:mm")}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <div className="title-sm"><FormattedMessage id="VoucherDetails.CardId" /></div>
                                            {voucher.voucherId}
                                        </Col>
                                        <Col>
                                            <div className="title-sm"><FormattedMessage id="VoucherDetails.Number" /></div>
                                            {voucher.ordinal}
                                        </Col>
                                        <Col>
                                            <div className="title-sm"><FormattedMessage id="EmitVoucherModal.Validity" /></div>
                                            {CheckAuthorization("voucherDetails:edit") ?
                                                <DateRangePicker
                                                    startDate={voucher.validFrom ? moment(voucher.validFrom) : null}
                                                    startDateId="startDate"
                                                    isOutsideRange={day => day < moment()}
                                                    endDate={voucher.validUntil ? moment(voucher.validUntil) : null}
                                                    endDateId="endDate"
                                                    onDatesChange={({ startDate, endDate }) => this.handleDateRangeChange(startDate, endDate)}
                                                    focusedInput={this.state.focusedInput}
                                                    onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                                    small={true}
                                                    numberOfMonths={1}
                                                    showDefaultInputIcon={true}
                                                    renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                                    showClearDates={true}
                                                    required={true}
                                                />
                                            :
                                                <span>{voucher.validFrom ? moment(voucher.validFrom).format("YYYY-MM-DD") : null} <FormattedMessage id="VoucherDetails.ToDate" />  {voucher.validUntil ? moment(voucher.validUntil).format("YYYY-MM-DD") : null}</span>
                                            }
                                           
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col>
                                            <div className="title-sm"><FormattedMessage id="VoucherDetails.SalePrice" /></div>
                                            <FormatAmountNumber value={voucher.salePrice} />
                                        </Col>
                                        <Col>
                                            <div className="title-sm"><FormattedMessage id="VoucherDetails.VoucherAmount" /></div>
                                            <FormatAmountNumber value={voucher.voucherValue} />
                                        </Col>
                                        <Col>
                                            <div className="title-sm"><FormattedMessage id="VoucherDetails.InvoiceReference" /></div>
                                            {voucher.invoiceReference}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col sm={4}>
                                            <div className="title-sm"><FormattedMessage id="VoucherDetails.IssueCode" /></div>
                                            {voucher.issueCode || '-'}
                                        </Col>
                                        <Col>
                                            <div className="title-sm"><FormattedMessage id="VoucherDetails.Notes" /></div>
                                            <Input
                                                type="textarea"
                                                placeholder=''
                                                value={voucher.notes || ''}
                                                name="notes"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                </div>


                                <FormGroup row className="mt-4">
                                    <Col>
                                        <div className="title-sm">
                                            <FormattedMessage id="EmitVoucherModal.AssociatedReservation" />
                                            <Authorization
                                                perform="admin:view"
                                                yes={() => (
                                                    <i className="fas fa-edit pointer ml-2" onClick={() => this.toggleModal("editReservationModal")} />
                                                )}
                                                no={() => <div></div>}
                                            />   
                                        </div>
                                            
                                        <span className="hrefStyle" onClick={() => { if (voucher.voucherReservation?.resIdValue) this.props.history.push({ pathname: `/SearchReservation`, selectedHotelId: voucher.voucherReservation?.hotelId, selectedRes: voucher.voucherReservation.resIdValue }) }} >
                                            {voucher.voucherReservation?.resIdValue || '-'}
                                        </span>                                
                                    </Col>
                                    <Col>
                                        <div className="title-sm"><FormattedMessage id="ReservationDetails.ReservationDate" /></div>
                                        {voucher.voucherReservation?.resIdDate ? moment(voucher.voucherReservation.resIdDate).format("YYYY-MM-DD") : ''}
                                    </Col>
                                    <Col>
                                        </Col>
                                </FormGroup>


                                {voucher.isGift ?
                                    <div>
                                        <Row className="mt-3">
                                            <Col>
                                                <h6><FormattedMessage id="VoucherDetails.GiftInfo" /></h6>
                                            </Col>
                                        </Row>
                                        <hr className="my-1 mb-3" />
                                        <Row>
                                            <Col sm={8}>
                                                <FormGroup row>
                                                    <Col>
                                                        <div className="title-sm"><FormattedMessage id="VoucherDetails.From" /></div>
                                                        {voucher.from || ''}
                                                    </Col>
                                                    <Col>
                                                        <div className="title-sm"><FormattedMessage id="VoucherDetails.To" /></div>
                                                        {voucher.to || ''}
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col>
                                                        <div className="title-sm"><FormattedMessage id="VoucherDetails.Sender" /></div>
                                                        {voucher.sender || ''}
                                                    </Col>
                                                    <Col>
                                                        <div className="title-sm"><FormattedMessage id="VoucherDetails.Recipient" /></div>
                                                        {voucher.recipient || ''}
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup row>
                                                    <Col>
                                                        <div className="title-sm"><FormattedMessage id="VoucherDetails.Message" /></div>
                                                        {voucher.message || ''}
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    : ''}
                            </Card>
                        </Form>
                        <div>
                            <Card className="shadow border-0 h-100" body>
                                <Row className="mb-3 border-bottom pb-3">
                                    <Col>
                                        <i className="fas fa-wallet mr-2" /> <FormattedMessage id="VoucherDetails.Usage" />
                                    </Col>
                                    <Col className="text-right">
                                        <Authorization
                                            perform="admin:view"
                                            yes={() => (
                                                <Button className="btn-host btn-sm" onClick={() => this.toggleModal('addRedeemModal')} title={this.props.intl.formatMessage({ id: "VoucherDetails.AddManualRedeem" })}>
                                                    <i className="fas fa-plus " />
                                                </Button>
                                            )}
                                            no={() => <div></div>}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="border-right">
                                        {voucher.voucherType && voucher.voucherType.isPaymentMode === true ?
                                            <>
                                                <Row className="mb-4 flex-row ml-2">
                                                    <Col className="d-flex align-items-center">
                                                        <i className="icon-icon-vouchers fa-lg mr-3 text-muted"/>
                                                        <div>
                                                            <div className="title-sm"><FormattedMessage id="VoucherDetails.CardAmount" /></div>
                                                            <FormatAmountNumber value={voucher.voucherValue}/>
                                                        </div>
                                                    </Col>
                                                    <Col className="d-flex align-items-center">
                                                        <i className="fas fa-undo fa-lg mr-3 text-muted" />
                                                        <div>
                                                            <div className="title-sm"><FormattedMessage id="VoucherDetails.Refunded" /></div> 
                                                            <FormatAmountNumber value={refund ? refund : 0} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3 ml-2">
                                                    <Col className="d-flex align-items-center">
                                                        <i className="fas fa-file-invoice-dollar fa-lg mr-3 pl-1 text-muted" />
                                                        <div>
                                                            <div className="title-sm"><FormattedMessage id="VoucherDetails.Used" /></div>
                                                            
                                                            <b style={{ fontSize: '16px' }}><FormatAmountNumber value={redeems ? redeems : 0} /></b>
                                                        </div>
                                                    </Col>
                                                    <Col className="d-flex align-items-center">
                                                        <i className="fas fa-hand-holding-usd fa-lg mr-3 text-muted" />
                                                        <div>
                                                            <div className="title-sm"><FormattedMessage id="VoucherDetails.Left" /></div>
                                                            
                                                            <b className={left ? 'text-success ' : ''} style={{ fontSize: '16px' }}><FormatAmountNumber value={left} /></b>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                            :
                                            voucher.voucherType?.voucherRebates.map((el, k) => {
                                                const totalUsed = voucher.redeems?.filter(x => x.rebateId === el.id).reduce((acc, cur) => acc + cur.quantity, 0) || 0;
                                                const totalLeft = el.quantity - (totalUsed);

                                                return <Row key={k} className="mb-3">
                                                    <Col>
                                                        <Card body className="overbglight">
                                                            <div className="mb-2">
                                                                <i className="icon-icon-vouchers mr-2" /><b className="text-ellipsis">{el.description}</b>
                                                            </div>
                                                            <Row>
                                                                <Col>
                                                                    <div className="title-sm"><FormattedMessage id="VoucherDetails.Total" /></div>
                                                                    {el.quantity}
                                                                </Col>
                                                                <Col>
                                                                    <div className="title-sm"><FormattedMessage id="VoucherDetails.Used" /></div>
                                                                    {totalUsed}
                                                                </Col>
                                                                <Col>
                                                                    <div className="title-sm"><FormattedMessage id="VoucherDetails.Left" /></div>
                                                                    <b className={totalLeft ? 'text-success' : '' }>{totalLeft || 0}</b>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            })
                                        }
                                    </Col>
                                    <Col sm={8} className="ml-3">
                                        <Row className="mb-2 text-muted">
                                            <Col><FormattedMessage id="VoucherDetails.Invoice" /></Col>
                                            <Col><FormattedMessage id="VoucherDetails.Reference" /></Col>
                                            <Col><FormattedMessage id="generic.Hotel" /></Col>
                                            {voucher.voucherType?.isPaymentMode === true ?
                                                <Col><FormattedMessage id="VoucherDetails.Amount" /></Col>
                                                : 
                                                <>
                                                    <Col><FormattedMessage id="VoucherDetails.RebateCode" /></Col>
                                                    <Col><FormattedMessage id="VoucherDetails.Quantity" /></Col>
                                                </>
                                            }
                                            <Col><FormattedMessage id="VoucherDetails.Date" /></Col>
                                            <Col><FormattedMessage id="VoucherDetails.Notes" /></Col>
                                            <Col className="text-right col-1"></Col>
                                        </Row>

                                        {voucher.redeems?.length > 0 ?
                                            voucher.voucherType?.isPaymentMode === true ?
                                                voucher.redeems.map((el, k) =>
                                                    <Row className="py-1 overbglight align-items-center" key={k}>
                                                        <Col><i className="fas fa-file-invoice-dollar mr-2" />{el.invoice}</Col>
                                                        <Col>{el.targetReference}</Col>
                                                        <Col>{el.hotelName}</Col>
                                                        <Col className={el.amount < 0 ? 'text-danger font-weight-bold' : ''}> {el.amount}</Col>
                                                        <Col>{el.businessDate.substring(0, 10)}</Col>
                                                        <Col className="text-ellipsis">{el.notes}</Col>
                                                        <Col className="text-right col-1">
                                                            {el.amount > 0 && redeems != 0 ?
                                                                <Button className="btn-host btn-sm" onClick={_ => this.toggleRefundModal(el)} title={this.props.intl.formatMessage({ id: "VoucherDetails.Refund" })}>
                                                                    <i className="fas fa-undo" />
                                                                </Button>
                                                            : ''}
                                                        </Col>
                                                    </Row>
                                                )
                                                :
                                                voucher.redeems.map((el, k) =>
                                                    <Row className="py-1 overbglight align-items-center" key={k}>
                                                        <Col><i className="fas fa-file-invoice-dollar mr-2" />{el.invoice}</Col>
                                                        <Col> {el.targetReference}</Col>
                                                        <Col> {el.hotelName}</Col>
                                                        <Col> {el.rebateCode}</Col>
                                                        <Col className={el.quantity < 0 ? 'text-danger font-weight-bold' : ''}>{el.quantity} </Col>
                                                        <Col> {el.businessDate.substring(0, 10)}</Col>
                                                        <Col className="text-ellipsis">{el.notes}</Col>
                                                        <Col className="text-right col-1">
                                                            {el.amount > 0 ?
                                                                <Button className="btn-host btn-sm" onClick={_ => this.toggleRefundModal(el)} disabled={this.disableServiceRefund(el)} title={this.props.intl.formatMessage({ id: "VoucherDetails.Refund" })}>
                                                                    <i className="fas fa-undo" />
                                                                </Button>
                                                            : ''}
                                                        </Col>
                                                    </Row>
                                                )
                                        : <span className="d-flex justify-content-center mt-5"> Sem Utilizações</span>
                                    }
                                    </Col>
                                </Row>
                            </Card>
                        </div>

                        {this.state.voucher.refunds ?
                            <Card className="shadow border-0 h-100 mt-3" body>
                                <Row className="mb-3 border-bottom pb-3">
                                    <Col>
                                        <i className="fas fa-undo mr-2" /> <FormattedMessage id="VoucherDetails.Refunds" />
                                    </Col>
                                </Row>
                                <div className="mt-3" >
                                    <Row className="mb-2 text-muted">
                                        <Col><FormattedMessage id="VoucherDetails.Date" /></Col>
                                        <Col><FormattedMessage id="VoucherDetails.Amount" /></Col>
                                        <Col><FormattedMessage id="VoucherDetails.Reason" /></Col>
                                        <Col><FormattedMessage id="VoucherDetails.Notes" /></Col>
                                    </Row>

                                    {this.state.voucher.refunds && this.state.voucher.refunds.map((el, k) =>
                                        <Row className="py-1 overbglight align-items-center" key={k}>
                                            <Col>{el.createdAt}</Col>
                                            <Col>{el.amount}</Col>
                                            <Col>{el.reason}</Col>
                                            <Col>{el.notes}</Col>
                                        </Row>
                                    )}
                                </div>
                            </Card>
                        : ''}
                    </div>
                    : ''}


                {addRedeemModal ?
                    <AddRedeem
                        modal={addRedeemModal}
                        toggleModal={() => this.toggleModal("addRedeemModal")}
                        voucher={voucher}
                        getVoucherDetails={() => this.getVoucherDetails(this.props.match.params.id)}
                    />
                    : ''}

                {refundModal ?
                    <RefundModal
                        modal={refundModal}
                        toggleModal={this.toggleRefundModal}
                        voucher={voucher}
                        maxQuantity={this.state.maxQuantity}
                        redeem={this.state.redeem}
                        getVoucherDetails={() => this.getVoucherDetails(this.props.match.params.id)}
                    />
                    : ''}

                {printVoucherModal ?
                    <PrintVoucherModal
                        toggleModal={() => this.toggleModal("printVoucherModal")}
                        modal={printVoucherModal}
                        voucherId={this.state.voucher.id}
                        voucher={this.state.voucher}
                    />
                    : ''}

                {editProfileModal ?
                    <AssociateProfile
                        block={this.state.blockAssociation}
                        error={this.state.errorAssociation}
                        associateProfileOrReservation={this.associateProfileOrReservation}
                        selectProfile={this.selectProfile}
                        profile={this.state.profile}
                        toggleModal={() => this.toggleModal("editProfileModal")}
                        modal={editProfileModal}
                    />
                    : ''}

                {editReservationModal ?
                    <AssociateReservation
                        block={this.state.blockAssociation}
                        error={this.state.errorAssociation}
                        associateProfileOrReservation={this.associateProfileOrReservation}
                        hotelId={this.state.voucher.hotelId}
                        toggleModal={() => this.toggleModal("editReservationModal")}
                        modal={editReservationModal}
                    />
                    : ''}
            </StyledCard>
        );
    }
}


export default injectIntl(VoucherDetails);