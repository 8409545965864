import moment from 'moment-timezone';
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import GoogleAnonymousProfile from './GoogleAnonymousProfile';

export default class GooglePost extends Component {
    render() {
        const { profilePhoto, locationDetail, googlePost, isNew, toggleEdit, toggleDelete } = this.props;

        const startDate = googlePost?.event?.schedule?.startDate &&
            moment(`${googlePost.event.schedule.startDate.year}-${googlePost.event.schedule.startDate.month}-${googlePost.event.schedule.startDate.day}`, 'YYYY-MM-DD');
        const endDate = googlePost?.event?.schedule?.endDate &&
            moment(`${googlePost.event.schedule.endDate.year}-${googlePost.event.schedule.endDate.month}-${googlePost.event.schedule.endDate.day}`, 'YYYY-MM-DD');

        return (
            googlePost ?
                <div className='shadow' style={{ padding: '1rem', borderRadius: '10px' }}>
                    <Row>
                        <Col>
                            <div className='d-flex align-items-center'>
                                <GoogleAnonymousProfile
                                    profilePhotoUri={profilePhoto}
                                    isMerchant={profilePhoto ? false : true}
                                />
                                <div>
                                    <div>{locationDetail?.title}</div>
                                    <div className='text-muted' style={{ fontSize: '0.8em' }}>
                                        {googlePost.updateTime ?
                                            moment(googlePost.updateTime).isBefore(moment().add(2, 'day')) ?
                                                moment().diff(moment(googlePost.updateTime), 'hours') > 0 ? 
                                                    <div>
                                                        <span className='mr-1'>{moment().diff(moment(googlePost.updateTime), 'hours')}</span>
                                                        <FormattedMessage id="GoogleBusinessConfig.Hours" defaultMessage="hours ago"/>
                                                    </div>
                                                    :
                                                    <FormattedMessage id="GoogleBusinessConfig.Today" defaultMessage="today"/>
                                            :
                                                <span>{moment(googlePost.updateTime).format('MMM DD, YYYY')}</span>
                                        :
                                            <span>{moment().format('MMM DD, YYYY')}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className='col-1 text-right'>
                            {isNew ?
                                <div>
                                    <i className="fas fa-ellipsis-v" style={{ color: "#757575" }}/>
                                </div>
                            :
                                <UncontrolledDropdown>
                                    <DropdownToggle style={{ background: 'none', border: 'none', color: 'unset', padding: 'unset', width: '10px' }}>
                                        <i className="fas fa-ellipsis-v"/>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={_ => toggleEdit(googlePost.name)}>
                                            <i className="fas fa-edit mr-2"/>
                                            <FormattedMessage id="stripo.edit"/>
                                        </DropdownItem>
                                        <DropdownItem onClick={_ => toggleDelete(googlePost.name)}>
                                            <div style={{ color: '#A80C19' }}>
                                                <i className="mr-2 far fa-trash-alt"/>
                                                <FormattedMessage id="stripo.delete"/>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            }
                        </Col>
                    </Row>
                    {googlePost.media && googlePost.media.length > 0 ?
                        <Row className='mt-2'>
                            <Col>
                                {googlePost.media.length > 1 ?
                                    <div style={{ overflow: 'auto', whiteSpace: 'nowrap', maxHeight: '300px' }}>
                                        {googlePost.media.map((el, key) =>
                                            <div key={key} style={{
                                                backgroundImage: `url(${el.googleUrl??el.sourceUrl})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                width: '90%',
                                                display: 'inline-block',
                                                marginBottom: '-5px',
                                                marginRight: '8px',
                                                paddingTop: '50%',
                                                position: 'relative'
                                            }}/>
                                        )}
                                    </div>
                                :
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        {googlePost.media[0]?.mediaFormat === "PHOTO" ?
                                            <img src={googlePost.media[0]?.googleUrl??googlePost.media[0]?.sourceUrl}
                                                style={{ backgroundColor: "#fff", display: "block", margin: "0 auto", maxWidth: "90%", maxHeight: '300px' }}
                                            />
                                        :
                                            <video style={{ width: '100%' }} height="300" controls="controls autoplay">
                                                <source src={googlePost.media[0]?.googleUrl??googlePost.media[0]?.sourceUrl} type="video/mp4" />
                                            </video>
                                        }
                                    </div>
                                }
                            </Col>
                        </Row>
                    :''}
                    <Row className='mt-2'>
                        <Col>
                            {googlePost.topicType !== "STANDARD" ?
                                <div style={{ fontSize: '1.4em', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                                    <div>
                                        <div>
                                            {googlePost.event?.title ?
                                                <span>{googlePost.event.title}</span>
                                            :
                                                <FormattedMessage id="GoogleBusinessConfig.OfferTitleGeneric"/>
                                            }
                                        </div>
                                        <div style={{ fontSize: '0.7em' }}>
                                            {startDate && endDate ?
                                                <FormattedMessage
                                                    id="GoogleBusinessConfig.OfferValidaty"
                                                    values={{ start: startDate?.format('MMM DD'), end: endDate?.format('MMM DD') }}
                                                />
                                            :''}
                                        </div>
                                    </div>
                                    {googlePost.topicType !== "EVENT" ?
                                        <div>
                                            <i style={{ color: '#fbbc04' }} className="fas fa-tag"/>
                                        </div>
                                    :''}
                                </div>
                            :''}
                            <div style={{ fontSize: '0.9em' }}>
                                {googlePost.summary ? 
                                    <span>{googlePost.summary}</span>
                                :
                                    <FormattedMessage id="GoogleBusinessConfig.Description"/>
                                }
                            </div>
                        </Col>
                    </Row>
                    {googlePost.offer?.couponCode && googlePost.topicType === "OFFER" ?
                        <Row className='mt-2'>
                            <Col className='bg-white d-flex align-items-center justify-content-center p-2' style={{ borderRadius: '10px' }}>
                                <div className='text-center'>
                                    <div style={{ fontWeight: '200' }}>
                                        <FormattedMessage id="GoogleBusinessConfig.ShowCouponCode"/>
                                    </div>
                                    <div className='my-1' style={{ fontSize: '1.2em', fontWeight: '600' }}>
                                        {googlePost.offer.couponCode}
                                    </div>
                                    <div style={{ fontSize: '0.7em', fontWeight: '600' }}>
                                        {startDate && endDate ?
                                            <FormattedMessage
                                                id="GoogleBusinessConfig.OfferValidaty"
                                                values={{ start: startDate?.format('MMM DD'), end: endDate?.format('MMM DD') }}
                                            />
                                        :''}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    :''}
                    <Row className='mt-2'>
                        {(googlePost.callToAction && googlePost.callToAction.actionType !== 'NONE') || googlePost.offer?.redeemOnlineUrl  ?
                            <Col>
                                <div style={{ color: '#0665ff', cursor: 'pointer' }}>
                                    <FormattedMessage id={`GoogleBusinessConfig.${googlePost.offer?.redeemOnlineUrl ? 'RedeemOnline' : googlePost.callToAction.actionType}`}/>
                                </div>
                            </Col>
                        :''}
                        <Col className='text-right'>
                            <i className="fas fa-share-alt" style={{ color: "#757575" }}/>
                        </Col>
                    </Row>
                    {googlePost.offer?.termsConditions && googlePost.topicType === "OFFER" ?
                        <Row className='mt-2 text-muted' style={{ borderTop: '1px solid #fff', fontSize: '0.8em' }}>
                            <Col>
                                {googlePost.offer.termsConditions}
                            </Col>
                        </Row>
                    :''}
                </div>
            :''
        )
    }
}
