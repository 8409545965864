import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Modal, ModalBody, Input } from 'reactstrap';
import { getRunPeriodList } from "../Base/ReferenceDataFunctions";
import { handleNotification } from "../Base/Notification";
import { BlankCard } from '../Base/CommonUIComponents';
import CustomSelect from "../Base/CustomSelect";
import { putAPI } from "../Base/API";

export class ScheduleDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            schedule: this.props.schedule ? this.props.schedule : {}
        };
    }
    
    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            schedule: {
                ...this.state.schedule,
                [name]: value
            }
        })
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
            schedule: {
                ...this.state.schedule,
                [name]: combo ? combo.value : null,
                day: null,
                weekDays: []
            }
        })
    };
    
    handleWeekDays = (combo) => {
        var days = [false, false, false, false, false, false, false];

        combo && combo.map(el => {
            for (var i = 0; i < days.length; i++) {
                if (el.value === i) {
                    days[i] = true;
                }
            }
        })

        this.setState({
            schedule: { ...this.state.schedule, weekDays: days }
        })
    };

    addRule = (e) => {
        e.preventDefault();
        this.setState({ block: true });
        var body = { ...this.state.schedule };
        body.dataQualityTemplateId = this.props.templateId;
        body.weekDays = body.weekDays ? body.weekDays : [];

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                    return;
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="ScheduleDetails.ScheduleSavedSuccessfully" />, <FormattedMessage id="generic.success" />);
                    this.props.updateTable(data.response[0], 'schedule');
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/DataQuality/v1/ScheduleTemplate`, JSON.stringify({ request: body }));
    }

    render() {
        const { block, error, schedule } = this.state;

        const weekDays = [
            {
                value: 0,
                label: this.props.intl.formatMessage({ id: "DataQualityTemplateDetail.Monday" })
            },
            {
                value: 1,
                label: this.props.intl.formatMessage({ id: "DataQualityTemplateDetail.Tuesday" })
            },
            {
                value: 2,
                label: this.props.intl.formatMessage({ id: "DataQualityTemplateDetail.Wednesday" })
            },
            {
                value: 3,
                label: this.props.intl.formatMessage({ id: "DataQualityTemplateDetail.Thursday" })
            },
            {
                value: 4,
                label: this.props.intl.formatMessage({ id: "DataQualityTemplateDetail.Friday" })
            },
            {
                value: 5,
                label: this.props.intl.formatMessage({ id: "DataQualityTemplateDetail.Saturday" })
            },
            {
                value: 6,
                label: this.props.intl.formatMessage({ id: "DataQualityTemplateDetail.Sunday" })
            }
        ];

        return (
            <Modal isOpen={this.props.modal} fade={false} size={"lg"} style={{ minWidth: 0 }} >
                <Button onClick={this.props.toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <Form onSubmit={this.addRule}>
                            <Row className="mb-3">
                                <Col>
                                    <h5> <FormattedMessage id="ScheduleDetails.Title" /></h5>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" type="submit"> <i className="fas fa-save" /></Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}> <FormattedMessage id="ScheduleDetails.RunPeriod" /></Label>
                                        <Col sm={8}>
                                            <CustomSelect
                                                options={getRunPeriodList()}
                                                required={true}
                                                isSearchable
                                                placeholder={""}
                                                onChange={(e) => this.handleChangeSelect(e, "period")}
                                                value={schedule.period ? getRunPeriodList().find(o => o.value === schedule.period) : null}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {schedule.period === 'Monthly' ? 
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Label sm={4}> <FormattedMessage id="ScheduleDetails.Day" /></Label>
                                            <Col sm={8}>
                                                <Input
                                                    type="number"
                                                    required
                                                    name="day"
                                                    onChange={(e) => this.handleChange(e)}
                                                    value={schedule.day ? schedule.day : ''}
                                                    min="1"
                                                    max="31"
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            : schedule.period === 'Weekly' ?
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Label sm={4}> <FormattedMessage id="ScheduleDetails.WeekDays" /></Label>
                                            <Col sm={8}>
                                                <CustomSelect
                                                    options={weekDays}
                                                    required={true}
                                                    isClearable
                                                    isSearchable
                                                    placeholder={""}
                                                    onChange={(e) => this.handleWeekDays(e)}
                                                    isMulti
                                                    value={schedule.weekDays ? weekDays.filter((o, i) => schedule.weekDays[i]) : null}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            : ''}
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(ScheduleDetails)