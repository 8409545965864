import React from "react";
import { Handle, Position } from "react-flow-renderer";

function CustomNode({ data, type }) {
    return (
        <div>
            <div className="text-updater-node">
                {type !== 'input' ? 
                    <Handle
                        type="target"
                        position={Position.Left}
                        isConnectable={data.isEditable}
                        style={{ opacity: data.isEditable ? 1 : 0 }}
                    />
                : ''}
                {data.label}
                <Handle
                    type="source"
                    position={Position.Right}
                    isConnectable={data.isEditable}
                    style={{ opacity: data.isEditable ? 1 : 0 }}
                />
            </div>
            {data.completedAt ?
                <div className="text-center" style={{ position: 'absolute', top: '55px', right: '0px', fontSize: '0.5rem' }}>
                    {data.completedAt}
                </div>
            :''}
        </div>
    );
}

export default CustomNode;
