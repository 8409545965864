import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import { getCustomMarketingType } from '../../Base/ReferenceDataFunctions';
import LinkedCampaignsConfig from '../Common/LinkedCampaignsConfig';
import { UniqueCampaignsDates } from '../Common/MarketingFunctions';

class CustomStep3Campaigns extends Component {

    render() {
        const { filters, campaign } = this.props;

        return (
            <LinkedCampaignsConfig
                campaign={campaign}
                filters={filters}
                handleLinkMarketing={this.props.handleLinkMarketing}
                handleLinkCampCriteria={this.props.handleLinkCampCriteria}
                cleanFormData={this.props.cleanFormData}
                handleMultiSelect={this.props.handleMultiSelect}
                campaignsOptions={getCustomMarketingType()}
                handleSelectFilters={this.props.handleSelectFilters}
            >
                <Row>
                    <Col sm={8}>
                        <Row className="mt-2">
                            <div className="px-3 col-3"> <h5><FormattedMessage id="CustomCampaign.StayDate" /></h5></div>
                            <div className="px-3 col-3"> <h5><FormattedMessage id="CustomCampaign.NoStayDate" /></h5></div>
                            <div className="px-3 col-3"> <h5><FormattedMessage id="CustomCampaign.ReservationDate" /></h5></div>
                            <div className="px-3 col-3"> <h5><FormattedMessage id="CustomCampaign.NoReservationDate" /></h5></div>
                        </Row>

                        <div className="pl-3" style={{ paddingRight: '13px' }}>
                            <UniqueCampaignsDates
                                stayFrom={filters.stayFrom}
                                stayTo={filters.stayTo}
                                bookingFromDate={filters.bookingFromDate}
                                bookingToDate={filters.bookingToDate}
                                noStayFrom={filters.noStayFrom}
                                noStayTo={filters.noStayTo}
                                noBookingFromDate={filters.noBookingFromDate}
                                noBookingToDate={filters.noBookingToDate}
                                handleDates={this.props.handleDates}
                                intl={this.props.intl}
                            />
                        </div>
                    </Col>
                </Row>
            </LinkedCampaignsConfig>
        );
    }
}
export default injectIntl(CustomStep3Campaigns)