import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

class SalesProcessDefaultPage extends Component {
    
    render() {
        const { children, showSelect, mainValue, height, text, icon, noValueText } = this.props;

        return (
            <div>
                {showSelect && mainValue ?
                    <div className="text-center d-flex align-items-center flex-column justify-content-center" style={{ height: (height || "60vh") }}>
                        <span className="mb-3"> <i className={icon + " text-muted"} style={{ fontSize: "80px" }} /> </span>
                        <FormattedMessage id={text} />
                    </div>
                : !mainValue ?
                    <div className="text-center d-flex align-items-center flex-column justify-content-center" style={{ height: (height || "60vh") }}>
                        <div className="mb-3">
                            <i className={icon + " text-muted"} style={{ fontSize: "80px" }}/>
                        </div>
                        <FormattedMessage id={noValueText}/>
                    </div>
                :
                    children
                }
            </div>
        );
    }
}

export default SalesProcessDefaultPage;
