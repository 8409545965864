import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, CustomInput, Input } from 'reactstrap';
import { handleNotification } from '../../Base/Notification';
import { BlankCard, StyledModal } from '../../Base/CommonUIComponents';
import CreatableSelect from 'react-select/creatable';
import CustomSelect from "../../Base/CustomSelect";
import { postAPI, getAPI, deleteAPI } from '../../Base/API';
import { getCustomFieldsTypes } from '../../Base/ReferenceDataFunctions';
import ConfirmActionModal from '../../Base/ConfirmActionModal';

class CustomFieldModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            deleteModal: false,
            customField: this.props.selectedCustomField || { listElements : [] }
        };
    }
    

    saveCustomField = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const body = { ...this.state.customField };

        if (!body.isList) delete body.listElements

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="CustomFields.CustomFieldSaved" />, <FormattedMessage id="generic.success" />);

                    this.props.updateCustomField(data.response[0], false);
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/CustomFieldConfig/v1`, JSON.stringify({ request: body }));
    }

    deleteCustomField = (e) => {
        if (e) e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="CustomFields.CustomFieldRemoved" />, <FormattedMessage id="generic.success" />);

                    this.props.updateCustomField({ id: this.state.customField.id }, true);
                    this.props.toggleModal();
                }
            }
        }, `/api/gms/CustomFieldConfig/v1/${this.state.customField.id}`);
    }

    toggleModal = (modal) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }));
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            customField: {
                ...this.state.customField,
                [name]: value
            }
        })
    }

    handleSwitch = (evt) => {
        const { name, checked } = evt.target;

        this.setState({
            customField: {
                ...this.state.customField,
                [name]: checked
            }
        });
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
            customField: {
                ...this.state.customField,
                [name]: combo ? combo.value : null
            }
        })
    };

    handleSelectCreate = (label) => {
        const { listElements } = this.state.customField;

        listElements.push(label);

        this.setState({
            customField: {
                ...this.state.customField,
                listElements
            }
        });
    };

    render() {
        const { customField, deleteModal } = this.state;
        const { modal, toggleModal } = this.props;


        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="xl">
                <BlankCard block={this.state.block} error={this.state.error}>
                    <Form onSubmit={this.saveCustomField}>
                        <Row className="mb-4">
                            <Col>
                                <h5>{this.state.customField.id ? <FormattedMessage id="CustomFields.EditCustomField" /> : <FormattedMessage id="CustomFields.AddCustomField" />}</h5>
                            </Col>
                            <Col className="text-right col-3">
                                {this.state.customField.id ?
                                    <Button className="btn-sm btn-host mr-2" onClick={() => this.toggleModal("deleteModal")}>
                                        <i className="fas fa-trash-alt" />
                                    </Button>
                                    : ''}
                                <Button className="btn-sm btn-host" type="submit">
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Label sm={2}> <FormattedMessage id="generic.Code" /> </Label>
                            <Col sm={4}>
                                <Input
                                    type="text"
                                    name="code"
                                    required
                                    onChange={(e) => this.handleChange(e)}
                                    value={customField.code || ''}
                                    max={100}
                                />
                            </Col>
                            <Label sm={2}> <FormattedMessage id="generic.active" /></Label>
                            <Col sm={4} className="d-flex align-items-center justify-content-start">
                                <CustomInput
                                    type="switch"
                                    id="active"
                                    name="active"
                                    onChange={this.handleSwitch}
                                    checked={customField.active || false}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}> <FormattedMessage id="generic.Description" /> </Label>
                            <Col sm={10}>
                                <Input
                                    type="text"
                                    name="description"
                                    required
                                    onChange={(e) => this.handleChange(e)}
                                    value={customField.description || ''}
                                    max={200}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}> <FormattedMessage id="CustomFields.DataType" /> </Label>
                            <Col sm={4}>
                                <CustomSelect
                                    options={getCustomFieldsTypes(this.props.intl)}
                                    required
                                    isSearchable
                                    placeholder=""
                                    onChange={(e) => this.handleChangeSelect(e, "dataType")}
                                    value={getCustomFieldsTypes(this.props.intl).find(el => el.value === customField.dataType) || ''}
                                />
                            </Col>
                            <Label sm={2}> <FormattedMessage id="CustomFields.SortOrder" /> </Label>
                            <Col sm={4}>
                                <Input
                                    type="number"
                                    name="sortOrder"
                                    onChange={(e) => this.handleChange(e)}
                                    value={customField.sortOrder || ''}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}> <FormattedMessage id="CustomFields.RegexValidation" /> </Label>
                            <Col sm={4}>
                                <Input
                                    type="text"
                                    name="regexValidation"
                                    onChange={(e) => this.handleChange(e)}
                                    value={customField.regexValidation || ''}
                                    max={4000}
                                />
                            </Col>
                            <Label sm={2}> <FormattedMessage id="CustomFields.InputMaxLen" /> </Label>
                            <Col sm={4}>
                                <Input
                                    type="number"
                                    name="inputMaxLen"
                                    required={customField.dataType && customField.dataType === "String"}
                                    onChange={(e) => this.handleChange(e)}
                                    value={customField.inputMaxLen || ''}
                                    max={100}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}> <FormattedMessage id="CustomFields.IsList" /></Label>
                            <Col sm={4} className="d-flex align-items-center justify-content-start">
                                <CustomInput
                                    type="switch"
                                    id="isList"
                                    name="isList"
                                    onChange={this.handleSwitch}
                                    checked={customField.isList || false}
                                />
                            </Col>
                            {customField.isList &&
                                <>
                                    <Label sm={2}> <FormattedMessage id="CustomFields.IsMultipleSelection" /></Label>
                                    <Col sm={4} className="d-flex align-items-center justify-content-start">
                                        <CustomInput
                                            type="switch"
                                            id="isMultipleSelection"
                                            name="isMultipleSelection"
                                            onChange={this.handleSwitch}
                                            checked={customField.isMultipleSelection || false}
                                        />
                                    </Col>
                                </>
                            }
                        </FormGroup>
                        <FormGroup row>
                            {customField.isList &&
                                <>
                                    <Label sm={2}> <FormattedMessage id="CustomFields.ListElements" /> </Label>
                                    <Col sm={4}>
                                        <CreatableSelect
                                            isClearable
                                            isMulti
                                            options={customField.listElements ? customField.listElements.map(el => ({ value: el, label: el })) : []}
                                            onChange={(e) => this.handleChange(e, "listElements")}
                                            onCreateOption={this.handleSelectCreate}
                                            formatCreateLabel={userInput => <span><FormattedMessage id="generic.Create" /> "{userInput}"</span>}
                                            required={customField.isList}
                                            value={customField.listElements ? customField.listElements.map(el => ({ value: el, label: el })) : []}
                                            placeholder=""
                                        />
                                    </Col>
                                </>
                            }

                            {customField.isMultipleSelection &&
                                <>
                                    <Label sm={2}> <FormattedMessage id="CustomFields.MultiSelectionSeparator" /> </Label>
                                    <Col sm={4}>
                                        <Input
                                            type="text"
                                            name="multiSelectionSeparator"
                                            required={customField.isMultipleSelection}
                                            onChange={(e) => this.handleChange(e)}
                                            value={customField.multiSelectionSeparator || ''}
                                            max={50}
                                        />
                                    </Col>
                                </>
                            }
                        </FormGroup>
                    </Form>
                </BlankCard>

                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={() => this.toggleModal("deleteModal")}
                        actionFunction={this.deleteCustomField}
                        text={<FormattedMessage id="CustomFields.ConfirmDeleteText" values={{ code: customField.code }} />}
                    />
                    : ''}
            </StyledModal>
        );
    }
}

export default injectIntl(CustomFieldModal)