import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { KebabMenu, StyledCard } from "../../Base/CommonUIComponents";
import { Button, Col, Row, Card } from "reactstrap";
import { getAPI, deleteAPI } from "../../Base/API";
import { handleNotification } from '../../Base/Notification';
import ConfirmActionModal from '../../Base/ConfirmActionModal';
import { CardTypeConfigModal } from "./CardTypeConfigModal";

export class LoyaltyCardTypes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            loyaltyTypeConfigs: [],
            wallets: []
        };
    }

    componentDidMount() {
        this.getWallets();
    }

    getWallets = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({
                    wallets: data.data ? data.data.map(wlt => {
                        const walletOpt = {
                            value: wlt.id,
                            label: wlt.programTypeCode
                        };
                        return walletOpt;
                }) : [] }, this.getLoyaltyCardTypeConfigs);
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Wallet/v1`);
    }

    getLoyaltyCardTypeConfigs = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ error: errorMessage, block: false, loyaltyTypeConfigs: data.response });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/LoyaltyCard/v1/LoyaltyCardTypeConfig`);
    }

    updateLoyaltyTypeConfigs = (newLoyaltyTypeConfig) => {
        const { loyaltyTypeConfigs } = this.state;

        const idx = loyaltyTypeConfigs.findIndex(config => config.code === newLoyaltyTypeConfig.code);
        if (idx >= 0) {
            loyaltyTypeConfigs[idx] = newLoyaltyTypeConfig;
        }
        else {
            loyaltyTypeConfigs.push(newLoyaltyTypeConfig);
        }
        this.setState({ loyaltyTypeConfigs });
    }

    toggleModal = (modal, value) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            selectedConfig: value
        }));
    }

    handleDelete = (selectedConfigCode) => {
        const { loyaltyTypeConfigs } = this.state;
        this.setState({ block: true });
        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false, error: null });
                }
                else {
                    handleNotification(data, <FormattedMessage id="LoyaltyCardTypes.Success" />);
                    const idx = loyaltyTypeConfigs.findIndex(config => config.code === selectedConfigCode);
                    if (idx >= 0) {
                        loyaltyTypeConfigs.splice(idx, 1);
                    }
                    this.setState({ block: false, error: null, loyaltyTypeConfigs }, () => this.toggleModal('deleteModal', null));
                }
            }
            else {
                this.setState({ block: false, error: null });
            }
        }, `/api/gms/LoyaltyCard/v1/LoyaltyCardTypeConfig/${selectedConfigCode}`)
    }

    render() {
        const { block, error, loyaltyTypeConfigs, selectedConfig, modal, deleteModal, wallets } = this.state;

        function getWalletLabel(walletId, wallets) {
            const wallet = wallets.find(wlt => wlt.value === walletId);
            return wallet ? wallet.label : '-';
        }

        return (
            <StyledCard block={block} error={error}>
                <Row className="mb-2">
                    <Col>
                        <h5>
                            <i className="fas fa-id-card-alt mr-2" />
                            <FormattedMessage id="LoyaltyCardTypes.Title" />
                        </h5>
                    </Col>
                    <Col className="text-right col-2">
                        <Button className="btn-sm btn-host mr-2" onClick={() => this.toggleModal('modal', null)}>
                            <i className="fas fa-plus" />
                        </Button>
                    </Col>
                </Row>
                <Row className="p-2" >
                    {
                        loyaltyTypeConfigs.map((loyaltyTypeConfig, key) =>
                            <Col key={key} className="col-3 p-2" style={{ padding: '1.2rem', borderRadius: '5px' }}>
                                <Card className="border-0 shadow h-100 p-2 px-3">
                                    <Row>
                                        <Col>
                                            <div className="title-sm"> <FormattedMessage id="LoyaltyCardTypes.Code" /> </div>
                                            <b>{loyaltyTypeConfig.code}</b>
                                        </Col>
                                        <Col className="col-1 pr-2 text-right">
                                            <KebabMenu
                                                editFunction={() => this.toggleModal('modal', loyaltyTypeConfig)}
                                                deleteFunction={() => this.toggleModal('deleteModal', loyaltyTypeConfig)}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="mt-3">
                                        <div className="title-sm"> <FormattedMessage id="LoyaltyCardTypes.Description" /> </div>
                                        <span title={loyaltyTypeConfig.description} className="text-ellipsis" style={{ display: 'block' }}>
                                            {loyaltyTypeConfig.description}
                                        </span>
                                    </div>
                                    <div className="mt-3">
                                        <div className="title-sm"> <FormattedMessage id="LoyaltyCardTypes.Wallet" /> </div>
                                        <span title={loyaltyTypeConfig.walletId} className="text-ellipsis" style={{ display: 'block' }}>
                                            {getWalletLabel(loyaltyTypeConfig.walletId, wallets)}
                                        </span>
                                    </div>
                                </Card>
                            </Col>
                        )
                    }
                </Row>
                {
                    modal ?
                        <CardTypeConfigModal
                            modal={modal}
                            toggleModal={() => this.toggleModal('modal', null)}
                            typeConfig={selectedConfig}
                            loyaltyTypeConfigs={loyaltyTypeConfigs}
                            wallets={wallets}
                            updateLoyaltyTypeConfigs={this.updateLoyaltyTypeConfigs}
                        />
                        :
                        ''
                }
                {
                    deleteModal ?
                        <ConfirmActionModal
                            modal={deleteModal}
                            toggleModal={() => this.toggleModal('deleteModal', null)}
                            actionFunction={() => this.handleDelete(selectedConfig.code)}
                            text={<FormattedMessage id="LoyaltyCardTypes.ConfirmDeleteText" values={{ type: selectedConfig.code }} />}
                        />
                        :
                        ''
                }
            </StyledCard>
        );
    }
}