import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Card, Col, Row, Breadcrumb, BreadcrumbItem, Form, Input, Badge } from 'reactstrap';
import { handleNotification } from "../Base/Notification";
import { StyledCard, ActiveInactiveStatusBadge, ActiveInactiveStatusCombo } from "../Base/CommonUIComponents";
import { getAPI, deleteAPI } from "../Base/API";
import CustomSelect from "../Base/CustomSelect";

import Authorization from '../Base/Authorization';

export class AutomationList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            modal: false,
            editMode: false,
            automations: [],
            egoiLists: [],
            limit: 10,
            offset: 0,
            totalItems: 0,
            status: null
        };
    }

    componentDidMount() {
        this.getEgoiLists();
    }

    getEgoiLists = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false, egoiLists: [] });
                    return;
                }

                if (data.items && data.items.length > 0) {
                    let egoiList = [];

                    data.items && data.items.forEach(list => {
                        egoiList.push({ value: list.list_id, label: list.public_name })
                    })

                    this.setState({ egoiLists: egoiList }, () => this.getAutomations());
                }
            }
        }, `/api/gms/Marketing/v1/egoi/lists?limit=100&offset=0`);
    }

    getAutomations = () => {
        let params = `?limit=${this.state.limit}&offset=${this.state.offset}`;

        params += this.state.status !== null ? `&status=${this.state.status === true ? 'active' : 'inactive'}` : '';
        params += this.state.listId ? `&listId=${this.state.listId}` : '';
        params += this.state.title ? `&title=${this.state.title}` : '';

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({
                    automations: data.items ? data.items : [],
                    totalItems: data.total_items ? data.total_items : 0
                });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/automations` + params);
    }

    removeAutomation = (e, automationId) => {
        e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.response && data.response === 'success') {
                    handleNotification(data, <FormattedMessage id="AutomationList.AutomationRemoved" />, <FormattedMessage id="generic.success" />);

                    this.setState({
                        offset: 0,
                        totalItems: 0
                    }, () => this.getAutomations());
                }
            }

            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/automations/${automationId}`);
    }

    getListName = (listId) => {
        const list = this.state.egoiLists.find(l => l.value === listId);

        return list ? list.label : listId
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
                [name]: value
        });
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            offset: this.state.offset + value
        }, () => this.getAutomations());
    }

    doSearch = (e) => {
        e.preventDefault();

        this.setState({
            block: true,
            offset: 0,
            totalItems: 0
        }, () => this.getAutomations());
    }

    render() {
        const { block, error, automations, limit, totalItems, offset, egoiLists } = this.state;

        return (
            <StyledCard block={block} error={error} >
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/MarketingConfigEgoi"> Config</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Automation</BreadcrumbItem>
                    </Breadcrumb>
                </div>

                <Form onSubmit={(e) => this.doSearch(e)}>
                    <Row className="mb-3 pr-3 align-items-center">
                        <Col className="col-3">
                            <ActiveInactiveStatusCombo
                                name='status'
                                onChangeFunc={this.handleSelect.bind(this)}
                                placeholder={this.props.intl.formatMessage({ id: "generic.status" })}
                                required={false}
                            />
                        </Col>
                        <Col className="col-3 px-1">
                            <CustomSelect
                                options={egoiLists}
                                isClearable
                                isSearchable
                                placeholder={this.props.intl.formatMessage({ id: "EgoiList.List" })}
                                onChange={this.handleSelect.bind(this, "listId")}
                            />
                        </Col>
                        <Col className="col-3">
                            <Input
                                type="text"
                                name="title"
                                onChange={(e) => this.handleChange(e)}
                                placeholder={this.props.intl.formatMessage({ id: "AutomationList.Title" })}
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className="btn- btn-host btn-small btn-sm" type="submit">
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                {automations && automations.length > 0 ?
                    automations.map((automation, key) =>
                        <Card className="border-0 mb-2 p-3 shadow" key={key} >
                            <Row>
                                <Col>
                                    <h4><b>{automation.title}</b></h4>
                                </Col>

                                <Authorization
                                    perform="automationList:delete"
                                    yes={() => (
                                        <Col className="text-right col-1">
                                            <Button className="btn btn-sm btn-host" onClick={(e) => this.removeAutomation(e, automation.automation_id)}>
                                                <i className="fas fa-trash-alt" />
                                            </Button>
                                        </Col>
                                    )}
                                    no={() => <div></div>}
                                />
                            </Row>
                            <Row>
                                <Col>
                                    <div><ActiveInactiveStatusBadge status={automation.status === 'active' ? true : false} /> </div>
                                    <div><Badge color={automation.allow_multiple_contacts ? 'primary' : 'secondary'} className="p-1"> <FormattedMessage id="AutomationList.AllowMultipleContacts" /></Badge> </div>
                                </Col>
                                <Col>
                                    <div><b><FormattedMessage id="EgoiList.List" />: </b>{this.getListName(automation.list_id)}</div>
                                    <div><b><FormattedMessage id="AutomationList.ContactLimit" />: </b>{automation.contact_limit}</div>
                                </Col>
                                <Col>
                                    <div><b><FormattedMessage id="EgoiList.Created" />: </b>{automation.created}</div>
                                    <div><b><FormattedMessage id="EgoiList.Updated" />: </b>{automation.updated}</div>
                                </Col>

                            </Row>
                        </Card>
                    )
                : <div className="text-center mt-4"> <FormattedMessage id="AutomationList.ThereIsNoAutomations" /> </div>}

                {totalItems > 0 && totalItems >= limit ?
                    <Row className="mt-3 px-3">
                        <Col>
                            <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.handlePagination(-limit)} disabled={offset === 0}>
                                <i className="fas fa-angle-left" />
                            </Button>
                        </Col>
                        <Col className="text-right">
                            <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.handlePagination(limit)} disabled={totalItems <= offset + limit}>
                                <i className="fas fa-angle-right" />
                            </Button>
                        </Col>
                    </Row>
                : ''}

            </StyledCard>
        );
    }
}
export default injectIntl(AutomationList)
