import React, { Component } from 'react'
import { StyledCard, KebabMenu } from '../../Base/CommonUIComponents'
import { Col, Row, Card, CardBody } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import TwilioPhoneFriendlyNameModal from './TwilioPhoneFriendlyNameModal';
import { postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';

class TwilioPhoneNumbers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            selectedHotel: {},
            modal: false
        };
    }

    changeFriendlyName = (e, sid, name) => {
        if (e) e.preventDefault();
        this.setState({ block: true }, () => postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.response) {
                    handleNotification(data, <FormattedMessage id="TwilioPhone.FriendlyNameChanged" />, <FormattedMessage id="generic.success" />);
                }
                this.setState({block: false, modal: false }, () => this.props.getPhoneNumbers());
            }
        }, `/api/gms/Twilio/v1/subaccount/${sid}?friendlyName=${name}`));
    }

    toggleModal = (selectedPhone) => {
        this.setState({ selectedPhone: selectedPhone, modal: !this.state.modal });
    }

    treatmentNumber = (number) => {
        return [number.slice(0, number.length - 9), " ",
            number.slice(number.length - 9, number.length - 6), " ",
            number.slice(number.length - 6, number.length - 3), " ",
            number.slice(number.length - 3)].join('')
    }

    treatmentCapabilities = (capabilities) => {
        return [capabilities.voice ? this.props.intl.formatMessage({ id: 'TwilioMessaging.Voice' }) : '',
        capabilities.voice && capabilities.sms ? " | " : '',
        capabilities.sms ? 'Sms' : '',
        (capabilities.voice || capabilities.sms) && capabilities.mms ? " | " : '',
        capabilities.mms ? 'Mms' : '',
        (capabilities.voice || capabilities.sms || capabilities.mm) && capabilities.fax ? " | " : '',
        capabilities.fax ? 'Fax' : ''].join('')
    }

    treatmentStatus = (status) => {
        return status == "in-use" ? <FormattedMessage id="TwilioMessaging.InUse" /> : <FormattedMessage id="TwilioMessaging.NotUsed" />
    }

    render() {
        const { page, twilioPhones } = this.props;
        const { block, error, selectedPhone, modal } = this.state;       
        return (
            <>
                <StyledCard block={block} error={error}>
                    <Row className="mb-4 py-1">
                        <Col>
                            <h5> <i className={page?.icon} /> {page?.name} </h5>
                        </Col>
                    </Row>
                    <div className='mt-2'>
                        {twilioPhones && twilioPhones.length > 0 ?
                            <div>
                                {twilioPhones.map((subUser, key) =>
                                    <Card key={key} className="border-0 shadow mt-2">
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="TwilioMessaging.UserName" />
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <b>{subUser.friendlyName}</b>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="TwilioMessaging.SID" />
                                                        </div>
                                                        <div>{subUser.sid}</div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="TwilioMessaging.PhoneNumber" />
                                                        </div>
                                                        <div>{subUser.phoneNumber && this.treatmentNumber(subUser.phoneNumber)}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className="mr-3">
                                                    <div>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="TwilioMessaging.Capabilities" />
                                                        </div>
                                                        <div>
                                                            <Row>
                                                                <Col>
                                                                    {subUser.capabilities && this.treatmentCapabilities(subUser.capabilities)}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="TwilioMessaging.Status" />
                                                        </div>
                                                        <div>{subUser.status && this.treatmentStatus(subUser.status)}</div>
                                                    </div>
                                                </Col>
                                                <Col className='col-2 d-flex align-items-center justify-content-end'>
                                                    <KebabMenu
                                                        editFunction={_ => this.toggleModal(subUser)}
                                                    />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                )}
                            </div>
                            : ''
                        }
                    </div>
                </StyledCard>
                {modal ?
                    <TwilioPhoneFriendlyNameModal
                        block={block}
                        error={error}
                        toggleModal={this.toggleModal}
                        modal={modal}
                        selectedPhone={selectedPhone}
                        changeFriendlyName={this.changeFriendlyName}
                    />
                    : ''}
            </>
        )
    }
}
export default injectIntl(TwilioPhoneNumbers);

