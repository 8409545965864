import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Modal, ModalBody, Input } from 'reactstrap';
import { handleNotification } from "../Base/Notification";
import { BlankCard } from '../Base/CommonUIComponents';
import { putAPI } from "../Base/API";

class PreferenceDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            preferenceData: this.props.preference ? this.props.preference : {},
        };
    }

    handleChange = (e, type) => {
        const { name, value } = e.target;

        this.setState({
            preferenceData: {
                ...this.state.preferenceData,
                [name]: value
            }
        })
    }

    savePreference = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var preference = { ...this.state.preferenceData };

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="PreferenceDetails.PreferenceSaved" />, <FormattedMessage id="generic.success" />);
                    this.props.updateTable(data.response[0], 'preferences');
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/profile/preference/${this.props.profileId}`, JSON.stringify({ request: preference }));
    }

    render() {
        const { block, error, preferenceData } = this.state;

        return (
            <Modal isOpen={this.props.modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={this.props.toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <Form onSubmit={this.savePreference}>
                            <Row className="mb-3">
                                <Col>
                                    <h5> {this.props.preference ? <FormattedMessage id="PreferenceDetails.EditPreference" /> : <FormattedMessage id="PreferenceDetails.AddPreference" />}</h5>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" type="submit"> <i className="fas fa-save" /></Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}> <FormattedMessage id="ProfileDetails.Type" /></Label>
                                        <Col sm={8}>
                                            <Input
                                                type="text"
                                                name="type"
                                                onChange={(e) => this.handleChange(e)}
                                                value={preferenceData.type ? preferenceData.type : ''}
                                                required
                                                maxLength="20"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}> <FormattedMessage id="ProfileDetails.Action" /></Label>
                                        <Col sm={8}>
                                            <Input
                                                type="text"
                                                name="action"
                                                onChange={(e) => this.handleChange(e)}
                                                value={preferenceData.action ? preferenceData.action : ''}
                                                required
                                                maxLength="50"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={2}> <FormattedMessage id="ProfileDetails.Notes" /></Label>
                                        <Col sm={10}>
                                            <Input
                                                type="textarea"
                                                name="notes"
                                                onChange={(e) => this.handleChange(e)}
                                                value={preferenceData.notes ? preferenceData.notes : ''}
                                                maxLength="1000"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(PreferenceDetails)