import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Col, Row, Card } from "reactstrap";
import { handleNotification } from "../../Base/Notification";
import { BlankCard, ActiveInactiveStatusBadge } from "../../Base/CommonUIComponents";
import { getProfileType } from "../../Base/ReferenceDataFunctions";
import { deleteAPI, getAPI, putAPI } from "../../Base/API"; 
import ConfigModal from "./ConfigModal";
import moment from 'moment';
import RuleModal from "./RuleModal";
import LoyaltyRule from "./LoyaltyRule";
import ConfirmActionModal from '../../Base/ConfirmActionModal';

class ConfigCardPoints extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            loyaltyCardConfigs: [],
            subTypeOptions: [{ value: 'ChargeCode', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.Charge' }) }, { value: 'All', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.All' }) }, { value: 'RoomCategory', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.RoomCategory' }) }, { value: 'Rate', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.Rate' }) }],
            profileTypeAvailable: getProfileType(this.props.intl),
            allProfileTypes: getProfileType(this.props.intl),
            type: [{ value: 'ReservationRevenue', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.ReservationRevenue' }) }, { value: 'AdditionalRevenue', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.AdditionalRevenue' }) }, { value: 'Nights', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.Nights' }) }, { value: 'Reservation', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.Reservation' }) }],
            modalData: {},
            ruleModal: false,
            removeRuleModal: false,
            selectedRule: null,
            pointRules: [],
            loyaltyCards: [],
            loyaltyCardTypes: [],
            selectedConfigId: null,
            ChargesList: [],
            RoomCategoryList: [],
            RateList: [],
            PriceListList: [],
            DistributionChannelsList: [],
            SegmentsList: [],
            SubSegmentsList: [],
            sourceOptions: [],
            ChannelList: [],
            SalesGroupOptions: [
                { value: '0', label: 'ROOM' },
                { value: '1', label: 'FB'},
                { value: '2', label: 'BANQ' },
                { value: '3', label: 'SPORT' },
                { value: null, label: 'ETC' },
            ],
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true;

        this.getLoyaltyCardConfig();
    }

    getLoyaltyCardConfig = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                let profileType = getProfileType(this.props.intl);

                if (data.response && data.response.length > 0) {
                    profileType = profileType.map(el => {
                        el.disabled = data.response.some(config => config.profileType === el.value);
                        return el;
                    });
                }

                const pointRules = [];

                data.response.forEach((config) => {
                    if(config.loyaltyCardConfigDetails){
                        config.loyaltyCardConfigDetails.forEach((rule) => {
                            rule.loyaltyCardConfigId = config.id;
                            pointRules.push(rule);
                        });
                    }
                });

                if(data.response.find((config) => config.loyaltyCardConfigDetails.find(r => r?.subType === "ChargeCode"))) this.getCharges();
                if(data.response.find((config) => config.loyaltyCardConfigDetails.find(r => r?.subType === "RoomCategory"))) this.getCat();
                if(data.response.find((config) => config.loyaltyCardConfigDetails.find(r => r?.subType === "Rate"))) this.getRates();
        
                if(data.response.find((config) => config.loyaltyCardConfigDetails.find(r => r?.filterType === "PriceListCode"))) this.getPriceListCode();
                if(data.response.find((config) => config.loyaltyCardConfigDetails.find(r => r?.filterType === "SegmentCode"))) this.getSegmentCode();
                if(data.response.find((config) => config.loyaltyCardConfigDetails.find(r => r?.filterType === "SubSegmentCodeCode"))) this.getSubSegmentCodeCode();
                if(data.response.find((config) => config.loyaltyCardConfigDetails.find(r => r?.filterType === "Source"))) this.getSource();
                if(data.response.find((config) => config.loyaltyCardConfigDetails.find(r => r?.filterType === "DistChannelCode"))) this.getDistChannelCode();

                this.setState({ pointRules, loyaltyCardConfigs: data.response, profileTypeAvailable: profileType }, () => this.getLevels());
            }
        }, `/api/gms/LoyaltyCard/v1/LoyaltyCardConfig`);
    }

    getLoyaltyCardTypes = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ error: errorMessage, block: false, loyaltyCardTypes: data.response?.map((el) => ({ label: el.description, code: el.code, value: el.code })) });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/LoyaltyCard/v1/LoyaltyCardTypeConfig`);
    }

    getLevels = () =>{
        getAPI(result => {
            const { data, error } = result;

            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                data.response.sort((a, b) => (a.minRoomNightsToLevel + a.minPointsToLevel > b.minRoomNightsToLevel + b.minPointsToLevel)
                    ? 1 : ((b.minRoomNightsToLevel + b.minPointsToLevel > a.minRoomNightsToLevel + a.minPointsToLevel) ? -1 : 0));

                this.setState({ error: errorMessage, loyaltyCards: data.response }, this.getLoyaltyCardTypes);
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/LoyaltyCard/v1/LoyaltyCardLevelConfig`);
    }

    saveConfig = (e, config) => {
        e.preventDefault();
        this.setState({ block: true });

        const body = [ config ];


        putAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="ConfigCardPoints.ConfigSaved" />, <FormattedMessage id="generic.success"/>);

                    let { loyaltyCardConfigs } = this.state;
                    loyaltyCardConfigs = loyaltyCardConfigs ? loyaltyCardConfigs : [];

                    if (config.index !== null && config.index !== undefined) {
                        loyaltyCardConfigs[config.index] = config;
                    }
                    else {
                        if (!config.profileType) {
                            config.profileType = "Person"
                        }
                        config.loyaltyCardConfigDetails = [];

                        loyaltyCardConfigs.push(config);
                    }

                    this.setState({ loyaltyCardConfigs });
                }
            }

            this.setState({ block: false });
        }, `/api/gms/LoyaltyCard/v1/LoyaltyCardConfig`, JSON.stringify({ request: body }));
    }

    addConfig = (e, config) => {
        e.preventDefault();

        if(!config?.tierRevenuePoints && !config?.tierResPoints && !config?.tierNightPoints) {
            return;
        };

        if(!config.tierRevenuePoints && config.loyaltyCardConfigDetails?.find(({type}) => type === "ReservationRevenue" || type === "AdditionalRevenue")) {
            const el = document.getElementById('tierRevenuePoints');

            el.setCustomValidity("You have rules configured for this type. Delete them first.");
            return;
        }
        if(!config.tierResPoints && config.loyaltyCardConfigDetails?.find(({type}) => type === "Reservation")) {
            const el = document.getElementById('tierResPoints');

            el.setCustomValidity("You have rules configured for this type. Delete them first.");
            return;
        }
        if(!config.tierNightPoints && config.loyaltyCardConfigDetails?.find(({type}) => type === "Night")) {
            const el = document.getElementById('tierNightPoints');

            el.setCustomValidity("You have rules configured for this type. Delete them first.");
            return;
        }

        this.setState({ modal: false, modalData: null }, () => this.saveConfig(e, config));

    }

    addRule = (e, configIdx) => {
        e.preventDefault();
        const { loyaltyCardConfigs } = this.state;

        loyaltyCardConfigs[configIdx].loyaltyCardConfigDetails.push({ type: 'Nights', subType: null, code: null, multiplier: 1 });

        this.setState({ loyaltyCardConfigs });
    }

    toggleModal = (modalData, index) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            modalData: modalData ? { ...modalData, index } : {}
        }));
    }

    toggleRemoveRuleModal = (ruleId) => {
        this.setState(prevState => ({
            removeRuleModal: !prevState.removeRuleModal,
            selectedRule: ruleId
        }));
    }

    toggleRuleModal = (selectedConfigId, selectedRule) => {
        this.setState(prevState => ({
            ruleModal: !prevState.ruleModal,
            selectedRule, selectedConfigId
        }));
    }

    handleChange = (e, configIdx, detailIdx) => {
        const { name, value } = e.target;
        const { loyaltyCardConfigs } = this.state;

        loyaltyCardConfigs[configIdx].loyaltyCardConfigDetails[detailIdx][name] = value;

        if (name === "type") {
            loyaltyCardConfigs[configIdx].loyaltyCardConfigDetails[detailIdx].subType = null;
        }

        this.setState({ loyaltyCardConfigs });
    }

    handleSelect = (combo, name, configIdx, detailIdx) => {
        const { loyaltyCardConfigs } = this.state;

        loyaltyCardConfigs[configIdx].loyaltyCardConfigDetails[detailIdx][name] = combo ? combo.value : ''

        this.setState({ loyaltyCardConfigs });
    }

    handleRadio = (name, configIdx, detailIdx) => {
        const { loyaltyCardConfigs } = this.state;

        loyaltyCardConfigs[configIdx].loyaltyCardConfigDetails[detailIdx].subType = name;

        this.setState({ loyaltyCardConfigs });
    }

    saveRules = (e, rule) => {
        e.preventDefault();
        this.setState({ block: true }, () => {
            const { selectedRule, pointRules, loyaltyCardConfigs, ruleModal } = this.state;

            const currentConfig = loyaltyCardConfigs.find(({id}) => id === rule.loyaltyCardConfigId);

            if(!currentConfig?.loyaltyCardConfigDetails){
                currentConfig.loyaltyCardConfigDetails = [];
            }

            let loyaltyCardConfigDetailsCurrenPointsRuleIdx = currentConfig.loyaltyCardConfigDetails.findIndex(({id}) => selectedRule === id);
            
            if(loyaltyCardConfigDetailsCurrenPointsRuleIdx > -1){
                currentConfig.loyaltyCardConfigDetails.splice(loyaltyCardConfigDetailsCurrenPointsRuleIdx, 1, rule);
            }
            else{
                currentConfig.loyaltyCardConfigDetails.push(rule);
            }

            let currentPointRulesIdx = pointRules.findIndex(({id}) => id === selectedRule);
            
            if(currentPointRulesIdx > -1){
                pointRules.splice(currentPointRulesIdx, 1, rule);
            }
            else{
                pointRules.push(rule);
            }
            
            this.setState({ pointRules, loyaltyCardConfigs, ruleModal: !ruleModal }, () => this.updateRules(rule));
        });
    }

    removeRule = (ruleId) => {
        this.setState({ block: true }, () => {
            const { loyaltyCardConfigs, pointRules } = this.state;

            const program = loyaltyCardConfigs.find(({loyaltyCardConfigDetails}) => loyaltyCardConfigDetails.find(({id}) => id === ruleId));
            const idx = program?.loyaltyCardConfigDetails?.findIndex(({id}) => id === ruleId);

            program.loyaltyCardConfigDetails.splice(idx, 1);

            pointRules.splice(pointRules.findIndex(({id}) => id === ruleId), 1);

            this.setState({ loyaltyCardConfigs, pointRules }, () => {
                deleteAPI(result => {
                    const { data, error } = result;
        
                    if (error) {
                        var errorMessage = [];
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
        
                        return;
                    }
                    if (data) {
                        if (data.errors && data.errors.length > 0) {
                            handleNotification(data);
                        }
                        else {  
                            handleNotification(data, <FormattedMessage id="ConfigCardPoints.ConfigSaved" />, <FormattedMessage id="generic.success"/>);
                        }
                    }
        
                    this.setState({ block: false }, () => this.toggleRemoveRuleModal(null));
                }, `/api/gms/LoyaltyCard/v1/LoyaltyCardRules?Id=` + ruleId);
            });
        });
    }

    updateRules = (rule) => {
        putAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="ConfigCardPoints.ConfigSaved" />, <FormattedMessage id="generic.success"/>);
                }
            }

            this.getLoyaltyCardConfig();
        }, `/api/gms/LoyaltyCard/v1/LoyaltyCardRules`, JSON.stringify({ request: [rule] }));
    }

    getChannelList = () => {
        this.getReferenceData("Channel");
    }

    getPriceListCode = () => {
        this.getReferenceData("PriceList");
    }
    
    getSegmentCode = () => {
        this.getReferenceData("Segments");
    }
    
    getSubSegmentCodeCode = () => {
        this.getReferenceData("SubSegments");
    }

    getDistChannelCode = () => {
        this.getReferenceData("DistributionChannels");
    }

    getRates = () => {
        this.getReferenceData("Rate");
    }

    getCat = () => {
        this.getReferenceData("RoomCategory");
    }

    getCharges = () => {
        this.getReferenceData("Charges");
    }

    getReferenceData = (type) => {
        let url = `/api/gms/Hotel/v1/hotelReferenceData?type=${type}`;

        if(type === "Charges" || type === "PriceList" || type === "DistributionChannels" || type === "Segments" || type === "SubSegments")
            url = `/api/gms/Hotel/v1/ReferenceData?type=${type}`;

        getAPI(result => {
            const { data, error } = result;

            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({ [`${type}List`]: data.response?.map(({description, code}) => ({value: code, label: <span>{description} <b className="ml-1">({code})</b></span>})), block: false });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, url)
    }

    render() {
        const { block, error, loyaltyCardConfigs, modal, profileTypeAvailable, modalData, allProfileTypes, ruleModal, selectedRule, pointRules, loyaltyCards, type, selectedConfigId,
        subTypeOptions, loyaltyCardTypes, ChargesList, RoomCategoryList, RateList, PriceListList, DistributionChannelsList, SegmentsList, SubSegmentsList,
        sourceOptions, ChannelList, SalesGroupOptions, removeRuleModal } = this.state;
        
        //const isAdmin = false;//CheckAuthorization("adminOperation:view", null);

        return (
            <BlankCard block={block} error={error} >
                <div>
                    <Row className="mb-3">
                        <Col>
                            <h5>
                                <i className="icon-icon-e-goi-config mr-2" />
                                <FormattedMessage id="ConfigCardPoints.Title" />
                            </h5>
                        </Col>
                        <Col className="text-right">
                            <Button
                                className="btn-sm btn-host"
                                onClick={() => this.toggleModal(null)}
                                //loyaltyCardConfigs.length - 1: loyaltyCardConfigs - default = loyaltyCardTypes
                                disabled={loyaltyCardConfigs && loyaltyCardTypes && (loyaltyCardConfigs.length - 1) == loyaltyCardTypes.length}
                                //disabled={loyaltyCardConfigs && loyaltyCardConfigs.find(({profileType}) => profileType === "Person") && !isAdmin}
                                >
                                <i className="fas fa-plus" />
                            </Button>
                        </Col>
                    </Row>

                    {loyaltyCardConfigs && loyaltyCardConfigs.map((config, configKey) => {
                        const baseRules = config.loyaltyCardConfigDetails.filter(({beginDate, endDate, subType, filterType}) => !beginDate && !endDate && subType === "All" && !filterType);
                        const alwaysRules = config.loyaltyCardConfigDetails.filter(({beginDate, endDate, subType, filterType}) => !beginDate && !endDate && (subType !== "All" || filterType));
                        const anyRules = config.loyaltyCardConfigDetails.filter(({beginDate, endDate}) => beginDate && endDate);
                        
                        const cardType = loyaltyCardTypes?.find(({value}) => value === config?.loyaltyCardTypeConfigCode);
                        
                        return(
                            <Card body className="border-0 shadow mb-4" key={configKey}>
                                <Row className="align-items-center pointer" onClick={() => this.toggleModal(config, configKey)}>
                                    <Col className="col-3">
                                        <div className="d-flex align-items-center">
                                            {cardType ?
                                                <>
                                                    <h5 className="m-0"> <i className="icon-icon-e-goi-config mr-2" />
                                                        <b>{cardType.code}</b>
                                                        <span className="mx-1">-</span>
                                                        <span>{cardType.label}</span>
                                                    </h5>
                                                    <span className="ml-1">
                                                        (<b>{allProfileTypes.some(p => p.value === config.profileType) ? allProfileTypes.find(p => p.value === config.profileType).label : ''}</b>)
                                                    </span>
                                                </>
                                            :
                                                <h5 className="m-0"> <i className="icon-icon-e-goi-config mr-2" />
                                                    <b>{allProfileTypes.some(p => p.value === config.profileType) ? allProfileTypes.find(p => p.value === config.profileType).label : ''}</b>
                                                </h5>
                                            }
                                        </div>

                                        <div className="pl-4 ml-2" style={{ fontSize: '12px' }}>
                                            {config.calculateFrom === 'FixedDate' && config.beginDate ?
                                                <span><FormattedMessage id="ConfigCardPoints.CalculateFrom" /> {moment(config.beginDate).format("YYYY-MM-DD")} </span>
                                                : <span><FormattedMessage id="ConfigCardPoints.CalculateFromCreationDate" /> </span>}
                                        </div>
                                    </Col>

                                    <Col className="d-flex align-items-center justify-content-around col-6">
                                        <div className="d-flex align-items-center">
                                            <b className="color-yellow"> <FormattedMessage id="ConfigCardPoints.RevenueAmountPoints"/> </b>
                                            <div className={`d-flex align-items-center justify-content-center bg-yellow color-white ml-2`}
                                                style={{ width: '35px', height: '35px', borderRadius: '30px' }}>
                                                <b>{config.tierRevenuePoints || "0"}</b>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <b className="color-cyan"> <FormattedMessage id="ConfigCardPoints.ResPoints" /></b>
                                            <div className={`d-flex align-items-center justify-content-center bg-cyan color-white ml-2`}
                                                style={{ width: '35px', height: '35px', borderRadius: '30px' }}>
                                                <b>{config.tierResPoints || "0"}</b>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <b className="color-violet"> <FormattedMessage id="ConfigCardPoints.NightPoints" /></b>
                                            <div className={`d-flex align-items-center justify-content-center bg-violet color-white ml-2`}
                                                style={{ width: '35px', height: '35px', borderRadius: '30px' }}>
                                                <b>{config.tierNightPoints || "0"}</b>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="text-right col-3">
                                        <ActiveInactiveStatusBadge status={config.active}/>
                                    </Col>
                                </Row>
                                {baseRules && baseRules.length > 0 ?
                                    <>
                                        <hr className="mt-3 mb-4" />
                                        <div>
                                            <div>
                                                <b><FormattedMessage id="ConfigCardPoints.BaseRules"/></b>
                                                <span className="text-muted ml-1"><FormattedMessage id="ConfigCardPoints.thirdTier"/></span>
                                            </div>
                                            <div className="mt-2">
                                                {baseRules.map((rule, key) =>
                                                    <LoyaltyRule
                                                        toggleRuleModal={this.toggleRuleModal}
                                                        toggleRemoveRuleModal={this.toggleRemoveRuleModal}
                                                        rule={rule}
                                                        config={config}
                                                        index={key}
                                                        key={key}
                                                        configKey={configKey}
                                                        type={type}
                                                        loyaltyCards={loyaltyCards}
                                                        subTypeOptions={subTypeOptions}
                                                        ChargesList={ChargesList}
                                                        RoomCategoryList={RoomCategoryList}
                                                        RateList={RateList}
                                                        PriceListCodeList={PriceListList}
                                                        DistributionChannelsList={DistributionChannelsList}
                                                        SegmentCodesList={SegmentsList}
                                                        SubSegmentCodeCodesList={SubSegmentsList}
                                                        ChannelList={ChannelList}
                                                        sourceList={sourceOptions}
                                                        SalesGroupList={SalesGroupOptions}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </>
                                :''}
                                {alwaysRules && alwaysRules.length > 0 ?
                                    <>
                                        <hr className="mt-3 mb-4" />
                                        <div>
                                            <div>
                                                <b><FormattedMessage id="ConfigCardPoints.AlwaysRules"/></b>
                                                <span className="text-muted ml-1"><FormattedMessage id="ConfigCardPoints.secTier"/></span>
                                            </div>
                                            <div className="mt-2">
                                                {alwaysRules.map((rule, key) =>
                                                    <LoyaltyRule
                                                        toggleRuleModal={this.toggleRuleModal}
                                                        toggleRemoveRuleModal={this.toggleRemoveRuleModal}
                                                        rule={rule}
                                                        config={config}
                                                        index={key}
                                                        key={key}
                                                        configKey={configKey}
                                                        type={type}
                                                        loyaltyCards={loyaltyCards}
                                                        subTypeOptions={subTypeOptions}
                                                        ChargesList={ChargesList}
                                                        RoomCategoryList={RoomCategoryList}
                                                        RateList={RateList}
                                                        PriceListCodeList={PriceListList}
                                                        DistributionChannelsList={DistributionChannelsList}
                                                        SegmentCodesList={SegmentsList}
                                                        SubSegmentCodeCodesList={SubSegmentsList}
                                                        ChannelList={ChannelList}
                                                        sourceList={sourceOptions}
                                                        SalesGroupList={SalesGroupOptions}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </>
                                :''}
                                {anyRules && anyRules.length > 0 ?
                                    <>
                                        <hr className="mt-3 mb-4" />
                                        <div>
                                            <div>
                                                <b><FormattedMessage id="ConfigCardPoints.AnyRules"/></b>
                                                <span className="text-muted ml-1"><FormattedMessage id="ConfigCardPoints.firstTier"/></span>
                                            </div>
                                            <div className="mt-2">
                                                {anyRules.map((rule, key) =>
                                                    <LoyaltyRule
                                                        toggleRuleModal={this.toggleRuleModal}
                                                        toggleRemoveRuleModal={this.toggleRemoveRuleModal}
                                                        config={config}
                                                        rule={rule}
                                                        type={type}
                                                        loyaltyCards={loyaltyCards}
                                                        index={key}
                                                        key={key}
                                                        configKey={configKey}
                                                        subTypeOptions={subTypeOptions}
                                                        ChargesList={ChargesList}
                                                        RoomCategoryList={RoomCategoryList}
                                                        RateList={RateList}
                                                        PriceListCodeList={PriceListList}
                                                        DistributionChannelsList={DistributionChannelsList}
                                                        SegmentCodesList={SegmentsList}
                                                        SubSegmentCodeCodesList={SubSegmentsList}
                                                        ChannelList={ChannelList}
                                                        sourceList={sourceOptions}
                                                        SalesGroupList={SalesGroupOptions}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </>
                                :''}
                                <Row>
                                    <Col className="text-center">
                                        <Button className="text-center bg-host-gradient px-3 py-2" style={{ borderRadius: '15px' }} onClick={_ => this.toggleRuleModal(config.id)}>
                                            <FormattedMessage id="ConfigCardPoints.NewRule"/>
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                        )
                    })}
                </div>

                {ruleModal ?
                    <RuleModal
                        saveRules={this.saveRules}
                        configId={selectedConfigId}
                        modal={ruleModal}
                        toggle={_ => this.toggleRuleModal()}
                        selectedRuleId={selectedRule}
                        rules={pointRules}
                        loyaltyCards={loyaltyCards}
                        type={type}
                        loyaltyCardConfigs={loyaltyCardConfigs}
                        getChannelList={this.getChannelList}
                        getPriceListCode={this.getPriceListCode}
                        getSegmentCode={this.getSegmentCode}
                        getSubSegmentCodeCode={this.getSubSegmentCodeCode}
                        getDistChannelCode={this.getDistChannelCode}
                        getRates={this.getRates}
                        getCat={this.getCat}
                        getCharges={this.getCharges}
                        ChargesList={ChargesList}
                        RoomCategoryList={RoomCategoryList}
                        RateList={RateList}
                        PriceListCodeList={PriceListList}
                        DistributionChannelsList={DistributionChannelsList}
                        SegmentCodesList={SegmentsList}
                        SubSegmentCodeCodesList={SubSegmentsList}
                        ChannelList={ChannelList}
                        sourceOptions={sourceOptions}
                        SalesGroupOptions={SalesGroupOptions}
                    />
                :''}

                {modal ?
                    <ConfigModal
                        modal={modal}
                        toggleModal={() => this.toggleModal(null)}
                        profileType={profileTypeAvailable}
                        loyaltyCardTypes={modalData && modalData.loyaltyCardTypeConfigCode ? loyaltyCardTypes.filter(tp => !loyaltyCardConfigs.some(cfg => tp.value === cfg.loyaltyCardTypeConfigCode) || tp.value === modalData.loyaltyCardTypeConfigCode) : loyaltyCardTypes.filter(tp => !loyaltyCardConfigs.some(cfg => tp.value === cfg.loyaltyCardTypeConfigCode))}
                        modalData={modalData}
                        addConfig={this.addConfig}
                    />
                : ''}

                {removeRuleModal ?
                    <ConfirmActionModal
                        modal={removeRuleModal}
                        toggleModal={_ => this.toggleRemoveRuleModal(selectedRule)}
                        actionFunction={() => this.removeRule(selectedRule)}
                        text={<FormattedMessage id="ConfigCardPoints.DeleteRule"/>}
                        block={block}
                    />
                : ''}
            </BlankCard>
        );
    }
}
export default injectIntl(ConfigCardPoints)