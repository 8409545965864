import React, { Component } from 'react'
import { Row, Col, Button, Form, Input, FormGroup } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BlankCard, StyledModal } from '../Base/CommonUIComponents';
import { postAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { SelectCustomType } from './SalesProcessFunctions';


class SalesPipelineDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            pipeline: this.props.pipeline || {
                salesPipelineStages: [],
                type: 'GroupReservation'
            },
            showDragArea: false,
            grabbingStageId: null,
            dragToStageIdx: null,
        };
    }
    
    savePipeline = (e) => {
        e.preventDefault();

        this.setState({ block: true }, () => {
            const payload = { ...this.state.pipeline };

            payload.salesPipelineStages.forEach(stage => {
                stage.id = stage.isNew ? null : stage.id;
            });

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        this.setState({ block: false }, () => handleNotification(data));
                        return;
                    }

                    this.setState({ block: false }, () =>
                        handleNotification(data, <FormattedMessage id="SalesPipeline.SavedSuccessfully" />, <FormattedMessage id="generic.success" />)
                    );

                    this.props.toggleModal();
                    this.props.getSalesPipelines();
                    return;
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/pipeline`, payload);
        });
    }

    handleSelect = (name, combo) => {
        this.setState({
            pipeline: {
                ...this.state.pipeline,
                [name]: combo ? combo.value : null
            }
        })
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            pipeline: {
                ...this.state.pipeline,
                [name]: value
            }
        })
    }

    orderStages = () => {
        const { pipeline } = this.state;

        pipeline.salesPipelineStages.sort((a, b) => a.index - b.index);

        this.setState({ pipeline });
    }

    handleChangeStage = (event, key) => {
        let { name, value } = event.target;
        const { pipeline } = this.state;

        pipeline.salesPipelineStages[key][name] = value;

        this.setState({ pipeline });
    }

    generateRandomId = () => {
        return Math.floor(Math.random() * 999);
    }

    addStage = () => {
        const { pipeline } = this.state;
        const lastIdx = pipeline.salesPipelineStages.length === 0 ?  0 : (pipeline.salesPipelineStages.sort((a, b) => b.index - a.index)[0]?.index + 1 ?? 999);

        const obj = {
            id: this.generateRandomId(),
            isNew: true,
            index: lastIdx
        };

        let i = 0; // Fallback

        while(pipeline.salesPipelineStages.some(({id}) => id === obj.id) || i > 10){
            obj.id = this.generateRandomId();
            ++i;
        }
        
        pipeline.salesPipelineStages.push(obj);

        this.setState({ pipeline });
    }

    removeStage = (index) => {
        const { pipeline } = this.state;

        const stage = pipeline.salesPipelineStages[index];

        const toUpdate = pipeline.salesPipelineStages.filter(({index}) => index > stage.index);

        toUpdate.forEach(stage => {
            stage.index = --stage.index;
        });

        pipeline.salesPipelineStages.splice(index, 1);

        this.setState({ pipeline });
    }
    
    onDragStart = (grabbingStageId) => {
        this.setState(prevState => ({ ...prevState, showDragArea: true, grabbingStageId }))
    }

    onDragEnd = () => {
        this.setState({ showDragArea: false, dragToStageIdx: null, grabbingStageId: null })
    }

    onDragOver = (e, stageIdx) => {
        e.preventDefault();

        if (this.state.dragToStageIdx !== stageIdx) {
            this.setState({ dragToStageIdx: stageIdx });
        }
    }

    changeStageIdx = (e) => {
        e.preventDefault();
        const { pipeline, grabbingStageId, dragToStageIdx } = this.state;

        const newIdx = dragToStageIdx??0;
        const stage = pipeline.salesPipelineStages.find(({id}) => id === grabbingStageId);

        // Apanhar todos os stages que precisam de ser atualizados
        // quando o idx é aumentado devemos apanhar todos os idx maiores que o idx original e menores ou iguais ao novo
        // quando o idx é diminuido devemos apanhar todos os idx menores que o idx original e maiores ou iguais ao novo
        const sameIDX = pipeline.salesPipelineStages.filter(({index, id}) => 
            id !== grabbingStageId && stage.index !== newIdx && 
            (
                stage.index > newIdx ? // diminui
                    index < stage.index && index >= newIdx
                :
                    index > stage.index && index <= newIdx
            )
        );

        sameIDX.forEach((sm) => {
            if(stage.index > newIdx)
                sm.index = ++sm.index;
            else
                sm.index = --sm.index;
        });

        stage.index = newIdx;

        this.setState({ pipeline });
    }

    render() {
        const { block, error, pipeline, grabbingStageId, dragToStageIdx } = this.state;
        const { modal, toggleModal } = this.props;

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="xl">
                <BlankCard block={block} error={error}>
                    <Form onSubmit={this.savePipeline}>
                        <Row className="pb-3">
                            <Col>
                                <h4><FormattedMessage id={`SalesPipeline.${pipeline.id ? 'Edit' : 'Create'}Pipeline`} /></h4>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host" type="submit">
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>

                        <FormGroup row>
                            <Col sm={6}>
                                <div className="title-sm"> <FormattedMessage id="generic.name" /> </div>
                                <div>
                                    <Input
                                        required
                                        type="text"
                                        name="name"
                                        placeholder=""
                                        value={pipeline.name || ''}
                                        onChange={this.handleChange}
                                        maxLength={100}
                                    />
                                </div>
                            </Col>
                            {/* <Col sm={6}>
                                <div className="title-sm"> <FormattedMessage id="generic.Type" /> </div>
                                <div>
                                    <CustomSelect
                                        required
                                        name="type"
                                        placeholder=""
                                        isClearable={false}
                                        isSearchable={false}
                                        options={getSalesPipelineTypes(this.props.intl)}
                                        value={getSalesPipelineTypes(this.props.intl).find(({ value }) => pipeline.type === value)}
                                        onChange={(combo) => this.handleSelect('type', combo)}
                                    />
                                </div>
                            </Col> */}
                        </FormGroup>
                        <FormGroup row>
                            <Col>
                                <div className="title-sm"> <FormattedMessage id="generic.Description" /> </div>
                                <div>
                                    <Input
                                        type="text"
                                        name="description"
                                        placeholder=""
                                        value={pipeline.description || ''}
                                        onChange={this.handleChange}
                                        maxLength={250}
                                    />
                                </div>
                            </Col>
                        </FormGroup>

                        <div className="mt-4 pt-2">
                            <Row className="align-items-center">
                                <Col>
                                    <b><FormattedMessage id="SalesPipeline.Stages" /></b>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" onClick={this.addStage}>
                                        <i className="fas fa-plus" />
                                    </Button>
                                </Col>
                            </Row>

                            <hr className="my-2" />

                            <div className="py-2 text-muted" body>
                                <Row className="mb-2 mt-2">
                                    <Col className='col-8'><FormattedMessage id="generic.name" /></Col>
                                    <Col className='col-3'><FormattedMessage id="generic.Type" /></Col>
                                    <Col className='col-1'></Col>
                                </Row>

                                {pipeline.salesPipelineStages.sort((a, b) => a.index - b.index)?.map((stage, key) =>
                                    <Row style={{ border: key === dragToStageIdx && stage.id !== grabbingStageId ? 'dashed 1px black' : 'solid 1px transparent' }}
                                    className="align-items-center py-2 overbglight" key={key} onDrop={(e) => this.changeStageIdx(e)}
                                    onDragOver={(e) => this.onDragOver(e, key)} draggable={true} onDragStart={_ => this.onDragStart(stage.id)} onDragEnd={this.onDragEnd}>
                                        <Col className='col-8'>
                                            <Input
                                                required
                                                type="text"
                                                name="name"
                                                placeholder=""
                                                value={stage.name || ''}
                                                onChange={(e) => this.handleChangeStage(e, key)}
                                                maxLength={100}
                                            />
                                        </Col>
                                        <Col className='col-3'>
                                            <SelectCustomType 
                                                name="type"
                                                onChangeFunc={(combo) => this.handleChangeStage({ target: { value: combo?.value, name: 'salesPipelineStageTypeId' } }, key)}
                                                required={true}
                                                value={stage.salesPipelineStageTypeId}
                                                placeholder=""
                                                isDisabled={false}
                                                isClearable={false}
                                                isSearchable={false}
                                                type={'PipelineStageType'}
                                            />
                                        </Col>
                                        <Col className="text-right col-1 d-flex align-items-center justify-content-between">
                                            <div>
                                                <i className="fas fa-trash-alt text-danger pr-2 pointer" onClick={_ => this.removeStage(key)}/>
                                            </div>
                                            <div className={`draggable ${grabbingStageId === stage.id ? 'active' : ''}`}>
                                                <i className="fas fa-grip-vertical"/>
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </div>
                        </div>
                    </Form>
                </BlankCard>
            </StyledModal>
        )
    }
}

export default injectIntl(SalesPipelineDetails);