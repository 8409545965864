import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Badge, Button, Card, Col, Row, Modal, ModalBody } from 'reactstrap';
import { KebabMenu, StyledCard } from "../../../Base/CommonUIComponents";
import { deleteAPI, getAPI, patchAPI, postAPI } from '../../../Base/API';
import { handleNotification } from '../../../Base/Notification';
import ConfirmActionModal from '../../../Base/ConfirmActionModal';
import DomainSettingsModal from './DomainSettingsModal';
import DomainAuthModal from './DomainAuthModal';

export class Whitelist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            domains: [],
            confirmModal: false,
            blockDelete: false,
            errorDelete: null,
            domainId: null,
            modal: null,
            authModal: false
        };
    }

    componentDidMount(){
        this.getDomains();
    }

    getDomains = (domainId) => {
        if(domainId)
            this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                
                this.setState({ domains: data.response, block: false, modal: domainId ? true : false, domainId });
            }
        }, `/api/gms/SendGrid/v1/domain/list`);
    }

    toggleAuthModal = (domainId) => {
        this.setState({ authModal: !this.state.authModal, block: domainId ? true: false }, () => {
            if(domainId)
                this.getDomains(domainId);
        });
    }

    toggleModal = (domainId) => {
        this.setState({ modal: !this.state.modal, domainId });
    }

    toggleConfirmModal = (domainId) => {
        this.setState({ confirmModal: !this.state.confirmModal, domainId });
    }

    removeDomain = () => {
        this.setState({ blockDelete: true }, () => {
            const { domainId } = this.state;
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ errorDelete: errorMessage, blockDelete: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ errorDelete: errorMessage, blockDelete: false });
                        return;
                    }
                    this.setState({ blockDelete: false, block: true, confirmModal: false }, this.getDomains());
                }
            }, `/api/gms/SendGrid/v1/domain/${domainId}`);
        });
    }

    setDomainDefault = (domainId) => {
        this.setState({ block: true }, () => {
            patchAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    handleNotification(data, <FormattedMessage id="SendGrid.DefaultUpdate" />, <FormattedMessage id="generic.success" />);
                    this.getDomains();
                }
            }, `/api/gms/SendGrid/v1/domain?domainId=${domainId}&isDefault=true`);
        });
    }

    verifyDomain = (domainId) => {
        this.setState({ block: true }, () =>
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                let openErrorModal = false;
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data.response.valid) {
                        handleNotification(data, <FormattedMessage id="SendGrid.VerifyUpdate" />, <FormattedMessage id="generic.success" />)
                    } else {
                        data.error = ({ error_message: <FormattedMessage id="SendGrid.VerifyUpdate" />, error_code: <FormattedMessage id="SendGrid.Failed" /> });
                        openErrorModal = true;
                        handleNotification(data)
                    }
                    this.setState({ block: false, openErrorModal, verifyDomain: data.response });
                }
            }, `/api/gms/SendGrid/v1/domain/${domainId}/validate`)
        );
    }

    toggleOpenErrorModal = () => {
        this.setState(prevState => ({
            openErrorModal: !prevState.openErrorModal
        }));
    }

    render() {
        const { block, error, domains, confirmModal, blockDelete, errorDelete, modal, domainId, authModal, openErrorModal, verifyDomain } = this.state;
        const { page } = this.props;

        return (
            <StyledCard block={block} error={error} size={"md"} style={{ minWidth: 0 }}>
                <div>
                    {openErrorModal ?
                        <Modal isOpen={openErrorModal} toggle={this.toggleOpenErrorModal}>
                            <ModalBody className='p-4' id="DomainAuthFailed">
                                <Row className="text-center mb-2">
                                    <Col>
                                        <h4><FormattedMessage id="DomainAuthentication.Failed" /></h4>
                                    </Col>
                                </Row>
                                {verifyDomain && verifyDomain.validation_results && Object.entries(verifyDomain.validation_results).map(x =>
                                    <Row className= "mt-3 ml-5">
                                        <Col className="col-1 ml-5 mr-3">
                                            <h5>{x[0]}</h5>
                                        </Col>
                                        <Col className="col-1 ml-5">
                                            {!x[1].valid ?
                                                <i className="color-light-red fas fa-times-circle" id="times-circle"></i>
                                                :
                                                <i className="color-green fas fa-check-circle" id="check-circle" />
                                            }
                                        </Col>
                                        <Col>
                                            {x[1].reason}
                                        </Col>
                                    </Row>
                                )}
                            </ModalBody>
                        </Modal>
                        : ''}
                </div>
                <div className="pb-4">
                    <Row className="mb-4 py-1">
                        <Col>
                            <h5>
                                <i className={page?.icon}/>
                                <span>{page?.name}</span>
                            </h5>
                        </Col>
                        <Col className='text-right'>
                            <Button className=" btn btn-host btn-sm" onClick={_ => this.toggleAuthModal()}>
                                <FormattedMessage id="SendGrid.AuthDomain"/>
                            </Button>
                        </Col>
                    </Row>
                    {domains && domains.length > 0 ?
                        <>
                            <Row className='mt-4 mb-3 px-4'>
                                <Col className='col-4 text-muted pl-3'>
                                    <FormattedMessage id="SendGrid.Status"/>
                                </Col>
                                <Col className='text-muted pl-3'>
                                    <FormattedMessage id="SendGrid.Domain"/>
                                </Col>
                            </Row>
                            {domains.map((d, k) =>
                                <Card className={`mb-2 p-4 shadow`} key={k}>
                                    <Row>
                                        <Col className='col-4'>
                                            {d.valid ?
                                                <div className='text-success'>
                                                    <i className="fas fa-check-circle"></i>
                                                    <span className="ml-2">
                                                        <FormattedMessage id="SendGrid.Verified"/>
                                                    </span>
                                                </div>
                                            : d.last_validation_attempt_at ?
                                                <div className='text-danger'>
                                                    <i className="fas fa-times-circle"/>
                                                    <span className="ml-2">
                                                        <FormattedMessage id="SendGrid.Failed"/>
                                                    </span>
                                                </div>
                                            :
                                                <div className='text-muted'>
                                                    <i className="fas fa-minus-circle"/>
                                                    <span className="ml-2">
                                                        <FormattedMessage id="SendGrid.Pending"/>
                                                    </span>
                                                </div>
                                            }
                                        </Col>
                                        <Col>
                                            <div className='d-flex align-items-center'>
                                                <b>{d.subdomain}.{d.domain}</b>
                                                {d.default ?
                                                    <Badge className='ml-2' color='primary'>
                                                        <FormattedMessage id="SendGrid.Default"/>
                                                    </Badge>
                                                :''}
                                            </div>
                                        </Col>
                                        <Col className='col-1 text-right'>
                                            <KebabMenu
                                                previewFunction={_ => this.toggleModal(d.id)}
                                                deleteFunction={_ => this.toggleConfirmModal(d.id)}
                                                extraFields={d.default ? [{
                                                    function: _ => this.verifyDomain(d.id),
                                                    icon: "fas fa-user-check mr-2",
                                                    text: "SendGrid.Verify"
                                                }] : [{
                                                    function: _ => this.setDomainDefault(d.id),
                                                    icon: "far fa-check-square mr-2",
                                                    text: "SendGrid.setDefault"
                                                }, {
                                                    function: _ => this.verifyDomain(d.id),
                                                    icon: "fas fa-user-check mr-2",
                                                    text: "SendGrid.Verify"
                                                }]}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            )}
                        </>
                    :''}
                </div>
                
                {confirmModal ?
                    <ConfirmActionModal
                        modal={confirmModal}
                        toggleModal={_ => this.toggleConfirmModal()}
                        actionFunction={this.removeDomain}
                        text={<FormattedMessage id="SendGrid.ConfirmRemoveDomain"/>}
                        block={blockDelete}
                        error={errorDelete}
                    />
                :''}

                {modal ?
                    <DomainSettingsModal
                        toggle={_ => this.toggleModal()}
                        domain={domains?.find(d => d.id === domainId)}
                        verify={this.verifyDomain}
                        blockParent={this.block}
                    />
                :''}

                {authModal ?
                    <DomainAuthModal
                        toggle={this.toggleAuthModal}
                    />
                :''}
            </StyledCard>
        );
    }
}

export default injectIntl(Whitelist)