import React, { useState, useCallback, useEffect } from 'react';
import { Col, Row, Input, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StyledCard } from '../../Base/CommonUIComponents';
import { useReactFlow } from 'react-flow-renderer';

import CustomSelect from '../../Base/CustomSelect';
import DecisionSplitConfig from './DecisionSplitConfig';

const ConfigConditions = ({ selectedNode, secondaryType, conditionType, handleNumberOfConditionsChange, handleNodesChange, changeEdges, edges, nodes, loyaltyCardsOptions }) => {
    const { setNodes, setEdges } = useReactFlow();
	const [sourceEdge, setSourceEdge] = useState([edges.find(edge => edge && selectedNode && edge.target === selectedNode.id)]);
	const [waitUntilOptions, setWaitUntilOptions] = useState([
        {
            label: <FormattedMessage id="CustomerJourney.emailElement" />,
			name: 'email',
            options: [
                { label: <FormattedMessage id="CustomerJourney.Open" />, value: 'open', disabled: true },
                { label: <FormattedMessage id="CustomerJourney.Click" />, value: 'click', disabled: true },
            ]
        },
        {
            label: <FormattedMessage id="CustomerJourney.Form" />,
			name: 'form',
            options: [
                { label: <FormattedMessage id="CustomerJourney.Response" />, value: 'response', disabled: true },
            ]
        },
        {
            label: <FormattedMessage id="CustomerJourney.Reservation" />,
			name: 'reserve',
            options: [
                { label: <FormattedMessage id="CustomerJourney.CheckIn" />, value: 'checkIn', disabled: true },
                { label: <FormattedMessage id="CustomerJourney.CheckOut" />, value: 'checkOut', disabled: true },
				{ label: <FormattedMessage id="CustomerJourney.Creation" />, value: 'creation', disabled: true },
                { label: <FormattedMessage id="CustomerJourney.Cancellation" />, value: 'cancellation', disabled: true },
            ]
        }
    ]);
	const [timeTypes] = useState([
		{ label: <FormattedMessage id="CustomerJourney.seconds" />, value: 'seconds' },
		{ label: <FormattedMessage id="CustomerJourney.minutes" />, value: 'minutes' },
		{ label: <FormattedMessage id="CustomerJourney.hours" />, value: 'hours' },
		{ label: <FormattedMessage id="CustomerJourney.days" />, value: 'days' }
	]);
    const [block, setBlock] = useState(false);
    const [error, setError] = useState(null);

	const initializeNodeMetaData = () => {
        if (!selectedNode.metaData) {
            selectedNode.metaData = {};
        }
		if(!selectedNode.metaData['1']){
			selectedNode.metaData['1'] = {};
		}
		if(!selectedNode.metaData['2']){
			selectedNode.metaData['2'] = {};
		}
		if(!selectedNode.metaData['3']){
			selectedNode.metaData['3'] = {};
		}
    }

    const handleConditionsNumberSelectChange = useCallback((type) => {
		if (selectedNode && selectedNode.data && selectedNode.data.numberOfConditions) {
			const value = type === 'add' ? selectedNode.data.numberOfConditions + 1 : selectedNode.data.numberOfConditions - 1;
			handleNumberOfConditionsChange(value);

			if(selectedNode.metaData && selectedNode.metaData.numberOfConditions){
				selectedNode.metaData.numberOfConditions = value;
				delete selectedNode.metaData['2'];
			}

			if(conditionType === 'randomSplit'){
				initializeNodeMetaData();
				if (!isNaN(selectedNode.metaData['1'].condition)) {
					if(selectedNode.metaData.numberOfConditions === 2){
						selectedNode.metaData['3'].condition = 100 - parseFloat(selectedNode.metaData['1'].condition);
					}else{
						selectedNode.metaData['2'].condition = (100 - parseFloat(selectedNode.metaData['1'].condition)) / 2;
						selectedNode.metaData['3'].condition = (100 - parseFloat(selectedNode.metaData['1'].condition)) / 2;
					}
				}
			}

			if (selectedNode) {
				setNodes(() =>
					nodes.map((node) =>
						node.id === selectedNode.id ? 
							{ ...node, data: { ...node.data, numberOfConditions: value }, metaData: selectedNode.metaData }
						: 
							node
					)
				);
				
				// Remove connections
				setEdges((edges) => {
					edges.forEach(edge => {
						if (edge.source === selectedNode.id) {
							changeEdges(edge, 'delete', edge.id);
						}
					});
					return edges.filter(edge => edge.source !== selectedNode.id);
				});
			}
		}
    }, [selectedNode, handleNumberOfConditionsChange, setNodes, setEdges]);

	const handleWaitUntilChange = useCallback((name, value) => {
		if(name === 'duration'){
			if(isNaN(value) || value <= 0){
				selectedNode.metaData['3'].condition = 1;
			} else {
				selectedNode.metaData['3'].condition = parseFloat(value);
			}
		} else if(name === 'timeType'){
			selectedNode.metaData['3'].type = value;
		} else{
			selectedNode.metaData['1'].condition = value
		}
	}, [selectedNode]);

	const handleWaitDuarationChange = useCallback((name, value) => {
		if(name === 'duration'){
			if(isNaN(value) || value <= 0){
				selectedNode.metaData['2'].condition = 1;
			} else {
				selectedNode.metaData['2'].condition = parseFloat(value);
			}
		} else {
			selectedNode.metaData['2'].type = value;
		}
	}, [selectedNode]);

	const handleRandomSplitChange = useCallback((name, value) => {
		const updateConditions = (top, middle, bottom) => {
			selectedNode.metaData['1'].condition = top;
			selectedNode.metaData['2'].condition = middle;
			selectedNode.metaData['3'].condition = bottom;
		};
		
		const parseValue = (val) => !val || isNaN(val) ? 0 : parseFloat(val);
	
		if (!selectedNode || !selectedNode.metaData || !selectedNode.data) return;
	
		const numberOfConditions = selectedNode.data.numberOfConditions;
		value = parseValue(value);
		
		if (name === 'condition1Percentage') {
			if (value <= 0) {
				if (numberOfConditions === 3) {
					updateConditions(0, 50, 50);
				} else {
					updateConditions(0, 0, 100);
				}
			} else if (value >= 100) {
				updateConditions(100, 0, 0);
			} else {
				const middle = parseValue(selectedNode.metaData['2'].condition);
				if (numberOfConditions === 3) {
					const bottom = 100 - value - middle;
					if (bottom >= 0) {
						updateConditions(value, middle, bottom);
					}
				} else {
					updateConditions(value, 0, 100 - value);
				}
			}
		} else if (name === 'condition2Percentage') {
			if (value <= 0) {
				if (numberOfConditions === 3) {
					updateConditions(50, 0, 50);
				} else {
					updateConditions(100, 0, 0);
				}
			} else if (value >= 100) {
				updateConditions(0, 0, 100);
			} else {
				const top = parseValue(selectedNode.metaData['1'].condition);
				if (numberOfConditions === 3) {
					const middle = 100 - value - top;
					if (middle >= 0) {
						updateConditions(top, middle, value);
					}
				} else {
					updateConditions(100 - value, 0, value);
				}
			}
		} else if (name === 'condition3Percentage') {
			if (value <= 0) {
				updateConditions(50, 0, 50);
			} else if (value >= 100) {
				updateConditions(0, 0, 100);
			} else {
				const top = parseValue(selectedNode.metaData['1'].condition);
				const bottom = 100 - value - top;
				if (bottom >= 0) {
					updateConditions(top, value, bottom);
				}
			}
		}
	}, [selectedNode]);

	const handleDecisionSplitChange = useCallback((name, value, field, conditionNumber) => {
		if(name === 'entity' || name === 'field'){
			delete selectedNode.metaData[conditionNumber].value;
			delete selectedNode.metaData[conditionNumber].condition;
			if(name === 'entity'){
				delete selectedNode.metaData[conditionNumber].field;
			}
		}

		if(field === 'Levels' && name === 'value'){
			let loyaltyLevel = null
			
			loyaltyCardsOptions && loyaltyCardsOptions.forEach(lc => {
				if(lc && lc.options && lc.options.length > 0){
					loyaltyLevel = lc.options.find(option => option.value === value && option.loyaltyCardTypeConfigCode === lc.type);
				}
			})

            if(loyaltyLevel && (loyaltyLevel.points === null || loyaltyLevel.points === undefined) && selectedNode && selectedNode.metaData && selectedNode.metaData[conditionNumber] 
                && selectedNode.metaData[conditionNumber].condition !== 'Equal' && selectedNode.metaData[conditionNumber].condition !== 'NotEqual'){
                    delete selectedNode.metaData[conditionNumber].condition;
            }
        }else if(name === 'field' && (value === 'HasLoyaltyCard' || value === 'DoesNothaveLoyaltyCard')){
			selectedNode.metaData[conditionNumber].field = 'HasLoyalty';
			selectedNode.metaData[conditionNumber].condition = 'Equal';
			selectedNode.metaData[conditionNumber].value = value === 'HasLoyaltyCard' ? [true] : [false];
			return;
		}

		if((value === null || value === undefined)){
			delete selectedNode.metaData[conditionNumber][name];
		}else{
			if(Array.isArray(value) || name !== 'value'){
				selectedNode.metaData[conditionNumber][name] = value;
			}else{
				selectedNode.metaData[conditionNumber][name] = [value];
			}
		}
		
	}, [selectedNode]);

	const changeCurrentNodeMetaDataFields = useCallback((evt) => {
		if (!selectedNode || !evt) return;
		const { name, value, conditionNumber, field } = evt.target ?? evt;
		if(!name || (value === undefined && field !== 'Nationality' && field !== 'Member') || value === null) return;

		initializeNodeMetaData();
		if(conditionType === 'waitUntil'){
			handleWaitUntilChange(name, value);
		} else if(conditionType === 'waitDuration'){
			handleWaitDuarationChange(name, value);
		} else if(conditionType === 'randomSplit'){
			handleRandomSplitChange(name, value);
		} else if(conditionType === 'decisionSplit'){
			if(conditionNumber === null || conditionNumber === undefined) return;
			handleDecisionSplitChange(name, value, field, conditionNumber);
		}
		
		if(selectedNode.metaData['1'] === null || selectedNode.metaData['1'] === undefined || 
			(Object.keys(selectedNode.metaData['1']) && Object.keys(selectedNode.metaData['1']).length === 0)){
			delete selectedNode.metaData['1'];
		}
		
		if(selectedNode.metaData['2'] === null || selectedNode.metaData['2'] === undefined || 
			(Object.keys(selectedNode.metaData['2']) && Object.keys(selectedNode.metaData['2']).length === 0)){
			delete selectedNode.metaData['2'];
		}
		
		if(selectedNode.metaData['3'] === null || selectedNode.metaData['3'] === undefined || 
			(Object.keys(selectedNode.metaData['3']) && Object.keys(selectedNode.metaData['3']).length === 0)){
				if(conditionType === 'decisionSplit'){
					selectedNode.metaData['3'] = {condition: 'else'};
				}else{
					delete selectedNode.metaData['3'];
				}
		}
		
		handleNodesChange(selectedNode);
	}, [selectedNode, initializeNodeMetaData, handleNodesChange]);

	useEffect(() => {
        if (conditionType === 'waitUntil') {
			const sourceNodeId = sourceEdge && sourceEdge[0] && sourceEdge[0].source;
			if(!sourceNodeId) return;

			const previousNode = nodes && nodes.find(node => node.id === sourceNodeId);
			const disableEmailOption = !previousNode || previousNode.typeUnchanged !== 'default-email';
			const disableFormOption = disableEmailOption || !previousNode || !previousNode.metaData || !previousNode.metaData.surveyId;
			const disableReserveOption = nodes && nodes.find(node => node.typeUnchanged === "input-reserve") === undefined;

			const updatedOptions = waitUntilOptions.map(category => {
                if (category && category.name === 'email') {
                    return {
                        ...category,
                        options: category.options.map(option => ({
                            ...option,
                            disabled: disableEmailOption
                        }))
                    };
                }else if(category && category.name === 'form'){
					return {
						...category,
						options: category.options.map(option => ({
							...option,
							disabled: disableFormOption
						}))
					};
				}else if(category && category.name === 'reserve'){
					return {
						...category,
						options: category.options.map(option => ({
							...option,
							disabled: disableReserveOption || (nodes && nodes.find(node => node.typeUnchanged === "input-reserve")?.metaData?.type === option.value)
						}))
					};
				}
                return category;
            });

            setWaitUntilOptions(updatedOptions);
        }
    }, [conditionType]);

    return (
        <StyledCard block={block} error={error}>
            <Row>
                <Col className='col-12 d-flex align-items-center justify-content-between'>
					<div>
                    	<FormattedMessage id="CustomerJourney.Conditions" />
					</div>
					<div>
						<Button 
							className="btn btn-host btn-sm mr-2" 
							disabled={
								!selectedNode || 
								selectedNode.data.numberOfConditions === 3 || 
								conditionType === 'waitDuration' ||
								conditionType === 'waitUntil'
							} 
							onClick={() => handleConditionsNumberSelectChange('add')}
						>
							<i className="fas fa-plus"></i>
						</Button>
					</div>
                </Col>
				<hr style={{ width: '95%' }} />
            </Row>
			{conditionType === 'waitUntil' ?
				<Row className='mt-3'>
					<Col className='col-12'>
						<Col className='col-12'>
							<p className='m-0'><FormattedMessage id="CustomerJourney.Condition1" /> (<FormattedMessage id="CustomerJourney.waitUntilEvent" />)</p>
						</Col>
						<Col className='col-12 pt-2'>
							<CustomSelect
								name="condition1"
								options={waitUntilOptions}
								value={selectedNode && selectedNode.metaData && selectedNode.metaData['1'] && waitUntilOptions.flatMap(category => {
									return category.options.find(s => s.value === selectedNode.metaData['1'].condition);
								})}
								onChange={(option) => changeCurrentNodeMetaDataFields({ name: 'condition', value: option.value })}
							/>
						</Col>
					</Col>
					<Col className='col-12 mt-4'>
						<Col className='col-12'>
							<p className='m-0'><FormattedMessage id="CustomerJourney.Condition2" /> (<FormattedMessage id="CustomerJourney.Duration" />)</p>
						</Col>
						<Row className='px-3 pt-2'>
							<Col className='col-6 pr-1'>
								<Input
									name="duration"
									type='number'
									value={selectedNode && selectedNode.metaData && selectedNode.metaData['3'] && selectedNode.metaData['3'].condition}
									onChange={changeCurrentNodeMetaDataFields}
								/>
							</Col>
							<Col className='col-6'>
								<CustomSelect
									name="timeType"
									options={timeTypes}
									value={selectedNode && selectedNode.metaData && selectedNode.metaData['3'] && timeTypes.find(s => s.value === selectedNode.metaData['3'].type)}
									onChange={(option) => changeCurrentNodeMetaDataFields({ name: 'timeType', value: option.value })}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			:
				conditionType === 'waitDuration' ?
					<Row className='mt-3'>
						<Col className='col-12'>
							<FormattedMessage id="CustomerJourney.Duration" />
						</Col>
						<Col className='col-6 pt-2 pr-1'>
							<Input
								name="duration"
								type='number'
								value={selectedNode && selectedNode.metaData && selectedNode.metaData['2'] && selectedNode.metaData['2'].condition}
								onChange={changeCurrentNodeMetaDataFields}
							/>
						</Col>
						<Col className='col-6 pt-2'>
							<CustomSelect
								name="timeTypes"
								options={timeTypes}
								value={selectedNode && selectedNode.metaData && selectedNode.metaData['2'] && timeTypes.find(s => s.value === selectedNode.metaData['2'].type)}
								onChange={(option) => changeCurrentNodeMetaDataFields({ name: 'type', value: option.value })}
							/>
						</Col>
					</Row>
				: 
					conditionType === 'randomSplit' ?
						<Row className='mt-3'>
							<Col className='col-12'>
								<Col className='col-12'>
									<FormattedMessage id="CustomerJourney.Condition1Percentage" />
								</Col>
								<Col className='col-12 d-flex align-items-center pt-2'>
									<Input
										name="condition1Percentage"
										type='text'
										value={selectedNode && selectedNode.metaData && selectedNode.metaData['1'] && selectedNode.metaData['1'].condition && selectedNode.metaData['1'].condition.toString()}
										onChange={changeCurrentNodeMetaDataFields}
									/>
									<Col className='col-2'>
										<i className="fas fa-percent"></i>
									</Col>
								</Col>
							</Col>
							{selectedNode && selectedNode.data && selectedNode.data.numberOfConditions === 3 ?
								<Col className='col-12 mt-4'>
									<Col className='col-12 d-flex align-items center'>
										<FormattedMessage id="CustomerJourney.Condition2Percentage" />
										<div className='pl-2 cursor-pointer hover-delete-condition' onClick={() => handleConditionsNumberSelectChange('delete')}>
											<i className="far fa-trash-alt"></i>
										</div>
									</Col>
									<Col className='col-12 pt-2'>
										<Col className='col-11 d-flex align-items-center px-0'>
											<Input
												name="condition3Percentage"
												type='text'
												value={selectedNode && selectedNode.metaData && selectedNode.metaData['2'] && selectedNode.metaData['2'].condition && selectedNode.metaData['2'].condition.toString()}
												onChange={changeCurrentNodeMetaDataFields}
											/>
											<Col className='col-1'>
												<i className="fas fa-percent"></i>
											</Col>
										</Col>
									</Col>
								</Col>	
							:
								<div />
							}
							<Col className='col-12 mt-4'>
								<Col className='col-12'>
									<FormattedMessage id={`CustomerJourney.Condition${selectedNode && selectedNode.data && selectedNode.data.numberOfConditions === 3 ? '3' : '2'}Percentage`} />
								</Col>
								<Col className='col-12 d-flex align-items-center pt-2'>
									<Input
										name="condition2Percentage"
										type='text'
										value={selectedNode && selectedNode.metaData && selectedNode.metaData['3'] && selectedNode.metaData['3'].condition && selectedNode.metaData['3'].condition.toString()}
										onChange={changeCurrentNodeMetaDataFields}
									/>
									<Col className='col-2'>
										<i className="fas fa-percent"></i>
									</Col>
								</Col>
							</Col>
						</Row>
					:
					    conditionType === 'decisionSplit' ?
							<DecisionSplitConfig
								selectedNode={selectedNode}
								changeCurrentNodeMetaDataFields={changeCurrentNodeMetaDataFields}
								handleConditionsNumberSelectChange={handleConditionsNumberSelectChange}
								loyaltyCardsOptions={loyaltyCardsOptions}
							/>
						:
							<div />
			}
        </StyledCard>
    );
}

export default injectIntl(ConfigConditions);
