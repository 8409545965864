import React, { Component } from 'react';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI } from '../../Base/API';
import {StyledCard } from '../../Base/CommonUIComponents';
import { Button, Col, Row } from 'reactstrap';
import { DateRangePicker } from 'react-dates';
import { QualityRun } from './QualityRun';

export class QualityRuns extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            startDate: moment().subtract(2, 'days'),
            endDate: moment(),
            qualityRuns: []
        };
    }

    componentDidMount() {
        this.getQaulityRuns();
    }

    getQaulityRuns = () => {
        const { startDate, endDate } = this.state;
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ error: errorMessage, block: false, qualityRuns: data });
            }
            else {
                this.setState({ error: errorMessage, block: false, qualityRuns: [] });
            }
        }, `/api/gms/DataQuality/v1/DataQualityRun/Dates?StartDate=${startDate.format("YYYY-MM-DD")}&EndDate=${endDate.format("YYYY-MM-DD")}`);
    }

    render() {
        const { block, error, endDate, qualityRuns, startDate } = this.state;

        return (
            <StyledCard block={block} error={error}>
                <Row className="mb-4 align-items-center">
                    <Col className="col-1">
                        <FormattedMessage id="QualityRuns.Dates" />
                    </Col>
                    <Col className="mx-2">
                        <DateRangePicker
                            startDate={startDate}
                            startDateId="startDateQualityRuns"
                            isOutsideRange={day => day > moment()}
                            endDate={endDate}
                            endDateId="endDateQualityRuns"
                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </Col>
                    <Col className="text-right">
                        <Button className="btn btn-sm btn-host" onClick={_ => this.setState({ block: true }, this.getQaulityRuns)}>
                            <i className="icon-icon-search"></i>
                        </Button>
                    </Col>
                </Row>
                {
                    qualityRuns.map((qualityRun, key) => <QualityRun key={key} qualityRun={qualityRun} />)
                }
            </StyledCard>
        );
    }
}

export default injectIntl(QualityRuns);
