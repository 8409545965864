import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Progress, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { getAPI } from '../Base/API';
import { ErrorAlert } from "../Base/ErrorAlert";
import BlockUi from 'react-block-ui';
import { getReviewFields } from '../Base/ReferenceDataFunctions';

export class ProfileReview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            reviews: []
        };
    }

    componentDidMount() {
        const { profileId } = this.props;
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                data.response.forEach(resp => {
                    resp.stars = [];
                    for (let i = 0; i < resp.score; i++) {
                        resp.stars.push({});    
                    }
                });
                this.setState({ error: errorMessage, block: false, reviews: data.response });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        },`/api/gms/Review/v1/${profileId}`)
    }

    render() {
        const { block, error, reviews } = this.state

        const Circle = ({ value }) => {
            return value &&
                <div className={'d-flex justify-content-center align-items-center mb-2 bg-host text-host-light'}
                    style={{ borderRadius: '100px', fontSize: '13px', marginRight: '12px', width: '25px', height: '23px' }}>
                    <b> {value} </b>
                </div>
        }

        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />

                <div className="m-2 p-2">
                    <Row className="mb-3">
                        <Col>
                            <h5>
                                <i className="icon-icon-reviews mr-2 ml-1" />
                                <FormattedMessage id="ProfileDetails.Reviews" />
                            </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {
                                reviews.length > 0 ?
                                    reviews.map((review, key) =>
                                        <Card className="border-0 shadow mb-3" key={key}>
                                            <CardHeader>
                                                <Row className="align-items-center">
                                                    <Col className="col-2">
                                                        <span>
                                                        {
                                                            review.stars.map((_, key2) => <i key={key2} className="fas fa-star text-warning" />)
                                                        }
                                                        </span>
                                                        <div>
                                                            <b>{review.source}</b>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="text-muted small">
                                                            <span>
                                                                <FormattedMessage id="ProfileReview.ReviewOn" />
                                                                {" "}
                                                                {moment(review.createdAt).format("DD MMMM YYYY")}
                                                            </span>
                                                        </div>
                                                    </Col>
                                                    <Col className="text-right">
                                                        {
                                                            review.reply ?
                                                                <i className="fab fa-replyd fa-2x text-success" />
                                                                :
                                                                <span />
                                                        }
                                                    </Col>
                                                </Row>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    {getReviewFields().map((rev, key) =>
                                                        <Col className="col-4 mb-2" key={key}>
                                                            <Row>
                                                                <Col> {rev.label} </Col>
                                                                <Col className="col-3">
                                                                    <Circle value={review[rev.value.charAt(0).toLowerCase() + rev.value.slice(1)]} />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    )}
                                                </Row>
                                                <div className="my-1 mt-3">
                                                    <span>
                                                        <i className="far fa-smile mr-2 text-success" />
                                                        {review.positive}
                                                    </span>
                                                </div>
                                                <div className="my-1">
                                                    <span>
                                                        <i className="far fa-frown mr-2 text-danger" />
                                                        {review.negative}
                                                    </span>
                                                </div>
                                                {
                                                    review.reply ?
                                                        <div className="my-1 mt-3">
                                                            <span>
                                                                <i className="fab fa-replyd text-success"></i>
                                                                {review.reply}
                                                            </span>
                                                        </div>
                                                        :
                                                        <div />
                                                }
                                            </CardBody>
                                        </Card>       
                                    )
                                    :
                                    <Row>
                                        <Col className="text-center">
                                            <FormattedMessage id="ProfileReview.NoReviewsAvailable" />
                                        </Col>
                                    </Row>
                            }
                        </Col>
                    </Row>
                    {/*No Reviews Available*/}
                </div>
            </BlockUi>
        );
    }
}