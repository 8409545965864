import React from 'react';

const CustomBlockUI = ({ blocking, children, className }) => {
    return (
        blocking ?
            <div style={{ textAlign: 'center', position: 'relative' }} className={`w-100 h-100 ${className}`}>
                <div style={{ position: 'absolute', background: 'transparent', width: '100%', padding: '0.5rem 0' }}>
                    <div className='loader'></div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: '0.5' }} className='w-100 h-100'>
                    {children}
                </div>
            </div>
        :
            <div className={`w-100 h-100 d-flex align-items-center justify-content-center ${className}`}>
                {children}
            </div>
    );
}

export default CustomBlockUI;
