import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Badge, Col, Row, Label, Button, Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { getConsentTypeList, getProfileType } from '../../Base/ReferenceDataFunctions';
import CreatableSelect from 'react-select/creatable';
import countryList from "react-select-country-list";
import CustomSelect from "../../Base/CustomSelect";
import { getAPI } from "../../Base/API";
import BlockUi from 'react-block-ui';
import { RecorringCampaignsDates } from '../Common/MarketingFunctions';
import { SelectHotel, SelectVipCodes } from '../../Base/CommonUIComponents';
import { handleNotification } from '../../Base/Notification';
import  CampaignTags  from './CampaignTags';
import CampaignExcludedTags  from './CampaignExcludedTags';

class CampaignStep3 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            blockTags: true,
            senders: null,
            totalItems: 0,
            offset: 0,
            limit: 10,
            tagList: [],
            loyaltyCardsOptions: [],
            activeTab: '0'
        };
    }

    componentDidMount() {
        this.getReferenceData();
        this.getTags();
        this.getLevels();
    }

    getReferenceData = () => {
        var params = '';
        if (this.state.hotelId) params += `hotelId=${this.state.hotelId}&`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                let rateData = [], channelData = [], roomCategoryData = [], cancelReasonData = [];

                data.response && data.response.forEach(referenceData => {
                    if (referenceData.type === 'Rate') {
                        rateData.push({ value: referenceData.code, label: referenceData.code });
                        return;
                    }
                    if (referenceData.type === 'Channel') {
                        channelData.push({ value: referenceData.code, label: referenceData.code });
                        return;
                    }
                    if (referenceData.type === 'RoomCategory') {
                        roomCategoryData.push({ value: referenceData.code, label: referenceData.code });
                    }
                    if (referenceData.type === 'CancelReason') {
                        cancelReasonData.push({ value: referenceData.code, label: referenceData.code });                        
                    }
                })
                this.setState({ rateData, channelData, roomCategoryData, cancelReasonData })
            }
            this.setState({ block: false })
        }, `/api/gms/Hotel/v1/hotelReferenceData?` + params);
    }

    getLevels = () => {
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage });

                return;
            }
            if (data) {
                const loyaltyCardsOptions = data.response
                    .sort((a, b) => a.minRoomNightsToLevel + a.minPointsToLevel - b.minRoomNightsToLevel - b.minPointsToLevel)
                    .map(opt => {
                        const loyaltyCardsOption = {
                            value: opt.code,
                            label: opt.description,
                        };
                        return loyaltyCardsOption;
                    });

                this.setState({ loyaltyCardsOptions: loyaltyCardsOptions });
            }
        }, `/api/gms/LoyaltyCard/v1/LoyaltyCardLevelConfig`);
    }

    getTags = () => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockTags: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    this.setState({ blockTags: false, tagList: data.response });
                }
                else {
                    this.setState({ blockTags: false });
                }
            }
            else {
                this.setState({ blockTags: false });
            }
        }, `/api/gms/Profile/v1/tag/groupDetails?tagType=all`)
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        })
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
            [name]: combo
        })
    };

    handleConsent = (data) => {
        const { campaign, handleChange } = this.props;

        if (campaign.consentType === data) {
            data = null;
        }
        const e = { target: { name: 'consentType', value: data } };

        handleChange(e);
    }

    addFilter = (key, combo, bool) => {
        const value = combo ? combo.value : null
        this.props.handleArrays(key, value, bool);

        this.setState({ [key]: null });
    }

    handleLoyaltyCard = (value) => {
        if (this.props.campaign.hasLoyaltyCard === value) {
            value = null;
        }

        this.props.handleLoyaltyCard({ target: { value: value } });
    }

    handleVipCodes = (value) => {
        if (this.props.campaign.hasVipCodes === value) {
            value = null;
        }

        this.props.handleVipCodes({ target: { value: value } });
    }

    getSelectedNationalities = () => {
        var options = countryList().getData();
        var nationalities = []

        this.props.campaign.nationalities && this.props.campaign.nationalities.forEach(c => {
            const nat = options.find(o => o.value === c);

            if (nat) {
                nationalities.push(nat);
            }
        })

        return nationalities;
    }

    toggle(id) {
        this.setState({ activeTab: id })
    }

    render() {
        const { campaign, filters, handleArrays, handleMultiDates, handleChangeMultiSelect, handleChangeFiltersArray, handleTagType, isDisabledSelectHotelIds, handleTag, handleExcludedTag, handleOtaEmail, selectType, changeTagMin } = this.props;
        const { activeTab, block, blockTags, rateData, channelData, roomCategoryData, cancelReasonData, tagList, loyaltyCardsOptions } = this.state;

        const loyaltyCardOptions = [
            { value: true, label: <FormattedMessage id="CampaignDetails.ProfilesWithCard" /> },
            { value: false, label: <FormattedMessage id="CampaignDetails.ProfilesWithoutCard" /> }
        ];

        const vipCodesOptions = [
            { value: true, label: <FormattedMessage id="CampaignDetails.ProfilesWithVipCodes" /> },
            { value: false, label: <FormattedMessage id="CampaignDetails.ProfilesWithoutVipCodes" /> }
        ];

        const otaEmailsOptions = [
            { value: true, label: <FormattedMessage id="CampaignDetails.ProfilesWithOtaEmails" /> },
            { value: false, label: <FormattedMessage id="CampaignDetails.ProfilesWithoutOtaEmails" /> }
        ];

        const occupancies = [
            { value: 'Solo', label: <FormattedMessage id="CampaignDetails.Solo" /> },
            { value: 'Couple', label: <FormattedMessage id="CampaignDetails.Couple" /> },
            { value: 'Families', label: <FormattedMessage id="CampaignDetails.Families" /> },
            { value: 'Groups', label: <FormattedMessage id="CampaignDetails.Groups" /> }
        ];

        return (
            <BlockUi tag="div" blocking={block || blockTags}>
                <div>
                    <Row className="my-2">
                        <Col>
                            <h5><FormattedMessage id="CampaignDetails.Consent" /></h5>
                        </Col>
                    </Row>
                    <Row>
                        {getConsentTypeList().map((consent, key) =>
                            <Col className="col px-2 mb-4" key={key}>
                                {consent.value ?
                                    <Card className="border-0 shadow pointer" body
                                        style={campaign && campaign.consentType && campaign.consentType === consent.value ? { backgroundColor: '#d3eaff' } : {}}
                                        onClick={() => this.handleConsent(consent.value)}
                                    >
                                        {consent.label}
                                    </Card>
                                : ''}
                            </Col>
                        )}
                    </Row>
                    <Row className="my-2">
                        <Col>
                            <h5><FormattedMessage id="CustomCampaign.ProfileType" /></h5>
                        </Col>
                    </Row>
                    <Row>
                        {
                            getProfileType(this.props.intl).filter(type => type.value !== getProfileType(this.props.intl)[3].value).map((type, key) =>
                                <Col className="mb-2 px-2" key={key}>
                                    <Card className="border-0 shadow pointer" body
                                        style={filters.profileType.some(pt => pt === type.value) ? { backgroundColor: '#d3eaff' } : {}}
                                        onClick={() => selectType(type, 'profileType')}
                                    >
                                        {type.label}
                                    </Card>
                                </Col>
                            )
                        }
                    </Row>
                </div>


                {campaign.marketingType !== 'Birthday' && campaign.marketingType !== 'CreationDay' ?
                    <div className="mb-4">
                        <RecorringCampaignsDates
                            style={{ width: "14.3%" }}
                            styleHotel={{ width: "28.5%%" }}
                            campaign={campaign}
                            filters={filters}
                            inputClassName="pt-1 px-2"
                            labelClassName="px-3"
                            handleMultiDates={handleMultiDates}
                            handleChange={this.props.handleChange}
                            handleDateChange={this.props.handleDateChange}
                        >
                            <div style={{ width: "28.5%" }} className="pt-1 px-2">
                                <SelectHotel name="hotelIds" isMulti={true} onChangeFunc={handleChangeFiltersArray} isClearable value={filters.hotelIds} isDisabled={isDisabledSelectHotelIds()} />
                            </div>
                        </RecorringCampaignsDates>
                    </div>
                : ''}


                <Row className="my-2">
                    <div style={{ width: '28%' }} className="px-3">
                        <h5><FormattedMessage id="CampaignDetails.LoyaltyCard" /></h5>
                    </div>

                    <div style={{ width: '15%' }} className="px-3">
                        <h5>
                            <FormattedMessage id="CampaignDetails.Levels" />
                        </h5>
                    </div>

                    <div style={{ width: '29%' }} className="px-3">
                        <h5> <FormattedMessage id="CampaignDetails.VipCodes" /></h5>
                    </div>
                    <div style={{ width: '28%' }} />
                </Row>

                <Row>
                    {loyaltyCardOptions.map((option, key) =>
                        <div className=" px-2 mb-4" key={key} style={{ width: '14.3%' }}>
                            <Card className="border-0 shadow pointer" body style={campaign && campaign.hasLoyaltyCard === option.value ? { backgroundColor: '#d3eaff' } : {}}
                                onClick={() => this.handleLoyaltyCard(option.value)}
                            >
                                {option.label}
                            </Card>
                        </div>
                    )}

                    <div style={{ width: '14%' }}>
                        <CustomSelect
                            className="px-2"
                            options={loyaltyCardsOptions}
                            value={loyaltyCardsOptions.filter(opt => filters.loyaltyCards.some(crd => opt.value === crd))}
                            onChange={(combo) => handleChangeFiltersArray('loyaltyCards', combo)}
                            isDisabled={!campaign.hasLoyaltyCard}
                            isClearable
                            isMulti
                        />
                    </div>

                    {vipCodesOptions.map((option, key) =>
                        <div className=" px-2 mb-4" key={key} style={{ width: '14.6%' }}>
                            <Card className="border-0 shadow pointer" body style={filters.hasVipCodes === option.value ? { backgroundColor: '#d3eaff' } : {}}
                                onClick={() => this.handleVipCodes(option.value)}
                            >
                                {option.label}
                            </Card>
                        </div>
                    )}

                    <div style={{ width: '28%' }}>
                        <SelectVipCodes name="vipCodes" isMulti={true} onChangeFunc={handleChangeFiltersArray} isClearable value={filters.vipCodes} isDisabled={!filters.hasVipCodes} />
                    </div>
                </Row>

                <Row className="my-2">
                    <Col className="d-flex">
                        <div style={{ width: '29%' }}>
                            <h5><FormattedMessage id="CampaignDetails.OtaEmails" /></h5>
                        </div>
                        <div style={{ width: '14.3%' }}>
                            <h5> <FormattedMessage id="CustomCampaign.Nationality" /></h5>
                        </div>
                        <div style={{ width: '28.5%' }}>
                            <h5><FormattedMessage id="ReferenceDataFunctions.Occupancies" /></h5>
                        </div>
                        {
                            campaign.frequency === 'CancelationDate' ?
                                <div className="pl-2" style={{ width: '28.5%' }}>
                                    <h5><FormattedMessage id="ReferenceDataFunctions.CancelReason" /></h5>
                                </div>
                                :
                                ''
                        }
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex mb-4 px-2">
                        {otaEmailsOptions.map((option, key) =>
                            <div className="px-2" key={key} style={{ width: '14.3%' }}>
                                <Card className="border-0 shadow pointer" body style={filters.hasOtaEmail === option.value ? { backgroundColor: '#d3eaff' } : {}}
                                    onClick={() => handleOtaEmail(filters.hasOtaEmail == option.value ? null : option.value)}
                                >
                                    {option.label}
                                </Card>
                            </div>
                        )}
                        <div style={{ width: '14.3%' }}>
                            <CustomSelect
                                icon={"fas fa-globe"}
                                options={countryList().getData()}
                                required={true}
                                isClearable
                                isMulti={true}
                                value={this.getSelectedNationalities()}
                                isSearchable
                                placeholder={''}
                                onChange={(combo) => handleChangeMultiSelect('nationalities', combo)}
                            />
                        </div>
                        <div className="pl-2" style={{ width: '28.5%' }}>
                            <CustomSelect
                                icon={"fas fa-users"}
                                options={occupancies}
                                value={occupancies.filter(opt => filters.occupancies.some(occ => opt.value === occ))}
                                onChange={(combo) => handleChangeFiltersArray('occupancies', combo)}
                                isClearable
                                isMulti
                                isSearchable
                            />
                        </div>
                        {
                            campaign.frequency === 'CancelationDate' ?
                                <div className="pl-3" style={{ width: '28.5%' }}>
                                    <CreatableSelect
                                        isClearable
                                        options={cancelReasonData}
                                        onChange={(combo) => this.props.handleChange({ target: { name: 'cancelReason', value: (combo ? combo.value : null) } })}
                                        formatCreateLabel={userInput => <span><FormattedMessage id="generic.Create" /> "{userInput}"</span>}
                                        value={campaign.cancelReason ? { value: campaign.cancelReason, label: campaign.cancelReason } : ''}
                                        placeholder=""
                                    />
                                </div>
                                :
                                ''
                        }
                    </Col>
                </Row>
                {
                    campaign.marketingType !== 'Birthday' && campaign.marketingType !== 'CreationDay' ?
                        <div>
                            <Row>
                                <Col>
                                    <Row className="mb-2 align-items-center">
                                        <Col className="pl-2 pr-0">
                                            <CreatableSelect
                                                isClearable
                                                options={rateData}
                                                onChange={(e) => this.handleChangeSelect(e, 'rate')}
                                                formatCreateLabel={userInput => <span><FormattedMessage id="generic.Create" /> "{userInput}"</span>}
                                                value={this.state.rate || ''}
                                                placeholder={<FormattedMessage id="Campaigns.SelectFilter" values={{ type: <FormattedMessage id="Campaigns.Rate" /> } } />}
                                            />
                                        </Col>
                                        <Col className="col-2 text-right">
                                            <Button className="btn-sm btn-host" onClick={() => this.addFilter('rate', this.state.rate, false)} disabled={this.state.rate ? false : true}>
                                                <i className="fas fa-plus" />
                                            </Button>
                                        </Col>
                                    </Row>

                                    {campaign.rate && campaign.rate.map((el, k) =>
                                        <Row className="mb-2 px-2" key={k}>
                                            <Col className="pl-3 pr-0 d-flex justify-content-between">
                                                {el}
                                                <div className="pr-2">
                                                    <i className="far fa-trash-alt pointer" onClick={() => handleArrays('rate', el, true)} style={{ color: 'red'}} />
                                                </div>
                                            </Col>
                                            <Col sm={2} />
                                        </Row>
                                    )}
                                </Col>
                                <Col>
                                    <Row className="mb-2 align-items-center">
                                        <Col className="pl-2 pr-0">
                                            <CreatableSelect
                                                isClearable
                                                options={roomCategoryData}
                                                onChange={(e) => this.handleChangeSelect(e, 'roomCategory')}
                                                formatCreateLabel={userInput => <span><FormattedMessage id="generic.Create" /> "{userInput}"</span>}
                                                value={this.state.roomCategory || ''}
                                                placeholder={<FormattedMessage id="Campaigns.SelectFilter" values={{ type: <FormattedMessage id="Campaigns.RoomCategory" /> }} />}
                                            />
                                        </Col>
                                        <Col className="col-2 text-right">
                                            <Button className="btn-sm btn-host" onClick={() => this.addFilter('roomCategory', this.state.roomCategory, false)} disabled={this.state.roomCategory ? false : true}>
                                                <i className="fas fa-plus" />
                                            </Button>
                                        </Col>
                                    </Row>

                                    {campaign.roomCategory && campaign.roomCategory.map((el, k) =>
                                        <Row className="mb-2 px-2" key={k}>
                                            <Col className="pl-3 pr-0 d-flex justify-content-between">
                                                {el}
                                                <div className="pr-2">
                                                    <i className="far fa-trash-alt pointer" onClick={() => handleArrays('roomCategory', el, true)} style={{ color: 'red' }} />
                                                </div>
                                            </Col>
                                            <Col sm={2} />
                                        </Row>
                                    )}
                                </Col>
                                <Col>
                                    <Row className="mb-2 align-items-center">
                                        <Col className="pl-2 pr-0">
                                            <CreatableSelect
                                                isClearable
                                                options={channelData}
                                                onChange={(e) => this.handleChangeSelect(e, 'channel')}
                                                formatCreateLabel={userInput => <span><FormattedMessage id="generic.Create" /> "{userInput}"</span>}
                                                value={this.state.channel || ''}
                                                placeholder={<FormattedMessage id="Campaigns.SelectFilter" values={{ type: <FormattedMessage id="Campaigns.Channel" /> }} />}
                                            />  
                                        </Col>
                                        <Col className="col-1 text-right">
                                            <Button className="btn-sm btn-host" onClick={() => this.addFilter('channel', this.state.channel, false)} disabled={this.state.channel ? false : true}>
                                                <i className="fas fa-plus" />
                                            </Button>
                                        </Col>
                                    </Row>

                                    {campaign.channel && campaign.channel.map((el, k) =>
                                        <Row className="mb-2 px-2" key={k}>
                                            <Col className="pl-3 pr-0 d-flex justify-content-between">
                                                {el}
                                                <div className="pr-2">
                                                    <i className="far fa-trash-alt pointer pr-1" onClick={() => handleArrays('channel', el, true)} style={{ color: 'red' }} />
                                                </div>
                                            </Col>
                                            <Col sm={2} />
                                        </Row>
                                    )}
                                </Col>
                            </Row>
                        </div>
                        :
                        ''
                }

                <Row className="mt-3">
                    <Col>
                        <b> Tags</b>
                    </Col>
                </Row>
                <Nav tabs className="border-0 mt-3">
                    <NavItem>
                        <NavLink className={activeTab === '0' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => { this.toggle('0'); }}>
                            <b>
                                <FormattedMessage id="CampaignDetails.Included" />
                            </b>
                            {
                                filters.tags && filters.tags.length > 0 ?
                                    <Badge className="ml-2 bg-host float-right">{filters.tags.length}</Badge>
                                    :
                                    ''
                            }
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={activeTab === '1' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => { this.toggle('1'); }}>
                            <b>
                                <FormattedMessage id="CampaignDetails.Excluded" />
                            </b>
                            {
                                filters.excludedTags && filters.excludedTags.length > 0 ?
                                    <Badge className="ml-2 bg-host float-right">{filters.excludedTags.length}</Badge>
                                    :
                                    ''
                            }
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="0">
                        <CampaignTags changeTagMin={changeTagMin} handleTag={handleTag} filters={filters} tagList={tagList} handleTagType={handleTagType} />
                    </TabPane>
                    <TabPane tabId="1">
                        <CampaignExcludedTags handleExcludedTag={handleExcludedTag} filters={filters} tagList={tagList} />
                    </TabPane>
                </TabContent>
            </BlockUi>
        );
    }
}
export default injectIntl(CampaignStep3)