import React, { Component } from 'react';
import { Button, Col, Input, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { SelectHotelGroup, StyledCard } from '../../Base/CommonUIComponents';
import { getAPI } from '../../Base/API';
import { CustomTable } from '../../Base/CustomTable';

export class TopMergeProfiles extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            hotelGroup: this.props.location && this.props.location.state && this.props.location.state.hotelGroup ? this.props.location.state.hotelGroup : null,
            minAssociatedProfiles: this.props.location && this.props.location.state && this.props.location.state.minAssociatedProfiles ? this.props.location.state.minAssociatedProfiles : null,
            masterIds: [],
            page: 0,
            sizePerPage: 10
        };
    }

    componentDidMount() {
        const { hotelGroup, minAssociatedProfiles } = this.state;

        if (hotelGroup && minAssociatedProfiles) {
            this.getTopMergeProfiles();
        }
    }

    handleHotelGroup = (_, combo) => {
        this.setState({ hotelGroup: combo && combo.value });
    }

    handleIntField = evt => {
        if (evt && evt.target) {
            const { name, value } = evt.target;
            this.setState({ [name]: value });
        }
    }

    getTopMergeProfiles = () => {
        const { hotelGroup, minAssociatedProfiles } = this.state;

        if (!this.form.current.reportValidity()) {
            return;
        }

        this.setState({ block: true });

        getAPI(result => {
            const { error, data } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response) {
                const page = this.props.location && this.props.location.state && this.props.location.state.page ? this.props.location.state.page : 0;
                this.setState({ masterIds: data.response, error: errorMessage, block: false, page: page });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Profile/v1/profile/${hotelGroup}/topMergeProfiles?minAssociatedProfiles=${minAssociatedProfiles}`);
    }

    handleTableChange = (_, { page }) => {
        this.setState({ page: page - 1 });
    }

    render() {
        const { block, error, hotelGroup, masterIds, minAssociatedProfiles, page, sizePerPage } = this.state;

        const columns = [
            {
                dataField: 'masterId',
                text: <FormattedMessage id="TopMergeProfiles.MasterId" />
            },
            {
                dataField: 'associateProfiles',
                text: <FormattedMessage id="TopMergeProfiles.AssociateProfiles" />
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: row => { this.props.history.push({ pathname: `/TopMergeProfiles/${row.masterId}`, searchCriteria: { hotelGroup: hotelGroup, minAssociatedProfiles: minAssociatedProfiles, page: page } }) }
        };

        return (
            <StyledCard block={block} error={error}>
                <form ref={this.form}>
                    <Row className="mb-2">
                        <Col className="col-1">
                            <FormattedMessage id="TopMergeProfiles.HotelGroup" />
                        </Col>
                        <Col className="col-3">
                            <SelectHotelGroup onChangeFunc={this.handleHotelGroup} value={hotelGroup} required />
                        </Col>
                        <Col className="col-2">
                            <FormattedMessage id="TopMergeProfiles.MinAssociatedProfiles" />
                        </Col>
                        <Col className="col-1">
                            <Input type="number" id="minAssociatedProfiles" name="minAssociatedProfiles" min="0" value={minAssociatedProfiles} onChange={this.handleIntField} required />
                        </Col>
                        <Col className="text-right">
                            <Button className="btn btn-sm btn-host" onClick={this.getTopMergeProfiles}>
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>
                </form>
                <Row className="mb-2">
                    <Col>
                        <CustomTable
                            keyField="masterId"
                            data={masterIds.slice(page * sizePerPage, (page * sizePerPage) + sizePerPage)}
                            columns={columns}
                            selectRow={selectRow}
                            remote={true}
                            onTableChange={this.handleTableChange}
                            page={page + 1}
                            sizePerPage={sizePerPage}
                            totalSize={masterIds.length}
                        />
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}