import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Col, Row, Collapse } from "reactstrap";
import CustomSelect from "../../../Base/CustomSelect";


export const PercentageBar = ({ left, right, reverse, title, difference, rowClass, showRightPerc }) => {
    const rightPerc = left.percentage ? parseFloat(100 - left.percentage).toFixed(1) : '0.0';

    if (!left.percentage) left.percentage = '0.0';


    return <Row className={reverse ? 'align-items-end' : rowClass}>
        <Col className="text-right">
            <span>{left.percentage.split(".")[0]}</span><span className="font_size_xxxs">.{left.percentage.split(".")[1]}</span>%
        </Col>

        <Col className={"col-5 px-0 " + (reverse ? 'd-flex flex-column-reverse' : '')}>
            <Row className="text-muted px-2 pt-1" style={{ fontSize: '10px' }}>
                <Col> {left.additionalText} </Col>
                <Col className="text-right"> {right.additionalText} </Col>
            </Row>

            <div className="d-flex " style={{ height: '15px', borderRadius: '8px', overflow: 'hidden' }}>
                {left.percentage > 0 ?
                    <span className={"pl-2 text-left color-white " + left.color} style={{ width: `${left.percentage}%`, backgroundColor: left.bgColor, fontSize: '12px' }} />
                    : ''}
                {rightPerc > 0 ?
                    <span className={"pr-2 text-right " + left.color} style={{ width: `${rightPerc}%`, backgroundColor: right.bgColor, fontSize: '12px' }} />
                    : ''}
            </div>

            <Row className="text-muted px-2 pt-1" style={{ fontSize: '10px' }}>
                <Col> {left.value} </Col>
                <Col className="text-center px-0" style={{ color: 'black' }}> {title} </Col>
                <Col className="text-right"> {right.value} </Col>
            </Row>
        </Col>

        <Col>
            {showRightPerc ?
                <span className="mr-3">
                    <span>{rightPerc.split(".")[0]}</span><span className="font_size_xxxs">.{rightPerc.split(".")[1]}</span> %
                </span>
                : ''}

            {difference?.icon ?
                <>
                    <span className="ml-2 mr-1">{difference.icon}</span>
                    {difference.percentage != 0 ?
                        <>
                            <span style={{ fontSize: '11px' }}>{difference.percentage.split(".")[0]}.{difference.percentage.split(".")[1]}% </span>
                        </>
                        : ''}
                </>
                : ''}
        </Col>
    </Row>
}


export const CompareRow = ({ firstReport, secReport, comparison, rowStyle, reportStyle, column }) => {
    return <Row className="border-bottom" style={rowStyle}>
        <Col className="pr-0 pl-4 col-1 d-flex flex-column justify-content-around" style={{ flex: '0 0 15%', maxWidth: '15%' }}>
            {column}
        </Col>
        <Col className="d-flex align-items-center" style={{ flex: '0 0 34%', maxWidth: '34%' }} style={reportStyle} >
            {firstReport}
        </Col>
        <Col className="border-left border-right d-flex align-items-center justify-content-center" style={{ flex: '0 0 17%', maxWidth: '17%' }}>
            {comparison}
        </Col>
        <Col className="d-flex align-items-center" style={{ flex: '0 0 34%', maxWidth: '34%' }} style={reportStyle}>
            {secReport}
        </Col>
    </Row>
}


export const CollapseTitle = ({ title, toggleCollapse, isOpen, filterOptions }) => {
    return <Row className="border-bottom align-items-center" style={{ height: '35px' }}>
        <Col className="pl-0">
            <div className={"px-4 py-2 pointer"} style={{ borderRadius: '0px 30px 30px 0px', fontSize: '14px', width: '255px', backgroundColor: '#ededed' }} onClick={toggleCollapse}>
                <i className={"fas mr-3 " + (isOpen ? " fa-angle-down" : " fa-angle-right")} />{title}
            </div>
        </Col>
        {filterOptions &&
            <Col className="text-right pr-0">
                <Filters
                    ruleOptions={filterOptions.ruleOptions}
                    applyRuleFilter={filterOptions.applyRuleFilter}
                    hotelOptions={filterOptions.hotelOptions}
                    applyHotelFilter={filterOptions.applyHotelFilter}
                    isOpen={filterOptions.isFilterOpen}
                    toggleCollapse={filterOptions.toggleCollapse}
                    handleStatus={{
                        statusOptions: filterOptions.statusOptions,
                        applyStatusFilter: filterOptions.applyStatusFilter,
                        selectedStatus: filterOptions.selectedStatus
                    }}
                />
            </Col>
        }
    </Row>
}


export const Filters = ({ toggleCollapse, isOpen, hotelOptions, applyHotelFilter, ruleOptions, applyRuleFilter, handleStatus }) => {
    const [hasHotelFilter, setHotelFilter] = useState(false);
    const [selectedRules, setSelectedRules] = useState([]);

    const handleHotelFilter = (combo) => {
        applyHotelFilter(combo);

        setHotelFilter(combo ? true : false);
    }

    const handleRulesFilter = (combo) => {
        applyRuleFilter(combo);

        setSelectedRules(combo || []);
    }

    const hasSelectedFilters = () => {
        return hasHotelFilter || selectedRules?.length > 0 || (handleStatus.selectedStatus.length < handleStatus.statusOptions.length)
    }


    return <>
        <div className={"px-2 float-right " + (hasSelectedFilters() ? ' color-host' : '')} style={{ borderRadius: '30px', fontSize: '14px', backgroundColor: '#ededed', width: 'fit-content' }} onClick={toggleCollapse}>
            <i className="icon-icon-filter fa-sm pointer" />
        </div>
        <div className="floatingFilterCard" style={{ position: 'absolute' }}>
            <Collapse isOpen={isOpen}>
                <div className="filterCard shadow text-left">
                    <h5 className="mb-3"><FormattedMessage id="generic.Filters" /></h5>

                    <div className="title-sm mb-1 ml-1">
                        <FormattedMessage id="generic.Hotel" />
                    </div>
                    <CustomSelect
                        isSearchable
                        isClearable
                        placeholder={<FormattedMessage id="generic.Hotel" />}
                        icon="icon-icon-hotel"
                        options={hotelOptions}
                        onChange={(combo) => handleHotelFilter(combo)}
                    />


                    <Row className="title-sm mb-1 mt-3 ml-1">
                        <Col className="pl-0">
                            <FormattedMessage id="MonthlyReportComparison.Rules" />
                        </Col>
                        <Col className="text-host text-right">
                            <span className="pointer" onClick={() => handleRulesFilter(ruleOptions)}>
                                <FormattedMessage id="generic.SelectAll" />
                            </span>
                        </Col>
                    </Row>
                    <CustomSelect
                        isMulti
                        isSearchable
                        isClearable
                        placeholder={<FormattedMessage id="MonthlyReportComparison.Rules" />}
                        icon="fas fa-list-ol"
                        options={ruleOptions}
                        onChange={(combo) => handleRulesFilter(combo)}
                        value={selectedRules}
                    />


                    <Row className="title-sm mb-1 mt-3 ml-1">
                        <Col className="pl-0">
                            <FormattedMessage id="generic.status" />
                        </Col>
                        <Col className="text-host text-right">
                            <span className="pointer" onClick={() => handleStatus.applyStatusFilter(handleStatus.statusOptions)}>
                                <FormattedMessage id="generic.SelectAll" />
                            </span>
                        </Col>
                    </Row>
                    <CustomSelect
                        isMulti
                        isSearchable
                        isClearable
                        placeholder={<FormattedMessage id="generic.status" />}
                        icon="fas fa-exclamation-triangle"
                        options={handleStatus.statusOptions}
                        onChange={(combo) => handleStatus.applyStatusFilter(combo)}
                        value={handleStatus.statusOptions.filter(so => handleStatus.selectedStatus.some(ss => ss === so.value)) || []}
                    />
                </div>
            </Collapse>
        </div>
    </>
}