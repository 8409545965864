import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import GoogleBusinessImg from '../../img/GoogleBusiness.ico';

export default class GoogleBusinessBreadcrumb extends Component {
    render() {
        const { pages } = this.props;

        return (
            <Breadcrumb>
                <BreadcrumbItem>
                    <img alt="" src={GoogleBusinessImg} height="18px" className="mr-2"/>
                    <Link to="/GoogleBusinessConfig">
                        <FormattedMessage id="GoogleBusinessConfig.Title"/>
                    </Link>
                </BreadcrumbItem>
                {pages?.map((page, key) => 
                    <BreadcrumbItem key={key} active={!page.link}>
                        {page.link ? 
                            <Link to={page.link}>
                                <FormattedMessage id={"GoogleBusinessConfig." + page.name}/>
                            </Link>
                        :
                            <FormattedMessage id={"GoogleBusinessConfig." + page.name}/>
                        }
                    </BreadcrumbItem>
                )}
            </Breadcrumb>
        )
    }
}
