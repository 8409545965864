import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { BlankCard, StyledModal } from "../../Base/CommonUIComponents";
import { Button, Col, Row, Input, FormGroup } from "reactstrap";
import { postAPI } from "../../Base/API";
import { handleNotification } from "../../Base/Notification";
import CustomSelect from "../../Base/CustomSelect";

export class CardTypeConfigModal extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            typeConfig: this.props.typeConfig || {}
        }
    }

    handleChange = (e) => {
        const { typeConfig } = this.state;
        const { name, value } = e.target;

        typeConfig[name] = value;
        this.setState({ typeConfig });
    }

    handleComboBox = (combo, evt) => {
        if (evt) {
            const { typeConfig } = this.state;
            typeConfig.walletId = combo && combo.value;

            this.setState({ typeConfig });
        }
    }

    handleSave = () => {
        if (this.form.current.reportValidity()) {
            const { updateLoyaltyTypeConfigs, toggleModal, loyaltyTypeConfigs } = this.props;
            const { typeConfig } = this.state;
            
            if(loyaltyTypeConfigs && loyaltyTypeConfigs.some(({code, id}) => code === typeConfig.code && id !== typeConfig.id)){
                handleNotification({warnings:[{message: <FormattedMessage id="LoyaltyCardTypes.CodeInUse"/>}]}, '', '');
                return;
            }

            const body = {
                request: typeConfig
            };

            this.setState({ block: true });
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ block: false, error: errorMessage });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false, error: null });
                    }
                    else if (data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="LoyaltyCardTypes.Success"/>);
                        updateLoyaltyTypeConfigs(data.response[0]);
                        this.setState({ block: false, error: null, typeConfig: data.response[0] }, toggleModal);
                    }
                    else {
                        this.setState({ block: false, error: null });
                    }
                }
                else {
                    this.setState({ block: false, error: null });
                }
            }, `/api/gms/LoyaltyCard/v1/LoyaltyCardTypeConfig`, body)
        }
    }

    render() {
        const { modal, toggleModal, wallets } = this.props;
        const { block, error, typeConfig } = this.state;

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="lg">
                <BlankCard block={block} error={error}>
                    <form ref={this.form}>
                        <Row className="align-items-center mb-3">
                            <Col>
                                <h5>{typeConfig.id ? <FormattedMessage id="LoyaltyCardTypes.EditCardType" /> : <FormattedMessage id="LoyaltyCardTypes.CreateCardType" />}</h5>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host" onClick={this.handleSave}>
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>
                        <FormGroup row className="pt-2">
                            <Col>
                                <div> <FormattedMessage id="LoyaltyCardTypes.Code" /> </div>
                                <Input
                                    required
                                    type="text"
                                    onChange={(e) => this.handleChange(e)}
                                    value={typeConfig.code}
                                    name="code"
                                    maxLength={20}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row className="pt-2">
                            <Col sm={12}>
                                <div> <FormattedMessage id="LoyaltyCardTypes.Description" /> </div>
                                <Input
                                    required
                                    type="text"
                                    onChange={(e) => this.handleChange(e)}
                                    value={typeConfig.description}
                                    name="description"
                                    maxLength={50}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row className="pt-2">
                            <Col sm={12}>
                                <div> <FormattedMessage id="LoyaltyCardTypes.Wallet" /> </div>
                                <CustomSelect options={wallets} value={wallets.find(wlt => wlt.value === typeConfig.walletId)} onChange={this.handleComboBox} />
                            </Col>
                        </FormGroup>
                    </form>
                </BlankCard>
            </StyledModal>
        );
    }
}