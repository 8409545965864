import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';

class CoolButtonCollapse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submitOptions: false
        };
    }

    toggleSubmitOptions = () => {
        this.setState({ submitOptions: !this.state.submitOptions });
    }

    render() {
        const { buttonText, buttonIcon, options } = this.props;
        const { submitOptions } = this.state;

        return (
            <div className='position-relative'>
                <Button type="submit" className="btn btn-host btn-sm" style={{ borderRadius: '0.2rem 0 0 0.2rem' }}>
                    <FormattedMessage id={buttonText}/>
                    <i className={`${buttonIcon} ml-2`}/>
                </Button>
                <Button onClick={this.toggleSubmitOptions} className="btn btn-host btn-sm" id="StatusOptions" style={{ borderRadius: '0 0.2rem 0.2rem 0', background: '#bfbfbf', border: '1px solid #bfbfbf' }}>
                    <i className='fas fa-chevron-down'/>
                </Button>
                {submitOptions && options?.length ?
                    <div style={{ borderRadius: '5px', zIndex: '1050', right: '0', width: 'max-content', fontSize: '0.95em' }} className='shadow bg-white mt-1 position-absolute'>
                        <div>
                            {options.map((op, key) =>
                                <div key={key} className='changeStatusOption p-2 d-flex align-items-center justify-content-between cursor-pointer' onClick={_ => {this.toggleSubmitOptions(); op.function()}}>
                                    <i className={`${op.icon} mr-2`}/>
                                    <FormattedMessage id={op.text}/>
                                </div>
                            )}
                        </div>
                    </div>
                :''}
            </div>
        );
    }
}

export default CoolButtonCollapse;
