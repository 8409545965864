import React, { useEffect, useState } from 'react';
import CustomSelect from "../../Base/CustomSelect";
import { Card, Row, Col } from 'reactstrap';
import { Doughnut, Line } from 'react-chartjs-2';
import { getNextColor } from '../../Base/ReferenceDataFunctions';
import countryList from 'react-select-country-list';
import { FormattedMessage } from 'react-intl';
import Chart from "react-google-charts";


export function getMainStatus(intl) {
    return [
        { value: "requests", label: intl.formatMessage({ id: "SendGrid.Requests" }), color: '#FBC753' },
        { value: "delivered", label: intl.formatMessage({ id: "SendGrid.Delivered" }), color: '#6EDE8A' },
        { value: "opens", label: intl.formatMessage({ id: "SendGrid.Opened" }), color: '#CF53FA' },
        { value: "clicks", label: intl.formatMessage({ id: "SendGrid.Clicked" }), color: '#0665ff' },
        { value: "bounces", label: intl.formatMessage({ id: "SendGrid.Bounces" }), color: '#FF9827' },
        { value: "spam_reports", label: intl.formatMessage({ id: "SendGrid.SpamReports" }), color: '#f16d84' }
    ]
}


export function getSendGridStatus(intl) {
    return getMainStatus(intl).concat(
        [
            { value: "blocks", label: intl.formatMessage({ id: "SendGrid.Blocks" }), color: '#fa339a' },
            //{ value: "bounce_drops", label: "bounce_drops", color: '' },
            //{ value: "deferred", label: "deferred", color: '' },
            //{ value: "invalid_emails", label: "invalid_emails", color: '' },
            //{ value: "processed", label: "processed", color: '' },
            //{ value: "spam_report_drops", label: "spam_report_drops", color: '' },
            //{ value: "unsubscribe_drops", label: "unsubscribe_drops", color: '' },
            { value: "unsubscribes", label: intl.formatMessage({ id: "SendGrid.Unsubscribes" }), color: '#7054E5' },
            { value: "unique_clicks", label: intl.formatMessage({ id: "SendGrid.UniqueClicks" }), color: '#75abff' },
            { value: "unique_opens", label: intl.formatMessage({ id: "SendGrid.UniqueOpens" }), color: '#df98f7' },
        ]
    )
}


export function getAggregatedByOptions(intl) {
    return [
        { value: "day", label: intl.formatMessage({ id: "SendGrid.Day" }) },
        { value: "week", label: intl.formatMessage({ id: "SendGrid.Week" }) },
        { value: "month", label: intl.formatMessage({ id: "SendGrid.Month" }) },
    ]
}


//#region inputs

export const SelectAggregatedBy = ({ intl, handleChange, required, value, isDisabled }) => {
    const options = getAggregatedByOptions(intl);

    return (
        <CustomSelect
            icon="far fa-clock"
            options={options}
            required={required}
            isSearchable
            value={options.filter(el => el.value === value) || ''}
            placeholder=""
            onChange={(e) => handleChange("aggregatedBy", e)}
            isDisabled={isDisabled}
        />
    )
};
//#endregion





//#region stats pages components
export function getOpenStatus(intl) {
    return [
        getMainStatus(intl).find(s => s.value === 'opens'),
        getSendGridStatus(intl).find(s => s.value === 'unique_opens'),
    ]
}

export function getClicksStatus(intl) {
    return [
        getMainStatus(intl).find(s => s.value === 'clicks'),
        getSendGridStatus(intl).find(s => s.value === 'unique_clicks')
    ]
}

export function getOpensAndClicksStatus(intl) {
    return getOpenStatus(intl).concat(getClicksStatus(intl))
}



export function RenderStatsPage({ renderDailyActivityCard = true, renderMapCard = false, results, metric, type }) {
    const [pieChart, setPieChart] = useState(null);
    const [top5List, setTop5List] = useState(null);
    const [lineChart, setLineChart] = useState(null);
    const [mapChart, setMapChart] = useState(null);
    const [tableData, setTableData] = useState(null);

    useEffect(() => {
        if (results && results.length > 0) {
            const orderedData = { dates: [], types: [] };
            let totalSelectedMetric = 0;

            results.forEach((date, key) => {
                orderedData.dates.push(date.date);

                date.stats.forEach((type) => {
                    const foundType = orderedData.types.find(el => el.label === type.name);
                    const value = type.metrics[metric];

                    if (foundType) {
                        foundType.data[key] = value;
                        foundType.total += value;
                    }
                    else {
                        const newType = {
                            label: type.name,
                            data: [value],
                            total: value
                        }

                        orderedData.types.push(newType);
                    }

                    totalSelectedMetric += value;
                })
            })



            //Order type by total desc
            orderedData.types.sort((a, b) => b.total - a.total);

            //Add colors and calculate percentage for each type
            orderedData.types.forEach((type, idx) => {
                type.color = getNextColor(idx);
                type.percentage = (type.total * 100) / totalSelectedMetric;
            })
            


            //Top 5 list
            const top5 = orderedData.types.slice(0, 5);


            //Pie chart
            const pieChartData = {
                labels: top5.map(el => el.label),
                datasets: [{
                    data: [],
                    backgroundColor: []
                }]
            };

            top5.forEach((el) => {
                pieChartData.labels.push(el.label);

                pieChartData.datasets[0].data.push(el.total);
                pieChartData.datasets[0].backgroundColor.push(el.color);
            })



            //Line chart
            if (renderDailyActivityCard) {
                const lineChart = {
                    labels: orderedData.dates,
                    datasets: orderedData.types.map((el, key) => ({
                        label: el.label,
                        data: el.data,
                        borderColor: el.color,
                        backgroundColor: el.color,
                    }))
                }

                setLineChart(lineChart);   
            }


            //Map Chart
            if (renderMapCard) {
                const mapData = [["Country", "Total"]];

                orderedData.types.forEach(el => {
                    mapData.push([el.label, el.total]);
                })

                setMapChart(mapData);
            } 
           
         
            setPieChart(pieChartData);
            setTop5List(top5);

            setTableData(orderedData);
        }
    }, [results, metric]);

    return <div className="mb-4">
        <PieChartCard
            cardTitle={<FormattedMessage id={`SendGrid.Top5${type}`} />}
            data={pieChart}
            top5List={top5List}
        />

        <div className="my-3">
            {renderDailyActivityCard ?
                <LineChartCard
                    data={lineChart}
                />
            : renderMapCard ?
                <MapCard
                    data={mapChart}
                />
            : ''}
        </div>

        <DailyValuesTable
            data={tableData}
        />
    </div>
}


export function PieChartCard({ data, top5List, cardTitle }) {

    const pieOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                color: 'white',
                formatter: (ctx, data) => {
                    return ctx ? ctx : '';
                }
            },
        }
    }

    return <Card className="border-0 shadow pb-5" body>
        <span className="text-muted d-flex mb-4">
            <h5> {cardTitle} </h5>
        </span>
        {data ?
            <Row>
                <Col sm={5}>
                    <Doughnut data={data} options={pieOptions} height={200} />
                </Col>
                <Col sm={7} className="pr-5">
                    <Row className="text-muted mb-2 pb-2 border-bottom">
                        <Col sm={6}> Top 5  </Col>
                        <Col> <FormattedMessage id="SendGrid.Percentage" /> </Col>
                        <Col> <FormattedMessage id="generic.Total" /> </Col>
                    </Row>

                    {top5List?.map((row, key) =>
                        <Row key={key} className="overbglight py-1 align-items-center">
                            <Col sm={6} className="d-flex align-items-center">
                                <span style={{ width: '35px', height: '9.5px', backgroundColor: row.color, border: `2px solid ${row.color}` }} className="mr-2 d-block" />
                                {row.label ? countryList().getLabel(row.label) || row.label : ''}
                            </Col>
                            <Col> {parseFloat(row.percentage).toFixed(2).replace(/[.,]00$/, "")}% </Col>
                            <Col> {row.total} </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        : <div className="text-center"> No data </div>}
    </Card>
}

export function LineChartCard({ data }) {
    
    const lineOptions = {
        type: 'line',
        maintainAspectRatio: false,
        legend: {
            display: true,
            position: "top"
        },
        scales: {
            x: { display: true },
            y: {
                beginAtZero: true, min: 0,
                grid: {
                    display: false
                },
            }
        },
        plugins: {
            datalabels: { display: false }
        }
    }


    return <Card className="border-0 shadow" body>
        <span className="text-muted d-flex mb-3">
            <h5> <FormattedMessage id="SendGrid.DailyActivity" /> </h5>
        </span>

        {data ?
            <Row>
                <Col>
                    <Line data={data} options={lineOptions} height={400} />
                </Col>
            </Row >
        : <div className="text-center"> No data </div>
}
    </Card>
}

export function DailyValuesTable({ data }) {

    return <Card className="border-0 shadow" body>
        <span className="text-muted d-flex mb-3">
            <h5> <FormattedMessage id="SendGrid.Details" /> </h5>
        </span>
        {data ?
            <>
                <Row>
                    <Col sm={2} className="border-right">
                        <div className=" border-bottom mb-2 pb-2 text-muted">
                            <b> <FormattedMessage id="generic.Date" /> </b>
                        </div>

                        {data.dates.map((row, key) =>
                            <div className="py-2">
                                <b key={key}> {row}</b>
                            </div>
                        )}
                    </Col>
                    <Col sm={10}>
                        <Row className="scrollableDiv">
                            {data.types.map((type, key) =>
                                <Col className="d-inline-block" sm={3} key={key}>
                                    <div className=" border-bottom mb-2 pb-2">
                                        <b>
                                            {type.label ? countryList().getLabel(type.label) || type.label : ''}
                                        </b>
                                    </div>

                                    {type.data.map((number, idx) =>
                                        <div className="py-2" key={key + "-number-" + idx}> {number} </div>
                                    )}
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </>
            : <div className="text-center"> No data </div>}
    </Card>
}

export function MapCard({ data }) {

    return <Card className="border-0 shadow my-3" body>
        <span className="text-muted d-flex">
            <h5><FormattedMessage id="SendGrid.TotalByCountry" /></h5>
        </span>
        <Row className="justify-content-center">
            <Col className="col-10" style={{ maxHeight: '590px', overflow: 'hidden' }}>
                <Chart
                    className='CustomChart'
                    width='100%'
                    height='auto'
                    chartType="GeoChart"
                    data={data}
                    options={{
                        colorAxis: { colors: ['#d6edfe', '#0867fa'] }
                    }}
                />
            </Col>
        </Row>
    </Card>
}
//#endregion



//#region alerts
export function getAlertType(intl) {
    return [
        { value: "stats_notification", label: intl.formatMessage({ id: "SendGridAlerts.stats_notification" }) },
        { value: "usage_limit", label: intl.formatMessage({ id: "SendGridAlerts.usage_limit" }) }
    ]
}

export function getFrequencyOptions(intl) {
    return [
        { value: "daily", label: intl.formatMessage({ id: "ReferenceDataFunctions.Daily" }) },
        { value: "weekly", label: intl.formatMessage({ id: "ReferenceDataFunctions.Weekly" }) },
        { value: "monthly", label: intl.formatMessage({ id: "ReferenceDataFunctions.Monthly" }) },
    ]
}

//#endregion



//#region webhooks
export function getWebhookActions(intl) {
    return [
        { value: 'processed', label: intl.formatMessage({ id: "SendGridWebhooks.processed" }) },
        { value: 'bounce', label: intl.formatMessage({ id: "SendGridWebhooks.bounce" }) },
        { value: 'deferred', label: intl.formatMessage({ id: "SendGridWebhooks.deferred" }) },
        { value: 'delivered', label: intl.formatMessage({ id: "SendGridWebhooks.delivered" }) },
        { value: 'open', label: intl.formatMessage({ id: "SendGridWebhooks.open" }) },
        { value: 'click', label: intl.formatMessage({ id: "SendGridWebhooks.click" }) },
        { value: 'dropped', label: intl.formatMessage({ id: "SendGridWebhooks.dropped" }) },
        { value: 'spam_report', label: intl.formatMessage({ id: "SendGridWebhooks.spam_report" }) },
        { value: 'unsubscribe', label: intl.formatMessage({ id: "SendGridWebhooks.unsubscribe" }) },
        { value: 'group_unsubscribe', label: intl.formatMessage({ id: "SendGridWebhooks.group_unsubscribe" }) },
        { value: 'group_resubscribe', label: intl.formatMessage({ id: "SendGridWebhooks.group_resubscribe" }) },
    ]
}
//#endregion


//#region Settings Status

const settingStatus = [
    { value: true, color: 'text-success', icon: 'fas fa-check-circle', text: "SendGrid.Enabled" },
    { value: false, color: 'text-danger', icon: 'fas fa-times-circle', text: "SendGrid.Disabled" },
    { value: null, color: 'text-muted', icon: 'fas fa-minus-circle', text: "SendGrid.Pending" }
]

export const SettingStatus = ({ status = null }) => {
    const statusProps = settingStatus.find(el => el.value === status);

    return statusProps && (
        <div className={statusProps.color} >
            <i className={statusProps.icon} />
            <span className="ml-2">
                <FormattedMessage id={statusProps.text} />
            </span>
        </div>
    )
};
//#endregion