import React, { Component } from 'react';
import { SingleDatePicker } from 'react-dates';
import { getAPI } from '../../../Base/API';
import CustomSelect from '../../../Base/CustomSelect';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Form, Button, Card } from 'reactstrap';
import { handleNotification } from '../../../Base/Notification';
import { Pagination, ResultsPerPage, TotalResults } from '../../../Base/PaginationComponents';
import { ActiveInactiveStatusBadge, DefaultPage, SelectHotelGroup, StyledCard } from '../../../Base/CommonUIComponents';
import { getConvertedDateToHotelTimezone, getCustomMarketingType, getMarketingType } from '../../../Base/ReferenceDataFunctions';
import moment from 'moment';


class MonitorCampaigns extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageIndex: 0,
            pageSize: 10,
            totalItems: 0,
            marketingTypeOptions: [
                { label: this.props.intl.formatMessage({ id: "CommunicationJourney.RecurringCampaigns" }), options: getMarketingType() },
                { label: this.props.intl.formatMessage({ id: "CommunicationJourney.UniqueCampaigns" }), options: getCustomMarketingType() }
            ]
        };
    }

    getCampaigns = (e) => { 
        if (e) e.preventDefault();

        this.setState({ block: true });

        var params = `?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}&fromDate=${moment(this.state.fromDate).format("YYYY-MM-DD")}`;
        if (this.state.hotelGroupId) params += `&hotelGroup=${this.state.hotelGroupId}`;
        if (this.state.marketingType) params += `&marketingType=${this.state.marketingType}`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ campaigns: data.response, totalItems: data.totalItems, error: errorMessage, block: false })
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/campaigns` + params);
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    doSearch = (e) => {
        e.preventDefault();
        this.setState({
            pageIndex: 0
        }, this.getCampaigns);
    }

    changePageSize = (evt) => {
        const { value } = evt.target;

        if (this.state.pageSize !== value) {
            this.setState({ pageSize: value, pageIndex: 0 }, this.getCampaigns);
        }
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, this.getCampaigns);
    }


    render() {
        const { block, error, marketingTypeOptions, marketingType, fromDate, hotelGroupId, campaigns, totalItems, pageIndex, pageSize } = this.state;

        return (
            <StyledCard block={block} error={error} title="NavMenu.MonitorCampaigns" icon="icon-icon-communication-journey" tabIndex="0">
                <Form onSubmit={this.doSearch}>
                    <Row className="align-items-center mt-3 mb-4">
                        <Col sm={3}>
                            <SelectHotelGroup
                                name="hotelGroupId"
                                icon="icon-icon-hotel"
                                onChangeFunc={this.handleChangeSelect}
                                value={hotelGroupId || ''}
                                placeholder={this.props.intl.formatMessage({ id: "generic.HotelGroup" })}
                            />
                        </Col>
                        <Col sm={3}>
                            <CustomSelect
                                icon="icon-icon-communication-journey"
                                options={marketingTypeOptions}
                                onChange={e => this.handleChangeSelect('marketingType', e)}
                                value={marketingTypeOptions.flatMap(el => el.options).find(({ value }) => value === (marketingType)) || ''}
                                placeholder={this.props.intl.formatMessage({ id: "CampaignDetails.CampaignType" })}
                                isSearchable isClearable
                            />
                        </Col>
                        <Col sm={2}>
                            <SingleDatePicker
                                id="dataFrom"
                                isOutsideRange={_ => false}
                                date={fromDate ? moment(fromDate) : null}
                                focused={this.state.focused}
                                onFocusChange={({ focused }) => this.setState({ focused })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                onDateChange={date => this.setState({ fromDate: date })}
                                required
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className="btn btn-host btn-sm" type="submit">
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                {campaigns?.length > 0 ?
                    <>
                        <Card className=" shadow p-2 py-2 border-0 my-3 text-muted" body>
                            <Row className="px-2 align-items-center">
                                <Col className="pr-0">
                                    <FormattedMessage id="Campaigns.Campaign" />
                                </Col>

                                <Col>
                                    <FormattedMessage id="generic.HotelGroup" />
                                </Col>

                                <Col>
                                    <FormattedMessage id="Campaigns.Sender" />
                                </Col>

                                <Col>
                                    <FormattedMessage id="Campaigns.Duration" />
                                </Col>
                                <Col className="col-1 pl-0">
                                    <FormattedMessage id="Campaigns.Frequency" />
                                </Col>
                                <Col className="col-1 px-0">
                                    <FormattedMessage id="Campaigns.ScheduleDay" />
                                </Col>

                                <Col className="col-1 text-center">
                                    <FormattedMessage id="generic.status" />
                                </Col>
                            </Row>
                        </Card>

                        {campaigns.map((campaign, idx) => {
                            const icon = marketingTypeOptions[0].options.some(mt => mt.value === campaign.marketingType) ? "icon-icon-recurring-campaign" : 'icon-icon-unique-campaign';

                            return <Card className=" shadow p-2 py-2 border-0 mb-2 overbglight" key={idx} body>
                                <Row className="px-2 align-items-center">
                                    <Col className="pr-0">
                                        <div> <b><i className={`${icon} mr-1`} /> {campaign.name} </b></div>
                                        {campaign.marketingType ? <span className="ml-4 font_size_xxs text-muted"> <FormattedMessage id={`Campaigns.${campaign.marketingType}`} /> </span> : ''}
                                    </Col>

                                    <Col> {campaign.hotelGroupName} </Col>

                                    <Col> {campaign.sender} </Col>

                                    <Col> {campaign.startDate ? moment(campaign.startDate).format("YYYY-MM-DD") : '-'} {campaign.endDate ? 'a ' + moment(campaign.endDate).format("YYYY-MM-DD") : ''} </Col>
                                    <Col className="col-1 pl-0"> {campaign.frequency && campaign.frequency !== 'None' ? <FormattedMessage id={`Campaigns.${campaign.frequency}`} /> : ''} </Col>
                                    <Col className="col-1 px-0"> {campaign.scheduleDay ? getConvertedDateToHotelTimezone(campaign.scheduleDay).format("YYYY-MM-DD HH:mm") : ''} </Col>

                                    <Col className="col-1 text-center"> <ActiveInactiveStatusBadge status={campaign.active} /> </Col>
                                </Row>
                            </Card>
                        })}

                        <Row className="my-3">
                            <Col>
                                <ResultsPerPage
                                    changeSizePerPage={this.changePageSize}
                                    pageSize={pageSize}
                                />

                                <span className="ml-2">
                                    <TotalResults
                                        end={pageIndex * pageSize + campaigns.length}
                                        start={pageIndex * pageSize + 1}
                                        total={totalItems}
                                    />
                                </span>
                            </Col>

                            <Col className="text-right">
                                <Pagination
                                    isPrevDisabled={pageIndex === 0}
                                    isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                    currentPage={pageIndex + 1}
                                    handlePrevButton={() => this.handlePagination(-1)}
                                    handleNextButton={() => this.handlePagination(1)}
                                />
                            </Col>
                        </Row>
                    </>
                    :
                    <DefaultPage text="generic.SelectSearchCriteria" icon="icon-icon-search" />
                }
            </StyledCard>
        );
    }
}

export default injectIntl(MonitorCampaigns);