import moment from 'moment-timezone';
import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Form, Modal, ModalBody, Row } from 'reactstrap';
import GenericTextArea from '../../Base/GenericTextArea';
import { getFormTypes } from '../../Base/ReferenceDataFunctions';
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import BlockUi from 'react-block-ui';

class RunModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            run: null,
            config: null,
            formOptions: [],
        };
    }

    componentDidMount(){
        const { summaryConfigRuns, selectedRunId, reviewConfigs } = this.props;

        const run = summaryConfigRuns?.find(run => run.id === selectedRunId);
        const config = reviewConfigs?.find(config => config.id === run?.reviewSummaryConfigId);

        this.setState({ run, config });
    }

    reRun = () => {
        const { run } = this.state;
        this.setState({ block: true }, () => {            
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    if (data.response && data.response.length > 0) {
                        run.report = data.response[0].choices[0].message.content;
                        run.status = "Finished";
                        run.updatedAt = moment().format('YYYY-MM-DD');
                        this.setState({ run, block: false });
                        return;
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/OpenAI/v1/GptReviewActions?reviewSummaryConfigRunId=${this.props.selectedRunId}`);
        });
    }

    render() {
        const { toggleModal, intl, formOptions } = this.props;
        const { run, block, config } = this.state;
        
        return (
            run ?
                <Modal isOpen={true} fade={false} toggle={toggleModal} className='modal-lg'>
                    <Button onClick={toggleModal} className="closeModal">
                        <i className="fas fa-times fa-sm" />
                    </Button>
                    <ModalBody className='p-4'>
                        <BlockUi blocking={block}>
                            <Row>
                                <Col className='d-flex align-items-center addPromptConfig'>
                                    <h5 className='cursor-pointer'><FormattedMessage id="SummaryConfig.Runs"/></h5>
                                    <div className='ml-1 text-muted cursor-pointer add' style={{ fontSize: '0.9em' }} onClick={this.reRun}>
                                        <i className="fas fa-sync-alt" />
                                    </div>
                                </Col>
                                <Col className='col-2 text-right'>
                                    <div style={{ color: run.status === "Running" ? '#6EDE8A' : run.status === "Finished" ? '#0665ff' :'' }}>
                                        <b><FormattedMessage id={`SummaryConfig.${run.status}`}/></b>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <div style={{ fontSize: '0.9em' }} className='text-muted'>
                                        <FormattedMessage id="SummaryConfig.Channel"/>
                                    </div>
                                    <div>
                                        <b>{config?.channel || '-'}</b>
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{ fontSize: '0.9em' }} className='text-muted'>
                                        <FormattedMessage id="SummaryConfig.FormType"/>
                                    </div>
                                    <div>
                                        {config?.formType ?
                                            getFormTypes(intl, config?.channel === 'Inquiry').find(opt => opt.value === config?.formType)?.label
                                        : "-"}
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{ fontSize: '0.9em' }} className='text-muted'>
                                        <FormattedMessage id="SummaryConfig.Form"/>
                                    </div>
                                    <div>
                                        {config?.formId && formOptions && formOptions.length > 0 ?
                                            <b>{formOptions.find(({value}) => value === parseInt(config.formId) || value === config.formId)?.label}</b>
                                        : '-'}
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{ fontSize: '0.9em' }} className='text-muted'>
                                        <FormattedMessage id="SummaryConfig.Prompt"/>
                                    </div>
                                    <div>
                                        {config?.promptConfig?.name || "-"}
                                    </div>
                                </Col>
                                <Col className=''>
                                    <div>
                                        <div style={{ fontSize: '0.9em' }} className='text-muted'>
                                            <FormattedMessage id="SummaryConfig.Date"/>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div>
                                            {moment(run.fromDate).format("YYYY-MM-DD")}
                                        </div>
                                        <div className='mx-2 h-100 d-flex align-items-end justify-content-center pb-1 text-muted'>-</div>
                                        <div>
                                            {moment(run.toDate).format("YYYY-MM-DD")}
                                        </div>
                                    </div>
                                </Col>
                                <Col className=''>
                                    <div>
                                        <div style={{ fontSize: '0.9em' }} className='text-muted'>
                                            {config?.channel === 'Inquiry' ?
                                                <FormattedMessage id="SummaryConfig.ProcessedReplies"/>
                                            :
                                                <FormattedMessage id="SummaryConfig.ProcessedReviews"/>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        {run.itemsAffected}
                                    </div>
                                </Col>
                            </Row>
                            {config?.reportType === "Report" && run.report ?
                                <Row className='mt-2'>
                                    <Col>
                                        <div style={{ fontSize: '0.9em' }} className='text-muted'>
                                            <FormattedMessage id="SummaryConfig.Report"/>
                                        </div>
                                        <div>
                                        {run.report.includes("<!DOCTYPE html>") ?
                                            <div dangerouslySetInnerHTML={{ __html: run.report }} className='metaAnalysisReport veryCoolScrollBar'
                                                style={{
                                                    width: '100%',
                                                    background: 'transparent',
                                                    boxShadow: 'unset',
                                                    outline: '0',
                                                    maxHeight : '600px',
                                                    overflow: 'auto',
                                                    padding: '5px 0',
                                                    resize: 'none',
                                                    color: '#07294C',
                                                }}>
                                            </div>
                                        :
                                            <GenericTextArea
                                                backgroundColor={"transparent"}
                                                value={run.report}
                                                placeholder={""}
                                                name="report"
                                                className="py-1"
                                                readOnly={true}
                                                noShadow={true}
                                                style={{
                                                    width: '100%',
                                                    background: 'transparent',
                                                    boxShadow: 'unset',
                                                    outline: '0',
                                                    maxHeight : '600px',
                                                    minHeight: '50px',
                                                    height: '50px',
                                                    padding: '5px 0',
                                                    resize: 'none',
                                                    color: '#07294C',
                                                }}
                                            />
                                        }
                                        </div>
                                    </Col>
                                </Row>
                            :''}
                        </BlockUi>
                    </ModalBody>
                </Modal>
            :''
        )
    }
}
export default injectIntl(RunModal);