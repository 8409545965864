import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap';
import { getAPI } from '../../Base/API';
import { StyledCard } from '../../Base/CommonUIComponents';
import { SankeyChart } from '../../Base/SankeyChart';

export class ProfileMasterDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            masterProfile: {},
            sankeyData: [],
            masterId: this.props.match.params.masterId,
            contacts: [],
            documents: [],
            searchCriteria: this.props.location && this.props.location.searchCriteria
        }
    }

    componentDidMount() {
        const { contacts, documents, masterId, sankeyData } = this.state;

        getAPI(result => {
            const { error, data } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                const masterProfile = data.response[0];

                sankeyData.push(['From', 'To', 'Weight']);

                masterProfile.contacts.forEach(contact => {
                    let contactCount = 0;
                    
                    masterProfile.associateProfiles.forEach(ap => {
                        const apContact = ap.contacts.find(c => c.contact === contact.contact);
                        if (apContact) {
                            sankeyData.push([`${contact.type}: ${contact.contact}`, `${ap.fullName}: ${ap.id}`, 1]);
                            contactCount++;
                        }
                    });

                    contacts.push({ value: contact.contact, type: contact.type, count: contactCount });

                    sankeyData.push([`Master: ${masterProfile.fullName}`, `${contact.type}: ${contact.contact}`, contactCount === 0 ? 1 : contactCount]);
                });

                masterProfile.documents.forEach(document => {
                    let documentCount = 0;
                    
                    masterProfile.associateProfiles.forEach(ap => {
                        const apDocument = ap.documents.find(c => c.number === document.number);
                        if (apDocument) {
                            sankeyData.push([`${document.type}: ${document.number}`, `${ap.fullName}: ${ap.id}`, 1]);
                            documentCount++;
                        }
                    });

                    documents.push({ value: document.number, type: document.type, count: documentCount });

                    sankeyData.push([`Master: ${masterProfile.fullName}`, `${document.type}: ${document.number}`, documentCount === 0 ? 1 : documentCount]);
                });

                masterProfile.associateProfiles.forEach(ap => {
                    ap.contacts.forEach(c => {
                        const apContact = masterProfile.contacts.find(contact => c.contact === contact.contact);
                        if (!apContact) {
                            sankeyData.push([`${ap.fullName}: ${ap.id}`, `${c.type}: ${c.contact}`, 1]);
                        }
                    });

                    ap.documents.forEach(d => {
                        const apDocument = masterProfile.documents.find(document => d.number === document.number);
                        if (!apDocument) {
                            sankeyData.push([`${ap.fullName}: ${ap.id}`, `${d.type}: ${d.number}`, 1]);
                        }
                    });
                });

                this.setState({ contacts, documents, sankeyData, error: errorMessage, block: false, masterProfile: data.response[0] });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Profile/v1/profile/${masterId}/mergeProfilesInfo`);
    }

    render() {
        const { block, contacts, documents, error, masterId, masterProfile, sankeyData, searchCriteria } = this.state;

        return (
            <StyledCard block={block} error={error}>
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to={{ pathname: "/TopMergeProfiles", state: searchCriteria }}>
                                <FormattedMessage id="NavMenu.TopMergeProfiles" />
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active><FormattedMessage id="TopMergeProfiles.MasterIdTitle" values={{ masterId: masterId }} /></BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Row className="mb-2">
                    <Col className="col-2">
                        <b>
                            <FormattedMessage id="ProfileDetails.Name" />
                        </b>
                    </Col>
                    <Col>
                        {masterProfile.firstName}
                    </Col>
                    {
                        masterProfile.middleName ?
                            <Col className="col-2">
                                <b>
                                    <FormattedMessage id="ProfileDetails.MiddleName" />
                                </b>
                            </Col>
                            :
                            <div />
                    }
                    {
                        masterProfile.middleName ?
                            <Col>
                                {masterProfile.middleName}
                            </Col>
                            :
                            <div />
                    }
                    <Col className="col-1">
                        <b>
                            <FormattedMessage id="ProfileDetails.Surname" />
                        </b>
                    </Col>
                    <Col>
                        {masterProfile.lastName}
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col className="col-2">
                        <b>
                            <FormattedMessage id="ProfileDetails.FullName" />
                        </b>
                    </Col>
                    <Col>
                        {masterProfile.fullName}
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <b>
                            <FormattedMessage id="ProfileDetails.ProfileContact" />
                        </b>
                    </Col>
                    <Col>
                        <b>
                            <FormattedMessage id="ProfileDetails.ProfileDocument" />
                        </b>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        {
                            contacts.map((item, key) =>
                                <div key={key}>
                                    {item.type}: {item.value} - <FormattedMessage id="TopMergeProfiles.Profiles" values={{ count: <b>{item.count}</b> }} />
                                </div>
                            )
                        }
                    </Col>
                    <Col>
                        {
                            documents.map((item, key) =>
                                <div key={key}>
                                    {item.type}: {item.value} - <FormattedMessage id="TopMergeProfiles.Profiles" values={{ count: <b>{item.count}</b> }} />
                                </div>
                            )
                        }
                    </Col>
                </Row>
                {
                    sankeyData.length > 0 ?
                        <SankeyChart data={sankeyData} />
                        :
                        <div />
                }
            </StyledCard>
        );
    }
}