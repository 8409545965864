import React, { Component } from 'react'
import { Model, Serializer, ElementFactory, Question } from "survey-core";
import { Converter } from "showdown";
import { Survey, ReactQuestionFactory, SurveyQuestionElementBase } from "survey-react-ui";
import { Col, Row } from 'reactstrap';
import IntlTelInput from 'react-intl-tel-input';
import "./DefaultForm.css";

const MOBILE_COUNTRY_CODE = "mobile-country-code";

export default class SurveyPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false
        };

        // Add question type metadata for further serialization into JSON
        Serializer.addClass(
            MOBILE_COUNTRY_CODE,
            [{
                name: "Mobile"
            }],
            function () {
                return new QuestionPhoneModel("");
            },
            "question"
        );

        ElementFactory.Instance.registerElement(MOBILE_COUNTRY_CODE, (name) => {
            return new QuestionPhoneModel(name);
        });

        // Register `SurveyQuestionPhonePicker` as a class that renders `mobile-country-code` questions
        ReactQuestionFactory.Instance.registerQuestion(MOBILE_COUNTRY_CODE, (props) => {
            return React.createElement(SurveyQuestionPhonePicker, props);
        });
    }
    
    render() {
        const { formData, css, hotelLogo, hotelName, activeCountryTab } = this.props;
        
        const survey = new Model(formData);

        survey.focusFirstQuestionAutomatic = false;

        survey.locale = activeCountryTab;

        const converter = new Converter();
        survey.onTextMarkdown.add(function (survey, options) {
            if(!options || !options.text) return;
            let str = converter.makeHtml(options.text);
            if(str){
                str = str.substring(3);
                str = str.substring(0, str.length - 4);
            }
            options.html = str; 
        });

        return (
            <div id='formComponent'>
                <style>
                    {css}
                </style>
                {hotelLogo || hotelName ?
                    <Row className='surveyHeader'>
                        <Col style={{ display: 'flex', alignItems: 'center' }}>
                            {hotelLogo ?
                                <img src={hotelLogo} style={{ maxWidth: '70%', height: 'auto', maxHeight: '70px' }} alt="Logo" className='mr-4'/>
                            :''}
                            {hotelName ?
                                <div className='hotelName'>
                                    <h4>{hotelName}</h4>
                                </div>
                            :''}
                        </Col>
                    </Row>
                :''}
                <Survey model={survey} />
            </div>
        );
    }
}

export class QuestionPhoneModel extends Question {
    getType() {
        return MOBILE_COUNTRY_CODE;
    }

    get colorPickerType() {
        return this.getPropertyValue("mobileCountryCodeType");
    }
    set colorPickerType(val) {
        this.setPropertyValue("mobileCountryCodeType", val);
    }

    get disableAlpha() {
        return this.getPropertyValue("disableAlpha");
    }
    set disableAlpha(val) {
        this.setPropertyValue("disableAlpha", val);
    }
}

// A class that renders questions of the new type in the UI
export class SurveyQuestionPhonePicker extends SurveyQuestionElementBase {
    constructor(props) {
        super(props);
        this.state = { value: this.question.value };
    }
    get question() {
        return this.questionBase;
    }
    get value() {
        return this.question.value;
    }
    get disableAlpha() {
        return this.question.disableAlpha;
    }
    get type() {
        return this.question.colorPickerType;
    }

    handleChange = (_, __, ___, fullNumber) => {
        this.question.value = fullNumber ? fullNumber.replaceAll(' ', '') : fullNumber;
    }

    renderElement() {
        return (
            <IntlTelInput
                preferredCountries={['pt', 'gb', 'us', 'es', 'fr', 'it', 'de']}
                onPhoneNumberChange={this.onPhoneNumberChange}
                containerClassName="intl-tel-input w-100"
                inputClassName="form-control tel-input telPadding"
            />
        );
    }
}

