import moment from 'moment-timezone';
import React, { Component } from 'react';
import { SingleDatePicker } from 'react-dates';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Input, Row } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import { SelectCustomType } from '../SalesProcessFunctions';

class InitiativeModalStep2 extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        const { initiative, handleChange, handleDate, intl, handleMarkets } = this.props;

        return (
            <div>
                <Row>
                    <Col className="col-12 mb-3">
                        <div className="title-sm">
                            <FormattedMessage id="SalesProcess.Location"/>
                        </div>
                        <Input
                            name="location"
                            placeholder=""
                            value={initiative.location || ''}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col className="col-6 mb-3">
                        <div className="title-sm">
                            <FormattedMessage id="SalesProcess.RegistrationDate"/>
                        </div>
                        <SingleDatePicker
                            date={initiative.registrationDate ? moment(initiative.registrationDate) : null}
                            id="registrationDate"
                            isOutsideRange={_ => false}
                            onDateChange={e => handleDate('registrationDate', e)}
                            focused={this.state.focused}
                            onFocusChange={({ focused }) => this.setState({ focused })}
                            small={true}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                        />
                    </Col>
                    <Col className="col-6 mb-3">
                        <div className="title-sm">
                            <FormattedMessage id="SalesProcess.PaymentDueDate"/>
                        </div>
                        <SingleDatePicker
                            date={initiative.paymentDueDate ? moment(initiative.paymentDueDate) : null}
                            id="paymentDueDate"
                            isOutsideRange={_ => false}
                            onDateChange={e => handleDate('paymentDueDate', e)}
                            focused={this.state.focused2}
                            onFocusChange={({ focused }) => this.setState({ focused2: focused })}
                            small={true}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                        />
                    </Col>
                    <Col className="col-6 mb-3">
                        <div className="title-sm">
                            <FormattedMessage id="SalesProcess.Budget"/>
                        </div>
                        <Input
                            type="number"
                            name="budget"
                            placeholder=""
                            value={initiative.budget || ''}
                            onChange={handleChange}
                            min={0}
                        />
                    </Col>
                    <Col className="col-6 mb-3">
                        <div className="title-sm">
                            <FormattedMessage id="SalesProcess.Cost"/>
                        </div>
                        <Input
                            type="number"
                            name="cost"
                            placeholder=""
                            value={initiative.cost || ''}
                            onChange={handleChange}
                            min={0}
                        />
                    </Col>
                    <Col className="col-12 mb-3">
                        <div className={`title-sm`}>
                            <FormattedMessage id="SalesDeal.Markets"/>
                        </div>
                        <SelectCustomType
                            name="markets"
                            onChangeFunc={e => handleMarkets('markets', e)}
                            value={initiative.markets || []}
                            placeholder=""
                            isClearable isSearchable
                            isMulti
                            type={'InitiativeMarketType'}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default injectIntl(InitiativeModalStep2);
