import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getAPI } from '../Base/API';
import { StyledCard } from '../Base/CommonUIComponents';
import { handleNotification } from '../Base/Notification';

export class Account extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            account: null
        }
    }

    componentDidMount() {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    this.setState({ account: data , error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Marketing/v1/egoi/myaccount`)
    }

    render() {
        const { block, error, account } = this.state;

        return (
            <StyledCard block={block} error={error}>
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/MarketingConfigEgoi"> Config</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Account</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Card className="border-0 p-3">
                    {
                        account ?
                            <div>
                                <Row>
                                    <Col>
                                        <h5>
                                            <b><FormattedMessage id="Account.GeneralInfo" /></b>
                                        </h5>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col classname="col-2">
                                        <b><FormattedMessage id="Account.Name" /></b>
                                    </Col>
                                    <Col className="col-4">
                                        {account.general_info.name}
                                    </Col>
                                    <Col className="col-2">
                                        <b><FormattedMessage id="Account.CellPhone" /></b>
                                    </Col>
                                    <Col className="col-4">
                                        {account.general_info.cellphone}
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col>
                                        <h5>
                                            <b><FormattedMessage id="Account.BillingInfo" /></b>
                                        </h5>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="col-2">
                                        <b><FormattedMessage id="Account.Name" /></b>
                                    </Col>
                                    <Col className="col-4">
                                        {account.billing_info.company_legal_name}
                                    </Col>
                                    <Col className="col-2">
                                        <b><FormattedMessage id="Account.FiscalNumber" /></b>
                                    </Col>
                                    <Col className="col-4">
                                        {account.billing_info.vat_number}
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col classname="col-2">
                                        <b><FormattedMessage id="Account.City" /></b>
                                    </Col>
                                    <Col className="col-4">
                                        {account.billing_info.city}
                                    </Col>
                                    <Col className="col-2">
                                        <b><FormattedMessage id="Account.State" /></b>
                                    </Col>
                                    <Col className="col-2">
                                        {account.billing_info.state}
                                    </Col>
                                    <Col className="col-1">
                                        <b><FormattedMessage id="Account.Country" /></b>
                                    </Col>
                                    <Col className="col-1">
                                        {account.billing_info.country.country_code}
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="col-2">
                                        <b><FormattedMessage id="Account.Address" /></b>
                                    </Col>
                                    <Col className="col-4">
                                        {account.billing_info.address1}
                                    </Col>
                                    <Col className="col-2">
                                        <b><FormattedMessage id="Account.ZipCode" /></b>
                                    </Col>
                                    <Col className="col-2">
                                        {account.billing_info.zip_code}
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col>
                                        <h5>
                                            <b><FormattedMessage id="Account.BalanceInfo" /></b>
                                        </h5>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="col-2">
                                        <b><FormattedMessage id="Account.Balance" /></b>
                                    </Col>
                                    <Col className="col-4">
                                        {account.balance_info.balance}
                                    </Col>
                                    <Col className="col-2">
                                        <b><FormattedMessage id="Account.Currency" /></b>
                                    </Col>
                                    <Col className="col-4">
                                        {account.balance_info.currency}
                                    </Col>
                                </Row>
                            </div>
                            :
                            <div />
                    }
                </Card>
            </StyledCard>
        );
    }
}