import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Modal, ModalBody, Input, CustomInput } from 'reactstrap';
import { getParameterOperator } from '../../../Base/ReferenceDataFunctions';
import { handleNotification } from "../../../Base/Notification";
import { BlankCard } from '../../../Base/CommonUIComponents';
import { postAPI, deleteAPI } from "../../../Base/API";
import CustomSelect from "../../../Base/CustomSelect";
import ConfirmActionModal from '../../../Base/ConfirmActionModal';

class TagDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            tag: this.props.tag ? this.props.tag : {},
            parameterOperator: getParameterOperator(),
            deleteModal: false,
        };
    }

    saveTag = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var tag = { ...this.state.tag };

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="TagDetails.TagSaved" />, <FormattedMessage id="generic.success" />);
                    this.props.updateTable(data.response[0], false);
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/SystemTag`, JSON.stringify({ request: tag }));
    }

    removeTag = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="TagDetails.TagRemoved" />, <FormattedMessage id="generic.success" />);
                    this.props.updateTable({ id: this.state.tag.id }, true);
                    this.toggleModal();
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/SystemTag/${this.state.tag.id}`);

    }

    handleChange = (e, type) => {
        const { name, value } = e.target;

        this.setState({
            tag: {
                ...this.state.tag,
                [name]: value
            }
        })
    }

    handleSelect = (name, combo) => {
        this.setState(prevState => ({
            tag: {
                ...prevState.tag,
                [name]: combo ? combo.value : null
            }
        }));
    }

    handleSwitchChange = (evt) => {
        if (evt && evt.target) {
            const { name, checked } = evt.target;

            this.setState(prevState => ({
                tag: {
                    ...prevState.tag,
                    [name]: checked
                }
            }));
        }
    }

    toggleModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal
        }));
    }

    render() {
        const { block, error, tag, parameterOperator, deleteModal } = this.state;

        return (
            <div>
                <Modal isOpen={this.props.modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                    <Button onClick={this.props.toggleModal} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    <ModalBody>
                        <BlankCard block={block} error={error}>
                            <Form onSubmit={this.saveTag}>
                                <Row className="mb-3">
                                    <Col>
                                        <h5>{this.props.tag ? <FormattedMessage id="TagDetails.EditTag" /> : <FormattedMessage id="TagDetails.AddTag" />}</h5>
                                    </Col>
                                    <Col className="text-right">
                                        {tag && tag.id ?
                                            <Button className="btn-sm btn-host mr-2" onClick={this.toggleModal}>
                                                <i className="fas fa-trash-alt" />
                                            </Button>
                                        : ''}
                                        <Button className="btn-sm btn-host" type="submit">
                                            <i className="fas fa-save" />
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Label sm={4}> <FormattedMessage id="TagsList.Code" /></Label>
                                            <Col sm={8}>
                                                <Input
                                                    type="text"
                                                    name="code"
                                                    onChange={(e) => this.handleChange(e)}
                                                    value={tag.code ? tag.code : ''}
                                                    required
                                                    maxLength="20"
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup row>
                                            <Label sm={4}> <FormattedMessage id="TagsList.Group" /></Label>
                                            <Col sm={8}>
                                                <CustomSelect
                                                    placeholder=""
                                                    options={this.props.tagGroups}
                                                    value={tag.tagGroupId ? this.props.tagGroups.find(tg => tg.value === tag.tagGroupId) : ''}
                                                    onChange={this.handleSelect.bind(this, 'tagGroupId')}
                                                    isClearable
                                                    isSearchable
                                                    required
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Label sm={4}> <FormattedMessage id="TagsList.Description" /></Label>
                                            <Col sm={8}>
                                                <Input
                                                    type="text"
                                                    name="description"
                                                    onChange={(e) => this.handleChange(e)}
                                                    value={tag.description ? tag.description : ''}
                                                    maxLength="500"
                                                    required
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup row>
                                            <Label sm={4}> <FormattedMessage id="TagsList.Type" /></Label>
                                            <Col sm={8}>
                                                <CustomSelect
                                                    placeholder=""
                                                    options={this.props.tagTypes}
                                                    value={tag.type ? this.props.tagTypes.find(tg => tg.value === tag.type) : ''}
                                                    onChange={this.handleSelect.bind(this, 'type')}
                                                    isSearchable
                                                    required
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup row className="align-items-center">
                                            <Label sm={4}> <FormattedMessage id="TagsList.Parameterized" /></Label>
                                            <Col sm={8}>
                                                <CustomInput
                                                    type="switch"
                                                    id="parameterized"
                                                    name="parameterized"
                                                    onChange={(e) => this.handleSwitchChange(e)}
                                                    checked={tag.parameterized}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        {tag.parameterized ?
                                            <FormGroup row>
                                                <Label sm={4}> <FormattedMessage id="TagsList.Parameter" /></Label>
                                                <Col sm={8}>
                                                    <CustomSelect
                                                        placeholder=""
                                                        options={parameterOperator}
                                                        value={tag.parameterOperator ? parameterOperator.find(tg => tg.value === tag.parameterOperator) : ''}
                                                        onChange={this.handleSelect.bind(this, 'parameterOperator')}
                                                        isClearable
                                                        isSearchable
                                                        required
                                                    />
                                                </Col>
                                            </FormGroup>
                                        : ''}
                                    </Col>
                                </Row>
                            </Form>
                        </BlankCard>
                    </ModalBody>
                </Modal>

                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={this.toggleModal}
                        actionFunction={this.removeTag}
                        text={<FormattedMessage id="TagDetails.ConfirmDeleteText" values={{ tag: tag.code }} />}
                        block={block}
                    />
                : ''}
            </div>
        );
    }
}
export default injectIntl(TagDetails)