import React, { useState } from 'react';
import { Input, CustomInput } from 'reactstrap';
import CustomSelect from '../../Base/CustomSelect';
import { FormattedMessage } from 'react-intl';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';


export const CustomFieldComponent = ({ customFieldSettings, field, handleChange }) => (
    <div>
        <div className="pr-0 mb-1"> {customFieldSettings?.description ?? field.typeCode} </div>
        <div>
            {customFieldSettings &&
                <CustomFieldInput
                    settings={customFieldSettings}
                    field={field}
                    handleChange={handleChange}
                />
            }
        </div>
    </div>
);


export const AddCustomField = ({ availableCustomData, handleChange }) => {
    const [customField, setCustomField] = useState();
    const [value, setValue] = useState();

    const selectCustomField = (combo) => {
        const value = combo ? combo.value : null;

        handleChange("value", null);
        setValue(null);
        handleChange("typeCode", value);
        setCustomField(combo);
    }

    const handleValue = (value) => {
        handleChange("value", value);
        setValue(value);
    }


    return <div>
        <div className="mb-1"><FormattedMessage id="CustomFields.CustomField" /></div>

        <CustomSelect
            options={availableCustomData}
            onChange={(combo) => selectCustomField(combo)}
            isClearable isSearchable
            placeholder=""
            value={customField || ''}
            required
        />

        {customField &&
            <div className="mt-3">
                <div className="pr-0 mb-1"> <FormattedMessage id="CustomFields.Value" /> </div>
                <CustomFieldInput
                    settings={customField}
                    field={{ value, typeCode: customField.code }}
                    handleChange={(value) => handleValue(value)}
                />
            </div>
        }
    </div>
};



const CustomFieldInput = ({ settings, field, handleChange }) => {
    const [focused, setFocused] = useState();
    
    return <div>
        {settings ?
            settings.isList ?
                settings.isMultipleSelection ?
                    <CustomSelect
                        options={settings.listElements ? settings.listElements.map(el => ({ value: el, label: el })) : []}
                        name={settings.code}
                        onChange={(combo) => handleChange(combo ? combo.map(el => el.value).join(settings.multiSelectionSeparator) : null)}
                        isClearable isSearchable
                        value={field && field.value ? field.value.split(settings.multiSelectionSeparator).map(el => ({ value: el, label: el })) : ''}
                        isMulti
                        placeholder=""
                    />
                :
                    <CustomSelect
                        options={settings.listElements ? settings.listElements.map(el => ({ value: el, label: el })) : []}
                        name={settings.code}
                        onChange={(combo) => handleChange(combo ? combo.value : null)}
                        isClearable isSearchable
                        value={field ? { value: field.value, label: field.value } : ''}
                        placeholder=""
                    />
                : settings.dataType === 'Date' ?
                    <SingleDatePicker
                        id="SingleDatePicker"
                        isOutsideRange={day => false}
                        date={field.value ? moment(field.value) : null}
                        onDateChange={date => handleChange(date ? moment(date).format("YYYY-MM-DD") : null)}
                        focused={focused}
                        onFocusChange={({ focused }) => setFocused(focused)}
                        small={true}
                        numberOfMonths={1}
                        showDefaultInputIcon={true}
                    />
                    : settings.dataType === "Bool" ?                    
                        <CustomInput
                            type="switch"
                            id="bool"
                            onChange={(e) => handleChange(e.target.checked ? "1" : "0")}
                            checked={field.value && field.value === "1" || false}
                        />
                    :
                        <Input
                            type={settings.dataType === "String" ? "text" : "number"}
                            onChange={(e) => handleChange(e.target.value)}
                            step={settings.dataType === "Int" ? 1 : 0.001}
                            maxLength={settings.inputMaxLen || 100}
                            value={field.value || ''}
                        />
            :
            <Input
                value={field.value}
                disabled
            />
        }
    </div>
};
