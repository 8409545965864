import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Modal, ModalBody, Row, Button, Collapse } from 'reactstrap';
import PreviewMobile from './PreviewMobile';
import PreviewDesktop from './PreviewDesktop';
import { getAPI } from '../../Base/API';
import BlockUi from 'react-block-ui';
import GenericTextArea from '../../Base/GenericTextArea';
import { CustomTable } from '../../Base/CustomTable';
import moment from 'moment-timezone';

class TemplateVersions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 1000,
            pageIndex: 0,
            block: false,
            templateVersions: [],
            totalItems: 0,
            showHtml: false,
            showVersionDetail: false,
            selectedTemplateVersion: null
        }
    }

    componentDidMount() {
        this.getTemplateVersions();
    }

    getTemplateVersions = () => {
        const { currentTemplateId } = this.props;
        if (currentTemplateId !== null) {
            this.setState({ block: true }, () => {
                getAPI(result => {
                    const { data, error } = result;
                    var errorMessage = [];

                    if (error) {
                        errorMessage.push({
                            message: error.message,
                            stack: error.stack,
                            messageType: "danger"
                        });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if(data.errors && data.errors.length > 0){
                            data.errors.forEach((error) => {
                                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                            })
                            this.setState({ error: errorMessage, block: false });
                            return;
                        }else if(data.response && data.response.length > 0){
                            this.setState({ templateVersions: data.response, totalItems: data.totalItems, block: false });
                        }else{
                            this.setState({ block: false });
                        }
                    }
                    else {
                        this.setState({ block: false });
                    }
                }, `api/gms/SendGrid/v1/template/${currentTemplateId}/Versions?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}`)
            });
        }
    }

    handlePagination = (value) => {
        this.setState({
            pageIndex: this.state.pageIndex + value
        }, () => this.getTemplateVersions());
    }

    toggleHtml = () => {
        this.setState({ showHtml: !this.state.showHtml });
    }

    render() {
        const { templateVersions, showVersionDetail, selectedTemplateVersion, showHtml, pageIndex, pageSize, totalItems } = this.state;

        const columns = [
            {
                dataField: 'templateId',
                text: <FormattedMessage id="stripo.TemplateId" />,
                sort: true
            },
            {
                dataField: 'templateVersion',
                text: <FormattedMessage id="stripo.TemplateVersion" />,
                sort: true
            },
            {
                dataField: 'updatedBy',
                text: <FormattedMessage id="stripo.TemplateUpdatedBy" />,
                sort: true
            },
            {
                dataField: 'updatedAt',
                text: <FormattedMessage id="stripo.UpdateTamplateVersionDate" />,
                formatter: (cell, row) => cell && moment(cell)?
                        moment(cell).format('DD/MM/YYYY - HH:mm') 
                    : '',
                sort: true
            }
        ]
        
        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row) => {
                this.setState({ showVersionDetail : true, selectedTemplateVersion : row })
            }
        };

        return (
            <Modal className='TemplateVersionModal' isOpen={this.props.previewModal} toggle={this.props.togglePreviewModal}>
                <BlockUi tag="div" blocking={this.state.block}>
                    {!showVersionDetail ? 
                        <ModalBody className='p-4' style={{ minHeight: '200px', backgroundColor:'white' }}>
                            <Row>
                                <Col className='TemplateVersionsTable'>
                                    <CustomTable
                                        keyField={'templateVersion'}
                                        data={templateVersions || []}
                                        columns={columns}
                                        shadow={false}
                                        exportCSV={false}
                                        showTotal={true}
                                        remote={false}
                                        selectRow={selectRow}
                                        hasPagination={true}
                                        search={false}
                                        hideSizePerPage={false}
                                    />
                                </Col>
                            </Row>
                        </ModalBody>
                    :
                        <>
                            <i className="fas fa-arrow-left pt-4 px-4 cursor-pointer" onClick={() => this.setState({ showVersionDetail: false, selectedTemplateVersion: null })}></i>
                            <ModalBody className='p-4' id="StripoPreview" style={{ minHeight: '200px' }}>
                                {selectedTemplateVersion && selectedTemplateVersion.content ?
                                    <>
                                        <Row className='d-flex align-items-center justify-content-between'>
                                            <Col>
                                                <h6><FormattedMessage id="stripo.currentVersion" values={{ version: selectedTemplateVersion.templateVersion }}/></h6>
                                            </Col>
                                            <Button onClick={() => this.toggleHtml()} className="btn btn-sm btn-host mr-1 btn btn-secondary p-2 control-button" title={this.props.intl.formatMessage({ id: "stripo.seeHtml" })}>
                                                <b>{`</>`}</b>
                                            </Button>
                                        </Row>
                                        
                                        <Row className='mt-2' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <PreviewDesktop
                                                compiledHtml={selectedTemplateVersion.content}
                                            />
                                            <PreviewMobile
                                                compiledHtml={selectedTemplateVersion.content}
                                            />
                                        </Row>
                                        <Collapse isOpen={showHtml}>
                                            <Row className='my-3'>
                                                <Col>
                                                    <GenericTextArea
                                                        backgroundColor={"white"}
                                                        value={selectedTemplateVersion.content}
                                                        readOnly
                                                        name={"htmlTemplateVersion"}
                                                        placeholder={""}
                                                        maxLength='2000'
                                                        className="py-1 verticalScroll"
                                                        style={{
                                                            width: '100%',
                                                            background: 'transparent',
                                                            boxShadow: 'unset',
                                                            outline: '0',
                                                            maxHeight : '500px',
                                                            minHeight: '50px',
                                                            height: '50px',
                                                            padding: '5px 0',
                                                            resize: 'none',
                                                            color: '#07294C',
                                                            minHeight: '300px',
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Collapse>
                                    </>
                                :
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <h6><FormattedMessage id="stripo.noPreviousVersions"/></h6>
                                    </div>
                                }
                            </ModalBody>
                        </>
                    }
                </BlockUi>
            </Modal>
        )
    }
}

export default injectIntl(TemplateVersions)
