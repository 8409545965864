import React, { Component } from 'react';
import { BlankCard } from '../../../Base/CommonUIComponents';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI } from '../../../Base/API';
import { handleNotification } from '../../../Base/Notification';
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import WhatsApp from './WhatsApp/WhatsApp';
import whatsApp from '../../../../img/WhatsApp.png';
import { ErrorAlert } from '../../../Base/ErrorAlert';
import BlockUi from 'react-block-ui';


export class ProfileChat extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            pageSize: 20,
            pageIndex: 0,
            totalItems: 0,
            activeTab: '0'
        }
    }

    toggleTab = (val) => {
        this.setState({ activeTab: val })
    }

    render() {
        const { block, error } = this.state;

        return (
            <div className="mx-2 px-2">
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <Nav tabs className="border-0">
                        <NavItem>
                            <NavLink className={(this.state.activeTab === '0' ? 'tab-border-host text-host' : '') + ' border-0 pt-0 pb-3 cursor-pointer'} onClick={() => { this.toggleTab('0'); }}>
                                <b><img alt="" src={whatsApp} height="20px" className="mr-2" /> WhatsApp </b>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <hr className="m-0" style={{ backgroundColor: '#C9DEFE' }} />
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="0" className="border-0 my-0 p-0 ">
                            <WhatsApp
                                profileId={this.props.profileId}
                                profile={this.props.profile}
                            />
                        </TabPane>
                    </TabContent>
                </BlockUi>
            </div>
        );
    }
}
export default injectIntl(ProfileChat);