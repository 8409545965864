import React, { Component } from 'react';
import { Button, Col, Row, Collapse, Form, Label } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from '../Base/Notification';
import { ErrorAlert } from '../Base/ErrorAlert';
import { CoolTooltip, DefaultPage, KebabMenu } from '../Base/CommonUIComponents';
import { getAPI, deleteAPI } from '../Base/API';
import { DateRangePicker } from 'react-dates';
import { CommonHelper } from '../Base/CommonUIComponents';
import CustomSelect from '../Base/CustomSelect';
import BlockUi from 'react-block-ui';
import moment from 'moment';
import SalesProcessCityEventsEventModal from './SalesProcessCityEventsEventModal';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import { getAllowedSalesProcessUsers, checkSalesProcessPermission } from './SalesProcessFunctions';

class SalesProcessCityEvents extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            configCityModal: false,
            hotels: [],
            hotelOptions: [],
            events: [],
            selectedEvent: null,
            eventModal: false,
            selectedHotelToAddEvent: null,
            hotelFilters: [],
            startDate: moment(),
            endDate: moment().add(1, 'years'),
            focusedInput: null,
            saveCityEventsPermission: false,
        };
    }

    componentDidMount() {
        window.setTimeout(
            () => {
                let currentUser = getAllowedSalesProcessUsers()?.find(x => x.isCurrentUser);
                let saveCityEventsPermission = checkSalesProcessPermission("SaveCityEvents", currentUser?.role);

                this.setState({ saveCityEventsPermission }, () => this.getHotels());
                
            }, global.modules ? 0 : 2500
        );
    }

    getHotels = (e) => {
        if(e) e.preventDefault();

        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data && data.response && data.response.length > 0) {
                    const { hotelFilters } = this.state;
                    let hotels = data.response.map(hotel => ({id: hotel.hotelId, name: hotel.name2, events: [], isEventsOpen: true}));

                    if(hotelFilters?.length > 0){
                        hotels = hotels?.filter(hotel => hotelFilters.includes(hotel.id)) || [];
                    }

                    this.setState({ hotels, hotelOptions: data.response?.map(hotel => ({ value: hotel.hotelId, label: hotel.name2, city: hotel.city })) || [] }, () => { this.getHotelsEvents(); });
                    return;
                }
                this.setState({ block: false });
            }, '/api/gms/Hotel/v1/hotel/list?onlyFromHotelGroup=true');
        });
    }

    getHotelsEvents = (e) => {
        if(e) e.preventDefault();
        const { hotels, hotelFilters, startDate, endDate } = this.state;
        const hotelList = hotelFilters?.length > 0 ? hotelFilters.map(hotelId => hotelId) : hotels.map(hotel => hotel.id);

        if(!startDate || !endDate){
            return;
        }

        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ events: [], error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ events: [], block: false });
                        return;
                    }

                    if (data.response?.length) {
                        this.setState({ events: data.response, block: false });
                        return;
                    }

                    this.setState({ error: errorMessage, block: false, events: [] });
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/location/events?${hotelList && hotelList.map(h => `hotelIds=${h}`).join('&')}${startDate ? `&startDate=${moment(startDate)?.format('YYYY-MM-DD')}` : ''}${endDate ? `&endDate=${moment(endDate)?.format('YYYY-MM-DD')}` : ''}`)
        });
    }

    deleteEvent = (toggleModal) => {
        const { selectedEvent } = this.state;

        if(!selectedEvent) {
            return;
        }

        this.setState({ block: true }, () => {
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
    
                    if(data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="SalesProcessLocationEvent.RemovedSuccessfully" />, <FormattedMessage id="generic.success" />);
                        this.setState({ block: false, deleteEventModal: false }, () => { 
                            if(toggleModal){
                                this.toggleEventModal(null, true); 
                            }else{
                                this.getHotelsEvents();
                            }
                        });
                        return;
                    }
    
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/location/events/${selectedEvent.id}`);
        });
    }

    toggleEventModal = (selectedEvent, getEvents, selectedHotelToAddEvent) => {
        const { eventModal } = this.state;
        this.setState({ eventModal: !eventModal, selectedEvent, selectedHotelToAddEvent }, () => { if(getEvents) this.getHotelsEvents(); });
    }

    toggleEventCollapse = (index) => {
        const { hotels } = this.state;
        hotels[index].isEventsOpen = !hotels[index].isEventsOpen;
        this.setState({ hotels });
    }

    toggleDeleteEventModal = (selectedEvent) => {
        const { deleteEventModal } = this.state;
        this.setState({ deleteEventModal: !deleteEventModal, selectedEvent });
    }

    handleHotels = (combo) => {
        this.setState({ hotelFilters: combo ? combo.map(c => c.value) : [] });
    };

    render() {
        const { error, block, events, selectedEvent, eventModal, selectedHotelToAddEvent, deleteEventModal, hotelFilters, startDate, endDate, focusedInput, hotelOptions, saveCityEventsPermission } = this.state;
        const { intl, icon } = this.props;
        
        return (
            <div className="border-0 h-100 pb-2">
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <div className='mb-4 d-flex align-items-center'>
                        <Col>
                        <h4 className='m-0'>
                            <i className={`${icon} mr-2`} />
                            <FormattedMessage id="SalesProcess.SalesProcessCityEvents" />
                            </h4>
                        </Col>
                        <Col className="pr-0">
                            <CommonHelper help={''} id={'CityEventsHelp'} />
                            </Col>
                    </div>
                    <Row>
                        <Col className='col-12 px-0'>
                            <Form className='d-flex align-items-center' onSubmit={(e) => {this.getHotels(e)}}>
                                <Col className='col-3'>
                                    <Label for="hotelListFilter">
                                        <FormattedMessage id="SalesProcess.SalesProcessCityEventsHotel" />
                                    </Label>
                                    <CustomSelect
                                        id="hotelListFilter"
                                        name="hotelListFilter"
                                        value={hotelFilters?.length && hotelOptions?.length ? hotelFilters.map(h => hotelOptions.find(ho => ho.value === h)) : null}
                                        options={hotelOptions}
                                        onChange={(combo) => this.handleHotels(combo)}
                                        isMulti={true}
                                        isSearchable
                                        placeholder={"Hotel"}
                                    />
                                </Col>
                                <Col className='col-3'>
                                    <Label for="cityevents_datesFilter">
                                        <FormattedMessage id="SalesProcess.SalesProcessCityEventsDates" />
                                    </Label>
                                    <div id="cityevents_datesFilter">
                                        <DateRangePicker
                                            startDate={startDate || null}
                                            startDateId="startDate_cityevents_filter"
                                            isOutsideRange={(day) => this.state.focusedInput == "endDate" ? day > moment(startDate).add(1, 'year') : null}
                                            endDate={endDate || null}
                                            endDateId="endDate_cityevents_filter"
                                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                            focusedInput={focusedInput}
                                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                                            small={true}
                                            numberOfMonths={1}
                                            showDefaultInputIcon={true}
                                            renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                                            required={true}
                                        />
                                    </div>
                                </Col>
                                <Col className='text-right'>
                                    <Button type='button' className="btn-sm btn-host btn mr-2" onClick={() => this.toggleEventModal(null, false, null)}>
                                        <i className="fas fa-plus" />
                                    </Button>
                                    <Button type='submit' className="btn-sm btn-host btn">
                                        <i className="fas fa-search" />
                                    </Button>
                                </Col>
                            </Form>
                        </Col>
                        <Col className='col-12'>
                            {events && events.length > 0 ?
                                <div className='mt-4' style={{ fontSize: '0.9em' }}>
                                    <div className='d-flex align-items-center justify-content-between shadow p-3 mb-3 bg-white text-muted' style={{ borderRadius: '4px', gap: '1%' }} >
                                        <div style={{ width: '20%' }}>
                                            <FormattedMessage id={`SalesProcess.SalesProcessCityEventsEventName`} />
                                        </div>
                                        <div style={{ width: '20%' }}>
                                            <FormattedMessage id={`SalesProcess.SalesProcessCityEventsHotels`} />
                                        </div>
                                        <div style={{ width: '15%' }}>
                                            <FormattedMessage id={`SalesProcess.Dates`} />
                                        </div>
                                        <div style={{ width: '10%' }}>
                                            <FormattedMessage id={`SalesProcess.SalesProcessCityEventsEventType`} />
                                        </div>
                                        <div style={{ width: '10%' }}>
                                            <FormattedMessage id={`SalesProcess.SalesProcessCityEventsEventCategory`} />
                                        </div>
                                        <div style={{ width: '10%' }}>
                                            <FormattedMessage id={`SalesProcess.SalesProcessCityEventsEventPax`} />
                                        </div>
                                        <div style={{ width: '10%' }}>
                                            <FormattedMessage id={`SalesProcess.SalesProcessCityEventsEventLocation`} />
                                        </div>
                                        <div style={{ width: '5%' }}>
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        {events.map((evt, key) =>
                                            <div onClick={_ => this.toggleEventModal(evt)} className='d-flex align-items-center justify-content-between shadow p-3 mb-2 bg-white cursor-pointer' key={key} style={{ borderRadius: '4px', gap: '1%' }}>
                                                <div style={{ width: '20%' }}>
                                                    <div className='text-truncate'>
                                                        <b>{evt.name}</b>
                                                    </div>
                                                </div>
                                                <div style={{ width: '20%' }}>
                                                    {evt.hotelList ?
                                                        <div style={{ maxHeight: '22px' }} className='overflow-auto veryCoolScrollBar'>
                                                            {evt.hotelList.map((id, key) => {
                                                                const hotel = global?.hotelList?.find(x => x.value === id);
                                                                return (

                                                                    <div key={key} className={key ? 'mt-1 text-truncate' : 'text-truncate'}>
                                                                        {hotel.label}
                                                                    </div>
                                                                )
                                                            }
                                                            )}
                                                        </div>
                                                        : '-'}
                                                </div>
                                                <div style={{ width: '15%' }}>
                                                    <div className='text-truncate' title={`${moment(evt.endDate).format('YYYY/MM/DD')}${evt.endDate ? ` - ${moment(evt.startDate).format('YYYY/MM/DD')}` : ''}`}>
                                                        {evt.startDate ?
                                                            <span>
                                                                {moment(evt.startDate).format('YYYY/MM/DD')}
                                                            </span>
                                                            : '-'}

                                                        {evt.endDate ?
                                                            <>
                                                                <span className='mx-1'>-</span>
                                                                <span>
                                                                    {moment(evt.endDate).format('YYYY/MM/DD')}
                                                                </span>
                                                            </>
                                                            : ''}
                                                    </div>
                                                </div>
                                                <div style={{ width: '10%' }}>
                                                    <div className='text-truncate'>
                                                        {evt.eventType}
                                                    </div>
                                                </div>
                                                <div style={{ width: '10%' }}>
                                                    <div className='text-truncate'>
                                                        {evt.category??"-"}
                                                    </div>
                                                </div>
                                                <div style={{ width: '10%' }}>
                                                    <div className='text-truncate'>
                                                        {evt.pax ?? "-"}
                                                    </div>
                                                </div>
                                                <div style={{ width: '10%' }}>
                                                    <div className='text-truncate'>
                                                        {evt.location ?? "-"}
                                                    </div>
                                                </div>
                                                <div style={{ width: '5%' }} className='text-right' onClick={(e) => e.stopPropagation()}>
                                                    <KebabMenu
                                                        editFunction={() => this.toggleEventModal(evt)}
                                                        deleteFunction={() => this.toggleDeleteEventModal(evt)}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            :
                                <DefaultPage
                                    icon='fas fa-city'
                                    text={intl.formatMessage({ id: 'SalesProcess.SalesProcessCityEventsNoCities' })}
                                />
                            }
                        </Col>
                    </Row>

                    {eventModal ?
                        <SalesProcessCityEventsEventModal
                            selectedEvent={selectedEvent}
                            hotelListOptions={hotelOptions}
                            selectedHotelToAddEvent={selectedHotelToAddEvent}
                            saveCityEventsPermission={saveCityEventsPermission}
                            toggleModal={this.toggleEventModal}
                            deleteEvent={this.deleteEvent}
                        />
                    :''}

                    {deleteEventModal ?
                        <ConfirmActionModal
                            modal={deleteEventModal}
                            toggleModal={() => this.toggleDeleteEventModal()}
                            actionFunction={() => this.deleteEvent()}
                            text={<FormattedMessage id="SalesProcess.SalesProcessCityEventsDeleteEventText" values={{ event: selectedEvent?.name }} />}
                        />
                    :''}

                </BlockUi>
            </div>
        )
    }
}

export default injectIntl(SalesProcessCityEvents);
