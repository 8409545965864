import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, CustomInput, Row, Nav, NavItem, NavLink, TabContent, TabPane, Badge } from 'reactstrap';
import { CheckIcon } from './MergeFunctions';
import classnames from 'classnames';
import moment from 'moment';
import $ from 'jquery'
import { ActiveInactiveStatusBadge } from '../../Base/CommonUIComponents';

class ProfileDataTabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1'
        };
    }

    syncScroll = () => {
        const divs = ["#div1", "#divAddresses", "#divConsents", "#divContacts", "#divDocuments", /*"#divInformations", "#divPreferences", "#divSalesMarketing", "#divProfileWarnings",*/ "#divSocialMedia", "#divMembershipCard"];

        divs.forEach(div => {
            divs.forEach(othersDiv => {
                if (othersDiv !== div) {
                    $(div).scroll(function () {
                        $(othersDiv).scrollLeft($(this).scrollLeft());
                    });
                }
            })
        })
    }

    toggle = (id) => {
        this.setState({ activeTab: id })
    }

    render() {
        const { duplicatedProfiles, handleSwitch } = this.props;
        const { addresses, contacts, consents, documents, /*informations, preferences, salesMarketing, profileWarnings, */ socialMedia, membershipCard, profileCredit, profileCustomData } = this.props.master;

        //Sync scrolls
        $('#div1, #divAddresses, #divConsents, #divContacts, #divDocuments, #divSocialMedia, #divMembershipCard, #divProfileCredit').on("scroll", this.syncScroll()); // #divInformations, #divPreferences, #divSalesMarketing, #divProfileWarnings

        return (
            <>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                            <FormattedMessage id="ProfileDetails.ProfileAddress" />
                            {addresses && addresses.length > 0 ? <Badge className="ml-2" color="primary"> {addresses.length} </Badge> : ''}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                            <FormattedMessage id="ProfileDetails.ProfileConsent" />
                            {consents && consents.length > 0 ? <Badge className="ml-2" color="primary"> {consents.length} </Badge> : ''}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                            <FormattedMessage id="ProfileDetails.ProfileContact" />
                            {contacts && contacts.length > 0 ? <Badge className="ml-2" color="primary"> {contacts.length} </Badge> : ''}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggle('4'); }}>
                            <FormattedMessage id="ProfileDetails.ProfileDocument" />
                            {documents && documents.length > 0 ? <Badge className="ml-2" color="primary"> {documents.length} </Badge> : ''}
                        </NavLink>
                    </NavItem>
                    {/*<NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '5' })} onClick={() => { this.toggle('5'); }}>
                            <FormattedMessage id="ProfileDetails.ProfileInformation" />
                            {informations && informations.length > 0 ? <Badge className="ml-2" color="primary"> {informations.length} </Badge> : ''}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '6' })} onClick={() => { this.toggle('6'); }}>
                            <FormattedMessage id="ProfileDetails.ProfilePreference" />
                            {preferences && preferences.length > 0 ? <Badge className="ml-2" color="primary"> {preferences.length} </Badge> : ''}
                        </NavLink>
                    </NavItem>*/}
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '10' })} onClick={() => { this.toggle('10'); }}>
                            <FormattedMessage id="ProfileDetails.MembershipCard" />
                            {membershipCard && membershipCard.length > 0 ? <Badge className="ml-2" color="primary"> {membershipCard.length} </Badge> : ''}
                        </NavLink>
                    </NavItem>
                    {/*<NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '7' })} onClick={() => { this.toggle('7'); }}>
                            <FormattedMessage id="ProfileDetails.ProfileSaleMarketing" />
                            {salesMarketing && salesMarketing.length > 0 ? <Badge className="ml-2" color="primary"> {salesMarketing.length} </Badge> : ''}
                        </NavLink>
                    </NavItem>*/}
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '8' })} onClick={() => { this.toggle('8'); }}>
                            <FormattedMessage id="ProfileDetails.ProfileSocialMedia" />
                            {socialMedia && socialMedia.length > 0 ? <Badge className="ml-2" color="primary"> {socialMedia.length} </Badge> : ''}
                        </NavLink>
                    </NavItem>
                    {/*<NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '9' })} onClick={() => { this.toggle('9'); }}>
                            <FormattedMessage id="ProfileDetails.Warnings" />
                            {profileWarnings && profileWarnings.length > 0 ? <Badge className="ml-2" color="primary"> {profileWarnings.length} </Badge> : ''}
                        </NavLink>
                    </NavItem>*/}
                    {global.toMasterProperties?.some(el => el === 'Credit') ?
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '11' })} onClick={() => { this.toggle('11'); }}>
                                <FormattedMessage id="ProfileDetails.Credit" />
                                {profileCredit?.length > 0 ? <Badge className="ml-2" color="primary"> {profileCredit.length} </Badge> : ''}
                            </NavLink>
                        </NavItem>
                        : ''}
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '12' })} onClick={() => { this.toggle('12'); }}>
                            <FormattedMessage id="ProfileDetails.CustomData" />
                            {profileCustomData?.length > 0 ? <Badge className="ml-2" color="primary"> {profileCustomData.length} </Badge> : ''}
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <Row>
                            <Col className="col-4 border-right">
                                {addresses && addresses.length > 0 && addresses.map((address, i) =>
                                    <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '160px' }} key={i}>
                                        <Col className="col-11 d-flex align-items-center pl-4">
                                            <span>
                                                <i className="fas fa-map-marker-alt mr-2 mb-2"></i>{address.locality}, {address.country} <br />

                                                <b className="ml-3"><FormattedMessage id="ProfileDetails.Type" />:</b> <FormattedMessage id={`ReferenceDataFunctions.${address.type}`} /> <br />
                                                <b className="ml-3"><FormattedMessage id="ProfileDetails.AddressLine" /> 1:</b> {address.addressLine1} <br />
                                                <b className="ml-3"><FormattedMessage id="ProfileDetails.AddressLine" /> 2:</b> {address.addressLine2} <br />
                                                <b className="ml-3"><FormattedMessage id="ProfileDetails.AddressLine" /> 3:</b> {address.addressLine3} <br />
                                                <b className="ml-3"><FormattedMessage id="ProfileDetails.AddressLine" /> 4:</b> {address.addressLine4} <br />
                                                <b className="ml-3"><FormattedMessage id="ProfileDetails.Region" />:</b> {address.region} <b className="ml-2">Postal Code:</b> {address.postalCode}
                                            </span>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                            <div className="col-8 pr-0">
                                <Row className="scrollableDiv" id="divAddresses">
                                    {duplicatedProfiles && duplicatedProfiles.map((profile, key) =>
                                        <Col className="col-6 border-right" key={key} style={{ display: 'inline-block', height: '100%' }}>
                                            {profile.addresses && profile.addresses.map((address, i) =>
                                                <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '160px' }} key={i}>
                                                    <Col className="col-1 d-flex align-items-center">
                                                        <CustomInput
                                                            id={'addresses' + (key + 1) + (i + 1)}
                                                            type="switch"
                                                            name="addresses"
                                                            onChange={(e) => handleSwitch(e, address)}
                                                            checked={this.props.state['addresses' + (key + 1) + (i + 1)] ? true : false}
                                                        />
                                                    </Col>
                                                    <Col className="col-11 d-flex align-items-center pl-4">
                                                        <span className="w-100">
                                                            <Row>
                                                                <Col sm={11}><i className="fas fa-map-marker-alt mr-2 mb-2"></i>{address.locality}, {address.country} </Col>
                                                                <CheckIcon checkIcon={address.checkIcon} />
                                                            </Row>

                                                            <b className="ml-3"><FormattedMessage id="ProfileDetails.Type" />:</b> <FormattedMessage id={`ReferenceDataFunctions.${address.type}`} /> <br />
                                                            <b className="ml-3"><FormattedMessage id="ProfileDetails.AddressLine" /> 1:</b> {address.addressLine1} <br />
                                                            <b className="ml-3"><FormattedMessage id="ProfileDetails.AddressLine" /> 2:</b> {address.addressLine2} <br />
                                                            <b className="ml-3"><FormattedMessage id="ProfileDetails.AddressLine" /> 3:</b> {address.addressLine3} <br />
                                                            <b className="ml-3"><FormattedMessage id="ProfileDetails.AddressLine" /> 4:</b> {address.addressLine4} <br />
                                                            <b className="ml-3"><FormattedMessage id="ProfileDetails.Region" />:</b> {address.region} <b className="ml-2">Postal Code:</b> {address.postalCode}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </Row>
                    </TabPane>

                    <TabPane tabId="2">
                        <Row>
                            <Col className="col-4 border-right">
                                {consents && consents.length > 0 && consents.map((consent, i) =>
                                    <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '70px' }} key={i}>
                                        <Col className="col-11 d-flex align-items-center pl-4">
                                            <span>
                                                {consent.type}{consent.active ? <><Badge color="primary" className="ml-2">Active</Badge> </> : ''}<br />
                                                <b>Begin:</b> {moment(consent.consentBegin).format('YYYY-MM-DD')}
                                                <b className="ml-2">End:</b> {consent.consentEnd ? moment(consent.consentEnd).format('YYYY-MM-DD') : ''} <br />
                                                <b>Method:</b>{consent.method}
                                            </span>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                            <div className="col-8 pr-0">
                                <Row className="scrollableDiv" id="divConsents">
                                    {duplicatedProfiles && duplicatedProfiles.map((profile, key) =>
                                        <Col className="col-6 border-right" key={key} style={{ display: 'inline-block', height: '100%' }}>
                                            {profile.consents && profile.consents.map((consent, i) =>
                                                <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '70px' }} key={i}>
                                                    <Col className="col-1 d-flex align-items-center">
                                                        <CustomInput
                                                            id={'consents' + (key + 1) + (i + 1)}
                                                            type="switch"
                                                            name="consents"
                                                            onChange={(e) => handleSwitch(e, consent)}
                                                            checked={this.props.state['consents' + (key + 1) + (i + 1)] ? true : false}
                                                        />
                                                    </Col>
                                                    <Col className="col-11 d-flex align-items-center pl-4">
                                                        <span className="w-100">
                                                            <Row>
                                                                <Col sm={11}>{consent.type}{consent.active ? <><Badge color="primary" className="ml-2">Active</Badge> </> : ''} </Col>
                                                                <CheckIcon checkIcon={consent.checkIcon} />
                                                            </Row>
                                                            <b>Begin:</b> {moment(consent.consentBegin).format('YYYY-MM-DD')}
                                                            <b className="ml-2">End:</b> {consent.consentEnd ? moment(consent.consentEnd).format('YYYY-MM-DD') : ''} <br />
                                                            <b>Method:</b>{consent.method}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </Row>
                    </TabPane>

                    <TabPane tabId="3">
                        <Row>
                            <Col className="col-4 border-right">
                                {contacts && contacts.length > 0 && contacts.map((contact, i) =>
                                    <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '38px' }} key={i}>
                                        <Col className="col-11 d-flex align-items-center pl-4">
                                            <span><b>{contact.type}:</b> {contact.contact} {contact.main ? <Badge color="primary" className="ml-2">Main</Badge> : ''} </span>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                            <div className="col-8 pr-0">
                                <Row className="scrollableDiv" id="divContacts">
                                    {duplicatedProfiles && duplicatedProfiles.map((profile, key) =>
                                        <Col className="col-6 border-right" key={key} style={{ display: 'inline-block', height: '100%' }}>
                                            {profile.contacts && profile.contacts.map((contact, i) =>
                                                <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '38px' }} key={i}>
                                                    <Col className="col-1 d-flex align-items-center">
                                                        <CustomInput
                                                            id={'contacts' + (key + 1) + (i + 1)}
                                                            type="switch"
                                                            name="contacts"
                                                            onChange={(e) => handleSwitch(e, contact)}
                                                            checked={this.props.state['contacts' + (key + 1) + (i + 1)] ? true : false}
                                                        />
                                                    </Col>
                                                    <Col className="col-10 d-flex align-items-center pl-4">
                                                        <span><b>{contact.type}:</b> {contact.contact} {contact.main ? <Badge color="primary" className="ml-2">Main</Badge> : ''} </span>
                                                    </Col>
                                                    <CheckIcon checkIcon={contact.checkIcon} keyIcon={contact.keyIcon} />
                                                </Row>
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </Row>
                    </TabPane>

                    <TabPane tabId="4">
                        <Row>
                            <Col className="col-4 border-right">
                                {documents && documents.length > 0 && documents.map((doc, i) =>
                                    <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '45px' }} key={i}>
                                        <Col className="col-11 d-flex align-items-center pl-4">
                                            <span><b>{doc.type}:</b> {doc.number} <br />
                                                <b>Valid Until:</b> {moment(doc.validUntil).format('YYYY-MM-DD')} <b className="ml-2">Issue Date:</b> {moment(doc.issueDate).format('YYYY-MM-DD')} </span>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                            <div className="col-8 pr-0">
                                <Row className="scrollableDiv" id="divDocuments">
                                    {duplicatedProfiles && duplicatedProfiles.map((profile, key) =>
                                        <Col className="col-6 border-right" key={key} style={{ display: 'inline-block', height: '100%' }}>
                                            {profile.documents && profile.documents.map((doc, i) =>
                                                <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '45px' }} key={i}>
                                                    <Col className="col-1 d-flex align-items-center">
                                                        <CustomInput
                                                            id={'documents' + (key + 1) + (i + 1)}
                                                            type="switch"
                                                            name="documents"
                                                            onChange={(e) => handleSwitch(e, doc)}
                                                            checked={this.props.state['documents' + (key + 1) + (i + 1)] ? true : false}
                                                        />
                                                    </Col>
                                                    <Col className="col-11 d-flex align-items-center pl-4">
                                                        <span className="w-100">
                                                            <Row>
                                                                <Col sm={11}><b>{doc.type}:</b> {doc.number} </Col>
                                                                <CheckIcon checkIcon={doc.checkIcon} keyIcon={doc.keyIcon} />
                                                            </Row>
                                                            <b>Valid Until:</b> {moment(doc.validUntil).format('YYYY-MM-DD')} <b className="ml-2">Issue Date:</b> {moment(doc.issueDate).format('YYYY-MM-DD')}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </Row>
                    </TabPane>

                    {/*<TabPane tabId="5">
                        <Row>
                            <Col className="col-4 border-right">
                                {informations && informations.length > 0 && informations.map((info, i) =>
                                    <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '38px' }} key={i}>
                                        <Col className="col-11 d-flex align-items-center pl-4">
                                            <span><b>{info.area}:</b> {info.notes} </span>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                            <div className="col-8 pr-0">
                                <Row className="scrollableDiv" id="divInformations">
                                    {duplicatedProfiles && duplicatedProfiles.map((profile, key) =>
                                        <Col className="col-6 border-right" key={key} style={{ display: 'inline-block', height: '100%' }}>
                                            {profile.informations && profile.informations.map((info, i) =>
                                                <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '38px' }} key={i}>
                                                    <Col className="col-1 d-flex align-items-center">
                                                        <CustomInput
                                                            id={'informations' + (key + 1) + (i + 1)}
                                                            type="switch"
                                                            name="informations"
                                                            onChange={(e) => handleSwitch(e, info)}
                                                            checked={this.props.state['informations' + (key + 1) + (i + 1)] ? true : false}
                                                        />
                                                    </Col>
                                                    <Col className="col-11 d-flex align-items-center pl-4">
                                                        <span><b> {info.area}:</b> {info.notes} </span>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </Row>
                    </TabPane>

                    <TabPane tabId="6">
                        <Row>
                            <Col className="col-4 border-right">
                                {preferences && preferences.length > 0 && preferences.map((preference, i) =>
                                    <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '45px' }} key={i}>
                                        <Col className="col-11 d-flex align-items-center pl-4">
                                            <span>
                                                <b>Type:</b>{preference.type} <b className="ml-2">Action: </b> {preference.action}  <br />
                                                <b>Notes:</b> {preference.notes}
                                            </span>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                            <div className="col-8 pr-0">
                                <Row className="scrollableDiv" id="divPreferences">
                                    {duplicatedProfiles && duplicatedProfiles.map((profile, key) =>
                                        <Col className="col-6 border-right" key={key} style={{ display: 'inline-block', height: '100%' }}>
                                            {profile.preferences && profile.preferences.map((preference, i) =>
                                                <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '45px' }} key={i}>
                                                    <Col className="col-1 d-flex align-items-center">
                                                        <CustomInput
                                                            id={'preferences' + (key + 1) + (i + 1)}
                                                            type="switch"
                                                            name="preferences"
                                                            onChange={(e) => handleSwitch(e, preference)}
                                                            checked={this.props.state['preferences' + (key + 1) + (i + 1)] ? true : false}
                                                        />
                                                    </Col>
                                                    <Col className="col-11 d-flex align-items-center pl-4">
                                                        <span>
                                                            <b>Type:</b>{preference.type} <b className="ml-2">Action: </b> {preference.action}  <br />
                                                            <b>Notes:</b> {preference.notes}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </Row>
                    </TabPane>

                    <TabPane tabId="7">
                        <Row>
                            <Col className="col-4 border-right">
                                {salesMarketing && salesMarketing.length > 0 && salesMarketing.map((sm, i) =>
                                    <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '45px' }} key={i}>
                                        <Col className="col-11 d-flex align-items-center pl-4">
                                            <span>
                                                <b>Segment:</b>{sm.segment} <b className="ml-2">SubSegment:</b> {sm.subSegment}  <br />
                                                <b>Channel:</b> {sm.distributionChannel} <b className="ml-2">Vip code:</b> {sm.vipCode}
                                            </span>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                            <div className="col-8 pr-0">
                                <Row className="scrollableDiv" id="divSalesMarketing">
                                    {duplicatedProfiles && duplicatedProfiles.map((profile, key) =>
                                        <Col className="col-6 border-right" key={key} style={{ display: 'inline-block', height: '100%' }}>
                                            {profile.salesMarketing && profile.salesMarketing.map((sm, i) =>
                                                <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '45px' }} key={i}>
                                                    <Col className="col-1 d-flex align-items-center">
                                                        <CustomInput
                                                            id={'salesMarketing' + (key + 1) + (i + 1)}
                                                            type="switch"
                                                            name="salesMarketing"
                                                            onChange={(e) => handleSwitch(e, sm)}
                                                            checked={this.props.state['salesMarketing' + (key + 1) + (i + 1)] ? true : false}
                                                        />
                                                    </Col>
                                                    <Col className="col-11 d-flex align-items-center pl-4">
                                                        <span>
                                                            <b>Segment:</b>{sm.segment} <b className="ml-2">SubSegment:</b> {sm.subSegment}  <br />
                                                            <b>Channel:</b> {sm.distributionChannel} <b className="ml-2">Vip code:</b> {sm.vipCode}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </Row>
                    </TabPane>*/}

                    <TabPane tabId="8">
                        <Row>
                            <Col className="col-4 border-right">
                                {socialMedia && socialMedia.length > 0 && socialMedia.map((sm, i) =>
                                    <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '70px' }} key={i}>
                                        <Col className="col-11 d-flex align-items-center pl-4">
                                            <span>
                                                <b>{sm.type}</b> {sm.main ? <Badge color="primary" className="ml-2">Main</Badge> : ''}  <br />
                                                <b>url:</b> {sm.url} <br />
                                                <b>notes:</b> {sm.notes}
                                            </span>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                            <div className="col-8 pr-0">
                                <Row className="scrollableDiv" id="divSocialMedia">
                                    {duplicatedProfiles && duplicatedProfiles.map((profile, key) =>
                                        <Col className="col-6 border-right" key={key} style={{ display: 'inline-block', height: '100%' }}>
                                            {profile.socialMedia && profile.socialMedia.length > 0 && profile.socialMedia.map((sm, i) =>
                                                <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '70px' }} key={i}>
                                                    <Col className="col-1 d-flex align-items-center">
                                                        <CustomInput
                                                            id={'socialMedia' + (key + 1) + (i + 1)}
                                                            type="switch"
                                                            name="socialMedia"
                                                            onChange={(e) => handleSwitch(e, sm)}
                                                            checked={this.props.state['socialMedia' + (key + 1) + (i + 1)] ? true : false}
                                                        />
                                                    </Col>
                                                    <Col className="col-11 d-flex align-items-center pl-4">
                                                        <span className="w-100">
                                                            <Row>
                                                                <Col sm={11}><b>{sm.type}</b> {sm.main ? <Badge color="primary" className="ml-2">Main</Badge> : ''}</Col>
                                                                <CheckIcon checkIcon={sm.checkIcon} />
                                                            </Row>
                                                            <b>url:</b> {sm.url} <br />
                                                            <b>notes:</b> {sm.notes}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </Row>
                    </TabPane>

                    {/*<TabPane tabId="9">
                        <Row className="pl-1">
                            <Col className="col-4 border-right">
                                {profileWarnings && profileWarnings.length > 0 && profileWarnings.map((warn, i) =>
                                    <Row className="mb-1 overbglight border-bottom overflow-hidden" style={warn.blackListCode ? { height: '55px' } : { height: '35px' }} key={i}>
                                        <Col className="col-11 d-flex align-items-center pl-4">
                                            <span>
                                                {warn.hasWarning ? <i className="fas fa-exclamation-circle text-warning mr-2"></i> : ''} {warn.warningText}  <br />
                                                {warn.blackListCode ? <div> {warn.blackListCode} - {warn.blackListDescription} </div> : ''}
                                            </span>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                            <div className="col-8 pr-0">
                                <Row className="scrollableDiv" id="divProfileWarnings">
                                    {duplicatedProfiles && duplicatedProfiles.map((profile, key) =>
                                        <Col className="col-6 border-right" key={key} style={{ display: 'inline-block', height: '100%' }}>
                                            {profile.profileWarnings && profile.profileWarnings.length > 0 && profile.profileWarnings.map((warn, i) =>
                                                <Row className="mb-1 overbglight border-bottom overflow-hidden" style={warn.blackListCode ? { height: '55px' } : { height: '35px' }} key={i}>
                                                    <Col className="col-1 d-flex align-items-center">
                                                        <CustomInput
                                                            id={'profileWarnings' + (key + 1) + (i + 1)}
                                                            type="switch"
                                                            name="profileWarnings"
                                                            onChange={(e) => handleSwitch(e, warn)}
                                                            checked={this.props.state['profileWarnings' + (key + 1) + (i + 1)] ? true : false}
                                                        />
                                                    </Col>
                                                    <Col className="col-11 d-flex align-items-center pl-4">
                                                        <span>
                                                            {warn.hasWarning ? <i className="fas fa-exclamation-circle text-warning mr-2"></i> : ''} {warn.warningText}  <br />
                                                            {warn.blackListCode ? <div> {warn.blackListCode} - {warn.blackListDescription} </div> : ''}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </Row>
                    </TabPane>*/}

                    <TabPane tabId="10">
                        <Row className="pl-1">
                            <Col className="col-4 border-right">
                                {membershipCard && membershipCard.length > 0 && membershipCard.map((mc, i) =>
                                    <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '75px' }} key={i}>
                                        <Col className="col-12 d-flex align-items-center pl-4">
                                            <span className="w-100">
                                                <Row>
                                                    <Col><b> <FormattedMessage id="CardDetails.CardNumber" />: </b> {mc.cardNumber} </Col>
                                                    {membershipCard.length > 1 ?
                                                        <Col className="col-1 text-right">
                                                            <i className="fas fa-trash-alt text-danger pointer" onClick={() => this.props.removeData('membershipCard', i)} />
                                                        </Col>
                                                        : ''}
                                                </Row>

                                                <b><FormattedMessage id="ProfileDetails.cardType" />:</b> {mc.cardTypeCode} - {mc.cardTypeDescription} <br />
                                                <b> <FormattedMessage id="CardDetails.Validity" />: </b> {mc.validFrom && mc.validFrom.length > 10 ? mc.validFrom.substr(0, 10) : mc.validFrom} / {mc.validTo && mc.validTo.length > 10 ? mc.validTo.substr(0, 10) : mc.validTo}
                                            </span>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                            <div className="col-8 pr-0">
                                <Row className="scrollableDiv" id="divMembershipCard">
                                    {duplicatedProfiles && duplicatedProfiles.map((profile, key) =>
                                        <Col className="col-6 border-right" key={key} style={{ display: 'inline-block', height: '100%' }}>
                                            {profile.membershipCard && profile.membershipCard.length > 0 && profile.membershipCard.map((mc, i) =>
                                                <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '75px' }} key={i}>
                                                    <Col className="col-1 d-flex align-items-center">
                                                        <CustomInput
                                                            id={'membershipCard' + (key + 1) + (i + 1)}
                                                            type="switch"
                                                            name="membershipCard"
                                                            onChange={(e) => handleSwitch(e, mc)}
                                                            checked={this.props.state['membershipCard' + (key + 1) + (i + 1)] ? true : false}
                                                        />
                                                    </Col>
                                                    <Col className="col-11 d-flex align-items-center pl-4">
                                                        <span className="w-100">
                                                            <Row>
                                                                <Col sm={11}><b> <FormattedMessage id="CardDetails.CardNumber" />: </b> {mc.cardNumber}</Col>
                                                                <CheckIcon checkIcon={mc.checkIcon} />
                                                            </Row>
                                                            <b><FormattedMessage id="ProfileDetails.cardType" />:</b> {mc.cardTypeCode} - {mc.cardTypeDescription} <br />
                                                            <b> <FormattedMessage id="CardDetails.Validity" />: </b> {mc.validFrom && mc.validFrom.length > 10 ? mc.validFrom.substr(0, 10) : mc.validFrom} / {mc.validTo && mc.validTo.length > 10 ? mc.validTo.substr(0, 10) : mc.validTo}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </Row>
                    </TabPane>

                    <TabPane tabId="11">
                        <Row className="pl-1">
                            <Col className="col-4 border-right">
                                {profileCredit?.length > 0 && profileCredit.map((pc, i) =>
                                    <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '75px' }} key={i}>
                                        <Col className="col-11 d-flex align-items-center pl-4">
                                            <span>
                                                <b> <FormattedMessage id="ProfileDetails.HasCityLedger" />:  </b> <ActiveInactiveStatusBadge status={pc.hasCityLedger} />  <br />
                                                <b> <FormattedMessage id="ProfileDetails.CreditLimit" />:  </b> {pc.creditLimit}  ({pc.creditLimitType}) <br />
                                                <b> <FormattedMessage id="ProfileDetails.CreditLimitPOS" />:  </b> {pc.creditLimitPOS}  ({pc.creditLimitTypePOS}) <br />
                                            </span>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                            <div className="col-8 pr-0">
                                <Row className="scrollableDiv" id="divProfileCredit">
                                    {duplicatedProfiles && duplicatedProfiles.map((profile, key) =>
                                        <Col className="col-6 border-right" key={key} style={{ display: 'inline-block', height: '100%' }}>
                                            {profile.profileCredit && profile.profileCredit.length > 0 && profile.profileCredit.map((pc, i) =>
                                                <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '75px' }} key={i}>
                                                    <Col className="col-1 d-flex align-items-center">
                                                        <CustomInput
                                                            id={'profileCredit' + (key + 1) + (i + 1)}
                                                            type="switch"
                                                            name="profileCredit"
                                                            onChange={(e) => handleSwitch(e, pc)}
                                                            checked={this.props.state['profileCredit' + (key + 1) + (i + 1)] ? true : false}
                                                        />
                                                    </Col>
                                                    <Col className="col-11 d-flex align-items-center pl-4">
                                                        <span>
                                                            <b> <FormattedMessage id="ProfileDetails.HasCityLedger" />:  </b> <ActiveInactiveStatusBadge status={pc.hasCityLedger} />  <br />
                                                            <b> <FormattedMessage id="ProfileDetails.CreditLimit" />:  </b> {pc.creditLimit}  ({pc.creditLimitType}) <br />
                                                            <b> <FormattedMessage id="ProfileDetails.CreditLimitPOS" />:  </b> {pc.creditLimitPOS}  ({pc.creditLimitTypePOS}) <br />
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </Row>
                    </TabPane>
                    <TabPane tabId="12">
                        <Row>
                            <Col className="col-4 border-right">
                                {profileCustomData && profileCustomData.length > 0 && profileCustomData.map((customData, i) =>
                                    <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '38px' }} key={i}>
                                        <Col className="col-11 d-flex align-items-center pl-4">
                                            <span>
                                                <b>{customData.typeCode}:</b> 
                                                {
                                                    customData.fieldSettings.dataType === "Bool" ?
                                                        customData.value ?
                                                            customData.value === "1" ? <i className="fas fa-toggle-on fa-lg color-host" /> : <i className="fas fa-toggle-off fa-lg" />
                                                            :
                                                            ''
                                                        :
                                                        customData.value
                                                }
                                            </span>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                            <div className="col-8 pr-0">
                                <Row className="scrollableDiv" id="divContacts">
                                    {duplicatedProfiles && duplicatedProfiles.map((profile, key) =>
                                        <Col className="col-6 border-right" key={key} style={{ display: 'inline-block', height: '100%' }}>
                                            {profile.profileCustomData && profile.profileCustomData.map((customData, i) =>
                                                <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '38px' }} key={i}>
                                                    <Col className="col-1 d-flex align-items-center">
                                                        <CustomInput
                                                            id={'profileCustomData' + (key + 1) + (i + 1)}
                                                            type="switch"
                                                            name="profileCustomData"
                                                            onChange={(e) => handleSwitch(e, customData)}
                                                            checked={this.props.state['profileCustomData' + (key + 1) + (i + 1)] ? true : false}
                                                        />
                                                    </Col>
                                                    <Col className="col-10 d-flex align-items-center pl-4">
                                                        <span>
                                                            <b>{customData.typeCode}:</b>
                                                            {
                                                                customData.fieldSettings.dataType === "Bool" ?
                                                                    customData.value ?
                                                                        customData.value === "1" ? <i className="fas fa-toggle-on fa-lg color-host" /> : <i className="fas fa-toggle-off fa-lg" />
                                                                        :
                                                                        ''
                                                                    :
                                                                    customData.value
                                                            }
                                                        </span>
                                                    </Col>
                                                    <CheckIcon checkIcon={customData.checkIcon} keyIcon={customData.keyIcon} />
                                                </Row>
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </Row>
                    </TabPane>
                </TabContent>
            </>
        );
    }
}
export default injectIntl(ProfileDataTabs)