import React, { Component } from 'react';
import { DateRangePicker } from 'react-dates';
import { getAPI } from '../../../Base/API';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Form, Button, Card, Badge } from 'reactstrap';
import { handleNotification } from '../../../Base/Notification';
import { Pagination, ResultsPerPage, TotalResults } from '../../../Base/PaginationComponents';
import { DefaultPage, FormatAmountNumber, SelectHotelGroup, StyledCard, StyledModal } from '../../../Base/CommonUIComponents';
import { getPMSReservationStatus } from '../../../Base/ReferenceDataFunctions';
import moment from 'moment';
import SearchReservation from '../../Reservations/SearchReservation';


const getColumns = (intl) => {
    return [
        { label: intl.formatMessage({ id: "generic.Hotel" }), format: (res) => res.hotelName || res.hotelId },
        { label: intl.formatMessage({ id: "ReservationList.ReservationId" }), format: (res) => res.resIdValue },
        { label: intl.formatMessage({ id: "ReservationList.ReservationDate" }), format: (res) => res.resIdDate && moment(res.resIdDate).format("YYYY-MM-DD") },
        { label: intl.formatMessage({ id: "ReservationList.Channel" }), format: (res) => res.source },
        { label: intl.formatMessage({ id: "ReferenceDataFunctions.CheckIn" }), format: (res) => res.checkIn && moment(res.checkIn).format("YYYY-MM-DD HH:mm") },
        { label: intl.formatMessage({ id: "ReferenceDataFunctions.CheckOut" }), format: (res) => res.checkOut && moment(res.checkOut).format("YYYY-MM-DD HH:mm") },
        {
            label: intl.formatMessage({ id: "ReservationList.Nights" }),
            format: (res) => {
                if (res.checkIn && res.checkOut) {
                    const ci = moment(res.checkIn).format('YYYY-MM-DD');
                    const co = moment(res.checkOut).format('YYYY-MM-DD');

                    return moment(co).diff(moment(ci), 'days')
                }
            }
        },
        { label: intl.formatMessage({ id: "ReservationList.GuestCount" }), format: (res) => res.guestCount },
        { label: intl.formatMessage({ id: "ReservationList.AmountAfterTax" }), format: (res) => <FormatAmountNumber value={res.amountAfterTax} currency={res.currencyCode} />, class: " px-0" },
        { label: intl.formatMessage({ id: "generic.UpdatedAt" }), format: (res) => res.updatedAt && moment(res.updatedAt).format("YYYY-MM-DD HH:mm") },
        { label: "PMS Timestamp", format: (res) => res.pmsTimestamp && moment(res.pmsTimestamp).format("YYYY-MM-DD HH:mm") },
        {
            label: intl.formatMessage({ id: "generic.status" }),
            format: (res) => {
                const resStatus = getPMSReservationStatus(intl).find(el => el.value == res.statusNumber);

                if (resStatus) {
                    return <Badge className="py-1 px-2 " color={resStatus.color}> {resStatus.label} </Badge>
                }
            },
            class: "text-center"
        }
    ]
}

class MonitorReservations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageIndex: 0,
            pageSize: 10,
            totalItems: 0,
            columns: getColumns(this.props.intl),
            modal: false,
            startDate: moment().subtract(1, 'month'),
            endDate: moment().subtract(1, 'day')
        };
    }

    getReservations = (e) => {
        if (e) e.preventDefault();

        this.setState({ block: true });

        var params = `?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}`;
        if (this.state.startDate) params += `&startDate=${moment(this.state.startDate).format("YYYY-MM-DD")}`;
        if (this.state.endDate) params += `&endDate=${moment(this.state.endDate).format("YYYY-MM-DD")}`;
        if (this.state.hotelGroupId) params += `&hotelGroupId=${this.state.hotelGroupId}`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ reservations: data.response, totalItems: data.totalItems, error: errorMessage, block: false })
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Monitor/v1/GetMonitorReservations` + params);
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    doSearch = (e) => {
        e.preventDefault();
        this.setState({
            pageIndex: 0
        }, this.getReservations);
    }

    changePageSize = (evt) => {
        const { value } = evt.target;

        if (this.state.pageSize !== value) {
            this.setState({ pageSize: value, pageIndex: 0 }, this.getReservations);
        }
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, this.getReservations);
    }

    toggleModal = (hotelId, resIdValue) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedHotelId: hotelId,
            selectedRes: resIdValue
        }));
    }


    render() {
        const { block, error, selectedHotelId, selectedRes, startDate, endDate, hotelGroupId, reservations, totalItems, pageIndex, pageSize, modal } = this.state;

        return (
            <StyledCard block={block} error={error} title="NavMenu.MonitorReservations" icon="icon-icon-reservas" tabIndex="0">
                {modal ?
                    <StyledModal toggleModal={this.toggleModal} modal={modal} size="xl" style={{ minWidth: 0, maxWidth: '90%', width: '90%' }}>
                        <SearchReservation
                            hideFilters={true}
                            selectedHotelId={selectedHotelId}
                            selectedRes={selectedRes}
                        />
                    </StyledModal>
                : ''}

                <Form onSubmit={this.doSearch}>
                    <Row className="align-items-center mt-3 mb-4">
                        <Col sm={3}>
                            <SelectHotelGroup
                                name="hotelGroupId"
                                icon="icon-icon-hotel"
                                onChangeFunc={this.handleChangeSelect}
                                value={hotelGroupId || ''}
                                placeholder={this.props.intl.formatMessage({ id: "generic.HotelGroup" })}
                                required    
                            />
                        </Col>
                        <Col sm={2}>
                            <DateRangePicker
                                startDate={startDate}
                                startDateId="your_unique_start_date_id"
                                endDate={endDate}
                                endDateId="your_unique_end_date_id"
                                isOutsideRange={day => day > moment().subtract(1, 'days')}
                                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className="btn btn-host btn-sm" type="submit">
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                {reservations?.length > 0 ?
                    <>
                        <Card className=" shadow p-2 py-2 border-0 my-3 text-muted" body>
                            <Row className="px-2 align-items-center">
                                {this.state.columns.map((column) =>
                                    <Col className={column.class}> {column.label} </Col>
                                )}
                            </Row>
                        </Card>

                        {reservations.map((reservation, idx) => {
                            return <Card className=" shadow p-2 py-2 border-0 mb-2 overbglight pointer" key={idx} body onClick={() => this.toggleModal(reservation.hotelId, reservation.resIdValue) }>
                                <Row className="px-2 align-items-center">
                                    {this.state.columns.map((column) =>
                                        <Col className={column.class}> {column.format(reservation)} </Col>
                                    )}
                                </Row>
                            </Card>
                        })}

                        <Row className="my-3">
                            <Col>
                                <ResultsPerPage
                                    changeSizePerPage={this.changePageSize}
                                    pageSize={pageSize}
                                />

                                <span className="ml-2">
                                    <TotalResults
                                        end={pageIndex * pageSize + reservations.length}
                                        start={pageIndex * pageSize + 1}
                                        total={totalItems}
                                    />
                                </span>
                            </Col>

                            <Col className="text-right">
                                <Pagination
                                    isPrevDisabled={pageIndex === 0}
                                    isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                    currentPage={pageIndex + 1}
                                    handlePrevButton={() => this.handlePagination(-1)}
                                    handleNextButton={() => this.handlePagination(1)}
                                />
                            </Col>
                        </Row>
                    </>
                    :
                    <DefaultPage text="generic.SelectSearchCriteria" icon="icon-icon-search" />
                }
            </StyledCard>
        );
    }
}

export default injectIntl(MonitorReservations);