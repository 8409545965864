import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { injectIntl, FormattedMessage } from 'react-intl';
import { Breadcrumb, BreadcrumbItem, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { StyledCard } from "../../Base/CommonUIComponents";
import WebhookForLists from './WebhookForLists';
import TransactionalWebhooks from './TransactionalWebhooks';

export class EgoiWebhookList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            modal: false,
            webhookList: [],
            transactionWebhookList: [],
            lists: [],
            limit: 10,
            offset: 0,
            totalItems: 0,
            newWebhook: null,
            activeTab: '0'
        };
    }

    toggleTab = (val) => {
        this.setState({ activeTab: val })
    }

    render() {
        const { block, error } = this.state;

        return (
            <StyledCard block={block} error={error} >
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/MarketingConfigEgoi"> Config</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Webhook</BreadcrumbItem>
                    </Breadcrumb>
                </div>

                <Nav tabs className="border-0">
                    <NavItem >
                        <NavLink className={this.state.activeTab === '0' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => { this.toggleTab('0'); }} style={{ cursor: "pointer" }}>
                            <b> <FormattedMessage id="EgoiWebhookList.WebhookForLists" /></b>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={this.state.activeTab === '1' ? 'tab-border-host text-host  border-0' : 'border-0'} onClick={() => { this.toggleTab('1'); }} style={{ cursor: "pointer" }}>
                            <b> <FormattedMessage id="EgoiWebhookList.TransactionalWebhooks" /></b>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="0" className="border-0 my-0 p-0 ">
                        <WebhookForLists
                            intl={this.props.intl}
                        />
                    </TabPane>
                    <TabPane tabId="1" className="border-0 my-0 p-0 ">
                        <TransactionalWebhooks
                            intl={this.props.intl}
                        />
                    </TabPane>
                </TabContent>
            </StyledCard>
        );
    }
}
export default injectIntl(EgoiWebhookList)
