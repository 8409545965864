import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Row, Col, Button, UncontrolledTooltip, Input, Label, Form } from 'reactstrap';
import { StyledCard } from '../../Base/CommonUIComponents';
import { ReactFlowProvider } from 'react-flow-renderer';
import { getAPI, postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';

import moment from 'moment-timezone';
import JourneyBuilderSideBar from './JourneyBuilderSideBar';
import JourneyBuilderFlow from './JourneyBuilderFlow';
import ConfigElementMenu from './ConfigElementMenu';

class JourneyBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            journeyId: null,
            journeyRunId: null,
            journeyName: null,
            openConfiguration: false,
            selectedNodeId: null,
            editJourney: false,
            journeyDescription: null,
            journeyRunDetails: null,
            active: false,
            nodes: [],
            edges: [],
            loyaltyCardsOptions: [],
            editedFlow: false,
        }
    };
    
    componentDidMount() {
        if(this.props.match && this.props.match.params && (this.props.match.params.id || this.props.match.params.id === 0)){
            if(this.props.match.params.journeyRunId || this.props.match.params.journeyRunId === 0){
                this.setState({ journeyId: this.props.match.params.id, journeyRunId: this.props.match.params.journeyRunId }, () => this.getJourneyRunDetails());
            }else{
                this.setState({ journeyId: this.props.match.params.id }, () => this.getJourneyDetails());
            }
        }else{
            this.setState({ editJourney: true, block: true }, () => this.getLoyaltyCardTypes());
        }
    };

    getJourneyDetails = () => {
        const { journeyId } = this.state;
        if(journeyId === null || journeyId === undefined) return;

        this.setState({ block: true }, () =>
            getAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if(data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    if (data.response && data.response.length > 0) {
                        const journey = data.response[0];

                        const nodesTransf = this.buildNodes(journey.journeyNodes);
                        const edgesTransf = this.buildEdges(journey.journeyConnections);

                        this.setState({
                            journeyName: journey.name, 
                            journeyDescription: journey.description, 
                            active: journey.active,
                            nodes: nodesTransf ?? [], 
                            edges: edgesTransf ?? []
                        }, () => this.getLoyaltyCardTypes());
                        return;
                    }
                }
                this.setState({ block: false });
            }, `/api/gms/Journey/v1/Journey/${journeyId}`)
        )
    };

    getJourneyRunDetails = () => {
        const { journeyId, journeyRunId } = this.state;
        if(journeyId === null || journeyId === undefined || journeyRunId === null || journeyRunId === undefined) return;

        this.setState({ block: true }, () =>
            getAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if(data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    if (data.response && data.response.length > 0) {
                        const journeyRun = data.response[0];
                        let journeyRunDetails = null;
                        if(journeyRun && journeyRun.journeyRunDetails){
                            journeyRunDetails = journeyRun.journeyRunDetails;
                        }
                        this.setState({ block: false, journeyRunDetails }, () => this.getJourneyDetails());
                        return;
                    }
                }
                this.setState({ block: false });
            }, `/api/gms/Journey/v1/journeyRun/${journeyRunId}`)
        )
    };

    saveJourney = (e) => {
        if(e){
            e.preventDefault();
        }

        const { journeyId, journeyName, journeyDescription, active, nodes, edges, editedFlow } = this.state;
        if((!journeyName)) return;

        const journeyNodes = [];
        const journeyConnections = [];

        if(nodes && nodes.length > 0){
            nodes.forEach(node => {
                if(node && node.typeUnchanged){
                    const [nodeType, nodeSubType, conditionType] = node.typeUnchanged.split('-');
                    if(conditionType){
                        if(!node.metaData) node.metaData = {};
                        node.metaData.numberOfConditions = node.data.numberOfConditions;
                    }

                    const nodeData = {
                        name: `${node.id}`,
                        positionX: node.position?.x ?? 0,
                        positionY: node.position?.y ?? 0,
                        nodeType: nodeType === 'input' ? "Event" : conditionType ? "Flow" : "Activity",
                        nodeSubType: conditionType ?? nodeSubType,
                        metaData: node.metaData ? JSON.stringify(node.metaData) : null
                    }

                    journeyNodes.push(nodeData);
                }
            });
        }
        if(edges && edges.length > 0){
            edges.forEach(edge => {
                if(edge){
                    const edgeData = {
                        name: `${edge.id}`,
                        sourceNode: journeyNodes.find(node => node.name === edge.source),
                        sourceHandle: edge.sourceHandle,
                        targetNode: journeyNodes.find(node => node.name === edge.target),
                        targetHandle: edge.targetHandle,
                    }

                    journeyConnections.push(edgeData);
                }
            });
        }
        const body = {
            id: journeyId !== null && journeyId !== undefined ? parseInt(journeyId) : 0,
            name: journeyName,
            description: journeyDescription,
            active: active,
            journeyConnections: editedFlow ? journeyConnections : null,
            journeyNodes: editedFlow ? journeyNodes : null
        };
        
        this.setState({ block: true }, () =>
            postAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if(data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    if (data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="CustomerJourney.JourneySaved" />);
                        window.history.replaceState(null, '', '/JourneyBuilder/' + data.response[0].id);
                        this.setState({ 
                            block: false, 
                            journeyId: data.response[0].id,
                            editedFlow: false
                        });
                        return;
                    }
                }
                this.setState({ block: false });
            }, `/api/gms/Journey/v1/Journey/upsert`, body)
        )
    };

    buildNodes = (journeyNodes) => {
        const { journeyRunId, journeyRunDetails } = this.state;
        
        return journeyNodes.map(node => {
            const nodeCompleted = journeyRunDetails && journeyRunDetails.length > 0 && journeyRunDetails.some(j => j.journeyNode && j.journeyNode.id === node.id && j.completedAt);
            
            const nodeTypeParsed = (
                node && node.nodeType === 'Event' ?
                    'input'
                :
                    node && node.nodeType === 'Flow' ?
                        'default-condition'
                    :
                        'default'
            )
            const nodeTypeUnchangedParsed =(
                node && node.nodeType === 'Event' ? 
                    `input-${node.nodeSubType === 'reservation' ? 'reserve' : node.nodeSubType}`
                : 
                    node && node.nodeType === 'Flow' ? 
                        'default-condition-' + node.nodeSubType 
                    : 
                        'default-' + node.nodeSubType 
            );

            const parsedMetaData = node.metaData ? JSON.parse(node.metaData) : null;
            const nodeConditionType = nodeTypeUnchangedParsed && nodeTypeUnchangedParsed.split('-') && nodeTypeUnchangedParsed.split('-').length === 3 ? nodeTypeUnchangedParsed.split('-')[2] : null;
            const numberOfConditions = parsedMetaData && parsedMetaData.numberOfConditions ? parsedMetaData.numberOfConditions : nodeConditionType === 'waitDuration' ? 1 : 2;
            return {
                id: `${node.id}`,
                position: { x: node.positionX, y: node.positionY },
                style: this.getNodeStyle(nodeTypeUnchangedParsed, node.id),
                type: nodeTypeParsed,
                typeUnchanged: nodeTypeUnchangedParsed,
                data: {
                    label: this.getNodeIcon(nodeTypeUnchangedParsed),
                    isEditable: journeyRunId === null || journeyRunId === undefined || !journeyRunDetails || (journeyRunDetails && journeyRunDetails.length === 0),
                    completedAt: nodeCompleted && journeyRunDetails ? moment(journeyRunDetails.findLast(j => j.journeyNode && j.journeyNode.id === node.id && j.completedAt)?.completedAt)?.format("DD/MM/YYYY HH:mm") : null,
                    numberOfConditions,
                },
                sourcePosition: "right",
                targetPosition: "left",
                width: 50,
                height: 50,
                metaData: parsedMetaData
            }
        });
    };

    buildEdges = (journeyConnections) => {
        const { journeyRunId } = this.state;
        return journeyConnections.map(edge => {
            return {
                id: `${edge.id}`,
                source: `${edge.sourceNode.id}`,
                sourceHandle: edge.sourceHandle,
                target: `${edge.targetNode.id}`,
                targetHandle: edge.targetHandle,
                animated: true,
                type: journeyRunId ? "" : "buttonEdge",
                style: { strokeWidth: 2 },
                markerEnd: { type: "arrow" },
                data: {
                    onDelete: (id) => this.changeEdges(edge, 'delete', id),
                    initialEdge: true
                },
            }
        });
    };

    editJourney = () => {
        this.setState(prevState => ({ editJourney: !prevState.editJourney }));
    };

    activateJourney = () => {
        this.setState(prevState => ({ active: !prevState.active }), () => this.saveJourney());
    };
    
    addWarning = (messageId, codeId) => {
        let warnings = [{ message: <FormattedMessage id={messageId} />, code: <FormattedMessage id={codeId} /> }];
        handleNotification({ warnings });
    };

    validateInputNodes = (inputNodes) => {
        if (!inputNodes || inputNodes.length === 0) {
            this.addWarning("CustomerJourney.NoInputNodes", "CustomerJourney.ErrorActivatingJourney");
            return true;
        }
    
        if (inputNodes.length > 1) {
            this.addWarning("CustomerJourney.MultipleInputNodes", "CustomerJourney.ErrorActivatingJourney");
            return true;
        }
    
        const inputNode = inputNodes[0];
    
        if (!inputNode) {
            this.addWarning("CustomerJourney.ErrorActivatingJourney", "CustomerJourney.ErrorActivatingJourney");
            return true;
        }
    
        if (!inputNode.typeUnchanged || inputNode.typeUnchanged === "input-event") {
            this.addWarning("CustomerJourney.MissingEventType", "CustomerJourney.ErrorActivatingJourney");
            return true;
        }
    
        if (!inputNode.metaData || !inputNode.metaData.type) {
            this.addWarning("CustomerJourney.MissingEventSubType", "CustomerJourney.ErrorActivatingJourney");
            return true;
        }
    
        if (inputNode.metaData.type === 'send' && (inputNode.metaData.campaignTargetsId == null)) {
            this.addWarning("CustomerJourney.MissingCampaignTarget", "CustomerJourney.ErrorActivatingJourney");
            return true;
        }
    
        return false;
    };

    validateDefaultNode = (node) => {
        if (!node.metaData) {
            this.addWarning("CustomerJourney.MissingActivityConfiguration", "CustomerJourney.ErrorActivatingJourney");
            return true;
        }
    
        if (!node.metaData.sender) {
            this.addWarning("CustomerJourney.MissingSender", "CustomerJourney.ErrorActivatingJourney");
            return true;
        }
    
        const templates = node.metaData.templates || [];
        if (templates.length === 0 || templates.some(t => !t.templateId)) {
            this.addWarning("CustomerJourney.MissingActivityTemplates", "CustomerJourney.ErrorActivatingJourney");
            return true;
        }
    
        if (node.metaData.marketingType === 'CustomInquiry') {
            if (!node.metaData.surveyProvider || !node.metaData.surveyId) {
                this.addWarning("CustomerJourney.MissingSurvey", "CustomerJourney.ErrorActivatingJourney");
                return true;
            }
    
            if (node.metaData.surveyProvider === 'ReviewPro' && !node.metaData.propertyId) {
                this.addWarning("CustomerJourney.MissingReviewProPMSId", "CustomerJourney.ErrorActivatingJourney");
                return true;
            }
        }
    
        return false;
    };
    
    validateDefaultConditionNode = (node) => {
        const conditionType = node.typeUnchanged?.split('-')[2];
    
        if (!node.metaData || !conditionType) {
            this.addWarning("CustomerJourney.MissingConditionConfiguration", "CustomerJourney.ErrorActivatingJourney");
            return true;
        }
    
        if (!node.data || !node.data.numberOfConditions || node.data.numberOfConditions === 0) {
            this.addWarning("CustomerJourney.MissingConditionNumber", "CustomerJourney.ErrorActivatingJourney");
            return true;
        }
    
        const metaData = node.metaData;
    
        switch (conditionType) {
            case 'waitDuration':
                if (!metaData['2']?.condition || !metaData['2']?.type) {
                    this.addWarning("CustomerJourney.MissingWaitDurationConfiguration", "CustomerJourney.ErrorActivatingJourney");
                    return true;
                }
                break;
    
            case 'waitUntil':
                if (!metaData['1']?.condition) {
                    this.addWarning("CustomerJourney.MissingWaitUntilEventConfiguration", "CustomerJourney.ErrorActivatingJourney");
                    return true;
                }
                if (!metaData['3']?.condition || !metaData['3']?.type) {
                    this.addWarning("CustomerJourney.MissingWaitUntilTimeConfiguration", "CustomerJourney.ErrorActivatingJourney");
                    return true;
                }
                break;
    
            case 'randomSplit':
                if (!metaData['1']?.condition || !metaData['3']?.condition) {
                    this.addWarning("CustomerJourney.MissingRandomSplitConfiguration", "CustomerJourney.ErrorActivatingJourney");
                    return true;
                }
    
                const sumRandomSplit = (node.data.numberOfConditions === 2)
                    ? metaData['1'].condition + metaData['3'].condition
                    : (metaData['1']?.condition || 0) + (metaData['2']?.condition || 0) + (metaData['3']?.condition || 0);
    
                if (sumRandomSplit !== 100) {
                    this.addWarning("CustomerJourney.RandomSplitMustBe100", "CustomerJourney.ErrorActivatingJourney");
                    return true;
                }
                break;
    
            case 'decisionSplit':
                if (!metaData['1']?.entity || !metaData['1']?.field || !metaData['1']?.condition || !metaData['1']?.value) {
                    this.addWarning("CustomerJourney.MissingDecisionSplitConfiguration", "CustomerJourney.ErrorActivatingJourney");
                    return true;
                }
                if (node.data.numberOfConditions === 3) {
                    if (!metaData['2']?.entity || !metaData['2']?.field || !metaData['2']?.condition || !metaData['2']?.value) {
                        this.addWarning("CustomerJourney.MissingDecisionSplitConfiguration", "CustomerJourney.ErrorActivatingJourney");
                        return true;
                    }
                }
                break;
    
            default:
                // Optionally handle unexpected condition types
                break;
        }
    
        return false;
    };

    validateJourneyActivation = () => {
        const { nodes, edges, active } = this.state;

        if(!active){
            if (!nodes?.length) {
                this.addWarning("CustomerJourney.NoNodes", "CustomerJourney.ErrorActivatingJourney");
                return;
            }

            if (!edges?.length) {
                this.addWarning("CustomerJourney.MissingConnections", "CustomerJourney.ErrorActivatingJourney");
                return;
            }

            const inputNodes = nodes.filter(node => node.type === 'input');
            const defaultNodes = nodes.filter(node => node.type === 'default');

            if (this.validateInputNodes(inputNodes)) return;

            if (!defaultNodes || defaultNodes.length === 0) {
                this.addWarning("CustomerJourney.NoActivityNodes", "CustomerJourney.ErrorActivatingJourney");
                return;
            }

            const nodesCopy = nodes.map(node => ({ ...node }));

            for (let edge of edges) {
                const sourceNode = nodesCopy.find(node => node.id === edge.source);
                const targetNode = nodesCopy.find(node => node.id === edge.target);

                if (!sourceNode || !targetNode) {
                    this.addWarning("CustomerJourney.NoNodes", "CustomerJourney.ErrorActivatingJourney");
                    return;
                }

                if (sourceNode.type !== 'default-condition') {
                    sourceNode.targetEdge = edge;
                } else {
                    sourceNode[`${edge.sourceHandle}Edge`] = edge;
                }
                targetNode.sourceEdge = edge;

                if (sourceNode.type === 'default-condition' && targetNode.type === 'default-condition') {
                    this.addWarning("CustomerJourney.ConditionsTogether", "CustomerJourney.ErrorActivatingJourney");
                    return;
                }
            }

            for (let node of nodesCopy) {
                if(!node){
                    this.addWarning("CustomerJourney.ErrorActivatingJourney", "CustomerJourney.ErrorActivatingJourney");
                    return;
                }

                if (node.type === 'default') {
                    if (this.validateDefaultNode(node)){
                        return
                    }
                }
                
                if (node.type === 'default-condition') {
                    if (this.validateDefaultConditionNode(node)){
                        return
                    }

                    if(node.data){
                        const { numberOfConditions } = node.data;
                        const hasTopEdge = Boolean(node.handleTopEdge);
                        const hasMiddleEdge = Boolean(node.handleMiddleEdge);
                        const hasBottomEdge = Boolean(node.handleBottomEdge);
                    
                        if ((numberOfConditions === 1 && !hasMiddleEdge) ||
                            (numberOfConditions === 2 && (!hasTopEdge || !hasBottomEdge)) ||
                            (numberOfConditions === 3 && (!hasTopEdge || !hasMiddleEdge || !hasBottomEdge))) {
                            this.addWarning("CustomerJourney.MissingConditionsConnections", "CustomerJourney.ErrorActivatingJourney");
                            return;
                        }
                    }
                }

                if ((node.type !== 'input' && !node.sourceEdge) || (node.type === 'input' && !node.targetEdge)) {
                    this.addWarning("CustomerJourney.MissingConnections", "CustomerJourney.ErrorActivatingJourney");
                    return;
                }
            }
        }
        
        this.activateJourney();
    };

    deleteJourney = () => {
        return;
    };

    toggleConfigureNode = (nodes, selectedNodeId) => {
        this.setState((prevState) => ({
            nodes,
            selectedNodeId: selectedNodeId === prevState.selectedNodeId ? null : selectedNodeId,
            openConfiguration: selectedNodeId ? selectedNodeId !== prevState.selectedNodeId : !prevState.openConfiguration
        }));
    };

    getNodeStyle = (type, nodeId) => {
        const { journeyRunId, journeyRunDetails } = this.state;

        let isNodeCompleted = false;

        if(journeyRunDetails && journeyRunDetails.length > 0){
            isNodeCompleted = journeyRunDetails.some(j => j.journeyNode && j.journeyNode.id === nodeId && j.completedAt);
        }

        const baseStyle = {
            boxShadow: 'unset',
            width: '50px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: 'unset'
        };
    
        if (!type) {
            return { ...baseStyle, backgroundColor: 'transparent', border: '1px solid grey' };
        }
    
        const [nodeType, nodeSubType] = type.split('-');
    
        let specificStyle = {};
        if (nodeType === 'input') {
            specificStyle = { backgroundColor: 'lightgreen', borderRadius: '100%' };
        } else if (nodeType === 'default' && nodeSubType === 'condition') {
            specificStyle = { backgroundColor: 'orange', borderRadius: '8%' };
        } else {
            specificStyle = { backgroundColor: 'lightseagreen', borderRadius: '20%' };
        }
        
        if(journeyRunId !== null && journeyRunId !== undefined && nodeId !== null && nodeId !== undefined && !isNodeCompleted){
            specificStyle = { ...specificStyle, opacity: 0.4, backgroundColor: 'grey' };
        }
    
        return { ...baseStyle, ...specificStyle };
    };

    getNodeIcon = (type) => {
        if (!type) return '';
        
        const [nodeType, nodeSubType, conditionType] = type.split('-');
    
        const iconMap = {
            /* 'input-reservation': 'fas fa-concierge-bell',
            'input-campaign': 'fas fa-bullseye', */
            'input-event': 'fas fa-sitemap',
            'input-reserve': 'fas fa-sitemap',
            'input-campaign': 'fas fa-sitemap',
            'input-loyalty': 'fas fa-sitemap',
            'default-sms': 'fas fa-comments',
            'default-email': 'fas fa-envelope-open-text',
            /* 'default-whatsApp': 'fab fa-whatsapp', */
            'default-condition-waitUntil': 'far fa-clock',
            'default-condition-waitDuration': 'fas fa-stopwatch',
            'default-condition-decisionSplit': 'fas fa-divide',
            'default-condition-randomSplit': 'fas fa-random'
        };
    
        const iconClass = iconMap[`${nodeType}-${nodeSubType}${conditionType ? `-${conditionType}` : ''}`];
    
        return iconClass ? <i className={iconClass} style={{ fontSize: "15px", color: 'white' }}></i> : '';
    };

    changeNodes = (node, type) => {
        if(type === 'delete'){
            this.setState((prevState) => ({
                nodes: prevState.nodes.filter((n) => n.id !== node.id),
                editedFlow: true
            }));
        }else{
            this.setState((prevState) => ({
                nodes: [...prevState.nodes, node],
                editedFlow: true
            }));
        }
    };
    
    changeEdges = (edge, type, id) => {
        this.setState((prevState) => {
            if (type === 'delete') {
                return {
                    edges: prevState.edges.filter((e) => e.id !== id),
                    editedFlow: true
                };
            } else {
                const edgeExists = prevState.edges.some((e) => e.id === edge.id);
                if (edgeExists) {
                    return{ 
                        prevState,
                        editedFlow: true
                    }
                }
                return {
                    edges: [...prevState.edges, edge],
                    editedFlow: true
                };
            }
        });
    };

    getLoyaltyCardTypes = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ error: errorMessage, loyaltyCardTypes: data.response?.map((el) => ({ label: el.description, code: el.code, value: el.code })) }, () => this.getLoyaltyLevels());
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/LoyaltyCard/v1/LoyaltyCardTypeConfig`);
    }
    
    getLoyaltyLevels = () => {
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                const { loyaltyCardTypes } = this.state;
              
                const loyaltyCardsOptions = loyaltyCardTypes
                    ? loyaltyCardTypes.map(({ code }) => ({ label: code, type: code, options: [] }))
                    : [];

                loyaltyCardsOptions.push({ label: <FormattedMessage id="LoyaltyCardLevel.Default" />, type: null, options: [] });
              
                if (data.response && data.response.length > 0) {
                    data.response
                        .sort((a, b) => a.minRoomNightsToLevel + a.minPointsToLevel - b.minRoomNightsToLevel - b.minPointsToLevel)
                        .forEach(opt => {
                            const loyaltyCardsOption = {
                                value: opt.id,
                                label: opt.description,
                                points: opt.minPointsToLevel,
                                loyaltyCardTypeConfigCode: opt.loyaltyCardTypeConfigCode,
                            };
                            const targetOption = loyaltyCardsOptions.find(el => el.type === opt.loyaltyCardTypeConfigCode);
                            if (targetOption) {
                                targetOption.options.push(loyaltyCardsOption);
                            }
                        });
                }
              
                const defaultOption = loyaltyCardsOptions.find(el => el.label === null);
                if (defaultOption) {
                  defaultOption.label = <FormattedMessage id="LoyaltyCardLevel.Default" />;
                }
              
                this.setState({ loyaltyCardsOptions, block: false });
              }
              
            this.setState({ block: false });
        }, `/api/gms/LoyaltyCard/v1/LoyaltyCardLevelConfig`);
    }

    updateNodesList = (nodes) => {
        this.setState({ nodes, editedFlow: true });
    }

    render() {
        const { journeyId, journeyRunId, journeyName, openConfiguration, editJourney, journeyDescription, nodes, edges, active, block, error } = this.state;
        const { match, history } = this.props;

        return (
            <StyledCard block={block} error={error} >
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to="/CustomerJourney">
                            <FormattedMessage id="NavMenu.CustomerJourney" />
                        </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                        <FormattedMessage id="CustomerJourney.JourneyBuilder" />
                    </BreadcrumbItem>
                </Breadcrumb>
                <ReactFlowProvider>
                    {!journeyRunId ?
                        <Row className='d-flex'>
                            <Col className='col-3 h-100'>
                                <Form onSubmit={(e) => this.saveJourney(e)}>
                                    <Row className='d-flex align-items-end justify-content-between pb-2'>
                                        <Col className={`${editJourney ? 'col-12' : 'col-6'} d-flex flex-column justify-content-center`}>
                                            {editJourney ?
                                                <>
                                                    <div>
                                                        <Label><FormattedMessage id="CustomerJourney.JourneyName" /></Label>
                                                        <Input required type="text" className="form-control" value={journeyName} onChange={(e) => this.setState({ journeyName: e.target.value })} />
                                                    </div>

                                                    <div className='mt-2'>
                                                        <Label> <FormattedMessage id="CustomerJourney.JourneyDecription" /></Label>
                                                        <Input type="textarea" className="form-control " value={journeyDescription} onChange={(e) => this.setState({ journeyDescription: e.target.value })} />
                                                    </div>
                                                </>
                                            :

                                                <>
                                                    <h5 className='m-0 text-truncate'>{journeyName}</h5>
                                                    <h6 className='text-muted text-truncate mb-0 mt-1'>{journeyDescription ?? ''}</h6>
                                                </>
                                            }
                                        </Col>
                                        <Col className={`${editJourney ? 'col-12 pt-3' : 'col-6'} d-flex align-items-center justify-content-end`}>
                                            <div className='ml-2'>
                                                <Button id='deleteJourney' className="btn btn-sm btn-host mb-1" onClick={() => this.deleteJourney()}>
                                                    <i className="fas fa-trash"></i>
                                                </Button>
                                                <UncontrolledTooltip placement="top" target="deleteJourney">
                                                    <FormattedMessage id="CustomerJourney.RemoveJourney" />
                                                </UncontrolledTooltip>
                                            </div>
                                            <div className='ml-2'>
                                                <Button id='editJourney' className="btn btn-sm btn-host mb-1" disabled={!journeyName} onClick={() => this.editJourney()}>
                                                    <i className="fas fa-edit"></i>
                                                </Button>
                                                <UncontrolledTooltip placement="top" target="editJourney">
                                                    <FormattedMessage id="CustomerJourney.EditJourney" />
                                                </UncontrolledTooltip>
                                            </div>
                                            <div className='ml-2'>
                                                <Button
                                                    id="activateJourney"
                                                    className={`btn btn-sm mb-1 d-flex align-items-center ${!active ? 'btn-host' : ''}`}
                                                    style={{ backgroundColor: !active ? '' : '#cb2424', borderColor: !active ? '' : '#cb2424' }}
                                                    onClick={() => this.validateJourneyActivation()}
                                                >
                                                    <i className={`fas ${!active ? 'fa-play' : 'fa-stop'} mr-2`} />
                                                    <FormattedMessage id={ !active ? "CustomerJourney.ActivateJourney" : "CustomerJourney.DeactivateJourney" } />
                                                </Button>
                                            </div>
                                            <div className='ml-2'>
                                                <Button id='saveJourney' type='submit' className="btn btn-sm btn-host mb-1" disabled={(!edges) || (edges && edges.length === 0)}>
                                                    <i className="fas fa-save"/>
                                                </Button>
                                                <UncontrolledTooltip placement="top" target="saveJourney">
                                                    <FormattedMessage id="CustomerJourney.SaveJourney" />
                                                </UncontrolledTooltip>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row style={{ height: '70vh' }}>
                                    <Col className='w-100'>
                                        {openConfiguration ?
                                            <ConfigElementMenu
                                                toggleConfigureNode={this.toggleConfigureNode}
                                                nodes={this.state.nodes}
                                                edges={this.state.edges}
                                                selectedNodeId={this.state.selectedNodeId}
                                                getNodeStyle={this.getNodeStyle}
                                                getNodeIcon={this.getNodeIcon}
                                                changeNodes={this.changeNodes}
                                                changeEdges={this.changeEdges}
                                                loyaltyCardsOptions={this.state.loyaltyCardsOptions}
                                                updateNodesList={this.updateNodesList}
                                            />
                                        :
                                            <JourneyBuilderSideBar
                                                match={match}
                                                history={history}
                                            />
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='h-100'>
                                {(journeyId !== null && journeyId !== undefined && nodes && nodes.length > 0) || journeyId === null || journeyId === undefined ?
                                    <JourneyBuilderFlow
                                        toggleConfigureNode={this.toggleConfigureNode}
                                        getNodeStyle={this.getNodeStyle}
                                        getNodeIcon={this.getNodeIcon}
                                        changeNodes={this.changeNodes}
                                        changeEdges={this.changeEdges}
                                        updateNodesList={this.updateNodesList}
                                        nodes={nodes}
                                        edges={edges}
                                    />
                                :''}
                            </Col>
                        </Row>
                    :
                        <>
                            <Row>
                                <Col className='col-6 d-flex flex-column justify-content-center'>
                                    <h5 className='m-0'>{journeyName}</h5>
                                    <h6 className='text-muted mb-0 mt-1'>{journeyDescription ?? ''}</h6>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='h-100'>
                                    {(journeyId !== null && journeyId !== undefined && nodes && nodes.length > 0) || journeyId === null || journeyId === undefined ?
                                        <JourneyBuilderFlow
                                            toggleConfigureNode={this.toggleConfigureNode}
                                            getNodeStyle={this.getNodeStyle}
                                            getNodeIcon={this.getNodeIcon}
                                            changeNodes={this.changeNodes}
                                            changeEdges={this.changeEdges}
                                            updateNodesList={this.updateNodesList}
                                            journeyRunId={journeyRunId}
                                            nodes={nodes}
                                            edges={edges}
                                        />
                                    :''}
                                </Col>
                            </Row>
                        </>
                    }
                </ReactFlowProvider>
            </StyledCard>
        );
    }
};

export default injectIntl(JourneyBuilder);

