import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Row, Label, Form, Input, FormGroup, Modal, ModalBody, Card } from 'reactstrap';
import { handleNotification } from '../../Base/Notification';
import { BlankCard } from "../../Base/CommonUIComponents";
import CustomSelect from "../../Base/CustomSelect";
import { postAPI } from "../../Base/API";

class NewTask extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            event: [{ value: 'AfterPriorTask', label: 'After Prior Task' }, { value: 'AfterTaskOpen', label: 'After Task Open' }, { value: 'StageStart', label: 'Stage Start' }],
            type: [{ value: 'Day', label: 'Day' }, { value: 'Hour', label: 'Hour' }, { value: 'Month', label: 'Month' }, { value: 'BusinessHour', label: 'Business Hour' }, { value: 'BusinessDay', label: 'Business Day' }],
            newTask: {}
        };
    }

    saveTask = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const body = { ...this.state.newTask };
        console.log(body)
        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="PipelineStage.NewTaskCreated" />, <FormattedMessage id="generic.success" />);
                }
            }

            this.setState({ block: false });
        }, `/api/gms/Pipeline/v1/stage/${this.props.selectedStageId}/task`, JSON.stringify({ request: body }));
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            newTask: {
                ...prevState.newTask,
                [name]: value
            }
        }));
    }

    handleSelect = (combo, name) => {
        this.setState(prevState => ({
            newTask: {
                ...prevState.newTask,
                [name]: combo ? combo.value : null
            }
        }));
    }

    render() {
        const { block, error, pipeline, stageModal, event, type, newTask } = this.state;
        const { taskModal, toggle } = this.props;

        return (
            <Modal isOpen={taskModal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={toggle} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={this.state.block} error={this.state.error}>
                        <Form onSubmit={this.saveTask}>
                            <Row className="mb-3">
                                <Col>
                                    <h5>New Task</h5>
                                </Col>
                                <Col className="text-right col-3">
                                    <Button className="btn-sm btn-host" type="submit" disabled>
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>

                            <FormGroup row>
                                <Label sm={2}> Event </Label>
                                <Col sm={2} >
                                    <Input
                                        type="number"
                                        name="dueValue"
                                        required
                                        onChange={this.handleChange}
                                        min={0}
                                        //maxLength={100}
                                    />
                                </Col>
                                <Col sm={3} >
                                    <CustomSelect
                                        isSearchable isClearable
                                        placeholder={""}
                                        options={type}
                                        required
                                        onChange={(e) => this.handleSelect(e, 'dueType')}
                                        //value={this.getReviewField(question.value)}
                                    />
                                </Col>
                                <Col sm={5} style={{ display: 'contents' }}>
                                    {event && event.map((evt, key) =>
                                        <Card body key={key} onClick={(e) => this.handleChange({ target: { name: 'dueEvent', value: evt.value }})} className={"mr-3 px-2 py-2 border-0 shadow pointer " + (newTask && newTask.dueEvent === evt.value ? ' bg-host-light' : '')}>
                                            {evt.label}
                                        </Card>
                                    )}
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label sm={2}> Description </Label>
                                <Col sm={10} >
                                    <Input
                                        type="textarea"
                                        name="description"
                                        onChange={this.handleChange}
                                        maxLength={1000}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(NewTask)