import React, { Component } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage } from "react-intl";

export class AdminIcon extends Component {

    render() {
        return (
            <span>
                <i className="fas fa-exclamation-circle p-1" style={{ color: 'red' }} id="Operations" />
                <UncontrolledTooltip placement="right" target="Operations" >
                    <FormattedMessage id="AdminIcon.Msg" />
                </UncontrolledTooltip>
            </span>
        );
    }
}