import React, { Component } from 'react';
import { Button, Card, Col, Input, InputGroup, InputGroupAddon, Row, Form } from 'reactstrap';
import { StyledCard } from '../../Base/CommonUIComponents';
import { injectIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import CustomSelect from '../../Base/CustomSelect';
import { getAPI } from '../../Base/API';
import Timeline from './Timeline';
import { LogMessages } from './LogMessages';
import { ErrorDetailsTable } from './ErrorDetailsTable';

import './Timeline.css';
import './TimelineElement.css';

class ApiLogs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            eventTypes: [
                {
                    value: 'Profile',
                    label: <FormattedMessage id="ApiLogs.Profile" />
                },
                {
                    value: 'IssueCardNumber',
                    label: <FormattedMessage id="ApiLogs.IssueCardNumber" />
                }
            ],
            eventType: null,
            states: [
                {
                    value: 'Ok',
                    label: <FormattedMessage id="ApiLogs.Success" />
                },
                {
                    value: 'Error',
                    label: <FormattedMessage id="ApiLogs.Error" />
                },
                {
                    value: 'Warning',
                    label: <FormattedMessage id="ApiLogs.Warning" />
                }
            ],
            status: null,
            pageIndex: 1,
            //startDate: moment().subtract(2, 'days'),
            startDate: moment().subtract(1, 'month'),
            endDate: moment(),
            events: [],
            selectedEvent: null,
            pageSize: 10,
            totalPages: 0,
            profileId: null
        };
    }

    componentDidMount() {
        this.doSearch();
    }

    doSearch = () => {
        const { endDate, eventType, pageIndex, pageSize, profileId, status, startDate } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.response) {
                    this.setState({ events: data.response, totalPages: Math.ceil(data.totalItems / pageSize), error: errorMessage, block: false });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/LogExternalApi/v1/logs?pageSize=${pageSize}&pageIndex=${pageIndex - 1}&fromDate=${moment(startDate).format("YYYY-MM-DD")}&toDate=${moment(endDate).format("YYYY-MM-DD")}${status ? `&status=${status}` : ``}${eventType ? `&eventType=${eventType}` : ``}${profileId ? `&profileId=${profileId}` : ``}`);
    }

    setComboStatus = (name, combo) => {
        this.setState({ [name]: combo && combo.value });
    }

    handleEvent = (combo) => {
        this.setState({
            eventType: combo ? combo.value : null,
            profileId: null
        });
    }

    selectEvent = (event) => {
        this.setState({ selectedEvent: event });
    }

    changePreviousPage = () => {
        const { pageIndex } = this.state;
        this.changePage(pageIndex - 1);
    }

    changeNextPage = () => {
        const { pageIndex } = this.state;
        this.changePage(pageIndex + 1);
    }

    changePage = (val) => {
        this.setState({ block: true, pageIndex: val, selectedEvent: null, selectedEventId: null }, this.doSearch);
    }

    handlePageIndex = (evt) => {
        if (evt && evt.target) {
            const { name, value } = evt.target;
            this.setState({ [name]: parseInt(value) });
        }
    }

    prepareDoSearch = () => {
        const { pageIndex } = this.state;
        if (!pageIndex || pageIndex < 1) {
            this.setState({ block: true, pageIndex: 1, selectedEvent: null, selectedEventId: null }, this.doSearch);
        }
        else {
            this.setState({ block: true, selectedEvent: null, selectedEventId: null }, this.doSearch);
        }
    }

    render() {
        const { block, endDate, error, events, eventType, eventTypes, pageIndex, pageSize, profileId, selectedEvent, startDate, states, totalPages } = this.state;

        return (
            <StyledCard block={block} error={error}>
                <Row className="mb-3">
                    <Col>
                        <h5>
                            <i className="fas fa-history mr-2" />
                            <FormattedMessage id="NavMenu.ApiLogs" />
                        </h5>
                    </Col>
                </Row>
                <Form onSubmit={event => {
                    event.preventDefault();
                    this.setState({ block: true, pageIndex: 1, selectedEvent: null, selectedEventId: null }, this.doSearch);
                }}>
                    <Row className="mb-3">
                        <Col className="col-2">
                            <CustomSelect icon={'fa fa-exclamation-triangle fa-fw'} isClearable isSearchable placeholder={<FormattedMessage id="ApiLogs.Status" />} options={states} onChange={this.setComboStatus.bind(this, 'status')} />
                        </Col>
                        <Col className="col-2">
                            <div>
                                <CustomSelect icon={'fa fa-filter fa-fw'} isClearable isSearchable placeholder={<FormattedMessage id="ApiLogs.EventType" />} options={eventTypes} onChange={(e) => this.handleEvent(e)} />
                                {
                                    eventType === eventTypes[0].value ?
                                        <InputGroup size="sm" className="mt-1">
                                            <InputGroupAddon addonType="prepend">
                                                <span className="input-group-text border-0">
                                                    <i className="fas fa-key" />
                                                </span>
                                            </InputGroupAddon>
                                            <FormattedMessage id="ApiLogs.ProfileId">
                                                {
                                                    placeholder =>
                                                        <Input
                                                            type="text"
                                                            name="profileId"
                                                            maxLength="50"
                                                            id="profileId"
                                                            placeholder={placeholder}
                                                            value={profileId || ''}
                                                            onChange={(e) => this.setState({ profileId: e.currentTarget.value })}
                                                        />
                                                }
                                            </FormattedMessage>
                                        </InputGroup>
                                        :
                                        ''
                                }
                            </div>
                        </Col>
                        <Col className="col-2 px-1">
                            <InputGroup size="sm text-right">
                                <DateRangePicker
                                    required
                                    startDate={startDate}
                                    startDateId="your_unique_start_date_id"
                                    endDate={endDate}
                                    endDateId="your_unique_end_date_id"
                                    isOutsideRange={day => day > moment() || day < moment().subtract(6, 'month')}
                                    onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    small={true}
                                    numberOfMonths={1}
                                    showDefaultInputIcon={true}
                                    renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                />
                            </InputGroup>
                        </Col>
                        <Col className="text-right">
                            <Button className="btn btn-host shadow btn-sm mt-2" type="submit">
                                <i className="fas fa-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <div className="mt-2" />
                <div className="small">
                    <Timeline
                        events={events}
                        layout='1-column'
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        selectedEventId={selectedEvent && selectedEvent.id}
                        states={states}
                        totalPages={totalPages}
                        changePreviousPage={this.changePreviousPage}
                        changeNextPage={this.changeNextPage}
                        handlePageIndex={this.handlePageIndex}
                        prepareDoSearch={this.prepareDoSearch}
                        selectEvent={this.selectEvent}
                    >
                        {
                            selectedEvent ?
                                <Card className="small border-0 h-100">
                                    {
                                        selectedEvent.result === states[1].value ?
                                            <ErrorDetailsTable title={<FormattedMessage id="ApiLogs.Errors" />} data={selectedEvent.messageResponse.indexOf('{') == 0 ? JSON.parse(selectedEvent.messageResponse).Errors : selectedEvent.messageResponse} icon={<i className="fas fa-exclamation-triangle text-danger" />} />
                                            :
                                            <div />
                                    }
                                    {
                                        selectedEvent.result === states[2].value ?
                                            <ErrorDetailsTable title={<FormattedMessage id="ApiLogs.Warnings" />} data={selectedEvent.messageResponse.indexOf('{') == 0 ? JSON.parse(selectedEvent.messageResponse).Warnings : selectedEvent.messageResponse} icon={<i className="fas fa-exclamation-triangle text-warning" />}/>
                                            :
                                            <div />
                                    }
                                    <LogMessages selectedEvent={selectedEvent} />
                                </Card>
                                :
                                <Card className="text-secondary d-flex justify-content-center text-center h-100 shadow border-0 py-3">
                                    <i className="fas fa-info-circle fa-8x"/>
                                    <br />
                                    <h5>
                                        <FormattedMessage id="Timeline.SelectEventToSeeDetails" />
                                    </h5>
                                </Card>
                        }
                    </Timeline>
                </div>
            </StyledCard>
        );
    }
}

export default injectIntl(ApiLogs);