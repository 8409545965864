import React, { Component } from 'react';
import { Row, Col, Input, Button, Collapse, FormGroup, UncontrolledTooltip, CardBody, Card } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getAPI } from "./Base/API";
import { createBrowserHistory } from "history";
import { BlankCard } from './Base/CommonUIComponents';

export class SearchButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            searchquery: null,
            result: null,
            totalPage: 3,
            page: 0,
            maxPage: 0,
            pathnames: [
                { path: '/DataQualityTemplateDashboard', categoryV2: 'DataQuality', categoryV3: 'Templates' },
                { path: '/RuleDashboard', categoryV2: 'DataQuality', categoryV3: 'Rules' },
                { path: '/TaskResultDashboard', categoryV2: 'DataQuality', categoryV3: 'TaskResults' },
                { path: '/DataQualityIssues', categoryV2: 'DataQuality', categoryV3: 'Issues' },
                { path: '/MostRepeated', categoryV2: 'DataQuality', categoryV3: 'MostRepeated' },
                { path: '/CurrentHotelGroup', categoryV2: 'Hotel', categoryV3: 'Integrations' },
                { path: '/QualityMetrics', categoryV2: 'Analitics', categoryV3: '' },
                { path: '/SearchProfile', categoryV2: 'Profile', categoryV3: 'Search' },
                { path: '/ImportProfile', categoryV2: 'Profile', categoryV3: 'Import' },
                { path: '/ProfileMergeDetail', categoryV2: 'Profile', categoryV3: 'Merge' },
                { path: '/ProfileAnalysis', categoryV2: 'Profile', categoryV3: 'Analysis' },
                { path: '/AssociateTags', categoryV2: 'Profile', categoryV3: 'Tag' },
                { path: '/TagMapping', categoryV2: 'Configuration', categoryV3: 'Tag' },
                { path: '/ProfileScore', categoryV2: 'Configuration', categoryV3: 'Score' },
                { path: '/ManageUser', categoryV2: 'UserManagment', categoryV3: '' },
                { path: '/ReferenceData', categoryV2: 'Configuration', categoryV3: '' },
                { path: '/ProfileMatchConfig', categoryV2: 'Configuration', categoryV3: 'Duplication' },
                { path: '/LoyaltyCardRules', categoryV2: 'Configuration', categoryV3: 'LoyaltyCardRule' },
                { path: '/CustomTags', categoryV2: 'Configuration', categoryV3: 'CustomTag' },
                { path: '/LoyaltyCardLevels', categoryV2: 'Configuration', categoryV3: 'LoyaltyCardLevel' },
                { path: '/ConfigCardPoints', categoryV2: 'Configuration', categoryV3: 'CardPoint' },
                { path: '/Mappings', categoryV2: 'Configuration', categoryV3: 'Egoi' },
                { path: '/reviewProMenu', categoryV2: 'Configuration', categoryV3: 'ReviewPro' },
                { path: '/reviewProConfig', categoryV2: 'Configuration', categoryV3: 'ReviewPro' },
                { path: '/reviewProInquiry', categoryV2: 'Configuration', categoryV3: 'ReviewPro' },
                { path: '/ReviewProMapForm', categoryV2: 'Configuration', categoryV3: 'ReviewPro' },
                { path: '/ReviewProStats', categoryV2: 'Configuration', categoryV3: 'ReviewPro' },
                { path: '/ReviewProExportProfiles', categoryV2: 'Configuration', categoryV3: 'ReviewPro' },
                { path: '/CustomExport', categoryV2: 'Configuration', categoryV3: 'ReviewPro' },
                { path: '/Mappings', categoryV2: 'Configuration', categoryV3: 'Egoi' },
                { path: '/CommunicationJourney', categoryV2: 'MarketingAutomation', categoryV3: 'Communication' },
                { path: '/Campaigns', categoryV2: 'MarketingAutomation', categoryV3: 'Campaign' },
                { path: '/CampaignDetails', categoryV2: 'MarketingAutomation', categoryV3: 'Campaign' },
                { path: '/CommunicationStats', categoryV2: 'MarketingAutomation', categoryV3: 'Campaign' },
                { path: '/CustomCampaign', categoryV2: 'MarketingAutomation', categoryV3: 'Campaign' },
                { path: '/CustomCampaignDetails', categoryV2: 'MarketingAutomation', categoryV3: 'Campaign' },
                { path: '/SendDetails', categoryV2: 'MarketingAutomation', categoryV3: 'Communication' },
                { path: '/MarketingAlerts', categoryV2: 'MarketingAutomation', categoryV3: 'Alerts' },
                { path: '/MarketingAlertDetails', categoryV2: 'MarketingAutomation', categoryV3: 'Alerts' },
                { path: '/ExportProfiles', categoryV2: 'MarketingAutomation', categoryV3: 'ExportProfiles' },
                { path: '/StatsEgoi', categoryV2: 'MarketingAutomation', categoryV3: 'Egoi' },
                { path: '/MarketingConfigEgoi', categoryV2: 'MarketingAutomation', categoryV3: 'Egoi' },
                { path: '/CampaignList', categoryV2: 'MarketingAutomation', categoryV3: 'Egoi' },
                { path: '/EgoiList', categoryV2: 'MarketingAutomation', categoryV3: 'Egoi' },
                { path: '/AlertsList', categoryV2: 'MarketingAutomation', categoryV3: 'Egoi' },
                { path: '/AlertDetails', categoryV2: 'MarketingAutomation', categoryV3: 'Egoi' },
                { path: '/SendersList', categoryV2: 'MarketingAutomation', categoryV3: 'Egoi' },
                { path: '/TagList', categoryV2: 'MarketingAutomation', categoryV3: 'Egoi' },
                { path: '/FormList', categoryV2: 'MarketingAutomation', categoryV3: 'Egoi' },
                { path: '/FormSubmissions', categoryV2: 'MarketingAutomation', categoryV3: 'Egoi' },
                { path: '/EgoiMapForm', categoryV2: 'MarketingAutomation', categoryV3: 'Egoi' },
                { path: '/TransactionalTemplateList', categoryV2: 'MarketingAutomation', categoryV3: 'Egoi' },
                { path: '/EgoiWebhookList', categoryV2: 'MarketingAutomation', categoryV3: 'Egoi' },
                { path: '/ShortenerList', categoryV2: 'MarketingAutomation', categoryV3: 'Egoi' },
                { path: '/DomainList', categoryV2: 'MarketingAutomation', categoryV3: 'Egoi' },
                { path: '/AutomationList', categoryV2: 'MarketingAutomation', categoryV3: 'Egoi' },
                { path: '/EgoiServiceActivation', categoryV2: 'MarketingAutomation', categoryV3: 'Egoi' },
                { path: '/Account', categoryV2: 'MarketingAutomation', categoryV3: 'Egoi' },
                { path: '/SuppressionList', categoryV2: 'MarketingAutomation', categoryV3: 'Egoi' },
                { path: '/BatchLogs', categoryV2: 'Integrations', categoryV3: 'BatchLog' },
                { path: '/HijiffyInbox', categoryV2: 'Integrations', categoryV3: 'HiJiffy' },
                { path: '/HijiffyContacts', categoryV2: 'Integrations', categoryV3: 'HiJiffy' },
                { path: '/HijiffyProfile', categoryV2: 'Integrations', categoryV3: 'HiJiffy' }
            ]
        };
    }

    toggle = () => {
        this.setState(prevstate => ({
            isOpen: !prevstate.isOpen,
            result: null,
            page: 0
        }));
    }

    doSearch = () => {
        const { searchquery, totalPage } = this.state;
        if (searchquery !== null && searchquery.length > 0) {
            this.setState({ block: true });
            getAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({
                        message: error.message,
                        stack: error.stack,
                        messageType: "danger"
                    });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    const maxPage = data.results ? (data.results.length % totalPage === 0 ? data.results.length / totalPage : parseInt(data.results.length / totalPage)) : 0;
                    this.setState({ result: data, page: 0, maxPage: maxPage, block: false });
                }
                else {
                    this.setState({ result: null, page: 0, maxPage: 0, block: false });
                }
            }, `/wiki/rest/api/search?cql=text~"${searchquery}"+and+type=page+and+space=ProfileNowExternalDoc`);
        }
    }

    getSupportLink = () => {
        return `${process.env.REACT_APP_SUPPORT_URL}?brand=ProfileNow&hotel=${global.hotelId}`;


        //TODO: Rever e enviar categorias
        //const history = createBrowserHistory();
        //const pathname = pathnames.find(ptn => history.location.pathname.includes(ptn.path));

        //if (pathname) {
        //    return `${process.env.REACT_APP_SUPPORT_URL}?v1=ProfileNow&v2=${pathname.categoryV2}&v3=${pathname.categoryV3}&hotel=${global.hotelId}`;
        //}
        //else {
        //    return `${process.env.REACT_APP_SUPPORT_URL}?v1=ProfileNow&hotel=${global.hotelId}`;
        //}
    }

    render() {
        const { block, error, isOpen, maxPage, pathnames, page, result, totalPage } = this.state;
        const begin = page * totalPage;
        const end = begin + totalPage;


        return (
            <div className="col-6 mt-5" style={{ position: 'fixed', bottom: '0' }} >
                <Row style={{ position: 'fixed', bottom: '0', right: '0', zIndex: '1000' }}>
                    <Col>
                        <span id="SearchButton" className="btn-sm btn-host float-right mb-1 mr-1" onClick={this.toggle}>
                            <i className="fas  fa-question-circle" />
                        </span>
                    </Col>
                </Row>

                <Collapse isOpen={isOpen}>
                    <Card className="bg-light" style={{ position: 'fixed', bottom: '0', width: '50%', right: '0', zIndex: '999' }}>
                        <CardBody className="pt-1">
                            <BlankCard error={error} block={block} style={{ width: "100%", height: "100%" }}>
                                <FormGroup sm="true" row className="mb-2">
                                    <Col sm={10}>
                                        <FormattedMessage id="SearchButton.Search">
                                            {
                                                placeholder => <Input id="SearchText" type="text" placeholder={placeholder} onChange={(evt) => this.setState({ searchquery: evt.target.value })} />
                                            }
                                        </FormattedMessage>
                                    </Col>
                                    <Col sm={2} className="text-right" >
                                        <Button className="btn-sm btn-host mr-2" id="Tooltipcreateticket" on="true" tag="a" href={this.getSupportLink()} target="_blank">
                                            <i className="fas fa-headset" />
                                        </Button>
                                        <UncontrolledTooltip placement="top" target="Tooltipcreateticket" >
                                            <FormattedMessage id="SearchButton.CreateTicket" />
                                        </UncontrolledTooltip>
                                        <Button className="btn-sm btn-host " onClick={this.doSearch}>
                                            <i className="fas fa-search" />
                                        </Button>
                                    </Col>
                                </FormGroup>

                                {
                                    result ?
                                        result.results ?
                                            result.results.length === 0 ?
                                                <FormattedMessage id="SearchButton.NoResults" />
                                                :
                                                <div>
                                                    {
                                                        result.results.slice(begin, end).map((result, key) =>
                                                            <CardBody className="py-1" key={key}>
                                                                <Row className="mb-1">
                                                                    <Col>
                                                                        <i className="far fa-file-alt mr-2" />
                                                                        <b>
                                                                            <a href={this.state.result._links.base + result.url} target="_blank">
                                                                                <div dangerouslySetInnerHTML={{ __html: result.title.replace(/\@\@\@hl\@\@\@/gi, "<u>").replace(/\@\@\@endhl\@\@\@/gi, "</u>") }} />
                                                                            </a>
                                                                        </b>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <div dangerouslySetInnerHTML={{ __html: result.excerpt.replace(/\@\@\@hl\@\@\@/gi, "<b>").replace(/\@\@\@endhl\@\@\@/gi, "</b>") }} />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col className="text-secondary">{result.friendlyLastModified}</Col>
                                                                </Row>
                                                            </CardBody>
                                                        )
                                                    }
                                                    <Row className="mt-2">
                                                        <Col>
                                                            <Button className="btn-sm btn-host float-left" onClick={_ => this.setState(prevstate => ({ page: prevstate.page - 1 }))} disabled={page === 0}>
                                                                <i className="fas fa-angle-left" />
                                                            </Button>
                                                            <Button className="btn-sm btn-host float-right mr-1" onClick={_ => this.setState(prevstate => ({ page: prevstate.page + 1 }))} disabled={page === maxPage}>
                                                                <i className="fas fa-angle-right" />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            :
                                            <div />
                                        :
                                        <div />
                                }
                            </BlankCard>
                        </CardBody>
                    </Card>
                </Collapse>
            </div>
        );
    }
}