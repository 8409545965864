import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Modal, ModalBody, Input, CustomInput, Card } from 'reactstrap';
import { getAllCountryDialCodes, getContactTypeList, getCountryDialCodes } from '../Base/ReferenceDataFunctions';
import { handleNotification } from "../Base/Notification";
import { BlankCard } from '../Base/CommonUIComponents';
import CustomSelect from "../Base/CustomSelect";
import { putAPI } from "../Base/API";
import parsePhoneNumber, { validatePhoneNumberLength, getExampleNumber, isSupportedCountry, isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import parseMax from 'libphonenumber-js/max'

class ContactDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            contactData: this.props.contact ? this.props.contact : {},
            contactTypes: this.props.availableTypes ? this.props.availableTypes : [],
            disableMain: false,
            invalidPhone: false,
            invalidCountry: false,
            countryCodeOptions: [],
            validatedCountries: [],
            invalidType: false,
            suggestedType: null,
            validPhone: false,
            validCountries: null,
            phone: ''
        };
    }

    componentDidMount() {
        const { contact } = this.props;
        this.formatContactTypes();
        if(contact && (contact.type === 'Mobile' || contact.type === 'Phone') && contact.contact){
            this.validateContact(contact.contact);
        }
    }

    formatContactTypes = () => {
        const { contact, availableTypes, intl } = this.props;

        if (contact?.id && contact.type !== 'Fax' && contact.type) {
            let contactTypes = [...availableTypes];
            const idx = contactTypes.findIndex(el => el.value === contact.type);
            let disableMain = false;

            const mainOption1 = contact.main ? 'hasMainEmail' : 'hasNotMainEmail';
            const mainOption2 = contact.main ? 'hasNotMainEmail' : 'hasMainEmail';

            if (idx > -1) {
                if(contactTypes[idx].mainOptions){
                    contactTypes[idx].mainOptions[mainOption1] = false;
                    disableMain = contactTypes[idx].mainOptions[mainOption2];
                };
            }
            else {
                const type = getContactTypeList(intl).find(el => el.value === contact.type);
                type.mainOptions = { [mainOption1]: false };

                contactTypes.push(type);

                disableMain = true;
            };
            
            this.setState({ disableMain, contactTypes });
        }
    }

    handleChange = (e) => {
        const { name, value, checked } = e.target;

        this.setState({
            phone: value,
            contactData: {
                ...this.state.contactData,
                [name]: (name === 'main' ? checked : value)
            }
        })
    }

    handleSwitch = (evt) => {
        if (evt) {
            const { name, checked } = evt.target;

            this.setState({
                contactData: {
                    ...this.state.contactData,
                    [name]: checked
                }
            });
        }        
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
            contactData: {
                ...this.state.contactData,
                [name]: combo ? combo.value : null
            },
            invalidType: null,
            suggestedType: null,
            invalidPhone: null,
            validPhone: null,
            phone: null 
        })
    };

    handleChangeType = (combo) => {
        const { phone, contactData } = this.state;
        const mainEnable = combo && combo.mainOptions ? !combo.mainOptions.hasMainEmail && !combo.mainOptions.hasNotMainEmail : true;

        this.setState(prevState => ({
            contactData: {
                ...prevState.contactData,
                type: combo && combo.value,
                main: mainEnable ? prevState.contactData.main : (combo.mainOptions.hasMainEmail ? false : true),
            },
            disableMain: !mainEnable
        }), () => {
            if(combo?.value === 'Mobile' || combo?.value === 'Phone') this.validateContact(phone);
        });
    };

    saveContact = (e) => {
        e.preventDefault();
        const { phone, contactData } = this.state;
        const { allContacts } = this.props;

        var contact = { ...contactData };

        if (contact && contact.type === 'Mobile' || contact.type === 'Phone') {
            contact.contact = phone;
        };

        if (!contact.contact) contact.contact = '';

        contact.contact = contact.contact.replace(/ /g, '');

        if (allContacts && allContacts.filter(({ type, main, id }) => type === contact.type && (main && contact.main) && contactData?.id !== id).length > 0) {
            return;
        };


        if (typeof this.props.saveContact === 'function') {
            this.props.saveContact(contact)
        }
        else {
            this.setState({ block: true });

            putAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    if (data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="ContactDetails.ContactSaved" />, <FormattedMessage id="generic.success" />);
                        this.props.updateTable(data.response[0], 'contacts');
                        this.props.toggleModal();
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/Profile/v1/profile/contact/${this.props.profileId}`, JSON.stringify({ request: contact }));
        }
    }

    getPossibelPrefix = (phone) => {
        const { nationality, addresses } = this.props;

        let possibleCountries = [];

        if(addresses && addresses.length > 0) possibleCountries.concat(addresses.map(({country}) => {return({ countryCode: country, origin: 'Country' })}));
        if(nationality) possibleCountries.push({ countryCode: nationality, origin: 'Nationality' });
        if(global?.hotelCountryCode) possibleCountries.push({ countryCode: global.hotelCountryCode, origin: 'Hotel' });

        possibleCountries = possibleCountries.filter((c, pos, self) => {
            return self.findIndex(({countryCode}) => countryCode === c.countryCode) == pos;
        });
        
        if(possibleCountries){
            const validatedCountries = possibleCountries.map((c) => {
                const prefix = getCountryDialCodes(c.countryCode);

                let numb = phone.includes(prefix) ? phone : prefix + phone;

                c.valid = isValidPhoneNumber(phone, c.countryCode);
                c.errorCode = validatePhoneNumberLength(numb);
                c.parsed = parsePhoneNumber(numb)?.formatInternational();
                c.type = c.valid ? parseMax(numb)?.getType() : null;

                if(phone.includes('(') || phone.includes(')')){
                    c.valid = false;
                    c.errorCode = "parentheses";
                };
                
                if(phone.charAt(0) === "+"){
                    c.valid = false;
                    c.errorCode = "exsDial";
                };

                return c;
            });

            if(validatedCountries.filter(({valid}) => valid).length === 0) this.getValidCountries(phone, validatedCountries);
            this.setState({ validatedCountries });
        };
    }

    getValidCountries = (phone, validatedCountries) => {
        const arr = [];

        Object.keys(getAllCountryDialCodes()).forEach((c) => {
            if(isValidPhoneNumber(phone, c) && validatedCountries.filter(({countryCode}) => countryCode === c).length === 0){
                const prefix = getCountryDialCodes(c);

                arr.push({
                    parsed: prefix?.slice(1) + phone,
                    countryCode: c
                });
            };
        });

        this.setState({ validCountries: arr })
    }

    validateContact = (value) => {
        const { contactData } = this.state;
        
        if (contactData?.type === 'Mobile' || contactData?.type === 'Phone') {
            var invalidPhone, suggestedType, invalidType, validPhone;
            var phone = value ? value.replace(/ /g,'') : null;
            
            if(value){
                var isValid = phone ? isValidPhoneNumber(phone) : false;
                var errorCode = phone ? validatePhoneNumberLength(phone) : "NO_PHONE";

                if(!isValid && phone?.charAt(0) !== "+"){ //When there prefix but missing "+"
                    var newPhone = "+" + phone;
                    
                    if(phone?.charAt(0) === "0" & phone?.charAt(1) === "0"){
                        newPhone = "+" + phone.substring(3);
                    }
                    
                    isValid = isValidPhoneNumber(newPhone);
                    errorCode = validatePhoneNumberLength(newPhone);
                };

                if (!isValid){
                    invalidPhone = true;
                    this.getPossibelPrefix(phone);
                }
                else {
                    validPhone = true;
                    contactData.contact = phone;
                    const type = parseMax("+" + phone)?.getType();
                    if (type && type !== contactData.type.toUpperCase()) {
                        if((type === "FIXED_LINE" || type === "FIXED_LINE_OR_MOBILE") && contactData.type !== "Phone") suggestedType = "Phone";
                        else if((type === "PERSONAL_NUMBER" || type === "FIXED_LINE_OR_MOBILE") && contactData.type !== "Mobile") suggestedType = "Mobile";
                        else suggestedType = type;
                        invalidType = true;
                        this.formatContactTypes();
                    };
                };
            }
            else invalidPhone = true;

            this.setState({ contactData, invalidType, suggestedType, invalidPhone, validPhone, phone });
        };
    }
    
    updatePhone = (parsed) => {
        this.setState({ phone: parsed, validPhone: isValidPhoneNumber(parsed), invalidPhone: !isValidPhoneNumber(parsed) }, () => this.validateContact(parsed));
    }

    render() {
        const { block, error, contactData, contactTypes, invalidPhone, validatedCountries, validPhone, invalidType, validCountries, phone, suggestedType } = this.state;
        const { toggleModal, modal, intl, contact } = this.props;

        
        return (
            <Modal isOpen={modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <Form onSubmit={this.saveContact}>
                            <Row className="mb-3 align-items-center">
                                <Col className='col-8 col-lg-6'>
                                    <h5 className='m-0'>{contact ? <FormattedMessage id="ContactDetails.EditContact" /> : <FormattedMessage id="ContactDetails.AddContact" />}</h5>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" type="submit"> <i className="fas fa-save" /></Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <FormGroup row id="isMain">
                                        <Label sm={3}> <FormattedMessage id="SocialMediaDetails.Main" /></Label>
                                        <Col sm={9} className="d-flex align-items-center justify-content-start">
                                            <CustomInput
                                                type="switch"
                                                id="main"
                                                name="main"
                                                onChange={(e) => this.handleSwitch(e)}
                                                checked={contactData.main ? contactData.main : false}
                                                disabled={this.state.disableMain}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col className="col-6">
                                    <FormGroup row>
                                        <Label sm={3}> <FormattedMessage id="generic.active" /></Label>
                                        <Col sm={9} className="d-flex align-items-center justify-content-start">
                                            <CustomInput
                                                type="switch"
                                                id="active"
                                                name="active"
                                                onChange={this.handleSwitch}
                                                checked={contactData.active ? contactData.active : false}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='col-12 col-lg-6'>
                                    <FormGroup row>
                                        <Label sm={3}> <FormattedMessage id="ProfileDetails.Type" /></Label>
                                        <Col sm={9}>
                                            {invalidType ?
                                                <div style={{ width: '100%', textAlign: 'right' }}>
                                                    <span title={intl.formatMessage({ id: suggestedType ? `ProfileDetails.${suggestedType}`: 'ProfileDetails.invalidType' })}
                                                        style={{ position: 'absolute', marginLeft: '-65px', marginTop: '5px', zIndex: '1', fontSize: '20px' }}>
                                                        <i className="icon-icon-warnings-enabled color-yellow"/>
                                                    </span>
                                                </div>
                                            :''}
                                            <CustomSelect
                                                options={contactTypes}
                                                required
                                                isSearchable
                                                placeholder={""}
                                                onChange={(e) => this.handleChangeType(e)}
                                                value={contactTypes.find(c => c.value === contactData.type)}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col className='col-12 col-lg-6'>
                                    <FormGroup row>
                                        <Label sm={3}>
                                            <FormattedMessage id="ProfileDetails.Contact"/>
                                        </Label>
                                        <Col sm={9}>
                                            {invalidPhone ?
                                                <div style={{ width: '100%', textAlign: 'right' }}>
                                                    <span title={intl.formatMessage({ id: "ProfileDetails.InvalidPhone" })} style={{ position: 'absolute', marginLeft: '-30px', marginTop: '4px', fontSize: '20px', zIndex: '1' }}>
                                                        <i className="icon-icon-warnings-enabled color-yellow"/>
                                                    </span>
                                                </div>
                                            :''}
                                            {validPhone ?
                                                <div style={{ width: '100%', textAlign: 'right' }}>
                                                    <span style={{ position: 'absolute', marginLeft: '-30px', marginTop: '4px', fontSize: '20px', zIndex: '1' }}>
                                                        <i className="text-success fas fa-check"/>
                                                    </span>
                                                </div>
                                            :''}
                                            <Input
                                                type="text"
                                                name="contact"
                                                onChange={(e) => this.handleChange(e)}
                                                value={(contactData.type === 'Mobile' || contactData?.type === 'Phone') ? phone : contactData.contact ? contactData.contact : ''}
                                                onBlur={(e) => this.validateContact(e?.target?.value)}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {invalidPhone && (contactData.type === 'Mobile' || contactData?.type === 'Phone') && phone && phone.length > 0 ?
                                <>
                                    <Row className='mt-4'>
                                        <Col>
                                            <h5><FormattedMessage id="ProfileDetails.phoneValidation"/></h5>
                                        </Col>
                                    </Row>
                                    {validatedCountries.map((c, k) => 
                                        <Card key={k} className='px-4 border-0 shadow py-2 mt-2 pointer' onClick={() => c.parsed && this.updatePhone(c.parsed)}>
                                            <Row>
                                                <Col className='col-12 col-lg-5'>
                                                    <span><FormattedMessage id='ProfileDetails.basedOn'/></span>
                                                    <span className='ml-1'><FormattedMessage id={`ProfileDetails.${c.origin}`}/>:</span>
                                                    <span className={`ml-4 flag-icon flag-icon-${c.countryCode.toLowerCase()}`}/>
                                                </Col>
                                                <Col className='col-12 col-lg-3 text-left text-lg-right mt-2 mt-lg-0'>
                                                    {c.parsed ?
                                                        <span>
                                                            <b>{c.parsed}</b>
                                                        </span>
                                                    :''}
                                                </Col>
                                                <Col className='col-8 col-lg-3 text-left text-lg-right mt-2 mt-lg-0'>
                                                    {!c.valid ?
                                                        <span>
                                                            <FormattedMessage id={`ProfileDetails.${c.errorCode}`}/>
                                                        </span>
                                                    :''}
                                                </Col>
                                                <Col className='text-right col-4 col-lg-1 mt-2 mt-lg-0'>
                                                    <span>
                                                        {c.valid ?
                                                            <i className="text-success fas fa-check"/>
                                                        :
                                                            <i className="fas fa-times color-red"/>
                                                        }
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Card>
                                    )}
                                    {validCountries && validCountries.length > 0 ?
                                        <>
                                            <Row className='mt-4 mb-3'>
                                                <Col>
                                                    <FormattedMessage id="ProfileDetails.validCountries"/>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {validCountries.map((c, k) => 
                                                        <span key={k} style={{ fontSize: '15px' }} onClick={() => this.updatePhone(c.parsed)} className={`pointer mx-3 flag-icon flag-icon-${c.countryCode.toLowerCase()}`}/>
                                                    )}
                                                </Col>
                                            </Row>
                                        </>
                                    :''}
                                </>
                            :''}
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(ContactDetails)