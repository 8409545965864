import React, { Component } from 'react';
import { Button, Col, Row, Modal, ModalBody, Input, Form } from 'reactstrap';
import CustomSelect from '../../Base/CustomSelect';
import { FormattedMessage } from "react-intl";
import { getAPI, postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { BlankCard } from '../../Base/CommonUIComponents';
import EditorText from '../../Base/EditorText';
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

export class TestSendModal extends Component {

    constructor(props) {
        super(props);
        this.formEmail = React.createRef();
        this.state = {
            block: true,
            contact: null,
            contacts: [],
            senders: [],
            senderName: null,
            commentEditorState: null
        }
    }

    componentDidMount() {
        const { hasModuleEgoi } = this.props;

        if (hasModuleEgoi) {
            this.getEgoiSenders();
        }
        else {
            this.getSendgridSenders();
        }
    }

    getEgoiSenders = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if ((data.Errors && data.Errors.length > 0) || (data.errors && data.errors.length > 0)) {
                    handleNotification(data);
                }

                let senders = [];

                data.items && data.items.forEach(resp => {
                    senders.push({ value: resp.sender_id, label: `${resp.name}(${resp.email})` });
                });

                this.setState({ block: false, senders });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Marketing/v1/egoi/senders/email?offset=0&limit=100`);
    }

    getSendgridSenders = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.results && data.response.results.length > 0) {
                const senders = data.response.results
                    .filter(resp => resp.verified)
                    .map(resp => {
                        const sender = {
                            value: resp.from_email,
                            label: `${resp.from_name}(${resp.from_email})`,
                            name: resp.from_name
                        };
                        return sender;
                    });
                this.setState({ error: errorMessage, block: false, senders: senders });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/SendGrid/v1/sender?limit=100`);
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null, senderName: combo ? combo.name : null
        });
    };

    handleChange = (e) => {
        const { value, name } = e.target;

        this.setState({ [name]: value });
    }

    addContact = () => {
        if (!this.formEmail.current.reportValidity()) {
            return;
        }

        const { contact, contacts } = this.state;
        contacts.push(contact);
        this.setState({ contacts, contact: null });
    }

    removeContact = (idx) => {
        const { contacts } = this.state;
        contacts.splice(idx, 1);
        this.setState({ contacts });
    }

    sendTest = (e) => {
        e.preventDefault();
        const { hasModuleEgoi } = this.props;
        const { commentEditorState } = this.state;

        window.StripoApi.compileEmail((e, html) => {
            if (e) {
                this.setState({ error: { message: e, messageType: 'danger' }, block: false });
                return;
            };

            let newhtml = html;
            if (commentEditorState && commentEditorState.getCurrentContent()) {
                const comment = draftToHtml(convertToRaw(commentEditorState.getCurrentContent()));
                if (!comment.includes('<p></p>')) {
                    newhtml = html.replace(/(<body)(.*?)>/i, `$1$2>${comment}`);
                }
            }

            this.setState({ block: true });

            if (hasModuleEgoi) {
                this.sendTestEgoi(newhtml);
            }
            else {
                this.sendTestSendGrid(newhtml);
            }
        });
    }

    sendTestEgoi = (html) => {
        const { subject, toggle } = this.props;
        const { contacts, from } = this.state;

        const body = contacts.map(cnt => {
            const req = {
                senderId: from,
                subject: subject,
                email: cnt,
                message: {
                    html: html
                }
            };
            return req;
        });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if ((data.Errors && data.Errors.length > 0) || (data.errors && data.errors.length > 0)) {
                    handleNotification(data);
                }
                else if (data.response) {
                    handleNotification(data, <FormattedMessage id={`TransactionalTemplateList.mailSent`} />, <FormattedMessage id="generic.success" />);
                    toggle();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoitransactional/mail/sendBulk`, body);
    }

    sendTestSendGrid = (html) => {
        const { subject, toggle } = this.props;
        const { contacts, from, senderName } = this.state;

        const request = {
            request: contacts.map(eml => {
                const req = {
                    senderName: senderName,
                    senderEmail: from,
                    email: eml,
                    subject: subject,
                    contentHtml: html
                };
                return req;
            })
        };

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="SendEmailModal.MessageSentWithSuccess" />, <FormattedMessage id="generic.success" />);
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SendGrid/v1/email/send`, request);
    }

    onEditorStateChange = (newCommentEditorState) => {
        this.setState({ commentEditorState: newCommentEditorState });
    }

    render() {
        const { modal, templateName, toggle } = this.props;
        const { block, error, contact, contacts, senders, commentEditorState } = this.state;

        return (
            <Modal isOpen={modal} fade={false} size={"lg"} style={{ minWidth: 0 }} >
                <Button onClick={toggle} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <Form onSubmit={this.sendTest}>
                            <Row className="mb-3">
                                <Col className="col-9 pl-1">
                                    <h5>
                                        {templateName}
                                    </h5>
                                </Col>
                                <Col className="text-right col-3">
                                    <Button className="btn btn-host btn-sm" onClick={this.sendMessage} type="submit" disabled={contacts.length === 0}>
                                        <FormattedMessage id="ProfileDetails.SendEmail" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col className="col-2 px-1">
                                    <FormattedMessage id="SendEmailModal.Sender" />
                                </Col>
                                <Col className="col-10">
                                    <CustomSelect
                                        name="from"
                                        options={senders}
                                        onChange={this.handleSelect.bind(this, "from")}
                                        required
                                        placeholder=""
                                    />
                                </Col>
                            </Row>
                        </Form>
                        <form ref={this.formEmail}>
                            <Row className="mt-2 mb-2">
                                <Col className="col-2 px-1">
                                    <FormattedMessage id="SendEmailModal.To" />
                                </Col>
                                <Col className="col-9">
                                    <Input
                                        type={'email'}
                                        name='contact'
                                        onChange={(e) => this.handleChange(e)}
                                        required
                                        value={contact || ''}
                                    />
                                </Col>
                                <Col>
                                    <Button className="btn btn-host btn-sm" onClick={this.addContact}>
                                        <i class="fas fa-plus" />
                                    </Button>
                                </Col>
                            </Row>
                            {
                                contacts.map((cnt, key) =>
                                    <Row key={key} className="mb-2">
                                        <Col className="col-2" />
                                        <Col className="col-9">
                                            <span className="ml-3">
                                                {cnt}
                                            </span>
                                        </Col>
                                        <Col className="col-1">
                                            <Button className="btn btn-host btn-sm" onClick={_ => this.removeContact(key)}>
                                                <i class="fas fa-trash-alt" />
                                            </Button>
                                        </Col>
                                    </Row>
                                )
                            }
                        </form>
                        <Row className="mt-2">
                            <Col className="col-2 px-1">
                                <FormattedMessage id="SendEmailModal.Comment" />
                            </Col>
                            <Col>
                                <EditorText
                                    editorState={commentEditorState}
                                    onEditorStateChange={this.onEditorStateChange}
                                />
                            </Col>
                        </Row>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}