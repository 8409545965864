import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Col, Collapse, Input, Row } from 'reactstrap'
import CustomSelect from '../../Base/CustomSelect';

export default class DetailSearchSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            opened: true,
        }
    }

    toggle = () => {
        this.setState({ opened: !this.state.opened });
    }

    render() {
        const { options, onChange, sectionName } = this.props;
        const { opened } = this.state;

        return (
            <div>
                <div onClick={this.toggle} style={{ fontSize: '1.2em' }} className='d-flex align-items justify-content-between py-2 cursor-pointer'>
                    <div>
                        <FormattedMessage id={`DetailProfileSearch.${sectionName}`}/>
                    </div>
                    <div>
                        {opened ?
                            <i className="fas fa-chevron-down"></i>
                        :
                            <i className="fas fa-chevron-up"></i>
                        }
                    </div>
                </div>
                <Collapse isOpen={opened} >
                    <div className='py-2'>
                        {options?.map((op, k) =>
                            <div className='mb-2' key={k}>
                                {op.type === "bool" ?
                                    <Row>
                                        <Col className="d-flex align-items-center">{op.name}</Col>
                                        <Col className='col-4'>
                                            <Input
                                                type="checkbox"
                                                value={op.value}
                                                onChange={e => onChange(op.key, sectionName, e.target.checked)}
                                            />
                                        </Col>
                                    </Row>
                                : op.type === "select" ?
                                        <CustomSelect
                                            options={op.options}
                                            placeholder={<FormattedMessage id={`DetailProfileSearch.${op.name}`}/>}
                                            value={op.options?.find(({selected}) => selected) ? op.options?.find(({selected}) => selected) : null}
                                            onChange={e => onChange(sectionName, k, e?.value)}
                                            isClearable={op.isClearable}
                                            required={op.required}
                                        />
                                :''}
                            </div>
                        )}
                    </div>
                </Collapse>
            </div>
        )
    }
}
