import React from "react";
import { Row, Col, Card, Badge } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';
import moment from 'moment';


class Calendar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            currentDate: this.props.currentDate,
            allmonths: moment.months(),
            weekDays: moment.weekdaysShort(),
            calendarRows: [],
            calendarView: 'Day',
            weeks: [],
            selectedWeek: {}
        }
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.setState(nextProps, () => this.handleCalendarView())
        }
    }

    handleCalendarView = () => {
        if (this.state.calendarView === 'Day') {
            this.dailyView();
            return;
        }
        if (this.state.calendarView === 'Week') {
            this.weeklyView();
            return;
        }
    }

    weeklyView = () => {
        let weeks = [], array = [];
        const daysInMonth = moment(this.state.currentDate).daysInMonth();

        for (var i = 1; i <= daysInMonth; i++) {
            if (i % 7 !== 0) {
                array.push(i.toString());
            }
            else {
                array.push(i.toString());
                weeks.push(array); 
                array = []; 
            }
            if (i === daysInMonth && array.length > 0) {
                weeks.push(array);
            }
        }
        
        this.setState({ weeks })
    }


    dailyView = () => {
        let blank = this.getBlankCells();
        let daysInMonth = this.getDaysInMonth();
        let totalSlots = [...blank, ...daysInMonth];
        let cells = [], rows = [];

        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row); // if index not equal 7 that means not go to next week
            } else {
                rows.push(cells); // when reach next week we contain all td in last week to rows 
                cells = []; // empty container 
                cells.push(row); // in current loop we still push current row to new container
            }
            if (i === totalSlots.length - 1) { // when end loop we add remain date
                while (cells.length < 7) {
                    cells.push(<Col className="p-1 m-1" key={`end` + i + cells.length}>{" "}</Col>)
                }
                rows.push(cells);
            }
        });

        this.setState({ calendarRows: rows })
    }

    getFirstDayOfMonth = () => {
        let dateObject = this.state.currentDate;
        let firstDay = moment(dateObject)
            .startOf("month")
            .format("d");
        return firstDay;
    };
    
    getBlankCells = () => {
        let blanks = [];

        for (let i = 0; i < this.getFirstDayOfMonth(); i++) {
            blanks.push(
                <Col className="p-1 m-1 text-center" key={`blank`+ i}>{""}</Col>
            );
        }

        return blanks
    }

    getDaysInMonth = () => {
        let daysInMonth = [];

        for (let d = 1; d <= moment(this.state.currentDate).daysInMonth(); d++) {
            let currentDay = d == this.state.currentDate.format("D") ? "selected-day" : "";
            let hasEvents = this.state.daysWithEvents && this.state.daysWithEvents.includes(d.toString()) ? 'has-events' : '';
                        
            daysInMonth.push(
                <Col key={d} className={`p-1 m-1 text-center ${currentDay === 'selected-day' ? currentDay : hasEvents}`} onClick={() => { if (hasEvents === 'has-events') this.updateDay(d) }}>
                    <b>{d}</b>
                </Col>
            );
        }

        return daysInMonth;
    }

    updateDay = (day) => {
        const date = moment(this.state.currentDate.format('YYYY-MM-' + day));

        this.setState({
            currentDate: date
        }, () => this.props.handleCurrentDate(date))
    }


    render() {
        const { weekDays, calendarRows, currentDate, block, calendarView, weeks, selectedWeek, daysWithEvents } = this.state;
        
        return (
            <BlockUi tag="div" blocking={block} className="h-100">
                <Card className="p-3 h-100 border-0 bg-white shadow display-flex" style={{ justifyContent: 'space-between' }}>
                    <Row className="mb-3">
                        <Col>
                            <h6 className="text-muted"><FormattedMessage id="ProfileJourney.Calendar"/> </h6>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col className="text-center">
                            <Badge className="p-2 cursor-pointer" color={calendarView === 'Day' ? "primary" : "not-selected"} onClick={() => this.props.changeCalendarView('Day')}>
                                <FormattedMessage id="ProfileJourney.Day" />
                            </Badge>
                            <Badge className="p-2 cursor-pointer mx-3" color={calendarView === 'Week' ? "primary" : "not-selected"} onClick={() => this.props.changeCalendarView('Week')}>
                                <FormattedMessage id="ProfileJourney.Week" />
                            </Badge>
                        </Col>
                    </Row>

                    <Row className="mt-4 mb-4">
                        <Col className="text-center">
                            <i className="fas fa-chevron-left text-primary cursor-pointer" onClick={() => { if (daysWithEvents && daysWithEvents.length > 0) this.props.getMonthEvents(moment(currentDate.format('YYYY-MM-' + daysWithEvents[0])), false) }} />

                            <span className="mx-4"><b> {currentDate.format("MMMM YYYY")} </b></span>

                            <i className="fas fa-chevron-right text-primary cursor-pointer" onClick={() => { if (daysWithEvents && daysWithEvents.length > 0) this.props.getMonthEvents(moment(currentDate.format('YYYY-MM-' + daysWithEvents[daysWithEvents.length - 1])), true) }}/>
                        </Col>
                    </Row>

                    {calendarView === 'Day' ?
                        <div className="mb-5">
                            <Row className="mb-3 px-3 align-items-center">
                                {weekDays && weekDays.map(day =>
                                    <Col key={day} className="week-day py-2 px-1 text-center">
                                        {day}
                                    </Col>
                                )}
                            </Row>
                            {calendarRows && calendarRows.map((d, i) =>
                                <Row className="px-3 align-items-center" key={i}>{d}</Row>
                            )}
                        </div>
                    : 
                        <div className="mb-5">
                            {weeks && weeks.map((week, i) => {
                                const hasEvents = daysWithEvents && week.some(w => daysWithEvents.includes(w));

                                return <Row className="mb-2" key={i}>
                                    <Col>
                                        <Badge
                                            color={selectedWeek && selectedWeek.join(',') === week.join(',') ? "primary" : hasEvents === true ? 'not-selected' : 'secondary'}
                                            className={`p-2 w-100 ${hasEvents === true ? 'cursor-pointer' : ''}`}
                                            onClick={() => { if (hasEvents === true) this.props.handleWeekSelection(week) }}
                                        >
                                            {week[0]} - {week[week.length - 1]}
                                        </Badge>
                                    </Col>
                                </Row>
                            } )}
                        </div>
                    }

                    <Row className="mb-3 mt-5 text-center">
                        <Col>
                            <h5 className="text-primary small"><b>{currentDate.format("dddd, MMMM Do YYYY")}</b></h5>
                        </Col>
                    </Row>
                </Card>
            </BlockUi>
        );
    }
}

export default Calendar;