import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Form, Input, Label, Modal, ModalBody, Row, TabContent, TabPane } from 'reactstrap';
import CustomSelect from "../../../Base/CustomSelect";
import { CommonHelper, StyledCard } from '../../../Base/CommonUIComponents';
import EditorText from '../../../Base/EditorText';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

 class SubscriptionModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enabled: this.props.subscriptionObj.enabled,
            html: this.props.subscriptionObj.html_content ,
            landing: this.props.subscriptionObj.landing,
            plain: this.props.subscriptionObj.plain_content,
            replace: this.props.subscriptionObj.replace,
            url: this.props.subscriptionObj.url,
            combo: [{ value: true, label: <FormattedMessage id="SendGrid.Yes" />}, {value: false, label: <FormattedMessage id="SendGrid.No" /> }],
            editorStateHtml: EditorState.createEmpty(),
            editorStateLanding: EditorState.createEmpty(),
        };
    }

    componentDidMount() {
        this.loadContentToEditor(this.state.html, 1);
        this.loadContentToEditor(this.state.landing, 2);
    }

    handleChange = (e) => {
        if(e.target.value === '' || e.target.value === null || e.target.value === undefined){
            this.setState({ [e.target.name]: null });
            return;
        }
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangeComboSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    onEditorStateChange = (editorIndex, editorState) => {
        if (editorIndex === 1) {
            this.setState({
                editorStateHtml: editorState,
            }, () => this.convertToHTML(editorIndex));
        } else if (editorIndex === 2) {
            this.setState({ 
                editorStateLanding: editorState 
            }, () => this.convertToHTML(editorIndex));
        }
    };

    convertToHTML = (editorIndex) => {
        const { editorStateHtml, editorStateLanding } = this.state;
        let contentState, markup;
        if (editorIndex === 1) {
            contentState = editorStateHtml.getCurrentContent();
            markup = draftToHtml(convertToRaw(contentState));
            this.setState({ html: markup });
        }else if (editorIndex === 2) {
            contentState = editorStateLanding.getCurrentContent();
            markup = draftToHtml(convertToRaw(contentState));
            this.setState({ landing: markup });
        }
    };

    loadContentToEditor = (content, editorIndex) => {
        if(content === null && content === undefined){
            return;
        }
        const blocksFromHtml = htmlToDraft(content);
        if (blocksFromHtml) {
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            if (editorIndex === 1) {
                const editorStateHtml = EditorState.createWithContent(contentState);
                this.setState({ editorStateHtml, html: content });
            } else if (editorIndex === 2) {
                const editorStateLanding = EditorState.createWithContent(contentState);
                this.setState({ editorStateLanding, landing: content });
            }
        }
    };

    saveConfigState = (event) => {
        event.preventDefault();

        this.props.updateSubscriptionObjStatus(
            this.state.enabled !== null && this.state.enabled !== undefined ? this.state.enabled : null,
            this.state.html,
            this.state.landing,
            this.state.plain ?? null, 
            this.state.replace ?? null, 
            this.state.url ?? null
        )
        this.props.toggle()
    }

    render() {
        const { enabled, plain, replace, url, combo, editorStateHtml, editorStateLanding, html, landing } = this.state;
        const { toggle } = this.props;

        return (
            <Modal isOpen={true} toggle={toggle} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={toggle} className="closeModal">
                    <i className="fas fa-times fa-sm"/>
                </Button>
                <ModalBody>
                    <StyledCard>
                        <Form onSubmit={this.saveConfigState}>
                            <Row>
                                <Col>
                                    <h5 className='m-0'><FormattedMessage id="SendGrid.SubscriptionTitle"/></h5>
                                </Col>
                                <Col className='col-2 text-right'>
                                    <Button className=" btn btn-host btn-sm">
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='mt-2 col-11'>
                                    <span className='m-0 text-muted'><FormattedMessage id="SendGrid.Subtitle"/></span>
                                </Col>
                            </Row>
                            <div className='mt-4'>
                                <Row>
                                    <Col className='col-6 mt-3 d-flex flex-column justify-content-end'>
                                        <Label className='text-muted'><FormattedMessage id="SendGrid.GoogleState"/></Label>
                                        <CustomSelect
                                            options={combo}
                                            required
                                            value={combo.find(el => el.value === enabled)}
                                            onChange={this.handleChangeComboSelect.bind(this, "enabled")}
                                        />
                                    </Col>
                                    <Col className='col-6 mt-3'>
                                        <div className='d-flex align-items-center'>
                                            <Label className='text-muted'><FormattedMessage id="SendGrid.ReplacementTag"/></Label>
                                            <Label ><CommonHelper  help={<FormattedMessage id="SendGrid.ReplacementTagHelp" />} id={'ReplacementTagHelp'} placement="top" /></Label>
                                        </div>
                                        
                                        <Input 
                                            type="text"
                                            name="replace"
                                            value={replace}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col className='col-6 mt-3'>
                                        <div className='d-flex align-items-center'>
                                            <Label className='text-muted'><FormattedMessage id="SendGrid.PlainText"/></Label>
                                            <Label className='text-muted'><CommonHelper placement="right" help={<FormattedMessage id="SendGrid.PlainTextHelp" />} id={'PlainTextHelp'} /></Label>
                                        </div>
                                        
                                        <Input 
                                            type="text"
                                            required={html.replace(/<[^>]*>/g, '').trim() !== '' ? false : true}
                                            name="plain"
                                            value={plain}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col className='col-6 mt-3'>
                                        <div className='d-flex align-items-center'>
                                            <Label className='text-muted'><FormattedMessage id="SendGrid.Url"/></Label>
                                            <Label className='text-muted'><CommonHelper placement="right" help={<FormattedMessage id="SendGrid.UrlHelp" />} id={'UrlHelp'} /></Label>
                                        </div>
                                        <Input 
                                            type="text"
                                            name="url"
                                            value={url}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col className='col-12 mt-3'>
                                        <div className='d-flex align-items-center'>
                                            <Label className='text-muted'><FormattedMessage id="SendGrid.Html"/></Label>
                                            <Label className='text-muted'><CommonHelper placement="right" help={<FormattedMessage id="SendGrid.HtmlHelp" />} id={'HtmlHelp'} /></Label>
                                        </div>
                                        <TabContent activeTab="1" className=''>
                                            <TabPane tabId="1" className='border-0 p-0'>
                                                <EditorText
                                                    editorState={editorStateHtml}
                                                    onEditorStateChange={(editorState) => this.onEditorStateChange(1, editorState)}
                                                />
                                            </TabPane>
                                        </TabContent>
                                    </Col>
                                    <Col className='col-12 mt-3'>
                                        <div className='d-flex align-items-center'>
                                            <Label className='text-muted'><FormattedMessage id="SendGrid.LandingPageHtml"/></Label>
                                            <Label className='text-muted'><CommonHelper placement="right" help={<FormattedMessage id="SendGrid.LandingPageHtmlHelp" />} id={'LandingPageHtmlHelp'} /></Label>
                                        </div>
                                        <TabContent activeTab="1" className=''>
                                            <TabPane tabId="1" className='border-0 p-0'>
                                                <EditorText
                                                    editorState={editorStateLanding}
                                                    onEditorStateChange={(editorState) => this.onEditorStateChange(2, editorState)}
                                                />
                                            </TabPane>
                                        </TabContent>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </StyledCard>
                </ModalBody>
            </Modal>
        )
    }
}

export default injectIntl(SubscriptionModal)