import React, { Component } from 'react';

class SurveyJS extends Component {

    render() {
        const { formId } = this.props;
        const baseURL = process.env.REACT_APP_SURVEY_URL;

        return (
            <iframe
                title='SurveyJs'
                style={{ width: '100%', height: '100%', minHeight: '80vh', border: '0' }}
                src={`${baseURL}/${formId}`}
            />
        );
    }
}
export default SurveyJS;