import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Col, Row, Form, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Tag, getListOptions, getFieldsToMapForms } from '../../Base/MapFormsComponents';
import { FormattedMessage } from "react-intl";
import { Link } from 'react-router-dom';
import { getAllReviewFields, getConsentTypeList } from '../../Base/ReferenceDataFunctions';
import { handleNotification } from '../../Base/Notification';
import { ActiveInactiveStatusCombo, BlankCard } from '../../Base/CommonUIComponents';
import { postAPI, getAPI } from '../../Base/API';
import CustomSelect from '../../Base/CustomSelect';
import AddTagsModal from '../../ReviewPro/Surveys/AddTagsModal';

class MapTypeForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            questionsList: [],
            openQuestReviews: [],
            optionsQuestReviews: [],
            consents: getConsentTypeList(),
            fields: getFieldsToMapForms(),
            formMap: {
                active: true,
                fieldId: null,
                reviewMappingFields: []
            },
            fieldKeys: [],
            tags: [],
            addTagsModal: false
        };
    }

    componentDidMount() {
        this.getFormQuestions();
    }

    getFormQuestions = () => {
        const { match } = this.props;
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            };

            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                };
                
                let questions = null, surveyName = null, keyOptions = null, hasPid = false;
                
                if (data.response && data.response.length > 0) {
                    hasPid = data.response[0]?.hidden && data.response[0].hidden?.find((f) => f?.toLowerCase() === "pid") && data.response[0].hidden.find((f) => f?.toLowerCase() === "sharedkey");

                    surveyName = data.response[0].title;

                    questions = data.response[0].fields
                        .reduce((acc, curr) => {
                            if (curr.properties.fields) {
                                acc = acc.concat(curr.properties.fields.map(this.handleQuestion));
                            }
                            else {
                                acc.push(this.handleQuestion(curr));
                            }
                            return acc;
                        }, []);

                    keyOptions = questions ? questions.map(({id, title}) => {
                        return({
                            value: id,
                            label: title 
                        });
                    }) : [];

                    if(hasPid) keyOptions.push({ value: 'pid', label: 'pid' });

                };
                
                this.setState({ block: false, keyOptions, surveyName, questions, hasPid, formId: match.params.id }, () => this.getMap());
                return;
            };
            
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/TypeForm/FormQuestions/${match.params.id}`);
    }

    handleQuestion = (field) => {
        return ({
            title: field.title,
            type: field.type,
            id: field.id,
            tagMappings: [],
            options: field.type === "multiple_choice" ?
                field.properties?.choices.map(({ id, label }) => {
                    return ({
                        value: label,
                        label: label
                    })
                })
                : null
        });
    }

    getMap = () => {
        const { match } = this.props;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    let { fields, consents, questions } = this.state;
                    let fieldsCopy = [], consentsCopy = [];
                    let reviews = getAllReviewFields();

                    if (data.response[0].reviewMappingFields) {
                        data.response[0].reviewMappingFields.forEach(el => {
                            //Review
                            reviews = reviews.filter(r => r.value !== el.reviewMapField);

                            //Mapping fields
                            const questionIndex = questions.findIndex(q => q.id === el.questionMap);

                            if (el.mapType === 'Tag' && questionIndex > -1) {
                                questions[questionIndex].tagMappings.push(el);
                            }
                        });

                        //Combo fields can only be used 1 time each.
                        fields.forEach(el => {
                            if (!data.response[0].reviewMappingFields.some(rmf => rmf.reviewMapField === el.value)) {
                                fieldsCopy.push(el);
                            }
                        });

                        consents.forEach(el => {
                            if (!data.response[0].reviewMappingFields.some(rmf => rmf.reviewMapField === el.value)) {
                                consentsCopy.push(el);
                            }
                        });
                    }
                    else {
                        data.response[0].reviewMappingFields = [];
                    }

                    this.setState({
                        openQuestReviews: reviews.filter(el => el.value === 'Positive' || el.value === 'Negative'),
                        optionsQuestReviews: reviews.filter(el => el.value !== 'Positive' && el.value !== 'Negative'),
                        formMap: data.response[0],
                        reviews,
                        fields: fieldsCopy, consents: consentsCopy,
                        block: false
                    });
                }
                else {
                    const allReviews = getAllReviewFields();

                    this.setState({
                        openQuestReviews: allReviews.filter(el => el.value === 'Positive' || el.value === 'Negative'),
                        optionsQuestReviews: allReviews.filter(el => el.value !== 'Positive' && el.value !== 'Negative'),
                        block: false
                    });
                }

                this.getTagGroups();
            }
            else this.setState({ error: errorMessage, block: false }, this.getTagGroups);
        }, `/api/gms/Review/v1/reviewMapping?listId=0&formId=${match.params.id}`);
    }

    getTagGroups = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                var list = [], allTags = [];

                data.response && data.response.forEach(group => {
                    const obj = { label: group.code, options: [] };

                    group.tag && group.tag.forEach(tag => {
                        obj.options.push({ value: tag.id, label: tag.code });
                        allTags.push({ value: tag.id, label: tag.code });
                    })

                    list.push(obj);
                })

                this.setState({ tags: list, allTags });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/tag/groupDetails?tagType=all`);
    }

    mapForm = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const body = { ...this.state.formMap }
        body.formId = body.formId ? body.formId : this.state.formId;
        body.listId = '0';
        body.provider = 'Typeform';
        body.fieldId = this.state.hasPid ? "pid" : body.fieldId;

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="MapFormModal.FormMapped" />, <FormattedMessage id="generic.success" />);
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Review/v1/reviewMapping`, JSON.stringify({ request: body }));
    }

    handleSelectMap = (combo, question, mapType) => {
        const mappingFields = [...this.state.formMap.reviewMappingFields];
        const index = mappingFields.findIndex(el => el.questionMap === question && el.mapType === mapType);

        let availComboOptions = [];
        const comboOptions = getListOptions(mapType);
        const comboName = mapType === 'Consent' ? 'consents' : 'fields';

        if (index === -1) {
            mappingFields.push({
                reviewMapField: combo ? combo.value : null,
                questionMap: question,
                mapType
            })
        }
        else {
            if (combo) {
                mappingFields[index].reviewMapField = combo.value;
            }
            else {
                mappingFields.splice(index, 1)
            }
        }

        //Combo fields can only be used 1 time each.
        comboOptions.forEach(el => {
            if (!mappingFields.some(rmf => rmf.reviewMapField === el.value)) {
                availComboOptions.push(el);
            }
        })


        this.setState({
            formMap: {
                ...this.state.formMap,
                reviewMappingFields: mappingFields
            },
            [comboName]: availComboOptions
        });
    }

    handleChangeReview = (combo, question) => {
        const mappingFields = [...this.state.formMap.reviewMappingFields];
        const index = mappingFields.findIndex(el => el.questionMap === question && el.mapType === 'Review');
        let openQuestReviews = [], optionsQuestReviews = [];

        if (index === -1) {
            mappingFields.push({
                reviewMapField: combo ? combo.value : null,
                questionMap: question,
                mapType: 'Review'
            })
        }
        else {
            if (combo) {
                mappingFields[index].reviewMapField = combo.value;
            }
            else {
                mappingFields.splice(index, 1)
            }
        }


        //Review fields can only be used 1 time each.
        getAllReviewFields().forEach(el => {
            if (!mappingFields.some(rmf => rmf.reviewMapField === el.value)) {
                if (el.value !== 'Positive' && el.value !== 'Negative') {
                    optionsQuestReviews.push(el);
                }
                else openQuestReviews.push(el);
            }
        })

        this.setState({
            formMap: {
                ...this.state.formMap,
                reviewMappingFields: mappingFields
            },
            optionsQuestReviews, openQuestReviews
        });
    }

    getComboValue = (question, mapType) => {
        let value = '';
        const reviewMapping = this.state.formMap.reviewMappingFields && this.state.formMap.reviewMappingFields.find(rm => rm.questionMap === question && rm.mapType === mapType);

        if (reviewMapping) {
            const options = getListOptions(mapType);

            value = options.find(r => r.value === reviewMapping.reviewMapField);
        }

        return value;
    }

    getReviewOptions = (type) => {
        const optionsQuestReviewsStates = ["rating", "opinion_scale"];

        if (optionsQuestReviewsStates.some(stt => stt === type)) {
            return this.state.optionsQuestReviews;
        }
        else {
            return this.state.openQuestReviews;
        }
    }

    addTagsToMap = (e, tagMaps, questionIdx) => {
        e.preventDefault();
        let mappingFields = [...this.state.formMap.reviewMappingFields];
        const questions = [...this.state.questions];

        mappingFields = mappingFields.concat(tagMaps);

        questions[questionIdx].tagMappings = questions[questionIdx].tagMappings.concat(tagMaps);

        this.setState({
            formMap: {
                ...this.state.formMap,
                reviewMappingFields: mappingFields
            },
            questions
        }, () => this.toggleModal(null));
    }

    removeTagMapping = (e, questionIdx, tagIndex) => {
        e.preventDefault();
        let mappingFields = [...this.state.formMap.reviewMappingFields];
        const questions = [...this.state.questions];

        //Remove from tagMappings (array inside question)
        const tagRemoved = questions[questionIdx].tagMappings.splice(tagIndex, 1);

        //Remove from reviewMappingFields (used in API)
        if (tagRemoved.length > 0) {
            const idx = mappingFields.findIndex(el => el.questionMap === tagRemoved[0].questionMap && el.mapType === 'Tag' && el.tagId === tagRemoved[0].tagId && el.tagValue === tagRemoved[0].tagValue);
            mappingFields.splice(idx, 1);
        }

        this.setState({
            formMap: {
                ...this.state.formMap,
                reviewMappingFields: mappingFields
            },
            questions
        });
    }

    toggleModal = (question, idx) => {
        if (question) {
            question.index = idx
        }

        this.setState(prevState => ({
            addTagsModal: !prevState.addTagsModal,
            selectedQuestion: question
        }));
    }

    handleStatus = (name, combo) => {
        const { formMap } = this.state;
        formMap[name] = combo && combo.value;

        this.setState({ formMap });
    }

    handleChangeSelect = (combo) => {
        this.setState({
            formMap: {
                ...this.state.formMap,
                fieldId: combo ? combo.value : null
            }
        })
    }

    render() {
        const { block, error, questions, addTagsModal, tags, selectedQuestion, consents, surveyName, fields, formMap, keyOptions } = this.state;

        return (
            <div>
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to="/Typeform">
                            <FormattedMessage id="TypeFormMenu.Title"/>
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <Link to="/TypeformForms">
                                <FormattedMessage id="TypeFormMenu.Forms"/>
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem><Link to={`/TypeformAnswers/${this.props.match.params.id}`}> {surveyName || 'Form'} </Link></BreadcrumbItem>
                        <BreadcrumbItem active><FormattedMessage id={`MapFormModal.Map`} /></BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <BlankCard block={block} error={error}>
                    <Form onSubmit={(e) => this.mapForm(e)}>
                        <Row className="mb-3 align-items-center">
                            <Col className='col-3'>
                                <h5> <FormattedMessage id={`MapFormModal.Map`} /> <b>{surveyName}</b> </h5>
                            </Col>
                            <Col className='col-4'>
                                <Row>
                                    <Col className='col-4'>
                                        <FormattedMessage id="MapFormModal.KeyField"/>
                                    </Col>
                                    <Col>
                                        <CustomSelect
                                            isSearchable
                                            placeholder={""}
                                            options={keyOptions}
                                            onChange={(e) => this.handleChangeSelect(e)}
                                            value={formMap.fieldId ? keyOptions.find(k => k.value === formMap.fieldId) : ''}
                                            required
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='col-4'>
                                <Row>
                                    <Col className='col-4'>
                                        <FormattedMessage id="generic.status" />
                                    </Col>
                                    <Col>
                                        <ActiveInactiveStatusCombo
                                            name='active'
                                            onChangeFunc={this.handleStatus}
                                            value={formMap.active}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host" type="submit">
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>

                        {questions ?
                            <div>
                                <Row className="my-4">
                                    <Col sm={3}> <b><FormattedMessage id="MapFormModal.Question" /></b></Col>
                                    <Col> <b><FormattedMessage id="MapFormModal.ReviewField" /></b></Col>
                                    <Col> <b><FormattedMessage id="MapFormModal.Consent" /></b></Col>
                                    <Col> <b><FormattedMessage id="MapFormModal.Field" /></b></Col>
                                    <Col sm={2}> <b><FormattedMessage id="MapFormModal.Tags" /></b> </Col>
                                </Row>

                                {questions.map((question, key) =>
                                    <Row className="mb-3 overbglight align-items-center" key={key}>
                                        <Col sm={3}> {question.title} </Col>

                                        <Col >
                                            <CustomSelect
                                                isSearchable isClearable
                                                placeholder={""}
                                                options={this.getReviewOptions(question.type)}
                                                onChange={(e) => this.handleChangeReview(e, question.id, 'Review')}
                                                value={this.getComboValue(question.id, 'Review')}
                                            />
                                        </Col>

                                        <Col>
                                            {question.type === "yes_no" ?
                                                <CustomSelect
                                                    isSearchable isClearable
                                                    placeholder={""}
                                                    options={consents}
                                                    onChange={(e) => this.handleSelectMap(e, question.id, 'Consent')}
                                                    value={this.getComboValue(question.id, 'Consent')}
                                                />
                                            :''}
                                        </Col>

                                        <Col>
                                            <CustomSelect
                                                isSearchable isClearable
                                                placeholder={""}
                                                options={fields}
                                                onChange={(e) => this.handleSelectMap(e, question.id, 'Field')}
                                                value={this.getComboValue(question.id, 'Field')}
                                            />
                                        </Col>

                                        <Col className="d-flex align-items-center" sm={2}>
                                            {question.tagMappings && question.tagMappings.length > 0 && question.tagMappings.map((tag, idx) =>
                                                <Tag
                                                    key={idx}
                                                    idx={idx}
                                                    tagLabel={this.state.allTags && this.state.allTags.some(t => t.value === tag.tagId) ? this.state.allTags.find(t => t.value === tag.tagId).label : tag.tagId}
                                                    tagValue={tag.tagValue}
                                                    removeTagMapping={(e) => this.removeTagMapping(e, key, idx)}
                                                />
                                            )}

                                            <Button className="btn-sm bg-host-gradient" onClick={() => this.toggleModal(question, key)}>
                                                <i className="fas fa-plus" /> <FormattedMessage id="MapFormModal.NewTag" />
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </div>
                            : ''}
                    </Form>
                </BlankCard>

                {addTagsModal ?
                    <AddTagsModal
                        tags={tags}
                        selectedQuestion={selectedQuestion}
                        addTagModal={addTagsModal}
                        toggleModal={this.toggleModal}
                        addTagsToMap={this.addTagsToMap}
                        title={selectedQuestion.title}
                        questionMapValue={selectedQuestion.id}
                    />
                    : ''}
            </div>
        );
    }
}
export default injectIntl(MapTypeForm)