import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Modal, ModalBody, Input } from 'reactstrap';
import { handleNotification } from "../../../Base/Notification";
import { BlankCard } from '../../../Base/CommonUIComponents';
import { postAPI, deleteAPI } from "../../../Base/API";
import ConfirmActionModal from '../../../Base/ConfirmActionModal';

class TagGroupDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            tagGroup: this.props.tagGroup ? this.props.tagGroup : {},
            deleteModal: false
        };
    }

    saveTagGroup = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var tagGroup = { ...this.state.tagGroup };

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="TagGroupDetails.TagGroupSaved" />, <FormattedMessage id="generic.success" />);
                    this.props.updateTable(data.response[0], false);
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/tag/group`, JSON.stringify({ request: tagGroup }));
    }

    removeTagGroup = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="TagGroupDetails.TagGroupRemoved" />, <FormattedMessage id="generic.success" />);
                    this.toggleDeleteModal();
                    this.props.updateTable({ id: this.state.tagGroup.id }, true);
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/tag/Group/${this.state.tagGroup.id}`);

    }

    handleChange = (e, type) => {
        const { name, value } = e.target;

        this.setState({
            tagGroup: {
                ...this.state.tagGroup,
                [name]: value
            }
        })
    }

    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal
        }));
    }

    render() {
        const { block, error, tagGroup, deleteModal } = this.state;

        return (
            <div>
                <Modal isOpen={this.props.modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                    <Button onClick={this.props.toggleModal} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    <ModalBody>
                        <BlankCard block={block} error={error}>
                            <Form onSubmit={this.saveTagGroup}>
                                <Row className="mb-3">
                                    <Col>
                                        <h5>{this.props.tagGroup ? <FormattedMessage id="TagGroupDetails.EditTagGroup" /> : <FormattedMessage id="TagGroupDetails.AddTagGroup" />}</h5>
                                    </Col>
                                    <Col className="text-right">
                                        {tagGroup && tagGroup.id ?
                                            <Button className="btn-sm btn-host mr-2" onClick={this.toggleDeleteModal}>
                                                <i className="fas fa-trash-alt" />
                                            </Button>
                                        : ''}
                                        <Button className="btn-sm btn-host" type="submit">
                                            <i className="fas fa-save" />
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Label sm={2}> <FormattedMessage id="TagsList.Code" /></Label>
                                            <Col sm={10}>
                                                <Input
                                                    type="text"
                                                    name="code"
                                                    onChange={(e) => this.handleChange(e)}
                                                    value={tagGroup.code ? tagGroup.code : ''}
                                                    required
                                                    maxLength="20"
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Label sm={2}> <FormattedMessage id="TagsList.Description" /></Label>
                                            <Col sm={10}>
                                                <Input
                                                    type="text"
                                                    name="description"
                                                    onChange={(e) => this.handleChange(e)}
                                                    value={tagGroup.description ? tagGroup.description : ''}
                                                    maxLength="500"
                                                    required
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </BlankCard>
                    </ModalBody>
                </Modal>

                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={this.toggleDeleteModal}
                        actionFunction={this.removeTagGroup}
                        text={<FormattedMessage id="TagGroupDetails.ConfirmDeleteText" values={{ tagGroup: tagGroup.code }} />}
                        block={block}
                    />
                : ''}
            </div>
        );
    }
}
export default injectIntl(TagGroupDetails)