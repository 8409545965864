import React, { Component } from 'react';
import { Card, Col, Collapse, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

export class QualityRun extends Component {

    constructor(props) {
        super(props);
        this.state = {
            collapse: false
        };
    }

    toggleCollapse = () => {
        this.setState(prevState => ({ collapse: !prevState.collapse }));
    }

    render() {
        const { qualityRun } = this.props;
        const { collapse } = this.state;

        return (
            <div className="mb-3">
                <Card className="shadow p-3 border-0 mb-2" >
                    <Row className="my-2">
                        <Col className="mx-2">
                            <h5 className="m-0">
                                <FormattedMessage id="QualityRuns.HotelGroup" values={{ name: qualityRun.hotelGroupName }} />
                            </h5>
                        </Col>
                        <Col className="col-2">
                            <FormattedMessage id="QualityRuns.Runs" values={{ runs: qualityRun.runs }} />
                        </Col>
                        <Col className="col-2">
                            <FormattedMessage id="QualityRuns.Success" values={{ success: qualityRun.successTotal }} />
                            <i className="mx-2 text-success fas fa-check" />
                        </Col>
                        <Col className="col-2">
                            <FormattedMessage id="QualityRuns.Error" values={{ error: qualityRun.errorTotal }} />
                            <i className="mx-2 text-danger fas fa-times" />
                        </Col>
                        <Col className="col-1 text-center">
                            <span onClick={this.toggleCollapse} className="pointer">
                                <i className={`fas fa-chevron-${collapse ? `up` : `down`}`} style={{ fontSize: '1.5em' }} />
                            </span>
                        </Col>
                    </Row>
                    <Collapse isOpen={collapse} className="px-3 my-2">
                        <h5 className="text-success mx-2">
                            <FormattedMessage id="QualityRuns.Success" values={{ success: qualityRun.successTotal }} />
                        </h5>
                        {
                            qualityRun.success.map((s, ks) =>
                                <div className="mx-2 mb-3" key={ks}>
                                    <Row className="mb-2 mx-1">
                                        <Col>
                                            <FormattedMessage
                                                id="QualityRun.Template"
                                                values={{ name: <b>{s.name}</b>, entries: <b>{s.dataQualityRuns.length}</b> }}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="pl-3">
                                        <Row className="mb-2 mx-1">
                                            <Col>
                                                <b><FormattedMessage id="QualityRun.RunDate" /></b>
                                            </Col>
                                            <Col>
                                                <b><FormattedMessage id="QualityRun.Duration" /></b>
                                            </Col>
                                        </Row>
                                        {
                                            s.dataQualityRuns.map((q, kq) =>
                                                <Row key={kq} className="mx-1 py-1 overbglight">
                                                    <Col>
                                                        {moment(q.runDate).format("YYYY-MM-DD HH:mm:ss")}
                                                    </Col>
                                                    <Col>
                                                        {q.duration}
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                    </div>
                                    <hr className="mx-1" />
                                </div>
                            )
                        }
                        <h5 className="text-danger mx-2">
                            <FormattedMessage id="QualityRuns.Error" values={{ error: qualityRun.errorTotal }} />
                        </h5>
                        {
                            qualityRun.error.map((e, ke) =>
                                <div className="mx-2 mb-3" key={ke}>
                                    <Row className="mb-2 mx-1">
                                        <Col>
                                            <FormattedMessage
                                                id="QualityRun.Template"
                                                values={{ name: <b>{e.name}</b>, entries: <b>{e.dataQualityRuns.length}</b> }}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="pl-3">
                                        <Row className="mb-2 mx-1">
                                            <Col>
                                                <b><FormattedMessage id="QualityRun.RunDate" /></b>
                                            </Col>
                                            <Col>
                                                <b><FormattedMessage id="QualityRun.Duration" /></b>
                                            </Col>
                                            <Col>
                                                <b><FormattedMessage id="QualityRun.Exception" /></b>
                                            </Col>
                                        </Row>
                                        {
                                            e.dataQualityRuns.map((q, kq) =>
                                                <Row key={kq} className="mx-1 py-1 overbglight">
                                                    <Col>
                                                        {moment(q.runDate).format("YYYY-MM-DD HH:mm:ss")}
                                                    </Col>
                                                    <Col>
                                                        {q.duration}
                                                    </Col>
                                                    <Col>
                                                        {q.exception}
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                    </div>
                                    <hr className="mx-1" />
                                </div>
                            )
                        }
                    </Collapse>
                </Card>
            </div>
        );
    }
}