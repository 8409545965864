import React, { Component } from 'react'
import { Button, Col, Input, Modal, ModalBody, Row, Form } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import { BlankCard } from "../../Base/CommonUIComponents";

class TwilioSubAccountActionsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subAccountId: this.props.selectedSubAccount?.sid,
            subAccountName: this.props.selectedSubAccount?.name
        };
    }

    changeSubAccountName = (e) => {
        this.setState({ subAccountName: e.target.value });
    }

    render() {
        const { block, error, modal } = this.props;
        const { subAccountName, subAccountId } = this.state;

        return (
            <Modal isOpen={modal} fade={false} size={"lg"} style={{ minWidth: 0 }} >
                <Button onClick={_ => this.props.toggleModal(null)} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <Form 
                        onSubmit={
                            (e) => 
                                subAccountId ? 
                                    this.props.editSubAccount(e, subAccountId, subAccountName) 
                                : 
                                    this.props.createSubAccount(e, subAccountName)
                        }
                    >
                        <BlankCard block={block} error={error}>
                            <Row>
                                <Col>
                                    <h5>
                                        <i className="fas fa-comments mr-1" />
                                        {subAccountId ?
                                            <FormattedMessage id="TwilioMessaging.EditAccountTitle"/>
                                        :
                                            <FormattedMessage id="TwilioMessaging.AddAccountTitle"/>
                                        }
                                        
                                    </h5>
                                </Col>
                                <Col className="text-right col-2">
                                    <Button className="btn btn-sm btn-host"
                                        type='submit'
                                        disabled={!subAccountName}
                                    >
                                        <i className="fas fa-save"></i>
                                    </Button>
                                </Col>
                                <Col className='col-12 mt-3'>
                                    <FormattedMessage id="TwilioMessaging.SubAccountName"/>
                                    <Input 
                                        onChange={(e) => this.changeSubAccountName(e)}
                                        value={subAccountName ?? ''}
                                        required
                                    />
                                </Col>
                            </Row>
                        </BlankCard>
                    </Form>
                </ModalBody>
            </Modal>
        )
    }
}

export default injectIntl(TwilioSubAccountActionsModal)
