import React, { Component } from 'react';
import moment from 'moment';
import { Button, Card, Row, Col, CardBody } from 'reactstrap';
import { CommonHelper } from '../Base/CommonUIComponents';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from "../Base/CustomSelect";
import { ErrorAlert } from '../Base/ErrorAlert';
import { Pie, Line } from 'react-chartjs-2';
import { DateRangePicker } from 'react-dates';
import DashboardCard from "../Base/DashboardCard";
import BlockUi from 'react-block-ui';
import { getAPI } from "../Base/API";

class Revenue extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            fromDate: moment().subtract(7, 'days'),
            toDate: moment(),
            revenueByHotel: { "datasets": [{ "label": "Revenue by Hotel", "data": [], "backgroundColor": ['#a645b1', '#bf78c7', '#9163cc', '#744ea4', '#4f2c7c'] }], "labels": [] },
            revenueByPayment: { "datasets": [{ "label": "Revenue by Category", "data": [], "backgroundColor": ['#a645b1', '#bf78c7', '#c39bd5', '#9163cc', '#744ea4', '#4f2c7c'] }], "labels": [] },
            revenueByCharges: { "datasets": [{ "label": "Revenue by Category", "data": [], "backgroundColor": ['#a645b1', '#bf78c7', '#c39bd5', '#9163cc', '#744ea4', '#4f2c7c'] }], "labels": [] },
            revenueByDay: { "drawOnChartArea": true, "datasets": [], "labels": [] },
            revenueByDayCategories: {
                "drawOnChartArea": true, "datasets": [
                    { label: this.props.intl.formatMessage({ id: "Revenue.Payments" }), data: [], fill: false, borderColor: "#9163cc", backgroundColor: "#9163cc" },
                    { label: this.props.intl.formatMessage({ id: "Revenue.Charges" }), data: [], fill: false, borderColor: "#c39bd5", backgroundColor: "#c39bd5" },
                ], "labels": []
            },
            hotelList: [],
            hotelIds: null
        };
    }

    componentDidMount() {
        this.getHotels();
        this.getReservationSummary();
    }

    getHotels = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                var list = [];

                if (data.response && data.response.length > 0) {
                    data.response.forEach(el => list.push({ value: el.hotelId, label: el.name2 }));
                }

                this.setState({ hotelList: list, block: false });
            }
        }, `/api/gms/Hotel/v1/hotel/list`)
    } 

    getReservationSummary = () => {
        this.setState({ block: true });

        var params = '';
        this.state.hotelIds && this.state.hotelIds.forEach(hotel => { params += `&hotelIds=${hotel}` })

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.calculateData(data.response);
                this.setState({ error: [], block: false });
            }
        }, `/api/gms/DashBoard/RevenueSummary?fromDate=${moment(this.state.fromDate).format('YYYY-MM-DD')}&toDate=${moment(this.state.toDate).format('YYYY-MM-DD')}` + params)
    }

    calculateData = (data) => {
        const { revenueByHotel, revenueByPayment, revenueByCharges, revenueByDay, revenueByDayCategories } = this.state;
        var revenueHotel = new Map(), revenuePayment = new Map(), revenueCharges = new Map();
        const colors = ['#a645b1', '#bf78c7', '#9163cc', '#744ea4', '#4f2c7c'], dates = [], dataByDay = [];

        data && data.forEach((hotel, i) => {
            revenueHotel.set(hotel.hotelName, hotel.amount);
            dataByDay[i] = { 'hotel': hotel.hotelName, values: [] };

            hotel && hotel.revenueByDay.forEach((date, idx) => {
                if (!dates.includes(moment(date.date).format("MMM DD, YYYY"))) {
                    dates.push(moment(date.date).format("MMM DD, YYYY"));
                }

                dataByDay[i].values[idx] = date;

                date.revenueByType && date.revenueByType.forEach(type => {
                    if (type.isPayment) {
                        revenuePayment.get(type.code) === undefined ? revenuePayment.set(type.code, type.amount) : revenuePayment.set(type.code, type.amount + revenuePayment.get(type.code));
                    }
                    else {
                        revenueCharges.get(type.code) === undefined ? revenueCharges.set(type.code, type.amount) : revenueCharges.set(type.code, type.amount + revenueCharges.get(type.code));
                    }
                })
            })
        });

        //Pie - Revenue by hotel
        var values = [];
        revenueByHotel.labels = [...revenueHotel.keys()];

        revenueByHotel.labels.forEach(item => { values.push(parseFloat(revenueHotel.get(item)).toFixed(2)); });

        revenueByHotel.datasets[0].data = values;

        //Pie - Revenue by charges
        var values = [];
        revenueByCharges.labels = [...revenueCharges.keys()];

        revenueByCharges.labels.forEach(item => { values.push(parseFloat(revenueCharges.get(item)).toFixed(2)); });

        revenueByCharges.datasets[0].data = values;

        //Pie - Revenue by payment
        var values = [];
        revenueByPayment.labels = [...revenuePayment.keys()];

        revenueByPayment.labels.forEach(item => { values.push(parseFloat(revenuePayment.get(item) * (-1)).toFixed(2)); });

        revenueByPayment.datasets[0].data = values;


        //Line - Revenue by day (hotel)
        revenueByDay.labels = dates.sort((a, b) => moment(a) - moment(b));
        const hotels = [...revenueHotel.keys()];
        const lineDatasets = [];

        hotels.forEach((hotel, idx) => {
            var obj = { label: hotel, data: [], borderColor: colors[idx], backgroundColor: colors[idx], fill: false }

            revenueByDay.labels.forEach((date, i) => {
                dataByDay.forEach(e => {
                    e.values && e.values.forEach(value => {
                        if (hotel === e.hotel && moment(value.date).isSame(moment(date))) {
                            obj.data[i] = value.amount;
                        }
                    })
                })
            })
            lineDatasets.push(obj);
        });

        revenueByDay.datasets = lineDatasets;

        //Line - Revenue by day (categories)
        revenueByDayCategories.labels = dates.sort((a, b) => moment(a) - moment(b));
        const paymentData = [], chargesData = [];

        revenueByDayCategories.labels.forEach((date, i) => {
            dataByDay.forEach(el => {
                el.values && el.values.forEach(data => {
                    paymentData[i] = paymentData[i] ? paymentData[i] : 0;
                    chargesData[i] = chargesData[i] ? chargesData[i] : 0;

                    data.revenueByType && data.revenueByType.forEach(type => {
                        if (moment(data.date).isSame(moment(date))) {
                            if (type.isPayment) {
                                paymentData[i] += type.amount * (-1);
                            }
                            else {
                                chargesData[i] += type.amount;
                            }
                        }
                    })
                })
            });
        })
        revenueByDayCategories.datasets[0].data = paymentData;
        revenueByDayCategories.datasets[1].data = chargesData;

        this.setState({ revenueByHotel, revenueByCharges, revenueByPayment, revenueByDay, revenueByDayCategories });
    }
    
    handleSelect = (evt, combo) => {
        var targetValue = '';

        if (Array.isArray(combo)) {
            targetValue = combo.map(el => el.value);
        } else {
            targetValue = combo ? combo.value : null;
        }

        this.setState({ [evt]: targetValue })
    }

    render() {
        const { block, error } = this.state;

        let options = {
            legend: {
                position: "top"
            }
        }

        let optionsPie = {
            legend: {
                position: "right"
            }
        }
        
        return (
            <div>
                <BlockUi tag="div" blocking={block}>
                    <ErrorAlert error={error} />

                    <Row className="my-1">
                        <Col>
                            <Card className="shadow ">
                                <CardBody>
                                    <Row className="mb-2">
                                        <Col className="col-3 pr-1">
                                            <CustomSelect
                                                icon={'icon-icon-hotel'}
                                                isClearable isSearchable isMulti
                                                placeholder={"Hotel"}
                                                options={this.state.hotelList}
                                                onChange={this.handleSelect.bind(this, 'hotelIds')}
                                            />
                                        </Col>
                                        <Col>
                                            <DateRangePicker
                                                startDate={moment(this.state.fromDate)}
                                                endDate={moment(this.state.toDate)}
                                                onDatesChange={({ startDate, endDate }) => this.setState({ fromDate: startDate, toDate: endDate })}
                                                isOutsideRange={() => false}
                                                endDateId="your_unique_end_date_id_announcement"
                                                startDateId="your_unique_start_date_id_announcement"
                                                focusedInput={this.state.focusedInput}
                                                onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                                small={true}
                                                numberOfMonths={1}
                                                showDefaultInputIcon={true}
                                            />
                                        </Col>
                                        <Col>
                                            <CommonHelper help='TODO' id="dashboardHelp" />
                                            <Button className="float-right btn btn-sm btn-host" onClick={this.getReservationSummary}>
                                                <i className="fa fa-search" />
                                            </Button>
                                        </Col>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mb-2">
                        <div className="container-fluid d-flex p-0">
                            <Col className="col-4 pr-0">
                                <DashboardCard header="Revenue.RevenueByHotel" block={block} >
                                    <Pie data={this.state.revenueByHotel} height={'100px'} options={optionsPie} />
                                </DashboardCard>
                            </Col>

                            <Col className="col-4">
                                <DashboardCard header="Revenue.RevenueByCharges" block={block}  >
                                    <Pie data={this.state.revenueByCharges} height={'100px'} options={optionsPie} />
                                </DashboardCard>
                            </Col>

                            <Col className="col-4">
                                <DashboardCard header="Revenue.RevenueByPayment" block={block}  >
                                    <Pie data={this.state.revenueByPayment} height={'100px'} options={optionsPie} />
                                </DashboardCard>
                            </Col>
                        </div>
                    </Row>

                    <Row className="mb-2">
                        <div className="container-fluid d-flex p-0">
                            <Col>
                                <DashboardCard header="Revenue.RevenueByDay" block={this.state.blockChannel}  >
                                    <Line data={this.state.revenueByDay} height={'40px'} options={options} />
                                </DashboardCard>
                            </Col>
                        </div>
                    </Row>
                    <Row className="mb-2">
                        <div className="container-fluid d-flex p-0">
                            <Col>
                                <DashboardCard header="Revenue.ChargesVsPayments" block={this.state.blockChannel}  >
                                    <Line data={this.state.revenueByDayCategories} height={'40px'} options={options} />
                                </DashboardCard>
                            </Col>
                        </div>
                    </Row>
                </BlockUi>
            </div>
        )
    }
}

export default injectIntl(Revenue);