import React, { Component } from 'react'
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import { Badge, Breadcrumb, BreadcrumbItem, Button, Card, Col, CustomInput, Form, Input, Label, Modal, ModalBody, Row, UncontrolledTooltip } from 'reactstrap';
import { getAPI, postAPI } from '../../Base/API';
import { CommonHelper, StyledCard } from '../../Base/CommonUIComponents';
import ConfirmActionModal from '../../Base/ConfirmActionModal';
import CustomSelect from '../../Base/CustomSelect';
import { handleNotification } from '../../Base/Notification';

class NumberManagement extends Component {

    constructor (props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            whatsAppPhones: [],
            registerModal: false,
            verifyModal: false,
            setPinModal: false,
            phoneId: null,
            pin: null,
            verificationCode: null,
            newPin: null,
            unregisterModal: false,
            codeSent: false
        };
    }
  
    componentDidMount() {
      this.getPhones();
    }
  
    getPhones = () => {
      getAPI(result => {
        const { data, error } = result;
        const errorMessage = [];
  
        if (error) {
            errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
            this.setState({ error: errorMessage, block: false });
            return;
        }
        if (data) {
            if (data.errors && data.errors.length > 0) {
                this.setState({ block: false }, () => handleNotification(data));
            }
            else if(data.response){
                this.setState({ whatsAppPhones: data.response[0].data, block: false });
            }
        }
        else {
            this.setState({ error: errorMessage, block: false });
        }
      }, `/api/gms/WhatsApp/v1/phoneNumber`)  
    }

    toggleModal = () => {

    }

    registerNumber = (e) => {
        e.preventDefault();

        const { phoneId, pin } = this.state;
        
        this.setState({ block: true }, () =>
            postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
        
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    this.setState({ block: false }, () => handleNotification(data));
                }
                else{
                    this.setState({ block: false, registerModal: false }, () =>
                        handleNotification(data, <FormattedMessage id="WhatsApp.CodeVerified"/>)
                    );
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
            }, `/api/gms/WhatsApp/v1/phoneNumber/${phoneId}/register${pin ? `?pin=${pin}` : ''}`)  
        );
    }

    openRegisterModal = (e, phoneId) => {
        e.preventDefault();
        this.setState({ registerModal: true, phoneId });
    }
    
    toogleRegisterModal = (e) => {
        e.preventDefault();
        this.setState(prev => ({ registerModal: !prev.registerModal }));
    }

    toogleVerifyModal = (e) => {
        e.preventDefault();
        this.setState(prev => ({ verifyModal: !prev.verifyModal }));
    }

    handleInput = (e) => {
        const { value, name } = e.target;

        this.setState({ [name]: value });
    }

    sendCode = (e) => {
        e.preventDefault();
        const { phoneId, codeMethod, language } = this.state;

        this.setState({ block: true }, () =>
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
        
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        this.setState({ block: false }, () => handleNotification(data));
                    }
                    else{
                        this.setState({ codeSent: true, block: false });
                    }
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/WhatsApp/v1/phoneNumber/${phoneId}/requestcode?language=${language}&codeMethod=${codeMethod}`)
        );
    }

    verifyCode = (e) => {
        e.preventDefault();
        const { code, phoneId } = this.state;

        this.setState({ block: true }, () =>
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
        
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        this.setState({ block: false }, () => handleNotification(data));
                    }
                    else if(data.response){
                        this.setState({ block: false, verifyModal: false }, () =>
                            handleNotification(data, <FormattedMessage id="WhatsApp.CodeVerified"/>)
                        );
                    }
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/WhatsApp/v1/phoneNumber/${phoneId}/verifyCode/${code}`)
        );
    }

    unresgisterPhone = (e) => {
        e.preventDefault();
        const { phoneId } = this.state;

        this.setState({ block: true }, () =>
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
        
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        this.setState({ block: false }, () => handleNotification(data));
                    }
                    else if(data.response){
                        this.setState({ block: false }, () =>
                            handleNotification(data, <FormattedMessage id="WhatsApp.UnregisterSuccess"/>)
                        );
                    }
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/WhatsApp/v1/phoneNumber/${phoneId}/deregister`)
        );
    }

    setNewPin = (e) => {
        e.preventDefault();
        const { newPin, phoneId } = this.state;

        this.setState({ block: true }, () =>
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
        
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        this.setState({ block: false }, () => handleNotification(data));
                    }
                    else if(data.response){
                        this.setState({ block: false }, () =>
                            handleNotification(data, <FormattedMessage id="WhatsApp.NewPINSuccess"/>)
                        );
                    }
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/WhatsApp/v1/phoneNumber/${phoneId}/TFA?pin=${newPin}`)
        );
    }

    openSetPinModal = (e, phoneId) => {
        e.preventDefault();
        this.setState({ setPinModal: true, phoneId });
    }

    toggleSetPinModal = (e) =>{
        e.preventDefault();
        this.setState(prev => ({ setPinModal: !prev.setPinModal }));
    }

    toggleunregisterModal = (e) => {
        e.preventDefault();

        this.setState(prev => ({ unregisterModal: !prev.unregisterModal }));
    }

    openUnregisterModal = (e, phoneId) => {
        e.preventDefault();
        this.setState({ unregisterModal: true, phoneId });
    }

    openVerifyModal = (e, phoneId) => {
        e.preventDefault();
        this.setState({ verifyModal: true, phoneId });
    }

    render() {
        const { whatsAppPhones, error, block, registerModal, verifyModal, phoneId, setPinModal, unregisterModal, codeSent } = this.state;
        const { intl } = this.props;

        const selectedPhone = whatsAppPhones?.find(({id}) => id === phoneId);

        const verificationChannels = [
            { value: 'SMS', label: intl.formatMessage({ id: "WhatsApp.SMS" }) },
            { value: 'VOICE', label: intl.formatMessage({ id: "WhatsApp.VOICE" }) },
        ]

        const methodLangs = [
            { value: 'pt', label: intl.formatMessage({ id: "WhatsApp.pt" }) },
            { value: 'en', label: intl.formatMessage({ id: "WhatsApp.en" }) },
            { value: 'fr', label: intl.formatMessage({ id: "WhatsApp.fr" }) },
            { value: 'de', label: intl.formatMessage({ id: "WhatsApp.de" }) },
            { value: 'es', label: intl.formatMessage({ id: "WhatsApp.es" }) },
        ]

        return (
            <StyledCard error={error} block={block}>
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to="/WhatsAppConfig"> 
                                <FormattedMessage id="NavMenu.WhatsAppConfig"/>
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            <FormattedMessage id="WhatsApp.NumberManagement"/>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Row className='mt-2'>
                    <Col>
                        {whatsAppPhones?.map((phone, key) => 
                            <Card key={key} className="border-0 mb-2 p-3 shadow">
                                <Row>
                                    <Col>
                                        <div>
                                            <h5>
                                                <b>{phone.verified_name}</b>
                                            </h5>
                                        </div>
                                        <div>
                                            {phone.id}
                                        </div>
                                    </Col>
                                    <Col style={{ display: 'flex', alignItems: 'center' }}>
                                        <div>
                                            <div>
                                                <b><FormattedMessage id="WhatsApp.DisplayPhoneNumber"/>:</b>
                                            </div>
                                            <div style={{ fontSize: '1.2em' }}>
                                                {phone.display_phone_number}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col style={{ display: 'flex', alignItems: 'center' }}>
                                        <div>
                                            <div>
                                                <b><FormattedMessage id="WhatsApp.Status"/>:</b>
                                            </div>
                                            <div style={{ fontSize: '1.2em' }}>
                                                {phone.code_verification_status}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col style={{ alignItems: 'flex-start', justifyContent: 'end' }} className="text-right">
                                        <div><Badge color="primary" className="p-1"> {phone.quality_rating}</Badge></div>
                                    </Col>
                                    <Col style={{ display: 'flex', justifyContent: 'end', columnGap: '0.5rem', alignItems: 'start' }}>
                                        <div>
                                            <Button id={`RegisterPhone${key}`} className="btn- btn-host btn-small btn-sm" onClick={e => this.openRegisterModal(e, phone.id)}>
                                                <i className="fas fa-sign-in-alt"/>
                                                <UncontrolledTooltip placement="bottom" target={`RegisterPhone${key}`}>
                                                    <FormattedMessage id="WhatsApp.RegisterPhoneNumber" />
                                                </UncontrolledTooltip>
                                            </Button>
                                        </div>
                                        <div>
                                            <Button id={`UnregisterPhone${key}`} title='Unregister' className="btn- btn-host btn-small btn-sm" onClick={e => this.openUnregisterModal(e, phone.id)}>
                                                <i className="fas fa-sign-out-alt"/>
                                                <UncontrolledTooltip placement="bottom" target={`UnregisterPhone${key}`}>
                                                    <FormattedMessage id="WhatsApp.UnregisterPhoneNumber" />
                                                </UncontrolledTooltip>
                                            </Button>
                                        </div>
                                        <div>
                                            <Button id={`VerifyPhone${key}`} className="btn- btn-host btn-small btn-sm" onClick={e => this.openVerifyModal(e, phone.id)}>
                                                <i className="fas fa-id-card-alt"/>
                                                <UncontrolledTooltip placement="bottom" target={`VerifyPhone${key}`}>
                                                    <FormattedMessage id="WhatsApp.VerifyPhoneNumber"/>
                                                </UncontrolledTooltip>
                                            </Button>
                                        </div>
                                        <div>
                                            <Button id={`SetPin${key}`} className="btn- btn-host btn-small btn-sm" onClick={e => this.openSetPinModal(e, phone.id)}>
                                                <i className="fas fa-key"/>
                                                <UncontrolledTooltip placement="bottom" target={`SetPin${key}`}>
                                                    <FormattedMessage id="WhatsApp.SetPin"/>
                                                </UncontrolledTooltip>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        )}
                    </Col>
                </Row>
                {registerModal ?
                    <Modal className="modal-md" style={{ minWidth: 0, marginTop: '15%' }} isOpen={registerModal} toggle={this.toogleRegisterModal}>
                        <BlockUi blocking={block}>
                            <ModalBody>
                                <Form onSubmit={this.registerNumber}>
                                    <Row>
                                        <Col>
                                            <h5>
                                                <FormattedMessage id="WhatsApp.Register"/>
                                                <span className='ml-2'>
                                                    <b>{selectedPhone?.verified_name}</b>
                                                </span>
                                            </h5>
                                        </Col>
                                        <Col className='col-2 text-right'>
                                            <Button type='submit' className="btn- btn-host btn-small btn-sm">
                                                <i className="fas fa-save"/>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <CommonHelper
                                                help={intl.formatMessage({ id: "WhatsApp.RegisterHelp" })}
                                                id={'RegisterHelp'}
                                            />
                                            <div>
                                                <FormattedMessage id="WhatsApp.Pin"/>
                                            </div>
                                            <div>
                                                <Input
                                                    required={true}
                                                    type="text"
                                                    minLength={'6'}
                                                    maxLength={'6'}
                                                    name="pin"
                                                    onChange={this.handleInput}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </ModalBody>
                        </BlockUi>
                    </Modal>
                :''}
                {unregisterModal ? 
                    <ConfirmActionModal
                        block={block}
                        error={error}
                        text={intl.formatMessage({ id: "WhatsApp.UnregisterWarning" })}
                        toggleModal={this.toggleunregisterModal}
                        modal={unregisterModal}
                        actionFunction={this.unresgisterPhone}
                    />
                :''}
                {verifyModal ?
                    <Modal className="modal-md" style={{ minWidth: 0, marginTop: '15%' }} isOpen={verifyModal} toggle={this.toogleVerifyModal}>
                        <BlockUi blocking={block}>
                            <ModalBody>
                                <Form onSubmit={e => {if(codeSent) this.verifyCode(e); else this.sendCode(e)}}>
                                    <Row>
                                        <Col>
                                            <h5>
                                                <FormattedMessage id="WhatsApp.ValidatePhoneCode"/>
                                            </h5>
                                        </Col>
                                        <Col className='col-2 text-right'>
                                            <Button type='submit' className="btn- btn-host btn-small btn-sm">
                                                <i className="fas fa-save"/>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {codeSent ?
                                            <Col>
                                                <div>
                                                    <FormattedMessage id="WhatsApp.VerificationCode"/>
                                                </div>
                                                <div>
                                                    <Input
                                                        type="text"
                                                        minLength={'6'}
                                                        required={true}
                                                        maxLength={'6'}
                                                        name="verificationCode"
                                                        onChange={this.handleInput}
                                                    />
                                                </div>
                                            </Col>
                                        :
                                            <>
                                                <Col>
                                                    <div>
                                                        <FormattedMessage id="WhatsApp.codeMethod"/>
                                                    </div>
                                                    <div>
                                                        <CustomSelect
                                                            options={verificationChannels}
                                                            required={true}
                                                            onChange={e => this.handleInput({target:{value:e?.value, name: "codeMethod"}})}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div>
                                                        <FormattedMessage id="WhatsApp.language"/>
                                                    </div>
                                                    <div>
                                                        <CustomSelect
                                                            options={methodLangs}
                                                            required={true}
                                                            onChange={e => this.handleInput({target:{value:e?.value, name: "language"}})}
                                                        />
                                                    </div>
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                </Form>
                            </ModalBody>
                        </BlockUi>
                    </Modal>
                :''}
                {setPinModal ?
                    <Modal className="modal-md" style={{ minWidth: 0, marginTop: '15%' }} isOpen={setPinModal} toggle={this.toggleSetPinModal}>
                        <BlockUi blocking={block}>
                            <ModalBody>
                                <Form onSubmit={this.setNewPin}>
                                    <Row>
                                        <Col>
                                            <h5>
                                                <FormattedMessage id="WhatsApp.SetPin"/>
                                            </h5>
                                        </Col>
                                        <Col className='col-2 text-right'>
                                            <Button type='submit' className="btn- btn-host btn-small btn-sm">
                                                <i className="fas fa-save"/>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <CommonHelper
                                                help={intl.formatMessage({ id: "WhatsApp.SetPinHelp" })}
                                                id={'SetPinHelp'}
                                            />
                                            <div>
                                                <FormattedMessage id="WhatsApp.VerificationCode"/>
                                            </div>
                                            <div>
                                                <Input
                                                    type="text"
                                                    minLength={'6'}
                                                    maxLength={'6'}
                                                    name="newPin"
                                                    onChange={this.handleInput}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </ModalBody>
                        </BlockUi>
                    </Modal>
                :''}
            </StyledCard>
        )
    }
}

export default injectIntl(NumberManagement);