import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { BlankCard } from '../../Base/CommonUIComponents';
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { Badge, Button, Col, Collapse, Form, Row, UncontrolledCollapse } from 'reactstrap';
import CustomSelect from '../../Base/CustomSelect';
import DetailSearchSection from './DetailSearchSection';
import DetailSearchTags from './DetailSearchTags';
import { getProfileType } from '../../Base/ReferenceDataFunctions';
import countryList from 'react-select-country-list';
import { CustomTable } from '../../Base/CustomTable';
import moment from 'moment-timezone';

class DetailProfileSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            profiles: null,
            searchSections: [
                {
                    sectionName: 'ProfileData',
                    options: [
                        { name: 'IsMaster', type: 'select', isClearable: false, required: true },
                        { name: 'ProfileType', type: 'select', isClearable: true, required: false },
                        { name: 'Nationality', type: 'select', isClearable: true, required: false },
                        { name: 'ProfileMembership', type: 'select', isClearable: true, required: false },
                    ]
                }
            ],
            pageIndex: 0,
            pageSize: 20,
            totalItems: 0,
            tagGroups: [],
            appliedFilters: []
        }
    }

    componentDidMount(){
        this.populateSearchSections();
    }

    populateSearchSections = () => {
        const { searchSections } = this.state;
        const { intl } = this.props;
        
        searchSections.forEach((section) =>{
            section.options.forEach((field) => {
                if(field.name === 'IsMaster')
                    field.options = ['true', 'false'].map((c) => ({ value: c, label: intl.formatMessage({ id: `DetailProfileSearch.${c}` }) }));;
                if(field.name === 'ProfileType')
                    field.options = getProfileType(intl);
                if(field.name === 'Nationality')
                    field.options = countryList().getData();
                if(field.name === 'ProfileMembership')
                    field.options = ['with', 'without'].map((c) => ({ value: c, label: intl.formatMessage({ id: `DetailProfileSearch.${c}` }) }));
            });
        });

        this.setState({ searchSections }, this.getTagList());
    }

    getTagList = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response) {
                const tagGroups = data.response.map(resp => {
                    const tags = resp.tag.map(t => { const tag = { value: t.id, label: t.code }; return tag; });
                    const obj = {
                        label: resp.code,
                        options: tags
                    };

                    return obj;
                });
                this.setState({ tagGroups: tagGroups, error: errorMessage, block: false });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }

        }, `api/gms/Profile/v1/tag/groupDetails?tagType=all`);
    }
    
    onKeyPressed(e) {
        if(e && e.code === 'Enter') this.doSearch();
    }

    doSearch = (e) => {
        e.preventDefault();
        this.setState({ pageIndex: 0, block: true }, () => this.getProfiles());
    }

    getProfiles = () => {
        const { pageIndex, pageSize, appliedFilters } = this.state;

        let params = `?page=${pageIndex}&pageSize=${pageSize}`;

        params += appliedFilters.map((f) => `&${f.name}=${f.value}`).join('');

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ error: errorMessage, block: false }));
                }
                else {
                    if (data.response?.length > 0) {
                        data.response.filter(profile => !profile.isMaster && profile.masterId).map(profile => profile.linked = true);
                    }

                    this.setState({ profiles: data.response, error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Profile/v1/DetailSearch` + params)
    }

    handleChange = (sectionName, optionKey, value) => {
        const { searchSections, appliedFilters } = this.state;

        const sectionOption = searchSections.find((s) => s.sectionName === sectionName).options[optionKey];
        const selectOption = sectionOption.options.find((o) => value === undefined ? o.selected : o.value === value);

        const appliedIdx = appliedFilters.findIndex((f) => f?.name === sectionOption.name);

        if(appliedIdx > -1){
            if(appliedFilters[appliedIdx].value === value || value === undefined){ //Remove
                appliedFilters.splice(appliedIdx, 1);
                selectOption.selected = false;
            }
            else{ // Change
                const oldOption = sectionOption.options.find((o) => o.value === appliedFilters[appliedIdx].value);
                appliedFilters[appliedIdx].value = value;
                appliedFilters[appliedIdx].label = selectOption.label;
                selectOption.selected = true;
                oldOption.selected = false;
            }
        }
        else{ // Create
            selectOption.selected = true;
            appliedFilters.push({ name: sectionOption.name, value, label: selectOption.label });
        }

        this.setState({ appliedFilters, searchSections });
    }

    handleTag = (value) => {
        const { tagGroups, appliedFilters } = this.state;

        const appliedIdx = appliedFilters.findIndex((f) => f?.name === "tag");
        const tag = tagGroups.flatMap(({options}) => options.find((o) => o.value === value)).filter(v => v)[0];

        if(appliedIdx > -1){
            if(appliedFilters[appliedIdx].value === value){ //Remove
                appliedFilters.splice(appliedIdx, 1);
                tag.selected = false;
            }
            else{ // Change
                const oldTag = tagGroups.flatMap(({options}) => options.find((o) => o.value === appliedFilters[appliedIdx].value)).filter(v => v)[0];
                appliedFilters[appliedIdx].value = value;
                appliedFilters[appliedIdx].label = tag.label;
                tag.selected = true;
                oldTag.selected = false;
            }
        }
        else{ // Create
            tag.selected = true;
            appliedFilters.push({ name: "tag", value, label: tag.label });
        }

        this.setState({ appliedFilters, tagGroups });        
    }

    removeFilter = (f) => {
        const { tagGroups, searchSections, appliedFilters } = this.state;

        const idx = appliedFilters.findIndex((a) => a.name === f.name && a.value === f.value);

        appliedFilters.splice(idx, 1);

        if(f.name === "tag")
            tagGroups.flatMap(({options}) => options.find((o) => o.value === f.value)).filter(v => v)[0].selected = false;
        else{
            const sectionOption = searchSections.flatMap(({options}) => options.find(({name}) => name === f.name))?.filter(s => s)[0];
            const selectedOption = sectionOption?.options.find(({value}) => value === f.value);
            selectedOption.selected = false;
        }

        this.setState({ appliedFilters, searchSections, tagGroups });
    }
    
    clearFilters = () => {
        const { tagGroups, searchSections, appliedFilters } = this.state;

        appliedFilters.forEach((f) => {
            if(f.name === "tag")
                tagGroups.flatMap(({options}) => options.find((o) => o.value === f.value)).filter(v => v)[0].selected = false;
            else{
                const sectionOption = searchSections.flatMap(({options}) => options.find(({name}) => name === f.name))?.filter(s => s)[0];
                const selectedOption = sectionOption?.options.find(({value}) => value === f.value);
                selectedOption.selected = false;
            }
        });

        this.setState({ appliedFilters: [], searchSections, tagGroups });
    }

    render() {
        const { block, error, searchSections, tagGroups, appliedFilters, profiles, pageIndex, pageSize } = this.state;
        const { intl, history } = this.props;

        const columns = [
            {
                dataField: 'type',
                text: intl.formatMessage({ id: "SearchProfile.Type" }),
                formatter: (cell, row) => {
                    if (cell) {
                        const type = getProfileType(intl).find(el => el.value === cell);

                        return <div className="text-muted text-center"> {type ? <i className={type.icon} /> : '-'} </div>
                    }
                    return '';
                },
                style: { width: '50px' }
            },
            {
                dataField: 'firstName',
                text: intl.formatMessage({ id: "SearchProfile.FirstName" })
            },
            {
                dataField: 'lastName',
                text: intl.formatMessage({ id: "SearchProfile.LastName" })
            },
            {
                dataField: 'middleName',
                text: intl.formatMessage({ id: "ProfileDetails.OtherNames" })
            },
            {
                dataField: 'birthDate',
                text: intl.formatMessage({ id: "SearchProfile.BirthDate" }),
                formatter: (cell, row) => cell ? moment(cell).format("YYYY-MM-DD") : '',
                style: { width: '180px' }
            },
            {
                dataField: 'gender',
                text: intl.formatMessage({ id: "SearchProfile.Gender" }),
                style: { width: '90px' }
            },
            {
                dataField: 'language',
                text: intl.formatMessage({ id: "SearchProfile.Language" }),
                style: { width: '90px' }
            },
            {
                dataField: 'nationality',
                text: intl.formatMessage({ id: "SearchProfile.Nationality" }),
                formatter: (cell, row) => cell ? <div><i className={('ml-2 mr-2 flag-icon flag-icon-') + (cell.toLowerCase())}></i> {countryList().getLabel(cell)} </div> : '',
                style: { width: '250px', paddingRight: '30px' }
            },
            {
                dataField: 'membershipCard',
                text: intl.formatMessage({ id: "SearchProfile.MembershipCard" }),
                formatter: (cell, row) => {
                    return cell && cell.map(el =>
                        <div className="text-ellipsis" style={{ fontSize: '13px' }}>
                            {el.cardTypeCode} - {el.cardNumber}
                        </div>
                    )
                }
            },
            {
                dataField: 'isMaster',
                text: 'Master',
                formatter: (cell, row) => cell ? <Badge color='primary'> Master </Badge> : row.linked ? <Badge className=' color-white bg-cyan'> Linked </Badge> : ''
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                history.push({ pathname: `/ProfileDetails/${row.id}` })
            }
        };
        
        return (
            <BlankCard icon="" block={block} error={error} onKeyDown={this.onKeyPressed} tabIndex="0">
                <Form onSubmit={this.doSearch}>
                    <Row>
                        <Col className='d-flex align-items-center'>
                            <h5 className='m-0'>
                                <i className="fas fa-user-astronaut mr-2"></i>
                                <FormattedMessage id="DetailProfileSearch.DetailProfileSearch"/>
                            </h5>
                        </Col>
                        <Col className='col-2 text-right'>
                            <Button className="btn btn-host btn-sm" type='submit' disabled={!appliedFilters?.find(({name}) => name === "tag")}>
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>
                    <hr/>
                    <div className='mt-4'>
                        {appliedFilters.length > 0 ?
                            <>
                                <div>
                                    <div>
                                        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={this.clearFilters}>
                                            <FormattedMessage id="DetailProfileSearch.ClearFilters"/>
                                            <i className="fas fa-trash ml-1"></i>
                                        </span>
                                    </div>
                                    <div className='d-flex align-items-center mt-2 flex-wrap'>
                                        {appliedFilters.map((f, key) =>
                                            <div key={key} className='shadow px-3 py-2   d-flex align-items-center mr-2 mb-2' style={{ borderRadius: '1000px'}}>
                                                <span className='mr-2 cursor-pointer' style={{ color: '#3b3b3b' }} onClick={_ => this.removeFilter(f)}>
                                                    <i className="fas fa-times"></i>
                                                </span>
                                                <div>
                                                    <FormattedMessage id={`DetailProfileSearch.${f.name}`}/>:
                                                    <span className='ml-1'>
                                                        <b>{f.label}</b>
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <hr/>
                            </>
                        :''}
                        <Row>
                            <Col className='col-4'>
                                {searchSections.map((section, key) => 
                                    <DetailSearchSection
                                        {...section}
                                        key={key}
                                        onChange={this.handleChange}
                                    />
                                )}
                                <DetailSearchTags
                                    tagGroups={tagGroups}
                                    handleTag={this.handleTag}
                                    onChange={this.handleChange}
                                />
                            </Col>
                            <Col>
                                {profiles ?
                                    <div className="veryCoolScrollBar tableWithScroll">
                                        <CustomTable
                                            data={profiles}
                                            columns={columns}
                                            selectRow={selectRow}
                                            page={pageIndex + 1}
                                            sizePerPage={pageSize}
                                            rowStyle={{ cursor: 'pointer' }}
                                            hasPagination={false}
                                        />
                                    </div>
                                :''}
                            </Col>
                        </Row>
                    </div>
                </Form>
            </BlankCard>
        )
    }
}

export default injectIntl(DetailProfileSearch)