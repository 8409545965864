import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Col, Card, CardBody, Table, Row, CardHeader } from 'reactstrap';
import { authContext } from '../../Utils/AdalConfig';
import { getAPI } from "../Base/API";
import BlockUi from 'react-block-ui';
import { FormattedMessage } from 'react-intl';
import { ErrorAlert } from '../Base/ErrorAlert';
import 'react-block-ui/style.css';

export class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            user: {}
        };
    }

    componentDidMount() {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ user: data, property: data.defaultProperty });
            }
            this.setState({ error: errorMessage, block: false });
        }, '/api/User/v1/User');
    }

    render() {
        const { block, error, user } = this.state;

        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />
                <Form className="sm small">
                    <Card className="mb-2 border-0 shadow">
                        <CardHeader className="border-bottom bg-white">
                            <h6><span className="fas fa-id-card mr-1"></span> <FormattedMessage id="Profile.Profile" /> </h6>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col className="col-6">
                                    <FormGroup row size="sm">
                                        <Label for="User" className="font-weight-bold" sm={2}> <FormattedMessage id="Profile.User" /> </Label>
                                        <Col sm={10}>
                                            <Input type="text" bsSize="sm" name="User" value={authContext.getCachedUser().userName} readOnly id="User" />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col className="col-6">
                                    <FormGroup row size="sm">
                                        <Label for="Email" className="font-weight-bold" sm={2}> <FormattedMessage id="Profile.Email" /> </Label>
                                        <Col sm={10}>
                                            <Input type="email" bsSize="sm" name="Email" value={authContext.getCachedUser().profile.email} readOnly id="User" />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <FormGroup row size="sm">
                                        <Label for="FirstName" className="font-weight-bold" sm={2}> <FormattedMessage id="Profile.FirstName" /> </Label>
                                        <Col sm={10}>
                                            <Input type="text" bsSize="sm" name="FirstName" value={authContext.getCachedUser().profile.given_name} readOnly id="User" />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col className="col-6">
                                    <FormGroup row size="sm">
                                        <Label for="FamilyName" className="font-weight-bold" sm={2}> <FormattedMessage id="Profile.FamilyName" /> </Label>
                                        <Col sm={10}>
                                            <Input type="text" bsSize="sm" name="FamilyName" value={authContext.getCachedUser().profile.family_name} readOnly id="User" />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Row>
                        <Col className="pr-1">
                            <Card className="mb-1 h-100  border-0 shadow">
                                <CardHeader className="border-bottom bg-white">
                                    <h6><span className="icon-icon-hotel mr-1"></span> <FormattedMessage id="Profile.Property" /></h6>
                                </CardHeader>
                                <CardBody>
                                    <Table className="table-sm table-borderless">
                                        <thead className="border-bottom">
                                            <tr>
                                                <th>#</th>
                                                <th><FormattedMessage id="Profile.Property" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                user.userAllowedProperties && user.userAllowedProperties.map((item, key) =>
                                                    <tr key={key} className={user.defaultProperty === item.property ? 'bg-secondary text-white' : ''}>
                                                        <th scope="row">
                                                            {key}
                                                        </th>
                                                        <td>
                                                            {item.propertyName}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="pl-1">
                            <Card className="mb-1 h-100  bg-white border-bottom shadow">
                                <CardHeader className="border-bottom bg-white"><h6><span className="fas fa-users mr-1"></span> <FormattedMessage id="Profile.AccessGroups" /> </h6></CardHeader>
                                <CardBody>
                                    <Table className="table-sm table-borderless">
                                        <thead className="border-bottom">
                                            <tr>
                                                <th>#</th>
                                                <th><FormattedMessage id="Profile.AccessGroup" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                authContext.getCachedUser().profile.groups && authContext.getCachedUser().profile.groups.map((item, key) =>
                                                    <tr key={key}>
                                                        <th scope="row">
                                                            {key}
                                                        </th>
                                                        <td>
                                                            <FormattedMessage id={'generic.accessGroup.' + item} />
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </BlockUi>
        );
    }
};