import React, { Component } from 'react';
import { Row, Col, Card, Button, Nav, NavItem, NavLink, TabContent, TabPane, Form } from 'reactstrap';
import { Crt, StyledCard } from '../../../Base/CommonUIComponents';
import { handleNotification } from '../../../Base/Notification';
import { FormattedMessage, injectIntl } from "react-intl";
import CustomSelect from "../../../Base/CustomSelect";
import countryList from 'react-select-country-list';
import { DateRangePicker } from 'react-dates';
import { getAPI } from '../../../Base/API';
import { Line } from 'react-chartjs-2';
import moment from 'moment';


class MessagesAnalytics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            fromDate: moment().subtract(15, 'days'),
            toDate: moment(),
            analytics: {},
            allMessagesData: { labels: [], datasets: [] },
            granulatarity: 'DAY'
        }
    }

    componentDidMount() {
        this.getAnalytics();
    }

    getAnalytics = (e) => {
        if (e) e.preventDefault();
        this.setState({ block: true });

        var params = `?fromDate=${moment(this.state.fromDate).format("YYYY-MM-DD")}&toDate=${moment(this.state.toDate).format("YYYY-MM-DD")}`;
        params += `&granulatarity=${this.state.granulatarity}`;

        if (this.state.phoneNumbers) this.state.phoneNumbers.forEach(pn => { params += `&phoneNumbers=${pn}` });
        if (this.state.countryCodes) this.state.countryCodes.forEach(cc => { params += `&countryCodes=${cc}` });


        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data)
                }

                this.organizeData(data.response);

                this.setState({ block: false, error: errorMessage });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/WhatsApp/v1/analytics` + params);
    }

    organizeData = (resp) => {
        const fromDate = moment(this.state.fromDate).format("YYYY-MM-DD 00:00");
        const toDate = moment(this.state.toDate).format("YYYY-MM-DD 23:59");
        var formatDate = 'YYYY-MM-DD', type = 'days';

        let startArray = [];

        if (this.state.granulatarity === 'HALF_HOUR') {
            formatDate = 'YYYY-MM-DD HH:mm';
            type = 'hours';

            startArray = [moment(fromDate).format(formatDate), moment(fromDate).format("YYYY-MM-DD HH:30")];
        }
        else {
            if (this.state.granulatarity === 'MONTHLY') {
                formatDate = 'YYYY-MMM';
                type = 'months';
            }

            startArray = [moment(fromDate).format(formatDate)];
        }


        var dates = new Array(moment(toDate).diff(moment(fromDate), type)).fill(undefined)
            .reduce((acc, cur) => {
                const date = this.state.granulatarity === 'HALF_HOUR' ? moment(fromDate).add((acc.length / 2 || 1), type) : moment(fromDate).add(acc.length, type);

                acc.push(date.format(formatDate));

                if (this.state.granulatarity === 'HALF_HOUR') {
                    acc.push(date.format("YYYY-MM-DD HH:30"));
                }

                return acc;
            }, startArray);



        let chart = {
            labels: dates, datasets: [
                { value: 'Sent', label: this.props.intl.formatMessage({ id: "WhatsAppAnalytics.Sent" }), data: Array(dates.length).fill(0), backgroundColor: "#4CC9F0", borderColor: "#4CC9F0", fill: false },
                { value: 'Delivered', label: this.props.intl.formatMessage({ id: "WhatsAppAnalytics.Delivered" }), data: Array(dates.length).fill(0), backgroundColor: "#6EDE8A", borderColor: "#6EDE8A", fill: false }
            ]
        };

        resp && resp.length > 0 && resp[0].analytics.data_points && resp[0].analytics.data_points.forEach(el => {
            const date = this.props.converUnixTimestampToDate(el.start, formatDate);
            const dateIndex = dates.findIndex(el => el === moment(date).format(formatDate));

            if (dateIndex !== -1) {
                chart.datasets[0].data[dateIndex] += el.sent;
                chart.datasets[1].data[dateIndex] += el.delivered;
            }
        })

        this.setState({ allMessagesData: chart })
    }

    handleSelect = (combo, name) => {
        var targetValue = '';

        if (Array.isArray(combo)) {
            targetValue = combo.map(el => el.value);
        }
        else {
            targetValue = combo ? combo.value : null;
        }

        this.setState({ [name]: targetValue })
    };

    render() {
        const { block, error, analytics, allMessagesData } = this.state;

        const optionsLine = {
            type: 'line',
            maintainAspectRatio: false,
            legend: {
                display: true,
                position: "top"
            },
            scales: {
                x: { display: true },
                y: {
                    beginAtZero: true, min: 0,
                    grid: {
                        display: false
                    },
                }
            },
            plugins: {
                datalabels: { display: false }
            }
        }

        const granularityOptions = [
            { value: 'HALF_HOUR', label: <FormattedMessage id="WhatsAppAnalytics.HALF_HOUR" /> },
            { value: 'DAY', label: <FormattedMessage id="WhatsAppAnalytics.DAILY" /> },
            { value: 'MONTH', label: <FormattedMessage id="WhatsAppAnalytics.MONTHLY" /> }
        ]

        return (
            <StyledCard block={block} error={error} >
                <Form onSubmit={this.getAnalytics}>
                    <Row>
                        <Col className="col-2">
                            <CustomSelect
                                options={countryList().getData()}
                                placeholder=""
                                placeholder={<FormattedMessage id="WhatsAppAnalytics.Countries" />}
                                onChange={(combo) => this.handleSelect(combo, 'countryCodes')}
                                isMulti
                                isClearable isSearchable
                            />
                        </Col>
                        <Col className="col-2">
                            <CustomSelect
                                options={this.props.phoneNumbersOptions}
                                isClearable
                                isSearchable
                                isMulti
                                placeholder={<FormattedMessage id="WhatsAppAnalytics.PhoneNumbers" />}
                                onChange={(e) => this.handleSelect(e, "phoneNumbers")}
                            />
                        </Col>
                        <Col className="col-2">
                            <CustomSelect
                                options={granularityOptions}
                                isClearable
                                isSearchable
                                placeholder={""}
                                onChange={(e) => this.handleSelect(e, "granulatarity")}
                                value={granularityOptions.find(el => el.value === this.state.granulatarity)}
                                required
                            />
                        </Col>
                        <Col sm={3}>
                            <DateRangePicker
                                startDate={this.state.fromDate}
                                startDateId="startDate"
                                isOutsideRange={day => day > moment()}
                                endDate={this.state.toDate}
                                endDateId="endDate"
                                onDatesChange={({ startDate, endDate }) => this.setState({ fromDate: startDate, toDate: endDate })}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                required
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className=" btn btn-host btn-sm mt-2" type="submit">
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <Row className="my-3">
                    <Col>
                        <Crt text={<FormattedMessage id="WhatsAppAnalytics.TotalDelivered" />} value={analytics.analytics?.data_points?.reduce((acc, cur) => acc += cur.delivered, 0) || 0} />
                    </Col>
                    <Col>
                        <Crt text={<FormattedMessage id="WhatsAppAnalytics.TotalSent" />} value={analytics.analytics?.data_points?.reduce((acc, cur) => acc += cur.sent, 0) || 0} />
                    </Col>
                    <Col>
                        <Crt text={<FormattedMessage id="WhatsAppAnalytics.TotalPhones" />} value={analytics.analytics?.phone_numbers?.length || 0} />
                    </Col>
                </Row>

                <Card className="border-0 shadow" body style={{ height: '300px' }}>
                    <Line data={allMessagesData} height="100" options={optionsLine} />
                </Card>
            </StyledCard>
        )
    }
}

export default injectIntl(MessagesAnalytics);