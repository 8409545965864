import React, { Component } from 'react';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Row } from 'reactstrap';
import { getAPI } from "../../Base/API";
import { StyledCard } from "../../Base/CommonUIComponents";
import CustomSelect from '../../Base/CustomSelect';
import { CustomTable } from '../../Base/CustomTable';
import { handleNotification } from '../../Base/Notification';
import { SystemEventsModal } from './SystemEventsModal';

export class SystemEvents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            systemEvents: [],
            startDate: moment().subtract(7, 'day'),
            endDate: moment(),
            eventType: null,
            pageIndex: 1,
            pageSize: 10,
            modal: false,
            systemEventDescription: null,
            systemEventPayload: null
        }
    }

    componentDidMount() {
        this.getSystemEvents();
    }

    getSystemEvents = () => {
        const { startDate, endDate, eventType, pageIndex, pageSize } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else if (data.response) {
                    this.setState({ systemEvents: data.response, error: errorMessage, block: false });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/SyncEvent/v1/SystemEvents?fromDate=${startDate.format('YYYY-MM-DD')}&toDate=${endDate.format('YYYY-MM-DD')}&type=${eventType !== null ? eventType : ``}&pageSize=${pageSize}&pageIndex=${pageIndex - 1}`);
    }

    doSearch = () => {
        this.setState({ block: true, pageIndex: 1 }, this.getSystemEvents);
    }

    handleTableChange = (page) => {
        this.setState({ block: true, pageIndex: page }, this.getSystemEvents);
    }

    setFilterState = (name, combo) => {
        this.setState({ [name]: combo && combo.value });
    }

    toggleModal = (description, payload) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            systemEventDescription: description,
            systemEventPayload: payload
        }));
    }

    render() {
        const { block, error, eventType, pageIndex, pageSize, startDate, endDate, systemEvents, modal, systemEventDescription, systemEventPayload } = this.state;

        const typeOptions = [
            {
                value: 'Error',
                label: <FormattedMessage id="SystemEvents.Error" />
            },
            {
                value: 'Warning',
                label: <FormattedMessage id="SystemEvents.Warning" />
            }
        ];

        const columns = [
            {
                dataField: 'type',
                text: this.props.intl.formatMessage({ id: "SystemEvents.Type" }),
                formatter: cell => typeOptions.filter(opt => opt.value === cell).map(opt => opt.label)
            },
            {
                dataField: 'code',
                text: this.props.intl.formatMessage({ id: "SystemEvents.Code" })
            },
            {
                dataField: 'description',
                text: this.props.intl.formatMessage({ id: "SystemEvents.Description" }),
                formatter: cell => <div style={{ height: '90px', /*whiteSpace: 'nowrap',*/ overflow: 'hidden', textOverflow: 'ellipsis' }}> {cell} </div>
            },
            {
                dataField: 'timestamp',
                text: this.props.intl.formatMessage({ id: "SystemEvents.Timestamp" }),
                headerStyle: () => { return { width: "10%" }; },
                formatter: cell => moment(cell).format("YYYY-MM-DD HH:mm:ss")
            },
            {
                dataField: 'payload',
                text: '',
                formatter: (_, row) => <Button onClick={() => this.toggleModal(row.description, row.stackTrace)} className="btn-sm btn-host btn" > <FormattedMessage id="SystemEvents.Details" /> </Button>
            }
        ];

        return (
            <StyledCard block={block} error={error} icon={'fas fa-exclamation-circle'} title={'NavMenu.SystemEvents'}>
                {
                    modal ?
                        <SystemEventsModal modal={modal} toggle={this.toggleModal} eventDescription={systemEventDescription} eventPayload={systemEventPayload}/>
                        :
                        <div />
                }
                <Row className="mb-2">
                    <Col className="col-2">
                        <CustomSelect icon={'fa fa-exclamation-triangle fa-fw'} options={typeOptions} value={typeOptions.find(opt => opt.value === eventType)} onChange={this.setFilterState.bind(this, 'eventType')} isClearable />
                    </Col>
                    <Col className="col-2">
                        <DateRangePicker
                            startDate={startDate}
                            startDateId="your_unique_start_date_id_announcement"
                            isOutsideRange={day => day > moment()}
                            endDate={endDate}
                            endDateId="your_unique_end_date_id_announcement"
                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate: startDate, endDate: endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </Col>
                    <Col className="text-right">
                        <Button className="float-right mr-2 btn-sm btn-host btn" onClick={this.doSearch}><i className="icon-icon-search"></i></Button>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="h-100">
                        <CustomTable
                            data={systemEvents}
                            columns={columns}
                            page={pageIndex}
                            sizePerPage={pageSize}
                            hasPagination={false}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-1">
                        <Button className="btn-host shadow btn-sm mr-1" disabled={pageIndex <= 1} onClick={() => this.handleTableChange(parseInt(pageIndex) - 1)}>
                            <i className="fas fa-angle-left" />
                        </Button>
                    </Col>
                    <Col className="text-center" style={{ fontSize: '20px' }}>
                        <FormattedMessage id="SystemEvents.Page" values={{ page: pageIndex }} />
                    </Col>
                    <Col className="col-1 text-right">
                        <Button className="btn-host btn-sm shadow" disabled={systemEvents.length < pageSize} onClick={() => this.handleTableChange(parseInt(pageIndex) + 1)}>
                            <i className="fas fa-angle-right" />
                        </Button>
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}

export default injectIntl(SystemEvents);
