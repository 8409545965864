import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Button, Card, Col, Modal, ModalBody, Pagination, Row, UncontrolledTooltip } from 'reactstrap';
import { getAPI } from '../../Base/API';
import { StyledCard } from '../../Base/CommonUIComponents';
import { handleNotification } from '../../Base/Notification';

export default class TypeformForms extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            forms: [],
            previewModal: false,
            page: 1,
            pageSize: 1
        };
    }

    componentDidMount(){
        this.getForms();
    }

    getForms = () => {
        const { page } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    this.setState({ block: false }, () =>
                        handleNotification(data)
                    );
                    return;
                };
                if (data.response && data.response.length > 0) {
                    this.getReviwMappings(data.response, data.pageSize);
                    return;
                };
            };
            this.setState({ block: false });
        }, `/api/gms/TypeForm/Forms?page=${page}`);
    }

    getReviwMappings = (forms, pageSize) => {
        if (forms) {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, forms, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    };

                    if (data.response && data.response.length > 0) {
                        forms.forEach(el => {
                            el.isMapped = data.response.some(rm => rm.formId == el.id);
                        })
                    };

                    this.setState({ forms: forms, pageSize: pageSize, block: false });
                }
                else this.setState({ block: false, forms });
            }, `/api/gms/Review/v1/reviewMapping/Typeform`);
        }
        else {
            this.setState({ forms: forms || [], block: false });
        }
    }

    toggleModal = () => {
        this.setState(prev => ({ previewModal: !prev.previewModal }));
    }

    previewForm = (formId) => {
        const { forms } = this.state;

        const formPreview = forms.find(({id}) => id === formId)?._links?.display;
        
        this.setState({ formPreview, previewModal: true });
    }

    handlePagination = (value) => {
        this.setState(prev => ({
            block: true,
            pageIndex: prev.pageIndex + value
        }), () => this.getForms());
    }

    render() {
        const { block, error, forms, previewModal, pageSize, page, formPreview } = this.state;
        
        return (
            <StyledCard block={block} error={error} >
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to="/Typeform"> 
                                <FormattedMessage id="TypeFormMenu.Title"/>
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            <FormattedMessage id="TypeFormMenu.Forms"/>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <Row>
                        {forms?.map((form, idx) =>
                            <Col className="h-100 col-4 mb-3" key={idx}>
                                <Card className="border-0 shadow p-3">
                                    <Row>
                                        <Col>
                                            <Link to={{ pathname: '/TypeformAnswers/' + form.id }}>
                                                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                                    <h5 className="mb-0">{form.title}</h5>
                                                    {form.isMapped &&
                                                        <span className='ml-2'>
                                                            <i className="fas fa-random mr-1" id={`Survey${idx}`} />
                                                            <UncontrolledTooltip target={`Survey${idx}`} placement="bottom">
                                                                <FormattedMessage id="reviewProConfig.AlreadyMapped" />
                                                            </UncontrolledTooltip>
                                                        </span>
                                                    }
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col className='text-right col-2'>
                                            <Button className="btn-sm btn-host mr-2" onClick={() => this.previewForm(form.id)}>
                                                <i className="icon-icon-preview" />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        )}
                    </Row>
                    <Row className="my-4">
                        <Col className="text-right mr-3">
                            <Pagination
                                isPrevDisabled={page === 1}
                                isNextDisabled={pageSize <= page}
                                currentPage={page}
                                handlePrevButton={() => this.handlePagination(-1)}
                                handleNextButton={() => this.handlePagination(1)}
                            />
                        </Col>
                    </Row>
                </div>
                <Modal isOpen={previewModal && formPreview} toggle={this.toggleModal} fade={false} size={"lg"}>
                    <ModalBody>
                        <div>
                            <iframe title="TypeformFrame" style={{ border: 'none', width: '100%', height: '100%', minHeight: '50rem', minWidth: '50rem' }} src={formPreview}/>
                        </div>
                    </ModalBody>
                </Modal>
            </StyledCard>
            
        )
  }
}
