import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Form, Input, Label, Modal, ModalBody, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { CommonHelper, StyledCard } from '../../../Base/CommonUIComponents';
import GenericTextArea from '../../../Base/GenericTextArea';
import { postAPI } from '../../../Base/API';
import { handleNotification } from '../../../Base/Notification';

class DomainSettingsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: this.props.blockParent,
            error: null,
            activeTab: '0',
            emailTo: '',
            message: this.props.intl.formatMessage({ id: 'SendGrid.DefaultMessage' }),
        };
    }

    copy = (content) => {
        navigator.clipboard.writeText(content);
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    sendEmail = (e) => {
        e.preventDefault();
        const { domain } = this.props;
        const { emailTo, message } = this.state;
        
        const body = {
            domainId: domain.id,
            email: emailTo,
            message: message
        };

        this.setState({ block: true }, () => {
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    
                    this.setState({ block: false }, handleNotification(data, <FormattedMessage id="SendGrid.EmailSent" />, <FormattedMessage id="generic.success" />));
                }
            }, `/api/gms/SendGrid/v1/email`, body)
        });
    }

    render() {
        const { activeTab, emailTo, message, block, error } = this.state;
        const { domain, toggle, verify } = this.props;
        const configs = Object.keys(domain.dns);

        return (
            <Modal isOpen={true} toggle={toggle} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={toggle} className="closeModal">
                    <i className="fas fa-times fa-sm"/>
                </Button>
                <ModalBody>
                    <StyledCard block={block} error={error}>
                        <Row>
                            <Col>
                                <Nav tabs className="border-0 mb-3">
                                    <NavItem>
                                        <NavLink className={activeTab === '0' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleTab('0')} style={{ cursor: "pointer" }}>
                                            <b><FormattedMessage id="SendGrid.Manual"/></b>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={activeTab === '1' ? 'tab-border-host text-host  border-0' : 'border-0'} onClick={() => this.toggleTab('1')} style={{ cursor: "pointer" }}>
                                            <b><FormattedMessage id="SendGrid.SendToCoworker"/></b>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                                    <Col className='text-right col-2'>
                                        <Button className=" btn btn-host btn-sm" onClick={() => verify(domain.id)} >
                                    <FormattedMessage id="SendGrid.Verify"/>
                                </Button>
                            </Col>
                        </Row>
                        {activeTab === '0' ?
                            <div>
                                <Row className='px-2 text-muted'>
                                    <Col className="col-2">
                                        <FormattedMessage id="SendGrid.Type"/>
                                    </Col>
                                    <Col>
                                        <FormattedMessage id="SendGrid.Host"/>
                                        <CommonHelper help={<FormattedMessage id="SendGrid.HostHelp" />} id={'HostHelp'} />
                                    </Col>
                                    <Col>
                                        <FormattedMessage id="SendGrid.Data"/>
                                        <CommonHelper help={<FormattedMessage id="SendGrid.DataHelp" />} id={'DataHelp'} />
                                    </Col>
                                </Row>
                                <div className='mt-2'>
                                    {configs.map((c, key) => {
                                        const config = domain.dns[c];
                                        return (
                                            <Row key={key} className="px-2 px-4">
                                                <Col className="col-2 py-2 text-truncate" style={{ border: '1px solid #e5e5e5', borderRight: 'none', borderTop: !key ? '1px solid #e5e5e5' : 'none' }}>
                                                    {config.type.toUpperCase()}
                                                </Col>
                                                <Col className='py-2 text-truncate d-flex align-items-center justify-content-between' style={{ border: '1px solid #e5e5e5', borderTop: !key ? '1px solid #e5e5e5' : 'none' }}>
                                                    <div className='text-truncate'>{config.host}</div>
                                                    <div>
                                                        <i className="far fa-copy cursor-pointer" onClick={_ => this.copy(config.host)}/>
                                                    </div>
                                                </Col>
                                                <Col className='py-2 d-flex align-items-center justify-content-between text-truncate' style={{ border: '1px solid #e5e5e5', borderLeft: 'none', borderTop: !key ? '1px solid #e5e5e5' : 'none' }}>
                                                    <div className='text-truncate'>{config.data}</div>
                                                    <div className='px-2'>
                                                        <i className="far fa-copy cursor-pointer" onClick={_ => this.copy(config.data)}/>
                                                    </div>
                                                </Col>
                                            </Row>
                                        )}
                                    )}
                                </div>
                            </div>
                        :
                            <Form onSubmit={this.sendEmail}>
                                <div>
                                    <div>
                                        <Label>
                                            <FormattedMessage id="SendGrid.EmailTo"/>
                                        </Label>
                                    </div>
                                    <Input
                                        type='email'
                                        required
                                        maxLength={'100'}
                                        name='emailTo'
                                        value={emailTo}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className='mt-2'>
                                    <div>
                                        <Label>
                                            <FormattedMessage id="SendGrid.Message"/>
                                        </Label>
                                    </div>
                                    <GenericTextArea
                                        backgroundColor={"white"}
                                        onChange={this.handleChange}
                                        name={'message'}
                                        value={message}
                                        placeholder={''}
                                        required={true}
                                        maxLength='999'
                                        className="py-1"
                                        style={{
                                            width: '100%',
                                            background: 'transparent',
                                            boxShadow: 'unset',
                                            outline: '0',
                                            maxHeight : '500px',
                                            minHeight: '50px',
                                            height: '50px',
                                            padding: '5px 0',
                                            resize: 'none',
                                            color: '#07294C',
                                        }}
                                    />
                                </div>
                                <div className='mt-2 d-flex align-items-center justify-content-end'>
                                    <Button className=" btn btn-host btn-sm" type={'submit'}>
                                        <FormattedMessage id="SendGrid.Send"/>
                                    </Button>
                                </div>
                            </Form>
                        }
                    </StyledCard>
                </ModalBody>
            </Modal>
        )
    }
}

export default injectIntl(DomainSettingsModal)