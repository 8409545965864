import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Col, Row, Form, FormGroup, Label, Input } from 'reactstrap';
import { postAPI } from "../../Base/API";
import { handleNotification } from "../../Base/Notification";
import { BlankCard, ActiveInactiveStatusCombo } from "../../Base/CommonUIComponents";
import CustomSelect from '../../Base/CustomSelect';

export class ExportProfilesModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            exportList: [],
            hotels: [],
            surveys: [],
            selectedExport: this.props.selectedExport ? this.props.selectedExport : {}
        };
    }

    componentDidMount() {
    }

    saveExport = (event) => {
        event.preventDefault();
        this.setState({ block: true });

        var body = { ...this.state.selectedExport };
        body.reviewProvider = 'ReviewPro';

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                    return;
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="ReviewProExportProfiles.ExportSaved" />, <FormattedMessage id="generic.success" />);
                    this.props.getExports();
                    this.props.toggleModal();
                }
            }
            this.setState({ block: false });
        }, `/api/gms/Review/v1/reviewexport`, body);
    }

    handleChangeSelect = (combo, name, name2) => {
        this.setState({
            selectedExport: {
                ...this.state.selectedExport,
                [name]: combo ? combo.value : null,
                [name2]: combo ? combo.label : null
            }
        });
    };

    handleChange = (e, type) => {
        const { name, value } = e.target;

        this.setState({
            selectedExport: {
                ...this.state.selectedExport,
                [name]: value
            }
        })
    }

    render() {
        const { block, error, surveys, hotels, products } = this.props;
        const { selectedExport } = this.state;

        return (
            <BlankCard block={block} error={error}>
                <Form onSubmit={this.saveExport}>
                    <Row className="mb-3">
                        <Col>
                            <h5></h5>
                        </Col>
                        <Col className="text-right">
                            <Button className="btn-sm btn-host" type="submit"> <i className="fas fa-save" /></Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup row>
                                <Label sm={2}> <FormattedMessage id="ProfileDetails.Name" /> </Label>
                                <Col sm={10}>
                                    <Input
                                        type="text"
                                        name="name"
                                        onChange={(e) => this.handleChange(e)}
                                        value={selectedExport.name || ''}
                                        maxLength="100"
                                        required
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-6">
                            <FormGroup row>
                                <Label sm={4}> Hotel </Label>
                                <Col sm={8} >
                                    <CustomSelect
                                        options={hotels}
                                        required
                                        isClearable
                                        isSearchable
                                        placeholder={""}
                                        onChange={(e) => this.handleChangeSelect(e, "hotelId")}
                                        value={selectedExport.hotelId ? hotels.find(c => c.value === selectedExport.hotelId) : ''}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup row>
                                <Label sm={4}> <FormattedMessage id="generic.status" /> </Label>
                                <Col sm={8}>
                                    <ActiveInactiveStatusCombo
                                        name='status'
                                        onChangeFunc={this.handleChangeSelect.bind(this)}
                                        placeholder={""}
                                        required
                                        value={selectedExport.active}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-6">
                            <FormGroup row>
                                <Label sm={4}> Survey </Label>
                                <Col sm={8} >
                                    <CustomSelect
                                        options={surveys}
                                        required
                                        isClearable
                                        isSearchable
                                        placeholder={""}
                                        onChange={(e) => this.handleChangeSelect(e, "surveyId", "surveyName")}
                                        value={selectedExport.surveyId ? surveys.find(c => c.value === selectedExport.surveyId) : ''}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col className="col-6">
                            <FormGroup row>
                                <Label sm={4}> <FormattedMessage id="generic.Product" /> </Label>
                                <Col sm={8} >
                                    <CustomSelect
                                        options={products}
                                        isClearable
                                        isSearchable
                                        placeholder={""}
                                        onChange={(e) => this.handleChangeSelect(e, "propertyId")}
                                        value={selectedExport.propertyId ? products.find(c => c.value === selectedExport.propertyId) : ''}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </BlankCard>
        );
    }
}
export default injectIntl(ExportProfilesModal)