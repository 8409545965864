import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { Button, Card, CardBody, Col, Row, Table, UncontrolledCollapse, UncontrolledTooltip } from 'reactstrap';
import { getAPI, postAPI } from "../Base/API";
import CustomSelect from '../Base/CustomSelect';
import { handleNotification } from "../Base/Notification";
import { ErrorAlert } from '../Base/ErrorAlert';
import { FormattedMessage, injectIntl } from 'react-intl';
import QueueMessage from './QueueMessage';

class QueueStatusDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: true,
            selectedMessages: [],
            isDeadletter: false,
            viewtype: "msg",
            selectedQueue: null
        }
    }

    componentDidMount() {
        this.getQueueData();
    }

    getQueueData() {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ QueueData: data, block: false });
            }
        }, '/api/User/Dashboard/v1/Queue?conn=PN');
    }

    peekQueue(name, isDeadletter) {
        this.setState({ block: true, queueName: name });
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                let hotelOptions = [], typeOptions = [];

                data.queueMessage && data.queueMessage.forEach(item => {
                    const body = JSON.parse(item.body);

                    if (!hotelOptions.some(el => el.value === item.hotelId)) {
                        hotelOptions.push({ value: item.hotelId, label: item.hotelId });
                    }
                    if (body && !typeOptions.some(el => el.value === body.Tag)) {
                        typeOptions.push({ value: body.Tag, label: body.Tag });
                    }

                    item.tag = body ? body.Tag : '';
                    item.visible = true;
                });

                this.setState({
                    PeekQueueData: data, selectedMessages: [], block: false, isDeadletter: isDeadletter,
                    hotelOptions, typeOptions, hotelIdFilter: null, typeFilter: null, selectedQueue: name
                });
            }
        }, '/api/User/Dashboard/v1/Queue/' + name + '?isDeadLetter=' + isDeadletter+'&conn=PN');
    }

    submitMessage(msgId, evt) {
        let req = this.state.selectedMessages;

        this.setState({ block: true });
        postAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="QueueStatusDashboard.DeadLetterConfirm" values={{ messages: data.count }} />, <FormattedMessage id="General.Success" />);
                this.setState({ PeekQueueData: data, PeekQueueData: null, block: false }, () => this.getQueueData());
            }
        }, '/api/User/Dashboard/v1/resubmitDeadLetterMessages/' + this.state.queueName+'?conn=PN', req);
    }

    deleteMessage() {
        let body = [...this.state.selectedMessages];

        this.setState({ block: true });
        postAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.success) {
                    handleNotification(data, <FormattedMessage id="QueueStatusDashboard.DeadLetterConfirmDelete" values={{ messages: data.count }} />, <FormattedMessage id="General.Success" />);
                    this.setState({ PeekQueueData: null, block: false }, () => this.getQueueData());
                }
            }
        }, `/api/User/Dashboard/v1/RemoveDeadLetterMessages/${this.state.queueName}?conn=PN`, body);
    }

    decryptMessage = (tag, encryptedData) => {
        this.setState({ block: true });

        postAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
            }
        }, `/api/gms/SyncEvent/v1/DecryptMessage?tag=${tag}`, encryptedData);
    }

    handleMsgClick = (key) => {
        if (this.state.isDeadletter) {
            this.state.selectedMessages.find(el => el == key) != undefined ?
                this.setState(prevState => ({ selectedMessages: [...prevState.selectedMessages.filter(el => el != key)] }))
                :
                this.setState(prevState => ({ selectedMessages: [...prevState.selectedMessages, key] }))
        }
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    handlePeekQueueFilter = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        }, this.setVisible );
    };

    setVisible = () => {
        const data = { ...this.state.PeekQueueData };

        if (this.state.hotelIdFilter || this.state.typeFilter) {
            data.queueMessage && data.queueMessage.forEach(item => {
                if ((this.state.hotelIdFilter ? item.hotelId === this.state.hotelIdFilter : true) && (this.state.typeFilter ? item.tag === this.state.typeFilter : true)) {
                    item.visible = true;
                }
                else {
                    item.visible = false;
                }
            })
        }
        else {
            if (this.state.hotelIdFilter === null && this.state.typeFilter === null) {
                data.queueMessage && data.queueMessage.map(item => item.visible = true);
            }
        }

        this.setState({ PeekQueueData: data, selectedMessages: [] });
    }

    handleAllMsgSelection = (action) => {
        let messages = [];

        if (action === 'selectAll') {
            messages = this.state.PeekQueueData.queueMessage.reduce((acc, cur) => {
                if (cur.visible) {
                    acc.push(cur.messageId);
                }
                return acc;
            }, []);
        }

        this.setState({ selectedMessages: messages });
    }

    render() {
        const viewOptions = [
            {
                value: 'all',
                label: "All Messages"
            },
            {
                value: 'msg',
                label: "With Messages"
            },
            {
                value: 'dlq',
                label: "With DLQ Messages"
            },
            {
                value: 'active',
                label: "With Active Messages"
            }
        ];

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />
                <Card>
                    <CardBody>
                        <Row className="mb-1">
                            <Col className="col-3 small">
                                <CustomSelect
                                    name="status"
                                    small="true"
                                    icon={'icon-icon-e-goi-config'}
                                    value={viewOptions.find(el => el.value === this.state.viewtype)}
                                    placeholder={"Search Type"}
                                    options={viewOptions}
                                    onChange={this.handleChangeSelect.bind(this, 'viewtype')}
                                    isClearable isSearchable
                                />
                            </Col>
                            <Col className="text-right">
                                <Button id="deleteDeadLetter" className="btn btn-host btn-sm mr-2" disabled={this.state.selectedMessages.length === 0 || this.state.isDeadletter === false} onClick={this.deleteMessage.bind(this)}>
                                    <i className="fas fa-trash"></i>
                                </Button>
                                <UncontrolledTooltip placement="right" target="deleteDeadLetter" >
                                    Delete selected messages
                                    </UncontrolledTooltip >
                                <Button id="submitMessage" id="Tooltipsendquque" className="btn btn-host btn-sm mr-2" disabled={this.state.selectedMessages.length === 0 || this.state.isDeadletter === false} onClick={this.submitMessage.bind(this)}><i className="fas fa-share"></i></Button>
                                <UncontrolledTooltip placement="right" target="Tooltipsendquque" >
                                    Send selected messages to Active Messages queue
                                    </UncontrolledTooltip >
                                <Button className="btn btn-sm btn-host" onClick={this.getQueueData.bind(this)}><i className="fas fa-sync"></i></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="h-100" sm={6}>
                                <Card className="border-0">
                                    <Table size="sm" className="small">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th >Inbound Hotel Information Queues</th>
                                                <th>Active Messages</th>
                                                <th>DeadLetter Messages</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {this.state.QueueData && this.state.QueueData.queuestatus.filter(el => el.pathInfo === null &&
                                                (
                                                    (this.state.viewtype === 'msg' && (el.activeMessages > 0 || el.deadLetterMessages > 0)) ||
                                                    (this.state.viewtype === 'dlq' && el.deadLetterMessages > 0) ||
                                                    (this.state.viewtype === 'active' && el.activeMessages > 0) ||
                                                    (this.state.viewtype === 'all')
                                                )
                                            ).map((item, key) =>
                                                <tr key={key}>

                                                    <th scope="row">{key}</th>
                                                    <td>{item.pathInfo ? item.pathInfo : item.path}</td>
                                                    <td className="text-center">{item.activeMessages} <Button disabled={item.activeMessages < 1} className="btn-sm btn-host float-right" onClick={this.peekQueue.bind(this, item.path, false)}><i className="far fa-eye"></i></Button></td>
                                                    <td className="text-center">{item.deadLetterMessages} <Button disabled={item.deadLetterMessages < 1} className="btn-sm btn-host float-right" onClick={this.peekQueue.bind(this, item.path, true)}><i className="far fa-eye"></i></Button></td>

                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                    <Table size="sm" className="small">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th >Outbound Hotel Reservation Queues</th>
                                                <th>Active Messages</th>
                                                <th>DeadLetter Messages</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.QueueData && this.state.QueueData.queuestatus.filter(el => el.pathInfo !== null &&
                                                (
                                                    (this.state.viewtype === 'msg' && (el.activeMessages > 0 || el.deadLetterMessages > 0)) ||
                                                    (this.state.viewtype === 'dlq' && el.deadLetterMessages > 0) ||
                                                    (this.state.viewtype === 'active' && el.activeMessages > 0) ||
                                                    (this.state.viewtype === 'all')
                                                )
                                            ).map((item, key) =>

                                                <tr key={key}>
                                                    <th scope="row">{key}</th>
                                                    <td>{item.pathInfo ? item.pathInfo : item.path}</td>
                                                    <td className="text-center">{item.activeMessages} <Button disabled={item.activeMessages < 1} className="btn-sm btn-host float-right" onClick={this.peekQueue.bind(this, item.path, false)}><i className="far fa-eye"></i></Button></td>
                                                    <td className="text-center">{item.deadLetterMessages} <Button disabled={item.deadLetterMessages < 1} className="btn-sm btn-host float-right" onClick={this.peekQueue.bind(this, item.path, true)}><i className="far fa-eye"></i></Button></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </Card>
                            </Col>
                            <Col sm={6}>
                                <Card className="small py-1">
                                    {this.state.PeekQueueData && this.state.PeekQueueData.queueMessage ?
                                        <Row className="pb-4 pt-3 px-2">
                                            <Col className="col-4">
                                                <CustomSelect
                                                    name="hotelId"
                                                    small="true"
                                                    placeholder={"Hotel"}
                                                    options={this.state.hotelOptions}
                                                    onChange={this.handlePeekQueueFilter.bind(this, 'hotelIdFilter')}
                                                    value={this.state.hotelIdFilter ? this.state.hotelOptions.find(el => el.value === this.state.hotelIdFilter) : ''}
                                                    isClearable isSearchable
                                                />
                                            </Col>
                                            <Col className="col-3 pl-2">
                                                <CustomSelect
                                                    name="status"
                                                    small="true"
                                                    placeholder={< FormattedMessage id="SystemEvents.Type" />}
                                                    options={this.state.typeOptions}
                                                    onChange={this.handlePeekQueueFilter.bind(this, 'typeFilter')}
                                                    value={this.state.typeFilter ? this.state.hotelOptions.find(el => el.value === this.state.typeFilter) : ''}
                                                    isClearable isSearchable
                                                />
                                            </Col>

                                            <Col className="mb-2 mt-1 d-flex align-items-center justify-content-end">
                                                <div>
                                                    {this.state.PeekQueueData.queueMessage && this.state.PeekQueueData.queueMessage.filter(el => el.visible).length === this.state.selectedMessages.length ?
                                                        <span onClick={() => this.handleAllMsgSelection('removeAll')} style={{ color: 'blue', cursor: 'pointer' }}>
                                                            <FormattedMessage id="QueueStatusDashboard.RemoveSelection" />
                                                        </span>
                                                        :
                                                        <span onClick={() => this.handleAllMsgSelection('selectAll')} style={{ color: 'blue', cursor: 'pointer' }}>
                                                            <FormattedMessage id="QueueStatusDashboard.SelectAll" />
                                                        </span>
                                                    }
                                                    <b> ({this.state.selectedMessages.length}) </b>
                                                </div>
                                            </Col>
                                        </Row>
                                    : ''}

                                    {this.state.PeekQueueData && this.state.PeekQueueData.queueMessage.map((item, key) => item.visible &&
                                        <QueueMessage
                                            key={key}
                                            item={item}
                                            handleMsgClick={this.handleMsgClick}
                                            selectedMessages={this.state.selectedMessages}
                                            selectedQueue={this.state.selectedQueue}
                                            isDeadletter={this.state.isDeadletter}
                                        />
                                    )}
                                </Card>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </BlockUi>
        );
    }
};

export default injectIntl(QueueStatusDashboard)