import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { getAPI, postAPI } from "../../Base/API";
import { Input, CustomInput, Row, Col, Button, Form } from 'reactstrap';
import { StyledCard } from "../../Base/CommonUIComponents";
import { handleNotification } from "../../Base/Notification";

class DataClean extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: true,
            error: null,
            status: { value: false },
            retentioPolList: [],
            channelCode: null,
            retentionPolicyType: ['Audit', 'Batch', 'SyncEvents', 'SystemEvents', 'ProcessIssues' ]
        }
    }

    componentDidMount() {
        this.getRetentionPolicyList();
    }

    getRetentionPolicyList = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0)
                    handleNotification(data);
                else
                    this.setState({ retentioPolList: data.response, block: false });
            }
        }, '/api/gms/Hotel/v1/retentionPolicy');
    }

    changeVal = (evt, type) => {
        let val = evt.target.value === 'on' ? evt.target.checked : Number(evt.target.value);
        let name = evt.target.name;

        let element = this.state.retentioPolList.find(el => el.type === type) === undefined ? {
            "type": type,
            "months": null,
            "active": false
        } : this.state.retentioPolList.find(el => el.type === type);

        element[name] = val;

        this.setState(prevState =>
            ({ retentioPolList: [...prevState.retentioPolList.filter(el => el.type !== type), element] }))

    }

    saveDataClean = (evt) => {
        evt.preventDefault();
        this.setState({ block: true });

        let req = { 'request': this.state.retentioPolList };

        postAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="DataClean.PoliciesSaved" />, <FormattedMessage id="General.Success" />);
                this.setState({ block: false });
            }
        }, '/api/gms/Hotel/v1/retentionPolicy', req);
    }


    render() {
        return (
            <StyledCard block={this.state.block} error={this.state.error}>
                <Form onSubmit={this.saveDataClean}>
                    <Row className="mb-3">
                        <Col>
                            <h5><i className="fas fa-trash mr-2" /><FormattedMessage id="DataClean.title" /> </h5>
                        </Col>
                        <Col className="text-right">
                            <Col className="text-right">
                                <Button className="float-right mr-2 btn-sm btn-host btn" type="submit">
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Col>
                    </Row>

                    {this.state.retentionPolicyType.map((type, key) => {
                        const value = this.state.retentioPolList.find(el => el.type === type);

                        return (
                            <Row className="mb-1" key={key}>
                                <Col className="col-4">
                                    <FormattedHTMLMessage id={`DataClean.${type}`} />
                                </Col>
                                <Col className="col-2">
                                    <Input name="months" type="number" required
                                        min="0"
                                        value={value ? value.months : ''}
                                        onChange={(evt) => this.changeVal(evt, type)}
                                    />
                                </Col>
                                <Col className="col-2"><FormattedMessage id="DataClean.Month" /></Col>
                                <Col className="col-3">
                                    <CustomInput name="active" type="switch" id={type}
                                        checked={value ? value.active : ''}
                                        onChange={(evt) => this.changeVal(evt, type)}
                                    >
                                        <FormattedHTMLMessage id="generic.active" />
                                    </CustomInput>
                                </Col>
                            </Row>
                        )
                    })}
                </Form>
            </StyledCard>
        );
    }
}

export default DataClean;