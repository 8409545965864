import React, { Component } from 'react';
import { Col, Card, Badge, Row, Button, Form } from 'reactstrap';
import { getAPI } from "../../Base/API";
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from "../../Base/Notification";
import { StyledCard } from "../../Base/CommonUIComponents";
import CustomSelect from "../../Base/CustomSelect";
import MappingModal from './MappingModal';
import { getEntityList, getFieldList } from "../../Base/ReferenceDataFunctions";

import roiback from '../../../img/roiback.jpg';
import egoi from '../../../img/egoi.png';

class Mappings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: true,
            error: null,
            modal: false,
            genericMappings: [],
            egoiList: [],
            egoiTags: [],
            tags: [],
            allTags: [],
            modalData: null,
            entities: [
                {
                    "value": "ProfileAddress",
                    "label": <FormattedMessage id="ReferenceDataFunctions.ProfileAddress" />
                },
                {
                    "value": "ProfileConsent",
                    "label": <FormattedMessage id="ReferenceDataFunctions.ProfileConsent" />
                },
                {
                    "value": "ProfileDocument",
                    "label": < FormattedMessage id="ReferenceDataFunctions.ProfileDocument" />
                },
            ]
        };
    }

    componentDidMount() {
        this.getEgoiList();
    }

    getMapping = (e) => {
        if (e) e.preventDefault();
        this.setState({ block: true });

        var params = "";
        if (this.state.originType) params += `originType=${this.state.originType}&`;
        if (this.state.originSubType) params += `originSubType=${this.state.originSubType}&`;
        if (this.state.listId) params += `listId=${this.state.listId}`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ genericMappings: data.response })
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/GenericMapping?` + params);
    }

    getEgoiList = () => {
        this.setState({ block: true });
        var list = [];

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {

                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }

                if (data.items && data.items.length > 0) {
                    data.items.forEach(item => {
                        list.push({ value: item.list_id, label: item.public_name });
                    });
                }
            }
            this.setState({ error: errorMessage, egoiList: list  }, this.getExtraFields);
        }, `/api/gms/Marketing/v1/egoi/lists?limit=100&offset=0`);
    }

    getEgoiTags = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                var list = [];

                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }

                if (data.items && data.items.length > 0) {
                    data.items.forEach(item => {
                        list.push({ value: item.tag_id, label: item.name, color: item.color });
                    });
                }

                this.setState({ egoiTags: list });
            }
            this.setState({ error: errorMessage }, this.getTagGroups);
        }, `/api/gms/Marketing/v1/egoi/tags?limit=100&offset=0`);
    }

    getTagGroups = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                var list = [], allTags = [];

                data.response && data.response.forEach(group => {
                    const obj = { label: group.code, options: [] };

                    group.tag && group.tag.forEach(tag => {
                        obj.options.push({ value: tag.id, label: tag.code });
                        allTags.push({ value: tag.id, label: tag.code });
                    })

                    list.push(obj);
                })

                this.setState({ tags: list, allTags }, this.getMapping);
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/tag/groupDetails?tagType=all`);
    }

    getExtraFields = () => {
        this.setState({ block: true });
        const list = [...this.state.egoiList]

        list.forEach(list => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.Errors && data.Errors.length > 0) {
                        handleNotification(data);
                    }
                    else {
                        list.extraFields = { textFields: [], optionFields: [] };

                        data.response && data.response.forEach(field => {
                            if (field.type === 'extra') {

                                if (field.format === 'text') {
                                    list.extraFields.textFields.push({ value: field.field_id, label: field.name });
                                }
                                if (field.format === 'options' && field.options && field.options.length > 0) {
                                    list.extraFields.optionFields.push({
                                        value: field.field_id, label: field.name,
                                        options: field.options.map(el => ({ value: el.option_id, label: this.availableOption(el) }))
                                    });
                                }
                            }
                        })
                    }
                }
            }, `/api/gms/Marketing/v1/egoi/lists/${list.value}/fields`);
        })

        this.setState({ egoiList: list, block: false }, this.getEgoiTags);
    }

    availableOption = (option) => {
        let label = option.en || option.pt || option.br || option.es || option.fr || option.de || option.hu;

        if (!label) {
            label = option.option_id;
        }

        return label;
    }

    updateMapping = (mapping, isToRemove) => {
        const genericMappings = [...this.state.genericMappings];
        const index = genericMappings.findIndex(el => el.id === mapping.id);

        if (index !== -1) {
            if (isToRemove) {
                genericMappings.splice(index, 1);
            }
            else {
                genericMappings[index] = mapping;
            }
        }
        else {
            genericMappings.push(mapping);
        }

        this.setState({ genericMappings });
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    };

    toggleModal = (mapping) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            modalData: mapping
        }));
    }

    render() {
        const { block, error, modal, genericMappings, egoiList, egoiTags, tags, allTags } = this.state;

        const originOptions = [
            { value: 'Egoi', label: 'E-goi', logo: egoi },
            //{ value: 'Roiback', label: 'Roiback', logo: roiback }
        ]

        const originSubTypeOptions = [
            { value: 'CustomField', label: <FormattedMessage id="Mappings.EgoiCustomField" /> },
            { value: 'Tag', label: <FormattedMessage id="Mappings.Tag" /> }
        ]

        const destinationOptions = [
            { value: 'Field', label: <FormattedMessage id="Mappings.Field" /> },
            { value: 'Tag', label: <FormattedMessage id="Mappings.Tag" /> }
        ]

        return (
            <StyledCard block={block} error={error}>
                <Row>
                    <Col>
                        <h5>
                            <i className="fas fa-random mr-2"></i>
                            <FormattedMessage id="Mappings.Title" />
                        </h5>
                    </Col>
                </Row>

                <Form onSubmit={this.getMapping}>
                    <Row className="my-3 align-items-center">
                        <Col sm={2}>
                            <CustomSelect
                                options={originOptions}
                                isClearable
                                isSearchable
                                placeholder={<FormattedMessage id="Mappings.OriginType" />}
                                onChange={(e) => this.handleChangeSelect(e, "originType")}
                            />
                        </Col>
                        <Col sm={2} className="px-1">
                            <CustomSelect
                                options={originSubTypeOptions}
                                isClearable
                                isSearchable
                                placeholder={<FormattedMessage id="Mappings.OriginSubType" />}
                                onChange={(e) => this.handleChangeSelect(e, "originSubType")}
                            />
                        </Col>
                        <Col sm={2}>
                            <CustomSelect
                                options={egoiList}
                                isClearable
                                isSearchable
                                placeholder={<FormattedMessage id="Mappings.EgoiList" />}
                                onChange={(e) => this.handleChangeSelect(e, "listId")}
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className="btn-sm btn-host mr-2" onClick={() => this.toggleModal(null)}>
                                <i className="fas fa-plus" />
                            </Button>
                            <Button className="btn-sm btn-host" type="submit">
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <Row>
                    {genericMappings && genericMappings.map((mapping, index) => {
                        const originType = originOptions.find(el => el.value === mapping.originType);
                        const list = mapping.listId ? egoiList.find(el => el.value.toString() === mapping.listId.toString()) : null;
                        const egoiTag = egoiTags.find(el => el.label === mapping.originMapValue);
                        const tag = allTags.find(el => el.value === mapping.tagId);
                        const entity = this.state.entities.find(el => el.value === mapping.entityName);
                        const field = getFieldList(mapping.entityName, this.props.intl).find(el => el.value === mapping.entityField)

                        const extraFieldsOptions = mapping.originMapValue && !isNaN(mapping.originMapValue) ? list.extraFields?.optionFields.find(el => el.value.toString() === mapping.originFieldId.toString()) : null;
                        const originMapValue = extraFieldsOptions && extraFieldsOptions.options.some(el => el.value.toString() === mapping.originMapValue) ? extraFieldsOptions.options.find(el => el.value.toString() === mapping.originMapValue).label : mapping.originMapValue;

                        return <Col sm={6} key={index} className="mb-3">
                            <Card body className="border-0 shadow pointer h-100 d-flex py-3 justify-content-center" key={index} onClick={() => this.toggleModal(mapping)}>
                                <Row className="d-flex align-items-center">
                                    <Col sm={2} className="text-center shadow mx-3 p-2" style={{ borderRadius: '50px' }}>
                                        {originType ?
                                            <span>
                                                <div><img alt="" src={originType.logo} height="20px" className="mr-2" /></div>
                                                <b>{originType.label}</b>
                                            </span>
                                        : ''}
                                    </Col>
                                    <Col className="px-2">
                                        {mapping.originSubType === 'CustomField' ?
                                            <span>
                                                <Badge className={"color-white py-1 px-2 mr-2 bg-cyan"}> extra </Badge> <b> {originMapValue} </b>
                                            </span>
                                            : 
                                            <>
                                                <span className="mr-1" style={{ borderRadius: '20px', backgroundColor: egoiTag && egoiTag.color, width: '20px', height: '20px' }} >&nbsp; &nbsp;&nbsp;&nbsp;</span>
                                                <b> {egoiTag && egoiTag.label} </b>
                                            </>
                                        }
                                        <br /> <span className="text-muted font_size_xxs"> {list && list.label}</span>
                                    </Col>

                                    <Col sm={2} className="text-host">
                                        <i className="fas fa-lg fa-long-arrow-alt-right" />
                                    </Col>

                                    {mapping.destinationType  === 'Field' ?
                                        <Col className="px-2">
                                            <b> {field && field.label} </b> <br />
                                            <span className="text-muted font_size_xxs"> {entity && entity.label}</span>
                                        </Col>
                                        :
                                        <Col className="px-2">
                                            <Badge color="primary px-2 py-1" style={{ fontSize: '12px' }}> <b> {tag && tag.label} </b> </Badge> <br />
                                            <span className="text-muted font_size_xxs"> Tag </span>
                                        </Col>
                                    }
                                </Row>
                            </Card>
                        </Col>
                    })}
                </Row>

                {modal ?
                    <MappingModal
                        toggleModal={() => this.toggleModal(null)}
                        updateMapping={this.updateMapping}
                        modal={modal}
                        originOptions={originOptions}
                        originSubTypeOptions={originSubTypeOptions}
                        destinationOptions={destinationOptions}
                        egoiLists={egoiList}
                        egoiTags={egoiTags}
                        tags={this.state.tags}
                        modalData={this.state.modalData}
                        allTags={allTags}
                        entities={this.state.entities}
                    />
                : ''}
            </StyledCard>
        );
    }
};

export default injectIntl(Mappings)