import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Card, Col, Row, Badge, Collapse, UncontrolledPopover, PopoverBody } from 'reactstrap';
import { handleNotification } from "../../Base/Notification";
import { getAPI} from "../../Base/API";
import { FormattedMessage } from "react-intl";
import BlockUi from 'react-block-ui';

import Authorization, { CheckAuthorization } from '../../Base/Authorization';

export class ListCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            block: false
        };
    }

    getExtraFields = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    const list = { ...this.props.list };
                    list.extraFields = data.response;

                    this.setState(prevState => ({ isOpen: !prevState.isOpen }), () => this.props.updateList(this.props.index, list));
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/lists/${this.props.list.list_id}/fields`);
    }

    handleFieldsCollapse = () => {
        if (!this.props.list.extraFields) {
            this.getExtraFields();
        }
        else {
            this.setState(prevState => ({ isOpen: !prevState.isOpen }));
        }
    }

    render() {
        const { list, toggleModal, block } = this.props;

        return (
            <BlockUi tag="div" blocking={block}>
                <Card className="border-0 mb-2 p-3 shadow">
                    <Row>
                        <Col className="pointer" onClick={(e) => { if (CheckAuthorization("egoiList:edit", null)) toggleModal(e, list) }}>
                            <h4><b>{list.public_name}</b></h4>
                        </Col>
                        <Authorization
                            perform="egoiList:delete"
                            yes={() => (
                                <Col className="text-right">
                                    <Button className="btn btn-sm btn-host" onClick={this.props.toggleDeleteModal}>
                                        <i className="fas fa-trash-alt" />
                                    </Button>
                                </Col>
                            )}
                            no={() => <div></div>}
                        />
                    </Row>
                    <Row>
                        <Col>
                            <div><b><FormattedMessage id="EgoiList.name" />: </b>{list.internal_name}</div>
                            <div><Badge color="primary" className="p-1"> {list.status}</Badge> </div>
                        </Col>
                        <Col>
                            <div><b><FormattedMessage id="EgoiList.List" />: </b>{list.list_id}</div>
                            <div><b><FormattedMessage id="EgoiList.Group" />: </b>{list.group_id}</div>
                        </Col>
                        <Col>
                            <div><b><FormattedMessage id="EgoiList.Created" />: </b> {list.created}</div>
                            <div><b><FormattedMessage id="EgoiList.Updated" />: </b> {list.updated}</div>
                        </Col>

                    </Row>

                    <div className="pointer mt-4" onClick={this.handleFieldsCollapse}>
                        <b> {this.state.isOpen ? <i className="fas fa-chevron-down" /> : <i className="fas fa-chevron-right" /> } <FormattedMessage id="EgoiList.ExtraFields" /></b>
                    </div>

                    <Collapse isOpen={this.state.isOpen}>
                        {list.extraFields &&
                            <div className="pl-3 mt-3">
                                <Row className="mb-2">
                                <Col> <b> <FormattedMessage id="EgoiList.Type" /> / <FormattedMessage id="EgoiList.Name" /></b> </Col>
                                <Col sm={2}> <b> <FormattedMessage id="EgoiList.Format" /> </b> </Col>
                                <Col className="col-1 text-center"> <b> <FormattedMessage id="EgoiList.Editable" /> </b> </Col>
                                <Col className="col-1 text-center"> <b> <FormattedMessage id="EgoiList.Unique" /> </b> </Col>
                                <Col> <b> <FormattedMessage id="EgoiList.Options" /> </b> </Col>
                            </Row>

                            {list.extraFields.map((field, key) =>
                                <Row key={key} className="py-2 overbglight border-top">
                                    <Col> <Badge className={"color-white py-1 px-2 mr-2 " + (field.type === 'base' ? ' bg-violet' : ' bg-cyan')}> {field.type} </Badge> <b> {field.name} </b> </Col>
                                    <Col sm={2}> <FormattedMessage id={`EgoiList.${field.format}`} />  </Col>
                                    <Col className="col-1 text-center"> {field.editable ? <i className="text-primary fas fa-lg fa-toggle-on" /> : <i className="text-muted fas fa-lg fa-toggle-off" />} </Col>
                                    <Col className="col-1 text-center"> {field.unique ? <i className="text-primary fas fa-lg fa-toggle-on" /> : <i className="text-muted fas fa-lg fa-toggle-off" />} </Col>
                                    <Col>
                                        {field.options.map((option, k) =>
                                            <span key={k}>
                                                {typeof option === 'object' ?
                                                    <Row>
                                                        <Col className="col-2 pr-0"><b><FormattedMessage id="EgoiList.Option" /> {k + 1}:</b> </Col>
                                                        <Col> <i className="flag-icon flag-icon-gb" />  {option.en} </Col>
                                                        <Col className="col-1 pl-0 text-right">
                                                            <span id={`option${key}-${k}`} className="btn-sm btn-link" onClick={() => true} style={{ fontSize: 'large' }}>
                                                                <i className="text-secondary fas fa-sm fa-info-circle mt-2 " />
                                                            </span>
                                                            <UncontrolledPopover data-trigger="focus" placement='left' target={`option${key}-${k}`}>
                                                                <PopoverBody>
                                                                    <div className="mb-1">
                                                                        {Object.keys(option).filter(el => el !== 'option_id' && el !== 'en').map((lang, index) =>
                                                                            <div key={index}>
                                                                                <b> <i className={`flag-icon flag-icon-${lang}`} /> {lang.toUpperCase()}: </b> {option[lang]}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </PopoverBody>
                                                            </UncontrolledPopover>
                                                        </Col>
                                                    </Row>
                                                    :
                                                    <Badge className="mr-2 py-1" color="primary"> {option} </Badge>
                                                }
                                            </span>
                                        )}
                                    </Col>
                                </Row>
                            )}
                        </div>
                        }

                    </Collapse>
                </Card>
            </BlockUi>
        );
    }
}
export default injectIntl(ListCard)
