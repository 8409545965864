import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Col, Row, Form, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getAllReviewFields, getConsentTypeList } from "../../Base/ReferenceDataFunctions";
import { Tag, getListOptions, getFieldsToMapForms } from "../../Base/MapFormsComponents";
import { handleNotification } from "../../Base/Notification";
import { ActiveInactiveStatusCombo, BlankCard } from "../../Base/CommonUIComponents";
import { postAPI, getAPI } from "../../Base/API";
import CustomSelect from '../../Base/CustomSelect';
import { FormattedMessage } from "react-intl";
import AddTagsModal from "./AddTagsModal";
import { Link } from 'react-router-dom';

class MapForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            questionsList: [],
            openQuestReviews: [],
            optionsQuestReviews: [],
            consents: getConsentTypeList(),
            fields: getFieldsToMapForms(),
            formMap: {
                active: true,
                fieldId: 'email',
                reviewMappingFields: []
            },
            fieldKeys: [],
            tags: [],
            addTagsModal: false
        };
    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let formId = params.get('form');
        let surveyName = "";

        if (this.props.history.location && this.props.history.location.state && this.props.history.location.state.surveyName) {
            surveyName = this.props.history.location.state.surveyName;
        }
        else {
            this.getReviwProForms(formId);
        }

        this.setState({
            formId, surveyName
        }, this.getFormQuestions);

    }

    getReviwProForms = (formId) => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                const surveyName = data.response && data.response.find(el => el.id === formId);

                this.setState({ surveyName: surveyName && surveyName.name });
            }
            this.setState({ error: errorMessage });
        }, '/api/gms/ReviewPro/v1/Surveys');
    }

    getFormQuestions = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    var list = [];

                    data.response.forEach(el => {
                        if (el.questionTitle) {
                            list.push({ value: el.id, label: el.questionTitle, outOf: el.outOf, tagMappings: []/*, reviewMappings: []*/ });
                        }
                    });

                    this.setState({ questions: list }, () => this.getMap());
                }
            }
            this.setState({ error: errorMessage });
        }, `/api/gms/ReviewPro/v1/SurveyQuestions/${this.state.formId}`);
    }

    getMap = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    let questions = this.state.questions;
                    let reviews = getAllReviewFields();

                    if (data.response[0].reviewMappingFields) {
                        data.response[0].reviewMappingFields.forEach(el => {
                            //Review
                            reviews = reviews.filter(r => r.value !== el.reviewMapField);

                            //Mapping fields
                            const questionIndex = questions.findIndex(q => q.value === el.questionMap);

                            if (el.mapType === 'Tag' && questionIndex > -1) {
                                questions[questionIndex].tagMappings.push(el);
                            }
                        })
                    }

                    this.setState({
                        openQuestReviews: reviews.filter(el => el.value === 'Positive' || el.value === 'Negative'),
                        optionsQuestReviews: reviews.filter(el => el.value !== 'Positive' && el.value !== 'Negative'),
                        formMap: data.response[0],
                        reviews,
                        block: false
                    });
                }
                else {

                    const allReviews = getAllReviewFields();

                    this.setState({
                        openQuestReviews: allReviews.filter(el => el.value === 'Positive' || el.value === 'Negative'),
                        optionsQuestReviews: allReviews.filter(el => el.value !== 'Positive' && el.value !== 'Negative'),
                        block: false
                    });
                }

                this.getTagGroups();
            }
            else this.setState({ error: errorMessage, block: false }, this.getTagGroups);
        }, `/api/gms/Review/v1/reviewMapping?listId=0&formId=${this.state.formId}`);
    }

    getTagGroups = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                var list = [], allTags = [];

                data.response && data.response.forEach(group => {
                    const obj = { label: group.code, options: [] };

                    group.tag && group.tag.forEach(tag => {
                        obj.options.push({ value: tag.id, label: tag.code });
                        allTags.push({ value: tag.id, label: tag.code });
                    })

                    list.push(obj);
                })

                this.setState({ tags: list, allTags });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/tag/groupDetails?tagType=all`);
    }

    mapForm = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const body = { ...this.state.formMap }
        body.formId = body.formId ? body.formId : this.state.formId;
        body.listId = '0';
        body.provider = 'ReviewPro';

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="MapFormModal.FormMapped" />, <FormattedMessage id="generic.success" />);
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Review/v1/reviewMapping`, JSON.stringify({ request: body }));
    }



    handleSelectMap = (combo, question, mapType) => {
        const mappingFields = [...this.state.formMap.reviewMappingFields];
        const index = mappingFields.findIndex(el => el.questionMap === question && el.mapType === mapType);

        let availComboOptions = [];
        const comboOptions = getListOptions(mapType);
        const comboName = mapType === 'Consent' ? 'consents' : 'fields';

        if (index === -1) {
            mappingFields.push({
                reviewMapField: combo ? combo.value : null,
                questionMap: question,
                mapType
            })
        }
        else {
            if (combo) {
                mappingFields[index].reviewMapField = combo.value;
            }
            else {
                mappingFields.splice(index, 1)
            }
        }


        //Combo fields can only be used 1 time each.
        comboOptions.forEach(el => {
            if (!mappingFields.some(rmf => rmf.reviewMapField === el.value)) {
                availComboOptions.push(el);
            }
        })


        this.setState({
            formMap: {
                ...this.state.formMap,
                reviewMappingFields: mappingFields
            },
            [comboName]: availComboOptions
        });
    }

    handleChangeReview = (combo, question) => {
        const mappingFields = [...this.state.formMap.reviewMappingFields];
        const index = mappingFields.findIndex(el => el.questionMap === question && el.mapType === 'Review');
        let openQuestReviews = [], optionsQuestReviews = [];


        if (index === -1) {
            mappingFields.push({
                reviewMapField: combo ? combo.value : null,
                questionMap: question,
                mapType: 'Review'
            })
        }
        else {
            if (combo) {
                mappingFields[index].reviewMapField = combo.value;
            }
            else {
                mappingFields.splice(index, 1)
            }
        }


        //Review fields can only be used 1 time each.
        getAllReviewFields().forEach(el => {
            if (!mappingFields.some(rmf => rmf.reviewMapField === el.value)) {
                if (el.value !== 'Positive' && el.value !== 'Negative') {
                    optionsQuestReviews.push(el);
                }
                else openQuestReviews.push(el);
            }
        })

        this.setState({
            formMap: {
                ...this.state.formMap,
                reviewMappingFields: mappingFields
            },
            optionsQuestReviews, openQuestReviews
        });
    }

    getComboValue = (question, mapType) => {
        let value = '';

        const reviewMapping = this.state.formMap.reviewMappingFields && this.state.formMap.reviewMappingFields.find(rm => rm.questionMap === question && rm.mapType === mapType) ;

        if (reviewMapping) {
            const options = getListOptions(mapType);

            value = options.find(r => r.value === reviewMapping.reviewMapField);
        }

        return value;
    }

    addTagsToMap = (e, tagMaps, questionIdx) => {
        e.preventDefault();
        let mappingFields = [...this.state.formMap.reviewMappingFields];
        const questions = [...this.state.questions];

        mappingFields = mappingFields.concat(tagMaps);

        questions[questionIdx].tagMappings = questions[questionIdx].tagMappings.concat(tagMaps);

        this.setState({
            formMap: {
                ...this.state.formMap,
                reviewMappingFields: mappingFields
            },
            questions
        }, () => this.toggleModal(null));
    }

    removeTagMapping = (e, questionIdx, tagIndex) => {
        e.preventDefault();
        let mappingFields = [...this.state.formMap.reviewMappingFields];
        const questions = [...this.state.questions];

        //Remove from tagMappings (array inside question)
        const tagRemoved = questions[questionIdx].tagMappings.splice(tagIndex, 1);

        //Remove from reviewMappingFields (used in API)
        if (tagRemoved.length > 0) {
            const idx = mappingFields.findIndex(el => el.questionMap === tagRemoved[0].questionMap && el.mapType === 'Tag' && el.tagId === tagRemoved[0].tagId && el.tagValue === tagRemoved[0].tagValue);
            mappingFields.splice(idx, 1);
        }

        this.setState({
            formMap: {
                ...this.state.formMap,
                reviewMappingFields: mappingFields
            },
            questions
        });
    }

    toggleModal = (question, idx) => {
        if (question) {
            question.index = idx
        }

        this.setState(prevState => ({
            addTagsModal: !prevState.addTagsModal,
            selectedQuestion: question
        }));
    }

    handleStatus = (name, combo) => {
        const { formMap } = this.state;
        formMap[name] = combo && combo.value;

        this.setState({ formMap });
    }

    render() {
        const { block, error, questions, addTagsModal, tags, selectedQuestion, openQuestReviews, optionsQuestReviews, consents, surveyName, fields, formMap } = this.state;
        
        return (
            <div>
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/ReviewProMenu"> ReviewPro Config</Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to="/reviewProConfig"> Surveys </Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to={`/reviewProInquiry/${this.state.formId}`}> {surveyName || 'Form'} </Link></BreadcrumbItem>
                        <BreadcrumbItem active><FormattedMessage id={`MapFormModal.Map`} /></BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <BlankCard block={block} error={error}>
                    <Form onSubmit={(e) => this.mapForm(e)}>
                        <Row className="mb-3 align-items-center">
                            <Col sm={7}>
                                <h5> <FormattedMessage id={`MapFormModal.Map`} /> <b>{surveyName}</b> </h5>
                            </Col>
                            <Col className="col-1">
                                <FormattedMessage id="generic.status" />
                            </Col>
                            <Col className="col-2 pl-0">
                                <ActiveInactiveStatusCombo
                                    name='active'
                                    onChangeFunc={this.handleStatus}
                                    value={formMap.active}
                                />
                            </Col>
                            <Col className="col-2 text-right">
                                <Button className="btn-sm btn-host" type="submit">
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>

                        {questions ?
                            <div>
                                <Row className="my-4">
                                    <Col sm={3}> <b><FormattedMessage id="MapFormModal.Question" /></b></Col>
                                    <Col> <b><FormattedMessage id="MapFormModal.ReviewField" /></b></Col>
                                    <Col> <b><FormattedMessage id="MapFormModal.Consent" /></b></Col>
                                    <Col> <b><FormattedMessage id="MapFormModal.Field" /></b></Col>
                                    <Col sm={2}> <b><FormattedMessage id="MapFormModal.Tags" /></b> </Col>
                                </Row>

                                {questions.map((question, key) =>
                                    <Row className="mb-3 overbglight align-items-center" key={key}>
                                        <Col sm={3}> {question.label} </Col>

                                        <Col >
                                            <CustomSelect
                                                isSearchable isClearable
                                                placeholder={""}
                                                options={question.outOf ? optionsQuestReviews : openQuestReviews}
                                                onChange={(e) => this.handleChangeReview(e, question.value, 'Review')}
                                                value={this.getComboValue(question.value, 'Review')}
                                            />
                                        </Col>

                                        <Col>
                                            {!question.outOf &&
                                                <CustomSelect
                                                    isSearchable isClearable
                                                    placeholder={""}
                                                    options={consents}
                                                    onChange={(e) => this.handleSelectMap(e, question.value, 'Consent')}
                                                    value={this.getComboValue(question.value, 'Consent')}
                                                />
                                            }
                                        </Col>

                                        <Col>
                                            <CustomSelect
                                                isSearchable isClearable
                                                placeholder={""}
                                                options={fields}
                                                onChange={(e) => this.handleSelectMap(e, question.value, 'Field')}
                                                value={this.getComboValue(question.value, 'Field')}
                                            />
                                        </Col>

                                        <Col className="d-flex align-items-center" sm={2}>
                                            {question.tagMappings && question.tagMappings.length > 0 && question.tagMappings.map((tag, idx) =>
                                                <Tag
                                                    key={idx}
                                                    idx={idx}
                                                    tagLabel={this.state.allTags && this.state.allTags.some(t => t.value === tag.tagId) ? this.state.allTags.find(t => t.value === tag.tagId).label : tag.tagId}
                                                    tagValue={tag.tagValue}
                                                    removeTagMapping={(e) => this.removeTagMapping(e, key, idx)}
                                                />
                                            )}

                                            <Button className="btn-sm bg-host-gradient" onClick={() => this.toggleModal(question, key)}>
                                                <i className="fas fa-plus" /> <FormattedMessage id="MapFormModal.NewTag" />
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </div>
                        : '' }
                    </Form>
                </BlankCard>

                {addTagsModal ?
                    <AddTagsModal
                        tags={tags}
                        selectedQuestion={selectedQuestion}
                        addTagModal={addTagsModal}
                        toggleModal={this.toggleModal}
                        addTagsToMap={this.addTagsToMap}
                        title={selectedQuestion.label}
                        questionMapValue={selectedQuestion.value}
                    />
                : ''}
            </div>
        );
    }
}
export default injectIntl(MapForm)
