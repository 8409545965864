import React, { Component } from 'react'
import { deleteAPI, getAPI, patchAPI, postAPI, putAPI } from '../../Base/API';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Badge, Button, Card, CardBody, Col, Form, Input, Modal, ModalBody, Row } from 'reactstrap';
import { KebabMenu, StyledCard, CommonHelper } from '../../Base/CommonUIComponents';
import { handleNotification } from '../../Base/Notification';
import ConfirmActionModal from '../../Base/ConfirmActionModal';
import CustomSelect from '../../Base/CustomSelect';
import { getSendGridScopes } from '../../Base/ReferenceDataFunctions';
import moment from 'moment-timezone';

class SendGridAdmin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: true,
            error: null,
            subUsers: [],
            modal: false,
            newAccount: {},
            errorModal: null,
            blockModal: false,
            deleteModal: false,
            deleteApiKeyModal: false,
            ips: [],
            hotelGroups: [],
            apiKeysModal: false,
            apiKeys: [],
            newApiKeyName: null,
            customScopesOptions: [
                { value: true, label: this.props.intl.formatMessage({ id: "SendGridAdmin.Yes" }) },
                { value: false, label: this.props.intl.formatMessage({ id: "SendGridAdmin.No" }) }
            ],
            selectCustomScopes: false,
            selectedScopes: [],
            credit: {},
            creditTypeOptions: [
                { value: "unlimited", label: this.props.intl.formatMessage({ id: "SendGridAdmin.Unlimited" }) },
                { value: "recurring", label: this.props.intl.formatMessage({ id: "SendGridAdmin.Recurring" }) },
                { value: "nonrecurring", label: this.props.intl.formatMessage({ id: "SendGridAdmin.NonRecurring" }) }   
            ],
            creditFrequencyOptions: [
                { value: "daily", label: this.props.intl.formatMessage({ id: "SendGridAdmin.Daily" }) },
                { value: "weekly", label: this.props.intl.formatMessage({ id: "SendGridAdmin.Weekly" }) },
                { value: "monthly", label: this.props.intl.formatMessage({ id: "SendGridAdmin.Monthly" }) }
            ],
            topUpAction: 'increment'
        }
    }

    componentDidMount() {
        this.getSubUsersStats();
    }

    getSubUsers = () => {
        const { subUsers } = this.state;
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }

                if(data.response){
                    data.response.forEach(el => {
                        const curr = subUsers?.find(({username}) => username === el.username);

                        if(curr) {
                            curr.disabled = false;
                            curr.email = el.email
                            curr.id = el.id
                        };
                    });
                    subUsers.forEach(el => {
                        el.disabled = el.email ? false : true;
                    });
                }

                this.setState({ subUsers, block: false });
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/gms/SendGrid/v1/subuser`);
    }

    getSubUsersStats = () => {
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }

                const subUsers = data.response?.stats?.filter(({type}) => type === "subuser").map((s) => ({
                        disabled: false,
                        email: null,
                        id: null,
                        username: s.name,
                        first_name: s.first_name,
                        last_name: s.last_name,
                        metrics: s.metrics,
                    })
                );
                
                this.setState({ subUsers }, () => this.getSubUsers());
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/gms/SendGrid/v1/subuser/stats`);
    }

    deleteSubUser = () => {
        const { name } = this.state;
        this.setState({ block: true }, () =>
            deleteAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data, <FormattedMessage id="HotelGroup.HotelGroupSaved" />, <FormattedMessage id="generic.success" />);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    this.setState({ name: null, deleteModal: false }, () => this.getSubUsersStats());
                }
                else {
                    this.setState({ block: false });
                }
            }, `/api/gms/SendGrid/v1/subuser/${name}`)
        );
    }

    getIps = () => {
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ erroModalr: errorMessage, blockModal: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ errorModal: errorMessage, blockModal: false });
                    return;
                }
                this.setState({ ips: data.response?.map(({ip}) => ({ value: ip, label: ip })) }, () => this.getHotelGroupList());
            }
            else {
                this.setState({ blockModal: false });
            }
        }, `/api/gms/SendGrid/v1/subuser/ips`);
    }

    getHotelGroupList = () => {
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ erroModalr: errorMessage, blockModal: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ errorModal: errorMessage, blockModal: false });
                    return;
                }
                this.setState({ hotelGroups: data.response?.map(({name}) => ({ value: name, label: name })), blockModal: false });
            }
            else {
                this.setState({ blockModal: false });
            }
        }, `/api/gms/Hotel/v1/hotelgroup/list?pageSize=10000`);
    }

    toggleDeleteModal = (name) => {
        this.setState({ deleteModal: !this.state.deleteModal, name: name });
    }

    toggleDeleteApiKeyModal = () => {
        this.setState({ deleteApiKeyModal: !this.state.deleteApiKeyModal });
    }

    toogleState = (name, disabled) => {
        const { subUsers } = this.state;
        this.setState({ block: true }, () => 
            patchAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    const curr = subUsers?.find(({username}) => username === name);
                    curr.disabled = !disabled;
                    this.setState({ subUsers, block: false });
                }
                this.setState({ block: false });
            }, `/api/gms/SendGrid/v1/subuser/enabled?username=${name}&disabled=${!disabled}`)
        );
    }

    toggleModal = (name, getCredit) => {
        const { modal, ips } = this.state;
        const getIps = !ips || ips.length === 0;
        this.setState({ name, modal: !modal, blockModal: getIps || getCredit }, () => {
            if(getIps)
                this.getIps();
            if(getCredit)
                this.getUsersCredit(name);
        });
    }

    createNewSubUser = (e) => {
        e.preventDefault();
        var { newAccount } = this.state;

        if (newAccount && newAccount.userName) {
            newAccount.userName = newAccount.userName.normalize("NFD")?.replace(/[^\w\s]/gi, "");
        }

        if(!newAccount.userName){
            handleNotification({errors: [{message: "SendGridAdmin.NameError"}]});
            return;
        }

        this.setState({ blockModal: true }, () =>
            postAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ errorModal: errorMessage, blockModal: false });

                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ errorModal: errorMessage, blockModal: false });
                        return;
                    }

                    this.setState({ modal: false, newAccount: {}, blockModal: false }, () => this.getSubUsersStats());
                }
                else {
                    this.setState({ blockModal: false });
                }
            }, `/api/gms/SendGrid/v1/subuser`, newAccount)
        );
    }
    
    handleChange = (e) => {
        const { newAccount } = this.state;
        if(newAccount){
            newAccount[e.target.name] = e.target.value;
        }
        this.setState({ newAccount });
    }

    handleIpsChange = (combo) => {
        const { newAccount } = this.state;
        if(newAccount){
            newAccount.ips = combo?.map(({value}) => value);
        }
        this.setState({ newAccount });
    }

    handleUserNameChange = (combo) => {
        const { newAccount } = this.state;
        if(newAccount){
            newAccount.userName = combo?.value;
        }
        this.setState({ newAccount });
    }

    toogleApiKeysModal = (name) => {
        const { apiKeysModal } = this.state;
        this.setState({ apiKeysModal: !apiKeysModal, blockModal: name ? true : false }, () => {
            if(name)
                this.getApiKeys(name);
        });
    }

    getApiKeys = (name) => {
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ erroModalr: errorMessage, blockModal: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ errorModal: errorMessage, blockModal: false });
                    return;
                }
                this.setState({ apiKeys: data.response?.result, blockModal: false, name });
            }
            else {
                this.setState({ blockModal: false });
            }
        }, `/api/gms/SendGrid/v1/subuser/apikey?name=${name}`);
    }

    updateSubUserName = (e) => {
        e.preventDefault();
        const { credit } = this.state;

        this.setState({ blockModal: true }, () => putAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ erroModalr: errorMessage, blockModal: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ errorModal: errorMessage, blockModal: false });
                    return;
                }
                handleNotification(data, <FormattedMessage id="SendGridAdmin.SubUserNameChanged" values={{ name: credit.newName }} />, <FormattedMessage id="generic.success" />);
                this.setState({ blockModal: false, credit: { ...this.state.credit, name: credit.newName } }, () => this.getSubUsersStats());
            }
            else {
                this.setState({ blockModal: false });
            }
        }, `/api/gms/SendGrid/v1/subuser/username?subuser=${credit.name}&&name=${credit.newName}`));
    }

    createNewApiKey = (e) => {
        e.preventDefault();
        const { newApiKeyName, selectedScopes, selectCustomScopes, apiKeys, name } = this.state;
        const rq = {
            name: newApiKeyName,
            isDefault: selectCustomScopes,
            userScopes: selectedScopes
        }
        this.setState({ blockModal: true }, () =>
            postAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ erroModalr: errorMessage, blockModal: false });

                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ errorModal: errorMessage, blockModal: false });
                        return;
                    }
                    if(data.response){
                        apiKeys.push({ name: data.response.name, api_key_id: data.response.api_key_id, api_key: data.response.api_key });

                        this.setState({ blockModal: false, apiKeys, newApiKeyName: null, selectCustomScopes: false, selectedScopes: [] }, () => 
                            handleNotification(data, <FormattedMessage id="SendGridAdmin.ApiKeySaved" />, <FormattedMessage id="generic.success" />)
                        );
                    }
                    else {
                        this.setState({ blockModal: false });
                    }
                }
                else {
                    this.setState({ blockModal: false });
                }
            }, `/api/gms/SendGrid/v1/subuser/apikey?name=${name}`, rq)
        );
    }

    deleteApiKey = (apiKeyId, subUserName) => {
        const { name } = this.state;

        this.setState({ block: true }, () =>
            deleteAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="SendGridAdmin.ApiKeyDeleted" />, <FormattedMessage id="generic.success" />);
                    this.setState({ block: false, deleteApiKeyModal: false }, () => this.getApiKeys(subUserName));
                }
                else {
                    this.setState({ block: false });
                }
            }, `/api/gms/SendGrid/v1/subuser/apikey/${apiKeyId}?name=${name}`)
        );
    }

    copy = (content) => {
        navigator.clipboard.writeText(content);
    }

    handleNewApiKeyNameChange = (e) => {
        this.setState({ newApiKeyName: e.target.value });
    }

    handleCustomScopesOptions = (combo) => {
        this.setState({ selectCustomScopes: combo?.value });
    }

    handleScopesChange = (combo) => {
        this.setState({ selectedScopes: combo?.map(({value}) => value) });
    }

    handleCreditTotalChange = (e) => {
        this.setState({ credit: {...this.state.credit, total: e.target.value} });
    }

    handleSubUserNameChange = (e) => {
        this.setState({ credit: { ...this.state.credit, newName: e.target.value } });
    }

    handleCreditTypeChange = (combo) => {
        this.setState({ credit: {...this.state.credit, type: combo?.value} });
    }

    handleCreditFrequencyChange = (combo) => {
        this.setState({ credit: {...this.state.credit, resetFrequency: combo?.value} });
    }

    submitCredit = (e) => {
        e.preventDefault();
        const { credit, apiKeys, name } = this.state;
        
        const rq = {
            type: credit.type,
            total: credit.total,
            resetFrequency: credit.resetFrequency
        };
        
        if(credit.type !== "recurring"){
            delete rq.resetFrequency;
        }
        
        if(credit.type === "unlimited"){
            delete rq.total;
        }
        
        this.setState({ blockModal: true }, () =>
            putAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ erroModalr: errorMessage, blockModal: false });

                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ errorModal: errorMessage, blockModal: false });
                        return;
                    }
                    if(data.response){
                        apiKeys.push({ name: data.response.name, api_key_id: data.response.api_key_id, api_key: data.response.api_key });

                        this.setState({ blockModal: false, apiKeys, newApiKeyName: null, selectCustomScopes: false, selectedScopes: [] }, () => 
                            handleNotification(data, <FormattedMessage id="SendGridAdmin.CreditDefined" />, <FormattedMessage id="generic.success" />)
                        );
                    }
                    else {
                        this.setState({ blockModal: false });
                    }
                }
                else {
                    this.setState({ blockModal: false });
                }
            }, `/api/gms/SendGrid/v1/subuser/credits?name=${name}`, rq)
        );
    }

    getUsersCredit = (name) => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorModal: errorMessage, blockModal: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ errorModal: errorMessage, blockModal: false });
                    return;
                }

                const credit = {
                    name: name,
                    newName: name,
                    remain: data.response?.remain,
                    total: data.response?.total,
                    overage: data.response?.overage,
                    used: data.response?.used,
                    lastReset: data.response?.last_reset,
                    nextReset: data.response?.next_reset,
                    resetFrequency: data.response?.reset_frequency,
                    type: data.response?.type ?? "recurring"
                };

                this.setState({ credit, blockModal: false });
            }
        }, `/api/gms/SendGrid/v1/subuser/credits?name=${name}`);
    }

    topUp = (e) => {
        e.preventDefault();
        const { name, topUpAction, topUpAmount } = this.state;
        
        this.setState({ blockModal: true }, () =>
            postAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ erroModalr: errorMessage, blockModal: false });

                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ errorModal: errorMessage, blockModal: false });
                        return;
                    }
                    if(data.response){
                        this.setState({ blockModal: false }, () => 
                            handleNotification(data, <FormattedMessage id="SendGridAdmin.SubUserTopedUp" />, <FormattedMessage id="generic.success" />)
                        );
                    }
                    else {
                        this.setState({ blockModal: false });
                    }
                }
                else {
                    this.setState({ blockModal: false });
                }
            }, `/api/gms/SendGrid/v1/subuser/topup?username=${name}&credits=${topUpAction === 'increment' ? topUpAmount : `-${topUpAmount}`}`)
        );
    }

    handleTopUpAmount = (e) => {
        this.setState({ topUpAmount: e.target.value });
    }

    handleTopUpAction = (e) => {
        this.setState({ topUpAction: e.value });
    }

    downloadMonthlyStats = (name) => {
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if(data.response && data.response.stats && data.response.stats[0]){
                        const stat = data.response.stats[0].metrics;
                        const csv = [
                            'blocks,bounce_drops,bounces,clicks,deferred,delivered,invalid_emails,opens,processed,requests,spam_report_drops,spam_reports,unique_clicks,unique_opens,unsubscribe_drops,unsubscribes',
                        ];

                        csv.push(`${stat.blocks},${stat.bounce_drops},${stat.bounces},${stat.clicks},${stat.deferred},${stat.delivered},${stat.invalid_emails},${stat.opens},${stat.processed
                        },${stat.requests},${stat.spam_report_drops},${stat.spam_reports},${stat.unique_clicks},${stat.unique_opens},${stat.unsubscribe_drops},${stat.unsubscribes}`);

                        const csvString = csv.join('\n');
                        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.setAttribute('download', `${name}-monthly-stats.csv`);
                        link.click();
                        this.setState({ block: false });
                    }
                    else {
                        this.setState({ block: false });
                    }
                }
                else {
                    this.setState({ block: false });
                }
            }, `/api/gms/SendGrid/v1/subuser/monthlystats?username=${name}&date=${moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD')}`);
        });
    }

    render() {
        const { block, error, subUsers, modal, newAccount, errorModal, blockModal, deleteModal, deleteApiKeyModal, ips, hotelGroups, apiKeysModal, apiKeys, newApiKeyName,
            customScopesOptions, selectCustomScopes, selectedScopes, credit, creditTypeOptions, creditFrequencyOptions, topUpAction, topUpAmount } = this.state;

        const topUpActionOptions = [
            { value: 'increment', label: <FormattedMessage id="SendGridAdmin.Increment" /> },
            { value: 'decrement', label: <FormattedMessage id="SendGridAdmin.Decrement" /> }
        ]

        return (
            <StyledCard block={block} error={error}>
                <Row>
                    <Col>
                        <h5>
                            <i className="fas fa-envelope-open-text mr-1" />
                            <FormattedMessage id="SendGridAdmin.Title"/>
                        </h5>
                    </Col>
                    <Col className="text-right col-2">
                        <Button className="btn btn-sm btn-host" onClick={_ => this.toggleModal()}>
                            <i className="fas fa-plus"></i>
                        </Button>
                    </Col>
                </Row>

                {subUsers ?
                    <div className='mt-2'>
                        {subUsers.map((subUser, key) =>
                            <Card key={key} className="border-0 shadow mt-2">
                                <CardBody>
                                    <Row>
                                        <Col className='d-flex align-items-center'>
                                            <div>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SendGridAdmin.UserName"/>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <b>{subUser.username}</b>
                                                    {subUser.disabled ?
                                                        <Badge color='secondary' className='ml-2'>
                                                            <FormattedMessage id="SendGridAdmin.Disabled"/>
                                                        </Badge>
                                                    :
                                                        <Badge color='primary' className='ml-2'>
                                                            <FormattedMessage id="SendGridAdmin.Enabled"/>
                                                        </Badge>
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SendGridAdmin.Email"/>
                                                </div>
                                                <div>{subUser.email}</div>
                                            </div>
                                        </Col>
                                        <Col className='col-2 d-flex align-items-center justify-content-end'>
                                            <KebabMenu
                                                deleteFunction={_ => this.toggleDeleteModal(subUser.username)}
                                                editFunction={_ => this.toggleModal(subUser.username, true)}
                                                extraFields={[{
                                                    icon: 'fas fa-key mr-2',
                                                    text: 'SendGridAdmin.ApiKeys',
                                                    function: _ => this.toogleApiKeysModal(subUser.username)
                                                },{
                                                    icon: 'fas fa-cloud-download-alt mr-2',
                                                    text: 'SendGridAdmin.DownloadMonthlyStats',
                                                    function: _ => this.downloadMonthlyStats(subUser.username)
                                                },{
                                                    icon: 'far fa-check-square mr-2',
                                                    text: subUser.disabled ? 'SendGridAdmin.Enable' : 'SendGridAdmin.Disabled',
                                                    function: _ => this.toogleState(subUser.username, subUser.disabled)
                                                }]}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        )}
                    </div>
                :''}

                {modal ?
                    <Modal isOpen={modal} size={"lg"} style={{ minWidth: '0' }}>
                        <Button onClick={_ => this.toggleModal()} className="closeModal">
                            <i className="fas fa-times fa-sm"></i>
                        </Button>
                        <ModalBody>
                            <StyledCard block={blockModal} error={errorModal}>
                                <CardBody>
                                    {this.state.name ?
                                        <>
                                            <Form onSubmit={this.updateSubUserName}>
                                                <Row>
                                                    <Col>
                                                        <b>
                                                            <FormattedMessage id="SendGridAdmin.SubUser" />
                                                        </b>
                                                    </Col>
                                                    <Col className='col-2 text-right'>
                                                        <Button type='submit' className="btn btn-sm btn-host">
                                                            <i className="fas fa-save" ></i>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Row className='mt-2'>
                                                    <Col>
                                                        <div>
                                                            <FormattedMessage id="SendGridAdmin.UserName" />
                                                        </div>
                                                        <Input
                                                            type='text'
                                                            name='username'
                                                            required={true}
                                                            value={credit.newName}
                                                            onChange={this.handleSubUserNameChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form>
                                            <Form onSubmit={this.submitCredit}>
                                                <Row className='mt-4'>
                                                    <Col>
                                                        <b>
                                                            <FormattedMessage id="SendGridAdmin.CreditManagement"/>
                                                        </b>
                                                    </Col>
                                                    <Col className='col-2 text-right'>
                                                        <Button type='submit' className="btn btn-sm btn-host float-right">
                                                            <i className="fas fa-save" ></i>
                                                        </Button>
                                                        <CommonHelper help={<FormattedMessage id="SendGridAdmin.ChangeType" />} id={'SgAlertType'} placement="bottom"/>
                                                    </Col>
                                                </Row>
                                                <Row className='mt-2'>
                                                    <Col>
                                                        <div>
                                                            <FormattedMessage id="SendGridAdmin.type"/>
                                                        </div>
                                                        <CustomSelect
                                                            options={creditTypeOptions}
                                                            onChange={this.handleCreditTypeChange}
                                                            value={credit.type ? creditTypeOptions.find(opt => credit.type === opt.value) : ''}
                                                            placeholder={''}
                                                            required={true}
                                                            isClearable={false}
                                                        />
                                                    </Col>
                                                    {credit.type === "recurring" ?
                                                        <Col>
                                                            <div>
                                                                <FormattedMessage id="SendGridAdmin.reset_frequency"/>
                                                            </div>
                                                            <CustomSelect
                                                                options={creditFrequencyOptions}
                                                                onChange={this.handleCreditFrequencyChange}
                                                                value={credit.resetFrequency ? creditFrequencyOptions.find(opt => credit.resetFrequency === opt.value) : ''}
                                                                placeholder={''}
                                                                required={true}
                                                                isClearable={false}
                                                            />
                                                        </Col>
                                                    :''}
                                                    {credit.type !== "unlimited" ?
                                                        <Col>
                                                            <div>
                                                                <FormattedMessage id="SendGridAdmin.total"/>
                                                            </div>
                                                            <Input
                                                                type='number'
                                                                min='0'
                                                                max='99999999999999'
                                                                name='total'
                                                                required={true}
                                                                value={credit.total}
                                                                onChange={this.handleCreditTotalChange}
                                                            />
                                                        </Col>
                                                    :''}
                                                </Row>
                                            </Form>
                                            <Form onSubmit={this.topUp}>
                                                <Row className='mt-4'>
                                                    <Col>
                                                        <b>
                                                            <FormattedMessage id="SendGridAdmin.TopUpSubUser"/>
                                                        </b>
                                                    </Col>
                                                    <Col className='text-right'>
                                                        <Button type='submit' className="btn btn-sm btn-host">
                                                            <i className="fas fa-save" ></i>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Row className='mt-2'>
                                                    <Col>
                                                        <div>
                                                            <FormattedMessage id="SendGridAdmin.action"/>
                                                        </div>
                                                        <CustomSelect
                                                            options={topUpActionOptions}
                                                            onChange={this.handleTopUpAction}
                                                            value={topUpActionOptions.find(opt => topUpAction === opt.value)}
                                                            placeholder={''}
                                                            required={true}
                                                            isClearable={false}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <div>
                                                            <FormattedMessage id="SendGridAdmin.Amount"/>
                                                        </div>
                                                        <Input
                                                            type='number'
                                                            min='0'
                                                            required={true}
                                                            max='99999'
                                                            name='amount'
                                                            value={topUpAmount}
                                                            onChange={this.handleTopUpAmount}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </>
                                    :
                                        <Form onSubmit={this.createNewSubUser}>
                                            <Row>
                                                <Col>
                                                    <h5>
                                                        <FormattedMessage id="SendGridAdmin.AddSubUser"/>
                                                    </h5>
                                                </Col>
                                                <Col className='col-2 text-right'>
                                                    <Button type='submit' className="btn btn-sm btn-host">
                                                        <i className="fas fa-save" ></i>
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row className='mt-2'>
                                                <Col className='col-6 mt-2'>
                                                    <div>
                                                        <div>
                                                            <FormattedMessage id="SendGridAdmin.UserName"/>
                                                        </div>
                                                        <div className='mt-2'>
                                                            <CustomSelect
                                                                options={hotelGroups}
                                                                onChange={this.handleUserNameChange}
                                                                disabled={this.state.name ? true : false}
                                                                value={newAccount && newAccount.userName ? hotelGroups.find(opt => newAccount.userName === opt.value) : ''}
                                                                placeholder={''}
                                                                required={true}
                                                                isClearable={false}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className='col-6 mt-2'>
                                                    <div>
                                                        <div>
                                                            <FormattedMessage id="SendGridAdmin.Email"/>
                                                        </div>
                                                        <div className='mt-2'>
                                                            <Input
                                                                type='email'
                                                                name='email'
                                                                required={true}
                                                                value={newAccount && newAccount.email}
                                                                disabled={this.state.name ? true : false}
                                                                onChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className='col-6 mt-2'>
                                                    <div>
                                                        <div>
                                                            <FormattedMessage id="SendGridAdmin.Password"/>
                                                        </div>
                                                        <div className='mt-2'>
                                                            <Input
                                                                type='text'
                                                                name='password'
                                                                required={true}
                                                                value={newAccount && newAccount.password}
                                                                disabled={this.state.name ? true : false}
                                                                onChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className='col-6 mt-2'>
                                                    <div>
                                                        <div>
                                                            Ips
                                                        </div>
                                                        <div className='mt-2'>
                                                            <CustomSelect
                                                                options={ips}
                                                                onChange={this.handleIpsChange}
                                                                value={newAccount && newAccount.ips ? ips.filter(opt => newAccount.ips.find(m => opt.value === m) !== undefined) : ''}
                                                                disabled={this.state.name ? true : false}
                                                                placeholder={''}
                                                                required={true}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    }
                                </CardBody>
                            </StyledCard>
                        </ModalBody>
                    </Modal>
                :''}
                
                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={_ => this.toggleDeleteModal()}
                        actionFunction={this.deleteSubUser}
                        text={<FormattedMessage id="SendGridAdmin.ConfirmDeleteText"/>}
                        block={block}
                    />
                : ''}

                {apiKeysModal ?
                    <Modal isOpen={apiKeysModal} size={"xl"} style={{ minWidth: '0' }}>
                        <Button onClick={_ => this.toogleApiKeysModal()} className="closeModal">
                            <i className="fas fa-times fa-sm"></i>
                        </Button>
                        <ModalBody>
                            <StyledCard block={blockModal} error={errorModal}>
                                <CardBody>
                                    <Form onSubmit={this.createNewApiKey}>
                                        <Row>
                                            <Col>
                                                <h5>
                                                    <FormattedMessage id="SendGridAdmin.AddApiKey"/>
                                                    <span className='mx-2'>-</span>
                                                    <b>{this.state.name}</b>
                                                </h5>
                                            </Col>
                                            <Col className='col-2 text-right'>
                                                <Button type='submit' className="btn btn-sm btn-host">
                                                    <i className="fas fa-save" ></i>
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='col-6 mt-2'>
                                                <div>
                                                    <FormattedMessage id="SendGridAdmin.ApiKeyName"/>
                                                </div>
                                                <Input
                                                    type='text'
                                                    name='name'
                                                    required={true}
                                                    value={newApiKeyName}
                                                    onChange={this.handleNewApiKeyNameChange}
                                                />
                                            </Col>
                                            <Col className='col-6 mt-2'>
                                                <div>
                                                    <FormattedMessage id="SendGridAdmin.CustomScopes"/>
                                                </div>
                                                <CustomSelect
                                                    options={customScopesOptions}
                                                    onChange={this.handleCustomScopesOptions}
                                                    value={customScopesOptions.find(opt => opt.value === selectCustomScopes)}
                                                    placeholder={''}
                                                    required={true}
                                                />
                                            </Col>
                                            {selectCustomScopes ?
                                                <Col className='col-12 mt-2'>
                                                    <div>
                                                        <FormattedMessage id="SendGridAdmin.Scopes"/>
                                                    </div>
                                                    <CustomSelect
                                                        options={getSendGridScopes()}
                                                        onChange={this.handleScopesChange}
                                                        value={selectedScopes ? getSendGridScopes().filter(opt => selectedScopes.find(m => opt.value === m) !== undefined) : ''}
                                                        placeholder={''}
                                                        required={true}
                                                        isMulti
                                                    />
                                                </Col>
                                            :''}
                                        </Row>
                                    </Form>
                                    <hr/>
                                    <div>
                                        <h5>
                                            <FormattedMessage id="SendGridAdmin.ConfiguredApiKeys"/>
                                            <span className='mx-2'>-</span>
                                            <b>{this.state.name}</b>
                                        </h5>
                                    </div>
                                    {apiKeys.map((key, k) =>
                                        <Row key={k}>
                                            <Col className='col-4'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SendGridAdmin.ApiKeyName"/>
                                                </div>
                                                <div>
                                                    {key.name}
                                                </div>
                                            </Col>
                                            <Col className='col-4'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SendGridAdmin.ApiKeyId"/>
                                                </div>
                                                <div>
                                                    {key.api_key_id}
                                                </div>
                                            </Col>
                                            {key.api_key ?
                                                <Col className='col-3'>
                                                    <div className='title-sm'>
                                                        <FormattedMessage id="SendGridAdmin.ApiKey"/>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-between text-truncate'>
                                                        <div className='text-truncate'>{key.api_key}</div>
                                                        <div className='px-2'>
                                                            <i className="far fa-copy cursor-pointer" onClick={_ => this.copy(key.api_key)}/>
                                                        </div>
                                                    </div>
                                                </Col>
                                            :''}
                                            <Col className={`${key.api_key ? 'col-1' : 'col-4'} d-flex align-items-center justify-content-end`}>
                                                <i className="fas fa-trash-alt" onClick={() => this.toggleDeleteApiKeyModal() /* this.deleteApiKey(key.api_key_id, this.state.name) */ }></i>
                                            </Col>
                                            {deleteApiKeyModal ?
                                                <ConfirmActionModal
                                                    modal={deleteApiKeyModal}
                                                    toggleModal={_ => this.toggleDeleteApiKeyModal()}
                                                    actionFunction={() => this.deleteApiKey(key.api_key_id, this.state.name)}
                                                    text={<FormattedMessage id="SendGridAdmin.ConfirmApiKeyDeleteText"/>}
                                                    block={block}
                                                />
                                            : ''}
                                        </Row>
                                    )}
                                </CardBody>
                            </StyledCard>
                        </ModalBody>
                    </Modal>
                :''}
            </StyledCard>
        )
    }
}

export default injectIntl(SendGridAdmin)