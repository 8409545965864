import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { BlankCard, StyledModal } from "../../../Base/CommonUIComponents";
import { Button, Col, Row, Input, FormGroup, Form, Label  } from "reactstrap";
import { handleNotification } from "../../../Base/Notification";
import CustomSelect from "../../../Base/CustomSelect";
import { patchAPI, postAPI } from "../../../Base/API";
import * as countryList from "react-select-country-list";
import { getCountryISO2, getCountryISO3, getStates } from "../../../Base/ReferenceDataFunctions";

class SenderDetails extends Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            sender: this.props.selectedSender || {}
        }
    }

    createSender = (e) => {
        e.preventDefault();
        this.setState({ block: true });
        
        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                    return;
                }
                else {
                    handleNotification(data, <FormattedMessage id="SenderDetails.SenderSavedSuccessfully" />, <FormattedMessage id="generic.success" />);

                    this.props.getVerifiedSenders();
                    this.props.toggleModal();
                }
            }
            else this.setState({ block: false });
        }, '/api/gms/SendGrid/v1/sender', JSON.stringify({ ...this.state.sender } ));
    }

    editSender = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        patchAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="SenderDetails.SenderSavedSuccessfully" />, <FormattedMessage id="generic.success" />);
                    
                    this.props.getVerifiedSenders();
                    this.props.toggleModal();
                }
            }
            else this.setState({ block: false });
        }, `/api/gms/SendGrid/v1/sender/${this.state.sender.id}`, {...this.state.sender });
    }
    

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState(prevState => ({
            sender: {
                ...prevState.sender,
                [name]: value
            }
        }));
    }

    handleChangeCombo = (name, combo) => {
        this.setState(prevState => ({
            sender: {
                ...prevState.sender,
                [name]: combo ? combo.value : null
            }
        }));
    }

    handleChangeCountry = (combo) => {
        this.setState(prevState => ({
            sender: {
                ...prevState.sender,
                country: combo ? getCountryISO3(combo.value) : null
            }
        }));
    }


    render() {
        const { modal, toggleModal } = this.props;
        const { block, error, sender } = this.state;

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="xl">
                <BlankCard block={block} error={error}>
                    <Form onSubmit={sender.id ? this.editSender : this.createSender}>
                        <Row className="align-items-center mb-3">
                            <Col>                                
                                <h5>{sender.id ? <FormattedMessage id="SenderDetails.EditSender" /> : <FormattedMessage id="SenderDetails.CreateSender" /> } </h5>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host" type="submit">
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>

                        <FormGroup row>
                            <Col sm={6}>
                                <Label><FormattedMessage id="SenderDetails.FromName" /></Label >
                                <Input
                                    type="text"
                                    maxLength="256"
                                    name="from_name"
                                    value={sender.from_name || ''}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Col>
                            <Col sm={6}>
                                <Label><FormattedMessage id="SenderDetails.Nickname" /></Label >
                                <Input
                                    type="text"
                                    maxLength="100"
                                    name="nickname"
                                    value={sender.nickname || ''}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col sm={6}>
                                <Label><FormattedMessage id="SenderDetails.FromEmail" /></Label >
                                <Input
                                    type="email"
                                    maxLength="256"
                                    name="from_email"
                                    value={sender.from_email || ''}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Col>
                            <Col sm={6}>
                                <Label><FormattedMessage id="SenderDetails.ReplyTo" /></Label >
                                <Input
                                    type="text"
                                    maxLength="256"
                                    name="reply_to"
                                    value={sender.reply_to || ''}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Col>
                                <Label><FormattedMessage id="SenderDetails.CompanyAddress" /></Label >
                                <Input
                                    type="text"
                                    maxLength="100"
                                    name="address"
                                    value={sender.address || ''}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col>
                                <Label><FormattedMessage id="SenderDetails.CompanyAddress2" /></Label >
                                <Input
                                    type="text"
                                    maxLength="100"
                                    name="address2"
                                    value={sender.address2 || ''}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Col sm={6}>
                                <Label><FormattedMessage id="SenderDetails.ZipCode" /></Label >
                                <Input
                                    type="text"
                                    maxLength="10"
                                    name="zip"
                                    value={sender.zip || ''}
                                    onChange={this.handleChange}
                                />
                            </Col>
                            <Col sm={6}>
                                <Label><FormattedMessage id="SenderDetails.City" /></Label >
                                <Input
                                    type="text"
                                    maxLength="150"
                                    name="city"
                                    value={sender.city || ''}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col sm={6}>
                                <Label><FormattedMessage id="SenderDetails.State" /></Label >
                                <CustomSelect
                                    options={getStates()}
                                    placeholder=""
                                    value={sender.state ? getStates().find(country => country.value === sender.state) : ''}
                                    onChange={(combo) => this.handleChangeCombo('state', combo)}
                                    isClearable isSearchable
                                />
                            </Col>
                            <Col sm={6}>
                                <Label><FormattedMessage id="SenderDetails.Country" /></Label>
                                <CustomSelect
                                    options={countryList().getData()}
                                    placeholder=""
                                    value={sender.country ? countryList().getData().find(country => country.value === getCountryISO2(sender.country)) : ''}
                                    onChange={(combo) => this.handleChangeCountry(combo)}
                                    isClearable isSearchable
                                    required
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                </BlankCard>
            </StyledModal>
        );
    }
}

export default injectIntl(SenderDetails);