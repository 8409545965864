import React, { Component } from 'react'
import { Button, Col, Input, Modal, ModalBody, Row, Form } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import { BlankCard } from "../../Base/CommonUIComponents";

class TwilioPhoneFriendlyNameModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Sid: this.props.selectedPhone?.sid,
            Name: this.props.selectedPhone?.name
        };
    }

    changeSubAccountName = (e) => {
        this.setState({ Name: e.target.value });
    }

    render() {
        const { block, error, modal } = this.props;
        const { Sid, Name } = this.state;

        return (
            <Modal isOpen={modal} fade={false} size={"lg"} style={{ minWidth: 0 }} >
                <Button onClick={_ => this.props.toggleModal(null)} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <Form
                        onSubmit={(e) => this.props.changeFriendlyName(e, Sid, Name)}
                    >
                        <BlankCard block={block} error={error}>
                            <Row>
                                <Col>
                                    <h5>
                                        <i className="fas fa-comments mr-1" />
                                        <FormattedMessage id="TwilioMessaging.EditPhoneTitle" />
                                    </h5>
                                </Col>
                                <Col className="text-right col-2">
                                    <Button className="btn btn-sm btn-host"
                                        type='submit'
                                    >
                                        <i className="fas fa-save"></i>
                                    </Button>
                                </Col>
                                <Col className='col-12 mt-3'>
                                    <FormattedMessage id="TwilioMessaging.PhoneName" />
                                    <Input
                                        onChange={(e) => this.changeSubAccountName(e)}
                                        value={Name ?? ''}
                                        required
                                    />
                                </Col>
                            </Row>
                        </BlankCard>
                    </Form>
                </ModalBody>
            </Modal>
        )
    }
}

export default injectIntl(TwilioPhoneFriendlyNameModal)
