import React, { Component } from 'react'
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import { BlankCard } from '../Base/CommonUIComponents';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import moment from 'moment-timezone';

class LoyaltyLedgerDetail extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            ruleTypes: [{ value: 'ReservationRevenue', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.ReservationRevenue' }) }, { value: 'AdditionalRevenue', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.AdditionalRevenue' }) }, { value: 'Nights', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.Nights' }) }, { value: 'Reservation', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.Reservation' }) }],
        }
    }

    render() {
        const { detailModal, ledgerData, selectedRow, toggleDetailModal, revertTransaction, sourceOptions, intl } = this.props;
        const { ruleTypes } = this.state;

        const selectedRowData = ledgerData?.find(({id}) => id === selectedRow);
        
        return (
            detailModal && selectedRowData ?
                <Modal isOpen={detailModal} fade={false} size={"xl"} toggle={toggleDetailModal} style={{ minWidth: 0 }}>
                    <Button type="button" onClick={toggleDetailModal} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    <ModalBody>
                        <BlankCard>
                            <Row>
                                <Col>
                                    <b>
                                        <FormattedMessage id="ProfileDetails.TransactionDetail" />
                                    </b>
                                </Col>
                                <Col>
                                    <Button className="btn btn-host btn-sm float-right" onClick={revertTransaction} disabled={selectedRowData.isCanceled} title={intl.formatMessage({ id: 'ProfileDetails.CancelTransaction' })}>
                                        <i className="far fa-trash-alt"/>
                                    </Button>
                                </Col>
                            </Row>
                            
                            <Row className='mt-1'>
                                <Col className='col-3 mt-1'>
                                    <div className="title-sm mb-1">
                                        <FormattedMessage id="ProfileDetails.hotel"/>
                                    </div>
                                    <div>
                                        {selectedRowData.hotelName || '-'}
                                    </div>
                                </Col>
                                <Col className='col-3 mt-1'>
                                    <div className="title-sm mb-1">
                                        <FormattedMessage id="ProfileDetails.cardTypeDescription"/>
                                    </div>
                                    <div>
                                        {selectedRowData.cardTypeDescription || '-'}
                                    </div>
                                </Col>
                                <Col className='col-3 mt-1'>
                                    <div className="title-sm mb-1">
                                        <FormattedMessage id="ProfileDetails.AppliedDate"/>
                                    </div>
                                    <div>
                                        {moment(selectedRowData.createdAt).format('YYYY-MM-DD HH:mm')}
                                    </div>
                                </Col>
                                <Col className='col-3 mt-1'>
                                    <div className="title-sm mb-1">
                                        <FormattedMessage id="ProfileDetails.sumPointsTier"/>
                                    </div>
                                    <div>
                                        {selectedRowData.sumPointsTier}
                                    </div>
                                </Col>
                                {/* {isAdmin ?
                                    <Col className='col-3 mt-1'>
                                        <div className="title-sm mb-1">
                                            <FormattedMessage id="ProfileDetails.sumPointsAcc"/>
                                        </div>
                                        <div>
                                            {selectedRowData.sumPointsTier}
                                        </div>
                                    </Col>
                                :''} */}
                            </Row>

                            <div>
                                <Row className='mt-1'>
                                    <Col className='col-3 mt-1'>
                                        <div className="title-sm mb-1">
                                            <FormattedMessage id="ProfileDetails.BaseTierMultiplier"/>
                                        </div>
                                        <div>
                                            {selectedRowData.source === "ReservationRevenue" || selectedRowData.source === "AdditionalRevenue" ||
                                            selectedRowData.rebated?.source === "ReservationRevenue" || selectedRowData.rebated?.source === "AdditionalRevenue" ?
                                                <>{selectedRowData.tierRevenuePoints || '-'}</>
                                            : selectedRowData.source === "Nights" || selectedRowData.rebated?.source === "Nights" ?
                                                <>{selectedRowData.tierNightPoints || '-'}</>
                                            : selectedRowData.source === "Reservation" || selectedRowData.rebated?.source === "Reservation" ?
                                                <>{selectedRowData.tierResPoints || '-'}</>
                                            :''}
                                        </div>
                                    </Col>
                                    {selectedRowData.rule ?
                                        <Col className='col-3 mt-1'>
                                            <div className="title-sm mb-1">
                                                <FormattedMessage id="ProfileDetails.AppliedMultiplier"/>
                                            </div>
                                            {selectedRowData.rule.multiplier !== null ?
                                                <div>
                                                    {selectedRowData.rule.multiplier}%
                                                </div>
                                            :''}
                                        </Col>
                                    :''}
                                    <Col className='col-3 mt-1'>
                                        <div className="title-sm mb-1">
                                            <FormattedMessage id="ProfileDetails.tierPoints"/>
                                        </div>
                                        <div>
                                            {selectedRowData.tierPoints > 0 ? '+' : ''}
                                            {selectedRowData.tierPoints}
                                        </div>
                                    </Col>
                                </Row>
                                {/* {isAdmin ?
                                    <Row className='mt-1'>
                                        <Col className='col-3 mt-1'>
                                            <div className="title-sm mb-1">
                                                <FormattedMessage id="ProfileDetails.BaseTierMultiplier"/>
                                            </div>
                                            <div>
                                                {selectedRowData.source === "Revenue" || selectedRowData.rebated?.source === "Revenue" ?
                                                    <>{selectedRowData.accrualRevenuePoints || '-'}</>
                                                : selectedRowData.source === "Nights" || selectedRowData.rebated?.source === "Nights" ?
                                                    <>{selectedRowData.accrualNightPoints || '-'}</>
                                                : selectedRowData.source === "Reservation" || selectedRowData.rebated?.source === "Reservation" ?
                                                    <>{selectedRowData.accrualResPoints || '-'}</>
                                                :''}
                                            </div>
                                        </Col>
                                        {selectedRowData.rule ?
                                            <Col className='col-3 mt-1'>
                                                <div className="title-sm mb-1">
                                                    <FormattedMessage id="ProfileDetails.AppliedMultiplier"/>
                                                </div>
                                                <div>
                                                    {selectedRowData.rule.multiplier}%
                                                </div>
                                            </Col>
                                        :''}
                                        <Col className='col-3 mt-1'>
                                            <div className="title-sm mb-1">
                                                <FormattedMessage id="ProfileDetails.accrualPoints"/>
                                            </div>
                                            <div>
                                                {selectedRowData.accrualPoints > 0 ? '+' : ''}
                                                {selectedRowData.accrualPoints}
                                            </div>
                                        </Col>
                                    </Row>
                                :''} */}
                            </div>
                            
                            {selectedRowData.rule ?
                                <>
                                    <hr/>
                                    <Row>
                                        <Col>
                                            <b>
                                                <FormattedMessage id="ProfileDetails.Rule" />
                                            </b>
                                        </Col>
                                    </Row>
                                    <Row className='mt-1'>
                                        <Col className='col-3 mt-1'>
                                            <div className="title-sm mb-1">
                                                <FormattedMessage id="ConfigCardPoints.pointsType"/>
                                            </div>
                                            <div>
                                                {ruleTypes.find(({value}) => value === selectedRowData.rule.type).label}
                                            </div>
                                        </Col>
                                        <Col className='col-3 mt-1'>
                                            <div className="title-sm mb-1">
                                                <FormattedMessage id="ConfigCardPoints.chargeType"/>
                                            </div>
                                            <div>
                                                <FormattedMessage id={`ConfigCardPoints.${selectedRowData.rule.subType}`}/>
                                            </div>
                                        </Col>
                                        {selectedRowData.rule.subType !== "All" && selectedRowData.rule.codes && selectedRowData.rule.codes.length > 0 ?
                                            <Col className='col-3 mt-1'>
                                                <div className="title-sm mb-1">
                                                    <FormattedMessage id="ConfigCardPoints.Code"/>
                                                </div>
                                                <div style={{ maxHeight: '40px', overflow: 'auto' }} className="veryCoolScrollBar">
                                                    {selectedRowData.rule.codes.map((c, key) => 
                                                        <div key={key}>
                                                            <b>{c}</b>
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                                        :''}
                                        {selectedRowData.rule.filterType && selectedRowData.rule.filterValue && selectedRowData.rule.filterValue.length > 0 ?
                                            <Col className='col-3 mt-1'>
                                                <div className='title-sm mb-1'>
                                                    {selectedRowData.rule.filterType}
                                                </div>
                                                <div style={{ maxHeight: '40px', overflow: 'auto' }} className="veryCoolScrollBar">
                                                    {selectedRowData.rule.filterValue.map((c, key) => 
                                                        <div key={key}>
                                                            <b>{c}</b>
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                                        :''}
                                        {selectedRowData.rule.beginDate && selectedRowData.rule.endDate ?
                                            <Col className='col-3 mt-1'>
                                                <div className="title-sm mb-1">
                                                    <FormattedMessage id="ConfigCardPoints.Date"/>
                                                </div>
                                                <div>
                                                    <span>{moment(selectedRowData.rule.beginDate).format('YYYY-MM-DD')}</span>
                                                    <span className='mx-1'>-</span>
                                                    <span>{moment(selectedRowData.rule.endDate).format('YYYY-MM-DD')}</span>
                                                </div>
                                            </Col>
                                        :''}
                                        <Col className='col-3 mt-1'>
                                            <div className="title-sm mb-1">
                                                <FormattedMessage id="ConfigCardPoints.Deleted"/>
                                            </div>
                                            <div>
                                                {selectedRowData.rule.deleted ?
                                                    <FormattedMessage id="generic.yes"/>
                                                :
                                                    <FormattedMessage id="generic.no"/>}
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            :''}

                            <hr/>
                            <Row>
                                <Col>
                                    <b>
                                        <FormattedMessage id="ProfileDetails.Item" />
                                    </b>
                                </Col>
                            </Row>
                            <Row className='mt-1'>
                                <Col className='col-3 mt-1'>
                                    <div className="title-sm mb-1">
                                        <FormattedMessage id="ProfileDetails.source"/>
                                    </div>
                                    <div>
                                        {sourceOptions.find(({value}) => value === selectedRowData.source)?.label}
                                    </div>
                                </Col>
                                <Col className='col-3 mt-1'>
                                    <div className="title-sm mb-1">
                                        <FormattedMessage id="ProfileDetails.date"/>
                                    </div>
                                    <div>
                                        {moment(selectedRowData.entryDate).format('YYYY-MM-DD HH:mm')}
                                    </div>
                                </Col>
                                {selectedRowData.source === "ReservationRevenue" || selectedRowData.source === "AdditionalRevenue" ?
                                    <>
                                        <Col className='col-3 mt-1'>
                                            <div className="title-sm mb-1">
                                                <FormattedMessage id="ConfigCardPoints.Charge"/>
                                            </div>
                                            <div>
                                                {selectedRowData.revenue.description}
                                            </div>
                                        </Col>
                                        <Col className='col-3 mt-1'>
                                            <div className="title-sm mb-1">
                                                <FormattedMessage id="ProfileDetails.units"/>
                                            </div>
                                            <div>
                                                {selectedRowData.revenue.units}
                                            </div>
                                        </Col>
                                        <Col className='col-3 mt-1'>
                                            <div className="title-sm mb-1">
                                                <FormattedMessage id="ProfileDetails.totalAmount"/>
                                            </div>
                                            <div>
                                                <FormattedNumber style="currency" value={selectedRowData.revenue.totalAmount} currency={selectedRowData.revenue.currencyCode}/>
                                            </div>
                                        </Col>
                                        <Col className='col-3 mt-1'>
                                            <div className="title-sm mb-1">
                                                <FormattedMessage id="ProfileDetails.isPackage"/>
                                            </div>
                                            <div>
                                                {selectedRowData.revenue.isPackage ?
                                                    <FormattedMessage id="generic.yes"/>
                                                :
                                                    <FormattedMessage id="generic.no"/>
                                                }
                                            </div>
                                        </Col>
                                        {selectedRowData.revenue.salesGroupDescription ?
                                            <Col className='col-3 mt-1'>
                                                <div className="title-sm mb-1">
                                                    <FormattedMessage id="ConfigCardPoints.SalesGroup"/>
                                                </div>
                                                <div>
                                                    {selectedRowData.revenue.salesGroupDescription}
                                                </div>
                                            </Col>
                                        :''}
                                        {selectedRowData.revenue.priceListCode ?
                                            <Col className='col-3 mt-1'>
                                                <div className="title-sm mb-1">
                                                    <FormattedMessage id="ConfigCardPoints.PriceList"/>
                                                </div>
                                                <div>
                                                    {selectedRowData.revenue.priceListCode}
                                                </div>
                                            </Col>
                                        :''}
                                        {selectedRowData.revenue.segmentCode ?
                                            <Col className='col-3 mt-1'>
                                                <div className="title-sm mb-1">
                                                    <FormattedMessage id="ConfigCardPoints.Segment"/>
                                                </div>
                                                <div>
                                                    {selectedRowData.revenue.segmentCode}
                                                </div>
                                            </Col>
                                        :''}
                                        {selectedRowData.revenue.subSegmentCode ?
                                            <Col className='col-3 mt-1'>
                                                <div className="title-sm mb-1">
                                                    <FormattedMessage id="ConfigCardPoints.SubSegment"/>
                                                </div>
                                                <div>
                                                    {selectedRowData.revenue.subSegmentCode}
                                                </div>
                                            </Col>
                                        :''}
                                        {selectedRowData.revenue.distChannelCode ?
                                            <Col className='col-3 mt-1'>
                                                <div className="title-sm mb-1">
                                                    <FormattedMessage id="ConfigCardPoints.DistChannel"/>
                                                </div>
                                                <div>
                                                    {selectedRowData.revenue.distChannelCode}
                                                </div>
                                            </Col>
                                        :''}
                                        {selectedRowData.revenue.source ?
                                            <Col className='col-3 mt-1'>
                                                <div className="title-sm mb-1">
                                                    <FormattedMessage id="ConfigCardPoints.Source"/>
                                                </div>
                                                <div>
                                                    {selectedRowData.revenue.source}
                                                </div>
                                            </Col>
                                        :''}
                                    </>
                                : selectedRowData.source === "Nights" ?
                                    <>
                                        <Col className='col-3 mt-1'>
                                            <div className="title-sm mb-1">
                                                <FormattedMessage id="ProfileDetails.Rate"/>
                                            </div>
                                            <div>
                                                {selectedRowData.reservation.rateCode || '-'}
                                            </div>
                                        </Col>
                                        <Col className='col-3 mt-1'>
                                            <div className="title-sm mb-1">
                                                <FormattedMessage id="ProfileDetails.Category"/>
                                            </div>
                                            <div>
                                                {selectedRowData.reservation.roomCategory || '-'}
                                            </div>
                                        </Col>
                                        <Col className='col-3 mt-1'>
                                            <div className="title-sm mb-1">
                                                <FormattedMessage id="ProfileDetails.Nights"/>
                                            </div>
                                            <div>
                                                {moment(selectedRowData.reservation.checkOut).diff(selectedRowData.reservation.checkIn, 'days')}
                                            </div>
                                        </Col>
                                    </>
                                : selectedRowData.source === "Reservation" ?
                                    <>
                                        <Col className='col-3 mt-1'>
                                            <div className="title-sm mb-1">
                                                <FormattedMessage id="ProfileDetails.Rate"/>
                                            </div>
                                            <div>
                                                {selectedRowData.reservation.rateCode || '-'}
                                            </div>
                                        </Col>
                                        <Col className='col-3 mt-1'>
                                            <div className="title-sm mb-1">
                                                <FormattedMessage id="ProfileDetails.Category"/>
                                            </div>
                                            <div>
                                                {selectedRowData.reservation.roomCategory || '-'}
                                            </div>
                                        </Col>
                                        <Col className='col-3 mt-1'>
                                            <div className="title-sm mb-1">
                                                <FormattedMessage id="ProfileDetails.totalAmount"/>
                                            </div>
                                            <div>
                                                <FormattedNumber style="currency" value={selectedRowData.reservation.amountAfterTax} currency={selectedRowData.reservation.currencyCode}/>
                                            </div>
                                        </Col>
                                    </>
                                : selectedRowData.source === "Manual" ?
                                    <Col>
                                        <div className="title-sm mb-1">
                                            <FormattedMessage id="ProfileDetails.Note"/>
                                        </div>
                                        <div>
                                            {selectedRowData.note || '-'}
                                        </div>
                                    </Col>
                                : selectedRowData.source === "Transfer" ?
                                    <>
                                        <Col className='col-3 mt-1'>
                                            <div className="title-sm mb-1">
                                                <FormattedMessage id="ProfileDetails.Profile"/>
                                            </div>
                                            <div>
                                                {selectedRowData.rebated.profileId || '-'}
                                            </div>
                                        </Col>
                                        <Col className='col-3 mt-1'>
                                            <div className="title-sm mb-1">
                                                <FormattedMessage id="ProfileDetails.date"/>
                                            </div>
                                            <div>
                                                {moment(selectedRowData.rebated.entryDate).format('YYYY-MM-DD HH:MM')}
                                            </div>
                                        </Col>
                                        <Col className='col-3 mt-1'>
                                            <div className="title-sm mb-1">
                                                <FormattedMessage id="ProfileDetails.source"/>
                                            </div>
                                            <div>
                                                {sourceOptions.find(({value}) => value === selectedRowData.rebated.source)?.label}
                                            </div>
                                        </Col>
                                        <Col className='col-3 mt-1'>
                                            <div className="title-sm mb-1">
                                                <FormattedMessage id="ProfileDetails.tierPoints"/>
                                            </div>
                                            <div>
                                                {selectedRowData.rebated.tierPoints}
                                            </div>
                                        </Col>
                                        {/* {isAdmin ?
                                            <Col className='col-3 mt-1'>
                                                <div className="title-sm mb-1">
                                                    <FormattedMessage id="ProfileDetails.accrualPoints"/>
                                                </div>
                                                <div>
                                                    {selectedRowData.rebated.accrualPoints}
                                                </div>
                                            </Col>
                                        :''} */}
                                    </>
                                : selectedRowData.source === "Rebate" ?
                                    <>
                                        <Col className='col-3 mt-1'>
                                            <div className="title-sm mb-1">
                                                <FormattedMessage id="ProfileDetails.date"/>
                                            </div>
                                            <div>
                                                {moment(selectedRowData.rebated.entryDate).format('YYYY-MM-DD HH:MM')}
                                            </div>
                                        </Col>
                                        <Col className='col-3 mt-1'>
                                            <div className="title-sm mb-1">
                                                <FormattedMessage id="ProfileDetails.source"/>
                                            </div>
                                            <div>
                                                {sourceOptions.find(({value}) => value === selectedRowData.rebated.source)?.label}
                                            </div>
                                        </Col>
                                        <Col className='col-3 mt-1'>
                                            <div className="title-sm mb-1">
                                                <FormattedMessage id="ProfileDetails.tierPoints"/>
                                            </div>
                                            <div>
                                                {selectedRowData.rebated.tierPoints}
                                            </div>
                                        </Col>
                                        {/* {isAdmin ?
                                            <Col className='col-3 mt-1'>
                                                <div className="title-sm mb-1">
                                                    <FormattedMessage id="ProfileDetails.accrualPoints"/>
                                                </div>
                                                <div>
                                                    {selectedRowData.rebated.accrualPoints}
                                                </div>
                                            </Col>
                                        :''} */}
                                    </>
                                :''}
                            </Row>
                        </BlankCard>
                    </ModalBody>
                </Modal>
            :''
        )
    }
}
export default injectIntl(LoyaltyLedgerDetail);