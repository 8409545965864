import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Badge, Collapse } from 'reactstrap'

export default class DetailSearchTags extends Component {

    constructor(props) {
        super(props);
        this.state = {
            opened: true,
        }
    }

    toggle = () => {
        this.setState({ opened: !this.state.opened });
    }

    render() {
        const { tagGroups, handleTag } = this.props;
        const { opened } = this.state;

        return (
            <div>
                <div onClick={this.toggle} style={{ fontSize: '1.2em' }} className='d-flex align-items justify-content-between py-2 cursor-pointer'>
                    <div>
                        <FormattedMessage id={`DetailProfileSearch.Tags`}/>
                    </div>
                    <div>
                        {opened ?
                            <i className="fas fa-chevron-down"></i>
                        :
                            <i className="fas fa-chevron-up"></i>
                        }
                    </div>
                </div>
                <Collapse isOpen={opened} >
                    <div>
                        {tagGroups?.map((g, k) =>
                            <div key={k}>
                                <div>
                                    <b>{g.label}</b>
                                </div>
                                <div className='mt-2'>
                                    {g.options?.map((t, key) =>
                                        <Badge className="mr-2 p-2 mb-2" color={t.selected ? 'primary' :  "secondary"} key={key} style={{ cursor: 'pointer' }} onClick={_ => handleTag(t.value)}>
                                            {t.label}
                                        </Badge>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </Collapse>
            </div>
        )
    }
}
