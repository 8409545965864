import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Row } from 'reactstrap';
import { BlankCard, StyledModal } from "../Base/CommonUIComponents";
import { getAPI, putAPI } from "../Base/API";
import CustomSelect from '../Base/CustomSelect';
import { handleNotification } from '../Base/Notification';

export class TagAssociation extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: true,
            tags: [],
            tag: null,
            url: null
        };
    }

    componentDidMount() {
        this.getTagGroups();
    }

    getTagGroups = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const tags = data.response.map(group => {
                    const obj = {
                        label: group.code,
                        options: group.tag.map(tag => {
                            const tagOption = {
                                value: tag.id,
                                label: tag.code
                            };
                            return tagOption;
                        })
                    };
                    return obj;
                });

                this.setState({ error: errorMessage, block: false, tags });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Profile/v1/tag/groupDetails?tagType=all`);
    }

    handleSelect = (combo, name) => {
        if (combo) {
            this.setState({ [name]: combo.value });
        }
    }

    handleSave = (evt) => {
        evt.preventDefault();
        if (!this.form.current.reportValidity()) {
            return;
        }

        const { campaignId, toggleModal } = this.props;
        const { tag, url } = this.state;

        const request = {
            request: {
                tagId: tag,
                textLink: url
            }
        };

        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="CustomCampaign.AssociateTagIntoProfiles" values={{ profiles: data.response[0].total }} />, <FormattedMessage id="generic.success" />);
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Marketing/v1/${campaignId}/Tag`, request);
    }

    render() {
        const { modal, toggleModal, urls } = this.props;
        const { block, error, tag, tags, url } = this.state;

        return (
            <StyledModal toggleModal={_ => this.setState({ tag: null, url: null }, _ => toggleModal(_, []))} modal={modal} size="lg">
                <BlankCard block={block} error={error}>
                    <form ref={this.form}>
                        <Row className="mb-2">
                            <Col>
                                <h5>
                                    <FormattedMessage id="CustomCampaign.AssociateTags" />
                                </h5>
                            </Col>
                            <Col className="text-right col-1">
                                <Button className="btn btn-sm btn-host btn-sm" onClick={this.handleSave}>
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col className="col-2">
                                <FormattedMessage id="TagsList.Title" />
                            </Col>
                            <Col>
                                <CustomSelect
                                    options={tags}
                                    value={tags.find(tOpt => tOpt.value === tag)}
                                    onChange={e => this.handleSelect(e, "tag")}
                                    isSearchable
                                    required
                                />
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col className="col-2">
                                <FormattedMessage id="ReferenceDataFunctions.Url" />
                            </Col>
                            <Col>
                                <CustomSelect
                                    options={urls}
                                    value={urls.find(uOpt => uOpt.value === url)}
                                    onChange={e => this.handleSelect(e, "url")}
                                    isSearchable
                                    required
                                />
                            </Col>
                        </Row>
                    </form>
                </BlankCard>
            </StyledModal>
        );
    }
}