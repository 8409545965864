import React, { Component } from 'react';
import { Row, Col, Card, Badge } from 'reactstrap';
import { FormattedMessage, injectIntl } from "react-intl";
import { handleNotification } from '../../Base/Notification';
import { StyledCard } from '../../Base/CommonUIComponents';
import WhatsApp from '../ProfileInteractions/Chat/WhatsApp/WhatsApp';
import { getAPI } from '../../Base/API';
import moment from 'moment';
import { getColorPallete } from '../../Base/ReferenceDataFunctions';
import { getTextOfMessageForReplyBox } from './CommonFunctions';


class WhatsAppChat extends Component {
    _isMounted = false;
    static displayName = WhatsAppChat.name;

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            lastMessages: [],
            selectedProfile: null
        };
    }

    componentDidMount() {
        this._isMounted = true;

        this.getLastMessages();
    }

    getLastMessages = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ error: errorMessage, block: false }));
                }
                else {
                    this.setState(({ lastMessages: data.response, error: errorMessage, block: false }));
                }
            }
            else {
                this.setState(({ error: errorMessage, block: false }));
            }
        }, `/api/gms/WhatsApp/v1/profileMessages/last`)
    }

    selectChat = (e, msg, badgeColor) => {
        this.setState({
            selectedProfile: {
                id: msg.profileId,
                firstName: msg.firstName,
                lastName: msg.lastName,
                contacts: msg.profileContacts,
                badgeColor
            }
        });
    }

    render() {
        const { block, error, selectedProfile, lastMessages } = this.state;

        return (
            <StyledCard block={block} error={error}>
                <Row>
                    <Col className="col-3 px-0" style={{ borderRight: '2px solid lightgrey' }}>
                        <Row className="px-4 pb-1" style={{ height: '50px', alignItems: 'center' }}>
                            <Col className="col-2 pr-0 text-center">
                                <i className="fab fa-whatsapp" style={{ color: '#25D366', fontSize: '22px' }} />
                            </Col>
                            <Col className="col-10">
                                <h5 className="m-0">
                                    <FormattedMessage id="WhatsAppChat.WhatsAppMessages" />
                                </h5>
                            </Col>
                        </Row>

                        <div className="mt-2 pt-2 verticalScroll w-100 px-4" style={{ height: "80vh", overflowY: 'auto', overflowX: 'hidden', borderTop: '2px solid lightgrey' }}>
                            {lastMessages && lastMessages.length > 0 ?
                                lastMessages.map((msg, key) => {
                                    const isSelected = selectedProfile?.id === msg.profileId;
                                    const sentDate = moment.unix(msg.sent);
                                    const badgeColor = getColorPallete()[key % getColorPallete().length];

                                    return <Row key={key} style={{ height: '70px', alignItems: 'center', backgroundColor: (isSelected ? '#f1f1f1' : ''), borderRadius: '5px', position: 'relative' }}
                                        className="overbglight pointer" onClick={(e) => this.selectChat(e, msg, badgeColor)}>
                                        {isSelected ?
                                            <div style={{ 'left': '6px', 'position': 'absolute', 'borderRadius': '0px 30px 30px 0px', 'width': '7px', 'height': '14px', 'background': '#0667FF 0% 0% no-repeat padding-box', 'opacity': '1', 'marginTop': '4px' }}></div>
                                            : ''}
                                        <Col className="col-2 pr-0 d-flex justify-content-center">
                                            <Badge className="profileBadge" style={{ height: '40px', width: '40px', fontSize: '13px', backgroundColor: badgeColor }}>
                                                {msg.firstName?.charAt(0).toUpperCase()}{msg.lastName?.charAt(0).toUpperCase()}
                                            </Badge>
                                        </Col>
                                        <Col className="col-10">
                                            <Row className="align-items-center">
                                                <Col className="ellipsisText pr-1">
                                                    <b className="font-weight-bold m-0"> {msg.firstName} {msg.lastName}</b>
                                                </Col>
                                                <Col className="col-4 text-right pl-2" style={{ fontSize: '12px' }}>
                                                    {sentDate.isSame(moment(), "day") ?
                                                        moment(sentDate).format("HH:mm")
                                                        : moment().diff(sentDate, "days") < 2 ?
                                                            <FormattedMessage id="WhatsAppChat.Yesterday" />
                                                            : moment(sentDate).format("YYYY-MM-DD")}
                                                </Col>
                                            </Row>

                                            <Row className="align-items-center">
                                                <Col className="text-muted mt-1 ellipsisText" style={{ fontSize: '12px' }}>

                                                    {!msg.userStart ?
                                                        msg.failed ?
                                                            <i className="icon-icon-warnings-enabled text-warning ml-1" />
                                                            : msg.read ?
                                                                <i className="fas fa-check-double color-host ml-1" />
                                                                : msg.delivered ?
                                                                    <i className={"fas fa-check-double ml-1 "} />
                                                                    : <i className={"fa-sm fas fa-check  ml-1"} />
                                                        : ''}
                                                    {getTextOfMessageForReplyBox(msg)}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>                                
                                })
                                :
                                <div className="text-muted d-flex align-items-center text-center h-100" style={{ padding: '0 25%' }}>
                                    {this.props.intl.formatMessage({ id: "WhatsAppChat.ThereAreNoMessagesInLast24Hours" })}
                                </div>
                            }
                        </div>
                    </Col>
                    <Col className="col-9 p-0">
                        {selectedProfile ?
                            <>
                                <Row style={{ height: '45px', alignItems: 'center' }} className="px-4">
                                    <Col className="text-center" style={{ maxWidth: '5%' }}>
                                        <Badge className="profileBadge  m-0" style={{ height: '40px', width: '40px', fontSize: '13px', backgroundColor: selectedProfile.badgeColor }}>
                                            {selectedProfile.firstName?.charAt(0).toUpperCase()}{selectedProfile.lastName?.charAt(0).toUpperCase()}
                                        </Badge>
                                    </Col>
                                    <Col style={{ maxWidth: '95%' }}>
                                        <h5 className="m-0">
                                            {selectedProfile.firstName} {selectedProfile.lastName}
                                        </h5>
                                    </Col>
                                </Row>

                                {selectedProfile.id ?
                                    <WhatsApp
                                        profileId={selectedProfile.id}
                                        profile={selectedProfile}
                                    />
                                    : ''}
                            </>
                            : <div className="d-flex align-items-center justify-content-center flex-column h-100">
                                <i className="fab fa-whatsapp" style={{ color: 'lightgrey', fontSize: '80px' }} />
                                <h5 className="mt-4 mb-2"><FormattedMessage id="WhatsAppChat.WhatsAppMessages" /></h5>
                                <div> <FormattedMessage id="WhatsAppChat.SelectAConversation" /></div>
                            </div>
                        }
                    </Col>
                </Row>
            </StyledCard>
        )
    }
}

export default injectIntl(WhatsAppChat);