import React, { Component } from 'react';
import { Button, Row, Col, Card, CardBody, Form } from 'reactstrap';
import { Crt, SelectHotel, StyledCard } from '../../Base/CommonUIComponents';
import { FormattedMessage, injectIntl } from 'react-intl';
import { DateRangePicker } from 'react-dates';
import { getAPI } from "../../Base/API";
import { getColorPallete, getNextColor } from '../../Base/ReferenceDataFunctions';
import moment from 'moment';
import CustomSelect from '../../Base/CustomSelect';
import CanceledDays from './CanceledDays';
import BasicCalendar from '../../Base/BasicCalendar';
import { handleNotification } from '../../Base/Notification';


export function getCancelProb() {
    return [
        {
            value: 75,
            label: "75%"
        },
        {
            value: 80,
            label: "80%"
        },
        {
            value: 85,
            label: "85%"
        },
        {
            value: 90,
            label: "90%"
        },
        {
            value: 95,
            label: "95%"
        },
        {
            value: 100,
            label: "100%"
        },
    ];
};

class CancelationForecast extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            currentDate: moment(),
            canceledDays: [],
            fromDate: moment(),
            toDate: moment().add(1, 'month'),
            confidenceLevel: 90,
            totalCanceledDays: 0,
            lowerColorPct: 85, 
            heigherColorPct: 45,
            cancelationsByDay: [],
            roomOptions: [],
            rateOptions: [],
            channelOptions: [],
            nationalityOptions: [],
            heigherNrOfCancelations: 0,
            hasNextMonth: true,
            hasPrevMonth: false
        };
    }

    getForecast = (e) => {
        if (e) e.preventDefault();

        this.setState({ block: true });

        let params = `?hotelId=${this.state.hotelId}&confidenceLevel=${this.state.confidenceLevel}&fromDate=${moment(this.state.fromDate).format("YYYY-MM-DD")}&toDate=${moment(this.state.toDate).format("YYYY-MM-DD")}`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }

                this.chartData(data);
            }
            else this.setState({ error: errorMessage, block: false });

        }, `/api/gms/Reservation/v1/roomStays/cancelProbability` + params)
    }
    
    chartData = (data) => {
        const canceledDays = [
            { title: "Room", field: "roomCode", totalRoomNights: 0, data: [] },
            { title: "Rate", field: "rateCode", totalRoomNights: 0, data: [] },
            { title: "Channel", field: "source", totalRoomNights: 0, data: [] },
            { title: "Nationality", field: "nationality", totalRoomNights: 0, data: [] }
        ]

        const hasPrevMonth = false;
        const formatDate = "YYYY-MM-DD";
        const hasNextMonth = this.state.fromDate.isBefore(this.state.toDate, 'month');

        const comboOptions = [{ options: [], field: 'roomCode' }, { options: [], field: 'rateCode' }, { options: [], field: 'source' }, { options: [], field: 'nationality' } ];
        const cancelationsByDay = [];
        let totalCanceledDays = 0;


        data.response && data.response.forEach(forecast => {
            totalCanceledDays += forecast.totalRoomNights;

            //Cancelations by day for calendar            
            for (var date = moment(forecast.checkIn); date.isBefore(forecast.checkOut); date.add(1, 'days')) {

                const dayIndex = cancelationsByDay.findIndex(d => d.date === date.format(formatDate));

                if (dayIndex === -1) {
                    cancelationsByDay.push({ date: date.format(formatDate), totalCancelations: 1, roomStays: [forecast] });
                }
                else {
                    cancelationsByDay[dayIndex].roomStays.push(forecast);
                    cancelationsByDay[dayIndex].totalCancelations++;
                }
            }

            //Canceled days 
            canceledDays.forEach(type => {
                const label = forecast[type.field];
                const idx = type.data.findIndex(cd => cd.label === label);

                if (idx !== -1) {
                    type.data[idx].value += forecast.totalRoomNights;
                }
                else {
                    type.data.push({ label: label, value: forecast.totalRoomNights, bgColor: getNextColor(type.data.length + 1) });
                }

                type.totalRoomNights += forecast.totalRoomNights;
            })

            //Combo values
            comboOptions.forEach(combo => {
                if (forecast[combo.field]) {
                    combo.options = this.updateComboOptions(combo.options, forecast[combo.field]);
                }
            })
        })

        const heigherNrOfCancelations = Math.max(...cancelationsByDay.map(cbd => cbd.totalCancelations));

        this.setState({
            block: false,
            canceledDays,
            totalCanceledDays,
            heigherNrOfCancelations,
            hasPrevMonth, hasNextMonth,
            currentDate: this.state.fromDate,
            cancelationsByDay: cancelationsByDay.sort((a, b) => moment(a.date).unix() - moment(b.date).unix()),
            roomOptions: comboOptions[0].options, rateOptions: comboOptions[1].options,
            channelOptions: comboOptions[2].options, nationalityOptions: comboOptions[3].options
        });
    }

    updateComboOptions = (combo, field) => {
        const index = combo.findIndex(option => option.value === field);

        if (index === -1) {
            combo.push({ value: field, label: field });
        }

        return combo;
    }

    //#region handle inputs
    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    handleDates = (startDate, endDate) => {
        this.setState({
            fromDate: startDate,
            toDate: endDate,
            hasNextMonth: false,
            hasPrevMonth: false
        });
    }

    handleFilters = (combo, name) => {
        const { cancelationsByDay, roomFilter, rateFilter, channelFilter, nationalityFilter } = this.state;

        const filters = { roomFilter, rateFilter, channelFilter, nationalityFilter };
        filters[name] = combo ? combo.value : null;

        const areFiltersSelected = filters.roomFilter || filters.rateFilter || filters.channelFilter || filters.nationalityFilter;

        cancelationsByDay.forEach(cbd => {
            cbd.roomStays.forEach(rs => {
                if (areFiltersSelected) {
                    rs.visible = (filters.roomFilter ? filters.roomFilter === rs.roomCode : true) &&
                                (filters.rateFilter ? filters.rateFilter === rs.rateCode : true) &&
                                (filters.channelFilter ? filters.channelFilter === rs.source : true) &&
                                (filters.nationalityFilter ? filters.nationalityFilter === rs.nationality : true) 
                }
                else rs.visible = true;
            })
        })

        this.setState({
            roomFilter: filters.roomFilter, rateFilter: filters.rateFilter,
            channelFilter: filters.channelFilter, nationalityFilter: filters.nationalityFilter,
            cancelationsByDay
        });
    }
    //#endregion


    //#region calendar
    getDayColor = (day) => {
        let color = '';
        const dayInfo = this.state.cancelationsByDay.find(d => d.date === moment(this.state.currentDate).format(`YYYY-MM-${day}`));

        if (dayInfo && dayInfo.totalCancelations > 0) {
            const { heigherNrOfCancelations, lowerColorPct, heigherColorPct } = this.state;

            const dayPct = dayInfo.totalCancelations * 100 / heigherNrOfCancelations;

            const colorPct = ((heigherColorPct - lowerColorPct) * ((dayPct - 1) / (100 - 1))) + lowerColorPct;

            color = `hsl(240 100% ${colorPct}%)`;
        }

        return color;
    } 

    renderCalendarDay = (day) => {
        const isDisable = moment().isAfter(moment(this.state.currentDate).format(`YYYY-MM-${day}`), 'days') ? " text-muted cursor-na " : "";
        const bgColor = !isDisable ? this.getDayColor(day) : '';

        return <div style={{ color: (bgColor ? 'white' : ''), backgroundColor: bgColor, borderRadius: '10px' }} className={`${isDisable}`}>
            <b>{day}</b>
        </div>
    }

    handleMonth = (month) => {
        let hasNextMonth = false, hasPrevMonth = false;
        const currentDate = moment(this.state.currentDate).add(month, 'month');

        hasPrevMonth = currentDate.isAfter(this.state.fromDate, 'month');
        hasNextMonth = currentDate.isBefore(this.state.toDate, 'month');

        this.setState({
            currentDate, hasNextMonth, hasPrevMonth
        });
    }
    //#endregion


    render() {
        const { block, error, cancelationsByDay, roomOptions, rateOptions, channelOptions, nationalityOptions, hasNextMonth, hasPrevMonth } = this.state;

        return (
            <StyledCard block={block} error={error} >
                <Row>
                    <Col>
                        <h5> <i className="fas fa-ban mr-1" /> <FormattedMessage id="NavMenu.CancelationForecast" /> </h5>
                    </Col>
                </Row>

                <Form onSubmit={this.getForecast}>
                    <Row className="my-3 align-items-center">
                        <Col className="col-3 pr-0">
                            <SelectHotel
                                name={'hotelId'} icon={'icon-icon-hotel'}
                                onChangeFunc={this.handleSelect}
                                value={this.state.hotelId}
                                placeholder={<FormattedMessage id="generic.Hotel" />}
                                params='?onlyFromHotelGroup=true'
                                required
                            />
                        </Col>
                        <Col className="col-2 pr-0">
                            <CustomSelect
                                icon={"fas fa-percent"}
                                options={getCancelProb()}
                                isClearable isSearchable
                                placeholder={<FormattedMessage id="CancelationForecast.CancelProb" />}
                                onChange={(e) => this.handleSelect('confidenceLevel', e)}
                                value={getCancelProb().find(cp => cp.value === this.state.confidenceLevel)}
                                required
                            />
                        </Col>
                        <Col>
                            <DateRangePicker
                                startDate={this.state.fromDate ? moment(this.state.fromDate) : null}
                                endDate={this.state.toDate ? moment(this.state.toDate) : null}
                                onDatesChange={({ startDate, endDate }) => this.handleDates(startDate, endDate)}
                                isOutsideRange={day => day < moment()}
                                endDateId="your_unique_end_date_id_announcement"
                                startDateId="your_unique_start_date_id_announcement"
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                required
                            />
                        </Col>
                        <Col className="col-1" >
                            <Button className="float-right btn btn-sm btn-host" type="submit">
                                <i className="fa fa-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <Row>
                    <Col className="col-4 pr-0">
                        <div className="mb-3" style={{ height: '360px' }}>
                            <Card body className="border-0 shadow h-100">
                                <Row>
                                    <Col>
                                        <h5 className="text-muted m-0"><FormattedMessage id="CancelationForecast.Calendar" /></h5>
                                    </Col>
                                    <Col className="text-right">
                                        <div style={{ background: `linear-gradient(0.25turn, hsl(240 100% ${this.state.lowerColorPct}%), hsl(240 100% ${this.state.heigherColorPct}%))`, height: '10px', width: '100%', borderRadius: '10px' }}>
                                        </div>
                                    </Col>
                                </Row>

                                <div className="px-3">
                                    <BasicCalendar
                                        currentDate={this.state.currentDate}
                                        renderCalendarDay={this.renderCalendarDay}
                                        totalCanceledDays={this.state.totalCanceledDays}
                                        disableNextMonth={!hasNextMonth}
                                        disablePrevMonth={!hasPrevMonth}
                                        nextMonth={() => this.handleMonth(1)}
                                        prevMonth={() => this.handleMonth(-1)}
                                    />
                                </div>
                            </Card>
                        </div>
                        <div style={{ height: '40vh' }}>
                            <CanceledDays
                                canceledDays={this.state.canceledDays}
                            />
                        </div>
                    </Col>
                    <Col className="col-8">
                        <Card body className="border-0 shadow px-0">
                            <h5 className="text-muted px-4"><FormattedMessage id="CancelationForecast.Details" /></h5>

                            <div className="mt-3 mb-4 px-4">
                                <Row>
                                    <Col>
                                        <CustomSelect
                                            icon="fas fa-bed"
                                            options={roomOptions}
                                            isClearable isSearchable
                                            placeholder={<FormattedMessage id="generic.RoomCategory" />}
                                            onChange={(e) => this.handleFilters(e, 'roomFilter')}
                                        />
                                    </Col>
                                    <Col>
                                        <CustomSelect
                                            icon="fas fa-gift"
                                            options={rateOptions}
                                            isClearable isSearchable
                                            placeholder={<FormattedMessage id="generic.Rate" />}
                                            onChange={(e) => this.handleFilters(e, 'rateFilter')}
                                        />
                                    </Col>
                                    <Col>
                                        <CustomSelect
                                            icon="fas fa-plug"
                                            options={channelOptions}
                                            isClearable isSearchable
                                            placeholder={<FormattedMessage id="generic.Channel" />}
                                            onChange={(e) => this.handleFilters(e, 'channelFilter')}
                                        />
                                    </Col>
                                    <Col>
                                        <CustomSelect
                                            icon="fas fa-globe-europe"
                                            options={nationalityOptions}
                                            isClearable isSearchable
                                            placeholder={<FormattedMessage id="generic.Nationality" />}
                                            onChange={(e) => this.handleFilters(e, 'nationalityFilter')}
                                        />
                                    </Col>
                                </Row>
                            </div>

                            <div className="mt-2 px-3 mx-1 verticalScroll" style={{ height: `calc(40vh + 232px)`, overflowY: 'auto' }}>
                                {cancelationsByDay?.map((date, key) => date.roomStays.some(rs => rs.visible !== false) &&
                                    <div key={key} className="mb-3">
                                        {key === 0 || !moment(date.date).isSame(moment(cancelationsByDay[key - 1].date), 'month') ?
                                            <div className="px-2 mb-2">
                                                <b> {moment(date.date).format("MMMM YYYY")}</b>
                                            </div>
                                            : ''}
                                        <DayDetails date={date.date} total={date.totalCancelations} roomStays={date.roomStays} />
                                    </div>
                                )}
                            </div>
                        </Card>
                    </Col> 
                </Row>
            </StyledCard>
        )
    }
}

export default injectIntl(CancelationForecast);


class DayDetails extends Component {
    shouldComponentUpdate(nextProps) {
        return this.props.date !== nextProps.date;
    }

    render() {
        const { date, total, roomStays } = this.props;

        return <Card body className="p-2 shadow">
            <Row className="mb-2">
                <Col className="col-2 border-right text-host d-flex flex-column justify-content-center align-items-center">
                    <h6 className="m-0 mt-1"><b> {moment(date).format("ddd")} </b></h6>
                    <h2 className="m-0"><b> {moment(date).format("DD")} </b></h2>
                </Col>
                <Col>
                    {roomStays?.map((rs, key) => rs.visible !== false &&
                        <Row className="overbglight py-1" key={key}>
                            <Col sm={3}>
                                <i className="fas fa-bed fa-sm text-muted" /> {rs.roomCode || '-'}
                            </Col>
                            <Col sm={3}>
                                <i className="fas fa-gift fa-sm text-muted" /> {rs.rateCode || '-'}
                            </Col>
                            <Col sm={3}>
                                <i className="fas fa-plug fa-sm text-muted" /> {rs.source || '-'}
                            </Col>
                            <Col className="pl-0">
                                <i className="fas fa-globe-europe fa-sm text-muted mr-1" />
                                {rs.nationality ?
                                    <span className={`flag-icon flag-icon-${rs.nationality.toLowerCase()}`} style={{ borderRadius: '3px' }} />
                                    : '-'}
                            </Col>
                            <Col className="pl-0">
                                <i className="fas fa-ban fa-sm text-muted mr-1" /> {rs.cancelationProbability}%
                            </Col>
                        </Row>
                    )}
                </Col>
                <Col className="col-2 border-left text-danger d-flex flex-column justify-content-center align-items-center">
                    <h6 className="m-0 mt-1"><b> Total </b></h6>
                    <h2 className="m-0"><b> {total || 0} </b></h2>
                </Col>
            </Row>
        </Card>
    }
}
