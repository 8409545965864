import React, { useEffect, useState } from 'react';
import { getAllReviewFields, getConsentTypeList } from "./ReferenceDataFunctions";
import { FormattedMessage } from "react-intl";



export const Tag = ({ idx, tagLabel, tagValue, removeTagMapping }) => (
    <span className="bg-host color-white mr-3" style={{ borderRadius: '5px', display: 'inline-block', fontSize: '11px', height: '30px', whiteSpace: 'nowrap' }} key={idx}>
        <div >
            <span className="d-flex justify-content-between pointer" style={{ padding: '5px 8px' }}>
                <span> {tagLabel} </span>
                <i className="fas fa-times pointer ml-3 pt-1" onClick={removeTagMapping} />
            </span>

            <div className="px-2 mx-1" style={{ backgroundColor: '#FAFCFF', borderRadius: '10px', border: '1px solid #C9DEFE', position: 'relative', top: '-2px', color: 'black', display: 'inline-block' }}>
                {tagValue}
            </div>
        </div>
    </span>
);


export function getFieldsToMapForms() {
    const fields = [
        {
            value: 'Country',
            label: <FormattedMessage id="ReferenceDataFunctions.Country" />
        },
        {
            value: 'PostalCode',
            label: <FormattedMessage id="ReferenceDataFunctions.PostalCode" />
        },
    ]
    return fields;
};


export function getListOptions (mapType) {
    var list = [];

    if (mapType === 'Review') {
        list = getAllReviewFields();
    }
    else if (mapType === 'Consent') {
        list = getConsentTypeList();
    }
    else list = getFieldsToMapForms();

    return list;
}

