import React, { Component } from 'react'
import { getAPI, patchAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { StyledCard } from '../../Base/CommonUIComponents';
import { Button, Card, CardBody, Col, Form, Input, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Doughnut } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import moment from 'moment-timezone';
import countryList from 'react-select-country-list';
import { getCountryISO3, getStates } from '../../Base/ReferenceDataFunctions';
import CustomSelect from '../../Base/CustomSelect';
import Authorization from '../../Base/Authorization';

class SendGridAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            accountDetails: null,
            edit: false
        };
    }

    componentDidMount() {
        this.getAccountDetails();
    }

    getAccountDetails = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                
                this.setState({ accountDetails: data.response }, () => this.getUserEmail());
            }
        }, `/api/gms/SendGrid/v1/userprofile`);
    }

    getUserEmail = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                
                this.setState({ accountDetails: {...this.state.accountDetails, email: data.response?.email}, block: false });
            }
        }, `/api/gms/SendGrid/v1/useremail`);
    }

    toggleEdit =  () => {
        this.setState({ edit: !this.state.edit });
    }

    saveChanges = (e) => {
        e.preventDefault();
        const { accountDetails } = this.state;
        this.setState({ block: true }, () => 
            patchAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
    
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }

                    handleNotification(data, <FormattedMessage id="SendGrid.ProfileSaved" />, <FormattedMessage id="generic.success" />);

                    this.setState({ edit: false }, () => this.getAccountDetails());
                }
            }, `/api/gms/SendGrid/v1/userprofile`, accountDetails)
        );
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ accountDetails: { ...this.state.accountDetails, [name]: value } });
    }

    handleChangeCountry = (combo) => {
        this.setState(prevState => ({
            sender: {
                ...prevState.accountDetails,
                accountDetails: combo ? getCountryISO3(combo.value) : null
            }
        }));
    }

    handleChangeCombo = (name, combo) => {
        this.setState(prevState => ({
            accountDetails: {
                ...prevState.accountDetails,
                [name]: combo ? combo.value : null
            }
        }));
    }

    render() {
        const { block, error, accountDetails, edit } = this.state;
        const { page, creditBalance, intl } = this.props;
        
        const pieData = {
            "labels": [ intl.formatMessage({ id: "SendGrid.Used" }), intl.formatMessage({ id: "SendGrid.Remain" })],
            "datasets": [
                { "data": [creditBalance?.used, creditBalance?.remain], "backgroundColor": ["#28a745", "#EDEDED"] },
            ]
        };

        const optionsPie = {
            maintainAspectRatio: false,
            cutout: '65%',
            plugins: {
                legend: {
                    display: false,
                    position: "left",
                    labels: {
                        generateLabels: function (chart) {
                            // Get the default label list
                            const original = Chart.overrides.pie.plugins.legend.labels.generateLabels;
                            const labelsOriginal = original.call(this, chart);

                            // Build an array of colors used in the datasets of the chart
                            let datasetColors = chart.data.datasets.map(function (e) {
                                return e.backgroundColor;
                            });
                            datasetColors = datasetColors.flat();

                            // Modify the color and hide state of each label
                            labelsOriginal.forEach(label => {
                                // There are twice as many labels as there are datasets. This converts the label index into the corresponding dataset index
                                // label.datasetIndex = (label.index - label.index % 2) / 2;

                                // The hidden state must match the dataset's hidden state
                                label.hidden = !chart.isDatasetVisible(label.datasetIndex);

                                // Change the color to match the dataset
                                label.fillStyle = datasetColors[label.index];
                            });

                            return labelsOriginal;
                        }
                    },
                    onClick: function (mouseEvent, legendItem, legend) {
                        // toggle the visibility of the dataset from what it currently is
                        legend.chart.getDatasetMeta(
                            legendItem.datasetIndex
                        ).hidden = legend.chart.isDatasetVisible(legendItem.datasetIndex);
                        legend.chart.update();
                    }
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            const labelIndex = (context.datasetIndex * 2) + context.dataIndex;
                            return  `${context.chart.data.labels[labelIndex]}: ${context.formattedValue}`;
                        }
                    }
                },
                datalabels: {
                    color: 'white',
                    formatter: (ctx, data) => {
                        return ctx ? ctx : '';
                    },
                    formatter: (val, context) => {
                        return val
                    },
                },
            }
        };

        return (
            <StyledCard block={block} error={error}>
                <div className="pb-4">
                    <div>
                        <h5>
                            <i className={page?.icon}/>
                            <span>
                                <FormattedMessage id="SendGrid.AccountDetails"/>
                            </span>
                        </h5>
                    </div>
                    
                    <Authorization
                        perform="admin:view"
                        yes={() => (
                            <>
                                {accountDetails ?
                                    <Card className='border-0 shadow p-2 mt-4'>
                                        <Form onSubmit={this.saveChanges}>
                                            {edit ?
                                                <div style={{ position: 'absolute', right: '1.5rem', top: '1.5rem' }}>
                                                    <Button className="btn btn-sm btn-host" type="submit">
                                                        <i className="fas fa-save"/>
                                                    </Button>
                                                </div>
                                            :
                                                <div className='prettyEdit' type="button" onClick={this.toggleEdit}>
                                                    <i className="far fa-edit"/>
                                                </div>
                                            }
                                            <CardBody>
                                                <div>
                                                    <div>
                                                        <b><FormattedMessage id="SendGrid.User"/></b>
                                                    </div>
                                                    <Row className='mt-2'>
                                                        <Col className='col-3'>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id="SendGrid.FirstName"/>
                                                            </div>
                                                            {edit ?
                                                                <Input
                                                                    type='text'
                                                                    name='first_name'
                                                                    value={accountDetails.first_name}
                                                                    onChange={this.handleChange}
                                                                />
                                                            :
                                                                <div>
                                                                    {accountDetails.first_name??'-'}
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col className='col-3'>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id="SendGrid.LastName"/>
                                                            </div>
                                                            {edit ?
                                                                <Input
                                                                    type='text'
                                                                    name='last_name'
                                                                    value={accountDetails.last_name}
                                                                    onChange={this.handleChange}
                                                                />
                                                            :
                                                                <div>
                                                                    {accountDetails.last_name??'-'}
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col className='col-3'>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id="SendGrid.Email"/>
                                                            </div>
                                                            {edit ?
                                                                <Input
                                                                    type='email'
                                                                    name='email'
                                                                    value={accountDetails.email}
                                                                    onChange={this.handleChange}
                                                                />
                                                            :
                                                                <div>
                                                                    {accountDetails.email??'-'}
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col className='col-3'>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id="SendGrid.PhoneNumber"/>
                                                            </div>
                                                            {edit ?
                                                                <Input
                                                                    type='phone'
                                                                    name='phone'
                                                                    value={accountDetails.phone}
                                                                    onChange={this.handleChange}
                                                                />
                                                            :
                                                                <div>
                                                                    {accountDetails.phone??'-'}
                                                                </div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className='mt-2'>
                                                    <div>
                                                        <b><FormattedMessage id="SendGrid.Company"/></b>
                                                    </div>
                                                    <Row className='mt-2'>
                                                        <Col className='col-3'>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id="SendGrid.CompanyName"/>
                                                            </div>
                                                            {edit ?
                                                                <Input
                                                                    type='text'
                                                                    name='company'
                                                                    value={accountDetails.company}
                                                                    onChange={this.handleChange}
                                                                />
                                                            :
                                                                <div>
                                                                    {accountDetails.company??'-'}
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col className='col-3'>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id="SendGrid.Website"/>
                                                            </div>
                                                            {edit ?
                                                                <Input
                                                                    type='text'
                                                                    name='website'
                                                                    value={accountDetails.website}
                                                                    onChange={this.handleChange}
                                                                />
                                                            :
                                                                <div>
                                                                    {accountDetails.website??'-'}
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col className='col-3'>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id="SendGrid.Address"/>
                                                            </div>
                                                            {edit ?
                                                                <Input
                                                                    type='text'
                                                                    name='address'
                                                                    value={accountDetails.address}
                                                                    onChange={this.handleChange}
                                                                />
                                                            :
                                                                <div>
                                                                    {accountDetails.address??'-'}
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col className='col-3'>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id="SendGrid.Address2"/>
                                                            </div>
                                                            {edit ?
                                                                <Input
                                                                    type='text'
                                                                    name='address2'
                                                                    value={accountDetails.address2}
                                                                    onChange={this.handleChange}
                                                                />
                                                            :
                                                                <div>
                                                                    {accountDetails.address2??'-'}
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col className='col-3 mt-2'>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id="SendGrid.Country"/>
                                                            </div>
                                                            {edit ?
                                                                <CustomSelect
                                                                    options={countryList().getData()}
                                                                    placeholder=""
                                                                    value={accountDetails.country ? countryList().getData().find(country => country.value === accountDetails.country) : ''}
                                                                    onChange={(combo) => this.handleChangeCountry(combo)}
                                                                    isClearable isSearchable
                                                                />
                                                            :
                                                                <div>
                                                                    {accountDetails.country ? countryList().getData().find(country => country.value === accountDetails.country)?.label : '-'}
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col className='col-3 mt-2'>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id="SendGrid.City"/>
                                                            </div>
                                                            {edit ?
                                                                <Input
                                                                    type='text'
                                                                    name='city'
                                                                    value={accountDetails.city}
                                                                    onChange={this.handleChange}
                                                                />
                                                            :
                                                                <div>
                                                                    {accountDetails.city??'-'}
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col className='col-3 mt-2'>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id="SendGrid.State"/>
                                                            </div>
                                                            {edit ?
                                                                <CustomSelect
                                                                    options={getStates()}
                                                                    placeholder=""
                                                                    value={accountDetails.state ? getStates().find(country => country.value === accountDetails.state) : ''}
                                                                    onChange={(combo) => this.handleChangeCombo('state', combo)}
                                                                    isClearable isSearchable
                                                                />
                                                            :
                                                                <div>
                                                                    {accountDetails.state ? getStates().find(country => country.value === accountDetails.state)?.label : '-'}
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col className='col-3 mt-2'>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id="SendGrid.Zip"/>
                                                            </div>
                                                            {edit ?
                                                                <Input
                                                                    type='text'
                                                                    name='zip'
                                                                    value={accountDetails.zip}
                                                                    onChange={this.handleChange}
                                                                />
                                                            :
                                                                <div>
                                                                    {accountDetails.zip??'-'}
                                                                </div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </CardBody>
                                        </Form>
                                    </Card>
                                :''}
                            </>
                        )}
                        no={() => <div></div>}
                    />
                    <Row>
                        <Col className='col-6'>
                            {creditBalance ?
                                <Card className='border-0 shadow p-2 mt-4'>
                                    <CardBody>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <b><FormattedMessage id="SendGrid.CreditBalance"/></b>
                                                </Col>
                                                <Col className='text-right'>
                                                    <div className='title-sm'>
                                                        <FormattedMessage id="SendGrid.ResetFrequency"/>
                                                    </div>
                                                    <div>
                                                        <FormattedMessage id={`SendGrid.${creditBalance.resetFrequency}`}/>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className='mt-2'>
                                                <Doughnut data={pieData} height={200} options={optionsPie} />
                                            </div>
                                            <Row>
                                                <Col>
                                                    <div className='title-sm'>
                                                        <FormattedMessage id="SendGrid.LastReset"/>
                                                    </div>
                                                    <div>
                                                        {moment(creditBalance.lastReset).format('YYYY-MM-DD')}
                                                    </div>
                                                </Col>
                                                <Col className='text-right'>
                                                    <div className='title-sm'>
                                                        <FormattedMessage id="SendGrid.NextReset"/>
                                                    </div>
                                                    <div>
                                                        {moment(creditBalance.nextReset).format('YYYY-MM-DD')}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            :''}
                        </Col>
                    </Row>
                </div>
            </StyledCard>
        );
    }
}

export default injectIntl(SendGridAccount)