import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Modal, ModalBody, Row, UncontrolledDropdown } from 'reactstrap';
import { StyledCard } from "../Base/CommonUIComponents";
import ConfirmActionModal from '../Base/ConfirmActionModal';
import { FormattedMessage } from 'react-intl';
import { deleteAPI, getAPI, patchAPI, postAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import moment from 'moment-timezone';
import CustomSelect from '../Base/CustomSelect';
import BlockUi from 'react-block-ui';

class GoogleBusinessMedia extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            locations: [],
            mediaList: [],
            deleteModal: false,
            createModal: false,
            newMedia: null,
            selectedMedia: null,
            fileName: null,
            isEditable: false,
            editableKey: null
        };
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.openUpload && !this.props.openUpload){
            const newMedia = {
                mediaFormat: 'PHOTO',
                locationAssociation: {
                    category: 'PROFILE'
                }
            };
            
            this.setState({ createModal: true, newMedia }, () => this.props.toggleOpenUpload());
        }

        if (nextProps.selectedLocation !== this.props.selectedLocation) {
            this.getMedia(nextProps.selectedLocation);
        }
    }

    componentDidMount() {
        this.getMedia(this.props.selectedLocation);
    }

    getMedia = (selectedLocation) => {
        const { accountId, setProfilePhoto } = this.props;
        
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    const profilePhoto = data.response[0].mediaItems?.find(el => el.mediaFormat === 'PHOTO' && el.locationAssociation.category === 'PROFILE');
                    this.setState({ mediaList: data.response[0].mediaItems, error: errorMessage, block: false }, () => setProfilePhoto(profilePhoto?.googleUrl));
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Google/v1/media/account/${accountId}/locations/${selectedLocation}/list`)
    }

    deleteMedia = () => {
        const { accountId, selectedLocation } = this.props;
        const { mediaList, selectedMedia } = this.state;

        if(!selectedMedia && isNaN(selectedMedia)) return;
        
        const mediaId = mediaList[selectedMedia].name;
        this.setState({ block: true }, () =>
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        errorMessage.push({ message: data.errors[0].message, messageType: 'danger' });
                        this.setState({ deleteModal: false, selectedMedia: null, error: errorMessage, block: false }, handleNotification(data));
                    }
                    else {
                        this.setState({ deleteModal: false, selectedMedia: null }, () => this.getMedia(selectedLocation));
                    }
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/Google/v1/media/account/${accountId}/locations/${selectedLocation}/delete?mediaName=${mediaId}`)
        );
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        }, () => this.getMedia());
    }

    toggleDeleteModal = (key) => {
        this.setState({ deleteModal: !this.state.deleteModal, selectedMedia: key });
    }

    addMedia = () => {
        const { accountId, selectedLocation } = this.props;
        const { newMedia } = this.state;

        this.setState({ block: true }, () =>
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        errorMessage.push({ message: data.errors[0].message, messageType: 'danger' });
                        this.setState({ createModal: false, newMedia: null, error: errorMessage, block: false }, handleNotification(data));
                    }
                    else {
                        this.setState({ createModal: false, newMedia: null, block: false }, () => this.getMedia(selectedLocation));
                    }
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/Google/v1/media/account/${accountId}/locations/${selectedLocation}/Upload`, newMedia)    
        );
    }

    toggleCreateModal = () => {
        this.setState({ createModal: !this.state.createModal, newMedia: null, isEditable: false, editableKey: null });
    }

    handleMediaCategory = (combo) => {
        const value = combo?.value;
        this.setState({ newMedia: { ...this.state.newMedia, locationAssociation: { category: value } } });
    }

    handleMediaUploadType = (combo) => {
        const value = combo?.value;
        this.setState({ uploadType: value });
    }

    handleDescription = (e) => {
        const value = e?.target?.value;
        this.setState({ newMedia: { ...this.state.newMedia, description: value } });
    }

    handleSourceUrl = (e) => {
        const value = e?.target?.value;
        this.setState({ newMedia: { ...this.state.newMedia, sourceUrl: value } });
    }

    toggleIsEditable = (key) => {
        const { mediaList } = this.state;

        const current = mediaList[key];

        if(!current) return;
        
        const newMedia = {
            name: mediaList[key].name,
            locationAssociation: mediaList[key].locationAssociation,
            description: mediaList[key].description,
        }

        this.setState(prev => ({ createModal: !prev.createModal, isEditable: !prev.createModal, newMedia }));
    }

    editMedia = () => {
        const { accountId, selectedLocation } = this.props;
        const { newMedia } = this.state;

        this.setState({ block: true }, () =>
            patchAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false, createModal: false, newMedia: null });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        errorMessage.push({ message: data.errors[0].message, messageType: 'danger' });
                        this.setState({ createModal: false, newMedia: null, error: errorMessage, block: false }, handleNotification(data));
                    }
                    else {
                        this.setState({ createModal: false, newMedia: null }, () => this.getMedia(selectedLocation));
                    }
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
                this.setState({ editableKey: null });
            }, `/api/gms/Google/v1/media/account/${accountId}/locations/${selectedLocation}/update?mediaName=${newMedia.name}`, newMedia)    
        );
    }

    submitForms = (e) => {
        e.preventDefault();
        if(this.state.isEditable) {
            this.editMedia();
        }
        else{
            this.addMedia();
        }
    }

    handleMediaType = (combo) => {
        const value = combo?.value;
        this.setState({ newMedia: { ...this.state.newMedia, mediaFormat: value } });
    }

    render() {
        const { block, error, mediaList, deleteModal, createModal, isEditable, editableKey, newMedia } = this.state;
        const { intl } = this.props;
        
        const mediaCategoryOptions = [
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.COVER" }), value: 'COVER' },
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.PROFILE" }), value: 'PROFILE' },
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.LOGO" }), value: 'LOGO' },
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.EXTERIOR" }), value: 'EXTERIOR' },
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.INTERIOR" }), value: 'INTERIOR' },
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.AT_WORK" }), value: 'AT_WORK' },
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.FOOD_AND_DRINK" }), value: 'FOOD_AND_DRINK' },
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.MENU" }), value: 'MENU' },
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.COMMON_AREA" }), value: 'COMMON_AREA' },
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.ROOMS" }), value: 'ROOMS' },
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.TEAMS" }), value: 'TEAMS' },
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.ADDITIONAL" }), value: 'ADDITIONAL' },
        ];

        const mediaOptions = [
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.PHOTO" }), value: 'PHOTO' },
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.VIDEO" }), value: 'VIDEO' },
        ];
        
        return (
            <StyledCard error={error}>
                {deleteModal ?
                    <ConfirmActionModal
                        block={block}
                        error={error}
                        text={intl.formatMessage({ id: "GoogleBusinessConfig.DeleteMediaWarning" })}
                        toggleModal={this.toggleDeleteModal}
                        modal={deleteModal}
                        actionFunction={this.deleteMedia}
                    />
                :''}
                {createModal ?
                    <Modal isOpen={createModal} toggle={this.toggleCreateModal}>
                        <BlockUi tag="div" blocking={block}>
                            <Button onClick={() => this.toggleCreateModal()} className="closeModal">
                                <i className="fas fa-times fa-sm"></i>
                            </Button>
                            <ModalBody className='py-4'>
                                <Form onSubmit={this.submitForms}>
                                    <Row>
                                        <Col>
                                            <h5>
                                                {isEditable ?
                                                    <FormattedMessage id="GoogleBusinessConfig.editMedia"/>
                                                :
                                                    <FormattedMessage id="GoogleBusinessConfig.NewMedia"/>
                                                }
                                            </h5>
                                        </Col>
                                        <Col className='col-1 text-right'>
                                            {isEditable ?
                                                <Button className='btn btn-host btn-sm' type='submit' title={intl.formatMessage({ id: "GoogleBusinessConfig.Save" })}>
                                                    <i className="fas fa-save"/>
                                                </Button>
                                            :
                                                <Button className='btn btn-host btn-sm' type='submit' title={intl.formatMessage({ id: "GoogleBusinessConfig.Upload" })}>
                                                    <i className="fas fa-file-upload"/>
                                                </Button>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='mt-2 col-6'>
                                            <div>
                                                <FormattedMessage id="GoogleBusinessConfig.MediaCategory"/>
                                            </div>
                                            <div className='mt-1'>
                                                <CustomSelect
                                                    options={mediaCategoryOptions}
                                                    placeholder=''
                                                    onChange={this.handleMediaCategory}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    required
                                                    value={newMedia?.locationAssociation?.category ? mediaCategoryOptions.find(({value}) => value === newMedia.locationAssociation.category) : undefined}
                                                />
                                            </div>
                                        </Col>
                                        {!isEditable ? 
                                            <>
                                                <Col className='mt-2 col-6'>
                                                    <div>
                                                        <FormattedMessage id="GoogleBusinessConfig.MediaDescription"/>
                                                    </div>
                                                    <div className='mt-1'>
                                                        <Input
                                                            type='text'
                                                            onChange={this.handleDescription}
                                                            required
                                                            value={newMedia?.description}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className='mt-2 col-6'>
                                                    <div>
                                                        <FormattedMessage id="GoogleBusinessConfig.MediaType"/>
                                                    </div>
                                                    <div className='mt-1'>
                                                        <CustomSelect
                                                            onChange={this.handleMediaType}
                                                            options={mediaOptions}
                                                            placeholder={''}
                                                            isSearchable={false}
                                                            isClearable={false}
                                                            value={newMedia?.mediaFormat ? mediaOptions.find(({value}) => value === newMedia.mediaFormat) : null}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className='mt-2 col-6'>
                                                    <div>
                                                        <FormattedMessage id="GoogleBusinessConfig.sourceUrl"/>
                                                    </div>
                                                    <div className='mt-1'>
                                                        <Input
                                                            type="text"
                                                            onChange={this.handleSourceUrl}
                                                            required
                                                        />
                                                    </div>
                                                </Col>
                                            </>
                                        :''}
                                    </Row>
                                </Form>
                            </ModalBody>
                        </BlockUi>
                    </Modal>
                :''}
                <Row>
                    <Col className='text-right'>
                        <Button className="btn btn-host btn-sm" onClick={this.toggleCreateModal}>
                            <i className="fas fa-plus"/>
                        </Button>
                    </Col>
                </Row>
                {mediaList ?
                    <div style={{ display: 'grid', gridTemplateColumns: '25% 25% 25% 25%' }}>
                        {mediaList?.map((el, key) => 
                            <div key={key} className="mt-3 px-2">
                                <div className='shadow h-100' style={{ display: 'grid' }}>
                                    <div style={{ backgroundImage: `url(${el.googleUrl})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '200px' }}/>
                                    <div style={{
                                        width: '100%',
                                        padding: '0.5rem',
                                        background: '#ffffff',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}>
                                        <div>
                                            {el.locationAssociation?.category ?
                                                <div style={{ fontSize: '0.8em' }}>
                                                    <div>
                                                        {el.description}
                                                    </div>
                                                    <div>
                                                        <FormattedMessage id={"GoogleBusinessConfig." + el.locationAssociation.category}/>
                                                    </div>
                                                </div>
                                            :''}
                                            <div className='text-muted' style={{ fontSize: '0.8em' }}>
                                                {moment(el.createTime).format('YYYY-MM-DD')}
                                            </div>
                                        </div>
                                        <div>
                                            <UncontrolledDropdown className='ml-2'>
                                                <DropdownToggle style={{ background: 'none', border: 'none', color: 'unset', padding: 'unset', width: '10px' }}>
                                                    <i className="fas fa-ellipsis-v"/>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={_ => this.toggleIsEditable(key)}>
                                                        <i className="mr-2 fas fa-edit"/>
                                                        <FormattedMessage id="stripo.edit"/>
                                                    </DropdownItem>
                                                    <DropdownItem onClick={e => this.toggleDeleteModal(key)}>
                                                        <div style={{ color: '#A80C19' }}>
                                                            <i className="mr-2 far fa-trash-alt"/>
                                                            <FormattedMessage id="stripo.delete"/>
                                                        </div>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                :
                    <Row>
                        <Col className='text-center'>
                            <FormattedMessage id="GoogleBusinessConfig.NoMedia"/>
                        </Col>
                    </Row>
                }
            </StyledCard>
        );
    }
}

export default injectIntl(GoogleBusinessMedia)