import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Row } from 'reactstrap';
import { StyledCard } from "../Base/CommonUIComponents";
import { FormattedMessage } from 'react-intl';
import GoogleBusinessImg from '../../img/GoogleBusiness.ico';

import { getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import GoogleBusinessBreadcrumb from './GoogleBusinessBreadcrumb';

class GoogleBusinessNotification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            settings: [],
            selectedAccount: null
        };
    }

    componentDidMount() {
        const accountId = this.props.match.params?.accountId;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false, accountId });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    this.setState({ error: errorMessage, block: false, accountId }, () => handleNotification(data));
                }
                else {
                    this.setState({ settings: data.response, error: errorMessage, block: false, accountId });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false, accountId });
            }
        }, `/api/gms/Google/v1/notification/${accountId}/list`)
    }


    render() {
        const { block, error } = this.state;

        return (
            <StyledCard block={block} error={error} >
                <GoogleBusinessBreadcrumb
                    pages={[{name: "GoogleBusinessNotification"}]}
                />
            </StyledCard>
        );
    }
}
export default injectIntl(GoogleBusinessNotification)
