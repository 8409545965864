import React, { Component } from 'react';
import { Row, Col, Card } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { Doughnut } from 'react-chartjs-2';

class ProfileSummaryCardData extends Component {

    render() {
        const { criteria, errors, idx, metric, optionsPie, pieData, unfilled } = this.props;

        return (
            <Col sm={3} key={idx} className="mb-4">
                <Card className="border-0 shadow py-4 h-100">
                    <Row className="align-items-center justify-content-center text-muted mb-3">
                        <i className={`${metric.icon} mr-1`} />
                        <h5 className="m-0"><b> {metric.label} </b></h5>
                    </Row>

                    <div className="text-center mt-4 mb-5">
                        {pieData.datasets && pieData.datasets.length > 0 && pieData.datasets[0].data.length > 0 ?
                            <Doughnut data={pieData} height={200} options={optionsPie} />
                            : ''}
                    </div>

                    <Row>
                        <Col className="text-center pr-2" style={{ color: '#22CCE2' }}>
                            <b style={{ fontSize: '19px' }} title={this.props.intl.formatMessage({ id: "ProfileSummary.Filled" })}>
                                {criteria.results && criteria.results[0]?.filled || '-'}
                            </b>
                            <br />
                            {/*<FormattedMessage id="ProfileSummary.Filled" />*/}
                        </Col>
                        <Col className="text-center px-2" style={{ color: '#959595' }}>
                            <b style={{ fontSize: '19px' }} title={this.props.intl.formatMessage({ id: "ProfileSummary.Unfilled" })}>
                                {unfilled || '-'}
                            </b>
                            <br />
                            {/*<FormattedMessage id="ProfileSummary.Unfilled" />*/}
                        </Col>
                        <Col className="text-center color-green px-2">
                            <b style={{ fontSize: '19px' }} title={this.props.intl.formatMessage({ id: "ProfileSummary.Valid" })}>
                                {criteria.results && criteria.results[0]?.valid || '-'}
                            </b>
                            <br />
                            {/*<FormattedMessage id="ProfileSummary.Valid"/>*/}
                        </Col>
                        <Col className="text-center  color-light-red pl-2">
                            <b style={{ fontSize: '19px' }} title={this.props.intl.formatMessage({ id: "ProfileSummary.Errors" })}>
                                {errors || '-'}
                            </b>
                            <br />
                            {/*<FormattedMessage id="ProfileSummary.Errors" />*/}
                        </Col>
                    </Row>
                </Card>
            </Col>
        );
    }
}

export default injectIntl(ProfileSummaryCardData);