import React, { Component } from 'react'
import { Card, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import ChatGPT from '../../../img/chatgpt.png';

export default class AiSideMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    render() {
        const { activeTab, toggle, pages } = this.props;

        return (
            <div className="side-menu">
                <Card className="border-0 shadow mb-3 p-3">
                    <Row>
                        <Col className='pr-0 d-flex align-items-center'>
                            <div>
                                <h5 className='m-0'><FormattedMessage id="NavMenu.AIConfig"/></h5>
                            </div>
                        </Col>
                    </Row>
                </Card>

                <Nav tabs vertical className='mt-4 border-0'>
                    <Card className="bg-white p-2 border-0 mb-3 shadow">
                        {pages.map((page, key) =>
                            <NavItem key={key} className="pb-2">
                                <NavLink className={activeTab === page.name ? 'text-primary border-0 gptMenuEntry active' : 'bg-white border-0 gptMenuEntry'} onClick={_ => toggle(page.name)} style={{ cursor: 'pointer' }} >
                                    {activeTab === page.name ?
                                        <div style={{
                                            'left': '0',
                                            'position': 'absolute',
                                            'borderRadius': '0px 30px 30px 0px',
                                            'width': '7px',
                                            'height': '14px',
                                            'background': '#0667FF 0% 0% no-repeat padding-box',
                                            'opacity': '1',
                                            'marginTop': '4px'
                                        }}/>
                                    : ''}
                                    <div className='d-flex align-items-center'>
                                        {page.icon}
                                        <FormattedMessage id={"AIConfig."+ page.name}/>
                                    </div>
                                </NavLink>
                            </NavItem>
                        )}
                    </Card>
                </Nav>
            </div>
        );
    }
}
