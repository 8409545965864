import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

export const adalConfig = {
    tenant: 'ba6750d3-d0c9-47c5-b8f6-989c1e0c0312',
    clientId: '81f2378d-f80f-4892-a2f0-09d5f2b38573',
    endpoints: {
        api: '81f2378d-f80f-4892-a2f0-09d5f2b38573'
    },
    postLogoutRedirectUri: window.location.origin,
    cacheLocation: 'localStorage'
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) => adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const getToken = () => authContext.getCachedToken(authContext.config.clientId);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);
