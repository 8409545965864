import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, CustomInput, Row, FormFeedback, FormGroup, Card, Input } from 'reactstrap';
import BlockUi from 'react-block-ui';
import { ActiveInactiveStatusCombo, BinaryStatusCombo } from '../../Base/CommonUIComponents';
import { SenderChannelOptions } from '../Common/CommunicationFunctions';
import CustomSelect from '../../Base/CustomSelect';

class CustomStep1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
        };
    }

    selectSender = (sender) => {
        const senderId = { target: { name: 'senderId', value: sender.sender_id } };
        const senderName = { target: { name: 'sender', value: sender.name ? sender.name : sender.email ? sender.email : sender.cellphone } };

        this.props.handleChange(senderId);
        this.props.handleChange(senderName);
    }

    handleButton = (field, data) => {
        const e = { target: { name: field, value: data } };

        this.props.handleChange(e);
    }

    handleSenderChannel = (field, data) => {
        const senderChannel = { target: { name: field, value: data } };
        this.props.handleChange(senderChannel);
        const senderId = { target: { name: 'senderId', value: null } };
        this.props.handleChange(senderId);
        const sender = { target: { name: 'sender', value: null } };
        this.props.handleChange(sender);
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            offset: this.state.offset + value
        }, () => this.getSenders(this.props.campaign.senderChannel));
    }

    render() {
        const { campaign, handleChange, handleStatusSelect, marketingType, hasSendGrid, alreadySentAndExists, collectMetricsForGoogleAnalytics, handleSwitch, invalidCampaignName, validateCampaignName } = this.props;

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <Row >
                    <Col>
                        <h5><FormattedMessage id="CampaignDetails.Name" /></h5>
                    </Col>
                </Row>
                <FormGroup row>
                    <Col>
                        <Input
                            type="text"
                            name="name"
                            onChange={(e) => handleChange(e)}
                            value={campaign.name ? campaign.name : ''}
                            maxLength='100'
                        />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col className="col-3">
                            <h5>
                                <FormattedMessage id="CampaignDetails.Status" />
                            </h5>
                        </Col>
                        {
                            hasSendGrid ?
                                <>
                                    <Col className="col-3">
                                        <h5>
                                            <FormattedMessage id="CampaignDetails.CollectMetricsForGoogleAnalytics" />
                                        </h5>
                                    </Col>
                                    {
                                        collectMetricsForGoogleAnalytics ?
                                            <Col style={{ display: collectMetricsForGoogleAnalytics ? 'initial' : 'none' }}>
                                                <h5>
                                                    <FormattedMessage id="CampaignDetails.CampaignName" />
                                                </h5>
                                            </Col>
                                            :
                                            ''
                                    }
                                </>
                                :
                                ''
                        }
                    </Row>
                    <Row>
                        <Col className='col-3'>
                            <ActiveInactiveStatusCombo
                                name='active'
                                value={campaign.active}
                                onChangeFunc={handleStatusSelect}
                                placeholder={<FormattedMessage id="generic.status" />}
                                required={true}
                            />
                        </Col>
                        {
                            hasSendGrid ?
                                <>
                                    <Col className="col-3">
                                        <BinaryStatusCombo
                                            name='collectMetricsForGoogleAnalytics'
                                            value={collectMetricsForGoogleAnalytics}
                                            onChangeFunc={handleSwitch}
                                            placeholder={<FormattedMessage id="generic.status" />}
                                        />
                                    </Col>
                                    {
                                        collectMetricsForGoogleAnalytics ?
                                            <Col className="ml-3 mr-3">
                                                <FormGroup row>
                                                    <FormattedMessage id="CampaignDetails.CampaignName">
                                                        {
                                                            placeholder =>
                                                                <Input
                                                                    type="text" name="campaignName" value={campaign.campaignName ? campaign.campaignName : ''}
                                                                    invalid={invalidCampaignName}
                                                                    disabled={alreadySentAndExists}
                                                                    onChange={(e) => handleChange(e)}
                                                                    onBlur={(e) => validateCampaignName(e)}
                                                                    maxLength="50"
                                                                    placeholder={placeholder}
                                                                />
                                                        }
                                                    </FormattedMessage>
                                                    <FormFeedback>
                                                        <FormattedMessage id="CampaignDetails.InvalidCampaignName" />
                                                    </FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            :
                                            ''
                                    }
                                </>
                                :
                                ''
                        }
                    </Row>
                </FormGroup>

                <Row>
                    <Col>
                        <h5><FormattedMessage id="CampaignDetails.CampaignType" /></h5>
                    </Col>
                </Row>
                <FormGroup row>
                    <Col>
                        <Row>
                            {marketingType && marketingType.map((mt, key) =>
                                <Col className="col-3" key={key}>
                                    <Card className="border-0 shadow pointer" body
                                        style={campaign && campaign.marketingType && campaign.marketingType === mt.value ? { backgroundColor: '#d3eaff' } : {}}
                                        onClick={() => this.props.handleCampaignType(mt.value)}
                                    >
                                        {mt.label}
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </FormGroup>

                <SenderChannelOptions
                    hasEgoi={this.props.hasEgoi}
                    hasSendGrid={this.props.hasSendGrid}
                    hasTwillio={this.props.hasTwillio}
                    selectedChannel={campaign?.senderChannel}
                    senderId={campaign?.senderId}
                    handleSenderChannel={(channel) => this.handleSenderChannel('senderChannel', channel)}
                    selectSender={this.selectSender}
                />
            </BlockUi>
        );
    }
}
export default injectIntl(CustomStep1)