import React, { Component } from 'react';
import { ActiveInactiveStatusBadge, BlankCard, StyledCard } from '../../Base/CommonUIComponents';
import { Button, Label, Input, Row, Col, Card, FormGroup, ModalBody, Form, Modal } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getAPI, postAPI } from "../../Base/API";
import moment from 'moment';
import { getProfileDataQualityMetrics } from '../../Base/ReferenceDataFunctions';
import { handleNotification } from '../../Base/Notification';
import CustomSelect from '../../Base/CustomSelect';
import ConfirmActionModal from '../../Base/ConfirmActionModal';

class ConfigProfileDataQuality extends Component {
    static displayName = ConfigProfileDataQuality.name;

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            modal: false,
            deleteModal: false,
            profileDataConfig: {},
            metrics: getProfileDataQualityMetrics(),
            selectedConfig: {},
            metricsWithoutConfig: ['Consent']
        };
    }

    componentDidMount() {
        this.getConfig();
    }

    getConfig = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ profileDataConfig: data.response && data.response.length > 0  ? data.response[0] : {}, block: false });
            }
            else this.setState({ profileDataConfig: {}, block: false });
        }, `/api/gms/DataQuality/v1/qualityProfile`);
    }

    saveConfig = (e, isToRemove) => {
        e.preventDefault();
        this.setState({ block: true });

        const body = this.getUpdatedList(isToRemove); 

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                    return;
                }
                else {
                    handleNotification(data, <FormattedMessage id="ConfigProfileDataQuality.ConfigSaved" />, <FormattedMessage id="generic.success" />);
                    this.setState({ profileDataConfig: data.response && data.response.length > 0 ? data.response[0] : {}, block: false, modal: false, deleteModal: false });
                }
            }
            else this.setState({ block: false });
        }, `/api/gms/DataQuality/v1/qualityProfile`, body);
    }

    toggleStatus = () => {
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                    return;
                }
                else {
                    handleNotification(data, <FormattedMessage id="ConfigProfileDataQuality.StatusChanged" />, <FormattedMessage id="generic.success" />);
                    this.setState({ profileDataConfig: data.response ? data.response[0] : {}, block: false });
                }
            }
            else this.setState({ block: false });
        }, `/api/gms/DataQuality/v1/qualityProfile/Activate?activate=${!this.state.profileDataConfig.active}`);
    }

    getUpdatedList = (isToRemove) => {
        var list = { ...this.state.profileDataConfig };

        if (!list.criteria) list.criteria = [];

        const idx = this.state.selectedConfig.id ? list?.criteria?.findIndex(el => this.state.selectedConfig.id === el.id) : -1;

        if (idx > -1) {
            if (isToRemove) {
                list.criteria.splice(idx, 1);
            }
            else list.criteria[idx] = this.state.selectedConfig;
        }
        else {
            list.criteria.push(this.state.selectedConfig);
        }

        return list;
    }

    getAvailableMetric = (selectedMetric) => {
        const { profileDataConfig } = this.state;
        const metrics = this.state.metrics;

        if (profileDataConfig.criteria && profileDataConfig.criteria.length > 0) {
            return this.state.metrics.filter(m => profileDataConfig.criteria.some(c => c.criteria === m.value) === false || m.value === selectedMetric);
        }

        return metrics;
    }

    toggleModal = (modalName, selectedConfig) => {
        this.setState(prevState => ({
            [modalName]: !prevState[modalName],
            selectedConfig: selectedConfig || {}
        }));
    }

    handleSelect = (combo, name) => {
        let validationRule = this.state.selectedConfig.validationRule;

        if (this.state.metricsWithoutConfig.some(el => el === combo.value)) {
            validationRule = null;
        }

        this.setState(prevState => ({
            selectedConfig: {
                ...prevState.selectedConfig,
                [name]: combo ? combo.value : null,
                validationRule
            }
        }));
    }

    handleChange = (evt) => {
        const { name, value } = evt.target;

        this.setState(prevState => ({
            selectedConfig: {
                ...prevState.selectedConfig,
                [name]: value
            }
        }));
    }

    render() {
        const { block, error, profileDataConfig, metrics, modal, selectedConfig, deleteModal } = this.state;

        return (
            <StyledCard block={block} error={error} >
                <Row className="mb-4">
                    <Col>
                        <h5> <i className="fas fa-user-cog" /> <FormattedMessage id="ConfigProfileDataQuality.ConfigProfileDataQuality" /> </h5>
                    </Col>
                    <Col className="text-right">
                        <Button className="btn-sm btn-host mr-2" onClick={this.toggleStatus}>
                            <i className="fas fa-power-off" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <b> <FormattedMessage id="BatchLogs.LastRun" /> </b>
                    </Col>
                    <Col>
                        {profileDataConfig?.lastRun ? moment(profileDataConfig.lastRun).format("YYYY-MM-DD HH:mm") : ''}
                    </Col>
                    <Col>
                        <b> <FormattedMessage id="generic.status" /></b>
                    </Col>
                    <Col>
                        <ActiveInactiveStatusBadge status={profileDataConfig.active} />
                    </Col>
                </Row>


                <Row className="mt-5 mb-3">
                    <Col>
                        <h5> <FormattedMessage id="ConfigProfileDataQuality.Metrics" /></h5>
                    </Col>

                    {!profileDataConfig.criteria || (profileDataConfig.criteria.length !== metrics.length) ?
                        <Col className="text-right">
                            <Button className="btn-sm btn-host mr-2" onClick={() => this.toggleModal('modal')}>
                                <i className="fas fa-plus" />
                            </Button>
                        </Col>
                    : ''}
                </Row>

                {profileDataConfig.criteria && profileDataConfig.criteria.map((criteria, idx) => {
                    const metric = metrics.find(m => m.value === criteria.criteria);
                    const isMetricWithoutConfig = this.state.metricsWithoutConfig.some(el => el === criteria.criteria);

                    return <Card className="border-0 shadow p-2 mb-2" key={idx}>
                        <Row className="align-items-center">
                            <Col sm={3}>
                                <i className={`${metric.icon} mr-2`}/> {metric.label}
                            </Col>
                            <Col sm={1}>
                                {isMetricWithoutConfig ? '' : <b> Regex </b>}
                            </Col>
                            <Col sm={7}>
                                {isMetricWithoutConfig ? <FormattedMessage id={`ConfigProfileDataQuality.${criteria.criteria}Text`} /> : criteria.validationRule}
                            </Col>
                            <Col className="col-1 text-right">
                                <Button className="btn-sm btn-host" onClick={() => this.toggleModal('modal', criteria)}>
                                    <i className="far fa-edit" />
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                })}


                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={() => this.toggleModal('deleteModal')}
                        actionFunction={(e) => this.saveConfig(e, true)}
                        text={<FormattedMessage id="ConfigProfileDataQuality.ConfirmDeleteText" />}
                        block={block}
                    />
                : ''}


                <Modal isOpen={modal} fade={false} size={"lg"} style={{ minWidth: 0 }} >
                    <Button onClick={() => this.toggleModal('modal')} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    <ModalBody className="pb-0">
                        <BlankCard block={this.state.block} error={this.state.error}>
                            <Form onSubmit={(e) => this.saveConfig(e, false)}>
                                <Row className="mb-4 align-items-center">
                                    <Col>
                                        <h5><FormattedMessage id="ConfigProfileDataQuality.ConfigMetric" /></h5>
                                    </Col>
                                    <Col className="text-right col-3">
                                        {selectedConfig.id ?
                                            <Button className="btn-sm btn-host mr-2" onClick={() => this.toggleModal('deleteModal', selectedConfig)}>
                                                <i className="fas fa-trash-alt" />
                                            </Button>
                                        : ''}

                                        <Button className="btn-sm btn-host" type="submit">
                                            <i className="fas fa-save" />
                                        </Button>
                                    </Col>
                                </Row>

                                <FormGroup row>
                                    <Label sm={4}> <FormattedMessage id="ConfigProfileDataQuality.Metric" /></Label>
                                    <Col sm={8} >
                                        <CustomSelect
                                            isSearchable
                                            placeholder={""}
                                            options={this.getAvailableMetric(selectedConfig.criteria)}
                                            value={this.getAvailableMetric(selectedConfig.criteria).find(el => el.value === selectedConfig.criteria)}
                                            onChange={(combo) => this.handleSelect(combo, 'criteria')}
                                            required
                                        />
                                    </Col>
                                </FormGroup>
                                {!this.state.metricsWithoutConfig.some(el => el === selectedConfig.criteria) ?
                                    <FormGroup row>
                                        <Label sm={4}> <FormattedMessage id="ConfigProfileDataQuality.Regex" /></Label>
                                        <Col sm={8} >
                                            <Input
                                                type="textarea"
                                                name="validationRule"
                                                placeholder={""}
                                                onChange={(e) => this.handleChange(e)}
                                                value={selectedConfig.validationRule || ''}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>
                                    :
                                    <Row>
                                        <Col sm={4} />
                                        <Col sm={8}> <FormattedMessage id={`ConfigProfileDataQuality.${selectedConfig.criteria}Text`} /> </Col>
                                    </Row>
                                    }
                            </Form>
                        </BlankCard>
                    </ModalBody>
                </Modal>
            </StyledCard>
        );
    }
}

export default injectIntl(ConfigProfileDataQuality);