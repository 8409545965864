import moment from 'moment-timezone';
import React, { Component } from 'react'
import { DateRangePicker } from 'react-dates';
import { FormattedMessage, injectIntl } from 'react-intl'
import { Button, Col, Form, Modal, ModalBody, Row } from 'reactstrap'

class CreateRunModal extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            newRun: {
                reviewSummaryConfigId: this.props.configId,
                status: "Running"
            }
        };
    }

    render() {
        const { toggleModal, createConfigRuns, intl } = this.props;
        const { newRun } = this.state;
        
        return (
            <Modal isOpen={true} fade={false} className='noSize'>
                <Button onClick={toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm" />
                </Button>
                <ModalBody className='p-4'>
                    <Form onSubmit={e => {toggleModal();createConfigRuns(e, newRun)}}>
                        <Row>
                            <Col>
                                <h5><FormattedMessage id="SummaryConfig.CreateRun"/></h5>
                            </Col>
                            <Col className="text-right mb-2">
                                <Button className="btn-sm btn-host" type="submit">
                                    <i className="fas fa-plus" />
                                </Button>
                            </Col>
                        </Row>
                        <Row className='mt-2 text-center'>
                            <Col>
                                <DateRangePicker
                                    startDate={newRun.fromDate ? moment(newRun.fromDate) : null}
                                    startDateId="startDate"
                                    isOutsideRange={day => day > moment()}
                                    endDate={newRun.toDate ? moment(newRun.toDate) : null}
                                    endDateId="endDate"
                                    onDatesChange={({ startDate, endDate }) => this.setState({ newRun: { ...newRun, fromDate: startDate?.format('YYYY-MM-DD'), toDate: endDate?.format('YYYY-MM-DD') } })}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    small={true}
                                    numberOfMonths={1}
                                    showDefaultInputIcon={true}
                                    renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                                    required
                                />
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        )
    }
}

export default injectIntl(CreateRunModal);