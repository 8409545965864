import React from 'react';
import Feature from 'ol/Feature';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import Map from 'ol/Map';
import { fromLonLat, transform } from 'ol/proj';
import VectorSource from 'ol/source/Vector'
import Stroke from 'ol/style/Stroke';
import OSM from 'ol/source/OSM'
import { Icon, Style } from 'ol/style';
import { Point, Circle } from 'ol/geom';
import View from 'ol/View'; 

class MapModal extends React.Component {
    constructor(props) {
        super(props);
        this.getMapDetails = this.getMapDetails.bind(this);
        this.onClick = this.onClick.bind(this);
        this.state = {};
    }

    componentDidUpdate(prevProps) {
        if (prevProps.latitude !== this.props.latitude || prevProps.longitude !== this.props.longitude) {
            //Change center view only when coordinates are not changed directly on the map
            const changeCenterView = this.state.latitude !== this.props.latitude || this.state.longitude !== this.props.longitude;

            this.updatePointer(this.props.latitude, this.props.longitude, changeCenterView);
        }
    }

    componentDidMount() {
        const { latitude, longitude } = this.props;

        const mapDetails = this.getMapDetails(latitude, longitude);

        mapDetails.map.on('singleclick', this.onClick);
        mapDetails.map.addLayer(mapDetails.layer);
        mapDetails.map.addLayer(mapDetails.radiusCircle);

        this.setState({
            layer: mapDetails.layer,
            map: mapDetails.map,
            radiusCircle: mapDetails.radiusCircle,
            featuresLayer: this.getFeaturesLayer()
        });
    }

    updatePointer = (latitude, longitude, changeCenterView) => {
        let { layer, map, radiusCircle } = this.state;

        const newLayer = this.getNewLayer(latitude, longitude);
        const newRadiusCircle = this.getRadiusCircle(latitude, longitude);

        if (changeCenterView) {
            //Center view to selected point
            map.getView().setCenter(fromLonLat([longitude, latitude]));
        }

        //Removes prev layers and set new ones
        map.removeLayer(layer);
        map.removeLayer(radiusCircle);
        map.addLayer(newLayer);
        map.addLayer(newRadiusCircle);


        this.setState({ layer: newLayer, map, radiusCircle: newRadiusCircle, featuresLayer: this.getFeaturesLayer(), latitude, longitude });
    }

    getFeaturesLayer() {
        const featuresLayer = new VectorLayer({
            source: new VectorSource({
                features: []
            })
        });
        return featuresLayer;
    }
    
    onClick(evt) {
        const coordinates = transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
        const latitude = coordinates[1];
        const longitude = coordinates[0];

        this.setState({ latitude, longitude });

        this.props.onClick(latitude, longitude);
    }

    getMapDetails(latitude = 0, longitude = 0) {
        const map = new Map({
            target: this.refs.mapContainer,
            layers: [
                new TileLayer({
                    source: new OSM()
                }),
            ],
            view: new View({
                center: fromLonLat([longitude, latitude]),
                zoom: 13,
            }),
            controls: []
        });

        if (this.props.disableInteractions) {
            map.getInteractions().forEach(x => x.setActive(false));
        }

        const layer = this.getNewLayer(latitude, longitude);
        const radiusCircle = this.getRadiusCircle(latitude, longitude);

        const mapDetails = {
            map: map,
            layer: layer,
            radiusCircle 
        };

        return mapDetails;
    }

    getNewLayer(latitude, longitude) {
        var layer = new VectorLayer({
            source: new VectorSource({
                features: [
                    new Feature({ geometry: new Point(fromLonLat([longitude, latitude])) })
                ]
            })
        });
        layer.setStyle(new Style({
            image: new Icon(({
                src: require("../../img/pointer.png")
            })),
        }));


        return layer;
    }

    getRadiusCircle = (latitude, longitude) => {
        var centerLongitudeLatitude = fromLonLat([longitude, latitude]);
        const radius = this.props.distance * 1000 //meters

        var layer = new VectorLayer({
            source: new VectorSource({
                features: [
                    new Feature(new Circle(centerLongitudeLatitude, radius)),
                ]
            }),
            style: [
                new Style({
                    stroke: new Stroke({
                        color: 'red',
                        width: 3
                    })
                })
            ]
        });

        return layer;
    }

    render() {
        return (
            <div ref="mapContainer" id="canvasContainer" style={{ width: '100%', height: this.props.mapHeight || '700px' }}>
            </div>
        );
    }
}

export default MapModal;