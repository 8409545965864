import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Badge, Button, Col, Form, Row, UncontrolledTooltip } from 'reactstrap';
import { getAPI, postAPI } from "../../Base/API";
import { getResolvedStatus, getRuleTypeList, getPMSReservationStatus, showDuplications, getProfileType } from "../../Base/ReferenceDataFunctions";
import { DefaultPage, ProfileIsMasterBadge, SelectHotel, SelectIsMaster, StyledCard } from "../../Base/CommonUIComponents"; 
import { CustomTable } from '../../Base/CustomTable';
import CustomSelect from '../../Base/CustomSelect';
import { DateRangePicker } from "react-dates";
import moment from "moment";

import Authorization, { CheckAuthorization } from '../../Base/Authorization';
import { handleNotification } from '../../Base/Notification';
import BulkAction from './BulkAction';
import EditStatus from './EditStatus';

export class DataQualityIssues extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            blockTemplates: true,
            blockRules: true,
            pageIndex: 0,
            pageSize: 10,
            totalItems: 0,
            issuesList: null,
            fromDate: moment().subtract(7, 'days'),
            toDate: moment(),
            templateList: [],
            rulesList: [],
            statusOptions: getResolvedStatus(this.props.intl),
            resolvedSatusOptions: getResolvedStatus(this.props.intl),
            issuesToUpdate: [],
            selectedRows: [],
            bulkActionModal: false,
            editStatusModal: false
        };
    }

    componentDidMount() {
        this.updateStateFilters();

        this.getComboData();
    }

    updateStateFilters = (disabledHotelFilter) => {
        let params = new URLSearchParams(window.location.search);
        const initialParamsSize = params.size;
                
        if (!params.has('fromDate')) params.append('fromDate', moment().subtract(7, 'days').format("YYYY-MM-DD"))
        if (!params.has('toDate')) params.append('toDate', moment().format("YYYY-MM-DD"))
        if (!params.has('page')) params.append('page', 1)
        if (!params.has('pageSize')) params.append('pageSize', 10)

        let fromDate = params.get('fromDate');
        let toDate = params.get('toDate');
        let pageSize = params.get('pageSize');
        let pageIndex = params.get('page') - 1;
        let templateId = params.get('template');
        let ruleId = JSON.parse(params.get('rules'));
        let resolved = params.get('resolved');
        let isMaster = params.get('isMaster');
        let hotelId = params.get('hotel');
        let profileType = params.get('profileType');

        if (ruleId) {
            params.delete('rules');
            params += `&rules=${JSON.stringify(ruleId)}`;
        }
        
        this.props.history.push({ search: `?${params}` });

        this.setState({ pageSize, pageIndex, fromDate, toDate, ruleId, templateId, resolved, isMaster, hotelId, profileType, disabledHotelFilter }, () => {
            if (initialParamsSize > 4 || pageSize !== '10' || pageIndex > 0) {
                this.getDataQualityIssues(false);
            }
        });
    }

    getComboData = () => {
        this.getTemplates();
        this.getRules();
    }
    
    getTemplates = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockTemplates: false });
                return;
            }
            if (data) {
                var templates = [];

                data.response && data.response.forEach(el => {
                    el.dataQualityTemplates && el.dataQualityTemplates.forEach(template => {
                        templates.push({ value: template.id, label: template.name });
                    })
                });
                this.setState({ error: errorMessage, blockTemplates: false, templateList: templates.sort((a, b) => a.label.toLowerCase() < b.label.toLowerCase() ? -1 : a.label.toLowerCase() < b.label.toLowerCase() ? 1 : 0) });
            }
            else {
                this.setState({ error: errorMessage, blockTemplates: false });
            }
        }, `/api/gms/DataQuality/v1/DataQualityTemplate`);
    }

    getRules = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockRules: false });
                return;
            }
            if (data) {
                var rules = [];

                data.response && data.response.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() < b.name.toLowerCase() ? 1 : 0).forEach(el => {
                    rules.push({ value: el.id, label: el.name });
                });

                this.setState({ error: errorMessage, blockRules: false, rulesList: rules });
            }
            else {
                this.setState({ error: errorMessage, blockRules: false });
            }
        }, `/api/gms/DataQuality/v1/Rule`);
    }
    
    getDataQualityIssues = (updateUrlParams, totalItemsToExport, cb) => {
        this.setState({ block: true });

        var params = this.updateAndGetUrlParams(updateUrlParams, totalItemsToExport);

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (totalItemsToExport) {
                    this.setState({ issuesList: data.response }, () => { cb(); this.getDataQualityIssues(false) });
                }
                else {
                    const notSelectableRows = [];

                    data.response && data.response.forEach(el => {
                        if (el.ruleType !== 'FieldCheck') {
                            notSelectableRows.push(el.id);
                        }
                    })

                    this.setState({ error: errorMessage, block: false, issuesList: data.response, totalItems: data.totalItems, notSelectableRows, selectedRows: [] });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/DashBoard/DataQualityIssues` + params);
    }

    updateAndGetUrlParams = (updateUrlParams, totalItemsToExport) => {
        const { pageSize, pageIndex, fromDate, toDate, templateId, ruleId, resolved, isMaster, hotelId, profileType } = this.state;

        if (updateUrlParams) {
            let url = `?page=${pageIndex + 1}&pageSize=${pageSize}`;

            if (fromDate) url += `&fromDate=${moment(fromDate).format("YYYY-MM-DD")}`;
            if (toDate) url += `&toDate=${moment(toDate).format("YYYY-MM-DD")}`;
            if (templateId) url += `&template=${templateId}`;
            if (ruleId) url += `&rules=${JSON.stringify(ruleId)}`;
            if (resolved != undefined && resolved != null) url += `&resolved=${resolved}`;
            if (isMaster !== undefined && isMaster !== null) url += `&isMaster=${isMaster}`;;
            if (hotelId) url += `&hotel=${hotelId}`;
            if (profileType) url += `&profileType=${profileType}`;

            this.props.history.push({ search: url });
        }

        var params = `?pageSize=${totalItemsToExport ? totalItemsToExport : pageSize}&pageIndex=${totalItemsToExport ? 0 : pageIndex}`;

        if (fromDate) params += `&rateDetailFrom=${moment(fromDate).format('YYYY-MM-DD')}`;
        if (toDate) params += `&rateDetailTo=${moment(toDate).format("YYYY-MM-DD")}`;
        if (templateId) params += `&templateId=${templateId}`;
        if (ruleId) ruleId.forEach(rule => { params += `&rules=${rule}` });
        if (resolved != undefined && resolved != null) params += `&resolved=${resolved}`;
        if (isMaster !== undefined && isMaster !== null) params += `&isMaster=${isMaster}`;
        if (hotelId) params += `&hotelId=${hotelId}`;
        if (profileType) params += `&profileType=${profileType}`;

        return params;
    }

    doSearch = (e) => {
        e.preventDefault();

        this.setState({
            pageIndex: 0
        }, () => this.getDataQualityIssues(true))
    }

    checkRules = (evt) => {
        evt.preventDefault();
        this.setState({ block: true });

        const body = [...this.state.selectedRows].map(el => el.runDetailId);

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="ProfileDetails.ProfileRevalidated" />, <FormattedMessage id="generic.success" />);
                    this.getDataQualityIssues();
                }

                this.setState({ block: false });
            }
        }, `/api/gmsscheduletask/ScheduleTasks/CheckRules`, body)
    }

    handleChangeSelect = (name, combo) => {
        var targetValue = [];

        if (Array.isArray(combo)) {
            combo.map(el => targetValue.push(el.value));
        } else {
            targetValue = combo ? combo.value : null;
        }

        this.setState({ [name]: targetValue })
    };

    handleTableChange = (_, { page, sizePerPage }) => {
        this.setState({
            pageIndex: page - 1,
            pageSize: sizePerPage,
        },() => this.getDataQualityIssues(true));
    }

    handleCustomExportClick = (cb) => {
        this.setState({ block: true }, _ => this.getDataQualityIssues(false, 5000, cb));
    }

    getDuplcationsOrStatus = (cell, row, rowIndex, resolvedSatusOptions) => {
        if (cell != null && showDuplications(row.ruleType, row.duplicateMatchType)) {
            const totalDup = cell.length;

            if (totalDup === 0) {
                const status = resolvedSatusOptions[0];

                return status ? <Badge color={status.color} className="py-1 px-2"> {status.label} </Badge> : ''
            }
            else {
                return <div>
                    {row.onHold ?
                        <>
                            <i className="icon-icon-warnings-enabled text-warning mr-2" id={`possibleMasterSplit-${row.profileId}`} />
                            <UncontrolledTooltip placement="bottom" target={`possibleMasterSplit-${row.profileId}`}>
                                <FormattedMessage id="TaskResultDashboard.PossibleMasterSplit" />
                            </UncontrolledTooltip>
                        </>
                        : row.masterId && row.profileId !== row.masterId ?
                            <>
                                <i className="fas fa-check-circle color-green mr-2" id={`merged-${row.profileId}`} />
                                <UncontrolledTooltip placement="bottom" target={`merged-${row.profileId}`}>
                                    <FormattedMessage id="TaskResultDashboard.ProfileAlreadyHasAnAssociatedMaster" />
                                </UncontrolledTooltip>
                            </>
                            : ''}

                    #{totalDup} {this.props.intl.formatMessage({ id: "TaskResultDetail.PossibleDuplications" })}

                    <Authorization
                        perform="dataQualityIssues:merge"
                        yes={() => (
                            <Button className="btn-sm btn-host float-right mr-2" onClick={() => this.props.history.push({ pathname: `/ProfileMergeDetail`, state: { duplicatedIds: [row.profileId].concat(row.duplicateIds) } })}>
                                Merge
                            </Button>
                        )}
                        no={() => <div></div>}
                    />
                </div>
            }
        }
        else {
            const status = resolvedSatusOptions.find(opt => opt.value === row.resolved);

            return status ? <Badge color={status.color} className="py-1 px-2"> {status.label} {row.resolved !== 'Ignored' && row.ruleMode ? `(${this.props.intl.formatMessage({ id: `ReferenceDataFunctions.${row.ruleMode}`}) })` : ''} </Badge> : ''
        }
    }


    renderTableButtons = () => {
        const { selectedRows, issuesList } = this.state;
        const hasSelectedRows = selectedRows && selectedRows.length > 0;

        const sameRuleId = selectedRows.length === [...selectedRows.filter(el => el.ruleId === selectedRows[0].ruleId)].length;

        return (issuesList && issuesList.length > 0 ?
            <Col className="col-6">
                <Authorization
                    perform="dataQualityIssues:status:edit"
                    yes={() => (
                        <Button className="btn-sm btn-host btn mr-2" onClick={() => this.toggleModal('editStatusModal')} disabled={!hasSelectedRows}>
                            <i className="fas fa-edit" /> <FormattedMessage id="DataQualityIssues.EditStatus" />
                        </Button>
                    )}
                    no={() => <div></div>}
                />
                <Authorization
                    perform="dataQualityIssues:transformation"
                    yes={() => (
                        <Button className="btn-sm btn-host btn mr-2" onClick={() => this.toggleModal('bulkActionModal')} disabled={!hasSelectedRows || !sameRuleId}>
                            <i className="fas fa-cog" /> <FormattedMessage id="DataQualityIssues.BulkAction" />
                        </Button>
                    )}
                    no={() => <div></div>}
                />
                <Authorization
                    perform="dataQualityIssues:rules:check"
                    yes={() => (
                        <Button className="btn-sm btn-host btn mr-2" onClick={this.checkRules} disabled={!hasSelectedRows}>
                            <i className="fas fa-user-check" /> <FormattedMessage id="DataQualityIssues.CheckRules" />
                        </Button>
                    )}
                    no={() => <div></div>}
                />
            </Col>
        : '');
    }

    toggleModal = (modal) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }));
    }

    getNonSelectableRows = (selectedRows) => {
        let nonSelectable = [];

        selectedRows.forEach((obj, index) => {
            if (obj.ruleType === 'FieldCheck') {
                nonSelectable.push(obj.id);
            }
        });

        return nonSelectable;
    }

    render() {
        const { block, blockRules, blockTemplates, error, issuesList, pageIndex, pageSize, templateList, rulesList, totalItems, statusOptions, resolvedSatusOptions, disabledHotelFilter, notSelectableRows } = this.state;

        const columns = [
            {
                dataField: "hotelName",
                text: this.props.intl.formatMessage({ id: "DataQualityIssues.Hotel" })
            },
            {
                dataField: "templateName",
                text: this.props.intl.formatMessage({ id: "DataQualityIssues.Template" })
            },
            {
                dataField: "ruleName",
                text: this.props.intl.formatMessage({ id: "DataQualityIssues.Rule" })
            },
            {
                dataField: "ruleType",
                text: this.props.intl.formatMessage({ id: "DataQualityIssues.RuleType" }),
                formatter: (cell) => cell && getRuleTypeList(this.props.intl).some(rt => rt.value === cell) ? getRuleTypeList(this.props.intl).find(rt => rt.value === cell).label : '',
                csvFormatter: (cell) => cell && getRuleTypeList(this.props.intl).some(rt => rt.value === cell) ? `${getRuleTypeList(this.props.intl).find(rt => rt.value === cell).label}` : ''
            },
            {
                dataField: "profileId",
                text: this.props.intl.formatMessage({ id: "DataQualityIssues.ProfileId" }),
                hidden: true
            },
            {
                dataField: "profileFirstName",
                text: this.props.intl.formatMessage({ id: "DataQualityIssues.Profile" }),
                formatter: (cell, row) => {
                    const profileType = getProfileType(this.props.intl).find(pt => pt.value === row.profileType);

                    return <div>
                        {profileType ? <i className={`${profileType.icon} mr-1`} /> : <span className="mr-4" />}
                        {row.profileFirstName} {row.profileLastName}
                        <span className="float-right pr-3"> <ProfileIsMasterBadge isMaster={row.isMaster} linked={!row.isMaster && row.masterId} /> </span>
                    </div>
                },
                style: { cursor: 'pointer', textDecoration: 'underline' },
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        this.props.history.push({ pathname: "/ProfileDetails/" + row.profileId, rules: [row.ruleId] })
                    },
                },
                csvFormatter: (cell, row) => `${row.profileFirstName} ${row.profileLastName}`
            },
            {
                dataField: "isMaster",
                text: "Master",
                hidden: true,
                csvFormatter: (cell, row) => cell ? 'Master' : row.masterId ? 'Linked' : ''
            },
            {
                dataField: "profileLastUpdateDate",
                text: this.props.intl.formatMessage({ id: "DataQualityIssues.LastUpdate" }),
                formatter: (cell) => `${moment(cell).format('YYYY-MM-DD')}`,
                csvFormatter: (cell) => cell ? `${moment(cell).format('YYYY-MM-DD')}` : ''
            },
            {
                dataField: "value",
                text: this.props.intl.formatMessage({ id: "DataQualityIssues.Value" }),
                csvFormatter: (cell) => cell ? cell : ''
            },
            {
                dataField: "resolved",
                text: this.props.intl.formatMessage({ id: "TaskResultDetail.Duplications/Status" }),
                formatter: (cell, row, rowIndex) => this.getDuplcationsOrStatus(row.duplicateIds, row, rowIndex, resolvedSatusOptions),
                sort: true,
                csvFormatter: (cell, row) => {
                    if (row.duplicateIds != null && showDuplications(row.ruleType, row.duplicateMatchType)) {
                        const totalDup = row.duplicateIds.length;

                        if (totalDup === 0) {
                            return `${resolvedSatusOptions[0].label}`
                        }

                        return `#${totalDup} ${this.props.intl.formatMessage({ id: "TaskResultDetail.PossibleDuplications" })}`
                    }
                    else {
                        return `${resolvedSatusOptions.filter(opt => opt.value === row.resolved).map(opt => opt.label)} ${row.ruleMode && row.resolved !== 'Ignored' ? `(${this.props.intl.formatMessage({ id: `ReferenceDataFunctions.${row.ruleMode}` })}` : ''})`
                    }
                }
            },
            {
                dataField: "reservationStatus",
                text: this.props.intl.formatMessage({ id: "DataQualityIssues.Reservation" }),
                formatter: (cell, row) => {
                    if (row.ruleType === 'FieldCheck') {
                        const resStatus = getPMSReservationStatus(this.props.intl).find(res => res.value == cell);

                        if (resStatus) {
                            return <Badge className="py-1 px-2 " color={resStatus.color}> {resStatus.label} </Badge>
                        }
                    }
                },
                sort: true,
                csvExport: false
            }
        ];

        const selectRow = {
            mode: 'checkbox',
            bgColor: '#f8f9fa',
            clickToSelect: true,
            nonSelectable: notSelectableRows,
            selected: [...this.state.selectedRows].map(el => el.id),
            onSelect: (row, isSelect, rowIndex, e) => {
                let list = [...this.state.selectedRows];

                if (isSelect) {
                    list.push(row);
                }
                else {
                    list = list.filter(el => el !== row);
                }

                this.setState({ selectedRows: list });
            },
            onSelectAll: (isSelect, rows, e) => {
                if (isSelect) {
                    this.setState({ selectedRows: rows.map(el => el) });
                }
                else {
                    this.setState({ selectedRows: [] });
                }
            }
        };

        return (
            <StyledCard block={block || blockRules || blockTemplates} error={error}>

                {this.state.editStatusModal ?
                    <EditStatus
                        modal={this.state.editStatusModal}
                        toggleModal={() => this.toggleModal('editStatusModal')}
                        selectedRows={this.state.selectedRows}
                        getDataQualityIssues={this.getDataQualityIssues}
                    />
                    : ''}

                {this.state.bulkActionModal ?
                    <BulkAction
                        modal={this.state.bulkActionModal}
                        toggleModal={() => this.toggleModal('bulkActionModal')}
                        selectedRows={this.state.selectedRows}
                        columns={columns}
                        getDataQualityIssues={this.getDataQualityIssues}
                    />
                : ''}

                <Form onSubmit={this.doSearch}>
                    <Row>
                        <Col><h5>
                            <i className="fas fa-exclamation-triangle mr-2"></i>
                            <FormattedMessage id="DataQualityIssues.Title" />
                            </h5>
                        </Col>
                    </Row>
                    <Row className="mb-2 mt-3 align-items-center">
                        <Col className="col-3 pr-1">
                            <SelectHotel
                                name={'hotelId'}
                                icon="icon-icon-hotel"
                                onChangeFunc={this.handleChangeSelect}
                                placeholder={<FormattedMessage id="generic.Hotel" />}
                                value={this.state.hotelId || ''}
                                addDefaultValue={!(new URLSearchParams(window.location.search).has("hotel"))}
                            />
                        </Col>
                        <Col className="col-3 pr-1">
                            <CustomSelect
                                options={templateList}
                                placeholder={<FormattedMessage id="TaskResultDashboard.SelectTemplate" />}
                                onChange={this.handleChangeSelect.bind(this, 'templateId')}
                                value={templateList.find(t => t.value == this.state.templateId) || ''}
                                isClearable
                                isSearchable
                            />
                        </Col>
                        <Col className="col-3 pr-1">
                            <CustomSelect
                                options={rulesList}
                                placeholder={<FormattedMessage id="DataQualityIssues.SelectRules" />}
                                onChange={this.handleChangeSelect.bind(this, 'ruleId')}
                                value={rulesList.filter(r => this.state.ruleId?.some(el => el == r.value)) || ''}
                                isClearable
                                isSearchable
                                isMulti
                            />
                        </Col>
                        <Col className="text-right" >
                            <Button className="btn btn-sm btn-host" type="submit">
                                <i className="icon-icon-search"></i>
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mb-4 align-items-center">
                        <Col className="col-3 pr-1">
                            <CustomSelect
                                options={statusOptions}
                                placeholder={<FormattedMessage id="generic.status" />}
                                onChange={this.handleChangeSelect.bind(this, 'resolved')}
                                value={statusOptions.find(t => t.value === this.state.resolved)}
                                isClearable
                                isSearchable
                            />
                        </Col>
                        <Col className="col-3 pr-1">
                            <CustomSelect
                                options={getProfileType(this.props.intl)}
                                placeholder={<FormattedMessage id="ReferenceDataFunctions.ProfileType" />}
                                onChange={this.handleChangeSelect.bind(this, 'profileType')}
                                value={getProfileType(this.props.intl).find(t => t.value === this.state.profileType)}
                                isClearable
                                isSearchable
                            />
                        </Col>
                        <Col className="col-3 pr-1">
                            <SelectIsMaster
                                name="isMaster"
                                onChangeFunc={this.handleChangeSelect}
                                value={this.state.isMaster}
                                placeholder={<FormattedMessage id="SearchProfile.IsMaster" />}
                            />
                        </Col>
                        <Col>
                            <DateRangePicker
                                startDate={this.state.fromDate ? moment(this.state.fromDate) : ''}
                                startDateId="your_unique_start_date_id_announcement"
                                isOutsideRange={() => false}
                                endDate={this.state.toDate ? moment(this.state.toDate) : ''}
                                endDateId="your_unique_end_date_id_announcement"
                                onDatesChange={({ startDate, endDate }) => this.setState({ fromDate: startDate, toDate: endDate })}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                small={true}
                                required
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                            />
                        </Col>
                    </Row>
                </Form>
                <Row>
                    <Col className="h-100">
                        {issuesList ?
                            <CustomTable
                                data={issuesList}
                                columns={columns}
                                shadow={false}
                                remote={true}
                                showTotal
                                page={pageIndex + 1}
                                sizePerPage={pageSize}
                                totalSize={totalItems}
                                onTableChange={this.handleTableChange}
                                exportCSV={true}
                                customExportCsv={true}
                                customExportTooltip={<FormattedMessage id="TaskResultDetail.ExportCsvTooltip" values={{ elems: 5000 }} />}
                                handleCustomExportClick={this.handleCustomExportClick.bind(this)}
                                hideSizePerPage={false}
                                tableButtons={this.renderTableButtons()}
                                selectRow={selectRow}
                            />
                            : <DefaultPage text="generic.SelectSearchCriteria" icon="icon-icon-search" />}
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}
export default injectIntl(DataQualityIssues)
