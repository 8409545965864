import React, { Component } from 'react'
import { StyledCard } from '../../Base/CommonUIComponents'
import { Col, Row, TabContent, TabPane } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import ChatGPT from '../../../img/chatgpt.png';
import ChatGPTBlue from '../../../img/chatgptblue.png';
import AiSideMenu from './AiSideMenu';
import PromptConfig from '../GPTConfig/PromptConfig';
import ConfigRuns from '../ReviewSummaryConfig/ConfigRuns';
import SummaryConfig from '../ReviewSummaryConfig/SummaryConfig';
import { deleteAPI, getAPI, putAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import APIUsage from './APIUsage';

export default class AIConfig extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            pages: [
                { name: 'PromptConfig', icon: <span><img alt="Prompt Config" className='mr-2 blackLogo' width='18' height='18' src={ChatGPT}/><img alt="Prompt Config" className='mr-2 blueLogo' width='18' height='18' src={ChatGPTBlue}/></span> },
                { name: 'SummaryConfig', icon: <i className={`mr-2 fas icon-icon-reviews`}/> },
                { name: 'ConfigRuns', icon: <i className={`mr-2 fas fa-step-backward`}/> },
                { name: 'APIUsage', icon: <i className="fas fa-history mr-2"/> },
            ],
            activeTab: 'PromptConfig',
            promptConfigList: [],
            pageSize: 10,
            pageIndex: 0,
            totalItems: 0,
            reviewConfigs: [],
            runsPageSize: 10,
            runsPageIndex: 0,
            runsFilters: [],
            runsTotalItems: 0,
            summaryConfigRuns: [],
            newRun: null
        };
    }

    componentDidMount(){
        window.setTimeout(() => this.getPromptConfigList(), 1000); // To garanty enough time for the user api to set global.hotelGroupId;
    }

    getPromptConfigList = () => {
        if(global.hotelGroupId){
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data?.errors && data.errors.length > 0) {
                    this.setState({ block: false, error: data.errors }, () => handleNotification(data));
                    return;
                };
                this.setState({ block: false, promptConfigList: data.response });
            }, `/api/gms/Review/v1/promptConfig?hotelGroupId=${global.hotelGroupId}`);
        }
        else{
            this.setState({ block: false });
        }
    }

    updatePromptConfigList = (newPromptConfig) => {
        const { promptConfigList }  = this.state;
        this.setState({ promptConfigList: [...promptConfigList, newPromptConfig]  });
    }

    getSummaryConfig = () => {
        const { pageSize, pageIndex } = this.state;
        this.setState({ block: true },
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    this.setState({
                        reviewConfigs: data.response || [],
                        totalItems: data.totalItems
                    }, () => this.getSummaryConfigRuns());
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/Review/v1/reviewSummaryConfig?pageSize=${pageSize}&pageIndex=${pageIndex}`));
    }

    getSummaryConfigRuns = () => {
        const { runsPageSize, runsPageIndex, runsFilters } = this.state;

        let params = '';

        if(runsFilters.length > 0){
            runsFilters.forEach(f => {
                params += `&${f.label}=${f.value}`
            });
        };
        this.setState({ block: true },
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    this.setState({ summaryConfigRuns: data.response, runsTotalItems: data.totalItems, block: false });
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/Review/v1/reviewSummaryConfigRun?hotelGroupId=${global.hotelGroupId}&pageSize=${runsPageSize}&pageIndex=${runsPageIndex}${params}`));
    }

    updateReviewConfigs = (reviewConfigs) => {
        this.setState({ reviewConfigs: reviewConfigs }, () => this.getSummaryConfig());
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.getSummaryConfig());
    }

    handleRunsPagination = (value) => {
        this.setState({
            block: true,
            runsPageIndex: this.state.runsPageIndex + value
        }, () => this.getSummaryConfigRuns());
    }

    deletePromptConfig = (deleteItem) => {

        this.setState({ block: true }, () => {
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data?.errors && data.errors.length > 0) {
                    this.setState({ block: false, error: data.errors }, () => handleNotification(data));
                    return;
                };
                handleNotification(data, <FormattedMessage id="SummaryConfig.PromptConfigDeleted" />, <FormattedMessage id="generic.success" />);
                this.setState({ block: false, deleteModal: false, deleteItem: null }, () => this.getPromptConfigList());
                
            }, `/api/gms/Review/v1/promptConfig/${deleteItem}`);
        });
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    createConfigRuns = (e, newRun) => {
        e.preventDefault();
        const { summaryConfigRuns } = this.state;

        if(!newRun) return;

        this.setState({ block: true }, () => {            
            putAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    if (data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="SummaryConfig.ConfigSaved" />, <FormattedMessage id="generic.success" />);
                        this.setState({ tab: "ConfigRuns" });
                        summaryConfigRuns.unshift(newRun);
                    }
                }
                this.setState({ error: errorMessage, summaryConfigRuns, block: false });
            }, `/api/gms/Review/v1/reviewSummaryConfigRun`, newRun);
        });        
    }


    render() {
        const { block, error, pages, activeTab } = this.state;

        return (
            <StyledCard block={block} error={error} >
                <Row>
                    <Col className='col-3'>
                        <AiSideMenu
                            pages={pages}
                            activeTab={activeTab}
                            toggle={this.toggle}
                        />
                    </Col>
                    <Col className="col-9 pl-0">
                        <TabContent activeTab={activeTab} className='border-0'>
                            <TabPane tabId='PromptConfig' className='border-0'>
                                <PromptConfig {...this.state}
                                    updatePromptConfigList={this.updatePromptConfigList}
                                    getPromptConfigList={this.getPromptConfigList}
                                    deletePromptConfig={this.deletePromptConfig}
                                />
                            </TabPane>
                            <TabPane tabId='SummaryConfig' className='border-0'>
                                {activeTab === 'SummaryConfig' ? 
                                    <SummaryConfig {...this.state}
                                        setBlock={this.setBlock}
                                        updatePromptConfigList={this.updatePromptConfigList}
                                        setReviewConfigs={this.setReviewConfigs}
                                        updateReviewConfigs={this.updateReviewConfigs}
                                        createConfigRuns={this.createConfigRuns}
                                        handlePagination={this.handlePagination}
                                        getSummaryConfig={this.getSummaryConfig}
                                    />
                                : ''}
                            </TabPane>
                            <TabPane tabId='ConfigRuns' className='border-0'>
                                {activeTab === 'ConfigRuns' ?
                                    <ConfigRuns {...this.state}
                                        handleRunsPagination={this.handleRunsPagination}
                                        getSummaryConfigRuns={this.getSummaryConfig}
                                    />
                                : ''}
                            </TabPane>
                            <TabPane tabId='APIUsage' className='border-0 py-1'>
                                {activeTab === 'APIUsage' &&
                                    <APIUsage />
                                }
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </StyledCard>
        )
    }
}
