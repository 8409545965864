import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import { Button, Card, Col, Row } from 'reactstrap';

export default class HijiffyContacts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentPage: 'contact'
        }
    }

    signOut = () => {
        this.setState({ currentPage: 'logout-ext' })
    }

    render() {
        const { currentPage } = this.state;

        return (
        <div>
            <Card className="ml-1 my-0 mr-0 border-0 bg-light h-100">
                <div className="m-2 p-2">
                    <Row className="mb-2">
                        <Col>
                            <h5><i className="icon-icon-chat mr-2"></i><FormattedMessage id="Hijiffy.Inbox"/></h5>
                        </Col>
                        <Col style={{ textAlign: 'right' }}>
                           <Button className="btn btn-host ml-2" onClick={this.signOut}>
                                <i className="fas fa-sign-out-alt"></i>
                           </Button>
                        </Col>
                    </Row>
                    <div className='mt-3'>
                        <iframe style={{ width:'100%', height: '100%', minHeight: '650px', border:'none', boxShadow:'none' }}
                        src={`https://host.console.hijiffy.com/${currentPage}`}
                        title="Hijiffy Inbox"></iframe>
                    </div>
                </div>
            </Card>
        </div>
        )
    }
}
