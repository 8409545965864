import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Modal, ModalBody, CustomInput, Input } from 'reactstrap';
import { handleNotification } from '../../Base/Notification';
import { BlankCard } from '../../Base/CommonUIComponents';
import CustomSelect from "../../Base/CustomSelect";
import { postAPI, getAPI } from '../../Base/API';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

class BatchRunModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            selectedRun: this.props.selectedRun || { autoImport: false },
            hotelList: [],
            connections: [],
            egoiLists: [],
            displayEgoiMappings: this.props.selectedRun?.apiConnectionName === 'Egoi'
        };
    }

    componentDidMount() {
        this.getHotelList();
        if (this.state.displayEgoiMappings) {
            this.getEgoiLists();
        }
    }

    getHotelList = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                var hotelList = data.response ? data.response.map(h => ({ value: h.hotelId, label: h.name2 })) : [];

                this.setState({ hotelList }, this.getHotelGroupConnections());
            }
            else this.getHotelGroupConnections();
        }, `/api/gms/Hotel/v1/hotel/list?onlyFromHotelGroup=true`)
    }

    getHotelGroupConnections = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                var connections = [];

                data.response && data.response.forEach(conn => {
                    if (this.state.selectedRun.id || this.props.availableConnections.includes(conn.type)) {
                        connections.push({ value: conn.id, label: conn.type})
                    }
                });

                this.setState({
                    connections,
                    block: false
                });
            }
            else this.setState({ block: false });
        }, `/api/gms/Hotel/v1/hotelgroup/apiConnections`)
    }

    getEgoiLists = () => {
        getAPI(result => {
            const { data } = result;
            
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                if (data.items && data.items.length > 0) {
                    const egoiList = data.items.map(list => {
                        const option = { value: list.list_id, label: list.public_name };
                        return option;
                    });
                    this.setState({ egoiLists: egoiList });
                }
            }
        }, `/api/gms/Marketing/v1/egoi/lists?limit=100&offset=0`);
    }
    
    saveBatchRun = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var body = { ...this.state.selectedRun };

        if (!body.type) {
            body.type = 'Import';
        }

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="BatchRunModal.BatchRunSaved" />, <FormattedMessage id="generic.success" />);

                    this.props.getBathRun();
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Roiback/v1/BatchRun/ImportProfiles`, JSON.stringify({ request: body }));
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
            selectedRun: {
                ...this.state.selectedRun,
                [name]: combo ? combo.value : null
            }
        })
    };

    handleDateChange = (name, date) => {
        this.setState(prevState => ({
            selectedRun: {
                ...prevState.selectedRun,
                [name]: date ? moment(date).format("YYYY-MM-DD") : null
            }
        }));
    }

    addBatchRunMapping = () => {
        const { selectedRun } = this.state;
        selectedRun.batchRunMappings.push(this.getNewBatchRunMapping())
        this.setState({ selectedRun });
    }

    getNewBatchRunMapping = () => {
        const batchRunMapping = {
            hotelId: null,
            code: null
        };
        return batchRunMapping;
    }

    handleHotelSelect = (combo, idx) => {
        if (combo) {
            const { selectedRun } = this.state;
            const { value } = combo;
            selectedRun.batchRunMappings[idx].hotelId = value;

            this.setState({ selectedRun });
        }
    }

    handleEgoiListSelect = (combo, idx) => {
        if (combo) {
            const { selectedRun } = this.state;
            const { value } = combo;
            selectedRun.batchRunMappings[idx].code = value;
            selectedRun.batchRunMappings[idx].extraFieldId = null;

            this.setState({ selectedRun });
        }
    }

    handleCodeChange = (evt, idx) => {
        if (evt && evt.target) {
            const { selectedRun } = this.state;
            const { value } = evt.target;
            selectedRun.batchRunMappings[idx].code = value;
            selectedRun.batchRunMappings[idx].extraFieldId = null;

            this.setState({ selectedRun });
        }
    }

    removeBatchRunMapping = (evt, idx) => {
        if (evt) {
            const { selectedRun } = this.state;
            selectedRun.batchRunMappings.splice(idx, 1);

            this.setState({ selectedRun });
        }
    }

    render() {
        const { modal, toggleModal, batchRuns } = this.props;
        const { selectedRun, hotelList, connections, egoiLists, displayEgoiMappings, egoiFieldsByList } = this.state;

        const types = [
            { value: "Import", label: <FormattedMessage id="BatchRunModal.Import" />, disabled: batchRuns.some(x => x.apiConnectionName === "Roiback" && x.type === "Import") },
            { value: "CardExport", label: <FormattedMessage id="BatchRunModal.CardExport" />, disabled: batchRuns.some(x => x.apiConnectionName === "Roiback" && x.type === "CardExport") }
        ]
        return (
            <Modal isOpen={modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={this.state.block} error={this.state.error}>
                        <Form onSubmit={this.saveBatchRun}>
                            <Row className="mb-3">
                                <Col>
                                    <h5>{selectedRun.id ? <FormattedMessage id="BatchRunModal.EditBatchRun" /> : <FormattedMessage id="BatchRunModal.CreateBatchRun" />}</h5>
                                </Col>
                                <Col className="text-right col-3">
                                    <Button className="btn-sm btn-host" type="submit">
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>

                            <FormGroup row className="align-items-center">
                                <Label sm={2}> <FormattedMessage id="BatchRunModal.Connection" /></Label>
                                <Col sm={4} >
                                    <CustomSelect
                                        options={connections}
                                        required
                                        isSearchable
                                        onChange={(e) => this.handleChangeSelect(e, 'hotelGroupApiConnectionId')}
                                        value={selectedRun.hotelGroupApiConnectionId ? connections.find(conn => conn.value === selectedRun.hotelGroupApiConnectionId) : ''}
                                        placeholder=""
                                        isDisabled={selectedRun.id ? true : false}
                                    />
                                </Col>
                                <Label sm={2}> <FormattedMessage id="BatchRunModal.AutoImport" /> </Label>
                                <Col sm={4}>
                                    <CustomInput
                                        type="switch"
                                        id="autoImport"
                                        name="autoImport"
                                        onChange={(e) => { if (e.target) this.setState({ selectedRun: { ...this.state.selectedRun, autoImport: e.target.checked } }) }}
                                        checked={selectedRun.autoImport}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row className="align-items-center">
                                <Label sm={2}> <FormattedMessage id="BatchRunModal.DataFrom" /> </Label>
                                <Col sm={4}>
                                    <SingleDatePicker
                                        id="dataFrom"
                                        isOutsideRange={day => false}
                                        date={selectedRun.dataFrom ? moment(selectedRun.dataFrom) : null}
                                        focused={this.state.focused}
                                        onFocusChange={({ focused }) => this.setState({ focused })}
                                        small={true}
                                        numberOfMonths={1}
                                        showDefaultInputIcon={true}
                                        onDateChange={date => this.handleDateChange('dataFrom', date)}
                                    />
                                </Col>
                                <Label sm={2}> <FormattedMessage id="BatchRunModal.ProfilesHotelId" /> </Label>
                                <Col sm={4}>
                                    <CustomSelect
                                        options={hotelList}
                                        isClearable
                                        isSearchable
                                        onChange={(e) => this.handleChangeSelect(e, 'profilesHotelId')}
                                        value={selectedRun.profilesHotelId ? hotelList.find(conn => conn.value === selectedRun.profilesHotelId) : ''}
                                        placeholder=""
                                    />
                                </Col>
                            </FormGroup>
                            {selectedRun.hotelGroupApiConnectionId === 8 && selectedRun.type !== "Export" ? 
                                <FormGroup row className="align-items-center">
                                    <Label sm={2}> <FormattedMessage id="BatchRunModal.Type" /> </Label>
                                    <Col sm={4}>
                                        <CustomSelect
                                            options={types}
                                            required
                                            onChange={(e) => this.handleChangeSelect(e, 'type')}
                                            value={selectedRun.type ? types.find(t => t.value === selectedRun.type) : ''}
                                            placeholder=""
                                        />
                                    </Col>
                                </FormGroup>
                            : ''}
                            {
                                selectedRun.apiConnectionName === 'Roiback' ?
                                    <div>
                                        <Row className="mb-2">
                                            <Col>
                                                <h6>
                                                    <FormattedMessage id="BatchRunModal.Mappings" />
                                                </h6>
                                            </Col>
                                            <Col className="col-1 text-right">
                                                <Button className="btn btn-sm btn-host" onClick={this.addBatchRunMapping}>
                                                    <i className="fas fa-plus" />
                                                </Button>
                                            </Col>
                                        </Row>
                                        {
                                            selectedRun
                                                .batchRunMappings
                                                .map((brm, key) =>
                                                    <FormGroup key={key} row className="align-items-center">
                                                        <Label sm={2}>
                                                            <FormattedMessage id="BatchRunModal.Hotel" />
                                                        </Label>
                                                        <Col sm={4}>
                                                            <CustomSelect
                                                                options={hotelList}
                                                                isSearchable
                                                                onChange={evt => this.handleHotelSelect(evt, key)}
                                                                value={brm.hotelId ? hotelList.find(htl => htl.value === brm.hotelId) : ''}
                                                                required
                                                            />
                                                        </Col>
                                                        <Label sm={2}>
                                                            <FormattedMessage id="BatchRunModal.Code" />
                                                        </Label>
                                                        <Col sm={3}>
                                                            <Input type="text" id="code" name="code" onChange={evt => this.handleCodeChange(evt, key)} value={brm.code} />
                                                        </Col>
                                                        <Col sm={1} className="text-right">
                                                            <Button className="btn btn-sm btn-host" onClick={evt => this.removeBatchRunMapping(evt, key)}>
                                                                <i className="fas fa-trash-alt" />
                                                            </Button>
                                                        </Col>
                                                    </FormGroup>
                                                )
                                        }
                                    </div>
                                    :
                                    <div />
                            }
                            {
                                displayEgoiMappings ?
                                    <div>
                                        <Row className="mb-2">
                                            <Col>
                                                <h6>
                                                    <FormattedMessage id="BatchRunModal.Mappings" />
                                                </h6>
                                            </Col>
                                            <Col className="col-1 text-right">
                                                <Button className="btn btn-sm btn-host" onClick={this.addBatchRunMapping}>
                                                    <i className="fas fa-plus" />
                                                </Button>
                                            </Col>
                                        </Row>
                                        {
                                            selectedRun
                                                .batchRunMappings
                                                .map((brm, key) => 
                                                    <FormGroup key={key} row className="align-items-center">
                                                        <Label sm={2}>
                                                            <FormattedMessage id="BatchRunModal.Hotel" />
                                                        </Label>
                                                        <Col sm={4}>
                                                            <CustomSelect
                                                                options={hotelList}
                                                                isSearchable
                                                                onChange={evt => this.handleHotelSelect(evt, key)}
                                                                value={brm.hotelId ? hotelList.find(htl => htl.value === brm.hotelId) : ''}
                                                                required
                                                            />
                                                        </Col>
                                                        <Label sm={2}>
                                                            <FormattedMessage id="BatchRunModal.List" />
                                                        </Label>
                                                        <Col sm={3}>
                                                            <CustomSelect
                                                                options={egoiLists}
                                                                isSearchable
                                                                onChange={evt => this.handleEgoiListSelect(evt, key)}
                                                                value={egoiLists.find(lst => lst.value == brm.code)}
                                                                required
                                                            />
                                                        </Col>                                                        
                                                        <Col sm={1} className="text-right">
                                                            <Button className="btn btn-sm btn-host" onClick={evt => this.removeBatchRunMapping(evt, key)}>
                                                                <i className="fas fa-trash-alt" />
                                                            </Button>
                                                        </Col>
                                                    </FormGroup>
                                            )
                                        }
                                    </div>
                                    :
                                    <div />
                            }
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(BatchRunModal)