import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Row, Badge } from 'reactstrap';
import { Crt, ChartCard } from '../../../Base/CommonUIComponents';
import { postAPI } from '../../../Base/API';
import { DateRangePicker } from 'react-dates';
import { ErrorAlert } from "../../../Base/ErrorAlert";
import BlockUi from 'react-block-ui';
import moment from 'moment';
import { handleNotification } from '../../../Base/Notification';


class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventOptions: [
                { value: "error_ui", label: this.props.intl.formatMessage({ id: "PlatformDeepDive.error_ui" }), isVisible: true },
                { value: "error_api", label: this.props.intl.formatMessage({ id: "PlatformDeepDive.error_api" }), isVisible: true },
                { value: "help", label: this.props.intl.formatMessage({ id: "PlatformDeepDive.help" }), isVisible: true },
                { value: "help_click", label: this.props.intl.formatMessage({ id: "PlatformDeepDive.help_click" }), isVisible: true },
                { value: "page_view", label: this.props.intl.formatMessage({ id: "PlatformDeepDive.page_view" }), isVisible: true },
                { value: "api_exceeded_time", label: this.props.intl.formatMessage({ id: "PlatformDeepDive.api_exceeded_time" }), isVisible: true }
            ],
            globalValues: [],
            lineChart:[]
        }
    }

    componentDidMount() {
        this.getEventsOverTime();
    }

    getEventsOverTime = (e) => {
        if (e) e.preventDefault();

        this.setState({ block: true });

        const body = {
            usageType: this.props.usageType,
            productType: this.props.productType,
            startDate: moment(this.props.startDate).format("YYYY-MM-DD"),
            endDate: moment(this.props.endDate).format("YYYY-MM-DD"),
            dimensions: ["date", "eventName"],
            metrics: ["eventCount"],
            aggregation: [],
            dimensionFilter: {
                filter: {
                    fieldName: "eventName",
                    inListFilter: {
                        values: this.state.eventOptions.map(el => el.value)
                    }
                }
            }
        }

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.error) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }

                if(data.errors){
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }

                const json = JSON.parse(data);
                this.createChartData(json);
            }
            else this.setState({ block: false });
        }, `/api/hotel/v1/googleanalytics`, body);
    }

    createChartData = (json) => {
        const metrics = [...this.state.eventOptions].filter(el => el.isVisible);

        var lineChart = [["Date"]];
        var globalValues = [];

        //Add metrics
        metrics.forEach(el => {
            lineChart[0].push(el.label);
            globalValues.push({ label: el.label, value: 0 });
        })

        //Add dates
        new Array(moment(this.props.endDate).diff(moment(this.props.startDate), 'days') + 1).fill(undefined)
            .forEach(el => {
                const row = new Array(lineChart[0].length).fill(0);
                row[0] = moment(this.props.startDate).add(lineChart.length - 1, 'days').format('YYYY-MM-DD');

                lineChart.push(row);
            });

        json.rows && json.rows.forEach((row) => {
            const index = lineChart.findIndex(el => moment(el[0]).isSame(row.dimensionValues[0].value));
            const metricIndex = metrics.findIndex(el => el.value === row.dimensionValues[1].value);
            const rowValue = parseInt(row.metricValues[0].value);

            if (metricIndex !== -1) {
                if (index !== -1) {
                    lineChart[index][metricIndex + 1] += rowValue;
                }
                //Global Values
                globalValues[metricIndex].value += rowValue;
            }
        })

        this.setState({ lineChart, globalValues, block: false });
    }

    handleBadge = (event) => {
        const eventOptions = [...this.state.eventOptions];
        const index = eventOptions.findIndex(el => el.value === event);
        if (index !== -1) {
            eventOptions[index].isVisible = !eventOptions[index].isVisible

            this.setState({ eventOptions });
        }
    }


    render() {
        const { eventOptions, lineChart, globalValues } = this.state;

        const lineOptions = {
            legend: "top",
            chartArea: { width: '90%' }
        };

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />
                <Row className="align-items-center mb-4 pt-1">
                    <Col className="d-flex align-items-center">
                        <DateRangePicker
                            startDate={this.props.startDate}
                            startDateId="your_unique_start_date_id_announcement"
                            isOutsideRange={(day) => this.props.isOutsideDate(day)}
                            endDate={this.props.endDate}
                            endDateId="your_unique_end_date_id_announcement"
                            onDatesChange={({ startDate, endDate }) => this.props.handleDates(startDate, endDate)}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                            small={true}
                            numberOfMonths={2}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />

                        <div className="ml-4">
                            <i className="fas fa-filter fa-sm mr-2 text-muted"/>
                            {eventOptions.map((event, key) =>
                                <Badge className="mr-3 p-2 pointer" key={key} color={event.isVisible ? 'primary' : 'secondary'} onClick={() => this.handleBadge(event.value)}>
                                    {event.label}
                                </Badge>
                            )}
                        </div>
                    </Col>
                    <Col className="text-right col-1">
                        <Button className="float-right  btn-sm btn-host btn" onClick={this.getEventsOverTime}>
                            <i className="fas fa-search" />
                        </Button>
                    </Col>
                </Row>

                <Row>
                    {globalValues.map((card, key) => 
                        <Col key={key} className="mb-3">
                            <Crt text={card.label} value={card.value} />
                        </Col>
                    )}
                </Row>

                <Row>
                    <Col>
                        <ChartCard
                            chartType='LineChart'
                            data={lineChart}
                            options={lineOptions}
                            title={<FormattedMessage id="PlatformDeepDive.EventCountByEventNameOverTime" />}
                            height="50vh"
                        />
                    </Col>
                </Row>
            </BlockUi>
        );
    }

};
export default injectIntl(Dashboard);