import React, { Component } from 'react';
import { Button, Col, Input, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI } from '../Base/API';
import { StyledCard } from '../Base/CommonUIComponents';
import { CustomTable } from '../Base/CustomTable';
import { getContactTypeList, getDocumentList } from '../Base/ReferenceDataFunctions';
import { handleNotification } from '../Base/Notification';

export class MostRepeated extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            blockContacts: true,
            blockDocuments: true,
            nRepetitions: 10,
            contacts: [],
            documents: []
        }
    }

    componentDidMount() {
        this.getTopContactsDocs();
    }

    getTopContactsDocs = () => {
        const { nRepetitions } = this.state;

        if (!this.form.current.reportValidity()) {
            return;
        }

        getAPI(result => {
            const { error, data } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockDocuments: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);

                    this.setState({ error: errorMessage, blockDocuments: false });
                    return;
                }
                else if (data.response && data.response.length > 0) {
                    this.setState({ error: errorMessage, blockDocuments: false, documents: data.response[0].entityTops });
                }
                else {
                    this.setState({ error: errorMessage, blockDocuments: false });
                }
            }
            else {
                this.setState({ error: errorMessage, blockDocuments: false });
            }

        }, `/api/gms/Profile/v1/profile/TopDocs?nRepetitions=${nRepetitions}`);

        getAPI(result => {
            const { error, data } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockContacts: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);

                    this.setState({ error: errorMessage, blockContacts: false });
                    return;
                }
                else if (data.response && data.response.length > 0) {
                    this.setState({ error: errorMessage, blockContacts: false, contacts: data.response[0].entityTops });
                }
                else {
                    this.setState({ error: errorMessage, blockContacts: false });
                }
            }
            else {
                this.setState({ error: errorMessage, blockContacts: false });
            }
        }, `/api/gms/Profile/v1/profile/TopContacts?nRepetitions=${nRepetitions}`);
    }

    doSearch = () => {
        this.setState({ blockContacts: true, blockDocuments: true }, this.getTopContactsDocs);
    }

    handleIntField = evt => {
        if (evt && evt.target) {
            const { name, value } = evt.target;
            this.setState({ [name]: value });
        }
    }

    render() {
        const { blockContacts, blockDocuments, error, contacts, documents, nRepetitions } = this.state;

        const contactsColums = [
            {
                dataField: 'type',
                text: this.props.intl.formatMessage({ id: "MostRepeated.Type" }),
                formatter: cell => getContactTypeList(this.props.intl).filter(c => c.value === cell).map(c => c.label),
                csvFormatter: cell => getContactTypeList(this.props.intl).filter(c => c.value === cell).map(c => c.label)
            },
            {
                dataField: 'value',
                text: this.props.intl.formatMessage({ id: "MostRepeated.Contact" })
            },
            {
                dataField: 'count',
                text: this.props.intl.formatMessage({ id: "MostRepeated.Repeated" })
            }
        ];

        const documentsColums = [
            {
                dataField: 'type',
                text: this.props.intl.formatMessage({ id: "MostRepeated.Type" }),
                formatter: cell => getDocumentList(this.props.intl).filter(c => c.value === cell).map(c => c.label),
                csvFormatter: cell => getDocumentList(this.props.intl).filter(c => c.value === cell).map(c => c.label)
            },
            {
                dataField: 'value',
                text: this.props.intl.formatMessage({ id: "MostRepeated.Number" })
            },
            {
                dataField: 'count',
                text: this.props.intl.formatMessage({ id: "MostRepeated.Repeated" })
            }
        ];

        return (
            <StyledCard block={blockContacts || blockDocuments} error={error}>
                <Row>
                    <Col>
                        <h5> <i className="fas fa-clone mr-1 mb-3"/> <FormattedMessage id="NavMenu.MostRepeated" /> </h5>
                    </Col>
                </Row>
                <form ref={this.form}>
                    <Row className="mb-2">
                        <Col className="col-1">
                            <FormattedMessage id="MostRepeated.Repeated" />
                        </Col>
                        <Col className="col-1">
                            <Input type="number" id="nRepetitions" name="nRepetitions" min="6" value={nRepetitions} onChange={this.handleIntField} required />
                        </Col>
                        <Col className="text-right">
                            <Button className="btn btn-sm btn-host" onClick={this.doSearch}>
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>
                </form>
                <Row className="mb-2">
                    <Col>
                        <b>
                            <FormattedMessage id="ProfileDetails.ProfileContact" />
                        </b>
                    </Col>
                    <Col>
                        <b>
                            <FormattedMessage id="ProfileDetails.ProfileDocument" />
                        </b>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <CustomTable data={contacts} columns={contactsColums} search={true} exportCSV={true} />
                    </Col>
                    <Col>
                        <CustomTable data={documents} columns={documentsColums} search={true} exportCSV={true} />
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}

export default injectIntl(MostRepeated)
