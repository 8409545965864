import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Col, Form, Row, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { getMarketingType, getCustomMarketingType } from '../../Base/ReferenceDataFunctions';
import { StyledCard, SelectHotel } from "../../Base/CommonUIComponents";
import { handleNotification } from "../../Base/Notification";
import { getAPI } from "../../Base/API";

import RecurringCampaigns from './RecurringCampaigns';
import UniqueCampaigns from './UniqueCampaigns';

class CommunicationJourney extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            activeTab: '0',
            pageIndex: 0,
            pageSize: 1000,
            recurringCampaigns: [],
            uniqueCampaigns: []
        };
    }

    componentDidMount() {
        this._isMounted = true;

        window.setTimeout(
            () => {
                if (global.modules && global.modules.some(m => m === 'Egoi')) {
                    this.getEmailTemplates();
                }
                else if (global.modules && global.modules.some(m => m === 'SendGrid')) {
                    this.getSendGridTemplates();
                }
            },
            global.modules ? 0 : 2500
        );
    }

    getSendGridTemplates = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorTemplates: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.result && data.response.result.length > 0) {
                const templates = [];

                data.response.result.forEach(temp => {
                    const currentVersion = temp.versions.find(vrs => vrs.active === 1);

                    const template = {
                        id: temp.id,
                        templateName: temp.name,
                        subject: currentVersion?.subject,
                        thumbnail: currentVersion?.thumbnail_url,
                        htmlBody: currentVersion?.html_content
                    }

                    templates.push(template);
                })

                this.setState({ error: errorMessage, emailTemplates: templates }, () => this.getCampaigns());
            }
            else this.setState({ error: errorMessage, emailTemplates: [] }, () => this.getCampaigns());
        }, `/api/gms/SendGrid/v1/templates?generations=legacy,dynamic&page=100`);
    }

    getCampaigns = () => {
        this.setState({ block: true });

        var params = '';
        //params += this.state.hotelId ? `&hotelId=${this.state.hotelId}` : '';
        //params += this.state.marketingType ? `&type=${this.state.marketingType}` : '';
        //params += this.state.channel ? `&channel=${this.state.channel}` : '';
        //params += this.state.roomCategory ? `&roomCategory=${this.state.roomCategory}` : '';
        //params += this.state.rate ? `&rate=${this.state.rate}` : '';

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                let campaigns = [];

                data.response && data.response.forEach(campaign => {
                    campaign.templates && campaign.templates.forEach(template => {

                        if (campaign.senderChannel === 'Email') {
                            Object.assign(template, this.state.emailTemplates.find(el => el.id === template.templateId))
                        }
                        else {
                            template.template = this.state.smsTemplates?.find(el => el.id === template.templateId);
                        }

                    })

                    campaigns.push(campaign);
                });

                this.setState({
                    recurringCampaigns: campaigns.filter(c => getMarketingType().some(rec => rec.value === c.marketingType)),
                    uniqueCampaigns: campaigns.filter(c => getCustomMarketingType().some(uni => uni.value === c.marketingType)),
                    totalItems: data.totalItems ? data.totalItems : 0
                });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/marketingsend/list?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}` + params);
    }

    processTemplateData = (data) => {
        var campaigns = [];

        if (data) {
            var templateIds = [];

            data.forEach(campaign => {
                campaign.templates && campaign.templates.forEach(template => {
                    if (templateIds.some(t => t.id === template.templateId && t.channel === campaign.senderChannel.toLowerCase()) === false) {
                        templateIds.push({ id: template.templateId, channel: campaign.senderChannel.toLowerCase() });
                    }
                });
            });
        }

        return campaigns;
    }

    getEmailTemplates = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                this.setState({
                    emailTemplates: data.response ? data.response : null
                });
            }
            this.setState({ error: errorMessage }, () => this.getSmsTemplates());
        }, `/api/gms/Marketing/v1/egoitransactional/template/email`);
    }

    getSmsTemplates = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({
                    smsTemplates: data.response ? data.response : null
                });
            }
            this.setState({ error: errorMessage, block: false }, () => this.getCampaigns());
        }, `/api/gms/Marketing/v1/egoitransactional/template/sms`);
    }

    toggleTab = (val) => {
        this.setState({ activeTab: val })
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    doSearch = (e) => {
        e.preventDefault();

        this.setState({
            block: true,
            offset: 0,
            totalItems: 0
        }, () => this.getCampaigns());
    }

    render() {
        return (
            <StyledCard block={this.state.block} error={this.state.error} >
                <Nav tabs className="border-0">
                    <NavItem>
                        <NavLink className={(this.state.activeTab === '0' ? 'tab-border-host text-host' : '') + ' border-0 pb-4 cursor-pointer'} onClick={() => { this.toggleTab('0'); }}>
                            <b> <FormattedMessage id="CommunicationJourney.RecurringCampaigns" /></b>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={(this.state.activeTab === '1' ? 'tab-border-host text-host' : '') + ' border-0 pb-4 cursor-pointer'} onClick={() => { this.toggleTab('1'); }} >
                            <b> <FormattedMessage id="CommunicationJourney.UniqueCampaigns" /></b>
                        </NavLink>
                    </NavItem>

                    <Form onSubmit={this.doSearch} className="ml-auto">
                        <Row className="align-items-center h-100 pb-3">
                            {/*<Col>
                                <SelectHotel
                                    name={'hotelId'}
                                    onChangeFunc={this.handleChangeSelect}
                                    placeholder={<FormattedMessage id="generic.Hotel" />}
                                    value={this.state.hotelId ? this.state.hotelId : ''}
                                />
                            </Col>
                            <Col className="px-1">
                                <CustomSelect
                                    isClearable isSearchable
                                    placeholder={<FormattedMessage id='Campaigns.Type' />}
                                    options={getMarketingType()}
                                    onChange={this.handleChangeSelect.bind(this, 'marketingType')}
                                />
                            </Col>
                            <Col className="px-1">
                                <CustomSelect
                                    isClearable isSearchable
                                    placeholder={<FormattedMessage id='Campaigns.Channel' />}
                                    options={getSenderChannel()}
                                    onChange={this.handleChangeSelect.bind(this, 'channel')}
                                />
                            </Col>
                            <Col>
                                <Input
                                    className="border-left-0"
                                    type="text"
                                    name="roomCategory"
                                    onChange={(e) => this.handleChange(e)}
                                    placeholder={this.props.intl.formatMessage({ id: "Campaigns.RoomCategory" })}
                                />
                            </Col>
                            <Col className="px-1">
                                <Input
                                    className="border-left-0"
                                    type="text"
                                    name="rate"
                                    onChange={(e) => this.handleChange(e)}
                                    placeholder={this.props.intl.formatMessage({ id: "Campaigns.Rate" })}
                                />
                            </Col>*/}

                            <Col className="text-right col-1">
                                <Button className="btn btn-sm btn-host" type="submit">
                                    <i className="icon-icon-search"/>
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Nav>
                <hr className="m-0" style={{ backgroundColor: '#C9DEFE' }} />
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="0" className="border-0 my-0 p-0 ">
                        <RecurringCampaigns
                            recurringCampaigns={this.state.recurringCampaigns}
                        />
                    </TabPane>
                    <TabPane tabId="1" className="border-0 my-0 p-0 ">
                        <UniqueCampaigns
                            uniqueCampaigns={this.state.uniqueCampaigns}
                        />
                    </TabPane>
                </TabContent>
            </StyledCard>
        );
    }
}
export default injectIntl(CommunicationJourney)