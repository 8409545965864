import React, { Component } from 'react';
import { Button, Col, Row, Modal, ModalBody } from 'reactstrap';
import { BlankCard } from '../../../Base/CommonUIComponents';
import EditorText from '../../../Base/EditorText';

export class EditHtmlModal extends Component {

    render() {
        const { editorState, k, modal, onBlurEditor, onEditorStateChange, toggle } = this.props;

        function toggleModal(evt, k) {
            onBlurEditor(evt, k);
            toggle();
        }

        return (
            <Modal isOpen={modal} size={"xl"} style={{ minWidth: 0 }}>
                <Button onClick={evt => toggleModal(evt, k)} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard>
                        <Row className="mb-2">
                            <Col className="text-right">
                                <Button className="btn btn-sm btn-host" onClick={evt => toggleModal(evt, k)}>
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <EditorText
                                    editorState={editorState}
                                    onEditorStateChange={onEditorStateChange}
                                />
                            </Col>
                        </Row>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}