import React, { Component } from 'react';
import CoolDataGridColumn from './CoolDataGridColumn';

class CoolDataGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rows: this.props.rows,
            cols: this.props.cols,
            blockScroll: false,
            pageIndex: 0
        };
    }

    componentWillReceiveProps(nextProps){
        const diffCols = nextProps.cols && this.props.cols && ((nextProps.cols.length !== this.props.cols.length) || nextProps.cols.some(({key}) => !this.props.cols.some(a => a.key === key)));
        const diffRows = () =>  (nextProps.rows?.length !== this.props?.length) || (nextProps.rows?.children?.reduce((a, b) => a + (1 + (b?.children?.length??0)), 0) !== this.props.rows?.children?.reduce((a, b) => a + (1 + (b?.children?.length??0)), 0))
        if(diffCols || diffRows())
            this.setState({ cols: nextProps.cols, rows: nextProps.rows });
    }

    toggleRowOpened = (rowKey, childKey, child2Key) => {
        const { rows } = this.state;

        if(rows[rowKey] && rows[rowKey].children[childKey] && rows[rowKey].children[childKey].children[child2Key]){
            rows[rowKey].children[childKey].children[child2Key].isOpened = !rows[rowKey].children[childKey].children[child2Key].isOpened;
        }
        else if(rows[rowKey] && rows[rowKey].children[childKey]){
            rows[rowKey].children[childKey].isOpened = !rows[rowKey].children[childKey].isOpened;
        }
        else if(rows[rowKey]){
            rows[rowKey].isOpened = !rows[rowKey].isOpened;
        }

        this.setState({ rows })
    }

    onScroll = (event) => {
        if (event && event.target) {
            const { scrollTop } = event.target;
            
            const mainContentCoolDataGrid = document.getElementById("MainContentCoolDataGrid");
            const fixedCoolDataGrid = document.getElementById("FixedCoolDataGrid");
            const toggleCoolDataGrid = document.getElementById("ToggleCoolDataGrid");

            if (fixedCoolDataGrid) {
                fixedCoolDataGrid.scrollTop = scrollTop;
            }
            if (toggleCoolDataGrid) {
                toggleCoolDataGrid.scrollTop = scrollTop;
            }
            if (mainContentCoolDataGrid) {
                mainContentCoolDataGrid.scrollTop = scrollTop;
            }
        }
    }

    render() {
        const { cols, rows } = this.state;
        const { tableName, newHotelRowStyle = true } = this.props;

        return (
            <div className="d-flex shadow" id={`MainCoolDataGrid-${tableName}`} onDrag={e => e.preventDefault()}>
                {rows?.some(({children}) => children?.length) ?
                    <div style={{ minWidth: '50px', maxWidth: '50px', maxHeight: '74vh', overflow: 'auto' }} className='noScrollBar' id="ToggleCoolDataGrid" onScroll={this.onScroll}>
                        <div id={`table-${tableName}-col-parentToggle`} className={"CoolDataGrid-cols"} style={{ position: 'sticky', top: '0' }}>
                            <div style={{ visibility: 'hidden' }}>-</div>
                        </div>
                        <div className="d-flex flex-column align-items-start CoolDataGrid-rows-container">
                            {rows.map((row, rowKey) => 
                                <React.Fragment key={rowKey}>
                                    {row.separator ? 
                                        <div className="CoolDataGrid-cell-separator justify-content-center">
                                            <div style={row.children?.length ? { cursor: 'pointer' } : !row.preWrapContent ? { visibility: 'hidden' } : {}} onClick={_ => this.toggleRowOpened(rowKey)}>
                                                {row.children?.length ?
                                                    row.isOpened ?
                                                        <div className=''>
                                                            <i className="fas fa-chevron-up"/>
                                                        </div>
                                                    :
                                                        <div className=''>
                                                            <i className="fas fa-chevron-down"/>
                                                        </div>
                                                : row.preWrapContent ?
                                                    row.preWrapContent
                                                :'-'}
                                            </div>
                                        </div>
                                    :
                                        <div className={`d-flex justify-content-center align-items-center px-2 w-100 CoolDataGrid-cell ${row.isSmall ? 'CoolDataGrid-cell-small' : ''} row-${rowKey} ${newHotelRowStyle && rowKey > 0 ? 'CoolDataGrid-cell-newHotel' : ''}`} id={`table-${tableName}-col-parentToggle-row-${rowKey}`} style={{ height: row.rowHeight }}>
                                            <div style={row.children?.length ? { cursor: 'pointer' } : !row.preWrapContent ? { visibility: 'hidden' } : {}} onClick={_ => this.toggleRowOpened(rowKey)}>
                                                {row.children?.length ?
                                                    row.isOpened ?
                                                        <div className=''>
                                                            <i className="fas fa-chevron-up"/>
                                                        </div>
                                                    :
                                                        <div className=''>
                                                            <i className="fas fa-chevron-down"/>
                                                        </div>
                                                : row.preWrapContent ?
                                                    row.preWrapContent
                                                :'-'}
                                            </div>
                                        </div>
                                    }
                                    {row.children?.length && row.isOpened ?
                                        row.children.map((child, childKey) => {
                                            const isTotal = row.hasTotal && childKey === (row.children.length - 1);
                                            return (
                                                <React.Fragment key={childKey}>
                                                    {child.separator ? 
                                                        <div className="CoolDataGrid-cell-separator justify-content-center">
                                                            <div style={child.children?.length ? { cursor: 'pointer' } : !child.preWrapContent ? { visibility: 'hidden' } : {}} onClick={_ => this.toggleRowOpened(rowKey, childKey)}>
                                                                {child.children?.length ?
                                                                    child.isOpened ?
                                                                        <i className="fas fa-chevron-up"/>
                                                                    :
                                                                        <i className="fas fa-chevron-down"/>
                                                                : child.preWrapContent ?
                                                                    child.preWrapContent
                                                                :'-'}
                                                            </div>
                                                        </div>
                                                    :
                                                        <div style={{ height: child.rowHeight, background: isTotal ? '#f5f5f6' : '' }} key={childKey} className={`d-flex justify-content-center align-items-center px-2 w-100 CoolDataGrid-cell ${child.isSmall ? 'CoolDataGrid-cell-small' : ''}`} id={`table-${tableName}-col-parentToggle-row-${rowKey}-child-${childKey}`}>
                                                            <div style={child.children?.length ? { cursor: 'pointer' } : !child.preWrapContent ? { visibility: 'hidden' } : {}} onClick={_ => this.toggleRowOpened(rowKey, childKey)}>
                                                                {child.children?.length ?
                                                                    child.isOpened ?
                                                                        <i className="fas fa-chevron-up"/>
                                                                    :
                                                                        <i className="fas fa-chevron-down"/>
                                                                : child.preWrapContent ?
                                                                    child.preWrapContent
                                                                :'-'}
                                                            </div>
                                                        </div>
                                                    }
                                                    {child?.children?.length && child.isOpened ?
                                                        child.children.map((child2, child2Key) => {
                                                            const isTotal = row.hasTotal && childKey === (child.children.length - 1);
                                                            return (
                                                                <React.Fragment key={child2Key}>
                                                                    {child2.separator ? 
                                                                        <div className="CoolDataGrid-cell-separator justify-content-center">
                                                                            <div className='w-100 d-flex align-items-center justify-content-center' style={child2.children?.length ? { cursor: 'pointer' } : !child2.preWrapContent ? { visibility: 'hidden' } : {}} onClick={_ => this.toggleRowOpened(rowKey, childKey, child2Key)}>
                                                                                {child2.children?.length ?
                                                                                    child2.isOpened ?
                                                                                        <i className="fas fa-chevron-up"/>
                                                                                    :
                                                                                        <i className="fas fa-chevron-down"/>
                                                                                : child2.preWrapContent ?
                                                                                    child2.preWrapContent
                                                                                :'-'}
                                                                            </div>
                                                                        </div>
                                                                    :
                                                                        <div style={{ height: child2.rowHeight, background: isTotal ? '#f5f5f6' : '' }} key={child2Key} className={`d-flex justify-content-center align-items-center px-2 w-100 CoolDataGrid-cell ${child2.isSmall ? 'CoolDataGrid-cell-small' : ''}`} id={`table-${tableName}-col-parentToggle-row-${rowKey}-child-${childKey}-child-${child2Key}`}>
                                                                            <div className='w-100 d-flex align-items-center justify-content-center' style={child2.children?.length ? { cursor: 'pointer' } : !child2.preWrapContent ? { visibility: 'hidden' } : {}} onClick={_ => this.toggleRowOpened(rowKey, childKey, child2Key)}>
                                                                                {child2.children?.length ?
                                                                                    child2.isOpened ?
                                                                                        <i className="fas fa-chevron-up"/>
                                                                                    :
                                                                                        <i className="fas fa-chevron-down"/>
                                                                                : child2.preWrapContent ?
                                                                                    child2.preWrapContent
                                                                                :'-'}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {child2?.children?.length && child2.isOpened ?
                                                                        child2.children.map((child3, child3Key) => {
                                                                            const isTotal = row.hasTotal && childKey === (child2.children.length - 1);
                                                                            return (
                                                                                child3.separator ?
                                                                                    <div className="CoolDataGrid-cell-separator justify-content-center">
                                                                                        <div style={!child3.preWrapContent ? { visibility: 'hidden' } : {}}>
                                                                                            {child3.preWrapContent ?
                                                                                                child3.preWrapContent
                                                                                            :'-'}
                                                                                        </div>
                                                                                    </div>
                                                                                :
                                                                                    <div style={{ height: child3.rowHeight, background: isTotal ? '#f5f5f6' : '' }} key={child3Key} className={`d-flex justify-content-center align-items-center px-2 w-100 CoolDataGrid-cell ${child3.isSmall ? 'CoolDataGrid-cell-small' : ''}`} id={`table-${tableName}-col-parentToggle-row-${rowKey}-child-${childKey}-child-${child2Key}-child-${child3}`}>
                                                                                        <div style={!child3.preWrapContent ? { visibility: 'hidden' } : {}}>
                                                                                            {child3.preWrapContent ?
                                                                                                child3.preWrapContent
                                                                                            :'-'}
                                                                                        </div>
                                                                                    </div>
                                                                            )}
                                                                        )
                                                                    :''}
                                                                </React.Fragment>
                                                            )}
                                                        )
                                                    :''}
                                                </React.Fragment>
                                            )}
                                        )
                                    :''}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                :''}
                {cols.filter(({isFixed}) => isFixed).map((col, key) =>
                    <CoolDataGridColumn
                        hasIndent={!key}
                        isFixed={true}
                        newHotelRowStyle={newHotelRowStyle}
                        firstCol={!key}
                        col={col}
                        key={key}
                        onScroll={this.onScroll}
                        rows={rows}
                    />
                )}
                {cols ?
                    <div style={{ maxHeight: '74vh', overflow: 'auto', width: '100%' }} className='veryCoolScrollBar' id="MainContentCoolDataGrid" onScroll={this.onScroll}>
                        <div style={{ position: 'relative', width: '100%' }}>
                            <div className="h-100 w-100 CoolDataGrid" id={`MainCoolDataGrid-Scroll-${tableName}`}>
                                {cols.filter(({isFixed}) => !isFixed).map((col, key) =>
                                    <CoolDataGridColumn
                                        colKey={key}
                                        firstCol={!key && !cols.some(({isFixed}) => isFixed)}
                                        col={col}
                                        key={key}
                                        rows={rows}
                                        newHotelRowStyle={newHotelRowStyle}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                :''}
            </div>
        );
    }
}

export default CoolDataGrid;
