import React, { Component } from 'react';
import { Row, Col, Label, FormGroup, Form, Button, Input } from 'reactstrap';
import { handleNotification } from '../Base/Notification';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BlankCard, StyledModal } from '../Base/CommonUIComponents';
import { getVoucherLanguageOptions } from './HeyCardFunctions';
import CustomSelect from '../Base/CustomSelect';
import { DateRangePicker } from 'react-dates';
import { getAPI, postAPI } from '../Base/API';
import { Link } from 'react-router-dom';
import moment from 'moment';
import * as countryList from 'react-select-country-list';

class EmitVoucherModal extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            languageOptions: getVoucherLanguageOptions(),
            voucher: {
                externalId: this.props.profileId || this.props.profile?.id,
                voucherTypeIds: [
                    {
                        voucherTypeId: null,
                        quantity: 1,
                        voucherSalePrice: null
                    }
                ],
                HostPaymentCodes: [
                    {
                        hostPaymentCode: null,
                        amount: null
                    }
                ],
                languageCode: getVoucherLanguageOptions()[0].value
            },
            isGenericVat: false,
            validTo: ''
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.fillProfileData();
        this.getVoucherTypes();

        if (this.props.selectedVoucherType) {
            this.changeVoucherType(this.props.selectedVoucherType);
        }
    }

    fillProfileData = () => {
        if (this.props.profile) {
            const { profile } = this.props;
            const { voucher } = this.state;

            const address = profile.addresses?.find(el => el.type === 'Main');
            const phone = profile.contacts?.find(el => el.main && el.type === 'Mobile');
            const mail = profile.contacts?.find(el => el.main && el.type === 'Email');
            const nif = profile.documents?.find(el => el.type === 'FiscalNumber');

            voucher.customerFirstName = profile.firstName;
            voucher.customerLastName = profile.lastName;

            if (phone) voucher.customerPhone = phone.contact;
            if (mail) voucher.customerMail = mail.contact;
            if (nif) voucher.customerNif = nif.number;

            if (address) {
                voucher.customerAddress = address.addressLine1;
                voucher.customerPostCode = address.postalCode;
                voucher.customerCity = address.locality;
                voucher.customerCountryCode = address.country;
            }

            this.setState({ voucher })
        }
    }

    getVoucherTypes = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                let vouchers = [];

                data.response && data.response.forEach((el) => {
                    if (el.channels?.some(c => c.channelConfigId === 2)) {
                        vouchers.push({
                            value: el.id, label: el.code, saleValidTo: el.saleValidTo,
                            cardAmount: el.cardAmount, payedAmount: el.payedAmount
                        })
                    }
                })

                this.setState({ vouchersList: vouchers, block: false });
            }
        }, '/api/Voucher/V1/Config?onlyValidForSale=true');
    }

    emitVoucher = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var body = { ...this.state.voucher };
        body.hostPaymentCode = parseInt(body.salePrice) > 0 ? body.hostPaymentCode : null;

        
        if (this.props.voucherReservation) {
            Object.assign(body, this.props.voucherReservation);
        }

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                if (error.request && error.request.status === 504) {
                    handleNotification({}, <FormattedMessage id="EmitVoucherModal.TimeoutConsultLogs" />, <FormattedMessage id="generic.timeout" />, 'info');
                }
                else {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                }
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.warnings && data.warnings.length > 0) {
                    handleNotification(data);
                    handleNotification({}, <FormattedMessage id="EmitVoucherModal.VoucherCreatedSucessfuly" />, <FormattedMessage id="generic.success" />);
                }
                else {
                    handleNotification(data, <FormattedMessage id="EmitVoucherModal.VoucherCreatedSucessfuly" />, <FormattedMessage id="generic.success" />);
                }

                this.setState({ block: false, createdVoucher: data.response }, this.props.updateVouchers);
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, '/api/Voucher/Voucher/v1/voucher', { "request": body });
    }

    changeVoucherType = (combo) => {
        if (combo) {
            const { voucher } = this.state;

            voucher.voucherValue = combo.cardAmount;
            voucher.voucherTypeIds[0].voucherTypeId = combo.value;
            voucher.voucherTypeIds[0].voucherSalePrice = combo.payedAmount;
            voucher.HostPaymentCodes[0].amount = combo.payedAmount;
            voucher.vouchersValidFrom = moment().format("YYYY-MM-DD");
            voucher.vouchersValidUntil = combo.saleValidTo;

            this.setState({ voucher, voucherTypeValidTo: combo.saleValidTo });
        }
    }
    
    handleFinalConsumerVat = (e) => {
        const { checked, name } = e.target;

        this.setState({
            [name]: checked,
            voucher: {
                ...this.state.voucher,
                customerNif: checked ? '999999990' : ''
            }
        });
    }

    handleQuantityChange = (evt) => {
        if (evt && evt.target) {
            const { value } = evt.target;
            const { voucher } = this.state;

            voucher.voucherTypeIds[0].quantity = parseInt(value);
            voucher.HostPaymentCodes[0].amount = voucher.voucherTypeIds[0].voucherSalePrice * voucher.voucherTypeIds[0].quantity;

            this.setState({ voucher });
        }
    }

    handleSalePriceChange = (evt) => {
        if (evt && evt.target) {
            const { value } = evt.target;
            const { voucher } = this.state;

            voucher.voucherTypeIds[0].voucherSalePrice = value;
            voucher.HostPaymentCodes[0].amount = value * voucher.voucherTypeIds[0].quantity;

            this.setState({ voucher });
        }
    }

    handleChange = (evt) => {
        const { name, value }  = evt.target;

        this.setState(prevState => ({
            voucher: {
                ...prevState.voucher,
                [name]: value
            }
        }));
    }

    handleCombo = (combo, name) => {
        this.setState(prevState => ({
            voucher: {
                ...prevState.voucher,
                [name]: combo ? combo.value : null
            }
        }));
    }
    
    handleDateRangeChange = (startDate, endDate) => {
        this.setState(prevState => ({
            ...prevState,
            voucher: {
                ...prevState.voucher,
                vouchersValidFrom: startDate,
                vouchersValidUntil: endDate

            },
        }));

    }

    getOutsideRange = (day) => {
        if (this.state.voucherTypeValidTo) {
            return day < moment() || day > moment(this.state.voucherTypeValidTo);
        }
        
        return day < moment();
    }

    render() {
        const { block, error, languageOptions, voucher } = this.state;
        const { toggleModal, modal } = this.props;
        const isBillingInfoRequired = this.props.isBillingInfoRequired !== null ? this.props.isBillingInfoRequired : voucher.voucherTypeIds[0].voucherSalePrice != 0;

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="xl">
                <BlankCard block={block} error={error}>
                    <Row>
                        <Col>
                            {this.state.createdVoucher?.map((soldVoucher, key) =>
                                <div key={key} className="mb-5">
                                    <b><FormattedMessage id="EmitVoucherModal.SoldVoucher" /> </b>
                                    <Link to={`/VoucherDetails/${soldVoucher.id}`} style={{ cursor: "pointer", color: "blue" }}>
                                        {soldVoucher.voucherId}
                                    </Link>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Form onSubmit={this.emitVoucher}>
                        <Row className="mb-3">
                            <Col>
                                <h5><FormattedMessage id="EmitVoucherModal.EmitVoucher" /></h5>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host " type="submit">
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>

                        <FormGroup row>
                            <Label sm={2}> <FormattedMessage id="EmitVoucherModal.SelectLanguage" /> </Label>
                            <Col className="col-4">
                                <CustomSelect
                                    options={languageOptions}
                                    value={languageOptions.find(lOpt => lOpt.value === voucher.languageCode) || ''}
                                    onChange={(e) => this.handleCombo(e, 'languageCode')}
                                />
                            </Col>

                            {this.props.roomCode &&
                                <>
                                <Label sm={2}> <FormattedMessage id="ProfileDetails.Room" /> </Label>
                                <Col className="col-4">
                                    <Input
                                        type="text"
                                        name="roomCode"
                                        value={this.props.roomCode}
                                        readOnly
                                    />
                                </Col>
                                </>    
                            }
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={2}> <FormattedMessage id="EmitVoucherModal.VoucherType" /> </Label>
                            <Col className="col-4">
                                <CustomSelect
                                    options={this.state.vouchersList}
                                    value={this.state.vouchersList?.find(el => el.value === voucher.voucherTypeIds[0].voucherTypeId) || ''}
                                    name="voucherTypeId"
                                    onChange={this.changeVoucherType}
                                    placeholder=""
                                    required
                                />
                            </Col>

                            <Label sm={2}> <FormattedMessage id="EmitVoucherModal.Quantity" /> </Label>
                            <Col className="col-4">
                                <Input
                                    type="number"
                                    name="quantity"
                                    value={voucher.voucherTypeIds[0].quantity || ''}
                                    onChange={this.handleQuantityChange}
                                    min="1"
                                    required
                                />
                            </Col>
                        </FormGroup>

                        <div className="pt-2">
                            <Row>
                                <Col>
                                    <b>
                                        <FormattedMessage id="EmitVoucherModal.Price&Validity" />
                                    </b>
                                </Col>
                            </Row>

                            <hr className="mt-0" />

                            <FormGroup row>
                                <Label sm={2}> <FormattedMessage id="EmitVoucherModal.SalePrice" /> </Label>
                                <Col className="col-4">
                                    <Input
                                        type="number"
                                        required
                                        value={voucher.voucherTypeIds[0].voucherSalePrice || ''}
                                        name="salePrice"
                                        onChange={this.handleSalePriceChange}
                                    />
                                </Col>

                                <Label sm={2}> <FormattedMessage id="EmitVoucherModal.VoucherValue" /> </Label>
                                <Col className="col-4">
                                    <Input
                                        type="number"
                                        required
                                        value={voucher.voucherValue || ''}
                                        name="voucherValue"
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2}> <FormattedMessage id="EmitVoucherModal.Invoice" /> </Label>
                                <Col className="col-4">
                                    <Input
                                        type="text"
                                        value={voucher.invoiceReference || ''}
                                        name="invoiceReference"
                                        onChange={this.handleChange}
                                    />
                                </Col>

                                <Label sm={2}> <FormattedMessage id="EmitVoucherModal.Validity" /> </Label>
                                <Col className="col-4">
                                    <DateRangePicker
                                        startDateId="vouchersValidFrom"
                                        startDate={voucher && voucher.vouchersValidFrom ? moment(voucher.vouchersValidFrom) : null}
                                        isOutsideRange={day => day < moment() || day > this.state.maxValidTo}
                                        endDate={voucher && voucher.vouchersValidUntil ? moment(voucher.vouchersValidUntil) : null}
                                        endDateId="vouchersValidUntil"
                                        onDatesChange={({ startDate, endDate }) => this.handleDateRangeChange(startDate, endDate)}
                                        focusedInput={this.state.focusedInput}
                                        onFocusChange={focusedInput => this.setState({ focusedInput })}
                                        small={true}
                                        required
                                        numberOfMonths={1}
                                        showDefaultInputIcon={true}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2}> <FormattedMessage id="EmitVoucherModal.Notes" /> </Label>
                                <Col className="col-10">
                                    <Input
                                        type="textarea"
                                        value={voucher.notes || ''}
                                        name="notes"
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </FormGroup>
                        </div>

                        {this.props.renderClientInfo &&
                            <div className="pt-2">
                                <Row>
                                    <Col>
                                        <b>
                                            <FormattedMessage id="EmitVoucherModal.ClientInfo" />
                                        </b>
                                    </Col>
                                </Row>

                                <hr className="mt-0" />

                                <FormGroup row>
                                    <Label sm={2}> <FormattedMessage id="EmitVoucherModal.CustomerFirstName" /> </Label>
                                    <Col className="col-4">
                                        <Input
                                            type="text"
                                            required
                                            value={voucher.customerFirstName || ''}
                                            name="customerFirstName"
                                            onChange={this.handleChange}
                                        />
                                    </Col>

                                    <Label sm={2}> <FormattedMessage id="EmitVoucherModal.CustomerLastName" /> </Label>
                                    <Col className="col-4">
                                        <Input
                                            type="text"
                                            required
                                            value={voucher.customerLastName || ''}
                                            name="customerLastName"
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={2}> <FormattedMessage id="EmitVoucherModal.CustomerMail" /> </Label>
                                    <Col className="col-4">
                                        <Input
                                            type="email"
                                            required={isBillingInfoRequired}
                                            value={voucher.customerMail || ''}
                                            name="customerMail"
                                            onChange={this.handleChange}
                                        />
                                    </Col>

                                    <Label sm={2}> <FormattedMessage id="EmitVoucherModal.CustomerNif" /> </Label>
                                    <Col className="col-2">
                                        <Input
                                            type="text"
                                            required={isBillingInfoRequired}
                                            value={voucher.customerNif || ''}
                                            name="customerNif"
                                            onChange={this.handleChange}
                                            readOnly={this.state.isGenericVat}
                                        />
                                    </Col>
                                    <Col className="col-2 d-flex align-items-center">
                                        <Input
                                            type="checkbox"
                                            name="isGenericVat"
                                            className="mb-2"
                                            checked={this.state.isGenericVat}
                                            onChange={(e) => this.handleFinalConsumerVat(e)}
                                        />
                                        <Label className="mb-1"><FormattedMessage id="EmitVoucherModal.FinalConsumer" /></Label>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={2}> <FormattedMessage id="EmitVoucherModal.Phone" /> </Label>
                                    <Col className="col-4">
                                        <Input
                                            type="text"
                                            name="customerPhone"
                                            value={voucher.customerPhone || ''}
                                            onChange={this.handleChange}
                                        />
                                    </Col>

                                    <Label sm={2}> <FormattedMessage id="EmitVoucherModal.CustomerCountryCode" /> </Label>
                                    <Col className="col-4">
                                        <CustomSelect
                                            options={countryList().getData()}
                                            isClearable
                                            isSearchable
                                            placeholder=""
                                            onChange={(e) => this.handleCombo(e, "customerCountryCode")}
                                            value={countryList().getData().find(el => el.value === voucher.customerCountryCode) || ''}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={2}> <FormattedMessage id="EmitVoucherModal.CustomerPostCode" /> </Label>
                                    <Col className="col-4">
                                        <Input
                                            type="text"
                                            name="customerPostCode"
                                            value={voucher.customerPostCode || ''}
                                            onChange={this.handleChange}
                                        />
                                    </Col>

                                    <Label sm={2}> <FormattedMessage id="EmitVoucherModal.CustomerCity" /> </Label>
                                    <Col className="col-4">
                                        <Input
                                            type="text"
                                            name="customerCity"
                                            value={voucher.customerCity || ''}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={2}> <FormattedMessage id="EmitVoucherModal.Address" /> </Label>
                                    <Col className="col-10">
                                        <Input
                                            type="text"
                                            name="customerAddress"
                                            value={voucher.customerAddress || ''}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </FormGroup>
                            </div>
                        }
                    </Form>
                </BlankCard>
            </StyledModal>
        );
    }
}
export default injectIntl(EmitVoucherModal)