import React from "react";
import { Row, Col } from 'reactstrap';
import moment from 'moment';


class BasicCalendar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            weekDays: moment.weekdaysShort(),
            calendarRows: []
        }
    }

    componentDidMount() {
        this.renderCalendarDays()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.totalCanceledDays !== this.props.totalCanceledDays || nextProps.currentDate !== this.props.currentDate) {
            this.renderCalendarDays();
        }
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.totalCanceledDays !== this.props.totalCanceledDays || nextProps.currentDate !== this.props.currentDate;
    }

    renderCalendarDays = () => {
        let blank = this.getBlankCells();
        let daysInMonth = this.getDaysInMonth();
        let totalSlots = [...blank, ...daysInMonth];
        let cells = [], rows = [];

        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row); // if index not equal 7 that means not go to next week
            } else {
                rows.push(cells); // when reach next week we contain all td in last week to rows 
                cells = []; // empty container 
                cells.push(row); // in current loop we still push current row to new container
            }
            if (i === totalSlots.length - 1) { // when end loop we add remain date
                while (cells.length < 7) {
                    cells.push({ key: `end ${i + cells.length}`, day: null });
                }
                rows.push(cells);
            }
        });

        this.setState({ calendarRows: rows })
    }

    getFirstDayOfMonth = () => {
        let dateObject = this.props.currentDate;
        let firstDay = moment(dateObject)
            .startOf("month")
            .format("d");
        return firstDay;
    };

    getBlankCells = () => {
        let blanks = [];

        for (let i = 0; i < this.getFirstDayOfMonth(); i++) {
            blanks.push({ key: `blank ${i}`, day: null });
        }

        return blanks
    }

    getDaysInMonth = () => {
        let daysInMonth = [];

        for (let d = 1; d <= moment(this.props.currentDate).daysInMonth(); d++) {
            daysInMonth.push({ key: d, day: d });
        }

        return daysInMonth;
    }

    render() {
        const { weekDays, calendarRows } = this.state;
        const { currentDate, disableNextMonth, disablePrevMonth } = this.props;

        return (
            <div /*className="mb-5"*/>
                <Row className="mt-4 mb-4">
                    <Col className="text-center">
                        <i className={"fas fa-chevron-left " + (disablePrevMonth ? ' text-muted' : " text-primary pointer")} onClick={!disablePrevMonth ? this.props.prevMonth : () => { }} />

                        <span className="mx-4"><b> {currentDate?.format("MMMM YYYY")} </b></span>

                        <i className={"fas fa-chevron-right " + (disableNextMonth ? ' text-muted' : " text-primary pointer")} onClick={!disableNextMonth ? this.props.nextMonth : () => { } } />
                    </Col>
                </Row>

                <Row className="mb-2 px-3 align-items-center">
                    {weekDays && weekDays.map(day =>
                        <Col key={day} className="week-day py-2 px-1 text-center">
                            {day}
                        </Col>
                    )}
                </Row>
                {calendarRows && calendarRows.map((week, i) => {
                    return < Row className="px-3 align-items-center" key={i} >
                        {week.map((day, idx) =>
                            <Col className="p-1 m-1 text-center" key={day.key}>
                                {this.props.renderCalendarDay(day.day)}
                            </Col>
                        )}
                    </Row>
                }
                )}
            </div>
        );
    }
}

export default BasicCalendar;