import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { DateRangePicker } from 'react-dates';
import moment from 'moment-timezone';
import { getTimePeriods, getTimePeriodsSmall } from '../Base/ReferenceDataFunctions';

class CustomTwoMonthDateRangePicker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstMonthRender: moment(this.props.startDate),
            endMonthRender: moment(this.props.endDate)
        };
    }

    componentDidMount() {
        const year = this.props.startDate.year();
        const yearOptions = [(year - 1), year, (year + 1)];

        this.setState({ yearOptions })
    }

    toggleChangeCurrentDate = (month, par) => {

        var weekDays1 = document.querySelector(".DayPicker_weekHeaders > .DayPicker_weekHeader:nth-of-type(1)");
        var weekDays2 = document.querySelector(".DayPicker_weekHeaders > .DayPicker_weekHeader:nth-of-type(2)");

        if (par) {
            weekDays1.classList.add('hideEven');
            weekDays2.classList.remove('hideOdd');
        } else {
            weekDays2.classList.add('hideOdd');
            weekDays1.classList.remove('hideEven');
        }

        if (this.state.changeCurrentDateOpened) {
            weekDays1.classList.remove('hideEven');
            weekDays2.classList.remove('hideOdd');
        }

        this.setState({ changeCurrentDateOpened: !this.state.changeCurrentDateOpened, activeMonth: month });
    }

    handleCurrentDate = (name, value, focused) => {
        this.setState({ focusedInput: null }, () => {
            let { startDate, endDate } = this.props;

            if (focused === "startDate") {
                startDate = startDate ? moment(startDate).set(name, value) : moment().set(name, value);
                endDate = moment(startDate).isAfter(moment(endDate)) ? moment(startDate).add(1, "year") : endDate;
            }
            else {
                endDate = endDate ? moment(endDate).set(name, value) : moment().set(name, value);
                startDate = moment(endDate).isBefore(moment(startDate)) ? moment(endDate).subtract(1, "year") : startDate;
            }

            this.props.handleDates(startDate, endDate, focused);

            this.setState({ focusedInput: focused, firstMonthRender: moment(startDate), endMonthRender: moment(endDate) });
        })

    }

    handlePeriods = (name, focused) => {
        this.setState({ focusedInput: null }, () => {
            let { startDate, endDate } = this.props;

            if (name === "LastYear") {
                startDate = moment().subtract(1, 'year').startOf('year');
                endDate = moment().subtract(1, 'year').endOf('year');
            }
            else if (name === "LastMonth"){
                startDate = moment().subtract(1, 'month').startOf('month');
                endDate = moment().subtract(1, 'month').endOf('month');
            }
            else if (name === "CurrentMonth") {
                startDate = moment().startOf('month');
                endDate = moment().endOf('month');
            }
            else if (name === "NextQuarter") {
                startDate = moment().add(1, 'quarter').startOf('quarter');
                endDate = moment().add(1, 'quarter').endOf('quarter');
            }
            else if (name === "CurrentYear") {
                startDate = moment().startOf('year');
                endDate = moment().endOf('year');
            }
            else if (name === "SameTimeLastYear") {
                startDate = moment().subtract(1, 'year').startOf('month');
                endDate = moment().subtract(1, 'year').endOf('month');
            }

            this.props.handleDates(startDate, endDate, "endDate");

            this.setState({ selectedPeriod: name, focusedInput: focused, firstMonthRender: moment(startDate), endMonthRender: moment(endDate) });
        })

    }

    nextMonthClick = (add) => {
        const { firstMonthRender, endMonthRender, focusedInput } = this.state;

        var weekDays1 = document.querySelector(".DayPicker_weekHeaders > .DayPicker_weekHeader:nth-of-type(1)");
        var weekDays2 = document.querySelector(".DayPicker_weekHeaders > .DayPicker_weekHeader:nth-of-type(2)");

        weekDays1.classList.remove('hideEven');
        weekDays2.classList.remove('hideOdd');

        if (focusedInput === 'startDate')
            this.setState({ changeCurrentDateOpened: false, firstMonthRender: add ? firstMonthRender.add(1, 'month') : firstMonthRender.subtract(1, 'month') })
        else
            this.setState({ changeCurrentDateOpened: false, endMonthRender: add ? endMonthRender.add(1, 'month') : endMonthRender.subtract(1, 'month') })
    }

    handleIsOutsideRange = (day) => {
        const { isOutsideRange } = this.props;
        const { startDate, focusedInput } = this.state;

        if(isOutsideRange)
            return isOutsideRange(day, focusedInput);
        else
            return startDate && day.isAfter(moment(startDate).add(2, "year"));
    }

    render() {
        const { startDate, endDate, handleDates, intl, required, small } = this.props;
        const { firstMonthRender, changeCurrentDateOpened, activeMonth, yearOptions, focusedInput, endMonthRender, selectedPeriod } = this.state;
        const periods = small ? getTimePeriodsSmall(intl) : getTimePeriods(intl);

        return (
            <DateRangePicker
                startDate={startDate}
                startDateId="your_unique_start_date_id_report"
                isOutsideRange={this.handleIsOutsideRange}
                endDate={endDate}
                endDateId="your_unique_end_date_id_report"
                onDatesChange={({ startDate, endDate }) => handleDates(startDate, endDate, focusedInput)}
                focusedInput={focusedInput}
                onFocusChange={focusedInput => this.setState({ focusedInput, changeCurrentDateOpened: false })}
                small={true}
                required={required}
                numberOfMonths={2}
                showDefaultInputIcon={true}
                onPrevMonthClick={() => this.nextMonthClick(false)}
                onNextMonthClick={() => this.nextMonthClick(true)}
                initialVisibleMonth={
                    () => {
                        this.setState({
                            firstMonthRender: moment(startDate),
                            endMonthRender: moment(endDate) })
                        if (this.state.focusedInput === 'endDate') {
                            return endDate ? moment(endDate) : moment();
                        } else {
                            return startDate ? moment(startDate) : moment();
                        }
                    }
                }
                renderMonthElement={({ month }) => {
                    return (
                        <div
                            className='mx-4 text-center pointer position-relative'
                            style={{ minWidth: '15ch', zIndex: '200' }}
                            onClick={() => this.toggleChangeCurrentDate(moment(month).format('MMMM YYYY'), moment(month).format('MMMM YYYY') === moment(focusedInput === 'startDate' ? firstMonthRender : endMonthRender).format('MMMM YYYY'))}
                        >
                            <b style={{ fontSize: '1.1em' }}>
                                {moment(month).format('MMMM YYYY')}
                            </b>
                            {changeCurrentDateOpened && activeMonth === moment(month).format('MMMM YYYY') && (
                                <div
                                    className="border shadow position-absolute p-2"
                                    style={{ backgroundColor: 'white', width: '300px', zIndex: '200', top: '150%', left: '-14%', fontSize: '0.8em' }}
                                >
                                    <Row>
                                        <Col className="col-6 pr-0">
                                            <div style={{ fontSize: '0.9em' }} className="pb-2 pt-1 text-center title-sm"><b><FormattedMessage id="SalesProcess.Month" /></b></div>

                                            <div className="verticalScroll" style={{ overflow: 'auto', height: '100px', overflowX: 'hidden' }}>
                                                {moment.months().map((monthName, index) => {
                                                    const isSelected = moment(month).month() === index;
                                                    return (
                                                        <div
                                                            className={`p-1 title-sm overbglight ${isSelected ? 'font-weight-bold' : ''}`}
                                                            key={index}
                                                            onClick={() => !isSelected && this.handleCurrentDate('month', index, focusedInput)}
                                                        >
                                                            {isSelected ? <b>{monthName}</b> : monthName}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </Col>
                                        <Col className="col-6">
                                            <div style={{ fontSize: '0.9em' }} className="pb-2 pt-1 text-center title-sm"><b><FormattedMessage id="SalesProcess.Year" /></b></div>

                                            <div className="verticalScroll" style={{ overflow: 'auto', height: '100px', overflowX: 'hidden' }}>
                                                {yearOptions.map((year, key) => {
                                                    const isSelected = moment(month).year() === year;
                                                    return (
                                                        <div
                                                            className={`p-1 title-sm overbglight ${isSelected ? 'font-weight-bold' : ''}`}
                                                            key={key}
                                                            onClick={() => !isSelected && this.handleCurrentDate('year', year, focusedInput)}
                                                        >
                                                            {isSelected ? <b>{year}</b> : year}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className='mt-2' style={{ fontSize: '0.8em' }}>
                                        <div>
                                            <b style={{ fontSize: '1.1em' }} className='text-muted'><FormattedMessage id="SalesProcess.Presets"/></b>
                                        </div>
                                        <div className='py-2 overflow-auto d-flex align-items-center justify-content-start veryCoolScrollBar' style={{ maxWidth: '100%', flexWrap: 'nowrap' }}>
                                            {periods.map((period, key) => {
                                                const isSelected = selectedPeriod === period.value;
                                                return (
                                                    <div className='mr-2' key={key}>
                                                        <div style={{ fontSize: '0.8em', whiteSpace: 'nowrap', padding: '0.4rem' }} onClick={() => !isSelected && this.handlePeriods(period.value, focusedInput)} className={`deal-action-badge-OnGoing ${isSelected ? 'selected-deal-action-badge' : ''} ml-2 text-truncate`}>
                                                            {period.label}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                }
                }
            />
        );
    }
}

export default injectIntl(CustomTwoMonthDateRangePicker);