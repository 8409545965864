import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Row, Label, Input, Collapse, CustomInput } from 'reactstrap';
import { ErrorAlert } from '../../Base/ErrorAlert';
import { CommonHelper, CoolTooltip, FormatAmountNumber } from '../../Base/CommonUIComponents';
import { SingleDatePicker } from 'react-dates';
import { getAPI } from '../../Base/API';
import { handleNotification } from "../../Base/Notification";

import Handlebars from 'handlebars';
import moment from 'moment';
import BlockUi from 'react-block-ui';
import CustomSelect from '../../Base/CustomSelect';

class SalesProcessDownloadProFormaModal extends Component {
    constructor(props) {
        super(props)

        this.templateRef = React.createRef();
        this.state = {
            block: false,
            error: null,
            proposalData: {},
            dynamicData: {},
            templateHtml: null,
            templateHtmlParsed: null,
            dateFocus: null,
            hotelDealOptions: [],
            selectedHotel: null,
            langOptions: [
                {
                    value: 'pt-PT',
                    label: this.props.intl.formatMessage({ id: 'SalesProcess.Portuguese' })
                },
                {
                    value: 'en-GB',
                    label: this.props.intl.formatMessage({ id: 'SalesProcess.English' })
                },
            ],
            selectedLang: 'en-GB',
            commissions: ['lodgingComission', 'fandBComission', 'avComission'],
            masterTemplates: [],
            proposalCurrency: null,
            invalidProposals: false,
            exchangeRate: null,
        }
    }

    componentDidMount() {
        const { deal, proposals } = this.props;
        window.setTimeout(
            () => {
                const validProposals = proposals?.some(proposal => !proposal.isCancelled);
                if(!validProposals) {
                    this.setState({ invalidProposals: true });
                    return;
                }

                if(deal?.hotelIds?.length > 0) {
                    this.setState({ 
                        hotelDealOptions: global.hotelList?.filter(hotel => deal.hotelIds.some(hotelId => hotelId === hotel.value)) || [], 
                        selectedHotel: deal.hotelIds[0],
                        selectedHotelCurrecy: global.hotelList?.find(hotel => hotel.value === deal.hotelIds[0])?.currency || null
                    }, () => this.getMasterTemplates());
                }
            },
            global.hotelList ? 0 : 2000
        );
    }

    roundToTwoDecimals = (value) => {
        if(!value) return 0;
        return Math.round(parseFloat(value) * 100) / 100;
    };

    getMasterTemplates = () => {
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                    }

                    if (data.data?.length > 0) {
                        const masterTemplates = data.data;
                        
                        this.setState({ masterTemplates }, () => { this.getTemplateHtml(null, true); });
                        return;
                    }
                    
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/communication/mastertemplates`)
        });
    }

    getTemplateHtml = (e, firstCall) => {
        if(e) e.preventDefault()

        const { selectedLang, selectedHotel, masterTemplates } = this.state;
        const { deal } = this.props;
        if(!deal || !selectedLang || !selectedHotel){
            this.setState({ block: false });
            return;
        }

        let selectedMasterTemplate = null
        if(masterTemplates?.length) {
            const matchingTemplates = masterTemplates.filter(masterTemplate => masterTemplate.type === 'Proposal' && masterTemplate.hotelId === selectedHotel);
            
            if (matchingTemplates?.length === 1) {
                selectedMasterTemplate = matchingTemplates[0];
            } else {
                const hasMultipleTemplates = matchingTemplates?.length > 1 ? true : false;
                this.setState({ block: false, templateHtml: null, templateHtmlParsed: null, proposalData: {}, dynamicData: {}, templateError: true }, () => handleNotification({errors: [{message: this.props.intl.formatMessage({id: hasMultipleTemplates? 'SalesDeal.MoreThanOneProposalTemplates': 'SalesDeal.NoProposalTemplates'}),code: ''}]}));
                return;
            }
        }

        if(!selectedMasterTemplate){
            this.setState({ block: false });
            return;
        }

        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false, templateHtml: null, templateHtmlParsed: null, proposalData: {}, dynamicData: {}, templateError: true });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false, templateHtml: null, templateHtmlParsed: null, proposalData: {}, dynamicData: {}, templateError: true });
                        return;
                    }

                    if(data.data?.length > 0){
                        const { proposalData, dynamicData, selectedHotel } = this.state;
                        
                        const templateHtml = data.data[0].html;
                        const dealDetail = data.data[0].dealDetail;
                        const proposalCurrency = dealDetail?.proposalCurrency || null;
                        const exchangeRate = dealDetail?.exchangeRates?.length > 0 ? dealDetail?.exchangeRates[0] : null;
                        const newProposalData = firstCall ? (dealDetail?.proposalData || null) : proposalData;
                        const newDynamicData = firstCall ? (newProposalData?.dynamicData || null) : dynamicData;
                        const selectedHotelCurrecy = global.hotelList?.find(hotel => hotel.value === selectedHotel)?.currency || null;

                        this.setState({ 
                            templateHtml, 
                            proposalData: newProposalData, 
                            dynamicData: newDynamicData, 
                            proposalCurrency, 
                            selectedHotelCurrecy, 
                            exchangeRate, 
                            block: false, 
                            templateError: false 
                        }, () => this.handlebars())
                        return;
                    }

                    this.setState({ block: false, templateHtml: null, templateHtmlParsed: null, proposalData: {}, dynamicData: {} });
                    return;
                }
                else this.setState({ error: errorMessage, block: false, templateHtml: null, templateHtmlParsed: null, proposalData: {}, dynamicData: {}, templateError: true });
            }, `/api/gms/SalesProcess/v1/communication/generateproformahtml?masterTemplateId=${selectedMasterTemplate.id}&languageCode=${selectedLang}&salesDealId=${deal.id}`)
        })
    };

    printTemplate = () => {
        const name = this.props.deal.name;
        const a = window.open('', 'PRINT');
        a.document.write(this.state.templateHtmlParsed);
        a.document.close();

        a.onload = function () {
            this.document.title = name;
            a.focus();
            a.print();
            a.close();
        };
    }

    handlebars = () => {
        let { templateHtml, proposalData, dynamicData, exchangeRate } = this.state;
        const { intl } = this.props;
        if(!templateHtml) return;

        let template = Handlebars.compile(templateHtml);

        Handlebars.registerHelper('formatDate', function (date) {
            return date ? moment(date).format("DD/MM/YYYY") : '-'
        })
        
        const formatCurrency = (value, currency) => intl.formatNumber(value || 0, { style: 'currency', currency });
          
        const formatPercentage = (value) => (value !== null && value !== undefined ? `${value}%` : null);
          
        const formatList = (list, currency, keys) =>
            list?.map((item) => ({
              ...item,
              ...Object.fromEntries(keys.map((key) => [key, formatCurrency(item[key], currency)])),
        })) || [];
          
        const data = {
            proposalData: {
                ...proposalData,
                totalRoomsValue: formatCurrency(proposalData?.totalRoomsValue, proposalData?.proposalCurrency || global.hotelCurrency),
                dailyRoomList: formatList(proposalData?.dailyRoomList, proposalData?.proposalCurrency || global.hotelCurrency, ['totalValue']).map((item) => ({
                    ...item,
                    categories: formatList(item?.categories, proposalData?.proposalCurrency || global.hotelCurrency, ['valueOfRooms']),
                })),
                space: {
                    ...proposalData?.space,
                    spaceList: formatList(proposalData?.space?.spaceList, proposalData?.proposalCurrency || global.hotelCurrency, ['dailyPrice', 'totalValue']),
                    spacesValue: formatCurrency(proposalData?.space?.spacesValue, proposalData?.proposalCurrency || global.hotelCurrency),
                },
                package: {
                    ...proposalData?.package,
                    equipmentList: formatList(proposalData?.package?.equipmentList, proposalData?.proposalCurrency || global.hotelCurrency, ['unitPrice', 'amountAfterTax']),
                    equipmentValue: formatCurrency(proposalData?.package?.equipmentValue, proposalData?.proposalCurrency || global.hotelCurrency),
                },
                fb: {
                    ...proposalData?.fb,
                    equipmentList: formatList(proposalData?.fb?.equipmentList, proposalData?.proposalCurrency || global.hotelCurrency, ['unitPrice', 'amountAfterTax']),
                    equipmentValue: formatCurrency(proposalData?.fb?.equipmentValue, proposalData?.proposalCurrency || global.hotelCurrency),
                },
                equipment: {
                    ...proposalData?.equipment,
                    equipmentList: formatList(proposalData?.equipment?.equipmentList, proposalData?.proposalCurrency || global.hotelCurrency, ['amountAfterTax']),
                    equipmentValue: formatCurrency(proposalData?.equipment?.equipmentValue, proposalData?.proposalCurrency || global.hotelCurrency),
                },
                amountAfterTax: formatCurrency(proposalData?.amountAfterTax, proposalData?.proposalCurrency || global.hotelCurrency),
                lodgingComission: {
                    ...proposalData?.lodgingComission,
                    value: formatPercentage(proposalData?.lodgingComission?.value),
                },
                fandBComission: {
                    ...proposalData?.fandBComission,
                    value: formatPercentage(proposalData?.fandBComission?.value),
                },
                avComission: {
                    ...proposalData?.avComission,
                    value: formatPercentage(proposalData?.avComission?.value),
                },
            },
            dynamicData,
            exchangeRate: exchangeRate?.length > 0 && !isNaN(parseFloat(exchangeRate[0])) ? parseFloat(exchangeRate[0]) : null,
        };

        const result = template(data);
        this.setState({ templateHtmlParsed: result })
    }

    handleInputChange = (e) => {
        if(!e || !e.target) return;
        
        const { proposalData } = this.state
        let { name, value } = e.target
        
        if(name === 'pax') {
            if(value < 0 || isNaN(value)){
                value = 0;
            }
        }

        this.setState({ proposalData: { ...proposalData, [name]: value } })
    }

    handleAddLodgeDate = () => {
        let { proposalData } = this.state

        if(!proposalData) {
            proposalData = {};
        };

        const currentDate = {
            date: null,
            total: 0,
            categories: proposalData.dailyRoomList?.[0]?.categories?.map(cat => ({
                catCode: cat.catCode,
                numberOfRooms: 0,
                valueOfRooms: 0,
                isRate: cat.isRate
            })) || []
        };

        if(proposalData?.dailyRoomList) {
            proposalData.dailyRoomList.push(currentDate)
        } else {
            proposalData.dailyRoomList = [currentDate]
        }

        proposalData.dailyRoomListCollapsed = false;

        this.setState({ proposalData })
    }

    handleDailyRoomListChange = (e, dateIdx, catIdx) => {
        if (!e || !e.target) return;
        let { proposalData } = this.state;
        let { name, value } = e.target;
        const { intl } = this.props;
    
        if (!proposalData) {
            proposalData = {};
        }
        const currentDate = proposalData.dailyRoomList?.[dateIdx];
    
        if (currentDate) {
            const currentCategory = currentDate.categories?.[catIdx];
    
            if (name === 'dailyRoomTotal') {
                currentDate.totalValue = value && !isNaN(value) ? this.roundToTwoDecimals(value) : 0;

            } else if (currentCategory) {
                currentCategory.numberOfRooms = value;
            }

            this.setState({
                proposalData: {
                    ...proposalData,
                    totalRoomsValue: this.roundToTwoDecimals(
                        proposalData.dailyRoomList.reduce((acc, item) => {
                            const dailyValue = parseFloat(item.totalValue);
                            return acc + (isNaN(dailyValue) ? 0 : dailyValue);
                        }, 0)
                    ),
                    dailyRoomList: proposalData.dailyRoomList.map((item, idx) =>
                        idx === dateIdx ? currentDate : item
                    )
                }
            });
        }
    };

    handleDeleteLodgeDate = (dateIdx) => {
        this.setState(prevState => {
            const proposalData = prevState.proposalData || {};
            const dailyRoomList = proposalData.dailyRoomList || [];
    
            return {
                proposalData: {
                    ...proposalData,
                    dailyRoomList: dailyRoomList.filter((_, i) => i !== dateIdx)
                }
            };

        });
    }

    addNewGenericNote = (noteType) => {
        this.setState(prevState => {
            const dynamicData = prevState.dynamicData || {};
    
            return {
                dynamicData: {
                    ...dynamicData,
                    [noteType]: [
                        ...(dynamicData[noteType] || []),
                        ''
                    ]
                },
                proposalData: {
                    ...prevState.proposalData,
                    lodgingNotesCollapsed: false
                }
            };
        });
    };

    handleGenericNoteChange = (e, index, noteType) => {
        if (!e || !e.target) return;
    
        const { value } = e.target;
    
        this.setState(prevState => {
            const dynamicData = prevState.dynamicData || {};
            const notes = [...(dynamicData[noteType] || [])];
            
            notes[index] = value;
    
            return {
                dynamicData: {
                    ...dynamicData,
                    [noteType]: notes
                }
            };
        });
    };

    handleDeleteGenericNote = (index, noteType) => {
        this.setState(prevState => {
            const dynamicData = prevState.dynamicData || {};
            const notes = dynamicData[noteType] || [];
    
            return {
                dynamicData: {
                    ...dynamicData,
                    [noteType]: notes.filter((_, i) => i !== index)
                }
            };
        });
    };

    handleAddFB = () => {
        let { proposalData } = this.state

        if(!proposalData) {
            proposalData = {};
        };

        if(!proposalData.fb) {
            proposalData.fb = {
                numberOfEquipment: 0,
                equipmentValue: 0,
                equipmentList: []
            };
        }

        const currentFB = {
            type: "FB",
            chargeCode: "",
            chargeDescription: null,
            startDate: null,
            endDate: null,
            quantity: 0,
            unitPrice: 0,
            amountAfterTax: 0,
            taxToPay: null,
            amountBeforeTax: 0,
            numberOfDays: 0,
            dailyPrice: null,
            venue: null
        }

        if(proposalData.fb?.equipmentList?.length > 0) {
            proposalData.fb.equipmentList.push(currentFB)
        }else{
            proposalData.fb.equipmentList = [currentFB]
        }

        proposalData.fbCollapsed = false;

        this.setState({ proposalData })
    }

    handleFBChange = (e, idx) => {
        if (!e || !e.target) return;
        let { proposalData } = this.state;
        let { value, name } = e.target;
    
        if (!proposalData) {
            proposalData = {};
        }
        const currentFB = proposalData.fb?.equipmentList?.[idx];
    
        if (currentFB) {
            currentFB[name] = name !== 'amountAfterTax' ? value : this.roundToTwoDecimals(value);
            
            if(name === 'unitPrice' || name === 'quantity' || name === 'numberOfDays') {
                currentFB.amountAfterTax = this.roundToTwoDecimals((currentFB.unitPrice || 0) * (currentFB.quantity || 0) * (currentFB.numberOfDays || 0));
            }
    
            this.setState({
                proposalData: {
                    ...proposalData,
                    fb: {
                        ...proposalData.fb,
                        equipmentValue: this.roundToTwoDecimals(proposalData.fb.equipmentList.reduce((acc, item) => {
                            const value = parseFloat(item.amountAfterTax);
                            return acc + (isNaN(value) ? 0 : value);
                        }, 0)),
                        equipmentList: proposalData.fb.equipmentList.map((item, index) =>
                            index === idx ? currentFB : item
                        )
                    }
                }
            });
        }
    }

    handleFbDelete = (idx) => {
        this.setState(prevState => {
            const proposalData = prevState.proposalData || {};
            const fb = proposalData.fb || {};
            const equipmentList = fb.equipmentList || [];
    
            return {
                proposalData: {
                    ...proposalData,
                    fb: {
                        ...fb,
                        equipmentList: equipmentList.filter((_, i) => i !== idx)
                    }
                }
            };
        });
    }

    HandleAddSpace = () => {
        let { proposalData } = this.state

        if(!proposalData) {
            proposalData = {};
        };

        if(!proposalData.space) {
            proposalData.space = {
                numberOfSpaces: 0,
                spacesValue: 0,
                spaceList: []
            };
        }

        const currentSpace = {
            spaceName: '',
            spaceTypeDescription: '',
            pax: 0,
            fromDate: null,
            toDate: null,
            adults: 0,
            children: 0,
            equipment: {
                numberOfEquipment: 0,
                equipmentValue: 0,
                equipmentList: []
            }
        }

        if(proposalData.space?.spaceList?.length > 0) {
            proposalData.space.spaceList.push(currentSpace)
        }else{
            proposalData.space.spaceList = [currentSpace]
        }

        proposalData.spacesCollapsed = false;

        this.setState({ proposalData })
    }

    handleSpaceChange = (e, idx) => {
        if (!e || !e.target) return;
        let { proposalData } = this.state;
        let { value, name } = e.target;
    
        if (!proposalData) {
            proposalData = {};
        }
        const currentSpace = proposalData.space?.spaceList?.[idx];
    
        if (currentSpace) {
            currentSpace[name] = name !== 'totalValue' ? value : this.roundToTwoDecimals(value);
    
            if (name === 'numberOfDays' || name === 'dailyPrice') {
                currentSpace.totalValue = this.roundToTwoDecimals(currentSpace.numberOfDays * currentSpace.dailyPrice);
            }
    
            this.setState({
                proposalData: {
                    ...proposalData,
                    space: {
                        ...proposalData.space,
                        spacesValue: this.roundToTwoDecimals(
                            proposalData.space.spaceList.reduce((acc, item) => {
                                const value = parseFloat(item.totalValue);
                                const setup = parseFloat(item.setup);

                                return acc + (isNaN(value) ? 0 : value) + (isNaN(setup) ? 0 : setup);
                            }, 0)
                        ),
                        spaceList: proposalData.space.spaceList.map((item, index) =>
                            index === idx ? currentSpace : item
                        )
                    }
                }
            });
        }
    };

    handleSpaceDelete = (idx) => {
        this.setState(prevState => {
            const proposalData = prevState.proposalData || {};
            const space = proposalData.space || {};
            const spaceList = space.spaceList || [];
    
            return {
                proposalData: {
                    ...proposalData,
                    space: {
                        ...space,
                        spaceList: spaceList.filter((_, i) => i !== idx)
                    }
                }
            };
        });
    }

    handleAddEquipment = () => {
        let { proposalData } = this.state

        if(!proposalData) {
            proposalData = {};
        };

        if(!proposalData.equipment) {
            proposalData.equipment = {
                numberOfEquipment: 0,
                equipmentValue: 0,
                equipmentList: []
            };
        }

        const currentEquipment = {
            type: 'EQUIPMENT',
            chargeCode: "",
            chargeDescription: null,
            startDate: null,
            endDate: null,
            quantity: 0,
            unitPrice: 0,
            amountAfterTax: 0,
            taxToPay: null,
            amountBeforeTax: 0,
            numberOfDays: 0,
            dailyPrice: null,
            venue: null
        }

        if(proposalData.equipment?.equipmentList?.length > 0) {
            proposalData.equipment.equipmentList.push(currentEquipment)
        }else{
            proposalData.equipment.equipmentList = [currentEquipment]
        }

        proposalData.equipmentCollapsed = false;

        this.setState({ proposalData })
    }

    handleEquipmentChange = (e, idx) => {
        if (!e || !e.target) return;
        let { proposalData } = this.state;
        let { value, name } = e.target;
    
        if (!proposalData) {
            proposalData = {};
        }
        const currentEquipment = proposalData.equipment?.equipmentList?.[idx];
    
        if (currentEquipment) {
            currentEquipment[name] = name !== 'amountAfterTax' ? value : this.roundToTwoDecimals(value);
    
            this.setState({
                proposalData: {
                    ...proposalData,
                    equipment: {
                        ...proposalData.equipment,
                        equipmentValue: this.roundToTwoDecimals(proposalData.equipment.equipmentList.reduce((acc, item) => {
                            const value = parseFloat(item.amountAfterTax);
                            return acc + (isNaN(value) ? 0 : value);
                        }, 0)),
                        equipmentList: proposalData.equipment.equipmentList.map((item, index) =>
                            index === idx ? currentEquipment : item
                        )
                    }
                }
            });
        }
    }

    handleEquipmentDelete = (idx) => {
        this.setState(prevState => {
            const proposalData = prevState.proposalData || {};
            const equipment = proposalData.equipment || {};
            const equipmentList = equipment.equipmentList || [];
    
            return {
                proposalData: {
                    ...proposalData,
                    equipment: {
                        ...equipment,
                        equipmentList: equipmentList.filter((_, i) => i !== idx)
                    }
                }
            };
        });
    }

    handleAddPackage = () => {
        let { proposalData } = this.state

        if(!proposalData) {
            proposalData = {};
        };

        if(!proposalData.package) {
            proposalData.package = {
                numberOfEquipment: 0,
                equipmentValue: 0,
                equipmentList: []
            };
        }

        const currentPackage = {
            type: "",
            chargeCode: "",
            chargeDescription: null,
            startDate: null,
            endDate: null,
            quantity: 0,
            unitPrice: 0,
            amountAfterTax: 0,
            taxToPay: null,
            amountBeforeTax: 0,
            numberOfDays: 0,
            dailyPrice: null,
            venue: null
        }

        if(proposalData.package?.equipmentList?.length > 0) {
            proposalData.package.equipmentList.push(currentPackage)
        }else{
            proposalData.package.equipmentList = [currentPackage]
        }

        proposalData.ddrCollapsed = false;

        this.setState({ proposalData })
    }

    handlePackageChange = (e, idx) => {
        if (!e || !e.target) return;
        let { proposalData } = this.state;
        let { value, name } = e.target;
    
        if (!proposalData) {
            proposalData = {};
        }
        const currentPackage = proposalData.package?.equipmentList?.[idx];
    
        if (currentPackage) {
            currentPackage[name] = name !== 'amountAfterTax' ? value : this.roundToTwoDecimals(value);
            
            if (name === 'unitPrice' || name === 'pax' || name === 'quantity') {
                currentPackage.amountAfterTax = this.roundToTwoDecimals((currentPackage.unitPrice || 0) * (currentPackage.pax || 0) * (currentPackage.quantity || 0));
            }
    
            this.setState({
                proposalData: {
                    ...proposalData,
                    package: {
                        ...proposalData.package,
                        equipmentValue: this.roundToTwoDecimals(
                            proposalData.package.equipmentList.reduce((acc, item) => {
                                const value = parseFloat(item.amountAfterTax);
                                const setup = parseFloat(item.setup);

                                return acc + (isNaN(value) ? 0 : value) + (isNaN(setup) ? 0 : setup);
                            }, 0)
                        ),
                        equipmentList: proposalData.package.equipmentList.map((item, index) =>
                            index === idx ? currentPackage : item
                        )
                    }
                }
            });
        }
    }

    handlePackageDelete = (idx) => {
        this.setState(prevState => {
            const proposalData = prevState.proposalData || {};
            const packageTemp = proposalData.package || {};
            const equipmentList = packageTemp.equipmentList || [];
    
            return {
                proposalData: {
                    ...proposalData,
                    package: {
                        ...packageTemp,
                        equipmentList: equipmentList.filter((_, i) => i !== idx)
                    }
                }
            };
        });
    }

    calcTotalValue = () => {
        const { proposalData } = this.state;
    
        const roomsValue = parseFloat(proposalData?.totalRoomsValue) || 0;
        const spacesValue = parseFloat(proposalData?.space?.spacesValue) || 0;
        const fbValue = parseFloat(proposalData?.fb?.equipmentValue) || 0;
        const equipmentValue = parseFloat(proposalData?.equipment?.equipmentValue) || 0;
        const ddrValue = parseFloat(proposalData?.package?.equipmentValue) || 0;
    
        this.setState(
            {
                proposalData: {
                    ...proposalData,
                    amountAfterTax: roomsValue + spacesValue + fbValue + equipmentValue + ddrValue,
                },
            },
            () => this.handlebars()
        );
    };

    handleSelectChange = (name, combo) => {
        this.setState({
            [name]: combo && combo.value
        });
    };

    handleToggleCollapse = (name) => {
        const { proposalData } = this.state;

        if (!proposalData) {
            return;
        }

        this.setState(prevState => ({
            proposalData: {
                ...prevState.proposalData,
                [`${name}Collapsed`]: !prevState.proposalData?.[`${name}Collapsed`]
            }
        }));
        
    };

    handleToggleCommissionVisilibility = () => {
        this.setState(prevState => ({
            proposalData: {
                ...prevState.proposalData,
                showComissionTable: !prevState.proposalData?.showComissionTable
            }
        }));
    }

    handleChangeCommission = (e, comissionType, changeVisibility) => {
        let { proposalData } = this.state;
        if (!e || !e.target) return;

        let value = !changeVisibility ? e.target.value : e.target.checked;
        let name = e.target.name;

        if (!proposalData) {
            proposalData = {};
        }

        this.setState({
            proposalData: {
                ...proposalData,
                [comissionType]: {
                    ...proposalData[comissionType],
                    [name]: value
                }
            }
        });
    }

    renderCommissionField = (commissionType, labelId, value) => (
        <Col className='col-12 d-flex align-items-center mb-2'>
            <div className='d-flex align-items-center justify-content-between' style={{ width: '100%' }}>
                <div className='pr-2' style={{ width: '70%' }}>
                    <FormattedMessage id={labelId} />
                </div>
                <div className='pr-2 pl-0' style={{ width: '15%' }}>
                    <Input
                        type="number"
                        name='value'
                        id={`${commissionType}CommissionValue`}
                        value={value?.value || 0}
                        onChange={(e) => this.handleChangeCommission(e, commissionType)}
                        min={0}
                    />
                </div>
                <div className='pl-0 d-flex align-items-center justify-content-center' style={{ width: '15%' }}>
                    <CustomInput 
                        type="switch"
                        id={`${commissionType}CommissionIsVisible`}
                        name='isVisible'
                        onChange={(e) => this.handleChangeCommission(e, commissionType, true)}
                        checked={value?.isVisible ? true : false}
                    />
                </div>
            </div>
        </Col>
    );

    handleCatCodeChange = (e, idx) => {
        if (!e || !e.target) return;
        let { proposalData } = this.state;
        let { value } = e.target;
    
        if (!proposalData) {
            proposalData = {};
        }
        const updatedRoomList = proposalData.dailyRoomList?.map((item) => {
            if (!item.categories) return item;
        
            return {
                ...item,
                categories: item.categories.map((cat, catIndex) =>
                    catIndex === idx ? { ...cat, catCode: value } : cat
                ),
            };
        });

        const updatedCatCodeList = proposalData.catCodeList?.map((cat, catIndex) => (catIndex === idx ? value : cat)); 
    
        if (updatedRoomList?.length > 0 && updatedCatCodeList?.length > 0) {
            this.setState({
                proposalData: {
                    ...proposalData,
                    dailyRoomList: updatedRoomList,
                    catCodeList: updatedCatCodeList
                }
            });
        }
    }

    isOutsideRange = (day) => {
        const { deal } = this.props;

        const dealStartDate = deal?.businessStartDate;
        const dealEndDate = deal?.businessEndDate;

        if(!dealStartDate || !dealEndDate) {
            return false;
        }

        if(day.isBefore(dealStartDate) || day.isAfter(dealEndDate, 'day')){
            return true;
        }

        return false;
    }

    getFirstValidMonth = () => {
        const { deal } = this.props;
    
        const dealStartDate = deal?.businessStartDate;
    
        if (dealStartDate) {
            return moment(dealStartDate);
        }
        return moment();
    };

    render() {
        const { proposalData, dynamicData, templateHtmlParsed, dateFocus, hotelDealOptions, selectedHotel, langOptions, 
            selectedLang, commissions, proposalCurrency, templateError, invalidProposals, selectedHotelCurrecy, exchangeRate, error, block } = this.state
        const { toggleActiveSupraTab } = this.props

        return (
            <div className='h-100'>
                <ErrorAlert error={error}/>
                <BlockUi tag="div" blocking={block}>
                    <Row>
                        <Col className="col-8 d-flex aling-items-center">
                            <div className='p-2 mr-2 cursor-pointer'>
                                <i className="fas fa-chevron-left" onClick={_ => toggleActiveSupraTab("Calendar")} />
                            </div>
                            <h5 className='m-0 d-flex align-items-center'>
                                <FormattedMessage id="SalesProcess.DownloadProposal" />
                            </h5>
                        </Col>
                        <Col className='d-flex align-items-center justify-content-end'>
                            <Button id='printButton' disabled={templateError || invalidProposals} type='button' className="ml-2 btn btn-host btn-sm"  onClick={this.printTemplate}>
                                <i className="fas fa-print" />
                            </Button>
                            <CoolTooltip placement="top" target={`printButton`}>
                                <FormattedMessage id="SalesProcess.PrintTemplateData" />
                            </CoolTooltip>
                            <Button className="ml-2 btn btn-host btn-sm" id='refresTemplateData' disabled={templateError || invalidProposals} type='button' onClick={() => this.calcTotalValue()}>
                                <i class="fas fa-sync-alt"></i>
                            </Button>
                            <CoolTooltip placement="top" target={`refresTemplateData`}>
                                <FormattedMessage id="SalesProcess.RefreshTemplateData" />
                            </CoolTooltip>
                            <CommonHelper help={<FormattedMessage id="SalesProcess.DownloadProposalHelp" />} id={'DownloadProposalHelp'} />
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        {!invalidProposals ?
                            <>
                                <Col className='col-12 px-0 d-flex align-items-end'>
                                    <Col className='col-3'>
                                        <Label className='title-sm text-muted'><FormattedMessage id="SalesProcess.Hotel" /></Label>
                                        <CustomSelect
                                            name={'selectedHotel'}
                                            icon={'icon-icon-hotel'}
                                            options={hotelDealOptions}
                                            onChange={(combo) => this.handleSelectChange('selectedHotel', combo)}
                                            value={hotelDealOptions.find(hotel => hotel.value === selectedHotel)}
                                            placeholder={<FormattedMessage id="SalesProcess.Hotel" />}
                                            disabled={hotelDealOptions.length <= 1}
                                            isClearable={false}
                                            required
                                        />
                                    </Col>
                                    <Col className='col-2'>
                                        <Label className='title-sm text-muted'><FormattedMessage id="SalesProcess.Language" /></Label>
                                        <CustomSelect
                                            name={'selectedLang'}
                                            options={langOptions}
                                            onChange={(combo) => this.handleSelectChange('selectedLang', combo)}
                                            value={langOptions.find(language => language.value === selectedLang)}
                                            placeholder={<FormattedMessage id="SalesProcess.Language" />}
                                            isClearable={false}
                                            required
                                        />
                                    </Col>
                                    <Col className='text-right'>
                                        <Button id='searchButton' className="ml-2 btn btn-host btn-sm" onClick={() => this.getTemplateHtml()}>
                                            <i className="fas fa-search" />
                                        </Button>
                                    </Col>
                                </Col>
                                {!templateError ?
                                    <>
                                        <Col className='col-12'>
                                            <hr />
                                        </Col>
                                        <Col className='col-12 px-0 d-flex align-items-start' style={{ height: '65vh' }}>
                                            <div className='h-100 pl-3 pr-2' style={{ borderRight: '1px solid #DFE1E3', width: '41%' }}> {/* HTML */}
                                                {templateHtmlParsed ?
                                                    <div id="printThis" style={{ width: "100%%", height: "100%" }}>
                                                        <iframe
                                                            id={`myIframe-SalesProcessDownloadProFormaModal`}
                                                            title="Meu iFrame SalesProcessDownloadProFormaModal"
                                                            srcDoc={templateHtmlParsed}
                                                            height="100%"
                                                            width="100%"
                                                            ref={this.templateRef}
                                                            style={{ border: "none" }}
                                                            onLoad={() => {
                                                                const iframe = document.getElementById('myIframe-SalesProcessDownloadProFormaModal');
                                                                const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                                                                iframeDoc.documentElement.classList.add('veryCoolScrollBar');

                                                                const styleElement = iframeDoc.createElement('style');
                                                                styleElement.textContent = `
                                                                .veryCoolScrollBar::-webkit-scrollbar {
                                                                    height: 7px;
                                                                    width: 7px;
                                                                }

                                                                .veryCoolScrollBar::-webkit-scrollbar-track {
                                                                    background: rgb(241, 241, 241);
                                                                    border-radius: 10px;
                                                                }

                                                                .veryCoolScrollBar::-webkit-scrollbar-thumb {
                                                                    border-radius: 10px;
                                                                    background: rgb(193, 193, 193);
                                                                }
                                                                `;
                                                                iframeDoc.head.appendChild(styleElement);
                                                            }}
                                                        />
                                                    </div>
                                                    :
                                                    <div className='d-flex justify-content-center'>
                                                        <p className="text-muted pt-4"><FormattedMessage id="SalesProcess.NoAssociatedConfigTemplate" /></p>
                                                    </div>
                                                }
                                            </div>
                                            <div className='dowloadProFormaFields h-100' style={{ overflow: 'auto', width: '59%' }}>
                                                <div> {/* Header */}
                                                    <Col className='col-12'>
                                                        <h5><FormattedMessage id="SalesProcess.TemplateHeader" /></h5>
                                                        <hr className='mt-0' />
                                                    </Col>
                                                    <Row className='px-3'>
                                                        <Col className='col-6 mb-2 text-muted pr-2'> {/* userContact */}
                                                            <Label for="userContact">
                                                                <FormattedMessage id="SalesProcess.Contact" />
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                name="userContact"
                                                                id="userContact"
                                                                value={proposalData?.userContact || ''}
                                                                onChange={(e) => this.handleInputChange(e)}
                                                            />
                                                        </Col>
                                                        <Col className="col-6 d-flex flex-column mb-2 text-muted pl-0"> {/* proposalDate */}
                                                            <Label for="proposalDate">
                                                                <FormattedMessage id="SalesProcess.ProposalDate" />
                                                            </Label>
                                                            <SingleDatePicker
                                                                name="proposalDate"
                                                                id="proposalDate"
                                                                isOutsideRange={this.isOutsideRange}
                                                                date={proposalData?.proposalDate ? moment(proposalData.proposalDate) : null}
                                                                focused={dateFocus === 'proposalDate'}
                                                                onFocusChange={({ focused }) => this.setState({ dateFocus: focused ? 'proposalDate' : null })}
                                                                small={true}
                                                                numberOfMonths={1}
                                                                showDefaultInputIcon={true}
                                                                onDateChange={date => this.setState({ proposalData: { ...proposalData, proposalDate: date } })}
                                                                initialVisibleMonth={this.getFirstValidMonth}
                                                            />
                                                        </Col>
                                                        <Col className="col-6 d-flex flex-column mb-2 text-muted pr-2"> {/* arrival */}
                                                            <Label for="arrival">
                                                                <FormattedMessage id="SalesProcess.Arrival" />
                                                            </Label>
                                                            <SingleDatePicker
                                                                name="arrival"
                                                                id="arrival"
                                                                isOutsideRange={this.isOutsideRange}
                                                                date={proposalData?.startDate ? moment(proposalData.startDate) : null}
                                                                focused={dateFocus === 'arrival'}
                                                                onFocusChange={({ focused }) => this.setState({ dateFocus: focused ? 'arrival' : null })}
                                                                small={true}
                                                                numberOfMonths={1}
                                                                showDefaultInputIcon={true}
                                                                onDateChange={date => this.setState({ proposalData: { ...proposalData, startDate: date } })}
                                                                initialVisibleMonth={this.getFirstValidMonth}
                                                            />
                                                        </Col>
                                                        <Col className="col-6 d-flex flex-column mb-2 text-muted pl-0"> {/* departure */}
                                                            <Label for="departure">
                                                                <FormattedMessage id="SalesProcess.Departure" />
                                                            </Label>
                                                            <SingleDatePicker
                                                                name="departure"
                                                                id="departure"
                                                                isOutsideRange={this.isOutsideRange}
                                                                date={proposalData?.endDate ? moment(proposalData.endDate) : null}
                                                                focused={dateFocus === 'departure'}
                                                                onFocusChange={({ focused }) => this.setState({ dateFocus: focused ? 'departure' : null })}
                                                                small={true}
                                                                numberOfMonths={1}
                                                                showDefaultInputIcon={true}
                                                                onDateChange={date => this.setState({ proposalData: { ...proposalData, endDate: date } })}
                                                                initialVisibleMonth={this.getFirstValidMonth}
                                                            />
                                                        </Col>
                                                        <Col className='col-6 mb-2 text-muted pr-2'> {/* pax */}
                                                            <Label for="pax">
                                                                <FormattedMessage id="SalesProcess.NrOfPax" />
                                                            </Label>
                                                            <Input
                                                                type="number"
                                                                name="pax"
                                                                id="pax"
                                                                value={proposalData?.pax || 0}
                                                                onChange={(e) => this.handleInputChange(e)}
                                                                min={0}
                                                            />
                                                        </Col>
                                                        <Col className='col-6 mb-2 text-muted pl-0'> {/* totalRooms */}
                                                            <Label for="totalRooms">
                                                                <FormattedMessage id="SalesProcess.NrOfRooms" />
                                                            </Label>
                                                            <Input
                                                                type="number"
                                                                name="totalRooms"
                                                                id="totalRooms"
                                                                value={proposalData?.totalRooms || 0}
                                                                onChange={(e) => this.handleInputChange(e)}
                                                                min={0}
                                                            />
                                                        </Col>
                                                        <Col className='col-6 mb-2 text-muted pr-2'> {/* resNumber */}
                                                            <Label for="resNumber">
                                                                <FormattedMessage id="SalesProcess.PreBooking" />
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                name="resNumber"
                                                                id="resNumber"
                                                                value={proposalData?.resNumber || ''}
                                                                onChange={(e) => this.handleInputChange(e)}
                                                            />
                                                        </Col>
                                                        <Col className="col-6 d-flex flex-column mb-2 text-muted pl-0"> {/* optionDate */}
                                                            <Label for="optionDate">
                                                                <FormattedMessage id="SalesProcess.OptionDate" />
                                                            </Label>
                                                            <SingleDatePicker
                                                                name="optionDate"
                                                                id="optionDate"
                                                                isOutsideRange={this.isOutsideRange}
                                                                date={proposalData?.optionDate ? moment(proposalData.optionDate) : null}
                                                                focused={dateFocus === 'optionDate'}
                                                                onFocusChange={({ focused }) => this.setState({ dateFocus: focused ? 'optionDate' : null })}
                                                                small={true}
                                                                numberOfMonths={1}
                                                                showDefaultInputIcon={true}
                                                                onDateChange={date => this.setState({ proposalData: { ...proposalData, optionDate: date } })}
                                                                initialVisibleMonth={this.getFirstValidMonth}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>

                                                {proposalData?.catCodeList?.length > 0 || proposalData?.rateCodeList?.length > 0 ?
                                                    <div> {/* Lodges */}
                                                        <Col className='col-12 pt-3'> 
                                                            <div className='d-flex align-items-center justify-content-between mb-2'>
                                                                <h5 className='m-0'><FormattedMessage id="SalesProcess.Lodges" /></h5>
                                                                <div className='d-flex align-items-center'>
                                                                    <Button className='btn btn-host btn-sm mr-2' onClick={() => this.handleAddLodgeDate()}>
                                                                        <i className="fas fa-plus" />
                                                                    </Button>
                                                                    <i className={`fas fa-chevron-up cursor-pointer rotate-icon ${!proposalData?.dailyRoomListCollapsed ? 'rotate-up' : 'rotate-down'}`} onClick={() => this.handleToggleCollapse('dailyRoomList')} />
                                                                </div>
                                                            </div>
                                                            <hr className='mt-0' />
                                                        </Col>
                                                        <Collapse isOpen={!proposalData?.dailyRoomListCollapsed}>
                                                            <Row className='px-3'>
                                                                {proposalData?.dailyRoomList?.length > 0 ? 
                                                                    <>
                                                                        <Col className="col-12 d-flex align-items-center mb-4">
                                                                            <div className="text-muted pr-3">
                                                                                <FormattedMessage id="SalesProcess.CategoryCodes" />
                                                                            </div>
                                                                            {proposalData.dailyRoomList?.[0]?.categories?.length ? 
                                                                                <div className="d-flex w-100" style={{ gap: '0.5rem' }}>
                                                                                    {proposalData.dailyRoomList[0].categories.map((cat, catIdx) => (
                                                                                        <Input
                                                                                            key={`categoryCode-${catIdx}`}
                                                                                            type="text"
                                                                                            name={`categoryCode-${catIdx}`}
                                                                                            id={`categoryCode-${catIdx}`}
                                                                                            value={cat.catCode || ''}
                                                                                            className="flex-grow-1"
                                                                                            onChange={(e) => this.handleCatCodeChange(e, catIdx)}
                                                                                        />
                                                                                    ))}
                                                                                </div>
                                                                             : ''}
                                                                        </Col>
                                                                        <Col className="col-12 d-flex align-items-center justify-content-between mb-1 pt-2">
                                                                            <div className="d-flex align-items-center justify-content-center" style={{ width: '98%' }}>
                                                                                <div className="text-muted pr-2" style={{ width: '16%' }}> {/* dailyRoomDate */}
                                                                                    <FormattedMessage id="SalesProcess.Date" />
                                                                                </div>

                                                                                {proposalData.dailyRoomList?.[0]?.categories?.length ? 
                                                                                    <div className="d-flex" style={{ flex: 1 }}>
                                                                                        {proposalData.dailyRoomList[0].categories?.map((cat, catIdx) => (
                                                                                            <div className="pr-2 pl-0 text-muted" style={{ flex: 1 }} key={catIdx}> {/* Roomcategory */}
                                                                                                {cat.catCode}
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                : ''}

                                                                                <div className="text-muted" style={{ width: '13%' }}> {/* totalValue */}
                                                                                    <FormattedMessage id="SalesProcess.SubTotal" />
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ width: '2%' }} />
                                                                        </Col>
                                                                        {proposalData.dailyRoomList.map((el, index) => {
                                                                            return (
                                                                                <Col className='col-12 d-flex align-items-center justify-content-between mb-2' key={index}>
                                                                                    <div className="d-flex align-items-center justify-content-center" style={{ width: '98%' }}>
                                                                                        <div className="text-muted pr-2" style={{ width: '16%' }}> {/* dailyRoomDate */}
                                                                                            <SingleDatePicker
                                                                                                name={`dailyRoomDate-${index}`}
                                                                                                id={`dailyRoomDate-${index}`}
                                                                                                isOutsideRange={this.isOutsideRange}
                                                                                                date={el?.date ? moment(el.date) : null}
                                                                                                focused={dateFocus === `dailyRoomDate-${index}`}
                                                                                                onFocusChange={({ focused }) => this.setState({ dateFocus: focused ? `dailyRoomDate-${index}` : null })}
                                                                                                small={true}
                                                                                                numberOfMonths={1}
                                                                                                showDefaultInputIcon={true}
                                                                                                onDateChange={date => this.setState({ proposalData: { ...proposalData, dailyRoomList: proposalData.dailyRoomList.map((item, idx) => idx === index ? { ...item, date } : item) }})}
                                                                                                initialVisibleMonth={this.getFirstValidMonth}
                                                                                            />
                                                                                        </div>
                                                                                        {el.categories?.length > 0 ? 
                                                                                            <div className="d-flex" style={{ flex: 1 }}>
                                                                                                {el.categories.map((cat, key) => {
                                                                                                    return (
                                                                                                        <div className="pr-2 pl-0" style={{ flex: 1 }} key={`${index}-${key}`}> {/* Roomcategory */}
                                                                                                            <Input
                                                                                                                type="number"
                                                                                                                name={`category-${index}-${key}`}
                                                                                                                id={`category-${index}-${key}`}
                                                                                                                value={cat.numberOfRooms || 0}
                                                                                                                onChange={(e) => this.handleDailyRoomListChange(e, index, key)}
                                                                                                                min={0}
                                                                                                            />
                                                                                                        </div>
                                                                                                    )
                                                                                                })}
                                                                                            </div>
                                                                                        :''}
                                                                                        <div style={{ width: '13%' }}> {/* totalValue */}
                                                                                            <Input
                                                                                                type="number"
                                                                                                name={`dailyRoomTotal`}
                                                                                                id={`dailyRoomTotal-${index}`}
                                                                                                value={el.totalValue || 0}
                                                                                                onChange={(e) => this.handleDailyRoomListChange(e, index)}
                                                                                                min={0}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='text-right' style={{ width: '2%' }}>
                                                                                        <i className='fas fa-trash-alt text-danger pointer' onClick={() => this.handleDeleteLodgeDate(index)} />
                                                                                    </div>
                                                                                </Col>
                                                                            )
                                                                        })}
                                                                    </>
                                                                :''}
                                                                <Col className='col-12 my-3'>
                                                                    <div className='d-flex align-items-end justify-content-between pr-2' style={{ width: '98%' }}>
                                                                        <div className='text-muted pr-2' style={{ width: '30%' }}> {/* TotalRoomNights */}
                                                                            <Label for={`totalRoomNights`}>
                                                                                <FormattedMessage id="SalesProcess.TotalRoomNights" />
                                                                            </Label>
                                                                            <Input
                                                                                type="number"
                                                                                name={`totalRoomNights`}
                                                                                id={`totalRoomNights`}
                                                                                value={proposalData?.totalRoomNights || 0}
                                                                                onChange={(e) => this.handleInputChange(e)}
                                                                                min={0}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <span className='text-muted pr-3'>
                                                                                <FormattedMessage id="SalesProcess.TotalRoomsValue" />
                                                                            </span>
                                                                            <span>
                                                                                <FormatAmountNumber value={proposalData?.totalRoomsValue || 0} currency={proposalCurrency} />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Collapse>
                                                    </div>
                                                :''}

                                                <div> {/* Aditional Lodges Notes */}
                                                    <Col className='col-12 pt-3'> 
                                                        <div className='d-flex align-items-center justify-content-between mb-2'>
                                                            <h5><FormattedMessage id="SalesProcess.LodgingNotes" /></h5>
                                                            <div className='d-flex align-items-center'>
                                                                <Button className='btn btn-host btn-sm mr-2' onClick={() => this.addNewGenericNote('lodgingNotes')}>
                                                                    <i className="fas fa-plus" />
                                                                </Button>
                                                                <i className={`fas fa-chevron-up cursor-pointer rotate-icon ${!proposalData?.lodgingNotesCollapsed ? 'rotate-up' : 'rotate-down'}`} onClick={() => this.handleToggleCollapse('lodgingNotes')} />
                                                            </div>
                                                        </div>
                                                        <hr className='mt-0' />
                                                    </Col>
                                                    <Collapse isOpen={!proposalData?.lodgingNotesCollapsed}>
                                                        <Row className='px-3'>
                                                            {dynamicData?.lodgingNotes?.length > 0 ?
                                                                dynamicData.lodgingNotes.map((el, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <Col className='col-12 text-muted'>
                                                                                <Label for={`lodgingNotes-${index}`}>
                                                                                    <FormattedMessage id="SalesProcess.LodgingNotesGeneric" /> {index + 1}
                                                                                </Label>
                                                                            </Col>
                                                                            <Col className='col-12 d-flex align-items-center'>
                                                                                <div className='mb-2 pr-2' style={{ width: '98%' }}>
                                                                                    <Input
                                                                                        type="text"
                                                                                        name={`lodgingNotes-${index}`}
                                                                                        id={`lodgingNotes-${index}`}
                                                                                        value={el || ''}
                                                                                        onChange={(e) => this.handleGenericNoteChange(e, index, 'lodgingNotes')}
                                                                                    />
                                                                                </div>
                                                                                <div className='mb-2 text-right' style={{ width: '2%' }}>
                                                                                    <i className='fas fa-trash-alt text-danger pointer' onClick={() => this.handleDeleteGenericNote(index, 'lodgingNotes')} />
                                                                                </div>
                                                                            </Col>
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                            :''}
                                                        </Row>
                                                    </Collapse>
                                                </div>

                                                <div> {/* Spaces */}
                                                    <Col className='col-12 pt-3'> 
                                                        <div className='d-flex align-items-center justify-content-between mb-2'>
                                                            <h5 className='m-0'><FormattedMessage id="SalesProcess.SpacesTemplate" /></h5>
                                                            <div className='d-flex align-items-center'>
                                                                <Button className='btn btn-host btn-sm mr-2' onClick={() => this.HandleAddSpace()}>
                                                                    <i className="fas fa-plus" />
                                                                </Button>
                                                                <i className={`fas fa-chevron-up cursor-pointer rotate-icon ${!proposalData?.spacesCollapsed ? 'rotate-up' : 'rotate-down'}`} onClick={() => this.handleToggleCollapse('spaces')} />
                                                            </div>
                                                        </div>
                                                        <hr className='mt-0' />
                                                    </Col>
                                                    <Collapse isOpen={!proposalData?.spacesCollapsed}>
                                                        <Row className='px-3'>
                                                            {proposalData?.space?.spaceList?.length > 0 ? 
                                                                <>
                                                                    <Col className='col-12 d-flex align-items-center justify-content-between mb-1'>
                                                                        <div className="d-flex align-items-center justify-content-center" style={{ width: '98%' }}>
                                                                            {[
                                                                                { id: "SalesProcess.SpaceNameTemplate", width: '27%' }, /* chargeDescription */
                                                                                { id: "SalesProcess.Pax", width: '8%' }, /* pax */
                                                                                { id: "SalesProcess.NrDays", width: '7%' }, /* numberOfDays */
                                                                                { id: "SalesProcess.FromDateTemplate", width: '15.5%' }, /* fromDate */
                                                                                { id: "SalesProcess.ToDateTemplate", width: '15.5%' }, /* toDate */
                                                                                { id: "SalesProcess.RentPerDay", width: '10%' }, /* dailyPrice */
                                                                                { id: "SalesProcess.SubTotal", width: '11%' }, /* totalValue */
                                                                                { id: "SalesProcess.Setup", width: '8%' }, /* setup */
                                                                            ].map((field, index) => (
                                                                                <div key={index} className="pr-2 pl-0 text-truncate text-muted" style={{ width: field.width }}>
                                                                                    <FormattedMessage id={field.id} />
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        <div style={{ width: '2%' }} />
                                                                    </Col>
                                                                    {proposalData.space.spaceList.map((el, index) => {
                                                                        return (
                                                                            <Col className='col-12 d-flex align-items-center justify-content-between mb-2' key={index}>
                                                                                <div className='d-flex align-items-center justify-content-center' style={{ width: '98%' }}>
                                                                                    <div className='pr-2' style={{ width: '27%' }}> {/* spaceName */}
                                                                                        <Input
                                                                                            type="text"
                                                                                            name='spaceName'
                                                                                            id={`chargeCode-${index}`}
                                                                                            value={el.spaceName || ''}
                                                                                            onChange={(e) => this.handleSpaceChange(e, index)}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='pr-2 pl-0' style={{ width: '8%' }}> {/* pax */}
                                                                                        <Input
                                                                                            type="number"
                                                                                            name='pax'
                                                                                            id={`space-pax-${index}`}
                                                                                            value={el.pax || 0}
                                                                                            onChange={(e) => this.handleSpaceChange(e, index)}
                                                                                            min={0}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='pr-2 pl-0' style={{ width: '7%' }}> {/* numberOfDays */}
                                                                                        <Input
                                                                                            type="number"
                                                                                            name='numberOfDays'
                                                                                            id={`numberOfDays-${index}`}
                                                                                            value={el.numberOfDays || 0}
                                                                                            onChange={(e) => this.handleSpaceChange(e, index)}
                                                                                            min={0}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="pr-2 pl-0" style={{ width: '15.5%' }}> {/* fromDate */}
                                                                                        <SingleDatePicker
                                                                                            name={`space-fromDate-${index}`}
                                                                                            id={`space-fromDate-${index}`}
                                                                                            isOutsideRange={this.isOutsideRange}
                                                                                            date={el?.fromDate ? moment(el.fromDate) : null}
                                                                                            focused={dateFocus === `space-fromDate-${index}`}
                                                                                            onFocusChange={({ focused }) => this.setState({ dateFocus: focused ? `space-fromDate-${index}` : null })}
                                                                                            small={true}
                                                                                            numberOfMonths={1}
                                                                                            showDefaultInputIcon={true}
                                                                                            onDateChange={date => this.setState({ proposalData: { ...proposalData, space: { ...proposalData.space, spaceList: proposalData.space?.spaceList.map((item, idx) => idx === index ? { ...item, fromDate: date } : item)} }})}
                                                                                            initialVisibleMonth={this.getFirstValidMonth}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="pr-2 pl-0" style={{ width: '15.5%' }}> {/* toDate */}
                                                                                        <SingleDatePicker
                                                                                            name={`space-toDate-${index}`}
                                                                                            id={`space-toDate-${index}`}
                                                                                            isOutsideRange={this.isOutsideRange}
                                                                                            date={el?.toDate ? moment(el.toDate) : null}
                                                                                            focused={dateFocus === `space-toDate-${index}`}
                                                                                            onFocusChange={({ focused }) => this.setState({ dateFocus: focused ? `space-toDate-${index}` : null })}
                                                                                            small={true}
                                                                                            numberOfMonths={1}
                                                                                            showDefaultInputIcon={true}
                                                                                            onDateChange={date => this.setState({ proposalData: { ...proposalData, space: { ...proposalData.space, spaceList: proposalData.space?.spaceList.map((item, idx) => idx === index ? { ...item, toDate: date } : item)} }})}
                                                                                            initialVisibleMonth={this.getFirstValidMonth}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='pr-2 pl-0' style={{ width: '10%' }}> {/* dailyPrice */}
                                                                                        <Input
                                                                                            type="number"
                                                                                            name='dailyPrice'
                                                                                            id={`dailyPrice-${index}`}
                                                                                            value={el.dailyPrice || 0}
                                                                                            onChange={(e) => this.handleSpaceChange(e, index)}
                                                                                            min={0}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='pr-2 pl-0' style={{ width: '11%' }}> {/* totalValue */}
                                                                                        <Input
                                                                                            type="number"
                                                                                            name='totalValue'
                                                                                            id={`totalValue-${index}`}
                                                                                            value={el.totalValue || 0}
                                                                                            onChange={(e) => this.handleSpaceChange(e, index)}
                                                                                            min={0}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='pr-2 pl-0' style={{ width: '8%' }}> {/* setup */}
                                                                                        <Input
                                                                                            type="number"
                                                                                            name='setup'
                                                                                            id={`setup-${index}`}
                                                                                            value={el.setup || ''}
                                                                                            onChange={(e) => this.handleSpaceChange(e, index)}
                                                                                            min={0}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='text-right' style={{ width: '2%' }}>
                                                                                    <i className='fas fa-trash-alt text-danger pointer' onClick={() => this.handleSpaceDelete(index)} />
                                                                                </div>
                                                                            </Col>
                                                                        )
                                                                    })}
                                                                    <Col className='col-12 text-right my-3'> {/* space.spacesValue  */}
                                                                        <div className='pr-2' style={{ width: '98%' }}>
                                                                            <span className='text-muted pr-3'>
                                                                                <FormattedMessage id="SalesProcess.SpacesValueTemplate" />
                                                                            </span>
                                                                            <span>
                                                                                <FormatAmountNumber value={proposalData?.space?.spacesValue || 0} currency={proposalCurrency} />
                                                                            </span>
                                                                        </div>
                                                                    </Col>
                                                                </>
                                                            :''}
                                                        </Row>
                                                    </Collapse>
                                                </div>

                                                <div> {/* DDR (Package) */}
                                                    <Col className='col-12 pt-3'> 
                                                        <div className='d-flex align-items-center justify-content-between mb-2'>
                                                            <h5 className='m-0'><FormattedMessage id="SalesProcess.PackageTemplate" /></h5>
                                                            <div className='d-flex align-items-center'>
                                                                <Button className='btn btn-host btn-sm mr-2' onClick={() => this.handleAddPackage()}>
                                                                    <i className="fas fa-plus" />
                                                                </Button>
                                                                <i className={`fas fa-chevron-up cursor-pointer rotate-icon ${!proposalData?.ddrCollapsed ? 'rotate-up' : 'rotate-down'}`} onClick={() => this.handleToggleCollapse('ddr')} />
                                                            </div>
                                                        </div>
                                                        <hr className='mt-0' />
                                                    </Col>
                                                    <Collapse isOpen={!proposalData?.ddrCollapsed}>
                                                        <Row className='px-3'>
                                                            {proposalData?.package?.equipmentList?.length > 0 ? 
                                                                <>
                                                                    <Col className='col-12 d-flex align-items-center mb-1'>
                                                                        <div className="d-flex align-items-center justify-content-center" style={{ width: '98%' }}>
                                                                            {[
                                                                                { id: "SalesProcess.Package", width: '27%' },  /* chargeDescription */
                                                                                { id: "SalesProcess.UnitPricePackage", width: '7.5%' }, /* unitPrice */
                                                                                { id: "SalesProcess.Pax", width: '7.5%' }, /* pax */
                                                                                { id: "SalesProcess.Venue", width: '15%' }, /* venue */
                                                                                { id: "SalesProcess.Date", width: '15.5%' }, /* startDate */
                                                                                { id: "SalesProcess.Quantity", width: '10%' }, /* quantity */
                                                                                { id: "SalesProcess.SubTotal", width: '11%' }, /* amountAfterTax */
                                                                                { id: "SalesProcess.Setup", width: '8%' }, /* setup */
                                                                            ].map((field, index) => (
                                                                                <div
                                                                                    key={index}
                                                                                    className="pr-2 pl-0 text-truncate text-muted"
                                                                                    style={{ width: field.width }}
                                                                                >
                                                                                    <FormattedMessage id={field.id} />
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        <div className='text-right pl-0' />
                                                                    </Col>
                                                                    {proposalData.package.equipmentList.map((el, index) => {
                                                                        return (
                                                                            <Col className='col-12 d-flex align-items-center justify-content-between mb-2' key={index}>
                                                                                <div className='d-flex align-items-center justify-content-center' style={{ width: '98%' }}>
                                                                                    <div className='pr-2' style={{ width: '27%' }}> {/* chargeDescription */}
                                                                                        <Input
                                                                                            type="text"
                                                                                            name='chargeDescription'
                                                                                            id={`package-chargeDescription-${index}`}
                                                                                            value={el.chargeDescription || ''}
                                                                                            onChange={(e) => this.handlePackageChange(e, index)}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='pr-2 pl-0' style={{ width: '7.5%' }}> {/* unitPrice */}
                                                                                        <Input
                                                                                            type="number"
                                                                                            name='unitPrice'
                                                                                            id={`package-unitPrice-${index}`}
                                                                                            value={el.unitPrice || 0}
                                                                                            onChange={(e) => this.handlePackageChange(e, index)}
                                                                                            min={0}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='pr-2 pl-0' style={{ width: '7.5%' }}> {/* pax */}
                                                                                        <Input
                                                                                            type="number"
                                                                                            name='pax'
                                                                                            id={`package-pax-${index}`}
                                                                                            value={el.pax || 0}
                                                                                            onChange={(e) => this.handlePackageChange(e, index)}
                                                                                            min={0}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='pr-2 pl-0' style={{ width: '15%' }}> {/* venue */}
                                                                                        <Input
                                                                                            type="text"
                                                                                            name='venue'
                                                                                            id={`package-venue-${index}`}
                                                                                            value={el.venue || ''}
                                                                                            onChange={(e) => this.handlePackageChange(e, index)}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="pr-2 pl-0" style={{ width: '15.5%' }}> {/* startDate */}
                                                                                        <SingleDatePicker
                                                                                            name={`package-startDate-${index}`}
                                                                                            id={`package-startDate-${index}`}
                                                                                            isOutsideRange={this.isOutsideRange}
                                                                                            date={el?.startDate ? moment(el.startDate) : null}
                                                                                            focused={dateFocus === `package-startDate-${index}`}
                                                                                            onFocusChange={({ focused }) => this.setState({ dateFocus: focused ? `package-startDate-${index}` : null })}
                                                                                            small={true}
                                                                                            numberOfMonths={1}
                                                                                            showDefaultInputIcon={true}
                                                                                            onDateChange={date => this.setState({ proposalData: { ...proposalData, package: { ...proposalData.package, equipmentList:  proposalData.package?.equipmentList.map((item, idx) => idx === index ? { ...item, startDate: date } : item)} }})}
                                                                                            initialVisibleMonth={this.getFirstValidMonth}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='pr-2 pl-0' style={{ width: '10%' }}> {/* quantity */}
                                                                                        <Input
                                                                                            type="number"
                                                                                            name='quantity'
                                                                                            id={`package-quantity-${index}`}
                                                                                            value={el.quantity || 0}
                                                                                            onChange={(e) => this.handlePackageChange(e, index)}
                                                                                            min={0}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='pr-2 pl-0' style={{ width: '11%' }}> {/* amountAfterTax */}
                                                                                        <Input
                                                                                            type="number"
                                                                                            name='amountAfterTax'
                                                                                            id={`package-amountAfterTax-${index}`}
                                                                                            value={el.amountAfterTax || 0}
                                                                                            onChange={(e) => this.handlePackageChange(e, index)}
                                                                                            min={0}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='pr-2 pl-0' style={{ width: '8%' }}> {/* setup */}
                                                                                        <Input
                                                                                            type="number"
                                                                                            name='setup'
                                                                                            id={`package-setup-${index}`}
                                                                                            value={el.setup || ''}
                                                                                            onChange={(e) => this.handlePackageChange(e, index)}
                                                                                            min={0}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='text-right pl-0' style={{ width: '2%' }}>
                                                                                    <i className='fas fa-trash-alt text-danger pointer' onClick={() => this.handlePackageDelete(index)} />
                                                                                </div>
                                                                            </Col>
                                                                        )
                                                                    })}
                                                                    <Col className='col-12 text-right my-3'> {/* pakcage.equipmentValue  */}
                                                                        <div className='pr-2' style={{ width: '98%' }}>
                                                                            <span className='text-muted pr-3'>
                                                                                <FormattedMessage id="SalesProcess.TotalPackage" />
                                                                            </span>
                                                                            <span>
                                                                                <FormatAmountNumber value={proposalData?.package?.equipmentValue || 0} currency={proposalCurrency} />
                                                                            </span>
                                                                        </div>
                                                                    </Col>
                                                                </>
                                                            :''}
                                                        </Row>
                                                    </Collapse>
                                                </div>

                                                <div> {/* Aditional DDR Notes (spacesNotes) */}
                                                    <Col className='col-12 pt-3'> 
                                                        <div className='d-flex align-items-center justify-content-between mb-2'>
                                                            <h5><FormattedMessage id="SalesProcess.DDRNotes" /></h5>
                                                            <div className='d-flex align-items-center'>
                                                                <Button className='btn btn-host btn-sm mr-2' onClick={() => this.addNewGenericNote('spacesNotes')}>
                                                                    <i className="fas fa-plus" />
                                                                </Button>
                                                                <i className={`fas fa-chevron-up cursor-pointer rotate-icon ${!proposalData?.spacesNotesCollapsed ? 'rotate-up' : 'rotate-down'}`} onClick={() => this.handleToggleCollapse('spacesNotes')} />
                                                            </div>
                                                        </div>
                                                        <hr className='mt-0' />
                                                    </Col>
                                                    <Collapse isOpen={!proposalData?.spacesNotesCollapsed}>
                                                        <Row className='px-3'>
                                                            {dynamicData?.spacesNotes?.length > 0 ?
                                                                dynamicData.spacesNotes.map((el, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <Col className='col-12 d-flex align-items-center'>
                                                                                <div className='mb-2 pr-2' style={{ width: '98%' }}>
                                                                                    <Input
                                                                                        type="text"
                                                                                        name={`spacesNotes-${index}`}
                                                                                        id={`spacesNotes-${index}`}
                                                                                        value={el || ''}
                                                                                        onChange={(e) => this.handleGenericNoteChange(e, index, 'spacesNotes')}
                                                                                    />
                                                                                </div>
                                                                                <div className='mb-2 pl-0 text-right' style={{ width: '2%' }}>
                                                                                    <i className='fas fa-trash-alt text-danger pointer' onClick={() => this.handleDeleteGenericNote(index, 'spacesNotes')} />
                                                                                </div>
                                                                            </Col>
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                            :''}
                                                        </Row>
                                                    </Collapse>
                                                </div>

                                                <div> {/* Food and Beverages */}
                                                    <Col className='col-12 pt-3'> 
                                                        <div className='d-flex align-items-center justify-content-between mb-2'>
                                                            <h5 className='m-0'><FormattedMessage id="SalesProcess.Food&Beverages" /></h5>
                                                            <div className='d-flex align-items-center'>
                                                                <Button className='btn btn-host btn-sm mr-2' onClick={() => this.handleAddFB()}>
                                                                    <i className="fas fa-plus" />
                                                                </Button>
                                                                <i className={`fas fa-chevron-up cursor-pointer rotate-icon ${!proposalData?.fbCollapsed ? 'rotate-up' : 'rotate-down'}`} onClick={() => this.handleToggleCollapse('fb')} />
                                                            </div>
                                                        </div>
                                                        <hr className='mt-0' />
                                                    </Col>
                                                    <Collapse isOpen={!proposalData?.fbCollapsed}>
                                                        <Row className='px-3'>
                                                            {proposalData?.fb?.equipmentList?.length > 0 ? 
                                                                <>
                                                                    <Col className='col-12 d-flex align-items-center mb-1'>
                                                                        <div className='d-flex align-items-center justify-content-center' style={{ width: '98%' }}>
                                                                            <div className='text-truncate text-muted pr-2' style={{ width: '16.5%' }}> {/* chargeDescription */}
                                                                                <FormattedMessage id="SalesProcess.Catering" />
                                                                            </div>
                                                                            <div className='pr-2 pl-0 text-truncate text-muted' style={{ width: '13.4%' }}> {/* unitPrice */}
                                                                                <FormattedMessage id="SalesProcess.UnitPrice" />
                                                                            </div>
                                                                            <div className='pr-2 pl-0 text-truncate text-muted' style={{ width: '13.4%' }}> {/* quantity */}
                                                                                <FormattedMessage id="SalesProcess.Pax" />
                                                                            </div>
                                                                            <div className='pr-2 pl-0 text-truncate text-muted' style={{ width: '13.4%' }}> {/* venue */}
                                                                                <FormattedMessage id="SalesProcess.Venue" />
                                                                            </div>
                                                                            <div className='pr-2 pl-0 text-truncate text-muted' style={{ width: '16.5%' }}> {/* startDate */}
                                                                                <FormattedMessage id="SalesProcess.Date" />
                                                                            </div>
                                                                            <div className='pr-2 pl-0 text-truncate text-muted' style={{ width: '13.4%' }}> {/* numberOfDays */}
                                                                                <FormattedMessage id="SalesProcess.NrDaysFb" />
                                                                            </div>
                                                                            <div className='pr-2 pl-0 text-truncate text-muted' style={{ width: '13.4%' }}> {/* amountAfterTax */}
                                                                                <FormattedMessage id="SalesProcess.SubTotal" />
                                                                            </div>
                                                                        </div>
                                                                        <div className='text-right pl-0' />
                                                                    </Col>
                                                                    {proposalData.fb.equipmentList.map((el, index) => {
                                                                        return (
                                                                            <Col className='col-12 d-flex align-items-center justify-content-between mb-2' key={index}>
                                                                                <div className='d-flex align-items-center justify-content-center' style={{ width: '98%' }}>
                                                                                    <div className='pr-2' style={{ width: '16.5%' }}> {/* chargeDescription */}
                                                                                        <Input
                                                                                            type="text"
                                                                                            name='chargeDescription'
                                                                                            id={`chargeDescription-${index}`}
                                                                                            value={el.chargeDescription || ''}
                                                                                            onChange={(e) => this.handleFBChange(e, index)}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='pr-2 pl-0' style={{ width: '13.4%' }}> {/* unitPrice */}
                                                                                        <Input
                                                                                            type="number"
                                                                                            name='unitPrice'
                                                                                            id={`unitPrice-${index}`}
                                                                                            value={el.unitPrice || 0}
                                                                                            onChange={(e) => this.handleFBChange(e, index)}
                                                                                            min={0}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='pr-2 pl-0' style={{ width: '13.4%' }}> {/* quantity */}
                                                                                        <Input
                                                                                            type="number"
                                                                                            name='quantity'
                                                                                            id={`fb-quantity-${index}`}
                                                                                            value={el.quantity || 0}
                                                                                            onChange={(e) => this.handleFBChange(e, index)}
                                                                                            min={0}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='pr-2 pl-0' style={{ width: '13.4%' }}> {/* venue */}
                                                                                        <Input
                                                                                            type="text"
                                                                                            name='venue'
                                                                                            id={`fb-venue-${index}`}
                                                                                            value={el.venue || ''}
                                                                                            onChange={(e) => this.handleFBChange(e, index)}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="pr-2 pl-0" style={{ width: '16.5%' }}> {/* startDate */}
                                                                                        <SingleDatePicker
                                                                                            name={`fb-startDate-${index}`}
                                                                                            id={`fb-startDate-${index}`}
                                                                                            isOutsideRange={this.isOutsideRange}
                                                                                            date={el?.startDate ? moment(el.startDate) : null}
                                                                                            focused={dateFocus === `fb-startDate-${index}`}
                                                                                            onFocusChange={({ focused }) => this.setState({ dateFocus: focused ? `fb-startDate-${index}` : null })}
                                                                                            small={true}
                                                                                            numberOfMonths={1}
                                                                                            showDefaultInputIcon={true}
                                                                                            onDateChange={date => this.setState({ proposalData: { ...proposalData, fb: { ...proposalData.fb, equipmentList:  proposalData.fb?.equipmentList.map((item, idx) => idx === index ? { ...item, startDate: date } : item)} }})}
                                                                                            initialVisibleMonth={this.getFirstValidMonth}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='pr-2 pl-0' style={{ width: '13.4%' }}> {/* numberOfDays */}
                                                                                        <Input
                                                                                            type="number"
                                                                                            name='numberOfDays'
                                                                                            id={`numberOfDays-${index}`}
                                                                                            value={el.numberOfDays || 0}
                                                                                            onChange={(e) => this.handleFBChange(e, index)}
                                                                                            min={0}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='pr-2 pl-0' style={{ width: '13.4%' }}> {/* amountAfterTax */}
                                                                                        <Input
                                                                                            type="number"
                                                                                            name='amountAfterTax'
                                                                                            id={`amountAfterTax-${index}`}
                                                                                            value={el.amountAfterTax || 0}
                                                                                            onChange={(e) => this.handleFBChange(e, index)}
                                                                                            min={0}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='text-right pl-0' style={{ width: '2%' }}>
                                                                                    <i className='fas fa-trash-alt text-danger pointer' onClick={() => this.handleFbDelete(index)} />
                                                                                </div>
                                                                            </Col>
                                                                        )
                                                                    })}
                                                                    <Col className='col-12 text-right my-3'> {/* fb.equipmentValue  */}
                                                                        <div className='pr-2' style={{ width: '98%' }}>
                                                                            <span className='text-muted pr-3'>
                                                                                <FormattedMessage id="SalesProcess.TotalFB" />
                                                                            </span>
                                                                            <span>
                                                                                <FormatAmountNumber value={proposalData?.fb?.equipmentValue || 0} currency={proposalCurrency} />
                                                                            </span>
                                                                        </div>
                                                                    </Col>
                                                                </>
                                                            :''}
                                                        </Row>
                                                    </Collapse>
                                                </div>

                                                <div> {/* Other Services (Equipment) */}
                                                    <Col className='col-12 pt-3'> 
                                                        <div className='d-flex align-items-center justify-content-between mb-2'>
                                                            <h5 className='m-0'><FormattedMessage id="SalesProcess.OtherServices" /></h5>
                                                            <div className='d-flex align-items-center'>
                                                                <Button className='btn btn-host btn-sm mr-2' onClick={() => this.handleAddEquipment()}>
                                                                    <i className="fas fa-plus" />
                                                                </Button>
                                                                <i className={`fas fa-chevron-up cursor-pointer rotate-icon ${!proposalData?.equipmentCollapsed ? 'rotate-up' : 'rotate-down'}`} onClick={() => this.handleToggleCollapse('equipment')} />
                                                            </div>
                                                        </div>
                                                        <hr className='mt-0' />
                                                    </Col>
                                                    <Collapse isOpen={!proposalData?.equipmentCollapsed}>
                                                        <Row className='px-3'>
                                                            {proposalData?.equipment?.equipmentList?.length > 0 ? 
                                                                <>
                                                                    <Col className='col-12 d-flex align-items-center mb-2'>
                                                                        <div className='d-flex align-items-center justify-content-bewtween' style={{ width: '98%' }}>
                                                                            <div className='text-truncate text-muted pr-2' style={{ width: '85%' }}>
                                                                                <FormattedMessage id="SalesProcess.Description" />
                                                                            </div>
                                                                            <div className='pl-0 text-truncate text-muted' style={{ width: '15%' }}>
                                                                                <FormattedMessage id="SalesProcess.SubTotal" />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ width: '2%' }} />
                                                                    </Col>
                                                                    {proposalData.equipment.equipmentList.map((el, index) => {
                                                                        return (
                                                                            <Col className='col-12 d-flex align-items-center mb-2' key={index}>
                                                                                <div className='d-flex align-items-center justify-content-bewtween' style={{ width: '98%' }}>
                                                                                    <div className='pr-2' style={{ width: '85%' }}> {/* chargeDescription */}
                                                                                        <Input
                                                                                            type="text"
                                                                                            name='chargeDescription'
                                                                                            id={`chargeDescription-${index}`}
                                                                                            value={el.chargeDescription || ''}
                                                                                            onChange={(e) => this.handleEquipmentChange(e, index)}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='pr-2 pl-0' style={{ width: '15%' }}> {/* amountAfterTax */}
                                                                                        <Input
                                                                                            type="number"
                                                                                            name='amountAfterTax'
                                                                                            id={`amountAfterTax-${index}`}
                                                                                            value={el.amountAfterTax || 0}
                                                                                            onChange={(e) => this.handleEquipmentChange(e, index)}
                                                                                            min={0}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='text-right pl-0' style={{ width: '2%' }}>
                                                                                    <i className='fas fa-trash-alt text-danger pointer' onClick={() => this.handleEquipmentDelete(index)} />
                                                                                </div>
                                                                            </Col>
                                                                        )
                                                                    })}
                                                                    <Col className='col-12  text-right my-3 text-muted'> {/* equipment.equipmentValue  */}
                                                                        <div className='pr-2' style={{ width: '98%' }}>
                                                                            <span className='text-muted pr-3'>
                                                                                <FormattedMessage id="SalesProcess.TotalOtherServices" />
                                                                            </span>
                                                                            <span>
                                                                                <FormatAmountNumber value={proposalData?.equipment?.equipmentValue || 0} currency={proposalCurrency} />
                                                                            </span>
                                                                        </div>
                                                                    </Col>
                                                                </>
                                                            :''}
                                                        </Row>
                                                    </Collapse>
                                                </div>

                                                {/* <div> Total Budget
                                                    <Col className='col-12 pt-3'>
                                                        <h5><FormattedMessage id="SalesProcess.TotalBudget" /></h5>
                                                        <hr className='mt-0' />
                                                    </Col>
                                                    <Row className='px-3'>
                                                        <Col className='col-12 mb-2 text-muted pr-2'> totalRoomsValue
                                                            <Label for="totalRoomsValueFinal">
                                                                <FormattedMessage id="SalesProcess.Lodges" />
                                                            </Label>
                                                            <Input
                                                                type="number"
                                                                name="totalRoomsValue"
                                                                id="totalRoomsValueFinal"
                                                                value={proposalData?.totalRoomsValue || 0}
                                                                onChange={(e) => this.handleInputChange(e)}
                                                            />
                                                        </Col>
                                                        <Col className='col-12 mb-2 text-muted pr-2'> fb.EquipmentValue
                                                            <Label for="fb-EquipmentValue">
                                                                <FormattedMessage id="SalesProcess.fb" />
                                                            </Label>
                                                            <Input
                                                                type="number"
                                                                name="fb-EquipmentValue"
                                                                id="fb-EquipmentValue"
                                                                value={proposalData?.fb?.equipmentValue || 0}
                                                                onChange={(e) => this.setState({ proposalData: { ...proposalData, fb: { ...proposalData.fb, EquipmentValue: e.target.value } }})}
                                                            />
                                                        </Col>
                                                        <Col className='col-12 mb-2 text-muted pr-2'> equipment.EquipmentValue
                                                            <Label for="eq-EquipmentValue">
                                                                <FormattedMessage id="SalesProcess.OtherServices" />
                                                            </Label>
                                                            <Input
                                                                type="number"
                                                                name="eq-EquipmentValue"
                                                                id="eq-EquipmentValue"
                                                                value={proposalData?.equipment?.equipmentValue || 0}
                                                                onChange={(e) => this.setState({ proposalData: { ...proposalData, equipment: { ...proposalData.equipment, EquipmentValue: e.target.value } }})}
                                                            />
                                                        </Col>
                                                        <Col className='col-12 mb-2 text-muted pr-2'> total (AmountAfterTax)
                                                            <Label for="amountAfterTax">
                                                                <FormattedMessage id="SalesProcess.Total" />
                                                            </Label>
                                                            <Input
                                                                type="number"
                                                                name="amountAfterTax"
                                                                id="amountAfterTax"
                                                                value={proposalData?.amountAfterTax || 0}
                                                                onChange={(e) => this.handleInputChange(e)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div> */}

                                                <div> {/* commissions */}
                                                    <Col className='col-12 pt-3'> 
                                                        <div className='d-flex align-items-center justify-content-between mb-2'>
                                                            <h5 className='m-0'><FormattedMessage id="SalesProcess.Commissions" /></h5>
                                                            
                                                            <div className='d-flex align-items-center'>
                                                                <CustomInput
                                                                    type="switch"
                                                                    id={'toggleCommissionVisibility'}
                                                                    name="active"
                                                                    className="ml-2"
                                                                    onChange={(e) => this.setState({ proposalData: { ...proposalData, showComissionTable: e.target.checked } }) }
                                                                    checked={proposalData?.showComissionTable ? true : false}
                                                                />
                                                                <i className={`fas fa-chevron-up`} style={{ visibility: 'hidden' }} />
                                                            </div>
                                                        </div>
                                                        <hr className='mt-0' />
                                                    </Col>
                                                    <Row className='px-3'>
                                                        <Col className='col-12 d-flex align-items-center mb-2'>
                                                            <div className='d-flex align-items-center justify-content-bewtween' style={{ width: '100%' }}>
                                                                <div className='text-truncate text-muted pr-2' style={{ width: '70%' }}>
                                                                    <FormattedMessage id="SalesProcess.Commission" />
                                                                </div>
                                                                <div className='pl-0 text-truncate text-muted' style={{ width: '15%' }}>
                                                                    <FormattedMessage id="SalesProcess.Value" />
                                                                </div>
                                                                <div className='pl-0 text-truncate text-muted d-flex justify-content-center' style={{ width: '15%' }}>
                                                                    <FormattedMessage id="SalesProcess.Visibility" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        {commissions.map((commissionType) => {
                                                            const commission = proposalData[commissionType];
                                                            return this.renderCommissionField(commissionType, `SalesProcess.${commissionType}`, commission || 0);
                                                        })}
                                                    </Row>
                                                </div>

                                                {proposalCurrency && proposalCurrency !== selectedHotelCurrecy ?
                                                    <div> {/* Exchange Rate */}
                                                        <Col className='col-12 pt-3'> 
                                                            <div className='d-flex align-items-center justify-content-between mb-2'>
                                                                <h5 className='m-0'><FormattedMessage id="SalesProcess.ExchangeRate" /></h5>
                                                            </div>
                                                            <hr className='mt-0' />
                                                        </Col>
                                                        <Row className='px-3'>
                                                            <Col className='col-12 d-flex align-items-center mb-2'>
                                                                <div className='text-truncate text-muted pr-2' >
                                                                    <FormattedMessage id="SalesProcess.Value" />
                                                                </div>
                                                            </Col>
                                                            <Col className='col-12 d-flex align-items-center mb-2'>
                                                                <Input
                                                                    type="number"
                                                                    name='exchangeRate'
                                                                    id='exchangeRate'
                                                                    value={exchangeRate?.length > 0 ? exchangeRate[0] : ''}
                                                                    onChange={(e) => this.setState({ exchangeRate: [e?.target?.value] || null })}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                :''}

                                                <div className='pb-3'> {/* Additional Notes */}
                                                    <Col className='col-12 pt-3'> 
                                                        <div className='d-flex align-items-center justify-content-between mb-2'>
                                                            <h5><FormattedMessage id="SalesProcess.AdditionalNotes" /></h5>
                                                            <div className='d-flex align-items-center'>
                                                                <Button className='btn btn-host btn-sm mr-2' onClick={() => this.addNewGenericNote('aditionalNotes')}>
                                                                    <i className="fas fa-plus" />
                                                                </Button>
                                                                <i className={`fas fa-chevron-up cursor-pointer rotate-icon ${!proposalData?.aditionalNotesCollapsed ? 'rotate-up' : 'rotate-down'}`} onClick={() => this.handleToggleCollapse('aditionalNotes')} />
                                                            </div>
                                                        </div>
                                                        <hr className='mt-0' />
                                                    </Col>
                                                    <Collapse isOpen={!proposalData?.aditionalNotesCollapsed}>
                                                        <Row className='px-3'>
                                                            {dynamicData?.aditionalNotes?.length > 0 ?
                                                                dynamicData.aditionalNotes.map((el, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <Col className='col-12 mb-2'>
                                                                                <div className='d-flex align-items-center justify-content-bewtween' style={{ width: '98%' }}>
                                                                                    <div className='text-truncate text-muted pr-2' style={{ width: '50%' }}>
                                                                                        <FormattedMessage id="SalesProcess.AdditionalNotes" /> {index + 1}
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ width: '2%' }} />
                                                                            </Col>
                                                                            <Col className='col-12 d-flex align-items-center mb-2'>
                                                                                <div className='d-flex align-items-center justify-content-bewtween' style={{ width: '98%' }}>
                                                                                    <Input
                                                                                        type="text"
                                                                                        name={`aditionalNotes-${index}`}
                                                                                        id={`aditionalNotes-${index}`}
                                                                                        value={el || ''}
                                                                                        onChange={(e) => this.handleGenericNoteChange(e, index, 'aditionalNotes')}
                                                                                    />
                                                                                </div>
                                                                                <div className='text-right' style={{ width: '2%' }}>
                                                                                    <i className='fas fa-trash-alt text-danger pointer' onClick={() => this.handleDeleteGenericNote(index, 'aditionalNotes')} />
                                                                                </div>
                                                                            </Col>
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                            :''}
                                                        </Row>
                                                    </Collapse>
                                                </div>
                                            </div>
                                        </Col>
                                    </>
                                :
                                    <Col className='col-12 d-flex align-items-center justify-content-center mt-4 text-muted' style={{ height: '40vh' }}>
                                        <FormattedMessage id="SalesProcess.NoAssociatedConfigTemplate" />
                                    </Col>
                                }
                            </>
                        :
                            <Col className='col-12 d-flex align-items-center justify-content-center mt-4 text-muted' style={{ height: '40vh' }}>
                                <FormattedMessage id="SalesProcess.NoValidProposals" />
                            </Col>
                        }
                    </Row>
                </BlockUi>
            </div>
        )
    }
}

export default injectIntl(SalesProcessDownloadProFormaModal)