import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Col, Row, Modal, ModalBody, Form, Label, Input, FormFeedback } from 'reactstrap';
import { getEgoiSenderStatus } from "../Base/ReferenceDataFunctions";
import { BlankCard } from "../Base/CommonUIComponents";
import { postAPI, putAPI } from "../Base/API";
import { handleNotification } from "../Base/Notification";
import CustomSelect from '../Base/CustomSelect';
import { FormattedMessage } from "react-intl";

export class SenderModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            selectedSender: this.props.selectedSender ? this.props.selectedSender : {},
            selectedSenderType: this.props.selectedSenderType ? this.props.selectedSenderType : {},
            isValidNumber: null
        };
    }

    componentDidMount() {
        if (this.state.selectedSenderType === 'cellphone' || this.state.selectedSenderType === 'phone') {
            this.setState({ selectedSender: { ...this.state.selectedSender, type: 'numeric' } })
        }
    }

    createEmailSender = () => {
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.detail) {
                    handleNotification({ errors: [{ code: data.title, message: data.detail }] });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.sender_id) {
                    handleNotification(data, <FormattedMessage id="SendersList.SenderCreated" />, <FormattedMessage id="generic.success" />);
                    this.props.toggleModal(null, 'email');
                    this.props.getEmailSendersList()
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/senders/email?name=${this.state.selectedSender.name}&email=${this.state.selectedSender.email}`);
    }

    createCellphoneSender = () => {
        this.setState({ block: true });

        const body = {
            type: this.state.selectedSender.type,
            cellphone: this.state.selectedSender.cellphone
        }

        if (this.state.previewDoc) {
            body.file = '"' + this.state.previewDoc.slice(this.state.previewDoc.indexOf('base64,') + 7) + '"'
        }

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.detail) {
                    handleNotification({ errors: [{ code: data.title, message: data.detail }] });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    return;
                }
                if (data.sender_id) {
                    handleNotification(data, <FormattedMessage id="SendersList.SenderCreated" />, <FormattedMessage id="generic.success" />);
                    this.props.toggleModal(null, 'cellphone');
                    this.props.getCellphoneSendersList()
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/senders/cellphone`, body);
    }

    //createPhoneSender = () => {
    //    this.setState({ block: true });

    //    const doc = this.state.previewDoc.slice(this.state.previewDoc.indexOf('base64,') + 7)

    //    postAPI(result => {
    //        const { data, error } = result;
    //        const errorMessage = [];

    //        if (error) {
    //            errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
    //            this.setState({ error: errorMessage, block: false });
    //            return;
    //        }
    //        if (data) {
    //            if (data && data.detail) {
    //                handleNotification({ errors: [{ code: data.title, message: data.detail }] });
    //                this.setState({ error: errorMessage, block: false });
    //                return;
    //            }
    //            if (data && data.sender_id) {
    //                handleNotification(data, <FormattedMessage id="SendersList.SenderCreated" />, <FormattedMessage id="generic.success" />);
    //                this.props.toggleModal(null, 'phone');
    //                this.props.getPhoneSendersList();
    //            }
    //        }
    //        this.setState({ error: errorMessage, block: false });
    //    }, `/api/gms/Marketing/v1/egoi/senders/phone?phone=${this.state.selectedSender.phone}`, '"' + doc + '"');
    //}

    createSender = (e) => {
        e.preventDefault();

        if (this.state.selectedSenderType === 'email') {
            this.createEmailSender()
        }
        else /*if (this.state.selectedSenderType === 'cellphone')*/ {
            this.createCellphoneSender()
        }
        //else 
        //this.createPhoneSender();
    }

    editSender = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.detail) {
                    handleNotification({ errors: [{ code: data.title, message: data.detail }] });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    return;
                }
                if (data.sender_id) {
                    handleNotification(data, <FormattedMessage id="SendersList.SenderUpdated" />, <FormattedMessage id="generic.success" />);

                    this.props.toggleModal(null, this.state.selectedSenderType);
                    this.props.getEmailSendersList();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/senders/email/${this.state.selectedSender.sender_id}?name=${this.state.selectedSender.name}`);
    }

    validateNumber = (value) => {
        const regex = /^(){0,1}(\d){1,3}-(\d){4,20}$/i;

        if (value && !regex.test(value)) {
            this.setState({ isValidNumber: true });
            return;
        }
        else {
            this.setState({ isValidNumber: false});
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            selectedSender: {
                ...this.state.selectedSender,
                [name]: value
            }
        });

        if (name === 'cellphone') {
            this.validateNumber(value);
        }
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
            selectedSender: {
                ...this.state.selectedSender,
                [name]: combo ? combo.value : null
            }
        })
    };

    addFile = () => {
        var file = document.querySelector('input[type=file]').files[0];

        if (file && file.size <= 1048576) { //1MB
            this.readFile(file);
        }
        else {
            handleNotification('', <FormattedMessage id="SendersList.PleaseSelectAFileLessThan" />, <FormattedMessage id="SendersList.SelectedFileIsTooBig" />);
        }
    }

    readFile = (file) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            this.setState({
                file: file,
                previewDoc: reader.result
            });

        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    render() {
        const { block, error, selectedSender, selectedSenderType } = this.state;

        return (
            <Modal isOpen={this.props.modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={this.props.toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <Form onSubmit={this.props.selectedSender && selectedSenderType === 'email' ? (e) => this.editSender(e) : (e) => this.createSender(e)}>
                            <Row className="mb-3">
                                <Col>
                                    <h5> {this.props.selectedSender ? <FormattedMessage id={`SendersList.Edit.${selectedSenderType}Sender`} /> : <FormattedMessage id={`SendersList.Create.${selectedSenderType}Sender`} />}</h5>
                                </Col>
                                {!this.props.selectedSender || selectedSenderType === 'email' ?
                                    <Col className="text-right">
                                        <Button className="btn-sm btn-host" type="submit" /*disabled={selectedSenderType === 'cellphone' && this.state.isValidNumber ? false : true}*/>
                                            <i className="fas fa-save" />
                                        </Button>
                                    </Col>
                                : ''}
                            </Row>

                            {selectedSenderType === 'email' ?
                                <div>
                                    <Row>
                                        <Label sm={2}><FormattedMessage id="SendersList.Name" /></Label>
                                        <Col sm={10}>
                                            <Input
                                                name="name"
                                                type="text"
                                                value={selectedSender && selectedSender.name ? selectedSender.name : ''}
                                                onChange={(e) => this.handleChange(e)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="my-2">
                                        <Label sm={2}> <FormattedMessage id="SendersList.Mail" /></Label>
                                        <Col sm={10}>
                                            <Input
                                                name="email"
                                                type="email"
                                                value={selectedSender && selectedSender.email ? selectedSender.email : ''}
                                                onChange={(e) => this.handleChange(e)}
                                                readOnly={this.props.selectedSender}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                : /*selectedSenderType === 'cellphone' ?*/
                                    <div>
                                        <Row>
                                            <Label sm={2}> <FormattedMessage id="SendersList.Type" /></Label>
                                            <Col sm={10}>
                                                <CustomSelect
                                                    isSearchable
                                                    placeholder={""}
                                                    options={this.props.typeOptions}
                                                    onChange={(e) => this.handleChangeSelect(e, "type")}
                                                    value={this.props.typeOptions.find(s => s.value === selectedSender.type)}
                                                    isDisabled={this.props.selectedSender ? true : false}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="my-2">
                                            <Label sm={2}> <FormattedMessage id="SendersList.Cellphone" /></Label>
                                            <Col sm={10}>
                                                <Input
                                                    name="cellphone"
                                                    type="text"
                                                    value={selectedSender && selectedSender.cellphone ? selectedSender.cellphone : ''}
                                                    onChange={(e) => this.handleChange(e)}
                                                    invalid={this.state.isValidNumber ? true : false}
                                                    placeholder={this.props.intl.formatMessage({ id: "SendersList.CellPhonePlaceholder" })}
                                                    required
                                                    disabled={this.props.selectedSender ? true : false}
                                                />
                                                <FormFeedback><FormattedMessage id="SendersList.InvalidNumber" /></FormFeedback>
                                            </Col>
                                        </Row>
                                        {!this.props.selectedSender && selectedSender.type === 'alpha_numeric' ?
                                            <Row>
                                                <Label sm={2}><FormattedMessage id="SendersList.Document" /> </Label>
                                                <Col sm={10}>
                                                    <input
                                                        id='doc'
                                                        required
                                                        type="file"
                                                        onChange={this.addFile}
                                                        accept="application/pdf"
                                                    />
                                                </Col>
                                            </Row>
                                        : ''}
                                     </div>
                                    }
                            {/*
                                <div>
                                    <Row className="my-2">
                                        <Label sm={3}> <FormattedMessage id="SendersList.Phone" /></Label>
                                        <Col sm={9}>
                                            <Input
                                                name="phone"
                                                type="text"
                                                value={selectedSender && selectedSender.phone ? selectedSender.phone : ''}
                                                onChange={(e) => this.handleChange(e)}
                                                invalid={this.state.isValidNumber ? true : false}
                                                placeholder={this.props.intl.formatMessage({ id: "SendersList.CellPhonePlaceholder" })}
                                                required
                                            />
                                            <FormFeedback><FormattedMessage id="SendersList.InvalidNumber" /></FormFeedback>
                                        </Col>
                                    </Row>
                                    {!this.props.selectedSender ?
                                        <Row>
                                            <Label sm={3}><FormattedMessage id="SendersList.Document" /> </Label>
                                            <Col sm={9}>
                                                <input
                                                    id='doc'
                                                    required
                                                    type="file"
                                                    onChange={this.addFile}
                                                    accept="application/pdf"
                                                />
                                            </Col>
                                        </Row>
                                        : ''}
                                </div>
                            */}

                            {this.props.selectedSender ?
                                <Row>
                                    <Label sm={2}> <FormattedMessage id="SendersList.Status" /></Label>
                                    <Col sm={10}>
                                        <CustomSelect
                                            isSearchable
                                            placeholder={""}
                                            value={getEgoiSenderStatus().find(s => s.value === selectedSender.status)}
                                            isDisabled
                                        />
                                    </Col>
                                </Row>
                                : ''}

                            {this.state.file && this.state.previewDoc ?
                                <Row className="mt-2">
                                    <Col className="d-flex justify-content-center">
                                        <embed
                                            type={this.state.file.type}
                                            src={this.state.previewDoc}
                                            style={{ minHeight: '700px', width: '100%' }}
                                            alt={this.state.file.name}
                                        />
                                    </Col>
                                </Row>
                            : ''}
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(SenderModal)
