import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Row, Label, Form, Input, FormGroup, Modal, ModalBody, Card } from 'reactstrap';
import { getAPI, postAPI } from "../../Base/API";
import { handleNotification } from '../../Base/Notification';
import { BlankCard } from "../../Base/CommonUIComponents";

export class Pipelines extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            pipelines: [],
            modal: false
        };
    }

    componentDidMount() {
        this.getPipelines();
    }

    getPipelines = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({ pipelines: data.response ? data.response : [] });
            }

            this.setState({ block: false });
        }, '/api/gms/Pipeline/v1');
    }

    savePipeline = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const body = {
            name: this.state.name,
            description: this.state.description
        }

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                    return;
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="Pipeliones.PipelioneSaved" />, <FormattedMessage id="generic.success" />);

                    const pipelines = [...this.state.pipelines];
                    pipelines.push(data.response[0]);

                    this.setState({ pipelines });
                }
            }

            this.setState({ block: false });
        }, '/api/gms/Pipeline/v1', JSON.stringify({ request: body }));
    }

    toggleModal = (modal) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }));
    }

    render() {
        const { block, error, pipelines, modal } = this.state;

        return (
            <BlankCard block={block} error={error} >
                <Row className="align-items-center mb-3">
                    <Col>
                        <h5> <i className="fas fa-rocket" /> <FormattedMessage id="Pipelines.Title" /> </h5>
                    </Col>
                    <Col className="text-right pr-4 mr-2">
                        <Button className="btn-host btn-sm" onClick={() => this.toggleModal('modal')}>
                            <i className="fas fa-plus" />
                        </Button>
                    </Col>
                </Row>

                {pipelines && pipelines.map((pipeline, key) =>
                    <Card className="border-0 p-3 shadow mb-3" key={key} >
                        <Row>
                            <Col> <h5>{pipeline.name} </h5></Col>
                            <Col sm={1} className="text-right">
                                <Button className="btn btn-host btn-sm" onClick={() => this.props.history.push({ pathname: "/PipelineStages/" + pipeline.id })}>
                                    <i className="far fa-edit"></i>
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col> {pipeline.description} </Col>
                        </Row>
                    </Card>
                )}


                <Modal isOpen={modal} fade={false} size={"lg"} style={{ minWidth: 0 }} >
                    <Button onClick={() => this.toggleModal('modal')} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    <ModalBody>
                        <BlankCard block={this.state.block} error={this.state.error}>
                            <Form onSubmit={this.savePipeline}>
                                <Row className="mb-3">
                                    <Col>
                                        <h5>New pipeline</h5>
                                    </Col>
                                    <Col className="text-right col-3">
                                        <Button className="btn-sm btn-host" type="submit">
                                            <i className="fas fa-save" />
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Label sm={4}> <FormattedMessage id="Pipelines.Name" /></Label>
                                            <Col sm={8} >
                                                <Input
                                                    type="text"
                                                    name="name"
                                                    required
                                                    onChange={this.handleChange}
                                                    maxLength={100}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Label sm={4}> <FormattedMessage id="Pipelines.Description" /> </Label>
                                            <Col sm={8}>
                                                <Input
                                                    type="textarea"
                                                    name="description"
                                                    required
                                                    onChange={this.handleChange}
                                                    maxLength={1000}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </BlankCard>
                    </ModalBody>
                </Modal>

            </BlankCard>
        );
    }
}
export default injectIntl(Pipelines)