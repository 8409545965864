import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Row, Col, Button, Input, PopoverBody } from 'reactstrap';
import { SalesProcessUsersCombo, getUsersRoles } from '../SalesProcessFunctions';
import CustomSelect from '../../Base/CustomSelect';
import CustomToolTip from '../../Base/CustomToolTip';


class SalesDealStep4 extends Component {

    addUser = () => {
        const profiles = this.props.deal.salesDealUser ? [...this.props.deal.salesDealUser] : [];

        profiles.push({});

        this.props.handleProfilesList('salesDealUser', profiles)

        this.setState({ associationModal: false, selectedProfile: null });
    }
    

    render() {
        const { usersList, deal, handleListElement, isLead } = this.props;

        return (
            <div>
                <Row className="mb-3 border-bottom pb-2 mt-4">
                    <Col>
                        <div className={`title-bold ${isLead ? "" : "required"}`}><b id="tooltippopover"><FormattedMessage id="SalesDeal.Users" /></b></div>
                    </Col>
                    {isLead ? '' :
                        <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="bottom" target={'tooltippopover'}>
                            <PopoverBody>
                                <FormattedMessage id="SalesDeal.RequiredResponsable" />
                            </PopoverBody>
                        </CustomToolTip>
                    }
                    <Col className="text-right">
                        <Button className="btn-host btn-sm" onClick={this.addUser}>
                            <i className="fas fa-plus " />
                        </Button>
                    </Col>
                </Row>

                <Row className="text-muted mb-3">
                    <Col sm={1}></Col>
                    <Col sm={4}><FormattedMessage id="generic.name" /></Col>
                    <Col><FormattedMessage id="SalesDeal.Role" /></Col>
                    <Col><FormattedMessage id="SalesDeal.Notes" /></Col>
                    <Col sm={1}></Col>
                </Row>

                {deal.salesDealUser?.map((user, key) =>
                    <Row className="align-items-center overbglight py-2" key={key}>
                        <Col className="text-center col-1">
                            <i className="fas fa-user"/>
                        </Col>
                        <Col sm={4}>
                            <SalesProcessUsersCombo
                                placeholder=""
                                value={user.userId}
                                onChangeFunc={(name, combo) => handleListElement('salesDealUser', key, 'userId', combo ? combo.value : null)}
                                isClearable
                                isSearchable
                                required
                            />
                        </Col>

                        <Col>
                        <CustomSelect
                            placeholder=""
                            options={getUsersRoles(this.props.intl)}
                            value={getUsersRoles(this.props.intl).find(t => t.value === user.role) || ''}
                            onChange={(combo) => handleListElement('salesDealUser', key, 'role', combo ? combo.value : null)}
                            isClearable
                            isSearchable
                            required
                        />
                        </Col>
                        <Col>
                            <Input
                                type="text"
                                name="notes"
                                placeholder=""
                                value={user.notes || ''}
                                onChange={(event) => handleListElement('salesDealUser', key, 'notes', event.target?.value)}
                                maxLength={500}
                            />
                        </Col>
                        <Col sm={1} className="text-right pr-4">
                            <i className="fas fa-trash-alt text-danger pointer" onClick={ _ => this.props.handleListElement('salesDealUser', key, null, null, true)} />
                        </Col>
                    </Row>
                )}
            </div>
        )
    }
}

export default injectIntl(SalesDealStep4);