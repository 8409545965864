import React, { Component } from 'react'
import { TabContent, TabPane } from 'reactstrap';
import SalesSource from './SalesSource';
import SalesPipeline from './SalesPipeline';
import SalesPipelineStage from './SalesPipelineStage';
import SalesDealList from './SalesDealList';
import { handleNotification } from '../Base/Notification';
import { deleteAPI, getAPI } from '../Base/API';
import SalesDealUsers from './SalesDealUsers';
import { FormattedMessage } from 'react-intl';
import SalesProcessStats from './SalesProcessStats';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from '../Base/ErrorAlert';
import SalesProcessSettings from './SalesProcessSettings';
import SalesProcessTasks from './SalesProcessTasks';
import SalesProcessResources from './SalesProcessResources';
import SalesProcessCustomization from './SalesProcessCustomization';
import SalesProcessExecutiveReport from './SalesProcessExecutiveReport';
import moment from 'moment-timezone';
import SalesLeads from './SalesLeads';
import SalesProcessGrid from './SalesProcessGrid/SalesProcessGrid';
import SalesDealDetail from './SalesDealDetail/SalesDealDetail';
import SalesProcessCityEvents from './SalesProcessCityEvents';
import SalesProcessSpaceManagement from './SalesProcessSpaceManagement';
import { getSalesProcessUsers } from './SalesProcessFunctions';
import SalesBudget from './SalesBudget';
import ShortSideBar from '../Base/ShortSideBar';
import { DefaultPage } from '../Base/CommonUIComponents';
import SalesInitiativeList from './SalesInitiativeList';
import SalesInitiativeDetail from './SalesInitiativeDetail';


export default class SalesProcess extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            activeTab: "SalesPipelineStage",
            dealListPrevious: false,
            salesPipelines: [],
            salesSources: [],
            deals: [],
            selectedDealId: null,
            selectedPipeline: null,
            blockPipelines: false,
            blockSources: false,
            salesUsers: this.props.salesUsers,
            dealList: [],
            taskId: null,
            hotelList: [],
            currentUser: this.props.currentUser,
            selectedInitiativeId: null,
            initiativeListPrevious: null
        };
    }

    componentDidMount(){
        this.getSalesPipelines();
        this.getHotels();

        this.goToSelectedHashMenu();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.hash !== this.props.location.hash && this.props.location.hash?.substring(1) !== this.state.activeTab) {
            this.goToSelectedHashMenu();
        }
    }

    getUsers = () => {
        getSalesProcessUsers(this.setSalesUsers);
    }

    setSalesUsers = () => {
        this.setState({ salesUsers: global.salesProcessUsers, block: false });
    }
    
    goToSelectedHashMenu = () => {
        const activeTab = window.location.hash.substring(1) || 'SalesPipelineStage';
        const params = new URLSearchParams(window.location.search);
        let selectedDealId = null, taskId = null, selectedInitiativeId = null;

        if (params) {
            selectedDealId = params.get('dealId');
            selectedInitiativeId = params.get('initiativeId');
            taskId = params.get('taskId'); 

            if (isNaN(selectedDealId)) {
                selectedDealId = null;
                params.delete('dealId');

                const qs = params.size > 0 ? `?${params.toString()}#${activeTab}` : '#SalesPipelineStage';

                this.props.history.replace(`/SalesProcess${qs}`);
            }
            
            if (isNaN(selectedInitiativeId)) {
                selectedInitiativeId = null;
                params.delete('initiativeId');

                const qs = params.size > 0 ? `?${params.toString()}#${activeTab}` : '#SalesPipelineStage';

                this.props.history.replace(`/SalesProcess${qs}`);
            }

            if (isNaN(taskId)) {
                taskId = null;
                params.delete('taskId');
                this.props.history.replace(`/SalesProcess${params.size > 0 ? `?${params.toString()}` : ''}#${activeTab}`);
            }
        }

        this.setState({ activeTab, selectedDealId, taskId, selectedInitiativeId });
    }

    getHotels = () => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                if (data.response) {
                    const hotelList = data.response.map(h => {
                        const hotel = { value: h.hotelId, label: h.name2, city: h.city };
                        return hotel;
                    });
                    this.setState({ hotelList });
                }
            }
        }, '/api/gms/Hotel/v1/hotel/list?onlyFromHotelGroup=true');
    }

    getSalesPipelines = () => {
        this.setState({ blockPipelines: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockPipelines: false }, this.getSalesSources);
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    const list = data.data?.map(el => ({
                        value: el.id,
                        label: el.name,
                        stages: el.salesPipelineStages.map(stage => ({
                            value: stage.id,
                            label: stage.name,
                            type: stage.type,
                            ...stage
                        })),
                        ...el
                    })) || [];

                    const uniqueStages = [
                        ...new Map(
                            list
                                .flatMap(el => el.stages)
                                .map(stage => [stage.id, { value: stage.id, label: stage.name, pipelineId: stage.salesPipelineId }])
                        ).values()
                    ];

                    const savedValue = localStorage.getItem("MainSalesPipeline")??'';
                    const selectedPipeline =  list.find(({value}) => value === parseInt(savedValue)) ?? list[0];
                    
                    this.setState({ salesPipelines: list, salesPipelinesStages: uniqueStages, blockPipelines: false }, () => {
                        this.getSalesSources();
                        if(selectedPipeline)
                            this.getDeals(selectedPipeline);
                    });
                    return;
                }
                else this.setState({ error: errorMessage, blockPipelines: false }, this.getSalesSources);
            }, `/api/gms/SalesProcess/v1/pipeline`)
        });
    }

    renderActivityHelperMsg = (msg) => {
        return (
            <div style={{ width: '200px' }}>
                <div className='d-flex align-items-center justify-content-between p-2'>
                    <div className='actionStatusIcon' style={{ background: "#dc3545" }}>
                        <i className="fas fa-angle-right" />
                    </div>
                    <FormattedMessage id="SalesProcess.MissedAction" />
                </div>
                <div className='d-flex align-items-center justify-content-between p-2'>
                    <div className='actionStatusIcon' style={{ background: "#0665ff" }}>
                        <i className="fas fa-angle-right" />
                    </div>
                    <FormattedMessage id="SalesProcess.OnGoingAction" />
                </div>
                <div className='d-flex align-items-center justify-content-between p-2'>
                    <div className='actionStatusIcon' style={{ background: "#28a745" }}>
                        <i className="fas fa-angle-right" />
                    </div>
                    <FormattedMessage id="SalesProcess.ScheduledAction" />
                </div>
                <div className='d-flex align-items-center justify-content-between p-2'>
                    <div className='actionStatusIcon' style={{ background: "#ffc107" }}>
                        <i className="fas fa-angle-right" />
                    </div>
                    <FormattedMessage id="SalesProcess.NoActions" />
                </div>
                <hr />
                <div>
                    {msg}
                </div>
            </div>
        );
    }

    deletePipeline = (id) => {
        this.setState({ blockPipelines: true }, () =>
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockPipelines: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        this.setState({ deleteModal: false, blockPipelines: false }, () =>
                            handleNotification(data)
                        );
                        return;
                    }
                    else {
                        this.setState({ deleteModal: false, blockPipelines: false }, () => {
                            handleNotification(data, <FormattedMessage id="SalesPipeline.DeletedSuccessfully" />, <FormattedMessage id="generic.success" />);
                            this.getSalesPipelines();
                        });
                        return;
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/pipeline/${id}`)
        );
    }

    getSalesSources = () => {
        this.setState({ blockSources: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockSources: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    const list = data.data?.map(el => ({ value: el.id, label: el.name })) || [];

                    const listTypes = [...new Set(data.data?.map(el => el.type))].map(el => ({ value: el, label: el }));

                    this.setState({ salesSources: list, salesSourcesTypes: listTypes, blockSources: false }, () => this.handleOffice365NewToken());
                    return;
                }
                else this.setState({ error: errorMessage, blockSources: false });
            }, `/api/gms/SalesProcess/v1/sources`);
        });
    }

    getDeals = (pipeline, filters) => {
        this.setState({ block: true, selectedPipeline: pipeline }, () => {
            localStorage.setItem("MainSalesPipeline", pipeline?.value);

            let qs = "?";

            if(filters){
                const { selectedStaff, startDate, endDate, salesSourceId, hotels, dealName } = filters;

                if(selectedStaff && selectedStaff.length > 0){
                    selectedStaff.forEach(staff => qs += `userId=${staff}&`);
                }
                if(startDate)
                    qs += `businessStartDate=${moment(startDate).format('YYYY-MM-DD')}&`;
                if(endDate)
                    qs += `businessEndDate=${moment(endDate).format('YYYY-MM-DD')}&`;
                if(hotels && hotels.length > 0){
                    hotels.forEach(hotel => qs += `hotelId=${hotel}&`);
                }
                if (salesSourceId)
                    qs += `sourceId=${salesSourceId}&`;
                if (dealName)
                    qs += `dealName=${dealName}&`;
            }

            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    
                    this.setState({ deals: data.data || [], block: false });
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/deals/pipeline/${pipeline.value}${qs}`);
        });
    }
    
    handleOffice365NewToken = () => {
        const search = new URLSearchParams(window.location.search);
        if(!search)
            return;

        const code = search.get("code");

        if(!code || !code.length)
            return;

        const { salesUsers } = this.state;
        const userId = salesUsers.find(user => user.isCurrentUser)?.id;

        if(!userId)
            return;
        
        this.setState({ block: true }, () =>
            getAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data && data.response && data.response[0]) {
                    search.delete("code");
                    this.setState({ block: false }, () => handleNotification(data, <FormattedMessage id="SalesProcess.SuccessfullyAuthenticated" />, <FormattedMessage id="generic.success" />));
                }
            }, `/api/gms/SalesProcess/v1/m365/${userId}/exchangecode?code=${code}`)
        );
    }

    updateDealStage = (deal) => {
        const { deals } = this.state;
        const index = deals?.findIndex(el => el.id === deal.id);

        if (index !== -1) {
            if (!deals[index].salesDealActions) deals[index].salesDealActions = [];

            deals[index].salesDealActions.unshift(deal.salesDealActions[0]);
            deals[index].salesPipelineStage = deal.salesPipelineStage;
            deals[index].salesPipelineStageId = deal.salesPipelineStageId;
            
            this.setState({ deals });
        }
    }

    toggleTab = (tab, newTab) => {
        if (newTab) {
            window.open(`/SalesProcess#${tab}`, '_blank');
        }
        else {
            this.setState({ activeTab: tab, selectedDealId: null, selectedInitiativeId: null, taskId: null, filters: null }, () => {
                this.props.history.push(`/SalesProcess#${tab}`)
                window.scrollTo(0, 0);
            });
        }
    }

    selectDealDetail = (id, dealListPrevious, newTab) => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('dealId', id);
        urlParams.set('prev', (dealListPrevious || urlParams.get('prev') || 'SalesDealList'));

        const tab = id ? 'SalesDealDetail' : this.state.dealListPrevious ? this.state.dealListPrevious : 'SalesDealList';

        if (newTab) {
            this.setState({ dealListPrevious }, _ => { window.open(`/SalesProcess?${urlParams.toString()}#${tab}`, '_blank') });
        } else {
            this.setState({ selectedDealId: id, dealListPrevious, activeTab: tab }, _ => { this.props.history.push(`/SalesProcess?${urlParams.toString()}#${tab}`) });
        }
    }

    returnToPreviousPage = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const prevPage = urlParams.get('prev') || 'SalesDealList';

        if (prevPage && !isNaN(parseInt(prevPage)) && window?.history) {
            window.history.back();
        }

        urlParams.delete('dealId');
        urlParams.delete('initiativeId');
        urlParams.delete('prev');

        this.setState({ selectedDealId: null, activeTab: prevPage }, _ => { this.props.history.push(`/SalesProcess${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}#${prevPage}`) });
    }

    selectInitiativeDetail = (id, initiativeListPrevious, newTab) => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('initiativeId', id);
        urlParams.set('prev', (initiativeListPrevious || urlParams.get('prev') || 'SalesDealList'));

        const tab = id ? 'SalesInitiativeDetail' : this.state.initiativeListPrevious ? this.state.initiativeListPrevious : 'SalesInitiativeList';
                

        if (newTab) {
            this.setState({ initiativeListPrevious }, _ => { window.open(`/SalesProcess?${urlParams.toString()}#${tab}`, '_blank') });
        } else {
            this.setState({ selectedInitiativeId: id, initiativeListPrevious, activeTab: tab }, _ => { this.props.history.push(`/SalesProcess?${urlParams.toString()}#${tab}`) });
        }
    }

    getDealList = (qs, cb) => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                cb(errorMessage);
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                
                this.setState({ dealList: data.data || [], totalDeals: data.total }, () => cb());
                return;
            }
            else cb(errorMessage);
        }, `/api/gms/SalesProcess/v1/deals` + qs);
    }

    render() {
        const { history, tabs } = this.props;
        const { block, error, activeTab, salesPipelines, deals, selectedDealId, salesSources, selectedPipeline, blockPipelines, salesUsers, dealList, salesSourcesTypes,
        taskId, salesPipelinesStages, hotelList, currentUser, selectedInitiativeId, totalDeals } = this.state;

        const flatTabs = tabs.flatMap(item => item.subMenus ? [...item.subMenus] : [item]);
        const showPage = flatTabs.some(tab => tab.name === activeTab)


        return (
            <div className="border-0 h-100 py-2" id='SalesProcess'>
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <div className='w-100 d-flex'>
                        {tabs.length > 0 &&
                            <ShortSideBar
                                tabs={tabs.filter(({ notMenu }) => !notMenu)}
                                activeTab={activeTab}
                                toggleTab={this.toggleTab}
                            />
                        }
                        <div style={{ width: !global.isMobile ? '96%' : '88%' }}>
                            <TabContent activeTab={showPage ? activeTab : 'default'} className='border-0'>
                                {!showPage ?
                                    <TabPane tabId="default" className='border-0 pt-0'>
                                        <DefaultPage text="SalesProcess.YouDontHavePermissionToViewThisPage" icon="fas fa-user-lock" />
                                    </TabPane>
                                :
                                    flatTabs.map((tab, key) =>
                                    <TabPane key={key} tabId={tab.name} className='border-0 pt-0'>
                                        {activeTab === tab.name || tab.wasOpened ?
                                            <>
                                                {tab.name === "SalesSource" ?
                                                    <SalesSource
                                                        salesPipelines={salesPipelines}
                                                        icon={tab.icon}
                                                        salesUsers={salesUsers}
                                                        history={history}
                                                    />
                                                : tab.name === "SalesPipeline" ?
                                                    <SalesPipeline
                                                        icon={tab.icon}
                                                        salesPipelines={salesPipelines}
                                                        getSalesPipelines={this.getSalesPipelines}
                                                        deletePipeline={this.deletePipeline}
                                                        blockPipelines={blockPipelines}
                                                    />
                                                : tab.name === "SalesPipelineStage" ?
                                                    <SalesPipelineStage
                                                        icon={tab.icon}
                                                        salesUsers={salesUsers}
                                                        salesPipelines={salesPipelines}
                                                        salesSources={salesSources}
                                                        deals={deals}
                                                        selectDealDetail={this.selectDealDetail}
                                                        getDeals={this.getDeals}
                                                        selectedPipeline={selectedPipeline}
                                                        blockPipelines={this.state.blockPipelines}
                                                        updateDealStage={this.updateDealStage}
                                                        history={this.props.history}
                                                        currentUser={currentUser}
                                                        hotelList={hotelList}
                                                    />
                                                : tab.name === "SalesDealList" ?
                                                    <SalesDealList
                                                        selectDealDetail={this.selectDealDetail}
                                                        icon={tab.icon}
                                                        salesUsers={salesUsers}
                                                        salesPipelines={salesPipelines}
                                                        salesSources={salesSources}
                                                        dealList={dealList}
                                                        getDealList={this.getDealList}
                                                        salesSourcesTypes={salesSourcesTypes}
                                                        history={this.props.history}
                                                        salesPipelinesStages={salesPipelinesStages}
                                                        currentUser={currentUser}
                                                        hotelList={hotelList}
                                                        renderActivityHelperMsg={this.renderActivityHelperMsg}
                                                        totalDeals={totalDeals}
                                                    />
                                                : tab.name === "SalesDealUsers" ?
                                                    <SalesDealUsers
                                                        icon={tab.icon}
                                                        salesUsers={salesUsers}
                                                        getUsers={this.getUsers}
                                                    />
                                                : tab.name === "SalesDealDetail" ?
                                                    <SalesDealDetail
                                                        salesUsers={salesUsers}
                                                        selectedDealId={selectedDealId}
                                                        salesSources={salesSources}
                                                        tempDeal={deals?.find(({id}) => id === selectedDealId)}
                                                        selectDealDetail={this.selectDealDetail}
                                                        selectInitiativeDetail={this.selectInitiativeDetail}
                                                        salesPipelines={salesPipelines}
                                                        selectedPipeline={selectedPipeline}
                                                        dealList={dealList}
                                                        salesSourcesTypes={salesSourcesTypes}
                                                        getDealList={this.getDealList}
                                                        currentUser={currentUser}
                                                        returnToPreviousPage={this.returnToPreviousPage}
                                                        hotelList={hotelList}
                                                        renderActivityHelperMsg={this.renderActivityHelperMsg}
                                                        totalDeals={totalDeals}
                                                    />
                                                : tab.name === "SalesProcessStats" ?
                                                    <SalesProcessStats
                                                        icon={tab.icon}
                                                        salesUsers={salesUsers}
                                                        salesSources={salesSources}
                                                    />
                                                : tab.name === "SalesProcessSettings" ?
                                                    <SalesProcessSettings
                                                        icon={tab.icon}
                                                    />
                                                : tab.name === "SalesProcessTasks" ?
                                                    <SalesProcessTasks
                                                        salesUsers={salesUsers}
                                                        icon={tab.icon}
                                                        taskId={taskId}
                                                        salesPipelines={salesPipelines}
                                                        selectDealDetail={this.selectDealDetail}
                                                        currentUser={currentUser}
                                                        selectInitiativeDetail={this.selectInitiativeDetail}
                                                    />
                                                : tab.name === "SalesProcessResources" ?
                                                    <SalesProcessResources
                                                        icon={tab.icon}
                                                        salesUsers={salesUsers}
                                                        selectDealDetail={this.selectDealDetail}
                                                    />
                                                : tab.name === "SalesProcessCustomization" ?
                                                    <SalesProcessCustomization
                                                        icon={tab.icon}
                                                    />
                                                : tab.name === "SalesProcessExecutiveReport" ?
                                                    <SalesProcessExecutiveReport
                                                        icon={tab.icon}
                                                        selectDealDetail={this.selectDealDetail}
                                                        salesUsers={salesUsers}
                                                        resourcesIcon={tabs.find(({name}) => name === "SalesProcessGrid")?.icon}
                                                        dealListIcon={tabs.find(({name}) => name === "SalesDealList").icon}
                                                        salesPipelines={salesPipelines}
                                                        salesSources={salesSources}
                                                        dealList={dealList}
                                                        getDealList={this.getDealList}
                                                        salesSourcesTypes={salesSourcesTypes}
                                                        hotelList={hotelList}
                                                        renderActivityHelperMsg={this.renderActivityHelperMsg}
                                                        totalDeals={totalDeals}
                                                    />
                                                : tab.name === "SalesLeads" ?
                                                    <SalesLeads
                                                        icon={tab.icon}
                                                        salesUsers={salesUsers}
                                                        salesSources={salesSources}
                                                        selectDealDetail={this.selectDealDetail}
                                                        currentUser={currentUser}
                                                        hotelList={hotelList}
                                                        renderActivityHelperMsg={this.renderActivityHelperMsg}
                                                    />
                                                : tab.name === "SalesProcessGrid" ?
                                                    <SalesProcessGrid
                                                        icon={tab.icon}
                                                        salesUsers={salesUsers}
                                                        history={this.props.history}
                                                        selectDealDetail={this.selectDealDetail}
                                                        currentUser={currentUser}
                                                    />
                                                : tab.name === "SalesBudget" ?
                                                    <SalesBudget
                                                        icon={tab.icon}
                                                        salesUsers={salesUsers}
                                                        history={this.props.history}
                                                        hotelList={hotelList}
                                                        currentUser={currentUser}
                                                    />
                                                : tab.name === "SalesProcessCityEvents" ?
                                                    <SalesProcessCityEvents
                                                        icon={tab.icon}
                                                        salesUsers={salesUsers}
                                                        history={this.props.history}
                                                        hotelList={hotelList}
                                                        currentUser={currentUser}
                                                    />
                                                : tab.name === "SalesProcessSpaceManagement" ?
                                                    <SalesProcessSpaceManagement
                                                        icon={tab.icon}
                                                        salesUsers={salesUsers}
                                                        history={this.props.history}
                                                        hotelList={hotelList}
                                                        currentUser={currentUser}
                                                    />
                                                : tab.name === "SalesInitiativeList" ?
                                                    <SalesInitiativeList
                                                        icon={tab.icon}
                                                        salesUsers={salesUsers}
                                                        history={this.props.history}
                                                        selectInitiativeDetail={this.selectInitiativeDetail}
                                                        hotelList={hotelList}
                                                        currentUser={currentUser}
                                                        renderActivityHelperMsg={this.renderActivityHelperMsg}
                                                    />
                                                : tab.name === "SalesInitiativeDetail" ?
                                                    <SalesInitiativeDetail
                                                        salesUsers={salesUsers}
                                                        selectedInitiativeId={selectedInitiativeId}
                                                        salesSources={salesSources}
                                                        selectInitiativeDetail={this.selectInitiativeDetail}
                                                        currentUser={currentUser}
                                                        history={this.props.history}
                                                        salesSourcesTypes={salesSourcesTypes}
                                                        salesPipelines={salesPipelines}
                                                        getDealList={this.getDealList}
                                                        dealList={dealList}
                                                        selectDealDetail={this.selectDealDetail}
                                                        returnToPreviousPage={this.returnToPreviousPage}
                                                        hotelList={hotelList}
                                                        renderActivityHelperMsg={this.renderActivityHelperMsg}
                                                        totalDeals={totalDeals}
                                                    />
                                                : ''}
                                            </>
                                            :''}
                                    </TabPane>
                                )}
                            </TabContent>
                        </div>
                    </div>
                </BlockUi>
            </div>
        )
    }
}
