import React, { Component } from 'react';
import { Row, Col, Button, UncontrolledTooltip, Modal, Label } from 'reactstrap';
import { handleNotification } from '../Base/Notification';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StyledCard } from "../Base/CommonUIComponents";
import { getAPI, postAPI } from '../Base/API';
import { CustomTable } from '../Base/CustomTable';
import { errorHandler } from '../Marketing/CommunicationJourney/CommonFunctions';

class ResendReservation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            modal: false,
            importedCsv: [],
            csvmodal: false,
        }
    }

    componentDidMount() {
        this.getHotels();
    }

    importCSV = (CSV) => {
        const { hotels } = this.state;
        var reader = new FileReader();
        var saveButton = false;
        reader.readAsText(CSV);

        reader.onload = (e) => {
            var allTextLines = e.target.result.split(/\r\n|\n/);
            var lines = allTextLines.map(data => data.split(/,|;/));
            var list = [];
            var listApi = [];
            lines.forEach((line, idx) => {
                let i = 0;
                var jsObj = { Reason: [] };
                var valid = true;
                while (i < line.length) {
                    if (idx !== 0) {
                        jsObj[lines[0][i]] = line[i];
                    }
                    i++;
                }
                if (jsObj["HotelId"] == null) {
                    valid = false
                    jsObj.Reason.push(<FormattedMessage id="ResendReservation.InvalidNullHotel" />);
                }
                if (hotels.filter(x => x.value == jsObj["HotelId"]).length == 0) {
                    valid = false
                    jsObj.Reason.push(<FormattedMessage id="ResendReservation.InvalidObjHotel" />);
                }
                if (jsObj["ReservationId"] == null) {
                    valid = false
                    jsObj.Reason.push(<FormattedMessage id="ResendReservation.InvalidObjRes" />);
                }

                jsObj["ValidReservation"] = valid;

                if (!saveButton && valid) {
                    saveButton = true;
                }

                if (idx !== 0 && line[0]) {
                    if (valid) {
                        var newObj = { HotelId: jsObj["HotelId"], ReservationId: jsObj["ReservationId"] }
                        listApi.push(newObj);
                    }
                    list.push(jsObj);
                }
            })
            this.setState({ block: false, importedCsv: list, importedCsvApi: listApi, saveButton }, () => this.toggleCsvModal())
        }
    }

    importCsvApi = () => {
        this.setState({ block: true }, () =>
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                var updates = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    else {
                        updates = data.response.map(x => ({ HotelId: x.hotelId, ReservationId: x.reservationId, Updated: x.updated, Reason: x.updated ? "" : <FormattedMessage id="ResendReservation.NOkResendInfo" /> }));
                        handleNotification(data, <FormattedMessage id="ResendReservation.OkResend" values={{ reservation: data.response.filter(x => x.updated).length }} />, <FormattedMessage id="generic.success" />);
                    }
                }
                this.setState({ error: errorMessage, block: false, csvmodal: false, updates, saveButton: false });
            }, `/api/gms/Reservation/v1/forceUpdateReservation`, this.state.importedCsvApi));
    }

    handleFiles = (event) => {
        var files = event.target.files;

        if (window.FileReader && files[0].size < 2097152) {
            this.setState({ block: true }, this.importCSV(files[0]));
        }
        else {
            errorHandler(<FormattedMessage id="ResendReservation.FileReader" />)
        }
    };

    fileChanged = (e) => {
        e.target.value = null;
    };

    importCSVClicked = () => {
        document.getElementById("resendReservationFileSelector").click();
    }

    getCSVStatus = (cell, row, idx, resend) => {
        return (
            <div>
                {!cell && !resend &&
                    <UncontrolledTooltip target={`Tooltip${idx}`} placement="left" >
                        <div>
                            <b><FormattedMessage id='Profile.Missing' /></b>
                            {row.Reason.map((miss, key) => {
                                return (
                                    <div key={key}>{miss}</div>
                                )
                            }
                            )}
                        </div>
                    </UncontrolledTooltip>
                }
                <span id={`Tooltip${idx}`}>
                    <i style={{ color: cell ? '#76EFAC' : '#EE6A5F' }} className={`fas fa-${cell ? 'check' : 'times'}`}></i>
                </span>
            </div>
        )
    }

    getHotels = () => {
        this.setState({ block: true }, () =>

            getAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    if (data.response) {
                        const hotels = data.response.map(h => ({ value: h.hotelId }));

                        this.setState({ block: false, hotels });

                        return;
                    }
                }
            }, '/api/gms/Hotel/v1/hotel/list'))
    }

    toggleCsvModal = () => {
        this.setState({ csvmodal: !this.state.csvmodal });
    }

    render() {
        const { error, block, csvmodal, updates, saveButton } = this.state;
        const columnsCSV = [
            {
                dataField: 'ValidReservation',
                text: this.props.intl.formatMessage({ id: "ResendReservation.Valid" }),
                sort: true,
                formatter: (cell, row, idx) => this.getCSVStatus(cell, row, idx)
            },
            {
                dataField: 'HotelId',
                text: this.props.intl.formatMessage({ id: "ResendReservation.HotelId" }),
                sort: true
            },
            {
                dataField: 'ReservationId',
                text: this.props.intl.formatMessage({ id: "ResendReservation.ReservationId" }),
                sort: true
            }
        ]

        const columnsResend = [
            {
                dataField: 'Updated',
                text: this.props.intl.formatMessage({ id: "ResendReservation.Updated" }),
                sort: true,
                formatter: (cell, row, idx) => this.getCSVStatus(cell, row, idx, true)
            },
            {
                dataField: 'HotelId',
                text: this.props.intl.formatMessage({ id: "ResendReservation.HotelId" }),
                sort: true
            },
            {
                dataField: 'ReservationId',
                text: this.props.intl.formatMessage({ id: "ResendReservation.ReservationId" }),
                sort: true
            },
            {
                dataField: 'Reason',
                text: this.props.intl.formatMessage({ id: "ResendReservation.Reason" }),
                sort: true
            }
        ]

        const columnsExample = [
            {
                dataField: 'HotelId',
                text: this.props.intl.formatMessage({ id: "ResendReservation.HotelId" }),
                sort: true
            },
            {
                dataField: 'ReservationId',
                text: this.props.intl.formatMessage({ id: "ResendReservation.ReservationId" }),
                sort: true
            }
        ]
        return (
            <StyledCard block={block} error={error}>
                <Row>
                    <Col>
                        <h5>
                            <i class="fas fa-share-square"></i>
                            <FormattedMessage id="NavMenu.ResendReservation" />
                        </h5>
                    </Col>
                </Row>
                <Row>
                    <Col className='col-3 py-4'>
                        <Label className='px-0'> <FormattedMessage id="Profile.ImportFile" /></Label>
                        <div className="custom-file">
                            <input
                                type="file"
                                className="custom-file-input"
                                id="resendReservationFileSelector"
                                onChange={(e) => this.handleFiles(e)}
                                accept=".csv"
                                onClick={(e) => this.fileChanged(e)}
                            />
                            <label className="custom-file-label" for="customFile">
                                <FormattedMessage id="generic.ChooseFile" />
                            </label>
                        </div>
                    </Col>
                    {saveButton ?
                        <Col className="text-right mt-5">
                            <Button className="btn-sm btn-host mr-2" onClick={this.importCsvApi}>
                                <i className="fas fa-save" />
                            </Button>
                        </Col>
                        : ''}
                </Row>
                <Row>
                    <Col>
                        {updates ?
                            <CustomTable
                                data={updates}
                                columns={columnsResend}
                                shadow={false}
                                sizePerPage={50}
                                showTotal={true}
                            />
                            :
                            this.state.importedCsv.length > 0 ?
                                <CustomTable
                                    data={this.state.importedCsv}
                                    columns={columnsCSV}
                                    shadow={false}
                                    sizePerPage={20}
                                    hasPagination={this.state.importedCsv && this.state.importedCsv.length > 20 ? true : false}
                                    showTotal={true}
                                />
                                :
                                <CustomTable
                                    data={''}
                                    columns={columnsExample}
                                    shadow={false}
                                    sizePerPage={50}
                                    showTotal={true}
                                />
                        }
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}

export default injectIntl(ResendReservation);