import React, { Component } from 'react';
import { Button, Col, UncontrolledCollapse, Modal, ModalBody, Row, Badge, Card } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BlankCard, FormatAmountNumber, StyledModal } from '../../../Base/CommonUIComponents';
import { getAPI } from '../../../Base/API';
import moment from 'moment';
import { CSVLink } from 'react-csv';

class CreditBalance extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            totalBalance: null
        };
    }

    componentDidMount() {
        this.getCreditBalance();
    }

    getCreditBalance = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {                
                var balanceByHotel = this.props.groupByHotel(data.response);

                var totalBalance = 0;
                var balanceByCurrency = [];

                data.response.forEach(balance => {
                    totalBalance += balance.balance;

                    const index = balanceByCurrency.findIndex(bbc => bbc.currency === balance.hotelCurrency);

                    if (index !== -1) {
                        balanceByCurrency[index].balance += balance.balance;
                    }
                    else {
                        balanceByCurrency.push({ currency: balance.hotelCurrency, balance: balance.balance });
                    }
                })

                this.setState({ totalBalance, balanceByHotel, balanceByCurrency, block: false });
            }
            else this.setState({ block: false });
        }, `/api/gms/Profile/v1/profile/${this.props.profileId}/balance`);
    }

    getCsvData = () => {
        const { balanceByHotel } = this.state;
        const titles = ['Hotel Id', 'Hotel Name', 'Profile Id', 'First Name', 'Last Name', 'PMS Timestamp', 'Balance', 'Currency'];
        const csvData = [titles];

        if (balanceByHotel) {
            Object.keys(balanceByHotel).forEach((hotel) => {
                const profiles = balanceByHotel[hotel];

                profiles.forEach(profile => {
                    const obj = [profile.hotelId, hotel, profile.profileId, profile.firstName,
                    profile.lastName, profile.pmsTimestamp, profile.balance, profile.hotelCurrency];

                    csvData.push(obj);
                })
            })
        }

        return csvData;
    }
    
    render() {
        const { isOpen, toggleModal } = this.props;
        const { block, error, totalBalance, balanceByHotel, balanceByCurrency } = this.state;
        console.log(balanceByCurrency)
        return (
            <StyledModal toggleModal={toggleModal} modal={isOpen} size="xl">
                <BlankCard block={block} error={error}>
                    <Row className="mb-3">
                        <Col>
                            <h5><i className="fas fa-donate mr-2" /><FormattedMessage id="ProfileCredit.CreditBalance" /></h5>
                        </Col>
                        <Col className="text-right">
                            <CSVLink className="btn btn-sm btn-host mr-2" data={this.getCsvData()} filename={`CreditBalance-${this.props.profileId}.csv`}>
                                <i className="fas fa-file-excel" />
                            </CSVLink>
                        </Col>
                    </Row>

                    <div className="d-flex my-4 justify-content-between px-2">
                        {balanceByCurrency?.map((el, idx) =>
                            <span key={idx} className={idx === balanceByCurrency.length -1 ? "text-right" : ''}>
                                <span className="title-sm">Total {el.currency}</span>
                                <h5><FormatAmountNumber value={el.balance} currency={el.currency} /></h5>
                            </span>
                        )}
                    </div>

                    <div className='verticalScroll' style={{ maxHeight: '55vh' }}>
                        {balanceByHotel && Object.keys(balanceByHotel).length > 0 ?
                            Object.keys(balanceByHotel).map((hotel, idx) => {
                                const total = balanceByHotel[hotel].reduce((acc, cur) => acc + cur.balance, 0);

                                return <Card key={idx} className="mb-3 shadow py-3" body>
                                    <Row id={`toggleHotel${idx}`}>
                                        <Col> <i className="icon-icon-hotel" /> <b>{hotel}</b> </Col>
                                        <Col className="col-4 text-right">
                                            <b className="mr-3">
                                                <FormatAmountNumber value={total} currency={balanceByHotel[hotel][0].hotelCurrency} />
                                            </b>
                                            <i className="fas fa-chevron-down ml-4" />
                                        </Col>
                                    </Row>

                                    <UncontrolledCollapse toggler={`#toggleHotel${idx}`} className="pt-3" defaultOpen={true}>
                                        {balanceByHotel[hotel].map((balance, i) =>
                                            <Row key={i} className="overbglight py-2 mx-2 border-top">
                                                <Col> {balance.firstName} {balance.lastName} </Col>
                                                <Col> {balance.pmsTimestamp ? moment(balance.pmsTimestamp).format("YYYY-MM-DD HH:mm") : ''} </Col>
                                                <Col className="text-right pr-5 col-3" style={{ paddingRight: '3.5rem' }}>
                                                    <FormatAmountNumber value={balance.balance} currency={balance.hotelCurrency} />
                                                </Col>
                                            </Row>
                                        )}
                                    </UncontrolledCollapse>
                                </Card>
                            })
                        : ''}
                    </div>
                </BlankCard>
            </StyledModal>
        );
    }
}

export default injectIntl(CreditBalance);