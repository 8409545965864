import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Modal, ModalBody } from 'reactstrap';
import { handleNotification } from '../Base/Notification';
import { BlankCard } from '../Base/CommonUIComponents';
import CustomSelect from "../Base/CustomSelect";
import { putAPI } from '../Base/API';

class RightToBeForgottenModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
        };
    }

    forgetProfile = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="RightToBeForgottenModal.ForgottenProfile" />, <FormattedMessage id="generic.success" />);

                    this.props.getProfile();
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/profile/${this.props.profileId}/rtbf`, this.state.reason);
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    };


    render() {
        const { modal, toggleModal } = this.props;

        const options = [
            { value: 'ForgetfulnessRequest', label: <FormattedMessage id="RightToBeForgottenModal.ForgetfulnessRequest" /> },
            { value: 'Death', label: <FormattedMessage id="RightToBeForgottenModal.Death" /> },
            { value: 'Other', label: <FormattedMessage id="RightToBeForgottenModal.Other" /> },
        ]

        return (
            <Modal isOpen={modal} fade={false} size={"md"} style={{ minWidth: 0 }} >
                <Button onClick={toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={this.state.block} error={this.state.error}>
                        <Form onSubmit={this.forgetProfile}>
                            <Row className="mb-3">
                                <Col>
                                    <h5><FormattedMessage id="RightToBeForgottenModal.Title" /></h5>
                                </Col>
                                <Col className="text-right col-3">
                                    <Button className="btn-sm btn-host" type="submit">
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}> <FormattedMessage id="RightToBeForgottenModal.Reason" /></Label>
                                        <Col sm={8} >
                                            <CustomSelect
                                                options={options}
                                                required
                                                isClearable
                                                isSearchable
                                                placeholder={<FormattedMessage id="RightToBeForgottenModal.Reason" />}
                                                onChange={(e) => this.handleChangeSelect(e, 'reason')}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(RightToBeForgottenModal)