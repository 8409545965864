import React, { Component } from "react";
import { FormattedMessage, injectIntl, FormattedHTMLMessage } from "react-intl";
import { BlankCard, CommonHelper, StyledModal } from "../../../../Base/CommonUIComponents";
import { Button, Col, Row, Input, FormGroup, Form, Label, CustomInput } from "reactstrap";
import { getAlertType, getFrequencyOptions } from "../../SendGridFunctions";
import { handleNotification } from "../../../../Base/Notification";
import CustomSelect from "../../../../Base/CustomSelect";
import { patchAPI, postAPI } from "../../../../Base/API";

class AlertDetails extends Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            alert: this.props.selectedAlert || {}
        }
    }

    createAlert = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="SendGridAlerts.AlertSavedSuccessfully" />, <FormattedMessage id="generic.success" />);

                    this.props.getAlerts();
                    this.props.toggleModal();
                }
            }
            else this.setState({ block: false });
        }, '/api/gms/SendGrid/v1/alert', JSON.stringify({ ...this.state.alert }));
    }

    editAlert = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        patchAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="SendGridAlerts.AlertSavedSuccessfully" />, <FormattedMessage id="generic.success" />);

                    this.props.getAlerts();
                    this.props.toggleModal();
                }
            }
            else this.setState({ block: false });
        }, `/api/gms/SendGrid/v1/alert/${this.state.alert.id}`, { ...this.state.alert });
    }


    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState(prevState => ({
            alert: {
                ...prevState.alert,
                [name]: value
            }
        }));
    }

    handleChangeCombo = (name, combo) => {
        this.setState(prevState => ({
            alert: {
                ...prevState.alert,
                [name]: combo ? combo.value : null
            }
        }));
    }
    

    render() {
        const { modal, toggleModal } = this.props;
        const { block, error, alert } = this.state;

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="lg">
                <BlankCard block={block} error={error}>
                    <Form onSubmit={alert.id ? this.editAlert : this.createAlert}>
                        <Row className="align-items-center mb-3">
                            <Col>
                                <h5>{alert.id ? <FormattedMessage id="SendGridAlerts.EditAlert" /> : <FormattedMessage id="SendGridAlerts.CreateAlert" />} </h5>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host" type="submit">
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>

                        <FormGroup row>
                            <Col sm={6}>
                                <Row>
                                    <Col>
                                        <Label><FormattedMessage id="SendGridAlerts.Type" /></Label >
                                    </Col>
                                    <Col>
                                        <CommonHelper help={<FormattedHTMLMessage id="SendGridAlerts.TypeHelp" />} id={'SgAlertType'} placement="bottom" />
                                    </Col>
                                </Row>
                                <CustomSelect
                                    options={getAlertType(this.props.intl)}
                                    placeholder=""
                                    value={alert.type ? getAlertType(this.props.intl).find(t => t.value === alert.type) : ''}
                                    onChange={(combo) => this.handleChangeCombo('type', combo)}
                                    isClearable isSearchable
                                    required
                                    isDisabled={alert.id}
                                />
                            </Col>

                            {alert.type ?
                                alert.type === 'stats_notification' ?
                                    <Col sm={6}>
                                        <Label><FormattedMessage id="SendGridAlerts.Frequency" /></Label >
                                        <CustomSelect
                                            options={getFrequencyOptions(this.props.intl)}
                                            placeholder=""
                                            value={alert.frequency ? getFrequencyOptions(this.props.intl).find(t => t.value === alert.frequency) : ''}
                                            onChange={(combo) => this.handleChangeCombo('frequency', combo)}
                                            isClearable isSearchable
                                            required
                                        />
                                    </Col>
                                    :
                                    <Col sm={6}>
                                        <Label><FormattedMessage id="SendGridAlerts.Percentage" /></Label >
                                        <CustomInput
                                            className="p-0"
                                            type="range"
                                            name="percentage"
                                            min="1"
                                            max="100"
                                            step="1"
                                            value={alert.percentage || ''}
                                            onChange={(e) => this.handleChange(e)}
                                            required
                                        />
                                        <div className="text-center text-muted font_size_xxs ml-1">{alert.percentage || '-'}%</div>
                                    </Col>
                                : ''}
                        </FormGroup>
                        <FormGroup row className="py-3">
                            <Col>
                                <Row>
                                    <Col>
                                        <Label><FormattedMessage id="SendGridAlerts.Recipients" /></Label >
                                    </Col>
                                    <Col>
                                        <CommonHelper help={<FormattedMessage id="SendGridAlerts.RecipientsHelp" />} id={'SgAlertRecipient'} />
                                    </Col>
                                </Row>
                                <Input
                                    type="textarea"
                                    name="email_to"
                                    value={alert.email_to || ''}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Col>
                        </FormGroup>

                    </Form>
                </BlankCard>
            </StyledModal>
        );
    }
}

export default injectIntl(AlertDetails);