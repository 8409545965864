import React, { Component } from 'react';
import moment from 'moment';
import { Button, Row, Col } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import DashboardCard from "../../Base/DashboardCard";
import { CustomTable } from '../../Base/CustomTable';
import { ErrorAlert } from '../../Base/ErrorAlert';
import { Doughnut, Bar } from 'react-chartjs-2';
import { DateRangePicker } from 'react-dates';
import { getAPI } from "../../Base/API";
import BlockUi from 'react-block-ui';
import { getColorPallete } from '../../Base/ReferenceDataFunctions';

class UnsubscribedStatistics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            fromDate: moment().subtract(15, 'days'),
            toDate: moment(),
            unsubPerDay: { "drawOnChartArea": true, "datasets": [], "labels": [] },
            unsubPerCampaign: { "datasets": [{ "label": "Reservations by Channel", "data": [], "backgroundColor": getColorPallete() }], "labels": [] },
            profiles: []
        };
    }

    componentDidMount() {
        this.getReservationSummary();
    }

    getReservationSummary = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.calculateData(data.response);
                this.setState({ block: false });
            }
        }, `/api/gms/Marketing/v1/MarketingSendRun/unsubscribe?fromDate=${moment(this.state.fromDate).format('YYYY-MM-DD')}&toDate=${moment(this.state.toDate).format('YYYY-MM-DD')}`)
    }

    calculateData = (data) => {
        const pieColors = getColorPallete();
        pieColors.shift();

        const unsubPerCampaign = { "datasets": [{ "label": "Unsub per Campaign", "data": [], "backgroundColor": pieColors }], "labels": [] };
        const unsubPerDay = {
            "drawOnChartArea": true, "datasets": [
                { label: this.props.intl.formatMessage({ id: "UnsubscribedStatistics.TotalUnsub" }), data: [], borderColor: '#0867fa', backgroundColor: '#0867fa', fill: false, type: 'line' }
            ], "labels": []
        };

        const formatDate = 'YYYY-MM-DD', type = 'days';

        var dates = new Array(moment(this.state.toDate).diff(moment(this.state.fromDate), type)).fill(undefined)
            .reduce((acc, cur) => {
                acc.push(moment(this.state.fromDate).add(acc.length, type).format(formatDate));

                return acc;
            }, [moment(this.state.fromDate).format(formatDate)]);


        unsubPerDay.labels = dates;
        unsubPerDay.datasets[0].data = new Array(dates.length).fill(0);


        data && data.forEach(unsub => {
            const idx = dates.findIndex(el => el == moment(unsub.unsubscribeDate).format(formatDate));

            if (idx !== -1) {
                unsubPerDay.datasets[0].data[idx]++;
            }


            const datasetIdx = unsubPerDay.datasets.findIndex(el => el.campaignId === unsub.campaignId);

            if (datasetIdx !== -1 && idx !== -1) {
                unsubPerDay.datasets[datasetIdx].data[idx]++;
            }
            else {
                const color = getColorPallete()[unsubPerDay.datasets.length];
                unsubPerDay.datasets.push({ label: unsub.campaignName, data: new Array(dates.length).fill(0), borderColor: color, backgroundColor: color, fill: false, campaignId: unsub.campaignId });
                unsubPerDay.datasets[unsubPerDay.datasets.length - 1].data[idx]++;
            }
        });

        const datasetsCopy = [...unsubPerDay.datasets];
        datasetsCopy.shift();

        datasetsCopy.forEach(camp => {
            unsubPerCampaign.labels.push(camp.label);
            unsubPerCampaign.datasets[0].data.push(camp.data.reduce((acc, cur) => acc + cur, 0));
        })

        this.setState({ unsubPerDay, unsubPerCampaign, profiles: data || [] });
    }

    handleSelect = (evt, combo) => {
        this.setState({
            [evt]: combo ? combo.value : null
        })
    }

    render() {
        const { block, error } = this.state;

        const columns = [
            {
                dataField: 'firstName',
                text: this.props.intl.formatMessage({ id: "UnsubscribedStatistics.Profile" }),
                style: { cursor: 'pointer', textDecoration: 'underline' },
                formatter: (cell, row) => {
                    return <div>
                        {row.firstName} {row.lastName}
                    </div>
                },
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        this.props.history.push({ pathname: "/ProfileDetails/" + row.profileId })
                    },
                },
            },
            {
                dataField: 'campaignName',
                text: this.props.intl.formatMessage({ id: "UnsubscribedStatistics.Campaign" })
            },
            {
                dataField: 'unsubscribeDate',
                text: this.props.intl.formatMessage({ id: "UnsubscribedStatistics.UnsubscribeDate" }),
                formatter: (cell) => <span> {cell && moment(cell).format("YYYY-MM-DD HH:mm")} </span>
            }
        ];

        const options = {
            responsive: true,
            maxBarThickness: 60,
            maintainAspectRatio: false,
            plugins: { datalabels: { display: false } },
            scales: {
                x: {
                    stacked: true,
                    gridLines: { display: false }
                },
                y: {
                    stacked: true,
                    display: true,
                    beginAtZero: true,
                    ticks: { precision: 0 },
                    position: 'left',
                    title: {
                        display: true,
                        text: this.props.intl.formatMessage({ id: "UnsubscribedStatistics.Unsubs" })
                    }
                }
            }
        }

        let optionsPie = {
            cutout: '60%',
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: true,
                    position: "right"
                },
                datalabels: {
                    color: 'white',
                    formatter: (ctx, data) => {
                        return ctx ? ctx : '';
                    }
                },
            }
        }

        return (
            <div>
                <BlockUi tag="div" blocking={block}>
                    <ErrorAlert error={error} />

                    <Row>
                        <Col>
                            <h5> <i className="fas fa-times-circle mr-1" /> <FormattedMessage id="NavMenu.UnsubscribedStatistics" /> </h5>
                        </Col>
                    </Row>

                    <Row className="mb-3 mt-2">
                        <Col>
                            <DateRangePicker
                                startDate={moment(this.state.fromDate)}
                                endDate={moment(this.state.toDate)}
                                onDatesChange={({ startDate, endDate }) => this.setState({ fromDate: startDate, toDate: endDate })}
                                isOutsideRange={() => false}
                                endDateId="your_unique_end_date_id_announcement"
                                startDateId="your_unique_start_date_id_announcement"
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                            />
                        </Col>
                        <Col>
                            <Button className="float-right btn btn-sm btn-host" onClick={this.getReservationSummary}>
                                <i className="fa fa-search" />
                            </Button>
                        </Col>

                    </Row>

                    <Row className="my-3">
                        <Col className="col-12">
                            <DashboardCard header="UnsubscribedStatistics.UnsubscribePerDay" >
                                <Bar data={this.state.unsubPerDay} height={'250px'} options={options} />
                            </DashboardCard>
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col className="col-5">
                            <DashboardCard header="UnsubscribedStatistics.UnsubscribePerCampaign" >
                                <div className="mt-5">
                                    <Doughnut data={this.state.unsubPerCampaign} height={'200px'} options={optionsPie} />
                                </div>
                            </DashboardCard>
                        </Col>
                        <Col className="col-7 noShadow">
                            <DashboardCard header="UnsubscribedStatistics.Profiles">
                                <CustomTable
                                    data={this.state.profiles}
                                    columns={columns}
                                    showTotal={true}
                                    sizePerPage={10}
                                    totalSize={this.state.profiles.length}
                                />
                            </DashboardCard>
                        </Col>
                    </Row>
                </BlockUi>
            </div>
        )
    }
}

export default injectIntl(UnsubscribedStatistics);