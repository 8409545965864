import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row } from 'reactstrap';
import { BlankCard, StyledModal } from '../../Base/CommonUIComponents';
import { AddCustomField, CustomFieldComponent } from './CustomDataFunctions';
import { handleNotification } from '../../Base/Notification';
import { postAPI } from '../../Base/API';

class CustomDataModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            customData: this.props.selectedCustomData ? this.props.selectedCustomData.customData : {},
        };
    }

    saveCustomData = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="ProfileCustomData.CustomDataSaved" />, <FormattedMessage id="generic.success" />);

                    this.props.updateTable(data.response[0], 'profileCustomData');
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/profile/${this.props.profileId}/customData`, JSON.stringify({ request: this.state.customData }));
    }

    handleChange = (name, value) => {
        this.setState({
            customData: {
                ...this.state.customData,
                [name]: value
            }
        })
    }

    render() {
        const { block, error, customData } = this.state;
        const { modal, toggleModal } = this.props;

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="md" block={block} error={error}>
                <BlankCard block={this.state.block} error={this.state.error}>
                    <Form onSubmit={this.saveCustomData}>
                        <Row className="mb-3">
                            <Col>
                                <h5>{customData?.id ? <FormattedMessage id="ProfileCustomData.EditCustomData" /> : <FormattedMessage id="ProfileCustomData.AddCustomData" />}</h5>
                            </Col>
                            <Col className="text-right col-3">
                                <Button className="btn-sm btn-host" type="submit">
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>

                        {customData?.id ?
                            <CustomFieldComponent
                                customFieldSettings={this.props.selectedCustomData.fieldSettings}
                                field={customData}
                                handleChange={(value) => this.handleChange("value", value)}
                            />
                        : 
                            <AddCustomField
                                availableCustomData={this.props.availableCustomData?.map(el => ({ ...el, value: el.code, label: el.description }))}
                                handleChange={this.handleChange}
                            />
                        }
                    </Form>
                </BlankCard>
            </StyledModal>
        );
    }
}
export default injectIntl(CustomDataModal)