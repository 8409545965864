import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, FormGroup } from 'reactstrap';
import { getCustomMarketingType } from '../../Base/ReferenceDataFunctions';
import { handleNotification } from "../../Base/Notification";
import CustomSelect from "../../Base/CustomSelect";
import { ErrorAlert } from '../../Base/ErrorAlert';
import { getAPI } from '../../Base/API';
import BlockUi from 'react-block-ui';

class FormSelection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            blockEgoiList: false,
            tagTab: 0,
            selectedRegion: [],
            totalItems: 0,
            pageIndex: 0,
            pageSize: 9,
            profiles: [],
            typeOptions: getCustomMarketingType().filter(el => el.value !== 'CustomLinked'),
            linkedToType: null,
            showEvents: false,
            linksOptions: []
        };
    }

    componentDidMount() {
        if (this.props.filters.formId) {
            if (this.props.filters.listId) {
                this.setState({ provider: 'Egoi' }, () => this.getEgoiLists(true));
            }
            else {
                this.setState({ provider: 'ReviewPro' }, () => this.getReviewProForms());
            }
        }
    }

    getReviewProForms = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                var forms = data.response && data.response.map(form => ({ value: form.id, label: form.name }));

                this.setState({ forms, block: false });
            }
            else this.setState({ block: false });
        }, '/api/gms/ReviewPro/v1/Surveys');
    }

    getEgoiLists = (searchForms) => {
        this.setState({ blockEgoiList: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockEgoiList: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, blockEgoiList: false });
                    return;
                }
                if (data.items && data.items.length > 0) {
                    let egoiList = [];

                    data.items && data.items.forEach(list => {
                        egoiList.push({ value: list.list_id, label: list.public_name })
                    })

                    this.setState({ egoiLists: egoiList, blockEgoiList: false }, () => {
                        if (searchForms) {
                            this.getEgoiForms(this.props.filters.listId);
                        }
                    });
                }
                else this.setState({ blockEgoiList: false });
            }
            else this.setState({ blockEgoiList: false });
        }, `/api/gms/Marketing/v1/egoi/lists?limit=100&offset=0`);
    }

    getEgoiForms = (listId) => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                var forms = data.response && data.response.map(form => ({ value: form.id, label: form.title }));

                this.setState({ forms, block: false });
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/Forms?listId=${listId}`);
    }

    getPNForms = () => {
        this.setState({ block: true }, () =>
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    this.setState({ forms: (data.response?.map(form => ({ value: form.id, label: form.name })) || []), block: false });
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/Survey/v1/survey?all=false`)
        );
    }

    getForms = (combo) => {
        if (combo.value !== this.state.provider) {
            this.setState({ provider: combo.value });

            if (combo.value === "Egoi") {
                this.getEgoiLists();
            }
            else if(combo.value === "ReviewPro"){
                this.getReviewProForms();
            }
            else{
                this.getPNForms();
            }

            //Set formId and listId to null
            this.props.cleanFormData();
        }
    }

    selectEgoiList = (combo) => {
        this.setState({ block: true });

        this.props.handleSelectFilters("listId", combo);

        this.getEgoiForms(combo.value);
    }



    render() {
        const { filters } = this.props;
        const { egoiLists, forms, provider, blockEgoiList, block } = this.state;

        const formProviders = [
            { value: 'Egoi', label: 'Egoi' },
            { value: 'ReviewPro', label: "Review Pro" },
            { value: 'ProfileNowForms', label: "Profile Now Forms" }
        ]

        const isEgoi = provider && provider === 'Egoi';
        const showFormCombo = provider && ((isEgoi && filters.listId) || !isEgoi);

        return (
            <div>
                <ErrorAlert error={this.state.error} />

                <Row>
                    <Col sm={4}>
                        <h5><FormattedMessage id="Campaigns.FormProvider" /></h5>
                    </Col>
                    {isEgoi &&
                        <Col sm={4}>
                            <h5><FormattedMessage id="Campaigns.EgoiList" /></h5>
                        </Col>
                    }
                    {showFormCombo &&
                        <Col sm={4}>
                            <h5><FormattedMessage id="Campaigns.Form" /></h5>
                        </Col>
                    }
                </Row>

                

                <FormGroup row>
                    <Col className="col-4">
                        <CustomSelect
                            isSearchable
                            placeholder={""}
                            options={formProviders}
                            onChange={(combo) => this.getForms(combo)}
                            value={provider ? formProviders.find((p) => p.value === provider) : ''}
                        />
                    </Col>

                    {isEgoi &&
                        <Col className="col-4">
                            <BlockUi tag="div" blocking={blockEgoiList}>
                                <CustomSelect
                                    isSearchable
                                    placeholder={""}
                                    options={egoiLists}
                                    onChange={(combo) => this.selectEgoiList(combo)}
                                    value={filters.listId && egoiLists ? egoiLists.find((p) => p.value === filters.listId) : ''}
                                />
                            </BlockUi>
                        </Col>
                    }

                    {showFormCombo &&
                        <Col className="col-4">
                            <BlockUi tag="div" blocking={block}>
                                <CustomSelect
                                    isSearchable
                                    placeholder={""}
                                    options={forms}
                                    onChange={(combo) => this.props.handleSelectFilters('formId', combo)}
                                    value={forms && filters.formId ? forms.find((p) => p.value == filters.formId) : ''}
                                />
                            </BlockUi>
                        </Col>
                    }
                </FormGroup>
            </div>
        );
    }
}
export default injectIntl(FormSelection)