import React, { Component } from 'react';
import { FormGroup, Input, Col, Card, Row, Form, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BlankCard, KebabMenu, StyledCard, StyledModal } from "../../Base/CommonUIComponents";
import { handleNotification } from '../../Base/Notification';
import { deleteAPI, getAPI, postAPI } from '../../Base/API';
import moment from 'moment';
import ConfirmActionModal from '../../Base/ConfirmActionModal';
import GoogleAnalytics from '../../../img/GoogleAnalytics.png';
import CustomSelect from '../../Base/CustomSelect';


const accountType = [
    { value: true, label: 'Conta Booking Engine' },
    { value: false, label: 'Conta Genérica' },
]


class GoogleAnalyticsAccounts extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            selectedAccount: null,
            isBeAccount: false,
            hotelList: []
        };
    }

    componentDidMount() {
        this.getGaAccounts(false);
    }

    getGaAccounts = (onlyUpdateHotelList) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false }, () => this.getHotelList(onlyUpdateHotelList));
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ gaAccounts: data.response, error: errorMessage, block: false }, () => this.getHotelList(onlyUpdateHotelList));
            }
            else this.setState({ error: errorMessage, block: false }, () => this.getHotelList(onlyUpdateHotelList));
        }, `/api/gms/GoogleAnalytics/v1/googleanalytics`);
    }

    getHotelList = (onlyUpdateHotelList) => {
        this.setState({ block: true }, () => {

            if (onlyUpdateHotelList) {
                const { hotelList } = this.state;

                hotelList.forEach(el =>
                    el.disabled = this.state.gaAccounts?.some(acc => acc.hotelId === el.hotelId)
                )

                this.setState({ hotelList, block: false });
            }
            else {
                getAPI(result => {
                    const { data, error } = result;
                    var errorMessage = [];

                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data.errors && data.errors.length > 0) {
                            handleNotification(data);
                        }
                        else {
                            const hotelList = data.response ? data.response.map(el => ({
                                value: el.hotelId,
                                label: el.name2,
                                disabled: this.state.gaAccounts?.some(acc => acc.hotelId === el.hotelId)
                            })) : []

                            this.setState({ hotelList, error: errorMessage, block: false });
                        }
                    }
                    else this.setState({ error: errorMessage, block: false });
                }, '/api/gms/Hotel/v1/hotel/list?onlyFromHotelGroup=true')
            }
        })
    }

    saveAccount = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var body = { ...this.state.selectedAccount };

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="GoogleAnalyticsAccounts.AccountSaved" />, <FormattedMessage id="generic.success" />);

                    this.getGaAccounts(true);
                    this.toggleModal('modal');
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/GoogleAnalytics/v1/googleanalytics`, body);
    }

    deleteAccount = (e, id) => {
        if (e) e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="GoogleAnalyticsAccounts.AccountRemoved" />, <FormattedMessage id="generic.success" />);
                }

                this.setState({ deleteModal: false, block: false }, this.getGaAccounts);
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/GoogleAnalytics/v1/googleanalytics/${id}`);
    }

    testConnection = (accountId) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.response && data.response.length > 0) {
                    if (data.response[0].isValid) {
                        handleNotification(data, <FormattedMessage id="GoogleAnalyticsAccounts.TestConnectionSuccessfully" />, <FormattedMessage id="generic.success" />);
                    }
                    else {
                        handleNotification({ errors: [{ message: <FormattedMessage id="GoogleAnalyticsAccounts.TestConnectionFailed" /> }]});
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/GoogleAnalytics/v1/PingConnection?googleAnalyticsId=${accountId}`);
    }

    getExtraFields = (accountId) => {
        var options = [
            { text: "GoogleAnalyticsAccounts.TestConnection", function: _ => this.testConnection(accountId), icon: "fas fa-plug mr-2" }
        ]

        return options;
    }

    toggleModal = (modal, account) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            selectedAccount: account || {},
            isBeAccount: account && account.hotelId ? true : false
        }))
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            selectedAccount: {
                ...this.state.selectedAccount,
                [name]: value
            }
        })
    }
    
    handleChangeSelect = (name, combo) => {
        this.setState({
            selectedAccount: {
                ...this.state.selectedAccount,
                [name]: combo ? combo.value : null
            }
        })
    };

    handleAccountType = (combo) => {
        this.setState(prevState => ({
            isBeAccount: combo ? combo.value : null,
            selectedAccount: {
                ...prevState.selectedAccount,
                googleAnalyticsId: combo.value ? '284895446' : null,
                hotelId: null
            }
        }))
    }


    render() {
        const { gaAccounts, modal, deleteModal, selectedAccount, block, error, isBeAccount, hotelList } = this.state;

        return (
            <StyledCard block={this.state.block} error={this.state.error} >
                <Row>
                    <Col className="d-flex">
                        <img alt="Google Analytics" className='mr-2' width='18' height='18' src={GoogleAnalytics} />
                        <h5><FormattedMessage id="NavMenu.GoogleAnalyticsAccounts" /></h5>
                    </Col>
                    <Col className="text-right">
                        <Button className=" btn btn-host btn-sm" onClick={() => this.toggleModal('modal')}>
                            <i className="fas fa-plus" />
                        </Button>
                    </Col>
                </Row>
                {gaAccounts?.length > 0 ?
                    <div>
                        <Card className=" shadow p-2 py-2 border-0 my-3 text-muted" >
                            <Row className="px-2 align-items-center">
                                <Col sm={2}>
                                    <FormattedMessage id="GoogleAnalyticsAccounts.AccountId" />
                                </Col>
                                <Col sm={2}>
                                    <FormattedMessage id="generic.Hotel" />
                                </Col>
                                <Col sm={2}>
                                    <FormattedMessage id="GoogleAnalyticsAccounts.Name" />
                                </Col>
                                <Col sm={2}>
                                    <FormattedMessage id="GoogleAnalyticsAccounts.CreatedAt" />
                                </Col>
                                <Col>
                                    <FormattedMessage id="GoogleAnalyticsAccounts.Email" />
                                </Col>
                                <Col sm={1}/>
                            </Row>
                        </Card>

                        {gaAccounts?.map((account, key) => {
                            return (
                                <Card className=" shadow p-2 py-2 border-0 mb-2 overbglight" key={key}>
                                    <Row className="px-2 align-items-center">
                                        <Col sm={2}>
                                            {account.googleAnalyticsId}
                                        </Col>
                                        <Col sm={2}>
                                            {hotelList.find(el => el.value === account.hotelId)?.label || '-'}
                                        </Col>
                                        <Col sm={2}>
                                            {account.name}
                                        </Col>
                                        <Col sm={2}>
                                            {account.createdAt ? moment(account.createdAt).format("YYYY-MM-DD HH:mm") : ''}
                                        </Col>
                                        <Col>
                                            {account.user}
                                        </Col>
                                        <Col sm={1} className="text-right">
                                            <KebabMenu
                                                editFunction={() => this.toggleModal('modal', account)}
                                                deleteFunction={() => this.toggleModal('deleteModal', account)}
                                                extraFields={this.getExtraFields(account.googleAnalyticsId)}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            );
                        })
                        }
                    </div>
                    :
                    <Row>
                        <Col className="d-flex flex-column align-items-center">
                            <FormattedMessage id="GoogleAnalyticsAccounts.NoAccountsConfigured" />
                        </Col>
                    </Row>
                }

                {modal ?
                    <StyledModal toggleModal={() => this.toggleModal('modal')} modal={modal} size="lg">
                        <BlankCard block={block} error={error}>
                            <Form onSubmit={this.saveAccount}>
                                <Row className="align-items-center mb-3">
                                    <Col>
                                        <h5><FormattedMessage id={`GoogleAnalyticsAccounts.${selectedAccount.id ? 'Update' : 'Create'}Account`} /> </h5>
                                    </Col>
                                    <Col className="text-right">
                                        <Button className="btn-sm btn-host" type="submit" >
                                            <i className="fas fa-save" />
                                        </Button>
                                    </Col>
                                </Row>

                                {global?.modules?.some(m => m === 'HeyBookingEngine') ? 
                                    <>
                                        <div className='title-sm'>
                                            <FormattedMessage id="GoogleAnalyticsAccounts.AccountType" />
                                        </div>
                                        <FormGroup row>
                                            <Col>
                                                <CustomSelect
                                                    options={accountType}
                                                    onChange={(combo) => this.handleAccountType(combo) }
                                                    placeholder=""
                                                    value={isBeAccount ? accountType[0] : accountType[1]}
                                                    isDisabled={selectedAccount.id}
                                                />
                                            </Col>
                                        </FormGroup>

                                        {isBeAccount ?
                                            <>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="generic.Hotel" />
                                                </div>
                                                <FormGroup row>
                                                    <Col>
                                                        <CustomSelect
                                                            options={hotelList}
                                                            onChange={(combo) => this.handleChangeSelect('hotelId', combo)}
                                                            placeholder=""
                                                            value={hotelList.find(el => el.value === selectedAccount.hotelId) || ''}
                                                            required
                                                            isDisabled={selectedAccount.id}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </>
                                        : ''}
                                    </>
                                : ''}

                                <div className='title-sm'>
                                    <FormattedMessage id="GoogleAnalyticsAccounts.AccountId" />
                                </div>
                                <FormGroup row>
                                    <Col>
                                        <Input
                                            type="text"
                                            name="googleAnalyticsId"
                                            value={selectedAccount.googleAnalyticsId || ''}
                                            onChange={this.handleChange}
                                            max={50}
                                            required
                                            disabled={isBeAccount}
                                        />
                                    </Col>
                                </FormGroup>

                                <div className='title-sm'>
                                    <FormattedMessage id="GoogleAnalyticsAccounts.Name" />
                                </div>
                                <FormGroup row>
                                    <Col>
                                        <Input
                                            type="text"
                                            name="name"
                                            value={selectedAccount.name || ''}
                                            onChange={this.handleChange}
                                            max={50}
                                            required
                                        />
                                    </Col>
                                </FormGroup>


                                <div className='title-sm'>
                                    <FormattedMessage id="GoogleAnalyticsAccounts.Email" />
                                </div>
                                <FormGroup row>
                                    <Col>
                                        <Input
                                            type="text"
                                            name="name"
                                            value="channelmanagergoogleanalytics@hotelads-352920.iam.gserviceaccount.com"
                                            readOnly
                                        />
                                    </Col>
                                </FormGroup>

                                <div className="mt-2">
                                    <i className="fas fa-info-circle color-host" />  <span className="title-sm color-black"><FormattedMessage id="GoogleAnalyticsAccounts.EmailAccessNote" /></span>
                                </div>
                            </Form>
                        </BlankCard>
                    </StyledModal>
                    : ''}


                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={() => this.toggleModal('deleteModal')}
                        actionFunction={(e) => this.deleteAccount(e, selectedAccount.id)}
                        text={<FormattedMessage id="GoogleAnalyticsAccounts.ConfirmDeleteText" values={{ account: selectedAccount.name }} />}
                        block={block}
                    />
                : ''}
            </StyledCard>
        );
    }
};

export default injectIntl(GoogleAnalyticsAccounts)