import React, { Component } from 'react';
import { CardBody, Table } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

export class ErrorDetailsTable extends Component {

    render() {
        const { data, icon, title } = this.props;

        return (
            <CardBody>
                <h6>{title}</h6>
                {
                    Array.isArray(data) ?
                        <Table size="sm">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th><FormattedMessage id="ApiLogs.Code" /></th>
                                    <th><FormattedMessage id="ApiLogs.Message" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data
                                        .map((error, key) =>
                                            <tr key={key}>
                                                <th scope="row">{icon}</th>
                                                <td>{error.Code}</td>
                                                <td>{error.Message}</td>
                                            </tr>
                                        )
                                }
                            </tbody>
                        </Table>
                        :
                        data
                }
                
            </CardBody>
        );
    }
}