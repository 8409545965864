import React, { useEffect, useMemo } from 'react';
import { Handle, Position, useUpdateNodeInternals } from 'react-flow-renderer';

const CustomConditionNode = ({ data, id }) => {
    const updateNodeInternals = useUpdateNodeInternals();
    const renderHandle = (id, key, top) => (
        <Handle
            key={key}
            type="source"
            position={Position.Right}
            id={id}
            style={{ top, opacity: data.isEditable ? 1 : 0 }}
        />
    );

    const sortedHandles = useMemo(() => {
        if (!data || !data.numberOfConditions || data.numberOfConditions < 1 || data.numberOfConditions > 3) {
            return [];
        }

        const handles = {
            1: [renderHandle('handleMiddle', 'handleMiddle-1', '25px')],
            2: [
                renderHandle('handleTop', 'handleTop-2', '10px'),
                renderHandle('handleBottom', 'handleBottom-2', '40px')
            ],
            3: [
                renderHandle('handleTop', 'handleTop-3', '10px'),
                renderHandle('handleMiddle', 'handleMiddle-3', '25px'),
                renderHandle('handleBottom', 'handleBottom-3', '40px')
            ],
        };

        return handles[data.numberOfConditions];
    }, [data.numberOfConditions]);

    useEffect(() => {
        updateNodeInternals(id);
    }, [sortedHandles, updateNodeInternals]);

    return (
        <div>
            <div className="text-updater-node">
                <Handle
                    type="target"
                    position={Position.Left}
                    isConnectable={data.isEditable}
                    style={{ opacity: data.isEditable ? 1 : 0 }}
                />
                {data.label}
                {sortedHandles}
            </div>
            {data.completedAt && (
                <div className="text-center" style={{ position: 'absolute', top: '55px', right: '0px', fontSize: '0.5rem' }}>
                    {data.completedAt}
                </div>
            )}
        </div>
    );
};

export default CustomConditionNode;
