import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { StyledCard } from "../Base/CommonUIComponents";
import { FormattedMessage } from 'react-intl';
import reviewPro from '../../img/LogoReviewPro.png';

export class ReviewProMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            hotelList: [],
            hotel: null
        };
    }

    render() {
        const { block, error } = this.state;

        return (

            <StyledCard block={block} error={error} >

                <Row className="mb-2">
                    <Col>
                        <h5> <img alt="" src={reviewPro} height="17px" className="mb-1" /> <FormattedMessage id="ReviewProMenu.Title" /></h5>
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Col>
                        <Card className="border-0 shadow py-3">
                            <CardBody className="text-center">
                                <Link to="/reviewProConfig"><b> <FormattedMessage id="ReviewProMenu.ViewMapSurvey" /> </b></Link>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="border-0 shadow py-3">
                            <CardBody className="text-center">
                                <Link to="/ReviewProStats"> <b> <FormattedMessage id="ReviewProMenu.Stats" /></b></Link>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="border-0 shadow py-3">
                            <CardBody className="text-center">
                                <Link to="/ReviewProExportProfiles"><b> <FormattedMessage id="ReviewProMenu.ExportProfiles" /> </b></Link>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="border-0 shadow py-3">
                            <CardBody className="text-center">
                                <Link to="/CustomExport"><b><FormattedMessage id="ReviewProMenu.CustomExport"/></b></Link>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}
export default injectIntl(ReviewProMenu)
