import React, { Component } from 'react';
import { Row, Col, Button, Card, CustomInput } from 'reactstrap';
import { handleNotification } from '../../Base/Notification';
import { StyledCard } from "../../Base/CommonUIComponents";
import { FormattedMessage } from 'react-intl';
import { getAPI, postAPI } from '../../Base/API';
import Authorization, { CheckAuthorization } from '../../Base/Authorization';

export class ProfileMatchConfig extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            config: {
                threshold: 0,
                nameWeight: 0,
                birthDateWeight: 0,
                contactWeight: 0,
                countryWeight: 0
            }
        };
    }

    componentDidMount() {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                const { config } = this.state;
                Object.assign(config, data.response[0]);
                this.setState({ config, error: errorMessage, block: false });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Profile/v1/profile/matchConfig`)
    }

    handleRangeInput = (evt) => {
        if (evt && evt.target) {
            const { name, value } = evt.target;
            const { config } = this.state;
            config[name] = value;
            this.setState({ config });
        }
    }

    saveConfig = () => {
        this.setState({ block: true });
        const { config } = this.state;

        const request = {
            request: config
        };

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="ProfileMatchConfig.ConfigSaved" />, <FormattedMessage id="generic.success" />);
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/profile/matchConfig`, request);
    }

    render() {
        const { block, error, config } = this.state;

        const weights = ['threshold', 'nameWeight', 'birthDateWeight', 'contactWeight', 'countryWeight', 'loyaltyCardWeight', 'documentWeight'];

        return (
            <StyledCard block={block} error={error}>
                <Row className="mb-3">
                    <Col>
                        <h5> <i className="fas fa-cogs mr-2" /> <FormattedMessage id="ProfileMatchConfig.Title" /> </h5>
                    </Col>
                    <Col className="text-right">
                        <Authorization
                            perform="profileMatchConfig:edit"
                            yes={() => (
                                <Button className="btn-sm btn-host" onClick={this.saveConfig}>
                                    <i className="fas fa-save" />
                                </Button>
                            )}
                            no={() => <div></div>}
                        />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <Card className="border-0 shadow" body>
                            <h5>
                                <FormattedMessage id="ProfileMatchConfig.Info" />
                            </h5>
                            <li>
                                <FormattedMessage id="ProfileMatchConfig.Text1" />
                            </li>
                            <li>
                                <FormattedMessage id="ProfileMatchConfig.Text2" />
                            </li>
                        </Card>
                    </Col>
                </Row>
                {
                    weights.map((weight, key) =>
                        <Card key={key} className="border-0 shadow mb-3" body>
                            <Row>
                                <Col>
                                    <b>
                                        <FormattedMessage id={`ProfileMatchConfig.${weight}`} />
                                    </b>
                                </Col>
                            </Row>
                            <Row className="align-items-center">
                                <Col className="col-2 text-right">
                                    <FormattedMessage id="ProfileMatchConfig.Weight" />
                                </Col>
                                <Col className="col-3">
                                    <Row className="align-items-center">
                                        <Col>
                                            <CustomInput
                                                type="range"
                                                id={weight}
                                                name={weight}
                                                min="0"
                                                max="100"
                                                step="1"
                                                value={config[weight]}
                                                onChange={(e) => { if (CheckAuthorization("profileMatchConfig:edit", null)) this.handleRangeInput(e) }}
                                            />
                                        </Col>
                                        <Col className="text-right col-2 pl-1 pr-2">
                                            {config[weight]}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="col-1" />
                                <Col>
                                    <FormattedMessage id={`ProfileMatchConfig.${weight}Desc`} />
                                </Col>
                            </Row>
                        </Card>
                    )
                }
            </StyledCard>
        );
    }
}