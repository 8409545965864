import React, { Component } from 'react';
import { FormattedNumber, injectIntl } from 'react-intl';
import { Card, Col, Row, Button, Form } from 'reactstrap';
import { StyledCard } from "../../Base/CommonUIComponents";
import { SelectAggregatedBy, getMainStatus, getSendGridStatus } from './SendGridFunctions';
import { handleNotification } from '../../Base/Notification';
import { DateRangePicker } from 'react-dates';
import { getAPI } from '../../Base/API';
import { Line } from 'react-chartjs-2';
import moment from 'moment';


export class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            lineChart: { labels: [], datasets: [] },
            startDate: moment().subtract(6, 'days'),
            endDate: moment(),
            aggregatedBy: 'day',
            offset: 0,
            cards: []
        };
    }

    componentDidMount() {
        this.getGlobalEmailStats();
    }

    getGlobalEmailStats = (e) => {
        if (e) e.preventDefault();

        this.setState({ block: true });
        const { startDate, endDate, limit, offset, aggregatedBy } = this.state;

        let params = `?startDate=${moment(startDate).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}`;

        if (limit) params += `&limit=${limit}`;
        if (offset !== null) params += `&offset=${offset}`;
        if (aggregatedBy) params += `&aggregatedBy=${aggregatedBy}`;


        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    this.buildChartsData(data.response);
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SendGrid/v1/stats/global` + params);
    }

    buildChartsData = (results) => {
        let { cards, lineChart, aggregatedBy } = this.state;
        const formatDate = aggregatedBy === 'month' ? "YYYY-MMM" : "YYYY-MM-DD";        

        lineChart.datasets = [];
        lineChart.labels = [];
        cards = [];


        //datasets
        lineChart.datasets = getSendGridStatus(this.props.intl).map(el => (
            {
                value: el.value,
                label: el.label,
                data: Array(results.length).fill(0),
                backgroundColor: el.color,
                borderColor: el.color
            }
        ))

        //cards
        cards = getMainStatus(this.props.intl).map(el => (
            { ...el, value: 0 }
        ))


        results && results.forEach(date => {
            date.stats && date.stats.forEach(stats => {
                let dataIndex = lineChart.labels.findIndex(el => el === moment(date.date).format(formatDate));
                
                if (stats.metrics) {
                    //Cards
                    cards[0].value += stats.metrics.requests;
                    cards[1].value += stats.metrics.delivered;
                    cards[2].value += stats.metrics.opens;
                    cards[3].value += stats.metrics.clicks;
                    cards[4].value += stats.metrics.bounces;
                    cards[5].value += stats.metrics.spam_reports;


                    //Line chart
                    if (dataIndex === -1) {
                        lineChart.labels.push(moment(date.date).format(formatDate));

                        dataIndex = lineChart.labels.length - 1;
                    }
                    
                    lineChart.datasets.forEach(dataset => {
                        dataset.data[dataIndex] += stats.metrics[dataset.value];
                    })

                }
            })
        })

        this.setState({ cards, lineChart, block: false });
    }

    handleChangeCombo = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    render() {
        const { block, error, cards, lineChart } = this.state;
        const { page } = this.props;
        
        const optionsLine = {
            type: 'line',
            maintainAspectRatio: false,
            legend: {
                display: true,
                position: "top"
            },
            scales: {
                x: { display: true },
                y: {
                    beginAtZero: true, min: 0,
                    grid: {
                        display: false
                    },
                }
            },
            plugins: {
                datalabels: { display: false }
            }
        }


        return (
            <StyledCard block={block} error={error} >
                <Row className="mb-4 py-1">
                    <Col>
                        <h5> <i className={page?.icon} /> {page?.name} </h5>
                    </Col>
                </Row>

                <Form onSubmit={this.getGlobalEmailStats}>
                    <Row className="align-items-center mb-3">
                        <Col sm={2}>
                            <SelectAggregatedBy
                                value={this.state.aggregatedBy}
                                handleChange={this.handleChangeCombo}
                                intl={this.props.intl}
                                required={true}
                            />
                        </Col>
                        <Col>
                            <DateRangePicker
                                startDate={this.state.startDate}
                                startDateId="startDate"
                                isOutsideRange={day => day > moment()}
                                endDate={this.state.endDate}
                                endDateId="endDate"
                                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                required
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className=" btn btn-host btn-sm" type="submit">
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <Row>
                    {cards.map((card, key) => 
                        <Col key={key}>
                            <Card className="bg-white shadow border-0 py-2" body>
                                <div className="text-muted">{card.label}</div>
                                <b className="text-right" style={{ color: card.color }}>
                                    <FormattedNumber value={card.value} />
                                </b>
                            </Card>
                        </Col>
                    )}
                </Row>

                <Row className="mt-3">
                    <Col>
                        <Card className="bg-white shadow border-0" body style={{ height: '60vh' }}>
                            <Line data={lineChart} options={optionsLine} />
                        </Card>
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}

export default injectIntl(Dashboard)