import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import CustomSelect from '../Base/CustomSelect';
import { getProfileType } from '../Base/ReferenceDataFunctions';
import { StyledCard } from '../Base/CommonUIComponents';
import { CustomTable } from '../Base/CustomTable';
import { getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';

class ReservationsByProfile extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            profileType: null,
            startDate: null,
            endDate: null,
            reservationsByProfile: []
        };
    }

    getReservationsByProfile = (evt) => {
        evt.preventDefault();
        if (!this.form.current.reportValidity()) {
            return;
        }

        const { endDate, profileType, startDate } = this.state;

        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else if (data.response) {
                    this.setState({ error: errorMessage, block: false, reservationsByProfile: data.response });
                }
                else {
                    this.setState({ error: errorMessage, block: false, reservationsByProfile: [] });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false, reservationsByProfile: [] });
            }
        }, `/api/gms/Analytics/GetRoomNightsByProfileType?profileType=${profileType}&startDate=${moment(startDate).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}`)
    }

    handleProfileType = (combo) => {
        this.setState({ profileType: combo && combo.value });
    }

    render() {
        const { intl } = this.props;
        const { block, error, endDate, profileType, reservationsByProfile, startDate } = this.state;

        const columns = [
            {
                dataField: 'profileId',
                text: intl.formatMessage({ id: "ReservationsByProfile.ProfileId" }),
                sort: true,
                style: { cursor: 'pointer', textDecoration: 'underline' },
                events: {
                    onClick: (_, __, ___, row) => {
                        this.props.history.push({ pathname: "/ProfileDetails/" + row.profileId })
                    },
                },
            },
            {
                dataField: 'fullName',
                text: intl.formatMessage({ id: "ReservationsByProfile.FullName" }),
                sort: true
            },
            {
                dataField: 'gender',
                text: intl.formatMessage({ id: "ReservationsByProfile.Gender" }),
                sort: true
            },
            {
                dataField: 'nationality',
                text: intl.formatMessage({ id: "ReservationsByProfile.Nationality" }),
                sort: true
            },
            {
                dataField: 'reservations',
                text: intl.formatMessage({ id: "ReservationsByProfile.Reservations" }),
                sort: true
            },
            {
                dataField: 'roomNights',
                text: intl.formatMessage({ id: "ReservationsByProfile.RoomNights" }),
                sort: true
            },
            {
                dataField: 'amount',
                text: intl.formatMessage({ id: "ReservationsByProfile.Amount" }),
                sort: true
            }
        ];

        return (
            <StyledCard block={block} error={error} title={'NavMenu.ReservationsByProfile'} icon="icon-icon-reservas">
                <form ref={this.form}>
                    <Row className="my-3">
                        <Col className="col-3">
                            <CustomSelect
                                placeholder={<FormattedMessage id="SearchProfile.EntityType" />}
                                options={getProfileType(intl).slice(1,3)}
                                value={getProfileType(intl).find(pt => pt.value === profileType)}
                                onChange={this.handleProfileType}
                                required
                            />
                        </Col>
                        <Col>
                            <DateRangePicker
                                startDate={startDate}
                                startDateId="startDate"
                                isOutsideRange={_ => false}
                                endDate={endDate}
                                endDateId="endDate"
                                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                small={true}
                                numberOfMonths={2}
                                showDefaultInputIcon={true}
                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                required
                            />
                        </Col>
                        <Col className="col-1 text-right">
                            <Button className=" btn btn-host btn-sm" onClick={this.getReservationsByProfile}>
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>
                </form>
                <Row className="my-3">
                    <Col>
                        <CustomTable
                            keyField='profileId'
                            data={reservationsByProfile}
                            columns={columns}
                            totalSize={reservationsByProfile.length}
                            exportCSV={true}
                            showTotal={true}
                            search={true}
                        />
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}
export default injectIntl(ReservationsByProfile);