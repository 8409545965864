import React, { Component } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Input } from 'reactstrap';
import { getFieldList, getOperatorTypeList, getGenderOptions } from '../../Base/ReferenceDataFunctions';

import * as countryList from 'react-select-country-list';
import DecisionSplitConditions from './DecisionSplitConditions';

class DecisionSplitConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entityList: [
                {
                    value: "Profile",
                    label: <FormattedMessage id="ReferenceDataFunctions.Profile" />
                },
                {
                    value: "ProfileContact",
                    label: <FormattedMessage id="ReferenceDataFunctions.ProfileContact" />
                },
                {
                    value: "Loyalty",
                    label: <FormattedMessage id="CustomerJourney.loyaltyElement" />
                }
            ],
            profileFieldList: [
                { value: "BirthDate", label: <FormattedMessage id="ReferenceDataFunctions.BirthDate" /> },
                { value: "Nationality", label: <FormattedMessage id="ReferenceDataFunctions.Nationality" /> },
                { value: "Gender", label: <FormattedMessage id="ReferenceDataFunctions.Gender" />}
            ],
            contactTypeList: [
                { value: "Email", label: <FormattedMessage id="ReferenceDataFunctions.Email" /> },
                { value: "Mobile", label: <FormattedMessage id="ReferenceDataFunctions.Mobile" /> },
            ],
            loyaltyFieldList: [
                { value: "HasLoyaltyCard", label: <FormattedMessage id="CustomerJourney.Member" /> },
                { value: "DoesNothaveLoyaltyCard", label: <FormattedMessage id="CustomerJourney.NonMember" /> },
                { value: "Levels", label: <FormattedMessage id="CustomerJourney.Levels" /> },
            ],
            block: false
        }
    }

    getEntityValue = (metaData, entityList, conditionNumber) => {
        const entity = metaData && metaData[conditionNumber] && metaData[conditionNumber].entity;
        return entityList && entityList.find(el => el.value === entity) || '';
    }

    getFieldOptions = (entity) => {
        switch (entity) {
            case 'Profile':
                return this.state.profileFieldList;
            case 'ProfileContact':
                return getFieldList(entity, this.props.intl).filter(el => el.value !== 'Main');
            case 'Loyalty':
                return this.state.loyaltyFieldList;
            default:
                return [];
        }
    }

    getFieldValue = (entity, field, conditionNumber) => {
        const options = this.getFieldOptions(entity);
        if(entity === 'Loyalty' && field === 'HasLoyalty'){
            const metaDataValue = this.props.selectedNode && this.props.selectedNode.metaData && this.props.selectedNode.metaData[conditionNumber] && this.props.selectedNode.metaData[conditionNumber].value;
            
            if(metaDataValue && Array.isArray(metaDataValue)){
                return metaDataValue[0] === true ? options.find(el => el.value === 'HasLoyaltyCard') : options.find(el => el.value === 'DoesNothaveLoyaltyCard');
            }
        }

        return options.find(el => el.value === field) || '';
    }

    getOperatorTypeOptions = (entity, field, conditionNumber) => {
        const operatorTypes = getOperatorTypeList();
        if(entity && field && operatorTypes) {
            if(entity === 'Profile') {
                if(field === 'BirthDate'){
                    return operatorTypes.filter(el => el.value !== 'Contains' && el.value !== 'DoesNotContain');
                }

                if(field === 'Nationality'){
                    return operatorTypes.filter(el => el.value === 'Contains' || el.value === 'DoesNotContain');
                }

                if(field === 'Gender'){
                    return operatorTypes.filter(el => el.value === 'Equal' || el.value === 'NotEqual');
                }
            }else if(entity === 'ProfileContact'){
                return operatorTypes.filter(el => el.value === 'Equal' || el.value === 'NotEqual');
            }else if(entity === 'Loyalty'){
                if(field === 'Levels' ){
                    const selectedNode = this.props.selectedNode;
                    const loyaltyValue = selectedNode && selectedNode.metaData && selectedNode.metaData[conditionNumber] && selectedNode.metaData[conditionNumber].value;
                    if(loyaltyValue){
                        let loyaltyObj = null;
                        
                        this.props.loyaltyCardsOptions && this.props.loyaltyCardsOptions.forEach(lc => {
                            if(lc && lc.options && lc.options.length > 0 && !loyaltyObj){
                                const loyaltyId = Array.isArray(loyaltyValue) ? loyaltyValue[0] : loyaltyValue;
                                loyaltyObj = lc.options.find(option => option.value === loyaltyId && option.loyaltyCardTypeConfigCode === lc.type);
                            }
                        });

                        if(loyaltyObj && (loyaltyObj.points === null || loyaltyObj.points === undefined)){
                            return operatorTypes.filter(el => el.value === 'Equal' || el.value === 'NotEqual');
                        }
                    }
                }
                return operatorTypes.filter(el => el.value !== 'Contains' && el.value !== 'DoesNotContain');
            }
        }
    }

    getOperatorTypeValue = (operatorType) => {
        return operatorType && getOperatorTypeList().find(el => el.value === operatorType) || '';
    }

    getValueOptions = (entity, field) => {
        if (entity === 'Profile') {
            switch (field) {
                case 'Nationality':
                    return countryList().getData();
                case 'Gender':
                    return getGenderOptions(this.props.intl);
                default:
                    return [];
            }
        } else if (entity === 'ProfileContact') {
            return field === 'Type' ? this.state.contactTypeList : [];
        } else if (entity === 'Loyalty') {
            return field === 'Levels' ? this.props.loyaltyCardsOptions : []; 
        } else {
            return [];
        }
    }

    getValue = (options, value, entity, field) => {
        if ((entity === 'Profile' && field === 'Nationality')) {
            return options.filter(option => value && value.includes(option.value));
        }

        if(entity === 'Loyalty' && field === 'Levels'){
            let loyaltyObj = null;

            if (this.props.loyaltyCardsOptions) {
                this.props.loyaltyCardsOptions.some(lc => {
                    if (lc?.options?.length > 0) {
                        loyaltyObj = lc.options.find(option => 
                            option.value === value && option.loyaltyCardTypeConfigCode === lc.type
                        );
                        return loyaltyObj;
                    }
                });
            }
            return loyaltyObj || '';
        }

        return options.find(option => option.value === value) || '';
    }

    render() {
        const { entityList, block } = this.state;
        const { selectedNode, changeCurrentNodeMetaDataFields, intl } = this.props;

        return (
            <Row className='mt-3'>
                <DecisionSplitConditions
                    entityList={entityList}
                    block={block}
                    selectedNode={selectedNode}
                    changeCurrentNodeMetaDataFields={changeCurrentNodeMetaDataFields}
                    conditionNumber={1}
                    intl={intl}
                    getEntityValue={this.getEntityValue}
                    getFieldOptions={this.getFieldOptions}
                    getFieldValue={this.getFieldValue}
                    getOperatorTypeOptions={this.getOperatorTypeOptions}
                    getOperatorTypeValue={this.getOperatorTypeValue}
                    getValueOptions={this.getValueOptions}
                    getValue={this.getValue}
                />
                {selectedNode && selectedNode.data && selectedNode.data.numberOfConditions === 3 &&
                    <DecisionSplitConditions
                        entityList={entityList}
                        block={block}
                        selectedNode={selectedNode}
                        changeCurrentNodeMetaDataFields={changeCurrentNodeMetaDataFields}
                        conditionNumber={2}
                        intl={intl}
                        getEntityValue={this.getEntityValue}
                        getFieldOptions={this.getFieldOptions}
                        getFieldValue={this.getFieldValue}
                        getOperatorTypeOptions={this.getOperatorTypeOptions}
                        getOperatorTypeValue={this.getOperatorTypeValue}
                        getValueOptions={this.getValueOptions}
                        getValue={this.getValue}
                        handleConditionsNumberSelectChange={this.props.handleConditionsNumberSelectChange}
                    />
                }
                <Col className='col-12 mt-4'>
                    <Col className='col-12'>
                        <h6 className='m-0'>
                            <b><FormattedMessage id='CustomerJourney.Condition3' /></b>
                        </h6>
                    </Col>
                    <Col className='col-11 mt-3'>
                        <Input
                            name="condition3"
                            type='text'
                            value={''}
                            onChange={this.props.changeCurrentNodeMetaDataFields}
                            disabled={true}
                        />
                    </Col>
                </Col>
            </Row>
        )
    }
}

export default injectIntl(DecisionSplitConfig);
