import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Card, UncontrolledTooltip } from 'reactstrap';
import { handleNotification } from "../../Base/Notification";
import RenderHtmlAsImage from '../../Base/RenderHtmlAsImage';
import { CampaignStats, getKpis } from './MarketingFunctions';
import { getAPI } from '../../Base/API';
import BlockUi from 'react-block-ui';

class StatsPerTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            marketingSendKpis: this.props.marketingSendKpis
        };
    }

    componentDidMount() {
        this.getAllTemplates();
    }

    componentDidUpdate(prevProps) {
        if (this.props.marketingSendKpis !== prevProps.marketingSendKpis) {
            this.setState({ marketingSendKpis: this.props.marketingSendKpis }, this.getAllTemplates);
        }
    }
    
    getAllTemplates = () => {
        this.setState({ blockTemplateDetails: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, allTemplates: [] }, this.getSendgridTemplates);
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, allTemplates: [] }, this.getSendgridTemplates);
                    return;
                }

                const allTemplates = data.response && Array.isArray(data.response) && data.response.length > 0 ? data.response : [];
                this.setState({ allTemplates }, this.getSendgridTemplates);
            }
            else {
                this.setState({ error: errorMessage, allTemplates: [] }, this.getSendgridTemplates);
            }
        }, `/api/gms/Marketing/v1/egoitransactional/template/${this.props.campaignType}`);
    }

    getSendgridTemplates = () => {
        let { allTemplates } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorTemplates: errorMessage, blockTemplateDetails: false });
                return;
            }
            if (data && data.response && data.response.result) {
                if (!allTemplates) {
                    allTemplates = [];
                }

                data.response.result
                    .filter(resp => resp.versions.some(vrs => vrs.active === 1))
                    .forEach(resp => {
                        const template = {
                            id: resp.id,
                            templateName: resp.name
                        }
                        const version = resp.versions.find(vrs => vrs.active === 1);
                        if (version) {
                            template.thumbnail = version.thumbnail_url.startsWith('//') ? `https:${version.thumbnail_url}` : `https://${version.thumbnail_url}`;
                            template.subject = version.subject;
                        }
                        allTemplates.push(template);
                    });

                this.setState({ errorTemplates: errorMessage, blockTemplateDetails: false, allTemplates });
            }
            else {
                this.setState({ errorTemplates: errorMessage, blockTemplateDetails: false });
            }
        }, `/api/gms/SendGrid/v1/templates?generations=legacy,dynamic&page=100`);
    }

    render() {
        const { allTemplates, marketingSendKpis } = this.state;

        return (
            <div>
                <BlockUi tag="div" blocking={this.state.blockTemplateDetails}>
                    <Row>
                        {marketingSendKpis?.map((tempStatus, index) => {
                            const template = allTemplates && Array.isArray(allTemplates) && allTemplates.find(el => el.id == tempStatus.templateId);

                            return <Col sm={6} key={index} className={'border-top ' + (index % 2 === 0 ? ' border-right' : '')}>
                                <Card className="border-0 mb-3 p-3 h-100 mb-3 border-top">
                                    <Row>
                                        <Col sm={5}>
                                            {
                                                template ?
                                                    (
                                                        template.thumbnail ?
                                                            <img src={template && template.thumbnail} style={{ width: 'inherit', height: '390px' }} />
                                                            :
                                                            <RenderHtmlAsImage
                                                                html={template.htmlBody}
                                                                id={`communicationStatsTemplate${index}`}
                                                                position='fixed'
                                                            />
                                                    )
                                                    :
                                                    ''

                                            }
                                        </Col>
                                        <Col className="pr-4">
                                            <div className="text-center"> <b> {template && template.templateName} </b> </div>
                                            <div className="text-center font_size_xxs"> {template && template.subject}  </div>

                                            <CampaignStats
                                                marketingSendKpis={[tempStatus]}
                                                styles={{ display: 'flex', justifyContent: 'space-around', padding: '20px 0 40px 0' }}
                                                showSentCircle={false}
                                            />

                                            <Row>
                                                {getKpis().map((kpi, idx) =>
                                                    <Col sm={6} key={idx}>
                                                        <Card className="border-0 shadow p-2 mb-2" id={`${kpi.value}Info${index}-${idx}`}>
                                                            <Row className="align-items-center">
                                                                <Col sm={4}>
                                                                    <div className={"color-white ml-2 bg-" + (kpi.bgColor)} style={{ width: '40px', padding: '4px 3px', textAlign: 'center', borderRadius: '5px' }}>
                                                                        <i className={kpi.icon} />
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    <b style={{ fontSize: '14px' }}># {tempStatus[kpi.value]}</b>
                                                                    <div className="text-muted font_size_xxs">{kpi.label}</div>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                        <UncontrolledTooltip placement="bottom" target={`${kpi.value}Info${index}-${idx}`} >
                                                            <FormattedMessage id={`CommunicationStats.${kpi.value}Text`} />
                                                        </UncontrolledTooltip>
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>
                                    {tempStatus.marketingSendUrlKpis ?
                                        <Card className="px-3 py-2 border-0 shadow font_size_xxs mt-1 mx-1">
                                            <Row className="text-muted pb-1 border-bottom">
                                                <Col sm={10}> URL </Col>
                                                <Col className="text-right col-2"> #Clicks </Col>
                                            </Row>

                                            {tempStatus.marketingSendUrlKpis.map((url, key) =>
                                                <Row key={key} className="overbglight pt-1">
                                                    <Col sm={10}><a href={url.url} target="_blank">{url.url}</a> </Col>
                                                    <Col className="text-right col-2" style={{ paddingRight: '35px' }}>{url.totalClicks}</Col>
                                                </Row>
                                            )}
                                        </Card>
                                        : ''}
                                </Card>
                            </Col>
                        })}
                    </Row>
                </BlockUi>
            </div>
        );
    }
}
export default injectIntl(StatsPerTemplate)