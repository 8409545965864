import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Input, Row } from 'reactstrap';
import classNames from 'classnames';
import TimelineElement from './TimelineElement';

class Timeline extends Component {

    render() {
        const { children, className, events, layout, selectEvent, selectedEventId, states, changePreviousPage, pageIndex, pageSize, totalPages, changeNextPage, prepareDoSearch, handlePageIndex } = this.props;

        return (
            <Row>
                <Col className="col-lg-4 col-sm-12 left border-right">
                    <div className={classNames(className, 'timeline ', { 'timeline--two-columns': layout === '2-columns', 'timeline--one-column': layout === '1-column' })}>
                        {
                            events.map((item, key) =>
                                <TimelineElement key={key} event={item} selectedEventId={selectedEventId} selectEvent={selectEvent} states={states}>
                                    <div className="timeline-element-subtitle">
                                        {item.eventType === 'Profile' ? <div> <FormattedMessage id={`ApiLogs.${item.eventType}.${item.action}`} values={{ ids: item.ids }} />  </div> : ''}
                                        {item.eventType === 'IssueCardNumber' ? <div> <FormattedMessage id={`ApiLogs.IssueCardNumber.${item.action}`} values={{ ids: item.ids }} />  </div> : ''}
                                    </div>
                                </TimelineElement>
                            )
                        }
                        <Row className="mt-2 align-items-center">
                            <Col className="col-1">
                                <Button className="btn-host shadow btn-sm float-left" style={{ opacity: '1' }} disabled={pageIndex <= 1} onClick={changePreviousPage}>
                                    <i className="fas fa-angle-left" />
                                </Button>
                            </Col>
                            <Col>
                                <span>
                                    <strong>
                                        <Row className=" align-items-center">
                                            <Col className="col-5 text-right pr-2" style={{ paddingRight: '0', fontSize: '20px' }}>
                                                <FormattedMessage id="ApiLogs.Page" />
                                            </Col>
                                            <Col className="col-2" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                <Input type="number" id="pageIndex" name="pageIndex" min="1" max={totalPages} onChange={handlePageIndex} value={pageIndex !== null ? pageIndex : ''} onBlur={prepareDoSearch}
                                                />
                                            </Col>
                                            <Col className="col-5 text-left" style={{ paddingLeft: '0', fontSize: '20px' }}>
                                                / {totalPages}
                                            </Col>
                                        </Row>
                                    </strong>
                                </span>
                            </Col>
                            <Col className="col-1">
                                <Button className="btn-host btn-sm shadow float-right" disabled={events.length < pageSize} onClick={changeNextPage}>
                                    <i className="fas fa-angle-right" />
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col className="col-lg-8 col-sm-12 left">
                    {
                        children
                    }
                </Col>
            </Row>                
        );
    }
}

Timeline.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    className: PropTypes.string,
    animate: PropTypes.bool,
    selectedEvent: PropTypes.object,
    selectedChannel: PropTypes.string,
    layout: PropTypes.oneOf(['1-column', '2-columns']),
};

Timeline.defaultProps = {
    animate: true,
    className: '',
    layout: '2-columns',
};

export default Timeline;