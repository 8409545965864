import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { injectIntl } from 'react-intl';
import { Button, Card, Col, Row, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { StyledCard } from "../Base/CommonUIComponents";
import { getAPI, deleteAPI } from "../Base/API";
import { handleNotification } from "../Base/Notification";
import PreviewTemplate from './PreviewTemplate';
import { FormattedMessage } from "react-intl";

import Authorization, { CheckAuthorization } from '../Base/Authorization';
import ConfirmActionModal from '../Base/ConfirmActionModal';

export class AlertList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            modal: false,
            deleteModal: false,
            alertList: []
        };
    }

    componentDidMount() {
        this.getAlertList();
    }

    getAlertList = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({
                    alertList: data.response ? data.response : []
                });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoitransactional/v2/alerts/template/list`);
    }

    removeAlert = (e, alertId) => {
        e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="AlertList.AlertRemoved" />, <FormattedMessage id="generic.success" />);
                    this.getAlertList();
                    this.toggleDeleteModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoitransactional/v2/alerts/${alertId}/template`);
    }

    toggleModal = (template, channel) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            templateId: template,
            channel
        }));
    }

    toggleDeleteModal = (alertToDelete) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
            alertToDelete
        }));
    }


    render() {
        const { block, error, alertList, alertToDelete, deleteModal } = this.state;

        return (

            <StyledCard block={block} error={error} >
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/MarketingConfigEgoi"> Config</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Alerts</BreadcrumbItem>
                    </Breadcrumb>
                </div>

                <Authorization
                    perform="alertList:add"
                    yes={() => (
                        <Row className="mb-2 text-right pr-3">
                            <Col>
                                <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.props.history.push({ pathname: "/AlertDetails" })}>
                                    <i className="fas fa-plus" />
                                </Button>
                            </Col>
                        </Row>
                    )}
                    no={() => <div></div>}
                />

                {alertList && alertList.map((el, k) =>
                    <Card className="border-0 p-3 mb-2" key={k}>
                        <Row>
                            <Col className="col-11 pointer" onClick={() => { if (CheckAuthorization("alertList:edit", null)) this.props.history.push({ pathname: "/AlertDetails/" + el.alertId, alert: el }) }}>
                                <Row>
                                    <Col className="col-3">
                                        <div><h4>{el.name}</h4></div>
                                        <div><b><FormattedMessage id="AlertList.Interval" />: </b> {el.interval} seconds</div>
                                        <div><b><FormattedMessage id="AlertList.MaxAttempts" />: </b> {el.maxAttempts}</div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col>
                                <Row>
                                    <Col className="text-right">
                                        <Button className="btn btn-host btn-sm" onClick={() => this.toggleModal(el.templateId, el.channel)}>
                                            <i className="icon-icon-preview"></i>
                                        </Button>

                                        <Authorization
                                            perform="alertList:delete"
                                            yes={() => (
                                                <Button className="btn btn-sm btn-host ml-2" onClick={() => this.toggleDeleteModal({ id: el.alertId, name: el.name })}>
                                                    <i className="fas fa-trash-alt" />
                                                </Button>
                                            )}
                                            no={() => <div></div>}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                )}


                {this.state.modal ?
                    <PreviewTemplate
                        modal={this.state.modal}
                        toggleModal={this.toggleModal}
                        templateId={this.state.templateId}
                        channel={this.state.channel}
                    />
                : ''}


                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={this.toggleDeleteModal}
                        actionFunction={(e) => this.removeAlert(e, alertToDelete.id)}
                        text={<FormattedMessage id="AlertList.ConfirmDeleteText" values={{ alertName: alertToDelete.name }} />}
                        block={block}
                    />
                : ''}
            </StyledCard>
        );
    }
}
export default injectIntl(AlertList)
