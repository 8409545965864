import React, { Component } from 'react';
import { StyledCard } from '../Base/CommonUIComponents';
import { Button, Row, Col, Card, Badge, Progress, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { getPMSReservationStatus } from '../Base/ReferenceDataFunctions';
import { injectIntl, FormattedMessage } from 'react-intl';
import { SelectHotel } from "../Base/CommonUIComponents";
import { getAPI } from "../Base/API";
import moment from 'moment';
import ReactBubbleChart from "react-bubble-chart";
import '../../../node_modules/react-bubble-chart/src/style.css';
import Authorization, { CheckAuthorization } from "../Base/Authorization";
import GuestsList from './GuestsList';
import { GeoChart } from '../Base/CustomCharts';
import * as countryList from 'react-select-country-list';

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            checkinToday: [],
            checkoutToday: [],
            inHotel: [],
            distributionByCountry: [["Country", "Total"]],
            checkedIn: true,
            checkInToday: false,
            checkOutToday: false,
            generations: [
                { name: 'Geração Alpha', start: 2013, end: moment().format("YYYY"), total: 0 },
                { name: 'Geração Z', start: 1997, end: 2012, total: 0 },
                { name: 'Millenials', start: 1981, end: 1996, total: 0 },
                { name: 'Geração X', start: 1965, end: 1980, total: 0 },
                { name: 'Baby Boomers', start: 1946, end: 1964, total: 0 },
                { name: 'NA', total: 0 }
            ],
            traveler: [
                { quant: 1, total: 0 },
                { quant: 2, total: 0 },
                { quant: 3, total: 0 }
            ],
            tags: [],
            totalGuests: 0,
            filters: [],
            hotelFilter: null,
            cachedData: [],
            birthdaysToday: 0,
            areTagsExpanded: false,
            bubbleChart: [
                {
                    value: 'tags',
                    data: []
                },
                {
                    value: 'source',
                    data: []
                },
                {
                    value: 'segmentCode',
                    data: []
                }
            ],
            bubbleChartIdx: 0,
            guestsIssues: [],
            totalMainGuest: 0,
            totalMailingConsent: 0
        };
    }

    componentDidMount() {
        window.setTimeout(() => {
            this.setState({
                hotelFilter: global.hotelId
            }, () => {
                if (CheckAuthorization("homepage:view", null)) {
                    this.getData();
                }
            });
        }, 2000 || global.hotelId); 

    }


    getData = () => {
        this.setState({ block: true });
        var param = this.state.hotelFilter ? `?hotelId=${this.state.hotelFilter}` : '';

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const { tags, generations } = this.state
                Array.isArray(data) && data.forEach((res) => {
                    //Distribution
                    const resLength = res.adults + res.children;

                    if (resLength === 1) {
                        res.distribution = 'Solo'
                    }
                    else if (resLength === 2) {
                        res.distribution = 'Couple';
                    }
                    else if (resLength > 2) {
                        res.distribution = 'Crowd';
                    }

                    //Generations
                    if (res.birthDate) {
                        const year = moment(res.birthDate).format("YYYY");

                        if (year >= generations[0].start && year <= generations[0].end) {
                            res.generation = generations[0].name;
                        }
                        else if (year >= generations[1].start && year <= generations[1].end) {
                            res.generation = generations[1].name;
                        }
                        else if (year >= generations[2].start && year <= generations[2].end) {
                            res.generation = generations[2].name;
                        }
                        else if (year >= generations[3].start && year <= generations[3].end) {
                            res.generation = generations[3].name;
                        }
                        else if (year >= generations[4].start && year <= generations[4].end) {
                            res.generation = generations[4].name;
                        }
                        else {
                            res.generation = generations[5].name;
                        }
                    }
                    else {
                        res.generation = generations[5].name;
                    }

                    //Tags
                    if (res && res.tags && Array.isArray(res.tags)) {
                        res.tags.forEach(tag => {
                            if (!tags.find((tg) => tg._id === tag.code)) {
                                tags.push({
                                    _id: tag.code,
                                    colorValue: tags.length + 1,
                                    selected: res.tags.length === 1 ? true : false
                                })
                            }
                        })
                    }

                    //GuestErrors
                    const mobile = res.contacts?.find(el => el.type === 'Mobile')?.contact;
                    const email = res.contacts?.find(el => el.type === 'Email')?.contact;

                    const contactRegex = /^(^[\+]|[\+]([ ])?[(][0-9]{1,3}[)])?([0-9]|[\- ])+$/g;

                    if (mobile && !new RegExp(contactRegex).test(mobile)) {
                        res.invalidContact = true;
                    }
                    else {
                        const phone = res.contacts?.find(el => el.type === 'Phone')?.contact

                        if (phone && !new RegExp(contactRegex).test(phone)) {
                            res.invalidContact = true;
                        }
                    }
                    if (email && !new RegExp(/^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/g).test(email)) {
                        res.invalidEmail = true;
                    }
                })

                this.setState({ cachedData: data, tags, error: errorMessage, block: false });
                this.reservationsData(data);
            }
            else this.setState({ error: errorMessage, block: false });
        }, '/api/gms/Profile/v1/hostprofiles' + param);
    }

    reservationsData = (data) => {
        let { generations, checkinToday, checkoutToday, inHotel, distributionByCountry, distributionByCountryCode, totalGuests, traveler, filters, cachedData, tags, birthdaysToday, totalMainGuest, totalMailingConsent, cachedDataFiltered, guestsIssues } = this.state;
        const now = moment().format("YYYY-MM-DD");

        generations = [
            { name: this.props.intl.formatMessage({ id: "Home.GenerationAlpha" }), start: 2013, end: moment().format("YYYY"), total: 0 },
            { name: this.props.intl.formatMessage({ id: "Home.GenerationZ" }), start: 1997, end: 2012, total: 0 },
            { name: this.props.intl.formatMessage({ id: "Home.Millennials" }), start: 1981, end: 1996, total: 0 },
            { name: this.props.intl.formatMessage({ id: "Home.GenerationX" }), start: 1965, end: 1980, total: 0 },
            { name: this.props.intl.formatMessage({ id: "Home.BabyBoomers" }), start: 1946, end: 1964, total: 0 },
            { name: 'NA', total: 0 }
        ]
        checkinToday = [];
        checkoutToday = [];
        inHotel = [];
        distributionByCountry = [[{ v: "Country Code", f: "Country Label" }, "Total"]];
        distributionByCountryCode = [["Country", "Total"]];
        traveler = [
            { quant: 1, total: 0 },
            { quant: 2, total: 0 },
            { quant: 3, total: 0 }
        ];
        birthdaysToday = 0; totalMainGuest = 0; totalMailingConsent = 0;
        guestsIssues = [
            { type: 'name', icon: "icon-icon-profile", unfilled: 0 },
            { type: 'email', icon: "fas fa-at", unfilled: 0, invalid: 0 },
            { type: 'contact', icon: "icon-icon-sms", unfilled: 0, invalid: 0 }
        ];
        tags = [];

        //Apply filters
        const reservationFiltered = cachedDataFiltered !== undefined ? ( cachedDataFiltered || [] ) : cachedData;
        var Reservations = data ? data : reservationFiltered ? reservationFiltered : cachedData;

        if (filters.length > 0) {
            Reservations = this.filterReservations(filters, Reservations, reservationFiltered);
        }

        totalGuests = Reservations.length;

        Reservations && Reservations.forEach(guest => {
            const checkIn = moment(guest.checkIn).format("YYYY-MM-DD");
            const checkOut = moment(guest.checkOut).format("YYYY-MM-DD");

            //Generations
            const generation = generations.find((gen) => gen.name === guest.generation);
            if (generation) {
                generation.total++;
            }

            //distributionByCountry
            let countryLabel = guest.nationality;

            if(guest.nationality)
                countryLabel = countryList().getLabel(guest.nationality.toUpperCase());

            const index = distributionByCountry.findIndex(el => el[0].v === guest.nationality);

            if (index !== -1) {
                distributionByCountry[index][1]++;
                
                const index2 = distributionByCountryCode.findIndex(el => el[0] === guest.nationality);

                if(index2 !== -1)
                    distributionByCountryCode[index2][1]++;
            }
            else {
                const arr = [{ v: guest.nationality, f: countryLabel }, 1];
                distributionByCountry.push(arr);

                const arr2 = [guest.nationality, 1];
                distributionByCountryCode.push(arr2);
            }

            //Traveler
            if (guest.distribution === 'Solo') {
                traveler[0].total++;
            }
            if (guest.distribution === 'Couple') {
                traveler[1].total++;
            }
            else if (guest.distribution === 'Crowd') {
                traveler[2].total++;
            }

            //Tags
            guest.tags && guest.tags.forEach(tag => {
                const currTag = tags.find((tg) => tg._id === tag.code);
                if (currTag) {
                    currTag.value = currTag.value + 1;
                }
                else {
                    tags.push({
                        _id: tag.code,
                        colorValue: tags.length + 1,
                        selected: guest.tags.length === 1 ? true : false,
                        value: 1
                    });
                }
            })


            //birthdays today
            if (guest.birthDate && (moment().format("DD-MM") === moment(guest.birthDate).format("DD-MM"))) {
                birthdaysToday ++;
            }

            //totalMainGuest, totalMailingConsent
            if (guest.isMainGuest) totalMainGuest++;
            if (guest.hasMailingsConsent) totalMailingConsent++;


            //Guest Issues
            if (!guest.firstName || !guest.lastName) {
                guestsIssues[0].unfilled++;
            }
            if (!guest.contacts?.some(el => el.type === 'Email')) {
                guestsIssues[1].unfilled++;
            }
            else if (guest.invalidEmail) {
                guestsIssues[1].invalid++;
            }
            if (!guest.contacts?.some(el => el.type === 'Mobile') && !guest.contacts?.some(el => el.type === 'Phone')) {
                guestsIssues[2].unfilled++;
            }
            else if (guest.invalidContact) {
                guestsIssues[2].invalid++;
            }


            //Tables
            if (moment(checkIn).isSame(now)) {
                checkinToday.push(guest);
                return;
            }

            if (moment(checkOut).isSame(now)) {
                checkoutToday.push(guest);
                return;
            }

            if (moment(checkOut).isAfter(now) && moment(checkIn).isBefore(now)) {
                inHotel.push(guest);
                return;
            }
        });

        tags = tags.filter(t => t.value != 0);

        const reservationsBySource = Reservations.reduce((acc, curr) => {
            const source = acc.find(src => src._id === curr.source);

            if (source) {
                source.value++;
            }
            else {
                const newSource = {
                    _id: curr.source,
                    colorValue: acc.length + 1,
                    selected: acc.length === 0,
                    value: 1
                };
                acc.push(newSource);
            }
            return acc;
        }, []);

        const reservationsBySegment = Reservations.reduce((acc, curr) => {
            const segment = acc.find(src => src._id === curr.segmentCode);
            if (segment) {
                segment.value++;
            }
            else {
                const newSegment = {
                    _id: curr.segmentCode,
                    colorValue: acc.length + 1,
                    selected: acc.length === 0,
                    value: 1
                };
                acc.push(newSegment);
            }
            return acc;
        }, []);

        const bubbleChart = [
            {
                value: 'tags',
                data: tags
            },
            {
                value: 'source',
                data: reservationsBySource
            },
            {
                value: 'segmentCode',
                data: reservationsBySegment
            }
        ];
        this.setState({ checkinToday, checkoutToday, inHotel, distributionByCountry, distributionByCountryCode, totalGuests, traveler, generations, tags, birthdaysToday, bubbleChart, guestsIssues, totalMainGuest, totalMailingConsent });
    }

    filterReservations = (filters, Reservations, reservationFiltered) => {
        Reservations = [];

        filters.forEach((filter) => {
            if (filter.type === 'list') {
                Reservations = reservationFiltered || [];
            }
            else if (filter.type === 'birthDate') {
                if (Reservations.length > 0) {
                    Reservations = Reservations.filter((res) => res[filter.type] && (moment().format("DD-MM") === moment(res[filter.type]).format("DD-MM")));
                }
                else {
                    Reservations.push(reservationFiltered.filter((res) => res[filter.type] && (moment().format("DD-MM") === moment(res[filter.type]).format("DD-MM"))));
                }

                Reservations = Reservations.flat();
            }
            else if (filter.type === 'invalidemail' || filter.type === 'invalidcontact') {
                const propertyName = filter.type === 'invalidemail' ? 'invalidEmail' : 'invalidContact';

                if (Reservations.length > 0) {
                    Reservations = Reservations.filter((res) => res[propertyName]);
                }
                else {
                    Reservations.push(reservationFiltered.filter((res) => res[propertyName]));
                }

                Reservations = Reservations.flat();
            }
            else if (filter.type === 'withoutcontact') {
                if (Reservations.length > 0) {
                    Reservations = Reservations.filter((res) => !res.contacts.some(c => c.type === 'Mobile' || c.type === 'Phone' ));
                }
                else {
                    Reservations.push(reservationFiltered.filter((res) => !res.contacts.some(c => c.type === 'Mobile' || c.type === 'Phone')));
                }

                Reservations = Reservations.flat();
            }
            else if (filter.type === 'withoutemail') {
                if (Reservations.length > 0) {
                    Reservations = Reservations.filter((res) => !res.contacts.some(c => c.type === 'Email'));
                }
                else {
                    Reservations.push(reservationFiltered.filter((res) => !res.contacts.some(c => c.type === 'Email')));
                }

                Reservations = Reservations.flat();
            }
            else if (filter.type === 'withoutname') {
                if (Reservations.length > 0) {
                    Reservations = Reservations.filter((res) => !res.firstName || !res.firstName);
                }
                else {
                    Reservations.push(reservationFiltered.filter((res) => !res.firstName || !res.firstName));
                }

                Reservations = Reservations.flat();
            }
            else {
                if (filter.type === 'tags') {
                    if (Reservations.length > 0) {
                        Reservations = Reservations.filter((res) => res[filter.type].find((tag) => tag.code === filter.val));
                    }
                    else {
                        Reservations.push(reservationFiltered.filter((res) => res[filter.type].find((tag) => tag.code === filter.val)))
                    }
                }
                else {
                    if (filter.type === 'fullName') {
                        if (Reservations.length > 0) {
                            Reservations = Reservations.filter((res) => res[filter.type] && (res[filter.type].toLowerCase()).includes(filter.val.toLowerCase()));
                        }
                        else {
                            Reservations.push(reservationFiltered.filter((res) => res[filter.type] && (res[filter.type].toLowerCase()).includes(filter.val.toLowerCase())));
                        }
                    }
                    else {
                        if (Reservations.length > 0) {
                            Reservations = Reservations.filter((res) => res[filter.type] === filter.val);
                        }
                        else {
                            Reservations.push(reservationFiltered.filter((res) => res[filter.type] === filter.val));
                        }
                    }
                }

                Reservations = Reservations.flat();
            }
        })

        return Reservations;
    }

    setFilters = (section, filter, listId, cachedDataFiltered) => {
        const filters = [...this.state.filters];

        const currFilter = filters.find((filt) => filt.type === section);
        cachedDataFiltered = this.getCachedDataFilterd(section, cachedDataFiltered, listId);

        if (currFilter && (currFilter.val === filter || !filter)) {
            if (section === 'list') {
                cachedDataFiltered = undefined;
            }

            filters.splice(filters.indexOf(currFilter), 1)
        }
        else {
            if (!currFilter) {
                if (section === 'list') { //filtro da lista tem que ser colocado em 1º
                    filters.unshift({ type: section, val: filter });
                }
                else filters.push({ type: section, val: filter });
            }
            else if (currFilter && currFilter.val !== filter) {
                currFilter.val = filter
            }
        }

        this.setState({ filters, cachedDataFiltered }, () => this.reservationsData());
    }

    getCachedDataFilterd = (section, cachedDataFiltered, listId) => {
        var reservations = cachedDataFiltered;

        //cachedDataFiltered === undefined  =>     sem filtro da lista
        //cachedDataFiltered === null       =>     não existem guests com os filtros selecionados
        //cachedDataFiltered === []         =>     c/ filtro da lista

        if (section === 'list') {
            if (cachedDataFiltered && cachedDataFiltered.length === 0) {
                const now = moment().format("YYYY-MM-DD");

                this.state.cachedData && this.state.cachedData.forEach(guest => {
                    const checkIn = moment(guest.checkIn).format("YYYY-MM-DD");
                    const checkOut = moment(guest.checkOut).format("YYYY-MM-DD");

                    if ((listId === 'checkInToday' && moment(checkIn).isSame(now)) ||
                        (listId === 'checkOutToday' && moment(checkOut).isSame(now)) ||
                        (listId === 'checkedIn' && moment(checkOut).isAfter(now) && moment(checkIn).isBefore(now))) {
                        reservations.push(guest);
                    }
                })

                if (reservations.length === 0) {
                    reservations = null;
                }
            }
        }
        else {
            reservations = this.state.cachedDataFiltered;
        }

        return reservations;
    }

    toggleExpanded = (name) => {
        this.setState(prevState => ({
            [name]: !prevState[name]
        }))
    }

    render() {
        const { block, checkinToday, checkoutToday, inHotel, generations, traveler, distributionByCountry, distributionByCountryCode, totalGuests, hotelFilter, filters, birthdaysToday, areTagsExpanded, bubbleChart, bubbleChartIdx, disabledHotelFilter, error } = this.state;
        const reservationStatus = getPMSReservationStatus(this.props.intl);

        const reservations = [
            {
                id: 'checkedIn',
                title: 'In-House',
                data: inHotel
            },
            {
                id: 'checkInToday',
                title: this.props.intl.formatMessage({ id: "Home.Check-inToday" }),
                data: checkinToday
            },
            {
                id: 'checkOutToday',
                title: this.props.intl.formatMessage({ id: "Home.Check-outToday" }),
                data: checkoutToday
            }
        ]

        var colorLegend = [
            {
                color: "#CF53FA",
                textColor: '#ffffff'
            },
            {
                color: "#FFBE2D",
                textColor: '#ffffff'
            },
            {
                color: "#0867FA",
                textColor: '#ffffff'
            },
            {
                color: "#4CC9F0",
                textColor: '#ffffff'
            }
        ];

        return (
            <Authorization
                perform="homepage:view"
                yes={() => (
                    <>
                        <StyledCard block={block} error={error}>
                            <div>
                                <Row className="align-items-center">
                                    <Col className="col-8 col-lg-6">
                                        <Row>
                                            <Col className='col-12 col-lg-4'>
                                                <SelectHotel
                                                    name={'hotelFilter'}
                                                    isClearable={!CheckAuthorization("user:restrictHotelFilter")}
                                                    icon={"icon-icon-hotel"}
                                                    onChangeFunc={(name, combo) => this.setState({ hotelFilter: combo ? combo.value : null }) }
                                                    placeholder={'Hotel'}
                                                    value={hotelFilter || ''}
                                                    params='?onlyFromHotelGroup=true'
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    {global.isMobile ?
                                        <Col className="text-right">
                                            <Button className="btn btn-host btn-sm" onClick={() => { this.setState({ filters: [] }, this.getData )}}>
                                                <i className="icon-icon-search"></i>
                                            </Button>
                                        </Col>
                                    :''}
                                    {filters.length > 0 &&
                                        <Col className='col-12 mt-3 col-lg-5 mt-lg-0'>
                                            <Row className='h-100' style={{ paddingLeft: '15px' }}>
                                                {filters.map((filter, k) =>
                                                    <div key={k} style={{ background: '#007bff', borderRadius: '5px', display: 'flex', margin: '2px', minWidth: '0', boxSizing: 'border-box', color: 'white' }}>
                                                        <div style={{
                                                            borderRadius: '2px', fontSize: '85%', overflow: 'hidden', padding: '3px', paddingLeft: '6px',
                                                            textOverflow: 'ellipsis', whiteSpace: 'nowrap', boxSizing: 'border-box', display: 'flex', alignItems: 'center'
                                                        }}>
                                                            <div>
                                                                {typeof filter.val === 'boolean' ? 
                                                                    <b><FormattedMessage id={`Home.${filter.type}:${filter.val}`} /> </b>
                                                                : 
                                                                    <>
                                                                        <b><FormattedMessage id={`Home.${filter.type}`} /> {filter.val ? ': ' : ''}</b>
                                                                        {filter.type === 'nationality' ?
                                                                            <span style={{ borderRadius: '3px', fontSize: '18px', marginLeft: '0.3rem' }} className={' mr-1 fa-md flag-icon flag-icon-' + filter.val.toLowerCase()} />
                                                                        : ` ${filter.val}` }
                                                                    </>
                                                                 }
                                                            </div>
                                                        </div>
                                                        <span style={{
                                                            alignItems: 'center', borderRadius: '2px', display: 'flex', paddingLeft: '4px',
                                                            paddingRight: '4px', height: '90%', boxSizing: 'border-box', cursor: 'pointer', fontWeight: 'bolder'
                                                        }} onClick={() => this.setFilters(filter.type, filter.val)}>
                                                            x
                                                        </span>
                                                    </div>
                                                )}
                                            </Row>
                                        </Col>
                                    }
                                    {!global.isMobile ?
                                        <Col className="text-right">
                                            <Button className="btn btn-host btn-sm" onClick={() => { this.setState({ filters: [] }, this.getData )}}>
                                                <i className="icon-icon-search"></i>
                                            </Button>
                                        </Col>
                                    :''}
                                </Row>
                            </div>

                            <Row className="my-3">

                                <Authorization
                                    perform="homePage:guestsList"
                                    yes={() => (
                                        <Col className='col-12 col-lg-6'>
                                            <GuestsList
                                                filters={filters}
                                                reservations={reservations}
                                                birthdaysToday={birthdaysToday}
                                                guestsIssues={this.state.guestsIssues}
                                                setFilters={this.setFilters}
                                                reservationStatus={reservationStatus}
                                                totalMainGuest={this.state.totalMainGuest}
                                                totalMailingConsent={this.state.totalMailingConsent}
                                                history={this.props.history}
                                                totalGuests={totalGuests}
                                            />
                                        </Col>
                                    )}
                                    no={() => <div></div>}
                                />

                                <Col className={CheckAuthorization("homePage:guestsList") && !global.isMobile ? "col-6 homeChartsCol" : "col-12"} style={{ gridTemplateRows: areTagsExpanded ? '1fr 1fr' : '1fr 105px 1fr' }}>
                                    <Row className='mt-3 mt-lg-0'>
                                        <Col className={areTagsExpanded || global.isMobile ? 'col-12' : 'col-6 pr-2'}>
                                            <Card className="border-0 shadow h-100" body>
                                                <Row>
                                                    <Col>
                                                        <Nav tabs className="border-0">
                                                            <NavItem>
                                                                <NavLink className={(this.state.bubbleChartIdx === 0 ? 'tab-border-host text-host' : '') + ' border-0 pt-0 cursor-pointer'} onClick={() => { this.setState({ bubbleChartIdx: 0 }) }}>
                                                                    <b><FormattedMessage id="Home.Tags" /></b>
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink className={(this.state.bubbleChartIdx === 1 ? 'tab-border-host text-host' : '') + ' border-0 pt-0 cursor-pointer'} onClick={() => { this.setState({ bubbleChartIdx: 1 }) }}>
                                                                    <b><FormattedMessage id="Home.channel" /></b>
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink className={(this.state.bubbleChartIdx === 2 ? 'tab-border-host text-host' : '') + ' border-0 pt-0 cursor-pointer'} onClick={() => { this.setState({ bubbleChartIdx: 2 }) }}>
                                                                    <b><FormattedMessage id="Home.segmentCode" /></b>
                                                                </NavLink>
                                                            </NavItem>
                                                        </Nav>
                                                    </Col>
                                                    {!global.isMobile ?
                                                        <Col className="col-1 text-right pr-4">
                                                            <i className={"fas text-host pointer mr-2 pt-2 " + (areTagsExpanded ? ' fa-compress-alt' : ' fa-expand-alt')} onClick={() => this.toggleExpanded('areTagsExpanded')} />
                                                        </Col>
                                                    :''}
                                                </Row>
                                                <TabContent style={{ height: (areTagsExpanded && !global.isMobile ? '420px' : '300px') }}>
                                                    <TabPane className="border-0 h-100 d-flex">
                                                        {bubbleChart[bubbleChartIdx].data?.length > 0 ?
                                                            <ReactBubbleChart
                                                                onClick={(e) => this.setFilters(bubbleChart[bubbleChartIdx].value, e._id)}
                                                                data={bubbleChart[bubbleChartIdx].data}
                                                                colorLegend={colorLegend}
                                                                selectedColor="#CF53FA"
                                                                selectedTextColor="#ffffff"
                                                            />
                                                        :''}
                                                    </TabPane>
                                                </TabContent>
                                            </Card>
                                        </Col>
                                        {!areTagsExpanded ?
                                            <Col className='col-12 col-lg-6 pl-3 pl-lg-2 mt-3 mt-lg-0'>
                                                <Card className="border-0 shadow h-100" body>
                                                    <h5 className="text-muted"><FormattedMessage id="Home.DistributionByGeneration" /></h5>
                                                    {generations && generations.map((gen, key) => {
                                                        const percentage = gen.total !== 0 ? `${((gen.total * 100) / totalGuests).toFixed(2)}%` : '0';

                                                        return <Row className="mb-2" key={key}>
                                                            <Col className="pr-1">
                                                                <b> {gen.name} </b> <br /> {gen.start} - {gen.end}
                                                            </Col>
                                                            <Col className="w-100 h-100 pl-0 d-flex align-items-center">
                                                                {gen.total > 0 ?
                                                                    <div style={{ cursor: 'pointer' }} onClick={() => this.setFilters('generation', gen.name)} className="d-flex h-100 align-items-center">
                                                                        <div style={{ width: percentage, borderRadius: '5px', height: '25px' }} className="bg-host text-right mr-2">
                                                                            <span className="ml-5" style={{ color: 'transparent' }}>{gen.total} </span>
                                                                        </div>
                                                                        {gen.total}
                                                                    </div>
                                                                    : ''}
                                                            </Col>
                                                        </Row>
                                                    })}
                                                </Card>
                                            </Col>
                                        : ''}
                                    </Row>

                                    {!areTagsExpanded &&
                                        <Card className="border-0 shadow mt-3" body >
                                        <h5 className="text-muted"><FormattedMessage id="Home.DistributionByTraveler" /></h5>

                                        <Row>
                                            <Col className="col-6 col-lg-4">
                                                <Row className="align-items-center">
                                                    <Col className='col-3 col-lg-2'><Badge className="bg-cyan-light color-cyan p-2"> <i className="fas fa-user" /> </Badge></Col>
                                                    <Col className="col-7 col-lg-8 pl-0 pl-lg-3"><Progress max={totalGuests} value={traveler[0].total} color="cyan" style={{ height: '9px', borderRadius: '10px' }} /></Col>
                                                    <Col onClick={() => traveler[0].total > 0 && this.setFilters('distribution', 'Solo')} style={traveler[0].total > 0 ? { cursor: 'pointer' } : {}} className='col-2 pl-0 px-lg-0'>
                                                        <span > {traveler[0].total} </span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className="col-6 col-lg-4">
                                                <Row className="align-items-center">
                                                    <Col className='col-3 col-lg-2'><Badge className="bg-yellow-light color-yellow p-2"> <i className="fas fa-user-friends" /> </Badge></Col>
                                                    <Col className="col-7 col-lg-8 pl-0 pl-lg-3"><Progress max={totalGuests} value={traveler[1].total} color="yellow" style={{ height: '9px', borderRadius: '10px' }} /></Col>
                                                    <Col onClick={() => traveler[1].total > 0 && this.setFilters('distribution', 'Couple')} style={traveler[1].total > 0 ? { cursor: 'pointer' } : {}} className='col-2 pl-0 px-lg-0'>
                                                        <span > {traveler[1].total} </span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className='col-12 col-lg-4 mt-2 mt-lg-0 text-center text-lg-left'>
                                                <Row className="align-items-center">
                                                    <Col className='col-3 col-lg-2'><Badge className="bg-purple-light color-purple p-2"> <i className="fas fa-users" /> </Badge></Col>
                                                    <Col className="col-7 col-lg-8 pl-0 pl-lg-3"><Progress max={totalGuests} value={traveler[2].total} color="purple" style={{ height: '9px', borderRadius: '10px' }} /></Col>
                                                    <Col onClick={() => traveler[2].total > 0 && this.setFilters('distribution', 'Crowd')} style={traveler[2].total > 0 ? { cursor: 'pointer' } : {}} className='col-2 px-lg-0'>
                                                        <span > {traveler[2].total} </span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                    }

                                    <Card className="border-0 shadow mt-3" body>
                                        <h5 className="text-muted"> <FormattedMessage id="Home.DistributionByCountry" /></h5>
                                        <GeoChart
                                            setFilters={this.setFilters}
                                            data={distributionByCountry}
                                            label={distributionByCountryCode}
                                            page={'home'}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        </StyledCard>
                    </>
                )}
                no={() => ""}
            />
        );
    }
}

export default injectIntl(Home);