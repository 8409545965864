import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { StyledCard } from "../Base/CommonUIComponents";

import Authorization from '../Base/Authorization';
import { getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';

export class MarketingConfigEgoi extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            hotelList: [],
            hotel: null,
            balance: null
        };
    }

    componentDidMount() {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    this.setState({ balance: data.balance_info, error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Marketing/v1/egoi/myaccount`)
    }

    render() {
        const { block, error, balance } = this.state;

        return (

            <StyledCard block={block} error={error} >

                <Row className="mb-2">
                    <Col>
                        <h5 ><i className="fa-lg icon-icon-e-goi-config mr-2"></i><FormattedMessage id="NavMenu.ConfigEgoi" /></h5>
                    </Col>
                    <Col className="text-right">
                        {
                            balance !== null && balance !== undefined ?
                                <FormattedMessage id="MarketingConfigEgoi.Balance" values={{ balance: balance.balance, currency: balance.currency }} />
                                :
                                <div />
                        }
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Col>
                        <Card className="border-0 shadow py-3">
                            <CardBody className="text-center">
                                <Link to="/CampaignList"><b> Campaigns</b></Link>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="border-0 shadow py-3">
                            <CardBody className="text-center">
                                <Link to="/EgoiList"><b> Lists</b></Link>
                                
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="border-0 shadow py-3">
                            <CardBody className="text-center">
                                <Link to="/AlertsList"> <b>Alerts</b></Link>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col>
                        <Card className="border-0 shadow py-3">
                            <CardBody className="text-center">
                                <Link to="/SendersList"> <b>Senders</b></Link>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="border-0 shadow py-3">
                            <CardBody className="text-center">
                                <Link to="/TagList"> <b>Tags</b></Link>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="border-0 shadow py-3">
                            <CardBody className="text-center">
                                <Link to="/FormList"> <b>Forms</b></Link>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col>
                        <Card className="border-0 shadow py-3">
                            <CardBody className="text-center">
                                <Link to="/TransactionalTemplateList"> <b> Templates</b></Link>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="border-0 shadow py-3">
                            <CardBody className="text-center">
                                <Link to="/EgoiWebhookList"> <b>Webhooks</b></Link>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="border-0 shadow py-3">
                            <CardBody className="text-center">
                                <Link to="/ShortenerList"> <b>Shortener</b></Link>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col className="col-4">
                        <Card className="border-0 shadow py-3">
                            <CardBody className="text-center">
                               <Link to="/DomainList"> <b>Domains</b></Link>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="col-4">
                        <Card className="border-0 shadow py-3">
                            <CardBody className="text-center">
                                <Link to="/AutomationList"> <b>Automation</b></Link>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="col-4">
                        <Card className="border-0 shadow py-3">
                            <CardBody className="text-center">
                                <Link to="/Account"> <b>Account</b></Link>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-4">
                        <Card className="border-0 shadow py-3">
                            <CardBody className="text-center">
                                <Link to="/SuppressionList"> <b>Suppression List</b></Link>
                            </CardBody>
                        </Card>
                    </Col>
                    <Authorization
                        perform="marketingConfigEgoi:serviceActivion:view"
                        yes={() => (
                            <Col className="col-4">
                                <Card className="border-0 shadow py-3">
                                    <CardBody className="text-center">
                                        <Link to="/EgoiServiceActivation"> <b>Service Activation</b></Link>
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                        no={() => <div></div>}
                    />
                </Row>
            </StyledCard>
        );
    }
}
export default injectIntl(MarketingConfigEgoi)
