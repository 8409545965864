import React, { useRef, useCallback, useState, useEffect } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import ReactFlow, {
    Background,
    Controls,
    addEdge,
    useNodesState,
    useEdgesState,
    useReactFlow,
    Position,
    MarkerType
} from 'react-flow-renderer';
import dagre from 'dagre';

import NodeMenu from './NodeMenu';
import CustomConditionNode from './CustomConditionNode';
import CustomNode from './CustomNode';
import CustomEdge from './CustomEdge';
import SelectNewNodeMenu from './SelectNewNodeMenu';

let id = 0;
const getId = () => `dndnode_${id++}`;

const customNodeTypes = { 
    'default-condition': CustomConditionNode,
    'default': CustomNode,
    'input': CustomNode
};
const edgeTypes = { 'buttonEdge': CustomEdge };
const nodeTypes= [
    {
        label: <FormattedMessage id="CustomerJourney.Actions" />, 
        options:[
            { label: <FormattedMessage id="CustomerJourney.smsElement" />,value:'default-sms' }, 
            { label: <FormattedMessage id="CustomerJourney.emailElement" />,value:'default-email' },
        ]
    },
    {
        label: <FormattedMessage id="CustomerJourney.Conditions" />, 
        options:[
            { label: <FormattedMessage id="CustomerJourney.waitUntilElement" />,value:'default-condition-waitUntil' }, 
            { label: <FormattedMessage id="CustomerJourney.waitDurationElement" />,value:'default-condition-waitDuration' },
            { label: <FormattedMessage id="CustomerJourney.randomSplitElement" />,value:'default-condition-randomSplit' },
            { label: <FormattedMessage id="CustomerJourney.decisionSplitElement" />,value:'default-condition-decisionSplit' }
        ]
    }
];

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 80;
const nodeHeight = 50;

const getLayoutedElements = (nodes, edges) => {
    dagreGraph.setGraph({
        rankdir: 'LR', // Alinhamento da esquerda para a direita
        ranksep: 100, // Espaçamento vertical entre os nodes
        nodesep: 50, // Espaçamento horizontal entre os nodes
    });

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = 'left';
        node.sourcePosition = 'right';

        node.position = {
            x: nodeWithPosition.x - nodeWidth / 2,
            y: nodeWithPosition.y - nodeHeight / 2,
        };

        return node;
    });

    return { nodes, edges };
};

function JourneyBuilderFlow ({...props}){
    const { project } = useReactFlow();
    const reactFlowWrapper = useRef(null);
    const connectingNodeId = useRef(null);
    const [menu, setMenu] = useState(null);
    const [nodes, setNodes, onNodesChange] = useNodesState(props.nodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(props.edges);
    const [selectedNodeTypeMenu, setSelectedNodeTypeMenu] = useState(null);
    const [sourceHandleId, setSourceHandleId] = useState(null);
    const [counter, setCounter] = useState(0);

    const createNode = (type, position) => {
        const id = getId();

        if(!type) return;
    
        let nodePrimaryType = type.split('-') && type.split('-')[0];
        let nodeSubType = type.split('-') && type.split('-')[1];
        let nodeConditionType = null;
    
        if (!nodePrimaryType) {
            nodePrimaryType = 'default';
        }

        if(nodeSubType){
            nodeConditionType = type.split('-') && type.split('-')[2]
        }
    
        const newNode = {
            id,
            type: nodePrimaryType === 'input' ? nodePrimaryType : nodePrimaryType === 'default' && nodeSubType === 'condition' ? `${nodePrimaryType}-${nodeSubType}` : nodePrimaryType,
            typeUnchanged: type,
            position,
            style: props.getNodeStyle(type),
            data: { 
                label: type ? props.getNodeIcon(type) : <i className="fas fa-ban"></i>,
                isEditable: true,
                completedAt: null,
                numberOfConditions: nodeConditionType === 'waitDuration' ? 1 : 2
            },
            ...nodeEdgePositions,
        };
    
        return newNode;
    };

    const applyLayout = useCallback(() => {
        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(nodes, edges);
        setNodes([...layoutedNodes]);
        setEdges([...layoutedEdges]);
        setCounter(prevCounter => prevCounter + 1);
    }, [nodes, edges, setNodes, setEdges]);

    const nodeEdgePositions = {
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
    };

    const onConnectStart = useCallback((event, { nodeId, handleId }) => {
        connectingNodeId.current = nodeId;
        setSourceHandleId(handleId); // HandleId that started the connection
    }, []);
    
    const onConnectStop = useCallback(
        (event) => {
            const targetIsPane = event.target.classList.contains('react-flow__pane');
    
            if (targetIsPane) {
                const pane = reactFlowWrapper.current.getBoundingClientRect();
                if(!pane) return;

                const typeMenuBounds = {
                    x: event.clientX - pane.left,
                    y: event.clientY - pane.top
                };
    
                const menuWidth = 300;
                const menuHeight = 450;
    
                let adjustedLeft = typeMenuBounds.x;
                let adjustedTop = typeMenuBounds.y;
    
                if (adjustedLeft + menuWidth > pane.width) {
                    adjustedLeft = pane.width - menuWidth;
                }
    
                if (adjustedTop + menuHeight > pane.height) {
                    adjustedTop = pane.height - menuHeight;
                }
    
                setSelectedNodeTypeMenu({ x: adjustedLeft, y: adjustedTop });
            }
        },
        []
    );

    const onNodeTypeSelect = (type) => {
        if (!selectedNodeTypeMenu) return;
    
        const position = project({
            x: selectedNodeTypeMenu.x,
            y: selectedNodeTypeMenu.y,
        });

        if (connectingNodeId && connectingNodeId.current) {
            const existingEdges = edges && edges.filter(
                (edge) => edge.source === connectingNodeId.current && edge.sourceHandle === sourceHandleId
            );

            if (existingEdges && existingEdges.length > 0) {
                setSelectedNodeTypeMenu(null);
                setSourceHandleId(null);
                return;
            }

            const newNode = createNode(type, position);
            if (!newNode) return;
        
            setNodes((nds) => nds.concat(newNode));
            props.changeNodes(newNode, 'add');

            const newEdge = {
                id: `edge-${connectingNodeId.current}-${newNode.id}`,
                source: connectingNodeId.current,
                sourceHandle: sourceHandleId, 
                target: newNode.id,
                type: 'buttonEdge',
                animated: true,
                style: { strokeWidth: 2 },
                markerEnd: {
                    type: MarkerType.Arrow,
                },
                data: {
                    initialEdge: false,
                    onDelete: (id) => {
                        props.changeEdges(newEdge, 'delete', id);
                        setEdges((eds) => eds.filter((e) => e.id !== id));
                    },
                    edges: props.edges,
                },
            };
            props.changeEdges(newEdge, 'add');
            setEdges((eds) => eds.concat(newEdge));
            connectingNodeId.current = null;
        }
    
        setSelectedNodeTypeMenu(null);
        setSourceHandleId(null);
    };

    const onConnect = useCallback(
        (params) => {
            setEdges((eds) => {
                const existingEdges = eds.filter(
                    (edge) => edge.source === params.source && edge.sourceHandle === params.sourceHandle
                );
    
                if (existingEdges.length > 0 || params.source === params.target) {
                    return eds;
                }
    
                const newEdge = {
                    ...params,
                    id: `edge-${params.source}-${params.target}`,
                    type: 'buttonEdge',
                    animated: true,
                    style: { strokeWidth: 2 },
                    markerEnd: {
                        type: MarkerType.Arrow,
                    },
                    data: {
                        initialEdge: false,
                        onDelete: (id) => {
                            props.changeEdges(newEdge, 'delete', id);
                            setEdges((eds) => eds.filter((e) => e.id !== id));
                        },
                        edges: props.edges,
                    },
                };
                props.changeEdges(newEdge, 'add');
                return addEdge(newEdge, eds);
            });
        },
        [setEdges]
    );

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback(
        (event) => {
            event.preventDefault();
    
            const type = event.dataTransfer.getData('application/reactflow');
            if (typeof type === 'undefined' || !type) {
                return;
            }
    
            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            const position = project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });
    
            const newNode = createNode(type, position);
            if(!newNode) return;
            
            props.changeNodes(newNode, 'add');
            setNodes((nds) => nds.concat(newNode));
        },
        [project, setNodes]
    );

    const onNodeContextMenu = useCallback(
        (event, node) => {
            event.preventDefault();

            const pane = reactFlowWrapper.current.getBoundingClientRect();
            const nodeBounds = {
                x: event.clientX - pane.left,
                y: event.clientY - pane.top
            };

            const menuWidth = 200;
            const menuHeight = 200;

            const adjustedLeft = nodeBounds.x + menuWidth > pane.width
                ? pane.width - 100
                : nodeBounds.x;

            const adjustedTop = nodeBounds.y + menuHeight > pane.height
                ? pane.height - 100
                : nodeBounds.y;

            setMenu({
                id: node.id,
                top: adjustedTop,
                left: adjustedLeft,
            });
        },
        [setMenu]
    );

    const onPaneClick = useCallback(() => {
        setMenu(null);
        setSelectedNodeTypeMenu(null);
    }, []);

    const onNodeDragStart = useCallback(() => {
        setMenu(null);
        setSelectedNodeTypeMenu(null);
    }, []);

    const onNodeDragStop = useCallback(() => {
        props.updateNodesList(nodes);
    }, [nodes, props]);

    const onNodesDelete = useCallback(
        (deleted) => {
            deleted.forEach((node) => {
                props.changeNodes(node, 'delete');
            });
            setNodes((nds) => nds.filter((node) => !deleted.some((d) => d.id === node.id)));
        },
        [setNodes, props]
    );

    const onEdgesDelete = useCallback(
        (deleted) => {
            deleted.forEach((edge) => {
                props.changeEdges(edge, 'delete', edge.id);
            });
            setEdges((eds) => eds.filter((edge) => !deleted.some((d) => d.id === edge.id)));
        },
        [setEdges, props]
    );

    useEffect(() => {
        if (counter === 0 && nodes.length && edges.length && props.journeyRunId !== null && props.journeyRunId !== undefined) {
            applyLayout();
        }
    }, [applyLayout, counter, nodes.length, edges.length]);
    
    return (
        <div style={{ height: '80vh', width: '100%' }} ref={reactFlowWrapper}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onNodesDelete={onNodesDelete}
                onEdgesDelete={onEdgesDelete}
                onConnectStart={onConnectStart}
                onConnectStop={onConnectStop}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onNodeContextMenu={onNodeContextMenu}
                onPaneClick={onPaneClick}
                onNodeDragStart={onNodeDragStart}
                onNodeDragStop={onNodeDragStop}
                nodeEdgePositions={nodeEdgePositions}
                nodeTypes={customNodeTypes}
                edgeTypes={edgeTypes}
                width={1000}
                height={500}
                nodesDraggable={!props.journeyRunId}
                nodesConnectable={!props.journeyRunId}
                nodesFocusable={!props.journeyRunId}
                edgesFocusable={!props.journeyRunId}
                elementsSelectable={!props.journeyRunId}
                deleteKeyCode={["Delete", "Backspace"]}
                fitView={props.journeyRunId !== null && props.journeyRunId !== undefined}
            >
                {nodeTypes && selectedNodeTypeMenu &&
                    <SelectNewNodeMenu 
                        nodeTypes={nodeTypes} 
                        selectedNodeTypeMenu={selectedNodeTypeMenu}
                        onNodeTypeSelect={onNodeTypeSelect}
                    />
                }
                {menu && (
                    <NodeMenu 
                        onClick={onPaneClick} 
                        toggleConfigureNode={() => props.toggleConfigureNode(nodes, menu.id)}
                        nodes={nodes}
                        edges={edges}
                        changeEdges={props.changeEdges}
                        changeNodes={props.changeNodes}
                        {...menu} 
                    />
                )}
                {!props.journeyRunId ?
                    <Button className={'layoutFlowBtn'} onClick={applyLayout}>
                        <FormattedMessage id="CustomerJourney.FormatLayoutFlow" />
                    </Button>
                :''}
                <Background />
                <Controls
                    showInteractive={false}
                />
            </ReactFlow>
        </div>
    );
};

export default injectIntl(JourneyBuilderFlow);
