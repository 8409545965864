import React, { Component } from 'react'
import { Row, Col, Button, Form, Input, FormGroup } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BlankCard, StyledModal } from '../Base/CommonUIComponents';
import CustomSelect from '../Base/CustomSelect';
import { putAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { getSalesConfigTypes } from './SalesProcessFunctions';


class SalesProcessConfigModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            config: this.props.config || {}
        };
    }

    handleSelect = (name, combo) => {
        this.setState({
            config: {
                ...this.state.config,
                [name]: combo ? combo.value : null
            }
        })
    }

    handleComboSelect = (name, combo) => {
        this.setState({
            config: {
                ...this.state.config,
                [name]: combo ? combo.map(x => x.value) : null
            }
        })
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            config: {
                ...this.state.config,
                [name]: value
            }
        })
    }

    saveConfig = (e) => {
        e.preventDefault();

        this.setState({ block: true }, () => {
            const payload = { ...this.state.config };

            putAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        this.setState({ block: false }, () => handleNotification(data));
                        return;
                    }

                    this.setState({ block: false }, () =>
                        handleNotification(data, <FormattedMessage id="SalesSource.SavedSuccessfully" />, <FormattedMessage id="generic.success" />)
                    );

                    this.props.toggleModal();
                    this.props.getConfigList();
                    return;
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/communication/config`, payload);
        });
    }

    render() {
        const { block, error, config } = this.state;
        const { modal, toggleModal } = this.props;

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="xl">
                <BlankCard block={block} error={error}>
                    <Form onSubmit={this.saveConfig}>
                        <Row className="pb-3">
                            <Col>
                                <h4><FormattedMessage id={`SalesProcess.${config.id ? 'Edit' : 'Create'}Config`} /></h4>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host" type="submit">
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>

                        <FormGroup row>
                            <Col sm={6}>
                                <div className="title-sm"> <FormattedMessage id="SalesProcess.ConfigName" /> </div>
                                <div>
                                    <Input
                                        required
                                        type="text"
                                        name="name"
                                        placeholder=""
                                        value={config.name || ''}
                                        onChange={this.handleChange}
                                        maxLength={200}
                                    />
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="title-sm"> <FormattedMessage id="SalesProcess.HotelGroupName" /> </div>
                                <div>
                                    <Input
                                        type="text"
                                        name="hotelGroupName"
                                        placeholder=""
                                        value={config.hotelGroupName || ''}
                                        onChange={this.handleChange}
                                        maxLength={200}
                                    />
                                </div>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col sm={6}>
                                <div className="title-sm"> <FormattedMessage id="SalesProcess.HotelGroupLogoUrl" /> </div>
                                <div>
                                    <Input
                                        type="text"
                                        name="hotelGroupLogoUrl"
                                        placeholder=""
                                        value={config.hotelGroupLogoUrl || ''}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="title-sm"> <FormattedMessage id="generic.Type" /> </div>
                                <div>
                                    <CustomSelect
                                        required
                                        name="type"
                                        placeholder=""
                                        isSearchable
                                        options={getSalesConfigTypes(this.props.intl)}
                                        value={getSalesConfigTypes(this.props.intl).find(({ value }) => config.type === value)}
                                        onChange={(combo) => this.handleSelect('type', combo)}
                                    />
                                </div>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col>
                                <div className="title-sm"> <FormattedMessage id="HotelList.title" /> </div>
                                <div>
                                    <CustomSelect
                                        required
                                        name="hotelIds"
                                        placeholder=""
                                        isSearchable
                                        isMulti
                                        options={global.hotelList}
                                        onChange={(combo) => this.handleComboSelect('hotelIds', combo)}
                                        value={global.hotelList?.filter(hotel => config.hotelIds?.includes(hotel.value))}
                                    />
                                </div>
                            </Col>
                        </FormGroup>
                    </Form>
                </BlankCard>
            </StyledModal>
        )
    }
}

export default injectIntl(SalesProcessConfigModal);