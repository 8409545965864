import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, Col, Row, Button, Form } from 'reactstrap';
import { CommonHelper, StyledCard } from "../../Base/CommonUIComponents";
import { handleNotification } from '../../Base/Notification';
import { DateRangePicker } from 'react-dates';
import { getAPI } from '../../Base/API';
import { Line, Bar } from 'react-chartjs-2';
import moment from 'moment';


export class Engagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            startDate: moment().subtract(6, 'days'),
            endDate: moment(),
            dailyChart: { labels: [], datasets: [] },
            metrics: [
                { value: 'score', label: this.props.intl.formatMessage({ id: "SendGridEngagement.score" }), score: 0, color: '#0665ff' },
                { value: 'engagement_recency', label: this.props.intl.formatMessage({ id: "SendGridEngagement.engagement_recency" }), score: 0, color: '#6EDE8A' },
                { value: 'open_rate', label: this.props.intl.formatMessage({ id: "SendGridEngagement.open_rate" }), score: 0, color: '#CF53FA' },
                { value: 'bounce_classification', label: this.props.intl.formatMessage({ id: "SendGridEngagement.bounce_classification" }), score: 0, color: '#FFC17D' },
                { value: 'bounce_rate', label: this.props.intl.formatMessage({ id: "SendGridEngagement.bounce_rate" }), score: 0, color: '#E58823' },
                { value: 'spam_rate', label: this.props.intl.formatMessage({ id: "SendGridEngagement.spam_rate" }), score: 0, color: '#F38375' }
            ]
        };
    }

    componentDidMount() {
        this.getEngagementQuality();
    }

    getEngagementQuality = (e) => {
        if (e) e.preventDefault();

        this.setState({ block: true });
        const { startDate, endDate } = this.state;

        let params = `?from=${moment(startDate).format("YYYY-MM-DD")}&to=${moment(endDate).format("YYYY-MM-DD")}&userName=${this.props.userName}`; 
        
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.buildChartData(data.response);
            }

            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SendGrid/v1/engagementQuality/scores` + params);
    }

    buildChartData = (data) => {
        let { dailyChart, metrics } = this.state;
        
        dailyChart.labels = [];
        dailyChart.datasets = metrics.map(metric => ({
            label: metric.label,
            data: new Array(moment(this.state.endDate).diff(this.state.startDate, 'days') + 1).fill(0),
            borderColor: metric.color,
            backgroundColor: metric.color
        }));

        if (data) {
            data.result && data.result.forEach((date, index) => {
                dailyChart.labels.push(moment(date.date).format("YYYY-MM-DD"));

                if (date.metrics) {
                    metrics.forEach((metric, key) => {
                        if (metric.value === "score") {
                            dailyChart.datasets[key].data[index] += date[metric.value];
                            metric.score += date[metric.value];
                        }
                        else {
                            dailyChart.datasets[key].data[index] += date.metrics[metric.value];
                            metric.score += date.metrics[metric.value];
                        }
                    })
                }
            })
        }

        this.setState({ dailyChart, metrics });
    }

    
    render() {
        const { block, error, metrics, dailyChart } = this.state;
        const { page } = this.props;

        const optionsLine = {
            type: 'line',
            maintainAspectRatio: false,
            legend: {
                display: true,
                position: "top"
            },
            scales: {
                x: { display: true },
                y: {
                    beginAtZero: true, min: 0, max: 5,
                    ticks: {
                        stepSize: 1
                    },
                    grid: {
                        display: false
                    },
                }
            },
            plugins: {
                datalabels: { display: false }
            }
        }

        const help = <div>
            <div> <b><FormattedMessage id="SendGridEngagement.score" /> </b> - <FormattedMessage id="SendGridEngagement.scoreHelp" /></div>
            <div> <b><FormattedMessage id="SendGridEngagement.engagement_recency" /></b> - <FormattedMessage id="SendGridEngagement.engagement_recencyHelp" /></div>
            <div> <b><FormattedMessage id="SendGridEngagement.open_rate" /></b> - <FormattedMessage id="SendGridEngagement.open_rateHelp" /></div>
            <div> <b><FormattedMessage id="SendGridEngagement.bounce_classification" /></b> - <FormattedMessage id="SendGridEngagement.bounce_classificationHelp" /></div>
            <div> <b><FormattedMessage id="SendGridEngagement.bounce_rate" /></b> - <FormattedMessage id="SendGridEngagement.bounce_rateHelp" /></div>
            <div> <b><FormattedMessage id="SendGridEngagement.spam_rate" /></b> - <FormattedMessage id="SendGridEngagement.spam_rateHelp" /></div>
        </div>

        return (
            <StyledCard block={block} error={error} >
                <Row className="mb-4 py-1">
                    <Col>
                        <h5> <i className={page?.icon} /> {page?.name} </h5>
                    </Col>
                    <Col>
                        <CommonHelper id="SendGridEngagement" help={help} />
                    </Col>
                </Row>

                <Form onSubmit={this.getEngagementQuality}>
                    <Row className="align-items-center mb-3">
                        <Col>
                            <DateRangePicker
                                startDate={this.state.startDate}
                                startDateId="startDate"
                                isOutsideRange={day => day > moment() || day < moment().subtract(90, 'days')}
                                endDate={this.state.endDate}
                                endDateId="endDate"
                                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                required
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className=" btn btn-host btn-sm" type="submit">
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <Row>
                    <Col>
                        <Card className="bg-white shadow border-0" body style={{ height: '60vh' }}>
                            <Line data={dailyChart} options={optionsLine} />
                        </Card>
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}

export default injectIntl(Engagement)