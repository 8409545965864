import React, { Component } from 'react'
import { StyledCard } from '../../Base/CommonUIComponents'
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { Col, Row } from 'reactstrap';

export default class TwilioBilling extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            billing: []
        };
    }

    componentDidMount() {
        this.getBilling();
    }

    getBilling = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }

                if(data.response && data.response.length > 0){
                    this.setState({ twilioConfig: data.response[0], block: false });
                    return;
                }

                this.setState({ block: false });
            }
        }, `/api/gms/Twilio/v1/subaccount/billing`);
    }

    render() {
        const { page } = this.props;
        const { block, error } = this.state;

        return (
            <StyledCard block={block} error={error}>
                <Row className="mb-4 py-1">
                    <Col>
                        <h5> <i className={page?.icon} /> {page?.name} </h5>
                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                </Row>
            </StyledCard>
        )
    }
}
