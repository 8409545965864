import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Col, Form, Input, Modal, ModalBody, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { StyledCard } from "../Base/CommonUIComponents";
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { deleteAPI, getAPI, patchAPI, postAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import CustomSelect from "../Base/CustomSelect";
import BlockUi from 'react-block-ui';
import InputGPT from '../Base/InputGPT';
import moment from 'moment-timezone';
import GoogleAnonymousProfile from './GoogleAnonymousProfile';
import GenericTextArea from '../Base/GenericTextArea';
import GooglePost from './GooglePost';
import ConfirmActionModal from '../Base/ConfirmActionModal';

class GoogleBusinessPosts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            postsList: [],
            createModal: false,
            activeTab: '0',
            newPost: {
                topicType: 'STANDARD'
            },
            currentImage: {},
            focusedInput: null,
            focusedInput2: null,
            startDate: null,
            endDate: null,
            optionalFieldsOpen: false,
            deleteModal: false,
            selectedPost: null
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedLocation !== this.props.selectedLocation) {
            this.getPosts();
        }
    }

    componentDidMount() {
        this.getPosts();
    }

    toggleCreateModal = () => {
        const { createModal } = this.state;
        if(createModal === true) {
            this.setState({ createModal: !createModal, newPost: { topicType: 'STANDARD' } });
        }
        else{
            this.setState({ createModal: !createModal });
        }
    }

    getPosts = () => {
        const { accountId, selectedLocation } = this.props;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    this.setState({ error: errorMessage, block: false, accountId }, () => handleNotification(data));
                }
                else {
                    this.setState({ postsList: data.response[0].localPosts, nextPageToken: data.response[0].nextPageToken, error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Google/v1/posts/account/${accountId}/locations/${selectedLocation}/list`)
    }

    toggleTab = (tab) => {
        const { newPost, activeTab } = this.state;

        if (activeTab !== tab) {
            if(tab === '0') newPost.topicType = 'STANDARD';
            if(tab === '1') newPost.topicType = 'OFFER';
            if(tab === '2') newPost.topicType = 'EVENT';
            this.setState({ activeTab: tab, newPost });
        }
    }

    handleSourceUrl = (e) => {
        const value = e?.target?.value;
        const name = e?.target?.name;
        this.setState({ currentImage: {...this.state.currentImage, [name]: value } });
    }

    submitImage = () => {
        const { newPost, currentImage } = this.state;
        
        if(!currentImage || !currentImage.sourceUrl || !currentImage.mediaFormat) return;

        if(!newPost.media)
            newPost.media = [];
            
        newPost.media.push(this.state.currentImage);

        this.setState({ newPost, currentImage: {} });
    }

    getGPTPrompt = () => {

    }

    handleDescription = (e) => {
        const value = e?.target?.value;
        this.setState({ newPost: { ...this.state.newPost, summary: value } });
    }
    
    handleButton = (combo) => {
        const value = combo?.value;
        this.setState({ newPost: { ...this.state.newPost, callToAction: {...this.state.newPost?.callToAction, actionType: value } } }); 
    }

    handleButtonUrl = (e) => {
        const value = e?.target?.value;
        this.setState({ newPost: { ...this.state.newPost, callToAction: {...this.state.newPost?.callToAction, url: value } } }); 
    }

    deleteImage = (index) => {
        const { newPost } = this.state;
        newPost.media.splice(index, 1);
        this.setState({ newPost });
    };

    toggleOptionalFields = () => {
        this.setState({ optionalFieldsOpen: !this.state.optionalFieldsOpen });
    }

    handleOfferTitle = (e) => {
        const value = e?.target?.value;
        this.setState({ newPost: { ...this.state.newPost, event: {...this.state.newPost?.event, title: value } } });
    }

    handleOfferDates = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate, newPost: { ...this.state.newPost, event: {...this.state.newPost?.event, schedule: {
            ...this.state.newPost?.event?.schedule,
            startDate: {
                "year": startDate?.format('YYYY'),
                "month": startDate?.format('MM'),
                "day": startDate?.format('DD')
            },
            endDate: {
                "year": endDate?.format('YYYY'),
                "month": endDate?.format('MM'),
                "day": endDate?.format('DD')
            }
            } } } });
    }

    handleVoucherCode = (e) => {
        const value = e?.target?.value;
        this.setState({ newPost: { ...this.state.newPost, offer: {...this.state.newPost?.offer, couponCode: value } } });
    }

    handleLinkToRedeem = (e) => {
        const value = e?.target?.value;
        this.setState({ newPost: { ...this.state.newPost, offer: {...this.state.newPost?.offer, redeemOnlineUrl: value } } });
    }

    handleTermsAndConditions = (e) => {
        const value = e?.target?.value;
        this.setState({ newPost: { ...this.state.newPost, offer: {...this.state.newPost?.offer, termsConditions: value } } });
    }

    handleStartHours = (e) => {
        const value = e?.value;
        this.setState({ newPost: { ...this.state.newPost, event: {...this.state.newPost?.event, schedule: {
            ...this.state.newPost?.event?.schedule,
            startTime: {
                ...this.state.newPost?.event?.schedule?.startTime,
                hours: value
            }
        } } } });
    }

    handleEndHours = (e) => {
        const value = e?.value;
        this.setState({ newPost: { ...this.state.newPost, event: {...this.state.newPost?.event, schedule: {
            ...this.state.newPost?.event?.schedule,
            endTime: {
                ...this.state.newPost?.event?.schedule?.endTime,
                hours: value
            }
        } } } });
    }

    handleStartMin = (e) => {
        const value = e?.value;
        this.setState({ newPost: { ...this.state.newPost, event: {...this.state.newPost?.event, schedule: {
            ...this.state.newPost?.event?.schedule,
            startTime: {
                ...this.state.newPost?.event?.schedule?.startTime,
                minutes: value
            }
        } } } });
    }

    handleEndMin = (e) => {
        const value = e?.value;
        this.setState({ newPost: { ...this.state.newPost, event: {...this.state.newPost?.event, schedule: {
            ...this.state.newPost?.event?.schedule,
            endTime: {
                ...this.state.newPost?.event?.schedule?.endTime,
                minutes: value
            }
        } } } });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { newPost } = this.state;
        if(newPost.createTime) this.updatePost();
        else this.createPost();
    }

    updatePost = () => {
        const { accountId, selectedLocation } = this.props;
        const { newPost } = this.state;
        
        this.setState({ block: true }, () =>
            patchAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        errorMessage.push({ message: data.errors[0].message, messageType: 'danger' });
                        this.setState({ createModal: false, error: errorMessage, block: false }, handleNotification(data));
                    }
                    else {
                        this.setState({ createModal: false, block: false }, () => this.getPosts());
                    }
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/Google/v1/posts/account/${accountId}/locations/${selectedLocation}/Update?postName=${newPost.name}`, newPost)
        );
    }

    createPost = () => {
        const { accountId, selectedLocation } = this.props;
        const { newPost } = this.state;
        
        this.setState({ block: true }, () =>
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        errorMessage.push({ message: data.errors[0].message, messageType: 'danger' });
                        this.setState({ createModal: false, error: errorMessage, block: false }, handleNotification(data));
                    }
                    else {
                        this.setState({ createModal: false, block: false }, () => this.getPosts());
                    }
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/Google/v1/posts/account/${accountId}/locations/${selectedLocation}/list`, newPost)
        );
    }

    toggleEdit = (postName) => {
        const { postsList } = this.state;
        const newPost = postsList.find(({name}) => name === postName);
        const activeTab = newPost.topicType === 'STANDARD' ? '0' : newPost.topicType === 'OFFER' ? '1' : '2';
        
        const startDate = newPost?.event?.schedule?.startDate &&
            moment(`${newPost.event.schedule.startDate.year}-${newPost.event.schedule.startDate.month}-${newPost.event.schedule.startDate.day}`, 'YYYY-MM-DD');
        const endDate = newPost?.event?.schedule?.endDate &&
            moment(`${newPost.event.schedule.endDate.year}-${newPost.event.schedule.endDate.month}-${newPost.event.schedule.endDate.day}`, 'YYYY-MM-DD');

        this.setState({ newPost, activeTab, startDate, endDate }, () => this.toggleCreateModal());
    }

    toggleDelete = (selectedPost) => {
        this.setState({ deleteModal: !this.state.deleteModal, selectedPost })
    }

    deletePost = () => {
        const { accountId, selectedLocation } = this.props;
        const { selectedPost } = this.state;

        if(!selectedPost) return;
        
        this.setState({ block: true }, () =>
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        errorMessage.push({ message: data.errors[0].message, messageType: 'danger' });
                        this.setState({ deleteModal: false, selectedPost: null, error: errorMessage, block: false }, handleNotification(data));
                    }
                    else {
                        this.setState({ deleteModal: false, selectedPost: null }, () => this.getPosts());
                    }
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/Google/v1/posts/account/${accountId}/locations/${selectedLocation}/list?postName=${selectedPost}`)
        );
    }

    handleMediaType = (combo) => {
        const value = combo?.value;

        this.setState({ currentImage: {...this.state.currentImage, mediaFormat: value} });
    }

    handleLanguageCode = (combo) => {
        const value = combo?.value;

        this.setState({ newPost: {...this.state.newPost, languageCode: value} });
    }

    render() {
        const { block, error, postsList, activeTab, createModal, newPost, currentImage, startDate, endDate, optionalFieldsOpen, deleteModal } = this.state;
        const { intl, locationDetail, profilePhoto } = this.props;

        const buttonsOptions = [
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.NONE" }), value: 'NONE' },
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.BOOK" }), value: 'BOOK' },
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.ORDER" }), value: 'ORDER' },
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.SHOP" }), value: 'SHOP' },
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.LEARN_MORE" }), value: 'LEARN_MORE' },
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.SIGN_UP" }), value: 'SIGN_UP' },
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.CALL" }), value: 'CALL' },
        ];

        const hours = [
            {
                value:'00',
                label:'00'
            },
            {
                value:'01',
                label:'01'
            },
            {
                value:'02',
                label:'02'
            },
            {
                value:'03',
                label:'03'
            },
            {
                value:'04',
                label:'04'
            },
            {
                value:'05',
                label:'05'
            },
            {
                value:'06',
                label:'06'
            },
            {
                value:'07',
                label:'07'
            },
            {
                value:'08',
                label:'08'
            },
            {
                value:'09',
                label:'09'
            },
            {
                value:'10',
                label:'10'
            },
            {
                value:'11',
                label:'11'
            },
            {
                value:'12',
                label:'12'
            },
            {
                value:'13',
                label:'13'
            },
            {
                value:'14',
                label:'14'
            },
            {
                value:'15',
                label:'15'
            },
            {
                value:'16',
                label:'16'
            },
            {
                value:'17',
                label:'17'
            },
            {
                value:'18',
                label:'18'
            },
            {
                value:'19',
                label:'19'
            },
            {
                value:'20',
                label:'20'
            },
            {
                value:'21',
                label:'21'
            },
            {
                value:'22',
                label:'22'
            },
            {
                value:'22',
                label:'22'
            },
            {
                value:'23',
                label:'23'
            },
        ];

        const min = [
            {
                label:'00',
                value:'00'
            },
            {
                label:'15',
                value:'15'
            },
            {
                label:'30',
                value:'30'
            },
            {
                label:'45',
                value:'45'
            }
        ];

        const mediaOptions = [
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.PHOTO" }), value: 'PHOTO' },
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.VIDEO" }), value: 'VIDEO' },
        ];

        const langOptions = [
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.en" }), value: 'en' },
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.pt" }), value: 'pt' },
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.fr" }), value: 'fr' },
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.es" }), value: 'es' },
            { label: intl.formatMessage({ id: "GoogleBusinessConfig.de" }), value: 'de' },
        ]
        
        return (
            <StyledCard block={block} error={error}>
                {deleteModal ?
                    <ConfirmActionModal
                        block={block}
                        error={error}
                        text={intl.formatMessage({ id: "GoogleBusinessConfig.DeletePostsWarning" })}
                        toggleModal={this.toggleDelete}
                        modal={deleteModal}
                        actionFunction={this.deletePost}
                    />
                :''}
                {createModal ?
                    <Modal isOpen={createModal} toggle={this.toggleCreateModal} className='modal-lg'>
                        <BlockUi tag="div" blocking={block}>
                            <Button onClick={() => this.toggleCreateModal()} className="closeModal">
                                <i className="fas fa-times fa-sm"></i>
                            </Button>
                            <ModalBody className='py-4'>
                                <Form onSubmit={this.handleSubmit}>
                                    <Row>
                                        <Col>
                                            <h5><FormattedMessage id="GoogleBusinessConfig.CreatePost"/></h5>
                                        </Col>
                                        <Col className='col-1 text-right'>
                                            <Button className='btn btn-host btn-sm' type='submit' title={intl.formatMessage({ id: "GoogleBusinessConfig.Publish" })}>
                                                <i className="fas fa-save"/>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className='mt-2'>
                                        <Col>
                                            {!newPost.createTime ?
                                                <Nav tabs className="border-0 mb-3">
                                                    <NavItem>
                                                        <NavLink className={activeTab === '0' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleTab('0')} style={{ cursor: "pointer" }}>
                                                            <b><FormattedMessage id="GoogleBusinessConfig.Update"/></b>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink className={activeTab === '1' ? 'tab-border-host text-host  border-0' : 'border-0'} onClick={() => this.toggleTab('1')} style={{ cursor: "pointer" }}>
                                                            <b><FormattedMessage id="GoogleBusinessConfig.Offer" /></b>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink className={activeTab === '2' ? 'tab-border-host text-host  border-0' : 'border-0'} onClick={() => this.toggleTab('2')} style={{ cursor: "pointer" }}>
                                                            <b><FormattedMessage id="GoogleBusinessConfig.Event" /></b>
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            :''}
                                            <Row>
                                                <Col>
                                                    <TabContent activeTab={activeTab}>
                                                        <TabPane tabId="0" className="border-0">
                                                            <Row>
                                                                <Col className='col-6'>
                                                                    <div>
                                                                        <FormattedMessage id="GoogleBusinessConfig.languageCode"/>
                                                                    </div>
                                                                    <div className='mt-1'>
                                                                        <CustomSelect
                                                                            onChange={this.handleLanguageCode}
                                                                            options={langOptions}
                                                                            placeholder={''}
                                                                            isSearchable={false}
                                                                            isClearable={false}
                                                                            value={newPost?.languageCode ? langOptions.find(({value}) => value === newPost.languageCode) : null}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-2'>
                                                                <Col>
                                                                    <div className='mt-2 d-flex align-items-end justify-content-between'>
                                                                        <Row style={{ width: '90%', display: 'flex', alignItems: 'flex-end' }}>
                                                                            <Col className='col-6'>
                                                                                <div>
                                                                                    <FormattedMessage id="GoogleBusinessConfig.MediaType"/>
                                                                                </div>
                                                                                <div className='mt-1'>
                                                                                    <CustomSelect
                                                                                        onChange={this.handleMediaType}
                                                                                        options={mediaOptions}
                                                                                        placeholder={''}
                                                                                        isSearchable={false}
                                                                                        isClearable={false}
                                                                                        value={currentImage?.mediaFormat ? mediaOptions.find(({value}) => value === currentImage.mediaFormat) : null}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col>
                                                                                <div>
                                                                                    <FormattedMessage id="GoogleBusinessConfig.sourceUrl"/>*
                                                                                </div>
                                                                                <div className='mt-2'>
                                                                                    <Input
                                                                                        type="text"
                                                                                        name="sourceUrl"
                                                                                        onChange={this.handleSourceUrl}
                                                                                        value={currentImage.sourceUrl ?? ''}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <div>
                                                                            <div onClick={this.submitImage} className='shadow' style={{
                                                                                height: '38px',
                                                                                aspectRatio: '1/1',
                                                                                background: 'white',
                                                                                color: '#757575',
                                                                                display: 'grid',
                                                                                placeContent: 'center',
                                                                                cursor: 'pointer',
                                                                                borderRadius: '5px'
                                                                            }}>
                                                                                <i className="fas fa-plus"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {newPost?.media && newPost?.media.length > 0 ?
                                                                        <div className='mt-2 pb-2' style={{ overflow: 'auto', maxHeight: '150px' }}>
                                                                            {newPost?.media?.map((item, index) =>
                                                                                <div className='mt-2 d-flex align-items-end justify-content-between' key={index}>
                                                                                    <Row style={{ width: '90%' }}>
                                                                                        <Col>
                                                                                            <div className='mt-2'>
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    value={item.googleUrl??item.sourceUrl}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <div>
                                                                                        <div onClick={_ => this.deleteImage(index)} className='shadow' style={{
                                                                                            height: '38px',
                                                                                            aspectRatio: '1/1',
                                                                                            background: 'white',
                                                                                            color: '#757575',
                                                                                            display: 'grid',
                                                                                            placeContent: 'center',
                                                                                            cursor: 'pointer',
                                                                                            borderRadius: '5px'
                                                                                        }}>
                                                                                            <i className="fas fa-plus" style={{ rotate: '45deg' }}/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    :''}
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-2'>
                                                                <Col>
                                                                    <div>
                                                                        <FormattedMessage id="GoogleBusinessConfig.Description"/>*
                                                                    </div>
                                                                    <div className='mt-2'>
                                                                        <InputGPT
                                                                            onChange={this.handleDescription}
                                                                            noSubmit={true}
                                                                            value={newPost?.summary}
                                                                            placeholder={''}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-2'>
                                                                <Col>
                                                                    <div>
                                                                        <FormattedMessage id="GoogleBusinessConfig.Button"/>
                                                                    </div>
                                                                    <div className='mt-2'>
                                                                        <CustomSelect
                                                                            onChange={this.handleButton}
                                                                            options={buttonsOptions}
                                                                            placeholder={''}
                                                                            value={newPost?.callToAction?.actionType ? buttonsOptions.find(({value}) => newPost.callToAction?.actionType === value) : buttonsOptions[0]}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                {newPost?.callToAction && newPost?.callToAction?.actionType !== "NONE" && newPost?.callToAction?.actionType !== "CALL" ?
                                                                    <Col>
                                                                        <div>
                                                                            <FormattedMessage id="GoogleBusinessConfig.buttonURL"/>
                                                                        </div>
                                                                        <div className='mt-2'>
                                                                            <Input
                                                                                type="text"
                                                                                onChange={this.handleButtonUrl}
                                                                                value={newPost?.callToAction?.url}
                                                                                placeholder={''}
                                                                                isSearchable={false}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                :''}
                                                            </Row>
                                                        </TabPane>
                                                        <TabPane tabId="1" className="border-0">
                                                            <Row>
                                                                <Col className='col-6'>
                                                                    <div>
                                                                        <FormattedMessage id="GoogleBusinessConfig.languageCode"/>
                                                                    </div>
                                                                    <div className='mt-1'>
                                                                        <CustomSelect
                                                                            onChange={this.handleLanguageCode}
                                                                            options={langOptions}
                                                                            placeholder={''}
                                                                            isSearchable={false}
                                                                            isClearable={false}
                                                                            value={newPost?.languageCode ? langOptions.find(({value}) => value === newPost.languageCode) : null}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-2'>
                                                                <Col>
                                                                    <div className='mt-2 d-flex align-items-end justify-content-between'>
                                                                        <Row style={{ width: '90%' }}>
                                                                            <Col className='col-6'>
                                                                                <div>
                                                                                    <FormattedMessage id="GoogleBusinessConfig.MediaType"/>
                                                                                </div>
                                                                                <div className='mt-1'>
                                                                                    <CustomSelect
                                                                                        onChange={this.handleMediaType}
                                                                                        options={mediaOptions}
                                                                                        placeholder={''}
                                                                                        isSearchable={false}
                                                                                        isClearable={false}
                                                                                        value={currentImage?.mediaFormat ? mediaOptions.find(({value}) => value === currentImage.mediaFormat) : null}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col>
                                                                                <div>
                                                                                    <FormattedMessage id="GoogleBusinessConfig.sourceUrl"/>*
                                                                                </div>
                                                                                <div className='mt-2'>
                                                                                    <Input
                                                                                        type="text"
                                                                                        name="sourceUrl"
                                                                                        onChange={this.handleSourceUrl}
                                                                                        value={currentImage.sourceUrl ?? ''}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <div>
                                                                            <div onClick={this.submitImage} className='shadow' style={{
                                                                                height: '38px',
                                                                                aspectRatio: '1/1',
                                                                                background: 'white',
                                                                                color: '#757575',
                                                                                display: 'grid',
                                                                                placeContent: 'center',
                                                                                cursor: 'pointer',
                                                                                borderRadius: '5px'
                                                                            }}>
                                                                                <i className="fas fa-plus"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {newPost?.media && newPost?.media.length > 0 ?
                                                                        <div className='mt-2 pb-2' style={{ overflow: 'auto', maxHeight: '150px' }}>
                                                                            {newPost?.media?.map((item, index) =>
                                                                                <div className='mt-2 d-flex align-items-end justify-content-between' key={index}>
                                                                                    <Row style={{ width: '90%' }}>
                                                                                        <Col>
                                                                                            <div className='mt-2'>
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    value={item.googleUrl??item.sourceUrl}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <div>
                                                                                        <div onClick={_ => this.deleteImage(index)} className='shadow' style={{
                                                                                            height: '38px',
                                                                                            aspectRatio: '1/1',
                                                                                            background: 'white',
                                                                                            color: '#757575',
                                                                                            display: 'grid',
                                                                                            placeContent: 'center',
                                                                                            cursor: 'pointer',
                                                                                            borderRadius: '5px'
                                                                                        }}>
                                                                                            <i className="fas fa-plus" style={{ rotate: '45deg' }}/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    :''}
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-2'>
                                                                <Col>
                                                                    <div>
                                                                        <FormattedMessage id="GoogleBusinessConfig.OfferTitle"/>*
                                                                    </div>
                                                                    <div>
                                                                        <Input
                                                                            name='offerTitle'
                                                                            type='text'
                                                                            onChange={this.handleOfferTitle}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col className='col-4'>
                                                                    <div>
                                                                        <FormattedMessage id="GoogleBusinessConfig.Date"/>*
                                                                    </div>
                                                                    <div>
                                                                        <DateRangePicker
                                                                            startDateId="startDate"
                                                                            startDate={startDate}
                                                                            isOutsideRange={day => moment().isSameOrAfter(day)}
                                                                            small={true}
                                                                            endDate={endDate}
                                                                            endDateId="endDate"
                                                                            onDatesChange={this.handleOfferDates}
                                                                            focusedInput={this.state.focusedInput}
                                                                            readOnly={true}
                                                                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                                                                            showDefaultInputIcon={true}
                                                                            renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-2'>
                                                                <Col>
                                                                    <div>
                                                                        <FormattedMessage id="GoogleBusinessConfig.Description"/>
                                                                    </div>
                                                                    <div className='mt-2'>
                                                                        <InputGPT
                                                                            onChange={this.handleDescription}
                                                                            noSubmit={true}
                                                                            value={newPost?.summary}
                                                                            placeholder={''}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-2'>
                                                                <Col>
                                                                    <div className='genericHover' style={{ cursor: 'pointer', display: 'initial' }} onClick={this.toggleOptionalFields}>
                                                                        <FormattedMessage id="GoogleBusinessConfig.OptionalFields"/>
                                                                        {optionalFieldsOpen ?
                                                                            <i className="fas fa-chevron-up ml-1"/>
                                                                        :
                                                                            <i className="fas fa-chevron-down ml-1"/>
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            {optionalFieldsOpen ?
                                                                <>
                                                                    <Row className='mt-2'>
                                                                        <Col>
                                                                            <div>
                                                                                <FormattedMessage id="GoogleBusinessConfig.VoucherCode"/>
                                                                            </div>
                                                                            <div className='mt-2'>
                                                                                <Input
                                                                                    type='text'
                                                                                    onChange={this.handleVoucherCode}
                                                                                    value={newPost?.offer?.couponCode}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col>
                                                                            <div>
                                                                                <FormattedMessage id="GoogleBusinessConfig.LinkToRedeem"/>
                                                                            </div>
                                                                            <div className='mt-2'>
                                                                                <Input
                                                                                    type='text'
                                                                                    onChange={this.handleLinkToRedeem}
                                                                                    value={newPost?.offer?.redeemOnlineUrl}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className='mt-2'>
                                                                        <Col>
                                                                            <div>
                                                                                <FormattedMessage id="GoogleBusinessConfig.TermsAndConditions"/>
                                                                            </div>
                                                                            <div className='mt-2'>
                                                                                <GenericTextArea
                                                                                    backgroundColor={"white"}
                                                                                    value={newPost?.offer?.termsConditions}
                                                                                    onChange={this.handleTermsAndConditions}
                                                                                    name={"TermsAndConditions"}
                                                                                    placeholder={""}
                                                                                    maxLength='5000'
                                                                                    className="py-1"
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        background: 'transparent',
                                                                                        boxShadow: 'unset',
                                                                                        outline: '0',
                                                                                        maxHeight : '500px',
                                                                                        minHeight: '50px',
                                                                                        height: '50px',
                                                                                        padding: '5px 0',
                                                                                        resize: 'none',
                                                                                        color: '#07294C',
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            :''}
                                                        </TabPane>
                                                        <TabPane tabId="2" className="border-0">
                                                            <Row>
                                                                <Col className='col-6'>
                                                                    <div>
                                                                        <FormattedMessage id="GoogleBusinessConfig.languageCode"/>
                                                                    </div>
                                                                    <div className='mt-1'>
                                                                        <CustomSelect
                                                                            onChange={this.handleLanguageCode}
                                                                            options={langOptions}
                                                                            placeholder={''}
                                                                            isSearchable={false}
                                                                            isClearable={false}
                                                                            value={newPost?.languageCode ? langOptions.find(({value}) => value === newPost.languageCode) : null}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-2'>
                                                                <Col>
                                                                    <div className='mt-2 d-flex align-items-end justify-content-between'>
                                                                        <Row style={{ width: '90%' }}>
                                                                            <Col className='col-6'>
                                                                                <div>
                                                                                    <FormattedMessage id="GoogleBusinessConfig.MediaType"/>
                                                                                </div>
                                                                                <div className='mt-1'>
                                                                                    <CustomSelect
                                                                                        onChange={this.handleMediaType}
                                                                                        options={mediaOptions}
                                                                                        placeholder={''}
                                                                                        isSearchable={false}
                                                                                        isClearable={false}
                                                                                        value={currentImage?.mediaFormat ? mediaOptions.find(({value}) => value === currentImage.mediaFormat) : null}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col>
                                                                                <div>
                                                                                    <FormattedMessage id="GoogleBusinessConfig.sourceUrl"/>*
                                                                                </div>
                                                                                <div className='mt-2'>
                                                                                    <Input
                                                                                        type="text"
                                                                                        name="sourceUrl"
                                                                                        onChange={this.handleSourceUrl}
                                                                                        value={currentImage.sourceUrl ?? ''}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <div>
                                                                            <div onClick={this.submitImage} className='shadow' style={{
                                                                                height: '38px',
                                                                                aspectRatio: '1/1',
                                                                                background: 'white',
                                                                                color: '#757575',
                                                                                display: 'grid',
                                                                                placeContent: 'center',
                                                                                cursor: 'pointer',
                                                                                borderRadius: '5px'
                                                                            }}>
                                                                                <i className="fas fa-plus"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {newPost?.media && newPost?.media.length > 0 ?
                                                                        <div className='mt-2 pb-2' style={{ overflow: 'auto', maxHeight: '150px' }}>
                                                                            {newPost?.media?.map((item, index) =>
                                                                                <div className='mt-2 d-flex align-items-end justify-content-between' key={index}>
                                                                                    <Row style={{ width: '90%' }}>
                                                                                        <Col>
                                                                                            <div className='mt-2'>
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    value={item.googleUrl??item.sourceUrl}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <div>
                                                                                        <div onClick={_ => this.deleteImage(index)} className='shadow' style={{
                                                                                            height: '38px',
                                                                                            aspectRatio: '1/1',
                                                                                            background: 'white',
                                                                                            color: '#757575',
                                                                                            display: 'grid',
                                                                                            placeContent: 'center',
                                                                                            cursor: 'pointer',
                                                                                            borderRadius: '5px'
                                                                                        }}>
                                                                                            <i className="fas fa-plus" style={{ rotate: '45deg' }}/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    :''}
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-2'>
                                                                <Col>
                                                                    <div>
                                                                        <FormattedMessage id="GoogleBusinessConfig.EventTitle"/>*
                                                                    </div>
                                                                    <div>
                                                                        <Input
                                                                            name='offerTitle'
                                                                            type='text'
                                                                            onChange={this.handleOfferTitle}
                                                                            value={newPost?.event?.title ?? ''}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-2'>
                                                                <Col>
                                                                    <div>
                                                                        <FormattedMessage id="GoogleBusinessConfig.Date"/>*
                                                                    </div>
                                                                    <div>
                                                                        <DateRangePicker
                                                                            startDateId="startDateID"
                                                                            startDate={startDate}
                                                                            isOutsideRange={day => moment().isSameOrAfter(day)}
                                                                            small={true}
                                                                            endDate={endDate}
                                                                            endDateId="endDateID"
                                                                            onDatesChange={this.handleOfferDates}
                                                                            focusedInput={this.state.focusedInput2}
                                                                            readOnly={true}
                                                                            onFocusChange={focusedInput => this.setState({ focusedInput2: focusedInput })}
                                                                            showDefaultInputIcon={true}
                                                                            renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    <div>
                                                                        <FormattedMessage id="GoogleBusinessConfig.StartTime"/>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div>
                                                                            <CustomSelect
                                                                                isSearchable={false}
                                                                                placeholder={"hh"}
                                                                                options={hours}
                                                                                onChange={this.handleStartHours}
                                                                                value={newPost?.event?.schedule?.startTime?.hours ? hours.find((op) => parseInt(op.label) === parseInt(newPost.event.schedule.startTime.hours)) : ''}
                                                                            />
                                                                        </div>
                                                                        <div style={{ padding:'10px 1rem', fontWeight:'1,2em', color:'grey' }}>
                                                                            <b>:</b>
                                                                        </div>
                                                                        <div>
                                                                            <CustomSelect
                                                                                isSearchable={false}
                                                                                placeholder={"mm"}
                                                                                options={min}
                                                                                onChange={this.handleStartMin}
                                                                                value={newPost?.event?.schedule?.startTime?.minutes ? min.find((op) => parseInt(op.label) === parseInt(newPost.event.schedule.startTime.minutes)) : ''}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    <div>
                                                                        <FormattedMessage id="GoogleBusinessConfig.EndTime"/>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div>
                                                                            <CustomSelect
                                                                                isSearchable={false}
                                                                                placeholder={"hh"}
                                                                                options={hours}
                                                                                onChange={this.handleEndHours}
                                                                                value={newPost?.event?.schedule?.endTime?.hours ? hours.find((op) => parseInt(op.label) === parseInt(newPost.event.schedule.endTime.hours)) : ''}
                                                                            />
                                                                        </div>
                                                                        <div style={{ padding:'10px 1rem', fontWeight:'1,2em', color:'grey' }}>
                                                                            <b>:</b>
                                                                        </div>
                                                                        <div>
                                                                            <CustomSelect
                                                                                isSearchable={false}
                                                                                placeholder={"mm"}
                                                                                options={min}
                                                                                onChange={this.handleEndMin}
                                                                                value={newPost?.event?.schedule?.endTime?.minutes ? min.find((op) => parseInt(op.label) === parseInt(newPost.event.schedule.endTime.minutes)) : ''}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-2'>
                                                                <Col>
                                                                    <div>
                                                                        <FormattedMessage id="GoogleBusinessConfig.Description"/>
                                                                    </div>
                                                                    <div className='mt-2'>
                                                                        <InputGPT
                                                                            onChange={this.handleDescription}
                                                                            noSubmit={true}
                                                                            value={newPost?.summary}
                                                                            placeholder={''}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-2'>
                                                                <Col>
                                                                    <div>
                                                                        <FormattedMessage id="GoogleBusinessConfig.Button"/>
                                                                    </div>
                                                                    <div className='mt-2'>
                                                                        <CustomSelect
                                                                            onChange={this.handleButton}
                                                                            options={buttonsOptions}
                                                                            placeholder={''}
                                                                            value={newPost?.callToAction?.actionType ? buttonsOptions.find(({value}) => newPost.callToAction?.actionType === value) : buttonsOptions[0]}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                {newPost?.callToAction && newPost?.callToAction?.actionType !== "NONE" && newPost?.callToAction?.actionType !== "CALL" ?
                                                                    <Col>
                                                                        <div>
                                                                            <FormattedMessage id="GoogleBusinessConfig.buttonURL"/>
                                                                        </div>
                                                                        <div className='mt-2'>
                                                                            <Input
                                                                                type="text"
                                                                                onChange={this.handleButtonUrl}
                                                                                value={newPost?.callToAction?.url}
                                                                                placeholder={''}
                                                                                isSearchable={false}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                :''}
                                                            </Row>
                                                        </TabPane>
                                                    </TabContent>
                                                </Col>
                                                <Col className='col-4'>
                                                    <GooglePost
                                                        isNew={true}
                                                        profilePhoto={profilePhoto}
                                                        locationDetail={locationDetail}
                                                        googlePost={newPost}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            </ModalBody>
                        </BlockUi>
                    </Modal>
                :''}
                <Row>
                    <Col className='text-right'>
                        <Button className="btn btn-host btn-sm" onClick={this.toggleCreateModal}>
                            <i className="fas fa-plus"/>
                        </Button>
                    </Col>
                </Row>
                {postsList && postsList.length > 0 ?
                    <Row className='mt-2'>
                        {postsList.map((post, index) =>
                            <Col className='col-4' key={index}>
                                <GooglePost
                                    profilePhoto={profilePhoto}
                                    locationDetail={locationDetail}
                                    googlePost={post}
                                    toggleEdit={this.toggleEdit}
                                    toggleDelete={this.toggleDelete}
                                />
                            </Col>
                        )}
                    </Row>
                :
                    <Row>
                        <Col className='text-center'>
                            <FormattedMessage id="GoogleBusinessConfig.NoPosts"/>
                        </Col>
                    </Row>
                }
            </StyledCard>
        );
    }
}
export default injectIntl(GoogleBusinessPosts)
