import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Modal, ModalBody, Input } from 'reactstrap';
import { getConsentTypeList, getDocumentList, getEntityList, getFieldList } from "../../Base/ReferenceDataFunctions";
import { handleNotification } from '../../Base/Notification';
import { BlankCard } from '../../Base/CommonUIComponents';
import { postAPI, deleteAPI, getAPI } from '../../Base/API';
import CustomSelect from "../../Base/CustomSelect";
import { getFieldsToMapForms } from '../../Base/MapFormsComponents';

class MappingModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            blockConsents: true,
            block: false,
            mapping: this.props.modalData ? this.props.modalData : { originType: 'Egoi' },
            list: null,
            extraFields: null,
            originMapValues: null,
            fieldOptions: {
                ProfileAddress: getFieldsToMapForms(),
                ProfileDocument: [
                    { "value": "Number", "label": <FormattedMessage id="ReferenceDataFunctions.Number" /> }
                ],
                ProfileConsent: [
                    { "value": "Active", "label": <FormattedMessage id="ReferenceDataFunctions.Active" /> }
                ]
            },
            typeValues: {
                ProfileConsent: [],
                ProfileDocument: getDocumentList(this.props.intl)
            }
        };
    }

    componentDidMount() {
        this.getConfiguredConsents();
        if (this.props.modalData) {
            const list = this.props.egoiLists.find(el => el.value.toString() === this.props.modalData.listId.toString());
            let extraFields = null;
            let originMapValues = null;

            if (list && list.extraFields) {
                extraFields = [
                    { label: this.props.intl.formatMessage({ id: 'Mappings.TextFields' }), options: list.extraFields.textFields },
                    { label: this.props.intl.formatMessage({ id: 'Mappings.OptionFields' }), options: list.extraFields.optionFields }
                ];
            }

            if (extraFields && this.props.modalData.originFieldId) {
                const selectedFieldId = extraFields.flatMap(el => el.options).find(c => c.value.toString() === this.props.modalData.originFieldId.toString());
                originMapValues = selectedFieldId && selectedFieldId.options ? selectedFieldId.options : null;
            }

            this.setState({
                extraFields, list, originMapValues
            })
        }
    }

    getConfiguredConsents = () => {
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockConsents: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                const { typeValues } = this.state;
                typeValues.ProfileConsent = data.response.filter(el => el.code !== getConsentTypeList()[2].value).map(el => {
                    const option = {
                        value: el.code,
                        label: el.description
                    };
                    return option;
                });

                this.setState({ typeValues, blockConsents: false });
            }
            else {
                this.setState({ blockConsents: false });
            }
        }, `/api/gms/Hotel/v1/consents`);
    }

    saveTagMapping = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="Mappings.MappingSaved" />, <FormattedMessage id="generic.success" />);

                    this.props.updateMapping(data.response[0], false);
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/GenericMapping`, JSON.stringify({ request: this.state.mapping }));
    }

    deleteMapping = (mappingId) => {
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="Mappings.MappingRemoved" />, <FormattedMessage id="generic.success" />);

                    this.props.updateMapping({ id: mappingId }, true);
                    this.props.toggleModal();
                }
            }
        }, `/api/gms/Marketing/v1/GenericMapping/${mappingId}`);
    }

    handleChange2Values = (combo, name, name2) => {
        this.setState({
            mapping: {
                ...this.state.mapping,
                [name]: combo ? combo.value : null,
                [name2]: combo ? combo.label : null
            }
        })
    };

    handleChangeSelect = (combo, name) => {
        this.setState({
            mapping: {
                ...this.state.mapping,
                [name]: combo ? combo.value : null
            }
        })
    };

    handleChangeEntityName = (combo) => {
        this.setState({
            mapping: {
                ...this.state.mapping,
                entityName: combo ? combo.value : null,
                entityField: null,
                typeValue: null
            }
        })
    };

    handleChangeList = (combo) => {
        const list = combo ? this.props.egoiLists.find(el => el.value.toString() === combo.value.toString()) : null;
        let extraFields = null;

        if (list && list.extraFields) {
            extraFields = [
                { label: this.props.intl.formatMessage({ id: 'Mappings.TextFields' }), options: list.extraFields.textFields },
                { label: this.props.intl.formatMessage({ id: 'Mappings.OptionFields' }), options: list.extraFields.optionFields }
            ];
        }

        this.setState({
            extraFields,
            list,
            mapping: {
                ...this.state.mapping,
                listId: combo ? combo.value : null
            }
        })
    };

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            mapping: {
                ...this.state.mapping,
                [name]: value
            }
        })
    }

    render() {
        const { mapping, extraFields, originMapValues, fieldOptions, typeValues } = this.state;
        const { modal, toggleModal, originOptions, originSubTypeOptions, destinationOptions, egoiLists, egoiTags, tags, allTags, entities } = this.props;

        return (
            <Modal isOpen={modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button type="button" onClick={toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={this.state.block || this.state.blockConsents} error={this.state.error}>
                        <Form onSubmit={this.saveTagMapping}>
                            <Row className="mb-3">
                                <Col>
                                    <h5>{mapping.id ? <FormattedMessage id="TagMapping.EditMapping" /> : <FormattedMessage id="TagMapping.AddMapping" />}</h5>
                                </Col>
                                <Col className="text-right col-3">
                                    {mapping.id &&
                                        <Button className="btn-sm btn-host mr-2" onClick={() => this.deleteMapping(mapping.id)}>
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                    }
                                    <Button className="btn-sm btn-host" type="submit">
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <b>Origin</b>

                                    <FormGroup row className="mt-3">
                                        <Label sm={4}> <FormattedMessage id="Mappings.OriginType" /></Label>
                                        <Col sm={8} >
                                            <CustomSelect
                                                options={originOptions}
                                                required
                                                isClearable
                                                isSearchable
                                                placeholder={""}
                                                onChange={(e) => this.handleChangeSelect(e, "originType")}
                                                value={mapping.originType ? originOptions.find(c => c.value === mapping.originType) : ''}
                                            />
                                        </Col>
                                    </FormGroup>
                                    {mapping.originType === 'Egoi' &&
                                        <FormGroup row>
                                            <Label sm={4}> <FormattedMessage id="Mappings.EgoiList" /></Label>
                                            <Col sm={8} >
                                                <CustomSelect
                                                    options={egoiLists}
                                                    required
                                                    isClearable
                                                    isSearchable
                                                    placeholder={""}
                                                    onChange={(e) => this.handleChangeList(e)}
                                                    value={mapping.listId ? egoiLists.find(c => c.value.toString() === mapping.listId.toString()) : ''}
                                                />
                                            </Col>
                                        </FormGroup>
                                    }
                                    <FormGroup row>
                                        <Label sm={4}> <FormattedMessage id="Mappings.OriginSubType" /></Label>
                                        <Col sm={8} >
                                            <CustomSelect
                                                options={originSubTypeOptions}
                                                required
                                                isClearable
                                                isSearchable
                                                placeholder={""}
                                                onChange={(e) => this.handleChangeSelect(e, "originSubType")}
                                                value={mapping.originSubType ? originSubTypeOptions.find(c => c.value === mapping.originSubType) : ''}
                                            />
                                        </Col>
                                    </FormGroup>
                                    {mapping.originSubType === 'Tag' &&
                                        <FormGroup row>
                                            <Label sm={4}> <FormattedMessage id="Mappings.EgoiTags" /></Label>
                                            <Col sm={8} >
                                                <CustomSelect
                                                    //styles={customStyles}
                                                    options={egoiTags}
                                                    required
                                                    isClearable
                                                    isSearchable
                                                    placeholder={""}
                                                    onChange={(e) => this.handleChange2Values(e, "originFieldId", 'originMapValue')}
                                                    value={mapping.originMapValue ? egoiTags.find(c => c.label === mapping.originMapValue) : ''}
                                                />
                                            </Col>
                                        </FormGroup>
                                    }

                                    {mapping.originSubType === 'CustomField' &&
                                        <>
                                            <FormGroup row>
                                                <Label sm={4}> <FormattedMessage id="Mappings.EgoiCustomField" /></Label>
                                                <Col sm={8} >
                                                    <CustomSelect
                                                        options={extraFields}
                                                        required
                                                        isClearable
                                                        isSearchable
                                                        placeholder={""}
                                                        isDisabled={!mapping.listId || !extraFields ? true : false}
                                                        onChange={(e) => { this.handleChangeSelect(e, "originFieldId"); this.setState({ originMapValues: e ? e.options : null }) }}
                                                        value={mapping.originFieldId && extraFields ? extraFields.flatMap(el => el.options).find(c => c.value.toString() === mapping.originFieldId.toString()) : ''}
                                                    />
                                                </Col>
                                            </FormGroup>

                                            {mapping.originFieldId ? originMapValues ?
                                                <FormGroup row>
                                                    <Label sm={4}> <FormattedMessage id="Mappings.Option" /></Label>
                                                    <Col sm={8} >
                                                        <CustomSelect
                                                            options={originMapValues}
                                                            required
                                                            isClearable
                                                            isSearchable
                                                            placeholder={""}
                                                            isDisabled={!originMapValues}
                                                            onChange={(e) => this.handleChangeSelect(e, "originMapValue")}
                                                            value={mapping.originMapValue && originMapValues ? originMapValues.find(c => c.value.toString() === mapping.originMapValue.toString()) : ''}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                :
                                                <FormGroup row>
                                                    <Label sm={4}> <FormattedMessage id="Mappings.Text" /></Label>
                                                    <Col sm={8} >
                                                        <Input
                                                            type="text"
                                                            name="originMapValue"
                                                            onChange={(e) => this.handleChange(e)}
                                                            value={mapping.originMapValue || ''}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            : ''}
                                        </>
                                    }
                                </Col>

                                <Col sm={1} className="text-host text-center">
                                    <i className="fas fa-lg fa-long-arrow-alt-right" />
                                </Col>

                                <Col>
                                    <b>Destination</b>

                                    <FormGroup row className="mt-3">
                                        <Label sm={4}> <FormattedMessage id="Mappings.DestinationType" /></Label>
                                        <Col sm={8} >
                                            <CustomSelect
                                                options={destinationOptions}
                                                required
                                                isClearable
                                                isSearchable
                                                placeholder={""}
                                                onChange={(e) => this.handleChangeSelect(e, "destinationType")}
                                                value={mapping.destinationType ? destinationOptions.find(c => c.value === mapping.destinationType) : ''}
                                            />
                                        </Col>
                                    </FormGroup>

                                    {mapping.destinationType && mapping.destinationType === 'Tag' &&
                                        <FormGroup row>
                                            <Label sm={4}> <FormattedMessage id="Mappings.Tag" /></Label>
                                            <Col sm={8} >
                                                <CustomSelect
                                                    options={tags}
                                                    required
                                                    isClearable
                                                    isSearchable
                                                    placeholder={""}
                                                    onChange={(e) => this.handleChangeSelect(e, "tagId")}
                                                    value={mapping.tagId ? allTags.find(c => c.value === mapping.tagId) : ''}
                                                />
                                            </Col>
                                        </FormGroup>
                                    }

                                    {mapping.destinationType && mapping.destinationType === 'Field' &&
                                    <>
                                        <FormGroup row>
                                            <Label sm={4}> <FormattedMessage id="RuleFiltersDetails.Entity" /></Label>
                                            <Col sm={8}>
                                                <CustomSelect
                                                    options={entities}
                                                    required
                                                    isClearable
                                                    isSearchable
                                                    placeholder={""}
                                                    onChange={this.handleChangeEntityName}
                                                    value={mapping.entityName ? entities.find(c => c.value === mapping.entityName) : ''}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={4}> <FormattedMessage id="RuleFiltersDetails.Field" /></Label>
                                            <Col sm={8}>
                                                <CustomSelect
                                                    options={fieldOptions[mapping.entityName]}
                                                    required
                                                    isClearable
                                                    isSearchable
                                                    placeholder={""}
                                                    isDisabled={!mapping.entityName ? true : false}
                                                    onChange={(e) => this.handleChangeSelect(e, "entityField")}
                                                    value={mapping.entityField && fieldOptions[mapping.entityName]?.find(el => el.value === mapping.entityField)}
                                                />
                                            </Col>
                                        </FormGroup>
                                        {
                                            //getEntityList()[1].value - ProfileAddress
                                            mapping.entityName !== getEntityList()[1].value ?
                                                <FormGroup row>
                                                    <Label sm={4}> <FormattedMessage id="RuleFiltersDetails.TypeValue"/></Label>
                                                    <Col sm={8}>
                                                        <CustomSelect
                                                            options={typeValues[mapping.entityName]}
                                                            onChange={(e) => this.handleChangeSelect(e, "typeValue")}
                                                            value={mapping.typeValue && typeValues[mapping.entityName].find(c => c.value === mapping.typeValue)}
                                                            isClearable
                                                            isSearchable
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                :
                                                ''
                                        }
                                    </>
                                    }
                                </Col>
                            </Row>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(MappingModal)