import React, { Component } from 'react';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import { Col, Collapse, Row, Button, UncontrolledPopover } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../Base/CustomSelect';
import { CoolTooltip, FormatAmountNumber, SelectHotel } from '../Base/CommonUIComponents';
import moment from 'moment-timezone';
import { handleNotification } from '../Base/Notification';
import { getAPI } from '../Base/API';
import { getHolidays } from '../Marketing/CommunicationJourney/CommonFunctions';
import { SelectCustomType } from './SalesProcessFunctions';

class SalesProcessResources extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            block: true,
            hotels: this.props.selectedHotel ? [this.props.selectedHotel] : [],
            statusList: [],
            currentDate: moment().set('year', (this.props.year ?? moment().format('YYYY'))).set('month', (this.props.selectedMonth ? (parseInt(this.props.selectedMonth) - 1) : (parseInt(moment().format('MM')) - 1))),
            months: [
                { month: 1 },
                { month: 2 },
                { month: 3 },
                { month: 4 },
                { month: 5 },
                { month: 6 },
                { month: 7 },
                { month: 8 },
                { month: 9 },
                { month: 10 },
                { month: 11 },
                { month: 12 }
            ],
            weekDays: moment.weekdaysShort(),
            totalCalendarRows: 0,
            holidaysOfMonth: [],
            calendarRows: [],
            hotelList: [],
            viewMode: this.props.isFromMonthly ? this.props.isSpaces ? "spaces" : "rooms" : "hotels",
            viewModeOptions: [
                { mode: "hotels", icon: "icon-icon-hotel"},
                { mode: "deals", icon: "fas fa-briefcase"},
                { mode: "rooms", icon: "fas fa-bed"},
                // { mode: "events", icon: "fas fa-theater-masks"},
                { mode: "spaces", icon: "fas fa-store-alt"},
            ],
            colors: [
                '#6610f2', '#fd7e14', '#17a2b8', '#e83e8c', '#dc3545', '#ffc107', '#28a745', '#20c997', '#6c757d', '#343a40',
                '#17a2b8', '#6f42c1', '#dce2c8', '#f96900', '#a8dcd1', '#65def1', '#4F5D2F', '#423629', '#7D7E75', '#B0B2B8',
                '#CFD6EA', '#99621E', '#D38B5D', '#739E82', '#2C5530', '#8C1C13', '#BF4342', '#BF4342', '#A78A7F', '#735751',
                '#EAD2AC', '#EABA6B', '#221D23', '#4F3824', '#D1603D', '#DDB967', '#D0E37F', '#B48B7D', '#094D92', '#875053'
            ],
            dealColor: [],
            openedDealDetail: [],
            yearOptions: [
                {label:'2023', value:'2023'},
                {label:'2024', value:'2024'},
                {label:'2025', value:'2025'},
                {label:'2026', value:'2026'},
                {label:'2027', value:'2027'},
                {label:'2028', value:'2028'},
                {label:'2029', value:'2029'}
            ],
            selectedStages: [],
            monthlyOcc: [
                { rooms: 60, spaces: 30 }
            ],
            openedHotelDetail: [],
            filters: []
        };
    }

    componentDidMount() {
        this.getResources();

        const year = this.state.currentDate.year();

        this.setState({ yearOptions: [(year - 1), year, (year + 1), (year + 2)] })
    }

    getResources = () => {
        const { selectedStageTypeId } = this.props;
        const { hotels, statusList, currentDate, selectedStages } = this.state;
        
        const startDate = currentDate.clone().startOf('month');
        const endDate = currentDate.clone().endOf('month');

        let q = `?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`;

        if(hotels)
            hotels.forEach(h =>
                q += `&hotelIds=${h}&`
            );

        if(selectedStages)
            selectedStages.forEach(s =>
                q += `&stageTypeList=${s}&`
            );

        if(statusList?.length)
            statusList.forEach(s =>
                q += `&statusList=${s}`
            );
        if(selectedStageTypeId)
            q += `&stageTypeId=${selectedStageTypeId}&`;


        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                if (data.data?.length > 0) {
                    data.data.forEach(hotel => {
                        hotel.isVisible = true;
                        hotel.salesDeals = hotel.salesDeals.map(dr => ({ ...dr, isVisible: true, showRooms: true, showSpaces: true }));
                    });
                }

                this.setState({
                    block: false,
                    hotelList: data.data
                }, () => this.renderCalendar());

                return;
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/resourcecalendar${q}`);
    }

    //#region Calendar

    renderCalendar = () => {
        let { currentDate, hotelList, colors, dealColor } = this.state;

        const months = this.addHolidays();

        const holidaysOfMonth = months ? months.find(m => m.month == currentDate.format('M')).holidays : [];

        let blank = this.getBlankCells();
        let daysInMonth = this.getDaysInMonth();
        let totalSlots = [...blank, ...daysInMonth];
        let cells = [], calendarRows = [];

        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row); // if index not equal 7 that means not go to next week
            } else {
                calendarRows.push(cells); // when reach next week we contain all td in last week to rows 
                cells = []; // empty container 
                cells.push(row); // in current loop we still push current row to new container
            }
            if (i === totalSlots.length - 1) { // when end loop we add remain date
                while (cells.length < 7) {
                    cells.push({ day: "" });
                }
                calendarRows.push(cells);
            }
        });
        
        const totalCalendarRows = (calendarRows && calendarRows.filter(cr => cr.length > 0).length) ?? 0;
        let dealList = [];
        
        hotelList.forEach(hotel => {
            if (hotel.isVisible && hotel.salesDeals){

                hotel.numberOfRooms = 1;
                hotel.numberOfSpaces = 1;

                hotel.salesDeals.forEach(deal => {
                    if (deal.isVisible) {
                        deal.startDate = moment.min(deal.proposals.map(({ startDate }) => moment(startDate)));
                        deal.endDate = moment.max(deal.proposals.map(({ endDate }) => moment(endDate)));

                        const days = calendarRows.flatMap(cells => cells).filter(d => d.date && (d.date.isSameOrAfter(deal.startDate, 'day') && d.date.isSameOrBefore(deal.endDate, 'day')));

                        if (days && days.length > 0) {
                            days.forEach(day => {
                                day.deals.push({
                                    startDate: deal.startDate,
                                    endDate: deal.endDate,
                                    isStartDate: deal.startDate.isSame(day.date, 'day'),
                                    isEndDate: deal.endDate.isSame(day.date, 'day'),
                                    dealName: deal.dealName,
                                    expectedValue: deal.expectedValue,
                                    status: deal.status,
                                    dealId: deal.dealId,
                                    hotelId: hotel.hotelId,
                                    numberOfRooms: deal.proposals?.reduce((a, b) => a + b.numberOfRooms, 0) ?? 0,
                                    numberOfSpaces: deal.proposals?.reduce((a, b) => a + b.numberOfSpaces, 0) ?? 0,
                                    showRooms: deal.showRooms,
                                    showSpaces: deal.showSpaces
                                });
                            });
                        }

                        if ((deal.showRooms || deal.showSpaces) && deal.proposals) {
                            deal.proposals.forEach(proposal => {
                                if (proposal.proposalJSON) {
                                    proposal.proposalJSON = typeof proposal.proposalJSON === "string" ? JSON.parse(proposal.proposalJSON) : proposal.proposalJSON;
                                    proposal.proposalJSON.BlockData = proposal.proposalJSON.BlockData && JSON.parse(JSON.stringify(proposal.proposalJSON.BlockData));
                                    const groupReservation = proposal.proposalJSON.data[0];
                                    const externalId = groupReservation.GroupReservationNumber;
                                    const blockData = proposal.proposalJSON.BlockData;

                                    if (deal.showRooms && blockData?.details?.length > 0) {
                                        blockData.details.forEach(block => {
                                            const rowIndex = calendarRows.findIndex(row => row.some(day => moment(block.Date).isSame(day.date, 'day')));

                                            if (rowIndex !== -1) {
                                                const dayIndex = calendarRows[rowIndex].findIndex(el => moment(block.Date).isSame(el.date, 'day'))

                                                if (dayIndex !== -1) {
                                                    const headerData = blockData.headerData?.length > 0 ? blockData.headerData.find(el => el.Id === block.HeaderId) : null;

                                                    calendarRows[rowIndex][dayIndex].rooms.push({
                                                        //startDate: start,
                                                        //endDate: end,
                                                        //isStartDate: start.isSame(day.date, 'day'),
                                                        //isEndDate: end.isSame(day.date, 'day'),
                                                        categoryId: block.CategoryId,
                                                        category: block.CategoryCode,
                                                        priceList: headerData?.PriceListCode,
                                                        package: headerData?.PackageCode,
                                                        numberOfRooms: block.CurrentRooms,
                                                        priceTotal: block.CurrentRooms * block.PriceAfterTaxPerRoom_Total,
                                                        externalId: externalId,
                                                        dealId: deal.dealId,
                                                        hotelId: hotel.hotelId
                                                    })
                                                }
                                            }
                                        });
                                    }

                                    if (deal.showSpaces &&groupReservation.DetailsEvents && groupReservation.DetailsEvents.length > 0) {
                                        groupReservation.DetailsEvents.forEach(event => {
                                            const eventStart = moment.min(event.Spaces.map(({ FromDate }) => moment(FromDate)));
                                            const eventEnd = moment.max(event.Spaces.map(({ ToDate }) => moment(ToDate)));

                                            const days = calendarRows.flatMap(cells => cells).filter(d => d.date && (d.date.isSameOrAfter(eventStart, 'day') && d.date.isSameOrBefore(eventEnd, 'day')));

                                            if (days && days.length > 0) {
                                                days.forEach(day => {
                                                    const daySpaces = event.Spaces.filter(({ FromDate, ToDate }) =>
                                                        day.date.isSameOrAfter(moment(FromDate), 'day') &&
                                                        day.date.isSameOrBefore(moment(ToDate), 'day')
                                                    ).map((space) => ({
                                                        name: space.SpaceName,
                                                        desc: space.SpaceDescription,
                                                        type: space.SpaceTypeDescription,
                                                        startDate: moment(space.FromDate),
                                                        numberOfSpaces: 1,
                                                        endDate: moment(space.ToDate)
                                                    }));

                                                    day.events.push({
                                                        startDate: eventStart,
                                                        endDate: eventEnd,
                                                        isStartDate: eventStart.isSame(day.date, 'day'),
                                                        isEndDate: eventEnd.isSame(day.date, 'day'),
                                                        name: event.EventName,
                                                        type: event.EventType,
                                                        manager: event.EventManager,
                                                        priceTotal: event.PriceTotal,
                                                        spaces: daySpaces,
                                                        externalId: externalId,
                                                        dealId: deal.dealId,
                                                        hotelId: hotel.hotelId
                                                    });
                                                });

                                                if (event.Spaces && event.Spaces.length > 0) {
                                                    event.Spaces.forEach(space => {
                                                        const spaceStart = moment(space.FromDate);
                                                        const spaceEnd = moment(space.ToDate);

                                                        const days = calendarRows.flatMap(cells => cells).filter(d => d.date && (d.date.isSameOrAfter(spaceStart, 'day') && d.date.isSameOrBefore(spaceEnd, 'day')));

                                                        if (days && days.length > 0) {
                                                            days.forEach(day => {
                                                                day.spaces.push({
                                                                    numberOfSpaces: 1,
                                                                    name: space.SpaceName,
                                                                    desc: space.SpaceDescription,
                                                                    type: space.SpaceTypeDescription,
                                                                    startDate: moment(space.FromDate),
                                                                    endDate: moment(space.ToDate),
                                                                    externalId: externalId,
                                                                    dealId: deal.dealId,
                                                                    hotelId: hotel.hotelId
                                                                });
                                                            })
                                                        }
                                                    });
                                                }
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    }
                });

                const days = calendarRows.flatMap(cells => cells).filter(d => d.deals?.find(({hotelId}) => hotelId === hotel.hotelId));

                hotel.startDate = moment.min(days.map(({ date }) => date));
                hotel.endDate = moment.max(days.map(({ date }) => date));
    
                if (days && days.length > 0) {
                    days.forEach(day => {
                        const dealsInDay = day.deals.filter(({hotelId}) => hotelId === hotel.hotelId);
                        day.hotels.push({
                            startDate: hotel.startDate,
                            endDate: hotel.endDate,
                            isStartDate: hotel.startDate.isSame(day.date, 'day'),
                            isEndDate: hotel.endDate.isSame(day.date, 'day'),
                            hotelId: hotel.hotelId,
                            hotelName: global.hotelList?.find(({value}) => value === hotel.hotelId)?.label??'',
                            numberOfDeals: dealsInDay.length,
                            numberOfRooms: dealsInDay.some(d => d.showRooms === true) ? dealsInDay.reduce((a, b) => a + (b.showRooms ? b.numberOfRooms : 0), 0) : null,
                            numberOfSpaces: dealsInDay.some(d => d.showSpaces === true) ? dealsInDay.reduce((a, b) => a + (b.showSpaces ? b.numberOfSpaces : 0), 0) : null
                        });
                    });
                }
                dealList = dealList.concat(hotel.salesDeals);
            }
        });

        calendarRows.flatMap(cells => cells).forEach(d=> {
            if(d.rooms && d.rooms.length){
                const newRooms = [];
                d.rooms.forEach(r => {
                    const idx = newRooms.findIndex(nr => nr.categoryId === r.categoryId && nr.package === r.package && nr.priceList === r.priceList && nr.dealId == r.dealId);
                    if(idx >= 0){
                        const c = newRooms[idx]
                        newRooms[idx] = {
                            ...c,
                            numberOfRooms: c.numberOfRooms + r.numberOfRooms,
                            priceTotal: c.priceTotal + r.priceTotal,
                            startDate: r.startDate.isBefore(c.startDate, 'day'),
                            endDate: r.startDate.isAfter(c.endDate, 'day'),
                        }
                    }
                    else{
                        newRooms.push(r);
                    }
                });
                d.rooms = newRooms;
            }
            if(d.spaces && d.spaces.length){
                const newSpaces = [];
                d.spaces.forEach(r => {
                    const idx = newSpaces.findIndex(nr => nr.name === r.name && nr.dealId === r.dealId);
                    if(idx >= 0){
                        const c = newSpaces[idx]
                        newSpaces[idx] = {
                            ...c,
                            numberOfSpaces: c.numberOfSpaces + r.numberOfSpaces,
                            startDate: r.startDate.isBefore(c.startDate, 'day'),
                            endDate: r.startDate.isAfter(c.endDate, 'day'),
                        }
                    }
                    else{
                        newSpaces.push(r);
                    }
                });
                d.spaces = newSpaces;
            }
        });

        const used = [];
        dealList.forEach(({dealId}) => {
            if(!dealColor.some(c => c.dealId === dealId)){
                let idx = Math.floor(Math.random() * colors.length + 1);
                let color = colors[idx];
    
                while(used.includes(color)){
                    idx = Math.floor(Math.random() * colors.length + 1);
                    color = colors[idx];
                }
    
                dealColor.push({
                    dealId,
                    color
                });
    
                used.push(color);
            }
        });
        hotelList.forEach(({hotelId}) => {
            if(!dealColor.some(c => c.dealId === hotelId)){
                let idx = Math.floor(Math.random() * colors.length + 1);
                let color = colors[idx];
    
                while(used.includes(color)){
                    idx = Math.floor(Math.random() * colors.length + 1);
                    color = colors[idx];
                }
    
                dealColor.push({
                    dealId: hotelId,
                    color
                });
    
                used.push(color);
            }
        });
        

        this.setState({
            months,
            calendarRows,
            totalCalendarRows,
            holidaysOfMonth,
            hotelList,
            dealColor
        });
    }

    addHolidays = () => {
        let { months, currentDate } = this.state;
        const holidays = global.hotelCountryCode ? getHolidays(currentDate.format("YYYY")).find(el => el.country.toLowerCase() === global.hotelCountryCode.toLowerCase()) : getHolidays(currentDate.format("YYYY"))[0];

        if (holidays) {
            holidays.months && holidays.months.forEach(dates => {
                const index = months.findIndex(m => m.month === dates.month);

                if (index > -1) {
                    months[index].holidays = dates.celebrations;
                }
            });
        }

        return months;
    }

    getFirstDayOfMonth = () => {
        let dateObject = this.state.currentDate;
        let firstDay = moment(dateObject)
            .startOf("month")
            .format("d");
        return firstDay;
    }

    getDaysInMonth = () => {
        let daysInMonth = [];

        for (let d = 1; d <= moment(this.state.currentDate).daysInMonth(); d++) {
            daysInMonth.push({
                day: d,
                date: moment(this.state.currentDate).startOf('month').add((d - 1), 'day'),
                hotels: [],
                deals: [],
                rooms: [],
                events: [],
                spaces: [],
            });
        }

        return daysInMonth;
    }

    getBlankCells = () => {
        let blanks = [];

        for (let i = 0; i < this.getFirstDayOfMonth(); i++) {
            blanks.push({ day: "" });
        }

        return blanks;
    }

    //#endregion

    handleCurrentDate = (name, value) => {
        let { currentDate } = this.state;

        currentDate = moment(currentDate).set(name, value);

        this.setState({ currentDate: currentDate, block: true }, () => this.getResources());
    }

    handleHotels = (n, combo) => {
        this.setState({ hotels: combo ? combo?.map(cmb => cmb.value) : [], block: true }, () => this.getResources());
    }

    changeMonth = (trans) => {
        const date = this.state.currentDate.add(trans, 'month');

        this.setState({
            currentDate: date,
            year: date.format("YYYY"),
            block: true
        }, () => this.getResources())
    }

    toggleOpenedDealDetail = (key, hotelKey) => {
        const { openedDealDetail } = this.state;

        if(!openedDealDetail[hotelKey])
            openedDealDetail[hotelKey] = [];

        openedDealDetail[hotelKey][key] = !openedDealDetail[hotelKey][key];
        this.setState({ openedDealDetail })
    }

    toggleOpenedHotelDetail = (key) => {
        const { openedHotelDetail } = this.state;

        openedHotelDetail[key] = !openedHotelDetail[key];
        this.setState({ openedHotelDetail });
    }

    handleStatus = (status) => {
        let { statusList } = this.state;
        if(statusList.includes(status)){
            statusList = statusList.filter(s => s !== status);
        }
        else{
            statusList.push(status);
        }
        this.setState({ statusList, block: true }, () => this.getResources());
    }

    handleStage = (combo) => {
        this.setState({ selectedStages: combo ? combo?.map(cmb => cmb.value) : [], block: true }, () => this.getResources());
    }

    setFilters = (objectType, objectId, label, hotelId, hotelName) => {
        const filters = [...this.state.filters];
        const idx = filters.findIndex((f) => f.objectType === objectType && f.objectId === objectId && f.hotelId === hotelId);

        if (idx >= 0) {
            filters.splice(idx, 1);
        }
        else {
            filters.push({ objectType, objectId, label, hotelId, hotelName });
        }

        this.updateHotels(filters);
    }

    updateHotels = (filters) => {
        const hotelList = [...this.state.hotelList];
        const hasFilters = filters.length > 0;
        const hotelFilters = filters.filter(({ objectType }) => objectType === 'Hotel');
        const dealFilters = filters.filter(({ objectType }) => objectType === 'Deal');

        hotelList.forEach(hotel => {
            const showHotel = hotelFilters.some(hf => hf.hotelId === hotel.hotelId);
            hotel.isVisible = hasFilters ? showHotel || filters.some(({ hotelId }) => hotelId === hotel.hotelId) : true;
            hotel.salesDeals.forEach(deal => {
                const showDeal = dealFilters.some(df => df.objectId === deal.dealId);
                const showRooms = filters.some(f => f.objectType === 'Rooms' && f.objectId === deal.dealId);
                const showSpaces = filters.some(f => f.objectType === 'Spaces' && f.objectId === deal.dealId);

                deal.isVisible = hasFilters ? showHotel || showDeal || showRooms || showSpaces : true;
                deal.showRooms = hasFilters ? showHotel || showDeal || showRooms : true;
                deal.showSpaces = hasFilters ? showHotel || showDeal || showSpaces : true;
            })
        })

        this.setState({ hotelList, filters }, this.renderCalendar);
    }

    hasFilter = (id, type, hotel) => {
        return [...this.state.filters].some(({ objectId, objectType, hotelId }) => objectId === id && objectType === type && hotelId === hotel)
    }


    render() {
        const { error, block, hotels, statusList, currentDate, weekDays, totalCalendarRows, holidaysOfMonth, calendarRows, hotelList, viewMode, yearOptions,
            viewModeOptions, dealColor, openedDealDetail, year, selectedStages, monthlyOcc, openedHotelDetail, filters, months } = this.state;
        const { icon, intl, isChild, toggleTab, isFromMonthly, selectedHotel } = this.props;
        
        return (
            <div className="border-0 h-100 pb-2">
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <div className='mb-4 d-flex align-items-center justify-content-state'>
                        {isChild ?
                            <div onClick={_ => toggleTab(isFromMonthly ? "SalesProcessExecutiveReportMonthly" : "SalesProcessExecutiveReportYearly", selectedHotel)} className='p-3 cursor-pointer mr-2'>
                                <i className='fas fa-chevron-left'/>
                            </div>
                        :''}
                        <h4 className='m-0'>
                            <i className={`${icon} mr-2`} />
                            <FormattedMessage id="SalesProcess.Resources" />
                        </h4>
                    </div>
                    <div>
                        <Row className='mt-4'>
                            <Col className='col-9'>
                                <div>
                                    <Row>
                                        <Col className='col-4 d-flex align-items-center'>
                                            <div className='d-flex align-items-center justify-content-start' style={{ fontSize: '0.7em' }}>
                                                {viewModeOptions.map((v, key) =>
                                                    <div key={key}onClick={() => this.setState({ viewMode: v.mode })} className={`deal-action-badge-${v.mode === "deals" ? 'OnGoing' : v.mode} ${viewMode === v.mode ? 'selected-deal-action-badge' : ''} mr-2 p-2`}>
                                                        <i className={`mr-1 ${v.icon}`}/>
                                                        <FormattedMessage id={`SalesProcess.${v.mode}`}/>
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                        <Col className='col-4 d-flex align-items-center justify-content-center'>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <div>
                                                    <div className='cursor-pointer' onClick={_ => this.changeMonth(-1)}>
                                                        <b><i className="fas fa-chevron-left"/></b>
                                                    </div>
                                                </div>
                                                <div className='mx-4 text-center pointer position-relative' style={{ minWidth: '15ch' }} onClick={_ => this.setState({ changeCurrentDateOpened: !this.state.changeCurrentDateOpened })}>
                                                    <b style={{ fontSize: '1.1em' }}>
                                                        {currentDate.format('MMMM YYYY')}
                                                    </b>

                                                    {this.state.changeCurrentDateOpened &&
                                                        <div className="border shadow position-absolute p-2" style={{ backgroundColor: 'white', width: '200px', left: '-25%', zIndex: '99', top: '150%' }}>
                                                            <Row>
                                                                <Col sm={6} className="pr-0">
                                                                    <div className="pb-3 pt-1 text-center title-sm"><b><FormattedMessage id="SalesProcess.Month" /></b></div>

                                                                    <div className="verticalScroll" style={{ overflow: 'auto', height: '100px', overflowX: 'hidden' }}>
                                                                        {months.map((month, key) => {
                                                                            const isSelected = moment(currentDate).month() === key;

                                                                            return <div className={'p-1 ' + (isSelected ? '' : 'title-sm overbglight')} key={key} onClick={_ => !isSelected && this.handleCurrentDate('month', key)}>
                                                                                <FormattedMessage id={`UniqueCampaigns.Month${key + 1}`} />
                                                                            </div>
                                                                        })}
                                                                    </div>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <div className="pb-3 pt-1 text-center title-sm"><b><FormattedMessage id="SalesProcess.Year" /></b></div>

                                                                    {yearOptions.map((year, key) => {
                                                                        const isSelected = moment(currentDate).year() === year;

                                                                        return <div className={'p-1 ' + (isSelected ? '' : 'title-sm overbglight')} key={key} onClick={_ => !isSelected && this.handleCurrentDate('year', year)}>
                                                                            {year}
                                                                        </div>
                                                                    }) }
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    }
                                                </div>
                                                <div>
                                                    <div className='cursor-pointer' onClick={_ => this.changeMonth(1)}>
                                                        <b><i className="fas fa-chevron-right"/></b>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className='col-4 d-flex align-items-center justify-content-end'>
                                            <AppliedFiltersButton
                                                filters={filters}
                                                setFilters={this.setFilters}
                                            />
                                        </Col>
                                    </Row>
                                    <div className='mt-4'>
                                        <Row className="mb-3 pl-4 align-items-center px-3">
                                            {weekDays && weekDays.map(day =>
                                                <Col key={day} className="week-day py-0 px-1 text-center">
                                                    {day}
                                                </Col>
                                            )}
                                        </Row>
                                        <div className='px-3'>
                                            {calendarRows && calendarRows.map((week, i) => week.length > 0 ?
                                                <Row key={i} style={{ height: `${(100 / totalCalendarRows - 1)}%`, minHeight: '120px', overflow: 'hidden' }}>

                                                    {week.map((day, key) => {
                                                        const holiday = holidaysOfMonth && holidaysOfMonth.find(hom => hom.day === day.day);

                                                        const isCurrentDay = day.date && moment().isSame(day.date, 'day') ? " bg-host-selected circle-md" : '';
                                                        const isHoliday = isCurrentDay === '' && holiday ? " bg-dark-blue circle-md" : '';
                                                        const calendarDay = isCurrentDay === '' && isHoliday === '' ? ' py-1 px-2' : '';

                                                        const bg = (id) => dealColor.find(({dealId}) => dealId === id)?.color??'#0665ff';
                                                        
                                                        return (
                                                            <Col key={key} className={`calendar-border p-3 overflow-hidden` + (key === 0 || key === 6 ? ' bg-weekend' : '')}>
                                                                <div className="d-flex align-items-center">
                                                                    <div className={` ${isCurrentDay} ${isHoliday} ${calendarDay}`}>
                                                                        <b> {day.day} </b>
                                                                    </div>
                                                                    <b className={"ml-2 overflow-hidden text-nowrap text-truncate w-100 text-dark-blue"} title={holiday?.label}>
                                                                        {holiday ?
                                                                            holiday.label
                                                                        :''}
                                                                    </b>
                                                                </div>
                                                                <div className='veryCoolScrollBar pt-1' style={{ fontSize: '0.9em', maxHeight: '100px', overflow: 'auto' }}>
                                                                    {viewMode === "hotels" && day.hotels && day.hotels.length > 0 ?
                                                                        day.hotels.map((hotel, hkey) => {
                                                                            const roomsInv = monthlyOcc[0].rooms;
                                                                            const spacesInv = monthlyOcc[0].spaces;

                                                                            const roomsOcc = Math.round(((hotel.numberOfRooms / roomsInv) * 100) * 100) / 100;
                                                                            const spacesOcc = Math.round(((hotel.numberOfSpaces / spacesInv) * 100) * 100) / 100;

                                                                            const roomsColor = roomsOcc > 66 ? '#d9534f' : roomsOcc > 33 ? '#f0ad4e' : '#5cb85c';
                                                                            const spacesColor = spacesOcc > 66 ? '#d9534f' : spacesOcc > 33 ? '#f0ad4e' : '#5cb85c';

                                                                            return (
                                                                                <React.Fragment key={hkey}>
                                                                                    <div style={{ border: `2px solid ${bg(hotel.hotelId)}`, color: 'unset' }} id={`hotels-${i}-${key}-${hkey}`} className='sales-deal-calendar-item text-truncate mb-1'>
                                                                                        {hotel.numberOfRooms !== null ?
                                                                                            <div className='d-flex align-items-center w-100' id={`hotels-rooms-${i}-${key}-${hkey}`}>
                                                                                                <div className='mr-2'>
                                                                                                    <i className="fas fa-bed" />
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span>
                                                                                                        {hotel.numberOfRooms}
                                                                                                    </span>
                                                                                                    <span>/</span>
                                                                                                    <b style={{ color: roomsColor }}>{roomsInv}</b>
                                                                                                </div>
                                                                                            </div>
                                                                                        : ''}
                                                                                        {hotel.numberOfSpaces !== null ?
                                                                                            <div className='d-flex align-items-center justify-content-end w-100' id={`hotels-spaces-${i}-${key}-${hkey}`}>
                                                                                                <div className='mr-2'>
                                                                                                    <span>{hotel.numberOfSpaces}</span>
                                                                                                    <span>/</span>
                                                                                                    <b style={{ color: spacesColor }}>{spacesInv}</b>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <i className="fas fa-store-alt" />
                                                                                                </div>
                                                                                            </div>
                                                                                        : ''}
                                                                                    </div>
                                                                                    <>
                                                                                        <CoolTooltip placement="right" target={`hotels-rooms-${i}-${key}-${hkey}`}>
                                                                                            <div>{roomsOcc}%</div>
                                                                                        </CoolTooltip>
                                                                                        <CoolTooltip placement="left" target={`hotels-spaces-${i}-${key}-${hkey}`}>
                                                                                            <div>{spacesOcc}%</div>
                                                                                        </CoolTooltip>
                                                                                    </>
                                                                                </React.Fragment>
                                                                            )}
                                                                        )
                                                                    :''}
                                                                    {viewMode === "deals" && day.deals && day.deals.length > 0 ?
                                                                        day.deals.map((deal, dkey) =>
                                                                            <div style={{ backgroundColor: bg(deal.dealId) }} key={dkey} id={`deals-${i}-${key}-${dkey}`} className='sales-deal-calendar-item text-truncate mb-1'>
                                                                                <div className='d-flex'>
                                                                                    <div className='mr-2'>
                                                                                        <i className="fas fa-bed"/>
                                                                                    </div>
                                                                                    <div>
                                                                                        {deal.numberOfRooms}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='d-flex'>
                                                                                    <div className='mr-2'>
                                                                                        {deal.numberOfSpaces}
                                                                                    </div>
                                                                                    <div>
                                                                                        <i className="fas fa-store-alt"/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    :''}
                                                                    {viewMode === "rooms" && day.rooms && day.rooms.length > 0 ?
                                                                        day.rooms.map((room, rkey) =>
                                                                            <div style={{ backgroundColor: bg(room.dealId) }} key={rkey} id={`rooms-${i}-${key}-${rkey}`} className='sales-deal-calendar-item text-truncate mb-1'>
                                                                                <div className='text-truncate'>{room.category}</div>
                                                                                <div>
                                                                                    {room.numberOfRooms}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    :''}
                                                                    {viewMode === "events" && day.events && day.events.length > 0 ?
                                                                        day.events.map((event, ekey) =>
                                                                            <div style={{ backgroundColor: bg(event.dealId) }} key={ekey} id={`rooms-${i}-${key}-${ekey}`} className='sales-deal-calendar-item text-truncate mb-1'>
                                                                                <div className='text-truncate'>{event.name}</div>
                                                                            </div>
                                                                        )
                                                                    :''}
                                                                    {viewMode === "spaces" && day.spaces && day.spaces.length > 0 ?
                                                                        day.spaces.map((space, skey) =>
                                                                            <div style={{ backgroundColor: bg(space.dealId) }} key={skey} id={`spaces-${i}-${key}-${skey}`} className='sales-deal-calendar-item text-truncate mb-1'>
                                                                                <div className='text-truncate'>{space.name}</div>
                                                                                <div>
                                                                                    {space.numberOfSpaces}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    :''}
                                                                </div>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            :'')}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col className='col-3'>
                                <div style={{ top: '0', position: 'sticky' }}>
                                    <div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div>
                                                <h5 className='m-0 text-truncate'>
                                                    <FormattedMessage id="SalesProcess.Deals"/>
                                                </h5>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-end" style={{ fontSize: '0.7em' }}>
                                                <div onClick={_ => this.handleStatus("WaitingHotel")} className={`deal-action-badge-OnGoing ${statusList.includes("WaitingHotel") ? 'selected-deal-action-badge' : ''} ml-2 p-2 text-truncate`}>
                                                    <i className="icon-icon-hotel mr-1"/>
                                                    <FormattedMessage id={`SalesProcess.Hotel`}/>
                                                </div>
                                                <div onClick={_ => this.handleStatus("WaitingClient")} className={`deal-action-badge-Scheduled ${statusList.includes("WaitingClient") ? 'selected-deal-action-badge' : ''} ml-2 p-2 text-truncate`}>
                                                    <i className="far fa-building mr-1"/>
                                                    <FormattedMessage id={`SalesProcess.Client`}/>
                                                </div>
                                                <div onClick={_ => this.handleStatus("Cancelled")} className={`deal-action-badge-Cancelled ${statusList.includes("Cancelled") ? 'selected-deal-action-badge' : ''} ml-2 p-2 text-truncate`}>
                                                    <i className="far fa-thumbs-down mr-1"/>
                                                    <FormattedMessage id={`SalesProcess.Lost`}/>
                                                </div>
                                                <div onClick={_ => this.handleStatus("Finished")} className={`deal-action-badge-Finished ${statusList.includes("Finished") ? 'selected-deal-action-badge' : ''} ml-2 p-2 text-truncate`}>
                                                    <i className="fas fa-trophy mr-1"/>
                                                    <FormattedMessage id={`SalesProcess.Won`}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-100 mt-3'>
                                            <SelectHotel
                                                isSearchable
                                                name='hotels'
                                                isMulti={true}
                                                onChangeFunc={this.handleHotels}
                                                placeholder={'Hotel'}
                                                value={hotels || ''}
                                                params='?onlyFromHotelGroup=true'
                                            />
                                        </div>
                                        <div className='w-100 mt-3'>
                                            <SelectCustomType
                                                isMulti={true}
                                                onChangeFunc={this.handleStage}
                                                value={selectedStages}
                                                placeholder={intl.formatMessage({ id: "SalesProcess.StageType" })}
                                                isDisabled={false}
                                                isClearable={true}
                                                type={'PipelineStageType'}
                                            />
                                        </div>
                                    </div>
                                    <div className='shadow w-100 bg-white mt-4 p-3'>
                                        {hotelList?.length ?
                                            hotelList.map((hotel, hotelKey) => {
                                                const hotelName = global.hotelList?.find(({ value }) => value === hotel.hotelId)?.label ?? '';

                                                return <div key={hotelKey} className={hotelKey ? `mt-3 pt-3` : ''} style={{ borderTop: hotelKey ? '1px solid #bfbfbf' : '' }}>
                                                    <div style={{ fontSize: '1.1em' }} className='text-muted d-flex align-items-center justify-content-between'>
                                                        <div style={{ maxWidth: '70%' }} onClick={_ => this.setFilters("Hotel", null, null, hotel.hotelId, hotelName)}>
                                                            <div className="d-flex align-items-center pointer position-relative" style={{  width: 'fit-content', maxWidth: '100%' }}>
                                                                <div style={{
                                                                    backgroundColor: dealColor.find(({ dealId }) => dealId === hotel.hotelId)?.color ?? '#0665ff',
                                                                    aspectRatio: '1/1',
                                                                    height: '10px'
                                                                }} />
                                                                <div className='ml-1 text-truncate'>
                                                                    {hotelName}
                                                                </div>
                                                                {this.hasFilter(null, "Hotel", hotel.hotelId) ? <FilterDot /> : ''}
                                                            </div>
                                                        </div>
                                                        <div style={{ maxWidth: '30%' }} className='d-flex aling-items-center justify-content-end'>
                                                            <b>
                                                                <FormatAmountNumber value={hotel.expectedValue} />
                                                            </b>
                                                            <div className='cursor-pointer ml-2' onClick={_ => this.toggleOpenedHotelDetail(hotelKey)}>
                                                                {openedHotelDetail[hotelKey] ?
                                                                    <i className="fas fa-chevron-up" />
                                                                    :
                                                                    <i className="fas fa-chevron-down" />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Collapse isOpen={openedHotelDetail[hotelKey]}>
                                                        <div>
                                                            {hotel.salesDeals.map((deal, key) =>
                                                                <div key={key} className={`mt-2 pt-2`} style={{ borderTop: key ? '1px solid lightgrey' : '' }}>
                                                                    <Row>
                                                                        <Col className='col-9 pr-0'>
                                                                            <div className='d-flex align-items-center pointer position-relative' style={{ fontSize: '0.9em', width: 'fit-content', maxWidth: '100%' }} onClick={_ => this.setFilters("Deal", deal.dealId, deal.dealName, hotel.hotelId, hotelName)} >
                                                                                <div style={{
                                                                                    backgroundColor: dealColor.find(({ dealId }) => dealId === deal.dealId)?.color ?? '#0665ff',
                                                                                    aspectRatio: '1/1',
                                                                                    height: '10px'
                                                                                }} />
                                                                                <div className='ml-1 text-truncate' style={{ marginTop: '1px' }}>
                                                                                    {deal.dealName}
                                                                                </div>
                                                                                {this.hasFilter(deal.dealId, "Deal", hotel.hotelId) ? <FilterDot /> : ''}
                                                                            </div>


                                                                        </Col>
                                                                        <Col className='col-3 d-flex align-items-center justify-content-end'>
                                                                            <DealStatus status={deal.status} id={`Hotel-${hotelKey}-Deal-${key}`} />

                                                                            <div className='cursor-pointer' onClick={_ => this.toggleOpenedDealDetail(key, hotelKey)}>
                                                                                {openedDealDetail[hotelKey] && openedDealDetail[hotelKey][key] ?
                                                                                    <i className="fas fa-chevron-up" />
                                                                                    :
                                                                                    <i className="fas fa-chevron-down" />
                                                                                }
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Collapse isOpen={openedDealDetail[hotelKey] && openedDealDetail[hotelKey][key]}>
                                                                        <div className='text-muted px-2' style={{ fontSize: '0.9em' }}>
                                                                            <div className='d-flex align-items-center justify-content-between mt-2'>
                                                                                <div className="pointer position-relative" onClick={_ => this.setFilters("Rooms", deal.dealId, deal.dealName, hotel.hotelId, hotelName)}>
                                                                                    <FormattedMessage id="SalesProcess.Rooms" />
                                                                                    {this.hasFilter(deal.dealId, "Rooms", hotel.hotelId) ? <FilterDot /> : ''}
                                                                                </div>
                                                                                <div>
                                                                                    <b>{deal.proposals?.reduce((a, b) => a + b.numberOfRooms, 0) ?? 0}</b>
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex align-items-center justify-content-between mt-2'>
                                                                                <div className="pointer position-relative" onClick={_ => this.setFilters("Spaces", deal.dealId, deal.dealName, hotel.hotelId, hotelName)}>
                                                                                    <FormattedMessage id="SalesProcess.Spaces" />
                                                                                    {this.hasFilter(deal.dealId, "Spaces", hotel.hotelId) ? <FilterDot /> : ''}
                                                                                </div>
                                                                                <div>
                                                                                    <b>{deal.proposals?.reduce((a, b) => a + b.numberOfSpaces, 0) ?? 0}</b>
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex align-items-center justify-content-between mt-2'>
                                                                                <div>
                                                                                    <FormattedMessage id="SalesProcess.Pax" />
                                                                                </div>
                                                                                <div>
                                                                                    <b>{deal.proposals?.reduce((a, b) => a + (b.adults + b.children), 0) ?? 0}</b>
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex align-items-center justify-content-between mt-2'>
                                                                                <div>
                                                                                    <FormattedMessage id="SalesProcess.ExpectedValue" />
                                                                                </div>
                                                                                <div>
                                                                                    <b>
                                                                                        <FormatAmountNumber value={deal.expectedValue} />
                                                                                    </b>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Collapse>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            })
                                        :
                                            <div className='text-center text-muted' style={{ fontSize: '0.9em' }}>
                                                <FormattedMessage id="SalesProcess.NoDeals"/>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(SalesProcessResources);


const DealStatus = ({ status, id }) => {
    const iconClass = status === 'WaitingHotel' ?
        'deal-action-status-OnGoing icon-icon-hotel' :
            status === 'WaitingClient' ?
                'deal-action-status-Scheduled far fa-building' :
            status === 'Cancelled' ?
                'deal-action-status-Cancelled far fa-thumbs-down' :
                'deal-action-status-Finished fas fa-trophy'

    return <div className='text-truncate ' style={{ fontSize: '0.9em' }}>
        <i className={`${iconClass} mr-2`} id={id} />

        <CoolTooltip placement="left" target={id}>
            <FormattedMessage id={`SalesProcess.${status}`} />
        </CoolTooltip>
    </div>
}

const FilterDot = () => {
    return <div style={{
        width: '5px',
        height: '5px',
        background: '#0665ff',
        borderRadius: '100%',
        position: 'absolute',
        top: '0',
        right: '-7px'
    }} />
}


const AppliedFiltersButton = ({ filters, setFilters }) => {
    const groupedFiltersByHotel = filters.reduce((acc, item) => {
        const hotel = acc.find(hotel => hotel.hotelId === item.hotelId);
        const showRooms = item.objectType === 'Rooms';
        const showSpaces = item.objectType === 'Spaces';
        const isHotelFilter = item.objectType === 'Hotel';

        if (hotel) {
            if (isHotelFilter) {
                hotel.isFilter = isHotelFilter;
            }
            else {
                const dealIndex = hotel.deals.findIndex(d => d.dealId === item.objectId);

                if (dealIndex !== -1) {
                    if (showRooms) hotel.deals[dealIndex].showRooms = true;
                    if (showSpaces) hotel.deals[dealIndex].showSpaces = true;
                }
                else {
                    hotel.deals.push({ dealId: item.objectId, dealName: item.label, showRooms, showSpaces });
                }
            }
        } else {
            const newHotel = {
                hotelId: item.hotelId,
                hotelName: item.hotelName,
                isFilter: isHotelFilter,
                deals: []
            }
            if (!isHotelFilter) {
                newHotel.deals.push({ dealId: item.objectId, dealName: item.label, showRooms, showSpaces });
            }

            acc.push(newHotel);
        }
        return acc;
    }, []);


    return <div>
        <Button className="ml-2 btn btn-host btn-sm" id={`AppliedFilters`}>
            <i className="fas fa-sliders-h" />
        </Button>
        <UncontrolledPopover target={`AppliedFilters`} placement={"left-start"} placementPrefix='coolPopover bs-popover'>
            <div className='py-2 px-3 mb-2' style={{ minWidth: '200px', maxWidth: '280px' }}>
                <div className='text-muted d-flex align-items-center mb-3' style={{ fontSize: '1.1em' }}>
                    <i className="fas fa-sliders-h mr-2 mt-1" />
                    <FormattedMessage id="SalesDeal.Filters" />
                </div>
                {groupedFiltersByHotel.length > 0 ?
                    <div className="verticalScroll" style={{ maxHeight: '300px', overflowX: 'hidden' }}>
                        {groupedFiltersByHotel.map((hotel, index) =>
                            <div key={index} className="text-ellipsis mb-3">
                                <Row>
                                    <Col className="text-ellipsis">
                                        <b><i className="icon-icon-hotel" /> {hotel.hotelName}</b>
                                    </Col>
                                    {hotel.isFilter ?
                                        <Col className="col-2 pl-0 text-right">
                                            <div style={{ color: '#A80C19' }} className='cursor-pointer' onClick={_ => setFilters("Hotel", null, null, hotel.hotelId)}>
                                                <i className="fas fa-times"></i>
                                            </div>
                                        </Col>
                                    : ''}
                                </Row>
                                {hotel.deals?.map((deal, dealIndex) =>
                                    <div className="pl-3" key={dealIndex}>
                                        <div className="my-2">
                                            <Row>
                                                <Col className="text-ellipsis">
                                                    <i className="fas fa-briefcase" /> {deal.dealName}
                                                </Col>
                                                <Col className="col-2 pl-0 text-right">
                                                    <div style={{ color: '#A80C19' }} className='cursor-pointer' onClick={_ => setFilters("Deal", deal.dealId, null, hotel.hotelId)}>
                                                        <i className="fas fa-times"></i>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        {deal.showRooms &&
                                            <Row>
                                                <Col className="ml-3 text-muted">
                                                Rooms
                                                </Col>
                                                <Col className="col-2 pl-0 text-right">
                                                    <div style={{ color: '#A80C19' }} className='cursor-pointer' onClick={_ => setFilters("Rooms", deal.dealId, null, hotel.hotelId)}>
                                                        <i className="fas fa-times"></i>
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                        {deal.showSpaces &&
                                            <Row>
                                                <Col className="ml-3 text-muted">
                                                    Spaces
                                                </Col>
                                                <Col className="col-2 pl-0 text-right">
                                                    <div style={{ color: '#A80C19' }} className='cursor-pointer' onClick={_ => setFilters("Spaces", deal.dealId, null, hotel.hotelId)}>
                                                        <i className="fas fa-times"></i>
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    :
                    <div className='text-muted mt-2 text-center'>
                        <FormattedMessage id="SalesDeal.NoFilters" />
                    </div>
                }
            </div>
        </UncontrolledPopover>
    </div>
}