import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from 'react-intl';
import { Breadcrumb, BreadcrumbItem, Button, Card, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { StyledCard } from "../../Base/CommonUIComponents";
import { handleNotification } from "../../Base/Notification";
import CustomSelect from "../../Base/CustomSelect";
import { getAPI } from "../../Base/API";

export class FormList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            formList: [],
            egoiLists: [],
            listId: null
        };
    }

    componentDidMount() {
        this.getEgoiLists();
        this.getReviwMappings();
    }

    getEgoiLists = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.items && data.items.length > 0) {
                    let egoiList = [];

                    data.items && data.items.forEach(list => {
                        egoiList.push({ value: list.list_id, label: list.public_name })
                    })

                    this.setState({ egoiLists: egoiList, listId: data.items[0].list_id }, () => this.getFormList());
                }
            }
        }, `/api/gms/Marketing/v1/egoi/lists?limit=100&offset=0`);
    }

    getFormList = () => {
        this.setState({ block: true });

        var param = '';
        if (this.state.listId) param += `listId=${this.state.listId}`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.getReviwMappings(data.response);
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/Forms?` + param);
    }

    getReviwMappings = (formList) => {
        if (formList) {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, formList, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    if (data.response && data.response.length > 0) {
                        formList.forEach(el => {
                            el.isMapped = data.response.some(rm => rm.formId == el.id);
                        })
                    }

                    this.setState({ formList, block: false });
                }
                else this.setState({ block: false, formList });
            }, `/api/gms/Review/v1/reviewMapping/Egoi?listId=${this.state.listId}`);
        }
        else {
            this.setState({ formList, block: false });
        }
    }

    handleSelect = (combo, name) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    };


    render() {
        const { block, error, formList } = this.state;

        return (

            <StyledCard block={block} error={error} >
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/MarketingConfigEgoi"> Config</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Form</BreadcrumbItem>
                    </Breadcrumb>
                </div>

                <Row className="mb-3 pr-3">
                    <Col className="col-3">
                        <CustomSelect
                            options={this.state.egoiLists}
                            required
                            isClearable
                            isSearchable
                            placeholder={this.props.intl.formatMessage({ id: "EgoiList.List" })}
                            onChange={(e) => this.handleSelect(e, "listId")}
                            value={this.state.listId ? this.state.egoiLists.find(l => l.value === this.state.listId) : ''}
                        />
                    </Col>
                    <Col className="text-right">
                        <Button className="btn- btn-host btn-small btn-sm" onClick={this.getFormList}>
                            <i className="icon-icon-search" />
                        </Button>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col>
                        {formList && formList.length > 0 ?
                            formList.map((el, k) =>
                                <Card className="border-0 p-3 pointer mb-2 shadow" key={k} >
                                    <Row>
                                        <Col className="col-2"><b>{el.title} </b></Col>
                                        <Col className="col-6"><a href={`//${el.url}`} target="_blank">{el.url}</a> </Col>
                                        <Col className="col-4 text-right">
                                            {el.isMapped &&
                                                <>
                                                    <i className="fas fa-random mr-4 color-host" id={`Survey${k}`} />
                                                    <UncontrolledTooltip target={`Survey${k}`} placement="bottom">
                                                        <FormattedMessage id="reviewProConfig.AlreadyMapped" />
                                                    </UncontrolledTooltip>
                                                </>
                                            }
                                            <Button className="btn- btn-host btn-small btn-sm" tag={Link} to={{ pathname: '/FormSubmissions', search: `?list=${this.state.listId}&form=${el.id}`, state: { formTitle: el.title } }}>
                                                 <i className="fas fa-file-signature" />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            )
                            : <div className="text-center mt-4"> <FormattedMessage id="FormList.ThereIsNoForm" /> </div>}
                    </Col>
                </Row>

            </StyledCard>
        );
    }
}
export default injectIntl(FormList)
