import React, { Component } from 'react';
import { Button, Card, Col, Row, Modal, ModalBody } from 'reactstrap';
import { getAPI } from '../../Base/API';
import { StyledCard } from '../../Base/CommonUIComponents';
import { SendGridTemplateValidations } from '../../Base/TemplateByCountry';
import Authorization from '../../Base/Authorization';
import { VerificationEmailSpamModal } from '../VerificationEmailSpamModal';

export class PreviewSendGridTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            htmlBody: null,
            verificationEmailSpamModal: false
        }
    }

    componentDidMount() {
        this.getTemplateById();
    }

    getTemplateById = () => {
        const { templateId } = this.props;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.versions && data.response.versions.length > 0) {
                const htmlBody = data.response.versions
                    .filter(vrs => vrs.active === 1)
                    .map(vrs => vrs.html_content)
                    .reduce((acc, curr) => acc ? acc : curr, null);

                this.setState({ error: errorMessage, block: false, htmlBody });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/SendGrid/v1/templates/${templateId}`);
    }

    toggleVerificationEmailSpamModal = () => {
        this.setState(prevState => ({ verificationEmailSpamModal: !prevState.verificationEmailSpamModal }));
    }

    render() {
        const { isOpen, templateName, toggle } = this.props;
        const { block, error, htmlBody, verificationEmailSpamModal } = this.state;

        return (
            <Modal isOpen={isOpen} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                {
                    verificationEmailSpamModal ?
                        <VerificationEmailSpamModal isOpen={verificationEmailSpamModal} toggle={this.toggleVerificationEmailSpamModal} htmlBody={htmlBody} />
                        :
                        ''
                }
                <Button onClick={toggle} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <StyledCard block={block} error={error}>
                        <Row>
                            <Col>
                                <Card className="p-2 border-0 mb-3 shadow h-100">
                                    <Row>
                                        <Col>
                                            <h5>{templateName}</h5>
                                        </Col>
                                        <Col className=" py-2 mr-1 pl-0 d-flex align-items-center justify-content-end">
                                            <SendGridTemplateValidations template={htmlBody} target="TooltipEmailSendGridValidate" />
                                            <Authorization
                                                perform="transactionalTemplate:spanCheck"
                                                yes={() => (
                                                    <Button className="btn btn-host btn-sm ml-2" onClick={this.toggleVerificationEmailSpamModal}>
                                                        <i className="fas fa-check" />
                                                    </Button>
                                                )}
                                                no={() => <div></div>}
                                            />
                                        </Col>
                                    </Row>
                                    <div dangerouslySetInnerHTML={{ __html: htmlBody }} />
                                </Card>
                            </Col>
                        </Row>
                    </StyledCard>
                </ModalBody>
            </Modal>
        );
    }
}