import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Col, Row, Badge, Popover, Collapse, UncontrolledTooltip } from "reactstrap";
import { getHolidays, PreviewTemplates } from './CommonFunctions';
import { withRouter } from 'react-router-dom';
import { ErrorAlert } from "../../Base/ErrorAlert";
import BlockUi from 'react-block-ui';
import moment from 'moment';


class UniqueCampaigns extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            block: false,
            showDayDetails: false, //Cartão que aparece do lado direito do ecrã ao selecionar um dia do calendário
            allMonthsView: true, //Tipo de vista selecionado
            showViewOptions: false, //Cartão com opções de vista
            previewTemplates: { isOpen: false , templates: null}, 
            campaigns: this.props.uniqueCampaigns ? this.props.uniqueCampaigns : [],
            calendarRows: [],
            currentDate: moment(),
            weekDays: moment.weekdaysShort(),
            months: [
                { month: 1, totalCampaigns: 0, campaigns: [], holidays: [] },
                { month: 2, totalCampaigns: 0, campaigns: [], holidays: [] },
                { month: 3, totalCampaigns: 0, campaigns: [], holidays: [] },
                { month: 4, totalCampaigns: 0, campaigns: [], holidays: [] },
                { month: 5, totalCampaigns: 0, campaigns: [], holidays: [] },
                { month: 6, totalCampaigns: 0, campaigns: [], holidays: [] },
                { month: 7, totalCampaigns: 0, campaigns: [], holidays: [] },
                { month: 8, totalCampaigns: 0, campaigns: [], holidays: [] },
                { month: 9, totalCampaigns: 0, campaigns: [], holidays: [] },
                { month: 10, totalCampaigns: 0, campaigns: [], holidays: [] },
                { month: 11, totalCampaigns: 0, campaigns: [], holidays: [] },
                { month: 12, totalCampaigns: 0, campaigns: [], holidays: [] }
            ],
            selectedDay: null,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.uniqueCampaigns !== this.props.uniqueCampaigns) {
            this.setState({
                campaigns: nextProps.uniqueCampaigns
            }, () => this.countCampaigns());
        }
    }

    componentDidMount() {
        this._isMounted = true;

        window.setTimeout(
            () => {
                this.addHolidays();
            }, global.hotelCountryCode ? 0 : 3000
        );

        this.renderCalendar();
    }

    addHolidays = () => {
        let { months, currentDate } = this.state;
        const holidays = global.hotelCountryCode ? getHolidays(currentDate.format("YYYY")).find(el => el.country.toLowerCase() === global.hotelCountryCode.toLowerCase()) : getHolidays(currentDate.format("YYYY"))[0];

        if (holidays) {
            holidays.months && holidays.months.forEach(dates => {
                const index = months.findIndex(m => m.month === dates.month);

                if (index > -1) {
                    months[index].holidays = dates.celebrations;
                }
            });

            this.setState({ months });
        }
    }

    countCampaigns = () => {
        let { months } = this.state;

        months = months.map(m => { return { ...m, campaigns: [], totalCampaigns: 0 } });

        this.state.campaigns && this.state.campaigns
            .filter(c => c.scheduleDay && moment(this.state.currentDate).isSame(moment(c.scheduleDay), 'year'))
            .forEach(campaign => {
                const index = months.findIndex(m => m.month == moment(campaign.scheduleDay).format('M'));
                
                if (index !== -1) {
                    months[index].totalCampaigns++;
                    months[index].campaigns.push(campaign);
                }
                months[index].campaigns.sort((a, b) => a.distance - b.distance);
            });
        
        this.setState({ months });
    }

    //#region calendar
    renderCalendar = () => {
        let blank = this.getBlankCells();
        let daysInMonth = this.getDaysInMonth();
        let totalSlots = [...blank, ...daysInMonth];
        let cells = [], rows = [];

        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row); // if index not equal 7 that means not go to next week
            } else {
                rows.push(cells); // when reach next week we contain all td in last week to rows 
                cells = []; // empty container 
                cells.push(row); // in current loop we still push current row to new container
            }
            if (i === totalSlots.length - 1) { // when end loop we add remain date
                while (cells.length < 7) {
                    cells.push({ day: "" })
                }
                rows.push(cells);
            }
        });

        this.setState({ calendarRows: rows }, () => this.countCampaigns())
    }

    getFirstDayOfMonth = () => {
        let dateObject = this.state.currentDate;
        let firstDay = moment(dateObject)
            .startOf("month")
            .format("d");
        return firstDay;
    };

    getDaysInMonth = () => {
        let daysInMonth = [];

        for (let d = 1; d <= moment(this.state.currentDate).daysInMonth(); d++) {
            daysInMonth.push({ day: d });
        }

        return daysInMonth;
    }

    getBlankCells = () => {
        let blanks = [];

        for (let i = 0; i < this.getFirstDayOfMonth(); i++) {
            blanks.push({ day: "" });
        }

        return blanks
    }
    //#endregion

    changeMonth = (month) => {
        const date = moment(this.state.currentDate.format('YYYY-' + month + '-DD'));

        this.setState({
            currentDate: date
        }, () => this.renderCalendar())
    }

    changeYear = (value) => {
        const date = moment(this.state.currentDate.add(value, 'year').format('YYYY-MM-DD'));

        this.setState({
            currentDate: date
        }, () => {
            this.addHolidays();
            this.renderCalendar()
        })
    }

    toggleDayDetails = (day, campaigns) => {
        this.setState(prevState => ({
            showDayDetails: !prevState.showDayDetails,
            selectedDay: day && !prevState.showDayDetails ? { 'day': day, 'campaigns': campaigns } : null
        }));
    }

    toggleBool = (name) => {
        this.setState(prevState => ({
            [name]: !prevState[name],
        }));
    }

    //Open / Close Collapse with template previews
    handleDetails = (e, key, templates, value) => {
        e.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            [key]: { 'isOpen': (value ? value : prevState[key] ? !prevState[key].isOpen : true), 'templates': templates },
        }));
    }

    //Handle popover at active months view
    handlePreview = (e, templates, campaignId) => {
        e.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            previewTemplates: { isOpen: !prevState.previewTemplates.isOpen, templates: templates, campaign: campaignId },
        }));
    }

    handleCampaignDetailsMode = (e, key) => {
        e.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            [key]: (prevState[key] ? !prevState[key] : true)
        }));
    }

    render() {
        const { weekDays, calendarRows, error, block, currentDate, months, showDayDetails, selectedDay, allMonthsView, showViewOptions, previewTemplates } = this.state;
        const totalCalendarRows = calendarRows && calendarRows.filter(cr => cr.length > 0).length;

        //Data of selected month
        const campaignsOfMonth = months.find(m => m.month == currentDate.format("M")).campaigns;
        const holidaysOfMonth = months ? months.find(m => m.month == moment(currentDate).format('M')).holidays : [];

        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />
                <Row style={{ height: '87vh', zIndex: '0' }} className="pt-4">
                    <Col sm={10}>
                        <Row className="mb-3 pl-4 align-items-center">
                            {weekDays && weekDays.map(day =>
                                <Col key={day} className="week-day py-0 px-1 text-center">
                                    {day}
                                </Col>
                            )}
                        </Row>

                        {/*Calendar*/}
                        <div className="h-100">
                            {calendarRows && calendarRows.map((week, i) => week.length > 0 ?
                                <Row className="ml-2" key={i} style={{ height: `${(100 / totalCalendarRows - 1)}%`, overflow: 'hidden' }}>

                                    {week.map(d => d.day).map((day, key) => {
                                        const campaigns = campaignsOfMonth && campaignsOfMonth.filter(com => moment(com.scheduleDay).format("D") == day);
                                        const holiday = holidaysOfMonth && holidaysOfMonth.find(hom => hom.day === day);

                                        const isSelected = selectedDay && selectedDay.day === day ? " bg-host" : '';
                                        const isSelectedBadge = isSelected !== '' ? " circle-md text-host bg-white" : '';

                                        const isCurrentDay = isSelected === '' && currentDate.format("D") == day ? " bg-host-selected circle-md" : '';
                                        const isHoliday = isSelected === '' && isCurrentDay === '' && holiday ? " bg-dark-blue circle-md" : '';
                                        const calendarDay = isSelected === '' && isCurrentDay === '' && isHoliday === '' ? ' py-1 px-2' : '';

                                        return (
                                            <Col key={key} className={"calendar-border p-3 cursor-pointer overflow-hidden " + (key === 0 || key === 6 ? ' bg-weekend' : '') + isSelected} onClick={() => { if (campaigns && campaigns.length > 0) this.toggleDayDetails(day, campaigns) }}>
                                                <div className="mb-3 d-flex align-items-center">
                                                    <div className={` ${isCurrentDay} ${isHoliday} ${calendarDay} ${isSelectedBadge}`}>
                                                        <b> {day} </b>
                                                    </div>
                                                    {holiday ? <b className={"ml-2 overflow-hidden text-nowrap text-truncate w-100 " + (isSelected !== '' ? 'color-white' : 'text-dark-blue')} title={holiday.label}> {holiday.label} </b> : ''}
                                                </div>

                                                <div>
                                                    {campaigns && campaigns.slice(0, 2).map((campaign, campKey) =>
                                                        <div className={"pl-2 mt-2 overflow-hidden text-nowrap text-truncate w-100 " + (isSelected === '' ? ' text-host' : ' color-white') + (campaign.active === '' ? '' : ' InactiveCampaign')} key={campKey}>
                                                            {campaign.senderChannel === "Email" ? <i className="icon-icon-mail" /> : <i className="icon-icon-sms" />}
                                                            <span title={ campaign.name }> {campaign.name} </span>
                                                        </div>
                                                    )}

                                                    {campaigns && campaigns.length > 2 ?
                                                        <div className="week-day" style={{ position: 'absolute', width: '88%', bottom: '15px', fontSize: '11px', paddingLeft: '27px' }}>
                                                            {campaigns.length - 2} more
                                                        </div>
                                                    : ''}
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                                : '')}
                        </div>
                    </Col>


                    {/*Views*/}
                    <Col sm={2} className="px-4">
                        <Row className="mb-2 align-items-center mx-2">
                            <Col className="week-day col-4"> <FormattedMessage id="UniqueCampaigns.View" /> </Col>
                            <Col className="pl-0 overflow-hidden text-nowrap">
                                <div className="text-center cursor-pointer bg-host-light text-host pl-0" onClick={() => this.toggleBool('showViewOptions')} style={{ borderRadius: '20px', padding: '4px 0' }}>
                                    <b> {allMonthsView ? <FormattedMessage id="UniqueCampaigns.AllMonths" /> : <FormattedMessage id="UniqueCampaigns.ActiveMonths" />} </b>
                                    <i className="fas fa-sm fa-chevron-down ml-2" />
                                </div>
                            </Col>
                        </Row>

                        {showViewOptions ?
                            <div className="shadow p-3 position-absolute w-50" style={{ zIndex: '100', backgroundColor: '#fff', right: '0', borderRadius: '10px', marginRight: '13%' }}>
                                <h6 className="hoverTextBold cursor-pointer" onClick={() => this.setState({ allMonthsView: true, showViewOptions: false })}>
                                    <FormattedMessage id="UniqueCampaigns.AllMonths" />
                                </h6>
                                <h6 className="hoverTextBold cursor-pointer mt-3" onClick={() => this.setState({ allMonthsView: false, showViewOptions: false })}>
                                    <FormattedMessage id="UniqueCampaigns.ActiveMonths" />
                                </h6>
                            </div>
                        : ''}

                        <Row className="mb-4 mt-5 align-items-center mx-2">
                            <Col className="pr-0">
                                <h3 className="m-0" style={{ fontSize: '28px' }}> {currentDate.format("YYYY")} </h3>
                            </Col>
                            <Col className="text-right pl-0">
                                <i className="fas fa-sm fa-chevron-left cursor-pointer p-2 bg-light-grey circle-sm" onClick={() => this.changeYear(-1)} />
                                <i className="fas fa-sm fa-chevron-right cursor-pointer ml-2 p-2 bg-light-grey circle-sm" onClick={() => this.changeYear(1)} />
                            </Col>
                        </Row>

                        <div className="scrollableDiv" style={{ overflowY: 'auto', overflowX: 'hidden', height: '55vh' }}>
                            {allMonthsView ?
                                months && months.map((month, key) =>
                                    <Row className={(currentDate.format("M") == month.month ? 'bg-host-selected' : month.totalCampaigns > 0 ? 'text-host' : '') + ' py-2 my-1 cursor-pointer mx-2'} onClick={() => this.changeMonth(month.month)} key={key}>
                                        <Col>
                                            <b> <FormattedMessage id={`UniqueCampaigns.Month${month.month}`} /> </b>
                                        </Col>
                                        <Col className="col-3 d-flex align-items-center justify-content-end">
                                            {month.totalCampaigns > 0 ?
                                                <Badge color="primary" className='bg-host-selected-day circle-sm'> {month.totalCampaigns} </Badge>
                                                : ''}
                                        </Col>
                                    </Row>
                                )
                                :
                                months && months.filter(m => m.totalCampaigns > 0).map((month, key) =>
                                    <Row key={key} className="mb-2">
                                        <Col>
                                            <h5 className={"py-3 mx-4 cursor-pointer" + (currentDate.format("M") == month.month ? ' text-host' : ' week-day')} onClick={() => this.changeMonth(month.month)}>
                                                <FormattedMessage id={`UniqueCampaigns.Month${month.month}`} />
                                            </h5>

                                            {month.campaigns.map((campaign, i) => {
                                                const linkedCampaign = campaign.marketingType === "CustomLinked" ?
                                                    this.state.campaigns.find(el => el.id === campaign.customCampaign?.linkMarketingSendId) :
                                                    null;

                                                return <div className="hoverBgLightGrey cursor-pointer px-2 py-2 my-2" style={{ borderRadius: '10px' }} key={i}
                                                    onClick={(e) => this.handlePreview(e, campaign.templates, campaign.id)}>
                                                    <div className="d-flex">
                                                        <div>
                                                            <i className="fas fa-ellipsis-v week-day mr-2" />
                                                        </div>
                                                        <div>
                                                            <h6><b> {campaign.name} </b></h6>

                                                            <div className="d-flex align-items-center">
                                                                <div className="bg-dark-blue circle-sm">
                                                                    {moment(campaign.scheduleDay).format("D")}
                                                                </div>
                                                                <div className="bg-light-grey circle-sm ml-2" style={{ paddingTop: '5px' }}>
                                                                    {campaign.senderChannel === "Email" ? <i className="icon-icon-mail" /> : <i className="icon-icon-sms" />}
                                                                </div>

                                                                {campaign.marketingType === 'CustomAB' ?
                                                                    <>
                                                                        <div className="bg-light-grey circle-sm ml-2" id={`CustomAB-${key}-${i}`}>
                                                                            AB
                                                                        </div>
                                                                        <UncontrolledTooltip placement="bottom" target={`CustomAB-${key}-${i}`}>
                                                                            <FormattedMessage id="Campaigns.CustomAB" />
                                                                        </UncontrolledTooltip>
                                                                    </>
                                                                    : campaign.marketingType === "CustomLinked" ?
                                                                        <>
                                                                            <div className="bg-light-grey circle-sm ml-2" id={`CustomLinked-${key}-${i}`}>
                                                                                <i className="fas fa-link" />
                                                                            </div>
                                                                            <UncontrolledTooltip placement="bottom" target={`CustomLinked-${key}-${i}`}>
                                                                                <FormattedMessage id="Campaigns.LinkedToCampaign" /> {linkedCampaign && linkedCampaign.name}
                                                                            </UncontrolledTooltip>
                                                                        </>
                                                                    : ''}

                                                                {campaign.customCampaign && typeof campaign.customCampaign === 'object' &&
                                                                    Object.keys(campaign.customCampaign).some(k => { if (Array.isArray(campaign.customCampaign[k]) && campaign.customCampaign[k].length > 0) return true; else return false; if (campaign.customCampaign[k] !== null) return true; else return false; }) ?
                                                                    <div className="bg-light-grey circle-sm ml-2" style={{ paddingTop: '5px' }} >
                                                                        <i className="icon-icon-filter" />
                                                                    </div>
                                                                    : ''}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            })}
                                        </Col>
                                    </Row>
                                )
                            }
                        </div>

                        <div style={previewTemplates.isOpen === false ? { display: 'none' } : { backgroundColor: 'white', zIndex: '999', position: 'fixed', minHeight: '300px', top: '30vh', borderRadius: '10px', right: '16vw', maxHeight: '415px' }} className="py-3 px-4 shadow">
                            <PreviewTemplates
                                hasTemplates={previewTemplates.templates ? true : false}
                                templates={previewTemplates.templates}
                                showOptions={true}
                                onEditClick={() => this.props.history.push({ pathname: "/CustomCampaignDetails/" + previewTemplates.campaignId })}
                                onStatsClick={() => this.props.history.push({ pathname: "/CommunicationStats/" + previewTemplates.campaignId })}
                                onClose={(e) => this.handlePreview(e, null, null )}
                            />
                        </div>

                        <div className="text-center ml-4" style={{ position: 'absolute', bottom: '0' }}>
                            <Button className="mt-5 text-center bg-host-gradient px-3 py-2 mb-2" style={{ borderRadius: '15px' }} onClick={() => this.props.history.push({ pathname: "/CustomCampaignDetails" })}>
                                + <FormattedMessage id="RecurringCampaigns.CreateCampaign" />
                            </Button>
                        </div>
                    </Col>
                </Row>



                {/*Details*/}
                {showDayDetails ?
                    <div style={{ backgroundColor: 'white', zIndex: '999', position: 'relative', height: '87vh', top: '-87vh', width: '600px', float: 'right' }} className="shadow p-5">
                        <Row className="mt-3">
                            <Col>
                                <h4><b>
                                    {selectedDay && selectedDay.campaigns && moment(selectedDay.campaigns[0].scheduleDay).format("D")}  <FormattedMessage id={`UniqueCampaigns.Month${currentDate.format("M")}`} />, {currentDate.format("YYYY")}
                                </b></h4>
                            </Col>
                            <Col sm={2} className="text-right">
                                <span  >
                                    <i className="fas fa-lg fa-times cursor-pointer" onClick={() => this.toggleDayDetails(null, null)} />
                                </span>
                            </Col>
                        </Row>

                        <Button className="btn-sm text-center bg-host-gradient px-3 py-2 my-4" style={{ borderRadius: '15px' }} onClick={() => this.props.history.push({ pathname: "/CustomCampaignDetails" })}>
                            + <FormattedMessage id="RecurringCampaigns.CreateCampaign" />
                        </Button>

                        <Row className="mt-5">
                            <Col>
                                <h6 className="week-day mb-4">  <FormattedMessage id="UniqueCampaigns.ScheduledCampaigns" /></h6>
                            </Col>
                        </Row>

                        <div className="scrollableDiv h-75" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                            {selectedDay && selectedDay.campaigns.map((campaign, key) => {
                                const linkedCampaign = campaign.marketingType === "CustomLinked" ?
                                    this.state.campaigns.find(el => el.id === campaign.customCampaign?.linkMarketingSendId) :
                                    null;

                                return <div key={key}>
                                    <Row className="mb-4">
                                        <Col sm={6} style={{ whiteSpace: 'normal' }}>
                                            {campaign.name}
                                        </Col>
                                        <Col className="px-1 col-1">
                                            <div className="bg-light-grey circle-sm ml-2">
                                                {campaign.senderChannel === "Email" ? <i className="icon-icon-mail" /> : <i className="icon-icon-sms" />}
                                            </div>
                                        </Col>
                                        <Col className="px-1 col-1">
                                            {campaign.marketingType === 'CustomAB' ?
                                                <>
                                                    <div className="bg-light-grey circle-sm ml-2" id={`CustomAB-${key}`}>
                                                        AB
                                                    </div>
                                                    <UncontrolledTooltip placement="bottom" target={`CustomAB-${key}`}>
                                                        <FormattedMessage id="Campaigns.CustomAB" />
                                                    </UncontrolledTooltip>
                                                </>
                                                : campaign.marketingType === "CustomLinked" ?
                                                    <>
                                                        <div className="bg-light-grey circle-sm ml-2" id={`CustomLinked-${key}`}>
                                                            <i className="fas fa-link" />
                                                        </div>
                                                        <UncontrolledTooltip placement="bottom" target={`CustomLinked-${key}`}>
                                                            <FormattedMessage id="Campaigns.LinkedToCampaign" /> {linkedCampaign && linkedCampaign.name}
                                                        </UncontrolledTooltip>
                                                    </>
                                                : ''}
                                        </Col>
                                        <Col className="text-right col-4">
                                            <Button className="btn-sm bg-host-light text-host border-0 mr-2" onClick={(e) => this.handleDetails(e, `detailsPreview${key}`, campaign.templates)}>
                                                <i className="icon-icon-preview" />
                                            </Button>
                                            <Button className="btn-sm bg-host-light text-host border-0 mr-2" onClick={() => this.props.history.push({ pathname: "/CustomCampaignDetails/" + campaign.id })}>
                                                <i className="far fa-edit" />
                                            </Button>
                                            <Button className="btn-sm bg-host-light text-host border-0" onClick={() => this.props.history.push({ pathname: "/CommunicationStats/" + campaign.id })}>
                                                <i className="icon-icon-statistics-campaigns" />
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Collapse isOpen={this.state[`detailsPreview${key}`] && this.state[`detailsPreview${key}`].isOpen === true ? true : false} >
                                        {this.state[`detailsPreview${key}`] ?
                                            <div className="py-3 px-4">
                                                <PreviewTemplates
                                                    hasTemplates={this.state[`detailsPreview${key}`] && this.state[`detailsPreview${key}`].templates && this.state[`detailsPreview${key}`].templates.length > 0 ? true : false}
                                                    templates={this.state[`detailsPreview${key}`].templates}
                                                    showOptions={false}
                                                />
                                            </div>
                                            : ''}
                                    </Collapse>
                                </div>
                            })}
                        </div>
                    </div>
                 : ''}
            </BlockUi>
        );
    }
}
export default injectIntl(withRouter(UniqueCampaigns))