import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Card, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import EditLocationModal from './EditLocationModal';

class GoogleBusinessLocationDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            weekdays: [ 'SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY' ],
            editModal: false
        };
    }

    toggleEditModal = () => {
        this.setState({ editModal: !this.state.editModal });
    }

    render() {
        const { location, profilePhoto, getLocationDetail, selectedLocation, categoryOptions } = this.props;
        const { weekdays, editTemplateName, editModal } = this.state;
        
        return (
            location ?
                <Card className='border-0 pb-4 px-4 bg-transparent'>
                    {editModal ?
                        <EditLocationModal
                            isOpen={editModal}
                            categoryOptions={categoryOptions}
                            toggle={this.toggleEditModal}
                            location={location}
                            selectedLocation={selectedLocation}
                            getLocationDetail={getLocationDetail}
                        />
                    :''}
                    <Row>
                        <Col className='text-right'>
                            <Button onClick={this.toggleEditModal} className="btn btn-host btn-sm">
                                <i className="fas fa-edit"/>
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='d-flex align-items-center'>
                                {profilePhoto ?
                                    <div className='mr-2'>
                                        <div className='rounded-circle' style={{ width: '100px', height: '100px', backgroundImage: `url(${profilePhoto})`, backgroundSize: 'cover', backgroundPosition: 'center' }}/>
                                    </div>
                                :''}
                                <div>
                                    <h4>{location.title}</h4>
                                    <div className='text-muted'>
                                        {location.categories?.primaryCategory?.displayName}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='mt-4'>
                                <div style={{ fontSize: '1.1em' }}>
                                    <i className="fas fa-map-marker-alt mr-2"/>
                                    <FormattedMessage id="GoogleBusinessConfig.Address"/>
                                </div>
                                <div className='pr-2 text-muted'>
                                    <span className='ml-4'>{location.storefrontAddress?.addressLines?.map((a) => ` ${a}`).toString()}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col className='col-4'>
                            <div style={{ fontSize: '1.1em' }}>
                                <i className="fas fa-clock mr-2"/>
                                <FormattedMessage id="GoogleBusinessConfig.Timetable"/>
                            </div>
                            <div className='mt-2 pr-2'>
                                <div className='ml-4'>
                                    {weekdays?.map((day, key) => {
                                        const time = location.regularHours?.periods.find(({openDay}) => openDay === day);
                                        return(
                                            <div key={key} className="py-1" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '10px' }}>
                                                <div className="text-muted"><FormattedMessage id={"weekdays." + day}/>:</div>
                                                <div className='text-right'>
                                                    {time && time.openTime && time.closeTime ?
                                                        <b>
                                                            <span>{`${time.openTime.hours}:${time.openTime.minutes??'00'}`}</span>
                                                            <span>-</span>
                                                            <span>{`${time.closeTime.hours}:${time.closeTime.minutes??'00'}`}</span>
                                                        </b>
                                                    :
                                                        <FormattedMessage id={"GoogleBusinessConfig.Closed"}/>
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col>
                            <div style={{ fontSize: '1.1em' }}>
                                <i className="fas fa-globe mr-2"/>
                                <FormattedMessage id="GoogleBusinessConfig.WebSite"/>
                            </div>
                            <div className='pl-2 text-muted'>
                                {location.websiteUri}
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col>
                            <div style={{ fontSize: '1.1em' }}>
                                <i className="fas fa-phone mr-2"/>
                                <FormattedMessage id="GoogleBusinessConfig.PhoneNumber"/>
                            </div>
                            <div className='pl-2 text-muted'>
                                {location.phoneNumbers?.primaryPhone}
                            </div>
                        </Col>
                    </Row>
                </Card>
            :''
        );
    }
}

export default injectIntl(GoogleBusinessLocationDetail);