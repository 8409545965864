import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ActiveInactiveStatusBadge, StyledCard } from '../../../Base/CommonUIComponents';
import { Row, Col, Card, Form, Button } from 'reactstrap';
import { getAPI } from '../../../Base/API';
import { Pagination, ResultsPerPage, TotalResults } from '../../../Base/PaginationComponents';
import moment from 'moment';
import CustomSelect from '../../../Base/CustomSelect';

class DuplicateRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageIndex: 0,
            pageSize: 10,
            totalItems: 0,
            dupRules: [],
            hotelGroupId: null,
            hotelList: [],
            hasRules: null
        };
    }

    componentDidMount() {
        this.getHotelList();
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.getDuplicateRules());
    }

    changePageSize = (evt) => {
        const { value } = evt.target;

        if (this.state.pageSize !== value) {
            this.setState({ pageSize: value, pageIndex: 0 }, () => this.getDuplicateRules());
        }
    }

    doSearch = (event) => {
        if (event) {
            event.preventDefault();

            this.setState({ pageIndex: 0, block: true }, () => this.getDuplicateRules());
        }
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    getDuplicateRules = () => {
        const { pageSize, pageIndex, hotelGroupId, hasRules } = this.state

        let params = `?&pageSize=${pageSize}&pageIndex=${pageIndex}`;

        if (hotelGroupId !== null)
            params += `&hotelGroupId=${hotelGroupId}`;

        if (hasRules !== null)
            params += `&hasRules=${hasRules}`;

        this.setState({ block: true },
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    this.setState({ dupRules: data.response, block: false, totalItems: data.totalItems ? data.totalItems : 0, });
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/Monitor/v1/GetAllDuplicateRules` + params));
        }

    getHotelList = () => {
        this.setState({ block: true }, () =>
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data && data.response && data.response.length > 0) {
                    var hotels = [];

                    data.response.map((item) =>
                        hotels.push({ 'value': item.hotelGroupId, 'label': item.name })
                    )

                    this.setState({ hotelList: hotels, block: false }, () => this.getDuplicateRules());
                }
            }, '/api/gms/Hotel/v1/hotelgroup/list?pageSize=1000&pageIndex=0')
        );
    }

    render() {
        const { block, error, dupRules, pageSize, pageIndex, totalItems, hotelGroupId, hotelList, hasRules } = this.state;
        const statusOptions = [
            {
                value: true,
                label: <FormattedMessage id="generic.yes" />
            },
            {
                value: false,
                label: <FormattedMessage id="generic.no" />
            }
        ];

        return (
            <StyledCard block={block} error={error} title="NavMenu.DuplicateRules" icon="fas fa-list" tabIndex="0">

                <Form onSubmit={this.doSearch}>
                    <Row className="align-items-center my-3">
                        <Col sm={4}>
                            <CustomSelect
                                options={hotelList}
                                name="hotelGroupId"
                                icon="icon-icon-hotel"
                                onChange={this.handleChangeSelect.bind(this, 'hotelGroupId')}
                                value={hotelGroupId !== null ? hotelList.find(v => v.value === hotelGroupId) : null}
                                placeholder={<FormattedMessage id="generic.HotelGroup" />}
                                isClearable
                                isSearchable
                            />
                        </Col>
                        <Col sm={2}>
                            <CustomSelect
                                placeholder={<FormattedMessage id="DuplicateRules.HasRules" />}
                                options={statusOptions}
                                onChange={this.handleChangeSelect.bind(this, 'hasRules')}
                                value={hasRules !== null ? statusOptions.find(v => v.value === hasRules) : null}
                                name="hasRules"
                                isClearable
                                isSearchable
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className="btn btn-host shadow btn-sm" type="submit">
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                {dupRules ? 
                    <>
                        {dupRules.map((rules, key) => {
                            const hotelGroup = hotelList?.find(({ value }) => value === rules.hotelGroupId)?.label;
                            return (
                                <div key={key} className="mb-5">
                                    <Row className="mb-3">
                                        <Col>
                                            <h5>{hotelGroup}</h5>
                                        </Col>
                                    </Row>
                                    {rules.rules[0] ?
                                        <Row>
                                            <Col>
                                                <Card className=" shadow p-2 py-2 border-0 mb-2 text-muted" >
                                                    <Row className="px-2 align-items-center">
                                                        <Col>
                                                            <FormattedMessage id="DuplicateRules.Name" />
                                                        </Col>
                                                        <Col>
                                                            <FormattedMessage id="DuplicateRules.Description" />
                                                        </Col>

                                                        <Col sm={1}>
                                                            <FormattedMessage id="DuplicateRules.Active" />
                                                        </Col>

                                                        <Col>
                                                            <FormattedMessage id="DuplicateRules.DuplicateMatchType" />
                                                        </Col>

                                                        <Col>
                                                            <FormattedMessage id="DuplicateRules.Entity" />
                                                        </Col>

                                                        <Col>
                                                            <FormattedMessage id="DuplicateRules.Field" />
                                                        </Col>

                                                        <Col>
                                                            <FormattedMessage id="DuplicateRules.LastRun" />
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </Row>
                                        :
                                        <Row className="ml-4">
                                            <Col>
                                                <b><FormattedMessage id="DuplicateRules.HotelGroupNoRules" /></b>
                                            </Col>
                                        </Row>
                                    }
                                    {rules.rules.map((dr, key) =>
                                        <Row className="my-1" key={key}>
                                            <Col>
                                                <Card className="shadow p-2 py-3 border-0 mb-2" >
                                                    <Row className="px-2 align-items-center">
                                                        <Col>
                                                            {dr.name}
                                                        </Col>
                                                        <Col>
                                                            {dr.description}
                                                        </Col>
                                                        <Col sm={1}>
                                                            <ActiveInactiveStatusBadge status={dr.active} />
                                                        </Col>
                                                        <Col>
                                                            {dr.duplicateMatchType}
                                                        </Col>
                                                        <Col>
                                                            {dr.entity}
                                                        </Col>
                                                        <Col>
                                                            {dr.field}
                                                        </Col>
                                                        <Col>
                                                            {dr.lastRun ? moment(dr.lastRun).format("yyyy-MM-DD  HH:mm.ssss") : '-'}
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            )
                        })}
                       

                        <Row>
                            <Col>
                                <ResultsPerPage
                                    changeSizePerPage={this.changePageSize}
                                    pageSize={pageSize}
                                />

                                <span className="ml-2">
                                    <TotalResults
                                        end={(pageIndex + 1) * pageSize <= totalItems ? (pageIndex + 1) * pageSize : totalItems}
                                        start={pageIndex * pageSize + 1}
                                        total={totalItems}
                                    />
                                </span>
                            </Col>

                            <Col className="text-right mr-3">
                                <Pagination
                                    isPrevDisabled={pageIndex === 0}
                                    isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                    currentPage={pageIndex + 1}
                                    handlePrevButton={() => this.handlePagination(-1)}
                                    handleNextButton={() => this.handlePagination(1)}
                                />
                            </Col>
                        </Row>

                    </>

                    :
                    <Row>
                        <Col className="d-flex flex-column align-items-center">
                            <FormattedMessage id="DuplicateRules.NoDuplicateRules" />
                        </Col>
                    </Row>
                }
            </StyledCard>
        );
    }
}

export default injectIntl(DuplicateRules);