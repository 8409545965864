import moment from 'moment-timezone';
import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, Row, UncontrolledDropdown, Button } from 'reactstrap';
import InputGPT from '../Base/InputGPT';
import GoogleAnonymousProfile from './GoogleAnonymousProfile';

class QAModal extends Component {
    
    getDaysAgo = (createTime) => {
        const hours = moment().diff(moment(createTime), 'hour');
        const days = moment().diff(moment(createTime), 'day');
        if(days > 20) return;
        if(hours < 24) {
            return (
                <>
                    <span className='mr-1'>{hours}</span>
                    <FormattedMessage id="GoogleBusinessConfig.HoursAgo"/>
                </>
            );
        }
        return (
            <>
                <span className='mr-1'>{days}</span>
                <FormattedMessage id="GoogleBusinessConfig.DaysAgo"/>
            </>
        );
    }

    render() {
        const { hotelName, toggle, open, intl, question, questionReply, submitReply, onChange, block, deleteReply, editReply, openInput, getGPTPrompt, configType } = this.props;
        const daysAgo = this.getDaysAgo(question?.updateTime);
        
        return (
            open && question ?
                <Modal isOpen={open} toggle={toggle}>
                    <BlockUi tag="div" blocking={block}>
                        <Button onClick={toggle} className="closeModal">
                            <i className="fas fa-times fa-sm"></i>
                        </Button>
                        <Card className='border-0 p-4 shadow'>
                            <Row>
                                <Col className='text-center'>
                                    <h5>
                                        <FormattedMessage id="GoogleBusinessConfig.HotelQuestions"/>
                                        <b className='ml-1'>{hotelName}</b>
                                    </h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <GoogleAnonymousProfile
                                            small={true}
                                            profilePhotoUri={question.author?.profilePhotoUri}
                                            isAnonymous={question.author?.isAnonymous}
                                        />
                                        <div>
                                            <div style={{ maxWidth: '350px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                {question.author.displayName}
                                            </div>
                                            <div style={{ fontSize: '0.9em' }} className='text-muted'>
                                                {daysAgo}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col className='text-right pl-0'>
                                    {moment(question.updateTime).format('DD-MM-YYYY')}
                                </Col>
                            </Row>
                            <Row className='mt-2 mx-2'>
                                <Col>
                                    <b style={{ whiteSpace: 'break-spaces' }}>{question.text}</b>
                                </Col>
                                {question.upvoteCount ?
                                    <Col className='col-1 pl-0 text-right'>
                                        <i className="far fa-thumbs-up"/>
                                        <span className='ml-2'>
                                            {question.upvoteCount}
                                        </span>
                                    </Col>
                                :''}
                            </Row>
                            {question.topAnswers ?
                                <Row className='px-4 mt-2' style={{ fontSize: '1em', maxHeight: '350px', overflow: 'auto' }}>
                                    <Col>
                                        {question.topAnswers.map((answer, key) => {
                                            const answerDaysAgo = this.getDaysAgo(answer.updateTime);
                                            return(
                                                <div className='my-3' key={key}>
                                                    <Row>
                                                        <Col style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                            <GoogleAnonymousProfile
                                                                small={true}
                                                                profilePhotoUri={answer.author?.profilePhotoUri}
                                                                isAnonymous={answer.author?.isAnonymous}
                                                                isMerchant={answer.author?.type === "MERCHANT"}
                                                            />
                                                            <div>
                                                                <div style={{ maxWidth: '350px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                                    {answer.author.type === "MERCHANT" ?
                                                                        <>
                                                                            <span className='mr-1'>{hotelName}</span>
                                                                            {answer.author.displayName ? 
                                                                                <span>({answer.author.displayName})</span>
                                                                            :''}
                                                                        </>
                                                                    :
                                                                        <span>{answer.author.displayName}</span>
                                                                    }
                                                                </div>
                                                                <div style={{ fontSize: '0.9em' }} className='text-muted'>
                                                                    {answerDaysAgo}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col className='text-right pl-0'>
                                                            {moment(answer.updateTime).format('DD-MM-YYYY')}
                                                        </Col>
                                                    </Row>
                                                    <Row className='mt-2'>
                                                        <Col><b>{answer.text}</b></Col>
                                                        <Col className='col-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                                            {question.upvoteCount ?
                                                                <div className='text-right mr-2'>
                                                                    <i className="far fa-thumbs-up"/>
                                                                    <span className='ml-2'>{answer.upvoteCount}</span>
                                                                </div>
                                                            :''}
                                                            <UncontrolledDropdown direction='down'>
                                                                <DropdownToggle style={{ background: 'none', border: 'none', color: 'unset', padding: 'unset', width: '10px' }}>
                                                                    <i className="fas fa-ellipsis-v"/>
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    <DropdownItem onClick={editReply}>
                                                                        <i className="fas fa-edit mr-2"/>
                                                                        <FormattedMessage id="GoogleBusinessConfig.Edit"/>
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={deleteReply} style={{ color: "#a80c19" }}>
                                                                            <i className="mr-2 far fa-trash-alt"/>
                                                                            <FormattedMessage id="GoogleBusinessConfig.Delete"/>
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )}
                                        )}
                                    </Col>
                                </Row>
                            :''}
                            {openInput ?
                                <Row className='mt-4'>
                                    <Col>
                                        <InputGPT
                                            onChange={onChange}
                                            submitReply={submitReply}
                                            value={questionReply}
                                            name="questionReply"
                                            placeholder={intl.formatMessage({ id: `GoogleBusinessConfig.QuestionReply` })}
                                            hasGPT={true}
                                            getGPTPrompt={getGPTPrompt}
                                            configType={configType}
                                        />
                                    </Col>
                                </Row>
                            :''}
                        </Card>
                    </BlockUi>
                </Modal>
            :''
        )
    }
}

export default injectIntl(QAModal)