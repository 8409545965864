import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Input, Form, Button, Card, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { DefaultPage, StyledCard } from "../../../Base/CommonUIComponents";
import { getAPI } from '../../../Base/API';
import { handleNotification } from '../../../Base/Notification';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { Pagination, ResultsPerPage, TotalResults } from '../../../Base/PaginationComponents';

export class Invalid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            invalids: [],
            startDate: moment().subtract(6, 'days'),
            endDate: moment(),
            email: '',
            invalidsPaginated: [],
            pageIndex: 0,
            pageSize: 10,
            totalItems: 0,
        };
    }

    componentDidMount() {
        this.getInvalids();
    }

    getInvalids = (e) => {
        if (e) e.preventDefault();

        this.setState({ block: true });
        const { startDate, endDate, email } = this.state;
        let params = `?startDate=${moment(startDate).unix()}&endDate=${moment(endDate).unix()}&email=${email}`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ invalids: data.response, block: false }, () => this.changePage());
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SendGrid/v1/invalid` + params);
    }

    handleChange = (name) => {
        this.setState({
            email: name.target.value
        })
    }

    changePageSize = (evt) => {
        const { value } = evt.target;

        if (this.state.pageSize !== value) {
            this.setState({ pageSize: value, pageIndex: 0 }, () => this.changePage());
        }
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.changePage());
    }

    changePage = () => {
        const { invalids, pageIndex, pageSize } = this.state;

        const totalItems = invalids.length;
        const invalidsPaginated = invalids.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);

        this.setState({ invalidsPaginated: invalidsPaginated, totalItems: totalItems, block: false });
    }

    render() {
        const { block, error, invalids, invalidsPaginated, pageIndex, pageSize, totalItems } = this.state;
        const { page } = this.props;


        return (
            <StyledCard block={block} error={error} >
                <Row className="mb-4 py-1">
                    <Col>
                        <h5> <i className={page?.icon} /> {page?.name} </h5>
                    </Col>
                </Row>            
                <Form onSubmit={this.getInvalids}>
                    <Row className="align-items-center mb-3">
                        <Col className="col-3 py-2">
                            <Input
                                type="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                placeholder="Email"
                            />
                        </Col>
                        <Col className="py-2">
                            <DateRangePicker
                                startDate={this.state.startDate}
                                startDateId="startDate"
                                isOutsideRange={day => day > moment()}
                                endDate={this.state.endDate}
                                endDateId="endDate"
                                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className=" btn btn-host btn-sm" type="submit">
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>
                {invalidsPaginated && invalidsPaginated.length > 0 ?
                    <>
                        <Row className=" text-muted align-items-center mb-2 mt-3 px-2">
                            <Col sm={4}> <FormattedMessage id="SendGridSupression.Email" /></Col>
                            <Col sm={2}> <FormattedMessage id="SendGridSupression.Date" /> </Col>
                            <Col> <FormattedMessage id="SendGridSupression.Reason" /> </Col>
                        </Row>

                        <div className="pt-1">
                            {invalidsPaginated.map((invalid, key) =>
                                <Card className="shadow py-2 px-3 overbglight mb-2" key={key}>
                                    <Row className="my-1 align-items-center">
                                        <Col sm={4} className="d-flex align-items-center">
                                            {invalid.email}
                                        </Col>
                                        <Col sm={2} className="d-flex align-items-center">
                                            {moment(invalid.created * 1000).format("YYYY/MM/DD")}
                                        </Col>
                                        <Col id={`ToolTipReason${key}`} className="d-inline-block align-items-center text-truncate">
                                            {invalid.reason}
                                            <UncontrolledTooltip
                                                className="supressingToolTip"
                                                placement="right"
                                                target={`ToolTipReason${key}`}
                                            >
                                                {invalid.reason}
                                            </UncontrolledTooltip>
                                        </Col>
                                    </Row>
                                </Card>
                            )}
                        </div>
                        {invalids && invalids.length > 10 ?
                            <Row className="my-3">
                                <Col>
                                    <ResultsPerPage
                                        changeSizePerPage={this.changePageSize}
                                        pageSize={pageSize}
                                    />

                                    <span className="ml-2">
                                        <TotalResults
                                            end={pageIndex * pageSize + invalidsPaginated.length}
                                            start={pageIndex * pageSize + 1}
                                            total={totalItems}
                                        />
                                    </span>
                                </Col>
                                <Col className="text-right">
                                    <Pagination
                                        isPrevDisabled={pageIndex === 0}
                                        isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                        currentPage={pageIndex + 1}
                                        handlePrevButton={() => this.handlePagination(-1)}
                                        handleNextButton={() => this.handlePagination(1)}
                                    />
                                </Col>
                            </Row>
                            : ''}
                    </>
                    : <DefaultPage text="SendGridSupression.ThereAreNoInvalid" icon={page?.icon} />}
            </StyledCard>
        );
    }
}

export default injectIntl(Invalid)