import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { handleNotification } from '../../Base/Notification';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI } from '../../Base/API';
import JourneyView from './JourneyView';
import Calendar from './Calendar'; 
import moment from 'moment';
import { ErrorAlert } from '../../Base/ErrorAlert';
import BlockUi from 'react-block-ui';

class ProfileOverview extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            events: [],
            totalItems: 0,
            currentDate: moment(),
            calendarView: 'Day',
            selectedWeek: null,
            dateFilter: null,
            forwardLookup: true
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    componentDidMount() {
        this._isMounted = true;

        if (this.props.profileId) {
            this.getProfileJourney(true);
        }
    }

    getProfileJourney = () => {
        this.setState({ block: true });

        var params = '';
        params += this.state.dateFilter ? `&date=${this.state.dateFilter.format('YYYY-MM-DD')}` : '';

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0 && JSON.stringify(this.state.events) !== JSON.stringify(data.response)) {
                    
                    let daysWithEvents = [];
                    
                    data.response.forEach(event => {
                        if (moment(event.startDate).isSame(moment(data.response[0].startDate), 'month') && !daysWithEvents.includes(moment(event.startDate).format('D'))) {
                            daysWithEvents.push(moment(event.startDate).format('D'));
                        }
                    })

                    this.setState({
                        events: data.response,
                        totalItems: data.totalItems,
                        currentDate: moment(data.response[0].startDate),
                        daysWithEvents: daysWithEvents.sort((a, b) => a - b)
                    })
                }
                else {
                    handleNotification(data, <FormattedMessage id={`ProfileJourney.ThereAreNo${this.state.forwardLookup == true ? 'Next' : 'Previous'}Events`} />, <FormattedMessage id="generic.success" />);
                    this.setState({ currentDate: this.state.currentDate })
                }

                this.setState({ block: false });
            }
        }, `/api/gms/Profile/v1/profile/${this.props.profileId}/timeline?forwardLookup=${this.state.forwardLookup}` + params)
    }

    //loadMoreData = () => {
    //    this.setState({
    //        pageIndex: this.state.pageIndex + 1
    //    }, () => this.getProfileJourney())
    //}

    handleCurrentDate = (date) => {
        this.setState({
            currentDate: date
        });
    }

    changeCalendarView = (view) => {
        this.setState({ calendarView: view })
    }

    handleWeekSelection = (week) => {
        this.setState({
            selectedWeek: week,
        }/*, () => this.getProfileJourney()*/)
    }

    handlePages = (type) => {
        const day = type === 'next' && this.state.daysWithEvents ? [...this.state.daysWithEvents].find(el => parseInt(el) > this.state.currentDate.format('D'))
            : [...this.state.daysWithEvents].reverse().find(el => parseInt(el) < this.state.currentDate.format('D'));

        
        if (day !== undefined) {
            this.setState({ currentDate: moment(this.state.currentDate.format('YYYY-MM-' + day)) })
        }
        else {

            this.getMonthEvents(this.state.currentDate, (type === 'next' ? true : false));
        }
    }

    getMonthEvents = (date, forwardLookup) => {
        this.setState({
            dateFilter: date,
            forwardLookup
        }, () => this.getProfileJourney())
    }

    render() {
        const { events, block, error, currentDate, daysWithEvents, calendarView, selectedWeek } = this.state;

        return (
            <div>
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <div className="m-2 p-2">
                        <Row className="mb-3">
                            <Col>
                            </Col>
                            <Col className="text-center">
                                <h5><b> <FormattedMessage id="ProfileDetails.Journey" /></b></h5>
                            </Col>
                            {this.state.daysWithEvents ?
                                <Col className="text-right">
                                    <span className="mr-5 text-primary cursor-pointer" onClick={() => this.handlePages('previous')} >
                                        <i className="fas fa-arrow-left" /> <FormattedMessage id="ProfileJourney.PreviousEvents" />
                                    </span>
                                    <span className="text-primary cursor-pointer" onClick={() => this.handlePages('next')}>
                                        <FormattedMessage id="ProfileJourney.NextEvents" /> <i className="fas fa-arrow-right" />
                                    </span>
                                </Col>
                            : <Col/>}
                        </Row>

                        <Row>
                            <Col className="col-3 h-100 journey-calendar">
                                <Calendar
                                    currentDate={currentDate}
                                    handleCurrentDate={this.handleCurrentDate}
                                    calendarView={calendarView}
                                    changeCalendarView={this.changeCalendarView}
                                    handleWeekSelection={this.handleWeekSelection}
                                    selectedWeek={this.state.selectedWeek}
                                    daysWithEvents={daysWithEvents}
                                    getMonthEvents={this.getMonthEvents}
                                />
                            </Col>
                            <Col className="h-100">
                                <JourneyView
                                    events={events ? calendarView === 'Day' ?
                                        events.filter(el => moment(el.startDate).isSame(currentDate, 'day'))
                                        : events.filter(el => selectedWeek ? moment(el.startDate).isSameOrAfter(currentDate.format('YYYY-MM-' + selectedWeek[0]), 'day') && moment(el.startDate).isSameOrBefore(currentDate.format('YYYY-MM-' + selectedWeek[selectedWeek.length-1]), 'day') : '')
                                        : []}
                                    calendarView={this.state.calendarView}
                                    currentDate={currentDate}
                                    selectedWeek={this.state.selectedWeek}
                                />
                            </Col>
                        </Row>
                      
                        {/*<BlockUi tag="div" blocking={block}>
                            <Row>
                                {events && events.length > 0 ?
                                    <>
                                        {events.map((event, key) =>
                                            <Col className="col-6">                                                   
                                                <div key={key}>
                                                    <TimelineEvents
                                                        description={event.description}
                                                        data={event}
                                                        date={moment(event.createdAt).format('YYYY-MM-DD HH:mm')}
                                                        textSide={key % 2 === 0 ? 'right' : 'left'}
                                                        eventType={event.referenceModule}
                                                    />
                                                </div>
                                            </Col>
                                        )}
                                        
                                    </>
                                : ''}
                            </Row>

                            {events == null || events.length == 0 ?
                                <h6><FormattedMessage id="ProfileDetails.NoJourneyAvailable" /></h6>
                                : 
                                <>
                                    <Row className="mb-2">
                                        <Col className="text-center">
                                            Showing {events.length} / {this.state.totalItems}
                                        </Col>
                                    </Row>
                                    {pageIndex <= (this.state.totalItems / this.state.pageSize) ?
                                        <Row>
                                            <Col className="text-center">
                                                <Button className="btn-sm btn-host" onClick={this.loadMoreData}>
                                                    Load more data...
                                        </Button>
                                            </Col>
                                        </Row>
                                    : ''}
                                </>
                            }
                        </BlockUi>*/}
                    </div>
                </BlockUi>
            </div>
        );
    }
}
export default injectIntl(ProfileOverview)