import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Card, Button, Badge } from 'reactstrap';
import { handleNotification } from "../../../Base/Notification";
import { StyledCard } from "../../../Base/CommonUIComponents";
import { getAPI } from "../../../Base/API";
import { Link } from 'react-router-dom';

class DataRulesActions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            modal: false,
            dataTranformConfigs: [],
            regexList: []
        };
    }

    componentDidMount() {
        this.getRegEx();
        this.getDataTranform();
    }

    getDataTranform = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response) {
                    this.setState({ block: false, dataTranformConfigs: data.response });
                }
                else {
                    this.setState({ block: false });
                }
            }
        }, `/api/gms/DataQuality/v1/DataTransformConfigurations`)
    }

    getRegEx = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const regexList = data.response?.reduce((acc, cur) => {
                    acc.push({ value: cur.id, label: cur.code });

                    return acc;
                }, []);

                this.setState({ regexList });
            }
            else this.setState({ error: errorMessage });
        }, '/api/gms/DataQuality/v1/regularExpressionRule?type=Transform');
    }

    render() {
        const { block, error, dataTranformConfigs } = this.state;

        return (
            <StyledCard block={block} error={error} >
                <Row className="mb-3">
                    <Col>
                        <h5><i className="fas fa-list-ol mr-2"></i><FormattedMessage id="NavMenu.DataRulesActions" /></h5>
                    </Col>

                    <Col className="text-right pr-4 mr-3">
                        <Link to="/DataRuleConfig/">
                            <Button className="btn btn-sm btn-host">
                                <i className="fas fa-plus"></i>
                            </Button>
                        </Link>
                    </Col>
                </Row>
                {dataTranformConfigs?.map((config, key) =>
                    <Card className="border-0 shadow mb-2" key={key} body>
                        <Row className="align-items-center">
                            <Col>
                                <h5 className="m-0">{config.name}</h5>
                            </Col>
                            <Col className="text-right">
                                {config.isClean && <Badge className="px-2 py-1 bg-light-orange"><FormattedMessage id="RuleDashboard.IsClean" /></Badge>}

                                <Button className="btn-sm btn-host ml-4" onClick={() => this.props.history.push({ pathname: "/DataRuleConfig/" + config.id })}>
                                    <i className="far fa-edit" />
                                </Button>
                            </Col>
                        </Row>

                        {config.isClean != true &&
                            <>
                                <hr />
                                {config.dataTransformConfigActions?.map((action, idx) => {
                                    const regex = this.state.regexList?.find(el => el.value === action.regularExpressionRuleId);

                                    return <Row key={idx} className="overbglight py-1">
                                        <Col><b><i className="fas fa-cog mx-2" /><FormattedMessage id={`ReferenceDataFunctions.${action.action}`} /></b></Col>
                                        <Col><FormattedMessage id={`ReferenceDataFunctions.${action.entity}`} /></Col>
                                        <Col><FormattedMessage id={`ReferenceDataFunctions.${action.field}`} /></Col>
                                        <Col>
                                            {action.value ? <FormattedMessage id={`ReferenceDataFunctions.${action.value}`} /> : ''}
                                        </Col>
                                        <Col>
                                            {regex ? regex.label : action.regularExpressionRuleId}
                                        </Col>
                                        <Col sm={1}>
                                            {action.overlapAction ? <FormattedMessage id={`ReferenceDataFunctions.${action.overlapAction}`} /> : ''}
                                        </Col>
                                        <Col>
                                            {action.removeSource ? <FormattedMessage id={`ReferenceDataFunctions.RemoveSourceField`} /> :
                                                action.removeSource === false ? <FormattedMessage id={`ReferenceDataFunctions.KeepSourceField`} />
                                                    : ''}
                                        </Col>
                                    </Row>
                                })}
                            </>
                        }
                    </Card>
                )}
            </StyledCard>
        );
    }
}
export default injectIntl(DataRulesActions)
