import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, Col, Row, Button, Input } from 'reactstrap';
import { DefaultPage, StyledCard, KebabMenu } from "../../../Base/CommonUIComponents";
import { getAPI, deleteAPI } from '../../../Base/API';
import { handleNotification } from '../../../Base/Notification';
import { DateRangePicker } from 'react-dates';
import { Pagination, ResultsPerPage, } from '../../../Base/PaginationComponents';
import moment from 'moment';

export class Suppression extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            globalSuppressions: [],
            startDate: moment().subtract(7, 'day'),
            endDate: moment(),
            limit: 10,
            offset: 0,
            email: null
        };
    }

    componentDidMount() {
        this.getGlobalSuppressions();
    }

    getGlobalSuppressions = () => {

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            var { offset } = this.state;
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    this.setState({ globalSuppressions: data.response, block: false, disableRight: false });
                }
                else {
                    if (offset > 0) {
                        offset -= 1;
                        this.setState({ block: false, disableRight: true, offset });
                    } else {
                        this.setState({ globalSuppressions: data.response, block: false });
                    }
                }               
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/SendGrid/v1/suppressions?${this.state.startDate ? `startDate=${this.state.startDate.format('YYYY/MM/DD')}` : ''}${this.state.endDate ? `&endDate=${this.state.endDate.format('YYYY/MM/DD')}` : ''}${this.state.limit ? `&limit=${this.state.limit}` : ''}${this.state.offset !== null && this.state.offset !== undefined ? `&offset=${this.state.offset}` : ''}`);
    }

    changePageSize = (evt) => {
        const { value } = evt.target;

        if (this.state.limit !== value) {
            this.setState({ limit: value, offset: 0 }, () => this.getGlobalSuppressions());
        }
    }

    getUnsubscribeEmail = (e) => {
        if (e) e.preventDefault();

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response ) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if(Object.keys(data.response).length !== 0){
                    this.setState({ globalSuppressions: [data.response], block: false });
                }else{
                    this.setState({ globalSuppressions: [], block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/SendGrid/v1/suppressions/${this.state.email}`);
    }

    deleteUnsubscribeGroup = (mail, key) => {
        const { globalSuppressions } = this.state;
        this.setState({ block: true }, () =>
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    
                    globalSuppressions.splice(key, 1);
                    
                    this.setState({ globalSuppressions }, () => this.getGlobalSuppressions());
                    handleNotification(data, <FormattedMessage id="SuppressionList.ItemRemoved" />, <FormattedMessage id="generic.success" />);
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/SendGrid/v1/suppressions/${mail}`)
        );
        
    }

    handleChangeSearch = (name, e) => {
        if(e.target.value === '') {
            this.setState({ [name]: null });
        }else{
            this.setState({
                [name]: e.target.value
            });
        }
    };

    handleSave = (e) => {
        e.preventDefault();
        this.setState({ block: true },
            () => {
                if(this.state.email){
                    this.getUnsubscribeEmail();
                }else{
                    this.getGlobalSuppressions();
                }
            }
        );
    };

    handlePagination = (value) => {
        this.setState({
            block: true,
            offset: this.state.offset + value
        }, () => this.getGlobalSuppressions());
    };

    render() {
        const { block, error, globalSuppressions, startDate, endDate, offset, limit, disableRight } = this.state;
        const { page } = this.props;
        
        return (
            <StyledCard block={block} error={error} >
                <Row className="mb-4 py-1">
                    <Col>
                        <h5> <i className={page?.icon} /> <FormattedMessage id="SendGridSupression.Suppressions" /> </h5>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-between align-items-center mb-4'>
                    <Col className='col-3'>
                        <Input
                            type="email"
                            value={this.state.email}
                            onChange={this.handleChangeSearch.bind(this, 'email')}
                            placeholder={this.props.intl.formatMessage({ id: "SendGridSupression.Email" })}
                        />
                    </Col>
                    <Col>
                        <DateRangePicker
                            startDate={startDate}
                            startDateId="startDate"
                            isOutsideRange={_ => false}
                            endDate={endDate}
                            endDateId="endDate"
                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate: startDate ?  moment(startDate) : null, endDate: endDate ?  moment(endDate) : null })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                            showClearDates={true}
                            onClearDates={() => this.setState({ startDate: null, endDate: null })}
                        />
                    </Col>
                    <Col className='text-right'>
                        <Button 
                            className="btn btn-sm btn-host" 
                            onClick={(e) => this.handleSave(e)}
                        >
                            <i className="fas fa-search"></i>
                        </Button>
                    </Col>
                </Row>
                {globalSuppressions && globalSuppressions.length > 0 ?
                    <>
                        <Row className=" text-muted align-items-center mb-2 mt-3 px-2">
                            <Col sm={5}> <FormattedMessage id="SendGridSupression.Email" /></Col>
                            <Col> <FormattedMessage id="SendGridSupression.Date" /> </Col>
                        </Row>

                        <div className="pt-1">
                            {globalSuppressions.map((unsubscribeGroup, key) =>
                                <Card className="shadow py-2 px-3 overbglight mb-2" key={key}>
                                    <Row className="my-1 align-items-center">
                                        <Col sm={5} className="d-flex align-items-center">
                                            {unsubscribeGroup.email ?? unsubscribeGroup.recipient_email}
                                        </Col>
                                        <Col sm={5} id="ToolTipReason" className="d-inline-block align-items-center text-truncate">
                                            {unsubscribeGroup.created ? moment.unix(unsubscribeGroup.created).format('DD/MM/YYYY - HH:mm') : '-'}
                                        </Col>
                                        <Col className="d-inline-block align-items-end text-right">
                                            <KebabMenu
                                                deleteFunction={() => this.deleteUnsubscribeGroup(unsubscribeGroup.email ?? unsubscribeGroup.recipient_email, key)}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            )}
                        </div>
                        <Row className="mt-2">
                            <Col>
                                <ResultsPerPage
                                    changeSizePerPage={this.changePageSize}
                                    pageSize={limit}
                                />
                            </Col>

                            <Col className="text-right mr-3">
                                <Pagination
                                    isPrevDisabled={offset === 0}
                                    isNextDisabled={(globalSuppressions.length * (offset + 1) < ((offset + 1) * limit)) || disableRight}
                                    currentPage={offset + 1}
                                    handlePrevButton={() => this.handlePagination(-1)}
                                    handleNextButton={() => this.handlePagination(1)}
                                />
                            </Col>
                        </Row>
                    </>
                :
                    !block &&
                        <DefaultPage text="SendGridSupression.ThereAreNoUnsubscribe" icon={page?.icon} />
                }
            </StyledCard>
        );
    }
}

export default injectIntl(Suppression)