import React, { Component } from 'react'
import { Button, Col, Input, Modal, ModalBody, Row, Form, Badge, Collapse } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BlankCard, PhoneFrame } from "../../Base/CommonUIComponents";
import { TemplateSMSValidationsHelper } from '../../Base/TemplateByCountry';
import CustomSelect from '../../Base/CustomSelect';
import { getLanguagesCodes } from '../../Base/ReferenceDataFunctions';

class TwilioCreateTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            FriendlyName: null,
            Language: null,
            Types: 'twilio/text',
            Body: null,
            collapse: false
        };
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value })
    }

    handleLang = (e) => {

        this.setState({ Language: e.value });
    }


    toggleCollapse = () => {
        this.setState(prevState => ({ collapse: !prevState.collapse }));
    }

    render() {
        const { block, error, modal } = this.props;
        const { Language, FriendlyName, Types, Body, collapse } = this.state;

        const LanguageOptions = getLanguagesCodes().map((code) => {
            return {
                value: code,
                label: code
            };
        });

        const mergeTags =
        {
            Profile: [
                { value: 'profile.FirstName', label: <FormattedMessage id="Campaigns.FirstName" /> },
                { value: 'profile.LastName', label: <FormattedMessage id="Campaigns.LastName" /> },
                { value: 'profile.MiddleName', label: <FormattedMessage id="Campaigns.MiddleName" /> },
                { value: 'profile.FullName', label: <FormattedMessage id="Campaigns.FullName" /> },
            ],
            Reservation: [
                { value: 'reservation.OnlineCheckinPin', label: <FormattedMessage id="Campaigns.OnlineCheckinPin" /> },
                { value: 'reservation.OnlineCheckinLink', label: <FormattedMessage id="Campaigns.OnlineCheckinLink" /> },
                { value: 'reservation.doubleOptIn', label: <FormattedMessage id="Campaigns.DoubleOptIn" /> },
                { value: 'reservation.KeypassLink', label: <FormattedMessage id="Campaigns.KeypassLink" /> },
                { value: 'reservation.CheckIn', label: <FormattedMessage id="Campaigns.CheckIn" /> },
                { value: 'reservation.CheckOut', label: <FormattedMessage id="Campaigns.CheckOut" /> },
                { value: 'reservation.ResIdValue', label: <FormattedMessage id="Campaigns.ResIdValue" /> },
                { value: 'reservation.Adults', label: <FormattedMessage id="Campaigns.Adults" /> },
                { value: 'reservation.Children', label: <FormattedMessage id="Campaigns.Children" /> },
                { value: 'reservation.Amount', label: <FormattedMessage id="Campaigns.Amount" /> },
                { value: 'reservation.Room', label: <FormattedMessage id="Campaigns.Room" /> },
                { value: 'reservation.NumberOfNights', label: <FormattedMessage id="Campaigns.NumberOfNights" /> },
                { value: 'reservation.Package', label: <FormattedMessage id="Campaigns.Package" /> },
                { value: 'reservation.DoorPin', label: <FormattedMessage id="Campaigns.DoorPin" /> },
                { value: 'reservation.HotelName', label: <FormattedMessage id="Campaigns.HotelName" /> }
            ],
            Form: [
                { value: 'reviewprourl', label: <FormattedMessage id="Campaigns.ReviewProEncryptedUrl" /> },
                { value: 'formUrl', label: <FormattedMessage id="Campaigns.FormEncryptedUrl" /> },
            ],
            Loyalty: [
                { value: 'loyalty.CardNumber', label: <FormattedMessage id="Campaigns.CardNumber" /> },
                { value: 'loyalty.CardPoints', label: <FormattedMessage id="Campaigns.CardPoints" /> },
                { value: 'loyalty.CardLevel', label: <FormattedMessage id="Campaigns.CardLevel" /> },
            ],
            Voucher: [
                { value: 'voucher.VoucherId', label: <FormattedMessage id="Campaigns.VoucherId" /> },
                { value: 'voucher.ValidFrom', label: <FormattedMessage id="Campaigns.ValidFrom" /> },
                { value: 'voucher.ValidUntil', label: <FormattedMessage id="Campaigns.ValidUntil" /> },
                { value: 'voucher.VoucherValue', label: <FormattedMessage id="Campaigns.VoucherValue" /> },
            ],
            Extra: [
                { value: 'REMOVE_LINK', label: <FormattedMessage id="Campaigns.Unsubscribe" /> },
                { value: 'doubleOptIn', label: <FormattedMessage id="Campaigns.DoubleOptIn" /> },
                { value: 'googleWallet', label: <FormattedMessage id="Campaigns.GoogleWallet" /> },
                { value: 'appleWallet', label: <FormattedMessage id="Campaigns.AppleWallet" /> },
            ]
        };

        return (
            <Modal isOpen={modal} fade={false} className="modal-xxl" style={{ minWidth: 0 }} >
                <Button onClick={_ => this.props.toggleModal(null)} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <Form
                        onSubmit={(e) => this.props.createTemplate(e, FriendlyName, Language, Types, Body)}
                    >
                        <BlankCard block={block} error={error}>
                            <Row>
                                <Col>
                                    <h5>
                                        <i className="fas fa-envelope-open mr-2"></i>
                                        <FormattedMessage id="TwilioMessaging.CreateTemplate" />
                                    </h5>
                                </Col>
                                <Col className="text-right col-2">
                                    <TemplateSMSValidationsHelper />
                                    <Button className="btn btn-sm btn-host"
                                        type='submit'
                                    >
                                        <i className="fas fa-save"></i>
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Row>
                                        <Col className='col-8'>
                                            <FormattedMessage id="TwilioMessaging.UserName" />
                                            <Input
                                                onChange={(e) => this.handleChange(e)}
                                                value={FriendlyName ?? ''}
                                                name="FriendlyName"
                                                required
                                            />
                                        </Col>
                                        <Col className='col-2'>
                                            <FormattedMessage id="TwilioMessaging.Language" />
                                            <CustomSelect
                                                options={LanguageOptions}
                                                required
                                                isSearchable
                                                onChange={(e) => this.handleLang(e)}
                                                placeholder={""}
                                                value={Language ? LanguageOptions?.find(c => c.value === Language) : null}
                                                enableRequired
                                            />
                                        </Col>
                                        <Col className='col-2'>
                                            <FormattedMessage id="TwilioMessaging.Types" />
                                            <Input
                                                onChange={(e) => this.handleChange(e)}
                                                value={Types ?? ''}
                                                name="Types"
                                                required
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-7 pr-0">
                                            <Row onClick={this.toggleCollapse} className="pointer mt-3">
                                                <Col>
                                                    <FormattedMessage id="TwilioMessaging.MergeTags" />
                                                    <span className="ml-2">
                                                        <i className={`fas fa-chevron-${collapse ? `up` : `down`}`} style={{ fontSize: '.9em' }} />
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Collapse isOpen={collapse}>
                                                    <Col className='col-12 mt-2'>
                                                        {Object.keys(mergeTags).map(mt => {
                                                            return (
                                                                <>
                                                                    <Row>
                                                                        <Col>
                                                                            <div>{mt}</div>
                                                                            {mergeTags[mt].map(x => {
                                                                                var startDrag = (val, e) => {
                                                                                    e.dataTransfer.dropEffect = 'move';
                                                                                    e.dataTransfer.setData("text", '{{' + val + '}}');
                                                                                }
                                                                                return (
                                                                                    <span className="mr-2 mb-2" draggable="true" onDragStart={startDrag.bind(this, x.value)} style={{ cursor: "move" }}>
                                                                                        <Badge className="p-2 mb-2"> {x.label} </Badge>
                                                                                    </span>
                                                                                )
                                                                            })
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )
                                                        })}
                                                    </Col>
                                                </Collapse>
                                            </Row>
                                            <Row>
                                                {Types ?
                                                    <Col className='col-12 mt-3'>
                                                        <FormattedMessage id="TwilioMessaging.Message" />
                                                        <Input
                                                            type="textarea"
                                                            onChange={(e) => this.handleChange(e)}
                                                            value={Body ?? ''}
                                                            name="Body"
                                                            rows="8"
                                                            required
                                                        />
                                                    </Col>
                                                    : ''}
                                            </Row>
                                        </Col>
                                        <Col className="pl-0 mt-3">
                                            <PhoneFrame>
                                                <div className='p-4 veryCoolScrollBar' style={{ display: 'flex', flexDirection: 'column', maxHeight: '90%', overflow: 'auto' }}>
                                                    <div className='iMessage' style={{
                                                        borderRadius: '20px', padding: '8px 15px', marginTop: '5px', marginBottom: '5px', marginRight: '20%',
                                                        backgroundColor: '#eee', position: 'relative'
                                                    }} >
                                                        {Body}
                                                    </div>
                                                </div>
                                            </PhoneFrame>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </BlankCard>
                    </Form>
                </ModalBody>
            </Modal>
        )
    }
}

export default injectIntl(TwilioCreateTemplate)
