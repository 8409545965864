import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { RebateVoucherCard, CashVoucherCard } from './VoucherCardDetail';
import EmitVoucherModal from './EmitVoucherModal';

export class VoucherCardList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newVoucherModal: false
        }
    }

    openEmitVoucher = (el) => {
        let voucherType = {
            value: el.id,
            label: el.code,
            saleValidTo: el.saleValidTo,
            cardAmount: el.cardAmount,
            payedAmount: el.payedAmount
        }

        this.setState({ newVoucherModal: true, selectedVoucherType: voucherType });
    }

    toggleModal = (modal) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }));
    }

    
    render() {
        const { newVoucherModal } = this.state;
        const { cashConfigs, rebateConfigs } = this.props;

        return (
            <div>
                <Row>
                    <Col>
                        <h5 className="ml-1 mt-2">
                            <FormattedMessage id="AvailableCards.TypeCash" />
                        </h5>
                    </Col>
                </Row>
                <Row className="mb-2">
                    { cashConfigs.map((el, k) =>
                        <Col key={k} className="col-3 py-2">
                            <CashVoucherCard
                                el={el}
                                icon="fas fa-wallet"
                                emitVoucher={() => this.openEmitVoucher(el)}
                            />
                        </Col>
                    )}
                </Row>

                <h5 className="ml-1 mt-4">
                    <FormattedMessage id="AvailableCards.TypeRebates" />
                </h5>
                <Row className="mb-2">
                    { rebateConfigs.map((el, k) =>
                        <Col key={k} className="col-3 py-2">
                            <RebateVoucherCard
                                el={el}
                                icon="fas fa-gift"
                                emitVoucher={() => this.openEmitVoucher(el)}
                            />
                        </Col>
                    )}
                </Row>

                {newVoucherModal ?
                    <EmitVoucherModal
                        modal={newVoucherModal}
                        toggleModal={() => this.toggleModal("newVoucherModal")}
                        updateVouchers={this.props.doSearch}
                        renderClientInfo={false}
                        selectedVoucherType={this.state.selectedVoucherType}
                    />
                : ''}
            </div>
        );
    }
}