import React, { Component } from 'react'
import { Button, Col, Form, Input, Modal, ModalBody, Row } from 'reactstrap'
import { BlankCard, PhoneFrame } from '../Base/CommonUIComponents';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../Base/CustomSelect';
import GenericTextArea from '../Base/GenericTextArea';
import { postAPI, putAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { TemplateSMSValidationsHelper } from '../Base/TemplateByCountry';

class SMSModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: null,
            error: null,
            encodeOptions: [
                { value: 'gsm0338', label: 'gsm0338', maxChar: 160 },
                { value: 'unicode', label: 'unicode', maxChar: 70 }
            ],
            sms: {},
            textComponents: []
        };
    }

    componentDidMount(){
        const { selectedSMSId, smsList } = this.props;
        let sms = smsList?.find(x => x.id === selectedSMSId);

        if(sms){
            if(sms.gsm0338){
                sms.encoding = 'gsm0338';
            }

            this.setState({ sms }, () => this.drawTextComponents());
        }
    }

    drawTextComponents = () => {
        const { sms, encodeOptions } = this.state;

        const maxCount = parseInt(sms.maxCount) || 1;
        const maxChar = encodeOptions.find(({value}) =>  value === (sms.encoding??"unicode"))?.maxChar * maxCount;
        let textComponents = [];

        if(sms.text){
            if(sms.text.length <= maxChar){
                textComponents.push(sms.text);
            }
            else{
                let newString = sms.text;
                let k = 0;
                while(k < 1 && newString.length > 0){
                    k++;
                    let breakPointList = newString.substring((maxChar - 30), (maxChar - 2)).split('. ');
                    let result;
                    if(breakPointList && breakPointList.length > 1){
                        let breakPoint = newString.indexOf(breakPointList.join('. ')) + breakPointList[0].length + 2;

                        if(breakPoint < maxChar - 30){
                            breakPoint = maxChar - 30;
                        }
                        
                        result = newString.substring(0, breakPoint);
                    }
                    else{
                        breakPointList = newString.substring((maxChar - 30), (maxChar - 2)).split(' ');

                        if(breakPointList && breakPointList.length > 1){
                            let breakPoint = newString.indexOf(breakPointList.join(' ')) + breakPointList[0].length + 1;
                            result = newString.substring(0, breakPoint);
                            
                            if(breakPoint < maxChar - 30){
                                breakPoint = maxChar - 30;
                            }
                        }
                        else{
                            result = newString.substring(0, maxChar);
                        }
                    }
                    
                    textComponents.push(result);
                    newString = newString.substring(result.length);
                }
            }
        }
        this.setState({ textComponents });
    }

    onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }

    saveSMS = (e) => {
        const { selectedSMSId, updateSMSList } = this.props;
        const { sms } = this.state;

        e.preventDefault();
        this.setState({ block: true }, () => {
            if(selectedSMSId){
                putAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];
        
                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if ((data.Errors && data.Errors.length > 0) || (data.errors && data.errors.length > 0)) {
                            handleNotification(data);
                        }
                        else {
                            handleNotification(data, <FormattedMessage id={`TransactionalTemplateList.SavedSMS`} />, <FormattedMessage id="generic.success" />);
                        }
                    }
                    this.setState({ error: errorMessage, block: false });
                }, `/api/gms/Marketing/v1/egoitransactional/template/${selectedSMSId}/sms`, sms);
            }
            else{
                postAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];
        
                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if ((data.Errors && data.Errors.length > 0) || (data.errors && data.errors.length > 0)) {
                            handleNotification(data);
                        }
                        else {
                            if(data.templateId)
                                sms.id = data.templateId;
                            updateSMSList(sms);
                            handleNotification(data, <FormattedMessage id={`TransactionalTemplateList.CreatedSMS`} />, <FormattedMessage id="generic.success" />);
                        }
                    }
                    this.setState({ error: errorMessage, block: false });
                }, `/api/gms/Marketing/v1/egoitransactional/template/sms`, sms);
            }
        });
    }

    handleSelect = (combo, name) => {
        this.setState({
            sms: {
                ...this.state.sms,
                [name]: combo?.value
            }
        }, () => this.drawTextComponents());
    }

    handleChange = (e) => {
        const { sms } = this.state;
        sms[e.target.name] = e.target.value;
        this.setState({ sms }, () => this.drawTextComponents());
    }

    render() {
        const { toggle, intl, selectedSMSId } = this.props;
        const { block, error, encodeOptions, sms, textComponents } = this.state;

        return (
            sms ?
                <Modal toggle={_ => toggle()} isOpen={true} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                    <Button onClick={_ => toggle()} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    <ModalBody>
                        <BlankCard block={block} error={error}>
                            <Form onSubmit={this.saveSMS}>
                                <Row>
                                    <Col className="col-9 pl-1">
                                        <h5>
                                            {selectedSMSId ?
                                                <FormattedMessage id="TransactionalTemplateList.EditSMS"/>
                                            :
                                                <FormattedMessage id="TransactionalTemplateList.NewSMS"/>
                                            }
                                        </h5>
                                    </Col>
                                    <Col className="text-right col-3">
                                        <Button className="btn btn-host btn-sm" type="submit" title={intl.formatMessage({ id: 'TransactionalTemplateList.Save' })}>
                                            <i className="fas fa-save" />
                                        </Button>
                                        <TemplateSMSValidationsHelper/>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <div className='title-sm mb-2'>
                                            <FormattedMessage id="TransactionalTemplateList.Name"/>
                                        </div>
                                        <div>
                                            <Input
                                                type='text'
                                                value={sms.templateName}
                                                name="templateName"
                                                required={true}
                                                onChange={e => this.handleChange(e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <div className='title-sm mb-2'>
                                            <FormattedMessage id="TransactionalTemplateList.Enconding"/>
                                        </div>
                                        <CustomSelect
                                            isSearchable={false}
                                            placeholder={""}
                                            options={encodeOptions}
                                            required={true}
                                            onChange={(e) => this.handleSelect(e, 'encoding')}
                                            value={encodeOptions.find(el => el.value === (sms.encoding??"unicode"))}
                                        />
                                    </Col>
                                    <Col className='col-4'>
                                        <div className='title-sm mb-2'>
                                            <FormattedMessage id="TransactionalTemplateList.MaxCount"/>
                                        </div>
                                        <div>
                                            <Input
                                                className="border-bottom without-arrows"
                                                type="number"
                                                name="maxCount"
                                                value={sms.maxCount || '1'}
                                                onChange={(e) => this.handleChange(e)}
                                                max={1000}
                                                min={1}
                                                required={true}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col>
                                        <div className='title-sm mb-2'>
                                            <FormattedMessage id="TransactionalTemplateList.Message"/>
                                        </div>
                                        <div>
                                            <GenericTextArea
                                                backgroundColor={"white"}
                                                value={sms.text}
                                                placeholder={""}
                                                onChange={(e) => this.handleChange(e)}
                                                name="text"
                                                className="py-1"
                                                required={true}
                                                readOnly={false}
                                                style={{
                                                    width: '100%',
                                                    background: 'white',
                                                    boxShadow: 'unset',
                                                    outline: '0',
                                                    maxHeight : '600px',
                                                    minHeight: '600px',
                                                    height: '600px',
                                                    padding: '5px 0',
                                                    resize: 'none',
                                                    color: '#07294C',
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col className='col-4'>
                                        <div className='title-sm mb-2'>
                                            <FormattedMessage id="TransactionalTemplateList.Preview"/>
                                        </div>
                                        <PhoneFrame>
                                            <div className='p-4 veryCoolScrollBar' style={{ display: 'flex', flexDirection: 'column', maxHeight: '90%', overflow: 'auto' }}>
                                                {textComponents.map((text, key) => 
                                                    <div className='iMessage' key={key} style={{ borderRadius: '20px', padding: '8px 15px', marginTop: '5px', marginBottom: '5px', marginRight: '20%',
                                                    backgroundColor: '#eee', position: 'relative' }}>
                                                        {text}
                                                    </div>
                                                )}
                                            </div>
                                        </PhoneFrame>
                                        {/* <div style={{ height: '630px', margin: '0 auto', width: '340px' }}>
                                            <div className='h-100 position-relative' style={{ border: '5px solid #111', borderRadius: '40px' }}>
                                                <div style={{ position: 'absolute', top: '-1px', width: '100%', height: '20px' }}>
                                                    <div style={{ width: '120px', height: '25px', margin: '0px auto', backgroundColor: '#111111', borderRadius: '0px 0px 20px 20px' }}></div>
                                                </div>
                                                <div style={{ display: 'block', borderRadius: '25px', height: '100%' }}>
                                                    <div className='d-flex align-items-center justify-content-between' style={{ padding: '0.75rem 1.8rem 0' }}>
                                                        <div>
                                                            <b>9:41</b>
                                                        </div>
                                                        <div>
                                                            <div className='d-flex align-items-center justify-content-center'>
                                                                <div style={{ border:'1px solid #313131', padding: '1px' }}>
                                                                    <div style={{ background: '#313131', height: '5px', width: '12px' }}></div>
                                                                </div>
                                                                <div style={{ height: '2px', width: '2px', background: '#313131' }}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='p-4' style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                                        <div className='iMessage' style={{ borderRadius: '20px', padding: '8px 15px', marginTop: '5px', marginBottom: '5px', marginRight: '25%',
                                                        backgroundColor: '#eee', position: 'relative' }}>
                                                            Message Text
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ position: 'absolute', bottom: '10px', width: '100%' }}>
                                                    <div style={{ width: '120px', height: '3px', margin: '0px auto', backgroundColor: '#313131', borderRadius: '100px' }}></div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </Col>
                                </Row>
                            </Form>
                        </BlankCard>
                    </ModalBody>
                </Modal>
            :''
        )
    }
}

export default injectIntl(SMSModal);