import React from 'react';

export function CheckIcon({ checkIcon, keyIcon }) {
    return <div className="col-1 d-flex align-items-center justify-content-end pr-3">
        {keyIcon && keyIcon} {checkIcon && checkIcon}
    </div>
}


//#region Highlight fields (check and key icons)

export const highlightFields = (profiles, master, mainFields) => {
    profiles.forEach((profile, index) => {

        // Add check icon in fields that were used for creating the master - main fields
        const data = highlightMergedFields(profile, master, mainFields);
        profile = data.profile;
        mainFields = data.mainFields;

        // Add icon to the fields that are key to match profiles
        profile = markKeyFields(profile, index, profiles);
    })


    // Add check icon in fields that were used for creating the master - array fields
    profiles = highlightArrays(profiles, master)

    return profiles;
}

const highlightArrays = (profiles, master) => {
    const arrayFields = [
        { arrayName: 'addresses', fields: ['addressLine1', 'addressLine2', 'addressLine3', 'addressLine4', 'country', 'locality', 'postalCode', 'region'] },
        { arrayName: 'consents', fields: ['consentBegin', 'consentEnd', 'method', 'type'] },
        { arrayName: 'contacts', fields: ['type', 'contact'] },
        { arrayName: 'documents', fields: ['issueDate', 'number', 'type'] },
        { arrayName: 'socialMedia', fields: ['notes', 'type', 'url'] },
        { arrayName: 'membershipCard', fields: ['cardNumber', 'cardTypeCode', 'cardTypeDescription', 'membershipSourceCode', 'validFrom', 'validTo'] }
    ];

    arrayFields.forEach(array => {
        master[array.arrayName] && master[array.arrayName].forEach(obj => {
            let araryIdx = -1
            const profileIndex = profiles.findIndex(p => {
                if (p[array.arrayName]) {
                    const idx = p[array.arrayName].findIndex(item => {
                        if (array.fields.every(el => obj[el] === item[el])) { return true } else return false
                    });

                    if (idx !== -1) {
                        araryIdx = idx;
                        return true;
                    }
                }
            });

            if (profileIndex !== -1 && araryIdx !== -1) {
                profiles[profileIndex][array.arrayName][araryIdx].checkIcon = <i className="fas fa-check-circle color-green ml-2" />
            }
        });
    });

    return profiles;
}

// Add check icon in fields that were used for creating the master of 1st duplicated profile
const highlightMergedFields = (profile, master, mainFields) => {
    const checkIcon = <i className="fas fa-check-circle color-green ml-2" />; //Indica se o campo foi usado para a criação do master

    mainFields && mainFields.forEach(key => {
        if (master[key] && profile[key] === master[key]) {
            profile[`${[key]}CheckIcon`] = checkIcon;

            mainFields = mainFields.filter(el => el !== key);
        }
    })

    return { profile, mainFields };
}


// Mark the fields that are key to match profiles when the same
const markKeyFields = (profile, profileIndex, allProfiles) => {
    const keyIcon = <i className="fas fa-key color-yellow" />; //Indica os campos chave usados para a existência de duplicados (número de documento, contacto, nome, pais, data nascimento)
    const keyFields = ['fullName', 'nationality', 'birthDate'];


    allProfiles.forEach((prof, idx) => {
        if (idx !== profileIndex) {
            keyFields.forEach(field => {
                if (profile[field] && prof[field] === profile[field]) {
                    profile[`${[field]}KeyIcon`] = keyIcon;
                }
            })

            if (prof.contacts && profile.contacts) {
                profile.contacts.forEach(contact => {
                    if (prof.contacts.some(p => p.type === contact.type && p.contact === contact.contact)) {
                        contact.keyIcon = keyIcon;
                    }
                })
            }

            if (prof.documents && profile.documents) {
                profile.documents.forEach(document => {
                    if (prof.documents.some(p => p.type === document.type && p.number === document.number)) {
                        document.keyIcon = keyIcon;
                    }
                })
            }
        }
    })

    return profile;
}

//#endregion



export const updateMasterList = (checked, obj, master, masterUniqueValues, listName, typePropName, masterTypeIdx) => {
    if (checked) {
        if (listName !== 'membershipCard') {
            if (masterTypeIdx >= 0) {
                master[listName][masterTypeIdx] = obj;
            }
            else {
                if (!master[listName]) {
                    master[listName] = [obj];
                }
                else {
                    master[listName].push(obj);
                }
            }
        }
        else {
            master[listName] = [obj];
        }
    }
    else if (typePropName) {
        const masterType = masterUniqueValues[listName].find(ma => ma[typePropName] === obj[typePropName]);
        if (masterType) {
            master[listName][masterTypeIdx] = masterType;
        }
        else {
            if (masterTypeIdx > -1) master[listName].splice(masterTypeIdx, 1);
        }
    }
    else {
        const masterType = masterUniqueValues[listName][0];

        if (masterType) {
            master[listName][masterTypeIdx] = masterType;
        }
        else {
            if (masterTypeIdx > -1) master[listName].splice(masterTypeIdx, 1);
        }
    }

    return master;
}