import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Modal, ModalBody, Input } from 'reactstrap';
import { handleNotification } from '../../Base/Notification';
import { BlankCard } from '../../Base/CommonUIComponents';
import { CustomTable } from '../../Base/CustomTable';
import CustomSelect from "../../Base/CustomSelect";
import { putAPI, getAPI } from '../../Base/API';

class BulkAction extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            dataTransformActions: [],
            configId: null
        };
    }

    componentDidMount() {
        this.getActions();
    }

    getActions = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                let list = [];

                data.response && data.response.forEach(el => {
                    list.push({ value: el.id, label: el.name });
                })

                this.setState({ dataTransformActions: list, block: false })
            }
            else this.setState({ block: false })
        }, `/api/gms/DataQuality/v1/DataTransformConfigurations`)
    }

    bulkAction = (e) => {
        e.preventDefault();
        this.setState({ block: true });
        const { selectedRows } = this.props;
        
        let params = `?ruleId=${selectedRows[0].ruleId}`;
        params += `&configId=${this.state.configId}`;
        selectedRows.forEach(el => { params += `&detailsId=${el.runDetailId}` });

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(
                        data,
                        <FormattedMessage id="BulkAction.DataTransformation" values={{ solved: data.response[0].solved, total: data.response[0].total }} />,
                        <FormattedMessage id="generic.success" />
                    );

                    this.props.getDataQualityIssues();
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gmsscheduletask/DataQuality/DataTransformation` + params);
    }

    handleSelect = (combo, name) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    }

    render() {
        const { modal, toggleModal, selectedRows, columns } = this.props;
        const { dataTransformActions, configId } = this.state;

        return (
            <Modal isOpen={modal} fade={false} size={"xl"} style={{ minWidth: 0, maxWidth: '90%', width: '90%' }} >
                <Button onClick={toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={this.state.block} error={this.state.error}>
                        <Form onSubmit={this.bulkAction}>
                            <Row className="mb-3">
                                <Col>
                                    <h5> Bulk Action </h5>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn btn-sm btn-host" type="submit">
                                        <i className="fas fa-save"/>
                                    </Button>
                                </Col>
                            </Row>

                            <FormGroup row>
                                <Label sm={3}> <FormattedMessage id="DataRuleConfig.Action" />: </Label>
                                <Col sm={9} >
                                    <CustomSelect
                                        options={dataTransformActions}
                                        required
                                        isClearable
                                        isSearchable
                                        placeholder=''
                                        value={dataTransformActions.find(el => el.value === configId) || ''}
                                        onChange={(combo) => this.handleSelect(combo, 'configId')}
                                    />
                                </Col>
                            </FormGroup>


                            <Label> <FormattedMessage id="BulkAction.SelectedLines" />: </Label>
                            <Row>
                                <Col className="h-100">
                                    <CustomTable
                                        data={selectedRows}
                                        columns={columns}
                                        shadow={false}
                                        showTotal
                                        sizePerPage={10}
                                        hideSizePerPage={false}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(BulkAction)