import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { StyledCard } from '../../../Base/CommonUIComponents';
import { handleNotification } from '../../../Base/Notification';
import { FormattedMessage, injectIntl } from "react-intl";
import ConversationAnalytics from './ConversationAnalytics';
import MessagesAnalytics from './MessagesAnalytics';
import { getAPI } from '../../../Base/API';
import { Link } from 'react-router-dom';
import moment from 'moment';


class Analytics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            activeTab: '0',
            phoneNumbersOptions: []
        }
    }

    componentDidMount() {
        this.getPhones();
    }

    getPhones = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data)
                }

                const phoneNumbersOptions = [];

                if (data.response && data.response.length > 0) {
                    data.response[0].data.forEach(el => {
                        phoneNumbersOptions.push({ value: el.display_phone_number, label: el.verified_name || el.display_phone_number });
                    })
                }

                this.setState({ block: false, error: errorMessage, phoneNumbersOptions });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/WhatsApp/v1/phoneNumber`)
    }

    converUnixTimestampToDate = (unix, format) => {
        return moment.unix(unix).format(format)
    }

    toggleTab = (val) => {
        this.setState({ activeTab: val })
    }


    render() {
        const { block, error, phoneNumbersOptions } = this.state;

        return (
            <StyledCard block={block} error={error} >
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to="/WhatsAppConfig">
                                <FormattedMessage id="NavMenu.WhatsAppConfig" />
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            <FormattedMessage id="WhatsApp.Analytics" />
                        </BreadcrumbItem>
                    </Breadcrumb>
                </div>

                <Nav tabs className="border-0">
                    <NavItem>
                        <NavLink className={(this.state.activeTab === '0' ? 'tab-border-host text-host' : '') + ' border-0 pb-3 cursor-pointer'} onClick={() => { this.toggleTab('0'); }}>
                            <b>Conversations {/*<FormattedMessage id="WhatsAppAnalytics.ConversationAnalytics" />*/}</b>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={(this.state.activeTab === '1' ? 'tab-border-host text-host' : '') + ' border-0 pb-3 cursor-pointer'} onClick={() => { this.toggleTab('1'); }}>
                            <b>Messages {/*<FormattedMessage id="WhatsAppAnalytics.MessagesAnalytics" />*/}</b>
                        </NavLink>
                    </NavItem>
                </Nav>
                <hr className="m-0" style={{ backgroundColor: '#C9DEFE' }} />
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="0" className="border-0 my-0 p-0 ">
                        <ConversationAnalytics
                            phoneNumbersOptions={phoneNumbersOptions}
                            converUnixTimestampToDate={this.converUnixTimestampToDate}
                        />
                    </TabPane>
                    <TabPane tabId="1" className="border-0 my-0 p-0 ">
                        <MessagesAnalytics
                            phoneNumbersOptions={phoneNumbersOptions}
                            converUnixTimestampToDate={this.converUnixTimestampToDate}
                        />
                    </TabPane>
                </TabContent>
            </StyledCard>
        )
    }
}

export default injectIntl(Analytics);