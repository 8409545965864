import React, { Component } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getAPI, postAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { SendMessageModal } from './SendMessageModal';
import { Pagination } from '../Base/PaginationComponents';

class SendSendGridEmailModal extends Component {

    constructor(props) {
        super(props);
        this.getSenders = this.getSenders.bind(this);
        this.getTemplates = this.getTemplates.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.form = React.createRef();
        this.state = {
            blockSenders: true,
            blockTemplates: true,
            senders: [],
            templates: [],
            sender: {},
            templateId: null,
            contact: null,
            page: 1,
            pageSize: 8,
            pagination: [this.getNewPagination(1)]
        }
    }

    getNewPagination = (page) => {
        const newPagination = {
            prevToken: null,
            nextToken: null,
            page: page
        };
        return newPagination;
    }

    componentDidMount() {
        if (this.props.contacts && this.props.contacts.length > 0) {
            const contact = this.props.contacts.some(el => el.main) ? this.props.contacts.find(el => el.main) : this.props.contact ? this.props.contact[0] : '';

            this.setState({ contact }, () => {
                this.getSenders();
                this.getTemplates();
            })
        }
    }

    getSenders() {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorSenders: errorMessage, blockSenders: false });
                return;
            }
            if (data && data.response && data.response.results && data.response.results.length > 0) {
                const senders = data.response.results
                    .filter(resp => resp.verified)
                    .map(resp => {
                        const sender = {
                            value: resp.from_email,
                            label: `${resp.from_name}(${resp.from_email})`,
                            name: resp.from_name
                        };
                        return sender;
                    });
                this.setState({ errorSenders: errorMessage, blockSenders: false, senders: senders });
            }
            else {
                this.setState({ errorSenders: errorMessage, blockSenders: false });
            }
        }, `/api/gms/SendGrid/v1/sender?limit=100`);
    }

    getTemplates(token) {
        const { page, pageSize, pagination } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorTemplates: errorMessage, blockTemplates: false });
                return;
            }
            if (data && data.response && data.response.result && data.response.result.length > 0) {
                const templates = data.response.result
                    .filter(resp => resp.versions.some(vrs => vrs.active === 1))
                    .map(resp => {
                        const template = {
                            value: resp.id,
                            label: resp.name
                        }
                        const version = resp.versions.find(vrs => vrs.active === 1);
                        if (version) {
                            template.thumbnail_url = version.thumbnail_url.startsWith('//') ? `https:${version.thumbnail_url}` : `https://${version.thumbnail_url}`;
                        }
                        return template;
                    });

                if (data.response._metadata) {
                    const paginationItem = pagination.find(p => p.page === page);
                    if (paginationItem) {
                        paginationItem.prevToken = data.response._metadata.self ? (new URLSearchParams(new URL(data.response._metadata.self).searchParams)).get("page_token") : null;
                        paginationItem.nextToken = data.response._metadata.next ? (new URLSearchParams(new URL(data.response._metadata.next).searchParams)).get("page_token") : null;
                    }
                }

                this.setState({ errorTemplates: errorMessage, blockTemplates: false, templates: templates, pagination });
            }
            else {
                this.setState({ errorTemplates: errorMessage, blockTemplates: false });
            }
        }, `/api/gms/SendGrid/v1/templates?generations=legacy,dynamic&page=${pageSize}${token ? `&token=${token}` : ``}`);
    }

    handleChange(combo, evt) {
        if (evt) {
            const { name } = evt;
            this.setState({ [name]: combo ? combo : {} });
        }
    }

    selectTemplate = (newTemplateId) => {
        const templateId = this.state.templateId === newTemplateId ? null : newTemplateId;

        this.setState({ templateId });
    }

    sendMessage() {
        const { type, profileId } = this.props;
        const { sender, contact, templateId } = this.state;

        const templateValidationMessage = document.getElementById("templateValidationMessage");
        if (templateId === null) {
            templateValidationMessage.setCustomValidity(this.props.intl.formatMessage({ id: "generic.FillInThisField" }));
        }
        else {
            templateValidationMessage.setCustomValidity("");
        }

        if (!this.form.current.reportValidity()) {
            return;
        }

        const request = {
            openTrackingEnable: true,
            clickTrackingEnable: true,
            templateId: templateId,
            senderName: sender.label,
            senderEmail: sender.value,
            email: contact.contact
        };

        this.setState({ blockSenders: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorSenders: errorMessage, blockSenders: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="SendEmailModal.MessageSentWithSuccess" />, <FormattedMessage id="generic.success" />);
                }
            }
            this.setState({ errorSenders: errorMessage, blockSenders: false });
        }, `/api/gms/SendGrid/v1/${type}/send/${profileId}`, request);
    }

    handlePagination = (token, newPage) => {
        const { pagination } = this.state;
        const hasPagination = pagination.some(p => p.page === newPage);
        if (!hasPagination) {
            pagination.push(this.getNewPagination(newPage));
        }

        this.setState({ blockTemplates: true, page: newPage, pagination }, _ => this.getTemplates(token));
    }

    render() {
        const { isOpen, toggle, buttonLabel } = this.props;
        const { blockSenders, blockTemplates, errorSenders, errorTemplates, senders, sender, templates, contact, page, pagination, templateId } = this.state;

        return (
            <SendMessageModal
                blockSenders={blockSenders}
                blockTemplates={blockTemplates}
                buttonLabel={buttonLabel}
                contact={contact}
                errorSenders={errorSenders}
                errorTemplates={errorTemplates}
                form={this.form}
                handleChange={this.handleChange}
                isOpen={isOpen}
                sender={sender}
                senders={senders}
                sendMessage={this.sendMessage}
                toggle={toggle}
            >
                <div>
                    <input tabIndex={-1} id="templateValidationMessage" name="templateValidationMessage" autoComplete="off" style={{ opacity: 0, width: "100%", height: 0, position: "absolute" }} />
                    <Row>
                        {
                            templates.length > 0 ?
                                templates.map((template, key) =>
                                    <Col key={key} sm={3} className="mb-3 px-2">
                                        <Card className="border-0 px-3 py-2 h-100 shadow d-flex justify-content-between" style={{ backgroundColor: templateId === template.value ? '#d3eaff' : 'white', cursor: 'pointer' }} onClick={_ => this.selectTemplate(template.value)}>
                                            <div>
                                                <Row>
                                                    <Col>
                                                        <h4 className="m-0">{template.label}
                                                        </h4>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div style={{ width: '100%', height: 'auto' }}>
                                                <img src={template.thumbnail_url} style={{ width: 'inherit' }} />
                                            </div>
                                        </Card>
                                    </Col>
                                )
                                :
                                <Col className="text-center mt-3">
                                    <FormattedMessage id="generic.NoEgoiTemplatesAvailable" />
                                </Col>
                        }
                    </Row>
                    <Row className="my-4">
                        <Col className="text-right">
                            <Pagination
                                isPrevDisabled={page === 1}
                                isNextDisabled={pagination[page - 1].nextToken === null}
                                currentPage={page}
                                handlePrevButton={_ => this.handlePagination(pagination[page - 2].prevToken, page - 1)}
                                handleNextButton={_ => this.handlePagination(pagination[page - 1].nextToken, page + 1)}
                            />
                        </Col>
                    </Row>
                </div>
            </SendMessageModal>
        );
    }
}

export default injectIntl(SendSendGridEmailModal);