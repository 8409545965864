import React, { Component } from 'react'
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl } from 'react-intl'
import { Button, Col, Form, Input, Modal, ModalBody, Row, Badge } from 'reactstrap'
import GenericTextArea from '../../Base/GenericTextArea';
import CustomSelect from '../../Base/CustomSelect';
import { putAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { getMetaAnalysisTags } from '../../Base/ReferenceDataFunctions';

class CreatePrompt extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            newPrompt: {},
        };
    }

    componentDidMount(){
        let { newPrompt } = this.state;
        newPrompt.type = this.props.type;
        newPrompt.hotelGroupId = global.hotelGroupId;
        newPrompt.metaAnalysisTags = [];

        if(this.props.newPrompt){
            newPrompt = this.props.newPrompt;
        }

        this.setState({ newPrompt });
    }

    savePrompt = (e) => {
        e.preventDefault();
        const { newPrompt } = this.state;
        this.setState({ block: true }, () =>
            putAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data?.errors && data.errors.length > 0) {
                    this.setState({ block: false, error: data.errors }, () => handleNotification(data));
                    return;
                };
                handleNotification(data, <FormattedMessage id="SummaryConfig.PromptConfigSaved" />, <FormattedMessage id="generic.success" />);
                this.setState({ block: false, newPrompt: data.response[0] }, () => {this.props.updatePromptConfigList(data.response[0]);this.props.toggleCreateModal(null, true)});
            }, `/api/gms/Review/v1/promptConfig`, newPrompt)
        );
    }

    toggleIsMain = () => {
        const { newPrompt } = this.state;
        newPrompt.isMain = !newPrompt.isMain;

        this.setState({ newPrompt });
    }

    handleChange = (value, name) => {
        const { newPrompt } = this.state;
        newPrompt[name] = value;
        this.setState({ newPrompt });
    }

    changeTags = (tag) => {
        const { newPrompt } = this.state;
        const index = newPrompt.metaAnalysisTags && newPrompt.metaAnalysisTags.indexOf(tag);
        if (index > -1) {
            newPrompt.metaAnalysisTags.splice(index, 1);
        }
        else {
            newPrompt.metaAnalysisTags.push(tag)
        }
        this.setState({ newPrompt });
    }

    render() {
        const { block, newPrompt } = this.state;
        const { types, toggleCreateModal, intl } = this.props;

        const typesOptions = types?.map((type) => ({ value: type, label: type }));

        return (
            <Modal isOpen={true} toggle={toggleCreateModal} className='modal-lg'>
                <BlockUi tag="div" blocking={block}>
                    <ModalBody className='p-4'>
                        <Button onClick={toggleCreateModal} className="closeModal">
                            <i className="fas fa-times fa-sm" />
                        </Button>
                        <Form onSubmit={this.savePrompt}>
                            <Row>
                                <Col>
                                    <h5><FormattedMessage id="PromptConfig.CreateNew"/></h5>
                                </Col>
                                <Col className='d-flex align-items-center justify-content-end'>
                                    <div style={{ width: '20px', color: '#0665ff' }}>
                                        <div className='cursor-pointer' onClick={this.toggleIsMain}>
                                            {newPrompt.isMain ?
                                                <i className="fas fa-star"/>
                                                :
                                                <i className="far fa-star"/>
                                            }
                                        </div>
                                    </div>
                                    <Button className="btn btn-host btn-sm ml-2" type='submit'>
                                        <i className="fas fa-save"></i>
                                    </Button>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col>
                                    <div>
                                        <FormattedMessage id="PromptConfig.PromptName"/>
                                    </div>
                                    <div className='mt-2'>
                                        <Input
                                            name="name"
                                            required={true}
                                            onChange={e => this.handleChange(e?.target?.value, "name")}
                                            value={newPrompt.name??""}
                                            maxLength="100"
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div>
                                        <FormattedMessage id="PromptConfig.PromptType"/>
                                    </div>
                                    <div className='mt-2'>
                                        <CustomSelect
                                            options={typesOptions}
                                            value={newPrompt.type && typesOptions ? typesOptions.find(({value}) => value === newPrompt.type) : null}
                                            isDisabled={true}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col>
                                    <div>
                                        <FormattedMessage id="PromptConfig.Desc"/>
                                    </div>
                                    <div className='mt-2'>
                                        <GenericTextArea
                                            backgroundcolor={"white"}
                                            onChange={e => this.handleChange(e?.target?.value, "description")}
                                            value={newPrompt.description??""}
                                            name={"description"}
                                            placeholder={""}
                                            maxLength='2000'
                                            className="py-1"
                                            style={{
                                                width: '100%',
                                                background: 'transparent',
                                                boxShadow: 'unset',
                                                outline: '0',
                                                maxHeight : '500px',
                                                minHeight: '50px',
                                                height: '50px',
                                                padding: '5px 0',
                                                resize: 'none',
                                                color: '#07294C',
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col>
                                    <div>
                                        <FormattedMessage id="PromptConfig.Context"/>
                                    </div>
                                    <div className='mt-2'>
                                        <GenericTextArea
                                            backgroundcolor={"white"}
                                            required={true}
                                            onChange={e => this.handleChange(e?.target?.value, "context")}
                                            value={newPrompt.context??""}
                                            name={"context"}
                                            placeholder={""}
                                            maxLength='5000'
                                            className="py-1"
                                            style={{
                                                width: '100%',
                                                background: 'transparent',
                                                boxShadow: 'unset',
                                                outline: '0',
                                                maxHeight : '500px',
                                                minHeight: '50px',
                                                height: '50px',
                                                padding: '5px 0',
                                                resize: 'none',
                                                color: '#07294C',
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col>
                                    <div>
                                        <FormattedMessage id="PromptConfig.Question"/>
                                    </div>
                                    <div className='mt-2'>
                                        <GenericTextArea
                                            required={true}
                                            backgroundcolor={"white"}
                                            onChange={e => this.handleChange(e?.target?.value, "question")}
                                            value={newPrompt.question??""}
                                            name={"question"}
                                            placeholder={""}
                                            maxLength='5000'
                                            className="py-1"
                                            style={{
                                                width: '100%',
                                                background: 'transparent',
                                                boxShadow: 'unset',
                                                outline: '0',
                                                maxHeight : '500px',
                                                minHeight: '50px',
                                                height: '50px',
                                                padding: '5px 0',
                                                resize: 'none',
                                                color: '#07294C',
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            {newPrompt.type && newPrompt.type === "MetaAnalysis" ?
                                <Row className='mt-3'>
                                    <Col>
                                        <div>
                                            <FormattedMessage id="PromptConfig.Tags" />
                                        </div>
                                        <div className='mt-2'>
                                            {getMetaAnalysisTags().map(tag =>
                                                <Badge className="mr-2 p-2 mb-2 pointer" color={`${newPrompt.metaAnalysisTags && newPrompt.metaAnalysisTags.includes(tag.value) ? 'primary' : 'secondary'}`} onClick={() => this.changeTags(tag.value)}>
                                                    {tag.label}
                                                </Badge>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                :''}
                        </Form>
                    </ModalBody>
                </BlockUi>
            </Modal>
        )
    }
}
export default injectIntl(CreatePrompt)