import React, { useCallback } from 'react';
import { useReactFlow } from 'react-flow-renderer';
import { FormattedMessage } from 'react-intl';

export default function NodeMenu({id, top, left, right, bottom, toggleConfigureNode, nodes, edges, ...props}) {
    const { setNodes, setEdges } = useReactFlow();

    const deleteNode = useCallback(() => {
        setNodes(() => {
            const currentNode = nodes.find((node) => node.id === id);
            const updatedNodes = nodes.filter((node) => node.id !== id);

            props.changeNodes(currentNode, 'delete');
            return updatedNodes;
        });
    
        setEdges(() => {
            edges.forEach((edge) => {
                if(edge.source === id || edge.target === id) {
                    props.changeEdges(edge, 'delete', edge.id);
                }
            });

            const updatedEdges = edges.filter((edge) => edge.source !== id || edge.target !== id);
            return updatedEdges;
        });
    }, [id, setNodes, setEdges, props]);

    return (
        <div
            style={{ top, left, right, bottom }}
            className="context-menu"
            {...props}
        >
            <p style={{ margin: '0.5em' }}>
                <FormattedMessage id="CustomerJourney.Actions" />
            </p>
            <button onClick={toggleConfigureNode}>
                <div className='d-flex align-items-center' style={{ fontSize: '11.5px', borderRadius: '0%' }}>
                    <i className="fas fa-cog mr-2"></i>
                    <FormattedMessage id="CustomerJourney.ConfigureNode" />
                </div>
            </button>
            <button onClick={deleteNode}>
                <div className='d-flex align-items-center' style={{ fontSize: '11.5px' }}>
                    <i className="far fa-trash-alt mr-2"></i>
                    <FormattedMessage id="CustomerJourney.DeleteNode" />
                </div>
            </button>
        </div>
    );
}