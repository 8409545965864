import moment from "moment-timezone";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import countryList from "react-select-country-list";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { getAPI, postAPI } from "../Base/API";
import Authorization, { CheckAuthorization } from '../Base/Authorization';
import { StyledCard } from "../Base/CommonUIComponents";
import CustomSelect from "../Base/CustomSelect";
import { handleNotification } from "../Base/Notification";

const currencies = require("../../Utils/Currencies.json");

class HotelDetail extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.form = React.createRef();
        this.state = {
            block: false,
            hotel: {
                HotelId: undefined,
                Name1: undefined,
                Name2: undefined,
                Address1: undefined,
                ZipCode: undefined,
                City: undefined,
                CountryCode: undefined,
                Phone: undefined,
                Email: undefined,
                WebSite: undefined,
                Contact: undefined,
                CurrencyCode: undefined,
                CultureCode: undefined,
                defaultTimeZone: undefined
            },
            timezones: null,
            hotelGroupList: []
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.props.match.params.id) {
            this.getHotel("hotelId");
        }

        if (CheckAuthorization("admin:view", null)) {
            this.getHotelGroups();
        }
    }

    getHotelGroups = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({  message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                var list = [];

                data.response && data.response.forEach(el => {
                    list.push({ value: el.hotelGroupId, label: el.name})
                })

                this.setState({ hotelGroupList: list, block: false }, this.getTimeZones());
            }
        }, `/api/gms/Hotel/v1/hotelgroup/list?pageSize=1000`);
    }

    getHotel() {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (CheckAuthorization("admin:view", null)) {
                    this.setState({ hotel: data.response[0], block: false });
                }
                else {
                    const hotel = data.response[0];
                    this.setState({ hotel, hotelGroupList: [{ value: hotel.hotelGroupId, label: hotel.hotelGroupName }], block: false }, this.getTimeZones);
                }        
            }
        }, `/api/gms/Hotel/v1/hotel/${this.props.match.params.id}`);
    }

    saveHotel(event) {
        event.preventDefault();
        this.setState({ block: true });
        const { hotel } = this.state;
        const url = this.props.match.params.id ? '/api/gms/Hotel/v1/hotel/update' : '/api/gms/Hotel/v1/hotel/add';

        const regex = /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i;
        const hotelId = document.getElementById('hotelId');

        if (hotel.hotelId && !regex.test(hotel.hotelId)) {
            hotelId.setCustomValidity(this.props.intl.formatMessage({ id: "hotel.hotelIdInvalidFormat" }));
            this.setState({ block: false });
            return;
        }

        postAPI( result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="hotel.HotelSavedSuccessfully" />, <FormattedMessage id="generic.success" /> );

                if (data.response && data.response.length > 0) {
                    this.setState({ user: data.response[0] });
                }
            }
            this.setState({ block: false });
        }, url, JSON.stringify({ request: hotel }));
    }
    
    handleInput = ({ target: { value, name } }) => {
        const { hotel } = this.state;

        hotel[name] = value;
        this.setState({ hotel });
    }
    
    getTimeZones = () => {
        const timezones = moment.tz.names();
        const timezoneOptions = timezones.map(timezone => ({ value: timezone, label: `(UTC${moment.tz(timezone).format('Z')}) ${timezone}` }));
    
        this.setState({ timezones: timezoneOptions });
    }

    render() {
        const help = <div>TODO1</div>;

        const { hotel, hotelGroupList, timezones } = this.state;
        return (
            <Form onSubmit={this.saveHotel.bind(this)}>
                <Row className="mb-2">
                    <Col>
                        <StyledCard
                            block={this.state.block}
                            error={this.state.error}
                        >
                            <Row className="mb-2">
                                <Col>
                                    <h5>
                                        <i className="far fa-address-card mr-2"></i>
                                    </h5>
                                </Col>
                                <Col className="text-right">
                                    <div className="py-1">
                                        <Authorization
                                            perform="hotelDetails:save"
                                            yes={() => (
                                                <Button className="btn btn-sm  btn-host " type="submit">
                                                    <i className="fas fa-save "></i>
                                                </Button>
                                            )}
                                            no={() => <div></div>}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <FormGroup row>
                                <FormattedMessage id={`hotel.hotelId`}>
                                    {placeholder => (
                                        <>
                                            <Label for="hotelId" sm={2}>
                                                {placeholder}
                                            </Label>
                                            <Col sm={4}>
                                                <Input
                                                    type="text"
                                                    name="hotelId"
                                                    id="hotelId"
                                                    value={this.state.hotel ? this.state.hotel.hotelId : ''}
                                                    onChange={e => this.handleInput(e)}
                                                    required
                                                    placeholder={placeholder}
                                                />
                                            </Col>
                                        </>
                                    )}
                                </FormattedMessage>
                                <FormattedMessage id={`hotel.hotelGroup`}>
                                    {placeholder => (
                                        <>
                                            <Label sm={2}>
                                                {placeholder}
                                            </Label>
                                            <Col sm={4}>

                                                <CustomSelect
                                                    icon={"icon-icon-hotel"}
                                                    options={hotelGroupList}
                                                    required={true}
                                                    isClearable
                                                    isMulti={false}
                                                    value={hotel.hotelGroupId ? hotelGroupList.find(hg => hg.value === hotel.hotelGroupId) : ""}
                                                    isSearchable
                                                    placeholder={placeholder}
                                                    onChange={(values, actionMeta) => {
                                                        const { value, label } = values ? values : null;
                                                        this.handleInput({ target: { value: value, name: "hotelGroupId" } });
                                                    }}
                                                />
                                            </Col>
                                        </>
                                    )}
                                </FormattedMessage>
                            </FormGroup>

                            <FormGroup row>
                                <FormattedMessage id={`hotel.name1`}>
                                    {placeholder => (
                                        <>
                                            <Label for="name1" sm={2}> {placeholder} </Label>
                                            <Col sm={4}>
                                                <Input
                                                    type="text"
                                                    name="name1"
                                                    id="name1"
                                                    placeholder={placeholder}
                                                    value={hotel.name1}
                                                    onChange={e =>
                                                        this.handleInput(e)
                                                    }
                                                    required
                                                />
                                            </Col>
                                        </>
                                    )}
                                </FormattedMessage>

                                <FormattedMessage id={`hotel.name2`}>
                                    {placeholder => (
                                        <>
                                            <Label for="Name2" sm={2}>
                                                {placeholder}
                                            </Label>
                                            <Col sm={4}>
                                                <Input
                                                    type="text"
                                                    required
                                                    name="name2"
                                                    id="name2"
                                                    placeholder={placeholder}
                                                    value={hotel.name2}
                                                    onChange={e =>
                                                        this.handleInput(e)
                                                    }
                                                />
                                            </Col>
                                        </>
                                    )}
                                </FormattedMessage>
                            </FormGroup>

                            <FormGroup row>
                                <FormattedMessage id={`hotel.fiscalNumber`}>
                                    {placeholder => (
                                        <>
                                            <Label for="fiscalNumber" sm={2}>
                                                {placeholder}
                                            </Label>
                                            <Col sm={4}>
                                                <Input
                                                    type="text"
                                                    name="fiscalNumber"
                                                    id="fiscalNumber"
                                                    placeholder={placeholder}
                                                    value={hotel.fiscalNumber}
                                                    onChange={e =>
                                                        this.handleInput(e)
                                                    }
                                                    required
                                                />
                                            </Col>
                                        </>
                                    )}
                                </FormattedMessage>
                            </FormGroup>
                        </StyledCard>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <StyledCard
                            block={this.state.block}
                        >
                            <Row className="mb-2">
                                <Col>
                                    <h5>
                                        <i className="fas fa-at mr-2"></i>
                                    </h5>
                                </Col>
                                </Row>
                            <FormGroup row>
                                <FormattedMessage id={`hotel.phone`}>
                                    {placeholder => (
                                        <>
                                            <Label for="phone" sm={2}>
                                                {placeholder}
                                            </Label>
                                            <Col sm={4}>
                                                <Input
                                                    type="text"
                                                    name="phone"
                                                    id="phone"
                                                    placeholder={placeholder}
                                                    bsSize="sm"
                                                    value={hotel.phone}
                                                    onChange={e =>
                                                        this.handleInput(e)
                                                    }
                                                />
                                            </Col>
                                        </>
                                    )}
                                </FormattedMessage>

                                <FormattedMessage id={`hotel.email`}>
                                    {placeholder => (
                                        <>
                                            <Label for="email" sm={2}>
                                                {placeholder}
                                            </Label>
                                            <Col sm={4}>
                                                <Input
                                                    type="text"
                                                    name="email"
                                                    id="email"
                                                    placeholder={placeholder}
                                                    bsSize="sm"
                                                    value={hotel.email}
                                                    onChange={e =>
                                                        this.handleInput(e)
                                                    }
                                                />
                                            </Col>
                                        </>
                                    )}
                                </FormattedMessage>
                            </FormGroup>
                            <FormGroup row>
                                <FormattedMessage id={`hotel.currencyCode`}>
                                    {placeholder => (
                                        <>
                                            <Label for="currencyCode" sm={2}>
                                                {placeholder}
                                            </Label>
                                            <Col sm={4}>
                                                <CustomSelect
                                                    icon={"fas fa-coins"}
                                                    options={currencies}
                                                    required={true}
                                                    isClearable
                                                    isMulti={false}
                                                    value={hotel.defaultCurrencyCode ?
                                                        { value: currencies.find( d => d.label === hotel.defaultCurrencyCode ).value, label: hotel.defaultCurrencyCode }
                                                    : "" }
                                                    isSearchable
                                                    placeholder={placeholder}
                                                    onChange={(values, actionMeta) => {
                                                        const { value, label } = values ? values : '';
                                                        this.handleInput({ target: { value: label, name: "defaultCurrencyCode" } });
                                                    }}
                                                />
                                            </Col>
                                        </>
                                    )}
                                </FormattedMessage>
                            </FormGroup>
                        </StyledCard>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <StyledCard
                            block={this.state.block}
                        >
                            <Row className="mb-2">
                                <Col>
                                    <h5>
                                        <i className="fas fa-map mr-2"></i>
                                    </h5>
                                </Col>
                            </Row>
                            <FormGroup row>
                                <FormattedMessage id={`hotel.contact`}>
                                    {placeholder => (
                                        <>
                                            <Label for="contact" sm={2}>
                                                {placeholder}
                                            </Label>
                                            <Col sm={10}>
                                                <Input
                                                    type="text"
                                                    name="contact"
                                                    id="Contact"
                                                    placeholder={placeholder}
                                                    value={hotel.contact}
                                                    onChange={e =>
                                                        this.handleInput(e)
                                                    }
                                                />
                                            </Col>
                                        </>
                                    )}
                                </FormattedMessage>
                            </FormGroup>
                            <FormGroup row>
                                <FormattedMessage id={`hotel.address1`}>
                                    {placeholder => (
                                        <>
                                            <Label for="street1" sm={2}>
                                                {placeholder}
                                            </Label>
                                            <Col sm={10}>
                                                <Input
                                                    type="text"
                                                    name="address1"
                                                    id="Street1"
                                                    placeholder={placeholder}
                                                    value={hotel.address1}
                                                    onChange={e =>
                                                        this.handleInput(e)
                                                    }
                                                />
                                            </Col>
                                        </>
                                    )}
                                </FormattedMessage>
                            </FormGroup>
                            <FormGroup row>
                                <FormattedMessage id={`hotel.address2`}>
                                    {placeholder => (
                                        <>
                                            <Label for="street2" sm={2}>
                                                {placeholder}
                                            </Label>
                                            <Col sm={10}>
                                                <Input
                                                    type="text"
                                                    name="address2"
                                                    id="Street2"
                                                    placeholder={placeholder}
                                                    value={hotel.address2}
                                                    onChange={e =>
                                                        this.handleInput(e)
                                                    }
                                                />
                                            </Col>
                                        </>
                                    )}
                                </FormattedMessage>
                            </FormGroup>
                            <FormGroup row>
                                <FormattedMessage id={`hotel.city`}>
                                    {placeholder => (
                                        <>
                                            <Label for="City" sm={2}>
                                                {placeholder}
                                            </Label>
                                            <Col sm={4}>
                                                <Input
                                                    type="text"
                                                    name="city"
                                                    id="City"
                                                    placeholder={placeholder}
                                                    value={hotel.city}
                                                    onChange={e =>
                                                        this.handleInput(e)
                                                    }
                                                />
                                            </Col>
                                        </>
                                    )}
                                </FormattedMessage>
                                <FormattedMessage id={`hotel.timezone`}>
                                    {placeholder => (
                                        <>
                                            <Label for="City" sm={2}>
                                                {placeholder}
                                            </Label>
                                            <Col sm={4}>
                                            <CustomSelect
                                                type="text"
                                                name="defaultTimeZone"
                                                id="defaultTimeZone"
                                                placeholder={placeholder}
                                                isSearchable isClearable
                                                options={timezones}
                                                onChange={(combo) => this.handleInput({ target: { value: (combo ? combo.value : null), name: "defaultTimeZone" } })}
                                                value={timezones && hotel && hotel.defaultTimeZone ? timezones.find(el => el.value === hotel.defaultTimeZone) : ''}
                                            />
                                            </Col>
                                        </>
                                    )}
                                </FormattedMessage>
                            </FormGroup>
                            <FormGroup row>
                                <FormattedMessage id={`hotel.zipCode`}>
                                    {placeholder => (
                                        <>
                                            <Label for="ZipCode" sm={2}>
                                                {placeholder}
                                            </Label>
                                            <Col sm={4}>
                                                <Input
                                                    type="text"
                                                    name="zipCode"
                                                    id="ZipCode"
                                                    placeholder={placeholder}
                                                    value={hotel.zipCode}
                                                    onChange={e =>
                                                        this.handleInput(e)
                                                    }
                                                />
                                            </Col>
                                        </>
                                    )}
                                </FormattedMessage>

                                <FormattedMessage id={`hotel.country`}>
                                    {placeholder => (
                                        <>
                                            <Label for="Country" sm={2}>
                                                {placeholder}
                                            </Label>
                                            <Col sm={4}>
                                                <CustomSelect
                                                    icon={"fas fa-globe"}
                                                    options={countryList().getData()}
                                                    required={true}
                                                    isClearable
                                                    isMulti={false}
                                                    value={hotel.countryCode ?
                                                        { value: hotel.countryCode, label: countryList().getData().find( d => d.value === hotel.countryCode ).label }
                                                    : "" }
                                                    isSearchable
                                                    placeholder={placeholder}
                                                    onChange={(values, actionMeta) => {
                                                        const { value, label } = values ? values : null;
                                                        this.handleInput({ target: { value, name: "countryCode" } });
                                                    }}
                                                />
                                            </Col>
                                        </>
                                    )}
                                </FormattedMessage>
                            </FormGroup>
                        </StyledCard>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <StyledCard block={this.state.block}>
                            <Row className="mb-2">
                                <Col>
                                    <h5>
                                        <i className="fas fa-info-circle mr-2"></i>
                                    </h5>
                                </Col>
                            </Row>
                            <FormGroup row>
                                <FormattedMessage id={`hotel.pmsUrl`}>
                                    {placeholder => (
                                        <>
                                            <Label for="pmsUrl" sm={2}>
                                                {placeholder}
                                            </Label>
                                            <Col sm={10}>
                                                <Input
                                                    type="text"
                                                    name="pmsUrl"
                                                    id="pmsUrl"
                                                    placeholder={placeholder}
                                                    value={hotel.pmsUrl}
                                                    onChange={e =>
                                                        this.handleInput(e)
                                                    }
                                                    disabled={!CheckAuthorization("hotelDetails:editPmsUrl")}
                                                />
                                            </Col>
                                        </>
                                    )}
                                </FormattedMessage>
                            </FormGroup>
                        </StyledCard>
                    </Col>
                </Row>
            </Form>
        );
    }
}
export default injectIntl(HotelDetail)