import React, { Component } from 'react'
import { Badge, Card, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import GoogleStars from './GoogleStars';

export default class GoogleSideMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            locationsOpen: false
        };
    }

    toggleLocationsOpen = () => {
        this.setState({ locationsOpen: !this.state.locationsOpen });
    }

    render() {
        const { locationsOpen } = this.state;
        const { activeTab, toggle, averageRating, totalReviewCount, location, locationsOptions, selectedLocation, handleSelect, pages, unverifiedLocation } = this.props;

        return (
            <div className="side-menu">
                <Card className="border-0 shadow mb-3 p-3">
                    <Row>
                        <Col className='pr-0'>
                            <div>
                                <b>{location?.title}</b>
                            </div>
                            {unverifiedLocation ?
                                <div className='py-1 d-flex align-items-center' style={{ color: "#A80C19", fontSize: '0.9em' }}>
                                    <i style={{ fontSize: '0.8em' }} className="fas fa-certificate mr-1"></i>
                                    <b><FormattedMessage id="google.unverifiedLocation"/></b>
                                </div>
                            :''}
                            <div className='text-muted'>
                                {location?.categories?.primaryCategory?.displayName}
                            </div>
                            <GoogleStars
                                averageRating={averageRating}
                                totalReviewCount={totalReviewCount}
                            />
                        </Col>
                        <Col className='col-1 pl-0'>
                            <div className='cursor-pointer' onClick={this.toggleLocationsOpen}>
                                {locationsOpen ?
                                    <i className="fas fa-chevron-up"/>
                                :
                                    <i className="fas fa-chevron-down"/>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {locationsOpen && locationsOptions ?
                                <div className="mt-2 location-options">
                                    {locationsOptions.map((op, key) =>
                                        <div key={key} onClick={e => {handleSelect(op); this.toggleLocationsOpen()}}
                                        className={`p-2  text-left cursor-pointer ${selectedLocation === op.value ? 'text-primary' : ''}`}>
                                            {selectedLocation === op.value ?
                                                <div style={{
                                                    'left': '0',
                                                    'position': 'absolute',
                                                    'borderRadius': '0px 30px 30px 0px',
                                                    'width': '7px',
                                                    'height': '14px',
                                                    'background': '#0667FF 0% 0% no-repeat padding-box',
                                                    'opacity': '1',
                                                    'marginTop': '4px'
                                                }}/>
                                            : ''}
                                            {op.label}
                                        </div>
                                    )}
                                </div>
                            :''}
                        </Col>
                    </Row>
                </Card>

                <Nav tabs vertical className='mt-4 border-0'>
                    <Card className="bg-white p-2 border-0 mb-3 shadow">
                        <div className='pl-2 py-2 cursor-pointer' onClick={this.toggleLocationsOpen}>
                            <h5 className='m-0'>
                                <FormattedMessage id={"GoogleBusinessConfig.Actions"}/>
                            </h5>
                        </div>
                        {pages.map((page, key) =>
                            <NavItem key={key} className="pb-2">
                                <NavLink className={activeTab === page.name ? 'text-primary border-0  ' : 'bg-white  border-0 '} onClick={_ => toggle(page.name)} style={{ cursor: 'pointer' }} >
                                    {activeTab === page.name ?
                                        <div style={{
                                            'left': '0',
                                            'position': 'absolute',
                                            'borderRadius': '0px 30px 30px 0px',
                                            'width': '7px',
                                            'height': '14px',
                                            'background': '#0667FF 0% 0% no-repeat padding-box',
                                            'opacity': '1',
                                            'marginTop': '4px'
                                        }}/>
                                    : ''}
                                    <div>
                                        <i className={`mr-2 ${page.icon}`}/>
                                        <FormattedMessage id={"GoogleBusinessConfig."+ page.name}/>
                                        {page.badgeCount ?
                                            <Badge id={`Badge-${page.name}`} className="float-right">{page.badgeCount}</Badge>
                                        : ''}
                                    </div>
                                </NavLink>
                            </NavItem>
                        )}
                    </Card>
                </Nav>
            </div>
        );
    }
}
