import React, { Component } from 'react';
import { BlankCard } from '../../../../Base/CommonUIComponents';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI, getMultiPartAPI } from '../../../../Base/API';
import { handleNotification } from '../../../../Base/Notification';
import { Button, Card, Form, Input, Modal, ModalBody, Row, Col, CardBody } from 'reactstrap';
import { Pagination } from '../../../../Base/PaginationComponents';
import MediaPreview from '../../../../Marketing/WhatsApp/Media/MediaPreview';

class SelectMedia extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: true,
            pageSize: 8,
            pageIndex: 0,
            totalMedia: 0,
            selectedMedia: {}
        }
    }

    componentDidMount() {
        this.getMedia();
    }

    getMedia = () => {
        const type = this.props.mediaType ? `&type=${this.props.mediaType}` : '';

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                }
                else {
                    this.setState({ mediaList: data.response, totalMedia: data.totalItems, block: false })
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/WhatsApp/v1/media?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}` + type)
    }

    getMediaDetails = (idx, mediaId) => {
        const { mediaList } = this.state;

        if (!mediaList[idx]?.preview) {
            mediaList[idx].block = true;
            this.setState({ mediaList });

            getMultiPartAPI(result => {
                const { data, error } = result;

                if (error) {
                    mediaList[idx].notFound = true;
                    mediaList[idx].block = false;

                    this.setState({ mediaList });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        mediaList[idx].notFound = true;
                        mediaList[idx].block = false;

                        this.setState({ mediaList });
                    }
                    else {
                        const reader = new FileReader();
                        reader.readAsDataURL(data);

                        reader.onloadend = () => {
                            mediaList[idx].preview = reader.result;
                            mediaList[idx].block = false;

                            this.setState({ mediaList });
                        }
                    }

                }
                else {
                    mediaList[idx].block = false;

                    this.setState({ mediaList });
                }
            }, `api/gms/WhatsApp/v1/${mediaId}/media`);
        }
    }

    sendMedia = (e) => {
        e.preventDefault();
        this.props.toggleModal();

        this.props.sendMessage(null, null, this.state.selectedMedia);
    }

    selectMedia = (media) => {
        this.setState(prevState => ({
            selectedMedia: {
                ...prevState.selectedMedia,
                socialMediaId: media.id,
                mediaId: media.contentId,
                type: media.type.toLowerCase(),
                preview: media.preview
            }
        }));
    }

    handleChange = (e) => {
        const { value, name } = e.target;

        this.setState(prevState => ({
            selectedMedia: {
                ...prevState.selectedMedia,
                [name]: value
            }
        }));
    }

    render() {
        const { imageModal, toggleModal, onlySelect } = this.props;
        const { mediaList, pageIndex, pageSize, totalMedia, selectedMedia } = this.state;


        return (
            <Modal isOpen={imageModal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={this.state.block} error={this.state.error}>
                        {mediaList && mediaList.length > 0 ?
                            <>
                                <Row className="mb-4">
                                    <Col className="pl-4">
                                        <h5><FormattedMessage id="WhatsApp.SelectMedia" /></h5>
                                    </Col>
                                    <Col className="text-right">
                                        {onlySelect ?
                                            <Button className="text-center btn-sm btn-host" onClick={() => this.props.submit(selectedMedia)} disabled={!selectedMedia?.socialMediaId}>
                                                <i className="fas fa-plus" />
                                            </Button>
                                            :
                                            <Button className="text-center btn-sm btn-host" onClick={this.sendMedia} disabled={!selectedMedia?.socialMediaId}>
                                                <i className="fas fa-paper-plane" />
                                            </Button>
                                        }
                                    </Col>
                                </Row>

                                {!onlySelect ?
                                    <Row className="mb-4">
                                        <Col className="col-3 pl-4">
                                            <FormattedMessage id="WhatsApp.Caption" />:
                                        </Col>
                                        <Col className="text-right">
                                            <Input
                                                type="text"
                                                name="caption"
                                                placeholder={this.props.intl.formatMessage({ id: "WhatsApp.AddACaption" })}
                                                onChange={(e) => this.handleChange(e)}
                                                value={selectedMedia?.caption || ''}
                                            />
                                        </Col>
                                    </Row>
                                : ''}

                                <Row>
                                    {mediaList.map((media, index) => {
                                        const mediaObj = { type: media.filetype, id: media.contentId, fileName: media.filename, preview: media.preview };

                                        return <Col key={index} className="col-3 mb-4">
                                            <Card className={"border-0 shadow pointer " + (selectedMedia?.socialMediaId === media.id ? 'bg-host-light' : '')} style={{ borderRadius: '15px' }}
                                                onClick={() => this.selectMedia(media)}
                                            >
                                                <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>                                                    
                                                    <MediaPreview
                                                        media={mediaObj}
                                                        borderRadius='15px 15px 0 0'
                                                        loadImage={true}
                                                        newMedia={true}
                                                        getMediaDetails={() => this.getMediaDetails(index, media.contentId)}
                                                        block={media.block}
                                                    />                                                    
                                                </div>
                                                <CardBody className="mb-2 mt-1">
                                                    <Row className="mb-2 align-items-center">
                                                        <Col>
                                                            <h5 title={media.filename} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', margin: '0' }}>
                                                                {media.filename}
                                                            </h5>
                                                        </Col>
                                                    </Row>
                                                    <div title={media.filetype} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}> {media.filetype}</div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    })}
                                </Row>

                                {totalMedia > mediaList.length ?
                                    <Row className="my-4">
                                        <Col className="text-right mr-3">
                                            <Pagination
                                                isPrevDisabled={pageIndex === 0}
                                                isNextDisabled={totalMedia <= ((pageIndex + 1) * pageSize)}
                                                currentPage={pageIndex + 1}
                                                handlePrevButton={() => this.handlePagination(-1)}
                                                handleNextButton={() => this.handlePagination(1)}
                                            />
                                        </Col>
                                    </Row>
                                    : ''}
                            </>
                            : <div className="text-center"> No media available </div>}
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(SelectMedia)