import React, { Component } from 'react';
import { Badge } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

export class CampaignTargetStatusBadge extends Component {

    render() {
        const { status } = this.props;

        const campaignTargetStatus = [
            {
                value: 'Finished',
                label: <FormattedMessage id="CampaignTargets.Finished" />,
                badge: 'success'
            },
            {
                value: 'Running',
                label: <FormattedMessage id="CampaignTargets.Running" />,
                badge: "primary"
            },
            {
                value: 'NotRun',
                label: <FormattedMessage id="CampaignTargets.Scheduled" />,
                badge: "secondary"
            }
        ];

        const currentStatus = campaignTargetStatus.find(item => item.value === status);

        return (
            <Badge className={'p-1 px-2 shadow text-white'} color={currentStatus != null ? currentStatus.badge : 'secondary'}>
                {currentStatus != null ? currentStatus.label : ''}
            </Badge>
        )
    }
}