import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Card, Row, Col, Button, Input } from 'reactstrap'
import { DateRangePicker } from 'react-dates';
import { StyledCard, DefaultPage } from '../../Base/CommonUIComponents';
import { getAPI } from '../../Base/API';
import moment from 'moment-timezone';
import { handleNotification } from '../../Base/Notification';
import { Pagination, ResultsPerPage, TotalResults } from '../../Base/PaginationComponents';

class CustomerJourneyDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            journeyList: [],
            statusSearch: null,
            journeyNameSearch: null,
            startDateSearch: null,
            endDateSearch: null,
            pageSize: 10,
            pageIndex: 0,
            totalItems: 0
        }
    }

    componentDidMount() {
        this.getJourneyList();
    }

    getJourneyList = () => {
        const { pageSize, pageIndex, journeyNameSearch, startDateSearch, endDateSearch } = this.state;
        this.setState({ block: true }, () =>
            getAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if(data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    if (data.response && data.response.length > 0) {
                        this.setState({ block: false, journeyList: data.response, totalItems: data.totalItems });
                        return;
                    }
                }
                this.setState({ block: false });
            }, `/api/gms/Journey/v1/Journey/list?${startDateSearch ? 'fromDate='+moment(startDateSearch).format("YYYY-MM-DD")+'&' : ''}${endDateSearch ? 'toDate='+moment(endDateSearch).format("YYYY-MM-DD")+'&' : ''}${journeyNameSearch ? 'name='+journeyNameSearch+'&' : ''}pageSize=${pageSize}&pageIndex=${pageIndex}`)
        )
    }

    changePageSize = (evt) => {
        const { value } = evt.target;

        if (this.state.pageSize !== value) {
            this.setState({ pageSize: value, pageIndex: 0 }, () => this.getJourneyList());
        }
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.getJourneyList());
    }

    render() {
        const { journeyList, journeyNameSearch, startDateSearch, endDateSearch, totalItems, pageIndex, pageSize, block, error } = this.state

        return (
            <StyledCard block={block} error={error} icon={'fas fa-project-diagram'} title={'NavMenu.CustomerJourney'} >
                <Row className="my-4 align-items-center">
                    <Col sm={3}>
                        <Input
                            type="text"
                            name="JournalName"
                            value={journeyNameSearch ?? ''}
                            placeholder={this.props.intl.formatMessage({ id: "CustomerJourney.SearchJourney" })}
                            onChange={(e) => this.setState({ journeyNameSearch: e.target.value })}
                            required
                        />
                    </Col>
                    <Col className="pl-0">
                        <DateRangePicker
                            startDate={startDateSearch || null}
                            startDateId="your_unique_start_date_id"
                            endDate={endDateSearch || null}
                            endDateId="your_unique_end_date_id"
                            isOutsideRange={day => day > moment() || day < moment().subtract(6, 'month')}
                            onDatesChange={({ startDate, endDate }) => this.setState({ startDateSearch: startDate, endDateSearch: endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </Col>

                    <Col className="text-right col-1">
                        <Button className="btn btn-sm btn-host mr-2" onClick={() => this.props.history.push({ pathname: "/JourneyBuilder" })}>
                            <i className="fas fa-plus"></i>
                        </Button>
                        <Button className="btn btn-sm btn-host" onClick={() => this.getJourneyList()}>
                            <i className="icon-icon-search"></i>
                        </Button>
                    </Col>
                </Row>
                <Row className='p-3 mb-2'>
                    <Col className='col-8'>
                        <FormattedMessage id="CustomerJourney.JourneyName" />
                    </Col>
                    <Col className='col-2'>
                        <FormattedMessage id="CustomerJourney.Status" />
                    </Col>
                    <Col className='col-2'>
                        <FormattedMessage id="CustomerJourney.LastModified" />
                    </Col>
                </Row>
                <hr className='m-0' />
                {journeyList && journeyList.length > 0 ?
                    <>
                        {journeyList.map((journey, k) => 
                            <div key={k}>
                                <Card className="border-0 p-3 overbglight cursor-pointer" key={k} style={{ backgroundColor: 'transparent' }} onClick={() => this.props.history.push({ pathname: "/JourneyBuilder/" + journey.id })}>
                                    <Row className="mb-3 d-flex align-items-center">
                                        <Col className='col-8 d-flex flex-column'>
                                            <h5>{journey.name}</h5>
                                            <span className='text-muted' >{journey.description}</span>
                                        </Col>
                                        <Col className='col-2'>
                                            {journey && journey.active ?
                                                <span className='d-flex align-items-center'>
                                                    <i className="fas fa-circle mr-2" style={{ color: '#0665ff', fontSize: '12px' }}></i>
                                                    <FormattedMessage id="CustomerJourney.Running" />
                                                </span>
                                            :
                                                <span className='d-flex align-items-center'>
                                                    <i className="fas fa-circle mr-2" style={{ color: 'grey', fontSize: '12px' }}></i>
                                                    <FormattedMessage id="CustomerJourney.Created" />
                                                </span>
                                            }
                                        </Col>
                                        <Col className="col-2">
                                            {moment(journey.updatedAt).format("YYYY-MM-DD - HH:mm")}
                                        </Col>
                                    </Row>
                                </Card>
                                <hr className='m-0' />
                            </div>
                        )}
                        <Row className="my-3">
                            <Col>
                                <ResultsPerPage
                                    changeSizePerPage={this.changePageSize}
                                    pageSize={pageSize}
                                />

                                <span className="ml-2">
                                    <TotalResults
                                        end={pageIndex * pageSize + journeyList.length}
                                        start={pageIndex * pageSize + 1}
                                        total={totalItems}
                                    />
                                </span>
                            </Col>

                            <Col className="text-right">
                                <Pagination
                                    isPrevDisabled={pageIndex === 0}
                                    isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                    currentPage={pageIndex + 1}
                                    handlePrevButton={() => this.handlePagination(-1)}
                                    handleNextButton={() => this.handlePagination(1)}
                                />
                            </Col>
                        </Row>
                    </>
                :
                    <DefaultPage text="generic.SelectSearchCriteria" icon="icon-icon-search" />}
            </StyledCard>
        )
    }
}

export default injectIntl(CustomerJourneyDashboard);
