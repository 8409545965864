import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Badge, Button, UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAddressTypeList } from '../Base/ReferenceDataFunctions';

import Authorization, { CheckAuthorization } from '../Base/Authorization';
import AddressDetails from './AddressDetails';
import { ProfileHistory } from '../Base/HistoryComponents';
import { KebabMenu } from '../Base/CommonUIComponents';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import { DefaultPage } from '../Base/CommonUIComponents';


export class ProfileAddresses extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            modal: false,
            selectedAddress: null,
            addressTypes: getAddressTypeList(this.props.intl),
            deleteModal: false
        }
    }

    toggleModal = (data) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedAddress: data
        }))
    }

    getAvailableTypes = () => {
        const { profile } = this.props;
        let types = getAddressTypeList(this.props.intl);

        if (profile.addresses && profile.addresses.length > 0) {
            if (this.state.selectedAddress) {
                types = this.state.addressTypes.filter(at => this.state.selectedAddress.type === at.value || profile.addresses && profile.addresses.some(pa => pa.type === at.value) === false);
            }
            else {
                types = this.state.addressTypes.filter(at => profile.addresses && profile.addresses.some(pa => pa.type === at.value) === false);
            }
        }

        return types;
    }

    toggleDeleteModal = (selectedAddress) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
            selectedAddress
        }));
    }

    removeAddress = () => {
        this.props.removeElement(this.props.profile.id, this.state.selectedAddress.id, 'address', 'addresses');

        this.toggleDeleteModal();
    }

    render() {
        const { profile, canEditProfile } = this.props;
        const { deleteModal } = this.state;

        return (
            <div className="m-2 p-2">
                <Row className="mb-2">
                    <Col>
                        <h5><i className="icon-icon-addresses mr-1"> </i><FormattedMessage id="ProfileDetails.ProfileAddress" /></h5>
                    </Col>
                    <Col className="text-right">
                        <Authorization
                            perform="profileAddress:add"
                            yes={() => (
                                <>
                                    <ProfileHistory
                                        component={"address"}
                                        profileId={this.props.profileId}
                                    />

                                    {canEditProfile &&
                                        <Button className="btn-sm btn-host ml-2" onClick={() => this.toggleModal(null)} disabled={profile.addresses && profile.addresses.length >= 2}>
                                            <i className="fas fa-plus" />
                                        </Button>
                                    }
                                </>
                            )}
                            no={() => <div></div>}
                        />
                    </Col>
                </Row>

                {profile.addresses && profile.addresses.map((address, key) => {
                    const type = address.type ? this.state.addressTypes.find(type => type.value === address.type) : null;

                    return <Card className="border-0 mb-2 shadow" key={key}>
                        <CardBody className="pt-2">
                            <Row>
                                <Col className="pl-2">
                                    <div style={{ cursor: 'pointer' }} onClick={() => { if (CheckAuthorization("profileAddress:add", null) && canEditProfile) this.toggleModal(address) }}>
                                        <Row>
                                            <Col className="col-2">
                                                <div>{address.country ? <i className={('mr-2 flag-icon flag-icon-') + (address.country.toLowerCase())}></i> : address.country} {address.locality}</div>
                                                <div>{type ? <Badge color="primary" >{type.label}</Badge> : ''}</div>
                                            </Col>
                                            <Col className="col-10">
                                                <Row>
                                                    <Col id={`AddressLine1-${address.id}`}>
                                                        <b><FormattedMessage id="ProfileDetails.AddressLine" /> 1: </b>  {address.addressLine1}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col id={`AddressLine2-${address.id}`}><b><FormattedMessage id="ProfileDetails.AddressLine" /> 2: </b> {address.addressLine2}</Col>
                                                </Row>
                                                <Row>
                                                    <Col id={`AddressLine3-${address.id}`}><b><FormattedMessage id="ProfileDetails.AddressLine" /> 3: </b>  {address.addressLine3}</Col>
                                                </Row>
                                                <Row>
                                                    <Col id={`AddressLine4-${address.id}`}><b><FormattedMessage id="ProfileDetails.AddressLine" /> 4: </b>  {address.addressLine4}</Col>
                                                </Row>
                                                <Row>
                                                    <Col id={`PostalCode-${address.id}`}><b><FormattedMessage id="ProfileDetails.PostalCode" />: </b> {address.postalCode}</Col>
                                                    <Col id={`Region-${address.id}`}><b><FormattedMessage id="ProfileDetails.Region" />: </b> {address.region}</Col>
                                                    <Col id={`Country-${address.id}`}><b><FormattedMessage id="ProfileDetails.Country" />: </b> {address.country}</Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Authorization
                                    perform="profileAddress:delete"
                                    yes={() => canEditProfile && (
                                        <Col className="text-right col-1 pr-2">
                                            <span onClick={() => this.toggleDeleteModal(address)} style={{ cursor: 'pointer' }}>
                                                <i className="fas fa-trash-alt" />
                                            </span>
                                        </Col>
                                    )}
                                    no={() => <div></div>}
                                />
                            </Row>
                        </CardBody>
                    </Card>
                })}

                {profile.addresses == null || profile.addresses.length == 0 ?                        
                    <DefaultPage text="ProfileDetails.NoAddressesAvailable" icon="icon-icon-addresses" />
                : ''}

                {this.state.modal ?
                    <AddressDetails
                        modal={this.state.modal}
                        toggleModal={this.toggleModal}
                        address={this.state.selectedAddress}
                        profileId={this.props.profileId}
                        updateTable={this.props.updateTable}
                        availableAddressType={this.getAvailableTypes()}
                    />
                    : ''}



                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={this.toggleDeleteModal}
                        actionFunction={this.removeAddress}
                        text={<FormattedMessage id="ProfileAddresses.ConfirmDeleteText" />}
                    />
                : ''}
            </div>
        );
    }
}

export default injectIntl(ProfileAddresses);
