import React, { Component } from 'react'
import { FormatAmountNumber, KebabMenu, StyledCard } from '../../Base/CommonUIComponents';
import { Badge, Button, Card, CardBody, Col, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import { getAPI, postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';


import ConfirmActionModal from '../../Base/ConfirmActionModal';
import TwilioSubAccountActionsModal from './TwilioSubAccountActionsModal';

class TwilioMessagingAdmin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: true,
            error: null,
            twilioConfig: null,
            deleteModal: false,
            selectedSubAccount: null,
            modal: false,
            pageSize: 10,
            pageIndex: 0
        }
    }

    componentDidMount(){
        this.getTwilioConfig();
    }

    getTwilioConfig = () => {
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                
                if(data.response && data.response.length > 0){
                    
                    const twilioConfig = data.response[0];

                    if(twilioConfig && twilioConfig.accounts){
                        twilioConfig.accounts.forEach(acc => {
                            const curr = acc.calls?.map(({priceUnit}) => priceUnit).filter(this.onlyUnique);
        
                            acc.sums = curr?.map((unit) => ({ unit, total: acc.calls.filter(({priceUnit}) => priceUnit === unit).reduce((a, b) => a + parseFloat(b.price), 0)})); 
                        });
                    }

                    this.setState({ twilioConfig, block: false });
                    return;
                }
            }
            this.setState({ block: false });
        }, `/api/gms/Twilio/v1/account`);
    }

    createSubAccount = (e, name) => {
        if (e) e.preventDefault();

        const modifiedName = name && name.normalize("NFD")?.replace(/[^\w\s]/gi, "");

        if (!modifiedName) {
            handleNotification({errors: [{message: <FormattedMessage id="TwilioMessaging.NameError" />}]});
            return;
        }

        this.setState({ block: true }, () => {
            postAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error, stack: error, messageType: "danger" });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    if (data.error) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }

                    if(data.errors){
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    
                    if(data.response){
                        handleNotification(data, <FormattedMessage id="TwilioMessaging.AccountSaved" />);

                        this.setState({ modal: !this.state.modal }, () => this.getTwilioConfig()); 
                        return;
                    }
                }
                this.setState({ block: false });
            }, `/api/gms/Twilio/v1/subaccount?name=${modifiedName}`);
        });
    }

    editSubAccount = (e, subAccount, name) => {
        if (e) e.preventDefault();

        const modifiedName = name && name.normalize("NFD")?.replace(/[^\w\s]/gi, "");

        if (!modifiedName) {
            handleNotification({errors: [{message: <FormattedMessage id="TwilioMessaging.NameError" />}]});
            return;
        }

        this.setState({ block: true }, () => {
            
            postAPI(result => {
                const { data, error } = result;
                
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error, stack: error, messageType: "danger" });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    if (data.errors) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    
                    if(data.response){
                        handleNotification(data, <FormattedMessage id="TwilioMessaging.AccountSaved" />);

                        this.setState({ modal: !this.state.modal }, () => this.getTwilioConfig()); 

                        return;
                    }
                }
                this.setState({ block: false });
            }, `/api/gms/Twilio/v1/subaccount/${subAccount}/edit?name=${modifiedName}`);
        });
    }

    setStatus = (accountSid, status) => {

        this.setState({ block: true }, () => {
        
            postAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data, <FormattedMessage id="TwilioMessaging.SubAccountStatusError" />);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    
                    if(data.response){

                        if(status === "closed"){
                            handleNotification(data, <FormattedMessage id="TwilioMessaging.SubAccountDeleted" />);
                            this.setState({ selectedSubAccount: null, deleteModal: false }, () => this.getTwilioConfig());
                        }else{
                            this.getTwilioConfig();
                        }

                        return;
                    }
                }
            }, `/api/gms/Twilio/v1/subaccount/${accountSid}/status?status=${status}`);
        });
    }

    onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }

    toggleModal = (subAccount) => {
        this.setState({selectedSubAccount: subAccount, modal: !this.state.modal });
    }

    toggleDeleteModal = (subAccount) => {
        this.setState({ selectedSubAccount: subAccount, deleteModal: !this.state.deleteModal });
    }

    render() {
        const { block, error, twilioConfig, deleteModal, modal, selectedSubAccount } = this.state;

        return (
            <>
                <StyledCard block={block} error={error}>
                    <Row>
                        <Col>
                            <h5>
                                <i className="fas fa-comments mr-1" />
                                <FormattedMessage id="TwilioMessaging.Title"/>
                            </h5>
                        </Col>
                        <Col className="text-right col-2">
                            <Button className="btn btn-sm btn-host" onClick={_ => this.toggleModal(null)}>
                                <i className="fas fa-plus"></i>
                            </Button>
                        </Col>
                    </Row>
                    <div className='mt-2'>
                        {twilioConfig?.accounts && twilioConfig.accounts.length > 0 ?
                            <div>
                                {twilioConfig.accounts.map((subUser, key) =>
                                    <Card key={key} className="border-0 shadow mt-2">
                                        <CardBody>
                                            <Row>
                                                <Col className='d-flex align-items-center'>
                                                    <div>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="TwilioMessaging.UserName"/>
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <b>{subUser.name}</b>
                                                            {subUser.status === "suspended" ?
                                                                <Badge color='secondary' className='ml-2'>
                                                                    <FormattedMessage id="TwilioMessaging.Disabled" />
                                                                </Badge>
                                                            :
                                                            subUser.status === "closed" ?
                                                                <Badge color='danger' className='ml-2'>
                                                                    <FormattedMessage id="TwilioMessaging.Closed" />
                                                                </Badge>
                                                            :
                                                            ''}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className='d-flex align-items-center'>
                                                    <div>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="TwilioMessaging.SID"/>
                                                        </div>
                                                        <div>{subUser.sid}</div>
                                                    </div>
                                                </Col>
                                                <Col className='d-flex align-items-center'>
                                                    <div>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="TwilioMessaging.CreatedAt"/>
                                                        </div>
                                                        <div>{moment(subUser.createdAt).format('YYYY-MM-DD')}</div>
                                                    </div>
                                                </Col>
                                                <Col className='d-flex align-items-center'>
                                                    <div>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="TwilioMessaging.Type"/>
                                                        </div>
                                                        <div>{subUser.type}</div>
                                                    </div>
                                                </Col>
                                                <Col className='d-flex align-items-center'>
                                                    <div>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="TwilioMessaging.Total"/>
                                                        </div>
                                                        <div className='veryCoolScrollBar pr-2' style={{ maxHeight: '40px', overflow: 'auto' }}>
                                                            {subUser.sums && subUser.sums.length > 0 ?
                                                                subUser.sums.map((s) =>
                                                                    <div className='mb-1'>
                                                                        <FormatAmountNumber currency={s.unit} value={s.total}/>
                                                                    </div>
                                                                )
                                                            :
                                                                <div>
                                                                    -
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className='col-2 d-flex align-items-center justify-content-end'>
                                                    {subUser.status !== "closed" ?
                                                        <KebabMenu
                                                            deleteFunction={_ => this.toggleDeleteModal(subUser)}
                                                            editFunction={_ => this.toggleModal(subUser)}
                                                            extraFields={[{
                                                                icon: "far fa-check-square mr-2",
                                                                text: `${subUser.status === 'active' ? 'TwilioMessaging.suspend' : 'TwilioMessaging.activate'}`,
                                                                function: _ => this.setStatus(subUser.sid, subUser.status === 'active' ? 'suspended' : 'active')
                                                            }]}
                                                        />
                                                    :''}
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                )}
                            </div>
                        : 
                        !block ?
                            <div className='text-muted text-center w-100 mt-2'>
                                <FormattedMessage id="TwilioMessaging.NoSubUsers"/>
                            </div>

                        : ''
                        }
                    </div>
                </StyledCard>

                {modal ? 
                    <TwilioSubAccountActionsModal 
                        block={block}
                        error={error}
                        toggleModal={this.toggleModal}
                        modal={modal}
                        selectedSubAccount={selectedSubAccount}
                        createSubAccount={this.createSubAccount}
                        editSubAccount={this.editSubAccount}
                    />
                : ''}

                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={_ => this.toggleDeleteModal(selectedSubAccount)}
                        actionFunction={() => this.setStatus(selectedSubAccount.sid, "closed")}
                        text={<FormattedMessage id="TwilioMessaging.deleteAction" values={{ name: selectedSubAccount.name }}  />}
                        block={block}
                    />
                : ''}
            </>
        )
    }
}

export default injectIntl(TwilioMessagingAdmin)
