import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Modal, ModalBody, Input, UncontrolledTooltip, CustomInput } from 'reactstrap';
import { BlankCard } from '../Base/CommonUIComponents';
import { handleNotification } from "../Base/Notification";
import { postAPI, deleteAPI, getAPI } from "../Base/API";
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import CustomSelect from '../Base/CustomSelect';

class CardDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            cardData: this.props.card ? this.props.card : { active: true },
            confirmActionModal: false,
            loyaltyLevels: this.props.loyaltyLevels,
            deleteModal: false
        };
    }

    componentDidMount() {
        this.getPrefixes();

        if (this.props.card && this.props.card.active === null) {
            this.setState({
                cardData: {
                    ...this.props.card,
                    active: true
                }
            })
        }
    }

    //Prefixes for source field
    getPrefixes = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                const prefixes = [];

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.response && data.response.length > 0) {
                    data.response.forEach(prefix => {
                        prefixes.push({
                            value: prefix.id,
                            label: `${prefix.code} (${prefix.description})`,
                            code: prefix.code
                        });
                    });
                }

                this.setState({ prefixes, error: errorMessage, block: false });
            }
            else this.setState({ error: errorMessage, block: false });
        }, '/api/gms/LoyaltyCard/v1/HotelGroupLoyaltyCardConfigPrefix');
    }

    getNextNumber = () => {
        this.setState({ block: true });

        const param = this.state.cardData?.membershipSourceId ? `?complexPrefixId=${this.state.cardData.membershipSourceId}` : '';

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false, confirmActionModal: false });
                }
                else if (data.response && data.response.length > 0) {
                    let validFrom = null, validTo = null;

                    if (data.response[0].vality) {
                        validFrom = moment().format("YYYY-MM-DD");
                        validTo = moment().add(data.response[0].vality, 'months').format("YYYY-MM-DD");
                    }

                    const level = this.state.loyaltyLevels.find(lv => lv.code === data.response[0].code);
                    const newType = this.props.loyaltyTypes?.find(t => t.code === data.response[0].loyaltyCardTypeConfigCode && !t.disabled);

                    this.setState(prevState => ({
                        block: false,
                        confirmActionModal: false,
                        cardData: {
                            ...prevState.cardData,
                            cardNumber: data.response[0].number,
                            membershipTypeId: newType?.value,
                            membershipTypeCode: newType?.code,
                            cardTypeCode: level ? level.code : null,
                            cardTypeDescription: level ? level.description : null,
                            validFrom,
                            validTo
                        }
                    }))
                }
            }
            else this.setState({ error: errorMessage, block: false });
        }, '/api/gms/LoyaltyCard/v1/NextLoyaltyCardNumber' + param);
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            cardData: {
                ...this.state.cardData,
                [name]: value
            }
        })
    }

    handleSource = (combo) => {
        this.setState({
            cardData: {
                ...this.state.cardData,
                membershipSourceId: combo ? combo.value : null,
                membershipSourceCode: combo ? combo.code : null,
            }
        })
    };

    handleType = (combo) => {
        this.setState({
            cardData: {
                ...this.state.cardData,
                membershipTypeId: combo ? combo.value : null,
                membershipTypeCode: combo ? combo.code : null,
            }
        })
    };

    handleLevel = (combo) => {
        this.setState({
            cardData: {
                ...this.state.cardData,
                cardTypeCode: combo ? combo.code : null,
                cardTypeDescription: combo ? combo.description : null,
            }
        })
    }

    handleSwitch = (evt) => {
        if (evt) {
            const { name, checked } = evt.target;

            this.setState({
                cardData: {
                    ...this.state.cardData,
                    [name]: checked
                }
            });
        }
    }

    toggleModal = (modal) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }));
    }

    saveCard = (e) => {
        e.preventDefault();
        this.setState({ block: true }, () => {
            const { profileId, updateTable, selectMembership, toggleModal } = this.props;
            var card = { ...this.state.cardData };
            card.profileId = profileId;

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    if (data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="CardDetails.CardSaved" />, <FormattedMessage id="generic.success" />);
                        updateTable(data.response[0], 'membershipCard');
                        selectMembership(data.response[0]?.id);
                        toggleModal();
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/Profile/v1/profile/membershipCard`, JSON.stringify({ request: card }));
        });
    }

    removeCard = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="CardDetails.CardRemoved" />, <FormattedMessage id="generic.success" />);
                    this.props.removeFromTable(this.props.card.id, 'membershipCard');
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/profile/${this.props.card.id}/membershipCard`);
    }

    render() {
        const { hasOneCardByType, loyaltyTypes } = this.props;
        const { block, error, cardData, prefixes, loyaltyLevels, deleteModal } = this.state;

        const selectedLevel = loyaltyLevels.find(ll => ll.code === cardData.cardTypeCode);

        return (
            <div>
                <Modal isOpen={this.props.modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                    <Button onClick={this.props.toggleModal} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    <ModalBody>
                        <BlankCard block={block} error={error}>
                            <Form onSubmit={this.saveCard}>
                                <Row className="mb-3">
                                    <Col>
                                        <h5>{this.props.card ? <FormattedMessage id="CardDetails.EditCard" /> : <FormattedMessage id="CardDetails.AddCard" />}</h5>
                                    </Col>
                                    <Col className="text-right">
                                        {global.modules && global.modules.some(m => m === 'Loyalty') ?
                                            <>
                                                <Button className="btn-sm btn-host mr-2" disabled={!cardData.membershipSourceCode} onClick={this.state.cardData?.cardNumber ? () => this.toggleModal('confirmActionModal') : this.getNextNumber} id="getNextLoyaltyCardNumber">
                                                    <i className="fas fa-hashtag" />
                                                </Button>
                                                <UncontrolledTooltip placement="bottom" target="getNextLoyaltyCardNumber" >
                                                    <FormattedMessage id="CardDetails.GetNextLoyaltyCardNumber" />
                                                </UncontrolledTooltip>
                                            </>
                                            : ''}
                                        {this.props.card ?
                                            <Button className="btn-sm btn-host mr-2" onClick={() => this.toggleModal('deleteModal')} >
                                                <i className="fas fa-trash-alt" />
                                            </Button>
                                            : ''}
                                        <Button className="btn-sm btn-host" type="submit"> <i className="fas fa-save" /></Button>
                                    </Col>
                                </Row>

                                <FormGroup row>
                                    <Col className="col-6" style={{ display: hasOneCardByType ? '' : 'none' }}>
                                        <Row>
                                            <Label sm={4}> <FormattedMessage id="CardDetails.MembershipTypeCode" /> </Label>
                                            <Col sm={8}>
                                                <CustomSelect
                                                    options={loyaltyTypes}
                                                    value={loyaltyTypes.find(tp => tp.code === cardData.membershipTypeCode) || ''}
                                                    isSearchable
                                                    isClearable={true}
                                                    placeholder={''}
                                                    onChange={(e) => this.handleType(e)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Label sm={2}> <FormattedMessage id="generic.active" /></Label>
                                    <Col sm={4} className="d-flex align-items-center justify-content-start">
                                        <CustomInput
                                            type="switch"
                                            id="active"
                                            name="active"
                                            onChange={this.handleSwitch}
                                            checked={cardData.active ? cardData.active : false}
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup row>
                                    <Label sm={2}> <FormattedMessage id="CardDetails.CardTypeCode" /> </Label>
                                    <Col sm={4}>
                                        <CustomSelect
                                            options={cardData.membershipTypeCode != null ? loyaltyLevels && loyaltyLevels.filter(p => p.type == cardData.membershipTypeCode || p.type == null) : loyaltyLevels}
                                            value={selectedLevel || (cardData.cardTypeCode ? { value: cardData.cardTypeCode, label: `${cardData.cardTypeCode} (${cardData.cardTypeDescription})` } : '')}
                                            isSearchable
                                            required
                                            placeholder={''}
                                            onChange={(e) => this.handleLevel(e)}
                                            isLoading={this.state.blockLevels}
                                        />
                                    </Col>
                                    <Label sm={2}> <FormattedMessage id="CardDetails.MembershipSourceCode" /> </Label>
                                    <Col sm={4}>
                                        <CustomSelect
                                            options={prefixes}
                                            value={prefixes?.find(p => p.code === cardData.membershipSourceCode) || ''}
                                            isSearchable
                                            placeholder={''}
                                            onChange={(e) => this.handleSource(e)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={2}> <FormattedMessage id="CardDetails.CardNumber" /> </Label>
                                    <Col sm={4}>
                                        <Input
                                            type="text"
                                            name="cardNumber"
                                            onChange={(e) => this.handleChange(e)}
                                            value={cardData.cardNumber ? cardData.cardNumber : ''}
                                            required
                                            maxLength="50"
                                        />
                                    </Col>
                                    <Label sm={2}> <FormattedMessage id="CardDetails.Validity" /> </Label>
                                    <Col sm={4}>
                                        <DateRangePicker
                                            startDate={this.state.cardData && this.state.cardData.validFrom ? moment(this.state.cardData.validFrom) : null}
                                            startDateId="startDate"
                                            isOutsideRange={day => false}
                                            endDate={this.state.cardData && this.state.cardData.validTo ? moment(this.state.cardData.validTo) : null}
                                            endDateId="endDate"
                                            onDatesChange={({ startDate, endDate }) => this.setState({ cardData: { ...this.state.cardData, validFrom: startDate, validTo: endDate } })}
                                            focusedInput={this.state.focusedInput}
                                            onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                            small={true}
                                            numberOfMonths={1}
                                            showDefaultInputIcon={true}
                                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                            required
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={2}> <FormattedMessage id="ProfileDetails.Notes" /> </Label>
                                    <Col sm={4}>
                                        <Input
                                            type="textarea"
                                            name="notes"
                                            onChange={(e) => this.handleChange(e)}
                                            value={cardData.notes ? cardData.notes : ''}
                                        />
                                    </Col>
                                    <Label sm={2}> <FormattedMessage id="CardDetails.Points" /> </Label>
                                    <Col sm={4}>
                                        <Input type="number" name="points" readOnly onChange={(e) => this.handleChange(e)} value={cardData.points ? cardData.points : ''} />
                                    </Col>
                                </FormGroup>
                            </Form>
                        </BlankCard>
                    </ModalBody>
                </Modal>

                {this.state.confirmActionModal || deleteModal ?
                    <ConfirmActionModal
                        modal={this.state.confirmActionModal || deleteModal}
                        toggleModal={() => this.toggleModal(deleteModal ? 'deleteModal' : 'confirmActionModal')}
                        actionFunction={deleteModal ? this.removeCard : this.getNextNumber}
                        text={deleteModal ? <FormattedMessage id="CardDetails.ConfirmDeleteText" /> : <FormattedMessage id="CardDetails.ReplaceCardInfo" />}
                        block={block}
                    />
                    : ''}
            </div>
        );
    }
}
export default injectIntl(CardDetails)