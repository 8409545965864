import React, { Component } from 'react'
import { Button, Modal, ModalBody, Col, Row, Input, CustomInput } from 'reactstrap'
import { FormattedMessage, injectIntl } from 'react-intl'
import { RenderTemplateSelection } from '../Common/CommunicationFunctions';
import { getAPI } from '../../Base/API';
import { StyledCard } from '../../Base/CommonUIComponents';
import { FormsWithTitleSelect, ReviewProFormsSelect } from '../Common/MarketingFunctions';
import { getLicenseModules } from '../../Base/ReferenceDataFunctions';

import CustomSelect from '../../Base/CustomSelect'

class ConfigActivities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            senders: [],
            surveyProviderOptions: [],
            marketingType: null,
            hasEgoi: false,
            hasSendGrid: false,
            emailTemplateModal: false,
            smsTemplateModal: false,
            hasTwilio: false,
            block: false,
            error: null
        }
    }

    componentDidMount() {
        this.timeoutId = this.initializeSurveyProviders();
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.selectedNode && this.props.selectedNode && prevProps.selectedNode.typeUnchanged !== this.props.selectedNode.typeUnchanged) {
            clearTimeout(this.timeoutId);
            this.timeoutId = this.initializeSurveyProviders();
        }
    }
    
    componentWillUnmount() {
        clearTimeout(this.timeoutId);
    }
    
    initializeSurveyProviders = () => {
        this.setState({ block: true }, () => {
            const timeoutId = window.setTimeout(() => {
                let { surveyProviderOptions } = this.state;
                const { intl, secondaryType } = this.props;
    
                const globalModules = global.modules || [];
                const hasModule = (name) => globalModules.some(m => m === name);
    
                const hasEgoi = hasModule('Egoi');
                const hasSendGrid = hasModule('SendGrid');
                const hasTwilio = hasModule('TwilioMessaging');
                const hasProfileNowModule = hasModule('ReviewPro');
                const hasReviewProModule = hasModule('ProfileNowForms');
                const hasTypeFormModule = hasModule('Typeform');
    
                if (surveyProviderOptions && surveyProviderOptions.length > 0) {
                    surveyProviderOptions = [];
                }
    
                if (hasReviewProModule) {
                    surveyProviderOptions.push(getLicenseModules(intl)[3].options[0]);
                }
                if (hasProfileNowModule) {
                    surveyProviderOptions.push(getLicenseModules(intl)[7].options[0]);
                }
                if (hasTypeFormModule) {
                    surveyProviderOptions.push(getLicenseModules(intl)[7].options[1]);
                }
    
                this.setState({ hasEgoi, hasSendGrid, hasTwilio, surveyProviderOptions }, () => {
                    if (secondaryType === 'email') {
                        if (hasEgoi) {
                            this.getEgoiSenders('Email');
                        } else if (hasSendGrid) {
                            this.getSendGridSenders();
                        }
                    } else if (secondaryType === 'sms') {
                        if (hasEgoi) {
                            this.getEgoiSenders('SMS');
                        } else if (hasTwilio) {
                            this.getTwilioSenders();
                        }
                    } else {
                        this.setState({ block: false });
                    }
                });
            }, global.modules && this.props.secondaryType ? 0 : 2500);
            
            this.timeoutId = timeoutId;
        });
    
        return this.timeoutId;
    }

    getSendGridSenders() {
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ errorSenders: errorMessage, block: false });
                    return;
                }
                if (data && data.response && data.response.results && data.response.results.length > 0) {
                    const senders = data.response.results
                        .filter(resp => resp.verified)
                        .map(resp => {
                            const sender = {
                                value: resp.id,
                                label: `${resp.from_name} (${resp.from_email})`,
                                name: resp.from_name
                            };
                            return sender;
                        });
                    this.setState({ errorSenders: errorMessage, block: false, senders: senders });
                }
                else {
                    this.setState({ errorSenders: errorMessage, block: false });
                }
            }, `/api/gms/SendGrid/v1/sender?limit=100`);
        });
    }

    getEgoiSenders = (channel) => {
        const type = channel === 'Email' ? 'email' : 'cellphone';
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    this.setState({
                        senders: data.items ? data.items : null,
                    });
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/Marketing/v1/egoi/senders/${type}?limit=${this.state.limit}&offset=${this.state.offset}`);
        });
    }

    getTwilioSenders = () => {
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ errorSenders: errorMessage, block: false });
                    return;
                }
                if (data && data.response && data.response.services && data.response.services.length > 0) {
                    const senders = data.response.services
                        .map(resp => {
                            const sender = {
                                value: resp.sid,
                                label: resp.friendly_name
                            };
                            return sender;
                        });
                    this.setState({ errorSenders: errorMessage, block: false, senders: senders });
                }
                else {
                    this.setState({ errorSenders: errorMessage, block: false });
                }
            }, `/api/gms/Twilio/v1/service`);
        });
    }

    initializeTemplates = (selectedNode) => {
        if (!selectedNode.metaData) {
            selectedNode.metaData = {};
        }
        selectedNode.metaData.templates = selectedNode.metaData.templates ?? [];
        selectedNode.metaData.type = this.props.secondaryType;
    }

    handleTemplates = (templateId, countries, data, templateName) => {
        const { selectedNode, handleNodesChange } = this.props;
        if (!selectedNode) return;
    
        this.initializeTemplates(selectedNode);
    
        const templateIdx = selectedNode.metaData.templates.findIndex(el => el === data);
    
        if (templateIdx !== -1) {
            if (templateId) {
                selectedNode.metaData.templates[templateIdx].templateId = templateId;
            }
    
            if (countries !== undefined) {
                selectedNode.metaData.templates[templateIdx].countries = countries;
            }

            if (templateName) {
                selectedNode.metaData.templates[templateIdx].templateName = templateName;
            }
        }
    
        handleNodesChange(selectedNode);
    }
    
    addTab = () => {
        const { selectedNode } = this.props;
        if (!selectedNode) return;
    
        this.initializeTemplates(selectedNode);
        
        selectedNode.metaData.templates.push({ isDefault: false });
        this.props.handleNodesChange(selectedNode);
    }
    
    removeTab = (data) => {
        const { selectedNode } = this.props;
        if (!selectedNode || !selectedNode.metaData || !selectedNode.metaData.templates) return;

        selectedNode.metaData.templates = selectedNode.metaData.templates.filter(el => el !== data);
        this.props.handleNodesChange(selectedNode);
    }
    
    handleDefaultTemplate = (templateId, templateName) => {
        const { selectedNode, handleNodesChange } = this.props;
        if (!selectedNode) return;
    
        this.initializeTemplates(selectedNode);
    
        const templateIndex = selectedNode.metaData.templates.findIndex(el => el.isDefault);
        
        if (templateIndex !== -1) {
            selectedNode.metaData.templates[templateIndex].templateId = templateId;
            selectedNode.metaData.templates[templateIndex].templateName = templateName;
        } else {
            selectedNode.metaData.templates.push({ templateId, isDefault: true, countries: [], templateName });
        }
    
        handleNodesChange(selectedNode);
    }

    toggleTemplateModal = (templateModalName) => {
        this.setState({ [templateModalName]: !this.state[templateModalName] });
    }

    handleSwitch = (evt) => {
        const { name, checked } = evt.target;
        const { selectedNode } = this.props;

        if (!selectedNode) return;
        this.initializeTemplates(selectedNode);
        selectedNode.metaData.marketingType = checked ? 'CustomInquiry' : null;

        if(!checked){
            selectedNode.metaData.surveyProvider = null;
            selectedNode.metaData.surveyId = null;
            selectedNode.metaData.propertyId = null;
        }

        this.setState({ marketingType: checked }, () => this.props.handleNodesChange(selectedNode));
    }

    handleText = (evt) => {
        const { name, value } = evt.target;
        const { selectedNode } = this.props;

        if (!selectedNode) return;
        this.initializeTemplates(selectedNode);
        selectedNode.metaData[name] = value;

        this.props.handleNodesChange(selectedNode);
    }

    render() {
        const { selectedNode, secondaryType } = this.props;
        const { senders, hasEgoi, hasSendGrid, hasTwilio, emailTemplateModal, smsTemplateModal, surveyProviderOptions, block, error } = this.state;

        return (
            <StyledCard block={block} error={error}>
                <>
                    <Row className={selectedNode && selectedNode.metaData && selectedNode.metaData.marketingType === 'CustomInquiry' ? 'pb-3' : ''}>
                        <Col>
                            <FormattedMessage id="Campaigns.Sender" />
                        </Col>
                        <Col className='col-12 pt-1'>
                            <CustomSelect 
                                name="sender" 
                                options={senders} 
                                value={selectedNode && selectedNode.metaData && senders && senders.length > 0 && senders.find(s => s.value === selectedNode.metaData.sender) ? senders.find(s => s.value === selectedNode.metaData.sender) : ''} 
                                onChange={(value) => this.props.handleSelect(value, 'sender')} 
                                required 
                            />
                        </Col>
                        <Col className='mt-3 col-12 d-flex align-items-center justify-content-between'>
                            <div>
                                <FormattedMessage id="CustomerJourney.IncludeForm" />
                            </div>
                            <div className='pt-1'>
                                <CustomInput
                                    type="switch"
                                    id="marketingType"
                                    name="marketingType"
                                    onChange={this.handleSwitch}
                                    checked={(selectedNode && selectedNode.metaData && selectedNode.metaData.marketingType === 'CustomInquiry') || false}
                                />
                            </div>
                        </Col>
                        {selectedNode && selectedNode.metaData && selectedNode.metaData.marketingType === 'CustomInquiry' ?
                            <Col className='mt-3'>
                                <Row>
                                    <Col sm={selectedNode.metaData.surveyProvider === "ReviewPro" ? 4 : 6}>
                                        <FormattedMessage id="Campaigns.Provider" />
                                    </Col>
                                    <Col sm={selectedNode.metaData.surveyProvider === "ReviewPro" ? 4 : 6}>
                                        <FormattedMessage id="Campaigns.Form" />
                                    </Col>
                                    {selectedNode.metaData.surveyProvider === "ReviewPro" ?
                                        <Col sm={4}>
                                            <FormattedMessage id="Campaigns.PmsId" />
                                        </Col>
                                    :''}
                                </Row>
                                <Row className='mt-2'>
                                    <Col sm={selectedNode.metaData.surveyProvider === "ReviewPro" ? 4 : 6}>
                                        <CustomSelect 
                                            options={surveyProviderOptions} 
                                            onChange={(value) => this.props.handleSelect(value, 'surveyProvider')} 
                                            value={surveyProviderOptions.find(opt => opt.value === selectedNode.metaData.surveyProvider)} 
                                        />
                                    </Col>
                                    {selectedNode.metaData.surveyProvider === "ReviewPro" ?
                                        <Col className="col-8 d-flex align-items-center px-0">
                                            <Col sm={6}>
                                                <ReviewProFormsSelect
                                                    id="ReviewProFormsSelectReviewPro"
                                                    key="ReviewProFormsSelectReviewPro"
                                                    onChangeFunc={(value) => this.props.handleSelect(value, 'surveyId')}
                                                    value={selectedNode.metaData.surveyId}
                                                    url={`/api/gms/ReviewPro/v1/Surveys`}
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Input 
                                                    type="text" 
                                                    id="propertyId" 
                                                    name="propertyId" 
                                                    value={selectedNode.metaData.propertyId} 
                                                    onChange={this.handleText} 
                                                />
                                            </Col>
                                        </Col>
                                    :
                                        selectedNode.metaData.surveyProvider === "ProfileNowForms" ?
                                            <Col className='col-6'>
                                                <ReviewProFormsSelect
                                                    id="ReviewProFormsSelectProfileNow"
                                                    key="ReviewProFormsSelectProfileNow"
                                                    onChangeFunc={(value) => this.props.handleSelect(value, 'surveyId')}
                                                    value={selectedNode.metaData.surveyId}
                                                    url={`/api/gms/Survey/v1/survey?hotelGroup=${global.hotelGroupId}&all=true`}
                                                />
                                            </Col>
                                        :
                                            selectedNode.metaData.surveyProvider === "Typeform" ?
                                                <Col className='col-6'>
                                                    <FormsWithTitleSelect
                                                        id="FormsWithTitleSelectTypeForm"
                                                        key="FormsWithTitleSelectTypeForm"
                                                        onChangeFunc={(value) => this.props.handleSelect(value, 'surveyId')}
                                                        value={selectedNode.metaData.surveyId}
                                                        url={`/api/gms/TypeForm/Forms?page=1&pageSize=200`}
                                                    />
                                                </Col>
                                            :
                                                <Col className='col-6'>
                                                    <CustomSelect options={[]} />
                                                </Col>
                                    }
                                </Row>
                            </Col>
                            :''}
                    </Row>
                    <Row className='mt-3 d-flex align-items-center justify-content-between'>
                        <Col>
                            <FormattedMessage id="CustomerJourney.Templates" />
                        </Col>
                        <Col>
                            <Button className="btn btn-host btn-sm mr-1 d-flex align-items-center" onClick={() => this.toggleTemplateModal(secondaryType === 'email' ? 'emailTemplateModal' : 'smsTemplateModal')}>
                                <i className="far fa-envelope mr-2"></i><FormattedMessage id="CustomerJourney.SelectTemplate" />
                            </Button>
                        </Col>
                    </Row>
                    <hr />
                    {selectedNode && selectedNode.metaData && selectedNode.metaData.templates && selectedNode.metaData.templates.length > 0 ?
                        <Row  className='mt-3 pl-2' style={{ overflow: 'auto', maxHeight: '350px' }}>
                            {selectedNode.metaData.templates.map((template, idx) => 
                                <Col className='col-12 mt-2' key={idx}>
                                    {template.isDefault ? 
                                        <li>Default - {template.templateName}</li>
                                        
                                    :
                                        template && template.countries && template.countries.length > 0 && template.countries[0] ?
                                            <li><span className={`flag-icon flag-icon-${template && template.countries && template.countries.length > 0 && template.countries[0] && template.countries[0].toLowerCase()} mb-2 px-2`} /> - {template.templateName}</li>
                                        :
                                            <li><b>NA</b> - {template.templateName}</li>
                                    }
                                </Col>
                            )}
                        </Row>
                    : 
                        <div />
                    }
                    
                    {secondaryType === 'email' ?
                        (hasEgoi || hasSendGrid ) && emailTemplateModal ?
                            <Modal isOpen={emailTemplateModal} fade={false} className="modal-xl" >
                                <Button onClick={() => this.toggleTemplateModal('emailTemplateModal')} className="closeModal">
                                    <i className="fas fa-times fa-sm cursor-pointer"></i>
                                </Button>
                                <ModalBody>
                                    <RenderTemplateSelection
                                        hasEgoi={hasEgoi}
                                        hasSendGrid={hasSendGrid}
                                        campaignTemplates={selectedNode && selectedNode.metaData && selectedNode.metaData.templates}
                                        handleTemplates={this.handleTemplates}
                                        addTab={this.addTab}
                                        removeTab={this.removeTab}
                                        handleDefaultTemplate={this.handleDefaultTemplate}
                                        senderChannel={'Email'}
                                        isJourneyBuilder={true}
                                        toggle={() => this.toggleTemplateModal('emailTemplateModal')}
                                    />
                                </ModalBody>
                            </Modal>
                    
                        : 
                            <div />
                    :
                        (hasEgoi || hasTwilio ) && smsTemplateModal ?
                            <Modal isOpen={smsTemplateModal} fade={false} className="modal-xl">
                                <Button onClick={() => this.toggleTemplateModal('smsTemplateModal')} className="closeModal">
                                    <i className="fas fa-times fa-sm cursor-pointer"></i>
                                </Button>
                                <ModalBody>
                                    <RenderTemplateSelection
                                        hasEgoi={hasEgoi}
                                        hasTwillio={hasTwilio}
                                        campaignTemplates={selectedNode && selectedNode.metaData && selectedNode.metaData.templates}
                                        handleTemplates={this.handleTemplates}
                                        addTab={this.addTab}
                                        removeTab={this.removeTab}
                                        handleDefaultTemplate={this.handleDefaultTemplate}
                                        senderChannel={'SMS'}
                                        isJourneyBuilder={true}
                                        toggle={() => this.toggleTemplateModal('smsTemplateModal')}
                                    />
                                </ModalBody>
                            </Modal>
                    
                        : ''
                    }
                </>
            </StyledCard>
        )
    }
}

export default injectIntl(ConfigActivities);