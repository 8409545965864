import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { injectIntl } from 'react-intl';
import { Button, Card, Col, Row, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { StyledCard } from "../Base/CommonUIComponents";
import { getAPI, postAPI } from "../Base/API";
import { handleNotification } from "../Base/Notification";
import { FormattedMessage } from "react-intl";

export class EgoiServiceActivation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            clientSettings: null
        };
    }

    componentDidMount() {
    }


    getClientSettings = () => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response) {
                    this.setState({ clientSettings: data.response });
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoitransactional/client/setting`);
    }

    getCheckClientEnabled= () => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                if (data.status) {
                    handleNotification(data, <FormattedMessage id="EgoiServiceActivation.ClientStatus" values={{status: data.status.toLowerCase()}}/>, <FormattedMessage id="generic.success" />);
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoitransactional/client/status`);
    }

    activateShortner= () => {
        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                if (data.data && data.data === 'ok') {
                    handleNotification(data, <FormattedMessage id="EgoiServiceActivation.ActivateShortner" />, <FormattedMessage id="generic.success" />);
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoitransactional/client/activate`);
    }
    
    activateTRansactional = () => {
        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                else {
                    handleNotification(data, <FormattedMessage id="EgoiServiceActivation.TransactionalEmailEnabled" />, <FormattedMessage id="generic.success" />);
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoitransactional/client/setting`);
    }
    

    render() {
        const { block, error } = this.state;

        return (
            <StyledCard block={block} error={error} >
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/MarketingConfigEgoi"> Config</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Service Activation</BreadcrumbItem>
                    </Breadcrumb>
                </div>


                <Row className="mb-2">
                    <Col><h4>Service Activation</h4></Col>
                </Row>

                <Row className="mb-1">
                    <Col >
                        <h5><Button className="btn btn-sm btn-host mr-2" onClick={this.getClientSettings.bind(this)}>
                            <i className="fa fa-check-circle"></i>
                        </Button>
                        Check Client Status </h5>
                    </Col>
                    <Col >
                        <h5><Button className="btn btn-sm btn-host mr-2" onClick={this.getCheckClientEnabled.bind(this)} >
                            <i className="fa fa-check-circle"></i>
                        </Button>
                        Check if Client is Enabled </h5>
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col >
                        <h5><Button className="btn btn-sm btn-host mr-2" onClick={this.activateTRansactional.bind(this)}>
                            <i className="fa fa-toggle-on"></i>
                        </Button>
                        Enable Transactional email</h5>
                    </Col>
                    <Col >
                        <h5><Button className="btn btn-sm btn-host mr-2" onClick={this.activateShortner.bind(this)}>
                            <i className="fa fa-toggle-on"></i>
                        </Button>
                        Enable Client Shortner </h5>
                    </Col>
                </Row>

                {this.state.clientSettings ?
                    <Card className="border-0 mt-4 shadow" body>
                        <Row className="mb-2">
                            <Col><h5> Client Status </h5></Col>
                        </Row>

                        <Row className="mb-2 px-3">
                            <Col><b> Config </b></Col>
                            <Col><b> Config Data </b></Col>
                        </Row>

                        {this.state.clientSettings.map((el, key) =>
                            <Row key={key} className="overbglight py-1 px-3">
                                <Col> {el.config} </Col>
                                <Col> {el.configData} </Col>
                            </Row>
                        )}
                    </Card>
                : ''}
                
            </StyledCard>
        );
    }
}
export default injectIntl(EgoiServiceActivation)
