import React, { useEffect, useState, useRef } from 'react';
import { Card, CardBody, CardHeader, UncontrolledPopover, PopoverBody, Badge, Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Modal, ModalBody, UncontrolledTooltip, Collapse } from 'reactstrap';
import { FormattedMessage, FormattedNumber, FormattedHTMLMessage } from 'react-intl';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "./ErrorAlert";
import CustomSelect from "./CustomSelect";
import { getHelpLink } from "./ReferenceDataFunctions"
import { getAPI } from "./API"
import moment from 'moment-timezone';
import { CheckAuthorization } from './Authorization';
import { HandleHelp, HandleHelpClick } from '../../Utils/EventGA';
import Chart from "react-google-charts";
import CustomToolTip from './CustomToolTip';
import { authContext } from '../../Utils/AdalConfig';
import { exportElementToPDF } from '../Marketing/CommunicationJourney/CommonFunctions';

export const PageMainCard = ({ block, error, children, icon, title, help, hasCard }) => (
    <BlockUi tag="div" blocking={block}>
        <Card>
            <CardBody>
                <ErrorAlert error={error} />
                <Card className="shadow border-0">
                    <CardHeader className="bg-white border-bottom ">
                        {icon ? <i className={icon + ' mr-1'}></i> : ''} {title ? <FormattedMessage id={title} /> : ''}
                        {
                            help ?
                                <div className="float-right">
                                    <span id="mainpagehelp" className="btn-sm btn-link" onClick={() => true} >
                                        <i className="text-secondary fas fa-question-circle mt-2 "></i>
                                    </span>
                                    <UncontrolledPopover data-trigger="focus" placement="left" target="mainpagehelp">
                                        <PopoverBody>
                                            {help}
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                </div>
                                :
                                ''
                        }
                    </CardHeader>
                    <CardBody>
                        {children}
                    </CardBody>
                </Card>
            </CardBody>
        </Card>
    </BlockUi>
);

export const StyledCard = ({ block, children, icon, title, help, error, id }) => (
    <div className="border-0 h-100 py-2">
        <ErrorAlert error={error} />
        <BlockUi tag="div" blocking={block}>
            <CardHeader className="bg-light border-0 p-0 pl-1">
                <Row>
                    <Col>
                        <h5>{icon ? <i className={icon + ' mr-1'}></i> : ''} {title ? <FormattedMessage id={title} /> : ''}</h5>
                    </Col>
                    <Col className="text-right col-1">
                        { help && help.length > 0 ?
                            <div className="float-right">
                                <span id={id} className="btn-sm btn-link h-100" onClick={() => true} style={{ fontSize: 'medium' }}>
                                    <i className="text-secondary fas fa-question-circle" />
                                </span>
                                <UncontrolledPopover data-trigger="focus" placement="left" target={id}>
                                    <PopoverBody>
                                        {help}
                                        {getHelpLink(id) ?
                                            <a className="float-right" href={getHelpLink(id)}>
                                                <FormattedMessage id="CommonUIComponents.MoreDetails" />
                                            </a>
                                        : '' }
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </div>
                        : ''}
                    </Col>
                </Row>
            </CardHeader>
            
                {children}
        </BlockUi>
    </div>
);

export const PrettyTitle = ({ name, size, id, isHelp }) => (
    <div className="addPromptConfig d-flex align-items-center">
        {size === "big" ?
            <h5><FormattedMessage id={`ConfigCardPoints.${name}`}/></h5>
        : size === "small" ?
            <FormattedMessage id={`ConfigCardPoints.${name}`}/>
        :
            <b><FormattedMessage id={`ConfigCardPoints.${name}`}/></b>
        }
        <div id={id} className='ml-2 text-muted cursor-pointer add' style={{ fontSize: '0.8em' }}>
            {isHelp ?
                <i className="fas fa-question"/>
            :
                <i className="fas fa-plus"/>
            }
        </div>
        {isHelp ?
            <UncontrolledTooltip style={{ maxWidth: '200px' }} data-trigger="focus" flip={false} placement="right" target={id}>
                <FormattedMessage id={`ConfigCardPoints.${id}`}/>
            </UncontrolledTooltip>
        :''}
    </div>
);

export const KebabMenu = ({ previewFunction, editFunction, deleteFunction, downloadFunction, extraFields, deleteText, direction }) => (
    <UncontrolledDropdown direction={direction}>
        <DropdownToggle style={{ background: 'none', border: 'none', color: 'unset', padding: 'unset', width: '10px' }} onClick={(e) => e.stopPropagation()}>
            <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        
        <DropdownMenu positionFixed={true}>
            {extraFields ?
                extraFields.map((field, key) =>
                    <DropdownItem key={key} onClick={field.function}>
                        <i className={field.icon} />
                        {field.noTranslation ?
                            field.textClass ?
                                <span className={field.textClass}>
                                    {field.text}
                                </span>
                            :
                                field.text
                        :
                            field.textClass ?
                                <span className={field.textClass}>
                                    <FormattedMessage id={field.text} />
                                </span>
                            :
                                <FormattedMessage id={field.text} />
                            }
                    </DropdownItem>
                )
            :''}

            {previewFunction ?
                <DropdownItem onClick={previewFunction}>
                    <i className="icon-icon-preview mr-2" />
                    <FormattedMessage id="generic.Preview" />
                </DropdownItem>
            : ''}

            {editFunction ?
                <DropdownItem onClick={editFunction}>
                    <i className="fas fa-edit mr-2" />
                    <FormattedMessage id="generic.Edit" />
                </DropdownItem>
                : ''}
            {downloadFunction ?
                <DropdownItem onClick={downloadFunction}>
                    <i className="fas fa-file-download mr-2" />
                    <FormattedMessage id="generic.download" />
                </DropdownItem>
                : ''}

            {deleteFunction ?
                <DropdownItem onClick={deleteFunction} style={{ color: '#A80C19' }}>
                    <i className="mr-2 far fa-trash-alt" />
                    <FormattedMessage id={deleteText ?? "generic.Delete"}/>
                </DropdownItem>
            : ''}
        </DropdownMenu>
    </UncontrolledDropdown>
);

export const PhoneFrame = ({ children }) => (
    <div style={{ height: '630px', margin: '0 auto', width: '340px' }}>
        <div className='h-100 position-relative' style={{ border: '5px solid #111', borderRadius: '40px' }}>
            <div style={{ position: 'absolute', top: '-1px', width: '100%', height: '20px' }}>
                <div style={{ width: '120px', height: '25px', margin: '0px auto', backgroundColor: '#111111', borderRadius: '0px 0px 20px 20px' }}></div>
            </div>
            <div style={{ display: 'block', borderRadius: '25px', height: '100%' }}>
                <div className='d-flex align-items-center justify-content-between' style={{ padding: '0.75rem 1.8rem 0' }}>
                    <div>
                        <b>{moment().format("HH:mm")}</b>
                    </div>
                    <div>
                        <div className='d-flex align-items-center justify-content-center'>
                            <div style={{ border:'1px solid #313131', padding: '1px' }}>
                                <div style={{ background: '#313131', height: '5px', width: '12px' }}></div>
                            </div>
                            <div style={{ height: '2px', width: '2px', background: '#313131' }}></div>
                        </div>
                    </div>
                </div>
                <div className='h-100'>
                    {children}
                </div>
            </div>
            <div style={{ position: 'absolute', bottom: '10px', width: '100%' }}>
                <div style={{ width: '120px', height: '3px', margin: '0px auto', backgroundColor: '#313131', borderRadius: '100px' }}></div>
            </div>
        </div>
    </div>
);

export const EmptyCard = ({ block, children, error }) => (
    <Card className="shadow border-0 h-100">
        <ErrorAlert error={error} />
        <BlockUi tag="div" blocking={block}>
            <CardBody>
                {children}
            </CardBody>
        </BlockUi>
    </Card>
);

export const CoolTooltip = (props) => (
    <CustomToolTip {...props} placementPrefix="coolTooltip bs-tooltip" placement={props.placement} target={props.target}>
        {props.children}
    </CustomToolTip>
);

export function prettifyXml(sourceXml) {
    var xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
    var xsltDoc = new DOMParser().parseFromString([
        // describes how we want to modify the XML - indent everything
        '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
        '  <xsl:strip-space elements="*"/>',
        '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
        '    <xsl:value-of select="normalize-space(.)"/>',
        '  </xsl:template>',
        '  <xsl:template match="node()|@*">',
        '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
        '  </xsl:template>',
        '  <xsl:output indent="yes"/>',
        '</xsl:stylesheet>',
    ].join('\n'), 'application/xml');

    var xsltProcessor = new XSLTProcessor();
    xsltProcessor.importStylesheet(xsltDoc);
    var resultDoc = xsltProcessor.transformToDocument(xmlDoc);
    var resultXml = new XMLSerializer().serializeToString(resultDoc);
    return resultXml;
};

export const CommonTimePicker = ({ handleTimePicker, time, isToday, currentHours }) => {
    
    const hours = [
        {
            value:'00',
            label:'00'
        },
        {
            value:'01',
            label:'01'
        },
        {
            value:'02',
            label:'02'
        },
        {
            value:'03',
            label:'03'
        },
        {
            value:'04',
            label:'04'
        },
        {
            value:'05',
            label:'05'
        },
        {
            value:'06',
            label:'06'
        },
        {
            value:'07',
            label:'07'
        },
        {
            value:'08',
            label:'08'
        },
        {
            value:'09',
            label:'09'
        },
        {
            value:'10',
            label:'10'
        },
        {
            value:'11',
            label:'11'
        },
        {
            value:'12',
            label:'12'
        },
        {
            value:'13',
            label:'13'
        },
        {
            value:'14',
            label:'14'
        },
        {
            value:'15',
            label:'15'
        },
        {
            value:'16',
            label:'16'
        },
        {
            value:'17',
            label:'17'
        },
        {
            value:'18',
            label:'18'
        },
        {
            value:'19',
            label:'19'
        },
        {
            value:'20',
            label:'20'
        },
        {
            value:'21',
            label:'21'
        },
        {
            value:'22',
            label:'22'
        },
        {
            value:'22',
            label:'22'
        },
        {
            value:'23',
            label:'23'
        },
    ];

    const min = [
        {
            label:'00',
            value:'00'
        },
        {
            label:'30',
            value:'30'
        }
    ];

    const hoursOpts = isToday ? hours.filter(hr => hr.value >= currentHours) : hours;

    return (
        <div className="d-flex" >
            <div style={{ display:'inline-block' }}>
                <CustomSelect
                    isSearchable
                    placeholder={"hh"}
                    options={hoursOpts}
                    required
                    onChange={(combo) => handleTimePicker(combo, "hours")}
                    value={time ? hoursOpts.find((op) => op.label === moment(time).format('HH')) : ''}
                />
            </div>
            <div style={{ padding:'10px 1rem', fontWeight:'1,2em', color:'grey' }}>
                <b>:</b>
            </div>
            <div style={{ display:'inline-block' }}>
                <CustomSelect
                    isSearchable
                    required
                    placeholder={"mm"}
                    options={min}
                    onChange={(combo) => handleTimePicker(combo, "min")}
                    value={time ? min.find((op) => op.label === moment(time).format('mm')) : ''}
                />
            </div>
        </div>
    )
};

export const CommonHelper = ({ help, id, placement = 'left' }) => {
    const helpLink = getHelpLink(id);
    return (
        <div className='float-right'>
            <span id={id} className="btn-sm btn-link h-100" onClick={() => HandleHelp(id)} style={{ fontSize: 'medium' }}>
                <i className="text-secondary fas fa-question-circle"/>
            </span>
            <UncontrolledPopover data-trigger="focus" placement={placement} target={id}>
                <PopoverBody style={{ maxWidth: '500px' }}>
                    <div className="mb-1">
                        {help}
                    </div>
                    {
                        helpLink ?
                            <a className="float-right" href={helpLink} target='_blank'>
                                <span onClick={_ => HandleHelpClick(id, helpLink)}>
                                    <FormattedMessage id="CommonUIComponents.MoreDetails" />
                                </span>
                            </a>
                        :
                            ''
                    }
                        
                </PopoverBody>
            </UncontrolledPopover>
        </div>
    )
};

export const BlankCard = ({ block, children, error }) => (
    <Card className="border-0 h-100 bg-light">
        <ErrorAlert error={error} />
        <BlockUi tag="div" blocking={block}>
            <CardBody>
                {children}
            </CardBody>
        </BlockUi>
    </Card>
);

export const ProfileIsMasterBadge = ({ isMaster, linked }) => (
    isMaster ? <Badge color='primary'> Master </Badge> : linked ? <Badge className=' color-white bg-cyan'> Linked </Badge> : ''
);

export const ActiveInactiveStatusBadge = ({ status }) => (
    <Badge color={status ? 'primary' : 'secondary'} className='p-1'>{status ? <FormattedMessage id="generic.active" /> : <FormattedMessage id="generic.inactive" />}</Badge>
);

export const ActiveDeletedStatusBadge = ({ status }) => (
    <Badge color={status ? 'primary' : 'secondary'} className='p-1'>{status ? <FormattedMessage id="generic.active" /> : <FormattedMessage id="generic.deleted" />}</Badge>
);

export const ActiveInactiveStatusCombo = ({ name, onChangeFunc, required = true, value, placeholder, isDisabled, isClearable }) => {
    const statusOptions = [
        { value: true, label: <FormattedMessage id="generic.active" /> },
        { value: false, label: <FormattedMessage id="generic.inactive" /> }
    ]

    return (
        <CustomSelect
            placeholder={placeholder}
            options={statusOptions}
            onChange={onChangeFunc.bind(this, name)}
            required={required}
            value={statusOptions.find(el => el.value === value)}
            isDisabled={isDisabled}
            isSearchable
            isClearable={isClearable}
        />
    )
};

export const BinaryStatusCombo = ({ name, onChangeFunc, value, placeholder, isDisabled, isClearable }) => {
    const statusOptions = [
        { value: true, label: <FormattedMessage id="generic.yes" /> },
        { value: false, label: <FormattedMessage id="generic.no" /> }
    ]

    return (
        <CustomSelect
            placeholder={placeholder}
            options={statusOptions}
            onChange={onChangeFunc.bind(this, name)}
            value={statusOptions.find(el => el.value === value)}
            isDisabled={isDisabled}
            isSearchable
            isClearable={isClearable}
        />
    )
};


export const Crt = ({ value, text }) => {
    return <Card className="shadow border-0 h-100 my-0 py-0">
        <CardBody>
            <Row className="mb-2">
                <Col><h5 className="text-muted">{text}</h5></Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <h3><div className="text-value-xl text-secondary"> {value}</div></h3>
                </Col>
            </Row>
        </CardBody>
    </Card>
}


export const reservationStatus = [
    {
        value: 'Created',
        label: <FormattedMessage id="CommonUIComponents.Created" />,
        badge: 'badge-success pr-3'
    },
    {
        value: 'Modified',
        label: <FormattedMessage id="CommonUIComponents.Modified" />,
        badge: 'badge-warning text-white'
    },
    {
        value: 'Deleted',
        label: <FormattedMessage id="CommonUIComponents.Deleted" />,
        badge: 'badge-danger text-white'
    },
    {
        value: 'Canceled',
        label: <FormattedMessage id="CommonUIComponents.Canceled" />,
        badge: 'badge-danger text-white'
    }
];

export const ReservationStatusBadge = ({ status }) => {
    const currentStatus = reservationStatus.find(item => item.value === status);
    return (
        <Badge className={'p-1 px-2 shadow ' + (currentStatus != null ? currentStatus.badge : 'badge-danger')}>{currentStatus != null ? currentStatus.label : ''}</Badge>
    )
};

export const TimelineEvents = ({ description, journeyView, date, eventType, status, location }) => {
    const iconList = [
        { eventType: 'T_H10_TASKS', icon: 'icon-icon-maintenance', color: 'danger', text: 'Maintenance Request' },
        { eventType: 'T_HPOS_DOC_SIGN', icon: 'fas fa-file-invoice', color: 'primary', text: 'Invoice' },
        { eventType: 'WELL_EVENT_SPACE', icon: 'icon-icon-wellness', color: 'info', text: 'Wellness Appointment' },
        { eventType: 'WELL_EVENT_RESOURCE', icon: 'icon-icon-wellness', color: 'info', text: 'Wellness Appointment' },
        { eventType: 'T_WELL_APPOINTMENTDETAILTREATMENTS', icon: 'icon-icon-wellness', color: 'success', text: 'Wellness Appointment' },
        { eventType: 'T_WELL_APPOINTMENTDETAILS', icon: 'icon-icon-wellness', color: 'success', text: 'Wellness Appointment' },
        { eventType: 'T_HPOS_TABLE_RESERVATION', icon: 'icon-icon-restaurant', color: 'warning', text: 'Restaurant Reservation' },
        { eventType: 'T_RESFORECAST', icon: 'icon-icon-room-service', color: 'secondary', text: 'Room Reservation' },
        { eventType: 'T_HPOS_TABLE_RESERVATION_ROOMSERVICE', icon: 'icon-icon-room-service', color: 'secondary', text: 'Room Service' }
    ]

    const statusList = [
        { status: 'Closed', icon: 'icon-icon-checked-out', color: 'secondary', text: 'Closed' },
        { status: 'Waiting List', icon: 'icon-icon-in-progress', color: 'primary', text: 'Waiting List' },
        { status: 'Not Done', icon: 'icon-icon-in-progress', color: 'primary', text: 'Not Done' },
        { status: 'In Progress', icon: 'icon-icon-in-progress', color: 'primary', text: 'In Progress' },
        { status: 'Em Tratamento', icon: 'icon-icon-in-progress', color: 'primary', text: 'In Progress' },
        { status: 'Marcado', icon: 'icon-icon-checked-in', color: 'success', text: 'Marcado' },
        { status: 'Option', icon: 'icon-icon-checked-in', color: 'success', text: 'Option' },
        { status: 'Done', icon: 'icon-icon-checked-in', color: 'success', text: 'Done' },
        { status: 'Pending', icon: 'icon-icon-pending', color: 'warning', text: 'Pending' },
        { status: 'Checked-in', icon: 'icon-icon-checked-in', color: 'success', text: 'Checked-in' },
        { status: 'Checked-out', icon: 'icon-icon-checked-out', color: 'secondary', text: 'Checked-out' },
        { status: 'Canceled', icon: 'icon-icon-checked-out', color: 'secondary', text: 'Canceled' },
        { status: 'Cancelation', icon: 'icon-icon-checked-out', color: 'secondary', text: 'Canceled' },
        { status: 'Standard', icon: 'icon-icon-checked-in', color: 'success', text: 'Standard' }
    ]
    
    const icon = iconList.find(el => el.eventType === eventType);
    const currentStatus = statusList.find(el => el.status === status);

    return (
        journeyView === 'Day' ?
            <div className="h-100 mb-5">
                <Row className="text-center my-3">
                    <Col>
                        <b>{date}</b>
                    </Col>
                </Row>
                <Card className="py-3 px-2 border-0 bg-white shadow h-75 position-relative">
                    <Row className="text-center">
                        <Col>
                            <i className={`text-host ${icon && icon.icon}`} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center text-primary px-1">
                            {icon && icon.text}
                        </Col>
                    </Row>
                    <Row className="mt-3 mb-4">
                        <Col className="text-center">
                            {description}
                        </Col>
                    </Row>
                    <div className="position-absolute w-100 my-3 d-flex align-items-center flex-column" style={{ bottom: 0, left: '-1%', fontSize: '12px' }}>
                        {location &&
                            <Row className="mb-1">
                        <Col className="text-center">
                                    <i className="mr-2 fas fa-map-marker-alt" /> {location}
                                </Col>
                            </Row>
                        }

                        {status &&
                            <Row>
                                <Col className="text-center">
                                    {currentStatus ?
                                        <i className={`mr-2 ${currentStatus.icon}`} />
                                    :''}
                                    <span className={`text-${currentStatus && currentStatus.color}`}>{currentStatus ? currentStatus.text : status}</span>
                                </Col>
                            </Row>
                        }
                    </div>
                </Card>
            </div>
            :
            <Row className="py-2">
                <Col>
                    <i className={`mr-3 text-host ${icon && icon.icon}`} />
                    <b>{date}</b>
                </Col>
            </Row>
    )
};


//#region Selects
export const SelectHotel = ({ maxOptions, icon, name, onChangeFunc, isMulti = false, required, value, placeholder, isDisabled, params = '', addDefaultValue = true, isClearable = true, hotelIdsToFilter, forceNoDisabledStyles }) => {

    const [data, setData] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [block, setLoad] = useState(true);
    const [error, setError] = useState('');
    
    useEffect(() => {
        window.setTimeout(
            () => {
                getAPI(result => {
                    const { data, error } = result;
                    if (error) {
                        var errorMessage = [];
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        setError(errorMessage);
                        setLoad(false)
                        setIsDataLoaded(false);
                        return;
                    }
                    if (data && data.response && data.response.length > 0) {
                        var combo = getHotelComboOptions(name, value, onChangeFunc, data.response, 'hotelId', 'name2', addDefaultValue);

                        setData(combo);
                        setIsDataLoaded(true);
                    }
                    setLoad(false);
                }, '/api/gms/Hotel/v1/hotel/list' + params);
            },
            global.modules ? 0 : 2500
        );
    }, []);

    useEffect(() => {
        if (isDataLoaded && data && hotelIdsToFilter && hotelIdsToFilter.length > 0) {
            const filteredData = data.map(group => ({
                ...group,
                options: group.options.filter(option => hotelIdsToFilter.includes(option.value))
            }));
            setData(filteredData);
            setIsDataLoaded(false);
        }
    }, [hotelIdsToFilter, data, isDataLoaded]);

    return (
        <CustomSelect
            icon={icon}
            options={data.map((a) => ({...a, options: a.options && a.options.map((o) => ({...o, disabled: maxOptions && value.length >= maxOptions}))})) || []}
            required={required || CheckAuthorization("user:restrictHotelFilter")}
            isClearable={isClearable}
            isMulti={isMulti}
            value={data ? data.flatMap(el => el.options).filter(el => Array.isArray(value) ? value.find(v => el.value == v) : el.value == value) : ''}
            isSearchable
            placeholder={placeholder}
            onChange={onChangeFunc.bind(this, name)}
            isDisabled={isDisabled}
            isLoading={block}
            forceNoDisabledStyles={forceNoDisabledStyles}
        />
    )
};

export const SelectHotelWithLoadedData = ({ name, onChangeFunc, isMulti = false, required, value, placeholder, isDisabled, options }) => {

    const [data, setData] = useState([]);
    const [block, setLoad] = useState(true);

    useEffect(() => {
        window.setTimeout(
            () => {
                var combo = getHotelComboOptions(name, value, onChangeFunc, options, 'value', 'label');
                
                setData(combo);
                setLoad(false);
            },
            global.modules ? 0 : 2500
        );
    }, []);


    return (
        <CustomSelect
            icon='icon-icon-hotel'
            options={data || []}
            isClearable isSearchable
            placeholder={placeholder  || <FormattedMessage id="generic.Hotel" />}
            onChange={onChangeFunc.bind(this, name)}
            value={data ? data.flatMap(el => el.options).filter(el => Array.isArray(value) ? value.find(v => el.value == v) : el.value == value) : ''}
            isDisabled={isDisabled} 
            required={required}
            isMulti={isMulti}
        />
    )
};

const getHotelComboOptions = (name, value, onChangeFunc, options, hotelIdProp, hotelNameProp, addDefaultValue) => {
    var combo = [];

    var restrictHotel = CheckAuthorization("user:restrictHotelFilter", null);

    if (restrictHotel) {
        if (!value && addDefaultValue) {
            onChangeFunc(name, { value: global.hotelId })
        };

        global.userAllowedProperties && global.userAllowedProperties.map((item) => {
            const hotel = options.find(el => el[hotelIdProp] === item.propertyGuid);

            if (hotel) {
                const obj = { value: hotel[hotelIdProp], label: hotel[hotelNameProp] };
                const groupIndex = combo.findIndex(c => c.groupId === hotel.hotelGroupId);

                if (groupIndex !== -1) {
                    combo[groupIndex].options.push(obj)
                }
                else {
                    combo.push({ label: hotel.hotelGroupName, groupId: hotel.hotelGroupId, options: [obj] });
                }
            }
        })
    }
    else {
        options && options.map((item) => {
            const obj = { value: item[hotelIdProp], label: item[hotelNameProp] };
            const groupIndex = combo.findIndex(c => c.groupId === item.hotelGroupId);

            if (groupIndex !== -1) {
                combo[groupIndex].options.push(obj)
            }
            else {
                combo.push({ label: item.hotelGroupName, groupId: item.hotelGroupId, options: [obj] });
            }
        })
    }

    return combo;
}


export const SelectHotelByHotelGroup = ({ icon, name, onChangeFunc, isMulti = false, required, value, placeholder, isDisabled }) => {

    const [data, setData] = useState([]);
    const [block, setLoad] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                setError(errorMessage);
                setLoad(false)
                return;
            }

            if (data && data.response && data.response.length > 0) {
                const combo = data.response.map((item) => {
                    const obj = { value: item.hotelId, label: item.name2 };
                    return obj;
                })

                setData(combo);
            }
            setLoad(false);
        }, `/api/gms/Hotel/v1`);
    }, []);

    return (
        <BlockUi tag="div" blocking={block}>
            {data.length > 0 ?
                <CustomSelect icon={icon} options={data} required={required} isClearable isMulti={isMulti} value={data ? data.filter(el => Array.isArray(value) ? value.find(v => el.value == v) : el.value == value) : null} isSearchable placeholder={placeholder} onChange={onChangeFunc.bind(this, name)} isDisabled={isDisabled} />
            : ''}
        </BlockUi>
    )
};

export const SelectHotelGroup = ({ icon, name, onChangeFunc, isMulti = false, required, value, placeholder, isDisabled }) => {

    const [data, setData] = useState([]);
    const [block, setLoad] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                setError(errorMessage);
                setLoad(false)
                return;
            }
            if (data && data.response && data.response.length > 0) {
                var combo = [];

                data.response.map((item) =>
                    combo.push({ 'value': item.hotelGroupId, 'label': item.name })
                )

                setData(combo);
            }
            setLoad(false);
        }, '/api/gms/Hotel/v1/hotelgroup/list?pageSize=1000&pageIndex=0');
    }, []);

    return (
        <CustomSelect
            icon={icon}
            options={data}
            required={required}
            isClearable isSearchable
            isMulti={isMulti}
            value={data ? data.filter(el => Array.isArray(value) ? value.find(v => el.value == v) : el.value == value) : null}
            placeholder={placeholder}
            onChange={onChangeFunc.bind(this, name)}
            isDisabled={isDisabled}
            isLoading={block}
        />
    )
};

export const SelectVipCodes = ({ icon, name, onChangeFunc, isMulti = false, required, value, placeholder, isDisabled }) => {

    const [data, setData] = useState([]);
    const [block, setLoad] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                setError(errorMessage);
                setLoad(false)
                return;
            }
            if (data && data.response && data.response.length > 0) {
                const combo = data.response
                    .reduce((acc, curr) => {
                        const newOption = { value: curr.code, label: curr.code, isDisabled: !curr.active };
                        const hotelIdx = acc.findIndex(el => el.hotelId === curr.hotelId);

                        if (hotelIdx !== -1) {
                            acc[hotelIdx].options.push(newOption);
                        }
                        else {
                            acc.push({ label: curr.hotelName, hotelId: curr.hotelId, options: [newOption] })
                        }
                        return acc;
                    }, []);

                setData(combo);
            }
            setLoad(false);
        }, '/api/gms/Hotel/v1/ReferenceData?type=VipCodes');
    }, []);

    return (
        <BlockUi tag="div" blocking={block}>
            <CustomSelect icon={icon} options={data} required={required} isClearable isMulti={isMulti} value={data && data.length > 0 ? data.flatMap(d => d.options).filter(el => Array.isArray(value) ? value.find(v => el.value == v) : el.value == value) : ''} isSearchable placeholder={placeholder} onChange={onChangeFunc.bind(this, name)} isDisabled={isDisabled} />
        </BlockUi>
    )
};

export const CoolBadge = ({ text, onClick, type, isSelected, onChange, data, value, hasInput, small, squared }) => {

    const handleClick = (d, t) => {
        onClick(d, t);
    }

    return (
        <div className={`my-1 coolBadge ${isSelected ? ' selectedBadge' : ''}${small ? ' smallBadge' : ''}${squared ? ' squaredBadge' : ''}`} onClick={() => handleClick(data, type)}>
            <span>{text} </span>
            {hasInput && isSelected ?
                <span className="bg-white text-host ml-2 px-1 invisible-input " style={{ borderRadius: '20px' }}>
                    <input
                        onClick={e => e.stopPropagation()}
                        className="hide-input-arrows text-center"
                        style={{ width: '30px' }}
                        type="number"
                        min="0"
                        autoFocus={true}
                        onChange={(e) => onChange(e, data, type)}
                        value={value}
                    />
                </span>
            : ''}
        </div>
    )
};

export const SelectIsMaster = ({ name, onChangeFunc, required = false, value, placeholder, isDisabled }) => {

    const isMasterOptions = [
        {
            value: true,
            label: <FormattedMessage id="SearchProfile.Master" />
        },
        {
            value: false,
            label: <FormattedMessage id="SearchProfile.NoMaster" />
        }
    ]

    return (
        <CustomSelect
            options={isMasterOptions}
            required={required}
            isClearable isSearchable
            value={isMasterOptions.find(el => el.value.toString() === value?.toString()) || ''}
            placeholder={placeholder}
            onChange={onChangeFunc.bind(this, name)}
            isDisabled={isDisabled}
        />
    )
};

export const SelectCampaign = ({ icon, name, onChangeFunc, isMulti = false, required, value, placeholder = '', isDisabled, isClearable, type, intl, includeAlerts }) => {

    const [block, setLoad] = useState(true);
    const [campaigns, setCampaigns] = useState([
        { label: intl.formatMessage({ id: "CommunicationJourney.UniqueCampaigns" }), options: [] },
        { label: intl.formatMessage({ id: "CommunicationJourney.RecurringCampaigns" }), options: [] },
        { label: intl.formatMessage({ id: "CommunicationJourney.EgoiCampaigns" }), options: [] }
    ]);


    useEffect(() => {
        setLoad(true);

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                return;
            }
            if (data) {
                if (data.response) {
                    switch (type) {
                        case 'custom':
                            campaigns[0].options = data.response.map(campaign => ({ label: campaign.name, value: campaign.id, campaignName: campaign.campaignName, campaignType: 'unique', scheduleDay: campaign.scheduleDay, id: campaign.id }));
                            break;
                        case 'recurring':
                            campaigns[1].options = data.response.map(campaign => ({ label: campaign.name, value: campaign.id, campaignName: campaign.campaignName, id: campaign.id }));
                            break;
                        default:
                            data.response.forEach((campaign, idx) => {
                                const obj = { label: campaign.name, value: campaign.id, campaignName: campaign.campaignName, scheduleDay: campaign.scheduleDay, id: campaign.id };

                                if (campaign.marketingType === 'Custom' || campaign.marketingType === 'CustomAB' || campaign.marketingType === 'CustomLinked' || campaign.marketingType === 'CustomInquiry') {
                                    obj.campaignType = 'unique';
                                    campaigns[0].options.push(obj);
                                }
                                else if (campaign.marketingType === 'EgoiCampaign') {
                                    campaigns[2].options.push(obj)
                                }
                                else campaigns[1].options.push(obj);
                            });
                    }
                }
            }

            setLoad(false);
        }, `/api/gms/Marketing/v1/marketingsend/basicList${type ? `?campaignType=${type}` : ''}`);

        if (includeAlerts) {
            campaigns.push({ label: intl.formatMessage({ id: "CommunicationJourney.Alerts" }), options: [] });


            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    return;
                }
                if (data) {
                    data.response && data.response.forEach(alert => {
                        const obj = { label: alert.name, value: `Alert-${alert.id}`, isAlert: true, id: alert.id, campaignName: alert.campaignName };

                        campaigns[3].options.push(obj)
                    });
                }
            }, `/api/gms/Marketing/v1/marketingalert/list?pageSize=1000&pageIndex=0`);
        }

    }, [type]);


    return (
        <CustomSelect
            icon={icon}
            options={campaigns}
            required={required}
            isClearable={isClearable}
            isMulti={isMulti}
            isSearchable
            value={value ? campaigns.flatMap(el => el.options).filter(el => Array.isArray(value) ? value.find(v => el.value == v) : el.value == value) : ''}
            placeholder={placeholder}
            onChange={onChangeFunc.bind(this, name)}
            isDisabled={isDisabled}
            isLoading={block}
        />
    )
};

//#endregion

export const FormatAmountNumber = ({ value, currency, avoidDecimals, hideDecimals }) => {
    return <FormattedNumber
        value={value ?? 0}
        maximumFractionDigits={hideDecimals ? 0 : avoidDecimals && value && value % 1 == 0 ? 0 : 2}
        style="currency"
        currency={currency || global.hotelCurrency}
    />
}


export const StyledModal = ({ className, modal, size, toggleModal, children, style }) => {
    return <Modal className={className} isOpen={modal} fade={false} size={size} style={style || { minWidth: 0 }} >
        <Button onClick={toggleModal} className="closeModal">
            <i className="fas fa-times fa-sm"/>
        </Button>
        <ModalBody>
            {children}
        </ModalBody>
    </Modal>
}

export const DefaultPage = ({ height, icon, text }) => {
    return <div className="text-center d-flex align-items-center flex-column justify-content-center" style={{ height: (height || "60vh") }}>
        <span className="mb-3"> <i className={icon + " text-muted"} style={{ fontSize: "80px" }} /> </span>
        <FormattedHTMLMessage id={text} />
    </div>
}

export const cardStatus = [
    {
        value: 'created',
        label: <FormattedMessage id="CommonUIComponents.CardCreated" />,
        badge: 'primary'
    },
    {
        value: 'sold',
        label: <FormattedMessage id="CommonUIComponents.Sold" />,
        badge: 'success'
    },
    {
        value: 'redeemed',
        label: <FormattedMessage id="CommonUIComponents.Redeemed" />,
        badge: 'warning'
    },
    {
        value: 'partialRedeemed',
        label: <FormattedMessage id="CommonUIComponents.PartialRedeemed" />,
        badge: 'warning'
    },
    {
        value: 'refunded',
        label: <FormattedMessage id="CommonUIComponents.Refunded" />,
        badge: 'secondary'
    },
    {
        value: 'partialRefund',
        label: <FormattedMessage id="CommonUIComponents.PartialRefund" />,
        badge: 'secondary'
    },
    {
        value: 'cancelled',
        label: <FormattedMessage id="CommonUIComponents.Cancelled" />,
        badge: 'danger'
    }
];

export const CardStatusBadge = ({ status }) => {
    const currentStatus = cardStatus.find(item => item.value === status);
    return (
        currentStatus != null ?
            <Badge className="p-1 px-2 shadow text-white" color={currentStatus.badge}>{currentStatus.label}</Badge>
            :
            <Badge className="p-1 px-2 shadow text-white" color="danger">{status}</Badge>
    )
};

export const ChartCard = ({ title, chartType, options, data, total, fullScreen, fullScreenFunction, height }) => {
    return (
        <Card body className="shadow border-0 h-100" >
            <Row>
                <Col className="text-xs font-weight-bold text-secondary text-uppercase mb-1">{title}</Col>
                {total ?
                    <Col className="text-xs font-weight-bold text-secondary text-right mb-1">{total}</Col>
                :''}
                {fullScreen ?
                    <Col className="text-right col-1">
                        <i onClick={fullScreenFunction} className="fas fa-expand-arrows-alt"></i>
                    </Col>
                :''}
            </Row>
            <Row>
                {data && data.length > 0 ?
                    <Chart
                        chartType={chartType}
                        width="100%"
                        height={height ? height : '200px'}
                        data={data}
                        options={options}
                    />
                : ''}
            </Row>
        </Card>
    )
};

export function getChannels() {
    const opt = [
        {
            value: 'Frontend',
            label: <FormattedMessage id="ReferenceDataFunctions.Local" />
        },
        {
            value: 'Online',
            label: <FormattedMessage id="ReferenceDataFunctions.Online" />
        },
        {
            value: 'Reservation',
            label: <FormattedMessage id="ReferenceDataFunctions.Reservation" />
        }
    ];
    return opt;
}

export const SmallScreenUser = ({ func }) => (
    <Col className="text-uppercase" onClick={func} style={{
        height: '35px',
        width: '35px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#989898',
        color: 'white',
        borderRadius: '100%',
        fontSize: '0.9em'
    }}>
        {authContext.getCachedUser()?.userName?.[0]}
    </Col>
)

export const ExportToPDFButton = ({ name, fromPage }) => (
    <>
        <Button className="btn-sm btn-host" id={`export-to-pdf-button-${name}`} onClick={_ => exportElementToPDF(name, fromPage)}>
            <i className="fas fa-file-export"/>
        </Button>
        <CoolTooltip target={`export-to-pdf-button-${name}`}>
            <FormattedMessage id="generic.DownloadToPDF"/>
        </CoolTooltip>
    </>
)

export const FilterButton = ({ totalFilters, children, butId, requiredList }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleSubmit = () => {
        if (requiredList && requiredList.length > 0) {
            requiredList.forEach(id => {
                const input = document.getElementById(id);
                if (input) {
                    const value = input.value;
                    if (!value) {
                        setIsOpen(true);
                    }
                }
            });
        }
    };

    useEffect(() => {
        const submitButton = document.getElementById(butId);

        if (submitButton) {
            submitButton.addEventListener("click", handleSubmit);

            return () => {
                submitButton.removeEventListener("click", handleSubmit);
            };
        }
    }, [butId]);

    return <>
        <Button className="btn-sm btn-filter position-relative" onClick={_ => setIsOpen(!isOpen)}>
            <i className="fas fa-filter" />

            {totalFilters ?
                <span style={{ position: 'absolute', top: '-10px', fontWeight: '800', border: '1px solid', right: '-14px', padding: '1px' }} className="circle-xs badge-filter-count shadow">
                    {totalFilters}
                </span>
                : ''}
        </Button>


        <div className="floatingFilterCard fullWidth" style={{ position: 'absolute', top: '17px', right: '15px' }}>
            <Collapse isOpen={isOpen}>
                <div className="filterCard shadow text-left">
                    <h5 className="mb-3"><FormattedMessage id="generic.Filters" /></h5>

                    {children}
                </div>
            </Collapse>
        </div>
    </>
}