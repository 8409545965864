import React, { Component } from 'react';
import { Row, Col, Card, Badge } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ShowGuestNumber } from './ProfileCommonFunctions';
import moment from 'moment';


class MainDataCard extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            fontSizeXs: '12px'
        }
    }

    render() {
        const { fontSizeXs } = this.state;
        const { profile, source, profileType } = this.props;

        const hotel = profile.hotelId && this.props.hotels.find(h => h.value === profile.hotelId);
        const membershipCardDesc = profile.membershipCard && profile.membershipCard.length > 0 && profile.membershipCard[0].cardTypeDescription;

        return (
            <div>
                <Card className="border-0 shadow mb-3 pb-2">
                    <div className="my-2 ml-2 pl-1" style={{ "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis" }}>
                        <b>{profile && profile.fullName}</b>
                    </div>
                    <Row className="mb-2 ml-1">
                        <Col>
                            {[1, 2, 3, 4, 5].map((score, key) =>
                                <i className={(profile.score && score <= profile.score ? "fas fa-star" : "far fa-star") + " color-yellow"} key={key} />
                            )}
                        </Col>
                        <Col className="text-right col-5 pl-0">
                            {profile.isMaster ?
                                <Badge color="primary" className="ml-2 mr-3"> Master </Badge>
                                :
                                <ShowGuestNumber
                                    searchableGuestNumber={profile.searchableGuestNumber}
                                    pmsId={profile.pmsId}
                                    idColor="text-muted"
                                />
                            }
                        </Col>
                    </Row>
                    <Row className={global.isMobile ? 'mx-1 align-items-center' : ''}>
                        <Col className={`col-4 col-lg-6 pr-lg-1`}>
                            {profile.type ?
                                <div className={`mb-2 ml-lg-4 d-flex align-items-center`} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                    <i className="fas fa-user-circle mr-1 " />
                                    <span style={{ fontSize: fontSizeXs }}> {profileType ? profileType.label : profile.type} </span>
                                </div>
                                : ''}
                        </Col>
                        <Col className={`col-5 col-lg-6 pl-lg-1`}>
                            {profile.source ?
                                <div className={`mb-0 mb-lg-2 ml-lg-4 d-flex align-items-center justify-content-center justify-content-lg-end pr-3`} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", fontSize: '12px' }}>
                                    {source ?
                                        <> {source.icon} {source.label} </>
                                        :
                                        <>
                                            <i className="fas fa-globe mr-1 " />
                                            <span> {profile.source} </span>
                                        </>
                                    }
                                </div>
                                : ''}
                        </Col>
                        {global.isMobile ?
                            <Col className="col-3 d-flex align-items-center justify-content-end cursor-pointer" onClick={() => this.props.toggleMenuCollapse()}>
                                <FormattedMessage id="generic.Menu" />
                                <i className="fas fa-chevron-down ml-2" />
                            </Col>
                        :''}
                    </Row>

                    {profile.isMaster ?
                        membershipCardDesc ?
                            <div className="mb-2 ml-4 mr-3 d-flex align-items-center" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                <i className="icon-icon-member-card mr-1 " />
                                <span style={{ fontSize: fontSizeXs }}> {membershipCardDesc} </span>
                            </div>
                            : ''
                        :
                        <div className="mb-2 ml-4 mr-3 d-flex align-items-center" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                            <i className="icon-icon-hotel mr-1 " />
                            <span style={{ fontSize: fontSizeXs }}> {hotel && hotel.label} </span>
                        </div>
                    }
                </Card>
            </div>
        );
    }
}
export default injectIntl(MainDataCard)