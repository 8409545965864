import React, { Component } from 'react';
import { FormGroup, Label, Input, Col, Card, CardBody, Table, Row, CardHeader, Button } from 'reactstrap';
import { getAPI, postAPI, deleteAPI } from "../../Base/API";
import { FormattedMessage, injectIntl } from 'react-intl';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../../Base/ErrorAlert";
import { CustomTable } from '../../Base/CustomTable';
import CustomSelect from '../../Base/CustomSelect';
import { handleNotification } from "../../Base/Notification";
import { ActiveInactiveStatusBadge, CommonHelper, SelectHotel } from "../../Base/CommonUIComponents";

class AdminUserManagement extends Component {

    constructor(props) {
        super(props);

        this.form = React.createRef();

        this.state = {
            hotelId: this.props.hotelId,
            block: true,
            blockUserDetails: false,
            blockProperty: false,
            modal: false,
            ComplexData: null,
            user: {},
            selectedUser: {},
            userDetails: {},
            hotelId: null
        };

    }

    componentDidMount() {
        this.searchUser();
        this.setState({ blockProperty: true });
        this.getHotels();
    }

    getHotels() {
        this.setState({ blockProperty: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });

                return;
            }
            if (data) {
                this.setState({ hotelList: data.response, blockProperty: false });
            }
        }, '/api/gms/Hotel/v1/hotel/list');
    }

    searchUser() {
        this.setState({ block: true })
        var param = this.state.hotelId ? `?hotelId=${this.state.hotelId}` : '';

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ listUsers: data.users, property: data.defaultProperty, userDetails: {}, block: false });
            }
        }, '/api/User/v1/User/getUsers' + param);
    }

    getAccessGroupsVoucher() {
        return [
            { value: 'da9f33db-f99c-4f3f-8817-ccc263972d5a', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.da9f33db-f99c-4f3f-8817-ccc263972d5a' }) },
            { value: '7279a2b8-235c-4e18-9906-7d1baebdbf24', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.7279a2b8-235c-4e18-9906-7d1baebdbf24' }) },
            { value: 'ec96829b-df17-4e86-b85a-460995dc7e50', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.ec96829b-df17-4e86-b85a-460995dc7e50' }) },
            { value: '0901c0c4-f875-40ea-89bc-603394609a66', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.0901c0c4-f875-40ea-89bc-603394609a66' }) },
        ]
    }

    getAccessGroupsCM() {
        return [
            { value: 'e2c23135-8904-4d26-93fa-d9573ee19d2d', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.e2c23135-8904-4d26-93fa-d9573ee19d2d' }) },
            { value: 'f7d0725a-bdb6-44fc-b2ce-5a74e69dd89c', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.f7d0725a-bdb6-44fc-b2ce-5a74e69dd89c' }) },
            { value: '4b836682-0839-4a47-8299-5ca9f9139eea', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.4b836682-0839-4a47-8299-5ca9f9139eea' }) },
            { value: 'b6afe5cc-2f35-4cd1-acdc-8f58ee39f82d', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.b6afe5cc-2f35-4cd1-acdc-8f58ee39f82d' }) },
            { value: '2fb7fbe0-22e9-4fa4-ab27-47b139c55999', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.2fb7fbe0-22e9-4fa4-ab27-47b139c55999' }) }
        ]
    }

    getAccessGroupsApi() {
        return [
            { value: '2441bc9e-d10e-4367-ae87-97521ecffa31', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.2441bc9e-d10e-4367-ae87-97521ecffa31' }) },
            { value: '093010d7-8798-4e34-9cd0-e92370177059', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.093010d7-8798-4e34-9cd0-e92370177059' }) }
        ]
    }

    getAccessGroupsTickets() {
        return [
            { value: 'f2441c88-f47d-4092-8dbd-02e8980edee0', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.f2441c88-f47d-4092-8dbd-02e8980edee0' }) }
        ]
    }

    getAccessGroupsGMS() {
        return [
            { value: 'a4140047-d6fd-4479-979b-80861dc0a954', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.a4140047-d6fd-4479-979b-80861dc0a954' }) }, //Administrator
            { value: '1f8c0154-62b3-404f-8880-63797a0ee01d', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.1f8c0154-62b3-404f-8880-63797a0ee01d' }) }, //Manager
            { value: '5f65f469-6340-4bf4-8c50-1c37158cc41a', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.5f65f469-6340-4bf4-8c50-1c37158cc41a' }) }, //GMS Reception Power User
            { value: '2b21bd6f-f770-4c19-9f63-6a79cb86a341', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.2b21bd6f-f770-4c19-9f63-6a79cb86a341' }) }, //GMS Reception User
            { value: 'c454bfea-ed1a-4869-b657-fc569972d9d1', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.c454bfea-ed1a-4869-b657-fc569972d9d1' }) }, //Marketing Power User
            { value: '2663d8ef-d855-4b65-a263-3390fbce0e35', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.2663d8ef-d855-4b65-a263-3390fbce0e35' }) }, //Marketing Automation Management
            { value: 'e02b5dfc-ee17-4dbd-aeba-699ab14ad35c', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.e02b5dfc-ee17-4dbd-aeba-699ab14ad35c' }) }, //Sales Process   
            { value: 'da02ccea-a45a-4803-b607-fa04418656d0', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.da02ccea-a45a-4803-b607-fa04418656d0' }) }, //View Only  
        ]
    }

    getUserDetails() {
        this.setState({ blockUserDetails: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {

                this.setState({ userDetails: data.user??{}, blockUserDetails: false });
            }
        }, '/api/User/v1/User/getUser/' + this.state.selectedUser.adId);
    }

    reinviteUser() {
        let body = {
            "adId": this.state.selectedUser.adId,
            "name": this.state.selectedUser.name,
            "surname": this.state.selectedUser.surname,
            "mobile": this.state.selectedUser.mobile,
            "email": this.state.selectedUser.email
        }

        this.setState({ blockUserDetails: true });

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="AdminUserManagement.NotificationSent" />, <FormattedMessage id="AdminUserManagement.Success" />);
                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/reInviteUser?company=GMS', body);

    }

    disableUser() {
        this.setState({ blockUserDetails: true });

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="AdminUserManagement.UserDisabled" />, <FormattedMessage id="AdminUserManagement.Success" />);
                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/disableUser/' + this.state.selectedUser.adId);
    }

    enableUser() {
        this.setState({ blockUserDetails: true });

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="AdminUserManagement.UserEnabled" />, <FormattedMessage id="AdminUserManagement.Success" />);
                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/enableUser/' + this.state.selectedUser.adId);
    }

    deleteUser() {
        this.setState({ blockUserDetails: true });

        deleteAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="AdminUserManagement.UserDeleted" />, <FormattedMessage id="AdminUserManagement.Success" />);
                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/deleteUser/' + this.state.selectedUser.adId);
    }

    createUser(event) {
        event.preventDefault();
        if (!this.form.current.reportValidity()) {
            return;
        }

        this.setState({ blockUserDetails: true });

        let req = this.state.userDetails;

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="AdminUserManagement.UserCreated" />, <FormattedMessage id="AdminUserManagement.Success" />);
                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/addUser?company=GMS', req);
    }

    saveUser(event) {
        event.preventDefault();
        if (!this.form.current.reportValidity()) {
            return;
        }

        this.setState({ blockUserDetails: true });

        let req = this.state.userDetails;

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="AdminUserManagement.UserUpdated" />, <FormattedMessage id="AdminUserManagement.Success" />);
                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/updateUser', req);
    }

    updateProperties() {
        this.setState({ blockUserDetails: true });

        let req = this.state.userDetails.allowedProperties
        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="AdminUserManagement.PropertiesUpdated" />, <FormattedMessage id="AdminUserManagement.Success" />);
                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/' + this.state.selectedUser.adId + '/updateAllowedProperties', req);
    }

    updateGroups() {
        this.setState({ blockUserDetails: true });

        let req = this.state.userDetails.groups
        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="AdminUserManagement.GroupUpdated" />, <FormattedMessage id="AdminUserManagement.Success" />);
                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/' + this.state.selectedUser.adId + '/updateGroups', req);
    }

    manageListCombo(val) {
        if (val != undefined) {
            this.setState({ selectedHotel: val.value })
        }
    }
    addHoteltoList() {
        if (this.state.selectedHotel != undefined) {
            this.manageListComboOp(this.state.selectedHotel, 'allowedProperties', 'add')

        }
    }

    manageListComboOp(el, type, op) {
        if (this.state.userDetails[type] === undefined)
            return;
        let newgroup = []
        if (op === 'add')
            newgroup = [...this.state.userDetails[type], el]
        else
            newgroup = this.state.userDetails[type].filter(ar => ar !== el)

        this.setState(prevState => ({
            ...prevState,
            userDetails: {
                ...prevState.userDetails,
                [type]: newgroup
            }
        }));

    }

    manageList(el, type, event) {
        if (this.state.userDetails[type] === undefined)
            return;

        let newgroup = this.state.userDetails[type].indexOf(el) >= 0 ? this.state.userDetails[type].filter(ar => ar !== el) : [...this.state.userDetails[type], el]

        this.setState(prevState => ({
            ...prevState,
            userDetails: {
                ...prevState.userDetails,
                [type]: newgroup
            }
        }));
    }

    manageGroups = (el, accessGroup) => {
        if (this.state.userDetails.groups === undefined)
            return;


        let newgroup = this.state.userDetails.groups.filter(g => !accessGroup.some(ag => ag.value === g));

        newgroup.push(el);


        this.setState(prevState => ({
            ...prevState,
            userDetails: {
                ...prevState.userDetails,
                groups: newgroup
            }
        }));
    }

    changeUserState(event) {

        let name = event.target.name;
        let value = event.target.value;

        this.setState(prevState => ({
            ...prevState,
            userDetails: {
                ...prevState.userDetails,
                [name]: value
            }
        }));
    }

    getHotelsCombo() {
        let arr = []
        this.state.hotelList && this.state.hotelList.map((el) => arr.push({ label: el.name2, value: el.hotelId }));
        return arr;
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    render() {
        const columns = [
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "ManageUser.name" }),
                sort: true
            },
            {
                dataField: 'surname',
                text: this.props.intl.formatMessage({ id: "ManageUser.surname" }),
                sort: true
            },
            {
                dataField: 'email',
                text: this.props.intl.formatMessage({ id: "ManageUser.email" }),
                sort: true
            },
            {
                dataField: 'mobile',
                text: this.props.intl.formatMessage({ id: "ManageUser.mobile" }),
                sort: true
            },
            {
                dataField: 'accountEnabled',
                text: this.props.intl.formatMessage({ id: "ManageUser.accountStatus" }),
                sort: true,
                formatter: (cell, row) => <ActiveInactiveStatusBadge status={cell} />
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ selectedUser: row, blockUserDetails: true }, () => this.getUserDetails())
            }
        };

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />

                <form ref={this.form}>
                    <div className="container-fluid content-row">
                        <Row>
                            <Col className="Col-6 pl-0">
                                <BlockUi tag="div" blocking={this.state.block}>
                                    <Card className="mb-2 border-0 shadow">
                                        <CardHeader className="border-bottom bg-white">
                                            <Row className="align-items-center">
                                                <Col>
                                                    <h6 className="m-0">
                                                        <span className="fas fa-users mr-2" />
                                                        <FormattedMessage id="ManageUser.users" />
                                                    </h6>
                                                </Col>
                                                <Col>
                                                    <Button className="btn btn-host btn-sm float-right mx-1" onClick={this.searchUser.bind(this)}>
                                                        <i className="fas fa-sync-alt" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <Row className="mb-2">
                                                <Col className="col-4">
                                                    <SelectHotel
                                                        name={'hotelId'} icon={'icon-icon-hotel'}
                                                        onChangeFunc={this.handleChangeSelect}
                                                        value={this.state.hotelId}
                                                        placeholder={<FormattedMessage id="generic.Hotel" />}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Button className="btn btn-host btn-sm float-right mx-1" onClick={this.searchUser.bind(this)}>
                                                        <i className="icon-icon-search" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <CustomTable
                                                keyField='adId'
                                                data={this.state.listUsers ? this.state.listUsers : []}
                                                columns={columns}
                                                page={this.state.currentPage}
                                                totalSize={this.state.listUsers && this.state.listUsers.length}
                                                onTableChange={this.handleTableChange}
                                                shadow={false}
                                                exportCSV={false}
                                                selectRow={selectRow}
                                                search={true}
                                                showTotal={true}
                                                remote={false}
                                            />
                                        </CardBody>
                                    </Card>
                                </BlockUi>
                                <BlockUi tag="div" blocking={this.state.blockUserDetails}>
                                    <Card className="mb-2 border-0 shadow small">
                                        <CardHeader className="border-bottom bg-white">
                                            <Row className="align-items-center">
                                                <Col>
                                                    <h6 className="m-0">
                                                        <span className="fas fa-id-card mr-2" />
                                                        <FormattedMessage id="ManageUser.selectedUser" />
                                                    </h6>
                                                </Col>
                                                <Col>
                                                    <CommonHelper help={<FormattedMessage id="ManageUser.Help" />} id={'manageruserhelp'} />

                                                    {
                                                        this.state.userDetails.id === undefined ?
                                                            <Button className="btn btn-host btn-sm float-right" disabled={this.state.userDetails.id === null} onClick={this.createUser.bind(this)} title={this.props.intl.formatMessage({ id: "ManageUser.CreateUser" })}>
                                                                <i className="fas fa-plus"></i>
                                                            </Button>
                                                            :
                                                            <div>
                                                                <Button className="btn btn-host btn-sm float-right mx-1" disabled={this.state.userDetails.id === undefined} onClick={this.deleteUser.bind(this)} title={this.props.intl.formatMessage({ id: "ManageUser.DeleteUser" })}>
                                                                    <i className="fas fa-user-times" />
                                                                </Button>
                                                                {
                                                                    this.state.userDetails.accountEnabled ?
                                                                        <Button className="btn btn-host btn-sm float-right mx-1" disabled={Object.keys(this.state.userDetails).length === 0} onClick={this.disableUser.bind(this)} title={this.props.intl.formatMessage({ id: "ManageUser.DisableUser" })} >
                                                                            <i className="fas fa-user-slash"></i>
                                                                        </Button>
                                                                        :
                                                                        <Button className="btn btn-host btn-sm float-right mx-1" disabled={Object.keys(this.state.userDetails).length === 0} onClick={this.enableUser.bind(this)} title={this.props.intl.formatMessage({ id: "ManageUser.EnableUser" })}>
                                                                            <i className="fas fa-user-check" />
                                                                        </Button>
                                                                }
                                                                <Button className="btn btn-host btn-sm float-right mx-1" disabled={Object.keys(this.state.userDetails).length === 0} onClick={this.saveUser.bind(this)} title={this.props.intl.formatMessage({ id: "ManageUser.Save" })}>
                                                                    <i className="fas fa-save" />
                                                                </Button>
                                                            </div>
                                                    }
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col className="col-6">
                                                    <FormGroup row size="sm small">
                                                        <Label for="User" className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.user" /> </Label>
                                                        <Col sm={9}>
                                                            <Input type="text" bsSize="sm" readOnly name="userPrinciplaName" value={this.state.userDetails.userPrinciplaName ? this.state.userDetails.userPrinciplaName : ''} id="userPrinciplaName" />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="col-6">
                                                    <FormGroup row size="sm">
                                                        <Label for="Email" className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.email" /> </Label>
                                                        <Col sm={9}>
                                                            <Input type="email" required bsSize="sm" name="email" value={this.state.userDetails.email ? this.state.userDetails.email : ''} onChange={this.changeUserState.bind(this)} id="email" />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-6">
                                                    <FormGroup row size="sm">
                                                        <Label for="FirstName" className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.name" /> </Label>
                                                        <Col sm={9}>
                                                            <Input type="text" bsSize="sm" required name="name" value={this.state.userDetails.name ? this.state.userDetails.name : ''} onChange={this.changeUserState.bind(this)} id="name" />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="col-6">
                                                    <FormGroup row size="sm">
                                                        <Label for="FamilyName" className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.surname" /> </Label>
                                                        <Col sm={9}>
                                                            <Input type="text" bsSize="sm" required name="surname" value={this.state.userDetails.surname ? this.state.userDetails.surname : ''} onChange={this.changeUserState.bind(this)} id="surname" />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FormGroup row size="sm">
                                                        <Label for="mobile" className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.mobile" /> </Label>
                                                        <Col sm={9}>
                                                            <Input type="text" bsSize="sm" required name="mobile" value={this.state.userDetails.mobile ? this.state.userDetails.mobile : ''} onChange={this.changeUserState.bind(this)} id="mobile" />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup row size="sm">
                                                        <Label for="stat" className="font-weight-bold" sm={3}> Inv. Status </Label>
                                                        <Col sm={this.state.userDetails.status && this.state.userDetails.status === 'PendingAcceptance' ? 7 : 9}>
                                                            <Input type="text" bsSize="sm" name="stat" defaultValue={this.state.userDetails.status ? this.state.userDetails.status : ''} disabled id="mobile" />
                                                        </Col>
                                                        {
                                                            this.state.userDetails.status && this.state.userDetails.status === 'PendingAcceptance' ?
                                                                <Col>
                                                                    <Button className="btn btn-sm btn-host float-right" onClick={this.reinviteUser.bind(this)}>
                                                                        <i className="far fa-share-square" />
                                                                    </Button>
                                                                </Col>
                                                                :
                                                                ''
                                                        }
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </BlockUi>
                            </Col>
                            <Col className="pr-0">
                                <Row>
                                    <Col className="pr-1">
                                        <BlockUi tag="div" blocking={this.state.blockUserDetails || this.state.blockProperty}>
                                            <Card className="mb-1 h-100  border-0 shadow">
                                                <CardHeader className="border-bottom bg-white">
                                                    <Row className="align-items-center">
                                                        <Col className="align-items-center">
                                                            <h6 className="m-0">
                                                                <span className="icon-icon-hotel mr-2"></span> <FormattedMessage id="Profile.Property" />
                                                            </h6>
                                                        </Col>
                                                        <Col>
                                                            <Button className="btn btn-host btn-sm float-right mx-1" disabled={this.state.userDetails.id === undefined} onClick={this.updateProperties.bind(this)}>
                                                                <i className="fas fa-save" />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col>{this.state.hotelList ?
                                                            <CustomSelect options={this.getHotelsCombo()} onChange={this.manageListCombo.bind(this)} name="allowedProperties" placeholder="Hotel" />
                                                            : ''}
                                                        </Col>
                                                        <Col className="col-2 text-right">
                                                            <Button className="btn-host btn-sm" disabled={this.state.userDetails.id === undefined} onClick={this.addHoteltoList.bind(this)}><i className="fas fa-plus"></i></Button>
                                                        </Col>
                                                    </Row>

                                                    <Table className={"table-sm table-borderless" + (this.state.userDetails.id === undefined ? '  text-secondary' : '')}>
                                                        <tbody>
                                                            {
                                                                this.state.userDetails && this.state.userDetails.allowedProperties && this.state.userDetails.allowedProperties.map((item, key) =>
                                                                    <tr key={key} className="d-flex mb-2 cursor-pointer" >

                                                                        <td className="col-11">{this.state.hotelList && this.state.hotelList.find(el => el.hotelId === item) != undefined && this.state.hotelList.find((el) => el.hotelId == item).name2}</td>
                                                                        <td className="col-1 text-center pr-0" onClick={this.manageListComboOp.bind(this, item, 'allowedProperties', 'remove')} ><i className=" text-danger fas fa-times"></i></td>
                                                                    </tr>
                                                                )}
                                                        </tbody>
                                                    </Table>
                                                </CardBody>
                                            </Card>
                                        </BlockUi>
                                    </Col>
                                    <Col className="pl-1">
                                        <BlockUi tag="div" blocking={this.state.blockUserDetails}>
                                            <Card className="mb-1 h-100  bg-white border-bottom shadow">
                                                <CardHeader className="border-bottom bg-white">
                                                    <Row className="align-items-center">
                                                        <Col>
                                                            <h6 className="m-0">
                                                                <span className="fas fa-users mr-2" />
                                                                <FormattedMessage id="Profile.AccessGroups" />
                                                            </h6>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <Button className="btn btn-host btn-sm float-right mx-1" disabled={this.state.userDetails.id === undefined} onClick={this.updateGroups.bind(this)} >
                                                                <i className="fas fa-save" />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </CardHeader>
                                                <CardBody>
                                                    <h6>Profile Now</h6>
                                                    <hr className="my-1" />
                                                    <Table className={"table-sm table-borderless" + (this.state.userDetails.id === undefined ? '  text-secondary' : '')}>
                                                        <tbody>
                                                            {this.getAccessGroupsGMS().map((item, key) =>
                                                                <tr key={key} className="cursor-pointer">
                                                                    <th style={{ width: '10%' }} scope="row"> {key}</th>
                                                                    <td className={this.state.userDetails && this.state.userDetails.groups && this.state.userDetails.groups.filter(el => el === item.value).length > 0 ? 'bg-secondary text-white' : ''} onClick={() => this.manageGroups(item.value, this.getAccessGroupsGMS())} >
                                                                        {item.label}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>

                                                    <h6>Channel Manager</h6>
                                                    <hr className="my-1" />
                                                    <Table className={"table-sm table-borderless" + (this.state.userDetails.id === undefined ? '  text-secondary' : '')}>
                                                        <tbody>
                                                            {
                                                                this.getAccessGroupsCM().map((item, key) =>
                                                                    <tr key={key} className="cursor-pointer">
                                                                        <th style={{ width: '10%' }} scope="row"> {key}</th>
                                                                        <td className={this.state.userDetails && this.state.userDetails.groups && this.state.userDetails.groups.filter(el => el === item.value).length > 0 ? 'bg-secondary text-white' : ''} onClick={() => this.manageGroups(item.value, this.getAccessGroupsCM())} >
                                                                            {item.label}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </Table>
                                                    <h6>Voucher</h6>
                                                    <hr className="my-1" />
                                                    <Table className={"table-sm table-borderless" + (this.state.userDetails.id === undefined ? '  text-secondary' : '')}>
                                                        <tbody>
                                                            {
                                                                this.getAccessGroupsVoucher().map((item, key) =>
                                                                    <tr key={key} className="cursor-pointer">
                                                                        <th style={{ width: '10%' }} scope="row"> {key}</th>
                                                                        <td className={this.state.userDetails && this.state.userDetails.groups && this.state.userDetails.groups.filter(el => el === item.value).length > 0 ? 'bg-secondary text-white' : ''} onClick={() => this.manageGroups(item.value, this.getAccessGroupsVoucher())} >
                                                                            {item.label}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </Table>

                                                    <h6>Api Portal</h6>
                                                    <hr className="my-1" />
                                                    <Table className={"table-sm table-borderless" + (this.state.userDetails.id === undefined ? '  text-secondary' : '')}>
                                                        <tbody>
                                                            {this.getAccessGroupsApi().map((item, key) =>
                                                                <tr key={key} className="cursor-pointer">
                                                                    <th style={{ width: '10%' }} scope="row"> {key}</th>
                                                                    <td className={this.state.userDetails && this.state.userDetails.groups && this.state.userDetails.groups.filter(el => el === item.value).length > 0 ? 'bg-secondary text-white' : ''} onClick={() => this.manageGroups(item.value, this.getAccessGroupsApi())} >
                                                                        {item.label}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>

                                                    <h6>Tickets</h6>
                                                    <hr className="my-1" />
                                                    <Table className={"table-sm table-borderless" + (this.state.userDetails.id === undefined ? '  text-secondary' : '')}>
                                                        <tbody>
                                                            {this.getAccessGroupsTickets().map((item, key) =>
                                                                <tr key={key} className="cursor-pointer">
                                                                    <th style={{ width: '10%' }} scope="row"> {key}</th>
                                                                    <td className={this.state.userDetails && this.state.userDetails.groups && this.state.userDetails.groups.filter(el => el === item.value).length > 0 ? 'bg-secondary text-white' : ''} onClick={() => this.manageGroups(item.value, this.getAccessGroupsTickets())} >
                                                                        {item.label}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>

                                                </CardBody>
                                            </Card>


                                        </BlockUi>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </form>
            </BlockUi>
        );
    }
}

export default injectIntl(AdminUserManagement)