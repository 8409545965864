import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Input } from 'reactstrap';
import { handleNotification } from "../../../Base/Notification";
import { BlankCard, StyledModal } from '../../../Base/CommonUIComponents';
import { postAPI, deleteAPI } from "../../../Base/API";
import ConfirmActionModal from '../../../Base/ConfirmActionModal';
import CustomSelect from '../../../Base/CustomSelect';
import { getAllConsentTypeList } from '../../../Base/ReferenceDataFunctions';

class ConsentModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            consent: this.props.consent || {},
            isDisabled: this.props.consent?.hotelGroupId === '00000000-0000-0000-0000-000000000000' || false
        };
    }

    saveConsent = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var consent = { ...this.state.consent };

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="ConsentsConfig.ConsentSaved" />, <FormattedMessage id="generic.success" />);
                    this.props.updateTable(data.response[0], false);
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Hotel/v1/consents`, JSON.stringify( consent ));
    }

    removeConsent = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="ConsentsConfig.ConsentRemoved" />, <FormattedMessage id="generic.success" />);
                    this.toggleDeleteModal();
                    this.props.updateTable(data.response[0], true);
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Hotel/v1/consents/${this.state.consent.id}`);

    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            consent: {
                ...this.state.consent,
                [name]: combo ? combo.value : null
            }
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            consent: {
                ...this.state.consent,
                [name]: value
            }
        })
    }

    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal
        }));
    }

    render() {
        const { block, error, consent, deleteModal, isDisabled } = this.state;
        const { toggleModal, modal, hotelGroupList, availableConsents } = this.props;

        return (
            <div>
                <StyledModal toggleModal={toggleModal} modal={modal} size="xl">
                    <BlankCard block={block} error={error}>
                        <Form onSubmit={this.saveConsent}>
                            <Row className="mb-3">
                                <Col>
                                    <h5>{this.props.consent ? <FormattedMessage id="ConsentsConfig.EditConsent" /> : <FormattedMessage id="ConsentsConfig.AddConsent" />}</h5>
                                </Col>
                                {!isDisabled ?
                                    <Col className="text-right">
                                        {consent && consent.id ?
                                            <Button className="btn-sm btn-host mr-2" onClick={this.toggleDeleteModal}>
                                                <i className="fas fa-trash-alt" />
                                            </Button>
                                            : ''}
                                        <Button className="btn-sm btn-host" type="submit">
                                            <i className="fas fa-save" />
                                        </Button>
                                    </Col>
                                : ''}
                            </Row>
                            <FormGroup row>
                                <Label sm={2}> <FormattedMessage id="generic.HotelGroup" /></Label>
                                <Col sm={4}>
                                    <CustomSelect
                                        options={hotelGroupList}
                                        isClearable isSearchable
                                        value={hotelGroupList.find(el => el.value === consent.hotelGroupId) || ''}
                                        placeholder={''}
                                        onChange={(combo) => this.handleChangeSelect('hotelGroupId', combo)}
                                        required
                                        isDisabled={isDisabled}
                                    />
                                </Col>
                                <Label sm={2}> <FormattedMessage id="ConsentsConfig.Code" /></Label>
                                <Col sm={4}>
                                    <CustomSelect
                                        options={availableConsents}
                                        isClearable isSearchable
                                        value={availableConsents.find(el => el.value === consent.code) || ''}
                                        placeholder={''}
                                        onChange={(combo) => this.handleChangeSelect('code', combo)}
                                        required
                                        isDisabled={isDisabled}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2}> <FormattedMessage id="ConsentsConfig.Description" /></Label>
                                <Col sm={10}>
                                    <Input
                                        type="text"
                                        name="description"
                                        onChange={(e) => this.handleChange(e)}
                                        value={consent.description || ''}
                                        maxLength="500"
                                        required
                                        disabled={isDisabled}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </BlankCard>
                </StyledModal>

                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={this.toggleDeleteModal}
                        actionFunction={this.removeConsent}
                        text={<FormattedMessage id="ConsentsConfig.ConfirmDeleteText" values={{ consent: consent.code }} />}
                        block={block}
                    />
                : ''}
            </div>
        );
    }
}
export default injectIntl(ConsentModal)