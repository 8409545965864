import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Form, Col, Modal, ModalBody, Row, Badge } from 'reactstrap';
import { handleNotification } from "../../../Base/Notification";
import { postAPI } from '../../../Base/API';
import { FormattedMessage } from 'react-intl';
import { BlankCard } from '../../../Base/CommonUIComponents';
import SearchProfile from '../../../ProfileMenu/SearchProfile';
import { CustomTable } from '../../../Base/CustomTable';
import moment from 'moment-timezone';
import countryList from 'react-select-country-list';

class AssociatedProfiles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
        };
    }

    associateForm = (e) => {
        if (e) e.preventDefault();
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                if (data.response && data.response.length > 0) {
                    this.props.updateForm(this.props.answerId, this.state.profile.id);
                }

                this.setState({ block: false });
            }
            else this.setState({ block: false });
        }, `/api/gms/Profile/v1/profile/${this.state.profile.id}/forms/PNForm/${this.props.answerId}`);
    }


    selectProfile = (profile) => {
        this.setState({ profile })
    }

    render() {
        const { block, error } = this.state;
        
        const dummyProfile = {
            firstName: "",
            lastName: "",
            middleName: "",
            birthDate: null,
            gender: "",
            language: "",
            nationality: null,
            isMaster: null
        }

        const columns = [
            {
                dataField: 'firstName',
                text: this.props.intl.formatMessage({ id: "SearchProfile.FirstName" })
            },
            {
                dataField: 'lastName',
                text: this.props.intl.formatMessage({ id: "SearchProfile.LastName" })
            },
            {
                dataField: 'middleName',
                text: this.props.intl.formatMessage({ id: "ProfileDetails.OtherNames" })
            },
            {
                dataField: 'birthDate',
                text: this.props.intl.formatMessage({ id: "SearchProfile.BirthDate" }),
                formatter: (cell, row) => cell ? moment(cell).format("YYYY-MM-DD") : ''
            },
            {
                dataField: 'gender',
                text: this.props.intl.formatMessage({ id: "SearchProfile.Gender" })
            },
            {
                dataField: 'language',
                text: this.props.intl.formatMessage({ id: "SearchProfile.Language" })
            },
            {
                dataField: 'nationality',
                text: this.props.intl.formatMessage({ id: "SearchProfile.Nationality" }),
                formatter: (cell, row) => cell ? <div><i className={('ml-2 mr-2 flag-icon flag-icon-') + (cell.toLowerCase())}></i> {countryList().getLabel(cell)} </div> : ''
            },
            {
                dataField: 'isMaster',
                text: 'Master',
                formatter: (cell, row) => cell ? <Badge color='primary'> Master </Badge> : row.linked ? <Badge className=' color-white bg-cyan'> Linked </Badge> : ''
            }
        ];

        return (
            <Modal isOpen={this.props.modal} fade={false} size={"xl"} >
                <Button onClick={this.props.toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <Form onSubmit={this.associateForm}>
                            <Row className="mb-3">
                                <Col>
                                    <h5><FormattedMessage id="AssociatedProfiles.AssociateProfile" /></h5>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" type="submit" disabled={!this.state.profile}> <i className="fas fa-save" /></Button>
                                </Col>
                            </Row>
                            <Row className="mb-4 mt-2">
                                <Col>
                                    <b><FormattedMessage id="AssociatedProfiles.SelectedProfile" />: </b>
                                    <div className='mt-2'>
                                        <CustomTable
                                            data={this.state.profile ? [this.state.profile] : []}
                                            columns={columns}
                                            hasPagination={false}
                                        />
                                    </div>
                                    {/* {this.state.profile ?
                                        <span>{this.state.profile.firstName} {this.state.profile.lastName} </span>
                                        :
                                        <FormattedMessage id="AssociatedProfiles.NoProfileSelected" />
                                    } */}
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <SearchProfile
                                        fromAnotherPage={true}
                                        addProfile={this.selectProfile}
                                        pageSize={5}
                                        disableMasterFilter={this.state.disableMasterFilter}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(AssociatedProfiles)
