import React, { Component } from 'react';
import RenderHtmlAsImage from '../../../Base/RenderHtmlAsImage';
import BlockUi from 'react-block-ui';
import CustomSelect from '../../../Base/CustomSelect';
import ReportsDetails from './ReportsDetails';
import moment from 'moment';

import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Card, Col, Row } from 'reactstrap';
import { getAPI } from "../../../Base/API";
import { StyledCard } from "../../../Base/CommonUIComponents";
import { Pagination, ResultsPerPage, TotalResults } from '../../../Base/PaginationComponents';
import { handleNotification } from '../../../Base/Notification';
import { DefaultPage } from '../../../Base/CommonUIComponents';

class Reports extends Component {
    constructor(props) {
        super(props)
        this.state = {
            block: false,
            blockGroup: false,
            error: null,
            reports: [],
            selectedReport: null,
            reportDetailsModal: false,
            selectedEventType: null,
            selectedHotelGroupId: '00000000-0000-0000-0000-000000000000',
            hotelGroupList: [],
            eventTypeList: [{
                value: 'InHouseGuest',
                label: this.props.intl.formatMessage({ id: 'Reports.InHouseGuest' })
            },
            {
                value: 'MonthlyOverview',
                label: this.props.intl.formatMessage({ id: 'Reports.MonthlyOverview' })
            },
            {
                value: 'TaskResults',
                label: this.props.intl.formatMessage({ id: 'Reports.TaskResults' })
            }
            ],
            pageSize: 10,
            pageIndex: 0,
            totalItems: 0,
        }
    }

    componentDidMount() {
        this.getHotelGroupList();
    }

    getHotelGroupList = () => {
        this.setState({ blockGroup: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockGroup: false });
                    return;
                }
                if (data) {
                    var hotelGroupList = [{ value: "00000000-0000-0000-0000-000000000000", label: this.props.intl.formatMessage({ id: "generic.All" }) }];

                    if (data.response && data.response.length > 0) {
                        data.response.map((item) =>
                            hotelGroupList.push({ value: item.hotelGroupId, label: item.name })
                        )
                    }

                    this.setState({ hotelGroupList, error: errorMessage, blockGroup: false }, () => this.getReports());
                }
                else this.setState({ error: errorMessage, blockGroup: false }, () => this.getReports());
            }, '/api/gms/Hotel/v1/hotelgroup/list?pageSize=1000&pageIndex=0');
        });
    }

    getReports = () => {
        const { selectedHotelGroupId, selectedEventType, pageSize, pageIndex } = this.state;
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
    
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
    
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }else if(data.response && data.response.length > 0){
                        handleNotification(data, <FormattedMessage id="Reports.SuccessfullyObtainedData" />, <FormattedMessage id="generic.success" />);
                        
                        this.setState({ reports: data.response, block: false, totalItems: data.totalItems, reportDetailsModal: false });
                        return;
                    }
                    this.setState({ reports: [], block: false, totalItems: data.totalItems, reportDetailsModal: false });
                    return;
                }
            }, `/api/gms/DataQuality/v1/ListNotifyGenericTemplates?${selectedHotelGroupId ? `hotelGroupId=${selectedHotelGroupId}` : ''}${selectedEventType ? `&eventType=${selectedEventType}` : ''}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
        });
    }

    
    togglePreview = (selectedReport) => {
        this.setState(prevState => ({
            reportDetailsModal: !prevState.reportDetailsModal,
            selectedReport
        }));
    }

    handleChangeSelect = (name, combo) => {
        this.setState({ [name]: combo ? combo.value : null })
    }

    changePageSize = (evt) => {
        const { value } = evt.target;

        if (this.state.pageSize !== value) {
            this.setState({ pageSize: value, pageIndex: 0 }, this.getReservations);
        }
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.getReports());
    }

    render() {
        const { reports, hotelGroupList, eventTypeList, selectedReport, reportDetailsModal, selectedEventType, 
            selectedHotelGroupId, pageSize, pageIndex, totalItems, block, error } = this.state;

        return (
            <StyledCard block={block} error={error} icon={'fas fa-file-contract'} title={'NavMenu.Reports'}>
                <Row className="d-flex align-items-center my-3">
                    <Col sm={2}>
                        <CustomSelect
                            className="w-100"
                            options={hotelGroupList}
                            isSearchable
                            value={hotelGroupList.find(el => el.value === selectedHotelGroupId) || ''}
                            icon={'fas fa-hotel'}
                            placeholder={this.props.intl.formatMessage({ id: "generic.HotelGroup" })}
                            onChange={(combo) => this.handleChangeSelect('selectedHotelGroupId', combo)}
                        />
                    </Col>
                    <Col sm={2}>
                        <CustomSelect
                            className="w-100"
                            options={eventTypeList}
                            isSearchable
                            isClearable
                            value={eventTypeList.find(el => el.value === selectedEventType) || ''}
                            icon={'fas fa-tag'}
                            placeholder={this.props.intl.formatMessage({ id: "Reports.EventType" })}
                            onChange={(combo) => this.handleChangeSelect('selectedEventType', combo)}
                        />
                    </Col>
                    <Col className="text-right">
                        <Button className="btn btn-sm btn-host" onClick={() => this.togglePreview()}>
                            <i className="fas fa-plus"></i>
                        </Button>
                        <Button className="btn btn-sm btn-host ml-2" onClick={() => this.getReports()}>
                            <i className="fas fa-search"></i>
                        </Button>
                    </Col>
                </Row>
                {reports?.length > 0 ?
                    <>
                        <Row>
                            {reports.map((template, idx) => 
                                <Col className="col-3 mb-3" key={`${template.id}-${idx}`}>
                                    <Card className="shadow p-3 pointer" body style={{ height: '430px', overflow: 'hidden' }} onClick={() => this.togglePreview(template)}>
                                        <Row className="mb-3 d-flex align-items-center">
                                            <Col className='text-truncate col-7'>
                                                <span className="my-2 text-ellipsis" title={template.name} style={{ fontSize: '18px' }}>
                                                    {template.name}
                                                </span>
                                            </Col>
                                            {template.updatedAt ?
                                                <Col className='text-right col-5'>
                                                    <span style={{ fontSize: '0.9em' }} className="text-muted">
                                                        {moment(template.updatedAt).format('DD/MM/YYYY - HH:mm')}
                                                    </span>
                                                </Col>
                                            : ''}
                                        </Row>
                                        <Row className='h-100 d-flex align-items-center'>
                                            <Col>
                                                {template.htmlTemplate ?
                                                    <RenderHtmlAsImage
                                                        html={template.htmlTemplate}
                                                        id={`reportImg-${template.id}-${idx}`}
                                                        isFromReports={true}
                                                    />
                                                : ''}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <Col>
                                <ResultsPerPage
                                    changeSizePerPage={this.changePageSize}
                                    pageSize={pageSize}
                                />

                                <span className="ml-2">
                                    <TotalResults
                                        end={pageIndex * pageSize + reports.length}
                                        start={pageIndex * pageSize + 1}
                                        total={totalItems}
                                    />
                                </span>
                            </Col>
                            <Col className="text-right">
                                <Pagination
                                    isPrevDisabled={pageIndex === 0}
                                    isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                    currentPage={pageIndex + 1}
                                    handlePrevButton={() => this.handlePagination(-1)}
                                    handleNextButton={() => this.handlePagination(1)}
                                />
                            </Col>
                        </Row>
                    </>
                : 
                    <DefaultPage text="Reports.NoNotifyGenericTemplates" icon='fas fa-file-contract' />
                }

                {this.state.reportDetailsModal ?
                    <ReportsDetails
                        modal={reportDetailsModal}
                        report={selectedReport}
                        hotelGroupList={hotelGroupList}
                        eventTypeList={eventTypeList}
                        toggleModal={this.togglePreview}
                        getReports={this.getReports}
                    />
                : ''}
            </StyledCard>
        )
    }
}

export default injectIntl(Reports)