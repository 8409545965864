import React, { Component } from 'react';
import { InputGroup, Row, Col, Button } from 'reactstrap';
import { StyledCard, ReservationStatusBadge, reservationStatus } from '../Base/CommonUIComponents';
import CustomSelect from "../Base/CustomSelect";
import { CustomTable } from '../Base/CustomTable';
import { FormattedMessage, injectIntl } from 'react-intl';
import { DateRangePicker } from 'react-dates';
import { getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import ReservationDetails from './ReservationDetails';
import moment from "moment";

class ReservationList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            reservationList: [],
            startDate: moment().subtract(15, 'days'),
            endDate: moment(),
            modal: false,
            pageIndex: 0,
            pageSize: 10,
            totalItems: 0,
            selectedReservation: null
        }
    }

    componentDidMount() {
        this.getReservations();
    }

    doSearch = () => {
        this.setState({
            pageIndex: 0
        }, () => this.getReservations())        
    }

    getReservations = () => {
        this.setState({ block: true });
        var params = this.state.status ? `&status=${this.state.status}` : '';

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                this.setState({ reservationList: data.response, totalItems: data.totalItems, block: false });
            }
        }, `/api/gms/Reservation/v1/GetReservations?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}&startDate=${this.state.startDate.format("YYYY-MM-DD")}&endDate=${this.state.endDate.format("YYYY-MM-DD")}` + params)
    }

    setComboStatus = (name, combo) => {
        this.setState(({
            [name]: combo ? combo.value : null
        }));
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        this.setState({
            pageIndex: page - 1,
            pageSize: sizePerPage,
        }, () => { this.getReservations() });
    }

    render() {
        const help = <div>TODO</div>

        const columns = [
            {
                dataField: 'pmsId',
                text: this.props.intl.formatMessage({ id: "ReservationList.ReservationId" })
            },
            {
                dataField: 'channelCode',
                text: this.props.intl.formatMessage({ id: "ReservationList.Channel" })
            },
            {
                dataField: 'guestCount',
                text: this.props.intl.formatMessage({ id: "ReservationList.GuestCount" })
            },
            {
                dataField: 'resIdDate',
                text: this.props.intl.formatMessage({ id: "ReservationList.ReservationDate" }),
                formatter: (cell, row) => cell ? moment(cell).format('YYYY-MM-DD') : '',
            },
            {
                dataField: 'amountAfterTax',
                text: this.props.intl.formatMessage({ id: "ReservationList.AmountAfterTax" }),
                formatter: (cell, row) => row.amountAfterTax ? `${parseFloat(row.amountAfterTax).toFixed(2)}` : <div />,
            },
            {
                dataField: 'amountCommission',
                text: this.props.intl.formatMessage({ id: "ReservationList.AmountCommission" }),
                formatter: (cell, row) => row.amountAfterTax ? `${parseFloat(row.amountCommission).toFixed(2)}` : <div />,
            },
            {
                dataField: 'status',
                text: this.props.intl.formatMessage({ id: "ReservationList.Status" }),
                formatter: (cell, row) => row.status ? <ReservationStatusBadge status={row.status} /> : ''
            },
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ modal: true, selectedReservation: row.id });
            }
        };

        return (
            <div>
                <StyledCard icon="fas fa-suitcase" block={this.state.block} error={this.state.error} help={help} title={<FormattedMessage id="ReservationList.Title" />}>
                    <Row className="mb-4">
                        <Col className="col-2">
                            <CustomSelect
                                icon={'icon-icon-e-goi-config'}
                                options={reservationStatus}
                                placeholder={<FormattedMessage id="ReservationList.Status" />}
                                onChange={this.setComboStatus.bind(this, 'status')}
                                isClearable isSearchable
                            />
                        </Col>
                        <Col className="px-1">
                            <InputGroup size="sm" >
                                <DateRangePicker
                                    startDate={this.state.startDate}
                                    startDateId="your_unique_start_date_id"
                                    isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment().add(6, 'month')}
                                    endDate={this.state.endDate}
                                    endDateId="your_unique_end_date_id"
                                    onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    small={true}
                                    numberOfMonths={2}
                                    showDefaultInputIcon={true}
                                />
                            </InputGroup>
                        </Col>
                        <Col className="col-1 text-right">
                            <Button className="btn btn-sm btn-host" onClick={this.doSearch}>
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CustomTable
                                data={this.state.reservationList}
                                columns={columns}
                                showTotal={true}
                                selectRow={selectRow}
                                remote={true}
                                page={this.state.pageIndex + 1}
                                sizePerPage={this.state.pageSize}
                                onTableChange={this.handleTableChange}
                                totalSize={this.state.totalItems}
                            />
                        </Col>
                    </Row>
                </StyledCard>

                {this.state.modal ?
                    <ReservationDetails
                        modal={this.state.modal}
                        toggleModal={this.toggleModal}
                        reservationId={this.state.selectedReservation}
                    />
                : ''}
            </div>
        );
    }
}
export default injectIntl(ReservationList)