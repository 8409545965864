import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Modal, ModalBody, Input } from 'reactstrap';
import { getConjunctionTypeList, getOperatorTypeList, getEntityList, getFieldList } from "../Base/ReferenceDataFunctions";
import { handleNotification } from "../Base/Notification";
import { BlankCard } from '../Base/CommonUIComponents';
import CustomSelect from "../Base/CustomSelect";
import { putAPI } from "../Base/API";

class RuleFiltersDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            ruleFilter: this.props.selectedRuleFilter ? this.props.selectedRuleFilter : {},
            fieldOptions: null
        };
    }

    componentDidMount() {
        if (this.props.selectedRuleFilter) {
            var field = getFieldList(this.props.selectedRuleFilter.entity, this.props.intl).find(el => el.value === this.props.selectedRuleFilter.field);

            this.setState({ fieldOptions: field.fieldOptions });
        }
    }

    handleChange = (e, key) => {
        const { name, value } = e.target;

        if (key) {
            this.setState({
                ruleFilter: {
                    ...this.state.ruleFilter,
                    [name]: value
                }
            })
        }
        else {
            this.setState({
                [name]: value
            })
        }
    }
    
    saveRuleFilter = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var ruleFilter = { ...this.state.ruleFilter };
        ruleFilter.ruleId = ruleFilter.ruleId ? ruleFilter.ruleId : this.props.ruleId;

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="RuleFiltersDetails.RuleFilterSaved" />, <FormattedMessage id="generic.success" />);
                    this.props.updateTable(data.response[0], 'ruleFilters');
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/DataQuality/v1/RuleFilter`, JSON.stringify({ request: ruleFilter }));
    }

    handleValues = (evt, type, value) => {
        evt.preventDefault();
        var values = this.state.ruleFilter.values ? [...this.state.ruleFilter.values] : [];

        if (type === "add") {
            if (values.indexOf(this.state.value) < 0) {
                values.push(this.state.value);
            }
        }
        else {
            values = values.filter(e => e !== value)
        }

        this.setState({
            ruleFilter: {
                ...this.state.ruleFilter,
                values
            },
            value: null
        });
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
            ruleFilter: {
                ...this.state.ruleFilter,
                [name]: combo ? combo.value : null
            }
        })
    };

    handleChangeEntity = (combo, name) => {
        this.setState({
            ruleFilter: {
                ...this.state.ruleFilter,
                [name]: combo ? combo.value : null,
                field: null,
                values: null
            },
            fieldOptions: null
        })
    };

    handleChangeValues = (combo) => {
        var values = '';

        values = combo ? combo.map(el => el.value) : null;

        this.setState({
            ruleFilter: {
                ...this.state.ruleFilter,
                values
            }
        });
    };

    handleChangeField = (combo, name) => {
        this.setState({
            ruleFilter: {
                ...this.state.ruleFilter,
                [name]: combo ? combo.value : null,
                values: !combo || (combo.fieldOptions !== this.state.fieldOptions) ? null : this.state.ruleFilter.values
            },
            fieldOptions: combo ? combo.fieldOptions : null
        })
    };


    render() {
        const { block, error, ruleFilter, fieldOptions } = this.state;

        return (
            <Modal isOpen={this.props.modal} >
                <Button onClick={this.props.toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <Form onSubmit={this.saveRuleFilter}>
                            <Row className="mb-3">
                                <Col>
                                    <h5> {this.props.selectedRuleFilter ? <FormattedMessage id="RuleFiltersDetails.EditRuleFilter" /> : <FormattedMessage id="RuleFiltersDetails.AddRuleFilter" />}</h5>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" type="submit" disabled={!this.state.ruleFilter.values || this.state.ruleFilter.values.length === 0}> <i className="fas fa-save" /></Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}> <FormattedMessage id="RuleFiltersDetails.Entity" /></Label>
                                        <Col sm={8}>
                                            <CustomSelect
                                                options={getEntityList()}
                                                required
                                                isClearable
                                                isSearchable
                                                placeholder={""}
                                                onChange={(e) => this.handleChangeEntity(e, "entity")}
                                                value={ruleFilter.entity && getEntityList().find(el => el.value === ruleFilter.entity) || ''}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}> <FormattedMessage id="RuleFiltersDetails.Field" /></Label>
                                        <Col sm={8}>
                                            <CustomSelect
                                                options={getFieldList(ruleFilter.entity, this.props.intl)}
                                                required
                                                isClearable
                                                isSearchable
                                                placeholder={""}
                                                isDisabled={ruleFilter && ruleFilter.entity ? false : true}
                                                onChange={(e) => this.handleChangeField(e, "field")}
                                                value={ruleFilter.field && getFieldList(ruleFilter.entity, this.props.intl).find(el => el.value === ruleFilter.field) || ''}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}> <FormattedMessage id="RuleFiltersDetails.ConjunctionType" /></Label>
                                        <Col sm={8}>
                                            <CustomSelect
                                                options={getConjunctionTypeList()}
                                                required
                                                isClearable
                                                isSearchable
                                                placeholder={""}
                                                onChange={(e) => this.handleChangeSelect(e, "conjunctionType")}
                                                value={getConjunctionTypeList().find(el => el.value === ruleFilter.conjunctionType) || ''}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}> <FormattedMessage id="RuleFiltersDetails.OperatorType" /></Label>
                                        <Col sm={8}>
                                            <CustomSelect
                                                options={getOperatorTypeList()}
                                                required
                                                isClearable
                                                isSearchable
                                                placeholder={""}
                                                onChange={(e) => this.handleChangeSelect(e, "operatorType")}
                                                value={getOperatorTypeList().find(el => el.value === ruleFilter.operatorType) || ''}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>

                            {!fieldOptions ?
                                <div>
                                    <FormGroup row className="align-items-center">
                                        <Label sm={2}> <FormattedMessage id="RuleFiltersDetails.Values" /></Label>
                                        <Col sm={9}>
                                            <Input
                                                type="text"
                                                name="value"
                                                placeholder={""}
                                                onChange={(e) => this.handleChange(e, null)}
                                                value={this.state.value ? this.state.value : ''}
                                            />
                                        </Col>
                                        <Col className="text-right">
                                            <Button sm={1} className="btn-sm btn-host" onClick={(e) => this.handleValues(e, 'add')} disabled={this.state.value ? false : true}>
                                                <i className="fas fa-plus" />
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                    <Row>
                                        <Col className="col-2"/>
                                        <Col className="col-10">
                                            <ul className="pl-3">
                                                {ruleFilter.values && ruleFilter.values.length > 0 && ruleFilter.values.map((el, key) =>
                                                    <li key={key}>
                                                        <Row className="overbglight">
                                                            <Col> {el} </Col>
                                                            {<Col className="col-2 text-right pr-4" onClick={(e) => this.handleValues(e, 'delete', el)} style={{ color: 'red', cursor: 'pointer' }}>
                                                                <i className="fas fa-times"></i>
                                                            </Col>}
                                                        </Row>
                                                    </li>
                                                )}
                                            </ul>
                                        </Col>
                                    </Row>
                                </div>
                                :
                                <FormGroup row>
                                    <Label sm={2}> <FormattedMessage id="RuleFiltersDetails.Values" /></Label>
                                    <Col sm={10}>
                                        <CustomSelect
                                            options={fieldOptions}
                                            required
                                            isClearable
                                            isSearchable
                                            isMulti
                                            placeholder={""}
                                            onChange={(e) => this.handleChangeValues(e)}
                                            value={ruleFilter.values?.map(v => fieldOptions.find(fo => fo.value.toString() === v.toString())) || ''}
                                        />
                                    </Col>
                                </FormGroup>
                            }
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(RuleFiltersDetails)