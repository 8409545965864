
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { AssociateProfile } from '../Card/VoucherDetails/AssociationModals';

class CoolSelectProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value || "",
            associationModal: false,
            createModal: false
        };
    }
    
    getValue = () => {
        if (this.props.value !== undefined) {
            return this.props.value;
        }
        return this.state.value || "";
    }

    toggleModal = () => {
        this.setState({ associationModal: !this.state.associationModal });
    }

    toggleCreateModal = () => {
        this.setState({ createModal: !this.state.createModal });
    }

    stopPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    selectProfile = (profile) => {
        this.setState({
            selectedProfile: {
                profileId: profile.id,
                name: `${profile.firstName ? profile.firstName : ''} ${profile.lastName ? profile.lastName : ''}`
            }
        });
    }

    associateProfile = () => {
        this.props.associateProfile(this.state.selectedProfile);
        this.setState({ associationModal: false });
    }
    
    associateNewProfile = (profile, firstName, lastName) => {
        this.setState({
            selectedProfile: {
                profileId: profile.id,
                name: `${firstName ? firstName : ''} ${lastName ? lastName : ''}`
            }
        });
    }

    render() {
        const { value, required, enableCreate = true, label = this.props.intl.formatMessage({ id: "generic.Entity" }), disableMasterFilter= true } = this.props;
        const { associationModal, createModal, selectedProfile } = this.state;
        
        return (
            <>
                <div onClick={this.toggleModal} style={{ borderRadius: '0.25rem', maxHeight: '38px', minHeight: '38px' }} className='d-flex aling-items-center justify-content-betwee bg-white shadow py-2 pl-3 pr-1'>
                    <div className='' style={{ width: '100%', color: value ? '' : '#808080' }}>
                        {value ?? label}
                    </div>
                    <div className='clickPlus'>
                        {value ?
                            <i className='fas fa-edit' style={{ fontSize: '0.6em' }}/>
                        :
                            '+'
                        }
                    </div>
                </div>
                {required ?
                    <div style={{ position: 'relative' }}>
                        <input
                            tabIndex={-1}
                            autoComplete="off"
                            style={{ opacity: 0, width: "95%", height: 0, position: 'absolute' }}
                            value={this.getValue()}
                            onChange={() => { }}
                            onFocus={() => this.selectRef.focus()}
                            required={required}
                        />
                    </div>
                :''}
                <form onSubmit={this.stopPropagation}>
                    {associationModal ?
                        <AssociateProfile
                            modal={associationModal}
                            toggleModal={this.toggleModal}
                            associateProfileOrReservation={this.associateProfile}
                            selectProfile={this.selectProfile}
                            profile={selectedProfile}
                            toggleCreateModal={this.toggleCreateModal}
                            associateNewProfile={this.associateNewProfile}
                            createModal={createModal}
                            disableMasterFilter={disableMasterFilter}
                            enableCreate={enableCreate}
                        />
                    :''}
                </form>
            </>
        );
    }
}

export default injectIntl(CoolSelectProfile);
