import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import { Row, Col, Button, Card, CardBody } from 'reactstrap';
import moment from 'moment';
import { ActiveInactiveStatusBadge, DefaultPage, StyledCard } from '../../Base/CommonUIComponents';
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { deepClone, getCountTypeOptions, getProfileType } from '../../Base/ReferenceDataFunctions';
import PriceListRulesModal from './PriceListRulesModal';


class PriceListRules extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            configs: [],
            hotels: [],
            priceLists: {},
            selectedConfig: null
        };
    }

    componentDidMount() {
        this.getPriceListRulesConfigs();
    }

    getPriceListRulesConfigs = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else {
                    this.setState({ configs: data.data ? data.data : [] }, this.getPriceLists);
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/PriceListRuleConfig/v1`)
    }

    getPriceLists = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.response) {
                    const hotels = data.response
                        .reduce((acc, curr) => {
                            if (!acc.some(htl => htl.value === curr.hotelId)) {
                                const hotelOpt = {
                                    value: curr.hotelId,
                                    label: curr.hotelName
                                };

                                acc.push(hotelOpt);
                            }

                            return acc;
                        }, []);

                    const priceLists = data.response
                        .reduce((acc, curr) => {
                            const opt = {
                                value: curr.code,
                                label: curr.code
                            };

                            if (Object.keys(acc).some(k => k === curr.hotelId)) {
                                acc[curr.hotelId].push(opt);
                            }
                            else {
                                acc[curr.hotelId] = [opt];
                            }

                            return acc;
                        }, {});

                    this.setState({ error: errorMessage, block: false, priceLists, hotels });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Hotel/v1/ReferenceData?type=PriceList`);
    }

    editPriceListRulesConfig = (config) => {
        this.setState({ priceListRulesModal: true, selectedConfig: deepClone(config) });
    }

    togglePriceRulesModal = () => {
        this.setState(prevState => ({ priceListRulesModal: !prevState.priceListRulesModal, selectedConfig: null }));
    }

    updateSelectedConfig = (newConfig) => {
        const { configs } = this.state;

        const idx = configs.findIndex(cfg => cfg.id === newConfig.id);
        if (idx !== -1) {
            configs[idx] = newConfig;
        }
        else {
            configs.push(newConfig);
        }

        this.setState({ configs });
    }

    render() {
        const { block, error, configs, priceListRulesModal, selectedConfig, hotels, priceLists } = this.state;

        const countPeriodOptions = [
            {
                value: 'Month',
                label: <FormattedMessage id="PriceListRules.Month" />
            },
            {
                value: 'Year',
                label: <FormattedMessage id="PriceListRules.Year" />
            }
        ];

        return (
            <StyledCard block={block} error={error} icon={'fas fa-dollar-sign'} title={'NavMenu.PriceListRules'}>
                {
                    priceListRulesModal ?
                        <PriceListRulesModal
                            modal={priceListRulesModal}
                            toggle={this.togglePriceRulesModal}
                            selectedConfig={selectedConfig}
                            countPeriodOptions={countPeriodOptions}
                            priceLists={priceLists}
                            hotels={hotels}
                            updateSelectedConfig={this.updateSelectedConfig}
                        />
                        :
                        ''
                }
                <Row className="mb-2">
                    <Col className="text-right">
                        <Button className="btn btn-host btn-sm" onClick={this.togglePriceRulesModal}>
                            <i className="fas fa-plus" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    {
                        configs.length > 0 ?
                            configs.map((config, key) =>
                                <Col className="mb-2 col-6" key={key}>
                                    <Card className="border-0 shadow mb-3 h-100" body>
                                        <Row className="mb-2">
                                            <Col className="text-right">
                                                <Button className="btn btn-host btn-sm" onClick={_ => this.editPriceListRulesConfig(config)}>
                                                    <i className="fas fa-edit" />
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <h6>
                                                    <b>
                                                        <FormattedMessage id="PriceListRules.ProfileType" />
                                                    </b>
                                                </h6>
                                            </Col>
                                            <Col>{getProfileType(this.props.intl).find(prf => prf.value === config.profileType)?.label}</Col>
                                            <Col>
                                                <h6>
                                                    <b>
                                                        <FormattedMessage id="PriceListRules.CountType" />
                                                    </b>
                                                </h6>
                                            </Col>
                                            <Col>{getCountTypeOptions().find(prf => prf.value === config.countType)?.label}</Col>
                                            <Col className="col-1">
                                                <ActiveInactiveStatusBadge status={config.active} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <h6>
                                                    <b>
                                                        <FormattedMessage id="PriceListRules.CountPeriod" />
                                                    </b>
                                                </h6>
                                            </Col>
                                            <Col>{countPeriodOptions.find(prf => prf.value === config.countPeriod)?.label}</Col>
                                            <Col>
                                                <h6>
                                                    <b>
                                                        <FormattedMessage id="PriceListRules.LastRun" />
                                                    </b>
                                                </h6>
                                            </Col>
                                            <Col>{config.lastRun ? moment(config.lastRun).format("YYYY-MM-DD HH:mm:ss") : '-'}</Col>
                                            <Col className="col-1" />
                                        </Row>
                                        <h6>
                                            <b>
                                                <FormattedMessage id="PriceListRules.ConfigPoints" />
                                            </b>
                                        </h6>
                                        {
                                            config.priceListRuleConfigPoints && config.priceListRuleConfigPoints.map((pnt, pntKey) =>
                                                <Row key={pntKey} className="mb-3">
                                                    <Col>
                                                        <Card className="shadow border-0 mb-1">
                                                            <CardBody className="py-2">
                                                                <Row className="mb-3">
                                                                    <Col>
                                                                        <FormattedMessage id="PriceListRules.From" />
                                                                        {` ${pnt.countPerPeriod} `}
                                                                        {getCountTypeOptions().find(tp => tp.value === config.countType)?.label}
                                                                    </Col>
                                                                </Row>
                                                                <hr className="mt-1" />
                                                                <Row className="mb-3">
                                                                    <Col>
                                                                        <b>
                                                                            <FormattedMessage id="PriceListRules.Hotel" />
                                                                        </b>
                                                                    </Col>
                                                                    <Col>
                                                                        <b>
                                                                            <FormattedMessage id="PriceListRules.PriceList" />
                                                                        </b>
                                                                    </Col>
                                                                </Row>
                                                                {
                                                                    pnt.priceListRuleConfigPointHotel && pnt.priceListRuleConfigPointHotel.map((pointHotel, pointHotelKey) =>
                                                                        <Row key={pointHotelKey} className="mb-3">                                                                            
                                                                            <Col>
                                                                                {hotels.find(htl => htl.value === pointHotel.hotelId)?.label}
                                                                            </Col>                                                                            
                                                                            <Col>
                                                                                {priceLists[pointHotel.hotelId] && priceLists[pointHotel.hotelId].find(prl => prl.value === pointHotel.priceListCode)?.label}
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                }
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                    </Card>
                                </Col>
                            )
                            :
                            <Col>
                                <DefaultPage text="PriceListRules.NoConfigs" icon="fas fa-dollar-sign" />
                            </Col>
                    }
                </Row>
            </StyledCard>
        );
    }
}

export default injectIntl(PriceListRules);