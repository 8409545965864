import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Col, Row, Breadcrumb, BreadcrumbItem, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Pagination, TotalResults } from '../../../Base/PaginationComponents';
import { getAPI } from "../../../Base/API";
import { handleNotification } from "../../../Base/Notification";
import { StyledCard } from "../../../Base/CommonUIComponents";
import { DateRangePicker } from 'react-dates';
import moment from "moment";

import Authorization from '../../../Base/Authorization';
import { improvFormObj, FormAnswers } from '../../../Marketing/Common/MarketingFunctions';
import AssociateProfile from './AssociateProfile';

export class PNFormAnswers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            modal: false,
            inquiryList: [],
            datei: moment().add(-7, "days"),
            datef: moment(),
            inquiry: null,
            lodgings: [],
            pageIndex: 0,
            pageSize: 9,
            totalItems: 0,
            surveyName: null
        };
    }

    componentDidMount() {
        this.getFormAnswers();

        if (this.props.history.location && this.props.history.location.state && this.props.history.location.state.surveyName) {
            this.setState({ surveyName: this.props.history.location.state.surveyName });
        }
        else {
            this.getPNFormDetails();
        }
    }

    getPNFormDetails = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ surveyName: data.response ? data.response[0].name : null });
            }
        }, `/api/gms/Survey/v1/survey/${this.props.match.params.id}`);
    }

    getFormAnswers = () => {
        this.setState({ block: true });

        var params = `&pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}&getSurvey=true`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                
                const surveys = improvFormObj(data.response);

                this.setState({
                    pnSurveys: surveys,
                    totalItems: data.totalItems
                });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/profile/forms/PNForm/${this.props.match.params.id}?fromDate=${moment(this.state.datei).format('YYYY-MM-DD')}&toDate=${moment(this.state.datef).format('YYYY-MM-DD')}` + params);
    }

    updateForm = (answerId, profileId) => {
        const { pnSurveys } = this.state;
        const index = pnSurveys.findIndex(s => s.id === answerId);

        if (index !== -1) {
            pnSurveys[index].profileId = profileId;
        }

        this.setState({ pnSurveys, modal: false });
    }

    toggleModal = (answerId) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedAnswer: answerId
        }))
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    };

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.getFormAnswers());
    }

    goToProfileDetails = (profileId) => {
        if(!profileId) return;
        this.props.history.push(`/ProfileDetails/${profileId}`);
        this.props.history.go();
    }

    render() {
        const { block, error, totalItems, pageSize, pageIndex, surveyName, pnSurveys, modal } = this.state;

        return (
            <StyledCard block={block} error={error} >
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to="/ProfileNowConfig"> 
                                <FormattedMessage id="ProfileNowMenu.Title"/>
                            </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active> {surveyName || 'Form'} </BreadcrumbItem>
                    </Breadcrumb>
                </div>


                <Row className="mb-3">
                    <Col sm={3}>
                        <DateRangePicker
                            startDate={this.state.datei ? moment(this.state.datei) : null}
                            startDateId="startDate"
                            isOutsideRange={day => day > moment()}
                            endDate={this.state.datef ? moment(this.state.datef) : null}
                            endDateId="endDate"
                            onDatesChange={({ startDate, endDate }) => this.setState({ datei: startDate, datef: endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                            required
                        />
                    </Col>
                    <Col className="text-right">
                        <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.getFormAnswers()}>
                            <i className="icon-icon-search" />
                        </Button>

                        <Authorization
                            perform="PNForm:map"
                            yes={() => (
                                <>
                                    <Button className="btn- btn-host btn-small btn-sm ml-2" id="mapForm" tag={Link} to={{ pathname: '/MapPNForm', search: `?form=${this.props.match.params.id}` }}>
                                        <i className="fas fa-random" />
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="mapForm">
                                        <FormattedMessage id="MapFormModal.Map" />
                                    </UncontrolledTooltip>
                                </>
                            )}
                            no={() => <div></div>}
                        />
                    </Col>
                </Row>

                <Row>
                    {pnSurveys && pnSurveys.map((survey, k) => {
                        return <Col className="col-4 mb-4" key={k}>
                            <FormAnswers answers={survey.answers} form={survey.rawForm} isPNForm={true}>
                                <Row>
                                    <Col className="col-8">
                                        <div>
                                            <h5 className="m-0 text-host"> <FormattedMessage id="MapFormModal.Answer" /> {k + 1}</h5>
                                        </div>
                                    </Col>
                                    <Col className="text-right d-flex aling-items-center justify-content-end">
                                        {survey.responseDate ? moment(survey.responseDate).format("YYYY-MM-DD") : ''}
                                        <UncontrolledDropdown>
                                            <DropdownToggle className='ml-2' style={{ background: 'none', border: 'none', color: 'unset', padding: 'unset', width: '10px' }}>
                                                <i className="fas fa-ellipsis-v"/>
                                            </DropdownToggle>
                                            {!survey.profileId ?
                                                <div className='ml-2'>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={_ => this.toggleModal(survey.id)}>
                                                            <i className="fas fa-user-edit mr-2" />
                                                            <FormattedMessage id="MapFormModal.AssociateProfile"/>
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                    
                                                </div>
                                            :
                                                <div className='ml-2'>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={_ => this.goToProfileDetails(survey.profileId)}>
                                                            <i class="fas fa-user-alt mr-2"></i>
                                                            <FormattedMessage id="MapFormModal.GoToProfileDetails"/>
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </div>
                                            }
                                        </UncontrolledDropdown>
                                    </Col>
                                </Row>

                                <hr />
                            </FormAnswers>
                        </Col>
                    })}
                </Row>

                {modal ?
                    <AssociateProfile
                        modal={modal}
                        toggleModal={this.toggleModal}
                        answerId={this.state.selectedAnswer}
                        updateForm={this.updateForm}
                    />
                : ''}

                {totalItems > 0 ?
                    <Row className="mb-4">
                        <Col>
                            <span className="ml-2">
                                <TotalResults
                                    end={pageIndex * pageSize + pnSurveys.length}
                                    start={pageIndex * pageSize + 1}
                                    total={totalItems}
                                />
                            </span>
                        </Col>

                        <Col className="text-right mr-3">
                            <Pagination
                                isPrevDisabled={pageIndex === 0}
                                isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                currentPage={pageIndex + 1}
                                handlePrevButton={() => this.handlePagination(-1)}
                                handleNextButton={() => this.handlePagination(1)}
                            />
                        </Col>
                    </Row>
                    : <div className="text-center mt-4"> <FormattedMessage id="FormList.ThereAreNoSubmittedForms" /> </div>}
            </StyledCard>
        );
    }
}
export default injectIntl(PNFormAnswers);