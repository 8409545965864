import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Card, Button, Badge, Label, Input, Form } from 'reactstrap';
import { getMLModelType } from '../../../Base/ReferenceDataFunctions';
import { handleNotification } from "../../../Base/Notification";
import { ActiveInactiveStatusBadge, SelectHotelGroup, StyledCard } from "../../../Base/CommonUIComponents";
import CustomSelect from '../../../Base/CustomSelect';
import { getAPI, postAPI } from "../../../Base/API";
import ModelDetails from './ModelDetails';
import ModelParamDetails from './ModelParamDetails';


class MachineLearningConfig extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            models: [],
            paramDetails: false,
            modelDetails: false
        };
    }

    componentDidMount() {
        this.doSearch();
        this.getHotelGroup();
    }

    doSearch = (e) => {
        if (e) e.preventDefault();
        this.setState({ block: true });

        var params = '';
        if (this.state.hotelGroupId) params += `hotelGroupId=${this.state.hotelGroupId}&`;
        if (this.state.type) params += `type=${this.state.type}`;

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                }
                else {
                    this.setState({ models: data.response, block: false });
                }
            }
        }, `/api/gms/MLModel/v1?` + params)
    }

    getHotelGroup = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                var combo = [];

                data.response.map((item) =>
                    combo.push({ 'value': item.hotelGroupId, 'label': item.name })
                )

                this.setState({ hotelGroupList: combo, block: false });
            }
        }, '/api/gms/Hotel/v1/hotelgroup/list?pageSize=1000&pageIndex=0')
    }

    updateModelList = (model) => {
        const { models } = this.state;
        const index = models.findIndex(el => el.modelId === model.modelId);

        if (index !== -1) {
            models[index] = model;
        }
        else {
            models.push(model);
        }

        this.setState({ models });
    }

    updateModelList = (model) => {
        const { models } = this.state;
        const index = models.findIndex(el => el.modelId === model.modelId);

        if (index !== -1) {
            models[index] = model;
        }
        else {
            models.push(model);
        }

        this.setState({ models, modelDetails: false });
    }

    updateParam = (params, modelId, isToRemove) => {
        const { models } = this.state;
        const modelIdx = models.findIndex(el => el.modelId === modelId);

        if (modelIdx !== -1) {
            if (isToRemove) {
                const idx = models[modelIdx].parameters.findIndex(el => el.id === params[0].id);

                models[modelIdx].parameters.splice(idx, 1);
            }
            else {
                models[modelIdx].parameters = params;
            }
        }

        this.setState({ models, paramDetails: false });
    }

    toggleModal = (modal, objName, object) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            [objName]: object
        }));
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    handleValueFilter = (e, index) => {
        const { value } = e.target;
        const { models } = this.state;

        if (value && models[index].parameters) {
            models[index].parameters.forEach(el => {
                el.show = el.paramValues.toLowerCase().includes(value.toLowerCase());
            })
        }
        else {
            models[index].parameters.forEach(el => el.show = true);
        }


        this.setState({ models });
    }

    render() {
        const { block, error, models, paramDetails, modelDetails, hotelGroupList } = this.state;

        return (
            <StyledCard block={block} error={error} icon="fas fa-brain" title="NavMenu.MachineLearning">
                <Form onSubmit={this.doSearch}>
                    <Row className="my-3 align-items-center">
                        <Col className="col-3">
                            <CustomSelect
                                icon={'icon-icon-hotel'}
                                options={hotelGroupList}
                                isClearable isSearchable
                                placeholder={<FormattedMessage id="generic.HotelGroup" />}
                                onChange={(e) => this.handleChangeSelect('hotelGroupId', e)}
                            />
                        </Col>
                        <Col className="col-3">
                            <CustomSelect
                                options={getMLModelType(this.props.intl)}
                                isClearable isSearchable
                                placeholder={<FormattedMessage id="MachineLearningConfig.Type" />}
                                onChange={(e) => this.handleChangeSelect('type', e)}
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className="btn btn-host btn-sm mr-2" onClick={() => this.toggleModal('modelDetails', 'selectedModel', null)}>
                                <i className="fas fa-plus"></i>
                            </Button>
                            <Button className="btn btn-host btn-sm " type="submit">
                                <i className="icon-icon-search"></i>
                            </Button>
                        </Col>
                    </Row>
                </Form>

                {models?.map((model, key) => {
                    const hotelGroup = hotelGroupList?.find(hg => hg.value === model.hotelGroupId);

                    return <Card className="border-0 shadow mb-3" key={key} body>
                        <Row className="border-bottom pb-3 align-items-center pointer" onClick={() => this.toggleModal('modelDetails', 'selectedModel', model)}>
                            <Col sm={4}>
                                <i className="icon-icon-hotel mr-2" />{hotelGroup?.label}
                            </Col>
                            <Col sm={4} className="text-center">
                                <b><FormattedMessage id={`ReferenceDataFunctions.${model.type}`}/></b>
                            </Col>
                            <Col sm={4} className="text-right">
                                <ActiveInactiveStatusBadge status={model.active} />
                            </Col>
                        </Row>

                        <Row className="my-3 align-items-center">
                            <Col sm={3}>
                                <i className="fas fa-list-ul mr-2" />Params
                            </Col>
                            {model.parameters?.length > 0 ?
                                <Col sm={3}>
                                    <div className="text-muted" style={{ fontSize: '10px' }}>
                                        <FormattedMessage id="MachineLearningConfig.FilterValues" />
                                    </div>
                                    <Input
                                        type="text"
                                        name="paramName"
                                        onChange={(e) => this.handleValueFilter(e, key)}
                                    />
                                </Col>
                            : ''}
                            <Col className="text-right">
                                <Button className="btn btn-host btn-sm" onClick={() => this.toggleModal('paramDetails', 'selectedParam', { modelId: model.modelId })}>
                                    <i className="fas fa-plus"></i>
                                </Button>
                            </Col>
                        </Row>

                        <Row>
                            {model.parameters?.map((param, idx) => param.show !== false &&
                                <Col className="mb-3" key={idx} sm={3}>
                                    <Card className="shadow pointer" body onClick={() => this.toggleModal('paramDetails', 'selectedParam', { ...param, modelId: model.modelId })}>
                                        {param.paramName}
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    </Card>
                } )}

                {modelDetails ?
                    <ModelDetails
                        modal={modelDetails}
                        toggleModal={() => this.toggleModal('modelDetails', 'selectedModel', null)}
                        model={this.state.selectedModel}
                        hotelGroupList={this.state.hotelGroupList}
                        updateModelList={this.updateModelList}
                    />
                : ''}

                {paramDetails ?
                    <ModelParamDetails
                        modal={paramDetails}
                        toggleModal={() => this.toggleModal('paramDetails', 'selectedParam', null)}
                        param={this.state.selectedParam}
                        updateParam={this.updateParam}
                    />
                : ''}
            </StyledCard>
        );
    }
}
export default injectIntl(MachineLearningConfig)