import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { BlankCard, StyledModal } from "../../../../Base/CommonUIComponents";
import { Button, Col, Row, Input, FormGroup, Form } from "reactstrap";
import { getAPI, patchAPI } from "../../../../Base/API";
import { handleNotification } from "../../../../Base/Notification";
import { ActiveInactiveStatusCombo } from "../../../../Base/CommonUIComponents";

export class BouncePurgeModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            settings: {}
        }
    }

    componentDidMount() {
        this.getSettings();
    }

    getSettings = (e) => {
        if (e) e.preventDefault();

        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ settings: data.response, block: false });
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SendGrid/v1/mailsettings/bouncepurge`);
    }

    handleSave = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const { settings } = this.state;

        var params = settings.soft_bounces ? `&softBounces=${settings.soft_bounces}` : '';
        if (settings.hard_bounces) params += `&hardBounces=${settings.hard_bounces}`;

        patchAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ block: false, error: errorMessage });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="MailSettings.SettingsSaved" />, <FormattedMessage id="generic.success" />);

                    this.props.updateSettings();
                }
            }
            this.setState({ block: false });
        }, `/api/gms/SendGrid/v1/mailsettings/bouncepurge?enabled=${settings.enabled}` + params)
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            settings: {
                ...prevState.settings,
                [name]: value
            }
        }));
    }

    handleSelect = (name, combo) => {
        this.setState(prevState => ({
            settings: {
                ...prevState.settings,
                [name]: combo ? combo.value : null
            }
        }));
    };

    render() {
        const { modal, toggleModal } = this.props;
        const { block, error, settings } = this.state;

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="md">
                <BlankCard block={block} error={error}>
                    <Form onSubmit={this.handleSave}>
                        <Row className="align-items-center mb-3">
                            <Col>
                                <h5 className="m-0"> <FormattedMessage id="MailSettings.BouncePurge" /></h5>
                            </Col>
                            <Col className="text-right col-2">
                                <Button className="btn-sm btn-host" type="submit">
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>

                        <div className="text-muted mb-4 py-3" style={{ textAlign: 'justify' }}>
                            <FormattedMessage id="MailSettings.BouncePurgeText" />
                        </div>

                        <FormGroup row className="pt-2">
                            <Col>
                                <div> <FormattedMessage id="generic.status" /> </div>

                                <ActiveInactiveStatusCombo
                                    name='enabled'
                                    onChangeFunc={this.handleSelect}
                                    placeholder={this.props.intl.formatMessage({ id: "generic.status" })}
                                    value={settings.enabled}
                                    required={true}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row className="pt-2">
                            <Col>
                                <div> <FormattedMessage id="MailSettings.DaysUntilPurgingBounces" /></div>
                                <Input
                                    type="number"
                                    onChange={(e) => this.handleChange(e)}
                                    value={settings.soft_bounces || ''}
                                    name="soft_bounces"
                                    step={1}
                                    min={1}
                                    max={3650}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row className="pt-2">
                            <Col>
                                <div><FormattedMessage id="MailSettings.DaysUntilPurgingBlocks" /></div>
                                <Input
                                    type="number"
                                    onChange={(e) => this.handleChange(e)}
                                    value={settings.hard_bounces || ''}
                                    name="hard_bounces"
                                    step={1}
                                    min={1}
                                    max={3650}
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                </BlankCard>
            </StyledModal>
        );
    }
}

export default injectIntl(BouncePurgeModal);