import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getDocumentAllList, getDocumentList } from '../Base/ReferenceDataFunctions';
import DocumentDetails from './DocumentDetails';
import moment from 'moment';

import Authorization, { CheckAuthorization } from '../Base/Authorization';
import { ProfileHistory } from '../Base/HistoryComponents';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import { DefaultPage } from '../Base/CommonUIComponents';


export class ProfileDocuments extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            modal: false,
            deleteModal: false
        }
    }

    toggleModal = (data) => {
        const availableDocuments = this.props.profile.documents ? getDocumentList(this.props.intl).filter(ct => data && data.type === ct.value || this.props.profile.documents.some(pc => pc.type === ct.value) === false) : getDocumentList(this.props.intl);

        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedDoc: data,
            availableDocuments
        }))
    }

    toggleDeleteModal = (selectedDoc) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
            selectedDoc
        }));
    }

    removeDocuments = () => {
        this.props.removeElement(this.props.profile.id, this.state.selectedDoc.id, 'document', 'documents');

        this.toggleDeleteModal();
    }


    render() {
        const { profile, profileId, removeElement, updateTable, canEditProfile } = this.props;
        const { deleteModal } = this.state;

        return (
            <div className="m-2 p-2">
                <Row className="mb-2">
                    <Col>
                        <h5><i className="icon-icon-documents mr-1"> </i><FormattedMessage id="ProfileDetails.ProfileDocument" /></h5>
                    </Col>
                    <Col className="text-right">
                        <ProfileHistory
                            component={"document"}
                            profileId={this.props.profileId}
                        />

                        <Authorization
                            perform="profileDocuments:add"
                            yes={() => canEditProfile && (
                                <Button className="btn-sm btn-host ml-2" onClick={() => this.toggleModal(null)} disabled={profile.documents && profile.documents.length === getDocumentList(this.props.intl).length}>
                                    <i className="fas fa-plus" />
                                </Button>
                            )}
                            no={() => <div></div>}
                        />
                    </Col>
                </Row>

                {profile.documents && profile.documents.map((doc, key) =>
                    <Card className="border-0 mb-2 shadow" key={key}>
                        <CardBody className="p-2">
                            <Row>
                                <Col>
                                    <div style={{ cursor: 'pointer' }} onClick={() => { if (canEditProfile && CheckAuthorization("profileDocuments:add", null)) this.toggleModal(doc) }}>
                                        <Row>
                                            <Col className="col-2" id={`Type-${doc.id}`}>
                                                <i className="fas fa-file-invoice mr-2"></i>
                                                {getDocumentAllList(this.props.intl).find(d => d.value === doc.type) ? getDocumentAllList(this.props.intl).find(d => d.value === doc.type).label : doc.type}
                                            </Col>
                                            <Col id={`Number-${doc.id}`}><b><FormattedMessage id="ProfileDetails.Number" />: </b> {doc.number} </Col>

                                            {doc.type !== 'FiscalNumber' ?
                                                <>
                                                    <Col id={`IssueDate-${doc.id}`}>
                                                        {doc.issueDate && <><b><FormattedMessage id="ProfileDetails.IssueDate" />: </b> {moment(doc.issueDate).format("YYYY-MM-DD")} </>}
                                                    </Col>
                                                    <Col id={`ValidUntil-${doc.id}`}>
                                                        {doc.validUntil &&
                                                            <div className="d-flex">
                                                                <span style={{ width: '16px' }}> {doc.isExpired ? <i className="icon-icon-warnings-enabled text-warning fa-sm" /> : ''}</span>
                                                                <b className="mr-2"><FormattedMessage id="ProfileDetails.ValidUntil" />: </b> {moment(doc.validUntil).format("YYYY-MM-DD")}
                                                            </div>
                                                        }
                                                    </Col>
                                                </>
                                            : ''}
                                        </Row>
                                    </div>
                                </Col>
                                <Authorization
                                    perform="profileDocuments:delete"
                                    yes={() => canEditProfile && (
                                        <Col className="text-right col-1">
                                            <span onClick={() => this.toggleDeleteModal(doc)} style={{ cursor: 'pointer' }}>
                                                <i className="fas fa-trash-alt" />
                                            </span>
                                        </Col>
                                    )}
                                    no={() => <div></div>}
                                />
                            </Row>
                        </CardBody>
                    </Card>
                )}

                {profile.documents == null || profile.documents.length == 0 ?
                    <DefaultPage text="ProfileDetails.NoDocumentsAvailable" icon="icon-icon-documents" />
                : ''}

                {this.state.modal ?
                    <DocumentDetails
                        modal={this.state.modal}
                        toggleModal={() => this.toggleModal()}
                        document={this.state.selectedDoc}
                        profileId={profileId}
                        updateTable={updateTable}
                        availableDocuments={this.state.availableDocuments}
                    />
                : ''}


                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={this.toggleDeleteModal}
                        actionFunction={this.removeDocuments}
                        text={<FormattedMessage id="ProfileDocuments.ConfirmDeleteText" />}
                    />
                : ''}
            </div>
        );
    }
}

export default injectIntl(ProfileDocuments);
