import React from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';


export const Pagination = ({ isPrevDisabled, isNextDisabled, currentPage, handlePrevButton, handleNextButton }) => {
    return (
        <span>
            <Button className="btn btn-host btn-sm" onClick={ handlePrevButton } disabled={isPrevDisabled}>
                <i className="fas fa-angle-left" />
            </Button>
            <span className="mx-2">
                <FormattedMessage id="SearchProfile.Page" values={{ page: currentPage }} />
            </span>
            <Button className="btn btn-host btn-sm" onClick={ handleNextButton } disabled={isNextDisabled}>
                <i className="fas fa-angle-right" />
            </Button>
        </span>
    )
}

export const ResultsPerPage = ({ changeSizePerPage, pageSize }) => {
    return (
        <ButtonGroup onClick={changeSizePerPage}>
            <Button className={(pageSize == 10 ? "bg-host text-white" : "text-secondary") + " page-link"} value={10}> 10 </Button>
            <Button className={(pageSize == 25 ? "bg-host text-white" : "text-secondary") + " page-link"} value={25}> 25 </Button>
            <Button className={(pageSize == 30 ? "bg-host text-white" : "text-secondary") + " page-link"} value={30}> 30 </Button>
            <Button className={(pageSize == 50 ? "bg-host text-white" : "text-secondary") + " page-link"} value={50}> 50 </Button>
        </ButtonGroup>
    )
}

export const TotalResults = ({ total, start, end }) => {
    return (
        <FormattedMessage id="generic.TotalResultsText" values={{ start: start, end: end, total: total }} />
    )
}