import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Col, Row, Form, Label, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Tag, getListOptions, getFieldsToMapForms } from "../../Base/MapFormsComponents";
import { getAllReviewFields, getConsentTypeList } from "../../Base/ReferenceDataFunctions";
import { ActiveInactiveStatusCombo, BlankCard } from "../../Base/CommonUIComponents";
import { postAPI, getAPI } from "../../Base/API";
import { handleNotification } from "../../Base/Notification";
import CustomSelect from '../../Base/CustomSelect';
import { FormattedMessage } from "react-intl";
import AddTagsModal from '../../ReviewPro/Surveys/AddTagsModal';
import { Link } from 'react-router-dom';


export class MapFormModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            addTagsModal: false,
            questionsList: [],
            reviews: getAllReviewFields(),
            consents: getConsentTypeList(),
            fields: getFieldsToMapForms(),
            formMap: {
                active: true,
                fieldId: null,
                reviewMappingFields: []
            },
            fieldKeys: [],
            tags: [],
            questions: { keys: [], allQuestions: [] }
        };
    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let listId = params.get('list');
        let formId = params.get('form');
        let questions = { keys: [], allQuestions: [] };
        let formTitle = ''

        if (this.props.location.state) {
            if (this.props.location.state.questions) questions = this.props.location.state.questions;

            formTitle = this.props.history.location.state.formTitle;
        }
        else {
            this.getFormName(listId, formId);
        }

        this.setState({
            listId, formId, questions, formTitle
        }, this.getMap);
    }

    getFormName = (listId, formId) => {
        this.setState({ block: true });


        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                const form = data.response && data.response.find(el => el.id == formId);

                this.setState({ formTitle: form && form.title });
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/Forms?listId=${listId}`);
    }

    getMap = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    let reviews = [...this.state.reviews];

                    if (data.response[0].reviewMappingFields) {
                        data.response[0].reviewMappingFields.map(el => reviews = reviews.filter(r => r.value !== el.reviewMapField))
                    }

                    this.setState({
                        formMap: data.response[0],
                        reviews
                    });
                }
            }
            this.setState({ error: errorMessage },
                () => !this.state.questions || this.state.questions.allQuestions.length === 0 ? this.getFormsSubmissions() : this.getTagGroups());
        }, `/api/gms/Review/v1/reviewMapping?listId=${this.state.listId}&formId=${this.state.formId}`);
    }

    getFormsSubmissions = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                else if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.response && data.response.length > 0) {
                    this.buildData(data.response);
                }
            }
            this.setState({ error: errorMessage, block: false }, this.getTagGroups);
        }, `/api/gms/Marketing/v1/FormsSubmissions?listId=${this.state.listId}&formId=${this.state.formId}`);
    }

    getTagGroups = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                var list = [];

                data.response && data.response.forEach(group => {
                    const obj = { label: group.code, options: [] };

                    group.tag && group.tag.forEach(tag => {
                        obj.options.push({ value: tag.id, label: tag.code });
                    })

                    list.push(obj);
                })

                this.setState({ tags: list });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/tag/groupDetails?tagType=all`);
    }

    buildData = (data) => {
        let questions = { keys: [], allQuestions: [] };

        data.forEach((answer) => {

            //Main questions that are repeated due to having "sub-questions"
            const repeatedQuestions = answer.inquiryAnswers.reduce((array, inquiry) => {
                if (inquiry.subQuestion && !array.some(el => el === inquiry.question)) {
                    array.push(inquiry.question);
                }

                return array;
            }, [])


            answer.inquiryAnswers.forEach((item) => {
                if (repeatedQuestions.some(el => el === item.question)) {
                    //Add to questions object for map modal
                    if (!questions.allQuestions.some(k => k.mainQuestion === item.question)) {
                        questions.allQuestions.push({ mainQuestion: item.question });
                    }
                    if (!questions.allQuestions.some(k => k.question === item.subQuestion)) {
                        questions.allQuestions.push({ question: item.subQuestion });
                    }
                }
                else {
                    //Add to questions object for map modal
                    if (!questions.keys.some(k => k.value === item.question)) {
                        questions.keys.push({ value: item.question, label: item.question });
                    }
                }
            })
        })

        if (questions.keys) {
            [...questions.keys].reverse().forEach(k => questions.allQuestions.unshift({ question: k.value }));
        }

        this.setState({ questions });
    }

    mapForm = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const body = {...this.state.formMap }
        body.formId = body.formId ? body.formId : this.state.formId;
        body.listId = body.listId ? body.listId : this.state.listId;
        body.provider = 'Egoi';

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="MapFormModal.FormMapped" />, <FormattedMessage id="generic.success" />);
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Review/v1/reviewMapping`, JSON.stringify({ request: body }));
    }

    handleSelectMap = (combo, question, mapType) => {
        const mappingFields = [...this.state.formMap.reviewMappingFields];
        const index = mappingFields.findIndex(el => el.questionMap === question && el.mapType === mapType);

        let availComboOptions = [];
        const comboOptions = getListOptions(mapType);
        const comboName = mapType === 'Review' ? 'reviews' : mapType === 'Consent' ? 'consents' : 'fields';

        if (index === -1) {
            mappingFields.push({
                reviewMapField: combo ? combo.value : null,
                questionMap: question,
                mapType
            })
        }
        else {
            if (combo) {
                mappingFields[index].reviewMapField = combo.value;
            }
            else {
                mappingFields.splice(index, 1)
            }
        }


        //Combo fields can only be used 1 time each.
        comboOptions.forEach(el => {
            if (!mappingFields.some(rmf => rmf.reviewMapField === el.value)) {
                availComboOptions.push(el);
            }
        })


        this.setState({
            formMap: {
                ...this.state.formMap,
                reviewMappingFields: mappingFields
            },
            [comboName]: availComboOptions
        });
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
            formMap: {
                ...this.state.formMap,
                [name]: combo ? combo.value : null
            }
        })
    };

    getComboValue = (question, mapType) => {
        let value = '';

        const reviewMapping = this.state.formMap.reviewMappingFields && this.state.formMap.reviewMappingFields.find(rm => rm.questionMap === question && rm.mapType === mapType);

        if (reviewMapping) {
            const options = getListOptions(mapType);

            value = options.find(r => r.value === reviewMapping.reviewMapField);
        }

        return value;
    }

    getTagMappings = (question) => {
        let tags = this.state.formMap.reviewMappingFields && this.state.formMap.reviewMappingFields.reduce((acc, cur, idx) => {
            if (cur.questionMap === question && cur.mapType === 'Tag') {
                cur.arrayIndex = idx; //auxiliar index for easier tags removal
                acc.push(cur);
            }

            return acc;
        }, [])

        return tags;
    }

    addTagsToMap = (e, tagMaps) => {
        e.preventDefault();
        let mappingFields = [...this.state.formMap.reviewMappingFields];

        mappingFields = mappingFields.concat(tagMaps);

        this.setState({
            formMap: {
                ...this.state.formMap,
                reviewMappingFields: mappingFields
            }
        }, () => this.toggleModal(null));
    }

    removeTagMapping = (e, index) => {
        e.preventDefault();
        let mappingFields = [...this.state.formMap.reviewMappingFields];

        mappingFields.splice(index, 1);

        this.setState({
            formMap: {
                ...this.state.formMap,
                reviewMappingFields: mappingFields
            },
        });
    }

    toggleModal = (question) => {
        if (question) {
            question.label = question.question;
        }

        this.setState(prevState => ({
            addTagsModal: !prevState.addTagsModal,
            selectedQuestion: question
        }));
    }

    handleStatus = (name, combo) => {
        const { formMap } = this.state;
        formMap[name] = combo && combo.value;

        this.setState({ formMap });
    }

    render() {
        const { block, error, reviews, formMap, selectedQuestion, tags, addTagsModal, questions, consents, fields, formTitle } = this.state;

        return (
            <div>
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/MarketingConfigEgoi"> Config</Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to="/FormList"> Forms </Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to={`/FormSubmissions?list=${this.state.listId}&form=${this.state.formId}`}> {formTitle || 'Form'}</Link></BreadcrumbItem>
                        <BreadcrumbItem active><FormattedMessage id={`MapFormModal.Map`} /></BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <BlankCard block={block} error={error}>
                    <Form onSubmit={(e) => this.mapForm(e)}>
                        <Row className="mb-3">
                            <Col>
                                <h5> <FormattedMessage id={`MapFormModal.Map`} /> <b> {formTitle} </b> </h5>
                            </Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" type="submit">
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                        </Row>
                        <Row className="mt-2 mb-5 overbglight">
                            <Label sm={3}> <FormattedMessage id="MapFormModal.KeyField" /></Label>
                            <div className="pl-3" style={{ width: '36.5%' }}>
                                <CustomSelect
                                    isSearchable
                                    placeholder={""}
                                    options={questions && questions.keys}
                                    onChange={(e) => this.handleChangeSelect(e, "fieldId")}
                                    value={formMap.fieldId ? questions.keys.find(k => k.value === formMap.fieldId) : ''}
                                    required
                                />
                            </div>
                            <Col className="col-1">
                                <FormattedMessage id="generic.status" />
                            </Col>
                            <Col className="col-2">
                                <ActiveInactiveStatusCombo
                                    name='active'
                                    onChangeFunc={this.handleStatus}
                                    value={formMap.active}
                                />
                            </Col>
                        </Row>

                        {formMap.fieldId ?
                            <div>
                                <Row className="my-4">
                                    <Col sm={3}> <b><FormattedMessage id="MapFormModal.Question" /></b></Col>
                                    <Col> <b><FormattedMessage id="MapFormModal.ReviewField" /></b></Col>
                                    <Col> <b><FormattedMessage id="MapFormModal.Consent" /></b></Col>
                                    <Col> <b><FormattedMessage id="MapFormModal.Field" /></b></Col>
                                    <Col sm={2}> <b><FormattedMessage id="MapFormModal.Tags" /></b> </Col>
                                </Row>

                                {questions.allQuestions && questions.allQuestions.filter(q => q.question !== formMap.fieldId).map((question, key) => {
                                    const tagMappings = this.getTagMappings(question.question);

                                    return question.mainQuestion ?
                                        <Row className="my-4" key={key}>
                                            <Col> {question.mainQuestion}</Col>
                                        </Row>
                                        :
                                        <Row className="my-3 overbglight" key={key}>
                                            <Col sm={3}> {question.question} </Col>

                                            <Col>
                                                <CustomSelect
                                                    isSearchable isClearable
                                                    placeholder={""}
                                                    options={reviews}
                                                    onChange={(e) => this.handleSelectMap(e, question.question, 'Review')}
                                                    value={this.getComboValue(question.question, 'Review')}
                                                />
                                            </Col>

                                            <Col>
                                                <CustomSelect
                                                    isSearchable isClearable
                                                    placeholder={""}
                                                    options={consents}
                                                    onChange={(e) => this.handleSelectMap(e, question.question, 'Consent')}
                                                    value={this.getComboValue(question.question, 'Consent')}
                                                />
                                            </Col>
                                            <Col>
                                                <CustomSelect
                                                    isSearchable isClearable
                                                    placeholder={""}
                                                    options={fields}
                                                    onChange={(e) => this.handleSelectMap(e, question.question, 'Field')}
                                                    value={this.getComboValue(question.question, 'Field')}
                                                />
                                            </Col>

                                            <Col className="d-flex align-items-center" sm={2}>
                                                {tagMappings.map((mappedTag, idx) => {
                                                    const tag = this.state.tags.flatMap(t => t.options).find(t => t.value === mappedTag.tagId);

                                                    return <Tag
                                                        idx={idx}
                                                        tagLabel={tag ? tag.label : mappedTag.tagId}
                                                        tagValue={mappedTag.tagValue}
                                                        removeTagMapping={(e) => this.removeTagMapping(e, mappedTag.arrayIndex)}
                                                    />
                                                })}

                                                <Button className="btn-sm bg-host-gradient" onClick={() => this.toggleModal(question)}>
                                                    <i className="fas fa-plus" /> <FormattedMessage id="MapFormModal.NewTag" />
                                                </Button>
                                            </Col>
                                        </Row>
                                })}
                            </div>
                        : ''}
                    </Form>
                </BlankCard>

                {addTagsModal ?
                    <AddTagsModal
                        tags={tags}
                        selectedQuestion={selectedQuestion}
                        addTagModal={addTagsModal}
                        toggleModal={this.toggleModal}
                        addTagsToMap={this.addTagsToMap}
                        title={selectedQuestion.label}
                        questionMapValue={selectedQuestion.label}
                    />
                : ''}
            </div>
        );
    }
}
export default injectIntl(MapFormModal)
