import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { BlankCard, StyledModal } from "../../Base/CommonUIComponents";
import { Button, Col, Row, Input, FormGroup, Form, Label } from "reactstrap";
import { postAPI } from "../../Base/API";
import { handleNotification } from "../../Base/Notification";
import CustomSelect from "../../Base/CustomSelect";
import { SingleDatePicker } from "react-dates";
import moment from 'moment';

class AddRedeem extends Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            voucherRedeem: {},
            hotelOptions: this.props.voucher?.voucherType?.validHotels?.map((el) => ({ value: el.guid, label: el.name }))
        }
    }

    saveRedeem = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        let req = this.state.voucherRedeem;
        req.id = null;
        req.voucherId = this.props.voucher.id
        
        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);

                    this.setState({ block: false });
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="AddRedeem.RedeemCreatedSuccessfully" />, <FormattedMessage id="generic.success" />);

                    this.setState({ block: false }, () => {
                        this.props.toggleModal();
                        this.props.getVoucherDetails()
                    });
                }

            }
            else this.setState({ block: false });
        }, '/api/Voucher/Redeem/Redeem/v1', { "request": [this.state.voucherRedeem] });
    }

    getRebates = () => {
        let arr = [];
        this.props.voucher && this.props.voucher.voucherType.voucherRebates && this.props.voucher.voucherType.voucherRebates.map((el, k) => arr.push({ value: el.id, label: el.code }))
        return arr;
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState(prevState => ({
            voucherRedeem: {
                ...prevState.voucherRedeem,
                [name]: value
            }
        }));
    }

    handleChangeCombo = (combo, name) => {
        this.setState(prevState => ({
            voucherRedeem: {
                ...prevState.voucherRedeem,
                [name]: combo ? combo.value : null
            }
        }));
    }

    render() {
        const { modal, toggleModal } = this.props;
        const { block, error, hotelOptions } = this.state;

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="xl">
                <BlankCard block={block} error={error}>
                    <Form onSubmit={this.saveRedeem}>
                        <Row className="align-items-center mb-3">
                            <Col>
                                <h5><FormattedMessage id="AddRedeem.AddRedeem" /> </h5>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host" type="submit">
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>

                        <FormGroup row className="pt-2">
                            <Label sm={2}><FormattedMessage id="generic.Hotel" /></Label>
                            <Col sm={4}>
                                <CustomSelect
                                    required
                                    options={hotelOptions}
                                    value={hotelOptions.find(el => el.value === this.state.voucherRedeem.usageHotel) || ''}
                                    onChange={(evt) => this.handleChangeCombo(evt, "usageHotel")}
                                    placeholder=""
                                />
                            </Col>
                            <Label sm={2}><FormattedMessage id="VoucherDetails.Date" /></Label>
                            <Col sm={4}>
                                <SingleDatePicker
                                    id="businessDate"
                                    isOutsideRange={day => day >= moment()}
                                    date={this.state.voucherRedeem && this.state.voucherRedeem.businessDate ? moment(this.state.voucherRedeem.businessDate) : null}
                                    onDateChange={date => this.setState(prevState => ({ ...prevState, voucherRedeem: { ...prevState.voucherRedeem, businessDate: moment(date).format("YYYY-MM-DD") } }))}
                                    focused={this.state.focused}
                                    onFocusChange={({ focused }) => this.setState({ focused })}
                                    small={true}
                                    numberOfMonths={1}
                                    required={true}
                                    showDefaultInputIcon={true}
                                />
                            </Col>
                        </FormGroup>


                        {this.props.voucher.voucherType.isPaymentMode === true ?
                            <>
                                <FormGroup row className="pt-2">
                                    <Label sm={2}><FormattedMessage id="VoucherDetails.Invoice" /></Label>
                                    <Col sm={4}>
                                        <Input
                                            type="text"
                                            minLength="1" maxLength="50"
                                            name="targetReference"
                                            value={this.state.voucherRedeem.targetReference || ''}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Label sm={2}><FormattedMessage id="VoucherDetails.Amount" /></Label>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            min="-10000"
                                            step="0.01"
                                            max="10000"
                                            required
                                            name="amount"
                                            value={this.state.voucherRedeem.amount || ''}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup row className="pt-2">
                                    <Label sm={2}><FormattedMessage id="VoucherDetails.Notes" /></Label>
                                    <Col sm={10}>
                                        <Input
                                            type="textarea"
                                            minLength="10" maxLength="200"
                                            name="notes"
                                            value={this.state.voucherRedeem.notes || ''}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </FormGroup>
                            </>
                            :
                            <>
                                <FormGroup row className="pt-2">
                                    <Label sm={2}><FormattedMessage id="AddRedeem.Rebates" /></Label>
                                    <Col sm={4}>
                                        <CustomSelect
                                            required
                                            options={this.getRebates()}
                                            value={this.getRebates().find(el => el.value === this.state.voucherRedeem.rebateId)}
                                            onChange={(evt) => this.handleChangeCombo(evt, "rebateId")}
                                            placeholder=""
                                        />
                                    </Col>

                                    <Label sm={2}><FormattedMessage id="VoucherDetails.Quantity" /></Label>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            min="1" max="10000"
                                            required
                                            name="quantity"
                                            value={this.state.voucherRedeem.quantity || ''}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="pt-2">
                                    <Label sm={2}><FormattedMessage id="VoucherDetails.Invoice" /></Label>
                                    <Col sm={4}>
                                        <Input
                                            type="text"
                                            minLength="1" maxLength="50"
                                            name="targetReference"
                                            value={this.state.voucherRedeem.targetReference || ''}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Label sm={2}><FormattedMessage id="VoucherDetails.Notes" /></Label>
                                    <Col sm={4}>
                                        <Input
                                            type="textarea"
                                            minLength="10" maxLength="200"
                                            name="notes"
                                            value={this.state.voucherRedeem.notes || ''}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </FormGroup>
                            </>
                        }
                        
                    </Form>
                </BlankCard>
            </StyledModal>
        );
    }
}

export default injectIntl(AddRedeem);