import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Card, Col, Row, Badge, Modal, ModalBody, Form, Label } from 'reactstrap';
import { getEgoiTransactionalWebhookActions, getEgoiTransactionalWebhookActionsByChannel, getSenderChannel } from "../../Base/ReferenceDataFunctions";
import { StyledCard, BlankCard } from "../../Base/CommonUIComponents";
import { handleNotification } from "../../Base/Notification";
import { getAPI, postAPI, deleteAPI } from "../../Base/API";
import CustomSelect from '../../Base/CustomSelect';

import Authorization from '../../Base/Authorization';

class TransactionalWebhooks extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            modal: false,
            transactionWebhookList: [],
            newWebhook: this.getNewWebhook()
        };
    }

    getNewWebhook() {
        const newWebhook = {
            channel: null,
            actions: []
        }
        return newWebhook;
    }

    componentDidMount() {
        this.getTransactionalWebhooks();
    }

    getTransactionalWebhooks = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                else {
                    this.setState({ error: errorMessage, block: false, transactionWebhookList: data.response ? data.response : [] });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Marketing/v1/egoitransactional/v2/webhooks`);
    }

    createList = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.response && data.response === "success"/*data.webhook_id*/) {
                    handleNotification(data, <FormattedMessage id="EgoiWebhookList.WebhookCreated" />, <FormattedMessage id="generic.success" />);

                    this.setState({
                        modal: false,
                        newWebhook: null
                    }, () => this.getTransactionalWebhooks());
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoitransactional/v2/webhooks?channel=${this.state.newWebhook.channel.toLowerCase()}`, this.state.newWebhook.actions);
    }

    removeWebhook = (e, webhookUrl, channel) => {
        e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }

                if (data.response && data.response === 'success') {
                    handleNotification(data, <FormattedMessage id="EgoiWebhookList.WebhookRemoved" />, <FormattedMessage id="generic.success" />);
                    this.getTransactionalWebhooks();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoitransactional/v2/webhooks?webhookUrl=${webhookUrl}&channel=${channel}`);
    }

    toggleModal = (data) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            newWebhook: data ? data : this.getNewWebhook()
        }));
    }

    handleSelectChannel = (combo) => {
        const { newWebhook } = this.state;
        newWebhook.channel = combo ? combo.value : null;
        newWebhook.actions = [];

        this.setState({ newWebhook });
    }

    handleSelectActions = (combo) => {
        const { newWebhook } = this.state;
        newWebhook.actions = combo ? combo.map(el => el.value) : [];

        this.setState({ newWebhook });
    }

    render() {
        const { block, error, newWebhook } = this.state;
        const { intl } = this.props;
        const actions = getEgoiTransactionalWebhookActions(intl);
        const actionsByChannel = getEgoiTransactionalWebhookActionsByChannel(intl);

        return (
            <StyledCard block={block} error={error}>
                <Authorization
                    perform="transactionalWebhooks:add"
                    yes={() => (
                        <Row>
                            <Col className="text-right">
                                <Button className="btn btn-sm btn-host mr-2" onClick={() => this.toggleModal(null)}>
                                    <i className="fa fa-plus"></i>
                                </Button>
                            </Col>
                        </Row>
                    )}
                    no={() => <div></div>}
                />

                <Card className="border-0 p-2 mb-4 mt-2">
                    <Row>
                        <Col><b><FormattedMessage id="EgoiWebhookList.Channel" /></b></Col>
                        <Col><b><FormattedMessage id="EgoiWebhookList.Callback" /></b></Col>
                        <Col><b><FormattedMessage id="EgoiWebhookList.Actions" /></b></Col>
                        <Authorization
                            perform="transactionalWebhooks:delete"
                            yes={() => (
                                <Col className="col-1"></Col>
                            )}
                            no={() => <div></div>}
                        />
                    </Row>

                    {this.state.transactionWebhookList && this.state.transactionWebhookList.map((el, k) =>
                        <Row key={k} className="mt-2 overbglight align-items-center">
                            <Col> {el.channel} </Col>
                            <Col> {el.callbackUrl} </Col>
                            <Col>
                                {el.actions && el.actions.map((action, key) =>
                                    <Badge color='primary' className="m-1 p-2" key={key}>{actions.find(a => a.value === action.toLowerCase()).label}</Badge>
                                )}
                            </Col>

                            <Authorization
                                perform="transactionalWebhooks:delete"
                                yes={() => (
                                    <Col className="text-right col-1">
                                        <Button className="btn btn-sm btn-host" onClick={(e) => this.removeWebhook(e, el.callbackUrl, el.channel)}>
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                    </Col>
                                )}
                                no={() => <div></div>}
                            />
                        </Row>
                    )}
                </Card>


                <Modal isOpen={this.state.modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                    <Button onClick={() => this.toggleModal(null)} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    <ModalBody>
                        <BlankCard block={block} error={error}>
                            <Form onSubmit={(e) => this.createList(e)}>
                                <Row className="mb-3">
                                    <Col>
                                        <h5> <FormattedMessage id="EgoiWebhookList.CreateWebhook" /></h5>
                                    </Col>
                                    <Col className="text-right">
                                        <Button className="btn-sm btn-host" type="submit"> <i className="fas fa-save" /></Button>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Label sm={3}><FormattedMessage id="EgoiWebhookList.Channel" /></Label>
                                    <Col sm={9}>
                                        <CustomSelect
                                            options={getSenderChannel()}
                                            onChange={this.handleSelectChannel}
                                            value={getSenderChannel().find(c => c.value === newWebhook.channel)}
                                            placeholder={""}
                                            isClearable
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Label sm={3}><FormattedMessage id="EgoiWebhookList.Actions" /></Label>
                                    <Col sm={9}>
                                        <CustomSelect
                                            options={actionsByChannel[newWebhook.channel]}
                                            onChange={this.handleSelectActions}
                                            value={actionsByChannel[newWebhook.channel].filter(opt => newWebhook.actions.some(m => opt.value === m))}
                                            placeholder={""}
                                            isClearable
                                            isMulti
                                            required
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </BlankCard>
                    </ModalBody>
                </Modal>
            </StyledCard>
        );
    }
}
export default injectIntl(TransactionalWebhooks)
