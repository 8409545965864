import React, { Component } from 'react';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import { FormattedMessage } from "react-intl";
import { BlankCard } from '../Base/CommonUIComponents';
import { putAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';

export class VerificationEmailSpamModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            score: null,
            rules: []
        }
    }

    componentDidMount() {
        const { htmlBody } = this.props;

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else if (data.response && data.response.length > 0) {
                    const rule = data.response[0];
                    this.setState({ error: errorMessage, block: false, score: rule.score, rules: rule.rules });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Marketing/v1/spam/email/verification`, htmlBody);
    }

    render() {
        const { isOpen, toggle } = this.props;
        const { block, error, rules, score } = this.state;

        return (
            <Modal isOpen={isOpen} fade={false} size={"xl"} style={{ minWidth: 0 }}>
                <Button onClick={toggle} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <h3>
                            <FormattedMessage id="VerificationEmailSpamModal.Title" />
                        </h3>
                        <h6>
                            <b>
                                <FormattedMessage id="VerificationEmailSpamModal.Score" />:
                            </b>
                            {` ${score ? `${score}` : `-`}`}
                        </h6>
                        <Row>
                            <Col className="col-2">
                                <b>
                                    <FormattedMessage id="VerificationEmailSpamModal.Score" />
                                </b>
                            </Col>
                            <Col>
                                <b>
                                    <FormattedMessage id="VerificationEmailSpamModal.Description" />
                                </b>
                            </Col>
                        </Row>
                        {
                            rules.map((rule, key) => 
                                <Row key={key}>
                                    <Col className="col-2">
                                        {rule.score}
                                    </Col>
                                    <Col>
                                        {rule.description}
                                    </Col>
                                </Row>
                            )
                        }
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}