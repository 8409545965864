import axios from 'axios';
import { getToken, adalConfig, authContext } from '../../Utils/AdalConfig';
import moment from 'moment-timezone';
import { HandleAPIError, HandleExceededTimeAPIError } from '../../Utils/EventGA';

// Add request interceptor
const instance = axios.create(adalConfig);

instance.interceptors.request.use((config) => {
    // Check and acquire a token before the request is sent
    return new Promise((resolve, reject) => {
        authContext.acquireToken(adalConfig.endpoints.api, (_, token) => {
            if (token) {
                config.headers.Authorization = `bearer ${token}`;
                resolve(config);
            }
            else {
                reject(config);
            }
        });
    });
},
    function (error) {
        return Promise.reject(error);
    }
);

export function getAPI(callback, url) {
    const timerStart = moment();
    const currentPath = window?.location?.pathname;
    const currentLocation = window?.location?.href;
    
    instance.get(url)
        .then(res => {
            callback({ data: res.data, isLoading: false, headers : res.headers });

            const timerEnd = moment();
            const diff = timerEnd.diff(timerStart, 'seconds');
            if(diff > 20)
                HandleExceededTimeAPIError(diff, url);
        })
        .catch(err => {
            HandleAPIError(err?.message, url, currentPath, currentLocation);
            callback({ error: err, isLoading: false});
        });
}

export function getAPIBlob(callback, url) {
    const timerStart = moment();
    const currentPath = window?.location?.pathname;
    const currentLocation = window?.location?.href;

    instance.get(url, { responseType: "blob" })
        .then(res => {
            callback({ data: res.data, isLoading: false, headers: res.headers });

            const timerEnd = moment();
            const diff = timerEnd.diff(timerStart, 'seconds');
            if (diff > 20)
                HandleExceededTimeAPIError(diff, url);
        })
        .catch(err => {
            HandleAPIError(err?.message, url, currentPath, currentLocation);
            callback({ error: err, isLoading: false });
        });
}

export function getMultiPartAPI(callback, url) {
    const timerStart = moment();
    const currentPath = window?.location?.pathname;
    const currentLocation = window?.location?.href;

    instance.get(url, { responseType: "blob" })
        .then(res => {
            callback({ data: res.data, isLoading: false, content: res.content });

            const timerEnd = moment();
            const diff = timerEnd.diff(timerStart, 'seconds');
            if(diff > 20)
                HandleExceededTimeAPIError(diff, url);
        })
        .catch(err => {
            HandleAPIError(err?.message, url, currentPath, currentLocation);
            callback({ error: err, isLoading: false })
        });
}

export function postAPI(callback, url, body) {
    const timerStart = moment();
    const currentPath = window?.location?.pathname;
    const currentLocation = window?.location?.href;
    
    instance.post(url, body, { withCredentials: true, headers: { 'Authorization': 'bearer ' + getToken(), 'Content-Type': 'application/json' } })
        .then(res => {
            callback({ data: res.data, isLoading: false });

            const timerEnd = moment();
            const diff = timerEnd.diff(timerStart, 'seconds');
            if(diff > 20)
                HandleExceededTimeAPIError(diff, url);
        })
        .catch(err => {
            HandleAPIError(err?.message, url, currentPath, currentLocation);
            callback({ error: err, isLoading: false });
        });
}

export function postAPIBlob(callback, url, body) {
    const timerStart = moment();
    const currentPath = window?.location?.pathname;
    const currentLocation = window?.location?.href;

    instance.post(url, body, { 
        withCredentials: true, 
        headers: { 'Authorization': 'bearer ' + getToken(), 'Content-Type': 'application/json' },
        responseType: 'blob'
    })
    .then(res => {
        callback({ data: res.data, headers: res.headers, isLoading: false });

        const timerEnd = moment();
        const diff = timerEnd.diff(timerStart, 'seconds');
        if (diff > 20)
            HandleExceededTimeAPIError(diff, url);
    })
    .catch(err => {
        HandleAPIError(err?.message, url, currentPath, currentLocation);
        callback({ error: err, isLoading: false });
    });
}

export function putAPI(callback, url, body) {
    const timerStart = moment();
    const currentPath = window?.location?.pathname;
    const currentLocation = window?.location?.href;

    instance.put(url, body, { withCredentials: true, headers: { 'Authorization': 'bearer ' + getToken(), 'Content-Type': 'application/json' } })
        .then(res => {
            callback({ data: res.data, isLoading: false });

            const timerEnd = moment();
            const diff = timerEnd.diff(timerStart, 'seconds');
            if(diff > 20)
                HandleExceededTimeAPIError(diff, url);
        })
        .catch(err => {
            HandleAPIError(err?.message, url, currentPath, currentLocation);
            callback({ error: err, isLoading: false });
        });
}

export function patchAPI(callback, url, body) {
    const timerStart = moment();
    const currentPath = window?.location?.pathname;
    const currentLocation = window?.location?.href;

    instance.patch(url, body, { withCredentials: true, headers: { 'Authorization': 'bearer ' + getToken() } })
        .then(res => {
            callback({ data: res.data, isLoading: false });

            const timerEnd = moment();
            const diff = timerEnd.diff(timerStart, 'seconds');
            if(diff > 20)
                HandleExceededTimeAPIError(diff, url);
        })
        .catch(err => {
            HandleAPIError(err?.message, url, currentPath, currentLocation);
            callback({ error: err, isLoading: false });
        });
}

export function postMultiPartAPI(callback, url, body) {
    const timerStart = moment();
    const currentPath = window?.location?.pathname;
    const currentLocation = window?.location?.href;
    
    instance.post(url, body, { withCredentials: true, headers: { 'Authorization': 'bearer ' + getToken(), 'Content-Type': 'multipart/form-data' } })
        .then(res => {
            callback({ data: res.data, isLoading: false });

            const timerEnd = moment();
            const diff = timerEnd.diff(timerStart, 'seconds');
            if(diff > 20)
                HandleExceededTimeAPIError(diff, url);
        })
        .catch(err => {
            HandleAPIError(err?.message, url, currentPath, currentLocation);
            callback({ error: err, isLoading: false });
        });
}

export function deleteAPI(callback, url) {
    const timerStart = moment();
    const currentPath = window?.location?.pathname;
    const currentLocation = window?.location?.href;

    instance.delete(url, { withCredentials: true, headers: { 'Authorization': 'bearer ' + getToken() } })
        .then(res => {
            callback({ data: res.data, isLoading: false });
            
            const timerEnd = moment();
            const diff = timerEnd.diff(timerStart, 'seconds');
            if(diff > 20)
                HandleExceededTimeAPIError(diff, url);
        })
        .catch(err => {
            HandleAPIError(err?.message, url, currentPath, currentLocation);
            callback({ error: err, isLoading: false });
        });
}
