import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Col, Row } from 'reactstrap';
import { StyledCard } from "../../../Base/CommonUIComponents";
import { CustomTable } from '../../../Base/CustomTable';
import { getAPI } from "../../../Base/API";
import TagDetails from './TagDetails';

class TagsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            tagGroups:[],
            tagsList: [],
            tag: null,
            modal: false
        };
    }

    componentDidMount() {        
        this.getTagGroups();
    }

    getTagGroups() {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                var list = [];

                data.response && data.response.forEach(tg => {
                    list.push({ value: tg.id, label: tg.code });
                })

                this.setState({ tagGroups: list }, () => this.getTags());
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/tag/group`);
    }

    getTags() {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ tagsList: data.response });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/tag?tagType=system`);
    }

    updateTable = (tag, isToDelete) => {
        this.setState({ block: true });
        var array = this.state.tagsList ? [...this.state.tagsList] : [];

        if (isToDelete) {
            array = array.filter(tg => tg.id !== tag.id);
        }
        else {
            const index = array && array.indexOf(array.find(el => el.id === tag.id));

            if (index === -1) {
                array.push(tag);
            }
            else {
                array[index] = tag;
            }
        }

        this.setState({ tagsList: array, block: false });
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            tag: null
        }))
    }    

    render() {
        const { block, error, tagsList } = this.state;

        const tagTypes = [
            { value: 'StayLength', label: <FormattedMessage id="TagsList.StayLength" /> },
            { value: 'BookingDistance', label: <FormattedMessage id="TagsList.BookingDistance" /> },
            { value: 'None', label: <FormattedMessage id="TagsList.Revenue" /> },
            { value: 'Reservation', label: <FormattedMessage id="TagsList.Reservation" /> },
            { value: 'ReservationChannel', label: <FormattedMessage id="TagsList.ReservationChannel" /> },
            { value: 'ReservationRate', label: <FormattedMessage id="TagsList.ReservationRate" /> },
            { value: 'ReservationRoom', label: <FormattedMessage id="TagsList.ReservationRoom" /> },
            { value: 'ReservationMarket', label: <FormattedMessage id="TagsList.ReservationMarket" /> },
            { value: 'ReservationSegment', label: <FormattedMessage id="TagsList.ReservationSegment" /> },
            { value: 'ReservationSubsegment', label: <FormattedMessage id="TagsList.ReservationSubsegment" /> },
            { value: 'ReservationService', label: <FormattedMessage id="TagsList.ReservationService" /> }
        ];

        const columns = [
            {
                dataField: "code",
                text: this.props.intl.formatMessage({ id: "TagsList.Code"}),
                sort: true
            },
            {
                dataField: "description",
                text: this.props.intl.formatMessage({ id: "TagsList.Description" }),
                sort: true
            },
            {
                dataField: "tagGroupId",
                text: this.props.intl.formatMessage({ id: "TagsList.Group" }),
                formatter: (cell, row) => {
                    const group = cell ? this.state.tagGroups.find(tg => tg.value === cell) : '';
                    return group && group.label;
                }
            },
            {
                dataField: "parameterOperator",
                text: this.props.intl.formatMessage({ id: "TagsList.Parameter" }),
                formatter: (cell, row) => {
                    return row.parameterized && cell !== 'None' ?
                        <span> {this.props.intl.formatMessage({id: `ReferenceDataFunctions.${cell}`})} </span>
                    : ' ';
                }
            },
            {
                dataField: "type",
                text: this.props.intl.formatMessage({ id: "TagsList.Type" }),
                formatter: (cell, row) => {
                    const type = tagTypes.find(el => el.value === cell);

                    return type ? type.label : cell;
                }
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({
                    modal: true,
                    tag: row
                })
            }
        };

        return (
            <StyledCard block={block} error={error} icon={'fas fa-tag'} title={'TagsList.Title'}>
                <Row>
                    <Col className="text-right">
                        <Button className="btn-sm btn-host" onClick={this.toggleModal}>
                            <i className="fas fa-plus" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col className="h-100">
                        <CustomTable
                            data={tagsList ? tagsList : []}
                            selectRow={selectRow}
                            columns={columns}
                            shadow={false}
                            search={true}
                            rowStyle={{ cursor: 'pointer' }}
                        />
                    </Col>
                </Row>
                { this.state.modal ?
                    <TagDetails
                        modal={this.state.modal}
                        toggleModal={this.toggleModal}
                        tag={this.state.tag}
                        updateTable={this.updateTable}
                        tagGroups={this.state.tagGroups}
                        tagTypes={tagTypes}
                    />
                : '' }
            </StyledCard>
        );
    }
}
export default injectIntl(TagsList)
