import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import Authorization, { CheckAuthorization } from '../Base/Authorization';
import { ProfileHistory } from '../Base/HistoryComponents';
import PreferenceDetails from './PreferenceDetails';
import { DefaultPage } from '../Base/CommonUIComponents';
import ConfirmActionModal from '../Base/ConfirmActionModal';

export class ProfilePreferences extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            preferencesModal: false,
            deleteModal: false
        }
    }


    toggleModal = (obj) => {
        this.setState(prevState => ({
            preferencesModal: !prevState.preferencesModal,
            selectedPreference: obj
        }))
    }

    toggleDeleteModal = (selectedPref) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
            selectedPref
        }));
    }

    removePreference = () => {
        this.props.removeElement(this.props.profileId, this.state.selectedPref.id, 'preference', 'preferences')

        this.toggleDeleteModal();
    }

    render() {
        const { preferences, canEditProfile } = this.props;
        const { deleteModal } = this.state;

        return (
            <div className="m-2 p-2">
                <Row className="mb-2">
                    <Col>
                        <h5><i className="icon-icon-preferences mr-2"> </i><FormattedMessage id="ProfileDetails.ProfilePreference" /></h5>
                    </Col>
                    <Col className="text-right">
                        <ProfileHistory
                            component={"preference"}
                            profileId={this.props.profileId}
                        />

                        <Authorization
                            perform="profilePreferences:add"
                            yes={() => canEditProfile && (
                                <Button className="btn-sm btn-host ml-2" onClick={() => this.toggleModal(null)}>
                                    <i className="fas fa-plus" />
                                </Button>
                            )}
                            no={() => <div></div>}
                        />
                    </Col>
                </Row>
                {preferences && Object.keys(preferences).map((hotel, key) =>
                    <div key={key} >
                        <Row className="col-6 mb-2">
                            <Col className="pl-2">{hotel} </Col>
                        </Row>

                        {preferences[hotel].map((pref, i) =>
                            <Row className="mb-2" key={i}>
                                <Col>
                                    <Card className="border-0 mb-1 shadow">
                                        <CardBody className="p-3">
                                            <Row className='align-items-center'>
                                                <Col className='d-flex align-items-center col-10 text-truncate' id={`Type-${pref.id}`}>
                                                    <i className="fas fa-bars mr-2"></i>{pref.type}
                                                </Col>
                                                <Authorization
                                                    perform="profilePreferences:delete"
                                                    yes={() => canEditProfile && (
                                                        <Col className="col-2 text-right">
                                                            <span onClick={() => this.toggleDeleteModal(pref)} style={{ cursor: 'pointer' }}>
                                                                <i className="fas fa-trash-alt" />
                                                            </span>
                                                        </Col>
                                                    )}
                                                    no={() => <div></div>}
                                                />
                                            </Row>
                                            <Row style={{ cursor: 'pointer' }} onClick={() => { if (canEditProfile && CheckAuthorization("profilePreferences:add", null)) this.toggleModal(pref) }}>
                                                <Col className='col-12'>
                                                    <hr />
                                                </Col>
                                                <Col className='col-12 pb-2 d-flex flex-column align-items-start' id={`Action-${pref.id}`}>
                                                    <span className='text-muted title-sm'><FormattedMessage id="ProfileDetails.Action" />: </span>  
                                                    <span className='text-truncate' style={{ maxWidth: '100%' }}>{pref.action}</span>
                                                </Col>
                                                <Col className='col-12 pt-3 d-flex flex-column align-items-start' id={`Notes-${pref.id}`}>
                                                    <span className='text-muted title-sm'><FormattedMessage id="ProfileDetails.Notes" />: </span>
                                                    <span style={{ maxHeight: '80px', overflow:'auto' }}>{pref.notes}</span>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </div>
                )}

                {preferences == null || preferences.length == 0 ?
                    <DefaultPage text="ProfileDetails.NoPreferencesAvailable" icon="icon-icon-preferences" />
                : ''}

                {this.state.preferencesModal ?
                    <PreferenceDetails
                        modal={this.state.preferencesModal}
                        toggleModal={() => this.toggleModal(null)}
                        preference={this.state.selectedPreference}
                        profileId={this.props.profileId}
                        updateTable={this.props.updateTable}
                    />
                : ''}


                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={this.toggleDeleteModal}
                        actionFunction={this.removePreference}
                        text={<FormattedMessage id="ProfilePreferences.ConfirmDeleteText" values={{ preference: this.state.selectedPref.type }} />}
                    />
                : ''}
            </div>
        );
    }
}

export default injectIntl(ProfilePreferences);
