import React, { Component } from 'react'
import Chart from 'react-google-charts'

export class SankeyChart extends Component {
    render() {
        const { data } = this.props;

        return (
            <div className="container mt-5">
                <Chart
                    width={'100%'}
                    height={'400px'}
                    chartType="Sankey"
                    loader={<div>Loading Chart</div>}
                    data={data}
                    rootProps={{ 'data-testid': '1' }}
                />
            </div>
        )
    }
}
