import React, { Component } from 'react'
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BlankCard, PhoneFrame } from "../../Base/CommonUIComponents";
import { SendGridTemplateValidations } from '../../Base/TemplateByCountry';

class TwilioPreviewModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { block, error, modal, template } = this.props;
        return (
            <Modal isOpen={modal} fade={false} size={"lg"} style={{ minWidth: 0 }} >
                <Button onClick={_ => this.props.toggleModal(null)} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <Row>
                            <Col>
                                <h5>
                                    <i className="fas fa-envelope-open mr-2"></i>
                                    <FormattedMessage id="TwilioMessaging.PreviewTemplate" />
                                </h5>
                            </Col>
                            <Col className="text-right col-2">
                                <SendGridTemplateValidations template={template.types["twilio/text"].body} target="TooltipEmailSendGridValidate" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row className="mt-4 mb-2 py-1">
                                    <Col>
                                        <b><FormattedMessage id="TwilioMessaging.UserName" />: </b>{template.friendly_name}
                                    </Col>
                                </Row>
                                {template && template.variables && Object.keys(template.variables).length > 0 ?
                                    <Row className="mb-2 py-1">
                                        <Col>
                                            <b><FormattedMessage id="TwilioMessaging.Variables" />:</b>
                                        </Col>
                                    </Row>
                                    : ''}
                                {template && template.variables && Object.keys(template.variables).map((x, key) =>
                                    <div key={key}>
                                        <Row className="mb-2 py-1" >
                                            <Col>
                                                {x}: {template.variables[x]}
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                                {template && template.types && Object.keys(template.types).map((x, key) =>
                                    <div key={key}>
                                        <Row className="mb-2 py-1" >
                                            <Col>
                                                <b><FormattedMessage id="TwilioMessaging.Type" />:</b> {x}
                                            </Col>
                                        </Row>
                                        <Row className="mb-2 py-1" >
                                            <Col>
                                                <b><FormattedMessage id="TwilioMessaging.Message" />:</b> {template.types[x].body}
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </Col>
                            <Col>
                                <PhoneFrame>
                                    <div className='p-4 veryCoolScrollBar' style={{ display: 'flex', flexDirection: 'column', maxHeight: '90%', overflow: 'auto' }}>
                                        <div className='iMessage' style={{
                                            borderRadius: '20px', padding: '8px 15px', marginTop: '5px', marginBottom: '5px', marginRight: '20%',
                                            backgroundColor: '#eee', position: 'relative'
                                        }} >
                                            {template.types["twilio/text"].body}
                                        </div>
                                    </div>
                                </PhoneFrame>
                            </Col>
                        </Row>
                    </BlankCard>
                </ModalBody>
            </Modal>
        )
    }
}

export default injectIntl(TwilioPreviewModal)
