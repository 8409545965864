import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { BlankCard, StyledModal } from "../../../../Base/CommonUIComponents";
import { Button, Col, Row, Input, FormGroup, Form } from "reactstrap";
import { getAPI, patchAPI, postAPI } from "../../../../Base/API";
import { handleNotification } from "../../../../Base/Notification";
import { ActiveInactiveStatusCombo } from "../../../../Base/CommonUIComponents";

class ForwardModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            url: this.props.selectedSetting === "forward_bounce" ? "forwardbounce" : "forwardspam",
            settings: {}
        }
    }

    componentDidMount() {
        this.getSettings();
    }

    getSettings = (e) => {
        if (e) e.preventDefault();

        this.setState({ block: true });
        
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ settings: data.response, block: false });
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SendGrid/v1/mailsettings/${this.state.url}`);
    }

    handleSave = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const { settings } = this.state;

        patchAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ block: false, error: errorMessage });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="MailSettings.SettingsSaved" />, <FormattedMessage id="generic.success" />);

                    this.props.updateSettings();
                }
            }
            this.setState({ block: false });
        }, `/api/gms/SendGrid/v1/mailsettings/${this.state.url}?mail=${settings.email}&enabled=${settings.enabled}`)
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        
        this.setState(prevState => ({
            settings: {
                ...prevState.settings,
                [name]: value
            }
        }));
    }

    handleSelect = (name, combo) => {
        this.setState(prevState => ({
            settings: {
                ...prevState.settings,
                [name]: combo ? combo.value : null
            }
        }));
    };

    render() {
        const { modal, toggleModal, selectedSetting } = this.props;
        const { block, error, settings } = this.state;

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="md">
                <BlankCard block={block} error={error}>
                    <Form onSubmit={this.handleSave}>
                        <Row className="align-items-center mb-3">
                            <Col>
                                <h5 className="m-0"><FormattedMessage id={`MailSettings.${selectedSetting}Title`} /> </h5>
                            </Col>
                            <Col className="text-right col-2">
                                <Button className="btn-sm btn-host" type="submit">
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>

                        <div className="text-muted mb-4 py-3" style={{ textAlign: 'justify' }}>
                            <FormattedMessage id={`MailSettings.${selectedSetting}Info`} />
                        </div>

                        <FormGroup row className="pt-2">
                            <Col>
                                <div> <FormattedMessage id="generic.status" /> </div>
                                    
                                <ActiveInactiveStatusCombo
                                    name='enabled'
                                    onChangeFunc={this.handleSelect}
                                    placeholder={this.props.intl.formatMessage({ id: "generic.status" })}
                                    value={settings.enabled}
                                    required={true}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row className="pt-2">
                            <Col>
                                <div> <FormattedMessage id="generic.Email" /> </div>
                                <Input
                                    required
                                    type="email"
                                    onChange={(e) => this.handleChange(e)}
                                    value={settings.email || ''}
                                    name="email"
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                </BlankCard>
            </StyledModal>
        );
    }
}

export default injectIntl(ForwardModal);