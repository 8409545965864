import React, { Component } from 'react';
import { Button, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { LogsRequestModal } from './LogsRequestModal';

export class LogMessages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            requestContent: '',
            requestType: ''
        };
    }

    toggle = (type) => {
        const { selectedEvent } = this.props;

        let content = '';
        let contentType = '';

        if (type && selectedEvent !== undefined) {
            contentType = type;
            try {
                content = JSON.stringify(JSON.parse(selectedEvent[type]), null, 2)
            } catch (e) {
                content = selectedEvent[type];
            }
        }

        this.setState(prevState => ({
            requestType: contentType,
            requestContent: content,
            modal: !prevState.modal
        }));
    }

    render() {
        const { selectedEvent } = this.props;
        const { modal, requestContent, requestType } = this.state;

        return (
            <div>
                <LogsRequestModal modal={modal} toggle={this.toggle.bind(this, '')} requestType={requestType} requestContent={requestContent} />
                <CardHeader className="bg-white">
                    <div className="inline">
                        <i className="far fa-envelope mr-2"></i> Raw Data
                        <a id="togglerRawData" className="float-right">
                            <span className="fas fa-angle-down" />
                        </a>
                    </div>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col className="col-6">
                            <Button className="btn-sm btn-host shadow mr-2" disabled={!selectedEvent.messageRequest} onClick={this.toggle.bind(this, 'messageRequest')}>
                                <i className="fas fa-file-import" />
                            </Button>
                            Request
                        </Col>
                        <Col className="col-6">
                            <Button className="btn-sm  btn-host shadow mr-2 " disabled={!selectedEvent.messageResponse} onClick={this.toggle.bind(this, 'messageResponse')}>
                                <i className="fas fa-file-export" />
                            </Button>
                            Response
                        </Col>
                    </Row>
                </CardBody>
            </div>
        );
    }
}