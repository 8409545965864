import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Col, Row, Modal, ModalBody, Form, FormGroup, Card } from 'reactstrap';
import { getAPI, putAPI } from "../../Base/API";
import { handleNotification } from "../../Base/Notification";
import { BlankCard } from "../../Base/CommonUIComponents";
import { getChannelOptions, getFormTypes, getReportTypeOptions } from '../../Base/ReferenceDataFunctions';
import CustomSelect from '../../Base/CustomSelect';
import { ReviewProFormsSelect, improvFormObj } from '../../Marketing/Common/MarketingFunctions';
import BlockUi from 'react-block-ui';
import CreatePrompt from '../GPTConfig/CreatePrompt';


export class ConfigModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            config: this.props.selectedConfig || {},
            getFormsUrl: null,
            types: [ "Review", "QA", "Chat", "MetaAnalysis" ],
            newPrompt: null,
            createModal: false,
            locationsOptions: [],
            locationsBlock: false,
            locationsError: false,
            questionsBlock: false,
            questionsOptions: {}
        };
    }

    componentDidMount() {
        const { selectedConfig, intl } = this.props;

        if (selectedConfig) {
            const formType = getFormTypes(intl, selectedConfig.channel === 'Inquiry').find(ft => ft.value === selectedConfig.formType);

            if (formType) {
                this.setState({ getFormsUrl: formType.getFormsUrl });
            }
            if(selectedConfig.channel === "Reviews" && selectedConfig.formType === "GoogleBusiness")
                this.getGoogleAccounts();

            if(selectedConfig.formId && formType?.getSingleFormUrl){
                this.getFormQuestions(selectedConfig.formId);
            }
        }
    }

    getGoogleAccounts = () => {
        this.setState({ locationsBlock: true }, () => 
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ locationsError: errorMessage, locationsBlock: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0 || !data.response) {
                        handleNotification(data);
                    }
                    else {
                        data.response[0].accounts = data.response[0]?.accounts?.map(el => {
                            el.name = el.name.substring(el?.name.indexOf('/') + 1, el?.name.length);
                            return el;
                        });

                        const accounts = data.response[0]?.accounts;
                        const accountId = accounts[0]?.name;
                        
                        this.setState({ accounts, accountId }, () => this.getGoogleLocations());
                        return;
                    }
                }
                this.setState({ locationsError: errorMessage, locationsBlock: false });
                
            }, `/api/gms/Google/v1/accounts`)
        );
    }

    getGoogleLocations = () =>{
        const { accountId } = this.state;

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                this.setState({ locationsBlock: false, locationsError: error.message }, () => console.error(error.message));
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    this.setState({ locationsBlock: false, locationsError: data.errors }, () => console.error(data.errors));
                }
                else {
                    const locationsOptions = data.response?.map(el => {
                        return({
                            value: el.name,
                            label: el.title
                        });
                    });

                    this.setState({ locationsOptions, locationsBlock: false });
                }
            }
        }, `/api/gms/Google/v1/accounts/${accountId}/location`)
    }

    getFormQuestions = (formId) => {
        const { config, questionsOptions } = this.state;
        const url = getFormTypes(this.props.intl, config.channel === 'Inquiry').find(opt => opt.value === config.formType)?.getSingleFormUrl;

        if(!url) return;
        
        this.setState({ questionsBlock: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ questionsBlock: false, questionsError: errorMessage });
                    return;
                }
                if (data && data.response && data.response.length > 0) {
                    const treatedData = improvFormObj(data.response.map((p) => {
                        p.formSource = config.formType === "ProfileNowForms" ? "PNForm" : config.formType;
                        return p;
                    }));

                    var combo = [];
                    if(config.formType === "ProfileNowForms"){
                        combo = treatedData[0].rawForm.map((item) => {
                            const title = typeof item?.title === 'object' ? item?.title?.default : item?.title;

                            const opt = {
                                value: item.name,
                                label: <div>
                                    <div>{item.name}</div>
                                    <div className='text-muted' style={{ fontSize: '0.8em' }}>
                                        {title}
                                    </div>
                                </div> };
                            return opt;
                        });
                    }
                    else if(config.formType === "Typeform"){
                        combo = treatedData[0].fields.map((item) => {
                            const opt = {
                                value: item.id,
                                label: <div>
                                    <div>{item.title}</div>
                                    <div className='text-muted' style={{ fontSize: '0.8em' }}>
                                        {item.properties?.description}
                                    </div>
                                </div> };
                            return opt;
                        });
                    }
                    else{
                        combo = treatedData.map((item) => {
                            const opt = {
                                value: item.id,
                                label: <div>
                                    <div>{item.shortName}</div>
                                    <div className='text-muted' style={{ fontSize: '0.8em' }}>
                                        {item.questionTitle}
                                    </div>
                                </div> };
                            return opt;
                        })
                    }
                    this.setState({ questionsOptions: {...questionsOptions, [config.formType]: combo }, questionsBlock: false });
                }
                else {
                    this.setState({ questionsBlock: false });
                }
            }, url + `/${formId}`);
        });
    }

    saveConfig = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var config = { ...this.state.config };

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="SummaryConfig.ConfigSaved" />, <FormattedMessage id="generic.success" />);
                    this.props.updateTable(data.response[0]);
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Review/v1/reviewSummaryConfig`, config);
    }

    handleChangeSelect = (combo, name) => {
        const { locationsOptions, locationsError, questionsOptions, config } = this.state;
        if((name === "channel" && combo?.value === 'Reviews') && locationsOptions?.length === 0 && !locationsError){
            this.getGoogleAccounts();
        }
        if(name === "formId" && combo?.value && ((questionsOptions[config.formType]) || (combo?.value !== config.formId))){
            this.getFormQuestions(combo.value);
        }
        this.setState({
            config: {
                ...this.state.config,
                [name]: combo ? combo.value : null
            }
        })
    };

    handleMultiSelect = (combo, name) => {
        this.setState({
            config: {
                ...this.state.config,
                [name]: combo?.map(el => el.value) || null
            }
        })
    };

    handleFormType = (combo, name) => {
        const { locationsOptions, config, locationsError } = this.state;
        if(config.channel === 'Reviews' && combo.value === "GoogleBusiness" && locationsOptions?.length === 0 && !locationsError){
            this.getGoogleAccounts();
        }
        this.setState({
            config: {
                ...this.state.config,
                [name]: combo ? combo.value : null
            },
            getFormsUrl: name === "formType" ? combo ? combo.getFormsUrl : null : this.state.getFormsUrl
        })
    };

    toggleCreateModal = () => {
        this.setState({ createModal: !this.state.createModal })
    }

    render() {
        const { config, types, newPrompt, createModal, locationsOptions, locationsBlock, questionsBlock, questionsOptions } = this.state;
        const { modal, toggleModal, promptConfigList, intl, block } = this.props;
        
        const MetaAnalysisPromptConfigList = promptConfigList?.filter(({type}) => type === "MetaAnalysis");

        return (
            <BlockUi blocking={block}>
                <Modal isOpen={modal} fade={false} className='modal-lg'>
                    <Button onClick={toggleModal} className="closeModal">
                        <i className="fas fa-times fa-sm" />
                    </Button>
                    <ModalBody className='p-4'>
                        <Form onSubmit={this.saveConfig}>
                            <Row>
                                <Col>
                                    <h5> {config.id ? <FormattedMessage id="SummaryConfig.EditConfig" /> : <FormattedMessage id="SummaryConfig.CreateConfig" />} </h5>
                                </Col>
                                <Col className="text-right mb-2">
                                    <Button className="btn-sm btn-host" type="submit">
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            <FormGroup row>
                                <Col sm={6} className='mt-2'>
                                    <div className='py-1'>
                                        <FormattedMessage id="SummaryConfig.ReportType" />
                                    </div>
                                    <div>
                                        <CustomSelect
                                            options={getReportTypeOptions(intl)}
                                            required
                                            isClearable={false}
                                            isSearchable={false}
                                            placeholder={''}
                                            onChange={(e) => this.handleChangeSelect(e, "reportType")}
                                            value={getReportTypeOptions(intl).find(p => p.value === config.reportType) || ''}
                                        />
                                    </div>
                                </Col>
                                <Col className='mt-2' sm={6}>
                                    <div className='py-1'>
                                        <FormattedMessage id="SummaryConfig.Channel" />
                                    </div>
                                    <div>
                                        <CustomSelect
                                            options={getChannelOptions(intl)}
                                            required
                                            isClearable={false}
                                            isSearchable={false}
                                            placeholder={''}
                                            onChange={(e) => this.handleChangeSelect(e, "channel")}
                                            value={getChannelOptions(intl).find(c => c.value === config.channel) || ''}
                                        />
                                    </div>
                                </Col>
                                {!(config?.reportType === "MetaData" && config?.channel === "Reviews") && config.channel ?
                                    <>
                                        <Col sm={6} className='mt-2'>
                                            <div className='py-1'>
                                                <FormattedMessage id="SummaryConfig.FormType" />
                                            </div>
                                            <div>
                                                <CustomSelect
                                                    isDisabled={!config.channel}
                                                    options={getFormTypes(intl, config.channel === 'Inquiry')}
                                                    placeholder={''}
                                                    required={true}
                                                    isClearable={false}
                                                    onChange={(e) => this.handleFormType(e, "formType")}
                                                    value={getFormTypes(intl, config.channel === 'Inquiry').find(opt => opt.value === config.formType)}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={6} className='mt-2'>
                                            <div className='py-1'>
                                                {config.channel === 'Inquiry' ?
                                                    <FormattedMessage id="SummaryConfig.Form" />
                                                :
                                                    <FormattedMessage id="SummaryConfig.Location"/>
                                                }
                                            </div>
                                            <div>
                                                {config.channel === 'Inquiry' ?
                                                    <ReviewProFormsSelect
                                                        onChangeFunc={(e) => this.handleChangeSelect(e, 'formId')}
                                                        value={config.formId}
                                                        url={this.state.getFormsUrl}
                                                        isDisabled={!this.state.getFormsUrl}
                                                    />
                                                :
                                                    <BlockUi blocking={locationsBlock}>
                                                        <CustomSelect
                                                            options={locationsOptions}
                                                            isDisabled={!config.formType || !locationsOptions || locationsOptions.length === 0}
                                                            placeholder={''}
                                                            required={true}
                                                            isClearable={false}
                                                            onChange={(e) => this.handleFormType(e, "formId")}
                                                            value={locationsOptions?.find(opt => opt.value === config.formId)}
                                                        />
                                                    </BlockUi>
                                                }
                                            </div>
                                        </Col>
                                        {config.channel === 'Inquiry' ?
                                            <Col sm={6} className='mt-2'>
                                                <div className='py-1'>
                                                    <FormattedMessage id="SummaryConfig.FormQuestion" />
                                                </div>
                                                <div>
                                                    <BlockUi blocking={questionsBlock}>
                                                        <CustomSelect
                                                            options={questionsOptions ? questionsOptions[config.formType] : []}
                                                            isDisabled={!config.formType || !config.formId || !questionsOptions || questionsOptions.length === 0}
                                                            placeholder={''}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            onChange={(e) => this.handleFormType(e, "questionId")}
                                                            value={questionsOptions && questionsOptions[config.formType]?.find(opt => opt.value === config.questionId)}
                                                        />
                                                    </BlockUi>
                                                </div>
                                            </Col>
                                        :''}
                                    </>
                                :''}
                            </FormGroup>
                            <div className="mt-2">
                                <Row>
                                    <Col className='d-flex align-items-center addPromptConfig'>
                                        <div className='py-1 cursor-pointer'>
                                            <FormattedMessage id="SummaryConfig.Prompt" />
                                        </div>
                                        <div className='ml-1 text-muted add' style={{ fontSize: '0.9em' }} onClick={this.toggleCreateModal}>
                                            <i className="fas fa-plus" />
                                        </div>
                                    </Col>
                                </Row>
                                {MetaAnalysisPromptConfigList && MetaAnalysisPromptConfigList?.length > 0 ?
                                    <Card className="border-0 shadow veryCoolScrollBar" style={{ maxHeight: "155px", overflowY: "overlay", scrollbarGutter: "stable both-edges" }}>
                                        {MetaAnalysisPromptConfigList.map((item, index) => 
                                            <div onClick={() => this.handleChangeSelect({value: item.id}, "promptConfigId")} key={index} className="mt-2 cursor-pointer p-2"
                                            style={{ background: item.id === config.promptConfigId || (!config.promptConfigId && item.isMain) ? "#eef4fb" : '' }}>
                                                <div>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='mr-2' style={{ color: '#0665ff' }}>
                                                            <div>
                                                                {item.isMain ?
                                                                    <i className="fas fa-star"/>
                                                                :
                                                                    <i className="far fa-star"/>
                                                                }
                                                            </div>
                                                        </div>
                                                        <b>{item.name}</b>
                                                    </div>
                                                </div>
                                                <div className='text-muted'>
                                                    <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                        {item.description}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Card>
                                :
                                    <Card className="border-0 shadow py-2 d-flex align-items-center justify-content-center">
                                        <FormattedMessage id="SummaryConfig.noMetaAnalysisPromptConfigList" />
                                    </Card>
                                }
                            </div>
                        </Form>
                    </ModalBody>
                </Modal>
                {createModal ?
                    <CreatePrompt
                        types={types}
                        type={"MetaAnalysis"}
                        updatePromptConfigList={this.props.updatePromptConfigList}
                        newPrompt={newPrompt}
                        toggleCreateModal={this.toggleCreateModal}
                    />
                :''}
            </BlockUi>
        );
    }
}
export default injectIntl(ConfigModal);