import React, { Component } from 'react';
import { Row, Col, Card, Button, Form } from 'reactstrap';
import { Crt, StyledCard } from '../../../Base/CommonUIComponents';
import { handleNotification } from '../../../Base/Notification';
import { FormattedMessage, injectIntl } from "react-intl";
import CustomSelect from "../../../Base/CustomSelect";
import { DateRangePicker } from 'react-dates';
import { getAPI } from '../../../Base/API';
import Chart from "react-google-charts";
import { Line } from 'react-chartjs-2';
import moment from 'moment';


class ConversationAnalytics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            fromDate: moment().subtract(15, 'days'),
            toDate: moment(),
            analytics: {},
            allConversations: { labels: [], datasets: [] },
            freeConversations: { labels: [], datasets: [] },
            paidConversations: { labels: [], datasets: [] },
            granulatarity: 'DAILY',
            globalAnalytics: {}
        }
    }

    componentDidMount() {
        this.getAnalytics();
    }

    getAnalytics = (e) => {
        if (e) e.preventDefault();
        this.setState({ block: true });

        //Available Dimensions: "CONVERSATION_DIRECTION", "CONVERSATION_TYPE", "COUNTRY", "PHONE"

        var params = `?fromDate=${moment(this.state.fromDate).format("YYYY-MM-DD")}&toDate=${moment(this.state.toDate).format("YYYY-MM-DD")}`;
        params += `&granulatarity=${this.state.granulatarity}&dimensionsTypes=CONVERSATION_DIRECTION&dimensionsTypes=CONVERSATION_TYPE&dimensionsTypes=COUNTRY&dimensionsTypes=PHONE`;

        if (this.state.phoneNumbers) this.state.phoneNumbers.forEach(pn => { params += `&phoneNumbers=${pn}` });
        if (this.state.countryCodes) this.state.countryCodes.forEach(cc => { params += `&countryCodes=${cc}` });


        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data)
                }

                this.organizeData(data.response);

                this.setState({ block: false, error: errorMessage });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/WhatsApp/v1/conversationanalytics` + params);
    }

    organizeData = (resp) => {
        const fromDate = moment(this.state.fromDate).format("YYYY-MM-DD 00:00");
        const toDate = moment(this.state.toDate).format("YYYY-MM-DD 23:59");
        var formatDate = 'YYYY-MM-DD', type = 'days';

        let startArray = [];

        if (this.state.granulatarity === 'HALF_HOUR') {
            formatDate = 'YYYY-MM-DD HH:mm';
            type = 'hours';

            startArray = [moment(fromDate).format(formatDate), moment(fromDate).format("YYYY-MM-DD HH:30")];
        }
        else {
            if (this.state.granulatarity === 'MONTHLY') {
                formatDate = 'YYYY-MMM';
                type = 'months';
            }

            startArray = [moment(fromDate).format(formatDate)];
        }


        var dates = new Array(moment(toDate).diff(moment(fromDate), type)).fill(undefined)
            .reduce((acc, cur) => {
                const date = this.state.granulatarity === 'HALF_HOUR' ? moment(fromDate).add((acc.length / 2 || 1), type) : moment(fromDate).add(acc.length, type);

                acc.push(date.format(formatDate));

                if (this.state.granulatarity === 'HALF_HOUR') {
                    acc.push(date.format("YYYY-MM-DD HH:30"));
                }

                return acc;
            }, startArray);


        const globalAnalytics = {
            totalConversations: 0,
            totalFreeConversations: 0,
            totalPaidConversations: 0,
            totalCost: 0
        }

        let conversationsByCountry = [["Country", "Total"]];

        let allConversations = {
            labels: dates, datasets: [
                { value: 'all', label: this.props.intl.formatMessage({ id: "WhatsAppAnalytics.All" }), data: Array(dates.length).fill(0), backgroundColor: '#0867fa', borderColor: '#0867fa', fill: false },
                { value: 'business initiated', label: this.props.intl.formatMessage({ id: "WhatsAppAnalytics.business_initiated" }), data: Array(dates.length).fill(0), backgroundColor: '#fa339a', borderColor: '#fa339a', fill: false },
                { value: 'user initiated', label: this.props.intl.formatMessage({ id: "WhatsAppAnalytics.user_initiated" }), data: Array(dates.length).fill(0), backgroundColor: '#ff8a00', borderColor: '#ff8a00', fill: false }
            ]
        };

        let freeConversations = {
            labels: dates, datasets: [
                { value: 'business initiated', label: this.props.intl.formatMessage({ id: "WhatsAppAnalytics.FREE_ENTRY" }), data: Array(dates.length).fill(0), backgroundColor: "#6EDE8A", borderColor: "#6EDE8A", fill: false },
                { value: 'user initiated', label: this.props.intl.formatMessage({ id: "WhatsAppAnalytics.FREE_TIER" }), data: Array(dates.length).fill(0), backgroundColor: "#4CC9F0", borderColor: "#4CC9F0", fill: false }
            ]
        };

        let paidConversations = {
            labels: dates, datasets: [
                { value: 'business initiated', label: this.props.intl.formatMessage({ id: "WhatsAppAnalytics.business_initiated" }), data: Array(dates.length).fill(0), backgroundColor: '#fa339a', borderColor: '#fa339a', fill: false },
                { value: 'user initiated', label: this.props.intl.formatMessage({ id: "WhatsAppAnalytics.user_initiated" }), data: Array(dates.length).fill(0), backgroundColor: '#ff8a00', borderColor: '#ff8a00', fill: false }
            ]
        };

        resp && resp.length > 0 && resp[0].conversation_analytics?.data && resp[0].conversation_analytics.data.length > 0 &&
            resp[0].conversation_analytics.data[0].data_points.forEach(el => {
                const date = this.props.converUnixTimestampToDate(el.start, formatDate);
                const dateIndex = dates.findIndex(el => el === moment(date).format(formatDate));
                const countryIndex = conversationsByCountry.findIndex(c => c[0] === el.country);

                if (countryIndex !== -1) {
                    conversationsByCountry[countryIndex][1] += el.conversation;
                }
                else {
                    conversationsByCountry.push([el.country, el.conversation])
                }

                if (dateIndex !== -1) {
                    globalAnalytics.totalCost += el.cost;
                    globalAnalytics.totalConversations += el.conversation;

                    allConversations.datasets[0].data[dateIndex] += el.conversation;

                    if (el.conversation_direction === 'BUSINESS_INITIATED') {
                        allConversations.datasets[1].data[dateIndex] += el.conversation;
                    }
                    else if (el.conversation_direction === 'USER_INITIATED') {
                        allConversations.datasets[2].data[dateIndex] += el.conversation
                    }


                    if (el.conversation_type === 'REGULAR') {
                        globalAnalytics.totalPaidConversations += el.conversation;

                        if (el.conversation_direction === 'BUSINESS_INITIATED') {
                            paidConversations.datasets[0].data[dateIndex] += el.conversation;
                        }
                        else if (el.conversation_direction === 'USER_INITIATED') {
                            paidConversations.datasets[1].data[dateIndex] += el.conversation
                        }
                    }
                    else {
                        globalAnalytics.totalFreeConversations += el.conversation;

                        if (el.conversation_type === 'FREE_ENTRY') {
                            freeConversations.datasets[0].data[dateIndex] += el.conversation;
                        }
                        else if (el.conversation_type === 'FREE_TIER') {
                            freeConversations.datasets[1].data[dateIndex] += el.conversation;
                        }
                    }
                }
        })

        this.setState({ allConversations, freeConversations, paidConversations, globalAnalytics, conversationsByCountry })
    }

    handleSelect = (combo, name) => {
        var targetValue = '';

        if (Array.isArray(combo)) {
            targetValue = combo.map(el => el.value);
        }
        else {
            targetValue = combo ? combo.value : null;
        }

        this.setState({ [name]: targetValue })
    };


    render() {
        const { block, error, globalAnalytics, allConversations, freeConversations, paidConversations, conversationsByCountry } = this.state;

        const optionsLine = {
            type: 'line',
            maintainAspectRatio: false,
            legend: {
                display: true,
                position: "top"
            },
            scales: {
                x: { display: true },
                y: {
                    beginAtZero: true, min: 0,
                    grid: {
                        display: false
                    },
                }
            },
            plugins: {
                datalabels: { display: false }
            }
        }

        const granularityOptions = [
            { value: 'HALF_HOUR', label: <FormattedMessage id="WhatsAppAnalytics.HALF_HOUR" /> },
            { value: 'DAILY', label: <FormattedMessage id="WhatsAppAnalytics.DAILY" /> },
            { value: 'MONTHLY', label: <FormattedMessage id="WhatsAppAnalytics.MONTHLY" /> }
        ]


        return (
            <StyledCard block={block} error={error} >
                <Form onSubmit={this.getAnalytics}>
                    <Row>
                        <Col className="col-2">
                            <CustomSelect
                                options={this.props.phoneNumbersOptions}
                                isClearable
                                isSearchable
                                isMulti
                                placeholder={<FormattedMessage id="WhatsAppAnalytics.PhoneNumbers" />}
                                onChange={(e) => this.handleSelect(e, "phoneNumbers")}
                            />
                        </Col>
                        <Col className="col-2">
                            <CustomSelect
                                options={granularityOptions}
                                isSearchable
                                placeholder={""}
                                onChange={(e) => this.handleSelect(e, "granulatarity")}
                                value={granularityOptions.find(el => el.value === this.state.granulatarity) || ''}
                                required
                            />
                        </Col>
                        <Col sm={3}>
                            <DateRangePicker
                                startDate={this.state.fromDate}
                                startDateId="startDate"
                                isOutsideRange={day => day > moment()}
                                endDate={this.state.toDate}
                                endDateId="endDate"
                                onDatesChange={({ startDate, endDate }) => this.setState({ fromDate: startDate, toDate: endDate })}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                required
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className=" btn btn-host btn-sm mt-2" type="submit">
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <Row className="my-3">
                    <Col>
                        <Crt text={<FormattedMessage id="WhatsAppAnalytics.TotalConversations" />} value={globalAnalytics.totalConversations || 0} />
                    </Col>
                    <Col>
                        <Crt text={<FormattedMessage id="WhatsAppAnalytics.TotalFreeConversations" />} value={globalAnalytics.totalFreeConversations || 0} />
                    </Col>
                    <Col>
                        <Crt text={<FormattedMessage id="WhatsAppAnalytics.TotalPaidConversations" />} value={globalAnalytics.totalPaidConversations || 0} />
                    </Col>
                    <Col>
                        <Crt text={<FormattedMessage id="WhatsAppAnalytics.TotalCost" />} value={globalAnalytics.totalCost || 0} />
                    </Col>
                </Row>

                <Card className="border-0 shadow" body>
                    <h5 className="text-muted"><FormattedMessage id="WhatsAppAnalytics.AllConversations" /> </h5>
                    <div style={{ height: '300px' }}>
                        <Line data={allConversations} height="100" options={optionsLine} />
                    </div>
                </Card>
                <Card className="border-0 shadow my-3" body >
                    <h5 className="text-muted"><FormattedMessage id="WhatsAppAnalytics.FreeConversations" /> </h5>
                    <div style={{ height: '300px' }}>
                        <Line data={freeConversations} height="100" options={optionsLine} />
                    </div>
                </Card>
                <Card className="border-0 shadow" body>
                    <h5 className="text-muted"><FormattedMessage id="WhatsAppAnalytics.PaidConversations" /> </h5>
                    <div style={{ height: '300px' }}>
                        <Line data={paidConversations} height="100" options={optionsLine} />
                    </div>
                </Card>

                <Card className="border-0 shadow mt-3" body>
                    <h5 className="text-muted"><FormattedMessage id="WhatsAppAnalytics.ConversationsByCountry" /> </h5>
                    <Row>
                        <Col className="col-11 pr-0" style={{ height: '330px', overflow: 'hidden' }}>
                            {conversationsByCountry ?
                                <Chart
                                    width='auto'
                                    height='100%'
                                    chartType="GeoChart"
                                    data={conversationsByCountry}
                                    options={{
                                        colorAxis: { colors: ['#d6edfe', '#0867fa'] }
                                    }}
                                />
                                : ''}
                        </Col>

                        <Col style={{ height: '330px', overflow: 'auto' }} className="col-1 verticalScroll pl-2">
                            {conversationsByCountry && conversationsByCountry
                                .slice(1, conversationsByCountry.length)
                                .sort((a, b) => b[1] - a[1]).map((arr, key) =>
                                    <Row className="mb-2 text-right" key={key} style={{ cursor: 'pointer' }}>
                                        <Col onClick={() => arr[0] && this.setFilters('nationality', arr[0])} className="px-0 text-right">
                                            {arr[0] ?
                                                <span style={{ borderRadius: '3px', fontSize: '18px' }} className={' mr-1 fa-md flag-icon flag-icon-' + arr[0].toLowerCase()} />
                                                : <b className='mr-2' > NA </b>}
                                        </Col>
                                        <Col className="px-2 col-5 text-left">
                                            <span > {arr[1]} </span>
                                        </Col>
                                    </Row>
                                )}
                        </Col>
                    </Row>
                </Card>
            </StyledCard>
        )
    }
}

export default injectIntl(ConversationAnalytics);