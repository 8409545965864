import React, { Component } from 'react'
import ChatGPT from '../../img/chatgpt.png';
import ChatGPTWhite from '../../img/chatgptwhite.png';

export default class GPTComponent extends Component {

    render() {
        const { menuVisible, toggleMenuVisible, showDetails } = this.props;

        return (
            <div>
                <div onClick={toggleMenuVisible} className={`shadow GPTbadge ${menuVisible || showDetails ? 'activeBadge' : ''}`}>
                    <img alt="GPT Generation" width='20' height='20' src={ChatGPT}/>
                    <img alt="GPT Generation" width='20' height='20' src={ChatGPTWhite}/>
                </div>  
            </div>
        )
    }
}