import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { prettifyXml } from '../../Base/CommonUIComponents';
import classnames from 'classnames';

export class SystemEventsModal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeTab: '1'
        }
	}

	toggle = (tab) => {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}

	render() {
		const { modal, toggle, eventDescription, eventPayload } = this.props;
		const { activeTab } = this.state;

        return (
			<Modal isOpen={modal} toggle={() => toggle(null, null)} >
				<ModalBody>
					<Nav tabs>
						<NavItem>
							<NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
								Stack Trace
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
								<FormattedMessage id="SystemEvents.Description" />
							</NavLink>
						</NavItem>
					</Nav>
					<TabContent activeTab={this.state.activeTab}>
						<TabPane tabId="1">
							<pre>
								<code>
									{eventPayload && eventPayload.length > 0 && eventPayload.charAt(0) === '<' ?
										prettifyXml(eventPayload)
										:
										eventPayload
									}
								</code>
							</pre>
						</TabPane>
						<TabPane tabId="2">
							{eventDescription}
						</TabPane>
					</TabContent>
				</ModalBody>
			</Modal>
        );
    }
}