import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Modal, ModalFooter } from 'reactstrap';
import { ErrorAlert } from "./ErrorAlert";
import BlockUi from 'react-block-ui';

class ConfirmActionModal extends Component {

    render() {
        const { block, error, text, toggleModal, modal, actionFunction, children, winDealActionFunction, winDealModal } = this.props;

        return (
            <Modal isOpen={modal} toggle={toggleModal} fade={false} className="modal-md" style={{ minWidth: 0, marginTop: '15%' }}>
                <BlockUi tag="div" blocking={block}>
                    <ErrorAlert error={error} />

                    <div className="text-center py-4 px-2">
                        {text}
                    </div>

                    {children}

                    <ModalFooter className="py-2 border-0">
                        <Button className={`btn btn-sm btn-host ${winDealModal ? '' : 'mr-2'}`} onClick={actionFunction}>
                            <FormattedMessage id="generic.Confirm" />
                        </Button>
                        {winDealModal ? 
                            <Button className="btn btn-info btn-custom-h btn-sm" onClick={winDealActionFunction}>
                                <FormattedMessage id="SalesProcess.ConfirmAndChangeStage" />
                            </Button>
                        :''}
                        <Button className="btn btn-sm" onClick={toggleModal}>
                            <FormattedMessage id="generic.Cancel" />
                        </Button>
                    </ModalFooter>
                </BlockUi>
            </Modal>
        );
    }
}
export default injectIntl(ConfirmActionModal)
