import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { BlankCard, CommonHelper, StyledModal } from "../../../../Base/CommonUIComponents";
import { Button, Col, Row, Input, FormGroup, Form } from "reactstrap";
import { getAPI, patchAPI } from "../../../../Base/API";
import { handleNotification } from "../../../../Base/Notification";
import { ActiveInactiveStatusCombo } from "../../../../Base/CommonUIComponents";

export class AddressModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            settings: { list: [""] }
        }
    }

    componentDidMount() {
        this.getSettings();
    }

    getSettings = (e) => {
        if (e) e.preventDefault();

        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                let settings = data.response;

                if (data.response && !data.response.list) {
                    settings = { ...settings, list: [""] };
                }

                this.setState({ settings, block: false });
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SendGrid/v1/mailsettings/addresswhitelist`);
    }

    handleSave = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const { settings } = this.state;

        patchAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ block: false, error: errorMessage });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="MailSettings.SettingsSaved" />, <FormattedMessage id="generic.success" />);

                    this.props.updateSettings();
                }
            }
            this.setState({ block: false });
        }, `/api/gms/SendGrid/v1/mailsettings/addresswhitelist?enabled=${settings.enabled}`, settings.list)
    }

    addEmail = () => {
        const { settings } = this.state;

        settings.list.push("");

        this.setState({ settings });
    }

    clearEmail = (index) => {
        const { settings } = this.state;

        if (index > 0) {
            settings.list.splice(index, 1)
        }
        else {
            settings.list[index] = "";
        }

        this.setState({ settings });
    }

    handleChange = (e, index) => {
        const { settings } = this.state;

        settings.list[index] = e.target.value;

        this.setState({ settings });
    }

    handleSelect = (name, combo) => {
        this.setState(prevState => ({
            settings: {
                ...prevState.settings,
                [name]: combo ? combo.value : null
            }
        }));
    };

    render() {
        const { modal, toggleModal } = this.props;
        const { block, error, settings } = this.state;
       
        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="md">
                <BlankCard block={block} error={error}>
                    <Form onSubmit={this.handleSave}>
                        <Row className="align-items-center mb-3">
                            <Col>
                                <h5 className="m-0"><FormattedMessage id="MailSettings.AddressWhitelist" /> </h5>
                            </Col>
                            <Col className="text-right col-2">
                                <Button className="btn-sm btn-host" type="submit">
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>

                        <div className="text-muted mb-4 py-3" style={{ textAlign: 'justify' }}>
                            <FormattedMessage id="MailSettings.AddressWhitelistText" />
                        </div>

                        <FormGroup row className="pt-2">
                            <Col>
                                <div className="pb-1"> <FormattedMessage id="generic.status" /> </div>

                                <ActiveInactiveStatusCombo
                                    name='enabled'
                                    onChangeFunc={this.handleSelect}
                                    placeholder={this.props.intl.formatMessage({ id: "generic.status" })}
                                    value={settings.enabled}
                                    required={true}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row className="pt-2">
                            <Col>
                                <Row className="pb-1">
                                    <Col> <FormattedMessage id="MailSettings.EmailAddressOrDomain" /> </Col>
                                    <Col sm={2}> <CommonHelper help={<FormattedMessage id="AddressModal.Help" />} id={'sendgridAddressWhitelist'} /> </Col>
                                </Row>

                                {settings.list.map((email, key) =>
                                    <div key={key} style={{ height: '50px' }}>
                                        <Input
                                            style={{ paddingRight: '45px' }}
                                            required
                                            type="text"
                                            onChange={(e) => this.handleChange(e, key)}
                                            value={email || ''}
                                        />
                                        <i className="fas fa-trash-alt text-danger border-left pointer"
                                            style={{ position: 'relative', left: '91.5%', top: '-28px', padding: '0 12px' }}
                                            onClick={() => this.clearEmail(key)}
                                        />
                                    </div>
                                )}
                                <Button className="btn-sm btn-host mt-2" onClick={this.addEmail} disabled={!settings || !settings.list[settings.list.length - 1]}>
                                    <FormattedMessage id="MailSettings.AddAnotherAddressOrDomain" />                                    
                                </Button>
                            </Col>
                        </FormGroup>
                    </Form>
                </BlankCard>
            </StyledModal>
        );
    }
}

export default injectIntl(AddressModal);