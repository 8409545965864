import React, { Component } from 'react';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { BlankCard, SelectHotel } from '../Base/CommonUIComponents';
import { handleNotification } from '../Base/Notification';
import { getAPI, putAPI } from '../Base/API';
import { getPMSReferenceDataType } from '../Base/ReferenceDataFunctions';
import CustomSelect from '../Base/CustomSelect';

export class ProfileDefaultDetail extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.saveDefaultTexts = this.saveDefaultTexts.bind(this);
        this.state = {
            block: false,
            defaultTexts: this.props.defaultTexts || {},
            referenceDataTypes: getPMSReferenceDataType()
        };
    }

    componentDidMount() {
        if (this.props.defaultTexts && this.props.defaultTexts.hotelId) {
            this.loadReferenceData();
        }
    }

    getReferenceData = (propName, type, apiName) => {
        this.setState({ [`block${propName}`]: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, [`block${propName}`]: false });
                return;
            }
            if (data) {
                let list = [];

                data.response && data.response.forEach(el => {
                    list.push({ value: el.code, label: <span>{el.description}<b className='ml-1'>({el.code})</b></span>, description: el.description, hotelId: el.hotelId });
                })
                

                this.setState({ [`${propName}Options`]: list, [`block${propName}`]: false })
            }
            else this.setState({ [`block${propName}`]: false })
        }, `/api/gms/Hotel/v1/${apiName}?type=${type}&hotelId=${this.state.defaultTexts.hotelId}`);
    }

    saveDefaultTexts(evt) {
        evt.preventDefault();
        if (!this.form.current.reportValidity()) {
            return;
        }

        const { profileId, updateTable } = this.props;
        const { defaultTexts } = this.state;

        const request = {
            request: defaultTexts
        };


        this.setState({ block: true });
        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="ProfileDetails.defaultSaved" />, <FormattedMessage id="generic.success" />);

                    data.response[0].hotelName = defaultTexts.hotelName;
                    Object.assign(defaultTexts, data.response[0]);

                    this.setState({ defaultTexts, error: errorMessage, block: false }, _ => updateTable(data.response[0], 'profileDefault'));

                    this.props.toggle();
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Profile/v1/profile/default/${profileId}`, request);
    }

    handleSelectHotel = (combo) => {
        this.setState(prevState => ({
            packageOptions: [],
            priceListOptions: [],
            roomOptions: [],
            defaultTexts: {
                ...prevState.defaultTexts,
                hotelId: combo ? combo.value : null,
                hotelName: combo ? combo.label : null
            }
        }), () => {
            if (combo) {
                this.loadReferenceData()
            }
        })
    }

    handleSelect = (name, combo) => {
        this.setState(prevState => ({
            defaultTexts:{
                ...prevState.defaultTexts, 
                [name]: combo ? combo.value : null
            }
        }))
    }

    loadReferenceData = () => {
        const room = this.state.referenceDataTypes.find(el => el.value === "Category");
        const ratePackage = this.state.referenceDataTypes.find(el => el.value === "Package");
        const priceList = this.state.referenceDataTypes.find(el => el.value === "PriceList");

        this.getReferenceData('room', room.value, room.endpoint);
        this.getReferenceData('package', ratePackage.value, ratePackage.endpoint);
        this.getReferenceData('priceList', priceList.value, priceList.endpoint);
    }

    handleChange(evt) {
        if (evt && evt.target) {
            const { defaultTexts } = this.state;
            const { name, value } = evt.target;
            defaultTexts[name] = value;
            this.setState({ defaultTexts });
        }
    }

    render() {
        const { isOpen, toggle, title, hotels } = this.props;
        const { block, error, defaultTexts } = this.state;

        return (
            <Modal isOpen={isOpen} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={toggle} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <form ref={this.form}>
                            <Row className="mb-3">
                                <Col>
                                    <h5> {title} </h5>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" onClick={this.saveDefaultTexts}>
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>

                            <FormGroup row>
                                <Label sm={2}>
                                    <FormattedMessage id="generic.Hotel" />
                                </Label>
                                <Col sm={4}>
                                    <CustomSelect
                                        id="hotelId"
                                        name="hotelId"
                                        options={hotels.filter(h => !h.isDisabled)}
                                        value={hotels.find(opt => opt.value === defaultTexts.hotelId)}
                                        onChange={(e) => this.handleSelectHotel(e)}
                                        required
                                        isDisabled={defaultTexts.id}
                                        placeholder={""}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2}>
                                    <FormattedMessage id="ProfileDetails.RoomCategory" />
                                </Label>
                                <Col sm={4}>
                                    <CustomSelect
                                        options={this.state.roomOptions || []}
                                        isClearable
                                        isSearchable
                                        isDisabled={!defaultTexts.hotelId}
                                        placeholder=""
                                        onChange={(e) => this.handleSelect("defaultRoomCategory", e)}
                                        value={defaultTexts.defaultRoomCategory ? this.state.roomOptions?.find(ro => ro.value === defaultTexts.defaultRoomCategory) || { value: defaultTexts.defaultRoomCategory, label: defaultTexts.defaultRoomCategory } : ''}
                                        isLoading={this.state.blockroom}
                                    />
                                </Col>
                                <Label sm={2}>
                                    <FormattedMessage id="ProfileDetails.Room" />
                                </Label>
                                <Col sm={4}>
                                    <Input
                                        type="text"
                                        id="defaultRoom"
                                        name="defaultRoom"
                                        value={defaultTexts.defaultRoom}
                                        onChange={this.handleChange}
                                        maxLength="10"
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2}>
                                    <FormattedMessage id="ProfileDetails.Package" />
                                </Label>
                                <Col sm={4}>
                                    <CustomSelect
                                        options={this.state.packageOptions || []}
                                        isClearable
                                        isSearchable
                                        isDisabled={!defaultTexts.hotelId}
                                        placeholder=""
                                        onChange={(e) => this.handleSelect("defaultPackage", e)}
                                        value={defaultTexts.defaultPackage ? this.state.packageOptions?.find(ro => ro.value === defaultTexts.defaultPackage) || { value: defaultTexts.defaultPackage, label: defaultTexts.defaultPackage } : ''}
                                        isLoading={this.state.blockratePackage}
                                    />
                                </Col>
                                <Label sm={2}>
                                    <FormattedMessage id="ProfileDetails.PriceList" />
                                </Label>
                                <Col sm={4}>
                                    <CustomSelect
                                        options={this.state.priceListOptions || []}
                                        isClearable
                                        isSearchable
                                        isDisabled={!defaultTexts.hotelId}
                                        placeholder=""
                                        onChange={(e) => this.handleSelect("defaultPricelist", e)}
                                        value={defaultTexts.defaultPricelist ? this.state.priceListOptions?.find(ro => ro.value === defaultTexts.defaultPricelist) || { value: defaultTexts.defaultPricelist, label: defaultTexts.defaultPricelist } : ''}
                                        isLoading={this.state.blockdefaultPricelist}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2}>
                                    <FormattedMessage id="ProfileDetails.Discount" />
                                </Label>
                                <Col sm={4}>
                                    <Input
                                        type="text"
                                        id="defaultDiscount"
                                        name="defaultDiscount"
                                        value={defaultTexts.defaultDiscount}
                                        onChange={this.handleChange}
                                        maxLength="10"
                                    />
                                </Col>
                                <Label sm={2}>
                                    <FormattedMessage id="ProfileDetails.DiscountRule" />
                                </Label>
                                <Col sm={4}>
                                    <Input
                                        type="text"
                                        id="defaultDiscountRule"
                                        name="defaultDiscountRule"
                                        value={defaultTexts.defaultDiscountRule}
                                        onChange={this.handleChange}
                                        maxLength="10"
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2}>
                                    <FormattedMessage id="ProfileDetails.PriceListPOS" />
                                </Label>
                                <Col sm={4}>
                                    <Input
                                        type="text"
                                        id="defaultPricelistPOS"
                                        name="defaultPricelistPOS"
                                        value={defaultTexts.defaultPricelistPOS}
                                        onChange={this.handleChange}
                                        maxLength="10"
                                    />
                                </Col>
                                <Label sm={2}>
                                    <FormattedMessage id="ProfileDetails.RestaurantTable" />
                                </Label>
                                <Col sm={4}>
                                    <Input
                                        type="text"
                                        id="defaultRestaurantTable"
                                        name="defaultRestaurantTable"
                                        value={defaultTexts.defaultRestaurantTable}
                                        onChange={this.handleChange}
                                        maxLength="10"
                                    />
                                </Col>
                            </FormGroup>
                        </form>
                    </BlankCard>
                </ModalBody>
            </Modal>                
        );
    }
}