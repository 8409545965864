import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Button, Form, Input, Nav, NavLink, NavItem } from 'reactstrap';
import { StyledModal } from '../../Base/CommonUIComponents';
import { ErrorAlert } from '../../Base/ErrorAlert';
import { SelectCustomType } from '../SalesProcessFunctions';
import BlockUi from 'react-block-ui';
import SalesDealList from '../SalesDealList';
import SalesLeads from '../SalesLeads';
import SalesInitiativeList from '../SalesInitiativeList';

const AssociationModal = ({ block, error, toggleModal, modal, salesUsers, salesPipelines, salesSources, salesSourcesTypes, dealList, currentDeal, getDealList, associateDeal, isInitiative, associateInitiative, renderActivityHelperMsg, totalDeals }) => {
    const [selectedDeal, setSelectedDeal] = useState(null);
    const [selectedInitiative, setSelectedInitiative] = useState(null);
    const [dealRelationType, setDealRelationType] = useState(null);
    const [activeTab, setActiveTab] = useState("Deal");

    const selectDeal = (deal) => {
        setSelectedDeal(deal);
    }

    const selectInitiativeDetail = (initiave) => {
        setSelectedInitiative(initiave);
    }

    const removeSelection = () => {
        setSelectedDeal(null);
        setSelectedInitiative(null);
    }

    const handleChangeRelationType = (combo) => {
        setDealRelationType(combo ? combo.value : null);
    }

    const saveAssociation = (e) => {
        e.preventDefault();

        if (activeTab === 'Initiative') {
            associateInitiative(e, currentDeal?.id, selectedInitiative?.id, dealRelationType);
        }
        else {
            associateDeal(e, currentDeal?.id, selectedDeal?.id, dealRelationType);
        }
    }

    return ( 
        <StyledModal toggleModal={toggleModal} modal={modal} size="xl" style={{ minWidth: 0, maxWidth: '85%', width: '85%' }}>
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />
                <div className='p-1'>

                    <Nav tabs className='mb-3 pb-2'>
                        <NavItem>
                            <NavLink className={activeTab === 'Deal' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => setActiveTab('Deal')} style={{ cursor: "pointer" }}>
                                <i className="fas fa-briefcase mr-2" />
                                <FormattedMessage id="SalesProcess.AssociateDeal" />
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={activeTab === 'Lead' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => setActiveTab('Lead')} style={{ cursor: "pointer" }}>
                                <i className="fas fa-bullseye mr-2" />
                                <FormattedMessage id="SalesProcess.AssociateLead" />
                            </NavLink>
                        </NavItem>
                        {isInitiative !== true ?
                            <NavItem>
                                <NavLink className={activeTab === 'Initiative' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => setActiveTab('Initiative')} style={{ cursor: "pointer" }}>
                                    <i className="fas fa-rocket mr-2" />
                                    <FormattedMessage id="SalesProcess.AssociateInitiative" />
                                </NavLink>
                            </NavItem>
                        : ''}
                    </Nav>



                    <div>
                        <Form onSubmit={(e) => saveAssociation(e)}>
                            <Row className="mb-4 mt-2 align-items-center">
                                <Col className='d-flex flex-column mb-3 col-3'>
                                    <p className='m-0 pb-2'><FormattedMessage id={`SalesProcess.Selected${activeTab}`} /></p>
                                    <div className='d-flex align-items-center'>
                                        <Input
                                            type="text"
                                            placeholder=""
                                            value={selectedDeal?.name || selectedInitiative?.name || '-'}
                                            required
                                            disabled
                                        />
                                        {selectedDeal || selectedInitiative ?
                                            <i onClick={() => removeSelection(null)} className="fas fa-trash-alt text-danger cursor-pointer ml-2" />
                                            : ''}
                                    </div>
                                </Col>
                                <Col className='d-flex flex-column mb-3 col-3'>
                                    <p className='m-0 pb-2'><FormattedMessage id={`SalesProcess.Select${activeTab}AssociationType`} /></p>
                                    <SelectCustomType
                                        name="DealRelationType"
                                        onChangeFunc={(combo) => handleChangeRelationType(combo)}
                                        required={true}
                                        value={dealRelationType}
                                        placeholder=""
                                        isDisabled={false}
                                        isClearable={true}
                                        type={'DealRelationType'}
                                    />
                                </Col>
                                <Col className='text-right'>
                                    <Button className="btn-sm btn-host btn btn-secondary" type="submit" disabled={!selectedDeal && !selectedInitiative}>
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            <hr />
                        </Form>

                        {activeTab === 'Deal' ?
                            <>
                                <div className="verticalScroll" style={{ height: '55vh', overflowX: 'hidden' }}>
                                    <SalesDealList
                                        selectDealDetail={selectDeal}
                                        icon={'fas fa-briefcase'}
                                        salesUsers={salesUsers}
                                        salesPipelines={salesPipelines}
                                        salesSources={salesSources}
                                        salesSourcesTypes={salesSourcesTypes}
                                        dealList={dealList}
                                        getDealList={getDealList}
                                        fromAssociateModal={true}
                                        renderActivityHelperMsg={renderActivityHelperMsg}
                                        totalDeals={totalDeals}
                                    />
                                </div>
                            </>
                            : activeTab === 'Lead' ?
                                <>
                                    <div className="verticalScroll" style={{ height: '55vh', overflowX: 'hidden' }}>
                                        <SalesLeads
                                            icon={"fas fa-bullseye"}
                                            salesUsers={salesUsers}
                                            salesSources={salesSources}
                                            selectDealDetail={selectDeal}
                                            fromAssociateModal={true}
                                            renderActivityHelperMsg={renderActivityHelperMsg}
                                        />
                                    </div>
                                </>
                                : activeTab === 'Initiative' ? 
                                    <>
                                        <div className="verticalScroll" style={{ height: '55vh', overflowX: 'hidden' }}>
                                            <SalesInitiativeList
                                                icon={"fas fa-rocket"}
                                                salesUsers={salesUsers}
                                                selectInitiativeDetail={selectInitiativeDetail}
                                                fromAssociateModal={true}
                                                renderActivityHelperMsg={renderActivityHelperMsg}
                                            />
                                        </div>
                                    </>
                        : ''}
                    </div>
                </div>
            </BlockUi>
        </StyledModal>
    )
};

export default injectIntl(AssociationModal);