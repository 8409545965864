import React, { Component } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Card, Row, Col } from 'reactstrap'
import { StyledCard } from '../../Base/CommonUIComponents'
import { getAPI } from '../../Base/API'
import { handleNotification } from '../../Base/Notification'
import { Pagination, ResultsPerPage, TotalResults } from '../../Base/PaginationComponents'
import moment from 'moment'

class ProfileJourneys extends Component {
    constructor(props) {
        super(props)
        this.state = {
            block: false,
            error: null,
            journeyRunList: [],
            startDateSearch: null,
            endDateSearch: null,
            pageSize: 10,
            pageIndex: 0,
            totalItems: 0
        }
    }

    componentDidMount() {
        this.getProfileJourneyRunList()
    }

    getProfileJourneyRunList = () => {
        const { pageSize, pageIndex } = this.state;
        const { profileId } = this.props;
        this.setState({ block: true }, () =>
            getAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if(data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    if (data.response && data.response.length > 0) {
                        this.setState({ block: false, journeyRunList: data.response, totalItems: data.totalItems });
                        return;
                    }
                }
                this.setState({ block: false });
            }, `/api/gms/Journey/v1/journey/profile/${profileId}?pageSize=${pageSize}&pageIndex=${pageIndex}`)
        )
    }

    changePageSize = (evt) => {
        const { value } = evt.target;

        if (this.state.pageSize !== value) {
            this.setState({ pageSize: value, pageIndex: 0 }, () => this.getProfileJourneyRunList());
        }
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.getProfileJourneyRunList());
    }

    render() {
        const { journeyRunList, pageIndex, pageSize, totalItems, block, error } = this.state
        return (
            <StyledCard block={block} error={error} className="ml-1 my-0 mr-0 border-0 bg-light h-100">
                <Row className="m-2 p-2">
                    <Col className="mb-2 p-0">
                        <h5 className='m-0'><i className="fas fa-project-diagram mr-2"></i><FormattedMessage id="ProfileDetails.CustomerJourney" /></h5>
                    </Col>
                </Row>
                {journeyRunList && journeyRunList.length > 0 ? 
                    <>
                        <Row className="m-2 p-2">
                            {journeyRunList.map((journeyRun, index) => {
                                if (journeyRun && journeyRun.journey) {
                                    const journey = journeyRun.journey;
                                    return (
                                        <Col key={index} className="mb-2">
                                            <Card 
                                                style={{ minHeight: '14vh', maxWidth: '20vw' }} 
                                                className='cursor-pointer d-flex flex-column justify-content-between profileCustomerJourneyHover'
                                                onClick={() => this.props.history.push({ pathname: "/JourneyBuilder/" + journey.id + '/' + journeyRun.id })}
                                            >
                                                <Row className="m-2 p-2">
                                                    <Col className="p-0 col-6">
                                                        <h5 className='m-0 text-truncate'>{journey.name}</h5>
                                                    </Col>
                                                    <Col className="p-0 col-6 text-right" style={{ fontSize: '12px' }}>
                                                        {journey.active ? (
                                                            <span className='m-0'>
                                                                <i className="fas fa-circle mr-2" style={{ color: 'blue' }} />
                                                                <FormattedMessage id="CustomerJourney.Running" />
                                                            </span>
                                                        ) : (
                                                            <span className='m-0'>
                                                                <i className="fas fa-circle mr-2" style={{ color: 'grey' }} />
                                                                <FormattedMessage id="CustomerJourney.Created" />
                                                            </span>
                                                        )}
                                                    </Col>
                                                    <Col className="mb-2 col-12 p-0">
                                                        <h6 className='m-0 text-muted text-truncate'>{journey.description}</h6>
                                                    </Col>
                                                </Row>
                                                <Row className="m-2 mb-2 px-2 pb-2">
                                                    <Col className="p-0">
                                                        {journey.updatedAt ? (
                                                            <h6 className='m-0 text-muted'>{moment(journey.updatedAt).format("YYYY-MM-DD HH:mm")}</h6>
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    );
                                } else {
                                    return '';
                                }
                            })}
                            
                        </Row>
                        <Row className="m-2 p-2">
                            <Col>
                                <ResultsPerPage
                                    changeSizePerPage={this.changePageSize}
                                    pageSize={pageSize}
                                />

                                <span className="ml-2">
                                    <TotalResults
                                        end={pageIndex * pageSize + journeyRunList.length}
                                        start={pageIndex * pageSize + 1}
                                        total={totalItems}
                                    />
                                </span>
                            </Col>

                            <Col className="text-right">
                                <Pagination
                                    isPrevDisabled={pageIndex === 0}
                                    isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                    currentPage={pageIndex + 1}
                                    handlePrevButton={() => this.handlePagination(-1)}
                                    handleNextButton={() => this.handlePagination(1)}
                                />
                            </Col>
                        </Row>
                    </>
                : 
                    <Row>
                        <Col className="d-flex flex-column align-items-center">
                            <h6><FormattedMessage id="ProfileDetails.NoJourneysAvailable" /></h6>
                        </Col>
                    </Row>
                }
            </StyledCard>
        )
    }
}

export default injectIntl(ProfileJourneys)