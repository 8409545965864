import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

export class LogsRequestModal extends Component {

    render() {
        const { modal, requestContent, requestType, toggle } = this.props;

        return (
            <Modal isOpen={modal}>
                <Button onClick={toggle} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <h5 className="mb-4">
                        <FormattedMessage id={`LogsRequestModal.${requestType}Content`} />
                    </h5>
                    <div className="overflow-auto" style={{ 'height': '70vh' }}>
                        <pre>
                            <code>
                                {
                                    requestContent
                                }
                            </code>
                        </pre>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        <FormattedMessage id="LogsRequestModal.Close" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}