import React, { Component } from 'react';
import { Button, Row, Col, Card, UncontrolledTooltip, UncontrolledPopover } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ChartLegend } from '../../Base/CustomCharts';


class CanceledDays extends Component {

    shouldComponentUpdate(nextProps) {
        return nextProps.canceledDays !== this.props.canceledDays
    }

    render() {
        const { canceledDays } = this.props;

        return (
            <Card body className="border-0 shadow h-100">
                <h5 className="text-muted mb-3"><FormattedMessage id="CancelationForecast.NumberOfCanceledDays" /></h5>

                <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-evenly' }}>
                    {canceledDays?.map((type, key) => {
                        return < div className = "mx-3 mb-3" key = { key } >
                            <b>{type.title}</b> <i id={`legend${key}`} className="text-secondary fas fa-sm fa-info-circle ml-2 " />

                                <UncontrolledTooltip data-trigger="focus" flip={false} placement='top' target={`legend${key}`} style={{ maxWidth: '500px', width: '500px', textAlign: 'left' } }>
                                <Row>
                                    {type.data?.map((obj, idx) =>
                                        <Col sm={3} key={`type-${idx}`} style={{ overflow: 'hidden' }}>
                                            <ChartLegend color={obj.bgColor} label={obj.label || 'N/A'} />
                                        </Col>
                                    )}
                                </Row>
                            </UncontrolledTooltip>

                            <div style={{ width: '100%', height: '15px', borderRadius: '5px', border: '1px solid #cbcbcb' }} className="d-flex shadow">
                                {type.data?.map((obj, idx) => {
                                    const id = `${type.title}-${idx}`;

                                    return <div style={{ backgroundColor: obj.bgColor, width: `${((obj.value * 100) / type.totalRoomNights)}%`, height: '100%', borderRadius: '1px', color: 'white', fontSize: '10px' }}
                                        key={idx} title={obj.label} id={id} >

                                        <UncontrolledTooltip placement="top" target={id} flip={false} >
                                            {obj.label || 'N/A'}: {obj.value}
                                        </UncontrolledTooltip>
                                    </div>
                                })}
                            </div>
                        </div>
                    }
                    )}
                </div>
            </Card>
        )
    }
}

export default injectIntl(CanceledDays);
