import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { Button, Card, CardBody, Col, Row, Collapse, UncontrolledTooltip } from 'reactstrap';
import { getAPI, postAPI } from "../../Base/API";
import { handleNotification } from "../../Base/Notification";
import { FormattedMessage, injectIntl } from 'react-intl';
import { DefaultPage, SelectHotel, StyledCard } from '../../Base/CommonUIComponents';
import { CustomTable } from '../../Base/CustomTable';
import CustomSelect from '../../Base/CustomSelect';

class AccessList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            accessList: [],
            hotels: []
        }
    }

    componentDidMount() {
        this.getHotel();
    }

    getHotel = () => {
        this.setState({ block: true });
        var combo = [];

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                var groups = [];

                data.response.map((item) => {
                    const obj = { value: item.hotelId, label: item.name2 };
                    const groupIndex = groups.findIndex(c => c.hotelGroupId === item.hotelGroupId);

                    if (groupIndex !== -1) {
                        groups[groupIndex].options.push(obj)
                    }
                    else {
                        groups.push({
                            hotelGroupName: item.hotelGroupName,
                            hotelGroupId: item.hotelGroupId,
                            options: [obj]
                        });
                    }
                })

                groups.forEach(group => {
                    combo.push({
                        label: <div className="pointer" onClick={(e) => this.handleChangeSelect('hotelId', group.options)}> {group.hotelGroupName} </div>,
                        groupId: group.hotelGroupId,
                        options: group.options
                    });
                })
                
            }
            this.setState({ hotels: combo, block: false });
        }, `/api/gms/Hotel/v1/hotel/list`);
    }

    getAccessList = () => {
        this.setState({ block: true });

        var params = this.state.hotelId ? `?${this.state.hotelId.map(el => `hotelsIds=${el}&`)}` : '';
        
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ accessList: data.response, block: false });
            }
            else this.setState({ block: false });
        }, `/api/User/v1/User/authorized` + params)
    }

    handleChangeSelect = (name, combo, action) => {
        var targetValue = this.state.hotelId ? [...this.state.hotelId] : [];

        if (action) {
            if (action.action === "select-option") {
                targetValue.push(action.option.value);
            }
            else if (action.action === "remove-value") {
                targetValue = targetValue.filter(c => c !== action.removedValue.value);
            }
            else if (action.action === "clear") {
                targetValue = []
            }
        }
        else if (Array.isArray(combo)) { //when hotel group label is selected
            targetValue = targetValue.concat(combo.map(el => el.value));
        }

        this.setState({ [name]: targetValue })
    };

    render() {
        const { block, error, accessList, hotels, hotelId } = this.state;

        const columns = [
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "ManageUser.name" }),
                sort: true
            },
            {
                dataField: 'surname',
                text: this.props.intl.formatMessage({ id: "ManageUser.surname" }),
                sort: true
            },
            {
                dataField: 'email',
                text: this.props.intl.formatMessage({ id: "ManageUser.email" }),
                sort: true
            },
            {
                dataField: 'groups',
                text: this.props.intl.formatMessage({ id: "AccessList.Groups" }),
                sort: true,
                formatter: (cell, row) => cell.join(', ') 
            },
            {
                dataField: 'allowedProperties',
                text: this.props.intl.formatMessage({ id: "AccessList.AllowedProperties" }),
                sort: true,
                formatter: (cell, row) => cell.join(', ')
            }
        ]

        return (
            <StyledCard block={block} error={error} title="NavMenu.AccessList" icon="fas fa-user-lock">
                <Row className="align-items-center my-3">
                    <Col className="col-6">
                        <CustomSelect
                            icon={"icon-icon-hotel"}
                            options={hotels}
                            isClearable
                            isMulti={true}
                            value={hotelId ? hotels.flatMap(el => el.options).filter(el => hotelId.find(v => el.value == v)) : ''}
                            isSearchable
                            placeholder={<FormattedMessage id="generic.Hotel" />}
                            onChange={(combo, action) => this.handleChangeSelect('hotelId', combo, action)}
                        />
                    </Col>
                    <Col className="text-right">
                        <Button className="btn btn-host btn-sm float-right" onClick={() => this.getAccessList()}>
                            <i className="icon-icon-search" />
                        </Button>
                    </Col>
                </Row>

                {accessList?.length > 0 ?
                    <CustomTable
                        data={this.state.accessList || []}
                        columns={columns}
                        shadow={false}
                        exportCSV={true}
                        search={true}
                        showTotal={true}
                        remote={false}
                    />
                : <DefaultPage text="AccessList.SelectHotel" icon="fas fa-user-lock" />}
            </StyledCard>
        );
    }
};

export default injectIntl(AccessList)