import React from "react";
import { Row, Col, Card, Badge } from 'reactstrap';
import { TimelineEvents } from '../../Base/CommonUIComponents';
import BlockUi from 'react-block-ui';
import moment from 'moment';


class JourneyView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            events: [],
            selectedWeek: null
        }
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.setState({
                events: nextProps.events,
                selectedWeek: nextProps.selectedWeek
            });

            if (nextProps.calendarView === 'Week' && nextProps.selectedWeek) {
                this.weeklyView(nextProps.events, nextProps.selectedWeek);
            }
        }
    }

    weeklyView = (events, week) => {
        let weeklyEvents = {};

        for (var i = 0; i < week.length; i++) {
            weeklyEvents[week[i]] = [];
        }

        events && events.forEach(event => {
            if (moment(event.startDate).isSame(this.props.currentDate, 'month')) {
                if (weeklyEvents[moment(event.startDate).format('D')]) {
                    weeklyEvents[moment(event.startDate).format('D')].push(event);
                }
                
            }
        })

        this.setState({ weeklyEvents })
    }

    render() {
        const { block, events, selectedWeek, weeklyEvents } = this.state;
        const { calendarView, currentDate } = this.props;


        return (
            <BlockUi tag="div" blocking={block} className="h-100">
                {calendarView === 'Day' ?
                    <Row className="h-100">
                        {events && events.slice(0, 8).map((event, i) =>
                            <Col className="col-3" key={i}>
                                <TimelineEvents
                                    date={moment(event.startDate).format('HH:mm')}
                                    description={event.description}
                                    eventType={event.referenceModule}
                                    status={event.status}
                                    location={event.location}
                                    journeyView='Day'
                                />
                            </Col>
                        )}
                    </Row>

                    :
                    <div>
                        <Row className="mb-3">
                            {selectedWeek && selectedWeek.map((day, i) =>
                                <Col key={i} className="text-center">
                                    <h4><b> {day} </b></h4>
                                    {moment(currentDate.format('YYYY-MM-' + day)).format('ddd')}
                                </Col>
                            )}
                        </Row>
                        <Row>
                            {weeklyEvents && Object.keys(weeklyEvents).map((day, i) =>
                                <Col key={i} className="text-center px-2">
                                    <Card className="border-0 shadow"> 
                                        {weeklyEvents[day] && weeklyEvents[day].map((event, i) =>
                                            <div key={i}>
                                                <TimelineEvents
                                                    date={moment(event.startDate).format('HH:mm')}
                                                    eventType={event.referenceModule}
                                                    journeyView='Week'
                                                />
                                            </div>
                                        )}
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    </div>
                }
            </BlockUi>
        );
    }
}

export default JourneyView;