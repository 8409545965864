import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { getMarketingType } from '../../Base/ReferenceDataFunctions';
import LinkedCampaignsConfig from '../Common/LinkedCampaignsConfig';
import { RecorringCampaignsDates } from '../Common/MarketingFunctions';

class CampaignStep3Linked extends Component {

    render() {
        const { filters, campaign } = this.props;

        return (
            <LinkedCampaignsConfig
                campaign={campaign}
                filters={filters}
                handleLinkMarketing={this.props.handleLinkMarketing}
                handleLinkCampCriteria={this.props.handleLinkCampCriteria}
                cleanFormData={this.props.cleanFormData}
                handleMultiSelect={this.props.handleMultiSelect}
                campaignsOptions={getMarketingType()}
                handleSelectFilters={this.props.handleSelectFilters}
            >
                <div className="pl-2">
                    <RecorringCampaignsDates
                        style={{ width: "16.6%" }}
                        campaign={campaign}
                        filters={filters}
                        inputClassName="pt-1 px-3"
                        labelClassName="px-3"
                        handleMultiDates={this.props.handleMultiDates}
                        handleChange={this.props.handleChange}
                        handleDateChange={this.props.handleDateChange}
                        handleHotelChange={this.props.handleHotelChange}
                    />
                </div>
            </LinkedCampaignsConfig>
        );
    }
}
export default injectIntl(CampaignStep3Linked)