import React, { Component } from 'react'
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import { Badge, Button, ButtonGroup, Col, Row, PopoverBody, Input } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../Base/CustomSelect';
import { DateRangePicker } from 'react-dates';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { CoolTooltip, FilterButton, FormatAmountNumber, CommonHelper } from '../Base/CommonUIComponents';
import { salesDealStatus } from '../Base/ReferenceDataFunctions';
import SalesDealModal from './SalesDealModal/SalesDealModal';
import SalesActionsPopOver from './SalesActionsPopOver';
import CustomToolTip from '../Base/CustomToolTip';
import CreateDealByImportGroupRes from './CreateDealByImportGroupRes';
import { DealStatusBadge, SalesProcessHotelCombo, SalesProcessUsersCombo, getAllowedSalesProcessUsers, checkSalesProcessPermission } from './SalesProcessFunctions';

class SalesDealList extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            modal: false,
            pipelineId: null,
            busStartDate: this.props.year && this.props.selectedMonth ?
                    this.props.selectedMonth ?
                        moment().set('year', this.props.year).set('month', this.props.selectedMonth - 1).startOf('month')
                    :
                        moment().set('year', this.props.year).startOf('year')
                : this.props.startDate ?
                    this.props.startDate
                : null,
            busEndDate: this.props.year && this.props.selectedMonth ?
                this.props.selectedMonth ?
                    this.props.isQuarter ?
                        moment().set('year', this.props.year).set('month', this.props.selectedMonth - 1).add(2, 'months').endOf('month')
                    :
                        moment().set('year', this.props.year).set('month', this.props.selectedMonth - 1).endOf('month')
                :
                    moment().set('year', this.props.year).endOf('year')
            : this.props.endDate ?
                this.props.endDate
            : null,
            staffIds: [],
            negStartDate: null,
            negEndDate: null,
            salesSourceId: this.props.selectedSource,
            hotel: this.props.selectedHotel,
            selectedStageTypeId: this.props.selectedStageTypeId,
            salesStatus: this.props.salesStatus,
            pageSize: 10,
            pageIndex: 0,
            queryString: ''
        };
    }

    componentDidMount() {
        var currentUser = getAllowedSalesProcessUsers()?.find(x => x.isCurrentUser);
        var importDealPermission = checkSalesProcessPermission("ImportDeals", currentUser?.role);

        this.setState({ importDealPermission });

        if (this.props.fromExecutiveReport || this.props.fromAssociateModal){
            this.getDealList();
        }else{
            this.getQueryStringValues();
        }

    }

    getDealList = () => {
        const { pipelineId, busStartDate, busEndDate, staffIds, negStartDate, negEndDate, salesSourceId, pageSize, pageIndex, hotel, salesStatus, selectedStageTypeId, createdStartDate, createdEndDate, dealName, salesPipelineStageId } = this.state;

        let qs = `?pageIndex=${pageIndex}&pageSize=${pageSize}&`;

        if(pipelineId)
            qs += `pipelineId=${pipelineId}&`;
        if(busStartDate)
            qs += `busStartDate=${moment(busStartDate).format('YYYY-MM-DD')}&`;
        if(busEndDate)
            qs += `busEndDate=${moment(busEndDate).format('YYYY-MM-DD')}&`;
        if(staffIds && staffIds.length > 0)
            staffIds.forEach(s => {
                qs += `staffIds=${s}&`;
            });
        if(negStartDate)
            qs += `negStartDate=${moment(negStartDate).format('YYYY-MM-DD')}&`;
        if(negEndDate)
            qs += `negEndDate = ${moment(negEndDate).format('YYYY-MM-DD')}&`;
        if (createdStartDate)
            qs += `createdStartDate=${moment(createdStartDate).format('YYYY-MM-DD')}&`;
        if (createdEndDate)
            qs += `createdEndDate=${moment(createdEndDate).format('YYYY-MM-DD')}&`;
        if (dealName)
            qs += `dealName=${dealName}&`;
        if (salesPipelineStageId)
            qs += `stageTypeId=${salesPipelineStageId}&`;
        if(salesSourceId)
            qs += `sourceId=${salesSourceId}&`;
        if (hotel && hotel.length > 0)
            hotel.forEach(h => {
                qs += `hotels=${h}&`;
            });
        if (salesStatus && salesStatus.length > 0){
            salesStatus.forEach(s => {
                qs += `status=${s}&`;
            });
        }

        if(selectedStageTypeId)
            qs += `stageTypeId=${selectedStageTypeId}&`;

        this.props.getDealList(qs, (errorMessage) => {
            this.setState({ block: false, error: errorMessage })
        });
    }

    getQueryStringValues = () => {
        let search = new URLSearchParams(window.location.search);
        search.delete('dealId');
    
        const hotel = search.get('hotel');
        const dealName = search.get('dealName');
        const staffIds = search.get('staffIds');
        const salesPipelineStageId = search.get('salesPipelineStageId');
        const busStartDate = search.get('busStartDate');
        const busEndDate = search.get('busEndDate');
        const salesStatus = search.get('salesStatus');
        const pipelineId = search.get('pipelineId');
        const salesSourceId = search.get('salesSourceId');
        const negStartDate = search.get('negStartDate');
        const negEndDate = search.get('negEndDate');
        const createdStartDate = search.get('createdStartDate');
        const createdEndDate = search.get('createdEndDate');

        const queryString = search?.toString()?.replace('?', '') || '';

        this.setState({
            hotel: hotel ? hotel.split(';') : [],
            dealName: dealName,
            staffIds: staffIds && staffIds.split(';') ? staffIds.split(';').map(id => parseInt(id, 10)) : [],
            salesPipelineStageId: salesPipelineStageId ? parseInt(salesPipelineStageId) : null,
            busStartDate: busStartDate ? moment(busStartDate) : null,
            busEndDate: busEndDate ? moment(busEndDate) : null,
            salesStatus: salesStatus ? salesStatus.split(';') : [],
            pipelineId: pipelineId ? parseInt(pipelineId) : null,
            salesSourceId: salesSourceId ? parseInt(salesSourceId) : null,
            negStartDate: negStartDate ? moment(negStartDate) : null,
            negEndDate: negEndDate ? moment(negEndDate) : null,
            createdStartDate: createdStartDate ? moment(createdStartDate) : null,
            createdEndDate: createdEndDate ? moment(createdEndDate) : null,
            queryString
        }, () => this.getDealList());
    };

    toggleModal = () => {
        this.setState(prevState => ({ modal: !prevState.modal }));
    }

    handleCombo = (combo, name) => {
        const { salesPipelineStageId } = this.state;

        if(name === 'pipelineId' && (!combo || !combo.value) && salesPipelineStageId !== null && salesPipelineStageId !== undefined){
            this.setState({ [name]: combo?.value, salesPipelineStageId: null });
        }else{
            this.setState({ [name]: combo?.value });
        }
    }

    handleBusDateChanges = ({ startDate, endDate }) => {
        this.setState({ busStartDate: startDate, busEndDate: endDate });
    }

    handleCreatedDateChanges = ({ startDate, endDate }) => {
        this.setState({ createdStartDate: startDate, createdEndDate: endDate });
    }

    handleNegDateChanges = ({ startDate, endDate }) => {
        this.setState({ negStartDate: startDate, negEndDate: endDate });
    }

    buildQueryString = () => {
        const { hotel, dealName, staffIds, salesPipelineStageId, busStartDate, busEndDate, salesStatus, pipelineId, salesSourceId, negStartDate, negEndDate, createdStartDate, createdEndDate } = this.state;
    
        const params = [];
    
        if (hotel && hotel.length > 0) params.push(`hotel=${hotel.join(';')}`);
        if (dealName) params.push(`dealName=${dealName}`);
        if (staffIds && staffIds.length > 0) params.push(`staffIds=${staffIds.join(';')}`);
        if (salesPipelineStageId !== null && salesPipelineStageId !== undefined) params.push(`salesPipelineStageId=${salesPipelineStageId}`);
        if (busStartDate && busEndDate) {
            params.push(`busStartDate=${moment(busStartDate).format('YYYY-MM-DD')}`);
            params.push(`busEndDate=${moment(busEndDate).format('YYYY-MM-DD')}`);
        }
        if (salesStatus && salesStatus.length > 0) params.push(`salesStatus=${salesStatus.join(';')}`);
        if (pipelineId !== null && pipelineId !== undefined) params.push(`pipelineId=${pipelineId}`);
        if (salesSourceId !== null && salesSourceId !== undefined) params.push(`salesSourceId=${salesSourceId}`);
        if (negStartDate && negEndDate) {
            params.push(`negStartDate=${moment(negStartDate).format('YYYY-MM-DD')}`);
            params.push(`negEndDate=${moment(negEndDate).format('YYYY-MM-DD')}`);
        }
        if (createdStartDate && createdEndDate) {
            params.push(`createdStartDate=${moment(createdStartDate).format('YYYY-MM-DD')}`);
            params.push(`createdEndDate=${moment(createdEndDate).format('YYYY-MM-DD')}`);
        }
        
        return params.length > 0 ? `${params.join('&')}` : '';
    }

    searchDeals = (e) => {
        e.preventDefault();
        let queryString = this.buildQueryString();

        this.setState({ block: true, queryString, pageIndex: 0 }, () => {
            this.getDealList();
            if (!this.props.fromExecutiveReport && !this.props.fromAssociateModal){
                this.props.history.push({ pathname: `/SalesProcess`, search: `?${queryString}` });
                window.location.hash = "#SalesDealList";
            }
        });
    }

    handleMultiCombo = (combo, name) => {
        this.setState({ [name]: combo ? combo?.map(cmb => cmb.value) : [] });
    }

    handlePageIndex = (p) => {
        const { pageIndex } = this.state;
        this.setState({ pageIndex: parseInt(pageIndex + p), block: true }, () => this.getDealList());
    }

    changePageSize = (evt) => {
        const { value } = evt.target;
        const { pageSize } = this.state;

        if (pageSize !== value) {
            this.setState({ block: true, pageSize: value }, () => this.getDealList());
        }
    }

    handleHotel = (name, combo) => {
        this.setState({ hotel: combo ? combo?.map(cmb => cmb.value) : [] });
    }

    handleStaff = (name, combo) => {
        this.setState({ staffIds: combo ? combo?.map(cmb => cmb.value) : [] });
    }

    toggleImportGroupResModal = () => {
        this.setState({ importGroupResModal: !this.state.importGroupResModal });
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value })
    }

    toggleFilter = () => {
        this.setState({ toggleFilter: !this.state.toggleFilter })
    }

    downloadData = () => {
        const { dealList, salesUsers } = this.props;

        let csv = `Deal,Client,Staff, Related Hotels, Negotiation Dates, Business Dates, Deal Status, Total Value, Rooms Value, Banquets Value, F&B Value, Equipment Value, Events Value, Spaces Value, Sources\n`;

        dealList.forEach(deal => {
            const hotelCurrency = global.hotelList.find(x => x.value == deal.hotelIds?.[0])?.currency ?? global.hotelCurrency;

            csv += `${deal.name ?? '-'}`
            if (deal.customer && deal.customer.name) {
                csv += `,${deal.customer.name.replaceAll(",", "")}`
            } else {
                csv += ',-';
            }
            if (deal.salesDealUser) {
                csv += ',';
                deal.salesDealUser.forEach((user, idx) => {
                    if (idx) csv += ' | '
                    const u = salesUsers?.find(({ value }) => value === user.userId);
                    csv += `${u?.firstName ?? ''} ${u.lastName ?? ''}`
                })
            }
            else {
                csv += ',-';
            }
            if (deal.hotelIds) {
                csv += ',';
                deal.hotelIds.forEach((id, idx) => {
                    if (idx) csv += ' | '
                    const hotel = global.hotelList?.find(x => x.value === id);
                    csv += `${hotel.label}`
                })
            } else {
                csv += ',-';
            }
            csv += `,${deal.negotiationStartDate ? moment(deal.negotiationStartDate).format('YYYY/MM/DD') : ''}` 
            csv += `${deal.negotiationEndDate ? ' - ' + moment(deal.negotiationEndDate).format('YYYY/MM/DD') : ''}` 
            csv += `,${deal.businessStartDate ? moment(deal.businessStartDate).format('YYYY/MM/DD') : ''}` 
            csv += `${deal.businessEndDate ? ' - ' + moment(deal.businessEndDate).format('YYYY/MM/DD') : ''}` 
            csv += `,${deal.status ?? '-'}`
            csv += `,${this.downLoadFormatAmountNumber(deal.totalValue, hotelCurrency)}`
            csv += `,${this.downLoadFormatAmountNumber(deal.roomsValue, hotelCurrency)}`
            csv += `,${this.downLoadFormatAmountNumber(deal.banquetsValue, hotelCurrency)}` 
            csv += `,${this.downLoadFormatAmountNumber(deal.fbValue, hotelCurrency)}` 
            csv += `,${this.downLoadFormatAmountNumber(deal.equipmentValue, hotelCurrency)}` 
            csv += `,${this.downLoadFormatAmountNumber(deal.eventsValue, hotelCurrency)}` 
            csv += `,${this.downLoadFormatAmountNumber(deal.spacesValue, hotelCurrency)}`
            csv += `,${deal.salesSourceName ?? '-'}`
            csv += '\n';
        });

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `DeaList_${moment().format("DD-MM-yyyy")}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    downLoadFormatAmountNumber = (value, currency) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value).replaceAll(",", "");
    };

    countFilters = (e) => {
        if (e) e.preventDefault();
        const { pipelineId, busStartDate, busEndDate, staffIds, negStartDate, negEndDate, salesSourceId, hotel, salesStatus, selectedStageTypeId, createdStartDate, createdEndDate, dealName, salesPipelineStageId } = this.state;
        let count = 0;
        
        if (pipelineId) count++;
        if (busStartDate || busEndDate) count++;
        if (staffIds && staffIds.length > 0) count++;
        if (negStartDate || negEndDate) count++;
        if (createdStartDate || createdEndDate) count++;
        if (dealName) count++;
        if (salesPipelineStageId) count++;
        if (salesSourceId) count++;
        if (hotel && hotel.length > 0) count++;
        if (salesStatus && salesStatus.length > 0) count++;
        if (selectedStageTypeId) count++;

        return count;
    }


    render() {
        const { block, error, modal, pipelineId, busStartDate, busEndDate, staffIds, negStartDate, negEndDate, salesSourceId, pageSize, pageIndex,
            importDealPermission, importGroupResModal, salesStatus, createdStartDate, createdEndDate, dealName, hotel, salesPipelineStageId } = this.state;
        const { icon, salesUsers, salesPipelines, salesSources, intl, selectDealDetail, dealList, salesSourcesTypes, isChild, toggleTab, isFromMonthly, selectedHotel, fromAssociateModal, salesPipelinesStages, currentUser, hotelList, totalDeals } = this.props;
        
        return (
            <div className="border-0 h-100 pb-2">
                {importGroupResModal ?
                    <CreateDealByImportGroupRes
                        toggleModal={this.toggleImportGroupResModal}
                        modal={true}
                        block={block}
                        hotels={global.hotelList}
                        salesPipelines={salesPipelines}
                        selectedPipeline={salesPipelines?.length === 1 ? salesPipelines[0] : null}
                        salesSources={salesSources}
                        history={this.props.history}
                    />
                    : ''}
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    {modal ?
                        <SalesDealModal
                            modal={true}
                            salesUsers={salesUsers}
                            toggleModal={this.toggleModal}
                            salesStages={salesPipelines.flatMap(({stages}) => stages)}
                            salesPipelines={salesPipelines}
                            editableSalesPipeline={true}
                            salesSources={salesSources}
                            getDeals={this.getDealList}
                            currentUser={currentUser}
                            hotelList={hotelList}
                        />
                    :''}
                    <div className='mb-4'>
                        <Row className='align-items-center'>
                            <Col className='d-flex align-items-center'>
                                {isChild ?
                                    <div onClick={_ => toggleTab(isFromMonthly ? "SalesProcessExecutiveReportMonthly" : "SalesProcessExecutiveReportYearly", selectedHotel)} className='cursor-pointer mr-3'>
                                        <i className='fas fa-chevron-left'/>
                                    </div>
                                :''}
                                {fromAssociateModal ?
                                    <h6 className='m-0'>
                                        <i className={`${icon} mr-2`} />
                                        <FormattedMessage id="SalesProcess.SalesDealList" />
                                    </h6>
                                :
                                    <h4 className='m-0'>
                                        <i className={`${icon} mr-2`} />
                                        <FormattedMessage id="SalesProcess.SalesDealList" />
                                    </h4>
                                }
                            </Col>
                            <Col className='text-right'>
                                <CommonHelper help={this.props.renderActivityHelperMsg(<FormattedMessage id="SalesProcess.DealListHelp" />)} id={'DealsListHelp'} />
                            </Col>
                        </Row>
                    </div>
                    <Row className="px-1">
                        <Col sm={2}>
                            <div className='title-sm'>
                                <i className={`icon-icon-hotel mr-2`} />
                                <FormattedMessage id="SalesProcess.Hotel" />
                            </div>
                            <div>
                                <SalesProcessHotelCombo
                                    isMulti={true}
                                    isClearable={true}
                                    name='hotelIds'
                                    placeholder={intl.formatMessage({ id: "SalesProcess.Hotel" })}
                                    value={hotel}
                                    onChangeFunc={this.handleHotel}
                                />
                            </div>
                        </Col>
                        <Col sm={3}>
                            <div className='title-sm'>
                                <i className="fas fa-briefcase mr-2" />
                                <FormattedMessage id="SalesProcess.DealName" />
                            </div>
                            <div>
                                <Input
                                    type="text"
                                    name="dealName"
                                    placeholder={this.props.intl.formatMessage({ id: "SalesProcess.DealName" })}
                                    onChange={(e) => this.handleChange(e)}
                                    value={dealName || ''}
                                    maxLength="500"
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className='title-sm'>
                                <i className={`fas fa-users mr-2`} />
                                <FormattedMessage id="SalesProcess.Staff" />
                            </div>
                            <div>
                                <SalesProcessUsersCombo
                                    isMulti={true}
                                    isClearable={true}
                                    name='staffIds'
                                    placeholder={intl.formatMessage({ id: "SalesProcess.Staff" })}
                                    value={staffIds}
                                    onChangeFunc={this.handleStaff}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className='title-sm'>
                                <i className="far fa-calendar-alt mr-2" />
                                <FormattedMessage id="SalesProcess.BusinessDates" />
                            </div>
                            <div>
                                <DateRangePicker
                                    startDate={busStartDate}
                                    startDateId="busStartDateId"
                                    endDate={busEndDate}
                                    endDateId="busEndDateId"
                                    isOutsideRange={_ => false}
                                    onDatesChange={this.handleBusDateChanges}
                                    focusedInput={this.state.focusedInput}
                                    showClearDates={true}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    small={true}
                                    showDefaultInputIcon={true}
                                    renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                                />
                            </div>
                        </Col>
                        <Col className="text-right mt-4 pt-1 pl-0" style={{ flex: ' 0 0 12%', maxWidth: '12%', minWidth: '14%' }}>
                            <span className="mr-2 pr-1">
                                <FilterButton totalFilters={this.countFilters()} butId={"buttonSubmit"}>
                                    <div className="fullWidth">
                                        <div>
                                            <div className='title-sm'>
                                                <i className={`fas fa-power-off mr-2`} />
                                                <FormattedMessage id="SalesProcess.DealStatus" />
                                            </div>
                                            <div>
                                                <CustomSelect
                                                    options={salesDealStatus()}
                                                    isClearable={true}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                    value={salesStatus ? salesDealStatus()?.filter(({ value }) => salesStatus.includes(value)) : ''}
                                                    onChange={e => this.handleMultiCombo(e, 'salesStatus')}
                                                    placeholder={intl.formatMessage({ id: "SalesProcess.Status" })}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className='title-sm mt-3'>
                                                <i className={`fas fa-sliders-h mr-2`} />
                                                <FormattedMessage id="SalesProcess.SalesPipelines" />
                                            </div>
                                            <div>
                                                <CustomSelect
                                                    options={salesPipelines}
                                                    isClearable={true}
                                                    isMulti={false}
                                                    value={salesPipelines?.filter(({ value }) => value === pipelineId) || ''}
                                                    isSearchable={true}
                                                    onChange={e => this.handleCombo(e, 'pipelineId')}
                                                    placeholder={intl.formatMessage({ id: "SalesProcess.SalesPipelines" })}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className='title-sm mt-3'>
                                                <i className="fas fa-route mr-2"></i>
                                                <FormattedMessage id="SalesProcess.SalesPipelineStage" />
                                            </div>
                                            <div>
                                                <CustomSelect
                                                    options={salesPipelinesStages ? salesPipelinesStages.filter((sps) => sps.pipelineId === pipelineId) : []}
                                                    isClearable={true}
                                                    isMulti={false}
                                                    value={salesPipelinesStages?.filter(({ value }) => value === salesPipelineStageId) || ''}
                                                    isSearchable={true}
                                                    onChange={e => this.handleCombo(e, 'salesPipelineStageId')}
                                                    placeholder={intl.formatMessage({ id: "SalesProcess.SalesPipelineStage" })}
                                                    isDisabled={pipelineId === null || pipelineId === undefined || !salesPipelinesStages || salesPipelinesStages.length === 0}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className='title-sm mt-3'>
                                                <i className={`fas fa-code-branch mr-2`} />
                                                <FormattedMessage id="SalesProcess.SalesSources" />
                                            </div>
                                            <div>
                                                <CustomSelect
                                                    options={salesSources}
                                                    isClearable={true}
                                                    isMulti={false}
                                                    value={salesSources?.filter(x => x.value == salesSourceId) || ''}
                                                    isSearchable={true}
                                                    onChange={e => this.handleCombo(e, 'salesSourceId')}
                                                    placeholder={intl.formatMessage({ id: "SalesProcess.SalesSources" })}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className='title-sm mt-3'>
                                                <i className="far fa-calendar-alt mr-2" />
                                                <FormattedMessage id="SalesProcess.NegotiationDates" />
                                            </div>
                                            <div>
                                                <DateRangePicker
                                                    startDate={negStartDate}
                                                    startDateId="negStartDateId"
                                                    endDate={negEndDate}
                                                    endDateId="negEndDateId"
                                                    isOutsideRange={_ => false}
                                                    onDatesChange={this.handleNegDateChanges}
                                                    focusedInput={this.state.focusedInput2}
                                                    showClearDates={true}
                                                    onFocusChange={focusedInput2 => this.setState({ focusedInput2: focusedInput2 })}
                                                    small={true}
                                                    showDefaultInputIcon={true}
                                                    renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className='title-sm mt-3'>
                                                <i className="far fa-calendar-alt mr-2" />
                                                <FormattedMessage id="SalesProcess.CreatedAtDates" />
                                            </div>
                                            <div>
                                                <DateRangePicker
                                                    startDate={createdStartDate}
                                                    startDateId="createdStartDateId"
                                                    endDate={createdEndDate}
                                                    endDateId="createdEndDateId"
                                                    isOutsideRange={_ => false}
                                                    onDatesChange={this.handleCreatedDateChanges}
                                                    focusedInput={this.state.focusedInput3}
                                                    showClearDates={true}
                                                    onFocusChange={focusedInput3 => this.setState({ focusedInput3 })}
                                                    small={true}
                                                    showDefaultInputIcon={true}
                                                    renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </FilterButton> 
                            </span>
                            {!fromAssociateModal ?
                                <>
                                    <Button className="btn btn-host btn-sm ml-2" onClick={this.downloadData}>
                                        <i className="fas fa-file-download" />
                                    </Button>
                                    {importDealPermission ?
                                        <Button className="btn-sm btn-host ml-2" onClick={this.toggleImportGroupResModal}>
                                            <i className="fas fa-upload"></i>
                                        </Button>
                                        : ''}
                                    <Button className="btn-sm btn-host ml-2" onClick={this.toggleModal}>
                                        <i className="fas fa-plus" />
                                    </Button>
                                </>
                                : ''}
                            <Button className="btn-sm btn-host ml-2" id="buttonSubmit" onClick={this.searchDeals}>
                                <i className="fas fa-search" />
                            </Button>
                        </Col>
                    </Row>

                    {dealList ?
                        <>
                            <div className="w-100 mt-4 chunkyVeryCoolScrollBar px-4" style={{ overflowX: 'auto', whiteSpace: 'nowrap', transform: 'rotateX(180deg)' }} >
                                <div className='mt-4' style={{ width: '2800px', transform: 'rotateX(180deg)', fontSize: '0.9em' }}>
                                    <Row className='d-flex align-items-center justify-content-between shadow py-3 mb-3 bg-white text-muted' style={{ borderRadius: '4px' }}>
                                        <Col className='col-1'>
                                            <FormattedMessage id={`SalesProcess.Deal`} />
                                        </Col>
                                        <Col className='col-1'>
                                            <FormattedMessage id={`SalesProcess.SalesPipeline`} />
                                        </Col>
                                        <Col className=''>
                                            <FormattedMessage id={`SalesProcess.Staff`} />
                                        </Col>
                                        <Col className='col-1'>
                                            <FormattedMessage id={`SalesProcess.RelatedHotels`} />
                                        </Col>
                                        <Col className='col-1'>
                                            <FormattedMessage id={`SalesProcess.NegotiationDates`} />
                                        </Col>
                                        <Col className='col-1'>
                                            <FormattedMessage id={`SalesProcess.BusinessDates`} />
                                        </Col>
                                        <Col>
                                            <FormattedMessage id={`SalesProcess.ActionsStatus`} />
                                        </Col>
                                        <Col>
                                            <FormattedMessage id={`SalesProcess.DealStatus`} />
                                        </Col>
                                        <Col>
                                            <FormattedMessage id={`SalesProcess.TotalValue`} />
                                        </Col>
                                        <Col>
                                            <FormattedMessage id={`SalesProcess.RoomsValue`} />
                                        </Col>
                                        <Col>
                                            <FormattedMessage id={`SalesProcess.BanquetsValue`} />
                                        </Col>
                                        <Col>
                                            <FormattedMessage id={`SalesProcess.FBValue`} />
                                        </Col>
                                        <Col>
                                            <FormattedMessage id={`SalesProcess.EquipmentValue`} />
                                        </Col>
                                        <Col>
                                            <FormattedMessage id={`SalesProcess.EventsValue`} />
                                        </Col>
                                        <Col>
                                            <FormattedMessage id={`SalesProcess.SpacesValue`} />
                                        </Col>
                                        <Col>
                                            <FormattedMessage id={`SalesProcess.SalesSource`} />
                                        </Col>
                                    </Row>
                                    <div className='mt-2' style={{ width: '2800px' }}>
                                        {dealList.map((deal, key) => {
                                            const pipeline = salesPipelines?.find(({ value }) => value === deal.salesPipelineId);

                                            const hotelCurrency = global.hotelList.find(x => x.value == deal.hotelIds?.[0])?.currency ?? global.hotelCurrency;
                                            return (
                                                <Row onMouseDown={(e) => {
                                                    if (e?.button !== 2) {
                                                        const isScrollClick = e?.button === 1;
                                                    
                                                        if (fromAssociateModal) {
                                                            if (!isScrollClick) {
                                                                selectDealDetail(deal, null);
                                                            }
                                                        } else {
                                                            selectDealDetail(deal.id, "SalesDealList", isScrollClick);
                                                        }
                                                    }
                                                  }} className='d-flex align-items-center justify-content-between shadow py-3 mb-2 bg-white cursor-pointer' key={key} style={{ borderRadius: '4px' }}>
                                                    <Col className='text-truncate col-1'>
                                                        <b id={`dealName-${key}`}>{deal.name}</b>
                                                        <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="right" target={`dealName-${key}`}>
                                                            <PopoverBody>
                                                                <div>
                                                                    <b>{deal.name}</b>
                                                                </div>
                                                                <div dangerouslySetInnerHTML={{ __html: deal.summary }} />
                                                            </PopoverBody>
                                                        </CustomToolTip>
                                                        {deal.customerId ?
                                                            <div style={{ fontSize: '0.8em' }} className='text-muted hover-underline text-truncate'>
                                                                <Link to={{ pathname: "/ProfileDetails/" + deal.customer.id }}>
                                                                    {deal.customer.name}
                                                                </Link>
                                                            </div>
                                                            : ''}
                                                    </Col>

                                                    <Col className='text-truncate col-1'>
                                                        {pipeline ?
                                                            <>
                                                                <span id={`pipeline-${key}`}>{pipeline.label}</span>
                                                                <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="right" target={`pipeline-${key}`}>
                                                                    <PopoverBody>
                                                                        <div>
                                                                            <b>{pipeline.label}</b>
                                                                        </div>
                                                                        <div className='my-1 px-2 h-100 d-flex w-100' style={{ minWidth: '80px' }}>
                                                                            {pipeline?.stages ?
                                                                                pipeline?.stages.map((stage, key) => {
                                                                                    const selectedStage = stage.value === deal.salesPipelineStageId;
                                                                                    return (
                                                                                        <div key={key} className='h-100' style={{ width: '100%' }}>
                                                                                            <div className='text-center pipeline smallPipeline'>
                                                                                                <div className={`stagearrow top small smallPipeline noShadow ${selectedStage ? 'selectedStage' : ''}`}></div>
                                                                                                <div className={`stagearrow bottom small noShadow ${selectedStage ? 'selectedStage' : ''}`}></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                                : ''}
                                                                        </div>
                                                                    </PopoverBody>
                                                                </CustomToolTip>
                                                            </>
                                                            : '-'}
                                                    </Col>

                                                    <Col className="text-truncate">
                                                        {deal.salesDealUser ?
                                                            <div style={{ maxHeight: '22px' }} className='overflow-auto veryCoolScrollBar'>
                                                                {deal.salesDealUser.map((user, k) => {
                                                                    const u = salesUsers?.find(({ value }) => value === user.userId);
                                                                    return (
                                                                        u ?
                                                                            <div key={k}>
                                                                                <div className={k ? 'mt-1 text-truncate' : 'text-truncate'}>
                                                                                    {`${u.firstName} ${u.lastName}`}
                                                                                </div>
                                                                            </div>
                                                                            : ''
                                                                    )
                                                                })}
                                                            </div>
                                                            : '-'}
                                                    </Col>

                                                    <Col className="col-1 text-truncate">
                                                        {deal.hotelIds ?
                                                            <div style={{ maxHeight: '22px' }} className='overflow-auto veryCoolScrollBar'>
                                                                {deal.hotelIds.map((id, key) => {
                                                                    const hotel = global.hotelList?.find(x => x.value === id);
                                                                    return (
                                                                        <div key={key} className={key ? 'mt-1 text-truncate' : 'text-truncate'}>
                                                                            {hotel.label}
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                            : '-'}
                                                    </Col>

                                                    <Col className='text-truncate col-1' title={`${moment(deal.negotiationStartDate).format('YYYY/MM/DD')}${deal.negotiationEndDate ? ` - ${moment(deal.negotiationEndDate).format('YYYY/MM/DD')}` : ''}`}>
                                                        {deal.negotiationStartDate ?
                                                            <span>
                                                                {moment(deal.negotiationStartDate).format('YYYY/MM/DD')}
                                                            </span>
                                                            : '-'}

                                                        {deal.negotiationEndDate ?
                                                            <>
                                                                <span className='mx-1'>-</span>
                                                                <span>
                                                                    {moment(deal.negotiationEndDate).format('YYYY/MM/DD')}
                                                                </span>
                                                            </>
                                                            : ''}
                                                    </Col>

                                                    <Col className='text-truncate col-1' title={`${moment(deal.businessStartDate).format('YYYY/MM/DD')}${deal.businessEndDate ? ` - ${moment(deal.businessEndDate).format('YYYY/MM/DD')}` : ''}`}>
                                                        {deal.businessStartDate ?
                                                            <span>
                                                                {moment(deal.businessStartDate).format('YYYY/MM/DD')}
                                                            </span>
                                                            : '-'}

                                                        {deal.businessEndDate ?
                                                            <>
                                                                <span className='mx-1'>-</span>
                                                                <span>
                                                                    {moment(deal.businessEndDate).format('YYYY/MM/DD')}
                                                                </span>
                                                            </>
                                                            : ''}
                                                    </Col>

                                                    <Col>
                                                        {deal.status !== "Finished" && deal.status !== "Cancelled" ?
                                                            <SalesActionsPopOver
                                                                id={`SalesAction-${key}`}
                                                                salesUsers={salesUsers}
                                                                placement={'left'}
                                                                deal={deal}
                                                                getDeals={this.getDealList}
                                                                selectedPipeline={deal.salesPipelineId}
                                                                small={true}
                                                            />
                                                            : ''}
                                                    </Col>

                                                    <Col>
                                                        <DealStatusBadge status={deal.status} id={`dealStatus-${key}`} />
                                                        {deal.statusReason ?
                                                            <CoolTooltip placement='left' target={`dealStatus-${key}`}>
                                                                {deal.statusReason}
                                                            </CoolTooltip>
                                                            : ''}
                                                    </Col>

                                                    <Col>
                                                        <div className='text-truncate'>
                                                            {deal.totalValue ?
                                                                <FormatAmountNumber value={deal.totalValue} currency={hotelCurrency} />
                                                                : '-'}
                                                        </div>
                                                    </Col>

                                                    <Col>
                                                        <div className='text-truncate'>
                                                            {deal.roomsValue ?
                                                                <FormatAmountNumber value={deal.roomsValue} currency={hotelCurrency} />
                                                                : '-'}
                                                        </div>
                                                    </Col>

                                                    <Col>
                                                        <div className='text-truncate'>
                                                            {deal.banquetsValue ?
                                                                <FormatAmountNumber value={deal.banquetsValue} currency={hotelCurrency} />
                                                                : '-'}
                                                        </div>
                                                    </Col>

                                                    <Col>
                                                        <div className='text-truncate'>
                                                            {deal.fbValue ?
                                                                <FormatAmountNumber value={deal.fbValue} currency={hotelCurrency} />
                                                                : '-'}
                                                        </div>
                                                    </Col>

                                                    <Col>
                                                        <div className='text-truncate'>
                                                            {deal.equipmentValue ?
                                                                <FormatAmountNumber value={deal.equipmentValue} currency={hotelCurrency} />
                                                                : '-'}
                                                        </div>
                                                    </Col>

                                                    <Col>
                                                        <div className='text-truncate'>
                                                            {deal.eventsValue ?
                                                                <FormatAmountNumber value={deal.eventsValue} currency={hotelCurrency} />
                                                                : '-'}
                                                        </div>
                                                    </Col>

                                                    <Col>
                                                        <div className='text-truncate'>
                                                            {deal.spacesValue ?
                                                                <FormatAmountNumber value={deal.spacesValue} currency={hotelCurrency} />
                                                                : '-'}
                                                        </div>
                                                    </Col>

                                                    <Col className='text-truncate'>
                                                        <span>{deal.salesSourceName}</span>
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                        )}
                                    </div>
                                </div>
                            </div>
                            <Row className="mt-1 mb-3">
                                <Col>
                                    <ButtonGroup onClick={this.changePageSize}>
                                        <Button className={(pageSize == 10 ? "bg-host text-white" : "text-secondary") + " page-link"} value={10}> 10 </Button>
                                        <Button className={(pageSize == 25 ? "bg-host text-white" : "text-secondary") + " page-link"} value={25}> 25 </Button>
                                        <Button className={(pageSize == 30 ? "bg-host text-white" : "text-secondary") + " page-link"} value={30}> 30 </Button>
                                        <Button className={(pageSize == 50 ? "bg-host text-white" : "text-secondary") + " page-link"} value={50}> 50 </Button>
                                    </ButtonGroup>
                                    <span className="text-muted ml-2" style={{fontSize: '0.8em'}}>
                                        <FormattedMessage id="generic.totalValuesMessage" values={{
                                            0: parseInt(pageIndex * pageSize + 1),
                                            1: totalDeals < parseInt((pageIndex + 1) * pageSize) ? totalDeals : parseInt((pageIndex + 1) * pageSize), 
                                            2: totalDeals
                                        }} />
                                    </span>
                                </Col>
                                <Col className="text-right">
                                    <span>
                                        <Button className="btn btn-host btn-sm mr-2" onClick={_ => this.handlePageIndex(-1)} disabled={pageIndex === 0}>
                                            <i className="fas fa-angle-left" />
                                        </Button>
                                        <span className="mr-2">
                                            <FormattedMessage id="SearchProfile.Page" values={{ page: parseInt(pageIndex + 1) }} />
                                        </span>
                                        <Button className="btn btn-host btn-sm" onClick={_ => this.handlePageIndex(1)} disabled={totalDeals <= pageSize * (pageIndex +1)}>
                                            <i className="fas fa-angle-right" />
                                        </Button>
                                    </span>
                                </Col>
                            </Row>
                        </>
                    :''}
                </BlockUi>
            </div>
        )
    }
}

export default injectIntl(SalesDealList);

