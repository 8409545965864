import React, { Component } from 'react';
import { Row, Col, Button, Badge, Input } from 'reactstrap';
import { getAPI } from '../../../Base/API';
import { StyledCard } from '../../../Base/CommonUIComponents';
import { CustomTable } from '../../../Base/CustomTable';
import { FormattedMessage } from 'react-intl';
import CustomSelect from '../../../Base/CustomSelect';

export class ApiGatewayUserManagement extends Component {
    static displayName = ApiGatewayUserManagement.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            keyList: [],
            note: null,
            email: null,
            firstname: null,
            state: null,
            skip: 0,
            hotels: [],
            pageIndex: 0,
            pageSize: 10,
            totalItems: 0
        }
        this.getUsers = this.getUsers.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.getHotels = this.getHotels.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getUsers();
        this.getHotels();
    }

    doSearch() {
        this.setState({ skip: 0 }, this.getUsers);
    }

    getUsers() {
        const { note, email, firstname, state, skip, pageSize } = this.state;

        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.value) {
                    this.setState({ userList: data.value.slice(0, pageSize), totalItems: data.count });
                }
                else {
                    this.setState({ userList: [], totalItems: 0 });
                }
            }
            this.setState({ block: false });
        }, `/api/User/APIGateway/v1/Users?note=${note !== null ? note : ''}&email=${email !== null ? email : ''}&firstname=${firstname !== null ? firstname : ''}&state=${state !== null ? state : ''}&skip=${skip}`);
    }

    getHotels = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.response) {
                    const hotels = data.response.map(h => {
                        const hotel = {
                            value: `${h.hotelId}`,
                            label: `${h.name2}`
                        };
                        return hotel;
                    });
                    this.setState({ hotels: hotels });
                }
            }
            this.setState({ block: false });
        }, '/api/gms/Hotel/v1/hotel/list');
    }

    handleChange(evt) {
        if (evt.target) {
            const { name, value } = evt.target;
            this.setState({ [name]: value });
        }
    }

    setCombo(name, combo) {
        this.setState({ [name]: combo && combo.value });
    }

    handleTableChange = (_, { sizePerPage, page }) => {
        const { pageSize } = this.state;
        const pages = page > 0 ? page - 1 : 0;
        this.setState({ pageSize: sizePerPage, skip: pages * pageSize, pageIndex: pages }, this.getUsers);
    }

    render() {
        const { userList, hotels, note, email, firstname, pageIndex, pageSize, totalItems } = this.state;

        const columns = [
            {
                dataField: 'firstName',
                text: <FormattedMessage id="ApiGatewayUserManagement.FirstName" />,
                sort: true,
                formatter: (cell, row) => <div>{row.properties.firstName}</div>
            },
            {
                dataField: 'lastName',
                text: <FormattedMessage id="ApiGatewayUserManagement.LastName" />,
                sort: true,
                formatter: (cell, row) => <div>{row.properties.lastName}</div>
            },
            {
                dataField: 'email',
                text: <FormattedMessage id="ApiGatewayUserManagement.Email" />,
                sort: true,
                formatter: (cell, row) => row.properties.email
            },
            {
                dataField: 'registrationDate',
                text: <FormattedMessage id="ApiGatewayUserManagement.RegistrationDate" />,
                sort: true,
                formatter: (cell, row) => row.properties.registrationDate
            },
            {
                dataField: 'note',
                text: "Hotel",
                sort: true,
                formatter: (cell, row) => row.properties.note
            },
            {
                dataField: 'state',
                text: <FormattedMessage id="ApiGatewayUserManagement.State" />,
                sort: true,
                formatter: (cell, row) => <Badge className={row.properties.state === "active" ? 'btn-host p-1' : 'p-1'}>{row.properties.state}</Badge>
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.props.history.push('/ApiGatewayUserDetails/' + row.name)
            }
        };

        return (
            <StyledCard icon={'fas fa-user-cog'} title={'ApiGatewayUserManagement.ApiGatewayUsers'} error={this.state.error} block={this.state.block}>
                <h4 className="mt-4">
                    <FormattedMessage id="ApiGatewayUserDetails.Subscriptions" />
                </h4>
                <Row className="mt-4 mb-2">
                    <Col>
                        <CustomSelect options={hotels} value={hotels.find(opt => opt.value === note)} onChange={this.setCombo.bind(this, 'note')} isClearable isSearchable placeholder="Hotel" />
                    </Col>
                    <Col>
                        <FormattedMessage id="ApiGatewayUserManagement.Email">{
                            placeholder => <Input type="text" id="email" name="email" value={email ? email : ''} placeholder={placeholder} onChange={this.handleChange} />
                        }
                        </FormattedMessage>
                    </Col>
                    <Col>
                        <FormattedMessage id="ApiGatewayUserManagement.FirstName">{
                            placeholder => <Input type="text" id="firstname" name="firstname" value={firstname ? firstname : ''} placeholder={placeholder} onChange={this.handleChange} />
                        }
                        </FormattedMessage>
                    </Col>
                    <Col className="text-right">
                        <Button className="btn-sm btn-host mr-1" onClick={this.doSearch}>
                            <i className="icon-icon-search" />
                        </Button>
                        <Button onClick={() => this.props.history.push('/ApiGatewayUserDetails/')} className="btn-sm btn-host">
                            <i className="fas fa-plus" />
                        </Button>
                    </Col>
                </Row>
                <CustomTable
                    data={userList ? userList : []}
                    columns={columns}
                    shadow={false}
                    exportCSV={false}
                    showTotal={true}
                    remote={true}
                    search={false}
                    selectRow={selectRow}
                    hideSizePerPage={false}
                    page={pageIndex + 1}
                    sizePerPage={pageSize}
                    totalSize={totalItems}
                    onTableChange={this.handleTableChange}
                />
            </StyledCard>
        );
    }
}

export default (ApiGatewayUserManagement)