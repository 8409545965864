import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import { Button, Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, UncontrolledDropdown } from 'reactstrap';
import { CheckAuthorization } from '../../Base/Authorization';

export default class TemplatePreview extends Component {

    constructor (props) {
        super(props);
        this.state = {
            editTemplate: false,
            templateName: ''
        };
    }

    componentDidMount(){
        this.setState({ templateName: this.props.templateName });
    }

    editTemplate = () => {
        const { id, history, templateGroupId } = this.props;

        history.push({ pathname: `/EmailCreation/${id}/false/${templateGroupId}` });
    }

    editTemplateName = () => {
        this.setState(prev => ({ editTemplate: !prev.editTemplate }));
    }

    handleNameChange = (e) => {
        this.setState({ templateName: e?.target?.value });
    }

    render() {
        const { id, thumbnail, isTemplate, countries, openDeleteTemplateModal, templateGroupId, type, toggleModal, saveTemplateName, template, onDragStart, onDragEnd, intl } = this.props;
        const { templateName, editTemplate } = this.state;
        
        return (
            <>
                <Card className="border-0 p-3 h-100 shadow d-flex justify-content-between" draggable onDragStart={(event) => onDragStart(event, template)} onDragEnd={onDragEnd}>
                    {!isTemplate ?
                        <div style={{ position: 'absolute', maxWidth: '200px' }} className="p-2">
                            {countries?.map((country, k) =>
                                country ? <span key={k} className={`flag-icon flag-icon-${country.toLowerCase()} px-2 mr-2`}/> : ''
                            )}
                        </div>
                    :''}
                    <div style={{ height: '350px', overflow:'hidden', cursor: 'pointer' }} onClick={this.editTemplate}>
                        {thumbnail ? <img draggable={false} style={{ width: '100%' }} src={`data:image/png;base64, ${thumbnail}`}/>:''}
                    </div>
                    <Row className='pt-3' style={{ display:'flex', alignItems:'center', justifyContent:'center' }}>
                        <Col style={{ overflow: 'hidden', height: '100%', display: 'flex', alignItems: 'center' }}>
                            {editTemplate ?
                                <Input
                                    type='text'
                                    value={templateName}
                                    autoFocus
                                    maxLength={'50'}
                                    required
                                    onChange={this.handleNameChange}
                                />
                            :
                                <h5 title={templateName} style={{ width: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} className="m-0">{templateName}</h5>
                            }
                        </Col>
                        <Col className={`text-right col-2 pl-0`}>
                                <>
                                    {editTemplate ?
                                        <Button className="btn btn-sm btn-host" onClick={() => saveTemplateName(id, templateGroupId, type, templateName, this.editTemplateName)}>
                                            <i className="fas fa-save"/>
                                        </Button>
                                    :
                                    <UncontrolledDropdown className='ml-2'>
                                        <DropdownToggle style={{ background: 'none', border: 'none', color: 'unset', padding: 'unset', width: '10px' }}>
                                            <i className="fas fa-ellipsis-v"/>
                                        </DropdownToggle>
                                        {template.hotelGroupId !== "00000000-0000-0000-0000-000000000000" || CheckAuthorization("admin:view", null) ?
                                            <DropdownMenu>
                                                {isTemplate ?
                                                    <DropdownItem onClick={e => toggleModal(e, id, id)}>
                                                        <i className="fas fa-envelope mr-2"/>
                                                        <FormattedMessage id="stripo.createBasedEmail"/>
                                                    </DropdownItem>
                                                :''}
                                                <DropdownItem onClick={this.editTemplateName}>
                                                    <i className="fas fa-edit mr-2"/>
                                                    <FormattedMessage id="stripo.edit"/>
                                                </DropdownItem>
                                                <DropdownItem onClick={e => toggleModal(e, id, false)}>
                                                    <i className="fas fa-copy mr-2"/>
                                                    <FormattedMessage id="stripo.copy"/>
                                                </DropdownItem>
                                                <DropdownItem onClick={() => openDeleteTemplateModal(id)}>
                                                    <div style={{ color: '#A80C19' }}>
                                                        <i className="mr-2 far fa-trash-alt"/>
                                                        <FormattedMessage id="stripo.delete"/>
                                                    </div>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        :
                                            <DropdownMenu>
                                                <DropdownItem onClick={this.editTemplateName}>
                                                    <i className="fas fa-edit"/>
                                                    <FormattedMessage id="stripo.edit"/>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        }
                                    </UncontrolledDropdown>
                                    }
                                </>
                        </Col>
                    </Row>
                </Card>
            </>
        )
    }
}