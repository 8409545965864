import { Component } from 'react';
import { HandleUIError } from '../../Utils/EventGA';

export default class TooltipErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        HandleUIError(error, errorInfo?.componentStack);
    }

    render() {
        return this.state.hasError ? "" : this.props.children;
    }
}