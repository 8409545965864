import React, { Component } from 'react';
import { BlankCard, CommonHelper, StyledModal } from '../../Base/CommonUIComponents';
import { Button, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import InitiativeModalStep1 from './InitiativeModalStep1';
import InitiativeModalStep2 from './InitiativeModalStep2';
import InitiativeModalStep3 from './InitiativeModalStep3';
import InitiativeModalStep4 from './InitiativeModalStep4';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import moment from 'moment-timezone';
import InitiativeModalStep5 from './InitiativeModalStep5';
import { postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';

class SalesInitiativeModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            steps: [
                { step: 1, active: true, name: 'Initiative' },
                { step: 2, active: false, name: 'Location' },
                { step: 3, active: false, name: 'Client' },
                { step: 4, active: false, name: 'Staff' },
                { step: 5, active: false, name: 'Report' }
            ],
            initiative: this.props.initiative || {},
            roles: []
        };
    }
    
    componentDidMount() {
        const { steps } = this.state;
        const { specificEditStep } = this.props;

        if (steps && specificEditStep) {
            const updatedSteps = steps.map(el => ({
                ...el,
                active: el.step === specificEditStep
            }));
    
            this.setState({ steps: updatedSteps });
        }
    }
    
    changeStep = (value) => {
        const { steps } = this.state;

        steps.forEach(el => {
            el.active = el.step === value;
        })

        this.setState({ steps });
    }

    isDisabled = () => {
        const { initiative, steps } = this.state;
        const currentStep = steps.find(el => el.active).step;

        if(!initiative)
            return true;

        if(currentStep === 1){
            return !initiative.name || !initiative.status || !initiative.type || !initiative.actionType || !initiative.hotelIds?.length || !initiative.summary || !(initiative.status !== "Cancelled" || initiative.cancelReasonId && initiative.statusReason);
        }
        else if(currentStep === 2){
            return false; // no required fields;
        }
        else if(currentStep === 3){
            return false; // no required fields;
        }
        else if(currentStep === 4){
            return !initiative.salesInitiativeUsers || !initiative.salesInitiativeUsers?.every(el => el.role && el.userId);
        }
        else if(currentStep === 5){
            return !initiative.name || !initiative.status || !initiative.type || !initiative.actionType || !initiative.hotelIds?.length || !initiative.summary || !initiative.salesInitiativeUsers || !initiative.salesInitiativeUsers?.every(el => el.role && el.userId);
        }

        return true;
    }

    saveInitiative = (e) => {
        this.setState({ block: true }, () => {
            const { updateInitiative, toggleModal, searchInitiatives } = this.props;
            const { initiative } = this.state;
            
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    if (updateInitiative) {
                        updateInitiative(data, true);
                    }
                    else if(searchInitiatives){
                        searchInitiatives(e);
                    }

                    toggleModal();

                    handleNotification(data, <FormattedMessage id="SalesProcess.InitiativeSaved" />, <FormattedMessage id="generic.success" />);

                    this.setState({ block: false });
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/initiatives`, initiative);
        });
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        if(name === 'cancelReasonType'){
            this.setState({
                initiative: {
                    ...this.state.initiative,
                    cancelReason: value ? value.label : null,
                    cancelReasonId: value ? value.value : null
                }
            })
            return;
        }

        this.setState({
            initiative: {
                ...this.state.initiative,
                [name]: value
            }
        })
    }

    handleSelect = (name, combo) => {
        let { initiative } = this.state;
        let targetValue = null;

        if (Array.isArray(combo)) {
            targetValue = combo.map(el => el.value);
        }
        else {
            targetValue = combo ? combo.value : null;
        }

        initiative = {...initiative, [name]: targetValue};

        this.setState({ initiative });
    }

    handleMarkets = (name, combo) => {
        let { initiative } = this.state;
        
        initiative = { ...initiative, [name]: combo ? combo.map(el => ({ id: el.value })) : null };

        this.setState({ initiative });
    }

    onEditorStateChange = (editorState, name, name2) => {
        const contentState = editorState.getCurrentContent();
        const markup = draftToHtml(convertToRaw(contentState));
        this.setState({
            initiative: {
                ...this.state.initiative,
                [name]: editorState,
                [name2]: markup
            }
        });
    }

    handleListElement = (listName, index, propName, value, isToRemove) => {
        if (index !== -1) {
            const { initiative } = this.state;

            if (isToRemove) {
                initiative[listName].splice(index, 1);
            }
            else{
                if(listName === 'salesInitiativeUsers'){
                    if(initiative?.[listName]?.some(x => x.userId === value)){
                        handleNotification({ warnings: [{ message: <FormattedMessage id="SalesDeal.UserAlreadyAssociated" /> }] });
                        return;
                    }
                }
                initiative[listName][index][propName] = value;
            }

            this.setState({ initiative });
        }        
    }

    handleDate = (name, date) => {
        this.setState({
            initiative: {
                ...this.state.initiative,
                [name]: date ? moment(date).format("YYYY-MM-DD") : null
            }
        })
    }

    handleProfilesList = (name, list) => {
        this.setState({
            initiative: {
                ...this.state.initiative,
                [name]: list
            }
        })
    }

    handleLoc = (city, cityOptions) => {
        const { initiative } = this.state;
        const { hotelList } = this.props;

        if(!cityOptions) return;

        const data = cityOptions.find(e => e.city === city);

        let updatedHotelList = initiative?.hotelIds ? [...initiative.hotelIds] : [];

        if (data) {
            if (data.isSelected) {
                const hotelsToRemove = hotelList?.filter(hotel => hotel.city === city)?.map(x => x.value) ?? [];
                updatedHotelList = updatedHotelList.filter(hotel => !hotelsToRemove.includes(hotel));
            }
            else {
                const hotelsToAdd = hotelList?.filter(hotel => hotel.city == city)?.map(x => x.value) ?? [];
                updatedHotelList = updatedHotelList.concat(hotelsToAdd);
            }

        }
        else {
            updatedHotelList = [];
        }
        data.isSelected = !data.isSelected;

        this.setState(() => ({
            initiative: {
                ...initiative,
                hotelIds: updatedHotelList 
            }
        }));
    }

    handleSelectAll = (selectAll) => {
        const { initiative } = this.state;
        const { hotelList } = this.props;

        let updatedHotelList = initiative?.hotelIds ? [...initiative.hotelIds] : [];

        if (selectAll) {
            updatedHotelList = hotelList?.map(x => x.value) ?? [];
        }else{
            updatedHotelList = [];
        }

        this.setState(() => ({
            initiative: {
                ...initiative,
                hotelIds: updatedHotelList
            }
        }));
    }

    render() {
        const { steps, block, error, initiative, roles } = this.state;
        const { modal, toggleModal, hotelList, isToEdit } = this.props;
        const currentStep = steps.find(el => el.active);

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="xl">
                <BlankCard block={block} error={error}>
                    <Row className="mb-4">
                        <Col className="col-1 text-left d-flex align-items-center">
                            {steps[0].active === false ?
                                <Button className="btn- btn-host btn-small mr-4" style={{ 'flex': '1' }} onClick={_ => this.changeStep(currentStep.step - 1)}>
                                    <i className="fas fa-angle-left" />
                                </Button>
                            : ''}
                        </Col>

                        <Col className="py-1">
                            <ul className="steps">
                                {steps.map((step, key) =>
                                    <li className={'step ' + (step.active ? ' step-active' : '')} style={{ cursor: 'pointer' }} key={key}>
                                        <div className="step-content">
                                            <span className="step-text small">
                                                <FormattedMessage id={`SalesDeal.Step${step.step}`} />
                                            </span>
                                            <span className="step-circle my-1" onClick={() => !this.isDisabled() ? this.changeStep(step.step) : undefined} />
                                            <span className="step-text small">
                                                <FormattedMessage id={`SalesDeal.${step.name}`} />
                                            </span>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </Col>

                        <Col className="col-1 text-right">
                            <CommonHelper help={<FormattedMessage id={"SalesDeal.InitiativeModalHelp"} />} id={'InitiativeModalHelp'} />
                            {isToEdit || steps[4].active ?
                                <Button className="btn- btn-host btn-small ml-4" style={{ 'flex': '1' }} onClick={(e) => this.saveInitiative(e)} disabled={this.isDisabled()}>
                                    <i className="fas fa-save" />
                                </Button>
                                :
                                <Button className="btn- btn-host btn-small ml-4" style={{ 'flex': '1' }} onClick={_ => this.changeStep(currentStep.step + 1)} disabled={this.isDisabled()}>
                                    <i className="fas fa-angle-right" />
                                </Button>
                            }
                        </Col>
                    </Row>
                    
                    {steps[0].active ?
                        <InitiativeModalStep1
                            initiative={initiative}
                            handleChange={this.handleChange}
                            handleSelect={this.handleSelect}
                            onEditorStateChange={this.onEditorStateChange}
                            hotelList={hotelList}
                            handleLoc={this.handleLoc}
                            handleSelectAll={this.handleSelectAll}
                        />
                    :''}
                    {steps[1].active ?
                        <InitiativeModalStep2
                            initiative={initiative}
                            handleChange={this.handleChange}
                            handleDate={this.handleDate}
                            handleMarkets={this.handleMarkets}
                        />
                    :''}
                    {steps[2].active ?
                        <InitiativeModalStep3
                            initiative={initiative}
                            handleListElement={(index, propName, value, isToRemove) =>  this.handleListElement('salesInitiativeProfile', index, propName, value, isToRemove)}
                            roles={roles}
                            handleProfilesList={a => this.handleProfilesList('salesInitiativeProfile', a)}
                        />
                    :''}
                    {steps[3].active ?
                        <InitiativeModalStep4
                            initiative={initiative}
                            handleListElement={(index, propName, value, isToRemove) => this.handleListElement('salesInitiativeUsers', index, propName, value, isToRemove)}
                            handleProfilesList={a => this.handleProfilesList('salesInitiativeUsers', a)}
                        />
                    :''}
                    {steps[4].active ?
                        <InitiativeModalStep5
                            initiative={initiative}
                            onEditorStateChange={this.onEditorStateChange}
                        />
                    :''}
                </BlankCard>
            </StyledModal>
        );
    }
}

export default SalesInitiativeModal;
