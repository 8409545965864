import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Button, Col, Row } from 'reactstrap';
import { getAPI } from '../../Base/API';
import { StyledCard } from '../../Base/CommonUIComponents';
import { handleNotification } from '../../Base/Notification';
import { Pagination } from '../../Base/PaginationComponents';
import TemplatePreview from './TemplatePreview';
import WhatsAppTemplates from './WhatsAppTemplates';
import { getLanguagesCodesOptions, getWhastAppStatus } from '../../Base/ReferenceDataFunctions';
import CustomSelect from '../../Base/CustomSelect';

class TemplateManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            templates: [],
            nameSearch: null,
            stateSearch: null,
            catSearch: null,
            selectedTemplateId: null,
            hasNext: false,
            hasBefore: false,
            previousCursor: null,
            nextCursor: null,
            currLang: null,
            currentPage: 1,
            status: null,
            language: null
        };
    }

    componentDidMount() {
        this.getTemplates();
    }

    getTemplates = (cursor, isPrevious) => {
        const { status, language } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    this.setState({ block: false }, () => handleNotification(data));
                }
                else if (data.response) {
                    const templates = data.response[0].data.map((template) => {
                        const newTemplate = {
                            templates: [template],
                            name: template.name
                        };
                        return newTemplate;
                    });

                    const hasNext = data.response[0]?.paging && data.response[0]?.paging.next ? true : false;
                    const hasBefore = data.response[0]?.paging && data.response[0]?.paging.previous ? true : false;
                    const previousCursor = data.response[0]?.paging.cursors ? data.response[0]?.paging.cursors.before : null;
                    const nextCursor = data.response[0]?.paging.cursors ? data.response[0]?.paging.cursors.after : null;

                    this.setState({ templates: templates, block: false, hasNext, hasBefore, previousCursor, nextCursor });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/WhatsApp/v1/messageTemplate?limit=10${cursor ? `&pageCursor=${cursor}` : ''}${isPrevious ? `&next=${false}` : ''}${status ? `&status=${status}` : ``}${language ? `&language=${language}` : ``}`)
    }

    search = (e) => {
        this.getTemplates();
    }

    handleSearch = (e) => {
        const { value, name } = e.target;

        this.setState({ [name]: value });
    }

    handleSelect = (combo, name) => {
        this.setState({ [name]: combo.value });
    }

    editTemplate = (e, name, currLang) => {
        e.preventDefault();

        if (name === 0) {
            this.setState({ selectedTemplateId: name, currLang });
        }
        else {
            this.setState({ block: true }, _ => this.getTemplatesByName(name, currLang));
        }
    }

    getTemplatesByName = (name, currLang) => {
        const { templates } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    this.setState({ block: false }, () => handleNotification(data));
                }
                else if (data.response) {
                    const template = templates.find(tmp => tmp.name === name);
                    if (template) {
                        template.templates = data.response[0].data;
                    }

                    this.setState({ error: errorMessage, block: false, templates: templates, selectedTemplateId: name, currLang });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/WhatsApp/v1/messageTemplate?limit=50&name=${name}`);
    }

    returnFunc = (e) => {
        e.preventDefault();
        const { selectedTemplateId, templates } = this.state;

        templates
            .filter(tmp => tmp.name === selectedTemplateId)
            .forEach(tmp => {
                tmp.templates.splice(1);
            });

        this.setState({ selectedTemplateId: null, templates });
    }

    orderComponents = (components) => {
        const res = components.sort((a, b) => {
            if (a.type === "HEADER") return -1;
            if (b.type === "HEADER") return 1;

            if (a.type === "BODY") return -1;
            if (b.type === "BODY") return 1;

            if (a.type === "FOOTER") return -1;
            if (b.type === "FOOTER") return 1;

            if (a.type === "BUTTONS") return -1;
            return 1;
        });
        return res;
    }

    handlePagination = (pos) => {
        const { hasNext, hasBefore, previousCursor, nextCursor, currentPage } = this.state;

        if (pos > 0 && hasNext) this.setState({ block: true, currentPage: currentPage + 1 }, () => this.getTemplates(nextCursor, false));
        if (pos < 0 && hasBefore) this.setState({ block: true, currentPage: currentPage - 1 }, () => this.getTemplates(previousCursor, true));
    }

    handleFilter = (name, combo, evt) => {
        if (evt) {
            this.setState({ [name]: combo && combo.value, previousCursor: null, hasBefore: false, nextCursor: null, hasNext: false });
        }
    }

    render() {
        const { templates, error, block, selectedTemplateId, currLang, hasNext, hasBefore, currentPage, status, language } = this.state;
        const { intl, history } = this.props;

        const getWhastAppStatusOptions = getWhastAppStatus().map(stt => { stt.label = intl.formatMessage({ id: `WhatsApp.${stt.value}` }); return stt; });

        return (
            (selectedTemplateId !== 0 && selectedTemplateId === null) ?
                <StyledCard error={error} block={block}>
                    <div>
                        <div>
                            <Breadcrumb>
                                <BreadcrumbItem>
                                    <Link to="/WhatsAppConfig">
                                        <FormattedMessage id="NavMenu.WhatsAppConfig" />
                                    </Link>
                                </BreadcrumbItem>
                                <BreadcrumbItem active>
                                    <FormattedMessage id="WhatsApp.TemplateManagement" />
                                </BreadcrumbItem>
                            </Breadcrumb>
                        </div>
                        <Row>
                            <Col className='col-3'>
                                <CustomSelect options={getWhastAppStatusOptions} value={getWhastAppStatusOptions.find(opt => opt.value === status)} onChange={this.handleFilter.bind(this, 'status')} isClearable isSearchable placeholder={<FormattedMessage id="generic.status" />} />
                            </Col>
                            <Col className='col-3'>
                                <CustomSelect options={getLanguagesCodesOptions()} placeholder={<FormattedMessage id="WhatsApp.Language" />} value={getLanguagesCodesOptions().find(opt => opt.value === language)} onChange={this.handleFilter.bind(this, 'language')} isClearable isSearchable />
                            </Col>
                            <Col className='text-right'>
                                <Button className="btn- btn-host btn-small btn-sm mr-2" onClick={this.search}>
                                    <i className="fas fa-search" />
                                </Button>
                                <Button className="btn- btn-host btn-small btn-sm" onClick={e => this.editTemplate(e, 0)}>
                                    <i className="fas fa-plus" />
                                </Button>
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            {templates?.map((master, key) =>
                                <TemplatePreview
                                    history={history}
                                    editTemplate={this.editTemplate}
                                    languages={master.templates?.map(({ language }) => language)}
                                    name={master.name}
                                    templates={master.templates}
                                    key={key}
                                    orderComponents={this.orderComponents}
                                    components={master.components}
                                />
                            )}
                        </Row>
                        {hasNext || hasBefore ?
                            <Row className="my-4">
                                <Col className="text-right mr-3">
                                    <Pagination
                                        isPrevDisabled={!hasBefore}
                                        isNextDisabled={!hasNext}
                                        currentPage={currentPage}
                                        handlePrevButton={() => this.handlePagination(-1)}
                                        handleNextButton={() => this.handlePagination(1)}
                                    />
                                </Col>
                            </Row>
                            : ''}
                    </div>
                </StyledCard>
                :
                <WhatsAppTemplates
                    isNew
                    returnFunc={this.returnFunc}
                    lang={currLang}
                    templates={templates?.find(({ name }) => name === selectedTemplateId)?.templates}
                    orderComponents={this.orderComponents}
                />
        )
    }
}

export default injectIntl(TemplateManagement);