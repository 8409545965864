import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import CoolDataGrid from '../../Base/CoolDataGrid/CoolDataGrid';
import moment from 'moment-timezone';
import { CoolTooltip, FormatAmountNumber } from '../../Base/CommonUIComponents';
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { ErrorAlert } from '../../Base/ErrorAlert';
import CustomBlockUI from '../../Base/CustomBlockUI';
import { Col, Progress, Row, UncontrolledPopover } from 'reactstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import SalesProcessGridSpaceCell from './SalesProcessGridSpaceCell';

class SalesProcessDealsRooms extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            columns: [],
            rows: [],
            startDate: null,
            endDate: null,
            spacesAvail: null,
            roomsAvail: null,
            error: null,
            blockSpaces: {},
            blockRooms: {},
            hotelIds: [],
            hideStageType: false,
            hideOption: false,
            hideNoInv: false,
            dealIdWithFocusedCells: null,
            statusAffectingInv: []
        };
    }

    componentWillReceiveProps(nextProps) {
        const { hotelIds, hotelList, startDate, endDate, hideStageType, hideOption, hideNoInv } = nextProps;
        
        const diffHotelLength = hotelList?.length !== this.props.hotelList?.length;
        const diffDealLength = () => hotelList?.reduce((a, b) => a + b.stageTypes?.reduce((a, b) => a + b.salesDeals?.length, 0), 0) !== this.props.hotelList?.reduce((a, b) => a + b.stageTypes?.reduce((a, b) => a + b.salesDeals?.length, 0), 0);
        const diffDates = (startDate && !startDate.isSame(this.state.startDate)) || (endDate && !endDate.isSame(this.state.endDate));
        const diffHotels = (hotelIds && (hotelIds?.length !== this.props.hotelIds?.length || hotelIds?.some((hotelId) => !this.props.hotelIds?.includes(hotelId)) || this.props.hotelIds?.some((hotelId) => !hotelIds?.includes(hotelId))));
        const diffViewParams = hideStageType !== this.state.hideStageType || hideOption !== this.state.hideOption || hideNoInv !== this.state.hideNoInv;

        if(diffViewParams || diffHotels || diffDates || diffHotelLength || diffDealLength()) {
            this.setState({
                hotelIds,
                hideStageType,
                hideOption,
                hideNoInv,
                startDate: startDate?.clone(),
                endDate: endDate?.clone(),
            }, () => {
                if (diffHotels || (diffDates && startDate && endDate)){
                    this.getHotelsEvents()
                    this.initialLoad(hotelList, startDate, endDate, hotelIds);
                }
                else {
                    this.getHotelsEvents()
                    this.initialCalc(hotelList, startDate, endDate);
                }
            });
        }
    }

    componentDidMount() {
        const { startDate, endDate, hotelList, hotelIds } = this.props;
        this.getTypesList();
        if(hotelIds && (!startDate?.isSame(this.state.startDate) || !endDate?.isSame(this.state.endDate)) && startDate && endDate){
            this.initialLoad(hotelList, startDate, endDate, hotelIds);
        }
    }

    getTypesList = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.data?.length) {
                    this.setState({
                        block: false,
                        statusAffectingInv: data.data.find(x => x.fixedValue === "Won")?.mappingCodes??[]
                    });

                    return;
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/customtypes?type=PipelineStageType`);
    }

    goToDealDetail = (e, id) => {
        e.stopPropagation();
        this.props.selectDealDetail(id, 'SalesProcessGrid');
    }

    toggleFocusedDeals = (dealId, focus) => {
        const { dealIdWithFocusedCells } = this.state;
        
        const idToUse = focus ? dealId : dealIdWithFocusedCells;
        const cells = document.getElementsByClassName(`space-cell-data-${idToUse}`);
        const remove = document.getElementsByClassName("resources-grid-focused-cell");

        if(remove && remove.length > 0){
            while(remove.length > 0){
                remove[0].classList.remove("resources-grid-focused-cell");
            };
        }

        if(cells && cells.length > 0){
            if(focus){
                cells.forEach(c => c.classList.add("resources-grid-focused-cell"));
            }

            cells[0].scrollIntoView();

            this.setState({ dealIdWithFocusedCells: focus ? dealId : null });
        }
    }

    sortByStatus(arr) {
        const statusOrder = {
            "Won": 1,
            "WaitingHotel": 2,
            "WaitingClient": 3,
        };
    
        return arr?.sort((a, b) => {
            const statusA = statusOrder[a?.status] || 4; // Default to 4 if status is not found
            const statusB = statusOrder[b?.status] || 4; // Default to 4 if status is not found
            
            return (statusA??0) - (statusB??0);
        });
    }

    getHotelsEvents = (e) => {
        if (e) e.preventDefault();
        const { startDate, endDate } = this.state;
        const { hotelIds } = this.props;

        if (!startDate || !endDate) {
            return;
        }

        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    if (data.response?.length) {
                        const dailySummary = {};

                        data.response.forEach(event => {
                            const startDate = new Date(event.startDate);
                            const endDate = new Date(event.endDate);

                            let currentDate = new Date(startDate);
                            while (currentDate <= endDate) {
                                const dayStr = currentDate.toISOString().split('T')[0];

                                if (!dailySummary[dayStr]) {
                                    dailySummary[dayStr] = { type: "", day: dayStr, pax: 0 };
                                }
                                dailySummary[dayStr].pax += event.pax;

                                dailySummary[dayStr].type = dailySummary[dayStr].type == "Event" ? "Event" : event.eventType;

                                currentDate.setDate(currentDate.getDate() + 1);
                            }
                        });

                        var sortedSummary = Object.values(dailySummary).sort((a, b) => new Date(a.day) - new Date(b.day));

                        this.setState({ hotelsCityEvents: sortedSummary, hotelsCityEventsDetails: data.response, block: false });
                        return;
                    }

                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/location/events?${hotelIds && hotelIds.map(h => `hotelIds=${h}`).join('&')}${startDate ? `&startDate=${moment(startDate)?.format('YYYY-MM-DD')}` : ''}${endDate ? `&endDate=${moment(endDate)?.format('YYYY-MM-DD')}` : ''}`)
        });
    }

    initialCalc = (data, startDate, endDate) => {
        const { blockRooms, spacesAvail, roomsAvail, blockSpaces, hotelIds, hideStageType, hideOption, hideNoInv, statusAffectingInv, dealIdWithFocusedCells, hotelsCityEvents, hotelsCityEventsDetails } = this.state;
        const { spaceList } = this.props;
        const { intl } = this.props;

        const columns = [
            {
                key: 'name',
                isFixed: true,
                name: intl.formatMessage({ id: "SalesProcess.Name" }),
                filterable: true,
                width: 280
            }
        ];
  
        const numberOfDays = moment(endDate).diff(moment(startDate), 'day') + 1;
  
        for (let d = 1; d <= numberOfDays; d++) {
            const date = startDate.clone().add((d - 1), 'day');
            
            const isWeekend = (date.day() === 0) || (date.day()  === 6);

            columns.push(
                {
                    key: moment(date).format('YYYY-MM-DD'),
                    name: `${moment(date).format('ddd')} ${moment(date).format('DD-MM')}`,
                    width: 100,
                    isWeekend,
                    filterable: true,
                    isToday: moment(date).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD'),
                    isHolidayOrNote: hotelsCityEvents?.some(x => (x.type == "Holiday" || x.type == "Note")
                        && moment(date).format('YYYY-MM-DD') == moment(x.day).format('YYYY-MM-DD')),
                    isSmallEvent: hotelsCityEvents?.some(x => x.type == "Event" && x.pax < 2000
                        && moment(date).format('YYYY-MM-DD') == moment(x.day).format('YYYY-MM-DD')),
                    isEvent: hotelsCityEvents?.some(x => x.type == "Event" && x.pax >= 2000 && x.pax < 15000
                        && moment(date).format('YYYY-MM-DD') == moment(x.day).format('YYYY-MM-DD')),
                    isBigEvent: hotelsCityEvents?.some(x => x.type == "Event" && x.pax >= 15000
                        && moment(date).format('YYYY-MM-DD') == moment(x.day).format('YYYY-MM-DD')),
                    eventDetails: hotelsCityEventsDetails?.filter(x => moment(date).format('YYYY-MM-DD') >= moment(x.startDate).format('YYYY-MM-DD') && moment(date).format('YYYY-MM-DD') <= moment(x.endDate).format('YYYY-MM-DD'))
                }
            );
        }
    
        const days = columns.slice(1);

        const calcRooms = (stage, hotelId, hideStageType, hideOption, hideNoInv, statusAffectingInv) => {
            return this.sortByStatus(stage.salesDeals)?.map((deal, dealKey) => {
                const roomsPerDay = {};
                const daysWithOptions = {};
                const daysNoAffecting = {};
                let dealHasEvents = deal.proposals.some(a => a.type === "Event");
                
                deal.proposals.filter(({isOption}) => !hideOption || !isOption).forEach(p => {
                    const json = JSON.parse(p.proposalJSON);
    
                    if(json?.BlockData?.details && (!hideNoInv || (json?.BlockData?.headerData && statusAffectingInv.includes(p.pmsStatus)))){
                        const notAffecting = !statusAffectingInv.includes(p.pmsStatus);
                        json.BlockData.details.forEach(d => {
                            const date = moment(d.Date).format('YYYY-MM-DD');
    
                            if(!roomsPerDay[date])
                                roomsPerDay[date] = 0;
                            
                            roomsPerDay[date] += d.CurrentRooms;

                            if(p.isOption)
                                daysWithOptions[date] = true;

                            if(notAffecting)
                                daysNoAffecting[date] = true;
                        });
                    }
                });

                const originalRoomsPerDay = JSON.parse(JSON.stringify(roomsPerDay));

                Object.keys(roomsPerDay).forEach((key) => {
                    const current = roomsPerDay[key];
                    const dayHasOption = daysWithOptions[key];
                    const dayNoInv = daysNoAffecting[key];
                    
                    roomsPerDay[key] = (
                        <div
                            className='w-100 h-100 d-flex align-items-center justify-content-center'
                            style={{
                                background: dayHasOption && dayNoInv ?
                                    'repeating-linear-gradient(45deg, #614a0457, #614a0457 5px, #bdd4f3 5px, #bdd4f3 12px)'
                                : dayHasOption ?
                                    'repeating-linear-gradient(45deg, #614a0457, #614a0457 5px, #ffffff 5px, #ffffff 12px)'
                                : dayNoInv ?
                                    'repeating-linear-gradient(45deg, #bdd4f3, #bdd4f3 5px, #ffffff 5px, #ffffff 12px)'
                                :''
                                }}
                            >
                            {current}
                        </div>
                    );
                });

                const preWrapContent = dealHasEvents && deal.status !== "Cancelled" ? (
                    <div className='w-100 h-100 d-flex align-items-center justify-content-center cursor-pointer' onClick={_ => this.toggleFocusedDeals(deal.dealId, true)}>
                        <i className="far fa-eye"/>
                    </div>
                ):'';

                const currency = global.hotelList.find(x => x.value == hotelId)?.currency;

                const mainBlockProposal =
                    deal.proposals.find(({pmsStatus, type}) => type === "Block" && statusAffectingInv.includes(pmsStatus))
                ??
                    deal.proposals.find(({type}) => type === "Block");

                return (
                    {
                        data: {
                            name: (
                                <>
                                    <div style={{ borderTop: hideStageType && !dealKey && stage.stageColor ? `3px solid ${stage.stageColor}` :'' }} className="h-100 cursor-pointer d-flex align-items-center justify-content-between w-100 px-2" id={`deal-detail-cell-${deal.dealId}-${hotelId}`}>
                                        <div className="text-truncate">
                                            <div className="text-truncate">
                                                <a className='hover-underline text-truncate' href={`/SalesProcess?dealId=${deal.dealId}#SalesDealDetail`}>
                                                    {deal.dealName}
                                                </a>
                                            </div>
                                            {deal.salesDealUsers && deal.salesDealUsers[0] ?
                                                <div style={{ fontSize: '0.8em', marginTop: '-2px' }}>{deal.salesDealUsers[0].name}</div>
                                            :''}
                                        </div>
                                        <div className="ml-2 d-flex align-items-center justify-content-between">
                                            <div className='mr-3 text-muted' style={{ fontSize: '0.8em' }}>
                                                {mainBlockProposal?.pmsStatus}
                                            </div>
                                            <div>
                                                {(deal.status === "WaitingClient" || deal.status === "WaitingHotel") ? 
                                                    <i style={{ color: '#0665ff' }} className="fas fa-people-arrows"/>
                                                : deal.status === "Finished" ?
                                                    <i style={{ color: '#36ad51' }} className="fas fa-trophy"/>
                                                :
                                                    <i style={{ color: '#f38375' }} className="far fa-thumbs-down"/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <UncontrolledPopover target={`deal-detail-cell-${deal.dealId}-${hotelId}`} placement={"right"} placementPrefix='salesProcessGridCellPopover coolPopover bs-popover'>
                                        <div style={{ width: '20rem' }} className='p-2'>
                                            <div>
                                                <div className='text-truncate' style={{ fontSize: '1.1em' }}>
                                                    <div className='text-truncate'>
                                                        {deal.dealName}
                                                    </div>
                                                </div>
                                                <div className='text-truncate' style={{ fontSize: '0.85em' }}>
                                                    <Link to={{ pathname: "/ProfileDetails/" + deal.customer?.id }} target="_blank" rel="noopener noreferrer">
                                                        <div className='text-truncate'>
                                                            {deal.customer?.name}
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <Row className='mt-1'>
                                                <Col className="col-6">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.Resposible"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {deal.salesDealUsers[0] ?
                                                            deal.salesDealUsers[0].name??'-'
                                                        :'-'}
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.MainBlock"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {mainBlockProposal?.pmsStatus}
                                                    </div>
                                                </Col>
                                                <Col className="col-6">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.TotalPax"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {deal.pax}
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.NumberOfRooms"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {deal.numberOfRooms}
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.CheckIn"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {moment(deal.checkIn).format('YYYY-MM-DD')}
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.CheckOut"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {moment(deal.checkOut).format('YYYY-MM-DD')}
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.NumberOfNights"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {moment(deal.checkOut).diff(moment(deal.checkIn), 'd')}
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.AvgRate"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {isNaN((deal.totalRevenue / deal.numberOfRooms)) ?
                                                            '0'
                                                        :
                                                            (deal.totalRevenue / deal.numberOfRooms)
                                                        }
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.RevenueRooms"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        <FormatAmountNumber
                                                            value={deal.revenueRooms}
                                                            currency={currency}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.F&B"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        <FormatAmountNumber
                                                            value={deal.revenueFB}
                                                            currency={currency}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.Banquets"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        <FormatAmountNumber
                                                            value={deal.revenueBanquets}
                                                            currency={currency}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.SpaceRental"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        <FormatAmountNumber
                                                            value={deal.revenueSpaces}
                                                            currency={currency}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.Misc"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        <FormatAmountNumber
                                                            value={deal.revenueEquipment}
                                                            currency={currency}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.TotalRevenue"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        <FormatAmountNumber
                                                            value={deal.totalRevenue}
                                                            currency={currency}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </UncontrolledPopover>
                                </>
                            ),
                            ...roomsPerDay
                        },
                        dailyData: originalRoomsPerDay, // just for calcs 
                        cellsNoPadding: true,
                        preWrapContent
                    }
                );
            })??[];
        }
        
        const rows = hotelIds?.map((hotelId) => {
            const h = data.find((h) => h.hotelId === hotelId);

            let stages;
            
            if(!hideStageType){
                stages = h?.stageTypes?.map((stage) => {
                    const children = calcRooms(stage, hotelId, false, hideOption, hideNoInv, statusAffectingInv);
        
                    const dailyInv = Object.assign({}, ...days.map(({key}) => ({ [key]: ((children?.reduce((a,b) => a + (b.dailyData[key]??0), 0)??0) > 0 ? (children?.reduce((a,b) => a + (b.dailyData[key]??0), 0)??0) : '-') })));
    
                    return ({
                        data: {
                            name: (
                                <div className='w-100 d-flex align-items-center justify-content-between'>
                                    <div className="text-muted">{stage.stageName}</div>
                                    {stage.stageColor ?
                                        <div style={{ background: stage.stageColor, width: '10%', height: '10px' }}/>
                                    :''}
                                </div>
                            ),
                            ...dailyInv
                        },
                        isSmall: true,
                        isOpened: true,
                        children
                    })
                });
            }
            else{
                stages = h?.stageTypes.map((stage) => calcRooms(stage, hotelId, true, hideOption, hideNoInv, statusAffectingInv))?.flat();
            }

            const dailyInv = Object.assign({}, ...days.map(({key}) => {
                const dailyInv = roomsAvail && roomsAvail[hotelId]?.find(({hotelDate}) => moment(hotelDate).isSame(key));
                const roomsInv = dailyInv?.inventoryRooms??0;
                const freeRooms = dailyInv?.freeRooms??0;
                const total = roomsInv - freeRooms;
                const percentage = Math.round(((total / roomsInv) * 100) * 100) / 100;

                const color = (0 <= percentage && percentage < 33) ?
                    { color: 'success', hex: '#5cb85c' }
                : (33 <= percentage && percentage < 66) ?
                    { color: 'warning', hex: '#f0ad4e' }
                : { color: 'danger', hex: '#d9534f' };


                return ({
                    [key]: (
                        <CustomBlockUI blocking={blockRooms[hotelId]}>
                            <div className='w-100' style={{ visibility: !dailyInv ? 'hidden' : '' }} id={`availabilityPercentage-${key}-${hotelId}`}>
                                <div className='px-3'>
                                    <Progress
                                        color={color.color}
                                        value={percentage + 1}
                                        style={{
                                            maxHeight: '5px',
                                        }}
                                    />
                                </div>
                                <div className='cursor-default text-center mt-2'>
                                    {freeRooms}
                                </div>
                            </div>
                            <CoolTooltip trigger="legacy" target={`availabilityPercentage-${key}-${hotelId}`} placement="right">
                                {roomsInv > 0 ?
                                    <>
                                        <i className={`far fa-square mr-2`} style={{ background: color.hex }} /> {percentage}%
                                    </>
                                :''}
                                <div className="d-flex align-items-center"> <i className="fas fa-hashtag fa-xs mr-2 pr-1 ml-1" /> {total}/{roomsInv} </div>
                            </CoolTooltip>
                        </CustomBlockUI>
                    )
                })
            }));

            const hotelSpaceAvail = spacesAvail && spacesAvail[hotelId];

            const deals = (h?.stageTypes?.flatMap(({salesDeals}) => salesDeals
                .flatMap((deal) => 
                    deal.proposals.filter(({isOption}) => !hideOption || !isOption).flatMap(({proposalJSON, pmsStatus}) => {
                        const json = JSON.parse(proposalJSON);
                        const events = json?.data && json.data[0]?.DetailsEvents && json.data[0].DetailsEvents[0];

                        return events?.Spaces?.map(({SpaceName, FromDate, ToDate}) => ({
                            name: SpaceName,
                            eventId: events?.IndividualReservationId,
                            dealId: deal.dealId,
                            startDate: FromDate,
                            endDate: ToDate,
                            dealName: deal.dealName,
                            pmsStatus
                        }))??[];
                    })
                )
            )??[]);

            let spaceRows = spaceList
                ?.filter((s) => s.hotelId === hotelId)
                .map(({description, id}) => {

                    const events = hotelSpaceAvail
                        ?.filter(({spaceName}) => spaceName === description);
                        
                    const dailyInv = Object.assign({}, ...days
                        .map(({key}) => {
                            const dealsInDay = deals.filter((deal) =>
                                moment(deal.startDate).startOf('d').isSameOrBefore(key) &&
                                moment(deal.endDate).startOf('d').isSameOrAfter(key)
                            );

                            const dailyEvents = events?.filter(({spaceFromDate, spaceToDate}) =>
                                moment(spaceFromDate).startOf('d').isSameOrBefore(key) &&
                                moment(spaceToDate).startOf('d').isSameOrAfter(key)
                            );

                            return ({
                                [key]: dailyEvents
                                    ?.map(({spaceFromDate, spaceToDate}) => {
                                        const timeDistribuition = [0,0,0,0];
                                        const from = moment(spaceFromDate);
                                        const to = moment(spaceToDate);
                                        const isFirstDay = from.clone().startOf('d').isSame(key);
                                        const isLastDay = to.clone().startOf('d').isSame(key);
                                        const startHour = parseInt(from.format('HH'));
                                        let endHour = parseInt(to.clone().format('HH'));
                                        
                                        if(endHour > 0) {
                                            endHour = parseInt(to.clone().add('minute', -1).format('HH'));
                                        }

                                        if(isFirstDay && isLastDay){
                                            if(startHour < 6 && endHour >= 0) {
                                                timeDistribuition[0] = 1;
                                            }
                                            if(startHour < 12 && endHour >= 6) {
                                                timeDistribuition[1] = 1;
                                            }
                                            if(startHour < 18 && endHour >= 12) {
                                                timeDistribuition[2] = 1;
                                            }
                                            if(startHour < 24 && endHour >= 18) {
                                                timeDistribuition[3] = 1;
                                            }
                                        }
                                        else if(isFirstDay) {
                                            if(startHour < 6) {
                                                timeDistribuition[0] = 1;
                                            }
                                            if(startHour < 12) {
                                                timeDistribuition[1] = 1;
                                            }
                                            if(startHour < 18) {
                                                timeDistribuition[2] = 1;
                                            }
                                            if(startHour < 24) {
                                                timeDistribuition[3] = 1;
                                            }
                                        }
                                        else if(isLastDay) {
                                            timeDistribuition[0] = 1;
                                            if(endHour > 6) {
                                                timeDistribuition[1] = 1;
                                            }
                                            if(endHour > 12) {
                                                timeDistribuition[2] = 1;
                                            }
                                            if(endHour > 18) {
                                                timeDistribuition[3] = 1;
                                            }
                                        }
                                        if(!isFirstDay && !isLastDay) { // only first and last days have hours
                                            timeDistribuition[0] = 1;
                                            timeDistribuition[1] = 1;
                                            timeDistribuition[2] = 1;
                                            timeDistribuition[3] = 1;
                                        }
                                        return timeDistribuition;
                                    })
                                    .reduce((a, b) => {
                                        const arr = [...a];

                                        arr[0] += (b[0]??0);
                                        arr[1] += (b[1]??0);
                                        arr[2] += (b[2]??0);
                                        arr[3] += (b[3]??0);

                                        return arr;
                                    }, [0,0,0,0])
                                    .map((value, k) => {
                                        const eligibleDeals = dealsInDay.filter(({eventId}) => dailyEvents?.map(a => a.eventId)?.includes(eventId));

                                        return ({
                                            value: value,
                                            dealList: eligibleDeals
                                            .filter(({startDate, endDate}) => {
                                                const from = moment(startDate);
                                                const to = moment(endDate);
                                                const isFirstDay = from.clone().startOf('d').isSame(key);
                                                const isLastDay = to.clone().startOf('d').isSame(key);
                                                const startHour = parseInt(from.format('HH'));
                                                let endHour = parseInt(to.clone().format('HH'));
                                                
                                                if(endHour > 0) {
                                                    endHour = parseInt(to.clone().add('minute', -1).format('HH'));
                                                }
                                                
                                                const maxHour = ((k + 1) * 6);
                                                const minHour = ((k + 1) * 6) - 6;
                                                if(isFirstDay && isLastDay){
                                                    if(startHour < maxHour && endHour >= minHour){
                                                        return true;
                                                    }
                                                }
                                                else if(isFirstDay){
                                                    if(startHour < maxHour){
                                                        return true;
                                                    }
                                                }
                                                else if(isLastDay){
                                                    if(maxHour <= endHour || (minHour < endHour && maxHour >= endHour)){
                                                        return true;
                                                    }
                                                }
                                                if(!isFirstDay && !isLastDay){
                                                    return true;
                                                }
        
                                            })
                                            .map(({dealId, pmsStatus}) => ({
                                                dealId,
                                                pmsStatus,
                                                isDEF: statusAffectingInv.includes(pmsStatus),
                                            }))
                                        })
                                    }
                                )
                            })
                        })
                    );

                    return ({ // sortOrder
                        data:{
                            name: description,
                            id: id,
                            ...dailyInv
                        }
                    });
                });
                
            if(spaceRows){
                spaceRows = spaceRows.map((r) => {
                    const data = {
                        name: <div className='px-2'>{r.data.name}</div>
                    }
        
                    Object.keys(r.data)
                        .filter(a => a !== "name" && a !== "id")
                        .map(d => {
                            const dailyOcc = r.data && r.data[d];

                            const first = dailyOcc && dailyOcc[0]?.dealList?.flatMap(({dealId}) => dealId);
                            const sec = dailyOcc && dailyOcc[1]?.dealList?.flatMap(({dealId}) => dealId);
                            const third = dailyOcc && dailyOcc[2]?.dealList?.flatMap(({dealId}) => dealId);
                            const fourth = dailyOcc && dailyOcc[3]?.dealList?.flatMap(({dealId}) => dealId);
                            
                            const dealList = dailyOcc && dailyOcc
                                .flatMap(({dealList}) => dealList)
                                .filter((a, b, c) => c.findIndex(({dealId}) => dealId === a.dealId) === b)
                                .map((deal) => ({
                                    dealId: deal.dealId,
                                    pmsStatus: deal.pmsStatus,
                                    isDEF: deal.isDEF,
                                    name: deals.find((d) => d.dealId === deal.dealId)?.dealName,
                                    start: first?.includes(deal.dealId) ? 0 : sec?.includes(deal.dealId) ? 6 : third?.includes(deal.dealId) ? 12 : 18,
                                    end: fourth?.includes(deal.dealId) ? 24 : third?.includes(deal.dealId) ? 18 : sec?.includes(deal.dealId) ? 12 : 6,
                                })
                            );

                            const customClasses = dealList?.map(({dealId}) => `space-cell-data-${dealId}`)?.toString()?.replace(',',' ');
                            
                            return ({
                                [d]: (
                                    <SalesProcessGridSpaceCell
                                        dailyOcc={dailyOcc}
                                        blockSpaces={blockSpaces}
                                        hotelId={hotelId}
                                        customClasses={customClasses}
                                        dealList={dealList}
                                        first={first}
                                        sec={sec}
                                        third={third}
                                        fourth={fourth}
                                        d={d}
                                        r={r}
                                        toggleFocusedDeals={this.toggleFocusedDeals}
                                    />
                                )
                            })
                        })
                        .forEach((a) => Object.keys(a).forEach(b => data[b] = a[b]));
        
                    return ({
                        ...r,
                        data: data,
                        cellsNoPadding: true
                    });
                });
            }

            return (
                {
                    data: {
                        name: global.hotelList.find(({value}) => value === hotelId)?.label??hotelId,
                        ...dailyInv
                    },
                    isOpened: true,
                    emptyNull: true,
                    children: [
                        {
                            separator: true,
                            isOpened: true,
                            title: intl.formatMessage({ id: "SalesProcess.Deals" }),
                            children: stages,
                        },
                        {
                            separator: true,
                            isOpened: true,
                            title: intl.formatMessage({ id: "SalesProcess.Spaces" }),
                            children: spaceRows
                        }
                    ].filter(({children}) => children && children.length > 0)
                }
            );
        });

        this.setState({ columns, rows });
    }

    initialLoad = (hotelList, startDate, endDate, hotelIds) => {
        const { blockRooms, blockSpaces, spacesAvail, roomsAvail } = this.state;
        
        const getRoomsData = (hotelId, key, array) => {
            this.getRoomsAvailability(hotelId, (hasError) => {
                if(hasError){
                    this.setState({
                        blockRooms: {
                            ...blockRooms,
                            [hotelId]: false
                        }
                    });
                }
                if((key + 1) === array.length){
                    this.setState({
                        blockRooms: {
                            ...blockRooms,
                            [hotelId]: false
                        }
                    }, () => this.initialCalc(hotelList, startDate, endDate));
                }
                else{
                    const nextKey = key + 1;
                    getRoomsData(array[nextKey], nextKey, array);
                }
            });
        }
        
        const getSpacesData = (hotelId, key, array) => {
            this.getSpacesAvailability(hotelId, (hasError) => {
                if(hasError){
                    this.setState({
                        blockSpaces: {
                            ...blockSpaces,
                            [hotelId]: false
                        }
                    });
                }
                if((key + 1) === array.length){
                    this.setState({
                        blockSpaces: {
                            ...blockSpaces,
                            [hotelId]: false
                        }
                    }, () => this.initialCalc(hotelList, startDate, endDate));
                }
                else{
                    const nextKey = key + 1;
                    getSpacesData(array[nextKey], nextKey, array);
                }
            });
        }

        if(hotelIds?.length){
            const hotelKey = hotelIds && hotelIds[0];
    
            const blockSpaces = {
                ...this.state.blockSpaces
            };
            const blockRooms = {
                ...this.state.blockRooms
            };
    
            if(hotelKey){
                blockRooms[hotelKey] = !(roomsAvail && roomsAvail[hotelKey] && roomsAvail[hotelKey].find(({hotelDate}) => moment(hotelDate).isSame(startDate) && roomsAvail[hotelKey].findLast(({hotelDate}) => moment(hotelDate).isSame(endDate))));
                blockSpaces[hotelKey] = !(spacesAvail && spacesAvail[hotelKey] && spacesAvail[hotelKey].find(({hotelDate}) => moment(hotelDate).isSame(startDate) && spacesAvail[hotelKey].findLast(({hotelDate}) => moment(hotelDate).isSame(endDate))));

                this.setState({ blockRooms, blockSpaces, hotelIds }, () => {
                    this.initialCalc(hotelList, startDate, endDate);
                    getRoomsData(hotelIds[0], 0, hotelIds);
                    getSpacesData(hotelIds[0], 0, hotelIds);
                });
            }
            else{
                this.initialCalc(hotelList, startDate, endDate);
            }
        }
    }

    getRoomsAvailability = (hotelId, cb) => {
        const { startDate, endDate, roomsAvail } = this.state;
        
        let qs = `?hotelId=${hotelId}&fromDate=${startDate.format('YYYY-MM-DD')}&toDate=${endDate.clone().add(1, 'day').format('YYYY-MM-DD')}`;
        let hasError = false;
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                hasError = true
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage }, _ => cb(hasError));
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    hasError = true;
                }

                this.setState({
                    roomsAvail: {
                        ...roomsAvail,
                        [hotelId]: data.response??[]
                    }
                }, _ => cb(hasError));

                return;
            }
            else this.setState({ error: errorMessage }, _ => cb(true));
        }, `/api/gms/SalesProcess/v1/roomsforecast${qs}`);
    }

    getSpacesAvailability = (hotelId, cb) => {
        const { startDate, endDate, spacesAvail } = this.state;

        let qs = `?hotelId=${hotelId}&fromDate=${startDate.format('YYYY-MM-DD')}&toDate=${endDate.clone().add(1, 'day').format('YYYY-MM-DD')}`;
        let hasError = false;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage }, _ => cb(hasError));
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({
                    spacesAvail: {
                        ...spacesAvail,
                        [hotelId]: data.response ? data.response[0] ? data.response[0].reservationList ?? [] : [] : []
                    }
                }, _ => cb(hasError));

                return;
            }
            else this.setState({ error: errorMessage }, () => cb(true));
        }, `/api/gms/SalesProcess/v1/spacesavailability${qs}`);
    }

    render() {
        const { columns, rows, error } = this.state;

        return (
            columns?.length > 1 ?
                <div className="mt-4">
                    <ErrorAlert error={error} />
                    <CoolDataGrid
                        rows={rows}
                        cols={columns}
                        tableName="GridRooms"
                    />
                </div>
            :''
        );
    }
}

export default injectIntl(SalesProcessDealsRooms);