import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import { Row, Col, Input, Button, Modal, ModalBody, Card, CardBody } from 'reactstrap';
import { ActiveInactiveStatusCombo, BlankCard } from '../../Base/CommonUIComponents';
import CustomSelect from '../../Base/CustomSelect';
import { getCountTypeOptions, getProfileType } from '../../Base/ReferenceDataFunctions';
import { postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';

class PriceListRulesModal extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            config: this.props.selectedConfig || { active: false, priceListRuleConfigPoints: [] }
        };
    }

    handleSave = () => {
        if (!this.form.current.reportValidity()) {
            return;
        }

        this.setState({ block: true });

        const { toggle, updateSelectedConfig } = this.props;
        const { config } = this.state;

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.data && data.data.length > 0) {
                    handleNotification(data, <FormattedMessage id="PriceListRules.ConfigSaved" />, <FormattedMessage id="generic.success" />);
                    updateSelectedConfig(data.data[0]);
                    toggle();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/PriceListRuleConfig/v1`, config);
    }

    handleStatus = (name, combo, evt) => {
        if (evt) {
            const { config } = this.state;
            config[name] = combo.value;

            this.setState({ config });
        }
    }

    handleComboBoxChange = (combo, evt) => {
        if (evt) {
            const { config } = this.state;
            const { name } = evt;
            config[name] = combo.value;

            this.setState({ config });
        }
    }

    addConfigPoints = () => {
        const { config } = this.state;

        const priceListRuleConfigPoint = {
            countPerPeriod: null,
            priceListRuleConfigPointHotel: []
        };
        config.priceListRuleConfigPoints.push(priceListRuleConfigPoint);

        this.setState({ config });
    }

    removeConfigPoints = (priceListRuleConfigPointsIdx) => {
        const { config } = this.state;

        config.priceListRuleConfigPoints.splice(priceListRuleConfigPointsIdx, 1);

        this.setState({ config });
    }

    handleConfigPoints = (evt, idx) => {
        if (evt && evt.target) {
            const { value } = evt.target;
            const { config } = this.state;

            config.priceListRuleConfigPoints[idx].countPerPeriod = value;

            this.setState({ config });
        }
    }

    addPointHotel = (priceListRuleConfigPointsIdx) => {
        const { config } = this.state;

        const pointHotel = {
            hotelId: null,
            priceListCode: null
        };

        config.priceListRuleConfigPoints[priceListRuleConfigPointsIdx].priceListRuleConfigPointHotel.push(pointHotel);

        this.setState({ config });
    }

    removePointHotel = (priceListRuleConfigPointsIdx, priceListRuleConfigPointHotelIdx) => {
        const { config } = this.state;

        config.priceListRuleConfigPoints[priceListRuleConfigPointsIdx].priceListRuleConfigPointHotel.splice(priceListRuleConfigPointHotelIdx, 1);

        this.setState({ config });
    }

    handleHotelId = (combo, evt, priceListRuleConfigPointsIdx, priceListRuleConfigPointHotelIdx) => {
        if (evt) {
            const { config } = this.state;

            config.priceListRuleConfigPoints[priceListRuleConfigPointsIdx].priceListRuleConfigPointHotel[priceListRuleConfigPointHotelIdx].hotelId = combo.value;
            config.priceListRuleConfigPoints[priceListRuleConfigPointsIdx].priceListRuleConfigPointHotel[priceListRuleConfigPointHotelIdx].priceListCode = null;

            this.setState({ config });
        }
    }

    handlePointConfig = (combo, evt, priceListRuleConfigPointsIdx, priceListRuleConfigPointHotelIdx) => {
        if (evt) {
            const { name } = evt;
            const { config } = this.state;

            config.priceListRuleConfigPoints[priceListRuleConfigPointsIdx].priceListRuleConfigPointHotel[priceListRuleConfigPointHotelIdx][name] = combo.value;

            this.setState({ config });
        }
    }

    render() {
        const { countPeriodOptions, modal, toggle, hotels, priceLists } = this.props;
        const { block, error, config } = this.state;

        return (
            <Modal isOpen={modal}>
                <Button onClick={toggle} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <form ref={this.form}>
                            <Row className="mb-3">
                                <Col className="text-right">
                                    <Button className="btn btn-host btn-sm" onClick={this.handleSave}>
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col className="col-2">
                                    <FormattedMessage id="PriceListRules.ProfileType" />
                                </Col>
                                <Col>
                                    <CustomSelect
                                        options={getProfileType(this.props.intl).slice(1, 3)}
                                        value={getProfileType(this.props.intl).slice(1, 3).find(tp => tp.value === config.profileType)}
                                        onChange={this.handleComboBoxChange}
                                        name="profileType"
                                        required
                                    />
                                </Col>
                                <Col className="col-2">
                                    <FormattedMessage id="PriceListRules.CountType" />
                                </Col>
                                <Col>
                                    <CustomSelect
                                        options={getCountTypeOptions()}
                                        value={getCountTypeOptions().find(tp => tp.value === config.countType)}
                                        onChange={this.handleComboBoxChange}
                                        name="countType"
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col className="col-2">
                                    <FormattedMessage id="PriceListRules.CountPeriod" />
                                </Col>
                                <Col>
                                    <CustomSelect
                                        options={countPeriodOptions}
                                        value={countPeriodOptions.find(tp => tp.value === config.countPeriod)}
                                        onChange={this.handleComboBoxChange}
                                        name="countPeriod"
                                        required
                                    />
                                </Col>
                                <Col className="col-2">
                                    <FormattedMessage id="PriceListRules.Status" />
                                </Col>
                                <Col>
                                    <ActiveInactiveStatusCombo
                                        onChangeFunc={this.handleStatus}
                                        value={config.active}
                                        name="active"
                                    />
                                </Col>
                            </Row>
                            <Card className="shadow border-0 mb-1">
                                <CardBody className="py-2">
                                    <Row className="mb-3">
                                        <Col>
                                            <FormattedMessage id="PriceListRules.ConfigPoints" />
                                        </Col>
                                        <Col className="text-right">
                                            <Button className="btn btn-host btn-sm" onClick={this.addConfigPoints}>
                                                <i className="fas fa-plus" />
                                            </Button>
                                        </Col>
                                    </Row>
                                    {
                                        config.priceListRuleConfigPoints.map((points, key) =>
                                            <Row key={key} >
                                                <Col>
                                                    <hr className="mt-1" />
                                                    <Row className="mb-3">
                                                        <Col className="col-1 pr-0">
                                                            <FormattedMessage id="PriceListRules.From" />
                                                        </Col>
                                                        <Col className="col-2">
                                                            <Input
                                                                type="number"
                                                                id="countPerPeriod"
                                                                name="countPerPeriod"
                                                                value={points.countPerPeriod}
                                                                onChange={evt => this.handleConfigPoints(evt, key)}
                                                                min={key === 0 ? "1" : config.priceListRuleConfigPoints[key - 1].countPerPeriod}
                                                                step="1"
                                                                required
                                                            />
                                                        </Col>
                                                        <Col className="col-2">
                                                            {getCountTypeOptions().find(tp => tp.value === config.countType)?.label}
                                                        </Col>
                                                        <Col className="text-right">
                                                            <Button className="btn btn-host btn-sm mr-2" onClick={_ => this.addPointHotel(key)}>
                                                                <i className="fas fa-plus" />
                                                            </Button>
                                                            <Button className="btn btn-host btn-sm" onClick={_ => this.removeConfigPoints(key)}>
                                                                <i className="fas fa-trash-alt" />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        points.priceListRuleConfigPointHotel.map((pointHotel, keyPointHotel) =>
                                                            <Row key={keyPointHotel} className="mb-3">
                                                                <Col className="col-1">
                                                                    <FormattedMessage id="PriceListRules.Hotel" />
                                                                </Col>
                                                                <Col className="col-5">
                                                                    <CustomSelect
                                                                        options={hotels}
                                                                        value={hotels.filter(htl => htl.value === pointHotel.hotelId)}
                                                                        onChange={(combo, evt) => this.handleHotelId(combo, evt, key, keyPointHotel)}
                                                                        name="hotelId"
                                                                        required
                                                                    />
                                                                </Col>
                                                                <Col className="col-2">
                                                                    <FormattedMessage id="PriceListRules.PriceList" />
                                                                </Col>
                                                                <Col className="col-3">
                                                                    <CustomSelect
                                                                        options={priceLists[pointHotel.hotelId]}
                                                                        value={priceLists[pointHotel.hotelId] && priceLists[pointHotel.hotelId].filter(pnt => pnt.value === pointHotel.priceListCode)}
                                                                        onChange={(combo, evt) => this.handlePointConfig(combo, evt, key, keyPointHotel)}
                                                                        name="priceListCode"
                                                                        isDisabled={!priceLists[pointHotel.hotelId]}
                                                                        required
                                                                    />
                                                                </Col>
                                                                <Col className="text-right">
                                                                    <Button className="btn btn-host btn-sm" onClick={_ => this.removePointHotel(key, keyPointHotel)}>
                                                                        <i className="fas fa-trash-alt" />
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    }
                                                </Col>
                                            </Row>
                                        )
                                    }
                                </CardBody>
                            </Card>
                        </form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(PriceListRulesModal);