import moment from 'moment-timezone';
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Card, Col, Row } from 'reactstrap'
import GoogleAnonymousProfile from './GoogleAnonymousProfile';

export default class GoogleBusinessCard extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            showAnswers: false
        };
    }

    // toggleShowAnswers = () => {
    //     this.setState(prev => ({ showAnswers: !prev.showAnswers }));
    // }

    render() {
        const { profileURI, displayName, upvoteCount, updateTime, question, answerCount, starRating, questionKey, isAnonymous, toggleDetailModal } = this.props;
        // const { showAnswers } = this.state;

        const starCount = starRating && starRating === "ONE" ? 1 : starRating === "TWO" ? 2 : starRating === "THIRD" ? 3 : starRating === "FOUR" ? 4 : 5;
        
        return (
            <div className="mt-2 GoogleBusinessCard mx-2">
                <Card className='border-0 p-4 shadow cursor-pointer h-100 justify-content-between' onClick={_ => toggleDetailModal(questionKey)}>
                    <Row>
                        <Col style={{ display: 'flex', alignItems: 'center' }}>
                            <GoogleAnonymousProfile
                                small={true}
                                profilePhotoUri={profileURI}
                                isAnonymous={isAnonymous}
                            />
                            <span style={{ maxWidth: '150px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{displayName}</span>
                        </Col>
                        {starRating ?
                            <Col style={{ color: '#fbbc04', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {[...Array(starCount).keys()].map((e, k) => 
                                    <i key={k} className="fas fa-star"/>
                                )}
                                {starCount < 5 ?
                                    [...Array(5 - starCount).keys()].map((e, key) => 
                                        <i key={key} className="far fa-star"/>
                                    )
                                :''}
                            </Col>
                        :''}
                        <Col className='text-right pl-0'>
                            {moment(updateTime).format('DD-MM-YYYY')}
                        </Col>
                    </Row>
                    {question ?
                        <Row className='mt-2'>
                            <Col>
                                <b>{question}</b>
                            </Col>
                        </Row>
                    :''}
                    {upvoteCount || answerCount ?
                        <Row className='mt-2'>
                            {answerCount ?
                                <Col>
                                    <span className='underlineHover text-muted'>
                                        <FormattedMessage id="GoogleBusinessConfig.ShowAnswers"/>
                                    </span>
                                </Col>
                            :''}
                            {upvoteCount ?
                                <Col className='text-right'>
                                    <span className='mr-1'>
                                        {upvoteCount}
                                    </span>
                                    <i className="far fa-thumbs-up"/>
                                </Col>
                            :''}
                        </Row>
                    :''}
                </Card>
            </div>  
        )
    }
}
