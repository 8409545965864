import React, { Component } from 'react';
import { Button, Col, Modal, ModalBody, Row, Form } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { BlankCard } from '../Base/CommonUIComponents';

export class BillingDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false
        };
    }

    componentDidMount() {
    }


    render() {
        const { isOpen, toggle, title } = this.props;
        const { block, error } = this.state;

        return (
            <Modal isOpen={isOpen}>
                <Button onClick={toggle} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody className="bg-light">
                    <BlankCard block={block} error={error}>
                        <Row className="mb-3">
                            <Col>
                                <h5>{title}</h5>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host" onClick={this.saveBilling}>
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>
                        <Form >
                            <Row>
                            </Row>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}