import React, { Component } from 'react';
import { Button, Col, Card, Form, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from '../../../Base/Notification';
import { getAPI } from '../../../Base/API';
import { Crt, DefaultPage, SelectHotelGroup, StyledCard } from '../../../Base/CommonUIComponents';
import CustomSelect from '../../../Base/CustomSelect';

export class SyncStatus extends Component {

    constructor(props) {
        super(props);

        this.state = {
            blockProfile: false,
            hotelGroupId: null,
            hours: 24,
            profilesUpdated: [],
            reservationsUpdated: [],
            revenueUpdated: [],
            hourOptions: [
                { value: 12, label: '12 hours' },
                { value: 24, label: '24 hours' },
                { value: 48, label: '48 hours' },
                { value: 72, label: '72 hours' }
            ]
        };
    }

    getProfileUpdates = () => {
        this.setState({ blockProfile: true });
        var list = [];

        getAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockProfile: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ error: errorMessage, blockProfile: false }));
                }
                if (data.response && data.response.length > 0) {
                    list = data.response;
                }
            }
            this.setState({ blockProfile: false, profilesUpdated: list });
        }, `/api/gms/Monitor/v1/profile/${this.state.hotelGroupId}/updated?hours=${this.state.hours}`);
    }

    getReservationUpdates = () => {
        this.setState({ blockReservation: true });
        var list = [];

        getAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockReservation: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ error: errorMessage, blockReservation: false }));
                }
                if (data.response && data.response.length > 0) {
                    list = data.response;
                }
            }
            this.setState({ blockReservation: false, reservationsUpdated: list });
        }, `/api/gms/Monitor/v1/reservation/${this.state.hotelGroupId}/updated?hours=${this.state.hours}`);
    }

    getRevenueUpdates = () => {
        this.setState({ blockRevenue: true });
        var list = [];

        getAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockRevenue: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    list = data.response;
                }
            }
            this.setState({ blockRevenue: false, revenueUpdated: list });
        }, `/api/gms/Monitor/v1/revenue/${this.state.hotelGroupId}/updated?hours=${this.state.hours}`);
    }

    doSearch = (event) => {
        if (event) event.preventDefault();

        this.getProfileUpdates();
        this.getReservationUpdates();
        this.getRevenueUpdates();
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }


    render() {
        const { blockProfile, blockReservation, blockRevenue, profilesUpdated, reservationsUpdated, revenueUpdated, error, hotelGroupId } = this.state;
        
        return (
            <StyledCard block={blockProfile || blockReservation || blockRevenue} error={error} title="NavMenu.SyncStatus" icon="fas fa-sync" tabIndex="0">
                <Form onSubmit={this.doSearch}>
                    <Row className="align-items-center my-3">
                        <Col sm={4}>
                            <SelectHotelGroup
                                name="hotelGroupId"
                                icon="icon-icon-hotel"
                                onChangeFunc={this.handleChangeSelect}
                                value={hotelGroupId || ''}
                                placeholder={<FormattedMessage id="generic.HotelGroup" />}
                                required
                            />
                        </Col>
                        <Col sm={2}>
                            <CustomSelect
                                icon="fas fa-history"
                                options={this.state.hourOptions}
                                value={this.state.hourOptions.find(h => h.value === this.state.hours)}
                                onChange={(e) => this.handleChangeSelect('hours', e)}
                                placeholder={"Hours"}
                                isSearchable
                                required
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className="btn btn-host shadow btn-sm" type="submit">
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                {profilesUpdated.length > 0 || reservationsUpdated.length > 0 || revenueUpdated.length > 0 ?
                    <>
                        <Row>
                            <Col>
                                <Crt text="Total Profiles Updated" value={profilesUpdated.reduce((acc, cur) => acc + cur.records, 0)} />
                            </Col>
                            <Col>
                                <Crt text="Total Reservations Updated" value={reservationsUpdated.reduce((acc, cur) => acc + cur.records, 0)} />
                            </Col>
                            <Col>
                                <Crt text="Total Revenue Updated" value={revenueUpdated.reduce((acc, cur) => acc + cur.records, 0)} />
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <RecordDetails array={profilesUpdated} />
                            </Col>
                            <Col>
                                <RecordDetails array={reservationsUpdated} />
                            </Col>
                            <Col>
                                <RecordDetails array={revenueUpdated} />
                            </Col>
                        </Row>
                    </>
                : <DefaultPage text="SyncStatus.SelectHotelGroup" icon="icon-icon-search" />}
            </StyledCard>
        );
    }
}

export default injectIntl(SyncStatus);


const RecordDetails = ({ array }) => {
    return <Card className="shadow border-0 h-100" body>
        <Row className="text-muted mb-3">
            <Col> Hotel </Col>
            <Col className="text-right"> Records </Col>
        </Row>
        {array.length > 0 ?
            array.map((item, key) =>
                <Row className="overbglight py-1" key={key}>
                    <Col> {item.hotelName}</Col>
                    <Col className="text-right col-2"> {item.records}</Col>
                </Row>
            )
            : <span className="d-flex align-items-center justify-content-center h-100">No data</span>
        }
    </Card>
}