import React, { Component } from 'react';
import { Row, Col, Card, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import Authorization, { CheckAuthorization } from '../../Base/Authorization';
import { ProfileHistory } from '../../Base/HistoryComponents';
import ConfirmActionModal from '../../Base/ConfirmActionModal';
import { DefaultPage, KebabMenu } from '../../Base/CommonUIComponents';
import { ErrorAlert } from '../../Base/ErrorAlert';
import CustomDataModal from './CustomDataModal';
import { getAPI } from '../../Base/API';
import BlockUi from 'react-block-ui';


class ProfileCustomData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            deleteModal: false,
            customData: this.props.customData
        }
    }

    componentDidMount() {
        this.getConfiguredCustomFields(this.state.customData);
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.customData !== prevProps.customData) {
            if (this.state.customFieldsList) {
                this.orderCustomData(this.props.customData, this.state.customFieldsList);
            }
        }
    }

    getConfiguredCustomFields = (customData) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ customFieldsList: data.response, error: errorMessage, block: false },
                    () => this.orderCustomData(customData, data.response)
                );
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/CustomFieldConfig/v1`);
    }

    orderCustomData = (customData, customFieldsList) => {
        this.setState({ block: true });

        if (customData?.length > 0 && customFieldsList?.length > 0) {
            customData.forEach(data => {
                const settings = customFieldsList.find(el => el.code === data.typeCode) || {};

                data.sortOrder = settings.sortOrder;
                data.fieldSettings = settings;
            })

            customData.sort((a, b) => {
                if (a.sortOrder === null && b.sortOrder !== null) {
                    return 1;
                } else if (a.sortOrder !== null && b.sortOrder === null) {
                    return -1;
                } else {
                    return a.sortOrder - b.sortOrder;
                }
            });
        }

        this.setState({
            customData,
            block: false
        })
    }

    removeCustomData = () => {
        this.props.removeElement(this.props.profileId, this.state.selectedCustomData?.customData?.id, 'customData', 'profileCustomData');

        this.toggleModal('deleteModal');
    }

    toggleModal = (modal, customData, fieldSettings) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            selectedCustomData: {
                customData, fieldSettings
            }
        }))
    }

    getAvailableCustomData = () => {
        var list = this.state.customFieldsList;

        if (this.state.customData && this.state.customData.length > 0) {
            list = this.state.customFieldsList?.filter(el => this.state.customData.some(cd => cd.typeCode === el.code) === false)
                .map(el => ({ ...el, value: el.code, label: el.description }));
        }

        return list;
    }


    render() {
        const { profileId, updateTable, canEditProfile } = this.props;
        const { deleteModal, customData, customFieldsList } = this.state;


        return (
            <div className="m-2 p-2">
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                    <Row className="mb-4 align-items-center">
                        <Col>
                            <h5><i className="icon-icon-custom-fields mr-1" /><FormattedMessage id="ProfileDetails.CustomData" /></h5>
                        </Col>
                        <Col className="text-right">
                            <Authorization
                                perform="profileCustomData:add"
                                yes={() => canEditProfile && (
                                    <>
                                        <ProfileHistory
                                            component={"customdata"}
                                            profileId={this.props.profileId}
                                        />

                                        <Button className="btn-sm btn-host ml-2" onClick={() => this.toggleModal('customDataModal')}>
                                            <i className="fas fa-plus" />
                                        </Button>
                                    </>
                                )}
                                no={() => <div></div>}
                            />
                        </Col>
                    </Row>

                    <Row>
                        {customData && customData.map((field, key) => {
                            const customFieldSettings = field.fieldSettings

                            return <Col className="mb-3 col-4" key={key}>
                                <Card className="shadow border-0 py-3 h-100" body>
                                    <Row>
                                        <Col className="title-sm"> {customFieldSettings?.description ?? field.typeCode} </Col>
                                        {canEditProfile && <Col className="text-right col-1">
                                            <KebabMenu
                                                editFunction={customFieldSettings ? () => { this.toggleModal('customDataModal', field, customFieldSettings) } : null}
                                                deleteFunction={() => this.toggleModal('deleteModal', field)}
                                            />
                                        </Col>
                                        }
                                    </Row>
                                    <div>
                                        {customFieldSettings && customFieldSettings.dataType === "Bool" ?
                                            field.value ?
                                                field.value === "1" ? <i className="fas fa-toggle-on fa-lg color-host" /> : <i className="fas fa-toggle-off fa-lg" />
                                                : ''
                                            : field.value}
                                    </div>
                                </Card>
                            </Col>
                        })}
                    </Row>

                    {customData == null || customData.length == 0 ?
                        <DefaultPage text="ProfileDetails.NoCustomFieldsAvailable" icon="icon-icon-custom-fields" />
                        : ''}

                    {this.state.customDataModal ?
                        <CustomDataModal
                            modal={this.state.customDataModal}
                            toggleModal={() => this.toggleModal('customDataModal')}
                            selectedCustomData={this.state.selectedCustomData}
                            profileId={profileId}
                            updateTable={updateTable}
                            availableCustomData={this.getAvailableCustomData()}
                        />
                        : ''}


                    {deleteModal ?
                        <ConfirmActionModal
                            modal={deleteModal}
                            toggleModal={() => this.toggleModal('deleteModal', this.state.selectedCustomData)}
                            actionFunction={this.removeCustomData}
                            text={<FormattedMessage id="ProfileCustomData.ConfirmDeleteText" />}
                        />
                        : ''}
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(ProfileCustomData);
