import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { injectIntl } from 'react-intl';
import { Button, Card, Col, Row, Breadcrumb, BreadcrumbItem, Badge, Nav, NavLink, NavItem, TabContent, TabPane, Modal, ModalBody, Input, Form, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { StyledCard, BlankCard, KebabMenu } from "../Base/CommonUIComponents";
import { TemplateSMSValidationsHelper, TemplateValidations, TemplateValidationsHelper } from '../Base/TemplateByCountry';
import { getAPI, deleteAPI, postAPI, putAPI } from "../Base/API";
import { handleNotification } from "../Base/Notification";
import CustomSelect from '../Base/CustomSelect';
import { FormattedMessage } from "react-intl";
import BlockUi from 'react-block-ui';

import Authorization from '../Base/Authorization';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import SMSModal from './SMSModal';
import { VerificationEmailSpamModal } from './VerificationEmailSpamModal';

export class TransactionalTemplateList extends Component {

    constructor(props) {
        super(props);
        this.formEmail = React.createRef();
        this.state = {
            blockMail: false,
            blockSms: false,
            modal: false,
            previewModal: false,
            deleteModal: false,
            emailList: [],
            smsList: [],
            activeTab: '0',
            modalSMS: false,
            selectedSMSId: null,
            verificationEmailSpamModal: false,
            htmlBody: null,
            contacts: []
        };
    }

    componentDidMount() {
        this.getEmailTemplates();
        this.getSmsTemplates();
    }

    getEmailTemplates = () => {
        this.setState({ blockMail: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockMail: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ emailList: data.response && data.response.length > 0 ? data.response.sort((a, b) => b.id - a.id) : [] });
            }
            this.setState({ error: errorMessage, blockMail: false });
        }, '/api/gms/Marketing/v1/egoitransactional/template/email');
    }

    getSmsTemplates = () => {
        this.setState({ blockSms: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockSms: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                this.setState({ smsList: data.response && data.response.length > 0 ? data.response.sort((a, b) => b.id - a.id) : [] });
            }
            this.setState({ error: errorMessage, blockSms: false });
        }, '/api/gms/Marketing/v1/egoitransactional/template/sms');
    }

    removeTemplate = (e, templateId, type) => {
        e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="TransactionalTemplateList.TemplateRemoved" />, <FormattedMessage id="generic.success" />);
                    this.toggleDeleteModal();
                    if (type === 'email') this.getEmailTemplates(); else { this.getSmsTemplates(); }
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoitransactional/template/${templateId}/${type}`);
    }

    sendTest = (e) => {
        e.preventDefault();
        const { contacts, from, modalData, smsMessage } = this.state;

        this.setState({ blockModal: true });

        let contactKey;
        let textMessage;
        let type;

        if (modalData.type === 'email') {
            contactKey = 'email';
            textMessage = null;
            type = 'mail';
        }
        else {
            contactKey = 'mobile';
            textMessage = smsMessage;
            type = 'sms';
        }

        const body = contacts.map(cnt => {
            const req = {
                senderId: from,
                [contactKey]: cnt,
                templateId: modalData.templateId,
                textMessage: textMessage
            };
            return req;
        });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorModal: errorMessage, blockModal: false });
                return;
            }
            if (data) {
                if ((data.Errors && data.Errors.length > 0) || (data.errors && data.errors.length > 0)) {
                    handleNotification(data);
                }
                else if (data.response) {
                    handleNotification(data, <FormattedMessage id={`TransactionalTemplateList.${this.state.modalData.type}Sent`} />, <FormattedMessage id="generic.success" />);
                    this.toggleModal();
                }
            }
            this.setState({ errorModal: errorMessage, blockModal: false });
        }, `/api/gms/Marketing/v1/egoitransactional/${type}/sendBulk`, body);
    }

    getSenders = (senderType) => {
        this.setState({ blockModal: true });

        const type = senderType === 'sms' ? 'cellphone' : 'email';

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorModal: errorMessage, blockModal: false });
                return;
            }
            if (data) {
                if ((data.Errors && data.Errors.length > 0) || (data.errors && data.errors.length > 0)) {
                    handleNotification(data);
                }

                let senders = [];

                data.items && data.items.forEach(resp => {
                    senders.push({ value: resp.sender_id, label: `${resp.name}(${resp[type]})` });
                });

                this.setState({ blockModal: false, senders });
            }
            else {
                this.setState({ errorModal: errorMessage, blockModal: false });
            }
        }, `/api/gms/Marketing/v1/egoi/senders/${type}?offset=0&limit=100`);
    }

    toggleTab = (val) => {
        this.setState({ activeTab: val });
    }

    toggleModal = (type, templateId, templateName) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            modalData: { type: type, templateId: templateId, templateName: templateName }
        }), () => { if (this.state.modal) this.getSenders(type) });
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    handleChange = (e) => {
        const { value, name } = e.target;

        this.setState({ [name]: value });
    }

    togglePreviewModal = (template) => {
        this.setState(prevState => ({
            previewModal: !prevState.previewModal,
            selectedTemplate: template
        }));
    }

    toggleDeleteModal = (id, name, type) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
            templateToDelete: {
                id,
                name,
                type
            }
        }));
    }

    toggleSMSModal = (id) => {
        this.setState(prevState => ({
            modalSMS: !prevState.modalSMS,
            selectedSMSId: id
        }));
    }

    updateSMSList = (sms) => {
        const { smsList } = this.state;
        smsList.unshift(sms);
        this.setState({ smsList });
    }

    toggleVerificationEmailSpamModal = (htmlBody) => {
        this.setState(prevState => ({
            verificationEmailSpamModal: !prevState.verificationEmailSpamModal,
            htmlBody: htmlBody
        }));
    }

    importTemplates = () => {
        this.setState({ blockMail: true, blockSms: true });

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorModal: errorMessage, blockMail: false, blockSms: false });
                return;
            }
            if (data) {
                if ((data.Errors && data.Errors.length > 0) || (data.errors && data.errors.length > 0)) {
                    handleNotification(data);
                }
                else {
                    
                    handleNotification(data, <FormattedMessage id="TransactionalTemplateList.ImportTemplatesSuccess" />, <FormattedMessage id="generic.success" />);
                }
            }
            this.setState({ errorModal: errorMessage, blockMail: false, blockSms: false });
        }, `/api/gms/Marketing/v1/egoitransactional/template/email/import`);
    }

    addContact = () => {
        if (!this.formEmail.current.reportValidity()) {
            return;
        }

        const { to, contacts } = this.state;
        contacts.push(to);
        this.setState({ contacts, to: null });
    }

    removeContact = (idx) => {
        const { contacts } = this.state;
        contacts.splice(idx, 1);
        this.setState({ contacts });
    }

    render() {
        const { block, error, emailList, smsList, modal, senders, modalData, previewModal, selectedTemplate, deleteModal, templateToDelete, modalSMS, selectedSMSId, verificationEmailSpamModal, htmlBody, contacts, to } = this.state;
        const { intl } = this.props;

        return (

            <StyledCard block={block} error={error} >

                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/MarketingConfigEgoi"> Config</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Templates</BreadcrumbItem>
                    </Breadcrumb>
                </div>

                {modal && modalData ?
                    <Modal isOpen={modal} fade={false} size={"lg"} style={{ minWidth: 0 }} >
                        <Button onClick={this.toggleModal} className="closeModal">
                            <i className="fas fa-times fa-sm"></i>
                        </Button>
                        <ModalBody>
                            <BlankCard block={this.state.blockModal} error={this.state.errorModal}>
                                <Form onSubmit={(e) => this.sendTest(e)}>
                                    <Row className="mb-3">
                                        <Col className="col-9 pl-1">
                                            <h5>{modalData.templateName}</h5>
                                        </Col>
                                        <Col className="text-right col-3">
                                            <Button className="btn btn-host btn-sm" onClick={this.sendMessage} type="submit" disabled={contacts.length === 0}>
                                                <FormattedMessage id={modalData.type === 'email' ? "ProfileDetails.SendEmail" : "ProfileDetails.SendSms"} />
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Row className="mt-2">
                                        <Col className="col-2 px-1">
                                            <FormattedMessage id="SendEmailModal.Sender" />
                                        </Col>
                                        <Col className="col-10">
                                            <CustomSelect
                                                name="from"
                                                options={senders}
                                                onChange={this.handleSelect.bind(this, "from")}
                                                required
                                                placeholder=""
                                            />
                                        </Col>
                                    </Row>
                                </Form>
                                <form ref={this.formEmail}>
                                    <Row className="mt-2 mb-2">
                                        <Col className="col-2 px-1">
                                            <FormattedMessage id="SendEmailModal.To" />
                                        </Col>
                                        <Col className="col-9">
                                            <Input
                                                type={modalData.type === 'email' ? "email" : "text"}
                                                name='to'
                                                onChange={(e) => this.handleChange(e)}
                                                required
                                                value={to || ''}
                                            />
                                    </Col>
                                    <Col>
                                        <Button className="btn btn-host btn-sm" onClick={this.addContact}>
                                            <i class="fas fa-plus" />
                                        </Button>
                                    </Col>
                                    </Row>
                                    {
                                        contacts.map((cnt, key) =>
                                            <Row key={key} className="mb-2">
                                                <Col className="col-2" />
                                                <Col className="col-9">
                                                    <span className="ml-3">
                                                        {cnt}
                                                    </span>
                                                </Col>
                                                <Col className="col-1">
                                                    <Button className="btn btn-host btn-sm" onClick={_ => this.removeContact(key)}>
                                                        <i class="fas fa-trash-alt" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                </form>
                            </BlankCard>
                        </ModalBody>
                    </Modal>
                : ''}

                {modalSMS ?
                    <SMSModal
                        toggle={this.toggleSMSModal}
                        selectedSMSId={selectedSMSId}
                        smsList={smsList}
                        updateSMSList={this.updateSMSList}
                    />
                :''}

                {previewModal && selectedTemplate ?
                    <Modal isOpen={this.state.previewModal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                        <Button onClick={this.togglePreviewModal} className="closeModal">
                            <i className="fas fa-times fa-sm"></i>
                        </Button>
                        <ModalBody>
                            <Row>
                                <Col>
                                    <Card className="p-2 border-0 mb-3 shadow h-100">
                                        <div>
                                            <h5>{selectedTemplate.templateName}</h5>
                                            <div>{selectedTemplate.clickTracking ? <Badge className="my-2 p-2 mr-2" color="primary">Click Tracking</Badge> : ''} {selectedTemplate.openTracking ? <Badge className="my-2 p-2" color="primary">Open Tracking</Badge> : ''}</div>
                                        </div>

                                        <div dangerouslySetInnerHTML={{ __html: selectedTemplate.htmlBody }} />

                                    </Card>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                : ''}

                {
                    verificationEmailSpamModal ?
                        <VerificationEmailSpamModal isOpen={verificationEmailSpamModal} toggle={this.toggleVerificationEmailSpamModal} htmlBody={htmlBody} />
                        :
                        ''
                }

                <Nav tabs className="border-0 pb-2">
                    <NavItem >
                        <NavLink className={this.state.activeTab === '0' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => { this.toggleTab('0'); }} style={{ cursor: "pointer" }}>
                            <b> <FormattedMessage id="StatsEgoi.Email" /></b>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={this.state.activeTab === '1' ? 'tab-border-host text-host  border-0' : 'border-0'} onClick={() => { this.toggleTab('1'); }} style={{ cursor: "pointer" }}>
                            <b> <FormattedMessage id="StatsEgoi.Sms" /></b>
                        </NavLink>
                    </NavItem>
                    <span className="ml-auto text-nowrap  border-top-0 border-left-0 border-right-0 d-flex align-items-center">
                        <span>
                            <Authorization
                                perform="transactionalTemplate:importTemplates"
                                yes={() => (
                                    <UncontrolledDropdown>
                                        <DropdownToggle style={{ background: 'none', border: 'none', color: 'unset', padding: 'unset', width: '10px' }}>
                                            <i className="fas fa-ellipsis-v" />
                                        </DropdownToggle>
                                        <DropdownMenu positionFixed={true}>
                                            <DropdownItem onClick={this.importTemplates}>
                                                <i className="fas fa-download mr-2"></i>
                                                <FormattedMessage id="TransactionalTemplateList.ImportTemplates" />
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                )}
                                no={() => <span/>}
                            />
                        </span>
                        {this.state.activeTab === '0' ?
                            <TemplateValidationsHelper />
                        :
                            <>
                                <Button onClick={_ => this.toggleSMSModal()} className=" btn-host btn-sm ">
                                    <i className="fas fa-plus"/>
                                </Button>
                                <TemplateSMSValidationsHelper />
                            </>
                        }
                    </span>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="0" className="border-0 my-0 p-0 ">
                        <BlockUi tag="div" blocking={this.state.blockMail}>
                            <Row className="px-2">
                                {emailList && emailList.length > 0 ?
                                    emailList.map((el, k) =>
                                        <Col sm={3} key={k} className="mb-3 px-2">
                                            <Card className="border-0 py-2 px-3 h-100 shadow d-flex justify-content-between">
                                                <div>
                                                    <Row>
                                                        <Col><h4 className="m-0">{el.templateName}</h4></Col>

                                                        <Col className="col-1 mr-1 pl-0 d-flex align-items-center justify-content-end">
                                                            <TemplateValidations template={el} target={`TooltipEmail${k}`} />
                                                        </Col>
                                                    </Row>

                                                    <div className="mb-2 ">{el.subject}</div>

                                                    <Row className="mb-3" >
                                                        <Col>
                                                            <span>
                                                                {el.clickTracking ? <Badge className="p-2 mr-2" color="primary">Click Tracking</Badge> : ''}
                                                                {el.openTracking ? <Badge className="p-2" color="primary">Open Tracking</Badge> : ''}
                                                            </span>
                                                            <span className="float-right">
                                                                <Authorization
                                                                    perform="transactionalTemplate:spanCheck"
                                                                    yes={() => (
                                                                        <Button className="btn btn-host btn-sm" onClick={_ => this.toggleVerificationEmailSpamModal(el.htmlBody)}>
                                                                            <i className="fas fa-check" />
                                                                        </Button>
                                                                    )}
                                                                    no={() => <div></div>}
                                                                />
                                                                <Button className="btn btn-host btn-sm ml-1" onClick={() => this.togglePreviewModal(el)}>
                                                                    <i className="icon-icon-preview" />
                                                                </Button>
                                                                <Button className="btn btn-sm btn-host ml-1" onClick={() => this.toggleModal('email', el.id, el.templateName)}>
                                                                    <i className="fas fa-envelope" />
                                                                </Button>
                                                                <Authorization
                                                                    perform="transactionalTemplateList:delete"
                                                                    yes={() => (
                                                                        <Button className="btn btn-sm btn-host ml-1" onClick={() => this.toggleDeleteModal(el.id, el.templateName, 'email')}>
                                                                            <i className="far fa-trash-alt"></i>
                                                                        </Button>
                                                                    )}
                                                                    no={() => <div></div>}
                                                                />
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                {/*<div dangerouslySetInnerHTML={{ __html: el.htmlBody }} style={{ maxHeight: "300px", overflow: "auto", overflowX: "hidden" }} />*/}

                                                <div style={{ width: '100%', height: 'auto' }}>
                                                    <img src={el.thumbnail} style={{ width: 'inherit' }} />
                                                </div>
                                            </Card>
                                        </Col>
                                    )
                                    : <Col className="text-center mt-3"><FormattedMessage id="generic.NoEgoiTemplatesAvailable" /></Col>}
                            </Row>
                        </BlockUi>
                    </TabPane>
                    <TabPane tabId="1" className="border-0 my-0 p-0 ">
                        <BlockUi tag="div" blocking={this.state.blockSms}>
                            <Row className="px-2">
                                {smsList && Array.isArray(smsList) && smsList.length > 0 ?
                                    smsList.map((el, k) =>
                                        <Col sm={3} key={k} className="mb-3 px-2">
                                            <Card className="border-0 py-2 px-3 h-100 shadow d-flex justify-content-between">
                                                <div className='mt-2 text-muted' style={{ width: '100%', height: 'auto', fontSize: '0.9em' }}>
                                                    {el.text}
                                                </div>
                                                <div>
                                                    <hr className='my-2'/>
                                                    <div style={{ fontSize: '0.9em' }} className='d-flex justify-content-between align-items-center'>
                                                        <span className='mr-1'>
                                                            <FormattedMessage id="TransactionalTemplateList.Enconding"/>:
                                                            <span className='ml-1'>{el.encoding??'GSM0338'}</span>
                                                        </span>
                                                        <span>
                                                            <FormattedMessage id="TransactionalTemplateList.MaxCount"/>:
                                                            <span className='ml-1'>{el.maxCount??'1'}</span>
                                                        </span>
                                                    </div>
                                                    <Row className='my-2'>
                                                        <Col className='d-flex align-items-end'>
                                                            <h4 className="m-0">{el.templateName}</h4>
                                                            <span className='ml-2'>
                                                                <TemplateValidations isPhone={true} template={el} target={`TooltipSms${k}`} />
                                                            </span>
                                                        </Col>
                                                        <Col className='col-2 d-flex align-items-end justify-content-end'>
                                                            <KebabMenu
                                                                editFunction={() => this.toggleSMSModal(el.id)}
                                                                // deleteFunction={() => this.toggleDeleteModal(el.id, el.templateName, 'sms')}
                                                                extraFields={[
                                                                    {
                                                                        function: () => this.toggleModal('sms', el.id, el.templateName),
                                                                        icon: 'fas fa-sms mr-2',
                                                                        text: 'TransactionalTemplateList.SendTest'
                                                                    }
                                                                ]}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Card>
                                        </Col>
                                    )
                                    : <h6 className="text-center mt-3"><FormattedMessage id="generic.NoEgoiTemplatesAvailable" /></h6>}
                            </Row>
                        </BlockUi>
                    </TabPane>
                </TabContent>


                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={this.toggleDeleteModal}
                        actionFunction={(e) => this.removeTemplate(e, templateToDelete.id, templateToDelete.type)}
                        text={<FormattedMessage id="TransactionalTemplateList.ConfirmDeleteText" values={{ templateName: templateToDelete.name }} />}
                        block={block}
                    />
                : ''}

            </StyledCard>
        );
    }
}
export default injectIntl(TransactionalTemplateList)
