import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Card } from 'reactstrap';
import { KebabMenu, StyledCard, CommonHelper } from "../../../Base/CommonUIComponents";
import { getAPI, patchAPI } from '../../../Base/API';
import { handleNotification } from "../../../Base/Notification";
import SubscriptionModal from './SubscriptionModal';
import GoogleAnalyticsModal from './GoogleAnalyticsModal';
import { SettingStatus } from '../SendGridFunctions';
import Authorization from '../../../Base/Authorization';

class Tracking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            trackingObjects: [],
            analyticsObj: [],
            subscriptionObj: [],
            openAnalyticsModal: false,
            openSubscriptionModal: false,
        };
    }

    componentDidMount() {
        this.getTrackingObjects();
    }

    getTrackingObjects = () => {
        this.setState({block: true}, () =>
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data && data.response) {

                    const order = [
                        "Click Tracking",
                        "Open Tracking",
                        "Subscription Tracking",
                        "Google Analytics"
                    ];
                    const orderedResult = data.response.result.sort((a, b) => {
                        return order.indexOf(a.title) - order.indexOf(b.title);
                    });

                    this.setState({ trackingObjects: orderedResult }, () => this.getSubscriptionObj());
                    
                }else{
                    this.setState({ error: errorMessage, block: false });
                }
                
            }, `/api/gms/SendGrid/v1/tracking`)
        );
        
    }

    updateClickObjStatus = (enabled) => {
        this.setState({block: true}, () =>
            patchAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    this.getTrackingObjects();
                    handleNotification(<FormattedMessage id="SendGrid.success" />, 'success');
                    this.setState({ block: false });
                }
            }, `/api/gms/SendGrid/v1/tracking/click${enabled !== null && enabled !== null ? `?enabled=${enabled}` : ''}`)
        );
    }

    updateOpenObjStatus = (enabled) => {
        this.setState({block: true}, () =>
            patchAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    this.getTrackingObjects();
                    handleNotification(<FormattedMessage id="SendGrid.success" />, 'success');
                    this.setState({ block: false });
                }
            }, `/api/gms/SendGrid/v1/tracking/open${enabled !== null && enabled !== null ? `?enabled=${enabled}` : ''}`)
        );
    }

    updateAnalyticsObjStatus = (enabled, utm_campaign, utm_content, utm_medium, utm_source, utm_term) => {
        this.setState({block: true}, () => {
            var body = {
                "enabled": enabled !== null && enabled !== undefined ? enabled : null,
                "campaign": utm_campaign ,
                "content": utm_content,
                "medium": utm_medium,
                "source": utm_source,
                "term": utm_term
            }

            patchAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    this.setState({ analyticsObj: data.response, block: false  }, () => this.getTrackingObjects());
                }
            }, `/api/gms/SendGrid/v1/tracking/google`, body)
        });
    }

    getAnalyticsObj = () => {

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response) {
                this.setState({ analyticsObj: data.response, block: false });
            }else{
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/SendGrid/v1/tracking/google`);
    }

    updateSubscriptionObjStatus = (enabled, html, landing, plain, replace, url) => {

        this.setState({block: true}, () => {
            var body = {
                "enabled": enabled !== null && enabled !== undefined ? enabled : null,
                "html": html.replace(/<[^>]*>/g, '').trim() !== '' ? html : null,
                "landing": landing.replace(/<[^>]*>/g, '').trim() !== '' ? landing : null,
                "plain": plain !== '' ? plain : null,
                "replace": replace !== '' ? replace : null,
                "url": url !== '' ? url : null
            }

            patchAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    this.setState({ subscriptionObj: data.response, block: false }, () => this.getTrackingObjects());
                    handleNotification(<FormattedMessage id="SendGrid.success" />, 'success');
                }
            }, `/api/gms/SendGrid/v1/tracking/subscription`, body)
        });
    }

    getSubscriptionObj = () => {

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response) {
                this.setState({ subscriptionObj: data.response }, () => this.getAnalyticsObj());
            }else{
                this.setState({ error: errorMessage, block: false });
            }
           
        }, `/api/gms/SendGrid/v1/tracking/subscription`);
    }

    toggleAnalyticsModal = () => {
        this.setState(prevstate => ({ openAnalyticsModal: !prevstate.openAnalyticsModal }));
    }

    toggleSubscriptionModal = () => {
        this.setState(prevstate => ({ openSubscriptionModal: !prevstate.openSubscriptionModal }));
    }

    render() {
        const { block, error, trackingObjects, openAnalyticsModal, analyticsObj, openSubscriptionModal } = this.state;
        const { page } = this.props;
        return (
            <StyledCard block={block} error={error} >
                <Row className="mb-4 py-1">
                    <Col>
                        <h5> <i className={page?.icon} /> {page?.name} </h5>
                    </Col>
                    <Col>
                        <Authorization
                            perform="admin:view"
                            yes={() => (
                                <CommonHelper help={<FormattedMessage id="SendGrid.TrackingHelp" />} id={'trackingHelp'}  />
                            )}
                            no={() => <div></div>}
                        />
                    </Col>
                </Row>
                <>
                    <Row className='mb-2 mt-3 px-4'>
                        <Col className='col-1 text-muted pl-3'>
                            <FormattedMessage id="SendGrid.Status"/>
                        </Col>
                        <Col className='text-muted pl-3 col-2'>
                            <FormattedMessage id="SendGrid.Setting"/>
                        </Col>
                        <Col className='text-muted pl-3 col-2'>
                            <FormattedMessage id="SendGrid.Description"/>
                        </Col>
                    </Row>
                    {trackingObjects && trackingObjects.map((obj, key) =>
                        <Card className={`mt-2 p-4 shadow`} key={key}>
                            <Row className='align-items-center'>
                                <Col className='col-1 pr-0'>
                                    <SettingStatus status={obj.enabled} />
                                </Col>
                                <Col className='col-2'>
                                    <div className='d-flex align-items-center '>
                                        {obj.title}
                                    </div>
                                </Col>
                                <Col>
                                    <div className='d-flex align-items-center'>
                                        <FormattedMessage id={`SendGrid.${obj.title.split(' ')[0]}Description`}/>
                                    </div>
                                </Col>
                                <Col className='col-1 text-right' key={key}>
                                    {obj.name === 'click' || obj.name === 'open' ?
                                        <KebabMenu
                                            extraFields={obj.enabled !== undefined && obj.enabled !== undefined ? [{
                                                function: _ => {obj.name === 'click'
                                                    ? this.updateClickObjStatus(!obj.enabled)
                                                    : this.updateOpenObjStatus(!obj.enabled)},
                                                icon: "far fa-check-square mr-2",
                                                text: obj.enabled === true ? "SendGrid.Disable" : "SendGrid.Enable"
                                            }]: null}
                                        />
                                    : obj.name === 'google_analytics' ?
                                        <KebabMenu
                                            extraFields={[{
                                                function: _ => {this.toggleAnalyticsModal()},
                                                icon: "far fa-edit mr-2",
                                                text: "SendGrid.Edit"
                                            }]}
                                        />
                                    : obj.name === 'subscription' ?
                                        <KebabMenu
                                            extraFields={[{
                                                function: _ => {this.toggleSubscriptionModal()},
                                                icon: "far fa-edit mr-2",
                                                text: "SendGrid.Edit"
                                            }]}
                                        />
                                    :
                                        ''
                                    }
                                </Col>
                            </Row>
                        </Card>
                    )}
                </>
                {openAnalyticsModal ?
                    <GoogleAnalyticsModal
                        toggle={_ => this.toggleAnalyticsModal()}
                        updateAnalyticsObjStatus={this.updateAnalyticsObjStatus}
                        analyticsObj={analyticsObj}
                    />
                :
                    ''
                }
                {openSubscriptionModal ?
                    <SubscriptionModal
                        toggle={_ => this.toggleSubscriptionModal()}
                        updateSubscriptionObjStatus={this.updateSubscriptionObjStatus}
                        subscriptionObj={this.state.subscriptionObj}
                    />
                :
                    ''
                }
            </StyledCard>
        )
    }
}

export default injectIntl(Tracking)
