import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, Col, Row } from 'reactstrap';
import CustomSelect from '../Base/CustomSelect';
import moment from 'moment-timezone';
import { ExportToPDFButton, FormatAmountNumber } from '../Base/CommonUIComponents';
import { getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { ErrorAlert } from '../Base/ErrorAlert';

class SalesProcessExecutiveReportMonthly extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            block: true,
            monthlyStats: null,
            hotelReport: null,
            selectedCurrency: "EUR",
            metrics: [
                {
                    name: "numberOfSpaces",
                    type: "number"
                },
                {
                    name: "numberOfLodging",
                    type: "number"
                },
                {
                    name: "adr",
                    type: "value"
                },
                {
                    name: "lodging",
                    type: "value"
                },
                {
                    name: "fb",
                    type: "value"
                },
                {
                    name: "banquets",
                    type: "value"
                },
                {
                    name: "spaceRental",
                    type: "value"
                },
                {
                    name: "misc",
                    type: "value"
                },
                {
                    name: "totalValue",
                    type: "value"
                }
            ]
        };
    }

    componentDidMount(){
        this.getHotelMonthlyMetricsPrevYear();
    }

    getHotelMonthlyMetricsPrevYear = () => {
        const { selectedHotel, year } = this.props;
        const { selectedCurrency } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.data?.length) {
                    this.setState({ prevYear: data.data }, () =>
                        this.getHotelMonthlyMetrics())
                    return;
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/executivereport/monthly?year=${year - 1}&hotelId=${selectedHotel}&needBudget=false&noCambio=${selectedCurrency !== "EUR"}`);
    }

    getHotelMonthlyMetrics = () => {
        const { selectedHotel, year } = this.props;
        const { selectedCurrency } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.data?.length) {
                        this.initialCalc(data, selectedHotel);
                    return;
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/executivereport/monthly?year=${year}&hotelId=${selectedHotel}&noCambio=${selectedCurrency !== "EUR"}`);
    }
    
    initialCalc = (data, selectedHotel) => {
        const fields = ['numberOfSpaces', 'numberOfLodging', 'adr', 'lodging', 'fb', 'banquets', 'spaceRental', 'misc', 'totalValue'];
        const { prevYear } = this.state;

        const monthlyStats = data.data.map((m, idx) => {
            const definitiveStage = m.hotels[0]?.stagesTypes.find(el => el.isDef);
            const definitiveStagePrev = prevYear[idx]?.hotels[0]?.stagesTypes.find(el => el.isDef);

            const lodging = definitiveStage?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.lodging, 0) ?? 0;
            const numberOfLodging = definitiveStage?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.numberOfLodging, 0) ?? 0;

            const adr = lodging && numberOfLodging ? (Math.round((lodging / numberOfLodging) * 100) / 100) : null;

            const lodgingPrev = definitiveStagePrev?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.lodging, 0) ?? 0;
            const numberOfLodgingPrev = definitiveStagePrev?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.numberOfLodging, 0) ?? 0;

            const adrPrev = lodgingPrev && numberOfLodgingPrev ? (Math.round((lodgingPrev / numberOfLodgingPrev) * 100) / 100) : null;

            const prev = {
                adr: adrPrev,
                lodging: lodgingPrev,
                numberOfLodging: numberOfLodgingPrev,
                fb: definitiveStagePrev?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.fb, 0) ?? 0,
                banquets: definitiveStagePrev?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.banquets, 0) ?? 0,
                misc: definitiveStagePrev?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.misc, 0) ?? 0,
                spaceRental: definitiveStagePrev?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.spaceRental, 0) ?? 0,
                totalValue: definitiveStagePrev?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.totalValue, 0) ?? 0,
                numberOfSpaces: definitiveStagePrev?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.numberOfSpaces, 0) ?? 0,
            }

            const budgetLodging = m.hotels[0]?.budget.find(el => el.salesGroup === 'ROOM')?.value;
            const budgetNumberOfLodging = m.hotels[0]?.budget.find(el => el.salesGroup === 'ROOM')?.roomNights;

            const budget = m.hotels[0]?.budget?.length > 0 ? {
                adr: budgetLodging && budgetNumberOfLodging ? (Math.round((budgetLodging / budgetNumberOfLodging) * 100) / 100) : null,
                lodging: budgetLodging,
                numberOfLodging: budgetNumberOfLodging,
                fb: m.hotels[0].budget.find(el => el.salesGroup === 'FB')?.value,
                banquets: m.hotels[0].budget.find(el => el.salesGroup === 'FB')?.value,
                misc: m.hotels[0].budget.find(el => el.salesGroup === 'ETC')?.value,
                spaceRental: m.hotels[0].budget.find(el => el.salesGroup === 'BANQ')?.value,
                totalValue: m.hotels[0].budget.reduce((acc, cur) => acc + cur.value, 0),
                numberOfSpaces: m.hotels[0].budget.find(el => el.salesGroup === 'BANQ')?.roomNights,
            }
                : null


            const object = {
                ...m,
                ...m.deals,
                adr,
                lodging,
                numberOfLodging,
                fb: definitiveStage?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.fb, 0) ?? 0,
                banquets: definitiveStage?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.banquets, 0) ?? 0,
                misc: definitiveStage?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.misc, 0) ?? 0,
                spaceRental: definitiveStage?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.spaceRental, 0) ?? 0,
                totalValue: definitiveStage?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.totalValue, 0) ?? 0,
                numberOfSpaces: definitiveStage?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.numberOfSpaces, 0) ?? 0,
                budget,
                prev
            }


            object.diff = fields.reduce((acc, cur) => {
                if (object.budget && object.budget[cur] && object.budget[cur] != null) {
                    acc[cur] = object[cur] - object.budget[cur];
                }
                else acc[cur] = 0;

                return acc;
            }, {})


            return object;
        });

        const hotelName = global.hotelList.find(({ value }) => value == selectedHotel)?.label;
        const hotelCurrency = global.hotelList.find(x => x.value == selectedHotel)?.currency;
        const currencyList = [{ value: "EUR", label: "EUR"}];
        if (hotelCurrency !== "EUR") {
            currencyList.push({ value: hotelCurrency, label: hotelCurrency })
        }

        const qData = {
            q1Months: monthlyStats
                .filter(m =>
                    parseInt(moment(m.startDate).format('MM')) <= 3
                ),
            q2Months: monthlyStats
                .filter(m =>
                    (parseInt(moment(m.startDate).format('MM')) > 3) && (parseInt(moment(m.startDate).format('MM')) <= 6)
                ),
            q3Months: monthlyStats
                .filter(m =>
                    (parseInt(moment(m.startDate).format('MM')) > 6) && (parseInt(moment(m.startDate).format('MM')) <= 9)
                ),
            q4Months: monthlyStats
                .filter(m =>
                    parseInt(moment(m.startDate).format('MM')) > 9
                ),
        };

        const quarterData = Object.keys(qData).map((k, idx) => {
            const quarter = qData[k];

            const numberOfLodging = quarter.reduce((a, b) => a + b.numberOfLodging, 0);
            const lodging = quarter.reduce((a, b) => a + b.lodging, 0);
            const adr = lodging && numberOfLodging ? (Math.round((lodging / numberOfLodging) * 100) / 100) : null;
            const fb = quarter.reduce((a, b) => a + b.fb, 0);
            const banquets = quarter.reduce((a, b) => a + b.banquets, 0);
            const spaceRental = quarter.reduce((a, b) => a + b.spaceRental, 0);
            const misc = quarter.reduce((a, b) => a + b.misc, 0);
            const numberOfSpaces = quarter.reduce((a, b) => a + b.numberOfSpaces, 0);
            const totalValue = quarter.reduce((a, b) => a + b.totalValue, 0);


            const prev = quarter.some(el => el.prev) ?
                quarter.reduce((acc, current) => {
                    if (current.prev) {
                        Object.keys(current.prev).forEach((key) => {
                            if (!acc[key]) acc[key] = 0;

                            acc[key] += current.prev[key] ?? 0;
                        });
                    }

                    return acc;
                }, {})
                : null;

            const budget = quarter.some(el => el.budget) ?
                quarter.reduce((acc, current) => {
                    if (current.budget) {
                        Object.keys(current.budget).forEach((key) => {
                            if (!acc[key]) acc[key] = 0;

                            acc[key] += current.budget[key] ?? 0;
                        });
                    }

                    return acc;
                }, {})
            : null;

            if(budget && budget.adr){
                budget.adr = budget.adr / (quarter.reduce((a, b) => a + (b.budget?.adr ? 1 : 0), 0));
            }

            const object = {
                idx: idx + 1,
                monthlyData: quarter,
                quarterData: {
                    numberOfLodging,
                    lodging,
                    adr,
                    fb,
                    banquets,
                    spaceRental,
                    misc,
                    numberOfSpaces,
                    totalValue,
                    budget,
                    prev
                }
            }

            object.quarterData.diff = fields.reduce((acc, cur) => {
                if (object.quarterData.budget && object.quarterData.budget[cur] && object.quarterData.budget[cur] !== null) {
                    acc[cur] = object.quarterData[cur] - object.quarterData.budget[cur];
                }
                else acc[cur] = 0;

                return acc;
            }, {});


            return object;
        });


        const hotelReport = this.hotelReportCalc(monthlyStats, fields);

        this.setState({ block: false, quarterData, hotelName, hotelReport, hotelCurrency, currencyList });
    }

    hotelReportCalc = (monthlyStats, fields) => {
        const dealFields = ['numberOfDeals', 'numberOfWonDeals', 'numberOfLostDeals', 'proposalValueTotal', 'proposalValueWon', 'proposalValueLost'];
        
        const hotelReport = {
            budget: {},
            prev: {}
        };

        monthlyStats.forEach(ms => {
            //calc deal totals
            dealFields.forEach(df => {
                if (!hotelReport[df]) hotelReport[df] = 0;
                hotelReport[df] += ms.deals[df] ?? 0;
            })

            //calc actual and budget 
            fields.forEach(f => {
                if (!hotelReport[f]) hotelReport[f] = 0;
                hotelReport[f] += ms[f] ?? 0;

                if (ms.budget) {
                    if (!hotelReport.budget[f]) hotelReport.budget[f] = 0;
                    hotelReport.budget[f] += ms.budget[f] ?? 0;
                }

                if (ms.prev) {
                    if (!hotelReport.prev[f]) hotelReport.prev[f] = 0;
                    hotelReport.prev[f] += ms.prev[f] ?? 0;
                }
            })
        })

        hotelReport.budget.adr = hotelReport.budget.lodging && hotelReport.budget.numberOfLodging ? (Math.round((hotelReport.budget.lodging / hotelReport.budget.numberOfLodging) * 100) / 100) : null;
        hotelReport.prev.adr = hotelReport.prev.lodging && hotelReport.prev.numberOfLodging ? (Math.round((hotelReport.prev.lodging / hotelReport.prev.numberOfLodging) * 100) / 100) : null;
        hotelReport.adr = hotelReport.lodging && hotelReport.numberOfLodging ? (Math.round((hotelReport.lodging / hotelReport.numberOfLodging) * 100) / 100) : null;

        hotelReport.diff = fields.reduce((acc, cur) => {
            if (hotelReport.budget[cur] && hotelReport.budget[cur] !== null) {
                acc[cur] = hotelReport[cur] - hotelReport.budget[cur];
            }
            else acc[cur] = 0;

            return acc
        }, {});



        return hotelReport;
    }

    handleYearChange = (combo) => {
        this.props.handleChangeYear(combo, () => { this.setState({ block: true }, () => this.getHotelMonthlyMetricsPrevYear())});
    }

    handleSelectedCurrency = (combo) => {
        this.setState({ selectedCurrency: combo.value }, () => this.getHotelMonthlyMetrics())
    }

    downLoadFormatAmountNumber = (value, currency) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value).replaceAll(",", "");
    };

    downloadData = () => {
        const { hotelReport, hotelName, quarterData, selectedCurrency } = this.state;
        const { year } = this.props;

        let csv = `${hotelName} Report - ${year}\n\n`;

        csv += 'Yearly Stats\n\n';

        csv += `Deal Type,Nr,Value (�)\n`;
        csv += `Deals,${hotelReport.numberOfDeals},${this.downLoadFormatAmountNumber(hotelReport.proposalValueTotal, selectedCurrency)}\n`
        csv += `Negotiation Deals,${hotelReport.numberOfDeals - hotelReport.numberOfLostDeals - hotelReport.numberOfWonDeals},${this.downLoadFormatAmountNumber(hotelReport.proposalValueTotal - hotelReport.proposalValueLost - hotelReport.proposalValueLost, selectedCurrency)}\n`
        csv += `Lost Deals,${hotelReport.numberOfLostDeals},${this.downLoadFormatAmountNumber(hotelReport.proposalValueLost, selectedCurrency)}\n`
        csv += `Won Deals,${hotelReport.numberOfWonDeals},${this.downLoadFormatAmountNumber(hotelReport.proposalValueWon, selectedCurrency)}\n`
        csv += '\n\n';


        csv += 'Metrics, Prev., Budget, Actual, Diff.\n';
        csv += `Number Of Spaces, ${hotelReport.prevNumberOfSpaces ?? '0'}, ${hotelReport.budgetNumberOfSpaces ?? '0'}, ${hotelReport.numberOfSpaces ?? '0'}, ${hotelReport.diffNumberOfSpaces ?? '0'}\n`;
        csv += `Number Of Rooms, ${hotelReport.prevNumberOfLodging ?? '0'}, ${hotelReport.budgetNumberOfLodging ?? '0'}, ${hotelReport.numberOfLodging ?? '0'}, ${hotelReport.diffNumberOfLodging ?? '0'}\n`;
        csv += `ADR, ${this.downLoadFormatAmountNumber(hotelReport.prevAdr ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(hotelReport.budgetAdr ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(hotelReport.adr ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(hotelReport.diffAdr ?? 0, selectedCurrency)}\n`;
        csv += `Lodging, ${this.downLoadFormatAmountNumber(hotelReport.prevLodging ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(hotelReport.budgetLodging ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(hotelReport.lodging ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(hotelReport.diffLodging ?? 0, selectedCurrency)}\n`;
        csv += `F&B, ${this.downLoadFormatAmountNumber(hotelReport.prevFb ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(hotelReport.budgetFb ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(hotelReport.fb ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(hotelReport.diffFb ?? 0, selectedCurrency)}\n`;
        csv += `Banquets, ${this.downLoadFormatAmountNumber(hotelReport.prevbanquets ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(hotelReport.budgetbanquets ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(hotelReport.banquets ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(hotelReport.diffbanquets ?? 0, selectedCurrency)}\n`;
        csv += `Space Rental, ${this.downLoadFormatAmountNumber(hotelReport.prevSpaceRental ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(hotelReport.budgetSpaceRental ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(hotelReport.spaceRental ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(hotelReport.diffSpaceRental ?? 0, selectedCurrency)}\n`;
        csv += `Misc, ${this.downLoadFormatAmountNumber(hotelReport.prevMisc ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(hotelReport.budgetMisc ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(hotelReport.misc ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(hotelReport.diffMisc ?? 0, selectedCurrency)}\n`;
        csv += `Total Value, ${this.downLoadFormatAmountNumber(hotelReport.prevTotalValue ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(hotelReport.budgetTotalValue ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(hotelReport.totalValue ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(hotelReport.diffTotalValue ?? 0, selectedCurrency)}\n`;
        csv += '\n\n';


        csv += 'Quarterly Stats \n\n';

        quarterData.forEach(q => {
             csv += `Quarter ${q.idx}\n`;
            var month1 = moment(q.monthlyData[0].startDate).format('MMMM');
            var month2 = moment(q.monthlyData[0].startDate).add(1, 'month').format('MMMM');
            var month3 = moment(q.monthlyData[0].startDate).add(2, 'month').format('MMMM');
            csv += `Metrics, ${month1} Prev., ${month1} Budget, ${month1} Actual, ${month1} Diff.,`;
            csv += `${month2} Prev., ${month2} Budget, ${month2} Actual, ${month2} Diff.,`;
            csv += `${month3} Prev., ${month3} Budget, ${month3} Actual, ${month3} Diff.,`;
            csv += 'Total\n';

            csv += `Number Of Spaces, ${q.monthlyData[0].prevSpaces ?? '0'}, ${q.monthlyData[0].budgetSpaces ?? '0'}, ${q.monthlyData[0].numberOfSpaces ?? '0'}, ${q.monthlyData[0].diffSpaces ?? '0'}, `
            csv += `${q.monthlyData[1].prevSpaces ?? '0'}, ${q.monthlyData[1].budgetSpaces ?? '0'}, ${q.monthlyData[1].numberOfSpaces ?? '0'}, ${q.monthlyData[1].diffSpaces ?? '0'}, `
            csv += `${q.monthlyData[2].prevSpaces ?? '0'}, ${q.monthlyData[2].budgetSpaces ?? '0'}, ${q.monthlyData[2].numberOfSpaces ?? '0'}, ${q.monthlyData[2].diffSpaces ?? '0'}, `
            csv += `${q.quarterData.numberOfSpaces ?? '0'}\n`;

            csv += `Number Of Rooms, ${q.monthlyData[0].prevRooms ?? '0'}, ${q.monthlyData[0].budgetRooms ?? '0'}, ${q.monthlyData[0].numberOfLodging ?? '0'}, ${q.monthlyData[0].diffRooms ?? '0'}, `
            csv += `${q.monthlyData[1].prevRooms ?? '0'}, ${q.monthlyData[1].budgetRooms ?? '0'}, ${q.monthlyData[1].numberOfLodging ?? '0'}, ${q.monthlyData[1].diffRooms ?? '0'}, `
            csv += `${q.monthlyData[2].prevRooms ?? '0'}, ${q.monthlyData[2].budgetRooms ?? '0'}, ${q.monthlyData[2].numberOfLodging ?? '0'}, ${q.monthlyData[2].diffRooms ?? '0'}, `
            csv += `${q.quarterData.numberOfLodging ?? '0'}\n`;

            csv += `ADR, ${this.downLoadFormatAmountNumber(q.monthlyData[0].prevAdr ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[0].budgetAdr ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[0].adr ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[0].diffAdr ?? 0, selectedCurrency)}, `
            csv += `${this.downLoadFormatAmountNumber(q.monthlyData[1].prevAdr ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[1].budgetAdr ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[1].adr ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[1].diffAdr ?? 0, selectedCurrency)}, `
            csv += `${this.downLoadFormatAmountNumber(q.monthlyData[2].prevAdr ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[2].budgetAdr ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[2].adr ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[2].diffAdr ?? 0, selectedCurrency)}, `
            csv += `${this.downLoadFormatAmountNumber(q.quarterData.adr ?? 0, selectedCurrency)}\n`;

            csv += `Lodging, ${this.downLoadFormatAmountNumber(q.monthlyData[0].prevLodging ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[0].budgetLodging ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[0].lodging ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[0].diffLodging ?? 0, selectedCurrency)}, `
            csv += `${this.downLoadFormatAmountNumber(q.monthlyData[1].prevLodging ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[1].budgetLodging ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[1].lodging ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[1].diffLodging ?? 0, selectedCurrency)}, `
            csv += `${this.downLoadFormatAmountNumber(q.monthlyData[2].prevLodging ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[2].budgetLodging ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[2].lodging ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[2].diffLodging ?? 0, selectedCurrency)}, `
            csv += `${this.downLoadFormatAmountNumber(q.quarterData.lodging ?? 0, selectedCurrency)}\n`;

            csv += `F&B, ${this.downLoadFormatAmountNumber(q.monthlyData[0].prevFb ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[0].budgetFb ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[0].fb ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[0].diffFb ?? 0, selectedCurrency)}, `
            csv += `${this.downLoadFormatAmountNumber(q.monthlyData[1].prevFb ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[1].budgetFb ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[1].fb ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[1].diffFb ?? 0, selectedCurrency)}, `
            csv += `${this.downLoadFormatAmountNumber(q.monthlyData[2].prevFb ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[2].budgetFb ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[2].fb ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[2].diffFb ?? 0, selectedCurrency)}, `
            csv += `${this.downLoadFormatAmountNumber(q.quarterData.fb ?? 0, selectedCurrency)}\n`;

            csv += `Banquets, ${this.downLoadFormatAmountNumber(q.monthlyData[0].prevbanquets ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[0].budgetbanquets ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[0].banquets ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[0].diffbanquets ?? 0, selectedCurrency)}, `
            csv += `${this.downLoadFormatAmountNumber(q.monthlyData[1].prevbanquets ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[1].budgetbanquets ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[1].banquets ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[1].diffbanquets ?? 0, selectedCurrency)}, `
            csv += `${this.downLoadFormatAmountNumber(q.monthlyData[2].prevbanquets ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[2].budgetbanquets ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[2].banquets ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[2].diffbanquets ?? 0, selectedCurrency)}, `
            csv += `${this.downLoadFormatAmountNumber(q.quarterData.banquets ?? 0, selectedCurrency)}\n`;

            csv += `Space Rental, ${this.downLoadFormatAmountNumber(q.monthlyData[0].prevSpaceRental ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[0].budgetSpaceRental ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[0].spaceRental ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[0].diffSpaceRental ?? 0, selectedCurrency)}, `
            csv += `${this.downLoadFormatAmountNumber(q.monthlyData[1].prevSpaceRental ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[1].budgetSpaceRental ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[1].spaceRental ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[1].diffSpaceRental ?? 0, selectedCurrency)}, `
            csv += `${this.downLoadFormatAmountNumber(q.monthlyData[2].prevSpaceRental ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[2].budgetSpaceRental ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[2].spaceRental ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[2].diffSpaceRental ?? 0, selectedCurrency)}, `
            csv += `${this.downLoadFormatAmountNumber(q.quarterData.spaceRental ?? 0, selectedCurrency)}\n`;

            csv += `Misc, ${this.downLoadFormatAmountNumber(q.monthlyData[0].prevMisc ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[0].budgetMisc ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[0].misc ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[0].diffMisc ?? 0, selectedCurrency)}, `
            csv += `${this.downLoadFormatAmountNumber(q.monthlyData[1].prevMisc ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[1].budgetMisc ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[1].misc ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[1].diffMisc ?? 0, selectedCurrency)}, `
            csv += `${this.downLoadFormatAmountNumber(q.monthlyData[2].prevMisc ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[2].budgetMisc ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[2].misc ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[2].diffMisc ?? 0, selectedCurrency)}, `
            csv += `${this.downLoadFormatAmountNumber(q.quarterData.misc ?? 0, selectedCurrency)}\n`;

            csv += `Total Value, ${this.downLoadFormatAmountNumber(q.monthlyData[0].prevTotalValue ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[0].budgetTotalValue ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[0].totalValue ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[0].diffTotalValue ?? 0, selectedCurrency)}, `
            csv += `${this.downLoadFormatAmountNumber(q.monthlyData[1].prevTotalValue ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[1].budgetTotalValue ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[1].totalValue ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[1].diffTotalValue ?? 0, selectedCurrency)}, `
            csv += `${this.downLoadFormatAmountNumber(q.monthlyData[2].prevTotalValue ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[2].budgetTotalValue ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[2].totalValue ?? 0, selectedCurrency)}, ${this.downLoadFormatAmountNumber(q.monthlyData[2].diffTotalValue ?? 0, selectedCurrency)}, `
            csv += `${this.downLoadFormatAmountNumber(q.quarterData.totalValue ?? 0, selectedCurrency)}\n`;

            csv += '\n\n'; 
        });


        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',`${hotelName} Report - ${year}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    

    render() {
        const { error, block, quarterData, hotelName, hotelReport, metrics, hotelCurrency, selectedCurrency, currencyList } = this.state;
        const { toggleTab, intl, year, yearOptions, selectedHotel } = this.props;

        return (
            <div className="border-0 h-100 pb-2">
                <Card id='executiveReportMonthlyPdf' className='border-0 shadow p-3 bg-white' style={{ minHeight: '90vh' }}>
                    <ErrorAlert error={error}/>
                    <BlockUi tag="div" blocking={block}>
                        <div>
                            <Row className='d-flex align-items-center justify-content-between'>
                                <Col className='d-flex align-items-center justify-content-start col-7'>
                                    <div onClick={_ => toggleTab("SalesProcessExecutiveReportYearly")} className='p-3 cursor-pointer'>
                                        <i className='fas fa-chevron-left'/>
                                    </div>
                                    <div className='ml-2'>
                                        <div>
                                            <h5 className='m-0'>
                                                <b className='mr-2'>{hotelName}</b>
                                                <FormattedMessage id="SalesProcess.Report"/>
                                            </h5>
                                        </div>
                                    </div>
                                </Col>
                                <Col className='col-5 d-flex align-items-center justify-content-end'>
                                    {hotelCurrency !== "EUR" ?
                                        <div style={{ minWidth: '150px' }}>
                                            <CustomSelect
                                                options={currencyList}
                                                value={currencyList?.find(({ value }) => value === selectedCurrency)}
                                                onChange={this.handleSelectedCurrency}
                                                placeholder={intl.formatMessage({ id: "Account.Currency" })}
                                            />
                                        </div>
                                        : ''
                                    }
                                    <div style={{ minWidth: '150px' }} className='ml-4'>
                                        <CustomSelect
                                            options={yearOptions}
                                            value={yearOptions.find(({value}) => value === year)}
                                            isSearchable={true}
                                            onChange={this.handleYearChange}
                                            placeholder={intl.formatMessage({ id: "SalesProcess.Year" })}
                                        />
                                    </div>
                                    <div className='ml-4 d-flex align-items-center'>
                                        <div className="mr-2">
                                            <ExportToPDFButton name="executiveReportMonthlyPdf" fromPage="executiveReportHotel" />
                                        </div>
                                        <Button className="btn btn-host btn-sm" onClick={this.downloadData}>
                                            <i className="fas fa-file-download"/>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <hr className='mt-2 mb-4'/>
                            <div>
                                <div className='pt-1'>
                                    <h5 className='m-0'>
                                        <i className="mr-2 fas fa-calendar-alt"/>
                                        <FormattedMessage id="SalesProcess.YearlyStats"/>
                                    </h5>
                                </div>
                                <Row className='mt-4'>
                                    <Col className='col-12'>
                                        <Row style={{ fontSize: '0.9em' }}>
                                            <Col className='col-3'>
                                                <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px' }} onClick={_ => toggleTab("SalesDealList", selectedHotel, null, null, true)}>
                                                    <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                        <i className={`fas fa-briefcase mr-2`}/>
                                                        <FormattedMessage id='SalesProcess.TotalDeals'/>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                        <div>
                                                            {hotelReport?.numberOfDeals??'0'}
                                                        </div>
                                                        <div>
                                                            <FormatAmountNumber
                                                                value={hotelReport?.proposalValueTotal ?? '0'}
                                                                currency={selectedCurrency}
                                                                hideDecimals={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col className='col-3'>
                                                <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px', color: '#0665ff' }} onClick={_ => toggleTab("SalesDealList", selectedHotel, null, null, true, null, ["WaitingHotel", "WaitingClient"])}>
                                                    <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                        <i className={`fas fa-people-arrows mr-2`}/>
                                                        <FormattedMessage id='SalesProcess.NegotiationDeals'/>
                                                    </div>
                                                    {(() => {
                                                        const number = (hotelReport?.numberOfDeals??0) - ((hotelReport?.numberOfLostDeals??0) + (hotelReport?.numberOfWonDeals??0));

                                                        const lost = hotelReport?.proposalValueLost??0;
                                                        const won = hotelReport?.proposalValueWon??0;
                                                        const total = hotelReport?.proposalValueTotal??0;
                                                        const wonAndLost = won + lost;
                                                        const value = total - wonAndLost;

                                                        return (
                                                            <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                                <div>
                                                                    {number}
                                                                </div>
                                                                <div>
                                                                    <FormatAmountNumber
                                                                        value={value}
                                                                        currency={selectedCurrency}
                                                                        hideDecimals={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        );
                                                    })()}
                                                </Card>
                                            </Col>
                                            <Col className='col-3'>
                                                <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px', color: '#F38375' }} onClick={_ => toggleTab("SalesDealList", selectedHotel, null, null, true, null, "Cancelled")}>
                                                    <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                        <i className={`far fa-thumbs-down mr-2`}/>
                                                        <FormattedMessage id='SalesProcess.LostDeals'/>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                        <div>
                                                            {hotelReport?.numberOfLostDeals??'0'}
                                                        </div>
                                                        <div>
                                                            <FormatAmountNumber
                                                                value={hotelReport?.proposalValueLost ?? '0'}
                                                                currency={selectedCurrency}
                                                                hideDecimals={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col className='col-3'>
                                                <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px', color: '#36ad51' }} onClick={_ => toggleTab("SalesDealList", selectedHotel, null, null, true, null, "Finished")}>
                                                    <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                        <i className={`fas fa-trophy mr-2`}/>
                                                        <FormattedMessage id='SalesProcess.WonDeals'/>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                        <div>
                                                            {hotelReport?.numberOfWonDeals??'0'}
                                                        </div>
                                                        <div>
                                                            <FormatAmountNumber
                                                                value={hotelReport?.proposalValueWon ?? '0'}
                                                                currency={selectedCurrency}
                                                                hideDecimals={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {hotelReport ?
                                        <Col className='col-12 mt-3' style={{ fontSize: '0.9em' }}>
                                            <Card className='shadow bg-white border-0 p-3 h-100' style={{ borderRadius: '5px' }}>
                                                <div className='overflow-auto scrollableDiv'>
                                                    <div className='d-flex align-items-center justify-content-between px-3 py-1' style={{ borderRadius: '4px' }}>
                                                        <div style={{ minWidth: '150px' }}>
                                                            <b>
                                                                <FormattedMessage id="SalesProcess.Metrics"/>
                                                            </b>
                                                        </div>
                                                        <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                            <div className='text-truncate'>
                                                                <b><FormattedMessage id="SalesProcess.PreviousYear"/></b>
                                                            </div>
                                                        </div>
                                                        <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                            <div className='text-truncate'>
                                                                <b><FormattedMessage id="SalesProcess.Budget"/></b>
                                                            </div>
                                                        </div>
                                                        <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                            <div className='text-truncate'>
                                                                <b><FormattedMessage id="SalesProcess.Actual"/></b>
                                                            </div>
                                                        </div>
                                                        <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                            <div className='text-truncate'>
                                                                <b><FormattedMessage id="SalesProcess.Diff"/></b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='mt-2'>
                                                        {metrics.map((metric, k) =>
                                                            <div key={k} style={{ borderTop: k ? '1px solid lightgrey' : '', background: (k + 1) === metrics.length ? '#e9e9e9' : k === 2 ? 'rgb(248, 248, 248)' :  '' }} className='py-2 px-3 d-flex align-items-center justify-content-between'>
                                                                <div className='text-truncate d-flex align-items-center' style={{ minWidth: '150px' }}>
                                                                    <div className='text-truncate text-center'>
                                                                        <FormattedMessage id={`SalesProcess.${metric.name}`}/>
                                                                    </div>
                                                                </div>
                                                                <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                                    <div className='text-truncate'>
                                                                        <RenderCellValue object={hotelReport.prev} metric={metric} showNulls={true} selectedCurrency={selectedCurrency} hideDecimals={true} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                                    <div className='text-truncate'>
                                                                        <RenderCellValue object={hotelReport.budget} metric={metric} showNulls={true} hideDecimals={true} selectedCurrency={selectedCurrency} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                                    <div className='text-truncate'>
                                                                        {metric.name === "totalValue" ?
                                                                            <span className='cursor-pointer hrefStyle' onClick={_ => toggleTab("SalesDealList", selectedHotel, null, null, true)}>
                                                                                <RenderCellValue object={hotelReport} metric={metric} selectedCurrency={selectedCurrency} hideDecimals={true} />
                                                                            </span>
                                                                            :
                                                                            <RenderCellValue object={hotelReport} metric={metric} selectedCurrency={selectedCurrency} hideDecimals={true} />
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                                    <div className='text-truncate'>
                                                                        <RenderCellValue object={hotelReport.diff} metric={metric} showNulls={true} hideDecimals={true} showCompareValueIcon={true} selectedCurrency={selectedCurrency} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                    :''}
                                </Row>
                            </div>
                            <div className='mt-4'>
                                <div className='pt-1'>
                                    <h5 className='m-0'>
                                        <i className="mr-2 fas fa-calendar-week"/>
                                        <FormattedMessage id="SalesProcess.QuarterlyStats"/>
                                    </h5>
                                </div>
                                <Row className='mt-2'>
                                    {quarterData ?
                                        quarterData.map((q, k) =>
                                            <Col key={k} className='col-12 mt-3' style={{ fontSize: '0.9em' }}>
                                                <Card className='shadow bg-white border-0 p-3 h-100' style={{ borderRadius: '5px' }}>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div className='text-muted'>
                                                            <FormattedMessage id="SalesProcess.Quarter"/>
                                                            <span className='ml-1'>{q.idx}</span>
                                                        </div>
                                                    </div>
                                                    <div className='overflow-auto mt-2 scrollableDiv'>
                                                        <div className='d-flex align-items-center justify-content-between px-3 py-1' style={{ borderRadius: '4px' }}>
                                                            <div style={{ minWidth: '150px' }}>
                                                                <b>
                                                                    <FormattedMessage id="SalesProcess.Metrics"/>
                                                                </b>
                                                            </div>
                                                            {q.monthlyData.map((m, k) =>
                                                                <div style={{ borderLeft: '1px solid #a5a5a5' }} key={k} className='w-100 text-center'>
                                                                    <div className='w-100'>
                                                                        <b>{moment(m.startDate).format('MMMM')}</b>
                                                                    </div>
                                                                    <div className='mt-1 w-100 d-flex align-items-center justify-content-between text-muted' style={{ gap: '5%' }}>
                                                                        <div className='w-100 text-center'>
                                                                            <div className='text-truncate'>
                                                                                <FormattedMessage id="SalesProcess.PreviousYear"/>
                                                                            </div>
                                                                        </div>
                                                                        <div className='w-100 text-center'>
                                                                            <div className='text-truncate'>
                                                                                <FormattedMessage id="SalesProcess.Budget"/>
                                                                            </div>
                                                                        </div>
                                                                        <div className='w-100 text-center'>
                                                                            <div className='text-truncate'>
                                                                                <FormattedMessage id="SalesProcess.Actual"/>
                                                                            </div>
                                                                        </div>
                                                                        <div className='w-100 text-center'>
                                                                            <div className='text-truncate'>
                                                                                <FormattedMessage id="SalesProcess.Diff"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                                <div className='w-100'>
                                                                    <b><FormattedMessage id="SalesProcess.Total"/></b>
                                                                </div>
                                                                <div className='mt-1 w-100 d-flex align-items-center justify-content-between text-muted' style={{ gap: '5%' }}>
                                                                    <div className='w-100'>
                                                                        <div className='text-truncate'>
                                                                            <FormattedMessage id="SalesProcess.PreviousYear"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className='w-100'>
                                                                        <div className='text-truncate'>
                                                                            <FormattedMessage id="SalesProcess.Budget"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className='w-100'>
                                                                        <div className='text-truncate'>
                                                                            <FormattedMessage id="SalesProcess.Actual"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className='w-100'>
                                                                        <div className='text-truncate'>
                                                                            <FormattedMessage id="SalesProcess.Diff"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='mt-2'>
                                                            {metrics.map((metric, k) =>
                                                                <div key={k} style={{ borderTop: k ? '1px solid lightgrey' : '', background: (k + 1) === metrics.length ? '#e9e9e9' : k === 2 ? 'rgb(248, 248, 248)' : '' }} className='py-2 px-3 d-flex align-items-center justify-content-between'>
                                                                    <div className='text-truncate d-flex align-items-center' style={{ minWidth: '150px' }}>
                                                                        <div className='text-truncate text-center'>
                                                                            <FormattedMessage id={`SalesProcess.${metric.name}`}/>
                                                                        </div>
                                                                    </div>
                                                                    {q.monthlyData.map((m, k) =>
                                                                        <div key={k} className='w-100 d-flex align-items-center justify-content-between' style={{ gap: '5%', borderLeft: '1px solid #a5a5a5', fontSize: "0.9em" }}>
                                                                            <div className='w-100 text-center text-truncate'>
                                                                                <RenderCellValue object={m.prev} metric={metric} showNulls={true} selectedCurrency={selectedCurrency} hideDecimals={true} />
                                                                            </div>
                                                                            <div className='w-100 text-center text-truncate'>
                                                                                <RenderCellValue object={m.budget} metric={metric} showNulls={true} hideDecimals={true} selectedCurrency={selectedCurrency} />
                                                                            </div>
                                                                            <div className='w-100 text-center text-truncate'>
                                                                                <span>
                                                                                    {metric.name === "numberOfLodging" || metric.name === "numberOfSpaces" ?
                                                                                        <span className='cursor-pointer hrefStyle' onClick={_ => toggleTab("SalesProcessResources", selectedHotel, null, moment(m.startDate).format('MM'), true, metric.name === "numberOfSpaces")}>
                                                                                            <RenderCellValue object={m} metric={metric} selectedCurrency={selectedCurrency} hideDecimals={true} />
                                                                                        </span>
                                                                                    : metric.name === "totalValue" ?
                                                                                        <span className='cursor-pointer hrefStyle' onClick={_ => toggleTab("SalesDealList", selectedHotel, null, moment(m.startDate).format('MM'), true)}>
                                                                                                <RenderCellValue object={m} metric={metric} selectedCurrency={selectedCurrency} hideDecimals={true} />
                                                                                        </span>
                                                                                        :
                                                                                            <RenderCellValue object={m} metric={metric} selectedCurrency={selectedCurrency} hideDecimals={true} />
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className='w-100 text-center text-truncate'>
                                                                                <RenderCellValue showCompareValueIcon={true} object={m.diff} metric={metric} showNulls={true} hideDecimals={true} selectedCurrency={selectedCurrency} />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    <div key={k} className='w-100 d-flex align-items-center justify-content-between' style={{ gap: '5%', borderLeft: '1px solid #a5a5a5', fontSize: "0.9em" }}>
                                                                        <div className='w-100 text-center text-truncate'>
                                                                            <RenderCellValue object={q.quarterData.prev} metric={metric} showNulls={true} hideDecimals={true} selectedCurrency={selectedCurrency} />
                                                                        </div>
                                                                        <div className='w-100 text-center text-truncate'>
                                                                            <RenderCellValue object={q.quarterData.budget} metric={metric} showNulls={true} hideDecimals={true} selectedCurrency={selectedCurrency} />
                                                                        </div>
                                                                        <div className='w-100 text-center text-truncate'>
                                                                            <span>
                                                                                {metric.name === "totalValue" ?
                                                                                    <span className='cursor-pointer hrefStyle' onClick={_ => toggleTab("SalesDealList", selectedHotel, null, moment(q.monthlyData[0].startDate).format('MM'), true, null, null, true)}>
                                                                                        <RenderCellValue object={q.quarterData} metric={metric} selectedCurrency={selectedCurrency} hideDecimals={true} />
                                                                                    </span>
                                                                                    : 
                                                                                    <RenderCellValue object={q.quarterData} metric={metric} selectedCurrency={selectedCurrency} hideDecimals={true} />
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        <div className='w-100 text-center text-truncate'>
                                                                            <RenderCellValue showCompareValueIcon={true} object={q.quarterData.diff} metric={metric} showNulls={true} hideDecimals={true} selectedCurrency={selectedCurrency} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                        )
                                    :''}
                                </Row>
                            </div>
                        </div>
                    </BlockUi>
                </Card>
            </div>
        );
    }
}

export default injectIntl(SalesProcessExecutiveReportMonthly);


const RenderCellValue = ({ metric, object, showNulls, showCompareValueIcon, hideDecimals, selectedCurrency }) => {
    if (object) {
        const value = object[metric.name];

        if (showNulls && !value) {
            return '-'
        }

        if (metric.type === "value")
            return <span title={value ?? 0} className={!showCompareValueIcon ? '' : value > 0 ? 'text-success' : 'text-danger'}><FormatAmountNumber value={value ?? 0} hideDecimals={hideDecimals} currency={selectedCurrency} /></span>
        else if (metric.type === "percentage")
            return <span title={value ?? 0} className={!showCompareValueIcon ? '' : value > 0 ? 'text-success' : 'text-danger'}>{value ?? 0}%</span>
        else
            return <span title={value ?? 0} className={!showCompareValueIcon ? '' : value > 0 ? 'text-success' : 'text-danger'}>{value ?? 0}</span>
    }
    else {
        return '-';
    }
}                                                    