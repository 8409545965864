import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Row } from 'reactstrap';
import { StyledCard } from "../Base/CommonUIComponents";
import { FormattedMessage } from 'react-intl';
import GoogleBusinessImg from '../../img/GoogleBusiness.ico';

import { getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import CustomSelect from "../Base/CustomSelect";

class GoogleBusinessFood extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            locations: [],
            questionList: [],
            selectedLocation: null
        };
    }

    componentDidMount() {
        const accountId = this.props.match.params?.accountId;
        
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false, accountId });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    this.setState({ block: false }, () => handleNotification(data));
                }
                else {
                    const locations = data.response?.map(el => {
                        return({ value: el.name, label: el.title });
                    });

                    this.setState({ selectedLocation: locations && locations[0]?.value, locations, error: errorMessage, accountId }, () => this.getFood());
                }
            }
            else {
                this.setState({ error: errorMessage, block: false, accountId });
            }
        }, `/api/gms/Google/v1/accounts/${accountId}/location`);
    }

    getFood = () => {
        const { accountId, selectedLocation } = this.state;
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    this.setState({ questionList: data.response, error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Google/v1/foodmenus/account/${accountId}/${selectedLocation}/list`)
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        }, () => this.getFood())
    }

    render() {
        const { block, error, selectedLocation, locations } = this.state;

        return (
            <StyledCard block={block} error={error} >
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><img alt="" src={GoogleBusinessImg} height="18px" className="mr-2" /><Link to="/GoogleBusinessConfig"> <FormattedMessage id="GoogleBusinessConfig.Title" /></Link></BreadcrumbItem>
                        <BreadcrumbItem active><FormattedMessage id="GoogleBusinessFood.Title" /></BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Row className="mb-2">
                    <Col className="col-9">
                    </Col>
                    <Col className="text-right col-3">
                        <CustomSelect
                            options={locations}
                            value={locations.find(m => m.value === selectedLocation)}
                            isSearchable
                            placeholder={''}
                            onChange={this.handleSelect.bind(this, 'selectedLocation')}
                            required={true}
                        />
                    </Col>
                </Row>

            </StyledCard>
        );
    }
}
export default injectIntl(GoogleBusinessFood)
