import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import EditorText from '../../Base/EditorText';
import { FormattedMessage } from 'react-intl';

class InitiativeModalStep5 extends Component {
    render() {
        const { initiative, onEditorStateChange } = this.props;

        return (
            <Row>
                <Col className="col-12 mb-3">
                    <div className="title-sm">
                        <FormattedMessage id="SalesProcess.Report"/>
                    </div>
                    <EditorText
                        editorState={initiative.editorStateHtml2}
                        onEditorStateChange={(editorState) => onEditorStateChange(editorState, 'editorStateHtml2', 'report')}
                        placeholder=""
                    />
                </Col>
            </Row>
        );
    }
}

export default InitiativeModalStep5;
