import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, UncontrolledDropdown } from 'reactstrap';
import { deleteAPI, getAPI, patchAPI, postAPI } from '../Base/API';
import { StyledCard } from '../Base/CommonUIComponents';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import { handleNotification } from '../Base/Notification';

class GoogleBusinessLocationActions extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            actionList: [],
            pageTokens: [null],
            pageIndex: 0,
            pageSize: 10,
            block: false,
            error: null,
            editableKey: null,
            selectedKey: null
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedLocation !== this.props.selectedLocation) {
            this.setState({ actionList: [], pageTokens: [null], pageIndex: 0 }, () => this.getPlaceActionsOptions());
        }
    }

    componentDidMount() {
        this.getPlaceActionsOptions();
    }

    getPlaceActions = () => {
        const { selectedLocation, handleUnverifiedLocation } = this.props;
        let { pageTokens, pageIndex, pageSize, placeActionsOptions } = this.state;

        let urlParams = "";
        if(pageTokens[pageIndex]) urlParams += `&pageToken=${pageTokens[pageIndex]}`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    if(data.errors[0].code === "UNVERIFIED_LOCATION"){
                        this.setState({ block: false }, handleUnverifiedLocation());
                    }
                    else{
                        this.setState({ block: false }, () => handleNotification(data));
                    }
                }
                else if(data.response[0]?.placeActionLinks){
                    if(data.response[0]?.nextPageToken) pageTokens[pageIndex + 1] = data.response[0]?.nextPageToken;
                    const actionList = data.response[0]?.placeActionLinks?.map((a) => {
                        a.original = a.uri;
                        return a;
                    });
                    
                    actionList.forEach((a) => {
                        if(!placeActionsOptions?.find(({placeActionType}) => placeActionType === a.placeActionType)) {
                            if(!placeActionsOptions) placeActionsOptions = [];
                            placeActionsOptions.push(a);
                        };
                    });
                    
                    this.setState({ actionList, placeActionsOptions, pageTokens, block: false, error: errorMessage });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false, });
            }
        }, `/api/gms/Google/v1/placeActions/locations/${selectedLocation}/list?pageSize=${pageSize}` + urlParams)
    }

    getPlaceActionsOptions = () => {
        const { selectedLocation, intl } = this.props;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    this.setState({ block: false }, () => handleNotification(data));
                }
                else if(data.response[0]){
                    this.setState({ placeActionsOptions: data.response[0]?.placeActionTypeMetadata }, () => this.getPlaceActions());
                }
            }
            else {
                this.setState({ error: errorMessage, block: false, });
            }
        }, `/api/gms/Google/v1/placeActionsMetadata/locations/${selectedLocation}/list?lang=${intl.locale === "en" ? "en-US" : "pt-PT"}`);
    }

    handleAction = (e) => {
        const { value, name } = e.target;
        const { actionList, editableKey, selectedKey } = this.state;
        
        actionList.filter(({placeActionType}) => placeActionType === editableKey)[selectedKey][name] = value;

        this.setState({ actionList });
    }

    submitChange = () => {
        const { actionList, editableKey, selectedKey } = this.state;
        const { selectedLocation } = this.props;

        const placeActionLink = actionList.filter(({placeActionType}) => placeActionType === editableKey)[selectedKey];

        if(!placeActionLink || !placeActionLink.uri || placeActionLink.uri === placeActionLink.original){
            this.setState({ editableKey: null });
            return;
        }

        this.setState({ block: true }, () => {
            if(placeActionLink.original){
                patchAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];
    
                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data.errors && data.errors.length > 0) {
                            this.setState({ block: false }, () => handleNotification(data));
                        }
                        else {
                            this.setState({ editableKey: null }, () => this.getPlaceActions());
                        }
                    }
                    else {
                        this.setState({ error: errorMessage, block: false, });
                    }
                }, `/api/gms/Google/v1/placeActions/${placeActionLink.name}`, placeActionLink);
            }
            else{
                delete placeActionLink.name;
                postAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];
    
                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data.errors && data.errors.length > 0) {
                            this.setState({ block: false }, () => handleNotification(data));
                        }
                        else {
                            this.setState({ editableKey: null }, () => this.getPlaceActions());
                        }
                    }
                    else {
                        this.setState({ error: errorMessage, block: false, });
                    }
                }, `/api/gms/Google/v1/placeActions/locations/${selectedLocation}`, placeActionLink);
            }
        });
    }

    toggleEdit = (key, selectedKey) => {
        const { editableKey } = this.state;
        if(editableKey === key) this.setState({ editableKey: null });
        else this.setState({ editableKey: key, selectedKey });
    }

    toggleDeleteModal = (key, selectedKey) => {
        this.setState({ deleteKey: key, selectedKey, deleteModal: !this.state.deleteModal });
    }

    addPlaceAction = (key) => {
        let { actionList, placeActionsOptions, editableKey, selectedKey } = this.state;
        const actionType = placeActionsOptions.find(({placeActionType}) => placeActionType === key)?.placeActionType;

        if(!actionList) actionList = [];

        const list = actionList.filter(({placeActionType}) => placeActionType === key);

        if(list.length > 4 || (actionType === editableKey && (selectedKey || selectedKey === 0) && list[selectedKey] && !list[selectedKey]?.uri)) return;

        const name = Math.random();
        actionList.push({ placeActionType: actionType, uri: "", name });

        selectedKey = actionList.filter(({placeActionType}) => placeActionType === key).length - 1;

        this.setState({ actionList: actionList.filter((el) => el.name === name || (el.name !== name && el.uri)), editableKey: key, selectedKey });
    }

    deleteAction = () => {
        const { deleteKey, actionList } = this.state;
        const placeAction = actionList.find(({name}) => name === deleteKey);
        const placeActionLinkId = placeAction?.name;

        if(!placeAction || !placeAction.original){
            // remove place action from action list
            this.setState({ actionList: actionList.filter(({name}) => name !== placeActionLinkId), deleteKey: null, deleteModal: !this.state.deleteModal });
        }
        else{
            this.setState({ block: true }, () =>
                deleteAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];

                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data.errors && data.errors.length > 0) {
                            this.setState({ block: false }, () => handleNotification(data));
                        }
                        else {
                            this.setState({ deleteModal: !this.state.deleteModal, deleteKey: null }, () => this.getPlaceActions());
                        }
                    }
                    else {
                        this.setState({ error: errorMessage, block: false, });
                    }
                }, `/api/gms/Google/v1/placeActions/${placeActionLinkId}`)
            );
        }
    }
    
    resetChanges = () => {
        const { actionList, editableKey, selectedKey } = this.state;

        const placeActionLink = actionList.filter(({placeActionType}) => placeActionType === editableKey)[selectedKey];

        if(placeActionLink.original) {
            placeActionLink.uri = placeActionLink.original;
        
            this.setState({ editableKey: null, placeActionLink });
        }
        else {
            this.setState({actionList: actionList.filter(({name}) => name !== placeActionLink.name), deleteKey: null, deleteModal: !this.state.deleteModal });
        }
    }

    render() {
        const { actionList, block, error, placeActionsOptions, editableKey, deleteModal, selectedKey } = this.state;
        const { intl } = this.props;
        
        return (
            <StyledCard block={block} error={error}>
                {deleteModal ?
                    <ConfirmActionModal
                        block={block}
                        error={error}
                        text={intl.formatMessage({ id: "GoogleBusinessConfig.DeleteAction" })}
                        toggleModal={this.toggleDeleteModal}
                        modal={deleteModal}
                        actionFunction={this.deleteAction}
                    />
                :''}
                {placeActionsOptions && placeActionsOptions.length > 0 ? 
                    <Row>
                        {placeActionsOptions?.map((action, key) => {
                            const currentList = actionList?.filter(({placeActionType}) => placeActionType === action.placeActionType);
                            return (
                                <Col className='col-4 mt-2' key={key}>
                                    <div className='mb-2'>
                                        <b><FormattedMessage id={`GoogleBusinessConfig.${action.placeActionType}`}/></b>
                                    </div>
                                    {currentList && currentList.length > 0 ?
                                        <>
                                            {currentList.map((current, currKey) => 
                                                <div key={currKey} className='mt-1'>
                                                    {current && (current.uri || editableKey === action.placeActionType) ?
                                                        editableKey === action.placeActionType && currKey === selectedKey ?
                                                            <Row className='mt-2'>
                                                                <Col>
                                                                    <Input
                                                                        type='text'
                                                                        value={current.uri}
                                                                        name='uri'
                                                                        onChange={this.handleAction}
                                                                    />
                                                                </Col>
                                                                <Col className='col-2 d-flex justify-content-end align-items-end'>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle style={{ background: 'none', border: 'none', color: 'unset', padding: 'unset', width: '10px' }}>
                                                                            <i className="fas fa-ellipsis-v"/>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            <DropdownItem onClick={this.submitChange}>
                                                                                <i className="fas fa-save mr-2"/>
                                                                                <FormattedMessage id="GoogleBusinessConfig.save"/>
                                                                            </DropdownItem>
                                                                            <DropdownItem onClick={_ => this.toggleDeleteModal(current.name, currKey)}>
                                                                                <div style={{ color: '#A80C19' }}>
                                                                                    <i className="mr-2 far fa-trash-alt"/>
                                                                                    <FormattedMessage id="stripo.delete"/>
                                                                                </div>
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </Col>
                                                            </Row>
                                                        :
                                                            <Row className='mt-2'>
                                                                <Col className='text-muted'>
                                                                    {current.uri}
                                                                </Col>
                                                                <Col className='col-2 text-right'>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle style={{ background: 'none', border: 'none', color: 'unset', padding: 'unset', width: '10px' }}>
                                                                            <i className="fas fa-ellipsis-v"/>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            <DropdownItem onClick={_ => this.toggleEdit(action.placeActionType, currKey)}>
                                                                                <i className="fas fa-edit mr-2"/>
                                                                                <FormattedMessage id="stripo.edit"/>
                                                                            </DropdownItem>
                                                                            <DropdownItem onClick={_ => this.toggleDeleteModal(current.name, currKey)}>
                                                                                <div style={{ color: '#A80C19' }}>
                                                                                    <i className="mr-2 far fa-trash-alt"/>
                                                                                    <FormattedMessage id="stripo.delete"/>
                                                                                </div>
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </Col>
                                                            </Row>
                                                    :''}
                                                </div>
                                            )}
                                        </>
                                    :''}
                                    <Row className='cursor-pointer mt-2 AddPhoneNumber' onClick={_ => this.addPlaceAction(action.placeActionType)}>
                                        <Col>
                                            <i className="fas fa-plus mr-2"/>
                                            <FormattedMessage id={`GoogleBusinessConfig.Add${action.placeActionType}`}/>
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        })}
                    </Row>
                :
                    <Row>
                        <Col className='text-center'>
                            <FormattedMessage  id="GoogleBusinessConfig.NoActions"/>
                        </Col>
                    </Row>
                }
            </StyledCard>
        )
    }
}

export default injectIntl(GoogleBusinessLocationActions);