import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Card, Col, Row, CardBody, Modal, ModalBody, Input, Form, FormGroup, Label, UncontrolledTooltip } from 'reactstrap';
import { getAPI, putAPI, deleteAPI } from "../Base/API";
import CustomSelect from "../Base/CustomSelect";
import { StyledCard, ActiveInactiveStatusBadge } from "../Base/CommonUIComponents";
import { getRunPeriodList, getWeekDays } from '../Base/ReferenceDataFunctions';
import { handleNotification } from '../Base/Notification';
import { BlankCard } from '../Base/CommonUIComponents';

import Authorization from '../Base/Authorization';
import ConfirmActionModal from '../Base/ConfirmActionModal';

export class DataQualityTemplateDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            templateList: [],
            hotel: null,
            topicsList: [],
            topicId: null,
            templateName: null,
            deleteModal: false,
            copyModal: false
        };
    }

    componentDidMount() {
        this.getTemplates();
    }

    getComboData = () => {
        this.getTopics();
    }

    getTemplates = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ templateList: data.response });
            }
            this.setState({ error: errorMessage }, () => this.getComboData());
        }, '/api/gms/DataQuality/v1/DataQualityTemplate');
    }

    getTopics = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                var list = [];
                if (data.response && data.response.length > 0) {
                    data.response.map(el => {
                        const topic = {
                            value: el.id,
                            label: el.code,
                        };
                        list.push(topic);
                    });
                }
                this.setState({ topicsList: list });
            }
            this.setState({ error: errorMessage, block: false });
        }, '/api/gms/DataQuality/v1/DataQualityTopic');
    }
    
    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    toggleCopyModal = () => {
        this.setState(prevState => ({
            copyModal: !prevState.copyModal
        }));
    }

    handleInput({ target: { value, name } }) {
        this.setState({
            templateDetail: {
                ...this.state.templateDetail,
                [name]: value
            }
        });
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
                [name]: combo ? combo.value : null
        });
    };

    handleChange = (e) => {
        const { value, name } = e.target;
        this.setState({
            [name]: value
        });
    }

    removeTemplate =(e,id) => {
        e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="DataQualityTemplateDashboard.TemplateRemovedSuccessfully" />, <FormattedMessage id="generic.success" />);
                this.getTemplates();
                this.toggleDeleteModal();
            }
            this.setState({ error: errorMessage, block: false });
        }, '/api/gms/DataQuality/v1/DataQualityTemplate/'+id);
    }

    addTemplate = (e) => {
        e.preventDefault();
        this.setState({ block: true });
        const body = { 
            id: 0,
            name: this.state.templateName,
            dataQualityTopicId: this.state.topicId
        }

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="DataQualityTemplateDashboard.TemplateAddedSuccessfully" />, <FormattedMessage id="generic.success" />);
                    this.getTemplates();
                    this.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, '/api/gms/DataQuality/v1/DataQualityTemplate', JSON.stringify({ request: body }));
    }

    toggleDeleteModal = (templateToDelete) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
            templateToDelete
        }));
    }

    getScheduleIcon = (template, topicIdx, templateIdx) => {
        const id = `schedule-${topicIdx}-${templateIdx}`;
        const runPeriod = template.schedule && template.schedule.period && getRunPeriodList().find(o => o.value === template.schedule.period);

        var weekDaysRunning = [];
        if (template.schedule && template.schedule.period === 'Weekly') {
            template.schedule.weekDays.map((day, i) => {
                if (day) {
                    weekDaysRunning.push(getWeekDays(this.props.intl)[i])
                }
            })
        }

        return <>
            <i className="fas fa-stopwatch text-muted mx-3 px-1" id={id} />

            <UncontrolledTooltip target={id} placement="bottom">
                {template.schedule.period === 'Never' ?
                    <span> {runPeriod?.label} {this.props.intl.formatMessage({ id: "DataQualityTemplateDetail.Runs" }).toLowerCase()} </span>
                    : <span> <FormattedMessage id="DataQualityTemplateDetail.Runs" /> {runPeriod?.label}</span>}

                {template.schedule.period === 'Weekly' ?
                    <span> <FormattedMessage id="DataQualityTemplateDetail.On" /> {weekDaysRunning.join(', ')} </span>
                    : template.schedule.period === 'Monthly' ?
                        <span> <FormattedMessage id="DataQualityTemplateDetail.OnDay" /> {template.schedule.day} </span>
                        : ''}
            </UncontrolledTooltip>
        </>
    }

    sortDataQualityTemplates = (a, b) => {
        // Sort by 'active' in descending order
        if (a.active === b.active) {
          // If 'active' is the same, sort by 'order' in ascending order
          if (a.order === null && b.order === null) {
            return 0; // Both have null order
          } else if (a.order === null) {
            return 1; // 'a' has null order, so it goes after 'b'
          } else if (b.order === null) {
            return -1; // 'b' has null order, so it goes after 'a'
          } else {
            return a.order - b.order;
          }
        } else {
          return b.active - a.active; // Sort 'active' in descending order
        }
    }

    copyDefaultTemplates = () => {
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="DataQualityTemplateDashboard.SucessfulyCopied" />, <FormattedMessage id="generic.success" />);
                }
                this.setState({ error: errorMessage, block: false, copyModal: false }, () => {
                    this.getTemplates();
                    this.getTopics();
                });
            }, '/api/gms/DataQuality/v1/CopyDefaultDataQualityTemplates');
        });
    }

    render() {
        const { block, error, templateList, topicsList, deleteModal, templateToDelete, copyModal } = this.state;

        return (
            <StyledCard block={block} error={error} >
                <Modal isOpen={this.state.modal} fade={false} size={"lg"} style={{ minWidth: 0 }} >
                    <Button onClick={this.toggleModal} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    <ModalBody>
                        <BlankCard block={block} error={error}>
                            <Form onSubmit={this.addTemplate}>
                                <Row className="mb-3">
                                    <Col>
                                        <h5> <FormattedMessage id="DataQualityTemplateDashboard.AddTemplate" /></h5>
                                    </Col>
                                    <Authorization
                                        perform="dataQualityTemplateDashboard:add"
                                        yes={() => (
                                            <Col className="text-right">
                                                <Button className="btn-sm btn-host" type="submit"> <i className="fas fa-save" /></Button>
                                            </Col>
                                        )}
                                        no={() => <div></div>}
                                    />
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Label sm={3}> <FormattedMessage id="DataQualityTemplateDashboard.Topic" /></Label>
                                            <Col sm={9}>
                                                <CustomSelect
                                                    options={topicsList}
                                                    required={true}
                                                    isClearable
                                                    value={this.state.topicId ? topicsList.find(o => o.value === this.state.topicId) : null}
                                                    isSearchable
                                                    placeholder={""}
                                                    onChange={(e) => this.handleChangeSelect(e, "topicId")}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Label for="name" sm={3}> <FormattedMessage id="DataQualityTemplateDashboard.TemplateName" /></Label>
                                            <Col sm={9}>
                                                <Input type="text" name="templateName" value={this.state.templateName} onChange={e => this.handleChange(e)} required maxLength="50" />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </BlankCard>
                    </ModalBody>
                </Modal>

                {copyModal ?
                    <ConfirmActionModal
                        modal={copyModal}
                        toggleModal={this.toggleCopyModal}
                        actionFunction={this.copyDefaultTemplates}
                        text={<FormattedMessage id="DataQualityTemplateDashboard.copyModal"/>}
                        block={block}
                    />
                :''}

                <Row className="mb-3 align-items-center">
                    <Col><h5><i className="fas fa-file-alt mr-2"></i><FormattedMessage id="DataQualityTemplateDashboard.Title" /></h5></Col>

                    <Authorization
                        perform="dataQualityTemplateDashboard:add"
                        yes={() => (
                            <Col className="text-right mr-1 mb-2">
                                {!templateList || templateList.length === 0 ?
                                    <Authorization
                                        perform="admin:view"
                                        yes={() => (
                                            <Button className="btn btn-sm btn-host mr-2" onClick={() => this.toggleCopyModal()}>
                                                <i className="fas fa-copy"></i>
                                            </Button>
                                        )}
                                        no={() => <div></div>}
                                    />
                                :''}
                                <Button className="btn btn-sm btn-host mr-3" onClick={() => this.toggleModal()}>
                                    <i className="fas fa-plus"></i>
                                </Button>
                            </Col>
                        )}
                        no={() => <div></div>}
                    />
                </Row>
                <Row>
                    <Col className="h-100">
                        {templateList && templateList.map((el, k) =>
                            <Card className="mb-3 border-0 shadow" key={k}>
                                <CardBody>
                                    <h6 className="mb-3 pb-3 border-bottom"><b>{el.code}</b><span className="mr-3" />-<span className="mr-3" /> {el.description}</h6>
                                   

                                    {el.dataQualityTemplates && el.dataQualityTemplates.sort(this.sortDataQualityTemplates).map((template, key) => 
                                        <Row key={key} className="overbglight py-1 pl-2" style={{ 'cursor': 'pointer' }} >
                                            <Col onClick={() => this.props.history.push("/DataQualityTemplateDetail/" + template.id)}>
                                                <b>{template.name}</b> <span className="mr-3" />{template.description}
                                            </Col>
                                            <Col className='col-1 text-center text-muted'>
                                                {template.order !== null ? 
                                                    <b title={this.props.intl.formatMessage({ id: "DataQualityTemplateDetail.Order"})}>{template.order}</b>
                                                :''}
                                            </Col>
                                            <Col className="text-right col-2 pl-1">

                                                <ActiveInactiveStatusBadge status={template.active}/>

                                                {template.schedule ?
                                                    <>
                                                        {this.getScheduleIcon(template, k, key)}
                                                    </>
                                                : <span style={{ margin: '24px' }} />}

                                                <Authorization
                                                    perform="dataQualityTemplateDashboard:delete"
                                                    yes={() => (
                                                        <Button className="btn btn-sm btn-host ml-2" onClick={() => this.toggleDeleteModal(template)}>
                                                            <i className="fas fa-trash"></i>
                                                        </Button>
                                                    )}
                                                    no={() => <div></div>}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                </CardBody>
                            </Card>
                        ) }
                    </Col>
                </Row>

                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={this.toggleDeleteModal}
                        actionFunction={(e) => this.removeTemplate(e, templateToDelete.id)}
                        text={<FormattedMessage id="DataQualityTemplateDashboard.ConfirmDeleteText" values={{ template: templateToDelete.name }} />}
                        block={block}
                    />
                : ''}
            </StyledCard>
        );
    }
}
export default injectIntl(DataQualityTemplateDashboard)
