import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Col, Row, Card, Badge } from 'reactstrap';
import { DefaultPage, StyledCard } from "../../Base/CommonUIComponents";
import { getAPI } from "../../Base/API";
import CustomTagModal from './CustomTagModal';

class CustomTags extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            tagGroups: [],
            tagsList: [],
            tag: null,
            modal: false
        };
    }

    componentDidMount() {
        this.getTagGroups();
    }

    getTagGroups = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                var list = [];

                data.response && data.response.forEach(tg => {
                    list.push({ value: tg.id, label: tg.code });
                })

                this.setState({ tagGroups: list }, () => this.getTags());
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/tag/group`);
    }

    getTags = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ tagsList: data.response, error: errorMessage, block: false });
                return;
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/tag`);
    }

    updateTable = (tag, isToDelete) => {
        this.setState({ block: true });
        var array = this.state.tagsList ? [...this.state.tagsList] : [];

        if (isToDelete) {
            array = array.filter(tg => tg.id !== tag.id);
        }
        else {
            const index = array && array.indexOf(array.find(el => el.id === tag.id));

            if (index === -1) {
                array.push(tag);
            }
            else {
                array[index] = tag;
            }
        }

        this.setState({ tagsList: array, block: false });
    }

    groupByTagGroup = (array) => {
        return array
            .reduce((hash, obj) => {
                if (obj.tagGroupId === undefined) return hash;
                return Object.assign(hash, { [obj.tagGroupId]: (hash[obj.tagGroupId] || []).concat(obj) })
            }, {})
    }

    toggleModal = (tag = null, tagGroupIndex = null, tagIndex = null) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            tag, tagGroupIndex, tagIndex
        }))
    }

    render() {
        const { block, error, tagsList } = this.state;

        const groupedTags = tagsList ? this.groupByTagGroup(tagsList) : {};

        return (
            <StyledCard block={block} error={error} icon={'fas fa-tag'} title={'CustomTags.Title'}>
                <Row>
                    <Col className="text-right">
                        <Button className="btn-sm btn-host" onClick={this.toggleModal}>
                            <i className="fas fa-plus" />
                        </Button>
                    </Col>
                </Row>

                {groupedTags && Object.keys(groupedTags).length > 0 ?
                    Object.keys(groupedTags).map((tagGroupId, key) => {
                        const group = tagGroupId && this.state.tagGroups.find(tg => tg.value == tagGroupId);

                        return <div key={key} className="mb-4">
                            <Row className="col-6 mb-2">
                                <Col className="pl-2"> <h5> <i className="fas fa-layer-group" /> {group && group.label} </h5> </Col>
                            </Row>

                            <Row>
                                {groupedTags[tagGroupId].map((tag, i) =>
                                    <Col key={i} className="mb-2 col-3">
                                        <Card className="border-0 shadow pt-2 px-3 pointer h-100 mb-2" onClick={() => this.toggleModal(tag, key, i)}>
                                            <h5> {tag.code} </h5>
                                            <span className="text-muted font_size_xxs"> {tag.description} </span>
                                        </Card>
                                    </Col>
                                )}
                            </Row>
                        </div>
                    })
                    : <DefaultPage text="CustomTags.NoTagsConfigured" icon="fas fa-tag" />
                }

                {this.state.modal ?
                    <CustomTagModal
                        modal={this.state.modal}
                        toggleModal={this.toggleModal}
                        tag={this.state.tag}
                        updateTable={this.updateTable}
                        tagGroups={this.state.tagGroups}
                    />
                    : ''}
            </StyledCard>
        );
    }
}

export default injectIntl(CustomTags)