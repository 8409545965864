import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Card, Button, UncontrolledCollapse } from 'reactstrap';
import { FrequencyText, LastStatusBadge, MarketingSendRunStatus } from '../../Marketing/Common/MarketingFunctions'
import { handleNotification } from "../../Base/Notification";
import { ErrorAlert } from "../../Base/ErrorAlert";
import { TemplateModal } from '../TemplateModal';
import { getAPI, putAPI } from "../../Base/API";
import BlockUi from 'react-block-ui';
import { getConvertedDateToHotelTimezone } from '../../Base/ReferenceDataFunctions';
import { KebabMenu } from '../../Base/CommonUIComponents';
import { CheckAuthorization } from '../../Base/Authorization';

class ProfileMarketing extends Component {

    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.state = {
            block: true,
            commList: [],
            templateModal: false,
            previewTemplate: null,
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        this.getComunication();
    }

    getComunication = () => {
        const { profileId } = this.props;
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                if (data.response && data.response.length > 0) {
                    this.setState({ commList: data.response, error: errorMessage, block: false });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `api/gms/Marketing/v1/marketingsendrun/${profileId}`);
    }

    toggleModal() {
        this.setState(prevState => ({ templateModal: !prevState.templateModal }));
    }

    getEgoiTemplate = (e, templateId, channel) => {
        e.preventDefault();
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else {
                    this.setState({ previewTemplate: data, templateModal: true, error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Marketing/v1/egoitransactional/template/${channel === 'Email' ? 'email' : 'sms'}/${templateId}`);
    }

    getSendGridTemplate = (e, templateId) => {
        e.preventDefault();
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.versions && data.response.versions.length > 0) {
                const template = data.response.versions
                    .filter(vrs => vrs.active === 1)
                    .reduce((acc, curr) => {
                        if (acc) {
                            return acc;
                        }
                        else {
                            const tmpl = {
                                id: data.response.id,
                                templateName: data.response.name,
                                htmlBody: curr.html_content,
                                subject: curr.subject,
                                generation: data.response.generation
                            }
                            return tmpl;
                        }
                    }
                    , null);

                this.setState({ error: errorMessage, block: false, previewTemplate: template, templateModal: true });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/SendGrid/v1/templates/${templateId}`);
    }

    getTwillioTemplate = (e, templateId) => {
        e.preventDefault();
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.contents && data.response.contents.length > 0) {
                const template = data.response.contents
                    .map(resp => {
                        const template = {
                            id: resp.sid,
                            templateName: resp.friendly_name,
                            smsTextBody: resp.types["twilio/text"].body
                        };

                        return template;
                    })
                    .find(resp => resp.id === templateId);

                this.setState({ error: errorMessage, block: false, previewTemplate: template, templateModal: true });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Twilio/v1/template`);
    }

    getMarketingCampaignsFeedback = (e, commId, commIdx) => {
        e.preventDefault();
        
        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const { commList } = this.state;

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.warnings && data.warnings.length > 0) {
                    handleNotification(data);
                }
                if (data.response.length > 0) {
                    handleNotification({}, <FormattedMessage id="ProfileDetails.EventMessagesSyncSuccessfully" />, <FormattedMessage id="generic.success" />);

                    commList[commIdx] = data.response[0];
                }
                this.setState({ error: errorMessage, block: false, commList });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Marketing/v1/marketingSendRunsFeedback/${commId}`);
    }

    getKabebExtraFields = (comm, key) => {
        if (CheckAuthorization("profileMarketing:checkResults") && !parseInt(comm.templateId) && comm.senderChannel === 'Email') {
            return [
                { function: (e) => parseInt(comm.templateId) ? this.getEgoiTemplate(e, comm.templateId, comm.senderChannel) : (comm.senderChannel === 'Email' ? this.getSendGridTemplate(e, comm.templateId, comm.senderChannel) : this.getTwillioTemplate(e, comm.templateId, comm.senderChannel)), text: <FormattedMessage id="ProfileMarketing.SeeTemplate" /> },
                { function: (e) => this.getMarketingCampaignsFeedback(e, comm.id, key), text: <FormattedMessage id="ProfileMarketing.CheckResults" /> }
            ];
        }
        else {
            return [
                { function: (e) => parseInt(comm.templateId) ? this.getEgoiTemplate(e, comm.templateId, comm.senderChannel) : (comm.senderChannel === 'Email' ? this.getSendGridTemplate(e, comm.templateId, comm.senderChannel) : this.getTwillioTemplate(e, comm.templateId, comm.senderChannel)), text: <FormattedMessage id="ProfileMarketing.SeeTemplate" /> }
            ];
        }
    }

    render() {
        const { block, error, commList, templateModal, previewTemplate } = this.state;

        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />

                <div className="m-2 p-2">
                    { templateModal ?
                        <TemplateModal isOpen={templateModal} toggle={this.toggleModal} previewTemplate={previewTemplate} />
                        : ''}
                    <Row>
                        <Col>
                            <h5>
                                <i className="icon-icon-marketing mr-1" />
                                <FormattedMessage id="ProfileDetails.Marketing" />
                            </h5>
                        </Col>
                    </Row>
                    <div className="verticalScroll p-2 pr-4" style={{ height: '85vh' }}>
                        {commList && commList.length > 0 ?
                            commList.map((comm, key) => {
                                return (
                                    <Row className="my-1" key={key}>
                                        <Col>
                                            <Card className=" shadow p-2 border-0 mb-3" >
                                                <Row className="px-2 align-items-center">
                                                    <Col id={`ProfileMarketing${comm.id}`} className="pointer" >
                                                        <i className="fas fa-angle-right" />
                                                        <span className="bg-light-grey circle-sm mx-2">
                                                            {comm.senderChannel === "Email" ? <i className="icon-icon-mail mx-1" /> : <i className="icon-icon-sms" style={{ padding: '0 5px' }} />}
                                                        </span>
                                                        <b> {comm.name ?? <FormattedMessage id="ProfileMarketing.ManualSending"/>} </b>

                                                        {comm.frequencyAdjust ?
                                                            <>
                                                            - <FrequencyText
                                                                marketingType={comm.marketingType}
                                                                frequency={comm.frequency}
                                                                frequencyAdjust={comm.frequencyAdjust}
                                                                pastSimple={true}
                                                                intl={this.props.intl}
                                                            />
                                                            </>
                                                        : ''}
                                                    </Col>
                                                
                                                    <Col className="col-4 text-right pl-0">
                                                        <span className="text-muted float-left">
                                                            <i className="far fa-clock mr-2"></i>{getConvertedDateToHotelTimezone(comm.createdAt).format("YYYY-MM-DD HH:mm")}
                                                        </span>

                                                        <LastStatusBadge data={comm} />

                                                        <span style={{ display: 'inline-flex' }} className="ml-2">
                                                            <KebabMenu extraFields={this.getKabebExtraFields(comm, key)} />
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <UncontrolledCollapse toggler={`#ProfileMarketing${comm.id}`}>
                                                    <MarketingSendRunStatus
                                                        comm={comm}
                                                    />
                                                </UncontrolledCollapse >
                                            </Card>
                                        </Col>
                                    </Row>
                                );
                            })
                            : 
                            <Row>
                                <Col className="d-flex flex-column align-items-center">
                                    <FormattedMessage id="ProfileMarketing.NoMessagesSent" />
                                </Col>
                            </Row>
                        }
                    </div>
                </div>
            </BlockUi>
        );
    }
}
export default injectIntl(ProfileMarketing)