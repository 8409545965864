import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ActiveInactiveStatusBadge, DefaultPage } from '../../../Base/CommonUIComponents';
import { ProfileCreditDetail } from './ProfileCreditDetail';
import { BillingDetails } from '../../BillingDetails';
import BlockUi from 'react-block-ui';

import Authorization, { CheckAuthorization } from '../../../Base/Authorization';
import { ProfileHistory } from '../../../Base/HistoryComponents';
import ConfirmActionModal from '../../../Base/ConfirmActionModal';
import BulkCredit from '../../BulkActions/BulkCredit';
import CreditBalance from './CreditBalance';


export class ProfileCredit extends Component {

    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.state = {
            modal: false,
            title: null,
            credit: null,
            billingModal: false,
            canCreateCredit: false,
            deleteModal: false,
            bulkModal: false,
            balanceModal: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.profile !== nextProps.profile.profileCredit) {
            this.canCreateCredit(nextProps);
        }
    }

    canCreateCredit = (props) => {
        let canCreateCredit = false;
        let hotelList = this.props.hotels ? JSON.parse(JSON.stringify(this.props.hotels)) : [];

        if (global.toMasterProperties?.some(el => el === 'Credit')) {
            canCreateCredit = !props.profile.profileCredit || props.profile.profileCredit.length === 0;
        }
        else {
            if (props.profile?.isMaster) {
                hotelList.forEach(hotel => {
                    if (props.profile.profileCredit?.some(el => el.hotelId === hotel.value)) {
                        hotel.isDisabled = true;
                    }
                    else {
                        hotel.isDisabled = false;
                    }
                })

                canCreateCredit = hotelList.some(h => !h.isDisabled);
            }
            else {
                canCreateCredit = !props.profile.profileCredit || props.profile.profileCredit.length === 0;
            }
        }

        this.setState({ hotels: hotelList, canCreateCredit });
    }

    toggleModal = (modal) => {
        this.setState(prevState => ({ [modal]: !prevState[modal], credit: null, title: <FormattedMessage id="ProfileDetails.CreateNewCredit" /> }));
    }

    toggleDeleteModal = (selectedCredit) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
            selectedCredit
        }));
    }

    removeWarning = () => {
        this.props.removeElement(this.props.profile.id, this.state.selectedCredit.id, 'credit', 'profileCredit');

        this.toggleDeleteModal();
    }

    render() {
        const { block, profile, updateTable, canEditProfile } = this.props;
        const { modal, title, credit, canCreateCredit, hotels, deleteModal, bulkModal, balanceModal } = this.state;
        
        const creditLimitTypeOptions = [
            {
                value: 'UNRESTRICTED',
                label: <FormattedMessage id="ProfileDetails.Unrestricted"/>
            },
            {
                value: 'WARNING',
                label: <FormattedMessage id="ProfileDetails.Warning"/>
            },
            {
                value: 'BLOCKED',
                label: <FormattedMessage id="ProfileDetails.Blocked"/>
            },
        ];

        const profileCredit = profile && profile.profileCredit;
        const billingInstructions = profile && profile.billingInstructions;

        function getCreditLimitTypeLabel(type) {
            const option = creditLimitTypeOptions.find(opt => opt.value === type);
            return option ? option.label : type;
        }

        //const credits = profileCredit ? this.props.groupByHotel(profileCredit) : null;
        const billingInst = billingInstructions ? this.props.groupByHotel(billingInstructions) : null;


        return (
            <BlockUi tag="div" blocking={block}>
                { modal ?
                    <ProfileCreditDetail
                        isOpen={modal}
                        toggle={() => this.toggleModal('modal')}
                        title={title}
                        credit={credit}
                        creditLimitTypeOptions={creditLimitTypeOptions}
                        updateTable={updateTable}
                        profileId={profile.id}
                        hotels={profile.isMaster ? hotels : null}
                    />
                    : <div />}

                {bulkModal ?
                    <BulkCredit
                        isOpen={bulkModal}
                        toggleModal={() => this.toggleModal('bulkModal')}
                        profileId={profile.id}
                        hotelOptions={[...hotels]}
                        creditLimitTypeOptions={creditLimitTypeOptions}
                        updateTable={updateTable}
                    />
                    : ''}


                {balanceModal ?
                    <CreditBalance
                        isOpen={balanceModal}
                        toggleModal={() => this.toggleModal('balanceModal')}
                        profileId={profile.id}
                        groupByHotel={this.props.groupByHotel}
                        hotels={[...this.props.hotels]}
                    />
                : ''}

                <div className="m-2 p-2">
                    <Row className="mb-2 align-items-center">
                        <Col className='col-4'>
                            <h5 className='m-0'>
                                <i className="icon-icon-credit mr-1" />
                                <FormattedMessage id="ProfileDetails.Credit" />
                            </h5>
                        </Col>

                        <Col className="text-right col-8" >
                            <Button className="btn-sm btn-host mr-2" onClick={() => this.toggleModal('balanceModal')} >
                                <i className="fas fa-donate" /> 
                            </Button>


                            {canEditProfile && profile.isMaster &&
                                <Authorization
                                    perform="profileCredit:bulk"
                                    yes={() => (
                                        <Button className="btn-sm btn-host mr-2" onClick={() => this.toggleModal('bulkModal')}>
                                            <i className="fas fa-cog" /> Bulk Action
                                        </Button>
                                    )}
                                    no={() => <div></div>}
                                />
                            }


                            <ProfileHistory
                                component={"credit"}
                                profileId={this.props.profileId}
                            />

                            <Authorization
                                perform="profileCredit:add"
                                yes={() => (
                                    canEditProfile && canCreateCredit &&
                                    <Button className="btn-sm btn-host ml-2" onClick={() => this.toggleModal('modal')}>
                                        <i className="fas fa-plus" />
                                    </Button>
                                )}
                                no={() => <div></div>}
                            />
                        </Col>
                    </Row>

                    {profileCredit?.map((pc, i) => {
                        const hotel = hotels?.find(el => el.value === pc.hotelId);
                            
                        return <Row className="mb-2" key={i}>
                            <Col>
                                <Card className="shadow border-0 mb-1">
                                    <CardBody className="py-2">
                                        <Row>
                                            <Col>
                                                <i className="icon-icon-hotel" /> {hotel?.label || pc.hotelId}
                                            </Col>
                                            <Authorization
                                                perform="profileCredit:delete"
                                                yes={() => canEditProfile && (
                                                    <Col className="text-right col-6">
                                                        <span onClick={() => this.toggleDeleteModal(pc)} style={{ cursor: 'pointer' }}>
                                                            <i className="fas fa-trash-alt" />
                                                        </span>
                                                    </Col>
                                                )}
                                                no={() => <div></div>}
                                            />
                                        </Row>
                                        <hr className="mt-1" />

                                        <div style={{ cursor: 'pointer' }} onClick={_ => { if (canEditProfile && CheckAuthorization("profileCredit:add", null)) this.setState({ modal: true, credit: pc, title: <FormattedMessage id="ProfileDetails.EditCredit" /> }) }}>
                                            <div className='mb-3 mb-lg-0'>
                                                <Row className="mb-2 mb-lg-1">
                                                    <Col className='col-4 col-lg-3'>
                                                        <b>
                                                            <FormattedMessage id="ProfileDetails.HasCityLedger" />
                                                        </b>
                                                    </Col>
                                                    <Col className='col-4 text-right text-lg-left'>
                                                        <ActiveInactiveStatusBadge status={pc.hasCityLedger} />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col className='col-6 col-lg-3'>
                                                        <b>
                                                            <FormattedMessage id="ProfileDetails.FinanceAccount" />
                                                        </b>
                                                    </Col>
                                                    {!global.isMobile ?
                                                        <Col>
                                                            {pc.financeAccount}
                                                        </Col>
                                                    :''}
                                                    <Col className='col-6 col-lg-3 pr-1 pr-lg-3'>
                                                        <b><FormattedMessage id="ProfileDetails.PaymentDueDays" /></b>
                                                    </Col>
                                                    {global.isMobile ?
                                                        <Col className='col-6'>
                                                            {pc.financeAccount}
                                                        </Col>
                                                    :''}
                                                    <Col className='col-6 col-lg-3'>
                                                        {pc.paymentDueDays}
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className="mt-3">
                                                <Row className="mb-1">
                                                    <Col className='col-6 col-lg-3'>
                                                        <b><FormattedMessage id="ProfileDetails.CreditLimit" /></b>
                                                    </Col>
                                                    {!global.isMobile ?
                                                        <Col>
                                                            {pc.creditLimit}
                                                        </Col>
                                                    :''}
                                                    <Col className='col-6 col-lg-3'>
                                                        <b><FormattedMessage id="ProfileDetails.Type" /></b>
                                                    </Col>
                                                    {global.isMobile ?
                                                        <Col className='col-6'>
                                                            {pc.creditLimit}
                                                        </Col>
                                                    :''}
                                                    <Col className='col-6 col-lg-3'>
                                                        {getCreditLimitTypeLabel(pc.creditLimitType)}
                                                    </Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col className='col-6 col-lg-3'>
                                                        <b><FormattedMessage id="ProfileDetails.CreditLimitPOS" /></b>
                                                    </Col>
                                                    {!global.isMobile ?
                                                        <Col>
                                                            {getCreditLimitTypeLabel(pc.creditLimitPOS)}
                                                        </Col>
                                                    :''}
                                                    <Col className='col-6 col-lg-3'>
                                                        <b><FormattedMessage id="ProfileDetails.TypePOS" /></b>
                                                    </Col>
                                                    {global.isMobile ?
                                                        <Col className='col-6'>
                                                            {getCreditLimitTypeLabel(pc.creditLimitPOS)}
                                                        </Col>
                                                    :''}
                                                    <Col className='col-6 col-lg-3'>
                                                        {getCreditLimitTypeLabel(pc.creditLimitTypePOS)}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    })}

                    {profileCredit == null || profileCredit.length == 0 ?                            
                        <DefaultPage text="ProfileDetails.NoCreditsAvailable" icon="icon-icon-credit" height="30vh" />
                    : ''}


                    {deleteModal ?
                        <ConfirmActionModal
                            modal={deleteModal}
                            toggleModal={this.toggleDeleteModal}
                            actionFunction={this.removeWarning}
                            text={<FormattedMessage id="ProfileCredit.ConfirmDeleteText" />}
                        />
                        : ''}
                </div>

                <div className="m-2 p-2">
                    <Row className="mb-2">
                        <Col className='col-9 col-lg-6'>
                            <h5>
                                <i className="icon-icon-credit mr-1" />
                                <FormattedMessage id="ProfileDetails.BillingInstructions" />
                            </h5>
                        </Col>
                        <Col className="text-right">
                            <ProfileHistory
                                component={"billing"}
                                profileId={this.props.profileId}
                            />
                            {/*<Button className="btn-sm btn-host" onClick={() => this.toggleModal('billingModal')}>
                                <i className="fas fa-plus" />
                            </Button>*/}
                        </Col>
                    </Row>

                    {billingInst && Object.keys(billingInst).map((hotel, key) =>
                        <div key={key}>
                            <Row className="col-6 mb-2" key={key}>
                                <Col className="pl-2">{hotel} </Col>
                            </Row>

                            {billingInst[hotel].map((bi, i) =>
                                <Row className="mb-0" key={i}>
                                    <Col>
                                        <Card className="shadow border-0 mb-1">
                                            <CardBody className="py-2">
                                                <Row className="mb-1">
                                                    <Col>
                                                        <b> {bi.mode} </b> - {bi.targetItem}
                                                    </Col>
                                                    {/*<Col className="text-right col-1">
                                                        <span onClick={() => removeElement(profile.id, bi.id, 'billingInstructions', 'billingInstructions')} style={{ cursor: 'pointer' }}>
                                                            <i className="fas fa-trash-alt" />
                                                        </span>
                                                    </Col>*/}
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    )}

                    {billingInstructions == null || billingInstructions.length == 0 ?
                        <DefaultPage text="ProfileDetails.NoBillingInstructionsAvailable" icon="icon-icon-credit" height="30vh" />
                    : ''}

                </div>
            </BlockUi>
        );
    }
}