import React, { Component } from 'react'
import { Button, Col, CustomInput, Form, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import { CommonHelper, StyledCard } from '../../../Base/CommonUIComponents';
import { FormattedMessage, injectIntl } from 'react-intl';
import { postAPI } from '../../../Base/API';
import { handleNotification } from '../../../Base/Notification';

class DomainAuthModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            domain: '',
            block: false,
            error: null,
            defaultDomain: false,
            automatic_security: true,
            custom_spf: false,
            custom_dkim_selector: false
        };
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        const regex = new RegExp('^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$');
        const el = document.getElementById('domain');
        if(!regex.test(value)){
            el.setCustomValidity(this.props.intl.formatMessage({ id: "SendGrid.InvalidDomainFormat" }));
            el.reportValidity();
        }
        else{
            el.setCustomValidity("");
        }

        this.setState({ [name]: value });
    }

    handleSwitchChange = (evt) => {
        if (evt && evt.target) {
            const { name, checked } = evt.target;

            this.setState({
                [name]: checked
            });
        }
    }

    createDomain = (e) => {
        e.preventDefault();

        this.setState({ block: true }, () => {
            const { domain, defaultDomain, automatic_security, custom_spf, custom_dkim_selector } = this.state;

            const body = {
                isDefault: defaultDomain,
                domain,
                automaticSecurity: automatic_security,
                customSpf: custom_spf,
                customDkim: custom_dkim_selector
            };
            
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
    
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    
                    this.setState({ block: false }, () => this.props.toggle(data.response?.id));
                }
            }, `/api/gms/SendGrid/v1/domain`, body);
        });
    }

    render() {
        const { domain, automatic_security, defaultDomain, custom_spf, custom_dkim_selector, block, error } = this.state;
        const { toggle } = this.props;

        return (
            <Modal isOpen={true} toggle={_ => toggle()} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={_ => toggle()} className="closeModal">
                    <i className="fas fa-times fa-sm"/>
                </Button>
                <ModalBody>
                    <StyledCard block={block} error={error}>
                        <Form onSubmit={this.createDomain}>
                            <Row>
                                <Col>
                                    <h5 className='m-0'><FormattedMessage id="SendGrid.AuthDomain"/></h5>
                                </Col>
                                <Col className='col-2 text-right'>
                                    <Button className=" btn btn-host btn-sm" onClick={this.createDomain}>
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            <div className='mt-2'>
                                <div>
                                    <Label className='text-muted'>
                                        <FormattedMessage id="SendGrid.Domain"/>
                                    </Label>
                                    <Input
                                        type='text'
                                        required
                                        maxLength={'100'}
                                        id="domain"
                                        name='domain'
                                        value={domain}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className='mt-2'>
                                    <div className='text-muted'>
                                        <FormattedMessage id="SendGrid.AdvancedSettings"/>
                                    </div>
                                    <div className='mt-2 d-flex align-items-center'>
                                        <CustomInput
                                            type="switch"
                                            id="defaultDomain"
                                            name="defaultDomain"
                                            onChange={this.handleSwitchChange}
                                            checked={defaultDomain}
                                        />
                                        <div className='d-flex align-items-center'>
                                            <FormattedMessage id="SendGrid.defaultDomain"/>
                                            <CommonHelper placement="right" help={<FormattedMessage id="SendGrid.defaultDomainHelp" />} id={'defaultDomainHelp'} />
                                        </div>
                                    </div>
                                    <div className='mt-2 d-flex align-items-center'>
                                        <CustomInput
                                            type="switch"
                                            id="automatic_security"
                                            name="automatic_security"
                                            onChange={this.handleSwitchChange}
                                            checked={automatic_security}
                                        />
                                        <div className='d-flex align-items-center'>
                                            <FormattedMessage id="SendGrid.automatic_security"/>
                                            <CommonHelper placement="right" help={<FormattedMessage id="SendGrid.automatic_securityHelp" />} id={'automatic_securityHelp'} />
                                        </div>
                                    </div>
                                    <div className='mt-2 d-flex align-items-center'>
                                        <CustomInput
                                            type="switch"
                                            id="custom_spf"
                                            name="custom_spf"
                                            onChange={this.handleSwitchChange}
                                            checked={custom_spf}
                                        />
                                        <div className='d-flex align-items-center'>
                                            <FormattedMessage id="SendGrid.custom_spf"/>
                                            <CommonHelper placement="right" help={<FormattedMessage id="SendGrid.custom_spfHelp" />} id={'custom_spfHelp'} />
                                        </div>
                                    </div>
                                    <div className='mt-2 d-flex align-items-center'>
                                        <CustomInput
                                            type="switch"
                                            id="custom_dkim_selector"
                                            name="custom_dkim_selector"
                                            onChange={this.handleSwitchChange}
                                            checked={custom_dkim_selector}
                                        />
                                        <div className='d-flex align-items-center'>
                                            <FormattedMessage id="SendGrid.custom_dkim_selector"/>
                                            <CommonHelper placement="right" help={<FormattedMessage id="SendGrid.custom_dkim_selectorHelp" />} id={'custom_dkim_selectorHelp'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </StyledCard>
                </ModalBody>
            </Modal>
        )
    }
}

export default injectIntl(DomainAuthModal)