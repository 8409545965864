import React, { Component, useEffect, useState } from 'react'
import { Button, Card, Col, Row, Input, Form, Label } from 'reactstrap';
import { getAPI, postAPI } from '../../Base/API';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { ErrorAlert } from '../../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import { StyledModal } from '../../Base/CommonUIComponents';
import { handleNotification } from '../../Base/Notification';



export const PreviewTemplateModal = ({ isOpen, toggle, selectedRun }) => {
    const [template, setTemplate] = useState([]);
    const [block, setLoad] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                setError(errorMessage);
                setLoad(false)
                return;
            }
            if (data && data.response && data.response.length > 0) {
                setTemplate(data.response[0]);
            }
            setLoad(false);
        }, `/api/gms/DataQuality/v1/DataQualityReportRun/${selectedRun}/template`);
    }, []);

    return (
        <StyledModal toggleModal={toggle} modal={isOpen} size="xl"  style={{ minWidth: 0 }}>
            <ErrorAlert error={error} />
            <BlockUi tag="div" blocking={block}>
                <Card className="p-2 border-0 mb-2">
                    <h4 className="mb-3">
                        <FormattedMessage id="MonthlyReportRuns.ReportTitle" values={{ date: moment(template.beginDate).format("MMMM YYYY") }} />
                    </h4>

                    <div dangerouslySetInnerHTML={{ __html: template.preview }} />
                </Card>
            </BlockUi>
        </StyledModal>
    );
};


export const ResendReport = ({ isOpen, toggle, selectedRun }) => {
    const [email, setEmail] = useState([]);
    const [block, setLoad] = useState(false);
    const [error, setError] = useState('');


    const resendReport = (e) => {
        setLoad(true);
        e.preventDefault();

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                setError(errorMessage);
                setLoad(false)
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    setLoad(false);
                    return;
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="MonthlyReportRuns.ReportSentSuccessfully" />, <FormattedMessage id="generic.success" />);
                }
            }
            setLoad(false);
            toggle();
        }, `/api/gms/DataQuality/v1/DataQualityReportRun/${selectedRun}/resend`, email);
    };

    return (
        <StyledModal toggleModal={toggle} modal={isOpen} size="lg" >
            <ErrorAlert error={error} />
            <BlockUi tag="div" blocking={block}>
                <Card className="p-2 border-0 mb-2">
                    <Form onSubmit={resendReport}>
                        <Row className="mb-2">
                            <Col>
                                <h5 className="mb-3">
                                    <FormattedMessage id="MonthlyReportRuns.ResendReport" />
                                </h5>
                            </Col>
                            <Col className="text-right col-2">
                                <Button type="submit" className="btn-host btn-sm">
                                    <i className="far fa-paper-plane " />
                                </Button>
                            </Col>
                        </Row>

                        <Label><FormattedMessage id="generic.Email" /></Label>
                        <Input
                            type="text"
                            name="email"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form>
                </Card>
            </BlockUi>
        </StyledModal>
    );
};