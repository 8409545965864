import BootstrapTable from 'react-bootstrap-table-next';
import React, { Component } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Row, Col, Button, UncontrolledTooltip, CustomInput } from 'reactstrap';
import { FormattedMessage } from 'react-intl';


const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;


const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="btn-group" role="group">
        {
            options.map(option => (
                <Button
                    key={option.text}
                    type="button"
                    onClick={() => onSizePerPageChange(option.page)}
                    className={`page-link  ${currSizePerPage === `${option.page}` ? 'bg-host text-white' : 'text-secondary'}`}

                >
                    {option.text}
                </Button>
            ))
        }
    </div>
);

const CustomToggleList = ({ columns, onColumnToggle, toggles, width }) => {
    return <div className="d-flex m-2" style={{ flexWrap: 'wrap' }}>
        {columns.map(column => (column.alwaysVisible !== true &&
            <div style={{ minWidth: '160px', width: (width || '') }} className="pr-3 mb-2" title={column.text}>
                <CustomInput
                    type="checkbox"
                    id={column.dataField}
                    className="text-ellipsis"
                    checked={column.hidden !== true}
                    onChange={() => onColumnToggle(column.dataField)}
                >
                    {column.text}
                </CustomInput>
            </div>
        ))}
    </div>
};


export const CustomTable = ({ data, page, sizePerPage, onTableChange, hideSizePerPage = true, totalSize, columns, selectRow, showTotal, search, remote, exportCSV, rowStyle, keyField, handleCustomExportClick, customExportTooltip, customExportCsv, condensed = true, expandRow, hasPagination = true, title, defaultSorted, toggleList, tableButtons, rightTableButtons, wrapperClasses = "table-responsive" }) => (
    <FormattedMessage id="generic.table.nodata" defaultMessage="No Data Available">
        {placeholder =>
            <FormattedMessage id="generic.table.search" defaultMessage="Search">
                {searchPlaceholder =>
                    <ToolkitProvider
                        keyField={keyField ? keyField : "id"}
                        data={data}
                        columns={columns}
                        search={search}
                        bootstrap4={true}
                        columnToggle
                    >
                        {
                            props => (

                                <div>
                                    {toggleList?.show ?
                                        <CustomToggleList {...props.columnToggleProps}
                                            width={toggleList.width}
                                            onColumnToggle={toggleList.onColumnToggle}
                                        />
                                    : ''}


                                    {search || exportCSV ?
                                        <Row>
                                            {tableButtons}
                                            <Col>{search ?
                                                <SearchBar className="form-control-sm" {...props.searchProps} placeholder={searchPlaceholder} />
                                                : ''}
                                            </Col>
                                            {title && 
                                                <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold'}}>
                                                    {title}
                                                </Col>
                                            }
                                            <Col className="mb-3 text-right">
                                                {rightTableButtons}
                                                { exportCSV ?
                                                    customExportCsv ?
                                                        <CustomExportCSV className="btn btn-host btn-sm float-right shadow" {...props.csvProps} handleOnClick={handleCustomExportClick} tooltipMessage={customExportTooltip}>
                                                            <i className="fas fa-file-excel" />
                                                        </CustomExportCSV>
                                                    :
                                                        <ExportCSVButton className="btn btn-host btn-sm float-right shadow" {...props.csvProps} id="exportCsvButton">
                                                            <i className="fas fa-file-excel"></i>
                                                        </ExportCSVButton>
                                                : '' }
                                            </Col>
                                        </Row>
                                    : ''}

                                    <BootstrapTable {...props.baseProps}
                                        wrapperClasses={wrapperClasses}
                                        noDataIndication={placeholder}
                                        condensed={condensed}
                                        bordered={false}
                                        exportCSV={exportCSV}
                                        pagination={hasPagination ? paginationFactory({ hidePageListOnlyOnePage: true, hideSizePerPage, page, sizePerPage, showTotal: showTotal, totalSize, sizePerPageRenderer }) : false}
                                        onTableChange={onTableChange}
                                        remote={remote}
                                        totalSize={totalSize}
                                        hover
                                        selectRow={selectRow}
                                        rowStyle={rowStyle}
                                        defaultSorted={defaultSorted}
                                        expandRow={expandRow}
                                        headerClasses="tableHeader"
                                    />
                                </div>
                            )
                        }
                    </ToolkitProvider>
                }
            </FormattedMessage>
        }
    </FormattedMessage>
);



export class CustomExportCSV extends Component {

    handleClick() {
        const { handleOnClick } = this.props;
        handleOnClick(() => {
            this.props.onExport();
        });
    }

    render() {
        const { children, tooltipMessage } = this.props;

        return (
            <div>
                <button id="exportCsvButton" className="btn btn-host btn-sm float-right" onClick={this.handleClick.bind(this)}>
                    {children}
                </button>
                <UncontrolledTooltip placement="left" target="exportCsvButton">
                    {tooltipMessage}
                </UncontrolledTooltip>
            </div>
        );
    }
}