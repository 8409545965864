import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Col, Row, Breadcrumb, BreadcrumbItem, Modal, ModalBody, Form } from 'reactstrap';
import { getAPI, deleteAPI } from "../../Base/API";
import { handleNotification } from "../../Base/Notification";
import { StyledCard, EmptyCard, ActiveInactiveStatusBadge, ActiveInactiveStatusCombo } from "../../Base/CommonUIComponents";
import ExportProfilesModal from './ExportProfilesModal';
import CustomSelect from '../../Base/CustomSelect';
import moment from "moment";

export class ReviewProExportProfiles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            exportList: [],
            hotels: [],
            surveys: [],
            selectedExport: null
        };
    }

    componentDidMount() {
        this.getHotels();
    }

    getExports = (evt) => {
        if (evt) evt.preventDefault();
        this.setState({ block: true });

        var param = ''
        if (this.state.status !== null && this.state.status !== undefined) param += `&status=${this.state.status}`;
        if (this.state.propertyId) param += `&property=${this.state.propertyId}`;
        if (this.state.surveyId) param += `&survey=${this.state.surveyId}`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ exportList: data.response });
            }
            this.setState({ block: false });
        }, '/api/gms/Review/v1/reviewexport?reviewProvider=ReviewPro' + param);
    }

    getHotels = () => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                var combo = [];

                data.response.map((item) =>
                    combo.push({ 'value': item.hotelId, 'label': item.name2 })
                )

                this.setState({ hotels: combo });
            }
            this.getSurveys();
        }, '/api/gms/Hotel/v1/hotel/list?onlyFromHotelGroup=true');
    }

    getSurveys = () => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                var list = [];

                data.response && data.response.forEach(survey => {
                    list.push({ value: survey.id, label: survey.name });
                })

                this.setState({ surveys: list });
            }
            this.getProducts();
        }, '/api/gms/ReviewPro/v1/Surveys');
    }

    getProducts = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    var list = [];

                    data.response.forEach(el => { list.push({ label: el.name, value: el.id.toString() }) });

                    this.setState({ products: list });
                }
            }
            this.getExports();
        }, '/api/gms/ReviewPro/v1/Lodgings');
    }

    removeAlert = (e, id) => {
        e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                    return;
                }
                else {
                    handleNotification(data, <FormattedMessage id="ReviewProExportProfiles.ExportRemoved" />, <FormattedMessage id="generic.success" />);
                    this.getExports();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Review/v1/reviewexport/${id}`);
    }

    getAvailableOptions = (list, key) => {
        var options = [];

        if (this.state.selectedExport) {
            options = list.filter(l => !this.state.exportList.some(el => el[key] !== this.state.selectedExport[key] && el[key] === l.value))
        }
        else {
            options = list.filter(l => !this.state.exportList.some(el => el[key] === l.value))
        }

        return options;
    }

    toggleModal = (selectedExport) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedExport: selectedExport ? selectedExport : null
        }));
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    render() {
        const { block, error, surveys, selectedExport, hotels, exportList, products } = this.state;

        return (
            <div>
                <StyledCard block={block} error={error} >
                    <div>
                        <Breadcrumb>
                            <BreadcrumbItem><Link to="/ReviewProMenu"> ReviewPro Config</Link></BreadcrumbItem>
                            <BreadcrumbItem active> Export Profiles </BreadcrumbItem>
                        </Breadcrumb>
                    </div>

                    <Form onSubmit={this.getExports}>
                        <Row className="mb-3">
                            <Col className="col-3">
                                <ActiveInactiveStatusCombo
                                    name='status'
                                    onChangeFunc={this.handleSelect.bind(this)}
                                    placeholder={this.props.intl.formatMessage({ id: "generic.status" })}
                                    required={false}
                                />
                            </Col>
                            <Col sm={3} className="px-1">
                                <CustomSelect
                                    isSearchable isClearable
                                    placeholder={"Survey"}
                                    options={surveys}
                                    onChange={(e) => this.handleSelect("surveyId", e)}
                                />
                            </Col>
                            <Col sm={3}>
                                <CustomSelect
                                    isSearchable isClearable
                                    placeholder={<FormattedMessage id="generic.Product" />}
                                    options={products}
                                    onChange={(e) => this.handleSelect("propertyId", e)}
                                />
                            </Col>

                            <Col className="text-right pt-2 mr-3">
                                <Button className="btn-host btn-sm mr-2" onClick={this.toggleModal}>
                                    <i className="fas fa-plus" />
                                </Button>
                                <Button className="btn-host btn-sm" type="submit">
                                    <i className="icon-icon-search" />
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                    {exportList && exportList.map((el, key) => {
                        const hotel = hotels.find(h => h.value === el.hotelId);
                        const product = products.find(p => p.value === el.propertyId);

                        return <Row key={key}>
                             <Col className="h-100 mb-2">
                                <EmptyCard >
                                    <Row>
                                        <Col className="pointer" onClick={() => this.toggleModal(el)}>
                                            <h5>{el.name}</h5>
                                        </Col>
                                        <Col className="text-right pr-3 col-2">
                                            <Button className="btn-host btn-sm" onClick={(e) => this.removeAlert(e, el.id)}>
                                                <i className="fas fa-trash-alt" />
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col> <b><FormattedMessage id="generic.status"/>: </b> <ActiveInactiveStatusBadge status={el.active} /> </Col>
                                        <Col> <b><FormattedMessage id="generic.Hotel" />:</b> {hotel && hotel.label} </Col>
                                        <Col/>
                                    </Row>
                                    <Row>
                                        <Col> <b>Last Run: </b> {el.lastRun ? moment(el.lastRun).format("YYYY-MM-DD  HH:mm") : ''} </Col>
                                        <Col> <b>Survey:</b> {el.surveyName} </Col>
                                        <Col> <b><FormattedMessage id="generic.Product" />:</b> {product ? product.label : el.propertyId} </Col>
                                    </Row>
                                </EmptyCard>
                            </Col>
                        </Row>
                    })}
                </StyledCard>

                {this.state.modal ?
                    <Modal isOpen={this.state.modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                        <Button onClick={this.toggleModal} className="closeModal">
                            <i className="fas fa-times fa-sm"></i>
                        </Button>
                        <ModalBody>
                            <ExportProfilesModal
                                toggleModal={this.toggleModal}
                                hotels={exportList && exportList.length > 0 ? this.getAvailableOptions(hotels, 'hotelId') : hotels}
                                surveys={exportList && exportList.length > 0 ? this.getAvailableOptions(surveys, 'surveyId') : surveys}
                                products={products}
                                selectedExport={selectedExport}
                                getExports={this.getExports}
                            />
                        </ModalBody>
                    </Modal>
                : ''}
            </div>
        );
    }
}
export default injectIntl(ReviewProExportProfiles)