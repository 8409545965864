import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { injectIntl } from 'react-intl';
import { Button, Card, Col, Row, Breadcrumb, BreadcrumbItem, Badge, FormFeedback, Form, Input, Label } from 'reactstrap';
import { StyledCard } from "../Base/CommonUIComponents";
import { getAPI, postAPI, patchAPI } from "../Base/API";
import { handleNotification } from "../Base/Notification";
import { FormattedMessage } from "react-intl";
import { getSenderChannel } from '../Base/ReferenceDataFunctions';

export class AlertDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            alert: {},
            templates: null,
            isValidName: true
        };
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.getAlert();
        }
    }

    getAlert = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    this.setState({ alert: data.response[0] }, () =>
                        this.getTemplates(data.response[0].channel));
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoitransactional/v2/alerts/${this.props.match.params.id}/template`);
    }

    getTemplates = (type) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                this.setState({
                    templates: data.response ? data.response : null
                });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoitransactional/template/${type}`);
    }

    createAlert = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var body = {
            name: this.state.alert.alertAlias,
            templateId: this.state.alert.templateId,
            channel: this.state.alert.channel,
            interval: this.state.alert.period,
            maxAttempts: this.state.alert.repeat
        };

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="AlertDetails.AlertCreated" />, <FormattedMessage id="generic.success" />);
                    this.props.history.push({ pathname: `/AlertDetails/${data.alertId}` });
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoitransactional/v2/alerts/template`, body);
    }

    editAlert = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var body = {
            alertId: this.state.alert.id,
            name: this.state.alert.alertAlias,
            templateId: this.state.alert.templateId,
            channel: this.state.alert.channel,
            interval: this.state.alert.period,
            maxAttempts: this.state.alert.repeat
        };

        patchAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="AlertDetails.AlertSaved" />, <FormattedMessage id="generic.success" />);
                }
            }

            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoitransactional/v2/alerts/${this.props.match.params.id}/template`, body);
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            alert: {
                ...this.state.alert,
                [name]: value
            }
        });
    }

    handleChannel = (field, data) => {
        const e = { target: { name: field, value: data } };

        this.handleChange(e);
        this.getTemplates(data);
    }

    isButtonDisabled = () => {
        let disabled = true;

        if (this.state.alert.alertAlias && this.state.alert.repeat && this.state.alert.period && this.state.alert.channel && this.state.alert.templateId && this.state.isValidName) {
            disabled = false;
        }

        return disabled;
    }

    validateName = (e) => {
        const { value } = e.target;
        let valid = true;

        if (value.indexOf(' ') >= 0) {
            valid = false;
        }

        this.setState({ isValidName: valid })
    }

    render() {
        const { block, error, alert } = this.state;

        return (
            <StyledCard block={block} error={error} >
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/MarketingConfigEgoi"> Config</Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to="/AlertsList"> Alerts</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Alert Details</BreadcrumbItem>
                    </Breadcrumb>
                </div>

                <Form onSubmit={this.props.match.params.id ? (e) => this.editAlert(e) : (e) => this.createAlert(e)}>
                    <Row className="mb-3">
                        <Col>
                            <h5> {this.props.match.params.id ? <FormattedMessage id="AlertDetails.EditAlert" /> : <FormattedMessage id="AlertDetails.CreateAlert" />}</h5>
                        </Col>
                        <Col className="text-right">
                            <Button className="btn-sm btn-host" type="submit" disabled={this.isButtonDisabled()}>
                                <i className="fas fa-save" />
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Label sm={2}><FormattedMessage id="AlertDetails.Name" /></Label>
                        <Col sm={10}>
                            <Input
                                type="text"
                                name="alertAlias"
                                value={alert && alert.alertAlias ? alert.alertAlias : ''}
                                onChange={(e) => { this.handleChange(e); this.validateName(e) }}
                                required
                                invalid={this.state.isValidName === true ? false : true}
                                readOnly={this.props.match.params.id}
                            />
                            <FormFeedback><FormattedMessage id="AlertDetails.InvalidAlertName" /></FormFeedback>
                        </Col>
                    </Row>
                    <Row>
                        <Label sm={2}> <FormattedMessage id="AlertList.Interval" /></Label>
                        <Col sm={4}>
                            <Input
                                type="number"
                                name="period"
                                value={alert && alert.period ? alert.period : ''}
                                onChange={(e) => this.handleChange(e)}
                                max="86400"
                                required
                            />
                        </Col>
                        <Label sm={2}> <FormattedMessage id="AlertList.MaxAttempts" /></Label>
                        <Col sm={4}>
                            <Input
                                type="number"
                                name="repeat"
                                value={alert && alert.repeat ? alert.repeat : ''}
                                onChange={(e) => this.handleChange(e)}
                                required
                            />
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <h5><FormattedMessage id="Campaigns.Channel" /></h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                {getSenderChannel().map((sc, key) =>
                                    <Col className="col-3">
                                        <Card className="border-0 shadow pointer" body key={key}
                                            style={alert && alert.channel && alert.channel === sc.value.toLowerCase() ? { backgroundColor: '#d3eaff' } : {}}
                                            onClick={() => this.handleChannel('channel', sc.value.toLowerCase())}>
                                            {sc.label}
                                        </Card>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>


                    {alert && alert.channel ?
                        <div>
                            <Row className="mb-3 mt-4">
                                <Col>
                                    <h5><FormattedMessage id="Campaigns.Template" /></h5>
                                </Col>
                            </Row>

                            {Array.isArray(this.state.templates) ? '' : <div dangerouslySetInnerHTML={{ __html: this.state.templates }} />}

                            {this.state.templates && Array.isArray(this.state.templates) && this.state.templates.map((el, k) =>
                                <Card className="p-2 border-0 mb-2 pointer" key={k}
                                    style={alert.templateId && alert.templateId == el.id ? { backgroundColor: '#d3eaff' } : {}}
                                    onClick={() => this.setState({ alert: { ...this.state.alert, templateId: el.id } })}>
                                    <Row>
                                        <Col className="col-3">
                                            <h4>{el.templateName}</h4>
                                            <div className="mb-2 ">{el.subject}</div>
                                            <div>{el.clickTracking ? <Badge className="mb-2 p-2" color="primary">Click Tracking</Badge> : ''}</div>
                                            <div>{el.openTracking ? <Badge className="mb-2 p-2" color="primary">Open Tracking</Badge> : ''}</div>
                                        </Col>
                                        <Col>
                                            <div dangerouslySetInnerHTML={{ __html: el.htmlBody }} style={{ maxHeight: "300px", overflow: "scroll", overflowX: "hidden" }} />
                                        </Col>
                                    </Row>
                                </Card>
                            )}
                        </div>
                    : ''}
                </Form>
            </StyledCard>
        );
    }
}
export default injectIntl(AlertDetails)
