import React, { Component } from 'react';
import { Row, Col, Card, CardBody, UncontrolledTooltip, Button } from 'reactstrap';
import { handleNotification } from '../../Base/Notification';
import { FormattedMessage, injectIntl, FormattedNumber } from 'react-intl';
import { CardStatusBadge, DefaultPage } from '../../Base/CommonUIComponents';
import { ErrorAlert } from "../../Base/ErrorAlert";
import { getAPI } from '../../Base/API';
import BlockUi from 'react-block-ui';
import moment from 'moment';
import EmitVoucherModal from '../../Card/EmitVoucherModal';
import { getVoucherTypesOptions } from '../../Card/HeyCardFunctions';

class ProfileVouchers extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: true,
            vouchers: [],
            newVoucherModal: false
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.props.profileId) {
            this.getVouchers();
        }
    }

    getVouchers = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                if (data.response && data.response.length === 1 && data.response[0].vouchers && data.response[0].vouchers.length > 0) {
                    this.setState({ vouchers: data.response[0].vouchers, error: errorMessage, block: false });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Voucher/Profile/v1/Profile/${this.props.profileId}?profileType=PN`);
    }

    updateVouchers = () => {
        this.setState({
            newVoucherModal: false
        }, this.getVouchers)
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    toggleModal = (modal) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }));
    }
    

    render() {
        const { vouchers, block, error, newVoucherModal } = this.state;
        const { canEditProfile } = this.props;

        return (
            <BlockUi tag="div" blocking={block}>
                {newVoucherModal ?
                    <EmitVoucherModal
                        modal={newVoucherModal}
                        toggleModal={() => this.toggleModal("newVoucherModal")}
                        profileId={this.props.profileId}
                        profile={this.props.profile}
                        updateVouchers={this.updateVouchers}
                        renderClientInfo={true}
                    />
                : ''}

                <ErrorAlert error={error} />
                <Card className="ml-1 my-0 mr-0 border-0  bg-light h-100">
                    <div className="m-2 px-2">
                        <Row className="mb-3">
                            <Col>
                                <h5><i className="icon-icon-vouchers mr-1" /><FormattedMessage id="ProfileDetails.Vouchers" /></h5>
                            </Col>
                            {canEditProfile &&
                                <Col className="text-right col-1">
                                    <Button className="btn-sm btn-host " onClick={() => this.toggleModal("newVoucherModal")}>
                                        <i className="fas fa-plus" />
                                    </Button>
                                </Col>
                            }
                        </Row>
                        <div className="verticalScroll p-2 pr-4" style={{ height: '81vh' }}>
                            {vouchers.length > 0 ?
                                <Row>
                                    {vouchers.map((voucher, key) =>
                                        <Col key={key} className="col-6 mb-3">
                                            <Card className="shadow border-0 h-100 pb-2 pointer" body onClick={() => this.props.history.push({ pathname: `/VoucherDetails/${voucher.id}` })}>
                                                <Row>
                                                    <Col>
                                                        {voucher.isPaymentMode ?
                                                            <span>
                                                                <i id={`profileVoucher-${getVoucherTypesOptions()[0].value}-${key}`} className="fas fa-wallet" />
                                                                <UncontrolledTooltip placement="bottom" target={`profileVoucher-${getVoucherTypesOptions()[0].value}-${key}`}>
                                                                    {getVoucherTypesOptions()[0].label}
                                                                </UncontrolledTooltip>
                                                            </span>
                                                            :
                                                            <span>
                                                                <i id={`profileVoucher-${getVoucherTypesOptions()[1].value}-${key}`} className="fas fa-gift" />
                                                                <UncontrolledTooltip placement="bottom" target={`profileVoucher-${getVoucherTypesOptions()[1].value}-${key}`}>
                                                                    {getVoucherTypesOptions()[1].label}
                                                                </UncontrolledTooltip>
                                                            </span>
                                                        }
                                                        <span className="ml-2">
                                                            <b>{voucher.voucherTypeCode}</b>
                                                        </span>
                                                    </Col>
                                                    <Col sm={2}>
                                                        <span className="float-right">
                                                            <CardStatusBadge status={voucher.status} />
                                                        </span>
                                                    </Col>
                                                </Row>

                                                <hr className="my-3" />

                                                <Row>
                                                    <Col>
                                                        <div className="title-sm"> Hotel </div>
                                                        {global.hotelList && global.hotelList.some(htl => htl.value === voucher.hotelId) ? global.hotelList.find(htl => htl.value === voucher.hotelId).label : ''}
                                                    </Col>
                                                    <Col className="text-right">
                                                        <div className="title-sm"> <FormattedMessage id="ReferenceData.CreatedAt" /> </div>
                                                        {voucher.createdAt ? moment(voucher.createdAt).format("YYYY-MM-DD") : ''} ({voucher.saleOrigin})
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3 mb-2">
                                                    <Col>
                                                        <div className="title-sm">
                                                            <FormattedMessage id="ProfileVouchers.Validity" />
                                                        </div>
                                                        <div>
                                                            {voucher.validUntil && moment(moment().format("YYYY-MM-DD")).isSameOrBefore(moment(voucher.validUntil).format("YYYY-MM-DD")) ?
                                                                <span>
                                                                    <i id={`profileVoucher-valid-${key}`} className="far fa-calendar-check text-success fa-sm mr-2" />
                                                                    <UncontrolledTooltip placement="bottom" target={`profileVoucher-valid-${key}`}>
                                                                        <FormattedMessage id="ProfileVouchers.ValidFromTo" values={{ from: moment(voucher.validFrom).format("YYYY-MM-DD"), to: moment(voucher.validUntil).format("YYYY-MM-DD") }} />
                                                                    </UncontrolledTooltip>
                                                                </span>
                                                                :
                                                                <span>
                                                                    <i id={`profileVoucher-expired-${key}`} className="far fa-calendar-times text-danger fa-sm mr-2" />
                                                                    <UncontrolledTooltip placement="bottom" target={`profileVoucher-expired-${key}`}>
                                                                        <FormattedMessage id="ProfileVouchers.ValidFromTo" values={{ from: moment(voucher.validFrom).format("YYYY-MM-DD"), to: moment(voucher.validUntil).format("YYYY-MM-DD") }} />
                                                                    </UncontrolledTooltip>
                                                                </span>
                                                            }

                                                            <span>
                                                                {moment(voucher.validFrom).format("YYYY-MM-DD")}
                                                                <FormattedMessage id="ProfileVouchers.to" />
                                                                {moment(voucher.validUntil).format("YYYY-MM-DD")}
                                                            </span>
                                                        </div>

                                                    </Col>
                                                    <Col className="text-right">
                                                        <div className="title-sm"> <FormattedMessage id="ProfileVouchers.Value" /> </div>
                                                        <h5 className="text-right">
                                                            <b>
                                                                <FormattedNumber value={voucher.voucherValue} maximumFractionDigits={2} style="currency" currency={global.hotelCurrency} />
                                                            </b>
                                                        </h5>
                                                    </Col>
                                                </Row>

                                            </Card>
                                        </Col>
                                    )}
                                </Row>
                                : <DefaultPage text="ProfileVouchers.NoVouchersAvailable" icon="icon-icon-vouchers" />
                            }
                        </div>
                    </div>
                </Card>
            </BlockUi>
        );
    }
}
export default injectIntl(ProfileVouchers)