import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Col, Row } from 'reactstrap';
import { getAPI, postAPI } from "../Base/API";
import { StyledCard } from "../Base/CommonUIComponents";
import { CustomTable } from '../Base/CustomTable';
import { handleNotification } from '../Base/Notification';
import { getJobName } from '../Base/ReferenceDataFunctions';

export class ScheduleTask extends Component {

    constructor(props) {
        super(props);
        this.getScheduleTasks = this.getScheduleTasks.bind(this);
        this.changeJobState = this.changeJobState.bind(this);
        this.state = {
            block: true,
            scheduleTaskList: [],
            selectedTask: null,
            searchText: null,
            resultsPerPage: 10,
            currentPage: 1,
            jobNames: getJobName(this.props.intl)
        };
    }

    componentDidMount() {
        this.getScheduleTasks();
    }

    getScheduleTasks() {
        let param = '';

        if (this.state.stState) {
            param += '?state=' + this.state.stState;
        }

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ error: errorMessage, block: false, scheduleTaskList: data });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, '/api/gmsscheduletask/ScheduleTasks' + param);
    }

    changeJobState(action, cb) {
        this.setState({ block: true });

        postAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                handleNotification(data, '', <FormattedMessage id="generic.success"/>)
                this.setState({ teste: data }, cb);
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gmsscheduletask/ScheduleTasks/${action}?jobName=${this.state.selectedTask.id}`);
    }

    changeJobStateWithCallback(action) {
        this.changeJobState(action, this.getScheduleTasks);
    }

    changeJobStateWithoutCallback(action) {
        this.changeJobState(action, _ => { });
    }

    setFilterSelectedChannel = (_, combo) => {
        const label = combo ? combo.label : null
        const val = combo ? combo.value : null
        this.setState({ channelCode: label, channelConfigId: val });
    }

    getName = (cell) => {
        const jobName = this.state.jobNames.find(o => o.value === cell);

        return (
            <div style={{marginLeft: '4%'}}>
                <Row>
                    <Col>
                        {cell && jobName ? jobName.label : cell}
                   </Col>
                </Row>
                <Row>
                    <Col style={{ fontSize: '10px', color: 'grey'} }>
                        {cell && jobName ? jobName.description : '' }
                    </Col>
                </Row> 
            </div>
        )
    }

    render() {
        const { block, error, scheduleTaskList, selectedTask } = this.state;

        const columns = [
            {
                dataField: 'id',
                text: this.props.intl.formatMessage({ id: "ScheduleTask.Name" }),
                sort: true,
                formatter: (cell) => this.getName(cell),
                headerStyle: () => {
                    return { width: "15%", paddingLeft: '1%' };
                },
                filterValue: (cell) => {
                    const jobName = this.state.jobNames.find(o => o.value === cell);
                    
                    if (jobName) {
                        return `${jobName.value} ${jobName.label} ${jobName.description}`
                    }

                    return cell;
                },
            },
            {
                dataField: 'state',
                text: this.props.intl.formatMessage({ id: "ScheduleTask.State" }),
                sort: true,
                headerStyle: () => {
                    return { width: "10%" };
                }
            },
            {
                dataField: 'lastFireTime',
                text: this.props.intl.formatMessage({ id: "ScheduleTask.LastFireTime" }),
                sort: true,
                headerStyle: () => {
                    return { width: "15%" };
                }
            },
            {
                dataField: 'nextFireTime',
                text: this.props.intl.formatMessage({ id: "ScheduleTask.NextFireTime" }),
                sort: true,
                headerStyle: () => {
                    return { width: "15%" };
                }
            },
            {
                dataField: 'priority',
                text: this.props.intl.formatMessage({ id: "ScheduleTask.Priority" }),
                sort: true

            },
            {
                dataField: 'cronExpression',
                text: this.props.intl.formatMessage({ id: "ScheduleTask.CronExpression" }),
                sort: true
            },
            {
                dataField: 'processingInfo',
                text: this.props.intl.formatMessage({ id: "ScheduleTask.ProcessingInfo" }),
                sort: true
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row) => this.setState({ selectedTask: row })
        };

        return (
            <StyledCard block={block} error={error} icon={'fas fa-list'} title={'ScheduleTask.title'}>
                <Row>
                    <Col className="mb-1">
                        <Button className="btn btn-sm btn-host float-right mr-1" disabled={selectedTask === null} onClick={this.changeJobStateWithoutCallback.bind(this, 'RunJob')}>
                            <i className="fas fa-walking" />
                        </Button>
                        <Button className="btn btn-sm btn-host float-right mr-1" onClick={this.getScheduleTasks.bind(this)}>
                            <i className="fas fa-sync" />
                        </Button>
                        <Button className="btn btn-sm btn-host float-right mr-1" disabled={selectedTask === null ? true : false}>
                            <i className="fas fa-clock" />
                        </Button>
                        <Button
                            className="btn btn-sm btn-host float-right mr-1"
                            disabled={selectedTask === null || selectedTask.state !== 'Paused' ? true : false}
                            onClick={this.changeJobStateWithCallback.bind(this, 'ResumeJob')}
                        >
                            <i className="fas fa-play" />
                        </Button>
                        <Button
                            className="btn btn-sm btn-host float-right mr-1"
                            disabled={selectedTask === null || selectedTask.state === 'Paused' ? true : false}
                            onClick={this.changeJobStateWithCallback.bind(this, 'PauseJob')}
                        >
                            <i className="fas fa-pause" />
                        </Button>
                        <Button
                            className="btn btn-sm btn-host float-right mr-1"
                            disabled={selectedTask === null || selectedTask.state === 'Paused' ? true : false}
                            onClick={this.changeJobStateWithCallback.bind(this, 'InterruptJob')}
                        >
                            <i className="fas fa-stop" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col className="h-100">
                        <CustomTable
                            data={scheduleTaskList || []}
                            columns={columns}
                            totalSize={scheduleTaskList.length}
                            shadow={false}
                            selectRow={selectRow}
                            search={true}
                            hideSizePerPage={false}
                            showTotal={true}
                            remote={false}
                        />
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}
export default injectIntl(ScheduleTask)
