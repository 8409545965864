import React, { Component } from 'react'
import { Row, Col, Button, Form, Nav, NavLink, NavItem } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BlankCard, StyledModal } from '../Base/CommonUIComponents';
import { handleNotification } from '../Base/Notification';
import { getAPI, getAPIBlob, postAPI } from '../Base/API';

import Handlebars from 'handlebars';
import moment from 'moment';
import { getMasterTemplateFileType } from './SalesProcessFunctions';


class SalesProcessEditTemplateModal extends Component {
    constructor(props) {
        super(props);
        this.templateRef = React.createRef();

        this.state = {
            block: true,
            error: null,
            dealExample: {
                "proposalData": {
                    "startDate": "2024-03-05T00:00:00Z",
                    "endDate": "2025-03-07T00:00:00Z",
                    "adults": 4,
                    "children": 0,
                    "pax": 4,
                    "numberOfNights": 2,
                    "numberOfSpaces": 1,
                    "taxToPay": 0,
                    "amountBeforeTax": 0,
                    "amountAfterTax": 80685,
                    "dailyRoomList": [
                        {
                            "totalValue": 0,
                            "totalRooms": 2,
                            "date": "2024-09-27T00:00:00",
                            "categories": [
                                {
                                    "catCode": "DDBL",
                                    "numberOfRooms": 1,
                                    "valueOfRooms": 0,
                                    "isRate": false
                                },
                                {
                                    "catCode": "DTWN",
                                    "numberOfRooms": 0,
                                    "valueOfRooms": 0,
                                    "isRate": false
                                },
                                {
                                    "catCode": "BB",
                                    "numberOfRooms": 1,
                                    "valueOfRooms": 0,
                                    "isRate": true
                                }
                            ]
                        },
                        {
                            "totalValue": 0,
                            "totalRooms": 1,
                            "date": "2024-09-28T00:00:00",
                            "categories": [
                                {
                                    "catCode": "DDBL",
                                    "numberOfRooms": 1,
                                    "valueOfRooms": 0,
                                    "isRate": false
                                },
                                {
                                    "catCode": "DTWN",
                                    "numberOfRooms": 1,
                                    "valueOfRooms": 0,
                                    "isRate": false
                                },
                                {
                                    "catCode": "BB",
                                    "numberOfRooms": 1,
                                    "valueOfRooms": 0,
                                    "isRate": true
                                }
                            ]
                        }
                    ],
                    "equipment": {
                        "numberOfEquipment": 1,
                        "equipmentValue": 0,
                        "equipmentList": [
                            {
                                "type": "EQUIPMENT",
                                "chargeCode": "300102",
                                "chargeDescription": "Serviço Internet",
                                "startDate": "2024-10-01T09:00:00",
                                "endDate": "2024-10-01T15:00:00",
                                "quantity": 1,
                                "pax": 0,
                                "unitPrice": null,
                                "amountAfterTax": 3385,
                                "taxToPay": null,
                                "amountBeforeTax": 0,
                                "numberOfDays": null,
                                "dailyPrice": null,
                                "venue": "Sala A"
                            },
                        ]
                    },
                    "fb": {
                        "numberOfEquipment": 11,
                        "equipmentValue": 0,
                        "equipmentList": [
                            {
                                "type": "FB",
                                "chargeCode": "Room_FB",
                                "chargeDescription": null,
                                "startDate": null,
                                "endDate": null,
                                "quantity": 2,
                                "unitPrice": 120,
                                "amountAfterTax": 200,
                                "taxToPay": null,
                                "amountBeforeTax": 0,
                                "numberOfDays": 1,
                                "dailyPrice": null,
                                "venue": null
                            },
                        ]
                    },
                    "package": {
                        "numberOfEquipment": 4,
                        "equipmentValue": 0,
                        "equipmentList": [
                            {
                                "type": "FB",
                                "chargeCode": "200251",
                                "chargeDescription": "Banquetes Comidas",
                                "startDate": "0001-01-01T00:00:00",
                                "endDate": "0001-01-01T00:00:00",
                                "quantity": 1,
                                "pax": 136,
                                "unitPrice": 0,
                                "amountAfterTax": 21230,
                                "taxToPay": null,
                                "amountBeforeTax": 18787.6106,
                                "numberOfDays": null,
                                "dailyPrice": null,
                                "venue": null
                            },
                        ]
                    },
                    "space": {
                        "numberOfSpaces": 44,
                        "spacesValue": 0,
                        "spaceList": [
                            {
                                "spaceName": "Terraço",
                                "spaceTypeDescription": "Outros",
                                "fromDate": "2024-09-30T15:30:00",
                                "toDate": "2024-09-30T20:00:00",
                                "adults": 2,
                                "children": 0,
                                "numberOfDays": 1,
                                "totalValue": 0,
                                "pax": 2,
                                "equipment": {
                                    "numberOfEquipment": 0,
                                    "equipmentValue": 0,
                                    "equipmentList": []
                                },
                                "dailyPrice": 0
                            }
                        ]
                    },
                    "totalRooms": 0,
                    "totalRoomNights": 2,
                    "totalRoomsValue": 0,
                    "optionDate": null,
                    "proposalDate": "2024-11-18T14:32:43.5141782+00:00",
                    "resNumber": null,
                    "catCodeList": [
                        "DDBL",
                        "DTWN",
                    ],
                    "rateCodeList": [
                        "BB"
                    ],
                    "userContact": "any@any.com",
                    showComissionTable: true,
                    "lodgingComission": { value: 0, isVisible: true },
                    "fandBComission": { value: 0, isVisible: true },
                    "avComission": { value: 0, isVisible: true },
                    "dynamicData": "Lorem ipsum odor amet, consectetuer adipiscing elit. Eget nunc justo arcu lorem vulputate gravida malesuada tempus."
                }
            }
        };
    }

    componentDidMount() {
        this.getTemplates(this.props.masterTemplate?.id)
    }

    getTemplates = (id) => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                const templates = data.data;
                let selectedTemplate = null;
                let languages = [];
                let activeTab = ''

                if (this.props.hasPreview && data.data?.length > 0) {

                    data.data.forEach(template => {
                        template.htmlPreview = this.fillHandlebars(template.exportedHTML);

                        languages.push({ languageCode: template.languageCode, subject: template.subject });
                    })

                    selectedTemplate = data.data[0];
                    activeTab = data.data[0].languageCode;
                }

                this.setState({ templates, languages, selectedTemplate, activeTab, block: false });
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/communication/templates?masterTemplateId=${id}`);
    }

    downloadFile = (template) => {
        this.setState({ block: true }, () => {
            getAPIBlob(result => {
                const { data, error, headers } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    else {
                        const blob = new Blob([data], { type: headers["content-type"] });
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');

                        a.href = url;
                        var f = headers["content-disposition"] ? headers["content-disposition"].match("filename=([^;]*);") : [template.subject + '.' + (this.props.masterTemplate?.fileType?.toLowerCase() || '.docx')];
                        a.download = f[1];
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                    }

                    this.setState({ block: false });
                    return;
                }
                else
                    this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/communication/template/${template.id}/download`)
        });
    }

    toggleTab = (lang) => {
        const selectedTemplate = this.state.templates.find(t => t.languageCode === lang);

        this.setState({ selectedTemplate, activeTab: lang });
    }

    fillHandlebars = (templateHtml) => {
        if (!templateHtml) return;

        let template = Handlebars.compile(templateHtml);

        Handlebars.registerHelper('formatDate', function (date) {
            return date ? moment(date).format("DD/MM/YYYY") : '-'
        })

        const data = { ...this.state.dealExample }

        return template(data);
    }

    prt = () => {
        var divContents = document.getElementById("printThis").innerHTML;
        var a = window.open('', 'PRINT');
        a.document.write(divContents);
        a.document.close();

        a.onafterprint = a.close;


        var is_chrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;

        a.onload = function () { // necessary if the div contain images
            a.focus();
            a.print();
            a.close();
        };
    }

    render() {
        const { modal, toggleModal, masterTemplate, hasPreview } = this.props;
        const { block, error, languages, selectedTemplate, activeTab, templates } = this.state;
        
        const fileType = getMasterTemplateFileType().find(el => el.value === masterTemplate.fileType);

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size={hasPreview ? "xl" : "md"} >
                <BlankCard style={{ maxHeight: '80vh' }} block={block} error={error}>
                    <Form onSubmit={(e) => this.props.updateMasterTemplate(e)}>
                        <Row>
                            <Col>
                                <h4>
                                    <i className={`${fileType?.icon || 'far fa-file'} mr-2`} />
                                    {masterTemplate?.name}
                                </h4>
                            </Col>
                        </Row>

                        {hasPreview ?
                            <>
                                {languages?.length > 0 ?
                                    <Nav tabs className='my-3'>
                                        {languages?.map((lang, key) =>
                                            <NavItem key={key}>
                                                <NavLink className={activeTab === lang.languageCode ? 'tab-border-host text-host  border-0' : 'border-0'} onClick={() => this.toggleTab(lang.languageCode)} style={{ cursor: "pointer" }}>
                                                    <i className={`fas flag-icon flag-icon-${lang.languageCode?.split('-')[1]?.toLowerCase()} mr-2`} /> {lang.subject}
                                                </NavLink>
                                            </NavItem>
                                        )}
                                    </Nav>
                                    : ''}
                                <Row>
                                    {selectedTemplate ?
                                        <Col className="text-center border p-0" style={{ minHeight: '70vh' }} >
                                            <div id="printThis" style={{ width: "100%", height: "100%" }}>
                                                <iframe
                                                    id={`myIframe-SalesProcessDownloadProFormaModal`}
                                                    title="Meu iFrame SalesProcessDownloadProFormaModal"
                                                    srcDoc={selectedTemplate.htmlPreview}
                                                    height="100%"
                                                    width="100%"
                                                    ref={this.templateRef}
                                                    style={{ border: "none" }}
                                                    onLoad={() => {
                                                        const iframe = document.getElementById('myIframe-SalesProcessDownloadProFormaModal');
                                                        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                                                        iframeDoc.documentElement.classList.add('veryCoolScrollBar');

                                                        const styleElement = iframeDoc.createElement('style');
                                                        styleElement.textContent = `
                                                        .veryCoolScrollBar::-webkit-scrollbar {
                                                            height: 1px;
                                                            width: 4px;
                                                        }

                                                        .veryCoolScrollBar::-webkit-scrollbar-track {
                                                            background: rgb(241, 241, 241);
                                                            border-radius: 10px;
                                                        }

                                                        .veryCoolScrollBar::-webkit-scrollbar-thumb {
                                                            border-radius: 10px;
                                                            background: rgb(193, 193, 193);
                                                        }
                                                        `;
                                                        iframeDoc.head.appendChild(styleElement);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        :
                                        <Col>
                                            <p className="text-muted pt-4"><FormattedMessage id="SalesProcess.NoTemplateAvailable" /></p>
                                        </Col>
                                    }
                                </Row>
                            </>
                            :
                            <>
                                {templates?.length > 0 ?
                                    templates.map((template, index) =>
                                        <Row key={index} className="py-2 overbglight mt-3 pointer" title={`Download ${template.subject}`} onClick={_ => this.downloadFile(template)}>
                                            <Col>
                                                <i className={`fas flag-icon flag-icon-${template.languageCode?.split('-')[1]?.toLowerCase()} mr-2`} /> {template.subject}
                                            </Col>
                                            <Col sm={2} className="text-right">
                                                <i className="fas fa-download"/>
                                            </Col>
                                        </Row>
                                    )
                                    :
                                    <Col>
                                        <p className="text-muted pt-4"><FormattedMessage id="SalesProcess.NoFilesAvailable" /></p>
                                    </Col>
                                }
                            </>
                        }
                    </Form>
                </BlankCard>
            </StyledModal>
        )
    }
}

export default injectIntl(SalesProcessEditTemplateModal);