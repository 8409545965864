import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Col, Row, TabContent, TabPane } from 'reactstrap';
import { StyledCard } from "../Base/CommonUIComponents";
import { getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import GoogleBusinessMedia from "./GoogleBusinessMedia";
import GoogleBusinessLocationDetail from './GoogleBusinessLocationDetail';
import GoogleBusinessQA from './GoogleBusinessQA';
import GoogleBusinessReview from './GoogleBusinessReview';
import GoogleSideMenu from './GoogleSideMenu';
import GoogleBusinessPerformance from './GoogleBusinessPerformance';
import GoogleBusinessPosts from './GoogleBusinessPosts';
import GoogleBusinessLocationActions from './GoogleBusinessLocationActions';

class GoogleBusinessConfig extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pages: [
                { name: 'GoogleBusinessLocations', icon: 'fas fa-store' },
                { name: 'GoogleBusinessLocationActions', icon: 'fas fa-location-arrow' },
                { name: 'GoogleBusinessQA', icon: 'far fa-comments', badgeCount: 0 },
                { name: 'GoogleBusinessReview', icon: 'far fa-comment-alt', badgeCount: 0 },
                { name: 'GoogleBusinessNotification', icon: 'fas fa-bell' },
                { name: 'GoogleBusinessPosts', icon: 'far fa-plus-square' },
                { name: 'GoogleBusinessMedia', icon: 'far fa-images' },
                { name: 'GoogleBusinessPerformance', icon: 'fas fa-chart-bar' },
            ],
            categoryOptions: [],
            accountId: null,
            block: true,
            error: null,
            accounts: [],
            accountsOptions: [],
            locationList: [],
            activeTab: 'GoogleBusinessLocations',
            averageRating: null,
            totalReviewCount: null,
            profilePhoto: null,
            openUpload: false,
            unverifiedLocation: false,
        };
    }

    componentDidMount() {
        this.checkGoogleAuth();
    }

    checkGoogleAuth = () => {
        const search = window.location.search;
        if(!search || search.length === 0) {
            this.getAccounts();
            return;
        }

        const start = search.indexOf("code=") + 5;
        const end = search.indexOf("&scope=");

        if(start === -1 || end === -1) {
            this.getAccounts();
            return;
        }

        const code = search.substring(start, end);
        
        if(code){
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if(data?.errors?.length){
                    errorMessage.push({ message: data.errors[0].message, stack: data.errors[0].stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data?.response && data.response.length) {
                    this.setState({ block: false });
                    this.getAccounts();
                    return;
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/Google/v1/ExchangeCode?code=${code}`);
        }
        else{
            this.getAccounts();
        }
    }

    getCategories = () => {
        const { intl } = this.props;
        
        getAPI(result => {
            const { data } = result;
            if (data && data.response && data.response[0].categories) {
                const categoryList = data.response[0].categories;

                const categoryOptions = categoryList?.map(el => {
                    return { value: el.name, label: el.displayName };
                });

                this.setState({ categoryOptions, block: false });
                return;
            };
            this.setState({ block: false });
        }, `/api/gms/Google/v1/accounts/location/categories?lang=${intl.locale === "en" ? "en-US" : "pt-PT"}`);
    }

    getAccounts = () => { 
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0 || !data.response) {
                    handleNotification(data);
                }
                else {
                    if(data.response[0]?.RedirectUri){
                        this.setState({ block: false }, () => window.open(data.response[0]?.RedirectUri, '_blank'));
                    }
                    else{
                        data.response[0].accounts = data.response[0]?.accounts?.map(el => {
                            el.name = el.name.substring(el?.name.indexOf('/') + 1, el?.name.length);
                            return el;
                        });

                        const accounts = data.response[0]?.accounts;
                        const accountId = accounts[0]?.name;
                        
                        this.setState({ accounts, accountId, error: errorMessage }, () => this.getLocations());
                    }
                    return;
                }
            }
            this.setState({ error: errorMessage, block: false });
            
        }, `/api/gms/Google/v1/accounts`)
    }

    getLocations = () =>{
        const { accountId } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    this.setState({ block: false }, () => handleNotification(data));
                }
                else {
                    const locationList = data.response.map(el => {
                        el.name = el.name?.substring(el.name.indexOf('/') + 1, el.name.length);
                        return el;
                    });

                    const locationsOptions = locationList?.map(el => {
                        return({
                            value: el.name,
                            label: el.title
                        });
                    });

                    const selectedLocation = data.response[0]?.name;

                    this.setState({ locationList, locationsOptions, selectedLocation, error: errorMessage }, () => this.getLocationDetail());
                }
            }
            else {
                this.setState({ error: errorMessage, block: false, });
            }
        }, `/api/gms/Google/v1/accounts/${accountId}/location`)
    }

    getLocationDetail = () => {
        let { accountId, selectedLocation, pages } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    this.setState({ block: false }, () => handleNotification(data));
                }
                else {
                    data.response.map(el => {
                        el.name = el.name?.substring(el.name.indexOf('/') + 1, el.name.length);
                        return el;
                    });

                    const canHaveFoodMenus = data.response[0]?.metadata?.canHaveFoodMenu;

                    if(canHaveFoodMenus) {
                        pages.push({ name: 'GoogleBusinessFood', icon: 'fas fa-utensils' });
                    }

                    this.setState({ pages, locationDetail: data.response[0], error: errorMessage, accountId }, () => this.getCategories());
                }
            }
            else {
                this.setState({ error: errorMessage, block: false, accountId });
            }
        }, `/api/gms/Google/v1/accounts/location/${selectedLocation}`)
    }

    handleSelect = (combo) => {
        this.setState({
            selectedLocation: combo ? combo.value : null, block: true
        },() => this.getLocationDetail());
    }

    toggle = (tab, openUpload) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
                openUpload
            });
        }
    }
    
    toggleOpenUpload = () => {
        this.setState({ openUpload: false });
    }

    handleQuestionLength = (length) => {
        const { pages } = this.state;    
        pages.find(({name}) => name === 'GoogleBusinessQA').badgeCount = length;
        this.setState({ pages });
    }

    handleReviewsLength = (length) => {
        const { pages } = this.state;    
        pages.find(({name}) => name === 'GoogleBusinessReview').badgeCount = length;
        this.setState({ pages });
    }

    handleRating = (averageRating, totalReviewCount) => {
        this.setState({ averageRating, totalReviewCount });
    }

    setProfilePhoto = (url) => {
        this.setState({ profilePhoto: url });
    }

    handleUnverifiedLocation = () => {
        const { locationList, selectedLocation } = this.state;
        const local = locationList?.find(({name}) => name === selectedLocation);
        if(local) local.unverifiedLocation = true;
        this.setState({ locationList });
    }

    render() {
        const { block, error, locationDetail, selectedLocation, locationsOptions, locationList, activeTab, accountId, averageRating, totalReviewCount, pages,
        profilePhoto, openUpload, categoryOptions } = this.state;
        
        const local = locationList.find(({name}) => name === selectedLocation);

        return (
            <StyledCard block={block} error={error} >
                <Row className='mt-4'>
                    <Col className='col-3'>
                        <GoogleSideMenu
                            pages={pages}
                            locationsOptions={locationsOptions}
                            selectedLocation={selectedLocation}
                            handleSelect={this.handleSelect}
                            activeTab={activeTab}
                            unverifiedLocation={local?.unverifiedLocation}
                            toggle={this.toggle}
                            averageRating={averageRating}
                            totalReviewCount={totalReviewCount}
                            location={locationDetail}
                        />
                    </Col>
                    <Col className="col-9">
                        {selectedLocation ?
                            <TabContent activeTab={activeTab} className='border-0'>
                                <TabPane tabId='GoogleBusinessLocations' className='border-0'>
                                    <GoogleBusinessLocationDetail
                                        categoryOptions={categoryOptions}
                                        location={locationDetail}
                                        toggle={this.toggle}
                                        selectedLocation={selectedLocation}
                                        profilePhoto={profilePhoto}
                                        getLocationDetail={this.getLocationDetail}
                                    />
                                </TabPane>
                                <TabPane tabId='GoogleBusinessLocationActions' className='border-0'>
                                    <GoogleBusinessLocationActions
                                        handleUnverifiedLocation={this.handleUnverifiedLocation}
                                        selectedLocation={selectedLocation}
                                        location={locationDetail}
                                    />
                                </TabPane>
                                <TabPane tabId='GoogleBusinessQA' className='border-0'>
                                    <GoogleBusinessQA
                                        hotelName={locationList?.find(({name}) => name === selectedLocation)?.title}
                                        selectedLocation={selectedLocation}
                                        handleQuestionLength={this.handleQuestionLength}
                                    />
                                </TabPane>
                                <TabPane tabId='GoogleBusinessReview' className='border-0'>
                                    <GoogleBusinessReview
                                        accountId={accountId}
                                        selectedLocation={selectedLocation}
                                        handleReviewsLength={this.handleReviewsLength}
                                        handleRating={this.handleRating}
                                        hotelName={locationList?.find(({name}) => name === selectedLocation)?.title}
                                    />
                                </TabPane>
                                <TabPane tabId='GoogleBusinessNotification' className='border-0'>
                                    {/* <GoogleBusinessNotification
                                        location={locationDetail}
                                    /> */}
                                </TabPane>
                                <TabPane tabId='GoogleBusinessPosts' className='border-0'>
                                    <GoogleBusinessPosts
                                        accountId={accountId}
                                        selectedLocation={selectedLocation}
                                        locationDetail={locationDetail}
                                        profilePhoto={profilePhoto}
                                    />
                                </TabPane>
                                <TabPane tabId='GoogleBusinessFood' className='border-0'>
                                    {/* <GoogleBusinessFood
                                        location={locationDetail}
                                    /> */}
                                </TabPane>
                                <TabPane tabId='GoogleBusinessMedia' className='border-0'>
                                    <GoogleBusinessMedia
                                        accountId={accountId}
                                        selectedLocation={selectedLocation}
                                        setProfilePhoto={this.setProfilePhoto}
                                        openUpload={openUpload}
                                        toggleOpenUpload={this.toggleOpenUpload}
                                    />
                                </TabPane>
                                <TabPane tabId='GoogleBusinessPerformance' className='border-0 p-0'>
                                    <GoogleBusinessPerformance
                                        selectedLocation={selectedLocation}
                                        handleUnverifiedLocation={this.handleUnverifiedLocation}
                                    />
                                </TabPane>
                            </TabContent>
                        :''}
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}

export default injectIntl(GoogleBusinessConfig);