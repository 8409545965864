import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';

export default class GoogleStars extends Component {
    render() {
        const { averageRating, totalReviewCount } = this.props;
        const averageRatingInt = parseInt(averageRating);

        return (
            averageRating ?
                <div className='mt-2'>
                    <span className='mr-1'>{parseFloat(averageRating).toFixed(1)}</span>
                    <span className='mr-2' style={{ color: '#fbbc04', textAlign: 'center' }}>
                        {[...Array(averageRatingInt).keys()].map((e, k) => 
                            <i key={k} className="fas fa-star"/>
                        )}
                        {averageRatingInt < averageRating ?
                            <i className="fas fa-star-half-alt"/>
                        :''}
                        {parseInt(averageRating - averageRatingInt) > 1 ?
                            [...Array(parseInt(averageRating - averageRatingInt)).keys()].map((e, key) => 
                                <i key={key} className="far fa-star"/>
                            )
                        :''}
                    </span>
                    <span className='text-muted' style={{ fontSize: '0.8em' }}>
                        <span className='mr-1'>{totalReviewCount}</span>
                        <FormattedMessage id="GoogleBusinessConfig.reviews"/>
                    </span>
                </div>
            :''
        )
    }
}
