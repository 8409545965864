import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import { Button, Col, Form, FormGroup, Label, Modal, ModalBody, Row } from 'reactstrap';
import { BlankCard } from '../../../Base/CommonUIComponents';
import CustomSelect from '../../../Base/CustomSelect';
import MediaPreview from './MediaPreview';

export default class MediaModal extends Component {
  render() {
    const { uploadMediaModal, toggleModal, uploadNewMedia, phoneNumbersOptions, handleSelect, handleMedia, mimeType, newMedia, mediaPreview, block } = this.props;

    return (
        <Modal isOpen={uploadMediaModal} fade={false} size={"lg"} style={{ minWidth: 0 }} >
            <Button onClick={() => toggleModal('uploadMediaModal')} className="closeModal">
                <i className="fas fa-times fa-sm" />
            </Button>
            <ModalBody>
                <BlankCard block={block}>
                    <Form onSubmit={uploadNewMedia}>
                        <Row className="mb-3">
                            <Col>
                                <h5><FormattedMessage id="MediaManagement.UploadNewMedia" /></h5>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host " type="submit">
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>

                        <FormGroup row>
                            <Label sm={3}> <FormattedMessage id="MediaManagement.PhoneNumber" /></Label>
                            <Col sm={9} style={{ zIndex: '99' }} >
                                <CustomSelect
                                    options={phoneNumbersOptions}
                                    isClearable
                                    isSearchable
                                    placeholder={<FormattedMessage id="MediaManagement.PhoneNumber" />}
                                    onChange={(e) => handleSelect(e, "phoneNumber")}
                                    required
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label sm={3}> <FormattedMessage id="MediaManagement.Media" /></Label>
                            <Col sm={9} >
                                <div className="custom-file">
                                    <input
                                        id='media'
                                        required
                                        type="file"
                                        className="custom-file-input"
                                        accept={mimeType}
                                        onChange={handleMedia}
                                    />
                                    <label className="custom-file-label" for="customFile">
                                        {newMedia ? newMedia.name : <FormattedMessage id="generic.ChooseFile" />}
                                    </label>
                                </div>
                            </Col>
                        </FormGroup>

                        {mediaPreview ?
                            <div className="mt-3">
                                <FormGroup row>
                                    <Label sm={3}> <FormattedMessage id="MediaManagement.Preview" /></Label>
                                    <Col className="col-9 text-center" >
                                        <div style={{ maxWidth: '100%' }}>
                                            <MediaPreview
                                                getMediaDetails={() => { }}
                                                media={mediaPreview}
                                                maxFileHeight="600px"
                                            />
                                        </div>
                                    </Col>
                                </FormGroup>
                            </div>
                        : ''}
                    </Form>
                </BlankCard>
            </ModalBody>
        </Modal>
    )
  }
}
