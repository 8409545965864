import React, { Component } from 'react';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import { Button, Card, Col, Form, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import { DateRangePicker } from 'react-dates';
import { FormatAmountNumber } from '../Base/CommonUIComponents';
import Chart from 'react-google-charts';
import CustomSelect from '../Base/CustomSelect';
import { Funnel } from '@nivo/funnel'

class SalesProcessStats extends Component {

    constructor(props) {
        super(props);

        this.funnelParent = React.createRef();

        this.state = {
            block: false,
            error: null,
            businessStartDate: null,
            businessEndDate: null,
            negotiationStartDate: moment().startOf('year'),
            negotiationEndDate: moment(),
            source: null,
            selectedStaff: [],
            importantCardValues: [
                {
                    label: "SalesProcessMetrics.NumberofDeals",
                    value: "10", //
                    icon: "fas fa-business-time",
                    status: 'decrease',
                    type: "number"
                },
                {
                    label: "SalesProcessMetrics.MediumDealValue",
                    value: "30700", //
                    icon: "fas fa-business-time",
                    status: 'increase',
                    type: "value"
                },
                {
                    label: "SalesProcessMetrics.ConversionRate",
                    value: "10",//
                    icon: "fas fa-business-time",
                    status: 'increase',
                    type: "percent"
                },
                {
                    label: "SalesProcessMetrics.ValueWon",
                    value: "80000", //
                    icon: "fas fa-business-time",
                    status: 'new',
                    type: "value"
                },
            ],
            cardValues: [
                {
                    label: "SalesProcessMetrics.MediumDealLength",
                    value: "> 1 Month", //
                    icon: "fas fa-business-time",
                    type: "number"
                },
                {
                    label: "SalesProcessMetrics.DealsWon",
                    value: "1", //
                    icon: "fas fa-business-time",
                    type: "number"
                },
                {
                    label: "SalesProcessMetrics.DealsLost",
                    value: "1", //
                    icon: "fas fa-business-time",
                    type: "number"
                },
                {
                    label: "SalesProcessMetrics.TotalDealsValue",
                    value: "307000", //
                    icon: "fas fa-business-time",
                    type: "value"
                },
            ],
            funnelWidth: null
        };
    }

    componentDidMount() {
        this.setState({
            funnelWidth: this.funnelParent?.current?.offsetWidth ?? 600
        }, () => this.getStats())
    }

    getStats = () => {

    }

    handleBusinessDateChanges = ({ startDate, endDate }) => {
        this.setState({ businessStartDate: startDate, businessEndDate: endDate });
    }

    handleNegotiationDateChanges = ({ startDate, endDate }) => {
        this.setState({ negotiationStartDate: startDate, negotiationEndDate: endDate });
    }

    handleSelectedStaff = (el) => {
        this.setState({ selectedStaff: el?.value });
    }

    handleSource = (combo) => {
        this.setState({ source: combo?.value });
    }

    downloadStats = () => {

    }

    handleSearch = (e) => {
        e.preventDefault();
        this.setState({ block: true }, () => this.getStats())
    }

    render() {
        const { block, error, cardValues, businessStartDate, businessEndDate, negotiationStartDate, negotiationEndDate, source, selectedStaff, funnelWidth, importantCardValues } = this.state;
        const { icon, intl, salesUsers, salesSources } = this.props;
        
        return (
            <div className="border-0 h-100 pb-2">
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <div className='mb-4'>
                        <Row>
                            <Col>
                                <h4 className='m-0'>
                                    <i className={`${icon} mr-2`} />
                                    <FormattedMessage id="SalesProcess.Stats" />
                                </h4>
                            </Col>
                        </Row>
                    </div>
                    <div className='mt-2'>
                        {/* Filters */}
                        <Form onSubmit={this.handleSearch}>
                            <div className='d-flex align-items-center justify-content-between' style={{ gap: '2%' }}>
                                <div style={{ width: '20%' }}>
                                    <div className='title-sm'>
                                        <i className={`fas fa-sliders-h mr-2`} />
                                        <FormattedMessage id="SalesProcess.SalesSource"/>
                                    </div>
                                    <div>
                                        <CustomSelect
                                            options={salesSources}
                                            isClearable={true}
                                            isMulti={false}
                                            value={salesSources?.find(({ value }) => value === source) || ''}
                                            isSearchable={true}
                                            onChange={this.handleSource}
                                            placeholder={intl.formatMessage({ id: "SalesProcess.SalesSource" })}
                                        />
                                    </div>
                                </div>
                                <div style={{ width: '20%' }}>
                                    <div className='title-sm'>
                                        <i className="fas fa-users mr-2"/>
                                        <FormattedMessage id="SalesProcess.Staff"/>
                                    </div>
                                    <div>
                                        <CustomSelect
                                            options={salesUsers}
                                            isClearable={true}
                                            isMulti={false}
                                            value={salesUsers?.find(({value}) => value === selectedStaff)}
                                            isSearchable={true}
                                            onChange={this.handleSelectedStaff}
                                            placeholder={intl.formatMessage({ id: "SalesProcess.Staff" })}
                                        />
                                    </div>
                                </div>
                                <div style={{ width: '17%', whiteSpace: 'nowrap' }}>
                                    <div className='title-sm'>
                                        <i className="far fa-calendar-alt mr-2"/>
                                        <FormattedMessage id="SalesProcessMetrics.BusinessDates"/>
                                    </div>
                                    <DateRangePicker
                                        startDate={businessStartDate}
                                        startDateId="startDateId"
                                        endDate={businessEndDate}
                                        endDateId="endDateId"
                                        isOutsideRange={_ => false}
                                        onDatesChange={this.handleBusinessDateChanges}
                                        focusedInput={this.state.focusedInput}
                                        onFocusChange={focusedInput => this.setState({ focusedInput })}
                                        small={true}
                                        showClearDates={true}
                                        showDefaultInputIcon={true}
                                        renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                                    />
                                </div>
                                <div style={{ width: '17%', whiteSpace: 'nowrap' }}>
                                    <div className='title-sm'>
                                        <i className="far fa-calendar-alt mr-2"/>
                                        <FormattedMessage id="SalesProcessMetrics.NegotiationDates"/>
                                    </div>
                                    <DateRangePicker
                                        startDate={negotiationStartDate}
                                        startDateId="negotiationStartDateId"
                                        endDate={negotiationEndDate}
                                        endDateId="negotiationEndDateId"
                                        isOutsideRange={_ => false}
                                        onDatesChange={this.handleNegotiationDateChanges}
                                        focusedInput={this.state.focusedInput}
                                        showClearDates={true}
                                        onFocusChange={focusedInput2 => this.setState({ focusedInput: focusedInput2 })}
                                        small={true}
                                        showDefaultInputIcon={true}
                                        renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                                    />
                                </div>
                                <div style={{ width: '20%' }} className='text-right'>
                                    <Button className="btn-sm btn-host" onClick={this.downloadStats}>
                                        <i className="fas fa-file-download"/>
                                    </Button>
                                    <Button className="btn-sm btn-host ml-2" type="submit">
                                        <i className="fas fa-search"/>
                                    </Button>
                                </div>
                            </div>
                        </Form>
                        {/* Card Values */}
                        <div>
                            <Card className='mt-4 shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                <Row>
                                    {importantCardValues.map((card, key) =>
                                        <Col className='col-3 mt-2' key={key}>
                                            <div>
                                                <div className='text-muted'>
                                                    <i className={`${card.icon} mr-2`}/>
                                                    <FormattedMessage id={card.label}/>
                                                </div>
                                                <div className={`mt-2 d-flex ${card.status === "decrease" ? "align-items-end" : ""}`}>
                                                    <div style={{ fontSize: '1.4em' }}>
                                                        {card.type === "value" ?
                                                            <FormatAmountNumber
                                                                value={card.value}
                                                            />
                                                        : card.type === "percent" ?
                                                            `${card.value}%`
                                                        :
                                                            card.value
                                                        }
                                                    </div>
                                                    <div className='ml-2'>
                                                        {card.status === "increase" ?
                                                            <i style={{ color: '#36ad51' }} className="fas fa-arrow-up"/>
                                                        : card.status === "decrease" ?
                                                            <i style={{ color: '#F38375' }} className="fas fa-arrow-down"/>
                                                        :
                                                            <div style={{ borderRadius: '100%', background: '#0665ff', width: '10px', height: '10px' }}/>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </Card>
                            <Row className='mt-4'>
                                {cardValues.map((card, key) =>
                                    <Col className='col-3 mt-2' key={key}>
                                        <Card className='shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='text-muted'>
                                                    <i className={`${card.icon} mr-2`}/>
                                                    <FormattedMessage id={card.label}/>
                                                </div>
                                                <div style={{ fontSize: '1.4em' }}>
                                                    {card.type === "value" ?
                                                        <FormatAmountNumber
                                                            value={card.value}
                                                        />
                                                    : card.type === "percent" ?
                                                        `${card.value}%`
                                                    :
                                                        card.value
                                                    }
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                )}
                            </Row>
                        </div>
                        {/* Content */}
                        <Row className='mt-4'>
                            <Col className='col-12'>
                                <Card className='shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='text-muted'>
                                            <i className="fas fa-chart-line mr-2"/>
                                            <FormattedMessage id="SalesProcessMetrics.ConversionperPipeline"/>
                                        </div>
                                    </div>
                                    <div ref={this.funnelParent}>
                                        <Funnel
                                            height={300}
                                            width={funnelWidth}
                                            data={[
                                                {
                                                    "id": "ContactoInicial",
                                                    "value": 307000,
                                                    "label": "Contacto Inicial"
                                                },
                                                {
                                                    "id": "RecolhadeRequisitos",
                                                    "value": 270050,
                                                    "label": "Recolha de Requisitos"
                                                },
                                                {
                                                    "id": "AlocaçãodeRecursos",
                                                    "value": 201020,
                                                    "label": "Alocação de Recursos"
                                                },
                                                {
                                                    "id": "NegociaçãodePreços",
                                                    "value": 152335,
                                                    "label": "Negociação de Preços"
                                                },
                                                {
                                                    "id": "ConfirmaçãoFinal",
                                                    "value": 80000,
                                                    "label": "Confirmação Final"
                                                }
                                                ]}
                                            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                                            direction="horizontal"
                                            valueFormat=">-.4s"
                                            colors={{ scheme: 'blues' }}
                                            borderWidth={20}
                                            labelColor={{
                                                from: 'color',
                                                modifiers: [
                                                    [
                                                        'darker',
                                                        3
                                                    ]
                                                ]
                                            }}
                                            beforeSeparatorOffset={20}
                                            afterSeparatorOffset={20}
                                        />
                                    </div>
                                </Card>
                            </Col>
                            <Col className='col-6 mt-3'>
                                <Card className='shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='text-muted'>
                                            <i className="fas fa-chart-line mr-2"/>
                                            <FormattedMessage id="SalesProcessMetrics.CreatedDealsperMonth"/>
                                        </div>
                                        <div style={{ fontSize: '1.4em' }}>
                                            10
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        {/* Bar + Line with medium */}
                                        <Chart
                                            chartType="Bar"
                                            width="100%"
                                            height="300px"
                                            data={[
                                                ["Month", "Deals" ],
                                                ["June", 1 ],
                                                ["July", 3 ],
                                                ["August", 1 ],
                                                ["September", 5 ],
                                            ]}
                                            options={{
                                                legend: {position: 'none'},
                                            }}
                                        />
                                    </div>
                                </Card>
                            </Col>
                            <Col className='col-6 mt-3'>
                                <Card className='shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='text-muted'>
                                            <i className="fas fa-chart-line mr-2"/>
                                            <FormattedMessage id="SalesProcessMetrics.ClosedDealsperMonth"/>
                                        </div>
                                        1
                                    </div>
                                    <div className='mt-2'>
                                        {/* Bar + Line with medium */}
                                        <Chart
                                            chartType="Bar"
                                            width="100%"
                                            height="300px"
                                            data={[
                                                ["Month", "Deals" ],
                                                ["June", 0 ],
                                                ["July", 0 ],
                                                ["August", 1 ],
                                                ["September", 1 ],
                                            ]}
                                            options={{
                                                legend: {position: 'none'},
                                            }}
                                        />
                                    </div>
                                </Card>
                            </Col>
                            {/* <Col className='col-6 mt-3'>
                                <Card className='shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='text-muted'>
                                            <i className="fas fa-chart-line mr-2"/>
                                            <FormattedMessage id="SalesProcessMetrics.DealDuration"/>
                                        </div>
                                        {`> 1 Month`}
                                    </div>
                                    <div>
                                        Bar + Line with medium
                                        <Chart
                                            chartType="Bar"
                                            width="100%"
                                            height="300px"
                                            data={[
                                                ["Category", "Price" ],
                                                ["Cat 1", 400 ],
                                                ["Cat 2", 460 ],
                                                ["Cat 3", 1120 ],
                                                ["Cat 4", 540 ],
                                            ]}
                                            options={{
                                                legend: {position: 'none'},
                                            }}
                                        />
                                    </div>
                                </Card>
                            </Col> */}
                            <Col className='col-6 mt-3'>
                                <Card className='shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='text-muted'>
                                            <i className="fas fa-chart-line mr-2"/>
                                            <FormattedMessage id="SalesProcessMetrics.DealStatus"/>
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        <Chart
                                            chartType="Bar"
                                            width="100%"
                                            height="300px"
                                            data={[
                                                ["Status", "Deals" ],
                                                ["Lost", 1 ],
                                                ["Waiting Client", 3 ],
                                                ["Waiting Hotel", 5 ],
                                                ["Won", 1 ],
                                            ]}
                                            options={{
                                                legend: {position: 'none'},
                                            }}
                                        />
                                    </div>
                                </Card>
                            </Col>
                            <Col className='col-6 mt-3'>
                                <Card className='shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='text-muted'>
                                            <i className="fas fa-chart-line mr-2"/>
                                            <FormattedMessage id="SalesProcessMetrics.DealsperPipeline"/>
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        <Chart
                                            chartType="BarChart"
                                            width="100%"
                                            height="300px"
                                            data={[
                                                ["Category", "Price" ],
                                                ["Main Pipeline", 7 ],
                                                ["Reservas Multi Hotel", 3 ]
                                            ]}
                                            options={{
                                                legend: {position: 'none'},
                                            }}
                                        />
                                    </div>
                                </Card>
                            </Col>
                            <Col className='col-6 mt-3'>
                                <Card className='shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='text-muted'>
                                            <i className="fas fa-chart-line mr-2"/>
                                            <FormattedMessage id="SalesProcessMetrics.Conversion/ValueperSource"/>
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        {/* Bar + Line */}
                                        <Chart
                                            chartType="Bar"
                                            width="100%"
                                            height="300px"
                                            data={[
                                                ["Source", "Value" ],
                                                ["Contactos Directos", 0 ],
                                                ["Parceiro - Abreu Viagens", 0 ],
                                                ["BTL", 80000 ]
                                            ]}
                                            options={{
                                                legend: {position: 'none'},
                                            }}
                                        />
                                    </div>
                                </Card>
                            </Col>
                            <Col className='col-6 mt-3'>
                                <Card className='shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='text-muted'>
                                            <i className="fas fa-chart-line mr-2"/>
                                            <FormattedMessage id="SalesProcessMetrics.DealsperSource"/>
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        <Chart
                                            chartType="BarChart"
                                            width="100%"
                                            height="300px"
                                            data={[
                                                ["Source", "Deals" ],
                                                ["Contactos Directos", 5 ],
                                                ["Parceiro - Abreu Viagens", 2 ],
                                                ["BTL", 3 ]
                                            ]}
                                            options={{
                                                legend: {position: 'none'},
                                            }}
                                        />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(SalesProcessStats);