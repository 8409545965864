import React, { Component } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import Chart from "react-google-charts";
import moment from 'moment';
import { CustomTable } from '../../Base/CustomTable';
import BlockUi from 'react-block-ui';
import { FormatAmountNumber } from '../../Base/CommonUIComponents';

export class RevenueAndCampaignKPI extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.campaignKPI !== nextProps.campaignKPI) {
            this.organizeCampaignKPI(nextProps.campaignKPI);
        }
    }


    organizeCampaignKPI = (data) => {
        var list = [];

        data.response && [...data.response].forEach(el => {
            el.campaignUrlsData.forEach(url => {
                const idx = list.findIndex(u => u.url === url.url);

                if (idx !== -1) {
                    list[idx].totalClicks += url.totalClicks;
                }
                else {
                    list.push({ ...url });
                }
            })
        })

        list.sort((a, b) => b.totalClicks - a.totalClicks);

        this.setState({ campaignKpi: list })
    }

    displayConversion = (cell, row) => {
        if (cell) {
            return <span> {cell} {row?.siteView ? `(${parseFloat((cell * 100) / row.siteView).toFixed(2)}%)` : ''} </span>
        }

        return '';
    }

    render() {
        const columns = [
            {
                dataField: 'url',
                text: 'Url'
            },
            {
                dataField: 'totalClicks',
                text: this.props.intl.formatMessage({ id: "CampaignResults.Clicks" }),
                style: { width: '10%' }
            }
        ];

        return (
            <Row className="my-3" style={{ minHeight: '330px' }}>
                <Col sm={6}>
                    <Card className="shadow pl-3 border-0 h-100 mb-3" body>
                        <BlockUi block={this.props.blockCampaigns}>
                            <span className="text-muted d-flex mb-3">
                                <h5 className="pr-1"><FormattedMessage id="CampaignResults.CampaignKPI" /> </h5>
                            </span>
                            <div className="noShadow">
                                <CustomTable
                                    data={this.state.campaignKpi || []}
                                    columns={columns}
                                    showTotal={false}
                                    remote={false}
                                    sizePerPage={10}
                                />
                            </div>
                        </BlockUi>
                    </Card>
                </Col>
                <Col sm={6}>
                    <Card className="shadow border-0 h-100" body>
                        <h5 className="text-muted mb-3"><FormattedMessage id="CampaignResults.RecordsPerSite" /> </h5>

                        <div className="noShadow">
                            <CustomTable
                                data={this.props.totalByChannel || []}
                                columns={[
                                    {
                                        dataField: 'channelName',
                                        text: 'Site'
                                    },
                                    {
                                        dataField: 'siteView',
                                        text: this.props.intl.formatMessage({ id: "CampaignResults.Views" }),
                                    },
                                    {
                                        dataField: 'sessionCampaignId',
                                        text: this.props.intl.formatMessage({ id: "CampaignResults.sessionCampaignName" }),
                                    },
                                    {
                                        dataField: 'conversions',
                                        text: this.props.intl.formatMessage({ id: "CampaignResults.Conversions" }),
                                        formatter: (cell, row) => this.displayConversion(cell, row)
                                    },
                                    {
                                        dataField: 'revenue',
                                        text: this.props.intl.formatMessage({ id: "CampaignResults.Revenue" }),
                                        formatter: (cell) => <FormatAmountNumber value={cell}/>
                                    }
                                ]}
                                showTotal={false}
                                remote={false}
                                sizePerPage={10}
                            />
                        </div>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default injectIntl(RevenueAndCampaignKPI);