import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { injectIntl } from 'react-intl';
import { Button, Card, Col, Row, Breadcrumb, BreadcrumbItem, Badge, Modal, ModalBody, Form, Input, Label } from 'reactstrap';
import { StyledCard, BlankCard } from "../Base/CommonUIComponents";
import { getAPI, postAPI, putAPI, deleteAPI } from "../Base/API";
import { handleNotification } from "../Base/Notification";
import { FormattedMessage } from "react-intl";

import Authorization, { CheckAuthorization } from '../Base/Authorization';

export class TagList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            tagList: [],
            selectedTag: null,
            limit: 100,
            offset: 0,
            totalItems: 0,
            editMode: false
        };
    }

    componentDidMount() {
        this.getTagList();
    }

    getTagList = () => {
        this.setState({ block: true });

        getAPI(result => {
             const { data, error } = result;
             const errorMessage = [];
             if (error) {
                 errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                 this.setState({ error: errorMessage, block: false });
                 return;
             }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({
                    tagList: data.items ? data.items : [],
                    totalItems: data.total_items ? data.total_items : 0
                });
             }
             this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/tags?limit=${this.state.limit}&offset=${this.state.offset}`);
    }

    createTag = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const color = encodeURIComponent(this.state.selectedTag.color);

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.detail) {
                    handleNotification({ errors: [{ code: data.title, message: data.detail }] });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.tag_id) {
                    handleNotification(data, <FormattedMessage id="TagList.TagCreated" />, <FormattedMessage id="generic.success" />);

                    this.setState(
                        { modal: false, selectedTag: null, editMode: false, offset: 0 },
                        () => this.getTagList()
                    );
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/tags?name=${this.state.selectedTag.name}&color=${color}`);
    }

    editTag = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const color = encodeURIComponent(this.state.selectedTag.color);

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.detail) {
                    handleNotification({ errors: [{ code: data.title, message: data.detail }] });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.tag_id) {
                    handleNotification(data, <FormattedMessage id="TagList.TagUpdated" />, <FormattedMessage id="generic.success" />);

                    this.setState({ modal: false, selectedTag: null, editMode: false, offset: 0 },
                        () => this.getTagList()
                    );
                }
            }

            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/tags/${this.state.selectedTag.tag_id}?name=${this.state.selectedTag.name}&color=${color}`);
    }

    removeTag = (e, tagId) => {
        e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.detail) {
                handleNotification({ errors: [{ code: data.title, message: data.detail }] });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.Errors && data.Errors.length > 0) {
                handleNotification(data);
                return;
            }
            if (data === '') {
                handleNotification(data, <FormattedMessage id="TagList.TagRemoved" />, <FormattedMessage id="generic.success" />);

                this.setState({ modal: false, selectedTag: null, editMode: false, offset: 0 },
                    () => this.getTagList()
                );
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/tags/${tagId}`);
    }

    toggleModal = (data) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedTag: data ? data : null,
            editMode: data ? true : false
        }));
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            selectedTag: {
                ...this.state.selectedTag,
                [name]: value
            }
        });
    }

    render() {
        const { block, error, tagList, selectedTag, editMode } = this.state;

        return (

            <StyledCard block={block} error={error} >
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/MarketingConfigEgoi"> Config</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Tags</BreadcrumbItem>
                    </Breadcrumb>
                </div>

                <Row className="mb-2 text-right pr-3">
                <Authorization
                    perform="tagList:add"
                        yes={() => (
                            <Col>
                                <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.toggleModal(null)}>
                                    <i className="fas fa-plus" />
                                </Button>
                            </Col>
                    )}
                    no={() => <div></div>}
                />
                </Row>

                <Row className="mb-4">
                    {tagList && tagList.map((el, k) =>
                        <Col className="col-3 mb-3" key={k}>
                            <Card className="border-0 p-3 pointer" onClick={() => { if (CheckAuthorization("tagList:edit", null)) this.toggleModal(el) }}>
                                <Row>
                                    <Col className="col-9">{el.name}</Col>
                                    <Col className="d-flex align-items-center justify-content-center">
                                        <Badge className="p-2" style={{ borderRadius: '20px', backgroundColor: el.color }} >&nbsp; &nbsp;&nbsp;</Badge>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    )}
                </Row>                


                <Modal isOpen={this.state.modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                    <Button onClick={() => this.toggleModal(null)} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    <ModalBody>
                        <BlankCard block={block} error={error}>
                            <Form onSubmit={editMode ? (e) => this.editTag(e) : (e) => this.createTag(e)}>
                                <Row className="mb-3">
                                    <Col>
                                        <h5> {editMode ? <FormattedMessage id="TagList.EditTag" /> : <FormattedMessage id="TagList.CreateTag" />}</h5>
                                    </Col>

                                    <Col className="text-right">
                                        {editMode ?
                                            <Button className="btn-sm btn-host mr-2" onClick={(e) => this.removeTag(e, selectedTag.tag_id)}>
                                                <i className="fas fa-trash-alt" />
                                            </Button>
                                        : ''}
                                        <Button className="btn-sm btn-host" type="submit">
                                            <i className="fas fa-save" />
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Label sm={3}><FormattedMessage id="TagList.Name" /></Label>
                                    <Col sm={9}>
                                        <Input
                                            name="name"
                                            type="text"
                                            value={selectedTag && selectedTag.name ? selectedTag.name : ''}
                                            onChange={(e) => this.handleChange(e)}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Label sm={3}> <FormattedMessage id="TagList.Color" /></Label>
                                    <Col sm={3}>
                                        <Input
                                            name="color"
                                            type="color"
                                            value={selectedTag && selectedTag.color ? selectedTag.color : '#ffffff'}
                                            onChange={(e) => this.handleChange(e)}
                                            required
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </BlankCard>
                    </ModalBody>
                </Modal>
            </StyledCard>
        );
    }
}
export default injectIntl(TagList)
