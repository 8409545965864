import React, { Component } from 'react'
import BlockUi from 'react-block-ui';
import { FormattedMessage } from 'react-intl';
import { Badge, Button, CustomInput,  } from 'reactstrap';
import { getAPI, postAPI } from './API';
import GenericTextArea from './GenericTextArea';
import GPTComponent from './GPTComponent';
import { handleNotification } from './Notification';
import { getContextPrompts } from './ReferenceDataFunctions';
import ChatGPTWhite from '../../img/chatgptwhite.png';
import Authorization from './Authorization';
import CustomSelect from './CustomSelect';

export default class InputGPT extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            showDetails: false,
            menuVisible: false,
            answerSize: "50",
            temperature: 0.3,
            replyModal: false,
            gptReply: null,
            tempOptions: [
                { label: 'Professional', value: '0' },
                { label: 'Relaxed', value: '0.5' },
                { label: 'Fun', value: '1' }
            ],
            temp: '0'
        };
    }

    componentDidMount(){
        this.getPromptConfig()
    }

    toggleDetails = () => {
        this.setState(prev => ({ showDetails: !prev.showDetails, menuVisible: false }));
    }

    toggleMenuVisible = () => {
        // this.setState(prev => ({ menuVisible: !prev.menuVisible }));
        this.setState(prev => ({ showDetails: !prev.showDetails, menuVisible: false }));
    }

    getReply = () => {
        const { answerSize, temp, selectedPrompt } = this.state;
        const { getGPTPrompt, name, onChange, configType } = this.props;

        const prompt = getContextPrompts("wordsLimit", answerSize) + getGPTPrompt();

        const chatCompletion = {
            maxTokens: answerSize * 3, // one word is around 3 tokens
            temperature: temp,
            promptConfigId: selectedPrompt,
            promptConfigType: configType,
            prompt
        };
        
        this.setState({ block: true, menuVisible: false }, () => {

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data?.errors && data.errors.length > 0) {
                    this.setState({ block: false, error: data.errors }, () => handleNotification(data));
                    return;
                };
                const gptReply = data?.response && data?.response[0]?.choices[0]?.message?.content;
                if(gptReply){
                    const e = {
                        target: {
                            value: gptReply,
                            name
                        }
                    }
                    this.setState({ block: false, error: null, gptReply }, () => onChange(e));
                }
                else{
                    this.setState({ block: false, error: null }, () => handleNotification(data));
                }
            }, `/api/gms/OpenAI/v1/GPTturbo`, chatCompletion);
        });
    }

    handleSize = (e) => {
        this.setState({ answerSize: e?.target?.value??'10' });
    }

    //change temp based on props
    handleTemp = (temp) => {
        this.setState({ temp });
    }

    getPromptConfig = () => {
        this.setState({ blockPromptConfig: true }, () => 
            getAPI(result => {
                const { data, error } = result;
                
                if (error) {
                    this.setState({ blockPromptConfig: false }, () => console.error({ message: error.message, stack: error.stack, messageType: 'danger' }));
                    return;
                }
                if (data?.errors && data.errors.length > 0) {
                    this.setState({ blockPromptConfig: false }, () => console.error(data.errors));
                    return;
                };
                this.setState({ blockPromptConfig: false, promptList: data.response, selectedPrompt: data.response?.find(({isMain, type}) => type === this.props.configType && isMain)?.id });
            }, `/api/gms/Review/v1/promptConfig?hotelGroupId=${global.hotelGroupId}`)
        )
    }

    render() {
        const { onChange, name, placeholder, hasGPT, submitReply, value, noSubmit } = this.props; 
        const { block, showDetails, menuVisible, answerSize, tempOptions, temp, promptList, blockPromptConfig, selectedPrompt } = this.state;
        const chatGPTModule = global.modules && global.modules.some(m => m === 'ChatGPT');

        const promptListOptions = promptList?.map(({name, id}) => ({ value: id, label: name }));

        return (
            <BlockUi blocking={block}>
                <div style={{ 
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'space-between',
                    minHeight: '50px',
                }}>
                    <div style={{
                            width: (hasGPT && chatGPTModule) || (!noSubmit) ? '90%' : '100%',
                            height: '100%',
                        }}>
                        <GenericTextArea
                            backgroundColor={"white"}
                            onChange={onChange}
                            name={name}
                            value={value}
                            placeholder={placeholder}
                            maxLength='999'
                            className="py-1"
                            style={{
                                width: '100%',
                                background: 'transparent',
                                boxShadow: 'unset',
                                outline: '0',
                                maxHeight : '500px',
                                minHeight: '50px',
                                height: '50px',
                                padding: '5px 0',
                                resize: 'none',
                                color: '#07294C',
                            }}
                        />
                        {showDetails ?
                            <div className='pt-3 px-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '2rem' }}>
                                    <div className='d-flex'>
                                        <CustomInput
                                            type="range"
                                            name="answerSize"
                                            onChange={this.handleSize}
                                            id="answerSize"
                                            className='gptRange'
                                            min="10"
                                            max="1000"
                                            step="10"
                                            value={answerSize}
                                        />
                                        <div className='ml-2 text-muted' style={{ fontSize: 'smaller' }}>
                                            <span className='mr-1'>{answerSize}</span>
                                            <FormattedMessage id="GPT.Words"/>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '1rem' }}>
                                        {tempOptions.map((option, key) => 
                                            <Badge key={key} className={temp === option.value ? "activeTemp" : "inactiveTemp"} onClick={_ => this.handleTemp(option.value)}>
                                                <FormattedMessage id={`OpenAI.${option.label}`}/>
                                            </Badge>
                                        )}
                                    </div>
                                    <div style={{ minWidth: '175px' }} id="GPTSelect">
                                        <BlockUi blocking={blockPromptConfig}>
                                            <CustomSelect
                                                options={promptListOptions}
                                                value={selectedPrompt && promptListOptions?.find(({value}) => value === selectedPrompt)}
                                                onChange={e => this.setState({ selectedPrompt: e?.value })}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 5,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary75: "#00a67e4d",
                                                        primary50:"#00a67e70",
                                                        primary25: "#00a67e54",
                                                        primary: "#00A67E",
                                                        neutral20: "#00A67E",
                                                        neutral30: "#00A67E",
                                                        neutral40: "#00A67E",
                                                        neutral60: "#00A67E",
                                                        neutral80: "#00A67E",
                                                    }
                                                })}
                                            />
                                        </BlockUi>
                                    </div>
                                </div>
                                <div>
                                    <Button style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent:'center', gap: '0.5rem', fontSize: '0.8em' }} onClick={this.getReply} className="activeTemp generate">
                                        <img className="white" src={ChatGPTWhite} alt="gpt" style={{ width: '15px' }}/>
                                        <FormattedMessage id="GPT.Generate"/>
                                    </Button>
                                </div>
                            </div>
                        :''}
                    </div>
                    {(hasGPT && chatGPTModule) || (!noSubmit) ?
                        <div style={{ height: '100%', width: '8%', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                            {hasGPT && chatGPTModule ?
                                <Authorization
                                    perform="GPT:usage"
                                    yes={() => (
                                        <div style={{ height: '100%', width: '50%' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width:'100%', height: '100%' }}>
                                                <GPTComponent
                                                    toggleMenuVisible={this.toggleMenuVisible}
                                                    getQuickReply={this.getReply}
                                                    menuVisible={menuVisible}
                                                    toggleDetails={this.toggleDetails}
                                                    showDetails={showDetails}
                                                />
                                            </div>
                                        </div>
                                    )}
                                />
                            :''}
                            {!noSubmit ?
                                <div style={{ height: '100%', width: '50%' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                                        <div className='submitGPT' onClick={submitReply}>
                                            <i className="fas fa-paper-plane"/>
                                        </div>
                                    </div>
                                </div>
                            :''}
                        </div>
                    :''}
                </div>
           </BlockUi>
        )
    }
}