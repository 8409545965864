import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import { getAPI, putAPI } from "../Base/API";
import { BlankCard } from '../Base/CommonUIComponents';
import { handleNotification } from "../Base/Notification";
import { getPMSReferenceDataType } from '../Base/ReferenceDataFunctions';
import CreatableSelect from 'react-select/creatable';
import CustomSelect from '../Base/CustomSelect';

class SalesMarketingDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            saleMarketingData: this.props.saleMarketing ? this.props.saleMarketing : {},
            referenceData: getPMSReferenceDataType(),
            segmentsOptions: [],
            subSegmentsOptions: [],
            distributionChannelsOptions: [],
            vipCodesOptions: []
        };
    }

    componentDidMount() {
        this.getReferenceData();
    }

    getReferenceData = () => {
        this.state.referenceData.forEach(el => {
            const isLoading = [`is${el.value}Loading`];

            this.setState({ [isLoading]: true });

            var params = `?type=${el.value}`;

            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, [isLoading]: false });
                    return;
                }
                if (data) {
                    const list = [];

                    data.response && data.response.forEach(rd => {
                        const newOption = { value: rd.code, label: rd.code, isDisabled: !rd.active };
                        const hotelIdx = list.findIndex(el => el.hotelId === rd.hotelId);
                        
                        if (rd.active) {
                            if (hotelIdx !== -1) {
                                list[hotelIdx].options.push(newOption);
                            }
                            else {
                                list.push({ label: rd.hotelName, hotelId: rd.hotelId, options: [newOption] })
                            }
                        }
                    })

                    this.setState({
                        [`${el.value.charAt(0).toLowerCase() + el.value.slice(1)}Options`]: list,
                        [isLoading]: false
                    });
                }
                else this.setState({ [isLoading]: false })
            }, `/api/gms/Hotel/v1/ReferenceData` + params);
        })
    }

    saveSaleMarketing = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var saleMarketing = { ...this.state.saleMarketingData };

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="SalesMarketingDetails.SaleMarketingSaved" />, <FormattedMessage id="generic.success" />);
                    this.props.updateTable(data.response[0], 'salesMarketing');
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/profile/salesMarketing/${this.props.profileId}`, JSON.stringify({ request: saleMarketing }));
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
            saleMarketingData: {
                ...this.state.saleMarketingData,
                [name]: combo ? combo.value : null
            }
        })
    };

    handleCreate = (label, name) => {
        this.setState({
            saleMarketingData: {
                ...this.state.saleMarketingData,
                [name]: label
            }
        });
    };

    render() {
        const { block, error, saleMarketingData } = this.state;

        return (
            <Modal isOpen={this.props.modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={this.props.toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody className="bg-light">
                    <BlankCard block={block} error={error}>
                    
                        <Form onSubmit={this.saveSaleMarketing}>
                            <Row className="mb-3">
                                <Col>
                                    <h5>{this.props.saleMarketing ? <FormattedMessage id="SalesMarketingDetails.EditSaleMarketing" /> : <FormattedMessage id="SalesMarketingDetails.AddSaleMarketing" />}</h5>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" type="submit"> <i className="fas fa-save" /></Button>
                                </Col>
                            </Row>

                            {!this.props.hasMarketingToMaster && this.props.isMaster && this.props.hotels?.length > 0 ?
                                <FormGroup row>
                                    <Label sm={4}> <FormattedMessage id="generic.Hotel" /></Label>
                                    <Col sm={8}>
                                        <CustomSelect
                                            id="hotelId"
                                            name="hotelId"
                                            options={this.props.hotels.filter(h => !h.isDisabled)}
                                            value={this.props.hotels.find(opt => opt.value === saleMarketingData.hotelId)}
                                            onChange={(e) => this.handleChangeSelect(e, 'hotelId')}
                                            required
                                            isDisabled={saleMarketingData.id}
                                            placeholder={""}
                                        />
                                    </Col>
                                </FormGroup>
                            : ''}
                            <FormGroup row>
                                <Label sm={4}> <FormattedMessage id="ProfileDetails.Segment" /></Label>
                                <Col sm={8}>
                                    <CreatableSelect
                                        options={this.state.segmentsOptions}
                                        onChange={(e) => this.handleChangeSelect(e, 'segment')}
                                        onCreateOption={(e) => this.handleCreate(e, 'segment')}
                                        formatCreateLabel={userInput => <span><FormattedMessage id="generic.Create" /> "{userInput}"</span>}
                                        placeholder={''}
                                        required
                                        value={{ value: saleMarketingData?.segment, label: saleMarketingData?.segment }}
                                        isLoading={this.state[`isSegmentsLoading`]}
                                    />
                                </Col>
                            </FormGroup>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}> <FormattedMessage id="ProfileDetails.SubSegment" /></Label>
                                        <Col sm={8}>
                                            <CreatableSelect
                                                options={this.state.subSegmentsOptions}
                                                onChange={(e) => this.handleChangeSelect(e, 'subSegment')}
                                                onCreateOption={(e) => this.handleCreate(e, 'subSegment')}
                                                formatCreateLabel={userInput => <span><FormattedMessage id="generic.Create" /> "{userInput}"</span>}
                                                placeholder={''}
                                                required
                                                value={{ value: saleMarketingData?.subSegment, label: saleMarketingData?.subSegment }}
                                                isLoading={this.state[`isSubSegmentsLoading`]}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}> <FormattedMessage id="ProfileDetails.DistributionChannel" /> </Label>
                                        <Col sm={8}>
                                            <CreatableSelect
                                                options={this.state.distributionChannelsOptions}
                                                onChange={(e) => this.handleChangeSelect(e, 'distributionChannel')}
                                                onCreateOption={(e) => this.handleCreate(e, 'distributionChannel')}
                                                formatCreateLabel={userInput => <span><FormattedMessage id="generic.Create" /> "{userInput}"</span>}
                                                placeholder={''}
                                                required
                                                value={{ value: saleMarketingData?.distributionChannel, label: saleMarketingData?.distributionChannel }}
                                                isLoading={this.state[`isDistributionChannelsLoading`]}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}> <FormattedMessage id="ProfileDetails.VipCode" /></Label>
                                        <Col sm={8}>
                                            <CreatableSelect
                                                isClearable
                                                options={this.state.vipCodesOptions}
                                                onChange={(e) => this.handleChangeSelect(e, 'vipCode')}
                                                onCreateOption={(e) => this.handleCreate(e, 'vipCode')}
                                                formatCreateLabel={userInput => <span><FormattedMessage id="generic.Create" /> "{userInput}"</span>}
                                                placeholder={''}
                                                value={{ value: saleMarketingData?.vipCode, label: saleMarketingData?.vipCode }}
                                                isLoading={this.state[`isVipCodesLoading`]}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(SalesMarketingDetails)