import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { BlankCard, StyledModal } from "../../Base/CommonUIComponents";
import { Button, Col, Row, FormGroup, Form, Label, CustomInput } from "reactstrap";
import { ProfileCreditFields } from "../ProfileCommonFunctions";
import { handleNotification } from "../../Base/Notification";
import CustomSelect from "../../Base/CustomSelect";
import { postAPI, putAPI } from "../../Base/API";


const creditFields = [
    { value: 'hasCityLedger', label: <FormattedMessage id="ProfileDetails.HasCityLedger" /> },
    { value: 'financeAccount', label: <FormattedMessage id="ProfileDetails.FinanceAccount" /> },
    { value: 'paymentDueDays', label: <FormattedMessage id="ProfileDetails.PaymentDueDays" /> },
    { value: 'creditLimit', label: <FormattedMessage id="ProfileDetails.CreditLimit" /> },
    { value: 'creditLimitType', label: <FormattedMessage id="ProfileDetails.Type" /> },
    { value: 'creditLimitPOS', label: <FormattedMessage id="ProfileDetails.CreditLimitPOS" /> },
    { value: 'creditLimitTypePOS', label: <FormattedMessage id="ProfileDetails.TypePOS" /> }
]

class BulkCredit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            createCredits: false,
            editCredits: false,
            newCreditsReq: {
                hotels: [],
                credit: {
                    creditLimitType: "UNRESTRICTED",
                    creditLimitTypePOS: "UNRESTRICTED"
                }
            },
            editCreditsReq: {
                hotels: [],
                credit: {
                    creditLimitType: "UNRESTRICTED",
                    creditLimitTypePOS: "UNRESTRICTED"
                },
                fields: {
                    hasCityLedger: false,
                    financeAccount: false,
                    paymentDueDays: false,
                    creditLimit: false,
                    creditLimitType: false,
                    creditLimitPOS: false,
                    creditLimitTypePOS: false
                }
            }
        }
    }

    createCreditsBulk = () => {        
        this.setState({ block: true });
        
        var body = { ...this.state.newCreditsReq };
        body.hotels = body.hotels?.map(cmb => cmb.value);

        if (!body.credit.hasCityLedger) body.credit.hasCityLedger = false;

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="ProfileCredit.creditSaved" />, <FormattedMessage id="generic.success" />);
                    
                    this.props.updateTable(data.response, 'profileCredit', true);
                    this.props.toggleModal();
                }

                this.setState({ block: false });
            }
            else this.setState({ block: false });
        }, `/api/gms/Profile/v1/profile/credit/${this.props.profileId}/createBulk`, JSON.stringify({ request: body }))
    }

    editCreditsBulk = () => {

        this.setState({ block: true });

        var body = { ...this.state.editCreditsReq };
        body.hotels = body.hotels?.map(cmb => cmb.value);

        if (body.fields.hasCityLedger && !body.credit.hasCityLedger) body.credit.hasCityLedger = false;


        putAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="ProfileCredit.creditSaved" />, <FormattedMessage id="generic.success" />);

                    this.props.updateTable(data.response, 'profileCredit', true);
                    this.props.toggleModal();
                }

                this.setState({ block: false });
            }
            else this.setState({ block: false });
        }, `/api/gms/Profile/v1/profile/credit/${this.props.profileId}/editBulk`, JSON.stringify({ request: body }))
    }

    saveCredits = (e) => {
        if (e) e.preventDefault();

        if (this.state.createCredits)
            this.createCreditsBulk();
        else
            this.editCreditsBulk();
    }

    handleAction = (e, secOptionName) => {
        const { checked, id } = e.target;

        this.setState({
            [id]: checked,
            [secOptionName]: false
        });
    }

    handleSwitch = (evt, propName) => {
        const { name, checked } = evt.target;

        this.setState(prevState => ({
            [propName]: {
                ...prevState[propName],
                credit: {
                    ...prevState[propName].credit,
                    [name]: checked
                }
            }
        }))
    }

    handleChange = (evt, propName) => {
        const { name, value } = evt.target;

        this.setState(prevState => ({
            [propName]: {
                ...prevState[propName],
                credit: {
                    ...prevState[propName].credit,
                    [name]: parseFloat(value)
                }
            }
        }))
    }

    setCombo = (combo, evt, propName) => {
        this.setState(prevState => ({
            [propName]: {
                ...prevState[propName],
                credit: {
                    ...prevState[propName].credit,
                    [evt.name]: combo && combo.value
                }
            }
        }))
    }

    handleChangeHotels = (combo, propName) => {
        this.setState(prevState => ({
            [propName]: {
                ...prevState[propName],
                hotels: combo ? combo : []
            }
        }))
    }

    handleEditableFields = (combo, action) => {
        if (action) {
            const { fields } = this.state.editCreditsReq;
            
            if (action.action === "select-option") {
                fields[action.option.value] = true;
            }
            else if (action.action === "remove-value") {
                fields[action.removedValue.value] = false;
            }
            else if (action.action === "clear") {
                Object.keys(fields).map(el => fields[el] = false );
            }

            this.setState(prevState => ({
                editCreditsReq: {
                    ...prevState.editCreditsReq,
                    fields
                }
            }));
        }
    }

    render() {
        const { isOpen, toggleModal, hotelOptions } = this.props;
        const { block, error, editCredits, createCredits, newCreditsReq, editCreditsReq } = this.state;

        const hotelList = hotelOptions.filter(h => (createCredits ? !h.isDisabled : h.isDisabled));

        return (
            <StyledModal toggleModal={toggleModal} modal={isOpen} size="xl">
                <BlankCard block={block} error={error}>
                    <Form onSubmit={this.saveCredits}>
                        <Row className="align-items-center mb-3">
                            <Col>
                                <h5><FormattedMessage id="BulkCredit.BulkCredit" /> </h5>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host" type="submit" disabled={!createCredits && !editCredits}>
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>

                        <FormGroup row>
                            <Col className="d-flex ">
                                <CustomInput type="radio" id="createCredits" label={<FormattedMessage id="BulkCredit.CreateCredits" />} onChange={(e) => this.handleAction(e, "editCredits")} checked={createCredits} className="mr-3" />
                                <CustomInput type="radio" id="editCredits" label={<FormattedMessage id="BulkCredit.EditCredits" />} onChange={(e) => this.handleAction(e, "createCredits")} checked={editCredits} />
                            </Col>
                        </FormGroup>



                        {createCredits || editCredits ?
                            <>                                
                                <div className="text-right text-host title-sm">
                                    <span className="pointer" onClick={() => this.handleChangeHotels(hotelList, (createCredits ? "newCreditsReq" : "editCreditsReq"))}>Select All</span>
                                </div>

                                <FormGroup row>
                                    <Label sm={2}><FormattedMessage id="generic.Hotel" /></Label>
                                    <Col sm={10}>
                                        <CustomSelect
                                            required
                                            options={hotelList}
                                            onChange={(evt) => this.handleChangeHotels(evt, (createCredits ? "newCreditsReq" : "editCreditsReq"))}
                                            value={createCredits ? newCreditsReq.hotels : editCreditsReq.hotels}
                                            isMulti
                                            placeholder=""
                                        />
                                    </Col>
                                </FormGroup>
                            </>
                        : ''}

                        {createCredits ?
                            <div className="mt-5">
                                <ProfileCreditFields
                                    credit={newCreditsReq.credit}
                                    handleSwitch={(e) => this.handleSwitch(e, "newCreditsReq")}
                                    handleChange={(e) => this.handleChange(e, "newCreditsReq")}
                                    setCombo={(combo, e) => this.setCombo(combo, e, "newCreditsReq")}
                                    creditLimitTypeOptions={this.props.creditLimitTypeOptions}
                                />
                            </div>
                            : editCredits ?
                                <div>
                                    <FormGroup row className="mb-5">
                                        <Label sm={2}><FormattedMessage id="BulkCredit.Fields" /></Label>
                                        <Col sm={10}>
                                            <CustomSelect
                                                required
                                                options={creditFields}
                                                onChange={(evt, action) => this.handleEditableFields(evt, action)}
                                                isMulti
                                                placeholder=""
                                            />
                                        </Col>
                                    </FormGroup>


                                    <ProfileCreditFields
                                        credit={editCreditsReq.credit}
                                        handleSwitch={(e) => this.handleSwitch(e, "editCreditsReq")}
                                        handleChange={(e) => this.handleChange(e, "editCreditsReq")}
                                        setCombo={(combo, e) => this.setCombo(combo, e, "editCreditsReq")}
                                        creditLimitTypeOptions={this.props.creditLimitTypeOptions}
                                        showFields={editCreditsReq.fields}
                                    />
                                </div>
                        : ''}

                    </Form>
                </BlankCard>
            </StyledModal>
        );
    }
}

export default injectIntl(BulkCredit);