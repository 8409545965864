import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import { BlankCard } from "../../../Base/CommonUIComponents";
import { handleNotification } from "../../../Base/Notification";
import { deleteAPI, putAPI } from "../../../Base/API";
import ConfirmActionModal from '../../../Base/ConfirmActionModal';

class ModelDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            param: this.props.param || {}
        };
    }

    saveParam = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="MachineLearningConfig.ParamSaved" />, <FormattedMessage id="generic.success" />);

                    if (data.response && data.response.length > 0) {
                        this.props.updateParam(data.response, this.props.param.modelId, false);
                    }
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/MLModel/v1/${this.props.param.modelId}/params`, JSON.stringify({ request: [{ ...this.state.param }] }));
    }

    removeParam = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="AlertList.AlertRemoved" />, <FormattedMessage id="generic.success" />);

                    this.props.updateParam([{ ...this.state.param }], this.props.param.modelId, true);
                }
            }
            this.setState({ error: errorMessage, block: false, deleteModal: false });
        }, `/api/gms/MLModel/v1/params/${this.state.param.id}`);
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            param: {
                ...this.state.param,
                [name]: value
            }
        })
    }

    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal
        }));
    }

    render() {
        const { block, error, param, deleteModal } = this.state;
        const { modal, toggleModal } = this.props;

        return (
            <Modal isOpen={modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <Form onSubmit={this.saveParam}>
                            <Row className="mb-3">
                                <Col>
                                    <h5>{param?.id ? <FormattedMessage id="MachineLearningConfig.EditParam" /> : <FormattedMessage id="MachineLearningConfig.CreateParam" />}</h5>
                                </Col>
                                <Col className="text-right">
                                    {this.state.param?.id &&
                                        <Button className="btn-sm btn-host mr-2" onClick={this.toggleDeleteModal}>
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                    }
                                    <Button className="btn-sm btn-host" type="submit">
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>

                            <FormGroup row className="pt-2">
                                <Label sm={2}> <FormattedMessage id="MachineLearningConfig.Name" /> </Label>
                                <Col sm={10}>
                                    <Input
                                        type="text"
                                        name="paramName"
                                        onChange={(e) => this.handleChange(e)}
                                        value={param.paramName ? param.paramName : ''}
                                        maxLength="50"
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2}> <FormattedMessage id="MachineLearningConfig.Values" /> </Label>
                                <Col sm={10}>
                                    <Input
                                        type="textarea"
                                        name="paramValues"
                                        onChange={(e) => this.handleChange(e)}
                                        value={param.paramValues ? param.paramValues : ''}
                                        maxLength="50"
                                        required
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </BlankCard>
                </ModalBody>

                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={this.toggleDeleteModal}
                        actionFunction={(e) => this.removeParam(e)}
                        text={<FormattedMessage id="MachineLearningConfig.ConfirmDeleteParamText" values={{ paramName: param.paramName }} />}
                        block={block}
                    />
                    : ''}
            </Modal>
        );
    }
}
export default injectIntl(ModelDetails)