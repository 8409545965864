import React, { Component } from 'react'
import { StyledCard, DefaultPage } from '../Base/CommonUIComponents';
import { getProfileType } from '../Base/ReferenceDataFunctions';
import {Button, Col, Row, Form } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI } from '../Base/API';
import { CustomTable } from '../Base/CustomTable';
import CustomSelect from '../Base/CustomSelect';
import { handleNotification } from '../Base/Notification';

class ProfileCreditBalance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profileType: null,
            block: true,
            error: null,
            profilesData: [],
            profileCreditCsvData: [],
            columns: [
                {
                    dataField: 'profileType',
                    text: this.props.intl.formatMessage({ id: "ProfileCredit.ProfileType" }),
                    sort: true,
                    headerStyle: () => {
                        return { width: "25%" };
                    }
                },
                {
                    dataField: 'name',
                    text: this.props.intl.formatMessage({ id: "ProfileCredit.FirstName" }),
                    sort: true,
                    headerStyle: () => {
                        return { width: "35%" };
                    },
                    formatter: (cell, row) => <span className={row.profileId ? "hrefStyle" : ''} onClick={() => { if (row.profileId) window.open(`ProfileDetails/${row.profileId}`, "_blank") }}>
                        {cell}
                    </span>
                },
                {
                    dataField: 'profileId',
                    text: this.props.intl.formatMessage({ id: "ProfileCredit.ActiveCredits" }),
                    sort: true,
                    headerStyle: () => {
                        return { width: "15%" };
                    },
                    formatter: (_, row) => row.creditData?.filter(el => el.hasCityLedger).length || 0
                },
                {
                    dataField: 'creditData',
                    text: this.props.intl.formatMessage({ id: "ProfileCredit.InactiveCredits" }),
                    sort: true,
                    headerStyle: () => {
                        return { width: "15%" };
                    },
                    formatter: (cell) => cell?.filter(el => !el.hasCityLedger).length || 0
                },
                {
                    dataField: 'vat',
                    text: this.props.intl.formatMessage({ id: "ProfileCredit.Nif" }),
                    sort: true,
                    headerStyle: () => {
                        return { width: "10%" };
                    }
                }
            ],
            columnsDetails: [
                {
                    dataField: 'hotelName',
                    text: this.props.intl.formatMessage({ id: "ProfileCredit.HotelName" }),
                    sort: true,
                    headerStyle: () => {
                        return { width: "15%" };
                    }
                },
                {
                    dataField: 'hasCityLedgerLabel',
                    text: this.props.intl.formatMessage({ id: "ProfileCredit.CityLedger" }),
                    sort: true,
                    headerStyle: () => {
                        return { width: "10%" };
                    }
                },
                {
                    dataField: 'creditLimit',
                    text: this.props.intl.formatMessage({ id: "ProfileCredit.CreditLimit" }),
                    sort: true,
                    headerStyle: () => {
                        return { width: "10%" };
                    }
                },
                {
                    dataField: 'creditLimitPOS',
                    text: this.props.intl.formatMessage({ id: "ProfileCredit.CreditLimitPOS" }),
                    sort: true,
                    headerStyle: () => {
                        return { width: "10%" };
                    }
                },
                {
                    dataField: 'creditLimitType',
                    text: this.props.intl.formatMessage({ id: "ProfileCredit.CreditLimitType" }),
                    sort: true,
                    headerStyle: () => {
                        return { width: "10%" };
                    }
                },
                {
                    dataField: 'creditLimitTypePOS',
                    text: this.props.intl.formatMessage({ id: "ProfileCredit.CreditLimitTypePOS" }),
                    sort: true,
                    headerStyle: () => {
                        return { width: "10%" };
                    }
                }
            ]
        }
    }

    componentDidMount() {
        this.getProfileCreditData();
    }

    getProfileCreditData(e) {
        if (e) e.preventDefault();

        const { profileType } = this.state;

        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                    }
                    else if (data.response && data.response.length > 0) {
                        data.response.forEach(profile => {
                            profile.creditData.forEach(credit => {                                
                                credit.hotelName = global.hotelList.find(hotel => hotel.value === credit.hotelId)?.label;
                                credit.creditLimitType = credit.creditLimitType === 0 ?
                                    this.props.intl.formatMessage({ id: "ProfileDetails.Unrestricted" })
                                    :
                                    credit.creditLimitType === 1 ?
                                        this.props.intl.formatMessage({ id: "ProfileDetails.Warning" })
                                        :
                                        this.props.intl.formatMessage({ id: "ProfileDetails.Blocked" });

                                credit.creditLimitTypePOS = credit.creditLimitTypePOS === 0 ?
                                    this.props.intl.formatMessage({ id: "ProfileDetails.Unrestricted" })
                                    :
                                    credit.creditLimitTypePOS === 1 ?
                                        this.props.intl.formatMessage({ id: "ProfileDetails.Warning" })
                                        :
                                        this.props.intl.formatMessage({ id: "ProfileDetails.Blocked" });

                                    credit.hasCityLedgerLabel = credit.hasCityLedger ?
                                        this.props.intl.formatMessage({ id: "ProfileCredit.CityLedgerActive" })
                                        :
                                        this.props.intl.formatMessage({ id: "ProfileCredit.CityLedgerInactive" });
                            });
                        });
                        this.setState({ profilesData: data.response }, () => this.getHotelList());
                    }
                    else {
                        handleNotification(data);
                        this.setState({ block: false });
                    }
                }
            }, `api/gms/Profile/v1/profile/mastersbalance?${profileType ? `profileType=${profileType}` :''}`);
        });
    }

    handleChangeComboSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    profileDetailsTable = (creditData) => {
        const { columnsDetails } = this.state;

        return(
            creditData ?
                <CustomTable
                    keyField={`hotelId`}
                    wrapperClasses={'profileCreditDetailsTable'}
                    classes={'profileCreditDetailsTable border'}
                    data={creditData}
                    columns={columnsDetails}
                    hideSizePerPage={true}
                    shadow={false}
                    search={false}
                    hasPagination={false}
                    bordered={true}
                />
            :''
        )
    }

    getHotelList = () => {
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                }
                else if (data.response && data.response.length > 0) {
                    this.setState({ hotelList: data.response, block: false });
                }
                else {
                    handleNotification(data);
                    this.setState({ block: false });
                }
            }
        }, `api/gms/Hotel/v1/hotel/list?onlyFromHotelGroup=true`);
    }

    downloadCSV = () => {
        const { profilesData, hotelList } = this.state;

        let csvData = '"sep=;"\n';

        csvData += '"Profile Type";"Name";"VAT";';

        hotelList.filter(x => global.hotelList.some(y => y.value == x.hotelId)).forEach((hotel, index) => {
            csvData += `"${hotel.name2} CityLedger";"${hotel.name2} Credit Limit";"${hotel.name2} Credit Limit POS";"${hotel.name2} Credit Limit Type";"${hotel.name2} Credit Limit Type POS"`;
            if (index !== hotelList.filter(x => global.hotelList.some(y => y.value == x.hotelId)).length - 1) {
                csvData += ';';
            }
        });

        csvData += '\n';

        profilesData.forEach(profile => {
            csvData += `${profile.profileType};${profile.name};${profile.vat};`;

            hotelList.filter(x => global.hotelList.some(y => y.value == x.hotelId)).forEach((hotel, index) => {
                const detail = profile.creditData?.find(p => p.hotelId === hotel.hotelId);

                if (detail) {
                    csvData += `"${detail.hasCityLedger}";"${detail.creditLimit}";"${detail.creditLimitPOS}";"${detail.creditLimitType}";"${detail.creditLimitTypePOS}"`;
                } else {
                    csvData += '"";"";"";"";';
                }

                if (index !== global.hotelList.length - 1) {
                    csvData += ';';
                }
            });
            
            csvData += '\n';
        });

        let anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
        anchor.target = '_blank';
        anchor.download = 'profileCredit.csv';
        anchor.click();
    }

    render() {
        const { block, error, profileType, profilesData, columns } = this.state;

        const expandRow = {
            renderer: row => (
                this.profileDetailsTable(row.creditData)
            ),
            showExpandColumn: true
        };

        return (
            <StyledCard block={block} error={error}>
                <Row>
                    <Col>
                        <h5> <i className="far fa-credit-card mr-2"></i> <FormattedMessage id="NavMenu.ProfileCredit" /></h5>
                    </Col>
                </Row>
                <Form onSubmit={(e) => this.getProfileCreditData(e)} className='mb-2'>
                    <Row className='align-items-center'>
                        <Col className='col-3 my-2'>
                            <CustomSelect
                                placeholder={<FormattedMessage id="SearchProfile.EntityType" />}
                                options={getProfileType(this.props.intl).filter(t => t.value === 'Agency' || t.value === 'Company')}
                                value={getProfileType(this.props.intl).find(t => t.value === profileType)}
                                onChange={this.handleChangeComboSelect.bind(this, 'profileType')}
                                isClearable
                                isSearchable
                            />
                        </Col>
                        
                        <Col className='text-right'>
                            <Button className="btn btn-host btn-sm float-right" type='submit'>
                                <i className="icon-icon-search" />
                            </Button> 
                            <Button className="btn btn-host btn-sm float-right shadow mr-2" id="exportCsvButton" onClick={() => this.downloadCSV()}>
                                <i className="fas fa-file-excel"></i>
                            </Button>                
                        </Col>
                    </Row>
                </Form>
                {profilesData && profilesData.length > 0 ?
                    <CustomTable
                        keyField={'profileId'}
                        data={profilesData}
                        columns={columns}
                        search={true}
                        showTotal={true}
                        rowStyle={{ cursor: 'pointer' }}
                        hideSizePerPage={false}
                        exportCSV={false}
                        expandRow={expandRow}
                        sort={false}
                        shadow={false}
                        sizePerPage={10} 
                    />
                : !block ?
                    <DefaultPage text="ProfileCredit.NoProfiles" icon="far fa-credit-card" />
                : ''
                }
            </StyledCard>
            
        )
    }
}

export default injectIntl(ProfileCreditBalance)