import React, { Component } from 'react';
import { Badge, Col, Row,  CustomInput, Input } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';


class CampaignTags extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterValue: null
        }
    }
    componentDidMount() {
    }

    filterTags = (e) => {
        const {  value } = e.target;
        this.setState({
            filterValue: value
        })
    }


    render() {
        const { changeTagMin, handleTag, filters, tagList, handleTagType, intl } = this.props;

        const tagParametersFilterAndTypes = [undefined, null, 'and'];

        return (
            <Row>
                <Col>
                    <Row>
                        <Col className="col-2">
                            <CustomInput
                                type="switch"
                                id="and"
                                name="and"
                                onChange={(e) => handleTagType(e)}
                                checked={tagParametersFilterAndTypes.some(tp => tp === filters.tagParametersFilterType)}
                                label={intl.formatMessage({ id: 'CampaignTags.AndTag' })}
                            />
                        </Col>
                        <Col className="col-2">
                            <CustomInput
                                type="switch"
                                id="or"
                                name="or"
                                onChange={(e) => handleTagType(e)}
                                checked={filters.tagParametersFilterType === 'or'}
                                label={intl.formatMessage({ id: 'CampaignTags.OrTag' })}
                            />
                        </Col>
                        <Col className="col-4" ></Col>
                        <Col className="col-4" >
                            <Input placeholder={intl.formatMessage({ id: 'CampaignTags.SearchTag' })} onChange={(e) => this.filterTags(e)}></Input>
                        </Col>
                    </Row>
                    <div className="mt-1 scrollableDiv" style={{ height: '90%' }}>
                        {tagList && tagList.map((el, k) =>
                            <Row className="mt-2 pl-1" key={k}>
                                <Col className="col-2"> {el.code}:  </Col>
                                <Col className="d-flex col-10">
                                    {el.tag && el.tag.map((tag, k1) => {
                                        const tagFilter = filters.tags && filters.tags.find(t => t.id === tag.id);
                                        const isSelected = tagFilter !== undefined ? true : false;
                                        const isvisible = ((this.state.filterValue === undefined || this.state.filterValue === null  )|| tag && tag.code?.toLowerCase().includes(this.state.filterValue?.toLowerCase()));


                                        return isvisible || isSelected ? <span key={k1}>
                                            <Badge className=" my-1 mr-2 d-flex align-items-center" color={isSelected ? "primary" : "secondary"} style={{ cursor: 'pointer', height: '30px' }} >
                                                <span onClick={() => handleTag(tag)}>{tag.code} </span>
                                                {isSelected ?
                                                    <span className="bg-white text-host ml-2 px-1 invisible-input " style={{ borderRadius: '20px' }}>
                                                        <input className="hide-input-arrows text-center" style={{ width: '30px' }}
                                                            type="number" min="0"
                                                            onChange={(e) => changeTagMin(e, tagFilter)}
                                                            value={tagFilter.min} />
                                                    </span>
                                                    : ''}
                                            </Badge>
                                        </span> : "";
                                    }
                                    )}
                                </Col>
                            </Row>
                        )}
                    </div>
                </Col>
            </Row>    
        );
    }
}
export default injectIntl(CampaignTags);