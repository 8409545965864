import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Breadcrumb, BreadcrumbItem, Button, Card, Col, Modal, ModalBody, Row, Form, FormGroup, Label, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { deleteAPI, getAPI, getMultiPartAPI, postMultiPartAPI } from '../../../Base/API';
import { BlankCard, StyledCard } from '../../../Base/CommonUIComponents';
import { handleNotification } from '../../../Base/Notification';
import { Pagination } from '../../../Base/PaginationComponents';
import CustomSelect from "../../../Base/CustomSelect";
import MediaPreview from './MediaPreview';
import BlockUi from 'react-block-ui';
import { getMediaTypes } from '../../../Base/ReferenceDataFunctions';
import MediaModal from './MediaModal';
import ConfirmActionModal from '../../../Base/ConfirmActionModal';

class MediaManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            pageIndex: 0,
            pageSize: 12,
            totalItems: 0,
            mediaList: null,
            uploadMediaModal: false,
            deleteModal: false,
            mimeType: [ 'audio/aac, audio/mp4, audio/mpeg, audio/amr, audio/ogg, image/jpeg, image/png, video/mp4, video/3gp, image/webp, text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
        };
    }

    componentDidMount() {
        this.getAllMedia();
        this.getPhones();
    }

    getPhones = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data)
                }

                const phoneNumbersOptions = [];

                if (data.response && data.response.length > 0) {
                    data.response[0].data.forEach(el => {
                        phoneNumbersOptions.push({ value: el.id, label: el.verified_name || el.display_phone_number });
                    })
                }

                this.setState({ block: false, error: errorMessage, phoneNumbersOptions });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/WhatsApp/v1/phoneNumber`)
    }

    getAllMedia = () => {
        this.setState({ block: true });

        const param = this.state.mediaType ? `&type=${this.state.mediaType}` : ''

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.response) {
                    this.setState({ mediaList: data.response, totalItems: data.totalItems, block: false })
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `api/gms/WhatsApp/v1/media?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}` + param)
    }

    getMediaDetails = (mediaId, index, block) => {
        this.setState({ [block]: true });

        const { mediaList } = this.state;

        getMultiPartAPI(result => {
            const { data, error } = result;

            if (error) {
                mediaList[index].notFound = true;

                this.setState({ mediaList, [block]: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);

                    mediaList[index].notFound = true;
                    this.setState({ mediaList, [block]: false });
                }
                else {
                    const reader = new FileReader();
                    reader.readAsDataURL(data);

                    reader.onloadend = () => {
                        mediaList[index].preview = reader.result;

                        this.setState({ mediaList, [block]: false });
                    }
                }
            }
            else {
                this.setState({ [block]: false });
            }
        }, `api/gms/WhatsApp/v1/${mediaId}/media`);
    }

    uploadNewMedia = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const formData = new FormData();
        formData.append('mediaFile', this.state.newMedia);

        postMultiPartAPI(result => {
            const { data, error } = result;

            var errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="MediaManagement.MediaUploaded" />, <FormattedMessage id="General.Success" />);
                    this.toggleModal('uploadMediaModal');
                    this.getAllMedia();
                }

                this.setState({ error: errorMessage, block: false });
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/WhatsApp/v1/${this.state.phoneNumber}/media`, formData);
    }

    handleMedia = (e) => {
        e.preventDefault();

        let file = e.target.files[0];

        if (file) {
            if (file.size <= 2097152) { //2MB
                let reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onloadend = () => {
                    this.setState({
                        newMedia: file,
                        mediaPreview: { type: file.type, preview: reader.result }
                    });
                }
            }
            else {
                document.getElementById('media').value = "";
                handleNotification('', <FormattedMessage id="MediaManagement.PleaseSelectAFileLessThan" />, <FormattedMessage id="MediaManagement.SelectedFileIsTooBig" />, 'info');
            }
        }
        else {
            document.getElementById('media').value = "";
            this.setState({ mediaPreview: null })
        }
    }

    deleteMedia = (e, mediaId) => {
        if (e) e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false }, this.toggleDeleteModal());
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else {
                    handleNotification(data, <FormattedMessage id="MediaManagement.MediaDeleted" />, <FormattedMessage id="General.Success" />);

                    this.getAllMedia();
                }

                this.toggleDeleteModal();
            }
            else {
                this.setState({ block: false });
            }
        }, `api/gms/WhatsApp/v1/media/${mediaId}`);
    }

    doSearch = (e) => {
        e.preventDefault();

        this.setState({
            pageIndex: 0
        }, this.getAllMedia)
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.getAllMedia());
    }

    toggleModal = (modal) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            mediaDetails: null,
            mediaPreview: null,
            newMedia: null,
            phoneNumber: null
        }))
    }

    toggleDeleteModal = (mediaToDelete) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
            mediaToDelete
        }));
    }

    handleSelect = (combo, name) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    render() {
        const { block, error, pageIndex, pageSize, totalItems, mediaList, mediaPreview, deleteModal } = this.state;

        return (
            <StyledCard block={block} error={error} >
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to="/WhatsAppConfig">
                            <FormattedMessage id="NavMenu.WhatsAppConfig" />
                        </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                        <FormattedMessage id="WhatsApp.MediaManagement" />
                    </BreadcrumbItem>
                </Breadcrumb>

                <Form onSubmit={this.doSearch}>
                    <Row className="mb-3">
                        <Col sm={3}>
                            <CustomSelect
                                icon="fas fa-photo-video"
                                options={getMediaTypes(this.props.intl)}
                                isClearable
                                isSearchable
                                placeholder={<FormattedMessage id="MediaManagement.MediaType" />}
                                onChange={(e) => this.handleSelect(e, "mediaType")}
                            />
                        </Col>
                        <Col className="text-right mt-2">
                            <Button className="btn-sm btn-host mr-2" onClick={() => this.toggleModal('uploadMediaModal')}>
                                <i className="fas fa-plus" />
                            </Button>
                            <Button className="btn-sm btn-host mr-2" type="submit">
                                <i className="fas fa-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <Row>
                    {mediaList?.map((media, index) => {
                        const block = `blockMedia-${index}`;

                        return <Col key={index} className="col-3 mb-4">
                            <Card className="border-0 shadow" style={{ borderRadius: '15px' }}>
                                <BlockUi blocking={this.state[block]}>
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                        {media ?
                                            <MediaPreview
                                                media={{ preview: media.preview, type: media.filetype, id: media.contentId, fileName: media.filename, notFound: media.notFound }}
                                                getMediaDetails={() => this.getMediaDetails(media.contentId, index, block)}
                                                block={this.state[block]}
                                                borderRadius='15px 15px 0 0'
                                                loadImage={true}
                                            />
                                        : ''}
                                    </div>
                                </BlockUi>
                                <CardBody className="mb-2 mt-1">
                                    <Row className="mb-2 align-items-center">
                                        <Col className="col-10 pr-0">
                                            <h5 title={media.filename} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', margin: '0' }}>
                                                {media.filename}
                                            </h5>
                                        </Col>
                                        <Col className="col-2 text-right">
                                            <Button className="btn btn-host btn-sm" onClick={() => this.toggleDeleteModal(media.id)}>
                                                <i className="fas fa-trash-alt"/>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <div title={media.filetype} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}> {media.filetype}</div>
                                </CardBody>
                            </Card>
                        </Col>
                    })}
                </Row>

                <Row className="my-4">
                    <Col className="text-right mr-3">
                        <Pagination
                            isPrevDisabled={pageIndex === 0}
                            isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                            currentPage={pageIndex + 1}
                            handlePrevButton={() => this.handlePagination(-1)}
                            handleNextButton={() => this.handlePagination(1)}
                        />
                    </Col>
                </Row>

                <MediaModal
                    uploadMediaModal={this.state.uploadMediaModal}
                    toggleModal={this.toggleModal}
                    uploadNewMedia={this.uploadNewMedia}
                    phoneNumbersOptions={this.state.phoneNumbersOptions}
                    handleSelect={this.handleSelect}
                    handleMedia={this.handleMedia}
                    mimeType={this.state.mimeType}
                    newMedia={this.state.newMedia}
                    mediaPreview={this.state.mediaPreview}
                    block={block}
                />

                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={this.toggleDeleteModal}
                        actionFunction={(e) => this.deleteMedia(e, this.state.mediaToDelete)}
                        text={<FormattedMessage id="MediaManagement.ConfirmDeleteText" />}
                        block={block}
                    />
                : ''}
            </StyledCard>
        )
    }
}

export default injectIntl(MediaManagement);