import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row, UncontrolledPopover } from 'reactstrap';
import { CommonHelper, FormatAmountNumber } from '../Base/CommonUIComponents';
import moment from 'moment-timezone';
import { getHolidays } from '../Marketing/CommunicationJourney/CommonFunctions';

class SalesDealProposalCalendar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDate: moment(),
            months: [
                { month: 1 },
                { month: 2 },
                { month: 3 },
                { month: 4 },
                { month: 5 },
                { month: 6 },
                { month: 7 },
                { month: 8 },
                { month: 9 },
                { month: 10 },
                { month: 11 },
                { month: 12 }
            ],
            weekDays: moment.weekdaysShort(),
            totalCalendarRows: 0,
            holidaysOfMonth: [],
            dealStartDate: null,
            dealEndDate: null
        };
    }
    
    componentDidMount(){
        this.initialCalc(this.props.proposals);
    }

    componentDidUpdate(prevProps) {
        if (this.props.filtersLength !== prevProps.filtersLength) {
            this.renderCalendar(this.props.proposals);
        }
    }

    initialCalc = (proposals) => {
        if(!proposals)
            return;

        const dealStartDate = moment.min(proposals.map(({startDate}) => moment(startDate)));
        const dealEndDate = moment.max(proposals.map(({endDate}) => moment(endDate)));

        const currentDate = dealStartDate ? dealStartDate.clone() : moment();

        const months = this.addHolidays();

        this.setState({
            dealStartDate,
            dealEndDate,
            months,
            currentDate
        }, () => this.renderCalendar());
    }

    renderCalendar = () => {
        const { proposals } = this.props;
        const { months, currentDate } = this.state;

        const holidaysOfMonth = months ? months.find(m => m.month == currentDate.format('M')).holidays : [];

        let blank = this.getBlankCells();
        let daysInMonth = this.getDaysInMonth();
        let totalSlots = [...blank, ...daysInMonth];
        let cells = [], calendarRows = [];

        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row); // if index not equal 7 that means not go to next week
            } else {
                calendarRows.push(cells); // when reach next week we contain all td in last week to rows 
                cells = []; // empty container 
                cells.push(row); // in current loop we still push current row to new container
            }
            if (i === totalSlots.length - 1) { // when end loop we add remain date
                while (cells.length < 7) {
                    cells.push({ day: "" });
                }
                calendarRows.push(cells);
            }
        });
        
        const totalCalendarRows = (calendarRows && calendarRows.filter(cr => cr.length > 0).length) ?? 0;
        
        proposals.forEach(({ proposalJSON, externalId, hotelId, isVisible }) => {
            if (isVisible) {
                const groupReservation = proposalJSON.data[0];

                if (proposalJSON.BlockData?.details?.length > 0) {
                    proposalJSON.BlockData.details.forEach(block => {
                        if (block.isVisible) {
                            const rowIndex = calendarRows.findIndex(row => row.some(day => moment(block.Date).isSame(day.date, 'day')));
                            
                            if (rowIndex !== -1) {
                                const dayIndex = calendarRows[rowIndex].findIndex(el => moment(block.Date).isSame(el.date, 'day'))
                                
                                if (dayIndex !== -1) {
                                    const headerData = proposalJSON.BlockData.headerData?.length > 0 ? proposalJSON.BlockData.headerData.find(el => el.Id === block.HeaderId) : null;

                                    calendarRows[rowIndex][dayIndex].dayEvents.push({
                                        isRoom: true,
                                        categoryId: block.CategoryId,
                                        category: block.CategoryCode,
                                        priceList: headerData?.PriceListCode,
                                        package: headerData?.PackageCode,
                                        numberOfRooms: block.CurrentRooms,
                                        priceTotal: block.CurrentRooms * block.PriceAfterTaxPerRoom_Total,
                                        externalId: externalId,
                                        hotelId
                                    })
                                }
                            }
                        }
                    });
                }

                if (groupReservation.DetailsEvents && groupReservation.DetailsEvents.length > 0) {
                    groupReservation.DetailsEvents.forEach(event => {
                        if (event.isVisible) {
                            const visibleSpaces = event.Spaces.filter(sp => sp.isVisible);

                            const eventStart = moment.min(visibleSpaces.map(({ FromDate }) => moment(FromDate)));
                            const eventEnd = moment.max(visibleSpaces.map(({ ToDate }) => moment(ToDate)));

                            const days = calendarRows.flatMap(cells => cells).filter(d => d.date && (d.date.isSameOrAfter(eventStart, 'day') && d.date.isSameOrBefore(eventEnd, 'day')));

                            if (days && days.length > 0) {
                                days.forEach(day => {
                                    const daySpaces = visibleSpaces.filter(({ FromDate, ToDate }) =>
                                        day.date.isSameOrAfter(moment(FromDate), 'day') &&
                                        day.date.isSameOrBefore(moment(ToDate), 'day')
                                    ).map((space) => ({
                                        name: space.SpaceName,
                                        desc: space.SpaceDescription,
                                        type: space.SpaceTypeDescription,
                                        checkIn: moment(space.FromDate),
                                        checkOut: moment(space.ToDate)
                                    }));

                                    day.dayEvents.push({
                                        isEvent: true,
                                        checkIn: eventStart,
                                        checkOut: eventEnd,
                                        isStartDate: eventStart.isSame(day.date, 'day'),
                                        isEndDate: eventEnd.isSame(day.date, 'day'),
                                        name: event.EventName,
                                        type: event.EventType,
                                        manager: event.EventManager,
                                        priceTotal: event.PriceTotal,
                                        spaces: daySpaces,
                                        externalId: externalId,
                                        hotelId
                                    });
                                });
                            }
                        }
                    });
                }
            }
        });

        this.setState({
            calendarRows,
            totalCalendarRows,
            holidaysOfMonth
        });
    }

    addHolidays = () => {
        let { months, currentDate } = this.state;
        const holidays = global.hotelCountryCode ? getHolidays(currentDate.format("YYYY")).find(el => el.country.toLowerCase() === global.hotelCountryCode.toLowerCase()) : getHolidays(currentDate.format("YYYY"))[0];

        if (holidays) {
            holidays.months && holidays.months.forEach(dates => {
                const index = months.findIndex(m => m.month === dates.month);

                if (index > -1) {
                    months[index].holidays = dates.celebrations;
                }
            });
        }

        return months;
    }

    getFirstDayOfMonth = () => {
        let dateObject = this.state.currentDate;
        let firstDay = moment(dateObject)
            .startOf("month")
            .format("d");
        return firstDay;
    };

    getDaysInMonth = () => {
        let daysInMonth = [];

        for (let d = 1; d <= moment(this.state.currentDate).daysInMonth(); d++) {
            daysInMonth.push({ day: d, date: moment(this.state.currentDate).startOf('month').add((d - 1), 'day'), dayEvents: [] });
        }

        return daysInMonth;
    }

    getBlankCells = () => {
        let blanks = [];

        for (let i = 0; i < this.getFirstDayOfMonth(); i++) {
            blanks.push({ day: "" });
        }

        return blanks;
    }

    changeMonth = (trans) => {
        const date = this.state.currentDate.add(trans, 'month');

        this.setState({
            currentDate: date
        }, () => this.renderCalendar())
    }

    changeYear = (trans) => {
        const date = this.state.currentDate.add(trans, 'year');

        this.setState({
            currentDate: date,
            months: this.addHolidays(),
        }, () => this.renderCalendar());
    }

    render() {
        const { currentDate, weekDays, calendarRows, totalCalendarRows, holidaysOfMonth, dealStartDate, dealEndDate } = this.state;
        const { hotelCurrency } = this.props;
        return (
            <div>
                <Row>
                    <Col className="col-3 text-muted" style={{ fontSize: '1.1em' }}>
                        <i className="far fa-calendar-alt mr-2"/>
                        <FormattedMessage id="SalesDeal.Calendar"/>
                    </Col>
                    <Col className='col-6 d-flex align-items-center justify-content-center'>
                        <div>
                            <div className='cursor-pointer' onClick={_ => this.changeMonth(-1)}>
                                <b><i className="fas fa-chevron-left"/></b>
                            </div>
                        </div>
                        <div className='mx-4'>
                            <b style={{ fontSize: '1.1em' }}>
                                {currentDate.format('MMMM')}
                            </b>
                        </div>
                        <div>
                            <div className='cursor-pointer' onClick={_ => this.changeMonth(1)}>
                                <b><i className="fas fa-chevron-right"/></b>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-3 text-right">
                        <CommonHelper
                            help="Calendario ajudas e tudo"
                            id="sales-process-deal-proposal-calendar-help"
                        />
                    </Col>
                </Row>
                <div className='mt-4'>
                    <Row className="mb-3 pl-4 align-items-center px-3">
                        {weekDays && weekDays.map(day =>
                            <Col key={day} className="week-day py-0 px-1 text-center">
                                {day}
                            </Col>
                        )}
                    </Row>
                    <div className='px-3'>
                        {calendarRows && calendarRows.map((week, i) => week.length > 0 ?
                            <Row key={i} style={{ height: `${(100 / totalCalendarRows - 1)}%`, minHeight: '120px', overflow: 'hidden' }}>

                                {week.map((day, key) => {
                                    const holiday = holidaysOfMonth && holidaysOfMonth.find(hom => hom.day === day.day);

                                    const isCurrentDay = day.date && currentDate.isSame(day.date) ? " bg-host-selected circle-md" : '';
                                    const isHoliday = isCurrentDay === '' && holiday ? " bg-dark-blue circle-md" : '';
                                    const calendarDay = isCurrentDay === '' && isHoliday === '' ? ' py-1 px-2' : '';

                                    const isDealStartDate = day.date && dealStartDate.isSame(day.date, 'day') ? 'isDealStartDate' : '';
                                    const isDealEndDate = day.date && dealEndDate.isSame(day.date, 'day') ? 'isDealEndDate' : '';

                                    const isDuringDeal = () => {
                                        if(day.date){
                                            if(day.date.isAfter(dealStartDate, 'day') && day.date.isBefore(dealEndDate, 'day')){
                                                return 'isDuringDeal';
                                            }
                                        }
                                        else{ // paint previous and next month cells
                                            if(i > 6){ // Next Month cells
                                                const previousWeek = calendarRows[i - 1];

                                                if(previousWeek){
                                                    const previousDay = previousWeek.at(-1);

                                                    if(previousDay && previousDay.date.isBefore(dealEndDate, 'day') && previousDay.date.isSameOrAfter(dealStartDate, 'day')){
                                                        return 'isDuringDeal';
                                                    }
                                                }
                                            }
                                            else{ // Previous month cells
                                                const nextDay = week.find(({day}) => day);
                                                if(nextDay && nextDay.date.isBefore(dealEndDate, 'day') && nextDay.date.isAfter(dealStartDate, 'day')){
                                                    return 'isDuringDeal';
                                                }
                                            }
                                        }
                                        return '';
                                    };

                                    let numberOfRooms = day.dayEvents?.filter(({ isRoom }) => isRoom)
                                        .reduce((a, b) => a + (b.numberOfRooms ?? 1), 0) || 0;

                                    let numberOfSpaces = day.dayEvents?.filter(({ isEvent }) => isEvent)
                                        .flatMap(({ spaces }) => spaces)
                                        .reduce((a, b) => a + (b.numberOfRooms ?? 1), 0) || 0;

                                    let rooms = day.dayEvents?.filter(({ isRoom }) => isRoom) || [];
                                    let events = day.dayEvents?.filter(({ isEvent }) => isEvent) || [];


                                    return (
                                        <React.Fragment key={key}>
                                            <Col className={`calendar-border p-3 overflow-hidden ${isDealStartDate} ${isDealEndDate} ${isDuringDeal()}` + (key === 0 || key === 6 ? ' bg-weekend' : '')}>
                                                <div className="d-flex align-items-center">
                                                    <div className={` ${isCurrentDay} ${isHoliday} ${calendarDay}`}>
                                                        <b> {day.day} </b>
                                                    </div>
                                                    {holiday ?
                                                        <b className={"ml-2 overflow-hidden text-nowrap text-truncate w-100 text-dark-blue"} title={holiday.label}>
                                                            {holiday.label}
                                                        </b>
                                                    :''}
                                                </div>
                                                <div className='mt-2'>
                                                    {numberOfRooms ?
                                                        <div id={`Rooms-${i}-${key}`} className='sales-deal-calendar-rooms'>
                                                            <div className='mr-1'>{numberOfRooms}</div>
                                                            <FormattedMessage id="SalesDeal.Rooms"/>
                                                        </div>
                                                    :''}
                                                    {events && events.length > 0 ?
                                                        <div id={`Spaces-${i}-${key}`} className='sales-deal-calendar-spaces'>
                                                            <div className="mr-1">{numberOfSpaces}</div>
                                                            <FormattedMessage id="SalesDeal.Spaces"/>
                                                        </div>
                                                    :''}
                                                </div>
                                                {numberOfRooms && rooms && rooms.length > 0 ?
                                                    <UncontrolledPopover target={`Rooms-${i}-${key}`} placement={"right-start"} placementPrefix='coolPopover bs-popover'>
                                                        <div className='py-2 pl-3 pr-2' style={{ minWidth: '200px', maxWidth: '280px' }} >
                                                            <div className='text-muted d-flex align-items-center mb-2' style={{ fontSize: '1.1em' }}>
                                                                <i className="fas fa-bed mr-2 mt-1"/>
                                                                <FormattedMessage id="SalesDeal.Rooms"/>
                                                            </div>
                                                            <div>
                                                                <div className="verticalScroll pr-2" style={{ maxHeight: '280px', overflow: 'auto', overflowX: 'hidden' }}>
                                                                    {rooms.map((room, key) =>
                                                                        <div key={key} className='d-flex align-items-start justify-content-between mt-1' >
                                                                            <div className='d-flex'>
                                                                                <div>{room.category}</div>
                                                                                <div className='mx-2'>-</div>
                                                                                <div>{room.priceList}</div>
                                                                            </div>
                                                                            <div className='text-right'>
                                                                                {room.numberOfRooms}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className='d-flex align-items-center justify-content-between mt-2 pt-2' style={{ borderTop: '1px solid lightgrey' }}>
                                                                    <div>
                                                                        <b><FormattedMessage id="SalesDeal.Total"/></b>
                                                                    </div>
                                                                    <div>
                                                                        <FormatAmountNumber
                                                                            value={rooms.reduce((a, b) => a + b.priceTotal, 0)}
                                                                            currency={hotelCurrency}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </UncontrolledPopover>
                                                :''}
                                                {numberOfSpaces && events && events.length > 0 ?
                                                    <UncontrolledPopover target={`Spaces-${i}-${key}`} placement={"right-start"} placementPrefix='coolPopover bs-popover'>
                                                        <div className='py-2 pl-3 pr-2' style={{ minWidth: '200px', maxWidth: '280px' }} >
                                                            <div className='text-muted d-flex align-items-center mb-2' style={{ fontSize: '1.1em' }}>
                                                                <i className="fas fa-theater-masks mr-2 mt-1"/>
                                                                <FormattedMessage id="SalesDeal.Events"/>
                                                            </div>

                                                            <div className="verticalScroll pr-2" style={{ maxHeight: '280px', overflow: 'auto', overflowX: 'hidden' }}>
                                                                {events.map((event, key) =>
                                                                    <div key={key} className='pt-1 mb-3' style={{ borderTop: key ? '1px solid lightgrey' : '' }}>
                                                                        <div className='d-flex align-items-start justify-content-between'>
                                                                            <div title={event.name} className='text-truncate' style={{ maxWidth: '80%' }}>
                                                                                {event.name}
                                                                            </div>
                                                                            <div className='text-truncate'>
                                                                                <FormatAmountNumber
                                                                                    value={event.priceTotal}
                                                                                    currency={hotelCurrency}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {event.spaces ?
                                                                            <div className='mt-2 pl-3'>
                                                                                <div className='text-muted d-flex align-items-center mb-2' style={{ fontSize: '1.1em' }}>
                                                                                    <i className="fas fa-store-alt mr-2"/>
                                                                                    <FormattedMessage id="SalesDeal.Spaces"/>
                                                                                </div>
                                                                                {event.spaces.map((space, key) =>
                                                                                    <div key={key} className='px-2 mt-1'>
                                                                                        {space.name}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        :''}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className='d-flex align-items-center justify-content-between mt-2 pt-2' style={{ borderTop: '1px solid lightgrey' }}>
                                                                <div>
                                                                    <FormattedMessage id="SalesDeal.Total"/>
                                                                </div>
                                                                <div>
                                                                    <FormatAmountNumber
                                                                        value={events.reduce((a, b) => a + b.priceTotal, 0)}
                                                                        currency={hotelCurrency}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </UncontrolledPopover>
                                                :''}
                                            </Col>
                                        </React.Fragment>
                                    )
                                })}
                            </Row>
                        :'')}
                    </div>
                </div>
            </div>
        );
    }
}

export default SalesDealProposalCalendar;
