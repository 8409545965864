import React, { Component } from 'react';
import { Row, Col, Badge, UncontrolledPopover, Card, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { FormattedMessage, injectIntl, FormattedNumber } from 'react-intl';
import { handleNotification } from '../../Base/Notification';
import { ErrorAlert } from "../../Base/ErrorAlert";
import { getAPI } from '../../Base/API';
import BlockUi from 'react-block-ui';
import moment from 'moment';
import { EgoiFormAnswers, improvFormObj, FormAnswers } from '../../Marketing/Common/MarketingFunctions';
import { Pagination, TotalResults } from '../../Base/PaginationComponents';
import SelectedProfileForm from './SelectedProfileForm';

class ProfileForms extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            forms: [],
            pageIndex: 0,
            pageSize: 9,
            totalItems: 0,
            selectedFormIdx: null
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.props.profileId) {
            this.getForms();
        }
    }

    getForms = () => {
        this.setState({ block: true });
        const { intl } = this.props;

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                const forms = data.response.map((form) => {
                    if(form.formSource !== 'TypeForm') return form;

                    const getAnswer = (answer) => {
                        if(!answer) return null;
                        if(answer.type === "text") return answer.text;
                        if(answer.type === "boolean") return answer[answer.type] ? intl.formatMessage({ id: 'generic.yes'}) : intl.formatMessage({ id: 'generic.no'});
                        if(answer[answer.type] !== null && answer[answer.type]?.label) return answer[answer.type].label;
                        if(answer[answer.type] !== null) return answer[answer.type];
                    }

                    const treatedForm = {
                        formName: form.formName,
                        formSource: form.formSource,
                        responseDate: form.responseDate,
                        answers: form.rawData.answers.map((answer) => {
                            return({
                                answer: getAnswer(answer),
                                question: answer.field?.Question ? answer.field?.Question : `-`,
                                type: answer.type
                            })
                        })
                    };

                    return treatedForm;
                });

                this.setState({ forms: forms || [], totalItems: data.totalItems, block: false });
            }
            else this.setState({ block: false });
        }, `/api/gms/Profile/v1/profile/forms/${this.props.profileId}?pageIndex=${this.state.pageIndex}&pageSize=${this.state.pageSize}&getSurvey=true`)
    }

    proccessEgoiAnswers = (data) => {
        let answers = [];

        if (data.Answers) {
            //Main questions that are repeated due to having "sub-questions"
            const repeatedQuestions = data.Answers.reduce((array, inquiry) => {
                if (inquiry.SubQuestion && !array.some(el => el === inquiry.Question)) {
                    array.push(inquiry.Question);
                }

                return array;
            }, [])

            const subQuestions = data.Answers.reduce((acc, current) => {
                if (repeatedQuestions.some(el => el === current.Question)) {
                    const index = acc && acc.findIndex(el => el.question === current.Question)

                    if (index === undefined || index === -1) {
                        acc.push({ question: current.Question, subQuestions: [{ subQuestion: current.SubQuestion, answer: current.Answer }] });
                    }
                    else {
                        acc[index].subQuestions.push({ subQuestion: current.SubQuestion, answer: current.Answer });
                    }
                }

                return acc;
            }, [])

            data.Answers.forEach(el => {
                if (!repeatedQuestions.some(rq => rq === el.Question)) {
                    answers.push({ question: el.Question, subQuestion: el.SubQuestion, answer: el.Answer });
                }
            });

            answers.push(subQuestions);
        }

        return answers;
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.getForms());
    }

    selectForm = (selectedFormIdx) => {
        this.setState({ selectedFormIdx });
    }

    render() {
        const { error, block, forms, totalItems, pageIndex, pageSize, selectedFormIdx } = this.state;
        const selectedForm = forms && improvFormObj(forms)[selectedFormIdx] ? improvFormObj(forms)[selectedFormIdx] : null;

        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />
                <div className="m-2 p-2">
                    <Row className="mb-3">
                        <Col>
                            <h5>
                                <i className="fas fa-file-signature mr-2 ml-1" />
                                <FormattedMessage id="ProfileDetails.Forms" />
                            </h5>
                        </Col>
                    </Row>

                    {selectedForm ?
                        <>
                            <Breadcrumb>
                                <BreadcrumbItem>
                                    <div onClick={_ => this.selectForm()}>
                                        <FormattedMessage id="ProfileDetails.Forms" />
                                    </div>
                                </BreadcrumbItem>
                                <BreadcrumbItem active>
                                    <FormattedMessage id="ProfileDetails.FormDetails"/>
                                </BreadcrumbItem>
                            </Breadcrumb>
                            <SelectedProfileForm
                                selectForm={this.selectForm}
                                selectedForm={selectedForm}
                            />
                        </>
                    : forms && forms.length > 0 ?
                        <>
                            <Row>
                                {improvFormObj(forms).map((form, idx) => {
                                    if (form.formSource === 'Egoi') {
                                        const egoiAnswers = form.rawData ? this.proccessEgoiAnswers(form.rawData) : null;

                                        return egoiAnswers && <Col className="col-4 mb-4" key={idx}>
                                            <EgoiFormAnswers answers={egoiAnswers}>
                                                <Row>
                                                    <Col><h5 className="m-0 text-host"> {form.formSource}</h5></Col>
                                                    <Col className="text-right">
                                                        {form.responseDate ? moment(form.responseDate).format("YYYY-MM-DD") : ''}
                                                    </Col>
                                                </Row>

                                                <div className="title-sm mt-1">{form.formName || '-'}</div>

                                                <hr />
                                            </EgoiFormAnswers>
                                        </Col>
                                    }
                                    else if (form.formSource === 'ReviewPro') {
                                        const el = form.rawData ? form.rawData : null;

                                        return el && <Col className="col-4 mb-4" key={idx}>
                                            <FormAnswers answers={el.answers}>
                                                <Row>
                                                    <Col><h5 className="m-0 text-host"> {form.formSource}</h5></Col>
                                                    <Col className="text-right">
                                                        {form.responseDate ? moment(form.responseDate).format("YYYY-MM-DD") : ''}
                                                    </Col>
                                                </Row>

                                                <div className="title-sm mt-1">{form.formName || '-'}</div>

                                                <hr/>

                                                {el.guest.email &&
                                                    <>
                                                        <div className="mb-1 text-muted">Email </div>
                                                        <div className="mb-3"> <b> {el.guest.email}</b> </div>
                                                    </>
                                                }

                                                {el.guest.company &&
                                                    <>
                                                        <div className="mb-1 text-muted"> Company </div>
                                                        <div className="mb-3"><b> {el.guest.company} </b></div>
                                                    </>
                                                }
                                            </FormAnswers>
                                        </Col>
                                    }
                                    else if (form.formSource === 'TypeForm') {
                                        return (
                                            <Col className='col-4 mb-4' key={idx}>
                                                <FormAnswers answers={form.answers} isTypeForm={true}>
                                                    <Row>
                                                        <Col><h5 className="m-0 text-host">{form.formSource}</h5></Col>
                                                        <Col className="text-right">
                                                            {form.responseDate ? moment(form.responseDate).format("YYYY-MM-DD") : ''}
                                                        </Col>
                                                    </Row>
                                                    <div className="title-sm mt-1">{form.formName || '-'}</div>
                                                    <hr/>
                                                </FormAnswers>
                                            </Col>
                                        )
                                    }
                                    else if (form.formSource === 'PNForm') {
                                        return (
                                            <Col key={idx} className='col-4 mb-4'>
                                                <FormAnswers answers={form.answers} form={form.rawForm} isPNForm={true}>
                                                    <>
                                                        <Row>
                                                            <Col>
                                                                <h5 onClick={_ => this.selectForm(idx)} className="m-0 cursor-pointer text-host">{form.formSource} </h5>
                                                            </Col>
                                                            <Col className="text-right">
                                                                {form.responseDate ? moment(form.responseDate).format("YYYY-MM-DD") : ''}
                                                            </Col>
                                                        </Row>

                                                        <div className="title-sm mt-1">{form.formName || '-'}</div>

                                                        <hr className="mt-2" />
                                                    </>
                                                    {/* {form.formTags && form.formTags.length > 0 ?
                                                        <Row className='mt-1 cursor-pointer' style={{ fontSize: '0.8em' }}>
                                                            <Col>
                                                                <div className='d-flex'>
                                                                    <div className='mr-2'>
                                                                        <FormattedMessage id="ProfileDetails.Positive"/>:
                                                                    </div>
                                                                    <div className='d-flex'>
                                                                        {form.formTags?.filter((tag) => tag.sentiment === "Positive").map((tag, idx) => 
                                                                            <div key={idx}>
                                                                                {idx > 0 ?
                                                                                    <span>
                                                                                        , 
                                                                                    </span>
                                                                                :''}
                                                                                <span className='ml-1'>
                                                                                    {tag.formTagName}
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className='mt-1 d-flex'>
                                                                    <div className='mr-2 color-light-red'>
                                                                        <FormattedMessage id="ProfileDetails.Negative"/>:
                                                                    </div>
                                                                    <div className='d-flex'>
                                                                        {form.formTags?.filter((tag) => tag.sentiment === "Negative").map((tag, idx) => 
                                                                            <div key={idx}>
                                                                                {idx > 0 ?
                                                                                    <span>
                                                                                        , 
                                                                                    </span>
                                                                                :''}
                                                                                <span className='ml-1'>
                                                                                    {tag.formTagName}
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    :''} */}
                                                </FormAnswers>
                                            </Col>
                                        )
                                    }
                                })}
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <span className="ml-2">
                                        <TotalResults
                                            end={pageIndex * pageSize + forms.length}
                                            start={pageIndex * pageSize + 1}
                                            total={totalItems}
                                        />
                                    </span>
                                </Col>

                                <Col className="text-right mr-3">
                                    <Pagination
                                        isPrevDisabled={pageIndex === 0}
                                        isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                        currentPage={pageIndex + 1}
                                        handlePrevButton={() => this.handlePagination(-1)}
                                        handleNextButton={() => this.handlePagination(1)}
                                    />
                                </Col>
                            </Row>
                        </>
                    :
                        <Row>
                            <Col className="d-flex flex-column align-items-center">
                                <h6><FormattedMessage id="ProfileDetails.NoFormsAvailable" /></h6>
                            </Col>
                        </Row>
                    } 
                                        
                </div>
            </BlockUi>
        );
    }
}
export default injectIntl(ProfileForms)