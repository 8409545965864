import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Row } from 'reactstrap';
import { StyledCard } from "../Base/CommonUIComponents";
import { FormattedMessage } from 'react-intl';
import GoogleBusinessImg from '../../img/GoogleBusiness.ico';

import { deleteAPI, getAPI, postAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import CustomSelect from "../Base/CustomSelect";
import GoogleBusinessBreadcrumb from './GoogleBusinessBreadcrumb';
import GoogleBusinessCard from './GoogleBusinessCard';
import { Pagination } from '../Base/PaginationComponents';
import QAModal from './QAModal';
import moment from 'moment-timezone';
import { getContextPrompts, googleTextTreatment } from '../Base/ReferenceDataFunctions';

class GoogleBusinessReview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            orderBy: null,
            orderByOptions: ["rating", "rating desc"],
            modal: false,
            selectedReview: null,
            questionReply: '',
            openInput: true,
            reviewList:[],
            pageTokens: [null],
            pageSize: 10,
            pageIndex: 0
        };
    }

    componentDidMount() {
        this.getReviews();
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.selectedLocation !== nextProps.selectedLocation){
            this.setState({
                orderBy: null,
                pageIndex: 0,
                pageTokens: [null],
                error: null,
                block: true
            }, () => this.getReviews());
        }
    }

    getReviews = () => {
        const { accountId, selectedLocation, handleReviewsLength, handleRating } = this.props;
        const { pageTokens, pageSize, pageIndex, orderBy } = this.state;
        
        let urlParams = "";
        if(pageTokens[pageIndex]) urlParams += `&pageToken=${pageTokens[pageIndex]}`;
        if(orderBy) urlParams += `&orderBy=${orderBy}`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    this.setState({ block: false }, () => handleNotification(data));
                }
                else {
                    if(data.response[0]?.nextPageToken) pageTokens[pageIndex + 1] = data.response[0].nextPageToken;

                    const reviewList = data.response[0].reviews?.map((review) => {
                        review.author = review.reviewer;
                        review.author.profilePhotoUri = review.author.profilePhotoUrl;
                        review.text = review.comment;

                        if(review.reviewReply) {
                            review.reviewReply.author = { isAnonymous: true, type: "MERCHANT" };
                            review.reviewReply.text = review.reviewReply.comment;
                            review.topAnswers = [review.reviewReply];
                        };

                        review.answerCount = review.reviewReply ? review.reviewReply.length : 0;

                        return review;
                    });
                    
                    handleReviewsLength(reviewList?.filter(({text, topAnswers}) => text && !topAnswers).length??0);
                    handleRating(data.response[0].averageRating, data.response[0].totalReviewCount)
                    this.setState({
                        reviewList,
                        error: errorMessage,
                        block: false,
                        pageTokens
                    });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Google/v1/reviews/account/${accountId}/locations/${selectedLocation}/list?pageSize=${pageSize}` + urlParams);
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null, pageIndex: 0, block: true
        }, () => this.getReviews());
    }

    handlePagination = (value) => {
        this.setState(prev => ({ pageIndex: prev.pageIndex + value, block: true }), () => this.getReviews());
    }

    toggleDetailModal = (reviewKey) => {
        const { modal, reviewList } = this.state;

        if(modal){
            this.setState({ modal: false });
        }

        const selectedReview = reviewList[reviewKey];
        if(!selectedReview) return;
        
        const openInput = !selectedReview.topAnswers?.find(({author}) => author?.type === "MERCHANT");

        this.setState({ modal: true, selectedReview, openInput, questionReply: '' });
    }

    onChange = (e) => {
        const { value, name } = e?.target;
        this.setState({ [name]: value });
    }

    editReply = () => {
        const { selectedReview } = this.state;
        const currentReply = selectedReview.topAnswers.find(({author}) => author?.type === "MERCHANT");
        if(!currentReply) return;
        this.setState({ questionReply: currentReply.text, openInput: true });
    }
    
    deleteReply = () => {
        const { selectedReview } = this.state;
        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response) {
                if (data.errors && data.errors.length > 0) {
                    this.setState({ block: false }, () => handleNotification(data));
                }
                else {
                    const originalAnswerIndex = selectedReview.topAnswers.findIndex(({author}) => author?.type === "MERCHANT");
                    selectedReview.topAnswers.splice(originalAnswerIndex, 1);
                    this.setState({ selectedReview, error: errorMessage, questionReply: '', block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Google/v1/reviews/locations/delete/${selectedReview.name}`);
    }

    submitReply = () => {
        const { questionReply, selectedReview } = this.state;
        const { hotelName } = this.props;
        
        if(!questionReply) return;

        this.setState({ block: true }, () => {
            const body = {
                comment: questionReply,
                name: selectedReview.name
            };
            
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data && data.response) {
                    if (data.errors && data.errors.length > 0) {
                        this.setState({ block: false }, () => handleNotification(data));
                    }
                    else {
                        if(!selectedReview.topAnswers) selectedReview.topAnswers = [];
                        let answer = data.response[0];
                        const originalAnswer = selectedReview.topAnswers.find(({author}) => author?.type === "MERCHANT");
                        if(originalAnswer){
                            answer = originalAnswer;
                            answer.text = data.response[0]?.comment;
                        }
                        else{
                            answer.text = answer.comment;
    
                            answer.author = {
                                profilePhotoUri: '',
                                isAnonymous: true,
                                displayName: hotelName,
                                updateTime: moment().format('YYYY-MM-DD')
                            };
                            selectedReview.topAnswers.push(answer);
                        }
                        this.setState({ selectedReview, error: errorMessage, questionReply: '', block: false });
                    }
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/Google/v1/reviews/locations/answer`, body);
        });
    }

    getGPTPrompt = () => {
        const { selectedReview } = this.state;
        const slicedQuestion = googleTextTreatment(selectedReview.text); //question.text.indexOf('(Translated by Google)') > -1 ? question.text.slice(0, (question.text.indexOf('(Translated by Google)'))) : question.text;
        
        let prompt = getContextPrompts('reviewerName', selectedReview.author.displayName) + getContextPrompts('reviewScore', selectedReview.starRating) + (slicedQuestion ? getContextPrompts('reviewText', slicedQuestion) : '');

        return prompt;
    }

    render() {
        const { block, error, orderBy, orderByOptions, questionReply, reviewList, pageTokens, pageIndex, selectedReview, modal, openInput } = this.state;
        const { intl, hotelName } = this.props;

        const orderBySelectOptions = orderByOptions.map((value) => {
            return({
                value, label: intl.formatMessage({ id: `GoogleBusinessConfig.${value}` })
            });
        });

        return (
            <StyledCard block={block} error={error}>
                <div className="mb-2 d-flex align-items-center justify-content-end">
                    <div style={{ width: '20%'  }}>
                        <CustomSelect
                            options={orderBySelectOptions}
                            isClearable={true}
                            isSearchable={false}
                            isDisabled={!reviewList || reviewList.length === 0}
                            value={orderBySelectOptions?.find(m => m.value === orderBy)}
                            placeholder={intl.formatMessage({ id: "GoogleBusinessConfig.OrderBy" })}
                            onChange={this.handleSelect.bind(this, 'orderBy')}
                        />
                    </div>
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
                    {reviewList?.map((review, key) =>
                        <GoogleBusinessCard
                            key={key}
                            questionKey={key}
                            profileURI={review.reviewer.profilePhotoUrl}
                            displayName={review.reviewer.displayName}
                            isAnonymous={review.reviewer.isAnonymous}
                            updateTime={review.updateTime}
                            starRating={review.starRating}
                            question={review.comment}
                            toggleDetailModal={this.toggleDetailModal}
                        />
                    )}
                </div>
                {pageTokens.length > 1 ?
                    <Row className="my-4">
                        <Col className="text-right mr-3">
                            <Pagination
                                isPrevDisabled={pageIndex === 0}
                                isNextDisabled={!pageTokens[pageIndex + 1]}
                                currentPage={pageIndex + 1}
                                handlePrevButton={() => this.handlePagination(-1)}
                                handleNextButton={() => this.handlePagination(1)}
                            />
                        </Col>
                    </Row>
                :''}
                <QAModal
                    hotelName={hotelName}
                    toggle={this.toggleDetailModal}
                    open={modal}
                    question={selectedReview}
                    questionReply={questionReply}
                    submitReply={this.submitReply}
                    onChange={this.onChange}
                    block={block}
                    getGPTPrompt={this.getGPTPrompt}
                    openInput={openInput}
                    editReply={this.editReply}
                    deleteReply={this.deleteReply}
                    configType="Review"
                />
            </StyledCard>
        );
    }
}

export default injectIntl(GoogleBusinessReview)