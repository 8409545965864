import React, { Component } from 'react';
import { BlankCard, StyledModal } from '../Base/CommonUIComponents';
import { FormattedMessage } from 'react-intl';
import { getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { FormAnswers, improvFormObj } from '../Marketing/Common/MarketingFunctions';
import profileNow from '../../img/profileNow.png';

class FormReplyModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            survey: null
        };
    }

    
    componentDidMount() {
        const { formId, toggleModal } = this.props;
        
        if(!formId) {
            toggleModal();
            return;
        }

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }

            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                
                const surveys = improvFormObj(data.response);

                this.setState({ survey: surveys && surveys[0], block: false });
                return;
            }

            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/profile/form/${formId}`);
    }

    render() {
        const { toggleModal, modal } = this.props;
        const { block, error, survey } = this.state;

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} className="bigModal" size="xl">
                <BlankCard block={block} error={error}>
                    <div>
                        <h5>
                            <img className='mr-2' alt="" src={profileNow} height="18px"/>
                            <FormattedMessage id="SalesProcess.FormReply"/>
                        </h5>
                    </div>
                    {survey ?
                        <div className='mt-4'>
                            <FormAnswers answers={survey.answers} form={survey.rawForm} isPNForm={true}/>
                        </div>
                    :''}
                </BlankCard>
            </StyledModal>
        );
    }
}

export default FormReplyModal;
