import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { TabPane, TabContent, NavItem, NavLink, Nav, Col, Row, Button, Card, Form } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Line, Doughnut } from 'react-chartjs-2';
import { getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { getColorPallete } from '../Base/ReferenceDataFunctions';
import { ErrorAlert } from '../Base/ErrorAlert';
import { DateRangePicker } from 'react-dates';
import BlockUi from 'react-block-ui';
import moment from "moment";
import { ChartLegend } from '../Base/CustomCharts';



class GoogleBusinessPerformance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            tabs: [
                { id: "Overview", metrics: [1, 2, 3, 4] }, //BUSINESS_IMPRESSIONS_DESKTOP_MAPS, BUSINESS_IMPRESSIONS_DESKTOP_SEARCH, BUSINESS_IMPRESSIONS_MOBILE_MAPS, BUSINESS_IMPRESSIONS_MOBILE_SEARCH
                { id: "Calls", chartData: "callsChart", metrics: [7] }, //CALL_CLICKS
                { id: "Messages", chartData: "messagesChart", metrics: [5] }, //BUSINESS_CONVERSATIONS
                { id: "Bookings", chartData: "bookingsChart", metrics: [9] }, //BUSINESS_BOOKINGS
                { id: "Directions", chartData: "directionsChart", metrics: [6] }, //BUSINESS_DIRECTION_REQUESTS	
                { id: "WebsiteClicks", chartData: "websiteClicksChart", metrics: [8] } //WEBSITE_CLICKS
            ],
            startDate: moment().subtract(6, 'months'),
            endDate: moment(),
            activeTab: "Overview",
            callsChart: { labels: [], datasets: [] },
            messagesChart: { labels: [], datasets: [] },
            bookingsChart: { labels: [], datasets: [] },
            directionsChart: { labels: [], datasets: [] },
            websiteClicksChart: { labels: [], datasets: [] },
            pieChart: { labels: [], datasets: [] }
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.selectedLocation !== this.props.selectedLocation) {
            this.doSearch(); 
        }
    }

    componentDidMount() {
        this.getPerformance(this.state.tabs[0]); 
    }

    getPerformance = (metric) => {
        this.setState({ block: true });

        let params = `?startDate=${moment(this.state.startDate).format("YYYY-MM-DD")}&endDate=${moment(this.state.endDate).format("YYYY-MM-DD")}`;
        metric.metrics.forEach(el => { params += `&metric=${el}` });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    if(data.errors[0].code === "UNVERIFIED_LOCATION"){
                        this.setState({ block: false }, this.props.handleUnverifiedLocation());
                    }
                    else{
                        this.setState({ block: false }, () => handleNotification(data));
                    }
                }
                else {
                    if (metric.id === "Overview") {
                        this.getSearchKeywords(data.response, metric);
                    }
                    else this.calculateData(data.response, metric);
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Google/v1/performance/locations/${this.props.selectedLocation}/list` + params)
    }

    getSearchKeywords = (performanceData, metric) => {
        this.setState({ block: true });

        let params = `?startDate=${moment(this.state.startDate).format("YYYY-MM-DD")}&endDate=${moment(this.state.endDate).format("YYYY-MM-DD")}`;
        metric.metrics.forEach(el => { params += `&metric=${el}` });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if(data.errors && data.errors.length > 0 && data.errors[0].code === "UNVERIFIED_LOCATION"){
                    this.setState({ block: false }, this.props.handleUnverifiedLocation());
                    return;
                }
                else{
                    this.setState({ block: false }, () => handleNotification({}, this.props.intl.formatMessage({ id: "GoogleBusinessConfig.DataSuccess" }), this.props.intl.formatMessage({ id: "GoogleBusinessConfig.Success" })));
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
                return;
            }

            this.calculateOverviewData(performanceData, data);
        }, `/api/gms/Google/v1/Searchkeywords/locations/${this.props.selectedLocation}/list` + params)
    }

    calculateOverviewData = (performanceData, searchKeywords) => {
        const keywords = searchKeywords?.response[0]?.searchKeywordsCounts ?? [];
        const pieChart = {
            labels: [],
            datasets: [
                {
                    label: "Overview",
                    data: [],
                    backgroundColor: getColorPallete(),
                }
            ]
        }

        performanceData && performanceData.forEach(el => {
            el.multiDailyMetricTimeSeries && el.multiDailyMetricTimeSeries.forEach(timeSerie => {
                timeSerie.dailyMetricTimeSeries && timeSerie.dailyMetricTimeSeries.forEach(metric => {

                    //Add metric to chart
                    pieChart.labels.push(this.props.intl.formatMessage({ id: `GoogleBusinessConfig.${metric.dailyMetric}` }));
                    pieChart.datasets[0].data.push(metric.timeSeries?.datedValues.reduce((acc, cur) => acc + cur.value, 0));
                })
            })
        })

        this.setState({ pieChart, searchKeywords: keywords, block: false });
    }

    calculateData = (data, metric) => {
        const formatDate = "MMM YYYY";
        const dates = this.getDates(formatDate);

        const chart = {
            labels: dates,
            datasets: [
                {
                    label: metric.id,
                    data: new Array(dates.length).fill(0),
                    borderColor: getColorPallete()[0],
                    backgroundColor: `${getColorPallete()[0]}33`,
                    fill: true
                }
            ]
        }

        data && data.forEach(el => {
            el.multiDailyMetricTimeSeries && el.multiDailyMetricTimeSeries.forEach(timeSerie => {
                timeSerie.dailyMetricTimeSeries && timeSerie.dailyMetricTimeSeries.forEach(metric => {
                    metric.timeSeries?.datedValues && metric.timeSeries.datedValues.forEach(date => {
                        const dateIndex = dates.findIndex(d => d === moment(`${date.date.year}-${date.date.month}-${date.date.day}`).format(formatDate));

                        if (dateIndex !== -1) {
                            chart.datasets[0].data[dateIndex] += date.value;
                        }
                    })
                })
            })
        })

        this.setState({ [metric.chartData]: chart, block: false });
    }

    getDates = (formatDate) => {
        let dates = [];

        if (this.state.startDate && this.state.endDate) {
            dates.push(moment(this.state.startDate).format(formatDate));
            var currDate = moment(this.state.startDate);

            while (currDate.add(1, 'month').diff(this.state.endDate) < 0) {
                dates.push(moment(currDate).format(formatDate));
            }
        }

        return dates;
    }

    doSearch = (e) => {
        if (e) e.preventDefault();

        const metric = this.state.tabs.find(el => el.id === this.state.activeTab);

        if (metric) {
            this.getPerformance(metric); 
        }
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab.id) {
            this.setState({
                activeTab: tab.id
            }, () => this.getPerformance(tab));
        }
    }

    render() {
        const { block, error, tabs, startDate, endDate, searchKeywords } = this.state;

        const pieOptions = {
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                    position: "right",
                },
                datalabels: {
                    display: false
                }
            }
        }

        const optionsLine = {
            maintainAspectRatio: false,
            scales: {
                x: { display: true },
                y: {
                    beginAtZero: true,
                    grid: {
                        display: false
                    },
                    ticks: {
                        stepSize: 1
                    }
                }
            },
            plugins: {
                legend: {
                    display: false,
                },
                datalabels: {
                    display: false
                }
            }
        }


        return (
            <div>
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <Nav tabs className="border-0">
                        {tabs.map((tab, key) =>
                            <NavItem key={key}>
                                <NavLink className={(this.state.activeTab === tab.id ? 'tab-border-host text-host' : '') + ' border-0 pb-2 pointer'} onClick={() => this.toggleTab(tab)}>
                                    <b><FormattedMessage id={`GoogleBusinessConfig.${tab.id}`} /></b>
                                </NavLink>
                            </NavItem>
                        )}

                        <Form onSubmit={this.doSearch} className="ml-auto" style={{ width: '30%' }}>
                            <Row className="align-items-center h-100 pb-3">
                                <Col className="px-1 text-right">
                                    <DateRangePicker
                                        startDate={startDate}
                                        startDateId="your_unique_start_date_id"
                                        endDate={endDate}
                                        endDateId="your_unique_end_date_id"
                                        isOutsideRange={day => day > moment()}
                                        onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                        focusedInput={this.state.focusedInput}
                                        onFocusChange={focusedInput => this.setState({ focusedInput })}
                                        small={true}
                                        numberOfMonths={1}
                                        showDefaultInputIcon={true}
                                        renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                        required
                                    />
                                </Col>

                                <Col className="text-right col-2 pl-0">
                                    <Button className="btn btn-sm btn-host" type="submit">
                                        <i className="icon-icon-search" />
                                    </Button>
                                </Col>
                            </Row>
                        </Form>

                        <hr className="m-0" style={{ backgroundColor: '#C9DEFE' }} />
                    </Nav>

                    <TabContent activeTab={this.state.activeTab} className="p-0">
                        <TabPane tabId="Overview" className="border-0 my-0 p-0 ">
                            <Row>
                                <Col className="col-6">
                                    <Card body className="border-0 shadow mt-3" style={{ height: '389px' }} >
                                        <Row>
                                            <Col className="col-7">
                                                <h5 className="text-muted pt-2 pl-2">
                                                    <FormattedMessage id="GoogleBusinessConfig.PlataformAndDevices" />
                                                </h5>
                                            </Col>
                                            <Col className="text-muted mr-3 mb-2 text-right">
                                                Total
                                                <h3>{this.state.pieChart.datasets[0]?.data?.reduce((acc, cur) => acc + cur, 0) ?? 0}</h3>
                                            </Col>
                                        </Row>

                                        <Row className="align-items-center h-100">
                                            <Col className="col-6" style={{ height: '70%' }}>
                                                 <Doughnut id="Overview" data={this.state.pieChart} options={pieOptions} />
                                            </Col>
                                            <Col className="col-6">
                                                {this.state.pieChart?.datasets[0]?.data.map((device, key) =>
                                                    <div key={key} className="mb-3">
                                                        <ChartLegend color={getColorPallete()[key]} label={this.state.pieChart?.labels[key]} />
                                                        <span className="pl-5 ml-2" style={{ fontSize: '12px', fontColor: '#666' }}># {device} ({(device * 100 / this.state.pieChart.datasets[0]?.data?.reduce((acc, cur) => acc + cur, 0) ?? 0).toFixed(0)}%)</span>
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>

                                <Col className="col-6">
                                    <Card body className="border-0 mt-3 pb-3 shadow" style={{ height: '389px' }} >
                                        <Row>
                                            <Col>
                                                <h5 className="text-muted pt-2 pl-2">
                                                    <FormattedMessage id="GoogleBusinessConfig.Searches" />
                                                </h5>
                                            </Col>
                                            <Col className="text-muted mr-3 mb-2 text-right">
                                                Total
                                                <h3>{searchKeywords?.reduce((acc, cur) => acc + cur.insightsValue?.value, 0) ?? 0}</h3>
                                            </Col>
                                        </Row>
                                        <div className="px-4 mt-3 verticalScroll" style={{ overflowY: 'auto' }}>
                                            {searchKeywords?.map((keyword, key) =>
                                                <Row key={key} className="py-2 overbglight">
                                                    <Col className="col-1 pr-0">
                                                        {key + 1}.
                                                    </Col>
                                                    <Col>
                                                        {keyword.searchKeyword}
                                                    </Col>
                                                    <Col className="col-2 text-right">
                                                        {keyword.insightsValue?.value ?? '-'}
                                                    </Col>
                                                </Row>
                                            )}
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>

                        {tabs.map((tab, key) => key !== 0 && this.state[tab.chartData] &&
                            <TabPane tabId={tab.id} className="border-0 my-0 p-0 " key={key}>
                                <Card body className="border-0 mt-3 pb-3 shadow" style={{ height: '389px' }} >
                                    <div className="text-muted mr-3 mb-2 text-right">
                                        <FormattedMessage id="generic.Total" /> <FormattedMessage id={`GoogleBusinessConfig.${tab.id}`} /> 
                                        <h3>{this.state[tab.chartData].datasets[0]?.data?.reduce((acc, cur) => acc + cur, 0) ?? 0}</h3>
                                    </div>

                                    <div style={{ height: '80%' }}>
                                        <Line id={tab.id} data={this.state[tab.chartData]} options={optionsLine} />
                                    </div>
                                </Card>
                            </TabPane>
                        )}
                        </TabContent>
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(GoogleBusinessPerformance);