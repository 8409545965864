import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Modal, ModalHeader, ModalBody, Input, CustomInput } from 'reactstrap';
import { handleNotification } from "../Base/Notification";
import { BlankCard, StyledModal } from '../Base/CommonUIComponents';
import CustomSelect from '../Base/CustomSelect';
import { getAPI, putAPI } from "../Base/API";

class WarningDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            warningData: this.props.warning ? this.props.warning : {},
        };
    }

    componentDidMount() {
        this.getBlackListCodes();
    }

    getBlackListCodes = () => {
        this.setState({ blockBLCodes: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockBLCodes: false });
                return;
            }
            if (data) {
                const list = [];

                data.response && data.response.forEach(rd => {
                    const newOption = { value: rd.code, label: rd.code, description: rd.description };
                    const hotelIdx = list.findIndex(el => el.hotelId === rd.hotelId);

                    if (rd.active) {
                        if (hotelIdx !== -1) {
                            list[hotelIdx].options.push(newOption);
                        }
                        else {
                            list.push({ label: rd.hotelName, hotelId: rd.hotelId, options: [newOption] })
                        }
                    }
                })

                this.setState({ blackListCodes: list, blockBLCodes: false });
            }
            else this.setState({ blockBLCodes: false })
        }, `/api/gms/Hotel/v1/ReferenceData?type=BlackList`);
    }

    saveWarning = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var warning = { ...this.state.warningData };

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="WarningDetails.WarningSaved" />, <FormattedMessage id="generic.success" />);
                    this.props.updateTable(data.response[0], 'profileWarnings');
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/profile/warning/${this.props.profileId}`, JSON.stringify({ request: warning }));
    }

    handleChange = (e) => {
        const { name, value, checked } = e.target;

        this.setState({
            warningData: {
                ...this.state.warningData,
                [name]: (name === 'hasWarning' ? checked : value)
            }
        })
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
            warningData: {
                ...this.state.warningData,
                [name]: combo ? combo.value : null
            }
        })
    };

    handleBlackList = (combo) => {
        this.setState({
            warningData: {
                ...this.state.warningData,
                blackListCode: combo ? combo.value : null,
                blackListDescription: combo ? combo.description : null
            }
        })
    };

    render() {
        const { block, error, warningData } = this.state;

        return (
            <StyledModal toggleModal={this.props.toggleModal} modal={this.props.modal} size="lg">
                <BlankCard block={block} error={error}>
                    <Form onSubmit={this.saveWarning}>
                        <Row className="mb-3">
                            <Col>
                                <h5>{this.props.warning ? <FormattedMessage id="WarningDetails.EditWarning" /> : <FormattedMessage id="WarningDetails.AddWarning" />}</h5>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host" type="submit"> <i className="fas fa-save" /></Button>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Label sm={3}> <FormattedMessage id="generic.active" /></Label>
                            <Col sm={3} className="d-flex align-items-center justify-content-start">
                                <CustomInput
                                    type="switch"
                                    id="hasWarning"
                                    name="hasWarning"
                                    onChange={(e) => this.handleChange(e)}
                                    checked={warningData.hasWarning ? warningData.hasWarning : false}
                                />
                            </Col>
                        </FormGroup>
                        {!this.props.hasWarningToMaster && this.props.isMaster && this.props.hotels?.length > 0 ?
                            <FormGroup row>
                                <Label sm={3}> <FormattedMessage id="generic.Hotel" /></Label>
                                <Col sm={9}>
                                    <CustomSelect
                                        id="hotelId"
                                        name="hotelId"
                                        options={this.props.hotels.filter(h => !h.isDisabled)}
                                        value={this.props.hotels.find(opt => opt.value === warningData.hotelId)}
                                        onChange={(e) => this.handleChangeSelect(e, 'hotelId')}
                                        required
                                        isDisabled={warningData.id}
                                        placeholder={""}
                                    />
                                </Col>
                            </FormGroup>
                        : ''}
                        <FormGroup row>
                            <Label sm={3}> <FormattedMessage id="ProfileDetails.WarningText" /> </Label>
                            <Col sm={9}>
                                <Input
                                    type="text"
                                    name="warningText"
                                    onChange={(e) => this.handleChange(e)}
                                    value={warningData.warningText ? warningData.warningText : ''}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row className="mb-0">
                            <Label sm={3}> <FormattedMessage id="WarningDetails.BlackList" /> </Label>
                            <Col sm={9}>
                                <CustomSelect
                                    className="mb-2"
                                    options={this.state.blackListCodes}
                                    onChange={(e) => this.handleBlackList(e)}
                                    placeholder={''}
                                    value={{ value: warningData?.blackListCode, label: warningData?.blackListCode }}
                                    isLoading={this.state.blockBLCodes}
                                />

                                <span className="text-muted ml-3">{warningData?.blackListDescription}</span>
                            </Col>
                        </FormGroup>
                    </Form>
                </BlankCard>
            </StyledModal >
        );
    }
}
export default injectIntl(WarningDetails)