import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Button, FormGroup, Label, Modal, ModalBody, Form, Input, UncontrolledTooltip } from 'reactstrap';
import { getAPI } from "../../../Base/API";
import { getEntityList, getFieldList, getOverlapActionType, getRemoveSource } from '../../../Base/ReferenceDataFunctions';
import CustomSelect from '../../../Base/CustomSelect';
import { BlankCard } from '../../../Base/CommonUIComponents';

class DataRuleActionDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            modal: false,
            selectedAction: {},
            entityList: [], 
            regexList: this.props.regexList
        };
    }

    componentDidMount() {
        const entityList = getEntityList().filter(el => el.value === 'Profile' || el.value === 'ProfileAddress' ||
            el.value === 'ProfileContact' || el.value === 'ProfileDocument')

        const selectedAction = this.props.selectedAction ? this.props.selectedAction : {};

        if (!this.props.selectedAction && this.props.availableActions.length === 1) {
            selectedAction.action = this.props.availableActions[0].value;
        }

        this.setState({ entityList, selectedAction }, () => {
            if (!this.props.regexList) {
                this.getRegEx();
            }
        });
    }

    getRegEx = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const regexList = data.response?.reduce((acc, cur) => {
                    acc.push({ value: cur.id, label: cur.code, desc: cur.description, regex: cur.regularExpression });

                    return acc;
                }, []);

                this.setState({ regexList });
            }
            else this.setState({ error: errorMessage });
        }, '/api/gms/DataQuality/v1/regularExpressionRule?type=Transform');
    }

    filterData(event) {
        event.preventDefault();

        try {
            let ignoreRes = event.target.value;
            let applyRes = "";
            let matchText = false;
            let replaceRes = "";

            const selectedRegex = this.state.regexList.find(el => el.value === this.state.selectedAction.regularExpressionRuleId);

            if (selectedRegex) {
                const { regularExpression, regularExpressionTransform } = selectedRegex;

                if (regularExpressionTransform) {
                    let rule = regularExpression;
                    let changeTo = regularExpressionTransform;
                    var flags = rule.replace(/.*\/([gimy]*)$/, '$1');
                    var pattern = rule.replace(new RegExp('^/(.*?)/' + flags + '$'), '$1');
                    var regex = new RegExp(pattern, flags);

                    if (replaceRes && replaceRes.length > 0) {
                        replaceRes = replaceRes.replace(regex, changeTo);
                    }
                    else {
                        replaceRes = ignoreRes.replace(regex, changeTo);
                    }
                }


                if (regularExpression) {
                    let regexString = regularExpression;

                    var flags = regexString.replace(/.*\/([gimy]*)$/, '$1');
                    var pattern = regexString.replace(new RegExp('^/(.*?)/' + flags + '$'), '$1');
                    var regex = new RegExp(pattern, flags);

                    applyRes = ignoreRes.replace(regex, (match) => `<b className="color-green" id="match">${match}</b>`);

                    let html = new DOMParser().parseFromString(applyRes, "text/html")

                    if (html && html.body && html.body.firstChild && html.body.firstChild.id === "match") {
                        matchText = true;
                    }
                }
            }

            this.setState({
                textSample: event.target.value,
                applyRes: applyRes,
                matchText,
                replaceRes: replaceRes
            });

        }
        catch (e) {
            console.log(e);
        }

    }

    getFields = () => {
        if (this.state.selectedAction.action === 'Split') {
            return getFieldList(this.state.selectedAction?.entity, this.props.intl).filter(el => el.split);
        }
        else {
            return getFieldList(this.state.selectedAction?.entity, this.props.intl).filter(el => el.fieldOptions);
        }
    }

    getValueOptions = () => {
        if (this.state.selectedAction.action === 'Split') {
            return getFieldList(this.state.selectedAction?.entity, this.props.intl)?.find(el => el.value === 'Type')?.fieldOptions;
        }
        else {
            return getFieldList(this.state.selectedAction?.entity, this.props.intl)?.find(el => el.value === this.state.selectedAction?.field)?.fieldOptions;
        }
    }

    handleSelect = (combo, name) => {
        this.setState({
            selectedAction: {
                ...this.state.selectedAction,
                [name]: combo ? combo.value : null
            }
        });
    };

    handleAction = (combo, name) => {
        this.setState({
            selectedAction: {
                ...this.state.selectedAction,
                action: combo ? combo.value : null,
                value: null,
                field: null
            }
        });
    };

    handleEntity = (combo) => {
        this.setState({
            selectedAction: {
                ...this.state.selectedAction,
                entity: combo ? combo.value : null,
                field: null
            }
        });
    }

    render() {
        const { modal, availableActions } = this.props;
        const { selectedAction, entityList, regexList } = this.state;

        const overlapTypes = getOverlapActionType();
        const removeSource = getRemoveSource();


        return (
            <Modal isOpen={modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={() => this.props.toggle()} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard>
                        <Form onSubmit={(e) => this.props.updateActions(e, selectedAction)}>
                            <Row className="mb-4">
                                <Col>
                                    <h5><FormattedMessage id="DataRuleConfig.Action" /> </h5>
                                </Col>
                                <Col className="text-right col-2">
                                    <Button className="btn-sm btn-host" type="submit">
                                        <i className="fas fa-plus" />
                                    </Button>
                                </Col>
                            </Row>
                            <FormGroup row>
                                <Label sm={2}> <FormattedMessage id="DataRuleConfig.Action" /></Label>
                                <Col sm={10} >
                                    <CustomSelect
                                        options={availableActions}
                                        required
                                        isSearchable
                                        placeholder={""}
                                        onChange={(combo) => this.handleAction(combo)}
                                        value={availableActions?.find(el => el.value === selectedAction?.action) || ''}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2}> <FormattedMessage id="RuleDashboard.Entity" /></Label>
                                <Col sm={4} >
                                    <CustomSelect
                                        options={entityList}
                                        required
                                        isSearchable
                                        placeholder={""}
                                        onChange={(combo) => this.handleEntity(combo, 'entity')}
                                        value={entityList?.find(el => el.value === selectedAction?.entity) || ''}
                                    />
                                </Col>
                                <Label sm={2}> <FormattedMessage id="RuleDashboard.Field" /></Label>
                                <Col sm={4} >
                                    <CustomSelect
                                        options={this.getFields()}
                                        required
                                        isSearchable
                                        placeholder={""}
                                        isDisabled={!selectedAction.entity}
                                        onChange={(combo) => this.handleSelect(combo, 'field')}
                                        value={getFieldList(selectedAction?.entity, this.props.intl).find(el => el.value === selectedAction?.field) || ''}
                                    />
                                </Col>
                            </FormGroup>

                            {selectedAction.action === 'Split' && selectedAction?.entity === 'Profile' ? '' :
                                <FormGroup row>
                                    <Label sm={2}> {selectedAction.action === 'Split' ? <FormattedMessage id="ReferenceDataFunctions.Type" /> : <FormattedMessage id="DataRuleConfig.Value" />} </Label>
                                    <Col sm={10} >
                                        <CustomSelect
                                            options={this.getValueOptions()}
                                            required
                                            isSearchable isClearable
                                            placeholder={""}
                                            onChange={(combo) => this.handleSelect(combo, 'value')}
                                            isDisabled={selectedAction.action === 'Split' ? !selectedAction.entity : !selectedAction.field}
                                            value={this.getValueOptions()?.find(el => el.value === selectedAction?.value) || ''}
                                        />
                                    </Col>
                                </FormGroup>
                            }

                            {selectedAction.action === 'Split' ?
                                <>
                                    <b className="mt-3"> <FormattedMessage id="DataRuleConfig.ConfigAction" /> </b>
                                    <hr/>
                                    <FormGroup row>
                                        <Label sm={2}> <FormattedMessage id="DataRuleConfig.OverlapAction" /> </Label>
                                        <Col sm={4} >
                                            <CustomSelect
                                                options={overlapTypes}
                                                required
                                                isSearchable
                                                placeholder={""}
                                                onChange={(combo) => this.handleSelect(combo, 'overlapAction')}
                                                value={overlapTypes.find(el => el.value === selectedAction?.overlapAction) || ''}
                                            />
                                        </Col>
                                        <Label sm={2}> <FormattedMessage id="DataRuleConfig.SourceField" /> </Label>
                                        <Col sm={4} >
                                            <CustomSelect
                                                options={removeSource}
                                                required
                                                isSearchable
                                                placeholder={""}
                                                onChange={(combo) => this.handleSelect(combo, 'removeSource')}
                                                value={removeSource.find(el => el.value === selectedAction?.removeSource) || ''}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={2}> Regex </Label>
                                        <Col sm={10} >
                                            <CustomSelect
                                                options={regexList}
                                                required
                                                isSearchable
                                                placeholder={""}
                                                onChange={(combo) => this.handleSelect(combo, 'regularExpressionRuleId')}
                                                value={regexList?.find(el => el.value === selectedAction?.regularExpressionRuleId) || ''}
                                            />
                                        </Col>
                                    </FormGroup>
                                </>
                                : ''}

                            <div>
                                <b> <FormattedMessage id="RuleDetail.RuleValidation" /></b>
                                    <hr/>
                                <FormGroup row>
                                    <Col className="col-2"><Label> <FormattedMessage id="RuleDetail.FilledSample" /></Label></Col>
                                    <Col><Input type="textarea" rows="2" onChange={(e) => this.filterData(e)}  > </Input> </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col className="col-2"><Label> <FormattedMessage id="RuleDetail.RuleDetection" /></Label></Col>
                                    <Col><div dangerouslySetInnerHTML={{ __html: this.state.applyRes }}></div></Col>
                                    <Col className="col-1 text-right">
                                        {this.state.matchText ?
                                            <>
                                                <i className="color-green fas fa-check-circle" id="check-circle" />

                                                <UncontrolledTooltip placement="left" target="check-circle" >
                                                    <FormattedMessage id="RuleDetail.ValidText" />
                                                </UncontrolledTooltip>
                                            </>
                                            : <>
                                                <i className="color-light-red fas fa-times-circle" id="times-circle"></i>

                                                <UncontrolledTooltip placement="left" target="times-circle" >
                                                    <FormattedMessage id="RuleDetail.RuleNotApplied" />
                                                </UncontrolledTooltip>
                                            </>}
                                    </Col>
                                </FormGroup>
                                <Row className="mb-2">
                                    <Col className="col-2"><Label> <FormattedMessage id="RuleDetail.RuleReplacement" /></Label></Col>
                                    <Col>
                                        {this.state.replaceRes}
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(DataRuleActionDetails)
