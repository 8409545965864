import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Button, Card } from 'reactstrap';
import { DefaultPage, KebabMenu, StyledCard } from "../../../Base/CommonUIComponents";
import ConfirmActionModal from '../../../Base/ConfirmActionModal';
import { handleNotification } from '../../../Base/Notification';
import { deleteAPI, getAPI, postAPI } from '../../../Base/API';
import SenderDetails from './SenderDetails';


export class Senders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            limit: 100,
            verifiedSenders: []
        };
    }

    componentDidMount() {
        this.getVerifiedSenders();
    }

    getVerifiedSenders = (e) => {
        if (e) e.preventDefault();
        
        this.setState({ block: true });
        const { limit, lastSeendId, id } = this.state;

        let params = `?limit=${limit}`;

        if (lastSeendId) params += `&lastSeendId=${lastSeendId}`;
        if (id) params += `&id=${id}`;


        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ verifiedSenders: data.response?.results, block: false });
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SendGrid/v1/sender` + params);
    }

    deleteSender = (e, id) => {
        if (e) e.preventDefault();

        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="SenderDetails.SenderRemoved" />, <FormattedMessage id="generic.success" />);
                }

                this.setState({ deleteModal: false }, () => this.getVerifiedSenders());
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SendGrid/v1/sender/${id}`);
    }

    resendVerification = (senderId) => {
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="SenderDetails.VerificationEmailSent" />, <FormattedMessage id="generic.success" />);
                }

                this.setState({ block: false });
            }
            else this.setState({ block: false });
        }, `/api/gms/SendGrid/v1/sender/resend/${senderId}`);
    }

    getExtraFields = (sender) => {
        var options = [];

        if (!sender.verified) {
            options.push(
                { text: "SenderDetails.ResendVerification", function: _ => this.resendVerification(sender.id), icon: "far fa-paper-plane mr-2" }
            )
        }

        return options;
    }


    handleChangeCombo = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    toggleModal = (modal, sender) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            selectedSender: sender
        }))
    }

    render() {
        const { block, error, verifiedSenders, senderDetails, deleteModal, selectedSender } = this.state;
        const { page } = this.props;


        return (
            <StyledCard block={block} error={error} >
                <Row className="mb-4 py-1">
                    <Col>
                        <h5> <i className={page?.icon} /> {page?.name} </h5>
                    </Col>
                    <Col className="text-right">
                        <Button className="btn-sm btn-host mr-1" onClick={() => this.toggleModal("senderDetails")}>
                            <i className="fas fa-plus" />
                        </Button>
                    </Col>
                </Row>

                {verifiedSenders && verifiedSenders.length > 0 ?
                    <>
                        <Row className=" text-muted align-items-center mb-2 mt-3 px-2">
                            <Col sm={4}> <FormattedMessage id="SenderDetails.Senders/ReplyTo"/></Col>
                            <Col sm={2}> <FormattedMessage id="SenderDetails.Nickname" /> </Col>
                            <Col sm={4}> <FormattedMessage id="SenderDetails.Address" /> </Col>
                            <Col className="col-1 text-center pr-0"> <FormattedMessage id="SenderDetails.Verified" /> </Col>
                            <Col sm={1} />
                        </Row>

                        <div className="pt-1">
                            {verifiedSenders.map((sender, key) =>
                                <Card className="border-0 shadow py-2 px-3 overbglight mb-2" key={key}>
                                    <Row className="my-1">
                                        <Col sm={4}>
                                            <div>{sender.from_email}</div>
                                            <div className="text-muted">{sender.reply_to}</div>
                                        </Col>
                                        <Col sm={2} className="d-flex align-items-center">
                                            {sender.nickname}
                                        </Col>
                                        <Col sm={4} className="d-flex flex-column justify-content-center">
                                            <div>{sender.address}</div>
                                            {sender.city}, {sender.zip} {sender.country}
                                        </Col>
                                        <Col className="col-1 text-center pr-0 d-flex align-items-center justify-content-center">
                                            {sender.verified ?
                                                <span className={"circle-sm color-white bg-green"}>
                                                    <i className="fas fa-sm fa-check mx-1" />
                                                </span>
                                                :
                                                <span className={"circle-sm color-white bg-light-red px-1"}>
                                                    <i className="fas fa-sm fa-times mx-1" />
                                                </span>
                                            }
                                        </Col>
                                        <Col className="col-1 text-right">
                                            <KebabMenu
                                                editFunction={() => this.toggleModal('senderDetails', sender)}
                                                deleteFunction={() => this.toggleModal('deleteModal', sender)}
                                                extraFields={this.getExtraFields(sender)}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            )}
                        </div>
                    </>
                : <DefaultPage text="SendGrid.ThereAreNoVerifiedSenders" icon={page?.icon} />}


                {senderDetails ?
                    <SenderDetails
                        modal={senderDetails}
                        toggleModal={() => this.toggleModal("senderDetails")}
                        selectedSender={this.state.selectedSender}
                        getVerifiedSenders={this.getVerifiedSenders}
                    />
                    : ''}


                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={() => this.toggleModal('deleteModal')}
                        actionFunction={(e) => this.deleteSender(e, selectedSender.id)}
                        text={<FormattedMessage id="SenderDetails.ConfirmDeleteText" values={{ sender: selectedSender.nickname }} />}
                        block={block}
                    />
                    : ''}
            </StyledCard>
        );
    }
}

export default injectIntl(Senders)