import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Card, Col, Row } from 'reactstrap';
import { StyledCard } from "../../../Base/CommonUIComponents";
import { CheckAuthorization } from '../../../Base/Authorization';
import { CustomTable } from '../../../Base/CustomTable';
import TagGroupDetails from './TagGroupDetails';
import { getAPI } from "../../../Base/API";

class TagGroupsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            tagGroupsList: [],
            tagGroup: null,
            modal: false
        };
    }

    componentDidMount() {
        this.getTagGroups();
    }

    getTagGroups() {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ tagGroupsList: data.response });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/tag/group`);
    }

    updateTable = (tagGroup, isToDelete) => {
        this.setState({ block: true });
        var array = [...this.state.tagGroupsList];

        if (isToDelete) {
            array = array.filter(tg => tg.id !== tagGroup.id);
        }
        else {
            const index = array && array.indexOf(array.find(el => el.id === tagGroup.id));

            if (index === -1) {
                array.push(tagGroup);
            }
            else {
                array[index] = tagGroup;
            }
        }

        this.setState({ tagGroupsList: array, block: false });
    }
    
    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            tagGroup: null
        }))
    }

    render() {
        const { block, error, tagGroupsList } = this.state;
        const columns = [
            {
                dataField: "code",
                text: this.props.intl.formatMessage({ id: "TagsList.Code" }),
                sort: true
            },
            {
                dataField: "description",
                text: this.props.intl.formatMessage({ id: "TagsList.Description" }),
                sort: true
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({
                    modal: true,
                    tagGroup: row
                })
            }
        };

        return (

            <StyledCard block={block} error={error} icon={'fas fa-tags'} title={'TagGroupsList.Title'}>
                <Row>
                    <Col className="text-right">
                        <Button className="btn-sm btn-host" onClick={this.toggleModal}>
                            <i className="fas fa-plus" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col className="h-100">
                        <Card className="border-0">
                            <CustomTable
                                data={tagGroupsList ? tagGroupsList : []}
                                selectRow={selectRow}
                                columns={columns}
                                shadow={false}
                                search={true}
                                rowStyle={{ cursor: 'pointer' }}
                            />
                        </Card>
                    </Col>
                </Row>

                {this.state.modal ? 
                    <TagGroupDetails 
                        modal={this.state.modal}
                        toggleModal={this.toggleModal}
                        tagGroup={this.state.tagGroup}
                        updateTable={this.updateTable}
                    />
                : ''}
            </StyledCard>
        );
    }
}
export default injectIntl(TagGroupsList)
