import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Input, Row } from 'reactstrap';
import { getUsersRoles, SalesProcessUsersCombo } from '../SalesProcessFunctions';
import CustomSelect from '../../Base/CustomSelect';

class InitiativeModalStep4 extends Component {
    
    addUser = () => {
        const { initiative, handleProfilesList } = this.props;
        const profiles = initiative.salesInitiativeUsers ? [...initiative.salesInitiativeUsers] : [];

        profiles.push({});

        handleProfilesList(profiles)
    }

    render() {
        const { initiative, handleListElement, intl } = this.props;

        return (
            <div>
                <Row className="mb-3 border-bottom pb-2 mt-4">
                    <Col>
                        <div className={`title-bold required`}>
                            <b>
                                <FormattedMessage id="SalesDeal.Users"/>
                            </b>
                        </div>
                    </Col>
                    <Col className="text-right">
                        <Button className="btn-host btn-sm" onClick={this.addUser}>
                            <i className="fas fa-plus"/>
                        </Button>
                    </Col>
                </Row>

                <Row className="text-muted mb-3">
                    <Col sm={1}></Col>
                    <Col sm={4}><FormattedMessage id="generic.name" /></Col>
                    <Col><FormattedMessage id="SalesDeal.Role" /></Col>
                    <Col><FormattedMessage id="SalesDeal.Notes" /></Col>
                    <Col sm={1}></Col>
                </Row>

                {initiative.salesInitiativeUsers?.map((user, key) =>
                    <Row className="align-items-center overbglight py-2" key={key}>
                        <Col className="text-center col-1">
                            <i className="fas fa-user"/>
                        </Col>
                        <Col sm={4}>
                            <SalesProcessUsersCombo
                                placeholder=""
                                value={user.userId}
                                onChangeFunc={(name, combo) => handleListElement(key, 'userId', combo ? combo.value : null)}
                                isClearable
                                isSearchable
                                required
                            />
                        </Col>

                        <Col>
                        <CustomSelect
                            placeholder=""
                            options={getUsersRoles(intl)}
                            value={getUsersRoles(intl).find(t => t.value === user.role) || ''}
                            onChange={(combo) => handleListElement(key, 'role', combo ? combo.value : null)}
                            isClearable
                            isSearchable
                            required
                        />
                        </Col>
                        <Col>
                            <Input
                                type="text"
                                name="notes"
                                placeholder=""
                                value={user.notes || ''}
                                onChange={(event) => handleListElement(key, 'notes', event.target?.value)}
                                maxLength={500}
                            />
                        </Col>
                        <Col sm={1} className="text-right pr-4">
                            <i className="fas fa-trash-alt text-danger pointer" onClick={ _ => handleListElement(key, null, null, true)} />
                        </Col>
                    </Row>
                )}
            </div>
        );
    }
}

export default injectIntl(InitiativeModalStep4);
