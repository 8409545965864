import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import Authorization, { CheckAuthorization } from '../Base/Authorization';
import WarningDetails from './WarningDetails';
import { ProfileHistory } from '../Base/HistoryComponents';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import { ActiveInactiveStatusBadge, DefaultPage } from '../Base/CommonUIComponents';

export class ProfileWarnings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            modal: false,
            warningModal: false,
            canCreate: false,
            deleteModal: false
        }
    }

    componentDidMount() {
        this.canCreateWarning(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.profile !== nextProps.profile) {
            this.canCreateWarning(nextProps);
        }
    }

    canCreateWarning = (props) => {
        let canCreate = false;
        let hotelList = this.props.hotels ? JSON.parse(JSON.stringify(this.props.hotels.map(el => el))) : [];

        if (global.toMasterProperties?.some(el => el === 'Warning')) {
            canCreate = !props.profile.profileWarnings || props.profile.profileWarnings.length === 0;
        }
        else {
            if (props.profile.isMaster) {
                hotelList.forEach(hotel => {
                    if (props.profile.profileWarnings?.some(el => el.hotelId === hotel.value)) {
                        hotel.isDisabled = true;
                    }
                    else {
                        canCreate = true
                        hotel.isDisabled = false;
                    }
                })
            }
            else {
                canCreate = !props.profile.profileWarnings || props.profile.profileWarnings.length === 0;
            }
        }

        this.setState({ canCreate, hotels: hotelList });
    }

    toggleModal = (obj) => {
        this.setState(prevState => ({
            warningModal: !prevState.warningModal,
            selectedWarning: obj
        }))
    }

    toggleDeleteModal = (selectedWarning) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
            selectedWarning
        }));
    }

    removeWarning = () => {
        this.props.removeElement(this.props.profile.id, this.state.selectedWarning.id, 'warning', 'profileWarnings');

        this.toggleDeleteModal();
    }

    render() {
        const { profile, updateTable, canEditProfile } = this.props;
        const { deleteModal } = this.state;

        const hasWarningToMaster = global.toMasterProperties?.some(el => el === 'Warning');

        return (
            <div className="m-2 p-2">
                <Row className="mb-2">
                    <Col>
                        <h5><i className="icon-icon-warnings mr-1"> </i><FormattedMessage id="ProfileDetails.Warnings" /></h5>
                    </Col>
                    <Col className="text-right">
                        <ProfileHistory
                            component={"warnings"}
                            profileId={this.props.profileId}
                        />

                        <Authorization
                            perform="profileWarnings:add"
                            yes={() => (canEditProfile && this.state.canCreate &&
                                <Button className="btn-sm btn-host ml-2" onClick={() => this.toggleModal(null)}>
                                    <i className="fas fa-plus" />
                                </Button>
                            )}
                            no={() => <div></div>}
                        />
                    </Col>
                </Row>

                {profile.profileWarnings?.map((warn, i) => {
                    const hotel = this.state.hotels?.find(el => el.value === warn.hotelId);

                    return <Card className="border-0 mb-2 shadow p-2" key={i} body>
                        {!hasWarningToMaster ?
                            <>
                                <Row>
                                    <Col>
                                        <i className="icon-icon-hotel" /> {hotel?.label || warn.hotelId}
                                    </Col>
                                    <Authorization
                                        perform="profileWarnings:delete"
                                        yes={() => canEditProfile &&  (
                                            <Col className="col-1 text-right">
                                                <span onClick={() => this.toggleDeleteModal(warn)} style={{ cursor: 'pointer' }}>
                                                    <i className="fas fa-trash-alt" />
                                                </span>
                                            </Col>
                                        )}
                                        no={() => ''}
                                    />
                                </Row>
                                <hr className="mt-1" />
                            </>
                            : ''}

                        <Row className="mb-0">
                            <Col style={{ cursor: 'pointer' }} onClick={() => { if (canEditProfile && CheckAuthorization("profileWarnings:add", null)) this.toggleModal(warn) }}>
                                <Row>
                                    <Col >
                                        <i className="fas fa-exclamation-circle text-warning mr-1"></i> {warn.warningText}
                                    </Col>
                                </Row>
                                {warn.blackListCode ?
                                    <Row className="my-1">
                                        <Col >
                                            <i className="fas fa-skull-crossbones text-black mr-2"></i>
                                            {/*<FormattedMessage id="ProfileDetails.UserIsOnBlackList" />*/}
                                            <b> {warn.blackListCode}</b> - {warn.blackListDescription}
                                        </Col>
                                    </Row>
                                : ''}
                            </Col>
                            <Col className="col-2 text-right">
                                <ActiveInactiveStatusBadge status={warn.hasWarning} />

                                {hasWarningToMaster &&
                                    <Authorization
                                        perform="profileWarnings:delete"
                                        yes={() => (
                                            <span onClick={() => this.toggleDeleteModal(warn)} style={{ cursor: 'pointer' }} className="ml-4">
                                                <i className="fas fa-trash-alt" />
                                            </span>
                                        )}
                                        no={() => ''}
                                    />
                                }
                            </Col>
                        </Row>
                    </Card>
                })}

                {profile.profileWarnings == null || profile.profileWarnings.length == 0 ?
                    <DefaultPage text="ProfileDetails.NoWarningsAvailable" icon="icon-icon-warnings" />
                : ''}

                {this.state.warningModal ?
                    <WarningDetails
                        modal={this.state.warningModal}
                        toggleModal={() => this.toggleModal(null)}
                        warning={this.state.selectedWarning}
                        profileId={this.props.profileId}
                        updateTable={updateTable}
                        hotels={this.state.hotels}
                        isMaster={profile.isMaster}
                        hasWarningToMaster={hasWarningToMaster}
                    />
                    : ''}


                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={this.toggleDeleteModal}
                        actionFunction={this.removeWarning}
                        text={<FormattedMessage id="WarningDetails.ConfirmDeleteText" values={{ warning: this.state.selectedWarning.warningText }} />}
                    />
                : ''}
            </div>
        );
    }
}

export default injectIntl(ProfileWarnings);
