import React, { Component } from 'react'

export default class PreviewMobile extends Component {

    constructor (props) {
        super(props);
        this.preview = React.createRef();
        this.state = {
        }
    };

    render() {
        const { compiledHtml } = this.props;
        return (
            <div
                style={{
                    width: "25%",
                    border: "1px solid rgb(95, 95, 95)",
                    borderRadius: "10px",
                    padding: "0 0.5rem"
                }}>
                <div style={{ height: "3px", width: "10%", backgroundColor: "gray", borderRadius: "20px", margin: "0.4rem auto" }}/>
                <iframe title="PreviewMobileFrame" style={{ borderRadius: '5px' }} ref={this.preview} srcDoc={compiledHtml} width="100%" height="96%" frameborder="0"></iframe>
            </div>
        )
    }
}
