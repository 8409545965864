import React, { Component } from 'react';
import { Modal, ModalBody, Row, Col, Button, Input, Form } from 'reactstrap';
import { postAPI, getAPI } from '../Base/API';
import { StyledCard, BlankCard } from '../Base/CommonUIComponents';
import { CustomTable } from '../Base/CustomTable';
import { handleNotification } from "../Base/Notification";
import Authorization, { CheckAuthorization } from '../Base/Authorization';
import { getLicenseModules } from "../Base/ReferenceDataFunctions";
import CustomSelect from '../Base/CustomSelect';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment-timezone';

export class HotelGroup extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            hotelList: [],
            selectedHotel: {},
            pageSize: 1000,
            pageIndex: 0,
            totalItems: 0,
            hotelGroupOption: []
        }
    }

    componentDidMount() {
        this.getHotels();
    }

    getHotels = () => {
        this.setState({ block: true });
        const { pageIndex, pageSize, filteredHotel } = this.state;

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                const hotelList = data.response?.map((hotel) => {
                    hotel.hotelGroupConnectionLength = !hotel.hotelGroupConnection ? 0 : hotel.hotelGroupConnection.length;
                    
                    return hotel;
                });

                const hotelGroupOption = hotelList?.map(({ hotelGroupId, name }) => {
                    return({
                        value: hotelGroupId,
                        label: name
                    });
                });

                this.setState({ hotelList, block: false, totalItems: data.totalItems, hotelGroupOption });
            }
        }, `/api/gms/Hotel/v1/hotelgroup/list?pageSize=${pageSize}&pageIndex=${pageIndex}${filteredHotel ? `&hotelGroupId=${filteredHotel}` : ''}`);
    }

    saveHotelGroup = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const req = {
            request: {
                name: this.state.selectedHotel.name,
                hotelGroupId: this.state.selectedHotel.hotelGroupId,
                licensedProfiles: this.state.selectedHotel.licensedProfiles
            }
        }

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="HotelGroup.HotelGroupSaved" />, <FormattedMessage id="generic.success" />);
                this.setState({ modal : false }, () => this.getHotels())
            }
        }, '/api/gms/Hotel/v1/hotelGroup', JSON.stringify(req));
    }

    validateModules = () => {
        this.setState({ block: true });

        const modules = { ...this.state.selectedHotel.hotelGroupLicense[0] }

        if (modules?.modules?.filter((value) => value === "StripoOwnAccount" || value === "StripoSharedAccount").length > 1) {
            const data = { errors: [{ code: '', message: <FormattedMessage id="HotelGroup.CantHaveStripoOwnAccountAndStripoSharedAccount" /> }] };

            handleNotification(data);
            this.setState({ block: false });
            return;
        };

        if (modules?.modules?.filter((value) => value === "Contact" || value === "TimeShare").length > 1) {
            const data = { errors: [{ code: '', message: <FormattedMessage id="HotelGroup.CantHaveSTimeShareAndContact" /> }] };

            handleNotification(data);
            this.setState({ block: false });
            return;
        };

        if (modules?.modules?.filter((value) => value === "Proprietary" || value === "Member").length > 1) {
            const data = { errors: [{ code: '', message: <FormattedMessage id="HotelGroup.CantHaveProprietaryAndMember" /> }] };

            handleNotification(data);
            this.setState({ block: false });
            return;
        };

        if (modules?.modules?.filter((value) => value === "Egoi" || value === "SendGrid").length > 1) {
            const data = { errors: [{ code: '', message: <FormattedMessage id="HotelGroup.CantHaveEgoiAndSendGrid" /> }] };

            handleNotification(data);
            this.setState({ block: false });
            return;
        };

        this.saveLicense(modules);
    }

    saveLicense = (modules) => {
        modules.hotelGroupId = this.state.selectedHotel.hotelGroupId;
                
        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="HotelGroup.HotelGroupLicenseSaved" />, <FormattedMessage id="generic.success" />);
                this.setState({ modal: false }, () => this.getHotels());
            }
        }, '/api/gms/Hotel/v1/License', JSON.stringify({ request: modules }));
    }

    toggleModal = (hotelGroup) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedHotel: hotelGroup
        }));
    }

    updateForm(f, evt) {
        let name = evt.target.name;
        let value = evt.target.value;

        this.setState(prevState => ({
            ...prevState,
            [f]: {
                ...prevState[f],
                [name]: value

            },
        }));
    }     

    handleModules = (combo) => {
        let license = this.state.selectedHotel.hotelGroupLicense ? this.state.selectedHotel.hotelGroupLicense[0] : { modules: [] };

        if (Array.isArray(combo)) {
            license.modules = combo.map(el => el.value);
        } else {
            license.modules = combo ? combo.value : [];
        }

        this.setState({
            selectedHotel: {
                ...this.state.selectedHotel,
                hotelGroupLicense: [license]
            }
        })
    }

    changePageSize = (evt) => {
        const { value } = evt.target;

        if (this.state.pageSize !== parseInt(value)) {
            this.setState({
                block: true,
                pageSize: parseInt(value)
            }, () => this.getHotels());
        }
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.getHotels());
    }
    
    handleSelect = (combo, name) => {
        this.setState({ [name]: combo.value }, () => this.getHotels());
    }

    render() {
        const { block, error, hotelList, modal } = this.state;
        const licenseModules = getLicenseModules(this.props.intl);

        const columns = [
            {
                dataField: 'hotelGroupId',
                text: <FormattedMessage id="HotelGroup.HotelGroupId" />,
                sort: true
            },
            {
                dataField: 'name',
                text: <FormattedMessage id="HotelGroup.Name" />,
                sort: true
            },
            {
                dataField: 'hotelGroupLicense',
                text: <FormattedMessage id="HotelGroup.Modules" />,
                formatter: (cell, row) => cell && cell[0].modules ?
                    cell[0].modules.map((m, k) => {
                        const module = licenseModules.flatMap(el => el.options).find(lm => lm.value === m);
                        return module ? module.label : ''
                    }).join(', ')
                    : '',
                sort: true,
                headerStyle: () => {
                    return { width: "30%" };
                }
            },
            {
                dataField: 'masterUserTotal',
                text: <FormattedMessage id="HotelGroup.Masters" />,
                sort: true
            },
            {
                dataField: 'nonMasterUserTotal',
                text: <FormattedMessage id="HotelGroup.NonMaster" />,
                sort: true
            },
            {
                dataField: 'licensedProfiles',
                text: <FormattedMessage id="HotelGroup.LicensedProfiles" />,
                sort: true
            },
            {
                dataField: 'hotelGroupConnectionLength',
                text: <FormattedMessage id="HotelGroup.hotelGroupConnection" />,
                sort: true
            },
            {
                dataField: 'masterCreationLastRun',
                text: <FormattedMessage id="HotelGroup.lastMasterRun" />,
                sort: true,
                formatter: (cell, row) => cell ?
                    <span>{moment(cell).format('DD-MM-YYYY')}</span>
                : 'not'
                ,
            }
        ]
        
        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ modal : true, selectedHotel : row })
            }
        };

        return (
            <StyledCard icon={'icon-icon-hotel'} title={'HotelGroup.HotelGroup'} error={error} block={block}>
                <Modal isOpen={modal} fade={false} className="modal-xl" >
                    <Button onClick={this.toggleModal} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    <ModalBody>
                        <BlankCard block={block} error={error}>
                            <Form onSubmit={this.saveHotelGroup}>
                                <Row className="mb-3">
                                    <Col>
                                        <div>
                                            <h5 className="pt-2"><FormattedMessage id="HotelGroup.HotelGroup" /></h5>
                                        </div>
                                        {this.state.selectedHotel ?
                                            <Row className='mt-4'>
                                                <Col>
                                                    <FormattedMessage id="HotelGroup.lastMasterRun"/>:
                                                    <span className='ml-2'>
                                                        <b>{moment(this.state.selectedHotel.masterCreationLastRun).format('DD-MM-YYYY')}</b>
                                                    </span>
                                                </Col>
                                            </Row>
                                        :''}
                                    </Col>
                                    <Col className="text-right">
                                        <Button className="btn-sm btn-host" type="submit"> <i className="fas fa-save" /></Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <FormattedMessage id="HotelGroup.Name"/>
                                        </div>
                                        <div>
                                            <Input
                                                type="text"
                                                minLength="3" maxLength="100"
                                                placeholder={this.props.intl.formatMessage({ id: "HotelGroup.Name" })}
                                                value={this.state.selectedHotel && this.state.selectedHotel.name ? this.state.selectedHotel.name : ''}
                                                onChange={this.updateForm.bind(this, 'selectedHotel')}
                                                name="name" required
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div>
                                            <FormattedMessage id="HotelGroup.HotelGroupId"/>
                                        </div>
                                        <div>
                                            <Input
                                                type="text"
                                                pattern="^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$"
                                                name="hotelGroupId"
                                                value={this.state.selectedHotel && this.state.selectedHotel.hotelGroupId ? this.state.selectedHotel.hotelGroupId : ''}
                                                disabled={true}
                                                placeholder={this.props.intl.formatMessage({ id: "HotelGroup.HotelGroupId" })}
                                                required
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <div>
                                            <FormattedMessage id="HotelGroup.hotelGroupConnection"/>
                                        </div>
                                        <div>
                                            <Input
                                                type="number"
                                                name="hotelGroupConnectionLength"
                                                value={this.state.selectedHotel ? this.state.selectedHotel.hotelGroupConnectionLength : ''}
                                                disabled={true}
                                                placeholder={this.props.intl.formatMessage({ id: "HotelGroup.hotelGroupConnection" })}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div>
                                            <FormattedMessage id="HotelGroup.LicensedProfiles"/>
                                        </div>
                                        <div>
                                            <Input
                                                type="number"
                                                name="licensedProfiles"
                                                value={this.state.selectedHotel ? this.state.selectedHotel.licensedProfiles : ''}
                                                disabled={CheckAuthorization("hotelGroupDetails:licensedProfiles:edit", null) === false}
                                                placeholder={this.props.intl.formatMessage({ id: "HotelGroup.LicensedProfiles" })}
                                                onChange={this.updateForm.bind(this, 'selectedHotel')}
                                                required
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <div>
                                            <FormattedMessage id="HotelGroup.Masters"/>
                                        </div>
                                        <div>
                                            <Input
                                                type="number"
                                                name="masterUserTotal"
                                                value={this.state.selectedHotel ? this.state.selectedHotel.masterUserTotal : ''}
                                                disabled={true}
                                                placeholder={this.props.intl.formatMessage({ id: "HotelGroup.Masters" })}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div>
                                            <FormattedMessage id="HotelGroup.NonMaster"/>
                                        </div>
                                        <div>
                                            <Input
                                                type="number"
                                                name="nonMasterUserTotal"
                                                value={this.state.selectedHotel ? this.state.selectedHotel.nonMasterUserTotal : ''}
                                                disabled={true}
                                                placeholder={this.props.intl.formatMessage({ id: "HotelGroup.NonMaster" })}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                   
                            </Form>

                            <Authorization
                                perform="hotelGroupDetails:saveModules"
                                yes={() => ( this.state.selectedHotel ?
                                    <div>
                                        <Row className="mt-5 mb-3">
                                            <Col>
                                                <h5><FormattedMessage id="HotelGroup.License" /></h5>
                                            </Col>
                                            <Col className="text-right">
                                                <Button className="btn-sm btn-host" onClick={this.validateModules}> <i className="fas fa-save" /></Button>
                                            </Col>
                                        </Row>

                                        <div className="mt-3 mb-1"> <FormattedMessage id="HotelGroup.Modules" /></div>
                                        <CustomSelect
                                            options={licenseModules}
                                            onChange={this.handleModules.bind(this)}
                                            value={this.state.selectedHotel.hotelGroupLicense ? licenseModules.flatMap(el => el.options).filter(opt => this.state.selectedHotel.hotelGroupLicense[0].modules.find(m => opt.value === m) !== undefined) : ''}
                                            placeholder={<FormattedMessage id="HotelGroup.Modules" />}
                                            isClearable isMulti
                                        />
                                    </div>
                                :'' )}
                                no={() => <div></div>}
                            />
                        </BlankCard>
                    </ModalBody>
                </Modal>

                <Row className="mb-2">
                    <Col className="text-right">
                        <Button className="btn-sm btn-host" onClick={() => this.toggleModal(null)}><i className="fas fa-plus"></i></Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CustomTable
                            keyField={'hotelGroupId'}
                            data={hotelList || []}
                            columns={columns}
                            shadow={false}
                            exportCSV={false}
                            showTotal={true}
                            remote={false}
                            selectRow={selectRow}
                            hasPagination={true}
                            search={{ searchFormatted: true }}
                            hideSizePerPage={false}
                        />
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}
export default injectIntl(HotelGroup);
