import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { injectIntl } from 'react-intl';
import { Button, Card, Col, Row, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { handleNotification } from "../Base/Notification";
import { StyledCard } from "../Base/CommonUIComponents";
import { getAPI, deleteAPI } from "../Base/API";
import { FormattedMessage } from "react-intl";

import Authorization from '../Base/Authorization';
import ConfirmActionModal from '../Base/ConfirmActionModal';

export class CampaignList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            deleteModal:false,
            campaignlist: [],
            limit: 10,
            offset: 0,
            totalItems: 0
        };
    }

    componentDidMount() {
        this.getCampaign();
    }

    getCampaign = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({
                    campaignlist: data.items ? data.items : [],
                    totalItems: data.total_items ? data.total_items : 0
                });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/campaigns?limit=${this.state.limit}&offset=${this.state.offset}`);
    }

    deleteCampaign = (e, campaignId) => {
        e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="CampaignList.CampaignRemoved" />, <FormattedMessage id="generic.success" />);
                    this.toggleModal();

                    this.setState({
                        offset: 0
                    }, () => this.getCampaign());
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/campaigns/${campaignId}`);
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            offset: this.state.offset + value
        }, () => this.getCampaign());
    }

    toggleModal = (campaignToDelete) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
            campaignToDelete
        }));
    }

    render() {
        const { block, error, campaignlist, totalItems, offset, limit, deleteModal, campaignToDelete } = this.state;

        return (

            <StyledCard block={block} error={error} >
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/MarketingConfigEgoi"> Config</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Campaign</BreadcrumbItem>
                    </Breadcrumb>
                </div>
               
                {campaignlist && campaignlist.map((el, k) =>
                    <Card className="border-0 mb-2 p-3" key={k}>
                        <Row>
                            <Col>
                                <h4><b>{el.title}</b></h4>
                                <div><b><FormattedMessage id="CampaignList.Channel" />: </b>{el.channel}</div>
                                <div><b><FormattedMessage id="CampaignList.Schedule" />: </b>{el.schedule_date.enabled ? '' : <FormattedMessage id="CampaignList.NoSchedule" />}</div>
                                <div><b><FormattedMessage id="CampaignList.List" />: </b>{el.list_id}</div>
                                <div><b><FormattedMessage id="CampaignList.Group" />: </b>{el.group_id}</div>
                            </Col>
                            <Col>
                                <h4>&nbsp;</h4>
                                <div><b><FormattedMessage id="CampaignList.Created" />:</b> {el.created}</div>
                                <div><b><FormattedMessage id="CampaignList.Updated" />:</b> {el.updated}</div>
                                {el.start_date && el.start_date !== '0000-00-00 00:00:00' ? <div><b><FormattedMessage id="CampaignList.Start" />:  </b> {el.start_date}</div> : ''}
                                {el.end_date && el.end_date !== '0000-00-00 00:00:00' ? <div><b><FormattedMessage id="CampaignList.End" />:    </b> {el.end_date}</div> : ''}
                            </Col>
                            <Col>
                                <img src={el.thumbnail}></img>
                            </Col>

                            <Authorization
                                perform="campaignList:delete"
                                yes={() => (
                                    <Col className="col-1 text-right">
                                        <Button className="btn btn-sm btn-host" onClick={() => this.toggleModal({ campaign_hash: el.campaign_hash, title: el.title })}>
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                    </Col>
                                )}
                                no={() => <div></div>}
                            />
                        </Row>    
                    </Card>
                )}

                {totalItems > 0 && totalItems >= limit ?
                    <Row className="mt-3 px-3">
                        <Col>
                            <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.handlePagination(-limit)} disabled={offset === 0}>
                                <i className="fas fa-angle-left" />
                            </Button>
                        </Col>
                        <Col className="text-right">
                            <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.handlePagination(limit)} disabled={totalItems <= offset + limit}>
                                <i className="fas fa-angle-right" />
                            </Button>
                        </Col>
                    </Row>
                    : ''}


                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={this.toggleModal}
                        actionFunction={(e) => this.deleteCampaign(e, campaignToDelete.campaign_hash)}
                        text={<FormattedMessage id="CampaignList.ConfirmDeleteText" values={{ campaignName: campaignToDelete.title }} />}
                        block={block}
                    />
                : ''}
            </StyledCard>
        );
    }
}
export default injectIntl(CampaignList)
