import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Col, Row } from 'reactstrap';
import { getAPI } from "../Base/API";
import { StyledCard } from "../Base/CommonUIComponents";
import { CustomTable } from '../Base/CustomTable';
import Authorization, { CheckAuthorization } from '../Base/Authorization';

export class HotelList extends Component {

    constructor(props) {
        super(props);
        this.getHotels = this.getHotels.bind(this);
        this.state = {
            block: true,
            hotelList: [],
            hotel: null
        };
    }

    componentDidMount() {
        if (CheckAuthorization("hotelList:viewAll", null)) {
            this.getHotels('/api/gms/Hotel/v1/hotel/list')
        }
        else {
            this.getHotels('/api/gms/Hotel/v1')
        }
    }

    getHotels(url) {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ hotelList: data.response });
            }
            this.setState({ error: errorMessage, block: false });
        }, url);
    }

   

    render() {
        const { block, error, hotelList } = this.state;
        const columns = [
            {
                dataField: "hotelId",
                text: this.props.intl.formatMessage({ id: "HotelList.hotelId" }),
                sort: true
            },
            {
                dataField: "hotelGroupName",
                text: this.props.intl.formatMessage({ id: "HotelList.HotelGroup" }),
                sort: true
            },
            {
                dataField: "name2",
                text: this.props.intl.formatMessage({ id: "HotelList.name2" }),
                sort: true
            },
            {
                dataField: "name1",
                text: this.props.intl.formatMessage({
                    id: "HotelList.name1"
                }),
                sort: true
            },
            {
                dataField: "city",
                text: this.props.intl.formatMessage({
                    id: "HotelList.city"
                }),
                sort: true
            },
            {
                dataField: "phone",
                text: this.props.intl.formatMessage({
                    id: "HotelList.phone"
                }),
                sort: true
            },
            {
                dataField: "email",
                text: this.props.intl.formatMessage({
                    id: "HotelList.email"
                }),
                sort: true
            }
        ];
        const selectRow = {
            mode: "radio",
            hideSelectColumn: true,
            bgColor: "#f8f9fa",
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.props.history.push("/HotelDetail/" + row.hotelId);
            }
        };

        return (

            <StyledCard block={block} error={error} >
                <Row className="mb-2">
                    <Col>
                        <h5>
                            <i className="icon-icon-hotel mr-2"></i> <FormattedMessage id="HotelList.title"/>
                        </h5>
                    </Col>
                    <Col className="text-right">
                        <Authorization
                            perform="hotelList:add"
                            yes={() => (
                                <Link to="/HotelDetail">
                                    <Button className="btn btn-sm btn-host">
                                        <i className="fas fa-plus"></i>
                                    </Button>
                                </Link>
                            )}
                            no={() => <div></div>}
                        />

                    </Col>
                </Row>
                <Row>
                    <Col className="h-100">
                        <CustomTable
                            keyField={'hotelId'}
                            data={hotelList ? hotelList : []}
                            columns={columns}
                            shadow={false}
                            selectRow={selectRow}
                            remote={false}
                            hasPagination={true}
                            search={{ searchFormatted: true }}
                            hideSizePerPage={false}
                            showTotal={true}
                        />
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}
export default injectIntl(HotelList)
