import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Col, Row, Card } from 'reactstrap';
import { deleteAPI } from "../../Base/API";
import { handleNotification } from "../../Base/Notification";
import { KebabMenu } from "../../Base/CommonUIComponents";
import moment from "moment";
import ConfigModal from './ConfigModal';
import { Pagination, TotalResults } from '../../Base/PaginationComponents';
import { getFormTypes } from '../../Base/ReferenceDataFunctions';
import CreateRunModal from './CreateRunModal';


export class SummaryConfig extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            createRunModal: false,
            selectedConfig: null
        };
    }

    componentDidMount() {
        this.props.getSummaryConfig();
    }

    updateTable = (config, isToDelete) => {
        var array = this.props.reviewConfigs ? [...this.props.reviewConfigs] : [];

        const index = array && array.indexOf(array.find(el => el.id === config.id));

        if (index === -1) {
            array.push(config);
        }
        else {
            if (isToDelete) {
                array.splice(index, 1);
            }
            else {
                array[index] = config;
            }
        }

        this.setState({ modal: false }, () => this.props.updateReviewConfigs(array));
    }

    toggleModal = (config) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedConfig: config
        }))
    }

    deleteConfig = (e, config) => {
        e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="SummaryConfig.ConfigDeleted" />, <FormattedMessage id="generic.success" />);
                    this.updateTable(config, true);
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Review/v1/reviewSummaryConfig/${config.id}`);
    }

    togglecreateRunModal = (selectedConfig) => {
        this.setState(prevState => ({
            createRunModal: !prevState.createRunModal,
            selectedConfig
        }))
    }

    render() {
        const { modal, createRunModal, selectedConfig } = this.state;
        const { reviewConfigs, pageSize, pageIndex, totalItems, intl, createConfigRuns } = this.props;

        return (
            <>
                {createRunModal ?
                    <CreateRunModal
                        createConfigRuns={createConfigRuns}
                        toggleModal={this.togglecreateRunModal}
                        configId={selectedConfig}
                    />
                :''}

                {modal ?
                    <ConfigModal
                        modal={modal}
                        toggleModal={() => this.toggleModal(null)}
                        updatePromptConfigList={this.props.updatePromptConfigList}
                        selectedConfig={this.state.selectedConfig}
                        updateTable={this.updateTable}
                        promptConfigList={this.props.promptConfigList}
                        deleteConfig={this.deleteConfig}
                    />
                : ''}

                <Row>
                    <Col>
                        <h5 className="m-0">
                            <i className="icon-icon-reviews fa-sm mr-2"/>
                            <FormattedMessage id="NavMenu.SummaryConfig" />
                        </h5>
                    </Col>
                    <Col className="text-right mb-3">
                        <Button className="btn-sm btn-host mr-2" onClick={() => this.toggleModal(null)}>
                            <i className="fas fa-plus" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    {reviewConfigs?.map((config, key) =>
                        <Col className="col-6 mb-3" key={key}>
                            <Card className="border-0 shadow pt-0 h-100" body>
                                <Row className="border-bottom py-2">
                                    <Col>
                                        <div className='d-flex align-items-center'>
                                            <div className='mr-2'>
                                                <b>{config.reportType || '-'}</b>
                                            </div>
                                            <div>{config.channel}</div>
                                        </div>
                                        <div className="text-muted" style={{ fontSize: '0.8em' }}>
                                            {config.updatedAt ? moment(config.updatedAt).format("YYYY-MM-DD HH:mm") : ''}
                                        </div>
                                    </Col>
                                    <Col className='d-flex align-items-center justify-content-end'>
                                        <KebabMenu
                                            editFunction={_ => this.toggleModal(config)}
                                            deleteFunction={e => this.deleteConfig(e, config)}
                                            extraFields={[{ text: "SummaryConfig.Run", function: _ => this.togglecreateRunModal(config.id), icon: "fas fa-play mr-2" }]}
                                        />
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col className='col-6'>
                                        <div style={{ fontSize: '0.8em' }} className='text-muted'>
                                            <FormattedMessage id="SummaryConfig.Prompt"/>
                                        </div>
                                        <div>
                                            <b>{config?.promptConfig?.name||"-"}</b>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div style={{ fontSize: '0.8em' }} className='text-muted'>
                                            <FormattedMessage id="SummaryConfig.FormType"/>
                                        </div>
                                        <div>
                                            <b>{getFormTypes(intl, config.channel === 'Inquiry')?.find(({value}) => value === config.formType)?.label|| config?.formType ||"-"}</b>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    )}
                </Row>

                {totalItems > pageSize ?
                    <Row className="mt-2">
                        <Col>
                            <span className="ml-2">
                                <TotalResults
                                    end={pageIndex * pageSize + reviewConfigs.length}
                                    start={pageIndex * pageSize + 1}
                                    total={totalItems}
                                />
                            </span>
                        </Col>

                        <Col className="text-right mr-3">
                            <Pagination
                                isPrevDisabled={pageIndex === 0}
                                isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                currentPage={pageIndex + 1}
                                handlePrevButton={() => this.props.handlePagination(-1)}
                                handleNextButton={() => this.props.handlePagination(1)}
                            />
                        </Col>
                    </Row>
                :''}
            </>
        );
    }
}
export default injectIntl(SummaryConfig);