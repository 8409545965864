import React, { Component } from 'react';
import { Button, Col, Input, Row, Form } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { cardStatus, getChannels, SelectHotelByHotelGroup, StyledCard } from '../Base/CommonUIComponents';
import CustomSelect from '../Base/CustomSelect';
import { getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { CustomTable } from '../Base/CustomTable';


class SearchCards extends Component {

    constructor(props) {
        super(props);
        this.voucherIdForm = React.createRef();
        this.state = {
            block: true,
            blockConfig: true,
            vouchers: [],
            pageIndex: 1,
            pageSize: 10,
            totalItems: 0,
            voucherId: null,
            selectedHotel: null,
            cardTypes: [],
            cardType: null,
            selectedHotel: null,
            status: null,
            channel: null
        };
    }

    componentDidMount() {
        this.getVoucherTypes();

        this.updateStateFilters();
    }
    
    getVoucherTypes = () => {
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockConfig: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ error: errorMessage, blockConfig: false }));
                }
                else {
                    const cardTypes = data.response.map(config => {
                        const option = {
                            value: config.id,
                            label: config.code
                        };
                        return option;
                    });

                    this.setState({ error: errorMessage, blockConfig: false, cardTypes });
                }
            }
            else {
                this.setState(({ error: errorMessage, blockConfig: false }));
            }
        }, `/api/Voucher/V1/Config`);
    }

    getVouchers = (updateUrlParams) => {
        this.setState({ block: true });

        var params = this.updateAndGetUrlParams(updateUrlParams)

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false, totalItems: 0 });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response) {
                    this.setState({ error: errorMessage, block: false, vouchers: data.response, totalItems: data.count });
                }
                else {
                    this.setState({ error: errorMessage, block: false, totalItems: 0 });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false, totalItems: 0 });
            }
        }, `/api/Voucher/Voucher/v1/listvouchers` + params);
    }


    updateStateFilters = () => {
        let params = new URLSearchParams(window.location.search);

        if (!params.has('page')) params.append('page', 1);
        if (!params.has('pageSize')) params.append('pageSize', 10);

        this.props.history.push({ search: `?${params}` });
        

        let pageSize = params.get('pageSize');
        let pageIndex = params.get('page') - 1;
        let voucherId = params.get('voucherId');
        let cardType = params.get('cardType');
        let selectedHotel = params.get('selectedHotel');
        let status = params.get('status');
        let channel = params.get('channel');

        this.setState({ pageSize, pageIndex, voucherId, cardType, selectedHotel, status, channel },
            () => this.getVouchers(false));
    }

    updateAndGetUrlParams = (updateUrlParams) => {
        const { pageSize, pageIndex, voucherId, cardType, selectedHotel, status, channel } = this.state;


        if (updateUrlParams) {
            let url = `?page=${pageIndex + 1}&pageSize=${pageSize}`;

            if (voucherId) url += `&voucherId=${voucherId}`;
            if (cardType) url += `&cardType=${cardType}`;
            if (selectedHotel) url += `&selectedHotel=${selectedHotel}`;
            if (status) url += `&status=${status}`;
            if (channel) url += `&channel=${channel}`;

            this.props.history.push({ search: url });
        }

        var params = `?PageSize=${pageSize}&PageIndex=${pageIndex}`;

        if (voucherId) params += `&voucherId=${voucherId}`;
        if (cardType) params += `&type=${cardType}`;
        if (selectedHotel) params += `&hotelId=${selectedHotel}`;
        if (status) params += `&status=${status}`;
        if (channel) params += `&channel=${channel}`;

        return params;
    }

    setComboStatus = (name, combo) => {
        this.setState({ [name]: combo ? combo.value : null });
    }

    handleTableChange = (_, { sizePerPage, page }) => {
        this.setState({
            pageIndex: page -1,
            pageSize: sizePerPage
        }, () => this.getVouchers(true));
    }

    handleInput = (evt) => {
        if (evt && evt.target) {
            const { name, value } = evt.target;
            this.setState({ [name]: value });
        }
    }

    search = (e) => {
        e.preventDefault();

        this.setState({
            pageIndex: 0
        }, () => this.getVouchers(true));
    }

    render() {
        const { block, blockConfig, error, voucherId, cardTypes, cardType, channel, pageIndex, pageSize, selectedHotel, status, totalItems, vouchers } = this.state;

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.props.history.push({ pathname: `/VoucherDetails/${row.id}` })
            }
        };

        const columns = [
            {
                dataField: 'voucherId',
                text: this.props.intl.formatMessage({ id: "SearchCards.CardId" }),
                sort: true
            },
            {
                dataField: 'hotelName',
                text: this.props.intl.formatMessage({ id: "SearchCards.SoldAt" }),
                sort: true
            },
            {
                dataField: 'saleOrigin',
                text: this.props.intl.formatMessage({ id: "SearchCards.Channel" }),
                sort: true,
                formatter: (cell) => {
                    if (cell) {
                        const channel = getChannels().find(s => s.value === cell);
                        return channel ? channel.label : '';
                    }
                }
            },
            {
                dataField: 'ordinal',
                text: this.props.intl.formatMessage({ id: "SearchCards.Number" }),
                sort: true
            },
            {
                dataField: 'voucherTypeCode',
                text: this.props.intl.formatMessage({ id: "SearchCards.CardType" }),
                sort: true
            },
            {
                dataField: 'createdAt',
                text: this.props.intl.formatMessage({ id: "SearchCards.IssueDate" }),
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : null
            },
            {
                dataField: 'status',
                text: this.props.intl.formatMessage({ id: "SearchCards.Status" }),
                sort: true,
                formatter: (cell) => {
                    if (cell) {
                        const status = cardStatus.find(s => s.value === cell);
                        return status ? status.label : '';
                    }
                }
            },
            {
                dataField: 'validUntil',
                text: this.props.intl.formatMessage({ id: "SearchCards.ValidUntil" }),
                sort: true
            }
        ];

        return (
            <StyledCard block={block} error={error}>
                <Row className="mb-2">
                    <Col>
                        <h5 className="m-0">
                            <i className="fas fa-search mr-2"></i>
                            <FormattedMessage id="NavMenu.SearchCards" />
                        </h5>
                    </Col>
                </Row>
                <Form onSubmit={this.search}>
                    <Row className="mb-2">
                        <Col className="col-2">
                            <form ref={this.voucherIdForm}>
                                <FormattedMessage id="SearchCards.CardId" >
                                    {
                                        placeholder => <Input type="text" placeholder={placeholder} name="voucherId" value={voucherId ? voucherId : ''} onChange={this.handleInput} />
                                    }
                                </FormattedMessage>
                            </form>
                        </Col>
                        <Col className="col-2">
                            <CustomSelect
                                options={cardTypes}
                                value={cardTypes.find(crd => crd.value === cardType)}
                                onChange={this.setComboStatus.bind(this, 'cardType')}
                                placeholder={<FormattedMessage id="SearchCards.CardType" />}
                                isSearchable
                                isClearable
                                isLoading={blockConfig}
                            />
                        </Col>
                        <Col className="col-2">
                            <SelectHotelByHotelGroup onChangeFunc={this.setComboStatus} value={selectedHotel} name="selectedHotel" placeholder={<FormattedMessage id="generic.Hotel" />} />
                        </Col>
                        <Col className="col-2">
                            <CustomSelect
                                options={cardStatus}
                                value={cardStatus.find(stt => stt.value === status)}
                                onChange={this.setComboStatus.bind(this, 'status')}
                                placeholder={<FormattedMessage id="SearchCards.Status" />}
                                isSearchable
                                isClearable
                            />
                        </Col>
                        <Col className="col-2">
                            <CustomSelect
                                options={getChannels()}
                                value={getChannels().find(chl => chl.value === channel)}
                                onChange={this.setComboStatus.bind(this, 'channel')}
                                placeholder={<FormattedMessage id="SearchCards.Channel" />}
                                isSearchable
                                isClearable
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className="btn-host btn-sm mr-2" type="submit">
                                <i className="fas fa-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <CustomTable
                    data={vouchers}
                    columns={columns}
                    page={pageIndex + 1}
                    sizePerPage={pageSize}
                    totalSize={totalItems}
                    remote={true}
                    onTableChange={this.handleTableChange}
                    showTotal={true}
                    selectRow={selectRow}
                    hideSizePerPage={false}
                />
            </StyledCard>
        );
    }
}
export default injectIntl(SearchCards)