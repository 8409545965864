import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Modal, ModalBody, Input, CustomInput } from 'reactstrap';
import { getConsentMethods, getConsentTypeList } from '../Base/ReferenceDataFunctions';
import { handleNotification } from "../Base/Notification";
import { BlankCard } from '../Base/CommonUIComponents';
import CustomSelect from "../Base/CustomSelect";
import { SingleDatePicker } from "react-dates";
import { putAPI } from "../Base/API";
import moment from 'moment';

class ConsentDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            consentData: this.props.consent ? this.props.consent : {},
        };
    }

    handleChange = (e) => {
        const { name, value, checked } = e.target;

        this.setState({
            consentData: {
                ...this.state.consentData,
                [name]: (name === 'active' ? checked : value)
            }
        })
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
            consentData: {
                ...this.state.consentData,
                [name]: combo ? combo.value : null
            }
        })
    };

    saveConsent = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var consent = { ...this.state.consentData };

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="ConsentDetails.ConsentSaved" />, <FormattedMessage id="generic.success" />);
                    this.props.updateTable(data.response[0], 'consents');
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/profile/consent/${this.props.profileId}`, JSON.stringify({ request: consent }));
    }

    render() {
        const { block, error, consentData } = this.state;
        const { availableConsents } = this.props;

        return (
            <Modal isOpen={this.props.modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={this.props.toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <Form onSubmit={this.saveConsent}>
                            <Row className="mb-3">
                                <Col>
                                    <h5>{this.props.consent ? <FormattedMessage id="ConsentDetails.EditConsent" /> : <FormattedMessage id="ConsentDetails.AddConsent" />}</h5>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" type="submit"> <i className="fas fa-save" /></Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-7">
                                    <FormGroup row>
                                        <Label sm={3}> <FormattedMessage id="ProfileDetails.Active" /></Label>
                                        <Col sm={9} className="d-flex align-items-center justify-content-start">
                                            <CustomInput
                                                type="switch"
                                                id="1"
                                                name="active"
                                                onChange={(e) => this.handleChange(e)}
                                                checked={consentData.active ? consentData.active : false}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-7">
                                    <FormGroup row>
                                        <Label sm={3}> <FormattedMessage id="ProfileDetails.Type" /></Label>
                                        <Col sm={9}>
                                            <CustomSelect
                                                options={availableConsents}
                                                required
                                                isClearable
                                                isSearchable
                                                placeholder={""}
                                                onChange={(e) => this.handleChangeSelect(e, "type")}
                                                value={availableConsents.find(c => c.value === consentData.type)}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={5}> <FormattedMessage id="ProfileDetails.ConsentBegin" /></Label>
                                        <Col sm={7} className="text-right">
                                            <SingleDatePicker
                                                id="ConsentBegin"
                                                isOutsideRange={day => day <= moment().subtract(1, 'day')}
                                                date={consentData.consentBegin ? moment(consentData.consentBegin) : null}
                                                focused={this.state.focused}
                                                onFocusChange={({ focused }) => this.setState({ focused })}
                                                small={true}
                                                numberOfMonths={1}
                                                onDateChange={date => this.setState({ consentData: { ...this.state.consentData, consentBegin: date } })}
                                                showDefaultInputIcon={true}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-7">
                                    <FormGroup row>
                                        <Label sm={3}> <FormattedMessage id="ProfileDetails.Method" /></Label>
                                        <Col sm={9}>
                                            <CustomSelect
                                                options={getConsentMethods()}
                                                onChange={(e) => this.handleChangeSelect(e, "method")}
                                                value={getConsentMethods().find(mtd => mtd.value === consentData.method)}
                                                isClearable
                                                isSearchable
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={5}> <FormattedMessage id="ProfileDetails.ConsentEnd" /></Label>
                                        <Col sm={7} className="text-right">
                                            <SingleDatePicker
                                                id="ConsentEnd"
                                                isOutsideRange={day => day <= moment()}
                                                date={consentData.consentEnd ? moment(consentData.consentEnd) : null}
                                                focused={this.state.focused2}
                                                onFocusChange={({ focused }) => this.setState({ focused2: focused })}
                                                small={true}
                                                numberOfMonths={1}
                                                onDateChange={date => this.setState({ consentData: { ...this.state.consentData, consentEnd: date } })}
                                                showDefaultInputIcon={true}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(ConsentDetails)