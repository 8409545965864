import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import classNames from 'classnames';
import { getConvertedDateToHotelTimezone } from '../../Base/ReferenceDataFunctions';

class TimelineElement extends Component {

    render() {
        const {
            id,
            children,
            iconStyle,
            position,
            style,
            className,
            event,
            selectedEventId,
            selectEvent,
            states
        } = this.props;

        function getEventIcon(result, eventType) {

            let badgeColor;
            if (result === states[0].value) {
                badgeColor = 'badge-secondary';
            }
            else if (result === states[1].value) {
                badgeColor = 'badge-danger';
            }
            else {
                badgeColor = 'badge-warning';
            }

            let icon;
            if (eventType === 'IssueCardNumber') {
                icon = 'icon-icon-member-card';
            }
            else if (eventType === 'Profile') {
                icon = 'fa-user';
            }
            else {
                //default icon
                icon = 'fa-user';
            }

            return (
                <span className={`badge ${badgeColor}`} style={{ width: '28px' }}>
                    <i className={`fas fa-2x ${icon}`} />
                </span>
            );
        }

        return (
            <div
                id={id}
                className={classNames(className, 'timeline-element', { 'timeline-element--left': position === 'left', 'timeline-element--right': position === 'right', 'timeline-element--no-children': children === '' })}
                style={style}
            >
                <div>
                    <span style={iconStyle} className="timeline-element-icon">
                        {getEventIcon(event.result, event.eventType)}
                    </span>
                    <div className="timeline-element-content">
                        <div className="mb-0 mt-0">
                            <Row>
                                <Col>
                                    <Row style={{minHeight: '10px'}}>
                                        <Col>
                                            <span className="timeline-element-date">{getConvertedDateToHotelTimezone(event.createdAt).format("YYYY-MM-DD HH:mm:ss")}</span>
                                        </Col>
                                    </Row>
                                    <Row style={{minHeight: '10px'}}>
                                        <Col>
                                            {children}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="col-3">
                                    <Button className={'float-right btn-host shadow btn-sm mt-1 ' + (event.id === selectedEventId ? 'active' : '')} onClick={_ => selectEvent(event)}>
                                        <i className="fas fa-info-circle" />
                                    </Button>
                                </Col>
                            </Row>
                            <hr className="mb-0 mt-1" />
                        </div>
                    </div>
                </div>
            </div>                
        );
    }
}

TimelineElement.propTypes = {
    id: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    className: PropTypes.string,
    icon: PropTypes.element,
    iconStyle: PropTypes.shape({}),
    iconOnClick: PropTypes.func,
    style: PropTypes.shape({}),
    date: PropTypes.node,
    position: PropTypes.string,
    lastSelected: PropTypes.number
};

TimelineElement.defaultProps = {
    id: '',
    children: '',
    className: '',
    icon: null,
    iconStyle: null,
    style: null,
    date: '',
    position: '',
    lastSelected: '',
    iconOnClick: null
};

export default TimelineElement;