import React, { Component } from 'react';
import { Row, Col, Button, Form } from 'reactstrap';
import { DefaultPage, StyledCard } from '../../../Base/CommonUIComponents';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI } from '../../../Base/API';
import { CustomTable } from '../../../Base/CustomTable';
import CustomSelect from '../../../Base/CustomSelect';
import ConsentModal from './ConsentModal';
import { getAllConsentTypeList } from '../../../Base/ReferenceDataFunctions';


class ConsentsConfig extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            hotelGroupList: []
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.getHotelGroupList();
    }

    getHotelGroupList = () => {
        this.setState({ blockGroup: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockGroup: false });
                return;
            }
            if (data) {
                var hotelGroupList = [{ value: "00000000-0000-0000-0000-000000000000", label: this.props.intl.formatMessage({ id: "generic.All" }) }];

                if (data.response && data.response.length > 0) {
                    data.response.map((item) =>
                        hotelGroupList.push({ value: item.hotelGroupId, label: item.name })
                    )
                }

                this.setState({ hotelGroupList, error: errorMessage, blockGroup: false }, this.getConsents);
            }
            else this.setState({ error: errorMessage, blockGroup: false }, this.getConsents);
        }, '/api/gms/Hotel/v1/hotelgroup/list?pageSize=1000&pageIndex=0');
    }

    getConsents = (e) => {
        if (e) e.preventDefault();
        this.setState({ block: true });

        var param = this.state.hotelGroupId ? `&hotelGroupId=${this.state.hotelGroupId}` : '';

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ consents: data.response, error: errorMessage, block: false  });
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Hotel/v1/consents?onlyFromHotelGroup=false` + param);
    }

    updateTable = (consent, isToRemove) => {
        this.setState({ block: true });
        var array = this.state.consents ? [...this.state.consents] : [];

        if (isToRemove) {
            array = array.filter(c => c.id !== consent.id);
        }
        else {
            const index = array.findIndex(el => el.id === consent.id);

            if (index === -1) {
                array.push(consent);
            }
            else {
                array[index] = consent;
            }
        }

        this.setState({ consents: array, block: false });
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedConsent: null
        }))
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    render() {
        const { block, blockGroup, error, consents, hotelGroupId, modal, hotelGroupList } = this.state;

        const columns = [
            {
                dataField: 'hotelGroupId',
                text: this.props.intl.formatMessage({ id: "generic.HotelGroup" }),
                sort: true,
                formatter: (cell) => {
                    const hotel = hotelGroupList.find(el => el.value === cell);

                    return hotel?.label || cell
                },
                headerStyle: { width: '30%' }
            },
            {
                dataField: 'code',
                text: this.props.intl.formatMessage({ id: "ConsentsConfig.Code" }),
                sort: true
            },
            {
                dataField: 'description',
                text: this.props.intl.formatMessage({ id: "ConsentsConfig.Description" }),
                sort: true
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ modal: true, selectedConsent: row });
            }
        };

        return (
            <StyledCard block={block || blockGroup} error={error} icon={'icon-icon-consents'} title={'NavMenu.Consents'}>
                {modal ?
                    <ConsentModal
                        modal={modal}
                        toggleModal={this.toggleModal}
                        consent={this.state.selectedConsent}
                        hotelGroupList={hotelGroupList}
                        updateTable={this.updateTable}
                        availableConsents={consents ? getAllConsentTypeList().filter(cl => !consents.some(c => c.hotelGroupId === "00000000-0000-0000-0000-000000000000" && c.code === cl.value)) : false}
                    />
                : ''}

                <Form onSubmit={this.getConsents}>
                    <Row className="my-3 align-items-center">
                        <Col className="col-3">
                            <CustomSelect
                                icon="icon-icon-hotel"
                                options={hotelGroupList}
                                isClearable isSearchable
                                value={hotelGroupList.find(el => el.value === hotelGroupId) || ''}
                                placeholder={<FormattedMessage id="generic.HotelGroup" />}
                                onChange={(combo) => this.handleChangeSelect('hotelGroupId', combo)}
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className="btn-sm btn-host mr-2" onClick={this.toggleModal} disabled={consents ? getAllConsentTypeList().every(cl => consents.some(c => c.hotelGroupId === "00000000-0000-0000-0000-000000000000" && c.code === cl.value)) : false}>
                                <i className="fas fa-plus" />
                            </Button>
                            <Button className="btn-sm btn-host" type="submit">
                                <i className="fas fa-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                {consents?.length > 0 ?
                    <CustomTable
                        data={consents}
                        columns={columns}
                        totalSize={consents?.length || 0}
                        search={true}
                        showTotal={true}
                        selectRow={selectRow}
                    />
                    : <DefaultPage text="ConsentsConfig.NoConsentsConfigured" icon="icon-icon-consents" />
                }
            </StyledCard>
        );
    }
}
export default injectIntl(ConsentsConfig)