import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Modal, ModalBody } from 'reactstrap';
import { getResolvedStatus, getRuleTypeList } from '../../Base/ReferenceDataFunctions';
import { handleNotification } from '../../Base/Notification';
import { BlankCard, ProfileIsMasterBadge } from '../../Base/CommonUIComponents';
import { CustomTable } from '../../Base/CustomTable';
import CustomSelect from "../../Base/CustomSelect";
import { putAPI } from '../../Base/API';
import moment from 'moment';

class EditStatus extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            issuesToUpdate: this.props.selectedRows,
            configId: null,
            resolvedSatusOptions: getResolvedStatus(this.props.intl)
        };
    }

    saveResolvedStatus = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const body = {
            request: this.state.issuesToUpdate
        };

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });

                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="DataQualityIssues.StatusSuccessfullySaved" />, <FormattedMessage id="generic.success" />);

                    this.props.getDataQualityIssues();
                    this.props.toggleModal();
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }

        }, `api/gms/DataQuality/v1/DataQualityRunDetail/Resolved`, body);
    }

    handleSelect = (combo, name) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    }

    handleChangeStatus = (combo, index) => {
        if (combo) {
            const issuesToUpdate = [...this.state.issuesToUpdate];

            issuesToUpdate[index].resolved = combo.value;

            this.setState({ issuesToUpdate: [] }, _ => this.setState({ issuesToUpdate: issuesToUpdate }));
        }
    }

    handleAllStatus = (combo) => {
        if (combo) {
            const issuesToUpdate = [...this.state.issuesToUpdate];

            issuesToUpdate.forEach(el => {
                el.resolved = combo.value;
            })

            this.setState({ issuesToUpdate: [] }, _ => this.setState({ issuesToUpdate: issuesToUpdate }));
        }
    }

    render() {
        const { modal, toggleModal } = this.props;
        const { issuesToUpdate, resolvedSatusOptions } = this.state;

        const columns = [
            {
                dataField: "hotelName",
                text: this.props.intl.formatMessage({ id: "DataQualityIssues.Hotel" })
            },
            {
                dataField: "templateName",
                text: this.props.intl.formatMessage({ id: "DataQualityIssues.Template" })
            },
            {
                dataField: "ruleName",
                text: this.props.intl.formatMessage({ id: "DataQualityIssues.Rule" })
            },
            {
                dataField: "ruleType",
                text: this.props.intl.formatMessage({ id: "DataQualityIssues.RuleType" }),
                formatter: (cell) => cell && getRuleTypeList(this.props.intl).some(rt => rt.value === cell) ? getRuleTypeList(this.props.intl).find(rt => rt.value === cell).label : '',
            },
            {
                dataField: "profileId",
                text: this.props.intl.formatMessage({ id: "DataQualityIssues.ProfileId" }),
                hidden: true
            },
            {
                dataField: "profileFirstName",
                text: this.props.intl.formatMessage({ id: "DataQualityIssues.Profile" }),
                formatter: (cell, row) => {
                    return <div>
                        {row.profileFirstName} {row.profileLastName}
                        <span className="float-right pr-3"> <ProfileIsMasterBadge isMaster={row.isMaster} linked={!row.isMaster && row.masterId} /> </span>
                    </div>
                },
                style: { cursor: 'pointer', textDecoration: 'underline' },
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        this.props.history.push({ pathname: "/ProfileDetails/" + row.profileId, rules: [row.ruleId] })
                    },
                }
            },
            {
                dataField: "isMaster",
                text: "Master",
                hidden: true
            },
            {
                dataField: "profileLastUpdateDate",
                text: this.props.intl.formatMessage({ id: "DataQualityIssues.LastUpdate" }),
                formatter: (cell) => `${moment(cell).format('YYYY-MM-DD')}`
            },
            {
                dataField: "value",
                text: this.props.intl.formatMessage({ id: "DataQualityIssues.Value" })
            },
            {
                dataField: "duplicateIds",
                text: this.props.intl.formatMessage({ id: "generic.status" }),
                formatter: (cell, row, rowIndex) => {
                    return <CustomSelect
                        options={resolvedSatusOptions}
                        onChange={evt => this.handleChangeStatus(evt, rowIndex)}
                        value={resolvedSatusOptions.find(t => t.value === row.resolved) || ''}
                        isSearchable
                        style={{ zIndex: 999999 }}
                    />
                },
                sort: true
            }
        ];

        return (
            <Modal isOpen={modal} fade={false} size={"xl"} style={{ minWidth: 0, maxWidth: '90%', width: '90%' }}>
                <Button onClick={toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={this.state.block} error={this.state.error}>
                        <Form onSubmit={this.saveResolvedStatus}>
                            <Row className="mb-3">
                                <Col>
                                    <h5> <FormattedMessage id="DataQualityIssues.EditStatus" /> </h5>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn btn-sm btn-host" type="submit">
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>


                            <Row className="mb-2 align-items-center">
                                <Col><FormattedMessage id="BulkAction.SelectedLines" />:</Col>
                                <Col className="d-flex justify-content-end align-items-center">
                                    <FormattedMessage id="BulkAction.UpdateAll" />:
                                    <CustomSelect
                                        className="w-25 ml-3"
                                        options={resolvedSatusOptions}
                                        onChange={evt => this.handleAllStatus(evt)}
                                        placeholder={<FormattedMessage id="generic.status" />}
                                        isSearchable
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="h-100">
                                    <CustomTable
                                        data={issuesToUpdate}
                                        columns={columns}
                                        shadow={false}
                                        showTotal
                                        sizePerPage={10}
                                        hideSizePerPage={false}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(EditStatus)