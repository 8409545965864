import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Nav, NavItem, Row, NavLink, TabPane, TabContent } from 'reactstrap';
import { getAPI, putAPI } from "../../Base/API";
import { handleNotification } from '../../Base/Notification';
import { ActiveInactiveStatusBadge, StyledCard } from "../../Base/CommonUIComponents";
import { getPMSReferenceDataType, getReferenceDataType } from "../../Base/ReferenceDataFunctions";
import { CustomTable } from '../../Base/CustomTable';
import CustomSelect from "../../Base/CustomSelect";
import moment from 'moment';

export class ReferenceData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            activeTab: 0,
            revenueData: [],
            rateData: [],
            channelData: [],
            roomCategoryData: [],
            cancelReasonData: [],
            segmentsData: [],
            subSegmentsData: [],
            distributionChannelsData: [],
            vipCodes: [],
            referenceDataTypes: getReferenceDataType().concat(getPMSReferenceDataType())
        };
    }

    componentDidMount() {
        this.getHotels();
    }

    getHotels = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });
            }
            if (data) {
                var hotels = [];

                data.response && data.response.forEach(hotel => {
                    hotels.push({ label: hotel.name2, value: hotel.hotelId});
                });

                this.setState({ hotelList: hotels });
            }
            this.setState({ block: false }, (e) => this.getReferenceData(e, "Revenue", 'hotelReferenceData'));
        }, '/api/gms/Hotel/v1/hotel/list');
    }

    getReferenceData = (e, type, apiName) => {
        if(e) e.preventDefault();
        this.setState({ block: true });

        if (!type) {
            type = this.state.referenceDataTypes[this.state.activeTab].value;
            apiName = this.state.referenceDataTypes[this.state.activeTab].endpoint;
        }

        var params = `?type=${type}`;
        if (this.state.hotelId) params += `&hotelId=${this.state.hotelId}`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (type) {
                    this.setState({
                        [`${type.charAt(0).toLowerCase() + type.slice(1)}Data`]: data.response,
                        block: false
                    });
                }
                else {
                    this.setState({ block: false });
                }
            }
            else this.setState({ block: false })
        }, `/api/gms/Hotel/v1/${apiName}` + params);
    }

    refreshData = (e, type, apiName, cleanCache = false) => {
        e.preventDefault();
        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="ReferenceData.DataRefreshed" />, <FormattedMessage id="generic.success" />);

                    const name = `${type.charAt(0).toLowerCase() + type.slice(1)}Data`;

                    this.setState({
                        [name]: data.response,
                        block: false
                    })
                }
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/gms/Hotel/v1/${apiName}?type=${type}&cleanCache=${cleanCache}`);
    }

    handleCombo = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    toggleTab = (val) => {
        this.setState({ activeTab: val })
    }

    render() {
        const { block, error, hotelList, referenceDataTypes } = this.state;

        const columns = [
            {
                dataField: "hotelId",
                text: this.props.intl.formatMessage({ id: "generic.Hotel" }),
                sort: true,
                formatter: (cell, row) => {
                    return row.hotelName ? <span> {row.hotelName} </span> :
                    cell ?
                        <span> {hotelList && hotelList.some(h => h.value === cell) ? hotelList.find(h => h.value === cell).label : cell} </span>
                    : null
                },
                csvFormatter: (cell, row) => {
                    return row.hotelName ? row.hotelName :
                        cell ?
                            hotelList && hotelList.some(h => h.value === cell) ? hotelList.find(h => h.value === cell).label : cell
                        : null
                },
            },
            {
                dataField: "code",
                text: this.props.intl.formatMessage({ id: "ReferenceData.Code" }),
                sort: true
            },
            {
                dataField: "description",
                text: this.props.intl.formatMessage({ id: "ReferenceData.Description" }),
                sort: true
            },
            {
                dataField: "references",
                text: this.props.intl.formatMessage({ id: "ReferenceData.References" }),
                sort: true,
                hidden: getReferenceDataType().length <= this.state.activeTab,
                csvExport: !(getReferenceDataType().length <= this.state.activeTab)
            },
            {
                dataField: "createdAt",
                text: this.props.intl.formatMessage({ id: "ReferenceData.CreatedAt" }),
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:MM") : '',
                csvFormatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:MM") : ''
            },
            {
                dataField: "updatedAt",
                text: this.props.intl.formatMessage({ id: "ReferenceData.UpdatedAt" }),
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:MM") : '',
                csvFormatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:MM") : ''
            },
            {
                dataField: "active",
                text: this.props.intl.formatMessage({ id: "generic.status" }),
                formatter: (cell) => <ActiveInactiveStatusBadge status={cell} />,
                sort: true,
                hidden: getReferenceDataType().length > this.state.activeTab,
                csvExport: !(getReferenceDataType().length > this.state.activeTab),
                csvFormatter: (cell) => cell ? this.props.intl.formatMessage({ id: "generic.active" }) : this.props.intl.formatMessage({ id: "generic.inactive" })
            }
        ];

        return (
            <StyledCard block={block} error={error} icon={'fas fa-database'} title="NavMenu.ReferenceData">
                <Row className="my-4 align-items-center">
                    <Col sm={3}>
                        <CustomSelect
                            icon="icon-icon-hotel"
                            options={hotelList}
                            isClearable isSearchable
                            placeholder={<FormattedMessage id="generic.Hotel" />}
                            onChange={this.handleCombo.bind(this, 'hotelId')}
                        />
                    </Col>
                    <Col className="text-right">
                        <Button className="btn btn-host btn-sm " onClick={(e) => this.getReferenceData(e)}>
                            <i className="icon-icon-search" />
                        </Button>
                    </Col>
                </Row>

                <Nav tabs className="border-0">
                    {referenceDataTypes.map((data, key) =>
                        <Tab
                            key={key}
                            label={data.label}
                            toggleTab={(e) => { this.toggleTab(key); this.getReferenceData(e, data.value, data.endpoint) }}
                            isOpen={this.state.activeTab === key}
                        />
                    )}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    {referenceDataTypes.map((data, key) =>
                        <ReferenceDataTabPane
                            tabId={key}
                            key={key}
                            data={this.state[`${data.value.charAt(0).toLowerCase() + data.value.slice(1)}Data`]}
                            columns={columns}
                            refreshData={(e) => this.refreshData(e, data.value, data.endpoint, true)}
                            showButton={getReferenceDataType().length > key}
                        />
                    )}
                </TabContent>
            </StyledCard>
        );
    }
}
export default injectIntl(ReferenceData)


export const Tab = ({ label, isOpen, toggleTab }) => {
    return (
        <NavItem>
            <NavLink className={(isOpen ? 'tab-border-host text-host' : '') + ' border-0 cursor-pointer'} onClick={toggleTab} >
                <b> {label} </b>
            </NavLink>
        </NavItem>
    )
};

export const ReferenceDataTabPane = ({ data, refreshData, columns, tabId, showButton }) => {
    const renderTableButtons = () => {
        return showButton &&
            <Button className="btn btn-host btn-sm mr-2" onClick={refreshData}>
                <i className="fas fa-sync-alt" />
            </Button>
    }

    return (
        <TabPane tabId={tabId} className="border-0 my-0 p-0 pt-2"  >
            <CustomTable
                data={data || []}
                columns={columns}
                showTotal={true}
                sizePerPage={15}
                search={true}
                hideSizePerPage={false}
                exportCSV={true}
                rightTableButtons={renderTableButtons()}
            />
        </TabPane>
    )
};