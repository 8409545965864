import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Button, Form } from 'reactstrap';
import { StyledModal } from '../../Base/CommonUIComponents';
import { ErrorAlert } from '../../Base/ErrorAlert';
import { SelectCustomType } from '../SalesProcessFunctions';

import BlockUi from 'react-block-ui';


const ChangeAssociationTypeModal = ({ block, error, toggleModal, modal, saveAssociation, selectedAssociationTypeId }) => {
    const [currentAssociationType, setCurrentAssociationType] = useState(selectedAssociationTypeId);

    const handleChangeRelationType = (combo) => {
        setCurrentAssociationType(combo ? combo.value : null);
    }

    return ( 
        <StyledModal toggleModal={toggleModal} modal={modal} size="md" style={{ minWidth: 0, maxWidth: '35%', width: '35%' }}>
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />

                <Form onSubmit={(e) => saveAssociation(e, currentAssociationType)}>
                    <div className='d-flex flex-column'>
                        <div className="my-3 d-flex justify-content-between align-items-center">
                            <div>
                                <h5><FormattedMessage id="SalesProcess.ChangeDealAssociationType" /></h5>
                            </div>
                            <div className='text-right'>
                                <Button className="btn-sm btn-host btn btn-secondary" type="submit">
                                    <i className="fas fa-save" />
                                </Button>
                            </div>
                        </div>

                        <div className="mb-4 mt-2 px-3">
                            <p><FormattedMessage id="SalesProcess.DealAssociationType" /></p>
                            <SelectCustomType 
                                name="DealRelationType"
                                onChangeFunc={(combo) => handleChangeRelationType(combo)}
                                required={true}
                                value={currentAssociationType}
                                placeholder=""
                                isDisabled={false}
                                isClearable={true}
                                type={'DealRelationType'}
                            />
                        </div>
                    </div>
                </Form>
            </BlockUi>
        </StyledModal>
    )
};

export default injectIntl(ChangeAssociationTypeModal);