import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Button, Card, Progress } from 'reactstrap';
import { DefaultPage, KebabMenu, StyledCard, CommonHelper } from "../../../../Base/CommonUIComponents";
import ConfirmActionModal from '../../../../Base/ConfirmActionModal';
import { handleNotification } from '../../../../Base/Notification';
import { deleteAPI, getAPI } from '../../../../Base/API';
import AlertDetails from './AlertDetails';
import Authorization from '../../../../Base/Authorization';


export class Alerts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            alertDetails:false,
            alerts: []
        };
    }

    componentDidMount() {
        this.getAlerts();
    }

    getAlerts = (e) => {
        if (e) e.preventDefault();

        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ alerts: data.response, block: false });
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SendGrid/v1/alerts`);
    }

    deleteAlert = (e, id) => {
        if (e) e.preventDefault();

        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="SendGridAlerts.AlertRemoved" />, <FormattedMessage id="generic.success" />);
                }

                this.setState({ deleteModal: false, block: false }, this.getAlerts);
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SendGrid/v1/alert/${id}`);
    }


    handleChangeCombo = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    toggleModal = (modal, alert) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            selectedAlert: alert
        }))
    }

    render() {
        const { block, error, alerts, alertDetails, deleteModal, selectedAlert } = this.state;
        const { page } = this.props;


        return (
            <StyledCard block={block} error={error} >
                <Row className="mb-4 py-1">
                    <Col className='col-2'>
                        <h5> <i className={page?.icon} /> {page?.name} </h5>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center">
                        <Button className="btn-sm btn-host mr-1" onClick={() => this.toggleModal("alertDetails")}>
                            <i className="fas fa-plus" />
                        </Button>
                        <Authorization
                            perform="admin:view"
                            yes={() => (
                                <CommonHelper help={<FormattedMessage id="SendGridAlerts.Help" />} id={'alertsHelp'}  /> 
                            )}
                            no={() => <div></div>}
                        />
                    </Col>
                </Row>

                {alerts && alerts.length > 0 ?
                    <>
                        <Row className=" text-muted align-items-center mb-2 mt-3">
                            <Col className="col-4 pl-4"> <FormattedMessage id="SendGridAlerts.Type" /></Col>
                            <Col sm={5}> <FormattedMessage id="SendGridAlerts.Recipients" /> </Col>
                            <Col className="col-2 pl-0"> <FormattedMessage id="SendGridAlerts.Send" /> </Col>
                            <Col sm={1}/>
                        </Row>

                        <div className="pt-1">
                            {alerts.map((alert, key) =>
                                <Card className="shadow p-3 overbglight mb-2" key={key}>
                                    <Row className="my-1 align-items-center">
                                        <Col className="col-4 pl-4">
                                            <FormattedMessage id={`SendGridAlerts.${alert.type}`} />
                                        </Col>
                                        <Col sm={5} className="d-flex align-items-center">
                                            {alert.email_to}
                                        </Col>
                                        <Col className="col-2 pl-2">
                                            {alert.frequency ?
                                                <FormattedMessage id={`ReferenceDataFunctions.${alert.frequency.charAt(0).toUpperCase() + alert.frequency.slice(1) }`} />
                                                :
                                                <Progress value={alert.percentage} > {alert.percentage}% </Progress>
                                            }
                                        </Col>
                                        <Col className="col-1 text-right">
                                            <KebabMenu
                                                editFunction={() => this.toggleModal('alertDetails', alert)}
                                                deleteFunction={() => this.toggleModal('deleteModal', alert)}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            )}
                        </div>
                    </>
                    : <DefaultPage text="SendGridAlerts.ThereAreNoAlerts" icon={page?.icon} />}


                {alertDetails ?
                    <AlertDetails
                        modal={alertDetails}
                        toggleModal={() => this.toggleModal("alertDetails")}
                        selectedAlert={selectedAlert}
                        getAlerts={this.getAlerts}
                    />
                : ''}


                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={() => this.toggleModal('deleteModal')}
                        actionFunction={(e) => this.deleteAlert(e, selectedAlert.id)}
                        text={<FormattedMessage id="SendGridAlerts.ConfirmDeleteText" values={{ alert: selectedAlert.nickname }} />}
                        block={block}
                    />
                : ''}
            </StyledCard>
        );
    }
}

export default injectIntl(Alerts)