import React, { Component } from 'react';
import { Row, Col, Input, Button, Card, CustomInput, Modal, ModalBody, Label } from 'reactstrap';
import { getScoreConfigType } from "../../Base/ReferenceDataFunctions";
import { handleNotification } from '../../Base/Notification';
import { StyledCard } from "../../Base/CommonUIComponents";
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from "../../Base/CustomSelect";
import { getAPI, postAPI } from '../../Base/API';


class ProfileScore extends Component {
    ProfileScore = false;
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            block: false,
            error: null,
            scoreConfig: [],
            tags:[],
            totalWeight: 0
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        this.getScoreConfig();
        this.getTags();
    }

    getScoreConfig = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    const scoreConfig = getScoreConfigType();

                    if (data.response && data.response.length > 0) {
                        scoreConfig.forEach(config => {
                            config.config = data.response.find(el => el.elementType === config.value);
                        })
                    }

                    const totalWeight = scoreConfig.reduce((acc, curr) => acc + (curr.config ? curr.config.weight : 0), 0);

                    this.setState({ scoreConfig, totalWeight });
                }
                this.setState({ block: false });
            }
        }, `/api/gms/Hotel/v1/scoreConfig`)
    }

    getTags = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    const list = [];

                    data.response && data.response.forEach(tag => {
                        list.push({ value: tag.id, label: tag.code });
                    })

                    this.setState({ tags: list });
                }
                this.setState({ block: false });
            }
        }, `/api/gms/Profile/v1/tag?tagType=all`)
    }

    saveConfig = () => {
        this.setState({ block: true });

        const body = this.state.scoreConfig.map(el => { if (el.config) return el.config });

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="ProfileScore.ConfigSaved" />, <FormattedMessage id="generic.success" />);
                }

                this.setState({ block: false });
            }
        }, `/api/gms/Hotel/v1/scoreConfig`, JSON.stringify({ request: body }))
    }

    addTag = () => {
        const { scoreConfig, newTag } = this.state;

        if (!scoreConfig[3].config) {
            scoreConfig[3].config = { elementType: 'Tag', maxValue: null };
        }
        if (!scoreConfig[3].config.scoreConfigTags) {
            scoreConfig[3].config.scoreConfigTags = [];
        }

        scoreConfig[3].config.scoreConfigTags.push({ tagId: newTag.value, code: newTag.label});

        this.setState({ scoreConfig, newTag: null });
        this.toggleModal();
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }))
    }

    handleRangeInput = (e, key) => {
        const scoreConfig = [...this.state.scoreConfig];
        const { value } = e.target;
        let totalWeight = 0;


        if (!scoreConfig[key].config) {
            scoreConfig[key].config = { elementType: scoreConfig[key].value, maxValue: 0 };
        }

        scoreConfig[key].config.weight = value;

        scoreConfig.forEach(el => totalWeight += (el.config ? parseInt(el.config.weight) : 0));


        this.setState({ scoreConfig, totalWeight });
    }

    handleTagValue = (e, key) => {
        const scoreConfig = [...this.state.scoreConfig];
        const { value } = e.target;

        if (scoreConfig[3].config && scoreConfig[3].config.maxValue) {
            if (scoreConfig[3].config.scoreConfigTags[key].value) {
                scoreConfig[3].config.maxValue = (parseInt(scoreConfig[3].config.maxValue) - parseInt(scoreConfig[3].config.scoreConfigTags[key].value) + parseInt(value));
            }
            else {
                scoreConfig[3].config.maxValue = (parseInt(scoreConfig[3].config.maxValue) + parseInt(value));
            }
        }
        else {
            if (!scoreConfig[3].config) {
                scoreConfig[3].config = { elementType: scoreConfig[key].value, weight: 0 };
            }

            scoreConfig[3].config.maxValue = value;
        }

        scoreConfig[3].config.scoreConfigTags[key].value = value;

        this.setState({ scoreConfig });
    }

    handleConfig = (evt, key) => {
        const scoreConfig = [...this.state.scoreConfig];
        const { name, value } = evt.target;


        if (!scoreConfig[key].config) {
            scoreConfig[key].config = { elementType: scoreConfig[key].value, weight: 0 };
        }

        scoreConfig[key].config[name] = value;


        this.setState({ scoreConfig });
    }


    render() {
        const { error, modal, block, scoreConfig, totalWeight, tags } = this.state;

        return (
            <StyledCard block={block} error={error}>
                <Row className="mb-3">
                    <Col>
                        <h5> <i className="fas fa-medal mr-2" /> <FormattedMessage id="ProfileScore.Title" /> </h5>
                    </Col>
                    <Col className="text-right">
                        <Button className="btn-sm btn-host" onClick={this.saveConfig} disabled={totalWeight !== 100}>
                            <i className="fas fa-save" />
                        </Button>
                    </Col>
                </Row>

                {scoreConfig && scoreConfig.map((config, key) =>
                    <Card className="border-0 shadow mb-3" body key={key}>
                        <Row>
                            <Col>
                                <b> {config.label} </b>
                            </Col>
                        </Row>
                                                
                        <Row className="align-items-center">
                            <Col className="col-2 text-right"><FormattedMessage id="ProfileScore.Max" /></Col>
                            <Col className="col-3 pr-0">
                                <Input
                                    type="number"
                                    name="maxValue"
                                    min={0} max={100000}
                                    value={config.config && config.config.maxValue ? parseInt(config.config.maxValue) : ''}
                                    readOnly={config.value === 'Tag'}
                                    onChange={e => this.handleConfig(e, key)}
                                />
                            </Col>

                            <Col className="col-2 text-right"><FormattedMessage id="ProfileScore.Weight" /></Col>
                            <Col className="col-3">
                                <Row className="align-items-center">
                                    <Col>
                                        <CustomInput
                                            type="range" id={config.value}
                                            min="0" max="100" step="1"
                                            value={config.config ? config.config.weight : 0}
                                            onChange={e => this.handleRangeInput(e, key)}
                                        />
                                    </Col>
                                    <Col className="text-right col-2 pl-1 pr-2">
                                        <span> {config.config ? config.config.weight : 0} % </span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        {config.value === 'Tag' ?
                            <div className="mt-5">
                                <Row className="align-items-center ml-4">
                                    <Col>
                                        <b><FormattedMessage id="ProfileScore.TagsValue" /></b>
                                    </Col>
                                    <Col className="text-right">
                                        <Button className="btn-sm btn-host" onClick={this.toggleModal} >
                                            <i className="fas fa-plus" />
                                        </Button>
                                    </Col>
                                </Row>

                                <hr className="ml-4 mt-2" />

                                <Row>
                                    {config.config && config.config.scoreConfigTags && config.config.scoreConfigTags.map((tag, k) =>
                                        <Col className="col-6" key={k}>
                                            <Row className="mb-2 align-items-center">
                                                <Col className={((k % 2 == 0) ? 'col-4' : 'col-2 pl-0') + " text-right"}> {tag.code} </Col>
                                                <Col className="col-6 pr-0">
                                                    <Input type="number" onChange={(e) => this.handleTagValue(e, k)} value={tag.value ? parseInt(tag.value) : ''} min={0} max={100000} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        : '' }
                    </Card>
                )}

                <Row className="mt-3">
                    <Col className="col-7" />
                    <Col className="col-3 pr-2">
                        <Card className="border-0 shadow" body>

                            {totalWeight < 100 ?
                                <Row className="mb-2" style={{ color: 'red', fontSize: '10px' }}>
                                    <Col>
                                        <b> <FormattedMessage id="ProfileScore.Missing" /> </b>
                                    </Col>
                                    <Col className="col-2 text-right pl-0">{100 - totalWeight} % </Col>
                                </Row>
                            : totalWeight > 100 ?
                                <Row className="mb-2" style={{ color: 'red', fontSize: '10px' }}>
                                    <Col>
                                        <b> <FormattedMessage id="ProfileScore.Exceeds" /> </b>
                                    </Col>
                                    <Col className="col-2 text-right pl-0">{totalWeight - 100} % </Col>
                                </Row>
                            : ''}

                            <Row>
                                <Col>
                                    <b> <FormattedMessage id="ProfileScore.TotalWeight" /> </b> {totalWeight === 100 ? <i className="fas fa-check ml-2" style={{ color: 'green' }}/> : ''}
                                </Col>
                                <Col className="text-right">{totalWeight} % </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>


                {modal ?
                    <Modal isOpen={modal} fade={false} size={"md"} style={{ minWidth: 0 }} >
                        <ModalBody>
                            <Button onClick={this.toggleModal} className="closeModal">
                                <i className="fas fa-times fa-sm"></i>
                            </Button>

                            <Row className="mb-3">
                                <Col>
                                    <h5><FormattedMessage id="ProfileScore.AddTag" /> </h5>
                                </Col>
                                <Col className="text-right col-3">
                                    <Button className="btn-sm btn-host" onClick={this.addTag} disabled={!this.state.newTag}>
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Label sm={4}> <FormattedMessage id="TagMapping.Tag" /></Label>
                                        <Col sm={8} >
                                            <CustomSelect
                                                options={scoreConfig[3].config && scoreConfig[3].config.scoreConfigTags && scoreConfig[3].config.scoreConfigTags.length > 0 ? tags.filter(tag => scoreConfig[3].config.scoreConfigTags.some(el => el.tagId === tag.value) === false) : tags}
                                                isSearchable
                                                placeholder={""}
                                                onChange={(combo) => this.setState({ newTag: combo })}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal> : ''}
            </StyledCard>
        );
    }
}
export default injectIntl(ProfileScore)