import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Col, Row, Modal, ModalBody, Form, Input } from 'reactstrap';
import { BlankCard } from "../../Base/CommonUIComponents";
import CustomSelect from '../../Base/CustomSelect';
import { FormattedMessage } from "react-intl";

class AddTagsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            tagMaps: [{ mapType: 'Tag', questionMap: this.props.questionMapValue }]
        };
    }

    handleChange = (e, index) => {
        const { name, value } = e.target;
        const tagMaps = [...this.state.tagMaps];

        tagMaps[index][name] = value;

        this.setState({ tagMaps });
    }

    handleSelect = (combo, index) => {
        const tagMaps = [...this.state.tagMaps];

        tagMaps[index].tagId = combo ? combo.value : null;

        this.setState({ tagMaps });
    }

    addTag = (e) => {
        e.preventDefault();
        const tagMaps = [...this.state.tagMaps];

        tagMaps.push({ mapType: 'Tag', questionMap: this.props.questionMapValue });

        this.setState({ tagMaps });
    }

    render() {
        const { tags, selectedQuestion, addTagModal, toggleModal, block, error, addTagsToMap, title } = this.props;
        const uiTitle = typeof title === 'object' ? title?.default : title;
        return (
            <Modal isOpen={addTagModal} fade={false} size={"lg"} style={{ minWidth: 0 }} >
                <Button onClick={() => toggleModal(null)} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody className="px-0">
                    <BlankCard block={block} error={error}>
                        <Form onSubmit={(e) => addTagsToMap(e, this.state.tagMaps, selectedQuestion.index)}>
                            <Row className="mb-3">
                                <Col>
                                    <h5>
                                        <b><FormattedMessage id="MapFormModal.Question" /></b>: {uiTitle}
                                    </h5>
                                </Col>
                                <Col className="text-right col-1">
                                    <Button className="btn-sm btn-host" type="submit">
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>

                            <Row className="my-4">
                                <Col> <b><FormattedMessage id="MapFormModal.Answer" /></b></Col>
                                <Col> <b><FormattedMessage id="MapFormModal.Tag" /></b></Col>
                                <Col className="text-right col-1">
                                    <Button className="btn-sm btn-host " onClick={this.addTag}>
                                        <i className="fas fa-plus"/>
                                    </Button>
                                </Col>
                            </Row>

                            {this.state.tagMaps && this.state.tagMaps.map((item, idx) =>
                                <Row key={idx} className="mb-3">
                                    <Col>
                                        {selectedQuestion.options ? 
                                            <CustomSelect
                                                options={selectedQuestion.options}
                                                onChange={e => this.handleChange({target: {value: e?.value, name: "tagValue"}}, idx)}
                                            />
                                        :
                                            <Input
                                                type="text"
                                                name="tagValue"
                                                onChange={(e) => this.handleChange(e, idx)}
                                                required
                                            />
                                        }
                                    </Col>
                                    <Col>
                                        <CustomSelect
                                            isSearchable isClearable
                                            placeholder={""}
                                            options={tags}
                                            required
                                            onChange={(e) => this.handleSelect(e, idx)}
                                        />
                                    </Col>

                                    <Col className="text-right col-1">
                                    </Col>
                                </Row>
                            )}
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(AddTagsModal)
