import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Card, Badge } from 'reactstrap';
import { handleNotification } from '../Base/Notification';
import { ActiveInactiveStatusBadge, BlankCard, StyledModal } from '../Base/CommonUIComponents';
import { Pagination, ResultsPerPage, TotalResults } from '../Base/PaginationComponents';
import { getResolvedStatus, showDuplications } from '../Base/ReferenceDataFunctions';
import { getAPI } from '../Base/API';
import moment from 'moment';

class ProfileAppliedRule extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            appliedRules: [],
            pageSize: 10,
            pageIndex: 0,
            totalItems: 0
        };
    }

    componentDidMount() {
        this.getRulesAppliedToProfile();
    }

    getRulesAppliedToProfile = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ appliedRules: data.response, totalItems: data.totalItems, block: false });
            }
        }, `/api/gms/Profile/v1/profile/${this.props.profileId}/rules?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}`)
    }

    changePageSize = (evt) => {
        const { value } = evt.target;

        if (this.state.pageSize !== value) {
            this.setState({ pageSize: value, pageIndex: 0 }, () => this.getRulesAppliedToProfile());
        }
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.getRulesAppliedToProfile());
    }


    render() {
        const { modal, toggleModal } = this.props;
        const { block, error, appliedRules, totalItems, pageSize, pageIndex } = this.state;

        const columns = [
            {
                dataField: 'ruleName',
                text: this.props.intl.formatMessage({ id: "DataQualityIssues.Rule" })
            },
            {
                dataField: 'entity',
                text: this.props.intl.formatMessage({ id: "RuleDashboard.Entity" }),
                formatter: (cell) => this.props.intl.formatMessage({ id: `ReferenceDataFunctions.${cell}` }),
                width: '15%'
            },
            {
                dataField: 'field',
                text: this.props.intl.formatMessage({ id: "RuleDashboard.Field" }),
                formatter: (cell) => this.props.intl.formatMessage({ id: `ReferenceDataFunctions.${cell}` }),
                width: '15%'
            },
            {
                dataField: 'value',
                text: this.props.intl.formatMessage({ id: "DataQualityIssues.Value" })
            },
            {
                dataField: 'updatedAt',
                text: this.props.intl.formatMessage({ id: "generic.UpdatedAt" }),
                formatter: (cell) => moment(cell).format("YYYY-MM-DD"),
                width: '9.5%'
            },
            {
                dataField: 'resolved',
                text: this.props.intl.formatMessage({ id: "TaskResultDetail.Resolved" }),
                width: '8%',
                formatter: (cell, row) => {
                    if (cell != null && showDuplications(row.ruleType, row.duplicateMatchType)) {
                        return '';
                    }
                    else {
                        const status = getResolvedStatus(this.props.intl).find(opt => opt.bool === row.resolved);

                        return status ? <Badge color={status.color} className="py-1 px-2"> {status.label} {row.resolved !== 'Ignored' && row.ruleMode ? `(${this.props.intl.formatMessage({ id: `ReferenceDataFunctions.${row.ruleMode}` })})` : ''} </Badge> : ''
                    }
                }
            },
            {
                dataField: 'active',
                text: this.props.intl.formatMessage({ id: "generic.status" }),
                formatter: (cell) => <ActiveInactiveStatusBadge status={cell} />,
                width: '6%'
            }
        ]

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="md" style={{ maxWidth: '85%', width: '85%' }}>
                <BlankCard block={block} error={error}>
                    <h5 className="mb-4">
                        <FormattedMessage id="ProfileDetails.AppliedRulesToProfile"/>
                    </h5>

                    <Card className="border-0 shadow text-muted mb-2 py-2 p-0" body>
                        <Row style={{ marginLeft: 0 }} className="w-100 px-2">
                            {columns.map((column, index) =>
                                <Col key={index} className="px-2" style={{ flex: column.width, maxWidth: column.width }}>
                                    {column.text}
                                </Col>
                            )}
                        </Row>
                    </Card>

                    <Card className="border-0 shadow p-0" body>
                        {appliedRules.map((rule, key) =>
                            <Row key={key} className="overbglight py-3 w-100 px-2" style={{ marginLeft: 0 }}>
                                {columns.map((column, index) => {
                                    const value = rule[column.dataField];

                                    return <Col key={index} className="px-2" style={{ flex: column.width, maxWidth: column.width, lineBreak: 'anywhere' }}>
                                        {column.formatter && value !== null ? column.formatter(value, rule) : value}
                                    </Col>
                                } )}
                            </Row>
                        )}
                    </Card>


                    <Row className="mt-3">
                        <Col>
                            <ResultsPerPage
                                changeSizePerPage={this.changePageSize}
                                pageSize={pageSize}
                            />

                            <span className="ml-2">
                                <TotalResults
                                    end={pageIndex * pageSize + appliedRules.length}
                                    start={pageIndex * pageSize + 1}
                                    total={totalItems}
                                />
                            </span>
                        </Col>

                        <Col className="text-right">
                            <Pagination
                                isPrevDisabled={pageIndex === 0}
                                isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                currentPage={pageIndex + 1}
                                handlePrevButton={() => this.handlePagination(-1)}
                                handleNextButton={() => this.handlePagination(1)}
                            />
                        </Col>
                    </Row>     
                </BlankCard>
            </StyledModal>
        );
    }
}

export default injectIntl(ProfileAppliedRule);