import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, Col, Modal, ModalBody, Row, Badge, Button, UncontrolledTooltip } from 'reactstrap'
import { getEntityList, getFieldList } from "../../Base/ReferenceDataFunctions";
import { BlankCard } from '../../Base/CommonUIComponents';

class EgoiMappings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            entities: getEntityList()
        };
    }

    render() {
        const { modal, toggleModal, List, tags } = this.props;

        return (
            <Modal isOpen={modal} toggle={toggleModal} fade={false} className="modal-xl" >
                <ModalBody className="p-0">
                    <BlankCard>
                        <Row>
                            <Col>
                                <h5 className="mb-4"><FormattedMessage id="ImportProfile.EgoiMappings" /> - {List.label}</h5>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn btn-sm btn-host" onClick={() => this.props.history.push({ pathname: `/Mappings` })} id="mappingsPage">
                                    <i className="fas fa-random"/>
                                </Button>

                                <UncontrolledTooltip target={`mappingsPage`} placement="left" >
                                    <FormattedMessage id="ImportProfile.GoToMappingsPage" />
                                </UncontrolledTooltip>
                            </Col>
                        </Row>

                        {List.mappings && List.mappings.length > 0 ?
                            <Row>
                                {List.mappings.map((mapping, index) => {
                                    const entity = this.state.entities.find(el => el.value === mapping.entityName);
                                    const field = getFieldList(mapping.entityName, this.props.intl).find(el => el.value === mapping.entityField);
                                    const tag = tags.find(el => el.value === mapping.tagId);

                                    return <Col className="col-6 mb-3" key={index}>
                                        <Card className="border-0 shadow py-2 px-3 h-100 d-flex justify-content-center">
                                            <Row className="align-items-center">
                                                <Col>
                                                    {mapping.originSubType === 'CustomField' ?
                                                        <span> <Badge className={"color-white py-1 px-2 mr-2 bg-cyan"}> extra </Badge> <b> {mapping.originMapValue} </b></span>
                                                        :
                                                        <>
                                                            {/*<span className="mr-1" style={{ borderRadius: '20px', backgroundColor: egoiTag && egoiTag.color, width: '20px', height: '20px' }} >&nbsp; &nbsp;&nbsp;&nbsp;</span>*/}
                                                            <b> {mapping.originMapValue} </b>
                                                        </>
                                                    }
                                                    <br />
                                                    <span className="text-muted font_size_xxs">
                                                        {mapping.originSubType === 'CustomField' ? <> <FormattedMessage id="ImportProfile.Field" /> Id: </> : <> Tag Id: </> } <b>{mapping.originFieldId}</b>
                                                    </span>
                                                </Col>

                                                <Col sm={2} className="text-host">
                                                    <i className="fas fa-lg fa-long-arrow-alt-right" />
                                                </Col>


                                                {mapping.destinationType === 'Field' ?
                                                    <Col className="px-2">
                                                        <b> {field && field.label} </b> <br />
                                                        <span className="text-muted font_size_xxs"> {entity && entity.label}</span>
                                                    </Col>
                                                    :
                                                    <Col className="px-2">
                                                        <Badge color="primary px-2 py-1" style={{ fontSize: '12px' }}> <b> {tag && tag.label} </b> </Badge> <br />
                                                        <span className="text-muted font_size_xxs"> Tag </span>
                                                    </Col>
                                                }
                                            </Row>
                                        </Card>
                                    </Col>
                                })}
                            </Row>
                            :
                            <div className="text-center"><FormattedMessage id="ImportProfile.ThereAreNoMappings" /></div>}
                    </BlankCard>
                </ModalBody>
            </Modal>
        )
    }
}

export default injectIntl(EgoiMappings)