import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Card, Button, FormGroup, Label, Input, Form, CustomInput } from 'reactstrap';
import { getEntityList, getFieldList, getTransformationActionType } from '../../../Base/ReferenceDataFunctions';
import { handleNotification } from "../../../Base/Notification";
import { StyledCard } from "../../../Base/CommonUIComponents";
import DataRuleActionDetails from './DataRuleActionDetails';
import CustomSelect from '../../../Base/CustomSelect';
import { getAPI, postAPI } from "../../../Base/API";

class DataRulesActionDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            modal: false,
            dataTranformConfig: this.props.config ? this.props.config : { dataTransformConfigActions: [], isClean: false },
            regexList: [],
            availableActions: getTransformationActionType()
        };
    }

    componentDidMount() {
        this.getRegEx();

        if (this.props.match.params.id) {
            this.getDataTranformConfig();
        }
    }

    getDataTranformConfig = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    let availableActions = getTransformationActionType();

                    if (data.response[0].dataTransformConfigActions?.length > 0) {
                        availableActions = getTransformationActionType().filter(el => el.value === data.response[0].dataTransformConfigActions[0].action);
                    }

                    this.setState({ block: false, dataTranformConfig: data.response[0], availableActions });
                }
                else {
                    this.setState({ block: false });
                }
            }
        }, `/api/gms/DataQuality/v1/DataTransformConfigurationById?id=${this.props.match.params.id}`)
    }

    getRegEx = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const regexList = data.response?.reduce((acc, cur) => {
                    acc.push({ value: cur.id, label: cur.code, desc: cur.description, regularExpression: cur.regularExpression, regularExpressionTransform: cur.regularExpressionTransform });

                    return acc;
                }, []);

                this.setState({ regexList });
            }
            else this.setState({ error: errorMessage });
        }, '/api/gms/DataQuality/v1/regularExpressionRule?type=Transform');
    }

    saveConfig = (e) => {
        e.preventDefault();

        this.setState({ block: true });

        const body = { request: this.state.dataTranformConfig };

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="DataRuleConfig.ConfigSaved" />, <FormattedMessage id="generic.success" />);
                    if (data.response && data.response.length > 0) this.props.history.push({ pathname: `/DataRuleConfig/${data.response[0].id}` });
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/DataQuality/v1/DataTransformConfiguration`, body)
    }

    toggleModal = (selectedAction) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedAction
        }))
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            dataTranformConfig: {
                ...this.state.dataTranformConfig,
                [name]: value
            }
        })
    }

    updateActions = (e, action) => {
        e.preventDefault();

        let { dataTranformConfig, availableActions } = this.state;
        const index = dataTranformConfig.dataTransformConfigActions.findIndex(el => action.id && el.id === action.id);

        if (index !== -1) {
            dataTranformConfig.dataTransformConfigActions[index] = action;
        }
        else {
            dataTranformConfig.dataTransformConfigActions.push(action);
        }

        if (availableActions.length === 2) {
            availableActions = availableActions.filter(el => el.value === action.action);
        }

        this.setState({ dataTranformConfig, modal: false, availableActions });
    }

    handleEntity = (combo) => {
        this.setState({
            dataTranformConfig: {
                ...this.state.dataTranformConfig,
                entity: combo ? combo.value : null,
                field: null
            }
        });
    }

    handleSelect = (combo, name) => {
        this.setState({
            dataTranformConfig: {
                ...this.state.dataTranformConfig,
                [name]: combo ? combo.value : null
            }
        });
    };

    handleIsClean = (event) => {
        this.setState({
            dataTranformConfig:
            {
                id: this.state.dataTranformConfig.id,
                name: this.state.dataTranformConfig.name,
                isClean: event.target.checked
            }
        })
    }


    render() {
        const { block, error, modal, dataTranformConfig } = this.state;

        return (
            <StyledCard block={block} error={error} >
                <Form onSubmit={this.saveConfig}>
                    <Row className="mb-4">
                        <Col>
                            <h5><i className="fas fa-list-ol mr-2"/> <FormattedMessage id="DataRuleConfig.DataRuleConfig" /> </h5>
                        </Col>
                        <Col className="text-right col-2 pr-4 mr-3">
                            <Button className="btn-sm btn-host" type="submit">
                                <i className="fas fa-save" />
                            </Button>
                        </Col>
                    </Row>

                    <FormGroup row className="px-4">
                        <Label sm={2}> <FormattedMessage id="generic.name" /></Label>
                        <Col sm={4} >
                            <Input
                                type="text"
                                name="name"
                                required
                                maxLength={50}
                                value={dataTranformConfig.name || ''}
                                onChange={this.handleChange}
                            />
                        </Col>
                        <Label sm={2}> <FormattedMessage id="RuleDashboard.IsClean" />? </Label>
                        <Col sm={4}>
                            <CustomInput
                                type="switch"
                                id="isClean"
                                name="isClean"
                                onChange={(e) => this.handleIsClean(e)}
                                checked={dataTranformConfig.isClean || false}
                            />
                        </Col>
                    </FormGroup>

                    {dataTranformConfig.isClean != true &&
                        <>
                            <FormGroup row className="px-4">
                                <Label sm={2}> <FormattedMessage id="RuleDashboard.Entity" /></Label>
                                <Col sm={4} >
                                    <CustomSelect
                                        options={getEntityList().filter(el => el.value === 'Profile' || el.value === 'ProfileAddress' || el.value === 'ProfileContact' || el.value === 'ProfileDocument')}
                                        isSearchable isClearable
                                        placeholder={""}
                                        onChange={(combo) => this.handleEntity(combo, 'entity')}
                                        value={getEntityList().find(el => el.value === dataTranformConfig.entity) || ''}
                                    />
                                </Col>
                                <Label sm={2}> <FormattedMessage id="RuleDashboard.Field" /></Label>
                                <Col sm={4} >
                                    <CustomSelect
                                        options={getFieldList(dataTranformConfig.entity, this.props.intl).filter(el => el.split)}
                                        required={!dataTranformConfig.entity}
                                        isSearchable isClearable
                                        placeholder={""}
                                        isDisabled={!dataTranformConfig.entity}
                                        onChange={(combo) => this.handleSelect(combo, 'field')}
                                        value={getFieldList(dataTranformConfig.entity, this.props.intl).find(el => el.value === dataTranformConfig.field) || ''}
                                    />
                                </Col>
                            </FormGroup>


                            <Row className="mb-3 mt-5">
                                <Col>
                                    <h5><FormattedMessage id="DataRuleConfig.Actions" /></h5>
                                </Col>
                                <Col className="text-right pr-4 mr-3">
                                    <Button className="btn-sm btn-host" onClick={() => this.toggleModal()}>
                                        <i className="fas fa-plus" />
                                    </Button>
                                </Col>
                            </Row>


                            <Card className="border-0 shadow mb-3 text-muted py-3" body>
                                <Row className="align-items-center">
                                    <Col> <FormattedMessage id="DataRuleConfig.Action" /> </Col>
                                    <Col> <FormattedMessage id="RuleDashboard.Entity" /></Col>
                                    <Col> <FormattedMessage id="RuleDashboard.Field" /> </Col>
                                    <Col> <FormattedMessage id="DataRuleConfig.Value" /></Col>
                                    <Col> <FormattedMessage id="DataRuleConfig.Rule" /></Col>
                                    <Col> <FormattedMessage id="DataRuleConfig.OverlapAction" /></Col>
                                    <Col> <FormattedMessage id="DataRuleConfig.SourceField" /> </Col>
                                    <Col className="col-1 text-right" />
                                </Row>
                            </Card>
                            {dataTranformConfig.dataTransformConfigActions?.map((action, key) => {
                                const regex = this.state.regexList?.find(el => el.value === action.regularExpressionRuleId);

                                return <Card className="border-0 shadow mb-2 overbglight" key={key} body>
                                    <Row>
                                        <Col>
                                            <b><FormattedMessage id={`ReferenceDataFunctions.${action.action}`} /></b>
                                        </Col>
                                        <Col>
                                            <FormattedMessage id={`ReferenceDataFunctions.${action.entity}`} />
                                        </Col>
                                        <Col>
                                            <FormattedMessage id={`ReferenceDataFunctions.${action.field}`} />
                                        </Col>
                                        <Col>
                                            {action.value ? typeof action.value == "boolean" ? <FormattedMessage id={`ReferenceDataFunctions.${action.field}-${action.value}`} /> : <FormattedMessage id={`ReferenceDataFunctions.${action.value}`} /> : ''}
                                        </Col>
                                        <Col>
                                            {regex ? regex.label : action.regularExpressionRuleId}
                                        </Col>
                                        <Col>
                                            {action.overlapAction ? <FormattedMessage id={`ReferenceDataFunctions.${action.overlapAction}`} /> : ''}
                                        </Col>
                                        <Col>
                                            {action.removeSource ? <FormattedMessage id="DataRuleConfig.Remove" /> : action.removeSource === false ? <FormattedMessage id="DataRuleConfig.Keep" /> : ''}
                                        </Col>
                                        <Col className="col-1 text-right">
                                            <Button className="btn-sm btn-host" onClick={() => this.toggleModal(action)}>
                                                <i className="far fa-edit" />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            })}
                        </>
                    }
                </Form>

                {modal &&
                    <DataRuleActionDetails
                    modal={this.state.modal}
                    toggle={this.toggleModal}
                    selectedAction={this.state.selectedAction}
                    regexList={this.state.regexList}
                    updateActions={this.updateActions}
                    availableActions={this.state.availableActions}
                    />
                }
            </StyledCard>
        );
    }
}
export default injectIntl(DataRulesActionDetails)
