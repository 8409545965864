import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { injectIntl } from 'react-intl';
import { Button, Card, Col, Row, Breadcrumb, BreadcrumbItem, Modal, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { StyledCard, BlankCard } from "../Base/CommonUIComponents";
import { getAPI, postAPI } from "../Base/API";
import { handleNotification } from "../Base/Notification";
import { FormattedMessage } from "react-intl";
import moment from 'moment';

import Authorization from '../Base/Authorization';

export class ShortenerList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            shortenerList: []
        };
    }

    componentDidMount() {
        this.getShortenerList();
    }

    getShortenerList = () => {
        getAPI(result => {
             const { data, error } = result;
             const errorMessage = [];
 
             if (error) {
                 errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                 this.setState({ error: errorMessage, block: false });
                 return;
             }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ shortenerList: data.response ? data.response : [] });
             }
             this.setState({ error: errorMessage, block: false });
        }, '/api/gms/Marketing/v1/egoitransactional/v2/shortener/links');
    }

    saveShortener = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                else {
                    handleNotification(data, <FormattedMessage id="ShortenerList.ShortenerSaved" />, <FormattedMessage id="generic.success" />);
                    this.getShortenerList();
                    this.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoitransactional/v2/shortener/link?name=${this.state.name}&originalLink=${this.state.originalLink}`);
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        })
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    render() {
        const { block, error, shortenerList } = this.state;

        return (

            <StyledCard block={block} error={error} >

                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/MarketingConfigEgoi"> Config</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Shortener</BreadcrumbItem>
                    </Breadcrumb>
                </div>


                <Row>
                    <Col><h4>Shortener</h4></Col>

                    <Authorization
                        perform="shortenerList:add"
                        yes={() => (
                            <Col className="text-right">
                                <Button className="btn btn-sm btn-host" onClick={this.toggleModal}>
                                    <i className="fa fa-plus"></i>
                                </Button>
                            </Col>
                        )}
                        no={() => <div></div>}
                    />
                </Row>
                <Card className="border-0 py-2 mb-4">

                    <Row className="mb-2">
                        <Col><b><FormattedMessage id="ShortenerList.Domain" /></b></Col>
                        <Col><b>Link</b></Col>
                        <Col className="col-3"><b><FormattedMessage id="ShortenerList.OriginalLink" /></b></Col>
                        <Col className="col-3"><b><FormattedMessage id="ShortenerList.Name" /></b></Col>
                        <Col><b><FormattedMessage id="ShortenerList.CreatedDate" /></b></Col>
                    </Row>
                    {shortenerList && shortenerList.map((el, k) =>
                        <Card className="border-0 mb-1" key={k}>
                            <Row className="overbglight py-1">
                                <Col>{el.domain}</Col>
                                <Col>{el.link}</Col>
                                <Col className="col-3">{el.originalLink}</Col>
                                <Col className="col-3">{el.name}</Col>
                                <Col>{el.createDate ? moment(el.createDate).format("YYYY-MM-DD HH:mm:ss") : ''}</Col>
                            </Row>
                        </Card>
                    )}
                </Card>
               
                {this.state.modal ?
                    <Modal isOpen={this.state.modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                        <Button onClick={this.toggleModal} className="closeModal">
                            <i className="fas fa-times fa-sm"></i>
                        </Button>
                        <ModalBody>
                            <BlankCard block={block} error={error}>
                                <Form onSubmit={(e) => this.saveShortener(e)}>
                                    <Row className="mb-3">
                                        <Col>
                                            <h5><FormattedMessage id="ShortenerList.AddShortenerLink" /></h5>
                                        </Col>
                                        <Col className="text-right">
                                            <Button className="btn-sm btn-host" type="submit"> <i className="fas fa-save" /></Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label sm={2}> <FormattedMessage id="ShortenerList.Name" /> </Label>
                                                <Col sm={10}>
                                                    <Input
                                                        type="text"
                                                        name="name"
                                                        onChange={(e) => this.handleChange(e)}
                                                        value={this.state.name ? this.state.name : ''}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        </Row>
                                            <Row>
                                        <Col >
                                            <FormGroup row>
                                                <Label sm={2}> <FormattedMessage id="ShortenerList.OriginalLink" /></Label>
                                                <Col sm={10} >
                                                    <Input
                                                        type="text"
                                                        name="originalLink"
                                                        onChange={(e) => this.handleChange(e)}
                                                        value={this.state.originalLink ? this.state.originalLink : ''}
                                                        required
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </BlankCard>
                        </ModalBody>
                    </Modal>
                    : ''}
            </StyledCard>
        );
    }
}
export default injectIntl(ShortenerList)
