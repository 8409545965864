import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Col, Form, Input, Row, Modal, ModalBody, UncontrolledTooltip, Progress, CustomInput, Card, Label } from "reactstrap";
import { getHotelGroupModeType, getToMasterProps } from "../Base/ReferenceDataFunctions";
import { handleNotification } from "../Base/Notification"; 
import { StyledCard, ActiveInactiveStatusCombo, BlankCard } from "../Base/CommonUIComponents";
import { getAPI, putAPI, postAPI, deleteAPI } from "../Base/API";
import CustomSelect from "../Base/CustomSelect";
import egoi from '../../img/egoi.png';
import reviewProLogo from '../../img/LogoReviewPro.png';
import heyCard from '../../img/HeyCard.png';
import roiback from '../../img/roiback.jpg'; 
import hiJiffy from '../../img/HiJiffy.png';
import stripo from '../../img/Stripo.png';
import whatsApp from '../../img/WhatsApp.png';
import profileNow from '../../img/profileNow.png';
import typeform from '../../img/Typeform.ico';
import googleBusiness from '../../img/GoogleBusiness.ico';
import facebook from '../../img/Facebook.ico';
import sendGrid from '../../img/SendGrid.ico';
import twilio from '../../img/Twilio.png';
import microsoft365 from '../../img/Microsoft365.png';
import googleWallet from '../../img/googleWallet.ico';
import appleWallet from '../../img/appleWallet.png';
import Authorization from '../Base/Authorization';
import ConnectionModal from "./ConnectionModal";

class CurrentHotelGroup extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.form = React.createRef();
        this.state = {
            block: false,
            hotelGroup: {},
            modal: false,
            connections: [
                {
                    type: 'Egoi',
                    logo: egoi,
                    configuration: null,
                    connectionName: 'egoiConnection',
                    title: <FormattedMessage id="CurrentHotelGroup.EgoiConnection" />
                },
                {
                    type: 'ReviewPro',
                    logo: reviewProLogo,
                    configuration: null,
                    connectionName: 'reviewProConnection',
                    title: <FormattedMessage id="CurrentHotelGroup.ReviewProConnection" />
                },
                {
                    type: 'HeyCard',
                    logo: heyCard,
                    configuration: null,
                    connectionName: 'heyCardConnection',
                    title: <FormattedMessage id="CurrentHotelGroup.HeyCardConnection" />
                },
                {
                    type: 'Roiback',
                    logo: roiback,
                    configuration: null,
                    connectionName: 'roibackConnection',
                    title: <FormattedMessage id="CurrentHotelGroup.RoibackConnection" />
                },
                {
                    type: 'HiJiffy',
                    logo: hiJiffy,
                    configuration: null,
                    connectionName: 'hiJiffyConnection',
                    title: <FormattedMessage id="CurrentHotelGroup.HiJiffyConnection" />
                },
                {
                    type: 'StripoOwnAccount',
                    logo: stripo,
                    configuration: null,
                    connectionName: 'StripoOwnAccountConnection',
                    title: <FormattedMessage id="CurrentHotelGroup.stripoConnection" />
                },
                {
                    type: 'StripoSharedAccount',
                    logo: stripo,
                    configuration: null,
                    connectionName: 'StripoSharedAccount',
                    title: <FormattedMessage id="CurrentHotelGroup.stripoConnection" />
                },
                {
                    type: 'ProfileNowForms',
                    logo: profileNow,
                    configuration: null,
                    connectionName: 'profileNowForms',
                    title: <FormattedMessage id="CurrentHotelGroup.ProfileNowFormsConnection" />
                },
                {
                    type: 'WhatsApp',
                    logo: whatsApp,
                    configuration: null,
                    connectionName: 'WhatsApp',
                    title: <FormattedMessage id="CurrentHotelGroup.WhatsAppConnection" />
                },
                {
                    type: 'Typeform',
                    logo: typeform,
                    configuration: null,
                    connectionName: 'Typeform',
                    title: <FormattedMessage id="CurrentHotelGroup.TypeformConnection" />
                },
                {
                    type: 'GoogleBusiness',
                    logo: googleBusiness,
                    configuration: null,
                    connectionName: 'GoogleBusiness',
                    title: <FormattedMessage id="CurrentHotelGroup.GoogleBusinessConnection" />
                },
                {
                    type: 'FacebookContent',
                    logo: facebook,
                    configuration: null,
                    connectionName: 'FacebookContent',
                    title: <FormattedMessage id="CurrentHotelGroup.FacebookContentConnection" />
                },
                {
                    type: 'SendGrid',
                    logo: sendGrid,
                    configuration: null,
                    connectionName: 'SendGrid',
                    title: <FormattedMessage id="CurrentHotelGroup.SendGridConnection" />
                },
                {
                    type: 'TwilioMessaging',
                    logo: twilio,
                    configuration: null,
                    connectionName: 'TwilioMessaging',
                    title: <FormattedMessage id="CurrentHotelGroup.TwilioMessaging" />
                },
                {
                    type: 'MicrosoftOffice365',
                    logo: microsoft365,
                    configuration: null,
                    connectionName: 'MicrosoftOffice365',
                    title: <FormattedMessage id="CurrentHotelGroup.MicrosoftOffice365" />
                },
                {
                    type: 'GoogleWallet',
                    logo: googleWallet,
                    configuration: null,
                    connectionName: 'GoogleWallet',
                    title: <FormattedMessage id="CurrentHotelGroup.GoogleWallet" />
                },
                {
                    type: 'AppleWallet',
                    logo: appleWallet,
                    configuration: null,
                    connectionName: 'AppleWallet',
                    title: <FormattedMessage id="CurrentHotelGroup.AppleWallet" />
                }
            ],
            isGoogle: false
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true;

        this.getHotelGroup();
    }

    getHotelGroup = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.response && data.response.length > 0) {
                    const { connections } = this.state;

                    connections.forEach(connection => {
                        const config = data.response[0].hotelGroupConnection && data.response[0].hotelGroupConnection.find(c => c.type === connection.type);

                        connection.configuration = config ? config : { name: null, active: null, type: connection.type, isConfigured: false };
                    })

                    this.setState({
                        hotelGroup: data.response[0],
                        connections,
                        block: false
                    });
                }
                else this.setState({ block: false });
            }
        }, `/api/gms/Hotel/v1/hotelgroup`);
    }

    saveHotelGroup = (event) => {
        event.preventDefault();
        this.setState({ block: true });
        const { hotelGroup } = this.state;
        
        putAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="CurrentHotelGroup.HotelGroupSaved" />, <FormattedMessage id="generic.success" />);
                }
            }
            this.setState({ block: false });
        }, '/api/gms/Hotel/v1/hotelgroup/config', JSON.stringify({ request: hotelGroup }));
    }

    saveConnection = (event, connectionName) => {
        event.preventDefault();
        this.setState({ block: true });

        const connections = [ ...this.state.connections ];
        const index = connections.findIndex(conn => conn.connectionName === connectionName);
        const config = connections[index].configuration;

        config.hotelGroupId = this.state.hotelGroup.hotelGroupId;

        putAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="CurrentHotelGroup.ConnectionSaved" />, <FormattedMessage id="generic.success" />);

                    Object.assign(config, data.response[0]);
                    config.isConfigured = true;
                    this.setState({ error: errorMessage, connections: connections, block: false })
                }
            }
            else this.setState({ error: errorMessage, block: false });
        }, '/api/gms/Hotel/v1/hotelGroup/apiConnection', JSON.stringify({ request: config }));
    }

    testConnection = (event) => {
        event.preventDefault();
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data && data.result && data.result === 'pong') {
                handleNotification(data, <FormattedMessage id="CurrentHotelGroup.TestConnectionSuccessfully" />, <FormattedMessage id="generic.success" />);
            }
            this.setState({ block: false });
        }, `/api/gms/Marketing/v1/egoi/ping`);
    }
    
    handleSelect = (name, combo) => {
        this.setState({
            hotelGroup: {
                ...this.state.hotelGroup,
                [name]: combo ? combo.value : null
            }
        })
    }

    handleChangeSwitch = (e) => {
        const { name, checked } = e.target;

        this.setState({
            hotelGroup: {
                ...this.state.hotelGroup,
                [name]: checked
            }
        })
    }

    handleConnectionStatus = (connectionName, fieldName, combo) => {
        const { connections } = this.state;
        const index = connections.findIndex(conn => conn.connectionName === connectionName);

        if (index !== -1) {
            connections[index].configuration.active = combo ? combo.value : null;
        }

        this.setState({ connections })
    };

    handleChangeConnection = (e, connectionName) => {
        const { name, value } = e.target;
        const { connections } = this.state;
        const index = connections.findIndex(conn => conn.connectionName === connectionName);

        if (index !== -1) {
            connections[index].configuration[name] = value;
        }

        this.setState({ connections })
    }

    toggleModal = (configuration, isGoogle) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedConfig: configuration,
            isGoogle
        }))
    }

    clearGoogleData = (cb) => {
        this.setState({ block: true }, () => {
            deleteAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    if(data.errors && data.errors.length > 0){
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }else if(data.response && data.response[0]){
                        this.setState({ block: false, modal: false }, () => {
                            if(cb) cb();
                        });
                        return;
                    }
                }
                this.setState({ block: false, modal: false });
            }, `/api/gms/Google/v1/GoogleData`);
        });
    }
    handleMasterProperties = (property, isSelected) => {
        const { hotelGroup } = this.state;

        if (isSelected) {
            hotelGroup.toMasterProperties = hotelGroup.toMasterProperties.filter(p => p !== property);
        }
        else {
            if (!hotelGroup.toMasterProperties) {
                hotelGroup.toMasterProperties = [];
            }

            hotelGroup.toMasterProperties.push(property);
        }

        this.setState({ hotelGroup });
    }

    render() {
        const { hotelGroup, connections, selectedConfig } = this.state;


        return (
            <div>
                <Form onSubmit={this.saveHotelGroup.bind(this)} className="mb-3">
                    <StyledCard block={this.state.block} error={this.state.error} >
                        <Row className="mb-4">
                            <Col>
                                <h5>
                                    <i className="icon-icon-hotel mr-2"></i>
                                    <FormattedMessage id="CurrentHotelGroup.HotelGroup" /> 
                                </h5>
                            </Col>

                            <Authorization
                                perform="currentHotelGroup:save"
                                yes={() => (
                                    <Col className="text-right py-1">
                                        <Button className="btn btn-sm  btn-host " type="submit">
                                            <i className="fas fa-save "></i>
                                        </Button>
                                    </Col>
                                )}
                                no={() => <div></div>}
                            />
                        </Row>
                        <Row className="mb-4">
                            <Col className="col-2"><FormattedMessage id="CurrentHotelGroup.HotelGroupId" /></Col>
                            <Col className="col-4"> {hotelGroup && hotelGroup.hotelGroupId} </Col>
                            <Col className="col-2"><FormattedMessage id="CurrentHotelGroup.Name" /></Col>
                            <Col className="col-4"> {hotelGroup && hotelGroup.name}
                            </Col>
                        </Row>

                        <Row className="mb-4 align-items-center">
                            <Col className="col-2">
                                <FormattedMessage id="CurrentHotelGroup.License" />
                            </Col>

                            <Col className="col-4 d-flex">
                                <div className="mt-1" style={{ width: '85%' }}>
                                    <Progress animated max={hotelGroup.licensedProfiles} value={hotelGroup.nonMasterUserTotal}>
                                        {hotelGroup.nonMasterUserTotal}
                                    </Progress>
                                </div>
                                <div className="text-right" style={{ width: '15%' }}>
                                    {hotelGroup.licensedProfiles}
                                </div>
                            </Col>

                            <Col className="col-2">
                                <FormattedMessage id="CurrentHotelGroup.TotalMasters" />
                            </Col>
                            <Col className="col-1">
                                {hotelGroup.masterUserTotal}
                            </Col>

                            <Col className="col-3 d-flex justify-content-end">
                                <FormattedMessage id="CurrentHotelGroup.CalculateProfileScore" />
                                <CustomInput
                                    className="ml-4"
                                    type="switch"
                                    id="profileScores"
                                    name="profileScores"
                                    onChange={(e) => this.handleChangeSwitch(e)}
                                    checked={hotelGroup.profileScores}
                                />
                            </Col>
                        </Row>

                        <Row className="align-items-center mb-4">
                            <Col className="col-2"><FormattedMessage id="CurrentHotelGroup.ProfileUpdateMode" /></Col>
                            <Col className="col-4" style={{ zIndex: '999' }}>
                                <CustomSelect
                                    options={getHotelGroupModeType()}
                                    value={hotelGroup.profileUpdateMode ? getHotelGroupModeType().find(m => m.value === hotelGroup.profileUpdateMode) : ''}
                                    isSearchable
                                    placeholder={''}
                                    onChange={this.handleSelect.bind(this, 'profileUpdateMode')}
                                    required={true}
                                />
                            </Col>

                            <Col className="col-2 pr-1"><FormattedMessage id="CurrentHotelGroup.MasterProfileCreationMode" /></Col>
                            <Col className="col-2" style={{ zIndex: '999' }}>
                                <CustomSelect
                                    options={getHotelGroupModeType()}
                                    value={hotelGroup.masterProfileCreationMode ? getHotelGroupModeType().find(m => m.value === hotelGroup.masterProfileCreationMode) : ''}
                                    isSearchable
                                    placeholder={''}
                                    onChange={this.handleSelect.bind(this, 'masterProfileCreationMode')}
                                    required={true}
                                />
                            </Col>

                            <Col className="col-2 d-flex justify-content-end">
                                <FormattedMessage id="CurrentHotelGroup.PopulateCoordinates" />
                                <CustomInput
                                    type="switch"
                                    className="ml-4"
                                    id="populateCoordinates"
                                    name="populateCoordinates"
                                    onChange={(e) => this.handleChangeSwitch(e)}
                                    checked={hotelGroup.populateCoordinates}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Col className="col-2">
                                <FormattedMessage id="CurrentHotelGroup.ToMasterProperties" />
                            </Col>
                            <Col>
                                <Row>
                                    {
                                        getToMasterProps().map((prop, key) => {
                                            const isSelected = hotelGroup?.toMasterProperties?.some(p => p === prop.value);
                                            return (
                                                <Col key={key} className="col-3 mb-3">
                                                    <Card className={"border-0 shadow py-2 px-3 pointer " + (isSelected ? 'bg-host-light' : '')} onClick={() => this.handleMasterProperties(prop.value, isSelected)}>
                                                        {prop.label}
                                                    </Card>
                                                </Col>
                                            );
                                        })
                                    }
                                </Row>
                            </Col>
                            <Col className="col-2 d-flex justify-content-end">
                                <Row>
                                    <Col className="col-12 d-flex justify-content-end">
                                        <FormattedMessage id="CurrentHotelGroup.ShowComplexes" />
                                        <CustomInput
                                            type="switch"
                                            className="ml-4"
                                            id="showComplexes"
                                            name="showComplexes"
                                            onChange={(e) => this.handleChangeSwitch(e)}
                                            checked={hotelGroup.showComplexes}
                                        />
                                    </Col>
                                    <Col className="col-12 d-flex justify-content-end">
                                        <FormattedMessage id="CurrentHotelGroup.ProcessTags" />
                                        <CustomInput
                                            type="switch"
                                            className="ml-4"
                                            id="processTags"
                                            name="processTags"
                                            onChange={(e) => this.handleChangeSwitch(e)}
                                            checked={hotelGroup.processTags}
                                        />
                                    </Col>
                                    <Col className="col-12 d-flex justify-content-end">
                                        <FormattedMessage id="CurrentHotelGroup.MasterGenActive" />
                                        <CustomInput
                                            type="switch"
                                            className="ml-4"
                                            id="masterGenActive"
                                            name="masterGenActive"
                                            onChange={(e) => this.handleChangeSwitch(e)}
                                            checked={hotelGroup.masterGenActive}
                                        />
                                    </Col>
                                </Row>
                            </Col>




                           
                        </Row>
                    </StyledCard>
                </Form>

                {connections.map((connection, key) => global.modules && global.modules.some(m => m === connection.type) && hotelGroup ?
                    <Form onSubmit={(e) => this.saveConnection(e, connection.connectionName)} key={key}>
                        <StyledCard block={this.state.block}>
                            <Card className="border-0 shadow " body>
                                <Row className="align-items-center">
                                    <Col>
                                        <h5 className="m-0">
                                            <img alt="" src={connection.logo} height="20px" className="mr-2" />
                                            {connection.title}
                                        </h5>
                                    </Col>

                                    <Col className="col-1"><FormattedMessage id="CurrentHotelGroup.Name" /></Col>
                                    <Col className="col-3 pl-0">
                                        <Input
                                            maxLength="200"
                                            name="name"
                                            value={connection.configuration && connection.configuration.name || ''}
                                            onChange={(e) => this.handleChangeConnection(e, connection.connectionName)}
                                            required
                                        />
                                    </Col>
                                    <Col className="col-1"><FormattedMessage id="generic.status" /></Col>
                                    <Col className="col-2 pl-0">
                                        <ActiveInactiveStatusCombo
                                            name='active'
                                            onChangeFunc={this.handleConnectionStatus.bind(this, connection.connectionName)}
                                            placeholder={''}
                                            value={connection.configuration && connection.configuration.active}
                                            required
                                        />
                                    </Col>

                                    <Col className="d-flex justify-content-end" sm={2}>
                                        {connection.configuration && (connection.configuration.isConfigured === undefined || connection.configuration.isConfigured !== false) ?
                                            <div>
                                                {connection.type === 'Egoi' ?
                                                    <Authorization
                                                        perform="currentHotelGroup:connection:test"
                                                        yes={() => (
                                                            <>
                                                                <Button className="btn btn-sm  btn-host mr-2" onClick={this.testConnection} id={`testConnection${key}`}>
                                                                    <i className="fas fa-plug "></i>
                                                                </Button>
                                                                <UncontrolledTooltip placement="bottom" target={`testConnection${key}`}>
                                                                    <FormattedMessage id="CurrentHotelGroup.TestConnection" />
                                                                </UncontrolledTooltip>
                                                            </>
                                                        )}
                                                        no={() => <div></div>}
                                                    />
                                                    : ''}



                                                {connection.type === 'GoogleWallet' ?
                                                    <Authorization
                                                        perform="currentHotelGroup:connection:test"
                                                        yes={() => (
                                                            <>
                                                                <Button className="btn btn-sm btn-host mr-2" onClick={() => this.toggleModal(connection.configuration, false)} id={`CheckAccount${key}`}>
                                                                    <i className="fas fa-eye"></i>
                                                                </Button>
                                                                <UncontrolledTooltip placement="bottom" target={`CheckAccount${key}`}>
                                                                    <FormattedMessage id="CurrentHotelGroup.CheckAccount" />
                                                                </UncontrolledTooltip>
                                                            </>
                                                        )}
                                                        no={() => <div></div>}
                                                    />
                                                    : ''}

                                                {connection.type === 'GoogleBusiness' ?
                                                    <Authorization
                                                        perform="currentHotelGroup:connection:newKey"
                                                        yes={() => (
                                                            <>
                                                                <Button className="btn btn-sm btn-host mr-2" onClick={() => this.clearGoogleData()} id={`ClearAccount${key}`}>
                                                                    <i className="fas fa-sign-out-alt"></i>
                                                                </Button>
                                                                <UncontrolledTooltip placement="bottom" target={`ClearAccount${key}`}>
                                                                    <FormattedMessage id="CurrentHotelGroup.ClearAccount" />
                                                                </UncontrolledTooltip>

                                                                <Button className="btn btn-sm btn-host mr-2" onClick={() => this.toggleModal(connection.configuration, true)} id={`CheckAccount${key}`}>
                                                                    <i className="fas fa-eye"></i>
                                                                </Button>
                                                                <UncontrolledTooltip placement="bottom" target={`CheckAccount${key}`}>
                                                                    <FormattedMessage id="CurrentHotelGroup.CheckAccount" />
                                                                </UncontrolledTooltip>
                                                            </>
                                                        )}
                                                        no={() => <div></div>}
                                                    />
                                                :
                                                    <Authorization
                                                        perform="currentHotelGroup:connection:newKey"
                                                        yes={() => (
                                                            <>
                                                                <Button className="btn btn-sm btn-host mr-2" onClick={() => this.toggleModal(connection.configuration)} id={`setNewKey${key}`}>
                                                                    <i className="fas fa-key" />
                                                                </Button>
                                                                <UncontrolledTooltip placement="bottom" target={`setNewKey${key}`}>
                                                                    <FormattedMessage id="CurrentHotelGroup.SetNewKey" />
                                                                </UncontrolledTooltip>
                                                            </>
                                                        )}
                                                        no={() => <div></div>}
                                                    />
                                                }
                                                
                                            </div>
                                        : ''}


                                        <Authorization
                                            perform="currentHotelGroup:connection:save"
                                            yes={() => (
                                                <Button className="btn btn-sm  btn-host " type="submit">
                                                    <i className="fas fa-save "></i>
                                                </Button>
                                            )}
                                            no={() => <div></div>}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </StyledCard>
                    </Form>
                    : ''
                    
                )}

                {this.state.modal ?
                    <ConnectionModal
                        modal={this.state.modal}
                        toggleModal={this.toggleModal}
                        selectedConfig={selectedConfig}
                        isGoogle={this.state.isGoogle}
                        clearGoogleData={this.clearGoogleData}
                    />
                : ''}
            </div>
        );
    }
}
export default injectIntl(CurrentHotelGroup)