import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { injectIntl } from 'react-intl';
import { Card, Col, Row, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { StyledCard } from "../Base/CommonUIComponents";
import { postAPI } from "../Base/API";
import { handleNotification } from "../Base/Notification";
import { FormattedMessage } from "react-intl";

export class DomainList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            domainList: []
        };
    }

    componentDidMount() {
        this.getDomainList();
    }

    getDomainList = () => {
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
 
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({
                    domainList: data.items ? data.items : []
                });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoitransactional/mail/domains/list`); 
    }

    render() {
        const { block, error, domainList } = this.state;

        return (
            <StyledCard block={block} error={error} >
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/MarketingConfigEgoi"> Config</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Domain</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                               
                <Row>
                    <Col><h4><FormattedMessage id="DomainList.Domain" /></h4></Col>
                </Row>

                <Card className="border-0 py-2 mb-4 mt-2">
                    <Row>
                        <Col><FormattedMessage id="DomainList.Domain" /></Col>
                        <Col><FormattedMessage id="DomainList.Fallbackurl" /></Col>
                    </Row>

                    {domainList && domainList.map((el, key) =>
                        <div key={key} className="pointer" onClick={() => this.toggleModal(el)}>
                            <Row>
                                <Col>{el.domain}</Col>
                                <Col>{el.fallbackurl}</Col>
                                {/*<Col className="col-1 text-right">
                                    <Button className="btn btn-sm btn-host" onClick={(e) => this.removeDomain(e, el.id)}>
                                        <i className="fas fa-trash-alt"></i>
                                    </Button>
                                </Col>*/}
                            </Row>
                        </div>
                    )}
                </Card>
            </StyledCard>
        );
    }
}
export default injectIntl(DomainList)
