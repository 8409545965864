import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { getAPI } from '../../Base/API';
import { StyledCard } from '../../Base/CommonUIComponents';
import { handleNotification } from '../../Base/Notification';

export default class WhatsAppConfig extends Component {

  constructor (props) {
      super(props);
      this.state = {
          block: true,
          error: null,
          whatsAppConfig: null
      };
  }

  componentDidMount() {
    this.getConfig();
  }

  getConfig = () => {
    getAPI(result => {
      const { data, error } = result;
      const errorMessage = [];

      if (error) {
          errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
          this.setState({ error: errorMessage, block: false });
          return;
      }
      if (data) {
          if (data.errors && data.errors.length > 0) {
            this.setState({ block: false }, () => handleNotification(data));
          }
          else if(data.response){
              this.setState({ whatsAppConfig: data.response[0], block: false });
          }
      }
      else {
          this.setState({ error: errorMessage, block: false });
      }
    }, `/api/gms/WhatsApp/v1/account`)
  }

  render() {
    const { block, error, whatsAppConfig } = this.state;

    return (
        <StyledCard block={block} error={error} >
            <Row>
                <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                  <h5>
                    <i className="fab fa-whatsapp mr-2"/>
                    <FormattedMessage id="NavMenu.WhatsAppConfig"/>
                  </h5>
                </Col>
                {whatsAppConfig ?
                  <Col className='text-right'>
                    <div>
                      <b>{whatsAppConfig.name}</b>
                    </div>
                    <div>
                      {whatsAppConfig.id}
                    </div>
                  </Col>
                :''}
            </Row>

            <Row className="mt-4">
                <Col>
                    <Card className="border-0 shadow py-3">
                        <CardBody className="text-center">
                            <Link to="/NumberManagement">
                              <b><FormattedMessage id="WhatsApp.NumberManagement"/></b>
                            </Link>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card className="border-0 shadow py-3">
                        <CardBody className="text-center">
                            <Link to="/TemplateManagement">
                              <b><FormattedMessage id="WhatsApp.TemplateManagement"/></b>
                            </Link>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card className="border-0 shadow py-3">
                        <CardBody className="text-center">
                            <Link to="/WhatsAppAnalytics">
                              <b><FormattedMessage id="WhatsApp.Analytics"/></b>
                            </Link>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <Card className="border-0 shadow py-3">
                        <CardBody className="text-center">
                            <Link to="/MediaManagement">
                                <b><FormattedMessage id="WhatsApp.MediaManagement" /></b>
                            </Link>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                </Col>
                <Col>
                </Col>
            </Row>
        </StyledCard>
    )
  }
}
