import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import Authorization, { CheckAuthorization } from '../Base/Authorization';
import SalesMarketingDetails from './SalesMarketingDetails';
import { ProfileHistory } from '../Base/HistoryComponents';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import { DefaultPage } from '../Base/CommonUIComponents';

export class ProfileSalesMarketing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            modal: false,
            salesMarketingModal: false,
            canCreate: false,
            deleteModal: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.profile !== nextProps.profile) {
            this.canCreateSalesMarketing(nextProps);
        }
    }

    canCreateSalesMarketing = (props) => {
        let canCreate = false;
        let hotelList = this.props.hotels ? JSON.parse(JSON.stringify(this.props.hotels)) : [];

        if (global.toMasterProperties?.some(el => el === 'Marketing')) {
            canCreate = !props.profile.salesMarketing || props.profile.salesMarketing.length === 0;
        }
        else {
            if (props.profile.isMaster) {
                hotelList.forEach(hotel => {
                    if (props.profile.salesMarketing?.some(el => el.hotelId === hotel.value)) {
                        hotel.isDisabled = true;
                    }
                    else {
                        hotel.isDisabled = false;
                    }
                })

                canCreate = hotelList.some(h => !h.isDisabled);
            }
            else {
                canCreate = !props.profile.salesMarketing || props.profile.salesMarketing.length === 0;
            }
        }

        this.setState({ canCreate, hotels: hotelList });
    }

    toggleModal = (obj) => {
        this.setState(prevState => ({
            salesMarketingModal: !prevState.salesMarketingModal,
            selectedSaleMarketing: obj
        }))
    }

    toggleDeleteModal = (selectedSales) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
            selectedSales
        }));
    }

    removeWarning = () => {
        this.props.removeElement(this.props.profile.id, this.state.selectedSales.id, 'salesMarketing', 'salesMarketing');

        this.toggleDeleteModal();
    }

    render() {
        const { profile, profileId, removeElement, updateTable, canEditProfile } = this.props;
        const { deleteModal } = this.state;

        const hasMarketingToMaster = global.toMasterProperties?.some(el => el === 'Marketing');
        
        return (
            <div className="m-2 p-2">
                <Row className="mb-2">
                    <Col>
                        <h5><i className="icon-icon-sales-marketing mr-1"></i> <FormattedMessage id="ProfileDetails.ProfileSaleMarketing" /></h5>
                    </Col>
                    <Col className="text-right">
                        <ProfileHistory
                            component={"salesmarketing"}
                            profileId={this.props.profileId}
                        />

                        <Authorization
                            perform="profileSalesMarketing:add"
                            yes={() => canEditProfile && this.state.canCreate &&
                                <Button className="btn-sm btn-host ml-2" onClick={() => this.toggleModal('salesMarketingModal', 'selectedSaleMarketing', null)}>
                                    <i className="fas fa-plus" />
                                </Button>
                            }
                            no={() => <div></div>}
                        />
                    </Col>
                </Row>

                {profile.salesMarketing?.map((sm, i) => {
                    const hotel = this.state.hotels?.find(el => el.value === sm.hotelId);

                    return <Card className="border-0 mb-2 shadow" key={i}>
                        <CardBody className="py-2">
                            {!hasMarketingToMaster ?
                                <>
                                    <Row>
                                        <Col>
                                            <i className="icon-icon-hotel" /> {hotel?.label || sm.hotelId}
                                        </Col>
                                        <Authorization
                                            perform="profileSalesMarketing:delete"
                                            yes={() => canEditProfile && (
                                                <Col className="text-right col-1">
                                                    <span onClick={() => this.toggleDeleteModal(sm)} style={{ cursor: 'pointer' }}>
                                                        <i className="fas fa-trash-alt" />
                                                    </span>
                                                </Col>
                                            )}
                                            no={() => <div></div>}
                                        />
                                    </Row>
                                    <hr className="mt-1" />
                                </>
                            : ''}
                            <Row style={{ cursor: 'pointer' }} onClick={() => { if (canEditProfile && CheckAuthorization("profileSalesMarketing:add", null)) this.toggleModal(sm) }}>
                                <Col className="col-2" id={`Segment-${sm.id}`}><i className="fas fa-marker mr-2"></i>{sm.segment}</Col>
                                <Col>
                                    <Row>
                                        <Col id={`SubSegment-${sm.id}`}> <b><FormattedMessage id="ProfileDetails.SubSegment" />: </b> {sm.subSegment} </Col>
                                        {hasMarketingToMaster &&
                                            <Authorization
                                                perform="profileSalesMarketing:delete"
                                                yes={() => (
                                                    <Col className="text-right col-1">
                                                        <span onClick={() => removeElement(profile.id, sm.id, 'salesMarketing', 'salesMarketing')} style={{ cursor: 'pointer' }}>
                                                            <i className="fas fa-trash-alt" />
                                                        </span>
                                                    </Col>
                                                )}
                                                no={() => <div></div>}
                                            />
                                        }
                                    </Row>
                                    <Row>
                                        <Col id={`DistributionChannel-${sm.id}`}><b><FormattedMessage id="ProfileDetails.DistributionChannel" />: </b> {sm.distributionChannel} </Col>
                                        <Col id={`VipCode-${sm.id}`}><b><FormattedMessage id="ProfileDetails.VipCode" />: </b> {sm.vipCode} </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                })}

                {profile.salesMarketing == null || profile.salesMarketing.length == 0 ?
                    <DefaultPage text="ProfileDetails.NoSalesMarketingAvailable" icon="icon-icon-sales-marketing" />
                : ''}


                {this.state.salesMarketingModal ?
                    <SalesMarketingDetails
                        modal={this.state.salesMarketingModal}
                        toggleModal={this.toggleModal}
                        saleMarketing={this.state.selectedSaleMarketing}
                        profileId={profileId}
                        updateTable={updateTable}
                        hotels={this.state.hotels}
                        isMaster={profile.isMaster}
                        hasMarketingToMaster={hasMarketingToMaster}
                    />
                : ''}

                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={this.toggleDeleteModal}
                        actionFunction={this.removeWarning}
                        text={<FormattedMessage id="ProfileSalesMarketing.ConfirmDeleteText" />}
                    />
                : ''}
            </div>
        );
    }
}

export default injectIntl(ProfileSalesMarketing);
