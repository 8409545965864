import React, { Component, useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Col, Row, Collapse } from "reactstrap";
import { StyledCard, DefaultPage } from "../../../Base/CommonUIComponents";
import { getProfileDataQualityMetrics } from "../../../Base/ReferenceDataFunctions";
import { CollapseTitle, CompareRow, PercentageBar } from "./MonthlyReportComponents";
import { handleNotification } from '../../../Base/Notification';
import { getAPI } from "../../../Base/API";
import moment from 'moment';



class MonthlyReportComparison extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            hotelOptions: [],
            ruleOptions: []
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true;

        this.getReportRuns();
    }

    getReportRuns = () => {
        let params = new URLSearchParams(window.location.search);
        let reports = JSON.parse(params.get('reports'));

        const hotelOptions = [];
        const ruleOptions = [];


        if (reports) {
            this.setState({ block: true });

            getAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    data.response && data.response.forEach(report => {
                        const result = JSON.parse(report.result);
                        report.result = result;

                        result.RulesApplied && result.RulesApplied.map(rule => {
                            if (!ruleOptions.some(el => el.value === rule.RuleName)) {
                                ruleOptions.push({ value: rule.RuleName, label: rule.RuleName });
                            }   

                            rule.Results && rule.Results.map(ruleRes => {
                                if (!hotelOptions.some(el => el.value === ruleRes.HotelName)) {
                                    hotelOptions.push({ value: ruleRes.HotelName, label: ruleRes.HotelName });
                                }   
                            })
                        });
                    })

                    this.setState({
                        runs: data.response, hotelOptions, ruleOptions, totalItems: data.totalItems,
                        block: false, errorMessage: []
                    });
                }
            }, `/api/gms/DataQuality/v1/DataQualityReportRuns/compare?runIds=${reports[0]}&runIds=${reports[1]}`);
        }
    }

    render() {
        const { runs } = this.state;

        return (
            <StyledCard block={this.state.block} error={this.state.error} icon="fas fa-exchange-alt" title={"MonthlyReportComparison.Title"}>
                {runs?.length === 2 ?
                    <div className="mx-5 pb-5">
                        <CompareRow
                            comparison={<h2 className="color-host">VS</h2>}
                            rowStyle={{ minHeight: '100px' }}
                            reportStyle={{ fontSize: '16px', justifyContent: 'center' }}
                            firstReport={
                                <b className="text-center">
                                    <div><FormattedMessage id="MonthlyReportComparison.Report" /></div>
                                    {moment(runs[0].beginDate).format("MMMM YYYY")}
                                </b>
                            }
                            secReport={
                                <b className="text-center">
                                    <div><FormattedMessage id="MonthlyReportComparison.Report" /></div>
                                    {moment(runs[1].beginDate).format("MMMM YYYY")}
                                </b>
                            }
                        />


                        <ProfileComparison runs={runs} intl={this.props.intl} />

                        <ProfileSummaryComparison runs={runs} intl={this.props.intl} />

                        <ProfileCountComparison runs={runs} intl={this.props.intl} />

                        <RulesSummaryComparison runs={runs} intl={this.props.intl} />

                        <RulesComparison
                            runs={runs}
                            intl={this.props.intl}
                            hotelOptions={this.state.hotelOptions}
                            ruleOptions={this.state.ruleOptions}
                        />
                    </div>
                    :
                    <DefaultPage text="MonthlyReportComparison.Select2Reports" icon="fas fa-exchange-alt" />
                }
            </StyledCard>
        );
    }
}

export default injectIntl(MonthlyReportComparison);





//#region Page Components
export const ProfileComparison = ({ runs, intl }) => {
    const [isOpen, setIsOpen] = useState(true);

    const fields = [
        { label: 'Linked', icon: 'fas fa-random', value: "LinkedUsers" },
        { label: 'Masters', icon: 'icon-icon-profile', value: "MasterUsers" },
        { label: intl.formatMessage({ id: "generic.Duplicates" }), icon: 'far fa-clone', value: "Duplicates" }
    ]

    return <>
        <CollapseTitle title={intl.formatMessage({ id: "generic.Profile" })} toggleCollapse={() => setIsOpen(!isOpen)} isOpen={isOpen} />

        <Collapse isOpen={isOpen}>
            {fields.map((field, index) => {
                const difference = runs[1] && runs[0] ? runs[1].result[field.value] - runs[0].result[field.value]: null;

                return <CompareRow
                    key={`profile-${index}`}
                    rowStyle={{ minHeight: '60px', backgroundColor: (index % 2 === 0 ? 'white' : '') }}
                    reportStyle={{ fontSize: '16px', justifyContent: 'center' }}

                    firstReport={runs[0].result[field.value]}
                    comparison={
                        <div className="text-center">
                            <div><i className={field.icon} /></div>
                            <b>{field.label}</b>
                        </div>
                    }
                    secReport={
                        <div className="d-flex align-items-baseline">
                            <span>{runs[1].result[field.value]}</span>
                            <b className={"ml-2 " + (difference > 0 ? ' text-success ' : ' text-danger ')} style={{ fontSize: '11px' }}>{difference ? difference > 0 ? `+${difference}` : difference : ''}</b>
                        </div>
                    }
                />
            })}
        </Collapse>
    </>
}


export const ProfileSummaryComparison = ({ runs, intl }) => {
    const [isOpen, setIsOpen] = useState(true);

    const metrics = getProfileDataQualityMetrics();

    const renderCharts = (chart1, chart2) => {
        const filledIcon = chart2 && chart1 ? chart1.filled > chart2.filled ? <i className="text-success fas fa-long-arrow-alt-up fa-lg" /> : chart1.filled < chart2.filled ? <i className="text-danger fas fa-long-arrow-alt-down fa-lg" /> : <i className="fas fa-equals fa-xs" /> : '';
        const validIcon = chart2 && chart1 ? chart1.valid > chart2.valid ? <i className="text-success fas fa-long-arrow-alt-up fa-lg" /> : chart1.valid < chart2.valid ? <i className="text-danger fas fa-long-arrow-alt-down fa-lg" /> : <i className="fas fa-equals fa-xs" /> : '';

        const filledDifference = chart1?.filledPerc ? parseFloat(Math.abs(chart2?.filledPerc - chart1?.filledPerc))?.toFixed(1) : 0;
        const validDifference = chart1?.validPerc ? parseFloat(Math.abs(chart2?.validPerc - chart1?.validPerc))?.toFixed(1) : 0;

        if (chart1) {
            return <div className="w-100 py-4">
                <PercentageBar
                    reverse={true}
                    title={intl.formatMessage({ id: "MonthlyReportComparison.Filled" })}
                    difference={{ icon: filledIcon, percentage: filledDifference } }
                    left={{ percentage: chart1.filledPerc, value: chart1.filled, bgColor: "#56CFE1" }}
                    right={{ value: chart1.unfilled, bgColor: "#EDEDED" }}
                />

                <div className="mt-3">
                    <PercentageBar
                        title={intl.formatMessage({ id: "MonthlyReportComparison.Valid" })}
                        difference={{ icon: validIcon, percentage: validDifference }}
                        left={{ percentage: chart1.validPerc, value: chart1.valid, bgColor: "#6EDE8A" }}
                        right={{ value: chart1.invalid, bgColor: "#EDEDED" }} 
                    />
                </div>
            </div>
        }
        else return <FormattedMessage id="MonthlyReportComparison.NoDataToCompare"/>;
    }

    const getChartData = (metric, result) => {
        const metricData = result?.QualityCriteria.find(qc => qc.Criteria === metric) || result?.SecQualityCriteria.find(qc => qc.Criteria === metric);

        if (metricData) {
            const total1 = metricData.Filled + metricData.Unfilled;
            const total2 = metricData.Valid + metricData.Invalid;

            return {
                filled: metricData.Filled,
                filledPerc: metricData.Filled && total1 ? parseFloat(metricData.Filled * 100 / total1).toFixed(1) : 0,
                unfilled: metricData.Unfilled,
                //unfilledPerc: metricData.Unfilled && total1 ? parseFloat(metricData.Unfilled * 100 / total1).toFixed(1) : 0,
                valid: metricData.Valid,
                validPerc: metricData.Valid && total2 ? parseFloat(metricData.Valid * 100 / total2).toFixed(1) : 0,
                invalid: metricData.Invalid,
                //invalidPerc: metricData.Invalid && total2 ? parseFloat(metricData.Invalid * 100 / total2).toFixed(1) : 0
            }
        }

        return null;
    }

    let indexAux = 1;

    return <>
        <CollapseTitle title={intl.formatMessage({ id: "MonthlyReportComparison.ProfileSummary" })} toggleCollapse={() => setIsOpen(!isOpen)} isOpen={isOpen} />

        <Collapse isOpen={isOpen}>
            {metrics.map((metric, index) => {
                const firstChart = getChartData(metric.value, runs[0].result);
                const secChart = getChartData(metric.value, runs[1].result);

                const showMetric = firstChart || secChart;

                if (showMetric) indexAux++;

                return showMetric && <CompareRow
                    key={`profile-${index}`}
                    rowStyle={{ minHeight: '100px', backgroundColor: (indexAux % 2 === 0 ? 'white' : '') }}
                    reportStyle={{ fontSize: '14px', justifyContent: 'center' }}

                    firstReport={renderCharts(firstChart)}
                    secReport={renderCharts(secChart, firstChart)}
                    comparison={
                        <div className="text-center">
                            <div><i className={metric.icon} /></div>
                            <b>{metric.label}</b>
                        </div>
                    }
                />
            })}
        </Collapse>
    </>
}


export const ProfileCountComparison = ({ runs, intl }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [report1, setReport1] = useState({});
    const [report2, setReport2] = useState({});
    const [hotels, setHotels] = useState(null);
    const [showHeader, setShowHeader] = useState(false);


    useEffect(() => {
        var hotels = [];

        if (runs) {
            if (runs[0]) {
                const report = runs[0].result.ProfilesCount;

                if (report?.ProfilesCountByHotel?.length > 0) {
                    report.ProfilesCountByHotel.forEach(pc => {
                        if (!hotels.some(hotel => hotel === pc.HotelName)) {
                            hotels.push(pc.HotelName)
                        }
                    })
                }

                setReport1(report);
            }

            if (runs[1]) {
                const report = runs[1].result.ProfilesCount;

                if (report?.ProfilesCountByHotel?.length > 0) {
                    report.ProfilesCountByHotel.forEach(pc => {
                        if (!hotels.some(hotel => hotel === pc.HotelName)) {
                            hotels.push(pc.HotelName)
                        }
                    })
                }

                setReport2(report);
            }

            setShowHeader(runs[0].result.HasProfilesCount || runs[1].result.HasProfilesCount);
        }

        setHotels(hotels);
    }, [runs]);

    const renderHeader = (showHeader) => {
        if (showHeader) {
            return <>
                <Row className="text-muted w-75">
                    <Col className="text-ellipsis text-center px-1"> #<FormattedMessage id="MonthlyReportComparison.CreatedProfiles" /> </Col>
                    <Col className="text-ellipsis text-center px-1"> #<FormattedMessage id="MonthlyReportComparison.UpdatedProfiles" /> </Col>
                </Row>
            </>
        }
        else {
            return <div style={{ minHeight: '100px', fontSize: '14px' }} className="d-flex align-items-center"><FormattedMessage id="MonthlyReportComparison.NoDataToCompare" /></div>
        }
    }

    const renderProfilesCount = (report, reportToCompare) => {
        return <>
            <Row className="w-75">
                <Col className="text-ellipsis text-center px-1"> {report ? report.CreatedProfiles : '-'} {reportToCompare && getIcon(report?.CreatedProfiles, reportToCompare.CreatedProfiles)} </Col>
                <Col className="text-ellipsis text-center px-1"> {report ? report.UpdatedProfiles : '-'} {reportToCompare && getIcon(report?.UpdatedProfiles, reportToCompare.UpdatedProfiles)} </Col>
            </Row>
        </>
    }

    const renderFooter = (totalCreated, totalUpdated, totalCreatedToCompare, totalUpdatedToCompare) => {
        return <>
            <Row className="w-75">
                <Col className="text-ellipsis text-center px-1"><b className="text-muted"> {totalCreated} </b> {totalCreatedToCompare && getIcon(totalCreated, totalCreatedToCompare)} </Col>
                <Col className="text-ellipsis text-center px-1"> <b className="text-muted"> {totalUpdated}</b> {totalUpdatedToCompare && getIcon(totalUpdated, totalUpdatedToCompare)} </Col>
            </Row>
        </>
    }


    const getIcon = (value, value2) => {
        if (value !== null && value2 !== null) {
            if (value2 < value) {
                return <i className={`text-success fas fa-long-arrow-alt-up fa- pl-2`} />;
            }
            else if (value2 > value) {
                return <i className={`text-danger fas fa-long-arrow-alt-down fa- pl-2`} />;
            }
        }
    }

    return <>
        <CollapseTitle title={intl.formatMessage({ id: "MonthlyReportComparison.ProfilesCount" })} toggleCollapse={() => setIsOpen(!isOpen)} isOpen={isOpen} />

        <Collapse isOpen={isOpen}>
            <CompareRow
                rowStyle={{ minHeight: '40px', backgroundColor: 'white', alignItems: 'center', fontSize: '12px' }}
                reportStyle={{ justifyContent: 'center' }}
                firstReport={renderHeader(showHeader)}
                comparison={showHeader ? <span className="text-muted"> <FormattedMessage id="generic.Hotel" /> </span> : ''}
                secReport={renderHeader(showHeader)}
            />

            {hotels && hotels.map((hotel, index) => {
                const firstReport = report1 && report1.ProfilesCountByHotel && report1.ProfilesCountByHotel.find(pc => pc.HotelName === hotel);
                const secReport = report2 && report2.ProfilesCountByHotel && report2.ProfilesCountByHotel.find(pc => pc.HotelName === hotel);

                return <CompareRow
                    rowStyle={{ minHeight: '40px', backgroundColor: (index % 2 === 0 ? '' : 'white') }}
                    reportStyle={{ fontSize: '16px', justifyContent: 'center' }}
                    
                    firstReport={renderProfilesCount(firstReport, null)}
                    comparison={<b>{hotel}</b>}
                    secReport={renderProfilesCount(secReport, firstReport)}
                />
            })}
            {hotels?.length > 0 &&
                <CompareRow
                    rowStyle={{ minHeight: '40px', backgroundColor: (hotels.length % 2 === 0 ? '' : 'white'), alignItems: 'center' }}
                    reportStyle={{ justifyContent: 'center' }}
                    firstReport={renderFooter((report1 ? report1.TotalCreated : '-'), (report1 ? report1.TotalUpdated : '-'))}
                    comparison={<b className="text-muted"><FormattedMessage id="generic.Total" /></b>}
                    secReport={renderFooter(report2.TotalCreated, report2.TotalUpdated, report1?.TotalCreated, report1?.TotalUpdated)}
                />
            }
        </Collapse>
    </>
}


export const RulesSummaryComparison = ({ runs, intl }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [report1, setReport1] = useState(true);
    const [report2, setReport2] = useState(true);

    useEffect(() => {
        const reports = [{ rules: runs[0].result.RulesApplied, function: setReport1 }, { rules: runs[1].result.RulesApplied, function: setReport2 }]

        reports.forEach(report => {
            const obj = {
                rules: report.rules.length,
                issues: 0,
                autoResolved: 0, manualResolved: 0, unresolved: 0
            }

            report.rules.forEach(rule => {
                rule.Results.forEach(result => {
                    obj.issues += result.TotalResults;
                    obj.autoResolved += result.TotalAutomaticResolved;
                    obj.manualResolved += result.TotalManualResolved;
                    obj.unresolved += result.TotalOthers;
                })
            })

            report.function(obj);
        })

    }, [runs]);


    const renderGlobalData = (value, valueToCompare) => {
        const difference = value - valueToCompare;

        return <div className="w-100 px-4 py-2">
            <Row className="text-center">
                <Col> {value} <b className={"ml-2 " + (difference > 0 ? ' text-danger ' : ' text-success ')} style={{ fontSize: '11px' }}>{difference ? difference > 0 ? `+${difference}` : difference : ''}</b></Col>
            </Row>
        </div>        
    }

    const renderResolvedChart = (report, reportToCompare) => {
        const total = report.manualResolved + report.autoResolved;
        const autoResolvedPerc = total ? parseFloat(report.autoResolved * 100 / total).toFixed(1) : 0;

        const difference = { percentage: 0 };

        if (reportToCompare) {
            const totalToCompare = reportToCompare.manualResolved + reportToCompare.autoResolved;

            if (total > totalToCompare) {
                difference.icon = <i className="text-success fas fa-long-arrow-alt-up fa-lg" />
            }
            else if (total < totalToCompare) {
                difference.icon = <i className="text-danger fas fa-long-arrow-alt-down fa-lg" />
            }
        }

        return <div className="w-100 py-2">
            <PercentageBar
                difference={difference }
                showRightPerc={true}
                rowClass="align-items-center"
                left={{ percentage: autoResolvedPerc, value: report.autoResolved, bgColor: "#56CFE1", additionalText: "Auto" }}
                right={{ value: report.manualResolved, bgColor: "#6EDE8A", additionalText: "Manual" }}
            />
        </div>
    }

    const fields = [
        { label: intl.formatMessage({ id: "MonthlyReportComparison.Rules" }), icon: 'fas fa-list-ol', value: "rules" },
        { label: intl.formatMessage({ id: "MonthlyReportComparison.Issues" }), icon: 'fas fa-bug', value: "issues" },
        { label: intl.formatMessage({ id: "MonthlyReportComparison.Resolved" }), icon: 'fas fa-check-circle', value: "resolved", fontSize: '14px' },
        { label: intl.formatMessage({ id: "MonthlyReportComparison.Unresolved" }), icon: 'fas fa-times-circle', value: "unresolved" }
    ]

    return <>
        <CollapseTitle title={intl.formatMessage({ id: "MonthlyReportComparison.RulesSummary" })} toggleCollapse={() => setIsOpen(!isOpen)} isOpen={isOpen} />

        <Collapse isOpen={isOpen}>
            {!report1 && !report2 ?
                <span> <FormattedMessage id="MonthlyReportComparison.NoRulesApplied" /> </span>
            :
            fields.map((field, index) => {
                return <CompareRow
                    key={`profile-${index}`}
                    rowStyle={{ minHeight: '60px', backgroundColor: (index % 2 === 0 ? 'white' : '') }}
                    reportStyle={{ fontSize: field.fontSize || '16px', justifyContent: 'center' }}

                    firstReport={field.value === "resolved" ? renderResolvedChart(report1) : renderGlobalData(report1[field.value])}
                    comparison={
                        <div className="text-center">
                            <div><i className={field.icon} /></div>
                            <b>{field.label}</b>
                        </div>
                    }
                    secReport={field.value === "resolved" ? renderResolvedChart(report2, report1) : renderGlobalData(report2[field.value], report1[field.value])}
                />
            })}
        </Collapse>
    </>
}


export const RulesComparison = ({ runs, intl, hotelOptions, ruleOptions }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [rules, setRules] = useState([]);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [selectedStatus, setStatus] = useState(['issues', 'autoResolved', 'manualResolved', 'others']);

    useEffect(() => {
        const rulesList = [];
        const reportRules = [runs[0].result.RulesApplied, runs[1].result.RulesApplied];

        reportRules.forEach((rules, idx) => {
            const position = idx ? 'secondReport' : 'firstReport';

            rules.forEach(rule => {
                const index = rulesList.findIndex(el => el.name === rule.RuleName);

                if (index !== -1) {
                    rule.Results.forEach((result, idc) => {
                        const hotelIdx = rulesList[index].hotels.findIndex(el => el.hotel === result.HotelName);
                        
                        if (hotelIdx !== -1) {
                            rulesList[index].hotels[hotelIdx][position] = result;
                        }
                        else {
                            rulesList[index].hotels.push({
                                hotel: result.HotelName,
                                [position]: result
                            });
                        }
                    })
                }
                else {
                    rulesList.push({
                        name: rule.RuleName,
                        hotels: rule.Results.map(el => {
                            return {
                                hotel: el.HotelName,
                                [position]: el 
                            }
                        })
                    })
                }
            })
        })

        setRules(rulesList);

    }, [runs]);

    const getIcon = (value, value2, invertedColors) => {
        if (value !== null && value2 !== null) {
            if (value2 < value) {
                return <i className={`${invertedColors ? "text-danger" :"text-success"} fas fa-long-arrow-alt-up fa- pl-2`} />;
            }
            else if (value2 > value){
                return <i className={`${invertedColors ? "text-success" : "text-danger"} fas fa-long-arrow-alt-down fa- pl-2`} />;
            }
        }
    }

    const getPercentage = (value, totalResults) => {
        if (value) {
            return `(${parseFloat((value * 100) / totalResults).toFixed(1).replace(/[.,]0$/, "")}%)` ;
        }

        return '';
    }


    const renderRules = (hotels, name, compareName) => {
        const columns = [ 
            //{ value: "TotalResults", invertedColors: true },
            { value: "TotalAutomaticResolved", isVisible: selectedStatus.some(value => value === 'autoResolved') },
            { value: "TotalManualResolved", isVisible: selectedStatus.some(value => value === 'manualResolved') },
            { value: "TotalOthers", invertedColors: true, isVisible: selectedStatus.some(value => value === 'others') },
        ]

        return hotels?.map((hotel, idx) => {
            const totalResults = hotel[name] && hotel[name].TotalResults;

            return hotel.hidden !== true && <Row key={idx} className={"w-75 py-2 my-1 align-items-center "}>
                {selectedStatus.some(value => value === 'issues') &&
                    <Col className="px-1 d-flex align-items-baseline" key={idx}>
                        {totalResults ? totalResults : '-'} {hotel[name] && hotel[compareName] ? getIcon(totalResults, hotel[compareName].TotalResults, true) : ''}
                    </Col>
                }
                {columns.map((column, idx) => column.isVisible &&
                    <Col className="px-1 d-flex align-items-baseline" key={idx}>
                        {hotel[name] ? <span>{hotel[name][column.value]} <span className='title-sm'>{getPercentage(hotel[name][column.value], totalResults)}</span></span> : '-'} {hotel[name] && hotel[compareName] ? getIcon(hotel[name][column.value], hotel[compareName][column.value], column.invertedColors) : ''}
                    </Col>
                )}
            </Row>
        })
    }


    const renderHeader = () => {
        return <Row className="text-muted w-75">
            {selectedStatus?.some(value => value === 'issues') && <Col className="text-ellipsis px-1"> #<FormattedMessage id="MonthlyReportComparison.Issues" /> </Col>}
            {selectedStatus?.some(value => value === 'autoResolved') && <Col className="text-ellipsis px-1"> #<FormattedMessage id="MonthlyReportComparison.Auto" /> </Col>}
            {selectedStatus?.some(value => value === 'manualResolved') && <Col className="text-ellipsis px-1"> #<FormattedMessage id="MonthlyReportComparison.Manual" /> </Col>}
            {selectedStatus?.some(value => value === 'others') && <Col className="text-ellipsis px-1"> #<FormattedMessage id="MonthlyReportComparison.Unresolved" /> </Col>}
        </Row>
    }

    const toggleFilterOptions = () => {
        setIsFilterOpen(!isFilterOpen);
    }

    const applyHotelFilter = (combo) => {
        const selectedHotel = combo ? combo.value : null ;
        const rulesFiltered = [...rules];

        rulesFiltered.forEach(rule => {
            rule.hotels.forEach(h => {
                h.hidden = selectedHotel ? selectedHotel !== h.hotel : false
            })
        })

        setRules(rulesFiltered);
    }

    const applyRuleFilter = (selectedRules) => {
        const rulesFiltered = [...rules];

        rulesFiltered.forEach(rule => {
            rule.hidden = selectedRules ? !selectedRules.some(sr => sr.value === rule.name) : false;
        })

        setRules(rulesFiltered);
    }


    const statusOptions = [
        { value: 'issues', label: <FormattedMessage id="MonthlyReportComparison.Issues" /> },
        { value: 'autoResolved', label: <FormattedMessage id="MonthlyReportComparison.Auto" /> },
        { value: 'manualResolved', label: <FormattedMessage id="MonthlyReportComparison.Manual" /> },
        { value: 'others', label: <FormattedMessage id="MonthlyReportComparison.Unresolved" /> },
    ]


    return <>
        <CollapseTitle
            title={intl.formatMessage({ id: "MonthlyReportComparison.Rules" })}
            toggleCollapse={() => setIsOpen(!isOpen)}
            isOpen={isOpen}
            filterOptions={{
                ruleOptions, applyRuleFilter,
                hotelOptions, applyHotelFilter: applyHotelFilter,
                isFilterOpen, toggleCollapse: toggleFilterOptions,
                statusOptions, applyStatusFilter: (combo) => setStatus(combo ? combo.map(el => el.value) : []), selectedStatus
            }}
        />

        <Collapse isOpen={isOpen}>
            <CompareRow
                rowStyle={{ minHeight: '40px', backgroundColor: 'white', alignItems: 'center', fontSize: '12px'}}
                reportStyle={{ justifyContent: 'center' }}
                column={<span className="text-muted"> <FormattedMessage id="generic.Hotel" /> </span>}
                firstReport={renderHeader()}
                comparison={<span className="text-muted"> <FormattedMessage id="MonthlyReportComparison.Rules" /> </span> }
                secReport={renderHeader()}
            />


            {rules?.map((rule, index) => {
                return rule.hidden !== true && <CompareRow
                    key={`profile-${index}`}
                    rowStyle={{ minHeight: '60px', alignContent: "space-around", backgroundColor: (index % 2 === 0 ? '' : 'white') }}
                    reportStyle={{ flexDirection: 'column', justifyContent: 'space-around' }}
                    column={rule.hotels.map((el, idx) => el.hidden !== true &&
                        <Row className="w-100 py-2 my-1 text-ellipsis" key={idx}>
                            <Col> {el.hotel}</Col>
                        </Row>
                    )}
                    firstReport={renderRules(rule.hotels, 'firstReport', null)}
                    comparison={
                        <div className="text-center">
                            <b>{rule.name}</b>
                        </div>
                    }
                    secReport={renderRules(rule.hotels, 'secondReport', 'firstReport')}
                />
            })}
        </Collapse>
    </>
}
//#endregion
