import React, { Component } from 'react'
import { injectIntl } from 'react-intl';
import { handleNotification } from '../../../Base/Notification';
import { getMultiPartAPI } from '../../../Base/API';
import BlockUi from 'react-block-ui';
import mediaNotFound from '../../../../img/MediaNotFound.png';
import { Col, Row } from 'reactstrap';

class MediaPreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            media: this.props.media || {},
            block: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if ((this.state.media.preview !== nextProps.media.preview && nextProps.media.preview) || this.state.media.notFound !== nextProps.media.notFound) {
            this.setState({
                media: nextProps.media
            })
        }
        else if (this.state.media.id !== nextProps.media.id) {
            this.setState({
                media: nextProps.media
            }, () => {
                this.updateMedia(nextProps.loadImage, nextProps.media)
            });
        }
    }

    componentDidMount() {
        this.updateMedia(this.props.loadImage, this.state.media);
    }

    updateMedia = (loadImage, media) => {
        if (!media.preview && loadImage && this.state.media?.type?.toLowerCase().startsWith('image') && !media.notFound) {
            if (this.props.getMediaDetails) {
                this.props.getMediaDetails();
            }
            else {
                this.getMediaDetails();
            }
        }
    }


    getMediaDetails = (download) => {
        this.setState({ block: true });

        getMultiPartAPI(result => {
            const { data, error } = result;

            if (error) {
                this.setState({
                    media: {
                        ...this.state.media,
                        notFound: true
                    },
                    block: false
                });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({
                        media: {
                            ...this.state.media,
                            notFound: true
                        },
                        block: false
                    });
                }
                else {
                    const reader = new FileReader();
                    reader.readAsDataURL(data);

                    reader.onloadend = (e) => {
                        if (download) {
                            this.downloadFile(reader.result)
                        }

                        this.setState({
                            media: {
                                ...this.state.media,
                                preview: reader.result
                            },
                            block: false
                        })
                    }
                }
            }
            else {
                this.setState({ block: false });
            }
        }, `api/gms/WhatsApp/v1/${this.state.media.id}/media`);
    }

    downloadFile = (file) => {
        const downloadLink = document.createElement("a");

        downloadLink.href = file;
        downloadLink.download = this.state.media.fileName;
        downloadLink.click();
    }


    render() {
        const { getMediaDetails, newMedia, maxHeight, fromWhatsAppChat, borderRadius = '', minWidth, loadMediaHeight, loadImage } = this.props;
        const { /*media,*/ block } = this.state;

        const media = this.props.media || this.state.media

        return (
            media ?
                media.notFound ?
                    <div className="text-center" style={fromWhatsAppChat ? { height: '100px', width: '300px', backgroundColor: '#e9e9e9', maxWidth: '100%' } : { height: '50px', backgroundColor: '#e9e9e9' }}>
                        <img className="mb-1 mt-2" src={mediaNotFound} style={{ maxHeight: '65%', maxWidth: '100%' }} />
                        <div style={fromWhatsAppChat ? { fontSize: '11px' } : {}}> Media Not Found </div>
                    </div>
                    :
                    <BlockUi tag="div" blocking={block || this.props.block} className={'h-100 w-100 d-flex mb-2 align-items-center justify-content-center  ' + (newMedia ? '' : 'pointer')} style={{ borderRadius: borderRadius, overflow: 'hidden' }}>
                        {this.props.isHeaderHandle ?
                            <embed
                                src={media.preview}
                                style={{ width: '100%', height: '100%', maxHeight }}
                            />
                        :
                    
                        media.type?.toLowerCase().startsWith('image') ?
                            media.preview ?
                                <img src={media.preview} style={{ height: 'auto', maxWidth: '100%', maxHeight: (maxHeight || '100%') }} className="pt-1" />
                                :
                                <div onClick={getMediaDetails} className="d-flex align-items-center justify-content-center w-100" style={{ background: 'rgb(204, 208, 213)', fontSize: '3em', minWidth, height: loadMediaHeight || '100%' }}>
                                    <i className="far fa-image color-white" />
                                </div>


                            : media.type?.toLowerCase().startsWith('video') ?
                                media.preview ?
                                    <video controls style={{ width: '100%', maxHeight: (maxHeight || '100%') }}>
                                        <source type={media.type} src={media.preview} />
                                    </video>
                                    :
                                    <div onClick={getMediaDetails} className="d-flex align-items-center justify-content-center w-100 pointer" style={{ background: 'rgb(204, 208, 213)', fontSize: '3em', minHeight: (fromWhatsAppChat ? '100px' : ''), minWidth, height: loadMediaHeight || '100%' }}>
                                        <i className="fas fa-video color-white" />
                                    </div>


                                : media.type?.toLowerCase().startsWith('audio') ?
                                    media.preview ?
                                        <audio controls src={media.preview} style={{ maxWidth: '100%' }} />
                                        :
                                        <div onClick={getMediaDetails} className="d-flex align-items-center justify-content-center w-100 pointer" style={{ background: 'rgb(204, 208, 213)', fontSize: '3em', minHeight: (fromWhatsAppChat ? '100px' : ''), minWidth, height: loadMediaHeight || '100%' }}>
                                            <i className="fas fa-microphone color-white" />
                                        </div>


                                    : media.preview || fromWhatsAppChat ?
                                        <DownloadFile media={media} getMediaDetails={() => media.preview ? this.downloadFile(media.preview) : fromWhatsAppChat && this.getMediaDetails(true) }/>
                                        :
                                        <div onClick={() => fromWhatsAppChat && this.getMediaDetails(true)} className="d-flex align-items-center justify-content-center w-100 h-100 pointer" style={{ background: 'rgb(204, 208, 213)', fontSize: '3em' }} id="downloadFile">
                                            <i className="fas fa-file-download color-white" />
                                        </div>
                        }
                </BlockUi>
            :''
        )
    }
}

export default injectIntl(MediaPreview);


export const DownloadFile = ({ media, getMediaDetails }) => {
    const fileIcons = [
        { type: 'application/pdf', icon: <i className="fas fa-lg fa-file-pdf color-red" /> },
        { type: 'application/vnd.ms-excel', icon: <i className="fas fa-lg fa-file-excel color-green" /> },
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', icon: <i className="fas fa-lg fa-file-excel color-green" /> },
        //{ type: 'application/vnd.\1',  icon: <i className="fas fa-lg fa-file-alt color-host" /> },
        { type: 'application/msword', icon: <i className="fas fa-lg fa-file-alt color-host" /> },
        { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', icon: <i className="fas fa-lg fa-file-alt color-host"/> },
        { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', icon: <i className="fas fa-lg fa-file-powerpoint color-yellow" /> },
    ];

    const fileType = fileIcons.find(el => el.type === media.type);

    return (
        <Row className="align-items-center w-100 m-0 py-3" style={{ backgroundColor: '#bdbdbd38', borderRadius: '5px', flexFlow: 'nowrap' }}>
            <Col sm={2}>
                {fileType ? fileType.icon : <i className="fas fa-lg fa-file-alt color-host" /> }
            </Col>
            <Col className="pl-0" style={{ fontSize: '11px', overflow: 'overlay' }}>
                {media.fileName}
            </Col>
            <Col className="col-3 text-right">
                <i className="fas fa-lg fa-arrow-alt-circle-down text-muted pointer" onClick={getMediaDetails}/>
            </Col>
        </Row>
    )
};