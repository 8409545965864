import React from 'react'
import { FormattedMessage } from 'react-intl';
import { Card, Col, Nav, NavItem, NavLink, Row, Collapse, Progress, UncontrolledTooltip, Badge } from 'reactstrap';
import BlockUi from 'react-block-ui';


export const SideBar = ({ activeTab, togglePage, pages, menuTitle }) => (
    <div className="side-menu">

        <Card  className="border-0 shadow mb-3 p-3">
            <Row>
                <Col className='pr-0 d-flex align-items-center'>
                    <div>
                        <h5 className='m-0'> {menuTitle} </h5>
                    </div>
                </Col>
            </Row>
        </Card>

        <Nav tabs vertical className='mt-4 border-0'>
            <Card className="bg-white p-2 border-0 mb-3 shadow">
                {pages.map((page, key) => {
                    const isSelected = activeTab === page.tag;

                    return <NavItem key={key} className="pb-2">
                        <NavLink className={"pointer border-0 " + (isSelected ? 'text-primary active' : 'bg-white')} onClick={_ => togglePage(page.tag)} >
                            {isSelected ?
                                <div style={{
                                    'left': '0',
                                    'position': 'absolute',
                                    'borderRadius': '0px 30px 30px 0px',
                                    'width': '7px',
                                    'height': '14px',
                                    'background': '#0667FF 0% 0% no-repeat padding-box',
                                    'opacity': '1',
                                    'marginTop': '4px'
                                }} />
                                : ''}
                            <div className='d-flex align-items-center'>
                                {page.icon ? <i className={page.icon} /> : ''} {page.name}
                            </div>
                        </NavLink>
                    </NavItem>
                })}
            </Card>
        </Nav>
    </div>
);


export const ComplexSideBar = ({ activeTab, toggleTab, toggleMenu, menus, menuTitle, activeMenu, userData, creditBalance, hideSubmenuIcon, block }) => (
    <div className="side-menu">
        <BlockUi tag="div" blocking={block}>
            <Card className="border-0 shadow mb-3 p-3">
                <div>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <h5 className='m-0 text-truncate' style={{maxWidth: '150px'}}>
                                {userData?.userName??menuTitle}
                            </h5>
                            {creditBalance && userData ?
                                <>
                                    <div className='mx-2' id="SendGridCreditBalence">
                                        <b>{creditBalance.used}</b>
                                        {creditBalance.total === 0 ?
                                            <span className='text-muted' style={{ fontSize: '0.8em' }}>/<i className="fas fa-infinity"></i></span>
                                        :
                                            <span className='text-muted' style={{ fontSize: '0.8em' }}>/{creditBalance.total}</span>
                                        }
                                    </div>
                                    <UncontrolledTooltip placement="right" target="SendGridCreditBalence" >
                                        <div>
                                            <FormattedMessage id="SendGrid.Used" />:
                                            <b className='ml-2'>{creditBalance.used}</b>
                                        </div>
                                        {creditBalance.total === 0 ?
                                            '' :
                                            <div>
                                                <FormattedMessage id="SendGrid.Total" />:
                                                <b className='ml-2'>{creditBalance.total}</b>
                                            </div>
                                        }
                                    </UncontrolledTooltip>
                                </>
                                : ''}
                        </div>
                        {userData?.reputation ?
                            <div className='ml-2' style={{ width: 'fit-content' }}>
                                <div className='text-muted d-flex align-items-center justify-content-between' style={{ fontSize: '0.8em', gap: '2rem' }}>
                                    <div>
                                        <FormattedMessage id="SendGrid.Reputation"/>
                                    </div>
                                    <div><b>{userData.reputation}%</b></div>
                                </div>
                                <div>
                                    <Progress
                                        max={100}
                                        color={userData.reputation > 50 ? userData.reputation > 80 ? 'success' : 'warning' : 'danger'}
                                        value={userData.reputation}
                                        style={{ height: '9px', borderRadius: '10px' }}
                                    />
                                </div>
                            </div>
                        :''}
                    </div>
                </div>
            </Card>
        </BlockUi>

        <Nav tabs vertical className='mt-4 border-0'>
            {menus.map((menu, key) => {
                const isSelectable = menu.isSelectable;
                const isMenuSelected = activeMenu === menu.tag;

                return <Card className="bg-white p-2 border-0 mb-3 shadow" key={key}>
                    <h6 onClick={() => toggleMenu(menu.tag, menu.isSelectable)} className={isMenuSelected && isSelectable ? ' pl-2 m-0 py-1 text-primary' : 'pl-2 m-0 py-1'} style={{ cursor: 'pointer' }} >
                        <Row className="pr-2">
                            <Col>
                                {menu.icon ? <i className={menu.icon} /> : ''}{menu.name}
                            </Col>
                            <Col className="col-2 text-right">
                                {!isSelectable ? <i className="fas fa-chevron-down" /> : ''}
                            </Col>
                        </Row>
                    </h6>

                    {menu.submenus?.length > 0 ?
                        <Collapse isOpen={isMenuSelected} className="pt-2">                                
                            {menu.submenus?.map((submenu, index) => {
                                const isSelected = activeTab === submenu.tag;

                                return <NavItem key={`${key}-${index}`} className="pb-2">
                                    <NavLink className={"pointer border-0 overbglight " + (isSelected ? 'text-primary active' : 'bg-white')} onClick={_ => toggleTab(submenu.tag)} >
                                        {isSelected ?
                                            <div style={{
                                                'left': '0',
                                                'position': 'absolute',
                                                'borderRadius': '0px 30px 30px 0px',
                                                'width': '7px',
                                                'height': '14px',
                                                'background': '#0667FF 0% 0% no-repeat padding-box',
                                                'opacity': '1',
                                                'marginTop': '4px'
                                            }} />
                                            : ''}
                                        <div className='d-flex align-items-center'>
                                            {submenu.icon && !hideSubmenuIcon ? <i className={submenu.icon} /> : <div className="pl-3" />} {submenu.name}
                                        </div>
                                    </NavLink>
                                </NavItem>
                            })}
                        </Collapse>
                    : ''}
                </Card>
            })}
        </Nav>
    </div>
);

export const TwilioSideBar = ({ name, activeTab, toggleTab, toggleMenu, menus, activeMenu, hideSubmenuIcon, block, status }) => (
    <div className="side-menu">
        <BlockUi tag="div" blocking={block}>
            <Card className="border-0 shadow mb-3 p-3">
                <div>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <h5 className='m-0 text-truncate' style={{maxWidth: '150px'}}>
                                {name}
                            </h5>
                            {status ?
                                <div>
                                    {status === "suspended" ?
                                        <Badge color='secondary' className='ml-2'>
                                            <FormattedMessage id="TwilioMessaging.Disabled" />
                                        </Badge>
                                    :
                                    status === "closed" ?
                                        <Badge color='danger' className='ml-2'>
                                            <FormattedMessage id="TwilioMessaging.Closed" />
                                        </Badge>
                                    :''}
                                </div>
                            :''}
                        </div>
                    </div>
                </div>
            </Card>
        </BlockUi>

        <Nav tabs vertical className='mt-4 border-0'>
            {menus.map((menu, key) => {
                const isSelectable = menu.isSelectable;
                const isMenuSelected = activeMenu === menu.tag;

                return <Card className="bg-white p-2 border-0 mb-3 shadow" key={key}>
                    <h6 onClick={() => toggleMenu(menu.tag, menu.isSelectable)} className={isMenuSelected && isSelectable ? ' pl-2 m-0 py-1 text-primary' : 'pl-2 m-0 py-1'} style={{ cursor: 'pointer' }} >
                        <Row className="pr-2">
                            <Col>
                                {menu.icon ? <i className={menu.icon} /> : ''}{menu.name}
                            </Col>
                            {!isSelectable ?
                                <Col className="col-2 text-right">
                                    <i className="fas fa-chevron-down" />
                                </Col>
                                : ''}
                        </Row>
                    </h6>

                    {menu.submenus?.length > 0 ?
                        <Collapse isOpen={isMenuSelected} className="pt-2">                                
                            {menu.submenus?.map((submenu, index) => {
                                const isSelected = activeTab === submenu.tag;

                                return <NavItem key={`${key}-${index}`} className="pb-2">
                                    <NavLink className={"pointer border-0 overbglight " + (isSelected ? 'text-primary active' : 'bg-white')} onClick={_ => toggleTab(submenu.tag)} >
                                        {isSelected ?
                                            <div style={{
                                                'left': '0',
                                                'position': 'absolute',
                                                'borderRadius': '0px 30px 30px 0px',
                                                'width': '7px',
                                                'height': '14px',
                                                'background': '#0667FF 0% 0% no-repeat padding-box',
                                                'opacity': '1',
                                                'marginTop': '4px'
                                            }} />
                                            : ''}
                                        <div className='d-flex align-items-center'>
                                            {submenu.icon && !hideSubmenuIcon ? <i className={submenu.icon} /> : <div className="pl-3" />} {submenu.name}
                                        </div>
                                    </NavLink>
                                </NavItem>
                            })}
                        </Collapse>
                    : ''}
                </Card>
            })}
        </Nav>
    </div>
);