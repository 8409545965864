import React, { Component } from "react";
import { Col, Row, Card, Badge } from "reactstrap";
import { FormattedMessage, injectIntl } from "react-intl";


class RuleCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dragCard: false
        };
    }

    onDragStartCode = (e, code) => {
        e.dataTransfer.setData("codeToSwitch", code);
    }

    onDragOver = (e) => {
        e.preventDefault();
    }

    onDragEnd = () => {
        this.setState({ showRemoveRule: false, showAddRule: false })
    }

    switchCode = (e, code) => {
        const codeToSwitch = e.dataTransfer.getData("codeToSwitch");

        if (code !== codeToSwitch) {
            const rule = { ...this.props.rule };
            const index1 = rule.code.findIndex(c => c === codeToSwitch);
            const index2 = rule.code.findIndex(c => c === code);

            rule.code[index1] = code;
            rule.code[index2] = codeToSwitch;

            this.props.updateRule(this.props.arrayName, this.props.ruleIndex, rule);
        }
    }

    handleChangeCode = (e, codeIndex) => {
        const { value } = e.target;
        const rule = { ...this.props.rule };

        rule.code[codeIndex] = value;

        this.props.updateRule(this.props.arrayName, this.props.ruleIndex, rule);
    }

    addCode = (e) => {
        if (e && e.target) {
            const newCode = e.target.value;
            const rule = { ...this.props.rule };
            rule.code = rule.code ? rule.code : [];

            if (newCode && rule.code.some(code => code.toLowerCase() === newCode.toLowerCase()) === false) {
                rule.code.push(newCode);

                this.props.updateRule(this.props.arrayName, this.props.ruleIndex, rule);
            }
        }
    }

    render() {
        const { rule, dragDropName, onDragStart, onDragEnd, removeCode, ruleIndex, arrayName, draggingCard, switchRuleIndex, loyalties } = this.props;

        return (
            <Card className="border-0 shadow px-2 py-3 h-100 " onDragOver={(e) => { if (rule.type !== 'CreationDate' && arrayName === 'rulesApplied') this.onDragOver(e) }} onDrop={(e) => switchRuleIndex(e, ruleIndex)} >
                <Row>
                    <Col sm={3} className={"border-right d-flex flex-column align-items-center justify-contnt-center color-white " + rule.bgColor} style={rule.isFixed ? { 'borderRadius': '0px 30px 30px 0px' } : { cursor: 'grab', 'borderRadius': '0px 30px 30px 0px' }}
                        draggable={rule.isFixed ? false : true}
                        onDragStart={(event) => onDragStart(event, rule, dragDropName, ruleIndex) }
                        onDragEnd={() => onDragEnd }
                    >
                        <FormattedMessage id="LoyaltyCardRules.Criteria" />
                        <b style={{ fontSize: '25px' }}> {rule.diplayIndex !== 0 ? rule.diplayIndex : '-'}</b>
                    </Col>
                    <Col>
                        <h6><b><FormattedMessage id={`LoyaltyCardRules.${rule.type}`} /></b></h6>

                        {rule.isFixed ?
                            <FormattedMessage id="LoyaltyCardRules.SortByMostRecentCreationDate" />
                            :

                            <div>
                                <div className="d-flex">
                                    {rule.code && rule.code.map((code, i) =>
                                        <div key={i} className="mr-2 px-2 invisible-input" style={{ borderRadius: '5px', border: '1px solid lightblue' }}
                                            draggable={true} onDragStart={(event) => this.onDragStartCode(event, code)} onDragEnd={this.onDragEnd}
                                            onDragOver={(e) => { if (!draggingCard) this.onDragOver(e) }} onDrop={(e) => this.switchCode(e, code)}
                                        >
                                            <input
                                                value={code}
                                                style={{ width: `${code.length + 1}ch` }}
                                            />
                                            <i className="fas fa-times ml-2 pointer" onClick={() => removeCode(arrayName, ruleIndex, i)} />
                                        </div>
                                    )}

                                    <select style={{ width: '115px' }} value="" onChange={(e) => this.addCode(e)}>
                                        <option value="" disabled selected>{this.props.intl.formatMessage({ id: 'LoyaltyCardRules.NewCode' })}</option>
                                        {this.props[rule.options]
                                                .filter(lylt => rule.code == null || !rule.code.some(c => c === lylt.value))
                                                .map((lylt, key) => <option key={key} value={lylt.value}>{lylt.label}</option>)
                                        }
                                    </select>
                                </div>
                            </div>
                        }
                    </Col>
                </Row>
            </Card>
        );
    }
}
export default injectIntl(RuleCard)