import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { injectIntl } from 'react-intl';
import { Button, Col, Row, Breadcrumb, BreadcrumbItem, Modal, ModalBody, Form, Label, Input } from 'reactstrap';
import { handleNotification } from "../../Base/Notification";
import { StyledCard, BlankCard } from "../../Base/CommonUIComponents";
import { getAPI, postAPI, putAPI, deleteAPI } from "../../Base/API";
import { FormattedMessage } from "react-intl";
import ListCard from './ListCard';

import Authorization from '../../Base/Authorization';
import ConfirmActionModal from '../../Base/ConfirmActionModal';

export class EgoiList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            modal: false,
            editMode: false,
            deleteModal: false,
            list: [],
            limit: 10,
            offset: 0,
            totalItems: 0,
            selectedList: null
        };
    }

    componentDidMount() {
        this.getList();
    }

    getList() {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({
                    list: data.items ? data.items : [],
                    totalItems: data.total_items ? data.total_items : 0
                });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/lists?limit=${this.state.limit}&offset=${this.state.offset}`);
    }

    removeList = (e, listId) => {
        e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.content) {
                    handleNotification({ errors: [{ message: data.content.message }] });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
            }
            else {
                handleNotification(data, <FormattedMessage id="EgoiList.ListRemoved" />, <FormattedMessage id="generic.success" />);
                this.toggleDeleteModal();

                this.setState({
                    offset: 0
                }, () => this.getList());
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/lists/${listId}`);
    }

    createList = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.content) {
                    handleNotification({ errors: [{ message: data.content.message }] });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.list_id) {
                    handleNotification(data, <FormattedMessage id="EgoiList.ListCreated" />, <FormattedMessage id="generic.success" />);

                    this.setState({
                        modal: false,
                        selectedList: null,
                        editMode: false,
                        offset: 0
                    }, () => this.getList());
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/lists/${this.state.selectedList.internal_name}?publicName=${this.state.selectedList.public_name}`);
    }

    editList = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.content) {
                    handleNotification({ errors: [{ message: data.content.message }] });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.list_id) {
                    handleNotification(data, <FormattedMessage id="EgoiList.ListUpdated" />, <FormattedMessage id="generic.success" />);

                    this.setState({
                        modal: false,
                        selectedList: null,
                        editMode: false,
                        offset: 0
                    }, () => this.getList());
                }
            }

            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/lists/${this.state.selectedList.list_id}?publicName=${this.state.selectedList.public_name}`);
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            offset: this.state.offset + value
        }, () => this.getList());
    }

    toggleModal = (e, data) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedList: data ? data : null,
            editMode: data ? true : false
        }));
    }

    toggleDeleteModal = (listToDelete) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
            listToDelete
        }));
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            selectedList: {
                ...this.state.selectedList,
                [name]: value
            }
        });
    }

    updateList = (index, listData) => {
        const { list } = this.state;

        list[index] = listData;

        this.setState({ list });
    }

    render() {
        const { block, error, totalItems, offset, limit, selectedList, editMode, deleteModal, listToDelete } = this.state;

        return (
            <StyledCard block={block} error={error} >
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/MarketingConfigEgoi"> Config</Link></BreadcrumbItem>
                        <BreadcrumbItem active>List</BreadcrumbItem>
                    </Breadcrumb>
                </div>

                <Authorization
                    perform="egoiList:add"
                    yes={() => (
                        <Row className="mb-2 text-right pr-3">
                            <Col>
                                <Button className="btn- btn-host btn-small btn-sm" onClick={this.toggleModal}>
                                    <i className="fas fa-plus" />
                                </Button>
                            </Col>
                        </Row>
                    )}
                    no={() => <div></div>}
                />

                {this.state.list && this.state.list.map((list, key) =>
                    <>
                        <ListCard
                            key={key}
                            index={key}
                            list={list}
                            toggleModal={this.toggleModal}
                            updateList={this.updateList}
                            toggleDeleteModal={() => this.toggleDeleteModal({ id: list.list_id, name: list.public_name })}
                        />
                    </>
                )}

                {totalItems > 0 && totalItems >= limit ?
                    <Row className="mt-3 px-3">
                        <Col>
                            <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.handlePagination(-limit)} disabled={offset === 0}>
                                <i className="fas fa-angle-left" />
                            </Button>
                        </Col>
                        <Col className="text-right">
                            <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.handlePagination(limit)} disabled={totalItems <= offset + limit}>
                                <i className="fas fa-angle-right" />
                            </Button>
                        </Col>
                    </Row>
                : ''}


                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={this.toggleDeleteModal}
                        actionFunction={() => this.removeList(listToDelete.id)}
                        text={<FormattedMessage id="EgoiList.ConfirmDeleteText" values={{ egoiList: listToDelete.name }} />}
                        block={block}
                    />
                : ''}


                <Modal isOpen={this.state.modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                    <Button onClick={this.toggleModal} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    <ModalBody>
                        <BlankCard block={block} error={error}>
                            <Form onSubmit={editMode ? (e) => this.editList(e) : (e) => this.createList(e)}>
                                <Row className="mb-3">
                                    <Col>
                                        <h5> {editMode ? <FormattedMessage id="EgoiList.EditList" /> : <FormattedMessage id="EgoiList.CreateList" />}</h5>
                                    </Col>
                                    <Col className="text-right">
                                        <Button className="btn-sm btn-host" type="submit"> <i className="fas fa-save" /></Button>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Label sm={3}><FormattedMessage id="EgoiList.InternalName" /></Label>
                                    <Col sm={9}>
                                        <Input
                                            name="internal_name"
                                            value={selectedList && selectedList.internal_name ? selectedList.internal_name : ''}
                                            onChange={(e) => this.handleChange(e)}
                                            required
                                            readOnly={editMode ? true : false}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Label sm={3}> <FormattedMessage id="EgoiList.PublicName" /></Label>
                                    <Col sm={9}>
                                        <Input
                                            name="public_name"
                                            value={selectedList && selectedList.public_name ? selectedList.public_name : ''}
                                            onChange={(e) => this.handleChange(e)}
                                            required
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </BlankCard>
                    </ModalBody>
                </Modal>
            </StyledCard>
        );
    }
}
export default injectIntl(EgoiList)
