import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Modal, ModalBody, Input } from 'reactstrap';
import { BlankCard, SelectHotel } from '../../../../Base/CommonUIComponents';
import CustomSelect from '../../../../Base/CustomSelect';

class PrefixModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            prefix: this.props.selectedPrefix || {}
        };
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            prefix: {
                ...this.state.prefix,
                [name]: value
            }
        })
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            prefix: {
                ...this.state.prefix,
                [name]: combo ? combo.value : null
            }
        });
    };


    render() {
        const { prefix } = this.state;
        const { modal, toggleModal, hotels, updatePrefixList } = this.props;

        return (
            <Modal isOpen={modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={this.state.block} error={this.state.error}>
                        <Form onSubmit={(e) => updatePrefixList(e, this.state.prefix, false)}>
                            <Row className="mb-3">
                                <Col>
                                    <h5>{prefix.id ? <FormattedMessage id="LoyaltyCardAttribution.EditPrefix" /> : <FormattedMessage id="LoyaltyCardAttribution.AddPrefix" />}</h5>
                                </Col>
                                <Col className="text-right col-3">
                                    {prefix.id || this.props.selectedPrefix ?
                                        <Button className="btn-sm btn-host mr-2" onClick={(e) => updatePrefixList(e, this.state.prefix, true)}>
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                        : ''}
                                    <Button className="btn-sm btn-host" type="submit">
                                        <i className="fas fa-plus" />
                                    </Button>
                                </Col>
                            </Row>

                            <FormGroup row>
                                <Label sm={2}> <FormattedMessage id="generic.Hotel" /></Label>
                                <Col sm={5} >
                                    <CustomSelect
                                        options={hotels}
                                        value={hotels?.find(m => m.value === prefix.hotelId) || ''}
                                        isSearchable
                                        placeholder={''}
                                        onChange={(e) => this.handleChangeSelect('hotelId', e)}
                                        isDisabled={prefix.id}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2}> <FormattedMessage id="LoyaltyCardAttribution.ComplexCode" /></Label>
                                <Col sm={10} >
                                    <Input
                                        type="text"
                                        name="complexCode"
                                        onChange={(e) => this.handleChange(e)}
                                        value={prefix.complexCode || ''}
                                        maxLength={50}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label sm={2}> <FormattedMessage id="LoyaltyCardAttribution.Code" /></Label>
                                <Col sm={10} >
                                    <Input
                                        type="text"
                                        name="code"
                                        onChange={(e) => this.handleChange(e)}
                                        value={prefix.code || ''}
                                        maxLength={20}
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2}> <FormattedMessage id="LoyaltyCardAttribution.Description" /> </Label>
                                <Col sm={10}>
                                    <Input
                                        type="textarea"
                                        name="description"
                                        onChange={(e) => this.handleChange(e)}
                                        value={prefix.description || ''}
                                        maxLength={100}
                                        required
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(PrefixModal);