import React, { Component } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Label, Input } from 'reactstrap';
import { SingleDatePicker } from 'react-dates';

import moment from 'moment';
import CustomSelect from '../../Base/CustomSelect';

class DecisionSplitConditions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focused: false,
            startDate: null,
        }
    }

    getYearOptions(startDate) {
        const currentYear = moment().year();
        const startDateYear = startDate ? moment(startDate).year() : currentYear;

        const pastYears = Array.from({ length: 100 }, (_, i) => currentYear - i);

        if (!pastYears.includes(startDateYear)) {
            pastYears.push(startDateYear);
            pastYears.sort((a, b) => b - a);
        }

        return pastYears;
    }

    renderMonthElement = ({ month, onYearSelect }) => {
        const yearOptions = this.getYearOptions(this.state.startDate);

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Row>
                    <Col className="pr-1">
                        {moment(month).locale(this.props.intl.locale).format('MMMM')}
                    </Col>
                    <Col className="pl-1">
                        <select
                            value={month.year()}
                            onChange={(e) => onYearSelect(month, Number(e.target.value))}
                            style={{ border: '0', fontSize: '1em', overflow: 'auto' }}
                        >
                            {yearOptions.map((year, idx) => (
                                <option key={idx} value={year} style={{ fontSize: '0.8em' }}>{year}</option>
                            ))}
                        </select>
                    </Col>
                </Row>
            </div>
        );
    };

    render() {
        const { entityList, block, selectedNode, changeCurrentNodeMetaDataFields, conditionNumber, intl } = this.props;

        const metaData = selectedNode && selectedNode.metaData;

        const entity = metaData && metaData[conditionNumber] && metaData[conditionNumber].entity;
        const field = metaData && metaData[conditionNumber] && metaData[conditionNumber].field;
        const operatorType = metaData && metaData[conditionNumber] && metaData[conditionNumber].condition;
        let value = null;

        if(metaData && metaData[conditionNumber] && metaData[conditionNumber].value){
            if(Array.isArray(metaData[conditionNumber].value)){
                if(metaData[conditionNumber].value.length > 1){
                    value = metaData[conditionNumber].value;
                }else{
                    value = metaData[conditionNumber].value[0];
                }
            } else {
                value = metaData[conditionNumber].value;
            }
        }

        const valueOptions = this.props.getValueOptions(entity, field);

        return (
            <div className={`w-100 ${conditionNumber !== 1 ? 'mt-4' : ''}`}>
                <Col className='col-12 '>
                    <Col className='col-12 d-flex align-items-center'>
                        <h6 className='m-0'>
                            <b><FormattedMessage id={`CustomerJourney.Condition${conditionNumber}`} /></b>
                        </h6>
                        {conditionNumber !== 1 &&
                            <div className='pl-2 cursor-pointer hover-delete-condition' onClick={() => this.props.handleConditionsNumberSelectChange('delete')}>
                                <i className="far fa-trash-alt"></i>
                            </div>
                        }
                    </Col>
                </Col>
                <Col>
                    <Col className='col-12 pr-0'>
                        <Label sm={6}> <FormattedMessage id="RuleDashboard.Entity" /></Label>
                        <Label sm={6}> <FormattedMessage id="RuleDashboard.Field" /></Label>
                    </Col>
                    <Col className='d-flex align-items-center pr-0'>
                        <Col sm={6}>
                            <CustomSelect
                                id={`entity-${conditionNumber}`}
                                options={entityList}
                                placeholder=""
                                onChange={(option) => changeCurrentNodeMetaDataFields({ name: 'entity', value: option.value, conditionNumber })}
                                value={this.props.getEntityValue(metaData, entityList, conditionNumber)}
                            />
                        </Col>
                        <Col sm={6}>
                            <CustomSelect
                                id={`field-${conditionNumber}`}
                                options={this.props.getFieldOptions(entity)}
                                placeholder=""
                                isDisabled={!entity}
                                onChange={(option) => changeCurrentNodeMetaDataFields({ name: 'field', value: option.value, conditionNumber })}
                                value={this.props.getFieldValue(entity, field, conditionNumber)}
                            />
                        </Col>
                    </Col>
                    <Col className='col-12 pr-0 mt-2'>
                        <Label sm={6}> <FormattedMessage id="RuleFiltersDetails.OperatorType" /></Label>
                        <Label sm={6}> <FormattedMessage id="RuleFiltersDetails.Values" /></Label>
                    </Col>
                    <Col className='d-flex align-items-center pr-0'>
                        <Col className='col-6'>
                            <CustomSelect
                                id={`operatorType-${conditionNumber}`}
                                options={this.props.getOperatorTypeOptions(entity, field, conditionNumber)}
                                isDisabled={!entity || !field || field === 'HasLoyalty'}
                                placeholder=""
                                onChange={(option) => changeCurrentNodeMetaDataFields({ name: 'condition', value: option.value, conditionNumber })}
                                value={field !== 'HasLoyalty' ? this.props.getOperatorTypeValue(operatorType) : ''}
                            />
                        </Col>
                        <Col className='col-6' id={`JourneyBuilderDatePicker${conditionNumber}`}>
                            {entity ? 
                                (entity === 'Profile' && field === 'BirthDate') ?
                                    <SingleDatePicker
                                        id={`profileBirthDate${conditionNumber}`}
                                        isOutsideRange={() => false}
                                        date={value ? moment(value) : null}
                                        focused={this.state.focused}
                                        onFocusChange={({ focused }) => this.setState({ focused })}
                                        small={true}
                                        numberOfMonths={1}
                                        showDefaultInputIcon={true}
                                        onDateChange={date => changeCurrentNodeMetaDataFields({ name: 'value', value: moment(date).format('YYYY-MM-DD'), conditionNumber })}
                                        renderMonthElement={this.renderMonthElement}
                                        showClearDate={false}
                                    />
                                :
                                    entity === 'ProfileContact' && field === 'Contact' ?
                                        <Input
                                            id={`inputValue-${conditionNumber}`}
                                            name="value"
                                            type='text'
                                            value={field !== 'HasLoyalty' && value ? value : ''}
                                            disabled={!entity || !field || field === 'HasLoyalty'}
                                            onChange={(evt) => changeCurrentNodeMetaDataFields({ name: 'value', value: evt && evt.target && evt.target.value, conditionNumber })}
                                        />
                                    :
                                        <CustomSelect
                                            id={`selectValue-${conditionNumber}`}
                                            block={block}
                                            options={valueOptions}
                                            isSearchable={true}
                                            isMulti={(entity === 'Profile' && field === 'Nationality')}
                                            isClearable={(entity === 'Profile' && field === 'Nationality')}
                                            placeholder=""
                                            isDisabled={!entity || !field || field === 'HasLoyalty'}
                                            onChange={(option) => {
                                                const valueOption = Array.isArray(option) ? option.map(opt => opt.value) : option?.value;
                                                changeCurrentNodeMetaDataFields({ name: 'value', value: valueOption, conditionNumber, field })
                                            }}
                                            value={this.props.getValue(valueOptions, value, entity, field)}
                                        />
                            :
                                <Input
                                    name="value"
                                    type='text'
                                    value={''}
                                    disabled={true}
                                />
                            }
                        </Col>
                    </Col>
                </Col>
            </div>
        );
    }
}

export default injectIntl(DecisionSplitConditions);