import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Col, Form, Input, Row, Modal, ModalBody } from "reactstrap";
import { handleNotification } from "../../Base/Notification";
import { BlankCard } from '../../Base/CommonUIComponents';
import { putAPI } from "../../Base/API";

export class TopicDetails extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            topic: this.props.topic ? this.props.topic : {}
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true;
    }

    saveTopic = (event) => {
        event.preventDefault();
        this.setState({ block: true});
        const { topic } = this.state;

        putAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="TopicDetails.TopicSavedSuccessfully" />, <FormattedMessage id="generic.success" />);
                    this.props.updateTable(data.response[0]);
                    this.props.toggleModal();
                }

                this.setState({ block: false });
            }
        }, `/api/gms/DataQuality/v1/DataQualityTopic`, JSON.stringify({ request: topic }));
    }

    handleInput = (evt) => {
        const { name, value } = evt.target;
        const { topic } = this.state;

        topic[name] = value;
        this.setState({ topic });
    }

    render() {
        const help = <div>TODO</div>;
        const { topic, block, error } = this.state;

        return (
            <Modal isOpen={this.props.modal} fade={false} size={"lg"} style={{ minWidth: 0 }} >
                <Button onClick={this.props.toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody >
                    <BlankCard block={block} error={error}>
                        <Form onSubmit={this.saveTopic}>
                            <Row className="mb-3">
                                <Col>
                                    <h5>{this.props.topic ? <FormattedMessage id="TopicDetails.EditTopic" /> : <FormattedMessage id="TopicDetails.CreateTopic" />}</h5>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" type="submit">
                                        <i className="fas fa-save"></i>
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-2">
                                    <FormattedMessage id="TopicManagement.code" />
                                </Col>
                                <Col>
                                    <Input
                                        type="text"
                                        name="code"
                                        value={topic.code ? topic.code : ''}
                                        onChange={e => this.handleInput(e)}
                                        maxLength="50"
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-2"><FormattedMessage id="TopicManagement.description" /></Col>
                                <Col>
                                    <Input
                                        type="textarea"
                                        name="description"
                                        value={topic.description}
                                        onChange={e => this.handleInput(e)}
                                        maxLength="2000"
                                        required
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
