import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Card, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { StyledCard, ActiveInactiveStatusBadge, ActiveInactiveStatusCombo, SelectCampaign } from "../../Base/CommonUIComponents";
import { getConvertedDateToHotelTimezone } from "../../Base/ReferenceDataFunctions";
import PreviewTemplatesByCountry from '../PreviewTemplatesByCountry';
import { CampaignStats } from "../Common/MarketingFunctions";
import { deleteAPI, getAPI, postAPI } from "../../Base/API";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import moment from 'moment';

import Authorization from '../../Base/Authorization';
import { handleNotification } from '../../Base/Notification';
import ConfirmActionModal from '../../Base/ConfirmActionModal';
import { DateRangePicker } from 'react-dates';

class CustomCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            modal: false,
            deleteModal: false,
            campaigns: [],
            selectedCampaign: null,
            pageSize: 10,
            pageIndex: 0,
            totalItems: 0,
            barChartData: []
        };
    }

    componentDidMount() {
        this.getCampaigns();
    }

    getCampaigns = () => {
        this.setState({ block: true });

        var params = `&campaignType=custom`;
        params += this.state.campaignId ? `&campaignId=${this.state.campaignId}` : '';
        params += this.state.isActive != null ? `&isActive=${this.state.isActive}` : '';
        params += this.state.startCreatedAt ? `&startCreatedAt=${moment(this.state.startCreatedAt).format("YYYY-MM-DD")}` : '';
        params += this.state.endCreatedAt ? `&endCreatedAt=${moment(this.state.endCreatedAt).format("YYYY-MM-DD")}` : '';

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({
                    campaigns: data.response ? data.response : [],
                    totalItems: data.totalItems ? data.totalItems : 0
                });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/marketingsend/list?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}` + params);
    }

    calculateKpi = (e, campaignId, idx) => {
        e.preventDefault();
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                    return;
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="CustomCampaign.CampaignStatsCalculated" />, <FormattedMessage id="generic.success" />);

                    let campaigns = [...this.state.campaigns];
                 
                    if (campaigns[idx].marketingSendKpis) {
                        data.response.forEach(el => {
                            const index = campaigns[idx].marketingSendKpis.findIndex(m => m.templateId === el.templateId);

                            if (index !== -1) {
                                campaigns[idx].marketingSendKpis[index] = el;
                            }
                            else {
                                campaigns[idx].marketingSendKpis.push(el);
                            }
                        })
                    }
                    else {
                        campaigns[idx].marketingSendKpis = data.response;
                    }

                    this.setState({ campaigns, block: false });
                    return;
                }
            }
            this.setState({ block: false });
        }, `/api/gms/Marketing/v1/MarketingSendKPIs?id=${campaignId}`);
    }

    doSearch = () => {
        this.setState({
            pageIndex: 0
        }, () => this.getCampaigns())
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        });
    };

    selectCampaign = (id) => {
        this.props.history.push({ pathname: "/CustomCampaignDetails/" + id })
    }

    toggleModal = (template, channel) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            templates: template,
            channel
        }));
    }

    toggleDeleteModal = (campaignToDelete) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
            campaignToDelete
        }));
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.getCampaigns());
    }

    deleteCampaign = (id) => {
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.errors && data.errors.length > 0) {
                handleNotification(data);
                this.setState({ error: errorMessage, block: false });
            }
            else {
                handleNotification(data, <FormattedMessage id="CustomCampaign.DeleteWithSuccess" />, <FormattedMessage id="generic.success" />);
                this.getCampaigns();
                this.toggleDeleteModal();
            }
        }, `/api/gms/Marketing/v1/marketingsend/${id}`);
    }

    render() {
        const { block, error, pageSize, pageIndex, totalItems, campaigns, deleteModal, campaignToDelete, startCreatedAt, endCreatedAt } = this.state;

        return (
            <StyledCard block={block} error={error} icon={'fal-lg icon-icon-unique-campaign'} title={'NavMenu.customsCampaign'} >
                <Row className="my-4 align-items-center">
                    <Col sm={3}>
                        <SelectCampaign
                            type="custom"
                            name='campaignId'
                            icon="icon-icon-unique-campaign"
                            isClearable
                            value={this.state.campaignId}
                            intl={this.props.intl}
                            onChangeFunc={this.handleChangeSelect.bind(this)}
                            placeholder={<FormattedMessage id="generic.Campaigns" />}
                        />
                    </Col>
                    <Col className="col-2 pl-0">
                        <ActiveInactiveStatusCombo
                            name='isActive'
                            isClearable
                            onChangeFunc={this.handleChangeSelect.bind(this)}
                            placeholder={this.props.intl.formatMessage({ id: "generic.status" })}
                        />
                    </Col>
                    <Col className="pl-0">
                        <DateRangePicker
                            startDate={startCreatedAt || null}
                            startDateId="your_unique_start_date_id"
                            endDate={endCreatedAt || null}
                            endDateId="your_unique_end_date_id"
                            isOutsideRange={day => day > moment() || day < moment().subtract(6, 'month')}
                            onDatesChange={({ startDate, endDate }) => this.setState({ startCreatedAt: startDate, endCreatedAt: endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </Col>

                    <Col className="text-right col-1">
                        <Authorization
                            perform="customCampaigns:add"
                            yes={() => (
                                <Button className="btn btn-sm btn-host mr-2" onClick={() => this.props.history.push({ pathname: "/CustomCampaignDetails" })}>
                                    <i className="fas fa-plus"></i>
                                </Button>
                            )}
                            no={() => <div></div>}
                        />
                        <Button className="btn btn-sm btn-host" onClick={this.doSearch}>
                            <i className="icon-icon-search"></i>
                        </Button>
                    </Col>
                </Row>

                {!campaigns || campaigns.length === 0 ? 'Configure your Custom Campaign' : campaigns.map((el, k) =>
                    <Card className="border-0 p-3 shadow mb-2 overbglight" key={k} >
                        <Row className="mb-3">
                            <Col>
                                <h5><b>{el.name}</b> </h5>
                            </Col>
                            <Col className="text-right">
                                {
                                    moment(moment(el.scheduleDay).format("YYYY-MM-DD HH:mm")).isBefore(moment().format("YYYY-MM-DD HH:mm")) ?
                                        <span>
                                            <Button className="btn btn-host btn-sm mr-2" id="calculateKpi" onClick={(e) => this.calculateKpi(e, el.id, k)}>
                                                <i className="fas fa-calculator"></i>
                                            </Button>

                                            <UncontrolledTooltip target="calculateKpi" placement="bottom">
                                                <FormattedMessage id="CustomCampaign.CalculateStats" />
                                            </UncontrolledTooltip>
                                        </span>
                                        :
                                        ''
                                }
                                <Button className="btn btn-host btn-sm mr-2" onClick={() => this.toggleModal(el.templates, el.senderChannel)}>
                                    <i className="icon-icon-preview"></i>
                                </Button>
                                <Authorization
                                    perform="customCampaigns:edit"
                                    yes={() => (
                                        <Button className="btn btn-host btn-sm mr-2" onClick={this.selectCampaign.bind(this, el.id)}>
                                            <i className="far fa-edit"></i>
                                        </Button>
                                    )}
                                    no={() => ''}
                                />
                                <Button className="btn btn-host btn-sm mr-2" onClick={() => this.props.history.push({ pathname: "/CommunicationStats/" + el.id })}>
                                    <i className="icon-icon-statistics-campaigns"></i>
                                </Button>
                                <Button className="btn btn-host btn-sm" onClick={() => this.toggleDeleteModal({ id: el.id, name: el.name })}>
                                    <i className="fas fa-trash-alt" />
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div><FormattedMessage id={`Campaigns.${el.marketingType}`} /></div>
                                <div>
                                    <b>Notify </b> {el.scheduleDay ? getConvertedDateToHotelTimezone(el.scheduleDay).format("YYYY-MM-DD HH:mm") : ''}
                                </div>
                                <div>
                                    <ActiveInactiveStatusBadge status={el.active} />
                                </div>
                            </Col>
                            <Col>
                                <div><b>Sender:</b> {el.sender}</div>
                                <div><b>Channel:</b> {el.senderChannel}</div>
                            </Col>
                            <Col sm={5}>
                                {el.marketingSendKpis && moment(moment(el.scheduleDay).format("YYYY-MM-DD HH:mm")).isBefore(moment().format("YYYY-MM-DD HH:mm")) ?
                                    <CampaignStats
                                        marketingSendKpis={el.marketingSendKpis}
                                        styles={{ display: 'flex', justifyContent: 'space-around', padding: '0 70px', position: 'absolute', top: '-15px' }}
                                    />
                                : ''}
                            </Col>

                            <Col sm={1}/>
                        </Row>
                    </Card>
                )}

                {totalItems > 0 && totalItems >= pageSize ?
                    <Row className="my-3 px-3">
                        <Col>
                            <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.handlePagination(-1)} disabled={pageIndex === 0}>
                                <i className="fas fa-angle-left" />
                            </Button>
                        </Col>
                        <Col className="text-right">
                            <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.handlePagination(1)} disabled={totalItems <= ((pageIndex + 1) * pageSize)}>
                                <i className="fas fa-angle-right" />
                            </Button>
                        </Col>
                    </Row>
                : ''}


                {this.state.modal ?
                    <PreviewTemplatesByCountry
                        modal={this.state.modal}
                        toggleModal={this.toggleModal}
                        templates={this.state.templates}
                        channel={this.state.channel}
                    />
                : ''}


                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={this.toggleDeleteModal}
                        actionFunction={_ => this.deleteCampaign(campaignToDelete.id)}
                        text={<FormattedHTMLMessage id="Campaigns.ConfirmDeleteText" values={{ campaignName: campaignToDelete.name }} />}
                        block={block}
                    />
                    : ''}
            </StyledCard>
        );
    }
}
export default injectIntl(CustomCampaign)
