import React from 'react';
import { getBezierPath, getEdgeCenter, useReactFlow } from 'react-flow-renderer';

const foreignObjectSize = 40;

export default function CustomEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
    data
}) {
    const { setEdges } = useReactFlow();

    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });

    const adjustedCenterX = edgeCenterX - foreignObjectSize / 2;
    const adjustedCenterY = edgeCenterY - foreignObjectSize / 2;

    const onEdgeClick = (evt) => {
        evt.stopPropagation();
        if (data && data.onDelete) {
            if(data.initialEdge){
                data.onDelete(id);
                setEdges((eds) => eds.filter((edge) => edge.id !== id));
            }else{
                data.onDelete(id);
            }
        }
    };

    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={adjustedCenterX}
                y={adjustedCenterY}
                className="edgebutton-foreignobject"
            >
                <div>
                    <button
                        style={{ border: 'unset', borderRadius: '100%' }}
                        className="edgebutton"
                        onClick={onEdgeClick}
                    >
                        <i className="fas fa-times"></i>
                    </button>
                </div>
            </foreignObject>
        </>
    );
}