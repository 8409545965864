import React from 'react';
import { FormatAmountNumber } from '../CommonUIComponents';
import moment from 'moment-timezone';

const CoolDataGridCell = ({ rowData, colKey, colType, rowEmptyNull, cellsNoPadding, childKey, tableName, rowKey, secChildKey, hasIndent, thirdChildKey, separator, title, firstCol, newHotel, isSmall, newHotelRowStyle, rowHeight }) => {
    
    const data = rowData && rowData[colKey];
    const hotelCurrency = global.hotelList.find(x => x.value == rowData?.hotelId)?.currency ?? global.hotelCurrency;

    return (
        separator ?
            <div className="CoolDataGrid-cell-separator">
                {firstCol ?
                    title
                :''}
            </div>
        :
            <div className={`${cellsNoPadding ? 'p-0' : 'pl-2 pr-2'} w-100 ${isSmall ? 'CoolDataGrid-cell-small' : ''} CoolDataGrid-cell ${newHotel && newHotelRowStyle ? 'CoolDataGrid-cell-newHotel' : ''} row-${rowKey} ${!isNaN(childKey) ? 'CoolDataGrid-cell-child' : ''}`} id={`table-${tableName}-col-${colKey}-row-${rowKey}-child-${childKey}-child-${secChildKey}-${thirdChildKey}`} style={{ height: rowHeight }}>
                <div className={`d-flex align-items-center justify-content-${hasIndent ? 'start' : 'center'} text-truncate ${isSmall ? 'w-100' : 'h-100'}`}>
                    {data || !isNaN(data) ?
                        colType === "currency" ?
                            <FormatAmountNumber
                                value={data}
                                currency={hotelCurrency}
                            />
                        : colType === "date" ?
                            moment(data).format('DD-MM-YYY')
                        :
                            data 
                    :
                        <div style={{ visibility: rowEmptyNull ? 'hidden' : '' }}>-</div>
                    }
                </div>
            </div>
    );
}

export default CoolDataGridCell;
