import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Col, Row, Label, Form, Input, FormGroup, Modal, ModalBody } from 'reactstrap';
import { handleNotification } from '../../Base/Notification';
import { BlankCard } from "../../Base/CommonUIComponents";
import CustomSelect from "../../Base/CustomSelect";
import { postAPI } from "../../Base/API";

export class StageModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            assignTypes: [{ value: 'Group', label: 'Group' }, { value: 'User', label: 'User' }],
            goalType: [{ value: 'ContactInfo', label: 'Contact Info' }, { value: 'Schedule', label: 'Schedule' }, { value: 'CustomActivity', label: 'Custom Activity' }],
            goalSubType: [{ value: 'Call', label: 'Call' }, { value: 'Demo', label: 'Demo' }, { value: 'Chat', label: 'Chat' }],
            stage: { subType: { goalType: {} }}
        };
    }

    componentDidMount() {
    }

    savePipeline = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const body = { ...this.state.stage };
        console.log(body)
        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                //this.setState({ pipeline: data.response });
            }

            this.setState({ block: false });
        }, `/api/gms/Pipeline/v1/${this.props.pipelineId}/stage`, JSON.stringify({ request: body }));
    }

    handleChangeTask = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            stage: {
                ...prevState.stage,
                [name]: value
            }
        }));
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            stage: {
                ...prevState.stage,
                [name]: value
            }
        }));
    }

    handleSelect = (combo, name) => {
        this.setState(prevState => ({
            stage: {
                ...prevState.stage,
                [name]: combo ? combo.value : null
            }
        }));
    }

    render() {
        const { stageModal, toggleModal } = this.props;
        const { block, error, assignTypes, goalType, goalSubType } = this.state;


        return (
            <Modal isOpen={stageModal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <Form onSubmit={this.savePipeline}>
                            <Row className="mb-3">
                                <Col>
                                    <h5>New Stage</h5>
                                </Col>
                                <Col className="text-right col-3">
                                    <Button className="btn-sm btn-host" type="submit" disabled>
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>

                            <FormGroup row className="pl-3">
                                <Label sm={2}> Name </Label>
                                <Col sm={10} >
                                    <Input
                                        type="text"
                                        name="name"
                                        required
                                        onChange={(e) => this.handleChange(e, 'stage')}
                                        maxLength={200}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="pl-3">
                                <Label sm={2}> Description </Label>
                                <Col sm={10} >
                                    <Input
                                        type="textarea"
                                        name="description"
                                        onChange={(e) => this.handleChange(e)}
                                        maxLength={1000}
                                    />
                                </Col>
                            </FormGroup>

                            <b> Who does this? </b>

                            <FormGroup row className="pl-3">
                                <Label sm={2}> Type </Label>
                                <Col sm={4} >
                                    <CustomSelect
                                        isSearchable isClearable
                                        placeholder={""}
                                        options={assignTypes}
                                        onChange={(e) => this.handleSelect(e, 'assignType')}
                                    />
                                </Col>
                                <Label sm={2}> Name </Label>
                                <Col sm={4} >
                                    <Input
                                        type="text"
                                        name="assignValue"
                                        required
                                        onChange={(e) => this.handleChange(e)}
                                        maxLength={100}
                                    />
                                </Col>
                            </FormGroup>

                            <b> Stage Goal </b>

                            <FormGroup row className="pl-3">
                                <Label sm={2}> Goal Type </Label>
                                <Col sm={4} >
                                    <CustomSelect
                                        isSearchable isClearable
                                        placeholder={""}
                                        options={goalType}
                                        onChange={(combo) => this.setState({ stage: { ...this.state.stage, subType: { ...this.state.stage.subType, goalType: { ...this.state.stage.subType.goalType, type: combo ? combo.value : null } } } })}
                                        //value={this.getReviewField(question.value)}
                                    />
                                </Col>
                                <Label sm={2}> Goal Sub Type </Label>
                                <Col sm={4} >
                                    <CustomSelect
                                        isSearchable isClearable
                                        placeholder={""}
                                        options={goalSubType}
                                        onChange={(combo) => this.setState({ stage: { ...this.state.stage, subType: { ...this.state.stage.subType, subType: combo ? combo.value : null } } })}
                                        //value={this.getReviewField(question.value)}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(StageModal)