import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Badge, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';

class SelectedFilters extends Component {
    ProfileReservations = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    render() {
        const { tags, countries, consents, selectedGraph, dataOutputs, dimensions, profileType } = this.props;
        const reviews = this.props.reviews ? Object.entries(this.props.reviews).filter(el => typeof el[1] !== 'boolean' && el[1] > 0) : [];

        return (
            <>
                <Row className="my-2">
                    <Col className="text-center">
                        <b><FormattedMessage id="ProfileAnalysis.SelectedFilters" /></b>
                    </Col>
                </Row>
                <Card className="p-2 border-0 bg-white shadow">

                <Row className="mb-1">
                        <Col className="col-2">
                            <b className="text-muted">Layout</b>
                        </Col>
                        <Col>    
                            {selectedGraph === 'Table' ? < i className="fas fa-border-all "></i> : 
                                selectedGraph === 'Pie_Chart' ? <i className="fas fa-chart-pie "></i> :
                                    selectedGraph === 'Line_Chart' ? <i className="fas fa-chart-line "></i> :
                                        selectedGraph === 'Bar_Chart' ? <i className="fas fa-chart-bar "></i> :
                                            selectedGraph === 'World_Map' ? <i className="fas fa-globe-europe "></i> :
                                                selectedGraph === 'Three_Map' ? <i className="fas fa-th-large "></i> :
                                                    ''
                                    }
                        </Col>
                        <Col  className="col-1 text-right">
                            <Button className=" btn btn-clever btn-sm " onClick={this.props.getAnaliticsData}>
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                </Row>
                <Row className="mb-1">
                        
                            <Col className="col-2">
                                <b className="text-muted">Output</b>
                            </Col>
                      <Col>
                            <div>
                                <i onClick={this.props.changeDimensionOrder} className={"fas mr-1 " + (this.props.dimensionOrderAsc === null ? ' text-muted ' : ' text-clever ')  + (this.props.dimensionOrderAsc ? "fa-sort-alpha-down" : "fa-sort-alpha-up")} style={{ cursor: "pointer" }}></i>
                            {dataOutputs[0].dimensions && dataOutputs[0].dimensions.length > 0 ?
                                    dataOutputs[0].dimensions.map((dimension, key) =>
                                        <span key={key}><Badge className="p-2 mr-1 mb-1" color="clever"> {dimension} </Badge> </span>
                                )
                            : 'No dimensions selected'}
                        </div>
                            <div>
                                <i onClick={this.props.changeMetricsOrder} className={"fas mr-1 " + (this.props.metricOrderAsc === null ? ' text-muted ' : ' text-clever ') + (this.props.metricOrderAsc ? " fa-sort-numeric-down " : "fa-sort-numeric-up ")} style={{ cursor: "pointer" }}></i>
                            {dataOutputs[0].metrics && dataOutputs[0].metrics.length > 0 ?
                                dataOutputs[0].metrics.map((metric, key) =>
                                    <span key={key}><Badge className="p-2 mr-1 mb-1" color="clever"> {metric} </Badge> </span>
                                )
                            :'No metrics selected'}
                        </div>

                    </Col>
                </Row>

                <Row className="mb-1 mt-2">
                        <Col className="col-2">
                            <b className="text-muted">Filters</b>
                        </Col>
                        <Col>
                            {countries && countries.length > 0 ?
                                <Row>
                                    <Col className="col-2">
                                        <b>País:</b>
                                    </Col>
                                    <Col>
                                        {countries && countries.map((country, key) =>
                                            <Badge className="p-2 my-1 mr-2 badge-clever" key={key}>
                                                {country.label}
                                            </Badge>
                                        )}
                                    </Col>
                                </Row>
                            : ''}

                            {tags && tags.length > 0 ?
                                <Row>
                                    <Col className="col-2">
                                        <b>Tags:</b>
                                    </Col>
                                    <Col>
                                        {tags && tags.map((tag, key) =>
                                            <Badge className="p-2 my-1 mr-2 badge-clever" color="primary" key={key}>
                                                {tag.code}
                                            </Badge>
                                        )}
                                    </Col>
                                </Row>
                                : ''}

                            {reviews && reviews.length > 0 ? 
                                <Row>
                                    <Col className="col-2">
                                        <b>Reviews:</b>
                                    </Col>
                                    <Col>
                                        {reviews.map((review, key) =>
                                            <Badge className="p-2 my-1 mr-2 badge-clever" key={key}>
                                                <FormattedMessage id={`ProfileReview.${review[0][0].toUpperCase() + review[0].slice(1)}`} />
                                                {this.props.reviews[`${review[0]}BiggerOrEqualThan`] ? ' >= ' : ' <= '} {review[1]}
                                            </Badge>
                                        )}
                                    </Col>
                                </Row>
                            : ''}

                            {consents && consents.length > 0 ?
                                <Row>
                                    <Col className="col-2 ">
                                        <b>Consents:</b>
                                    </Col>
                                    <Col>
                                        {consents && consents.map((consent, key) =>
                                            <Badge className="p-2 my-1 mr-2 badge-clever" color="primary" key={key}>
                                                {consent}
                                            </Badge>
                                        )}
                                    </Col>
                                </Row>
                            : ''}

                            {profileType ?
                                <Row>
                                    <Col className="col-2">
                                        <b>Profile Type:</b>
                                    </Col>
                                    <Col>
                                        <Badge className="p-2 my-1 mr-2 badge-clever">
                                            {profileType}
                                        </Badge>
                                    </Col>
                                </Row>
                            : ''}
                        </Col>

                    </Row>
                </Card>
            </>
        );
    }
}
export default injectIntl(SelectedFilters)