import React, { Component } from 'react';
import { Button, Card, Col, Row, CustomInput } from 'reactstrap';
import { FormattedMessage } from "react-intl";
import { DefaultPage, StyledCard } from '../../Base/CommonUIComponents';
import Authorization from '../../Base/Authorization';
import { deleteAPI, getAPI, patchAPI } from '../../Base/API';
import { PreviewSendGridTemplate } from './PreviewSendGridTemplate';
import { SendGridEmailModal } from './SendGridEmailModal';
import ConfirmActionModal from '../../Base/ConfirmActionModal';
import { handleNotification } from '../../Base/Notification';
import { Pagination } from '../../Base/PaginationComponents';
import { SendGridTemplateValidationsHelper } from '../../Base/TemplateByCountry';

export class SendGridTransactionalTemplateList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            blockSenders: true,
            blockTemplates: true,
            templates: [],
            previewTemplate: false,
            selectedTemplateId: null,
            selectedTemplateName: null,
            sendEmailModal: false,
            deleteTemplate: false,
            deleteTemplateAdmin: false,
            page: 1,
            pageSize: 10,
            pagination: [this.getNewPagination(1)],
            changeName: false
        };
    }

    getNewPagination = (page) => {
        const newPagination = {
            prevToken: null,
            nextToken: null,
            page: page
        };
        return newPagination;
    }

    componentDidMount() {
        this.getTemplates();
        this.getSenders();
    }

    getTemplates(token) {
        const { page, pageSize, pagination } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorTemplates: errorMessage, blockTemplates: false });
                return;
            }
            if (data && data.response && data.response.result) {
                const templates = data.response.result
                    .filter(resp => resp.versions.some(vrs => vrs.active === 1))
                    .map(resp => {
                        const template = {
                            id: resp.id,
                            name: resp.name
                        }
                        const version = resp.versions.find(vrs => vrs.active === 1);
                        if (version) {
                            template.thumbnail_url = version.thumbnail_url.startsWith('//') ? `https:${version.thumbnail_url}` : `https://${version.thumbnail_url}`;
                            template.subject = version.subject;
                        }
                        return template;
                    });

                if (data.response._metadata) {
                    const paginationItem = pagination.find(p => p.page === page);
                    if (paginationItem) {
                        paginationItem.prevToken = data.response._metadata.self ? (new URLSearchParams(new URL(data.response._metadata.self).searchParams)).get("page_token") : null;
                        paginationItem.nextToken = data.response._metadata.next ? (new URLSearchParams(new URL(data.response._metadata.next).searchParams)).get("page_token") : null;
                    }
                }

                this.setState({ errorTemplates: errorMessage, blockTemplates: false, templates: templates, pagination });
            }
            else {
                this.setState({ errorTemplates: errorMessage, blockTemplates: false });
            }
        }, `/api/gms/SendGrid/v1/templates?generations=legacy,dynamic&page=${pageSize}${token ? `&token=${token}` : ``}`);
    }

    getSenders() {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorSenders: errorMessage, blockSenders: false });
                return;
            }
            if (data && data.response && data.response.results && data.response.results.length > 0) {
                const senders = data.response.results
                    .filter(resp => resp.verified)
                    .map(resp => {
                        const sender = {
                            value: resp.from_email,
                            label: `${resp.from_name}(${resp.from_email})`,
                            name: resp.from_name
                        };
                        return sender;
                    });
                this.setState({ errorSenders: errorMessage, blockSenders: false, senders: senders });
            }
            else {
                this.setState({ errorSenders: errorMessage, blockSenders: false });
            }
        }, `/api/gms/SendGrid/v1/sender?limit=100`);
    }

    editTemplateName(id, name) {
        this.setState({ blockTemplates: true }, () => {
            patchAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    handleNotification(data);
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ errorTemplates: errorMessage, blockTemplates: false, changeName: false, editTemplateId: null, editTemplateName: null });
                    return;
                }
                if (data && data.response) {
                    handleNotification(data, <FormattedMessage id="SendGridTemplate.EditedName" />, <FormattedMessage id="generic.success" />);
                    this.setState({ errorTemplates: errorMessage, changeName: false, editTemplateId: null, editTemplateName: null }, () => this.getTemplates());
                }
                else {
                    this.setState({ errorTemplates: errorMessage, blockTemplates: false, changeName: false, editTemplateId: null, editTemplateName: null });
                }
            }, `/api/gms/SendGrid/v1/templates/${id}?name=${name}`);
        })
    }
    

    togglePreviewModal = (templateId, templateName) => {
        this.setState((prevState => ({ previewTemplate: !prevState.previewTemplate, selectedTemplateId: templateId, selectedTemplateName: templateName })));
    }

    toggleSendSendGridEmailModal = (templateId, templateName) => {
        this.setState((prevState => ({ sendEmailModal: !prevState.sendEmailModal, selectedTemplateId: templateId, selectedTemplateName: templateName })));
    }

    toggleDeleteModal = (templateId, templateName, isTemplateUsed) => {
        if(isTemplateUsed === null || isTemplateUsed === undefined){
            this.setState({ deleteTemplate: false, deleteTemplateAdmin: false, selectedTemplateId: templateId, selectedTemplateName: templateName });
        }else{
            this.setState({ deleteTemplate: !isTemplateUsed, deleteTemplateAdmin: isTemplateUsed, selectedTemplateId: templateId, selectedTemplateName: templateName });
        }
    }

    removeTemplate = (e) => {
        e.preventDefault();
        const { selectedTemplateId } = this.state;
        this.setState({ blockTemplates: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorTemplates: errorMessage, blockTemplates: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="TransactionalTemplateList.TemplateRemoved" />, <FormattedMessage id="generic.success" />);
                    this.toggleDeleteModal();
                    this.getTemplates();
                }
            }
            this.setState({ errorTemplates: errorMessage, blockTemplates: false });
        }, `/api/gms/SendGrid/v1/templates/${selectedTemplateId}`);
    }

    getTemplateCampaignUsage = (e, templateId, templateName) => {
        e.preventDefault();
        this.setState({ blockTemplates: true }, () =>

            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ errorTemplates: errorMessage, blockTemplates: false });
                    return;
                }
                if (data) {
                    if (data.Errors && data.Errors.length > 0) {
                        handleNotification(data);
                        this.setState({ errorTemplates: errorMessage, blockTemplates: false });
                    }
                    else if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ errorTemplates: errorMessage, blockTemplates: false });
                    }
                    else if (data.response && data.response.length > 0){
                        if(data.response[0].isUsed){
                            handleNotification(data, <FormattedMessage id="TransactionalTemplateList.TemplateIsUsed" />, '', "info");
                        }
                        
                        this.setState({
                            deleteTemplate: !data.response[0].isUsed, 
                            deleteTemplateAdmin: data.response[0].isUsed, 
                            selectedTemplateId: templateId, 
                            selectedTemplateName: templateName,
                            errorTemplates: errorMessage, 
                            blockTemplates: false}, () => this.toggleDeleteModal(templateId, templateName, data.response[0].isUsed)
                        );
                    }else{
                        this.setState({ errorTemplates: errorMessage, blockTemplates: false });
                    }
                }
                
            }, `/api/gms/SendGrid/v1/template/${templateId}/CampaignUsage`));
    }

    handlePagination = (token, newPage) => {
        const { pagination } = this.state;
        const hasPagination = pagination.some(p => p.page === newPage);
        if (!hasPagination) {
            pagination.push(this.getNewPagination(newPage));
        }

        this.setState({ blockTemplates: true, page: newPage, pagination }, _ => this.getTemplates(token));
    }

    handleNameChange = (e) => {
        this.setState({ editTemplateName: e?.target?.value });
    }

    editNameTemplate = (id, name) => {
        this.setState({ changeName: true, editTemplateId: id, editTemplateName: name });
    }
    
    render() {
        const { blockTemplates, blockSenders, deleteTemplate, deleteTemplateAdmin, errorTemplates, errorSenders, page, pagination, previewTemplate, selectedTemplateId, selectedTemplateName, senders, sendEmailModal, templates, changeName, editTemplateName, editTemplateId } = this.state;

        return (
            <StyledCard block={blockTemplates || blockSenders} error={errorTemplates || errorSenders}>
                {
                    previewTemplate ?
                        <PreviewSendGridTemplate
                            isOpen={previewTemplate}
                            toggle={this.togglePreviewModal}
                            templateId={selectedTemplateId}
                            templateName={selectedTemplateName}
                        />
                        :
                        ''
                }
                {
                    sendEmailModal ?
                        <SendGridEmailModal
                            isOpen={sendEmailModal}
                            toggle={this.toggleSendSendGridEmailModal}
                            templateId={selectedTemplateId}
                            templateName={selectedTemplateName}
                            senders={senders}
                        />
                        :
                        ''
                }
                {
                    deleteTemplate ?
                        <ConfirmActionModal
                            modal={deleteTemplate}
                            toggleModal={this.toggleDeleteModal}
                            actionFunction={(e) => this.removeTemplate(e, selectedTemplateId)}
                            text={<FormattedMessage id="TransactionalTemplateList.ConfirmDeleteText" values={{ templateName: selectedTemplateName }} />}
                            block={blockTemplates}
                        />
                    :
                    deleteTemplateAdmin ?
                        <Authorization
                            perform="transactionalTemplateList:delete"
                            yes={() => (
                                <ConfirmActionModal
                                    modal={deleteTemplateAdmin}
                                    toggleModal={this.toggleDeleteModal}
                                    actionFunction={(e) => this.removeTemplate(e, selectedTemplateId)}
                                    text={<FormattedMessage id="TransactionalTemplateList.ConfirmDeleteText" values={{ templateName: selectedTemplateName }} />}
                                    block={blockTemplates}
                                />
                            )}
                            no={() => <div></div>}
                        />
                    :
                        ''
                }
                <Row className="mb-4 py-1">
                    <Col>
                        <h5> <i className={this.props.page?.icon} /> {this.props.page?.name} </h5>
                    </Col>
                    <span className="ml-auto text-nowrap  border-top-0 border-left-0 border-right-0 d-flex align-items-center">
                        <SendGridTemplateValidationsHelper />
                    </span>
                </Row>

                    {templates.length > 0 ?
                        <Row>
                            {templates.map((template, key) =>
                                <Col key={key} sm={3} className="mb-3 px-2">
                                    <Card className="border-0 px-3 py-2 h-100 shadow d-flex justify-content-between">
                                        <div>
                                            <Row>
                                                <Col>
                                                    {changeName && editTemplateId == template.id ? 
                                                        <Row>
                                                            <Col className="col-8">
                                                                <CustomInput
                                                                    value={editTemplateName}
                                                                    type="text"
                                                                    name="resIdValue"
                                                                    onChange={this.handleNameChange}
                                                                />
                                                            </Col>
                                                            <Col className="text-right">
                                                                <Button disabled={editTemplateName ? false : true} className="btn btn-sm btn-host" onClick={() => this.editTemplateName(template.id, editTemplateName)}>
                                                                    <i class="fas fa-save"></i>
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        :
                                                        <h4 className="m-0">
                                                            {template.name}
                                                        </h4>
                                                        }
                                                </Col>
                                            </Row>
                                            <div className="mb-2 ">{template.subject}</div>
                                            <Row className="mb-3" >
                                                <Col className="text-right pl-0">
                                                    <Button className="btn btn-host btn-sm" onClick={() => this.editNameTemplate(template.id, template.name)}>
                                                        <i class="far fa-edit"></i>
                                                    </Button>
                                                    <Button className="btn btn-host btn-sm ml-2" onClick={() => this.togglePreviewModal(template.id, template.name)}>
                                                        <i className="icon-icon-preview" />
                                                    </Button>
                                                    <Button className="btn btn-sm btn-host ml-2" onClick={() => this.toggleSendSendGridEmailModal(template.id, template.name)}>
                                                        <i className="fas fa-envelope" />
                                                    </Button>
                                                    <Button className="btn btn-sm btn-host ml-2" onClick={(e) => this.getTemplateCampaignUsage(e, template.id, template.name)}>
                                                        <i className="far fa-trash-alt"></i>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div style={{ width: '100%', height: 'auto' }}>
                                            <img src={template.thumbnail_url} style={{ width: 'inherit' }} />
                                        </div>
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    : <DefaultPage text="generic.NoEgoiTemplatesAvailable" icon={this.props.page?.icon} />}
                <Row className="my-4">
                    <Col className="text-right mr-3">
                        <Pagination
                            isPrevDisabled={page === 1}
                            isNextDisabled={pagination[page - 1].nextToken === null}
                            currentPage={page}
                            handlePrevButton={_ => this.handlePagination(pagination[page - 2].prevToken, page - 1)}
                            handleNextButton={_ => this.handlePagination(pagination[page - 1].nextToken, page + 1)}
                        />
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}