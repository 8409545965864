import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Col, Form, Input, Row, Modal, ModalBody, Label } from "reactstrap";
import { handleNotification } from "../Base/Notification";
import { BlankCard } from "../Base/CommonUIComponents";
import { getAPI, putAPI } from "../Base/API";
import moment from "moment-timezone";

class ConnectionModal extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.form = React.createRef();
        this.state = {
            block: false,
            selectedConfig: this.props.selectedConfig || {},
            googleData: null,
            invalidToken: false
        };
    }

    componentDidMount(){
        if (this.props.isGoogle) this.getGoogleData();
        if (this.props.selectedConfig?.type == 'GoogleWallet') this.getGoogleWalletData();
    }

    getGoogleData = () => {
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ invalidToken: true, block: false });
                        return;
                    }
                    if(data.response && data.response[0]){
                        this.setState({ googleData : data.response[0], block: false });
                        return;
                    }
                }
                this.setState({ block: false });
            }, `/api/gms/Google/v1/GoogleData`);
        });
    }

    getGoogleWalletData = () => {
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ invalidToken: true, block: false });
                        return;
                    }
                    if (data.response && data.response[0]) {
                        this.setState({ googleWalletData: data.response[0], block: false });
                        return;
                    }
                }
                this.setState({ block: false });
            }, `/api/gms/Wallet/v1/Issuer`);
        });
    }

    saveNewKey = (event, connecion) => {
        event.preventDefault();
        this.setState({ block: true });
        const { apiKey, secret, key } = this.state;

        const sharedSecret = connecion.type === 'ReviewPro' || connecion.type === 'WhatsApp' || connecion.type === 'Typeform' || connecion.type === 'MicrosoftOffice365' ? `&secret=${secret}` : '';

        putAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="CurrentHotelGroup.ConnectionKeySaved" />, <FormattedMessage id="generic.success" />);
                }
                this.props.toggleModal();
            }
            this.setState({ block: false });
        }, `/api/gms/Hotel/v1/hotelGroup/apiConnection/${connecion.id}/key?key=${key}${!apiKey ? '' : `&apiKey=${apiKey}`}` + sharedSecret);
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        })
    }

    refreshToken = () => {
        this.props.history.push('/GoogleBusinessConfig/');
        this.props.history.go();
    }

    render() {
        const { selectedConfig, googleData, googleWalletData,invalidToken } = this.state;
        const { clearGoogleData } = this.props;

        return (
            <Modal isOpen={this.props.modal} fade={false} className="modal-xl" >
                <Button onClick={this.props.toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={this.state.block} error={this.state.error}>
                        <Form onSubmit={(e) => this.saveNewKey(e, selectedConfig)}>
                            <Row className="mb-3">
                                <Col>
                                    <h5>
                                        {selectedConfig.type === "GoogleBusiness" && googleData && !invalidToken ?
                                            <FormattedMessage id="CurrentHotelGroup.GoogleBusinessAccount" />
                                        :
                                            <FormattedMessage id="CurrentHotelGroup.SetNewKey" />
                                        }
                                    </h5>
                                </Col>
                                <Col className="text-right">
                                    {selectedConfig.type === "GoogleBusiness" && googleData ?
                                        <>
                                            <Button className="btn-sm btn-host" type="button" onClick={clearGoogleData}>
                                                <i className="fas fa-sign-out-alt"/>
                                            </Button>
                                            {invalidToken ?
                                                <Button onClick={this.refreshToken} className="btn-sm btn-host ml-2" type="button">
                                                    <i className="fas fa-sync"></i>
                                                </Button>
                                            :''}
                                        </>
                                    :
                                        <Button className="btn-sm btn-host" type="submit">
                                            <i className="fas fa-save" />
                                        </Button>
                                    }
                                </Col>
                            </Row>


                            {selectedConfig.type === "WhatsApp" ?
                                <>
                                    <Row className="mb-3">
                                        <Label className="col-2"><FormattedMessage id="CurrentHotelGroup.WhatsAppBusinessAccountID" /></Label>
                                        <Col className="col-10">
                                            <Input
                                                name="key"
                                                onChange={this.handleChange.bind(this)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label className="col-2"><FormattedMessage id="CurrentHotelGroup.AppID" /></Label>
                                        <Col className="col-10">
                                            <Input
                                                name="secret"
                                                onChange={this.handleChange.bind(this)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label className="col-2"><FormattedMessage id="CurrentHotelGroup.TemporaryAccessToken" /></Label>
                                        <Col className="col-10">
                                            <Input
                                                name='apiKey'
                                                onChange={this.handleChange.bind(this)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                </>
                            : selectedConfig.type === "FacebookContent" ?
                                <>
                                    <Row className="mb-3">
                                        <Label className="col-2"><FormattedMessage id="CurrentHotelGroup.FacebookAppID" /></Label>
                                        <Col className="col-10">
                                            <Input
                                                name="key"
                                                onChange={this.handleChange.bind(this)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label className="col-2"><FormattedMessage id="CurrentHotelGroup.Secretkey" /></Label>
                                        <Col className="col-10">
                                            <Input
                                                name="secret"
                                                onChange={this.handleChange.bind(this)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label className="col-2"><FormattedMessage id="CurrentHotelGroup.TemporaryAccessToken" /></Label>
                                        <Col className="col-10">
                                            <Input
                                                name='apiKey'
                                                onChange={this.handleChange.bind(this)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                </>
                            : selectedConfig.type === "GoogleBusiness" ?
                                googleData && googleData.name && googleData.picture && !invalidToken ?
                                    <>
                                        <Row className="mb-3">
                                            <Col className="d-flex">
                                                <div style={{ borderRadius: '100%', overflow: 'hidden' }}>
                                                    <img src={googleData.picture} alt="Profile Picture" height={'40'} width={'40'}/>
                                                </div>
                                                <div className="ml-2">
                                                    <div className="title-sm">
                                                        <FormattedMessage id="CurrentHotelGroup.Name"/>
                                                    </div>
                                                    <div>
                                                        {googleData.name}
                                                    </div>
                                                </div>
                                                <div className="ml-2">
                                                    <div className="title-sm">
                                                        <FormattedMessage id="CurrentHotelGroup.Issued"/>
                                                    </div>
                                                    <div>
                                                        {moment(googleData.issued).local().format('DD-MM-YYYY HH:mm')}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                :
                                    <div className="text-center">
                                        <b><FormattedMessage id="CurrentHotelGroup.InvalidToken"/></b>
                                    </div>
                            : selectedConfig.type === "StripoOwnAccount" || selectedConfig.type === "StripoSharedAccount" ?
                                <>
                                    <Row>
                                        <Label className="col-2"><FormattedMessage id="CurrentHotelGroup.pluginId" /></Label>
                                        <Col className="col-10">
                                            <Input
                                                name="key"
                                                onChange={this.handleChange.bind(this)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Label className="col-2"><FormattedMessage id="CurrentHotelGroup.Secretkey" /></Label>
                                        <Col className="col-10">
                                            <Input
                                                name="secret"
                                                onChange={this.handleChange.bind(this)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                </>
                            : selectedConfig.type === 'ReviewPro' ?
                                <>
                                    <Row>
                                        <Label className="col-2"><FormattedMessage id="CurrentHotelGroup.NewKey" /></Label>
                                        <Col className="col-10">
                                            <Input
                                                name={"key"}
                                                onChange={this.handleChange.bind(this)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label className="col-2"><FormattedMessage id="CurrentHotelGroup.SharedSecret" /></Label>
                                        <Col className="col-10">
                                            <Input
                                                name="secret"
                                                onChange={this.handleChange.bind(this)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                </>
                            : selectedConfig.type === 'Typeform' ?
                                <div>
                                    <Row className="mb-3">
                                        <Label className="col-2"><FormattedMessage id="CurrentHotelGroup.NewKey" /></Label>
                                        <Col className="col-10">
                                            <Input
                                                name="key"
                                                onChange={this.handleChange.bind(this)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label className="col-2"><FormattedMessage id="CurrentHotelGroup.ClientId" /></Label>
                                        <Col className="col-10">
                                            <Input
                                                name="secret"
                                                onChange={this.handleChange.bind(this)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                </div>
                             : selectedConfig.type === 'MicrosoftOffice365' ?
                                <div>
                                    <Row className="mb-3">
                                        <Label className="col-2"><FormattedMessage id="CurrentHotelGroup.Tenant" /></Label>
                                        <Col className="col-10">
                                            <Input
                                                name="key"
                                                onChange={this.handleChange.bind(this)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label className="col-2"><FormattedMessage id="CurrentHotelGroup.ClientId" /></Label>
                                        <Col className="col-10">
                                            <Input
                                                name="secret"
                                                onChange={this.handleChange.bind(this)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label className="col-2"><FormattedMessage id="CurrentHotelGroup.ClientSecret" /></Label>
                                        <Col className="col-10">
                                            <Input
                                                name="apiKey"
                                                onChange={this.handleChange.bind(this)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                                        </div>
                                                        : selectedConfig.type === "GoogleWallet" ?
                                                            googleWalletData && googleWalletData.name ?
                                                                <>
                                                                    <Row className="mb-3">
                                                                        <Col className="d-flex">
                                                                            <div className="ml-2">
                                                                                <div className="title-sm">
                                                                                    <FormattedMessage id="CurrentHotelGroup.IssuerId" />
                                                                                </div>
                                                                                <div>
                                                                                    {googleWalletData.issuerId}
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col className="d-flex">
                                                                            <div className="ml-2">
                                                                                <div className="title-sm">
                                                                                    <FormattedMessage id="CurrentHotelGroup.Name" />
                                                                                </div>
                                                                                <div>
                                                                                    {googleWalletData.name}
                                                                                </div>
                                                                            </div>
                                                                        </Col>



                                                                    </Row>
                                                                </>
                                                                :
                                                                <div className="text-center">
                                                                    <b><FormattedMessage id="CurrentHotelGroup.InvalidToken" /></b>
                                                                </div>
                            :
                                <Row>
                                    <Label className="col-2"><FormattedMessage id="CurrentHotelGroup.NewKey" /></Label>
                                    <Col className="col-10">
                                        <Input
                                            name={"key"}
                                            onChange={this.handleChange.bind(this)}
                                            required
                                        />
                                    </Col>
                                </Row>
                            }
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(ConnectionModal)