import React from 'react';
import { FormattedMessage } from 'react-intl';

//Return text message to show in reply box for all msg types
export const getTextOfMessageForReplyBox = (message) => {
    if (message) {
            if (message.body) {
                if (message.type === 'Template') {
                    const json = JSON.parse(message.body);

                    return <span> <i className="fas fa-image fa-sm " /> {json?.components?.find(el => el.type === 'BODY')?.text} </span>
                }
                else if (message.type === 'Location') {
                    return <span> <i className="fas fa-xs fa-map-marker-alt mr-1" />
                        <FormattedMessage id="WhatsApp.Location" />: {message.body.latitude}, {message.body.longitude}
                    </span>;
                }
                else if (message.type === 'Contact') {
                    return `Contacto`;
                }
                else if (message.type === 'Image') {
                    return <span> <i className="fas fa-xs fa-camera mr-1" /> <FormattedMessage id="MediaType.Image"/> </span>;
                }
                else if (message.type === 'Sticker') {
                    return <span className="d-flex align-items-center"> <i className="fas fa-xs fa-camera mr-1" /> Sticker </span>;
                }
                else if (message.type === 'Document') {
                    return <span> <i className="fas fa-xs fa-file-alt mr-1"/> {message.body.filename}</span>;
                }
                else if (message.type === 'Audio') {
                    return <span> <i className="fas fa-xs fa-microphone mr-1" /> <FormattedMessage id="MediaType.Audio" /> </span>;
                }
                else {
                    return message.body || ''
                }
            }
    }

    return '';
};