import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Modal, ModalBody, Input } from 'reactstrap';
import { handleNotification } from "../Base/Notification";
import { BlankCard } from '../Base/CommonUIComponents';
import CustomSelect from "../Base/CustomSelect";
import { postAPI, getAPI } from "../Base/API";

class RuleTransformDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            ruleTransform: this.props.selectedRuleTransform ? this.props.selectedRuleTransform : {},
            rules: []
        };
    }

    componentDidMount() {
        this.getTransformRules()
    }

    getTransformRules() {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                let list = [];

                data.response && data.response.map((el, k) =>
                    list.push({
                        label: el.code,
                        value: el.id,
                        description: el.description
                    })
                );

                this.setState({ rules: list });
            }
            this.setState({ error: errorMessage, block: false });
        }, '/api/gms/DataQuality/v1/regularExpressionRule?type=Transform');
    }

    handleChange = (e, key) => {
        const { name, value } = e.target;

        this.setState({
            ruleTransform: {
                ...this.state.ruleTransform,
                [name]: value
            }
        })
    }

    handleChangeSelect = (combo) => {
        this.setState({
            ruleTransform: {
                ...this.state.ruleTransform,
                regularExpressionRuleId: combo ? combo.value : null
            }
        })
    };

    saveRuleFilter = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var ruleTransform = { ...this.state.ruleTransform };
        ruleTransform.ruleId = ruleTransform.ruleId ? ruleTransform.ruleId : this.props.ruleId;

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="RuleTransformDetails.RuleTransformSaved" />, <FormattedMessage id="generic.success" />);

                    const rule = this.props.ruleList.find(el => el.id === data.response[0].regularExpressionRuleId);

                    if(rule){
                        const regularExpressionRule = {
                            code: rule.code,
                            description: rule.description,
                            id: rule.id,
                            regularExpression: rule.regularExpression,
                            regularExpressionTransform: rule.regularExpressionTransform,
                            regularExpressionType: rule.regularExpressionType,
                        }
    
                        this.props.updateTable({
                            ...data.response[0],
                            regularExpressionRule: regularExpressionRule
                        },
                            'rulesRegexTransform');
                    }
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/DataQuality/v1/ruleTransform`, JSON.stringify({ request: ruleTransform }));
    }

    render() {
        const { block, error, ruleTransform, rules } = this.state;
        const regularExpressionRule = ruleTransform.regularExpressionRuleId && rules.find(el => el.value === ruleTransform.regularExpressionRuleId);

        return (
            <Modal isOpen={this.props.modal}>
                <Button onClick={this.props.toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <Form onSubmit={this.saveRuleFilter}>
                            <Row className="mb-3">
                                <Col>
                                    <h5> {this.props.selectedRuleTransform ? <FormattedMessage id="RuleTransformDetails.EditRuleTransform" /> : <FormattedMessage id="RuleTransformDetails.AddRuleTransform" />}</h5>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" type="submit">
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>

                            <FormGroup row>
                                <Label sm={2}> <FormattedMessage id="RuleTransformDetails.Name" /></Label>
                                <Col sm={10}>
                                    <Input
                                        type="text"
                                        name="name"
                                        required
                                        placeholder={""}
                                        onChange={(e) => this.handleChange(e)}
                                        value={ruleTransform.name || ''}
                                        maxLength="200"
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="mb-1">
                                <Label sm={2}> <FormattedMessage id="RuleTransformDetails.TranformRule" /></Label>
                                <Col sm={10}>
                                    <CustomSelect
                                        options={rules}
                                        required
                                        isClearable
                                        isSearchable
                                        onChange={this.handleChangeSelect.bind(this)}
                                        placeholder={<FormattedMessage id="RuleDetail.SelectType" />}
                                        value={regularExpressionRule}
                                    />
                                </Col>
                            </FormGroup>
                            <Row>
                                <Col sm={2}/>
                                <Col>
                                    <b>{regularExpressionRule && regularExpressionRule.description}</b>
                                </Col>
                            </Row>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(RuleTransformDetails)