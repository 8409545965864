import React, { Component } from 'react';
import { BlankCard } from '../../../../Base/CommonUIComponents';
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from '../../../../Base/Notification';
import { Button, Card, Form, Input, Modal, ModalBody, Row, Col } from 'reactstrap';
import TemplatePreview from '../../../../Marketing/WhatsApp/TemplatePreview';
import { getEmojis } from '../../../../Base/ReferenceDataFunctions';
import SelectTemplate from './SelectTemplate';
import MapModal from '../../../../Base/MapModal';
import SelectMedia from './SelectMedia';

export class SendMessage extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            blockMedia: false,
            templatesModal: false,
            pageSize: 8,
            pageIndex: 0,
            totalMedia: 0,
            selectedMedia: {},
            localizationModal: false,
            location: { latitude: 0, longitude: 0 }
        }
    }

    sendLocation = (e) => {
        this.toggleModal('locationModal');

        this.props.sendMessage(e, null, null, null, this.state.location );
    }

    toggleCollapse = (collapse1, collapse2) => {
        this.setState(prevState => ({
            [collapse1]: !prevState[collapse1],
            [collapse2]: !prevState[collapse1] === true ? false : prevState[collapse2]
        }))
    }

    toggleModal = (modal) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }))
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.getMedia());
    }

    mapOnClick = (latitude, longitude) => {
        this.setState(prevState => ({
            location: {
                ...prevState.location,
                latitude, longitude
            }
        }));
    }


    render() {
        const { hasMessages, newMessage, replyTo, hasMoreThan24h } = this.props;
        const { imageModal, templates, locationModal, location } = this.state;
        
        return (
            <>
                <Form onSubmit={this.props.sendMessage} className="mt-3" style={{ position: 'absolute', bottom: '0', width: '100%' }}>
                    <div className="p-2 pl-4" style={{ backgroundColor: '#f0f2f5' }}>
                        {this.props.mainContact ?
                            <>
                                <div>
                                    {replyTo ?
                                        <div className="d-flex">
                                            <div style={{ width: '15%' }} />
                                            <div className="mb-2 p-1 replyTo d-flex" style={{ width: '78%', whiteSpace: 'break-spaces', maxHeight: '35px' }}>
                                                <span style={{ width: '99%' }} className="pl-1">
                                                    {replyTo.message}
                                                </span>
                                                <div style={{ width: '2%', fontSize: '13px' }} >
                                                    <i className="fas fa-times pointer float-right px-1 text-muted" onClick={this.props.removeReplyTo} />
                                                </div>
                                            </div>
                                            <div style={{ width: '7%' }} />
                                        </div>
                                        : ''}
                                </div>
                                <div className=" d-flex align-items-center">
                                    <div style={{ width: '7%', cursor: 'pointer' }} onClick={() => this.setState({ templatesModal: true, showReactions: false, selectAttachment: false })} >
                                        Templates
                                    </div>

                                    {hasMoreThan24h ?
                                        <div className="text-center w-100"> <FormattedMessage id="WhatsApp.Passed24hText" /> </div>

                                        : hasMessages ?
                                            <>
                                            <div className="text-center" style={{ width: '4%' }}>
                                                <i className="far fa-smile pointer" onClick={() => this.toggleCollapse('showReactions', 'selectAttachment')} />

                                                {this.state.showReactions ?
                                                    <div className="bg-white shadow color-red" style={{ position: 'absolute', padding: '5px 10px', borderRadius: '10px', bottom: '55px', fontSize: '16px' }}>
                                                        {getEmojis().map((el, idx) => <span key={idx} className="mx-1 pointer" onClick={() => this.props.selectEmoji(el)}> {el} {idx === 9 && <br />} </span>)}
                                                    </div>
                                                : ''}
                                            </div>

                                            <div className="text-center" style={{ width: '4%' }}>
                                                <i className="fas fa-paperclip pointer" onClick={() => this.toggleCollapse('selectAttachment', 'showReactions')} />

                                                {this.state.selectAttachment ?
                                                    <div className="bg-white p-3 shadow" style={{ display: 'flex', position: 'absolute', bottom: '55px', borderRadius: '10px', left: '130px' }}>
                                                        <div className="d-flex flex-column align-items-center pointer" onClick={() => this.toggleModal('imageModal')}>
                                                            <div className="circle-md bg-purple color-white mb-1" >
                                                                <i className="far fa-image p-2" />
                                                            </div>
                                                            <span style={{ fontSize: '10px' }}>
                                                                <FormattedMessage id="MediaManagement.Media" />
                                                            </span>
                                                        </div>
                                                        <div className="ml-3 d-flex flex-column align-items-center pointer" onClick={() => this.toggleModal('locationModal')}>
                                                            <div className="circle-md bg-green color-white mb-1" >
                                                                <i className="fas fa-map-marker-alt p-2" />
                                                            </div>
                                                            <span style={{ fontSize: '10px' }}>
                                                                <FormattedMessage id="WhatsApp.Location" />
                                                            </span>
                                                        </div>
                                                        {/*<div className="pl-2 d-flex flex-column align-items-center pointer">
                                                            <div className="circle-md bg-cyan color-white py-2 mb-1" >
                                                                <i className="icon-icon-profile px-2" />
                                                            </div>
                                                            <span style={{ fontSize: '10px' }}> Contact </span>
                                                        </div>*/}
                                                    </div>
                                                    : ''}
                                            </div>
                                            <div className="text-center" style={{ width: '78%' }}>
                                                <Input
                                                    type="text"
                                                    name="newMessage"
                                                    placeholder={this.props.intl.formatMessage({ id: "WhatsApp.TypeAMessage" })}
                                                    onChange={(e) => this.props.handleChange(e)}
                                                    value={newMessage || ''}
                                                    required
                                                />
                                            </div>
                                        </>
                                        :

                                        <div className="scrollableDiv d-flex pb-2" style={{ overflow: 'auto', width: '93%' }}>
                                            {templates?.map((master, key) =>
                                                <TemplatePreview
                                                    history={this.props.history}
                                                    languages={master.templates?.map(({ language }) => language)}
                                                    name={master.name}
                                                    templates={master.templates}
                                                    key={key}
                                                    orderComponents={this.orderComponents}
                                                    components={master.components}
                                                    selectable={true}
                                                    selectTemplate={this.selectTemplate}
                                                    selectedTemplate={this.state.selectedTemplate?.id}
                                                />
                                            )}
                                        </div>
                                    }
                                    <button className="text-center ml-1" style={{ width: '7%', border: 'transparent' }}>
                                        <i className="fas fa-paper-plane pointer color-host" type="submit" />
                                    </button>
                                </div>
                            </>
                            :
                            <div className="text-center w-100"> <FormattedMessage id="WhatsApp.InvalidContactText" /> </div>
                        }
                    </div>

                </Form>

                {this.state.templatesModal ?
                    <SelectTemplate
                        sendMessage={this.props.sendMessage}
                        templatesModal={this.state.templatesModal}
                        toggleModal={() => this.toggleModal("templatesModal")}
                    />
                : ''}

                {locationModal ?
                    <SendLocationModal
                        locationModal={this.state.locationModal}
                        toggleModal={() => this.toggleModal('locationModal')}
                        location={location}
                        handleCoordinates={(name, value) => this.setState(prevState => ({ location: { ...prevState.location, [name]: value } })) }
                        mapOnClick={this.mapOnClick}
                        sendLocation={this.sendLocation}
                    />
                : ''}

                {imageModal ?
                    <SelectMedia
                        imageModal={imageModal}
                        toggleModal={() => this.toggleModal('imageModal')}
                        sendMessage={this.props.sendMessage}
                    />
                : ''}
            </>
        );
    }
}
export default injectIntl(SendMessage);




export const SendLocationModal = ({ locationModal, toggleModal, location, handleCoordinates, mapOnClick, sendLocation }) => {
    const getCoordinates = () => {
        navigator.geolocation.getCurrentPosition(position => {
             mapOnClick(position.coords.latitude, position.coords.longitude);
        }, showError);

        function showError(error) {
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    handleNotification(error.code, <FormattedMessage id="MapModal.PermissionDenied" />, '', 'info');
                    break;
                case error.POSITION_UNAVAILABLE:
                    handleNotification(error.code, <FormattedMessage id="MapModal.PositionUnvailable" />, '', 'info');
                    break;
                case error.TIMEOUT:
                    handleNotification(error.code, <FormattedMessage id="MapModal.Timeout" />, '', 'info');
                    break;
                case error.UNKNOWN_ERROR:
                    handleNotification(error.code, <FormattedMessage id="MapModal.UnknownError" />, '', 'info');
                    break;
            }
        }
    }

  
    return <Modal isOpen={locationModal} size={"lg"} style={{ minWidth: 0 }}>
        <Button onClick={toggleModal} className="closeModal">
            <i className="fas fa-times fa-sm"></i>
        </Button>
        <ModalBody>
            <BlankCard>
                <Form onSubmit={sendLocation}>
                    <Row className="mb-4">
                        <Col>
                            <h5> <FormattedMessage id="WhatsApp.SendLocation" /> </h5>
                        </Col>
                        <Col className="col-2 text-right">
                            <Button className="btn-host btn-sm" type="submit" >
                                <i className="fas fa-paper-plane" />
                            </Button>
                        </Col>
                    </Row>

                    <Row className="mb-4 align-items-center">
                        <Col className="col-2">
                            <FormattedMessage id="WhatsApp.Latitude" />
                        </Col>
                        <Col className="text-right">
                            <Input
                                type="text"
                                name="latitude"
                                placeholder=""
                                onChange={(e) => handleCoordinates('latitude', e.target?.value)}
                                value={location.latitude}
                                required
                            />
                        </Col>
                        <Col className="col-2">
                            <FormattedMessage id="WhatsApp.Longitude" />
                        </Col>
                        <Col className="text-right">
                            <Input
                                type="text"
                                name="longitude"
                                placeholder={""}
                                onChange={(e) => handleCoordinates('longitude', e.target?.value)}
                                value={location.longitude}
                                required
                            />
                        </Col>
                        <Col className="col-1 text-right">
                            <Button color=" btn-host btn-sm" onClick={getCoordinates} >
                                <i className="fas fa-map-marker-alt" style={{ padding: '0 2px' }} />
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mb-4 align-items-center">
                        <Col className="col-2">
                            <FormattedMessage id="WhatsApp.Name" />
                        </Col>
                        <Col className="text-right">
                            <Input
                                type="text"
                                name="name"
                                placeholder=""
                                onChange={(e) => handleCoordinates('name', e.target?.value)}
                                value={location.name}
                                required={location.address}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-4 align-items-center">
                        <Col className="col-2">
                            <FormattedMessage id="WhatsApp.Address" />
                        </Col>
                        <Col className="text-right">
                            <Input
                                type="text"
                                name="address"
                                placeholder={""}
                                onChange={(e) => handleCoordinates('address', e.target?.value)}
                                value={location.address}
                                required={location.name}
                            />
                        </Col>
                    </Row>
                </Form>

                <MapModal
                    mapHeight='60vh'
                    latitude={location.latitude}
                    longitude={location.longitude}
                    onClick={mapOnClick}
                />
            </BlankCard>
        </ModalBody>
    </Modal>
};