import React, { Component } from 'react';
import { Button, Col, Row, Label } from 'reactstrap';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import { handleNotification } from '../Base/Notification';
import { ErrorAlert } from '../Base/ErrorAlert';
import { DefaultPage, KebabMenu, SelectHotel } from '../Base/CommonUIComponents';
import { CommonHelper} from '../Base/CommonUIComponents';
import { getAPI } from '../Base/API';
import { getAllowedSalesProcessUsers, checkSalesProcessPermission } from './SalesProcessFunctions';

import BlockUi from 'react-block-ui';

class SalesProcessSpaceManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            spaces: [],
            setSpaceVisibilityPermission: false,
        };
    }

    componentDidMount() {
        let currentUser = getAllowedSalesProcessUsers()?.find(x => x.isCurrentUser);
        let setSpaceVisibilityPermission = checkSalesProcessPermission("SetSpaceVisibility", currentUser?.role);

        this.setState({ setSpaceVisibilityPermission }, () => this.getSpaces());
        
    }

    getSpaces = (e) => {
        if(e){
            e.preventDefault();
        }
        const { hotelId } = this.state;

        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if(data){
                    if (data?.errors?.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    if (data?.data?.length > 0) {
                        const spaces = data.data.sort((a, b) => a.sortOrder - b.sortOrder);
                        
                        this.setState({ spaces, block: false });
                        return;
                    }
                }
                this.setState({ block: false });
            }, `/api/gms/SalesProcess/v1/eventspaces${hotelId? `?hotelId=${hotelId}` : ''}`);
        });
    }

    setSpaceVisible = (selectedSpaceId, isVisible) => {
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if(data){
                    if (data?.errors?.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    if (data?.data?.length > 0) {
                        handleNotification(data, <FormattedMessage id="SalesProcessSpaceManagement.SpaceVisibilityChanged" />, <FormattedMessage id="generic.success" />);
                        this.getSpaces();
                        return;
                    }
                }
                this.setState({ block: false });
            }, `/api/gms/SalesProcess/v1/eventspaces/${selectedSpaceId}/visible?isVisible=${isVisible}`)
        });
    }

    handleSelectHotel = (evt, combo) => {
        this.setState({
            [evt]: combo ? combo.value : null
        })
    }

    render() {
        const { block, error, spaces, hotelId, setSpaceVisibilityPermission } = this.state;
        const { intl, icon } = this.props;
        
        return (
            <div className="border-0 h-100 pb-2">
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <div className='mb-4 d-flex align-items-center'>
                        <Col>
                        <h4 className='m-0'>
                            <i className={`${icon} mr-2`} />
                            <FormattedMessage id="SalesProcess.SalesProcessSpaceManagement" />
                            </h4>
                        </Col>
                        <Col className="pr-0">
                            <CommonHelper help={''} id={'SpaceManagementHelp'} />
                        </Col>

                    </div>

                    <Row className='mb-3'>
                        <Col className='col-3'>
                            <Label for="hotelId"> <FormattedMessage id="generic.Hotel" /></Label>
                            <SelectHotel
                                name={'hotelId'} 
                                icon={'icon-icon-hotel'}
                                onChangeFunc={this.handleSelectHotel}
                                value={hotelId}
                                placeholder={<FormattedMessage id="generic.Hotel" />}
                                params='?onlyFromHotelGroup=true'
                            />
                        </Col>
                        <Col className='d-flex align-items-end justify-content-end'>
                            <Button className="btn-sm btn-host btn" onClick={(e) => this.getSpaces(e)}>
                                <i className="fas fa-search" />
                            </Button>
                        </Col>
                    </Row>
                        {spaces?.length > 0 ?
                            <>
                                <div className='mt-4 col-12 pr-4 d-flex align-items-center shadow p-3 mb-3 bg-white text-muted' style={{ borderRadius: '4px', gap: '1%' }} >
                                    <div style={{ width: '18%' }}>
                                        <span>
                                            <FormattedMessage id="SalesProcessSpaceManagement.ShortName" />
                                        </span>
                                    </div>
                                    <div style={{ width: '18%' }}>
                                        <span>
                                            <FormattedMessage id="SalesProcess.Hotel" />
                                        </span>
                                    </div>
                                    <div style={{ width: '12%' }}>
                                        <span>
                                            <FormattedMessage id="SalesProcessSpaceManagement.Price" />
                                        </span>
                                    </div>
                                    <div style={{ width: '10%' }}>
                                        <span>
                                            <FormattedMessage id="SalesProcessSpaceManagement.Active" />
                                        </span>
                                    </div>
                                    <div style={{ width: '14%' }}>
                                        <span>
                                            <FormattedMessage id="SalesProcessSpaceManagement.IsSpaceCommunicant" />
                                        </span>
                                    </div>
                                    <div style={{ width: '10%' }}>
                                        <span>
                                            <FormattedMessage id="SalesProcessSpaceManagement.IsVisible" />
                                        </span>
                                    </div>
                                    <div style={{ width: '14%' }}>
                                        <span>
                                            <FormattedMessage id="SalesProcessSpaceManagement.SpaceTypeDescription" />
                                        </span>
                                    </div>
                                    <div style={{ width: '4%' }}>
                                    </div>
                                </div>
                                <div className='col-12 px-0 mt-2'>
                                    {spaces.map((space, index) => {
                                        return (
                                            <div className='d-flex align-items-center justify-content-between shadow p-3 mb-2 bg-white' key={index} style={{ borderRadius: '4px', gap: '1%' }}>
                                                    <div style={{ width: '18%' }}>
                                                        <span>
                                                            {space.shortName}
                                                        </span>
                                                    </div>
                                                    <div className='text-truncate' style={{ width: '18%' }}>
                                                        <span className="">
                                                            {space.hotelName}
                                                        </span>
                                                    </div>
                                                    <div style={{ width: '12%' }}>
                                                        <span>
                                                            <FormattedNumber
                                                                value={space.price}
                                                                maximumFractionDigits={2}
                                                                style="currency"
                                                                currency={global.hotelCurrency}
                                                            />
                                                        </span>
                                                    </div>
                                                    <div style={{ width: '10%' }}>
                                                        <span>
                                                            <FormattedMessage id={`generic.${space.active ? 'yes' : 'no'}`} />
                                                        </span>
                                                    </div>
                                                    <div style={{ width: '14%' }}>
                                                        <span>
                                                            <FormattedMessage id={`generic.${space.isSpaceCommunicant ? 'yes' : 'no'}`} />
                                                        </span>
                                                    </div>
                                                    <div style={{ width: '10%' }}>
                                                        <span>
                                                            <FormattedMessage id={`generic.${space.isVisible ? 'yes' : 'no'}`} />
                                                        </span>
                                                    </div>
                                                    <div style={{ width: '14%' }}>
                                                        <span>
                                                            {space.spaceTypeDescription ?? '-'}
                                                        </span>
                                                    </div>
                                                    <div className='text-right' style={{ width: '4%' }}>
                                                        {setSpaceVisibilityPermission ?
                                                            <KebabMenu
                                                                extraFields={[{
                                                                    function: _ => {this.setSpaceVisible(space.id, !space.isVisible)},
                                                                    icon: `far fa-eye${space.isVisible ? '-slash' : ''} mr-2`,
                                                                    text: `SalesProcessSpaceManagement.SetSpace${space.isVisible ? 'Not' : ''}Visible`
                                                                }]}
                                                            />
                                                        :''}
                                                    </div>
                                                </div>
                                        )
                                    })}
                                </div>
                            </>
                        :
                            <Col className='col-12'>
                                <DefaultPage
                                    icon='fas fa-store-alt'
                                    text={'SalesProcessSpaceManagement.NoSpacesConfigured'}
                                />
                            </Col>
                        }
                </BlockUi>
            </div>
        )
    }
}

export default injectIntl(SalesProcessSpaceManagement);
