import React, { Component } from 'react';
import { Row, Col, CardBody, Modal, ModalBody, Label, Card, CardHeader, FormGroup, Input, UncontrolledCollapse, Button } from 'reactstrap';
import { ReservationStatusBadge, BlankCard } from '../Base/CommonUIComponents';
import { FormattedMessage, injectIntl, FormattedNumber } from 'react-intl';
//import ReservationLog from './ReservationLog';
import { getAPI } from "../Base/API"
import { handleNotification } from "../Base/Notification";
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import moment from "moment";

class ReservationDetails extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            reservation: {},
            confirmModal: false,
        };
    }

    componentDidMount() {
        if (this.props.reservationId) {
            this.setState(({ block: true }));

            getAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    if (data.response && data.response.length > 0) {
                        this.setState(({ reservation: data.response[0] }));
                    }
                    this.setState(({ block: false }));
                }
            }, `/api/gms/Reservation/v1/GetReservationDetails/${this.props.reservationId}`)
        }
    }
    
    render() {
        const { reservation, block, error } = this.state;

        return (
            <Modal isOpen={this.props.modal} className="modal-lg" >
                <Button onClick={this.props.toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody className="modal-lg px-2 pb-2">
                    <BlankCard block={block} error={error}>
                        <Row className="mb-3">
                            <Col>
                                <h5><FormattedMessage id="ReservationDetails.ReservationDetails" /></h5>
                            </Col>
                        </Row>
                        {reservation ?
                            <div>
                                <Card className="mb-2 shadow border-0">
                                    <CardHeader className="pb-0 bg-white border-bottom">
                                        <h6>
                                            <span className="icon-icon-hotel mr-1" />
                                            <FormattedMessage id="ReservationDetails.Reservation" />
                                            <div className="float-right mx-1">
                                                Reservation Status: <ReservationStatusBadge status={reservation.status} />
                                            </div>
                                        </h6>
                                    </CardHeader>
                                    <CardBody className="pb-0">
                                        <Row>
                                            <Col className="col-6">
                                                <FormGroup sm="true" row>
                                                    <Label for="resId" sm={4}> <FormattedMessage id="ReservationDetails.ReservationId" /> </Label>
                                                    <Col sm={8}>
                                                        <Input type="text" name="Id" id="resId" bsSize="sm" className="text-sm" disabled defaultValue={reservation.resIdValue} />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col className="col-6">
                                                <FormGroup sm="true" row>
                                                    <Label for="Country" sm={4}> <FormattedMessage id="ReservationDetails.ReservationDate" /> </Label>
                                                    <Col sm={8}>
                                                        <Input type="text" name="Type" id="Type" bsSize="sm" className="text-sm" disabled defaultValue={reservation.resIdDate} />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-6">
                                                <FormGroup sm="true" row>
                                                    <Label for="channel" sm={4}> <FormattedMessage id="ReservationDetails.Channel" /> </Label>
                                                    <Col sm={8}>
                                                        <Input type="text" name="Id" id="Id" bsSize="sm" className="text-sm" disabled defaultValue={reservation.channelCode} />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col className="col-6">
                                                <FormGroup sm="true" row>
                                                    <Label for="Id" sm={4}> <FormattedMessage id="ReservationDetails.NumberOfGuests" /> </Label>
                                                    <Col sm={8}>
                                                        <Input type="text" name="guestCount" id="guestCount" bsSize="sm" className="text-sm" disabled defaultValue={reservation.guestCount} />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-6">
                                                <FormGroup sm="true" row>
                                                    <Label for="amount" sm={4}> <FormattedMessage id="ReservationDetails.ReservationAmount" /> </Label>
                                                    <Col sm={8}>
                                                        <Input type="text" name="Id" id="amount" bsSize="sm" className="text-sm" disabled defaultValue={reservation.amountAfterTax} />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col className="col-6">
                                                <FormGroup sm="true" row>
                                                    <Label for="Country" sm={4}> <FormattedMessage id="ReservationDetails.ReservationCommission" /> </Label>
                                                    <Col sm={8}>
                                                        <Input type="text" name="Type" id="Country" bsSize="sm" className="text-sm" disabled defaultValue={reservation.amountCommission} />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card className="mb-2 border-0 shadow">
                                    <CardHeader className="pb-0  bg-white border-bottom ">
                                        <h6>
                                            <span className="fas fa-concierge-bell mr-1" />
                                            <FormattedMessage id="ReservationDetails.RoomStays" />
                                        </h6>
                                    </CardHeader>
                                    <CardBody className="pb-0">
                                        <Row>
                                            <Col>
                                                <Card className="border-0 mb-2">
                                                    <Row className="mb-1">
                                                        <Col>
                                                            <b><FormattedMessage id="ReservationDetails.NumberOfRooms" /></b>
                                                        </Col>
                                                        <Col>
                                                            <b><FormattedMessage id="ReservationDetails.RoomCatCode" /></b>
                                                        </Col>
                                                        <Col>
                                                            <b><FormattedMessage id="ReservationDetails.RatePlanCode" /></b>
                                                        </Col>
                                                        <Col>
                                                            <b><FormattedMessage id="ReservationDetails.Amount" /></b>
                                                        </Col>
                                                        <Col>
                                                            <b><FormattedMessage id="ReservationDetails.Commission" /></b>
                                                        </Col>
                                                        <Col>
                                                            <b><FormattedMessage id="ReservationDetails.Arrival" /></b>
                                                        </Col>
                                                        <Col>
                                                            <b><FormattedMessage id="ReservationDetails.NumberOfNights" /></b>
                                                        </Col>
                                                        <Col>
                                                            <b><FormattedMessage id="ReservationDetails.NumSpecialRequests" /></b>
                                                        </Col>
                                                    </Row>
                                                    {reservation.hotelReservation && reservation.hotelReservation.map((item, key) =>
                                                            <React.Fragment key={key}>
                                                                <Row className="mb-1 overbglight">
                                                                    <Col>{item.numberOfRooms}</Col>
                                                                    <Col>{item.channelRoomTypeCode}</Col>
                                                                    <Col>{item.hotelRateCode && item.hotelRateCode.length > 0 && item.hotelRateCode[0].ratePlanCode}</Col>
                                                                    <Col>{item.amountAfterTax ? <FormattedNumber value={item.amountAfterTax} style={'currency'} currency={item.currencyCode} /> : ''}</Col>
                                                                    <Col>{item.amountCommission ? <FormattedNumber value={item.amountCommission} style={'currency'} currency={item.currencyCode} /> : ''}</Col>
                                                                    <Col>{item.hotelRateCode && item.hotelRateCode.length > 0 && moment(item.hotelRateCode[0].effectiveDate).format("YYYY-MM-DD")}</Col>
                                                                    <Col>{item.hotelRateCode && item.hotelRateCode.length}</Col>
                                                                    <Col>
                                                                        <a id={'togglerdetails' + key} style={{ cursor: 'pointer' }}><i className="fas fa-info-circle text-center"></i></a>
                                                                    </Col>
                                                                </Row>
                                                                <UncontrolledCollapse toggler={'togglerdetails' + key}>
                                                                {item.adults > 0 || item.children > 0 || item.infants > 0 ?
                                                                    <div>
                                                                        <h6 className="color-host">
                                                                            <FormattedMessage id="ReservationDetails.Occupacion" />
                                                                        </h6>
                                                                        <hr className="my-1 border-bottom-host" />
                                                                        {item.adults > 0 ?
                                                                            <Row>
                                                                                <Col>{item.adults} <FormattedMessage id="generic.adults" /></Col>
                                                                            </Row>
                                                                            : ''}
                                                                        {item.children > 0 ?
                                                                            <Row>
                                                                                <Col>{item.children} <FormattedMessage id="generic.children" /></Col>
                                                                            </Row>
                                                                            : ''}
                                                                        {item.infants > 0 ?
                                                                            <Row>
                                                                                <Col>{item.infants} <FormattedMessage id="generic.infants" /></Col>
                                                                            </Row>
                                                                            : ''}
                                                                    </div>
                                                                : ''}
                                                                <div>
                                                                    <h6 className="color-host mt-2">
                                                                        <FormattedMessage id="ReservationDetails.DailyPrice" />
                                                                    </h6>
                                                                    <hr className="my-1 border-bottom-host" />
                                                                </div>
                                                                {item.hotelRateCode && item.hotelRateCode.map((el, k1) =>
                                                                    <Row key={k1}>
                                                                        <Col className="col-3">{el.effectiveDate ? el.effectiveDate.substring(0, 10) : ''}</Col>
                                                                        <Col className="col-3">{el.amountAfterTax} {reservation.hotelReservation.currencyCode}</Col>
                                                                    </Row>
                                                                )}
                                                                
                                                                {(item.specialRequest && item.specialRequest.length > 0) || (item.taxesCharges && item.taxesCharges.length > 0) ?
                                                                    <div>
                                                                        {(item.taxesCharges && item.taxesCharges.length > 0) ?
                                                                            <Row>
                                                                                <Col className="border-bottom mt-2">
                                                                                    <b><FormattedMessage id="ReservationDetails.TaxesAndCharges" /></b>
                                                                                </Col>
                                                                            </Row>
                                                                        :''}
                                                                        { item.taxesCharges && item.taxesCharges.map((sritem, srkey) =>
                                                                            <Row>
                                                                                <Col><FormattedMessage id={"generic.reservation.taxecode." + sritem.taxCode} /> </Col>
                                                                                <Col><FormattedMessage id={"generic.reservation.chargefrequency." + sritem.chargeFrequency} /></Col>
                                                                                <Col>{sritem.description}</Col>
                                                                                <Col>{sritem.amount} {sritem.currencyCode}</Col>
                                                                                <Col>{sritem.type}</Col>
                                                                            </Row>
                                                                        )}
                                                                        {(item.specialRequest && item.specialRequest.length > 0) ?
                                                                            <Row>
                                                                                <Col className="border-bottom mt-2">
                                                                                    <b><FormattedMessage id="ReservationDetails.NumSpecialRequests" /></b>
                                                                                </Col>
                                                                            </Row>
                                                                        : ''}
                                                                        {item.specialRequest.map((sritem, srkey) =>
                                                                            <Row>
                                                                                {sritem.code ? <Col className="col-1">{sritem.code} </Col> : ''}
                                                                                <Col>{sritem.description}</Col>
                                                                            </Row>
                                                                        )}
                                                                    </div>
                                                                : ''}
                                                            </UncontrolledCollapse>
                                                        </React.Fragment>
                                                    )}
                                                </Card>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card className="mb-2 shadow border-0">
                                    <CardHeader className="pb-0 bg-white border-bottom">
                                        <h6>
                                            <span className="far fa-id-card mr-1" />
                                            <FormattedMessage id="ReservationDetails.Profiles" />
                                        </h6>
                                    </CardHeader>
                                    <CardBody className="pb-0">
                                        <Row>
                                            <Col>
                                                <Row>
                                                    {reservation.reservationProfile && reservation.reservationProfile.map((item, key) =>
                                                        <Col key={key} className="col-4 pb-2">
                                                            <Card className="border-left-primary shadow h-100 py-2">
                                                                <CardBody className="pb-0">
                                                                    <Row>
                                                                        <Col className="col-9">
                                                                            <h5>{item.countryCode} : {item.givenName} , {item.surname}</h5>
                                                                            <strong> <FormattedMessage id="ReservationDetails.Phone" /> </strong> {item.phoneNumber} <br />
                                                                            <strong> <FormattedMessage id="ReservationDetails.Email" /> </strong> {item.email}
                                                                        </Col>
                                                                        <Col>
                                                                            <span className="fas fa-user fa-3x text-secondary" />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col > {item.addressLine} {item.postalCode} {item.cityName}</Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                {reservation.hotelService && reservation.hotelService.length > 0 ?
                                    <Card className="mb-2">
                                        <CardHeader className="pb-0  bg-white border-bottom">
                                            <h6>
                                                <span className="fas fa-hot-tub mr-1" />
                                                <FormattedMessage id="ReservationDetails.Services" />
                                            </h6>
                                        </CardHeader>
                                        <CardBody className="pb-0">
                                            <Row className="mb-1">
                                                <Col>
                                                    <b>#</b>
                                                </Col>
                                                <Col>
                                                    <b><FormattedMessage id="ReservationDetails.ServiceCode" /></b>
                                                </Col>
                                                <Col>
                                                    <b><FormattedMessage id="ReservationDetails.Duration" /></b>
                                                </Col>
                                                <Col>
                                                    <b><FormattedMessage id="ReservationDetails.NumberOfGuests" /></b>
                                                </Col>
                                                <Col>
                                                    <b><FormattedMessage id="ReservationDetails.PriceTypeCode" /></b>
                                                </Col>
                                                <Col>
                                                    <b><FormattedMessage id="ReservationDetails.Amount" /></b>
                                                </Col>
                                            </Row>
                                            {reservation.hotelService && reservation.hotelService.map((item, key) =>
                                                <Row className="overbglight mb-1">
                                                    <Col> {key} </Col>
                                                    <Col>{item.serviceCode} </Col>
                                                    <Col>{item.duration} </Col>
                                                    <Col>{item.guestCount} </Col>
                                                    <Col>{item.servicePricingType} </Col>
                                                    <Col>{item.feeAmount}</Col>
                                                </Row>
                                            )}
                                        </CardBody>
                                    </Card>
                                :  ''}
                                <Card className="mb-1 shadow border-0">
                                    <CardHeader className="pb-0 bg-white border-bottom">
                                        <h6>
                                            <span className="far fa-comment mr-1" />
                                            <FormattedMessage id="ReservationDetails.Comments" />
                                        </h6>
                                    </CardHeader>
                                    <CardBody className="pb-1 pt-2">
                                        <Row>
                                            <Col>
                                                {reservation.reservationComments && reservation.reservationComments.map((item, key) =>
                                                    <li className="mailboxballon" key={key}> {item.comment}</li>
                                                )}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card> 
                            </div>
                        : ''}
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(ReservationDetails);