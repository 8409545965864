import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { StyledCard } from '../../Base/CommonUIComponents';
import typeform from '../../../img/Typeform.ico';

export default class TypeForm extends Component {

  constructor(props) {
      super(props);
      this.state = {
          block: false
      };
  }

  render() {
    const {error, block} = this.state;

    return (
        <StyledCard error={error} block={block}>
          <Row className="mb-2">
              <Col>
                  <h5>
                    <img className='mr-2' alt="" src={typeform} height="18px"/>
                    <FormattedMessage id="TypeFormMenu.Title"/>
                  </h5>
              </Col>
          </Row>

          <Row className="mb-4">
              <Col>
                  <Card className="border-0 shadow py-3">
                      <CardBody className="text-center">
                          <Link to="/TypeformForms"><b> <FormattedMessage id="TypeFormMenu.ViewForms" /> </b></Link>
                      </CardBody>
                  </Card>
              </Col>
              <Col>
              </Col>
          </Row>
        </StyledCard>
    )
  }
}
