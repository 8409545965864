import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';

class DashboardCard extends Component {
       
    render() {
        const {
            children,
            header,
            block
        } = this.props;


        return (
            <Card className=" shadow h-100 border-0 my-0 py-0">
                <BlockUi tag="div" blocking={block} className="h-100">
                    <CardBody className="h-100">
                        <Row className="mb-2">
                            <Col><h5 className="text-muted"><FormattedMessage id={header} /></h5></Col>
                        </Row>
                        
                        <Row style={{ height: '90%' }}>
                            <Col className="h-100">{children}</Col>
                        </Row>
                    </CardBody>
                </BlockUi>
            </Card>
        );
    }
}

DashboardCard.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    header: PropTypes.string
};

DashboardCard.defaultProps = {

};

export default DashboardCard;