import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import { Badge, Card, Col, Row } from 'reactstrap'
import { getWhastAppStatus } from '../../Base/ReferenceDataFunctions';
import SpeechBubble from './SpeechBubble';
import WhatsAppMessage from './WhatsAppMessage';

export default class TemplatePreview extends Component {

    constructor (props) {
        super(props);
        this.state = {
            currTemplateId: null
        };
    }

    changeCurrTemplate = (lang) => {
        const { templates } = this.props;
        const { currTemplateId } = this.state;

        const template = templates.find(({ language }) => language === lang)

        if (template?.id && currTemplateId !== template.id) {
            this.setState({ currTemplateId: template.id }, () => {
                if (this.props.selectable) {
                    this.props.selectTemplate(template)
                }
            });
        }
    }

    editTemplate = (e) => {
        e.preventDefault();
        const { history } = this.props;
        const { currTemplateId } = this.state;

        history.push({ pathname: `/WhatsAppTemplates/${currTemplateId}` });
    }

    render() {
        const { languages, name, templates, editTemplate, orderComponents, selectable, selectedTemplate, disableNotApprovedTemplates } = this.props;
        const { currTemplateId } = this.state;

        const whatsAppStatus = getWhastAppStatus();

        const areAllDisabled = disableNotApprovedTemplates && templates.every(({ status }) => status !== "APPROVED");

        const currTemplate = templates[0];

        return (
            currTemplate ?
                <Col className='my-2' style={selectable ? { flex: '0 0 25%', maxWidth: '25%' } : { flex: '0 0 20%', maxWidth: '20%' }}>
                    {areAllDisabled ?
                        <div style={{
                            background: '#000',
                            width: '89.3%',
                            height: '100%',
                            opacity: '0.13',
                            position: 'absolute',
                            zIndex: '99',
                            cursor: 'not-allowed'
                        }} />
                    : ''}
                    <Card className={"border-0 p-3 h-100 shadow d-flex justify-content-between pointer" + (selectedTemplate === currTemplate.id ? ' bg-host-light ' : '')}
                        style={{ cursor: 'pointer' } }
                        onClick={() => !areAllDisabled && this.props.selectTemplate && this.props.selectTemplate(currTemplate)}
                    >
                        <div>
                            <div style={{ position: 'absolute', maxWidth: '200px', zIndex: '1', bottom: '3.5rem' }} className="p-2">
                                {languages && languages.length > 0 ?
                                    <>
                                        {languages.map((language, k) => {
                                            const isDisabled = disableNotApprovedTemplates && templates.find(el => el.language === language)?.status !== "APPROVED";

                                            return <span
                                                onClick={() => !isDisabled && this.changeCurrTemplate(language)}
                                                key={k}
                                                className={`flag-icon flag-icon-${language.includes('_') ?
                                                    language.split('_')[1].toLowerCase() : language.toLowerCase()} px-2 mr-2 ${currTemplate.language === language ? 'shadow' : ''}`}
                                                style={{ fontSize: currTemplate.language === language ? '1.4em' : '', cursor: (isDisabled ? 'not-allowed' : '') }}
                                            />
                                        } )}
                                    </>
                                : ''}
                            </div>
                            <div onClick={e => editTemplate && editTemplate(e, currTemplate.name, currTemplate.language)}>
                                <WhatsAppMessage>
                                    <SpeechBubble
                                        showInteractions={false}
                                        components={orderComponents(currTemplate?.components.filter(({ active }) => active !== false))}
                                    />
                                </WhatsAppMessage>
                            </div>
                        </div>
                        <Row className='pt-3' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={e => editTemplate && editTemplate(e, currTemplate.name, currTemplate.language)}>
                            <Col style={{ overflow: 'hidden', height: '100%', display: 'flex', alignItems: 'center' }}>
                                <h5 title={name} style={{ width: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} className="m-0">
                                    {name}
                                </h5>
                            </Col>
                            <Col className={`text-right col-4 pl-0`}>
                                <Badge style={{ background: whatsAppStatus.find(({ value }) => value === currTemplate.status) ?
                                whatsAppStatus.find(({ value }) => value === currTemplate.status).badgeColor : '#6c757d' }}>
                                    <FormattedMessage id={`WhatsApp.${currTemplate.status}`}/>
                                </Badge>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            :''
        )
    }
}
