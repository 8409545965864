import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Card, Col, Row, Modal, ModalBody, Badge, Button } from 'reactstrap';
import BlockUi from 'react-block-ui';
import { getAPI } from "../Base/API";

class PreviewTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            previewTemplate: []
        };
    }

    componentDidMount() {
        if (this.props.templateId) {
            const type = this.props.channel === 'Email' || this.props.channel === 'email' ? 'email' : 'sms';
            this.getTemplate(type, this.props.templateId, false);

            return;
        }

        if (this.props.templates) {
            this.props.templates.forEach((template, i) => {
                this.getTemplate(template.channel, template.templateId, (this.props.templates.length - 1 === i) ? false : true);
            })
        }
    }

    getTemplate = (type, templateId, block) => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                const { previewTemplate } = this.state;
                previewTemplate.push(data);
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoitransactional/template/${type}/${templateId}`);
    }

    render() {
        const { previewTemplate } = this.state;

        return (
            <Modal isOpen={this.props.modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={this.props.toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlockUi tag="div" blocking={this.state.block}>
                        <Row>
                            {previewTemplate && previewTemplate.length > 0 ?
                                previewTemplate.map((template, key) =>
                                    <Col className={(previewTemplate < 4 ? 'col-3' : '') + ' px-2'} key={key} /*style={{ height: '500px' }}*/>
                                        <Card className="p-2 border-0 mb-3 h-100" key={key}>
                                            <h4>{template.templateName}</h4>
                                            <div className="mb-2 ">{template.subject}</div>
                                            <div>{template.clickTracking ? <Badge className="mb-2 p-2 mr-2" color="primary">Click Tracking</Badge> : ''} {template.openTracking ? <Badge className="mb-2 p-2" color="primary">Open Tracking</Badge> : ''}</div>

                                            <img src={template.thumbnail} style={{ width: 'inherit' }} />
                                        </Card>
                                    </Col>
                                )
                            : <Col className="text-center my-3"> <FormattedMessage id="PreviewTemplate.ThereIsNoTemplates" /> </Col>}
                        </Row>
                    </BlockUi>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(PreviewTemplate)
