import React, { Component } from 'react';
import { Badge, Col, Row, Input } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';

class CampaignExcludedTags extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filterValue: null
        }
    }
    componentDidMount() {
    }

    filterTags = (e) => {
        const { value } = e.target;
        this.setState({
            filterValue: value
        })
    }

    render() {
        const { handleExcludedTag, filters, tagList, intl } = this.props;

        return (
            <Row>
                <Col>
                    <Row>
                        <Col className="col-8"></Col>
                        <Col className="col-4" >
                            <Input placeholder={intl.formatMessage({ id: 'CampaignTags.SearchTag' })} onChange={(e) => this.filterTags(e)}></Input>
                        </Col>
                    </Row>
                    <div className="mt-5 scrollableDiv" style={{ height: '90%' }}>
                        {tagList && tagList.map((el, k) =>
                            <Row className="mt-2 pl-1" key={k}>
                                <Col className="col-2"> {el.code}:  </Col>
                                <Col className="d-flex col-10">
                                    {
                                        el.tag && el.tag.map((tag, k1) => {
                                            const tagFilter = filters.excludedTags && filters.excludedTags.find(t => t.id === tag.id);
                                            const isSelected = tagFilter !== undefined;
                                            const isvisible = ((this.state.filterValue === undefined || this.state.filterValue === null) || tag && tag.code?.toLowerCase().includes(this.state.filterValue?.toLowerCase()));

                                            return (
                                                isvisible || isSelected ? <span key={k1}>
                                                    <Badge className=" my-1 mr-2 d-flex align-items-center" color={isSelected ? "primary" : "secondary"} style={{ cursor: 'pointer', height: '30px' }}>
                                                        <span onClick={() => handleExcludedTag(tag)}>{tag.code} </span>
                                                    </Badge>
                                                </span>
                                                    :""
                                            );
                                        })
                                    }
                                </Col>
                            </Row>
                        )}
                    </div>
                </Col>
            </Row>
        );
    }
}
export default injectIntl(CampaignExcludedTags);