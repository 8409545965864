import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, } from 'reactstrap';
import moment from "moment";

import { improvFormObj, FormAnswers, EgoiFormAnswers } from '../../Marketing/Common/MarketingFunctions';
import { StyledModal } from '../../Base/CommonUIComponents';

export class PreviewForm extends Component {

    proccessEgoiAnswers = (data) => {
        let answers = [];

        if (data.Answers) {
            //Main questions that are repeated due to having "sub-questions"
            const repeatedQuestions = data.Answers.reduce((array, inquiry) => {
                if (inquiry.SubQuestion && !array.some(el => el === inquiry.Question)) {
                    array.push(inquiry.Question);
                }

                return array;
            }, [])

            const subQuestions = data.Answers.reduce((acc, current) => {
                if (repeatedQuestions.some(el => el === current.Question)) {
                    const index = acc && acc.findIndex(el => el.question === current.Question)

                    if (index === undefined || index === -1) {
                        acc.push({ question: current.Question, subQuestions: [{ subQuestion: current.SubQuestion, answer: current.Answer }] });
                    }
                    else {
                        acc[index].subQuestions.push({ subQuestion: current.SubQuestion, answer: current.Answer });
                    }
                }

                return acc;
            }, [])

            data.Answers.forEach(el => {
                if (!repeatedQuestions.some(rq => rq === el.Question)) {
                    answers.push({ question: el.Question, subQuestion: el.SubQuestion, answer: el.Answer });
                }
            });

            answers.push(subQuestions);
        }

        return answers;
    }

    treatTypeForm = (form) => {
        const getAnswer = (answer) => {
            if (!answer) return null;
            if (answer.type === "text") return answer.text;
            if (answer.type === "boolean") return answer[answer.type] ? this.props.intl.formatMessage({ id: 'generic.yes' }) : this.props.intl.formatMessage({ id: 'generic.no' });
            if (answer[answer.type] !== null && answer[answer.type]?.label) return answer[answer.type].label;
            if (answer[answer.type] !== null) return answer[answer.type];
        }

        return {
            formSource: form.formSource,
            responseDate: form.responseDate,
            answers: form.rawData.answers.map((answer) => {
                return ({
                    answer: getAnswer(answer),
                    question: answer.field?.id,
                    type: answer.type
                })
            })
        };
    }

    render() {
        const { modal, selectedForm } = this.props;


        return (
            <StyledModal toggleModal={this.props.toggleModal} modal={modal} size="lg">
                {improvFormObj([selectedForm])?.map((form, idx) => {
                    if (form.formSource === 'Egoi') {
                        const egoiAnswers = form.rawData ? this.proccessEgoiAnswers(form.rawData) : null;

                        return egoiAnswers && <EgoiFormAnswers answers={egoiAnswers} key={idx}>
                            <Row>
                                <Col><h5 className="m-0 text-host"> {form.formSource}</h5></Col>
                                <Col className="text-right">
                                    {form.responseDate ? moment(form.responseDate).format("YYYY-MM-DD") : ''}
                                </Col>
                            </Row>

                            <hr />
                        </EgoiFormAnswers>
                    }
                    else if (form.formSource === 'ReviewPro') {
                        const el = form.rawData ? form.rawData : null;

                        return el &&  <FormAnswers answers={el.answers} key={idx}>
                            <Row>
                                <Col><h5 className="m-0 text-host"> {form.formSource}</h5></Col>
                                <Col className="text-right">
                                    {form.responseDate ? moment(form.responseDate).format("YYYY-MM-DD") : ''}
                                </Col>
                            </Row>

                            <hr />

                            {el.guest.email &&
                                <>
                                    <div className="mb-1 text-muted">Email </div>
                                    <div className="mb-3 pl-2 ml-1"> <b> {el.guest.email}</b> </div>
                                </>
                            }

                            {el.guest.company &&
                                <>
                                    <div className="mb-1 text-muted"> Company </div>
                                    <div className="mb-3 pl-2 ml-1"><b> {el.guest.company} </b></div>
                                </>
                            }
                        </FormAnswers>
                    }
                    else if (form.formSource === 'TypeForm') {
                        const typeFormObj = this.treatTypeForm(form)

                        return (
                            <FormAnswers answers={typeFormObj.answers} isTypeForm={true} key={idx}>
                                <Row>
                                    <Col><h5 className="m-0 text-host">{typeFormObj.formSource}</h5></Col>
                                    <Col className="text-right">
                                        {typeFormObj.responseDate ? moment(typeFormObj.responseDate).format("YYYY-MM-DD") : ''}
                                    </Col>
                                </Row>
                                <hr />
                            </FormAnswers>
                        )
                    }
                    else if (form.formSource === 'PNForm') {
                        return (
                            <FormAnswers answers={form.answers} isPNForm={true} key={idx}>
                                <Row>
                                    <Col><h5 className="m-0 text-host">{form.formSource}</h5></Col>
                                    <Col className="text-right">
                                        {form.responseDate ? moment(form.responseDate).format("YYYY-MM-DD") : ''}
                                    </Col>
                                </Row>
                                <hr />
                            </FormAnswers>
                        )
                    }
                })}

            </StyledModal>
        );
    }
}
export default injectIntl(PreviewForm);