import React, { Component } from 'react';
import { Button, Col, Label, Form, Row, CustomInput, FormGroup, Input } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ActiveInactiveStatusCombo, BlankCard, StyledModal } from '../../../../Base/CommonUIComponents';
import { handleNotification } from '../../../../Base/Notification';
import { patchAPI, postAPI } from '../../../../Base/API';

class SendGridWebhookModal extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            webhook: {
                id: this.props.selectedWebhook?.id,
                enabled: this.props.selectedWebhook?.enabled,
                friendly_name: this.props.selectedWebhook?.friendly_name,
                actions: this.props.selectedWebhook ? Object.keys(this.props.selectedWebhook).filter(el => this.props.selectedWebhook[el]) : []
            }
        }
    }


    createWebhook = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const { webhook } = this.state;

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="SendGridWebhooks.WebhookSavedSuccessfully" />, <FormattedMessage id="generic.success" />);

                    this.props.getWebhooks();
                    this.props.toggleModal();
                }
            }
            this.setState({ block: false });
        }, `/api/gms/SendGrid/v1/webhooks?enabled=${webhook.enabled}&friendlyName=${webhook.friendly_name}`, [...webhook.actions]);
    }

    editWebhook = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const { webhook } = this.state;

        patchAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="SendGridWebhooks.WebhookSavedSuccessfully" />, <FormattedMessage id="generic.success" />);

                    this.props.getWebhooks();
                    this.props.toggleModal();
                }
            }
            this.setState({ block: false });
        }, `/api/gms/SendGrid/v1/webhooks/${webhook.id}?enabled=${webhook.enabled}&friendlyName=${webhook.friendly_name}`, [...webhook.actions]);
    }

    handleActions = (evt) => {
        if (evt && evt.target) {
            const { webhook } = this.state;
            const { name, checked } = evt.target;

            if (checked) {
                webhook.actions.push(name);
            }
            else {
                webhook.actions = webhook.actions.filter(el => el !== name);
            }

            this.setState({ webhook });
        }
    }

    handleSelect = (name, combo) => {
        this.setState({
            webhook: {
                ...this.state.webhook,
                [name]: combo ? combo.value : null
            }
        });
    };

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            webhook: {
                ...this.state.webhook,
                [name]: value
            }
        });
    }

    render() {
        const { isOpen, toggleModal, webhookActions } = this.props;
        const { block, error, webhook } = this.state;

        return (
            <StyledModal toggleModal={toggleModal} modal={isOpen} size="lg">
                <BlankCard block={block} error={error}>
                    <Form onSubmit={webhook.id ? this.editWebhook : this.createWebhook}>
                        <Row className="mb-3">
                            <Col>
                                <h5>{webhook.id ? <FormattedMessage id="SendGridWebhooks.EditWebhook" /> : <FormattedMessage id="SendGridWebhooks.CreateWebhook" />} </h5>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host" type="submit">
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Col sm={6}>
                                <Label><FormattedMessage id="generic.name" /></Label>
                                <Input
                                    type="text"
                                    name="friendly_name"
                                    value={webhook.friendly_name || ''}
                                    onChange={(e) => this.handleChange(e)}
                                />
                            </Col>
                            <Col sm={6}>
                                <Label><FormattedMessage id="generic.status" /></Label>
                                <ActiveInactiveStatusCombo
                                    name='enabled'
                                    value={webhook.enabled}
                                    onChangeFunc={this.handleSelect}
                                    placeholder={""}
                                    required
                                />
                            </Col>
                        </FormGroup>
                        <Row className="pt-2">
                            <Col>
                                <Label> <FormattedMessage id="SendGridWebhooks.Actions" /> </Label>
                            </Col>
                        </Row>
                        <FormGroup row>
                            {webhookActions.map((action, key) =>
                                <Col className="col-4 mb-1" key={key}>
                                    <CustomInput type="checkbox" id={action.value} name={action.value} checked={webhook.actions.some(el => el === action.value)} onChange={this.handleActions}>
                                        {action.label}
                                    </CustomInput>
                                </Col>
                            )}
                        </FormGroup>
                    </Form>
                </BlankCard>
            </StyledModal>
        );
    }
}

export default injectIntl(SendGridWebhookModal);