import React, { Component } from 'react';
import { SingleDatePicker } from 'react-dates';
import { Button, Form, Modal, ModalBody, Row, Col, FormGroup, Input } from 'reactstrap';
import { getCurrency, getPMSReservationStatus } from '../../../Base/ReferenceDataFunctions';
import { BlankCard } from '../../../Base/CommonUIComponents';
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from '../../../Base/Notification';
import CustomSelect from '../../../Base/CustomSelect';
import { putAPI, postAPI, getAPI } from '../../../Base/API';
import moment from 'moment';

class ReservationDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            reservation: {
                resIdDate: moment().format("YYYY-MM-DD"),
                resIdValue: null,
                currencyCode: null,
                guestCount: 0,
                source: null,
                channelCode: null,
                hotelId: this.props.profile ? this.props.profile.hotelId : null,
                reservationProfile: [
                    {
                        globalId: this.props.profile?.pmsGlobalId,
                        isMainGuest: true,
                        rph: 1
                    }
                ],
                reservationRoomStay: [
                    {
                        complexCode: null,
                        channelRoomTypeCode: null,
                        amountAfterTax: 0,
                        adults: 0,
                        children: 0,
                        infants: 0,
                        numberOfRooms: 1,
                        guestsRphs: [
                            1
                        ],
                        reservationStatusCode: null,
                        reservationStatus: null,
                        checkIn: null,
                        checkOut: null,
                        reservationRoomRate: [
                            {
                                ratePlanCode: null
                            }
                        ],
                    }
                ]
            },
            complexCodes: []
        };
    }

    componentDidMount() {
        const { selectedReservation, canEditReservations } = this.props;
        
        if (selectedReservation) {

            const canEdit = canEditReservations && !selectedReservation.pmsId;
    
            const reservation = {
                pmsId: !canEdit ? selectedReservation.pmsId : null,
                id: selectedReservation.id,
                resIdDate: selectedReservation.resIdDate,
                resIdValue: selectedReservation.resIdValue,
                currencyCode: selectedReservation.currencyCode,
                guestCount: selectedReservation.guestCount,
                source: selectedReservation.source,
                channelCode: selectedReservation.channelCode,
                hotelId: selectedReservation.hotelId,
                reservationProfile: [
                    {
                        globalId: this.props.profile?.pmsGlobalId,
                        isMainGuest: true,
                        rph: 1
                    }
                ],
                reservationRoomStay: [
                    {
                        complexCode: selectedReservation.complexCode,
                        channelRoomTypeCode: selectedReservation.channelRoomTypeCode,
                        amountAfterTax: selectedReservation.amountAfterTax,
                        adults: selectedReservation.adults,
                        children: selectedReservation.children,
                        infants: selectedReservation.infants,
                        guestsRphs: [
                            1
                        ],
                        reservationStatus: selectedReservation.statusNumber,
                        checkIn: selectedReservation.checkIn,
                        checkOut: selectedReservation.checkOut,
                        reservationRoomRate: [
                            {
                                ratePlanCode: selectedReservation.ratePlanCode,
                                priceListCode: selectedReservation.priceListCode
                            }
                        ],
                        pinCode: selectedReservation.pinCode,
                        pinValidFromDate: selectedReservation.pinValidFromDate,
                        pinValidToDate: selectedReservation.pinValidToDate
                    }
                ]
            }
            
            this.setState({ reservation, canEdit });
        }
        else{
            this.setState({ canEdit: true });
        }
        this.getLoyaltyCardLevelConfigs();
    }

    getLoyaltyCardLevelConfigs = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else if (data.response && data.response.length > 0 && data.response[0].hotelGroupLoyaltyCardConfigPrefixes && data.response[0].hotelGroupLoyaltyCardConfigPrefixes.length > 0) {
                    const complexCodes = data
                                        .response[0]
                                        .hotelGroupLoyaltyCardConfigPrefixes
                                        .map(resp => {
                                            const option = {
                                                value: resp.complexCode,
                                                label: resp.complexCode,
                                                hotelId: resp.hotelId
                                            }
                                            return option;
                                        });
                    this.setState({ error: errorMessage, block: false, complexCodes });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/LoyaltyCard/v1/HotelGroupLoyaltyCardConfig`);
    }

    saveReservation = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const body = { ...this.state.reservation };

        if (!body.reservationRoomStay[0].checkOut || !body.reservationRoomStay[0].checkIn) {
            handleNotification({ errors: [{ code: '', message: <FormattedMessage id="Reservation.CheckInAndCheckOutAreRequired" /> }] });

            this.setState({ block: false });
            return;
        }

        body.guestCount = parseInt(body.reservationRoomStay[0].adults) + parseInt(body.reservationRoomStay[0].children) + parseInt(body.reservationRoomStay[0].infants);
        body.reservationRoomStay[0].reservationStatus = parseInt(body.reservationRoomStay[0].reservationStatus);


        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="Reservation.ReservationSaved" />, <FormattedMessage id="generic.success" />);

                    this.props.doSearch();
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Reservation/v1/reservations/manual`, JSON.stringify({ request: body }));
    }

    resendReservation = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const body = { ...this.state.reservation };

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="Reservation.ReservationResent" />, <FormattedMessage id="generic.success" />);

                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Reservation/v1/reservations/resend`, JSON.stringify({ request: body }));
    }

    handleChangeSelectHotel = (combo, name) => {
        const { reservation } = this.state;
        reservation.hotelId = combo ? combo.value : null;
        reservation.reservationRoomStay[0].complexCode = null;
        this.setState({ reservation });
    };

    handleChangeSelect = (combo, name) => {
        this.setState(prevState => ({
            reservation: {
                ...prevState.reservation,
                [name]: combo ? combo.value : null
            }
        }));
    };

    handleChangeSelectOnRoomStay = (combo, name) => {
        const { reservation } = this.state;
        reservation.reservationRoomStay[0][name] = combo ? combo.value : null;
        this.setState({ reservation });
    };

    handleDateChange = (name, date) => {
        this.setState(prevState => ({
            reservation: {
                ...prevState.reservation,
                [name]: date ? moment(date).format("YYYY-MM-DD") : null
            }
        }));
    }

    handleRoomStaySelect = (name, value) => {
        this.setState(prevState => ({
            reservation: {
                ...prevState.reservation,
                reservationRoomStay: [
                    {
                        ...prevState.reservation.reservationRoomStay[0],
                        [name]: value
                    }
                ]
            }
        }));
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            reservation: {
                ...prevState.reservation,
                [name]: value
            }
        }));
    }

    handleChangeRoomStay = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            reservation: {
                ...prevState.reservation,
                reservationRoomStay: [
                    {
                        ...prevState.reservation.reservationRoomStay[0],
                        [name]: value
                    }
                ]
            }
        }));
    }

    handleChangeRate = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            reservation: {
                ...prevState.reservation,
                reservationRoomStay: [
                    {
                        ...prevState.reservation.reservationRoomStay[0],
                        reservationRoomRate: [
                            {
                                ...prevState.reservation.reservationRoomStay[0].reservationRoomRate[0],
                                [name]: value
                            }
                        ]
                    }
                ]
            }
        }));
    }

    render() {
        const { block, error, reservation, canEdit, complexCodes } = this.state;
        const { canEditProfile } = this.props;

        return (
            <Modal isOpen={this.props.modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={this.props.toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <Form onSubmit={this.saveReservation}>

                            <Row className="mb-4 pb-2 mb-3 border-bottom">
                                <Col className="d-flex align-items-center">
                                        <i className="icon-icon-reservas mr-2 " />
                                        <h5 className="m-0"> <FormattedMessage id="Reservation.Reservation" /> </h5>
                                    </Col>
                                    <Col className="text-right">
                                        {canEdit ?
                                            <Button className="btn btn-host btn-sm ml-2" type="submit">
                                                <i className="fas fa-save"></i>
                                            </Button>
                                            :
                                            <Button title='ReSend Reservation' className="btn btn-host btn-sm ml-2" onClick={this.resendReservation}>
                                                <i className="fas fa-sync-alt"></i>
                                            </Button>
                                        }
                                    </Col>
                                </Row>

                            <div className="pl-3 pb-4">
                                <FormGroup row className="align-items-center">
                                    <Col className="col-12 col-md-6 mb-3 mb-md-0">
                                        <div className="title-sm"> <FormattedMessage id="generic.Hotel" /> </div>
                                        <div>
                                            <CustomSelect
                                                options={this.props.hotels}
                                                onChange={(e) => this.handleChangeSelectHotel(e, 'hotelId')}
                                                name="hotel"
                                                placeholder={this.props.intl.formatMessage({ id: "generic.Hotel" })}
                                                required
                                                value={reservation.hotelId ? this.props.hotels.find(el => el.value === reservation.hotelId) : ''}
                                                isDisabled={!canEdit || reservation.hotelId !== null}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="col-12 col-md-6">
                                        <div className="title-sm"> <FormattedMessage id="ReservationList.ComplexCode" /></div>
                                        <div>
                                            <CustomSelect
                                                options={complexCodes.filter(cod => cod.hotelId === reservation.hotelId)}
                                                onChange={(e) => this.handleChangeSelectOnRoomStay(e, 'complexCode')}
                                                name="complexCode"
                                                placeholder={this.props.intl.formatMessage({ id: "ReservationList.ComplexCode" })}
                                                value={complexCodes.find(cod => reservation.reservationRoomStay[0].complexCode)}
                                                isDisabled={!canEdit || reservation.hotelId === null}
                                                isClearable
                                                isSearchable
                                            />
                                        </div>
                                    </Col>                             
                                </FormGroup>
                                <FormGroup row className="align-items-center">
                                    <Col className="col-12 col-md-6 mb-3 mb-md-0">
                                        <div className="title-sm">  <FormattedMessage id="Reservation.ResIdValue" /></div>
                                        <div>
                                            <Input
                                                type="text"
                                                name="resIdValue"
                                                onChange={(e) => this.handleChange(e, 'resIdValue')}
                                                value={reservation.resIdValue || ''}
                                                placeholder={this.props.intl.formatMessage({ id: "Reservation.ResIdValue" })}
                                                required
                                                disabled={!canEdit}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="col-12 col-md-6">
                                        <div className="title-sm"> <FormattedMessage id="Reservation.ResIdDate" /></div>
                                        <div>
                                            <SingleDatePicker
                                                id="resIdDate"
                                                isOutsideRange={day => false}
                                                date={reservation.resIdDate ? moment(reservation.resIdDate) : null}
                                                focused={this.state.focused}
                                                onFocusChange={({ focused }) => this.setState({ focused })}
                                                small={true}
                                                numberOfMonths={1}
                                                showDefaultInputIcon={true}
                                                onDateChange={date => this.handleDateChange('resIdDate', date)}
                                                required
                                                disabled={!canEdit}
                                            />
                                        </div>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="align-items-center">
                                    <Col className="col-12 col-md-6 mb-3 mb-md-0">
                                        <div className="title-sm"> <FormattedMessage id="ReservationList.Source" /> </div>
                                        <div>
                                            <Input
                                                type="text"
                                                name="source"
                                                onChange={(e) => this.handleChange(e, 'source')}
                                                value={reservation.source || ''}
                                                placeholder={this.props.intl.formatMessage({ id: "ReservationList.Source" })}
                                                required
                                                disabled={!canEdit}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="col-12 col-md-6">
                                        <div className="title-sm"> <FormattedMessage id="Reservation.ChannelCode" /></div>
                                        <div>
                                            <Input
                                                type="text"
                                                name="channelCode"
                                                onChange={(e) => this.handleChange(e, 'channelCode')}
                                                value={reservation.channelCode || ''}
                                                placeholder={this.props.intl.formatMessage({ id: "Reservation.ChannelCode" })}
                                                required
                                                disabled={!canEdit}
                                            />
                                        </div>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="align-items-center">
                                    <Col className="col-12 col-md-6 mb-3 mb-md-0">
                                        <div className="title-sm"> <FormattedMessage id="Reservation.CurrencyCode" /> </div>
                                        <div>
                                            <CustomSelect
                                                options={getCurrency()}
                                                onChange={(e) => this.handleChangeSelect(e, 'currencyCode')}
                                                name="currencyCode"
                                                placeholder={this.props.intl.formatMessage({ id: "Reservation.CurrencyCode" })}
                                                value={reservation.currencyCode ? getCurrency().find(el => el.value === reservation.currencyCode) : ''}
                                                isDisabled={!canEdit}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="col-12 col-md-6">
                                        <div className="title-sm"><FormattedMessage id="generic.status" /></div>
                                        <div>
                                            <CustomSelect
                                                options={getPMSReservationStatus(this.props.intl)}
                                                onChange={(e) => this.handleRoomStaySelect('reservationStatus', e ? e.value : null)}
                                                name="status"
                                                placeholder={this.props.intl.formatMessage({ id: "generic.status" })}
                                                value={getPMSReservationStatus(this.props.intl).find(el => el.value == reservation.reservationRoomStay[0]?.reservationStatus) || ''}
                                                required
                                                isDisabled={!canEdit}
                                            />
                                        </div>
                                    </Col>
                                </FormGroup>
                            </div>


                            <div className="d-flex align-items-center pb-2 mb-3 border-bottom ">
                                <i className="icon-icon-reservas mr-2 " />
                                <h5 className="m-0"> <FormattedMessage id="Reservation.Room" /> </h5>
                            </div>

                            <div className="pl-3">
                                <FormGroup row className="align-items-center">
                                    <Col className="col-12 col-md-6 mb-3 mb-md-0">
                                        <div className="title-sm"> <FormattedMessage id="Reservation.Guest" /></div>
                                        <div>
                                            {this.props.profile?.fullName}
                                        </div>
                                    </Col>
                                    <Col className="col-12 col-md-2 mb-3 mb-md-0">
                                        <div className="title-sm"> <FormattedMessage id="Reservation.Pin" /></div>
                                        {reservation.reservationRoomStay[0].pinCode || '-'}
                                    </Col>
                                    <Col className="col-6 col-md-2">
                                        <div className="title-sm"> <FormattedMessage id="Reservation.PinValidFromDate" /></div>
                                        {reservation.reservationRoomStay[0].pinValidFromDate ? moment(reservation.reservationRoomStay[0].pinValidFromDate).format("YYYY-MM-DD") : ''}
                                    </Col>
                                    <Col className="col-6 col-md-2">
                                        <div className="title-sm"> <FormattedMessage id="Reservation.PinValidToDate" /></div>
                                        {reservation.reservationRoomStay[0].pinValidToDate ? moment(reservation.reservationRoomStay[0].pinValidToDate).format("YYYY-MM-DD") : ''}
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="align-items-center">
                                    <Col className="col-12 col-md-6 mb-3 mb-md-0">
                                        <div className="title-sm"><FormattedMessage id="Reservation.ChannelRoomTypeCode" /></div>
                                        <div>
                                            <Input
                                                type="text"
                                                name="channelRoomTypeCode"
                                                onChange={(e) => this.handleChangeRoomStay(e, 'channelRoomTypeCode')}
                                                value={reservation.reservationRoomStay[0].channelRoomTypeCode || ''}
                                                placeholder={this.props.intl.formatMessage({ id: "Reservation.ChannelRoomTypeCode" })}
                                                disabled={!canEdit}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="col-12 col-md-6">
                                        <Row>
                                            <Col className='text-truncate'>
                                                <div className="title-sm"> <FormattedMessage id="Reservation.Adults" /></div>
                                                <Input
                                                    type="number"
                                                    name="adults"
                                                    onChange={(e) => this.handleChangeRoomStay(e, 'adults')}
                                                    value={reservation.reservationRoomStay[0].adults}
                                                    placeholder={this.props.intl.formatMessage({ id: "Reservation.Adults" })}
                                                    min={0}
                                                    required
                                                    disabled={!canEdit}
                                                />
                                            </Col>
                                            <Col className='text-truncate'>
                                                <div className="title-sm"> <FormattedMessage id="Reservation.Children" /></div>
                                                <Input
                                                    type="number"
                                                    name="children"
                                                    required
                                                    onChange={(e) => this.handleChangeRoomStay(e, 'children')}
                                                    value={reservation.reservationRoomStay[0].children}
                                                    placeholder={this.props.intl.formatMessage({ id: "Reservation.Children" })}
                                                    min={0}
                                                    disabled={!canEdit}
                                                />
                                            </Col>
                                            <Col className='text-truncate'>
                                                <div className="title-sm">  <FormattedMessage id="Reservation.Infants" /></div>
                                                <Input
                                                    type="number"
                                                    name="infants"
                                                    required
                                                    onChange={(e) => this.handleChangeRoomStay(e, 'infants')}
                                                    value={reservation.reservationRoomStay[0].infants}
                                                    placeholder={this.props.intl.formatMessage({ id: "Reservation.Infants" })}
                                                    min={0}
                                                    disabled={!canEdit}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="align-items-center">
                                    <Col className="col-12 col-md-6 mb-3 mb-md-0">
                                        <div className="title-sm"><FormattedMessage id="ReservationList.Package" /></div>
                                        <div>
                                            <Input
                                                type="text"
                                                name="ratePlanCode"
                                                onChange={(e) => this.handleChangeRate(e, 'ratePlanCode')}
                                                value={reservation.reservationRoomStay[0]?.reservationRoomRate[0]?.ratePlanCode || ''}
                                                placeholder={this.props.intl.formatMessage({ id: "ReservationList.Package" })}
                                                disabled={!canEdit}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="col-12 col-md-3 mb-3 mb-md-0">
                                        <div className="title-sm"><FormattedMessage id="Reservation.CheckIn" /></div>
                                        <div>
                                            <SingleDatePicker
                                                id="checkIn"
                                                isOutsideRange={_ => false}
                                                date={reservation.reservationRoomStay[0].checkIn ? moment(reservation.reservationRoomStay[0].checkIn) : null}
                                                focused={this.state.focused2}
                                                onFocusChange={({ focused }) => this.setState({ focused2: focused })}
                                                small={true}
                                                numberOfMonths={1}
                                                showDefaultInputIcon={true}
                                                onDateChange={date => this.handleRoomStaySelect('checkIn', date ? moment(date).format("YYYY-MM-DD") : null)}
                                                required
                                                disabled={!canEdit}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="col-12 col-md-3">
                                        <div className="title-sm"><FormattedMessage id="Reservation.CheckOut" /></div>
                                        <div>
                                            <SingleDatePicker
                                                id="checkOut"
                                                isOutsideRange={day => day < moment(reservation.reservationRoomStay[0].checkIn)}
                                                date={reservation.reservationRoomStay[0].checkOut ? moment(reservation.reservationRoomStay[0].checkOut) : null}
                                                focused={this.state.focused3}
                                                onFocusChange={({ focused }) => this.setState({ focused3: focused })}
                                                small={true}
                                                numberOfMonths={1}
                                                showDefaultInputIcon={true}
                                                onDateChange={date => this.handleRoomStaySelect('checkOut', date ? moment(date).format("YYYY-MM-DD") : null)}
                                                required
                                                disabled={!canEdit || !reservation.reservationRoomStay[0].checkIn}
                                                //displayFormat="DD/MM/YYYY"
                                            />
                                        </div>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="align-items-center">
                                    <Col className="col-12 col-md-6 mb-3 mb-md-0">
                                        <div className="title-sm"> <FormattedMessage id="ReservationList.PriceList" /></div>
                                        <div>
                                            <Input
                                                type="text"
                                                name="priceListCode"
                                                onChange={(e) => this.handleChangeRate(e, 'priceListCode')}
                                                value={reservation.reservationRoomStay[0]?.reservationRoomRate[0]?.priceListCode || ''}
                                                placeholder={this.props.intl.formatMessage({ id: "ReservationList.PriceList" })}
                                                disabled={!canEdit}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="col-12 col-md-6">
                                        <div className="title-sm"><FormattedMessage id="Reservation.AmountAfterTax" /></div>
                                        <div>
                                            <Input
                                                type="number"
                                                name="amountAfterTax"
                                                onChange={(e) => this.handleChangeRoomStay(e, 'amountAfterTax')}
                                                value={reservation.reservationRoomStay[0].amountAfterTax || ''}
                                                placeholder={this.props.intl.formatMessage({ id: "Reservation.AmountAfterTax" })}
                                                disabled={!canEdit}
                                            />
                                        </div>
                                    </Col>
                                </FormGroup>
                            </div>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(ReservationDetails)