import React, { Component } from 'react'
import { Col, Row, Breadcrumb, BreadcrumbItem, Card } from 'reactstrap';
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { StyledCard } from "../../Base/CommonUIComponents";

class TwilioServiceDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.getServicePhoneNumbers(this.props.selectedService.sid)
    }

    getServicePhoneNumbers = (sid) => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }

                if (data.response) {
                    this.setState({ servicePhoneNumbers: data.response.phone_numbers, block: false });
                    return;
                }

                this.setState({ block: false });
            }
        }, `/api/gms/Twilio/v1/service/${sid}`);
    }


    render() {
        const { block, error, page, selectedService } = this.props;
        const { servicePhoneNumbers } = this.state;

        return (
            <StyledCard block={block} error={error}>
                <Row className="mb-4 py-1">
                    <Col>
                        <Breadcrumb className="customBreadCrumb">
                            <BreadcrumbItem><Link onClick={_ => this.props.toggleModal()}><h5> <i className={page?.icon} /> {page?.name} </h5></Link></BreadcrumbItem>
                            <BreadcrumbItem active> {selectedService.friendly_name}  </BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </Row>
                {servicePhoneNumbers ?
                    <Card className="border-0 pl-4 pb-4 shadow">
                        <Row className="mt-4 py-1">
                            <Col>
                                <b><FormattedMessage id="TwilioMessaging.AssociatedPhones" /></b>
                            </Col>
                        </Row>
                        {servicePhoneNumbers.map((phone) =>
                            <Row className="mt-2 py-1">
                                <Col className="col-3">
                                    <b><FormattedMessage id="TwilioMessaging.PhoneNumber" />: {phone.phone_number}</b>
                                </Col>
                                <Col>
                                    <FormattedMessage id="TwilioMessaging.CountryCode" />: {phone.country_code}
                                </Col>
                            </Row>
                        )}
                        <Row className="mt-4 py-1">
                            <Col>
                                <b><FormattedMessage id="TwilioMessaging.OptOut" /></b>
                            </Col>
                        </Row>
                        <Row className="mt-2 py-1">
                            <Col>
                                <FormattedMessage id="TwilioMessaging.KeyWords" />: STOP, STOPALL, UNSUBSCRIBE, CANCEL, END and Quit
                            </Col>
                        </Row>
                        <Row className="mt-2 py-1">
                            <Col>
                                <FormattedMessage id="TwilioMessaging.Reply" />: You have successfully been unsubscribed. You will not receive any more messages from this number. Reply START to resubscribe.
                            </Col>
                        </Row>
                        <Row className="mt-4 py-1">
                            <Col>
                                <b><FormattedMessage id="TwilioMessaging.OptIn" /></b>
                            </Col>
                        </Row>
                        <Row className="mt-2 py-1">
                            <Col>
                                <FormattedMessage id="TwilioMessaging.KeyWords" />: START, YES and UNSTOP
                            </Col>
                        </Row>
                        <Row className="mt-2 py-1">
                            <Col>
                                <FormattedMessage id="TwilioMessaging.Reply" />: You have successfully been re-subscribed to messages from this number. Reply HELP for help. Reply STOP to unsubscribe. Msg&Data Rates May Apply.
                            </Col>
                        </Row>
                        <Row className="mt-4 py-1">
                            <Col>
                                <b><FormattedMessage id="TwilioMessaging.Help" /></b>
                            </Col>
                        </Row>
                        <Row className="mt-2 py-1">
                            <Col>
                                <FormattedMessage id="TwilioMessaging.KeyWords" />: HELP and INFO
                            </Col>
                        </Row>
                        <Row className="mt-2 py-1">
                            <Col>
                                <FormattedMessage id="TwilioMessaging.Reply" />: Reply STOP to unsubscribe. Msg&Data Rates May Apply.
                            </Col>
                        </Row>
                    </Card>
                    : ''}
            </StyledCard>
        )
    }
}

export default injectIntl(TwilioServiceDetails)
