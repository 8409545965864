import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { injectIntl, FormattedMessage } from 'react-intl';
import { Card, CardBody, Col, Row, Breadcrumb, BreadcrumbItem, UncontrolledTooltip } from 'reactstrap';
import { handleNotification } from '../../Base/Notification';
import { getAPI } from "../../Base/API";
import { StyledCard } from "../../Base/CommonUIComponents";

export class reviewProConfig extends Component {

    constructor(props) {
        super(props);
        this.getHotels = this.getReviwProForms.bind(this);
        this.state = {
            block: true,
            inquiryList: [],
            inquiry: null
        };
    }

    componentDidMount() {
        this.getReviwProForms('/api/gms/ReviewPro/v1/Surveys')
    }

    getReviwProForms(url) {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.getReviwMappings(data.response);
            }
            else this.setState({ block: false });
        }, url);
    }

    getReviwMappings = (inquiryList) => {

        if (inquiryList) {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, inquiryList, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    if (data.response && data.response.length > 0) {
                        inquiryList.forEach(el => {
                            el.isMapped = data.response.some(rm => rm.formId === el.id);
                        })
                    }

                    this.setState({ inquiryList, block: false });
                }
                else this.setState({ block: false, inquiryList });
            }, `/api/gms/Review/v1/reviewMapping/ReviewPro`);
        }
        else {
            this.setState({ inquiryList, block: false });
        }
    }


    render() {
        const { block, error, inquiryList } = this.state;
        return (
            <StyledCard block={block} error={error} >
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/ReviewProMenu"> ReviewPro Config</Link></BreadcrumbItem>
                        <BreadcrumbItem active> Surveys </BreadcrumbItem>
                    </Breadcrumb>
                </div>

                <Row className="mb-2">
                    <Col>
                        <h5>
                            <i className="icon-icon-hotel mr-2"></i> <FormattedMessage id="reviewProConfig.title" />
                        </h5>
                    </Col>
                </Row>
                <Row>
                    {inquiryList && inquiryList.map((el, k) =>
                        <Col className="h-100 col-3 mb-2" key={k}>
                            <Link to={{ pathname: '/reviewProInquiry/' + el.id, state: { surveyName: el.name }}}>
                                <Card className="shadow border-0">
                                    <CardBody>
                                        <Row>
                                            <Col> <h5 className="mb-0">{el.name}</h5> </Col>
                                            {el.isMapped &&
                                                <Col className="col-1 text-right pl-0">
                                                    <i className="fas fa-random mr-1" id={`Survey${k}`} />
                                                    <UncontrolledTooltip target={`Survey${k}`} placement="bottom">
                                                        <FormattedMessage id="reviewProConfig.AlreadyMapped" />
                                                    </UncontrolledTooltip>
                                                </Col>
                                            }
                                        </Row>                                        
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>    
                        )}
                    
                </Row>
            </StyledCard>
        );
    }
}
export default injectIntl(reviewProConfig)
