import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Row, Button, UncontrolledTooltip } from 'reactstrap';
import { StyledCard } from "../../Base/CommonUIComponents";
import { handleNotification } from "../../Base/Notification";
import { DateRangePicker } from 'react-dates';
import { getAPI } from "../../Base/API";
import moment from 'moment';

import Authorization from '../../Base/Authorization';
import { EgoiFormAnswers } from '../Common/MarketingFunctions';

class FormSubmissions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            answer: []
        };
    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let listId = params.get('list');
        let formId = params.get('form');
        let formTitle = '';

        if (this.props.history.location && this.props.history.location.state && this.props.history.location.state.formTitle) {
            formTitle = this.props.history.location.state.formTitle;
        }
        else {
            this.getFormName(listId, formId);
        }

        this.setState({
            listId, formId, formTitle
        })

        if (listId && formId) {
            this.getFormsSubmissions(listId, formId)
        }
    }

    getFormName = (listId, formId) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                const form = data.response && data.response.find(el => el.id == formId);

                this.setState({ formTitle: form && form.title });
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/Forms?listId=${listId}`);
    }

    getFormsSubmissions = (listId, formId, onlyAnswers) => {
        this.setState({ block: true });

        var params = "";
        if (this.state.startDate) params += `&startDate=${moment(this.state.startDate).format("YYYY-MM-DD")}`;
        if (this.state.endDate) params += `&endDate=${moment(this.state.endDate).format("YYYY-MM-DD")}`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                else if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.response && data.response.length > 0) {
                    this.buildData(data.response, onlyAnswers);
                }
                else {
                    this.setState({ answers: [] })
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/FormsSubmissions?listId=${listId}&formId=${formId}` + params);
    }

    buildData = (data, onlyAnswers) => {
        let questions = { keys: [], allQuestions: [] };

        const answers = data.sort((a, b) => moment(b.timeStamp).diff(moment(a.timeStamp))).map((answer) => {

            //Main questions that are repeated due to having "sub-questions"
            const repeatedQuestions = answer.inquiryAnswers.reduce((array, inquiry) => {
                if (inquiry.subQuestion && !array.some(el => el === inquiry.question)) {
                    array.push(inquiry.question);
                }

                return array;
            }, [])


            const subQuestions = answer.inquiryAnswers.reduce((acc, current) => {
                if (repeatedQuestions.some(el => el === current.question)) {
                    //'Organize' questions and answers
                    const index = acc && acc.findIndex(el => el.question === current.question)

                    if (index === undefined || index === -1) {
                        acc.push({ question: current.question, subQuestions: [{ subQuestion: current.subQuestion, answer: current.answer }] });
                    }
                    else {
                        acc[index].subQuestions.push({ subQuestion: current.subQuestion, answer: current.answer });
                    }

                    //Add to questions object for map modal
                    if (!questions.allQuestions.some(k => k.mainQuestion === current.question)) {
                        questions.allQuestions.push({ mainQuestion: current.question });
                    }
                    if (!questions.allQuestions.some(k => k.question === current.subQuestion)) {
                        questions.allQuestions.push({ question: current.subQuestion });
                    }
                }
                else {
                    //Add to questions object for map modal
                    if (!questions.keys.some(k => k.value === current.question)) {
                        questions.keys.push({ value: current.question, label: current.question });
                    }
                }

                return acc;
            }, [])

            answer.inquiryAnswers = answer.inquiryAnswers.filter(el => !repeatedQuestions.some(rq => rq === el.question));
            answer.inquiryAnswers.push(subQuestions);

            return answer;
        })

        if (onlyAnswers) {
            this.setState({ answers });
        }
        else {
            if (questions.keys) {
                [...questions.keys].reverse().forEach(k => questions.allQuestions.unshift({ question: k.value }));
            }

            this.setState({ answers, questions });
        }
    }

    render() {
        const { block, error, answers, formTitle } = this.state;

        return (
            <StyledCard block={block} error={error} >
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/MarketingConfigEgoi"> Config</Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to="/FormList"> Forms</Link></BreadcrumbItem>
                        <BreadcrumbItem active> {formTitle || 'Form'} </BreadcrumbItem>
                    </Breadcrumb>
                </div>

                <Row className="align-items-center mb-3">
                    <Col>
                        <DateRangePicker
                            startDate={this.state.startDate ? moment(this.state.startDate) : null}
                            startDateId="startDate"
                            isOutsideRange={day => day > moment().add(1, 'day')}
                            endDate={this.state.endDate ? moment(this.state.endDate) : null}
                            endDateId="endDate"
                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </Col>

                    <Col className="text-right">
                        <Button className="btn- btn-host btn-small btn-sm mr-2" onClick={() => this.getFormsSubmissions(this.state.listId, this.state.formId, true)}>
                            <i className="fas fa-search" />
                        </Button>

                        <Authorization
                            perform="formSubmissions:map"
                            yes={() => (
                                <>
                                    <Button className="btn- btn-host btn-small btn-sm" id="mapForm" tag={Link} to={{ pathname: '/EgoiMapForm', search: `?list=${this.state.listId}&form=${this.state.formId}`, state: { questions: this.state.questions, formTitle } }}>
                                        <i className="fas fa-random" />
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="mapForm">
                                        <FormattedMessage id="MapFormModal.Map" />
                                    </UncontrolledTooltip>
                                </>
                            )}
                            no={() => <div></div>}
                        />
                    </Col>
                </Row>

                <Row>
                    {answers && answers.length > 0 ?
                        answers.map((answer, key) =>
                            <Col className="col-4 mb-4" key={key}>
                                <EgoiFormAnswers answers={answer.inquiryAnswers}>
                                    <Row>
                                        <Col><h5 className="m-0 text-host"> <FormattedMessage id="MapFormModal.Answer" /> {key + 1}</h5></Col>
                                        <Col className="text-right">
                                            {answer.timeStamp ? moment(answer.timeStamp).format("YYYY-MM-DD") : ''}
                                        </Col>
                                    </Row>

                                    <hr />
                                </EgoiFormAnswers>
                            </Col>
                        )
                    : <Col className="text-center mt-4"> <FormattedMessage id="FormList.ThereAreNoSubmittedForms" /> </Col> }
                </Row>

                {/*this.state.modal ?
                    <MapFormModal
                        modal={this.state.modal}
                        toggleModal={this.toggleModal}
                        formData={this.state.formData}
                        listId={this.state.listId}
                        formId={this.state.formId}
                        questions={this.state.questions}
                    />
                : '' */}

            </StyledCard>
        );
    }
}
export default injectIntl(FormSubmissions)
