import React, { Component } from 'react';
import moment from 'moment';
import { Table, Button, Card, Row, Col, CardBody } from 'reactstrap';
import { CommonHelper } from '../Base/CommonUIComponents';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from "../Base/CustomSelect";
import { ErrorAlert } from '../Base/ErrorAlert';
import { Pie, Line } from 'react-chartjs-2';
import { DateRangePicker } from 'react-dates';
import DashboardCard from "../Base/DashboardCard";
import BlockUi from 'react-block-ui';
import { getAPI } from "../Base/API";

class ReservationSummary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            fromDate: moment().subtract(7, 'days'),
            toDate: moment(),
            reservationsByHotel: { "datasets": [{ "label": "Reservations by Hotel", "data": [], "backgroundColor": ['#c39bd5', '#9163cc', '#744ea4', '#4f2c7c'] }], "labels": [] },
            reservationsByChannel: { "datasets": [{ "label": "Reservations by Channel", "data": [], "backgroundColor": ['#c39bd5', '#9163cc', '#744ea4', '#4f2c7c'] }], "labels": [] },
            reservationsByDay: { "drawOnChartArea": true, "datasets":  [], "labels": [] },
            tableData: [],
            channelOptions: []
        };
    }

    componentDidMount() {
        this.getReservationSummary();
    }

    getReservationSummary = () => {
        this.setState({ block: true });

        var params = '';
        params += this.state.status ? `&status=${this.state.status}` : '';
        params += this.state.channel ? `&channels=${this.state.channel}` : '';

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.calculateData(data.response);
                this.setState({ block: false });
            }
        }, `/api/gms/DashBoard/ReservationsSummary?fromDate=${moment(this.state.fromDate).format('YYYY-MM-DD')}&toDate=${moment(this.state.toDate).format('YYYY-MM-DD')}` + params)
    }

    calculateData = (data) => {
        const { reservationsByHotel, reservationsByChannel, reservationsByDay } = this.state;
        var reservationsHotel = new Map(), reservationsChannel = new Map();
        const colors = ['#c39bd5', '#9163cc', '#744ea4', '#4f2c7c'], dates = [], lineDatasets = [];
        var dataByDay = [], tableData = [];

        //Proccess data
        data && data.forEach((hotel, i) => {
            reservationsHotel.set(hotel.hotelName);
            dataByDay[i]= { 'hotel': hotel.hotelName, values: [] };

            hotel.dataByDate && hotel.dataByDate.forEach((date, idx) => {
                if (!dates.includes(moment(date.date).format("MMM DD, YYYY"))) {
                    dates.push(moment(date.date).format("MMM DD, YYYY"));
                }

                dataByDay[i].values[idx] = {'date': date.date}
                
                date.dataByChannel && date.dataByChannel.forEach(channel => {
                    if (!tableData.find(el => el.channel === channel.channel)) {
                        tableData.push({ 'channel': channel.channel, totalRoomNights: 0, totalRoomNightsCancelled: 0 });
                    }

                    channel.dataByStatus && channel.dataByStatus.forEach(reservation => {
                        //Fill reservationsHotel and reservationsChannel
                        reservationsHotel.get(hotel.hotelName) === undefined ? reservationsHotel.set(hotel.hotelName, reservation.totalReservations) : reservationsHotel.set(hotel.hotelName, reservation.totalReservations + reservationsHotel.get(hotel.hotelName));
                        reservationsChannel.get(channel.channel) === undefined ? reservationsChannel.set(channel.channel, reservation.totalReservations) : reservationsChannel.set(channel.channel, reservation.totalReservations + reservationsChannel.get(channel.channel));                        

                        //Data by day
                        dataByDay[i].values[idx].totalReservations = dataByDay[i].values[idx].totalReservations ? dataByDay[i].values[idx].totalReservations + reservation.totalReservations : reservation.totalReservations;

                        //tableData
                        const index = tableData.indexOf(tableData.find(td => td.channel === channel.channel));
                        tableData[index].totalRoomNights += reservation.totalRoomNights;
                        if (reservation.status === "Deleted") {
                            tableData[index].totalRoomNightsCancelled += reservation.totalRoomNights;
                        }
                    })
                })
            })
        })

        //Pie - Reservations by hotel
        var values = [];
        reservationsByHotel.labels = [...reservationsHotel.keys()];

        reservationsByHotel.labels.forEach(item => { values.push(parseInt(reservationsHotel.get(item))); });

        reservationsByHotel.datasets[0].data = values;


        //Pie - Reservations by channel
        var values = [];
        reservationsByChannel.labels = [...reservationsChannel.keys()];

        reservationsByChannel.labels.forEach(item => { values.push(parseInt(reservationsChannel.get(item))); });

        reservationsByChannel.datasets[0].data = values;


        //Line - Reservations by day
        reservationsByDay.labels = dates.sort((a, b) => moment(a) - moment(b));
        const hotels = [...reservationsHotel.keys()]

        hotels.forEach((hotel, idx) => {
            var obj = { label: hotel, data: [], borderColor: colors[idx], backgroundColor: colors[idx], fill: false }

            reservationsByDay.labels.forEach((date, i) => {
                dataByDay.forEach(e => {
                    e.values && e.values.forEach(value => {
                        if (hotel === e.hotel && moment(value.date).isSame(moment(date))) {
                            obj.data[i] = value.totalReservations;
                        }
                    })
                })
            })
            obj.data = Array.from(obj.data, x => x || 0);
            lineDatasets.push(obj);
        });

        reservationsByDay.datasets = lineDatasets;
        
        this.setState({ reservationsByHotel, reservationsByChannel, reservationsByDay, tableData, channelOptions: [...reservationsChannel.keys()] });
    }

    handleSelect = (evt, combo) => {
        this.setState({
            [evt]: combo ? combo.value : null
        })
    }

    render() {
        const { block, error, tableData } = this.state;

        let options = {
            legend: {
                position: "top"
            }
        }

        let optionsPie = {
            legend: {
                position: "right"
            }
        }

        /*const reservationStatus = [
            {
                value: "Created",
                label: this.props.intl.formatMessage({ id: "ReservationSummary.Created" })
            },
            {
                value: "Modified",
                label: this.props.intl.formatMessage({ id: "ReservationSummary.Modified" })
            },
            {
                value: "Deleted",
                label: this.props.intl.formatMessage({ id: "ReservationSummary.Deleted" })
            }
        ]*/

        return (
            <div>
                <BlockUi tag="div" blocking={block}>
                    <ErrorAlert error={error} />

                    <Row className="my-1">
                        <Col>
                            <Card className="shadow ">
                                <CardBody>
                                    <Row className="mb-2">
                                        {/*<Col className="col-3 px-1">
                                            <CustomSelect
                                                icon={'fas fa-bed'}
                                                isClearable isSearchable
                                                placeholder={"Reservation Status"}
                                                options={reservationStatus}
                                                onChange={this.handleSelect.bind(this, 'status')}
                                            />
                                        </Col>*/}
                                        <Col>
                                            <DateRangePicker
                                                startDate={moment(this.state.fromDate)}
                                                endDate={moment(this.state.toDate)}
                                                onDatesChange={({ startDate, endDate }) => this.setState({ fromDate: startDate, toDate: endDate })}
                                                isOutsideRange={() => false}
                                                endDateId="your_unique_end_date_id_announcement"
                                                startDateId="your_unique_start_date_id_announcement"
                                                focusedInput={this.state.focusedInput}
                                                onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                                small={true}
                                                numberOfMonths={1}
                                                showDefaultInputIcon={true}
                                            />
                                        </Col>
                                        <Col>
                                            <CommonHelper help='TODO' id="dashboardHelp" />
                                            <Button className="float-right btn btn-sm btn-host" onClick={this.getReservationSummary}>
                                                <i className="fa fa-search" />
                                            </Button>
                                        </Col>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mb-2">
                        <div className="container-fluid d-flex p-0">
                            <Col className="col-6 pr-0">
                                <DashboardCard header="ReservationSummary.ReservationsByHotel" block={block} >
                                    <Pie data={this.state.reservationsByHotel} height={'70px'} options={optionsPie} />
                                </DashboardCard>
                            </Col>

                            <Col className="col-6">
                                <DashboardCard header="ReservationSummary.ReservationsByChannel" block={block}  >
                                    <Pie data={this.state.reservationsByChannel} height={'70px'} options={optionsPie} />
                                </DashboardCard>
                            </Col>
                        </div>
                    </Row>

                    <Row className="mb-2">
                        <div className="container-fluid d-flex p-0">
                            <Col>
                                <DashboardCard header="ReservationSummary.ReservationsByDay" block={this.state.blockChannel}  >
                                    <Line data={this.state.reservationsByDay} height={'40px'} options={options} />
                                </DashboardCard>
                            </Col>
                        </div>
                    </Row>

                    <Row>
                        <Col>
                            <DashboardCard header="ReservationSummary.ReservationStatusByChannel" block={this.state.blockChannel}  >
                                <Table size="sm">
                                    <thead>
                                        <tr>
                                            <th>{this.props.intl.formatMessage({ id: "ReservationSummary.Channel" })}</th>
                                            <th>{this.props.intl.formatMessage({ id: "ReservationSummary.TotalRoomNight" })}</th>
                                            <th>{this.props.intl.formatMessage({ id: "ReservationSummary.CanceledRoomNights" })}</th>
                                            <th>{this.props.intl.formatMessage({ id: "ReservationSummary.CancelationInChannel" })}</th>
                                            <th>{this.props.intl.formatMessage({ id: "ReservationSummary.CancelationTotal" })}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((channel, key) =>
                                            <tr key={key}>
                                                <td><b>{channel.channel}</b></td>
                                                <td>{channel.totalRoomNights}</td>
                                                <td>{channel.totalRoomNightsCancelled}</td>
                                                <td >{parseFloat((channel.totalRoomNightsCancelled / channel.totalRoomNights) * 100).toFixed(2)} %</td>
                                                <td >{parseFloat((channel.totalRoomNightsCancelled / tableData.reduce((a, b) => a + b.totalRoomNightsCancelled, 0)) * 100).toFixed(2)} %</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </DashboardCard>
                        </Col>
                    </Row>
                </BlockUi>
            </div>
        )
    }
}

export default injectIntl(ReservationSummary);