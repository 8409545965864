import React, { Component } from 'react'

export default class PreviewDesktop extends Component {

    render() {
        const { compiledHtml } = this.props;
        return (
            <div style={{
                borderRadius: '4px',
                overflow: 'auto',
                width: '70%',
                border: '1px solid #6b6b6b',
                padding: '0.5rem',
                marginRight: '5%'
            }}>
                <div style={{
                    borderRadius: '4px',
                    overflow: 'auto',
                    height: '600px'
                }}>
                    <div dangerouslySetInnerHTML={{__html: compiledHtml }}/>
                </div>
            </div>
        )
    }
}
