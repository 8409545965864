import React, { Component } from 'react';
import { Row, Col, Input, Button } from 'reactstrap';
import { BlankCard } from './Base/CommonUIComponents';
import { FormattedMessage, injectIntl } from 'react-intl';

export class NoAccessPage extends Component {

    render() {
        return (
            <Row className="pt-5" style={{ height: '97VH', width: '100%' }}>
                <Col>
                    <Row>
                        <Col>
                            <div className="p-4">
                                <h1 className="mb-4"><i className="fas fa-ban text-danger"></i><FormattedMessage id="NoAccessPage.YouDoNotHaveAccessTo" />  <div className="color-host">Guest Managment System</div></h1>
                                <p className="lead mb-3"><FormattedMessage id="NoAccessPage.ContactUs" />:</p>

                                <Row>
                                    <Col className="text-center">
                                        <b > <FormattedMessage id="NoAccessPage.DataQuality" /></b>
                                        <hr className="my-1" />
                                        <div className="text-left">
                                            <div className="mb-2">
                                                <i className=" text-success fas fa-check mr-2"></i> <FormattedMessage id="NoAccessPage.DefineDataQualityRules" />
                                            </div>
                                            <div className="mb-2">
                                                <i className=" text-success fas fa-check mr-2"></i> <FormattedMessage id="NoAccessPage.AutomateFixes" />
                                            </div>
                                            <div className="mb-2">
                                                <i className=" text-success fas fa-check mr-2"></i> <FormattedMessage id="NoAccessPage.ApplyBestPractices" />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="text-center">
                                        <b> <FormattedMessage id="NoAccessPage.ConsolidatedCustomerView" /></b>
                                        <hr className="my-1" />
                                        <div className="text-left">
                                            <div className="mb-2">
                                                <i className=" text-success fas fa-check mr-2"></i> <FormattedMessage id="NoAccessPage.IdentifyClientDuplications" />
                                            </div>
                                            <div className="mb-2">
                                                <i className=" text-success fas fa-check mr-2"></i> <FormattedMessage id="NoAccessPage.ReceiveCienteInfo" />
                                            </div>
                                            <div className="mb-2">
                                                <i className=" text-success fas fa-check mr-2"></i> <FormattedMessage id="NoAccessPage.ViewClientExpensesAndReservation" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col>
                            <div className="p-4">
                                <BlankCard>
                                    <div className="p-2">
                                        <Row className="mb-3">
                                            <Col className="text-center">
                                                <h4><b><FormattedMessage id="NoAccessPage.RequestForDemo" /></b></h4>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Col>
                                                <Input className="input-host" type="text" placeholder={this.props.intl.formatMessage({ id: "NoAccessPage.Name" })} required />

                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                <Input className="input-host" type="email" placeholder="Email" required />

                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                <Input type="number" className="input-host" placeholder={this.props.intl.formatMessage({ id: "NoAccessPage.NrProperties/Rooms" })} required />

                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2">
                                            <Col>
                                                <p className="lead text-center ">
                                                    <Button color="primary" className="btn-host"><FormattedMessage id="NoAccessPage.RequestDemo" /></Button>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </BlankCard>
                            </div>

                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default injectIntl(NoAccessPage);