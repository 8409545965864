import React, { Component } from 'react'
import { Button, Col, Form, Input, Modal, ModalBody, Row } from 'reactstrap';
import CustomSelect from '../../Base/CustomSelect';
import { FormattedMessage, injectIntl } from 'react-intl';
import CreatableSelect from 'react-select/creatable';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { getAPI } from '../../Base/API';

class RuleModal extends Component {
    
    constructor(props){
        super(props);

        this.state = {
            loyaltyCardsOptions: this.props.loyaltyCards?.map((card) => ({ value: card.id, loyaltyCardTypeConfigCode: card.loyaltyCardTypeConfigCode, label: <div className='d-flex align-items-center'><div>{card.code}</div> <div className='mx-1'>-</div> <div className='text-muted' style={{ fontSize: '0.8em' }}>{card.description}</div></div> })),
            selectedRule: null,
            block: true,
            error: null,
            type: [],
            ruleTypeList: [
                { value: 'Base', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.BaseRules' }) },
                { value: 'Always', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.AlwaysRules' }) },
                { value: 'Any', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.AnyRules' }) }
            ],
            ruleType: null,
            filterTypeOptions: [
                { value: 'SalesGroup', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.SalesGroup' })},
                { value: 'PriceListCode', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.PriceListCode' })},
                { value: 'SubSegmentCodeCode', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.SubSegmentCode' })},
                { value: 'SegmentCode', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.SegmentCode' })},
                { value: 'DistChannelCode', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.DistChannelCode' })},
                { value: 'Source', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.Source' })}
            ]
        };
    }
    
    componentDidMount(){
        this.startUpData();
    }

    startUpData = () => {
        const { selectedRuleId, rules, configId, type, loyaltyCardConfigs } = this.props;
        
        const currentConfig = loyaltyCardConfigs?.find(({id}) => id === configId);

        const filteredType = type?.filter(({value}) => !currentConfig ||
            ((value === "AdditionalRevenue" || value === "ReservationRevenue") && currentConfig.tierRevenuePoints) ||
            ((value === "Reservation") && currentConfig.tierResPoints) ||
            ((value === "Nights") && currentConfig.tierNightPoints)
        );

        let ruleType;
        let selectedDateOption = 'Always';

        const selectedRule = selectedRuleId ? rules?.find(({id}) => id === selectedRuleId) : {
                new: true,
                loyaltyCardConfigId: configId,
                loyaltyCardLevelRule: []
            };


        if(selectedRuleId){
            if(!selectedRule.beginDate && !selectedRule.endDate && selectedRule.subType === "All" && !selectedRule.filterType){
                ruleType = "Base";
            }
            else if(!selectedRule.beginDate && !selectedRule.endDate && (selectedRule.subType !== "All" || selectedRule.filterType)){
                ruleType = "Always";
            }
            else{
                ruleType = "Any";   
            }
        }

        if(selectedRule && selectedRule.beginDate && selectedRule.endDate) selectedDateOption = "Nights"

        this.setState({ selectedRule, selectedDateOption, ruleType, type: filteredType });
    }

    selectTags = (values) => {
        this.setState({
            selectedRule: {...this.state.selectedRule, codes: values?.map(({value}) => value)??[]}
        });
    }

    selectFilterValue = (values) => {
        this.setState({
            selectedRule: {...this.state.selectedRule, filterValue: values?.map(({value}) => value)??[]}
        });
    }

    handleSelect = (combo, name) => {
        const { selectedRule } = this.state;
        if(name === 'type' && selectedRule.type && combo?.value !== selectedRule.type){
            selectedRule.subType = null;
            selectedRule.codes = [];
            selectedRule.filterType = null;
            selectedRule.filterValue = [];
        }

        if(name === 'subType' && combo?.value !== selectedRule.subType){
            selectedRule.codes = [];
        }
        
        if(name === 'filterType' && combo?.value !== selectedRule.filterType){
            selectedRule.filterValue = [];
        }

        if(name === "subType" && combo?.value && combo.value !== "All"){
            if(combo.value === "ChargeCode" && this.props.ChargesList?.length === 0) this.props.getCharges();
            if(combo.value === "RoomCategory" && this.props.RoomCategoryList?.length === 0) this.props.getCat();
            if(combo.value === "Rate" && this.props.RateList?.length === 0) this.props.getRates();
        }

        if(name === "filterType" && combo?.value && combo.value !== "All"){
            if(combo.value === "PriceListCode" && this.props.PriceListCodeList?.length === 0) this.props.getPriceListCode();
            if(combo.value === "SubSegmentCodeCode" && this.props.SubSegmentCodeCodesList?.length === 0) this.props.getSubSegmentCodeCode();
            if(combo.value === "SegmentCode" && this.props.SegmentCodesList?.length === 0) this.props.getSegmentCode();
            if(combo.value === "DistChannelCode" && this.props.DistributionChannelsList?.length === 0) this.props.getDistChannelCode();
            if(combo.value === "Source" && this.props.ChannelList?.length === 0) this.props.getChannelList();
        }

        this.setState({
            selectedRule: {
                ...selectedRule,
                [name]: combo ? combo.value : null
            }
        });
    }

    addCard = () => {
        const { selectedRule } = this.state;
        const newCard = {};
        if(!selectedRule.loyaltyCardLevelRule) selectedRule.loyaltyCardLevelRule = [];
        selectedRule.loyaltyCardLevelRule.push(newCard);
        this.setState({ selectedRule });
    }

    removeCard = (key) => {
        const { selectedRule } = this.state;
        selectedRule.loyaltyCardLevelRule.splice(key, 1);
        this.setState({ selectedRule });    
    }

    handleChange = (e, key) => {
        const { selectedRule } = this.state;
        selectedRule.loyaltyCardLevelRule[key][e.target.name] = e.target.value;
        this.setState({ selectedRule });
    }

    handleBaseChange = (e) => {
        const { selectedRule } = this.state;
        selectedRule[e.target.name] = e.target.value;
        this.setState({ selectedRule });
    }

    handleCardSelect = (combo, key) => {
        const { selectedRule } = this.state;
        selectedRule.loyaltyCardLevelRule[key].loyaltyCardLevelConfigId = combo.value;
        this.setState({ selectedRule });
    }

    getSubTypeOptions = () => {
        const { selectedRule, ruleType } = this.state;
        const { type } = selectedRule;
        const result = [];
        if(type === 'AdditionalRevenue' || type === 'ReservationRevenue'){
            result.push({ value: 'ChargeCode', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.Charge' }) });
        }
        else{
            result.push({ value: 'RoomCategory', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.RoomCategory' }) });
            // result.push({ value: 'Rate', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.Rate' }) });
        }

        if(ruleType !== "Base"){
            result.push({ value: 'All', label: this.props.intl.formatMessage({ id: 'ConfigCardPoints.All' }) });
        }

        return result;
    }

    handleRuleType = (e) => {
        const { selectedRule } = this.state;
        const { value } = e;

        if(value === "Base"){
            selectedRule.beginDate = null;
            selectedRule.endDate = null;
            selectedRule.codes = [];
            selectedRule.multiplier = null;
            selectedRule.subType = "All";
        }
        else if(value === "Always"){
            selectedRule.beginDate = null;
            selectedRule.endDate = null;
        }
        
        this.setState({ ruleType: value, selectedRule });
    }

    getCodeOptions = () => {
        const { selectedRule } = this.state;
        const { ChargesList, RoomCategoryList, RateList } = this.props;
        return selectedRule.subType === "ChargeCode" ? ChargesList :
        selectedRule.subType === "RoomCategory" ? RoomCategoryList
        : RateList
    }
    
    getFilterValueOptions = () => {
        const { selectedRule } = this.state;
        const { SalesGroupOptions, PriceListCodeList, SubSegmentCodeCodesList, SegmentCodesList, DistributionChannelsList, ChannelList } = this.props;

        return selectedRule.filterType === "SalesGroup" ? SalesGroupOptions :
            selectedRule.filterType === "PriceListCode" ? PriceListCodeList :
            selectedRule.filterType === "SubSegmentCodeCode" ? SubSegmentCodeCodesList :
            selectedRule.filterType === "SegmentCode" ? SegmentCodesList : 
            selectedRule.filterType === "DistChannelCode" ? DistributionChannelsList : ChannelList;
    }

    getMultiSelectValues = (name) => {
        const { selectedRule } = this.state;
        let result = [];
        if(!selectedRule) return result;

        if(name === "filterValue"){
            const options = this.getFilterValueOptions();

            result = selectedRule?.filterValue?.map((val) => {
                const label = options.find(({value}) => value === val)?.label;
                return({ value: val, label: label??val })
            });
        }
            
        if(name === "code"){
            const options = this.getCodeOptions();

            result = selectedRule?.codes?.map((val) => {
                const label = options.find(({value}) => value === val)?.label;
                return({ value: val, label: label??val })
            });
        }
        return result
    }

    render() {
        const { modal, toggle, saveRules, loyaltyCards, loyaltyCardConfigs, configId } = this.props;
        const { loyaltyCardsOptions, type, selectedRule, ruleTypeList, ruleType, filterTypeOptions } = this.state;
        
        const config = loyaltyCardConfigs?.find(({id}) => id === configId);

        return (
            <Modal isOpen={modal} toggle={toggle} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button type="button" onClick={toggle} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                {selectedRule ? 
                    <ModalBody>
                        <Form name='ConfigRule' onSubmit={e => saveRules(e, selectedRule)}>
                            <Row className='mt-2'>
                                <Col>
                                    <h5><FormattedMessage id="ConfigCardPoints.ConfigRule"/></h5>
                                </Col>
                                <Col className='text-right'>
                                    <Button className="btn-sm btn-host ml-2" type="submit">
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='col-6 mt-2'>
                                    <div className='title-sm mb-1'>
                                        <FormattedMessage id="ConfigCardPoints.pointsType"/>
                                    </div>
                                    <CustomSelect
                                        isSearchable={false}
                                        placeholder={""}
                                        options={type}
                                        onChange={(e) => this.handleSelect(e, 'type')}
                                        value={selectedRule.type ? type.find(el => el.value === selectedRule.type) : ''}
                                    />
                                </Col>
                                <Col className='col-6 mt-2'>
                                    <div className='title-sm mb-1'>
                                        <FormattedMessage id="ConfigCardPoints.ruleType"/>
                                    </div>
                                    <CustomSelect
                                        isSearchable={false}
                                        placeholder={""}
                                        options={ruleTypeList}
                                        onChange={(e) => this.handleRuleType(e)}
                                        value={ruleType ? ruleTypeList.find(el => el.value === ruleType) : ''}
                                    />
                                </Col>
                                {ruleType && ruleType !== "Base" ?
                                    <>
                                        <Col className='col-6 mt-2'>
                                            <div className='title-sm mb-1'>
                                                <FormattedMessage id="ConfigCardPoints.chargeType"/>
                                            </div>
                                            <CustomSelect
                                                isSearchable={false}
                                                isDisabled={!selectedRule.type}
                                                placeholder={""}
                                                isClearable={true}
                                                options={this.getSubTypeOptions()}
                                                onChange={(e) => this.handleSelect(e, 'subType')}
                                                value={selectedRule.subType ? this.getSubTypeOptions().find(el => el.value === selectedRule.subType) : ''}
                                            />
                                        </Col>
                                        <Col className='col-6 mt-2'>
                                            <div className='title-sm mb-1'>
                                                <FormattedMessage id="ConfigCardPoints.Code"/>
                                            </div>
                                            <CustomSelect
                                                isMulti={true}
                                                isSearchable
                                                isClearable
                                                required={true}
                                                isDisabled={!selectedRule.type || !selectedRule.subType || selectedRule.subType === "All"}
                                                placeholder={""}
                                                options={this.getCodeOptions()}
                                                onChange={this.selectTags}
                                                value={this.getMultiSelectValues('code')}
                                            />
                                        </Col>
                                    </>
                                :''}
                                {ruleType && ruleType !== "Base" && selectedRule.type === "ReservationRevenue" ?
                                    <>
                                        <Col className='col-6 mt-2'>
                                            <div className='title-sm mb-1'>
                                                <FormattedMessage id="ConfigCardPoints.FilterType"/>
                                            </div>
                                            <CustomSelect
                                                isSearchable={false}
                                                isDisabled={!selectedRule.type}
                                                placeholder={""}
                                                options={filterTypeOptions}
                                                isClearable={true}
                                                onChange={(e) => this.handleSelect(e, 'filterType')}
                                                value={selectedRule.filterType ? filterTypeOptions.find(el => el.value === selectedRule.filterType) : ''}
                                            />
                                        </Col>
                                        <Col className='col-6 mt-2'>
                                            <div className='title-sm mb-1'>
                                                <FormattedMessage id="ConfigCardPoints.FilterValue"/>
                                            </div>
                                            <CustomSelect
                                                isMulti={true}
                                                isSearchable
                                                isClearable
                                                required={true}
                                                isDisabled={!selectedRule.filterType}
                                                placeholder={""}
                                                options={this.getFilterValueOptions()}
                                                onChange={this.selectFilterValue}
                                                value={this.getMultiSelectValues('filterValue')}
                                            />
                                        </Col>
                                    </>
                                :''}
                                {selectedRule.type && selectedRule.subType && ruleType && ruleType !== "Base" ?
                                    <>
                                        {ruleType === "Any" ?
                                            <Col className='col-6 mt-2'>
                                                <Row>
                                                    <Col className='col-6'>
                                                        <div className='title-sm mb-1'>
                                                            <FormattedMessage id="ConfigCardPoints.beginDate"/>
                                                        </div>
                                                        <SingleDatePicker
                                                            id="BeginDate"
                                                            date={selectedRule.beginDate ? moment(selectedRule.beginDate) : ''}
                                                            isOutsideRange={d => selectedRule.endDate && d.isSameOrAfter(moment(selectedRule.endDate), 'date')}
                                                            placeholder=''
                                                            focused={this.state.focused2}
                                                            customInputIcon={<></>}
                                                            required={true}
                                                            onFocusChange={({ focused }) => this.setState({ focused2: focused })}
                                                            small={true}
                                                            numberOfMonths={1}
                                                            onDateChange={date => this.setState({ selectedRule: { ...this.state.selectedRule, beginDate: date?.format('YYYY-MM-DD') } })}
                                                            showDefaultInputIcon={true}
                                                        />
                                                    </Col>
                                                    <Col className='col-6'>
                                                        <div className='title-sm mb-1'>
                                                            <FormattedMessage id="ConfigCardPoints.endDate"/>
                                                        </div>
                                                        <SingleDatePicker
                                                            id="EndDate"
                                                            date={selectedRule.endDate ? moment(selectedRule.endDate) : ''}
                                                            isOutsideRange={d => selectedRule.beginDate && d.isSameOrBefore(moment(selectedRule.beginDate), 'date')}
                                                            focused={this.state.focused}
                                                            onFocusChange={({ focused }) => this.setState({ focused })}
                                                            placeholder=''
                                                            required={true}
                                                            customInputIcon={<></>}
                                                            small={true}
                                                            numberOfMonths={1}
                                                            onDateChange={date => this.setState({ selectedRule: { ...this.state.selectedRule, endDate: date?.format('YYYY-MM-DD') } })}
                                                            showDefaultInputIcon={true}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        :''}
                                        <Col className='col-6 mt-2'>
                                            <div className='title-sm mb-1'>
                                                <FormattedMessage id="ConfigCardPoints.BaseMultiplier"/>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <Input
                                                    className="border-bottom without-arrows text-right"
                                                    style={{ paddingRight: '25px' }}
                                                    type="number"
                                                    name="multiplier"
                                                    value={selectedRule.multiplier || ''}
                                                    onChange={(e) => this.handleBaseChange(e)}
                                                    max={1000}
                                                    min={1}
                                                />
                                                <span className='text-muted' style={{ position: 'absolute', right: '25px', marginTop: '-2px' }}>%</span>
                                            </div>
                                        </Col>
                                    </>
                                :''}
                            </Row>
                            <div>
                                <Row className='py-2 mt-2'>
                                    <Col className='d-flex align-items-center addPromptConfig'>
                                        <b className='cursor-pointer'>
                                            <FormattedMessage id="ConfigCardPoints.Cards"/>
                                        </b>
                                        <div className='ml-2 text-muted cursor-pointer add' style={{ fontSize: '0.8em' }} onClick={this.addCard}>
                                            <i className="fas fa-plus" />
                                        </div>
                                    </Col>
                                </Row>
                                <div>
                                    {selectedRule.loyaltyCardLevelRule ?
                                        selectedRule.loyaltyCardLevelRule.map((card, key) =>
                                            <Row key={key}>
                                                <Col className='col-6 mt-2'>
                                                    <div className='title-sm mb-1'>
                                                        <FormattedMessage id="ConfigCardPoints.cardLevel"/>
                                                    </div>
                                                    <CustomSelect
                                                        isSearchable={false}
                                                        placeholder={""}
                                                        style={{ height: '50px' }}
                                                        isDisabled={loyaltyCardsOptions.length === 0}
                                                        options={loyaltyCardsOptions.filter(({loyaltyCardTypeConfigCode}) => loyaltyCardTypeConfigCode === config?.loyaltyCardTypeConfigCode).map((option) => {
                                                            option.disabled = selectedRule.loyaltyCardLevelRule.find(({loyaltyCardLevelConfigId}) => loyaltyCardLevelConfigId === option.value);
                                                            return option;
                                                        })}
                                                        onChange={(e) => this.handleCardSelect(e, key)}
                                                        value={card.loyaltyCardLevelConfigId ? loyaltyCardsOptions.find(el => el.value === card.loyaltyCardLevelConfigId) : ''}
                                                    />
                                                </Col>
                                                <Col className='col-4 mt-2'>
                                                    <div className='title-sm mb-1'>
                                                        <FormattedMessage id="ConfigCardPoints.Multiplier"/>
                                                    </div>
                                                    <div className='d-flex align-items-center'>
                                                        <Input
                                                            className="border-bottom without-arrows text-right"
                                                            style={{ paddingRight: '25px' }}
                                                            type="number"
                                                            name="multiplier"
                                                            value={card.multiplier || ''}
                                                            onChange={(e) => this.handleChange(e, key)}
                                                            max={1000}
                                                            min={1}
                                                        />
                                                        <span className='text-muted' style={{ position: 'absolute', right: '25px', marginTop: '-2px' }}>%</span>
                                                    </div>
                                                </Col>
                                                <Col className='d-flex align-items-center justify-content-end'>
                                                    <span onClick={() => this.removeCard(key)} className='pt-4' style={{ cursor: 'pointer', fontSize: '14px', color: 'red' }}>
                                                        <i className="fas fa-trash-alt"/>
                                                    </span>
                                                </Col>
                                            </Row>
                                        )
                                    :''}
                                </div>
                            </div>
                            {selectedRule.type && ruleType ?
                                <div>
                                    <div className='mt-2'>
                                        <b><FormattedMessage id="ConfigCardPoints.ExplainTitle"/></b>
                                    </div>
                                    {ruleType === "Base" ?
                                        <div className='mt-2 mb-4'>
                                            <span className='mr-1'>
                                                <FormattedMessage id="ConfigCardPoints.Explain.ForEvery"/>
                                            </span>
                                            <span className='mr-1'>
                                                <b><FormattedMessage id={`ConfigCardPoints.${selectedRule.type}`}/></b>
                                            </span>
                                            <div className='pl-2'>
                                                {selectedRule.loyaltyCardLevelRule?.filter(({loyaltyCardLevelConfigId, multiplier}) => loyaltyCardLevelConfigId && multiplier).map((card, key) =>
                                                    <div key={key}>
                                                        <span className='mr-1'>
                                                            <FormattedMessage id="ConfigCardPoints.Explain.TheLevel"/>
                                                        </span>
                                                        <span className='mr-1'>
                                                            <b>{loyaltyCards.find(el => el.id === card.loyaltyCardLevelConfigId)?.description}</b>
                                                        </span>
                                                        <span className='mr-1'>
                                                            <FormattedMessage id="ConfigCardPoints.Explain.WillBeAwardedTimes"/>
                                                        </span>
                                                        <span className='mr-1'>
                                                            <b>{card.multiplier}</b>%
                                                        </span>
                                                        <span className='mr-1'>
                                                            <FormattedMessage id="ConfigCardPoints.Explain.FromTheBase"/>
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    : ruleType === "Always" ?
                                        <div className='mt-2 mb-4'>
                                            <span className='mr-1'>
                                                <FormattedMessage id="ConfigCardPoints.Explain.ForEvery"/>
                                            </span>
                                            <span className='mr-1'>
                                                <b><FormattedMessage id={`ConfigCardPoints.${selectedRule.type}`}/></b>
                                            </span>
                                            {selectedRule.subType ?
                                                <span className='mr-1'>
                                                   {selectedRule.subType === "All" ?
                                                        <FormattedMessage id="ConfigCardPoints.Explain.FromAll"/>
                                                    :
                                                        selectedRule.subType && selectedRule.codes?.length > 0 ?
                                                            <FormattedMessage id="ConfigCardPoints.Explain.From"/>
                                                        :''
                                                    }
                                                </span>
                                            :''}
                                            <span className='mr-1'>
                                                {selectedRule.type !== 'AdditionalRevenue' && selectedRule.type !== 'ReservationRevenue' && selectedRule.subType ?
                                                    <>
                                                        <b><FormattedMessage id={`ConfigCardPoints.${selectedRule.subType}`}/></b>
                                                    </>
                                                :
                                                    selectedRule.subType === "All" ?
                                                        <FormattedMessage id="ConfigCardPoints.Explain.AllCodes"/>
                                                    :
                                                        selectedRule.subType && selectedRule.codes?.length > 0 ?
                                                            <FormattedMessage id="ConfigCardPoints.Explain.Code"/>
                                                        :''
                                                }
                                            </span>
                                            {selectedRule.codes?.map((code, key) =>
                                                <span key={key}>
                                                    {key > 0 ?
                                                        <span className='mr-1'>,</span>
                                                    :''}
                                                    <b>{this.getCodeOptions().find(({value}) => value === code)?.label}</b>
                                                </span>
                                            )}
                                            {selectedRule.filterType && selectedRule.filterValue?.length > 0 && selectedRule.type === 'ReservationRevenue' ?
                                                <span className={selectedRule.codes?.length > 0 ? 'ml-1' : ''}>
                                                    <span className='mr-1'>
                                                        <FormattedMessage id={`ConfigCardPoints.Explain.Where`}/>
                                                    </span>
                                                    <b className='mr-1'><FormattedMessage id={`ConfigCardPoints.${selectedRule.filterType}`}/></b>
                                                    <span className='mr-1'>
                                                        <FormattedMessage id={`ConfigCardPoints.Explain.Is`}/>
                                                    </span>
                                                    <span>
                                                        {selectedRule.filterValue?.map((code, key) =>
                                                            <span key={key} className='mr-1'>
                                                                {key > 0 ?
                                                                    <span className='mr-1'><FormattedMessage id={`ConfigCardPoints.Explain.Or`}/></span>
                                                                :''}
                                                                <b>{this.getFilterValueOptions().find(({value}) => value === code).label}</b>
                                                            </span>
                                                        )}
                                                    </span>
                                                </span>
                                            :''}
                                            {selectedRule.subType === "All" || selectedRule.codes?.length > 0 ?
                                                <>
                                                    <span className='ml-1'>
                                                        <FormattedMessage id="ConfigCardPoints.Explain.EveryCard"/>
                                                    </span>
                                                    <span className='ml-1'>
                                                        <b>{selectedRule.multiplier && selectedRule.multiplier !== ""  ? selectedRule.multiplier : 0}</b>%
                                                    </span>
                                                </>
                                            :''}
                                            <div className='pl-2'>
                                                {selectedRule.loyaltyCardLevelRule?.filter(({loyaltyCardLevelConfigId, multiplier}) => loyaltyCardLevelConfigId && multiplier).map((card, key) =>
                                                    <div key={key}>
                                                        <span className='mr-1'>
                                                            <FormattedMessage id="ConfigCardPoints.Explain.TheLevel"/>
                                                        </span>
                                                        <span className='mr-1'>
                                                            <b>{loyaltyCards.find(el => el.id === card.loyaltyCardLevelConfigId)?.description}</b>
                                                        </span>
                                                        <span className='mr-1'>
                                                            <FormattedMessage id="ConfigCardPoints.Explain.WillBeAwardedTimes"/>
                                                        </span>
                                                        <span className='mr-1'>
                                                            <b>{card.multiplier}</b>%
                                                        </span>
                                                        <span className='mr-1'>
                                                            <FormattedMessage id="ConfigCardPoints.Explain.FromTheBase"/>
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    :
                                        <div className='mt-2 mb-4'>
                                            <span className='mr-1'>
                                                <FormattedMessage id="ConfigCardPoints.Explain.ForEvery"/>
                                            </span>
                                            <span className='mr-1'>
                                                <b><FormattedMessage id={`ConfigCardPoints.${selectedRule.type}`}/></b>
                                            </span>
                                            {selectedRule.subType ?
                                                <span className='mr-1'>
                                                    {selectedRule.subType === "All" ?
                                                        <FormattedMessage id="ConfigCardPoints.Explain.FromAll"/>
                                                    :
                                                        selectedRule.subType && selectedRule.codes?.length > 0 ?
                                                            <FormattedMessage id="ConfigCardPoints.Explain.From"/>
                                                        :''
                                                    }
                                                </span>
                                            :''}
                                            <span className='mr-1'>
                                                {selectedRule.type !== 'AdditionalRevenue' && selectedRule.type !== 'ReservationRevenue' && selectedRule.subType ?
                                                    <>
                                                        <b><FormattedMessage id={`ConfigCardPoints.${selectedRule.subType}`}/></b>
                                                    </>
                                                :
                                                    selectedRule.subType === "All" ?
                                                        <FormattedMessage id="ConfigCardPoints.Explain.AllCodes"/>
                                                    :
                                                        selectedRule.subType && selectedRule.codes?.length > 0 ?
                                                            <FormattedMessage id="ConfigCardPoints.Explain.Code"/>
                                                        :''
                                                }
                                            </span>
                                            {selectedRule.codes?.map((code, key) =>
                                                <span key={key}>
                                                    {key > 0 ?
                                                        <span className='mr-1'>,</span>
                                                    :''}
                                                    <b>{this.getCodeOptions().find(({value}) => value === code)?.label}</b>
                                                </span>
                                            )}
                                            {selectedRule.filterType && selectedRule.filterValue?.length > 0 && selectedRule.type === 'ReservationRevenue' ?
                                                <span className={selectedRule.codes?.length > 0 ? 'ml-1' : ''}>
                                                    <span className='mr-1'>
                                                        <FormattedMessage id={`ConfigCardPoints.Explain.Where`}/>
                                                    </span>
                                                    <b className='mr-1'><FormattedMessage id={`ConfigCardPoints.${selectedRule.filterType}`}/></b>
                                                    <span className='mr-1'>
                                                        <FormattedMessage id={`ConfigCardPoints.Explain.Is`}/>
                                                    </span>
                                                    <span>
                                                        {selectedRule.filterValue?.map((code, key) =>
                                                            <span key={key} className='mr-1'>
                                                                {key > 0 ?
                                                                    <span className='mr-1'><FormattedMessage id={`ConfigCardPoints.Explain.Or`}/></span>
                                                                :''}
                                                                <b>{this.getFilterValueOptions().find(({value}) => value === code)?.label}</b>
                                                            </span>
                                                        )}
                                                    </span>
                                                </span>
                                            :''}
                                            {selectedRule.subType === "All" || selectedRule.codes?.length > 0 ?
                                                <>
                                                    <span className='ml-1'>
                                                        <FormattedMessage id="ConfigCardPoints.Explain.EveryCard"/>
                                                    </span>
                                                    <span className='ml-1'>
                                                        <b>{selectedRule.multiplier && selectedRule.multiplier !== ""  ? selectedRule.multiplier : 0}</b>%
                                                    </span>
                                                </>
                                            :''}
                                            {selectedRule.beginDate ?
                                                <>
                                                    <span className='ml-1'>
                                                        <FormattedMessage id="ConfigCardPoints.Explain.FromDate"/>
                                                    </span>
                                                    <span className='ml-1'>
                                                        <b>{moment(selectedRule.beginDate).format('DD-MM-YYYY')}</b>
                                                    </span>
                                                </>
                                            :''}
                                            {selectedRule.endDate ?
                                                <>
                                                    <span className='ml-1'>
                                                        <FormattedMessage id="ConfigCardPoints.Explain.To"/>
                                                    </span>
                                                    <span className='ml-1'>
                                                        <b>{moment(selectedRule.endDate).format('DD-MM-YYYY')}</b>
                                                    </span>
                                                </>
                                            :''}

                                            <div className='pl-2'>
                                                {selectedRule.loyaltyCardLevelRule?.filter(({loyaltyCardLevelConfigId, multiplier}) => loyaltyCardLevelConfigId && multiplier).map((card, key) =>
                                                    <div key={key}>
                                                        <span className='mr-1'>
                                                            <FormattedMessage id="ConfigCardPoints.Explain.TheLevel"/>
                                                        </span>
                                                        <span className='mr-1'>
                                                            <b>{loyaltyCards.find(el => el.id === card.loyaltyCardLevelConfigId)?.description}</b>
                                                        </span>
                                                        <span className='mr-1'>
                                                            <FormattedMessage id="ConfigCardPoints.Explain.WillBeAwardedTimes"/>
                                                        </span>
                                                        <span className='mr-1'>
                                                            <b>{card.multiplier}</b>%
                                                        </span>
                                                        <span className='mr-1'>
                                                            <FormattedMessage id="ConfigCardPoints.Explain.FromTheBase"/>
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    }
                                </div>
                            :''}
                        </Form>
                    </ModalBody>
                :''}
            </Modal>
        )
    }
}

export default injectIntl(RuleModal)