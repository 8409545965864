import React, { Component } from 'react';
import { Button, Modal, ModalBody, Row, Col } from 'reactstrap';
import { getAPI } from "../Base/API";
import { StyledCard, BlankCard, SelectHotel } from "../Base/CommonUIComponents";
import { CustomTable } from "../Base/CustomTable";
import { handleNotification } from "../Base/Notification";
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import CustomSelect from '../Base/CustomSelect';
import { FormattedMessage, injectIntl } from 'react-intl';


export class SyncEvents extends Component {


    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.state = {
            block: true,
            activeTab: '1',
            modal: false,
            eventTypes: [{ value: 'Error', label: 'Error' }, { value: 'Warning', label: 'Warning' }],
            eventType: null,
            syncEvents: [],
            startDate: moment().subtract(7, 'day'),
            endDate: moment(),
            pageIndex: 1,
            pageSize: 10,
            hotelId: null
        };
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    componentDidMount() {
        this.getSyncEvents();
    }

    getSyncEvents() {
        const { startDate, endDate, eventType, hotelId, pageIndex, pageSize } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else if (data.response) {
                    this.setState({ syncEvents: data.response, error: errorMessage, block: false });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/SyncEvent/v1/SyncEvents?fromDate=${startDate.format('YYYY-MM-DD')}&toDate=${endDate.format('YYYY-MM-DD')}&eventType=${eventType !== null ? eventType : ``}&hotelId=${hotelId !== null ? hotelId : ``}&pageSize=${pageSize}&pageIndex=${pageIndex - 1}`);
    }

    handleTableChange = (page) => {
        this.setState({
            block: true,
            pageIndex: page,
        }, this.getSyncEvents);
    }

    search = () => {
        this.setState({ block: true, pageIndex: 1 }, this.getSyncEvents);
    }

    handleChangeSelect = (name, combo) => {
        console.log(name);
        console.log(combo);
        //const { name } = evt;
        this.setState({
            [name]: combo && combo.value
        });
    };

    render() {
        const { block, error, modal, startDate, endDate, hotelId, pageIndex, pageSize, syncEvents, val } = this.state;

        const columns = [
            {
                dataField: 'id',
                text: 'Id'
            },
            {
                dataField: 'hotelName',
                text: this.props.intl.formatMessage({ id: "SyncEvents.HotelName" })
            },
            {
                dataField: 'hotelId',
                text: this.props.intl.formatMessage({ id: "SyncEvents.HotelId" })
            },
            {
                dataField: 'type',
                text: this.props.intl.formatMessage({ id: "SyncEvents.Type" }),
                formatter: (cell, row) => <div>{cell}</div>
            },
            {
                dataField: 'code',
                text: this.props.intl.formatMessage({ id: "SyncEvents.Code" }),
                formatter: (cell, row) => <div>{cell === 0 ? 'Unknown' : cell === 1 ? 'Serialization failed' : cell === 2 ? 'Root object not found' : cell === 3 ? 'Record is older than the one on DB' : cell === 4 ? "Not a valid object" : cell}</div>
            },
            {
                dataField: 'exception',
                text: this.props.intl.formatMessage({ id: "SyncEvents.Exception" })
            }, 
            {
                dataField: 'objectType',
                text: this.props.intl.formatMessage({ id: "SyncEvents.ObjectType" })
            }, {
                dataField: 'timeStamp',
                text: this.props.intl.formatMessage({ id: "SyncEvents.TimeStamp" })
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: row => this.setState({ modal: true, val: row.message })
        };

        return (
            <StyledCard block={block} error={error} icon={'fas fa-sync-alt'} title={'SyncEvents.title'}>
                <Modal isOpen={modal} fade={false}>
                    <Button onClick={this.toggleModal} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    <ModalBody>
                        <BlankCard>
                            <Row className="mb-3">
                                <Col>
                                    <h5><FormattedMessage id="SyncEvents.MessageReceived" /> </h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div>
                                        <pre>
                                            {val != null ? val : ""}
                                        </pre>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-right">
                                    <Button className="btn btn-host" onClick={this.toggleModal}><FormattedMessage id="SyncEvents.Close" /></Button>
                                </Col>
                            </Row>
                        </BlankCard>
                    </ModalBody>
                </Modal>

                <Row className="mt-3">
                    <Col className="col-3">
                        <CustomSelect name={'eventType'} icon={'fas fa-exclamation-circle'} isClearable isSearchable placeholder={<FormattedMessage id='SyncEvents.ChooseEventType' />} options={this.state.eventTypes} onChange={this.handleChangeSelect.bind(this, 'eventType')} />
                    </Col>
                    <Col className="col-2">
                        <SelectHotel
                            name={'hotelId'}
                            icon={'icon-icon-hotel'}
                            onChangeFunc={this.handleChangeSelect}
                            placeholder={<FormattedMessage id="generic.Hotel" />}
                            value={hotelId}
                        />
                    </Col>
                    <Col className="col-2">
                        <DateRangePicker
                            startDate={startDate}
                            startDateId="startDate"
                            isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment()}
                            endDate={endDate}
                            endDateId="endDate"
                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate: startDate, endDate: endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </Col>
                    <Col className="text-right">
                        <Button className="btn btn-sm btn-host" onClick={this.search}><i className="icon-icon-search"></i></Button>
                    </Col>
                </Row>
                <hr />
                <CustomTable
                    data={syncEvents}
                    columns={columns}
                    exportCSV={true}
                    selectRow={selectRow}
                    hasPagination={false}
                    rowStyle={{ cursor: 'pointer' }}   
                />
                <Row className="mt-3">
                    <Col className="col-1">
                        <Button className="btn-host shadow btn-sm mr-1" disabled={pageIndex <= 1} onClick={() => this.handleTableChange(parseInt(pageIndex) - 1)}>
                            <i className="fas fa-angle-left" />
                        </Button>
                    </Col>
                    <Col className="text-center" style={{ fontSize: '20px' }}>
                        <FormattedMessage id="SyncEvents.Page" values={{ page: pageIndex }} />
                    </Col>
                    <Col className="col-1 text-right">
                        <Button className="btn-host btn-sm shadow" disabled={syncEvents.length < pageSize} onClick={() => this.handleTableChange(parseInt(pageIndex) + 1)}>
                            <i className="fas fa-angle-right" />
                        </Button>
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}

export default injectIntl(SyncEvents);