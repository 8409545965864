import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Col, Row, Label, FormGroup, Modal, ModalBody, Input, CustomInput, Card } from 'reactstrap';
import { deepClone, getDailyReportElements, getDataQualityReportType } from '../../Base/ReferenceDataFunctions';
import { handleNotification } from '../../Base/Notification';
import { BlankCard } from '../../Base/CommonUIComponents';
import CustomSelect from "../../Base/CustomSelect";
import { postAPI, getAPI } from '../../Base/API';

class DailyReportModal extends Component {

    constructor(props) {
        super(props);

        this.form = React.createRef();

        this.state = {
            block: false,
            config: this.props.selectedConfig || { active: true, reportElements: [] }
        };
    }

    componentDidMount() {
        if (this.props.selectedConfig && this.props.selectedConfig.type) {
            const type = getDataQualityReportType().find(rt => rt.value === this.props.selectedConfig.type);

            if (type) {
                this.getTemplates(type.templateType);
            }
        }
    }

    getTemplates = (templateType) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                let list = [];

                data.response && data.response.forEach(template => {
                    list.push({ value: template.id, label: template.name, html: template.htmlTemplate });
                })

                this.setState({ templates: list, block: false })
            }
            else this.setState({ block: false })
        }, `/api/gms/DataQuality/v1/notifyGenericTemplates?eventType=${templateType}`);
    }

    saveReportConfig = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const body = deepClone(this.state.config);

        if (body.type === getDataQualityReportType()[0].value) {
            var validation = document.getElementById("reportElements");

            if (!body.reportElements || body.reportElements.length < 1 === true) {
                validation.setCustomValidity("At least one element must be selected");
            }
            else {
                validation.setCustomValidity("");
            }
        }
        else if (body.type === getDataQualityReportType()[1].value) {
            body.hotelId = "00000000-0000-0000-0000-000000000000";
        }

        if (!this.form.current.reportValidity()) {
            this.setState({ block: false });
            return;
        }


        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="DailyReport.ConfigSaved" />, <FormattedMessage id="generic.success" />);

                    var config = Object.assign(this.state.config, data.response[0]);
                    config.hotelName = this.state.selectedHotel;

                    this.props.updateList(config);
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/DataQuality/v1/DataQualityReport`, JSON.stringify({ request: body }));
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            config: {
                ...this.state.config,
                [name]: value
            }
        })
    }

    handleChangeSelect = (combo, name) => {
        let { selectedHotel } = this.state;

        if (name === 'hotelId') {
            selectedHotel = combo ? combo.value : null;
        }

        this.setState({
            selectedHotel,
            config: {
                ...this.state.config,
                [name]: combo ? combo.value : null
            }
        }, () => {
            if (name === 'type' && combo) {
                this.getTemplates(combo.templateType);
            }
        })
    };

    handleChangeTemplate = (combo) => {
        this.setState({
            config: {
                ...this.state.config,
                notifyGenericTemplateId: combo ? combo.value : null
            },
            htmlTemplate: combo ? combo.html : null
        })
    };

    handleCheckbox = (e) => {
        const { name, id, checked } = e.target;

        let value = [...this.state.config.reportElements] || [];

        if (checked) {
            value.push(id)
        }
        else {
            value = value.filter(el => el !== id);
        }

        this.setState({
            config: {
                ...this.state.config,
                [name]: value
            }
        })
    }

    handleSwitch = (evt) => {
        const { name, checked } = evt.target;

        this.setState({
            config: {
                ...this.state.config,
                [name]: checked
            }
        });
    }

    addEmail = () => {
        var emails = this.state.config.email ? this.state.config.email.split(';') : [];

        emails.push(this.state.newEmail);

        this.setState({
            config: {
                ...this.state.config,
                email: emails.join(';')
            },
            newEmail: ''
        })
    }

    removeEmail = (index) => {
        var emails = this.state.config.email.split(';');

        emails.splice(index, 1);

        this.setState({
            config: {
                ...this.state.config,
                email: emails.join(';')
            }
        })
    }

    handleDaysToReport = (evt) => {
        if (evt) {
            const { name, value } = evt.target;

            this.setState({
                config: {
                    ...this.state.config,
                    [name]: value
                }
            });
        }
    }

    render() {
        const { modal, toggleModal, hotelList, availableTypes } = this.props;
        const { templates, config } = this.state;

        const isInHouseReport = config.type === getDataQualityReportType()[0].value;

        return (
            <Modal isOpen={modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={this.state.block} error={this.state.error}>
                        <form ref={this.form}>
                            <Row className="mb-3">
                                <Col>
                                    <h5><FormattedMessage id="DailyReport.ReportConfig" /></h5>
                                </Col>
                                <Col className="text-right col-3">
                                    <Button className="btn-sm btn-host" type="submit" onClick={this.saveReportConfig} disabled={!config.email}>
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>


                            <FormGroup row className="align-items-center">
                                <Label sm={2}> <FormattedMessage id="generic.status" /></Label>
                                <Col sm={10} >
                                    <CustomInput
                                        type="switch"
                                        id="active"
                                        name="active"
                                        onChange={(e) => this.handleSwitch(e)}
                                        checked={config.active || false}
                                    />
                                </Col>
                            </FormGroup>

                            <Row>
                                <Col>
                                    <FormGroup row className="align-items-center">
                                        <Label sm={4}> <FormattedMessage id="generic.Type" /> </Label>
                                        <Col sm={8} style={{ zIndex: '999' }}>
                                            <CustomSelect
                                                options={availableTypes}
                                                required
                                                isClearable
                                                isSearchable
                                                isDisabled={config.id}
                                                placeholder={""}
                                                onChange={(e) => this.handleChangeSelect(e, 'type')}
                                                value={getDataQualityReportType()?.find(el => el.value == config.type) || ''}
                                            />
                                        </Col>
                                    </FormGroup>
                                    {isInHouseReport ?
                                        <FormGroup row className="align-items-center">
                                            <Label sm={4}> <FormattedMessage id="generic.Hotel" /></Label>
                                            <Col sm={8} style={{ zIndex: '995' }}>
                                                <CustomSelect
                                                    options={hotelList.filter(el => !el.hasConfig)}
                                                    required
                                                    isClearable
                                                    isSearchable
                                                    isDisabled={config.id}
                                                    placeholder={""}
                                                    onChange={(e) => this.handleChangeSelect(e, 'hotelId')}
                                                    value={hotelList?.find(el => el.value == config.hotelId) || ''}
                                                />
                                            </Col>
                                        </FormGroup>
                                    : ''}

                                    <FormGroup row>
                                        <Label sm={4}> <FormattedMessage id="generic.Template" /></Label>
                                        <Col sm={8} style={{ zIndex: '990' }}>
                                            <CustomSelect
                                                options={templates || []}
                                                required
                                                isClearable
                                                isSearchable
                                                placeholder={""}
                                                isDisabled={!config.type}
                                                onChange={(e) => this.handleChangeTemplate(e)}
                                                value={templates?.find(temp => temp.value === config.notifyGenericTemplateId) || ''}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>

                                <Col sm={6}>
                                    <Row className="align-items-center mb-2">
                                        <Label sm={3}> <FormattedMessage id="generic.Email" /></Label>
                                        <Col sm={7} >
                                            <Input
                                                type="email"
                                                name="newEmail"
                                                onChange={(e) => this.setState({ newEmail: e.target?.value })}
                                                value={this.state.newEmail || ''}
                                            />
                                        </Col>
                                        <Col className="text-right col-2">
                                            <Button className="btn-sm btn-host" onClick={this.addEmail} disabled={!this.state.newEmail}>
                                                <i className="fas fa-plus" />
                                            </Button>
                                        </Col>
                                    </Row>

                                    {config.email && config.email.split(';').map((el, key) =>
                                        <Row key={key} className="align-items-center">
                                            <Col sm={3}/>
                                            <Col className="ml-3 py-1"> {el} </Col>
                                            <Col className="col-2 text-right pr-4" onClick={() => this.removeEmail(key)} style={{ color: 'red', cursor: 'pointer' }}>
                                                <i className="fas fa-trash-alt"></i>
                                            </Col>
                                        </Row>
                                    )}
                                </Col>
                            </Row>

                            {
                                isInHouseReport ?
                                    <span>
                                        <FormGroup row className="align-items-center">
                                            <Label sm={2}> <FormattedMessage id="DailyReport.DaysToReport" /></Label>
                                            <Col sm={4}>
                                                <Input
                                                    id="daysToReport"
                                                    name="daysToReport"
                                                    type="number"
                                                    min="0"
                                                    max="5"
                                                    onChange={this.handleDaysToReport}
                                                    value={this.state.config.daysToReport || ''}
                                                />
                                            </Col>
                                            <Col sm={4} />
                                        </FormGroup>
                                        <FormGroup row className="my-2">
                                            <Label sm={2}>
                                                <FormattedMessage id="DailyReport.Elements" />
                                                <br />
                                                <input tabIndex={-1} id="reportElements" name="reportElements" autoComplete="off" style={{
                                                    opacity: 0,
                                                    width: "100%",
                                                    height: 0,
                                                    position: "absolute"
                                                }} />
                                            </Label>
                                            <Col sm={10} >
                                                <div className="mt-2" >
                                                    {getDailyReportElements(this.props.intl).map((element, index) =>
                                                        <div className="pr-3 mb-2 " key={index}>
                                                            <CustomInput
                                                                type="checkbox"
                                                                name="reportElements"
                                                                id={element.value}
                                                                checked={config.reportElements?.some(el => el === element.value) || false}
                                                                onChange={(e) => this.handleCheckbox(e)}
                                                            >
                                                                {element.label} <span className="text-muted"> - {element.description} </span>
                                                            </CustomInput>
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                                        </FormGroup>
                                    </span>
                                    :
                                    ''
                            }


                            {this.state.htmlTemplate ?
                                <>
                                    Template Preview

                                    <Card className='border-0 shadow mt-3' style={{  height: '500px', overflow: 'hidden' }}>
                                        <div dangerouslySetInnerHTML={{ __html: this.state.htmlTemplate }} style={{ maxHeight: "100%", overflow: "auto", overflowX: "hidden" }} />
                                    </Card>
                                </>
                            : ''}
                        </form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(DailyReportModal)