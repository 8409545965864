import React, { Component } from 'react';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import { Button, Card, Col, Form, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../Base/CustomSelect';
import CustomFunnel from '../Base/CustomFunnel';
import { Doughnut } from 'react-chartjs-2';
import { FormatAmountNumber, CommonHelper, ExportToPDFButton } from '../Base/CommonUIComponents';
import { getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import CustomTwoMonthDateRangePicker from '../Base/CustomTwoMonthDateRangePicker';
import { getSalesProcessTypeOfRevenue } from '../Base/ReferenceDataFunctions';
import { SalesProcessHotelCombo } from './SalesProcessFunctions';
import Chart from 'react-google-charts';


class SalesProcessExecutiveReportYearly extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            block: true,
            hotelList: [],
            totalStageMetrics: [],
            funnelData: null,
            modal: false,
            selectedHotel: null,
            selectedRevenue: 'totalValue',
            preSelectedRevenue: 'totalValue',
        };
    }

    componentDidMount(){
        const { yearlyReport } = this.props;
        if(!yearlyReport){
            this.getYearlyReport();
        }
        else{
            this.inicialCalc(yearlyReport);
        }
    }

    getYearlyReport = () => {
        const { hotelList } = this.state;
        const { startDate, endDate } = this.props;

        let qs = `?startDate=${startDate?.format('YYYY-MM-DD')}&endDate=${endDate?.format('YYYY-MM-DD') }`;

        if(hotelList?.length)
            hotelList.forEach(h => {
                qs += `&hotelIds=${h}`;
            });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if(data.data?.length){
                    this.inicialCalc(data.data[0]);
                    return;
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/executivereport/yearly${qs}`);
    }

    inicialCalc = (yearlyReport) => {
        const { intl, colors } = this.props;
        const { selectedRevenue } = this.state;
        const totalStageMetrics = [];
        let funnelData, pieDataRevenue, segmentChart, chartData = null;
        let groupedSources = {};

        if (yearlyReport.hotels) {
            segmentChart = this.segmentChartCalc(yearlyReport.hotels);

            const numbOfDeals = yearlyReport.hotels.length > 0 ? yearlyReport.hotels.reduce((a,b) => a + b.stagesTypes
                .reduce((a, b) => a + b.sourceTypes
                    .reduce((a, b) => a + (b?.deals?.numberOfDeals??0), 0), 0
                ), 0
            ) : 0;

            yearlyReport.hotels.forEach((hotel, key) => {
                hotel.color = colors.at(key);



                const totalBudget = {
                    numberOfLodging: 0, fb: 0, banquets: 0, lodging: 0, misc: 0, spaceRental: 0, totalValue: 0
                };

                hotel.budget.forEach(el => {
                    if (el.salesGroup === 'FB') {
                        totalBudget.fb += el.value;
                        totalBudget.banquets += el.value;
                    }
                    else if (el.salesGroup === 'ROOM') {
                        totalBudget.numberOfLodging += el.roomNights || 0;
                        totalBudget.lodging += el.value;
                    }
                    else if (el.salesGroup === 'ETC') {
                        totalBudget.misc += el.value;
                    }
                    else if (el.salesGroup === 'BANQ') {
                        totalBudget.spaceRental += el.value;
                    }

                    totalBudget.totalValue += el.value || 0;
                })

                hotel.totalBudget = totalBudget;
                
                
                if (hotel.stagesTypes) {
                    const hotelNumbOfDeals = hotel.stagesTypes.reduce((a, b) => a + b.sourceTypes
                        .reduce((a, b) => a + (b?.deals?.numberOfDeals??0), 0), 0
                    );
                    
                    hotel.numberOfDeals = hotelNumbOfDeals;
                    hotel.dealShare = Math.round((hotelNumbOfDeals / numbOfDeals * 100) * 100) / 100;

                    hotel.stagesTypes.forEach(stage => {
                        let current = totalStageMetrics.find(({stageTypeId}) => stageTypeId === stage.stageTypeId);

                        if(!current){
                            totalStageMetrics.push({
                                stageTypeId: stage.stageTypeId,
                                stageName: stage.stageName,
                                isDef: stage.isDef
                            });
                            current = totalStageMetrics.find(({stageTypeId}) => stageTypeId === stage.stageTypeId);
                        }

                        stage.numberOfDeals = stage.sourceTypes.reduce((a, b) => a + (b?.deals?.numberOfDeals??0), 0);
                        stage.numberOfWonDeals = stage.sourceTypes.reduce((a, b) => a + (b?.deals?.numberOfWonDeals??0), 0);
                        stage.numberOfLostDeals = stage.sourceTypes.reduce((a, b) => a + (b?.deals?.numberOfLostDeals??0), 0);

                        stage.fb = stage.sourceTypes.reduce((a, b) => a + (b?.revenueMetrics?.fb??0), 0);
                        stage.banquets = stage.sourceTypes.reduce((a, b) => a + (b?.revenueMetrics?.banquets ??0), 0);
                        stage.misc = stage.sourceTypes.reduce((a, b) => a + (b?.revenueMetrics?.misc??0), 0);
                        stage.lodging = stage.sourceTypes.reduce((a, b) => a + (b?.revenueMetrics?.lodging??0), 0);
                        stage.spaceRental = stage.sourceTypes.reduce((a, b) => a + (b?.revenueMetrics?.spaceRental??0), 0);
                        stage.totalValue = stage.sourceTypes.reduce((a, b) => a + (b?.revenueMetrics?.totalValue??0), 0);
                        stage.numberOfLodging = stage.sourceTypes.reduce((a, b) => a + (b?.revenueMetrics?.numberOfLodging??0), 0);

                        const numberOfDeals = (current.deals?.numberOfDeals??0) + stage.numberOfDeals;
                        
                        stage.conversionRate = Math.round(((stage.numberOfDeals / hotelNumbOfDeals) * 100) * 100) / 100;
                        
                        current.deals = {
                            numberOfDeals,
                            numberOfWonDeals: (current.deals?.numberOfWonDeals??0) + stage.numberOfWonDeals??0,
                            numberOfLostDeals: (current.deals?.numberOfLostDeals??0) + stage.numberOfLostDeals??0,
                            conversionRate: Math.round(((numberOfDeals / numbOfDeals) * 100) * 100) / 100
                        };

                        current.revenueMetrics = {
                            fb: (current.revenueMetrics?.fb??0) + stage.fb??0,
                            banquets: (current.revenueMetrics?.banquets ?? 0) + stage.banquets ??0,
                            misc: (current.revenueMetrics?.misc??0) + stage.misc??0,
                            lodging: (current.revenueMetrics?.lodging??0) + stage.lodging??0,
                            spaceRental: (current.revenueMetrics?.spaceRental??0) + stage.spaceRental??0,
                            totalValue: (current.revenueMetrics?.totalValue??0) + stage.totalValue??0,
                            numberOfLodging: (current.revenueMetrics?.numberOfLodging??0) + stage.numberOfLodging??0
                        };
                    });

                    hotel.fb = hotel.stagesTypes.reduce((a, b) => a + b.fb, 0);
                    hotel.banquets = hotel.stagesTypes.reduce((a, b) => a + b.banquets, 0);
                    hotel.misc = hotel.stagesTypes.reduce((a, b) => a + b.misc, 0);
                    hotel.lodging = hotel.stagesTypes.reduce((a, b) => a + b.lodging, 0);
                    hotel.spaceRental = hotel.stagesTypes.reduce((a, b) => a + b.spaceRental, 0);
                    hotel.totalValue = hotel.stagesTypes.reduce((a, b) => a + b.totalValue, 0);
                }
            });
            
            let revenueColors = colors.slice(0, 5);
            pieDataRevenue = {
                datasets: [
                    {
                        label: intl.formatMessage({ id: "SalesProcess.RevenueDistribution" }),
                        data: yearlyReport.hotels.length > 1 ?
                            yearlyReport.hotels.reduce((accumulator, hotel) => {

                                if (selectedRevenue === "totalValue") {

                                    Array.isArray(accumulator) ? accumulator[0] += hotel.fb ?? 0 : accumulator.fb += hotel.fb ?? 0;
                                    Array.isArray(accumulator) ? accumulator[1] += hotel.banquets ?? 0 : accumulator.banquets += hotel.banquets ?? 0;
                                    Array.isArray(accumulator) ? accumulator[2] += hotel.lodging ?? 0 : accumulator.lodging += hotel.lodging ?? 0;
                                    Array.isArray(accumulator) ? accumulator[3] += hotel.misc ?? 0 : accumulator.misc += hotel.misc ?? 0;
                                    Array.isArray(accumulator) ? accumulator[4] += hotel.spaceRental ?? 0 : accumulator.spaceRental += hotel.spaceRental ?? 0;
                                    
                                }
                                if ( selectedRevenue === "fb") {
                                    Array.isArray(accumulator) ? accumulator[0] += hotel.fb ?? 0: accumulator.fb += hotel.fb ?? 0;
                                }
                                if (selectedRevenue === "banquets") {
                                    Array.isArray(accumulator) ? accumulator[0] += hotel.banquets ?? 0 : accumulator.banquets +=  hotel.banquets ?? 0;
                                }
                                if (selectedRevenue === "misc") {
                                    Array.isArray(accumulator) ? accumulator[0] += hotel.misc ?? 0 : accumulator.misc += hotel.misc ?? 0;
                                }
                                if (selectedRevenue === "spaceRental") {
                                    Array.isArray(accumulator) ? accumulator[0] += hotel.spaceRental ?? 0 : accumulator.spaceRental += hotel.spaceRental ?? 0;
                                }
                                if (selectedRevenue === "lodging") {
                                    Array.isArray(accumulator) ? accumulator[0] += hotel.lodging ?? 0 :  accumulator.lodging += hotel.lodging ?? 0;
                                }

                                if (selectedRevenue === "totalValue") {
                                    return Array.isArray(accumulator) ? accumulator : [accumulator.fb, accumulator.banquets, accumulator.lodging, accumulator.misc, accumulator.spaceRental];
                                }
                                    
                                else
                                    return Array.isArray(accumulator) ? accumulator :[accumulator[selectedRevenue]];
                            })
                            : yearlyReport.hotels.length === 1 ?
                                selectedRevenue === "totalValue" ?
                                    [yearlyReport.hotels[0].fb ?? 0, yearlyReport.hotels[0].banquets ?? 0, yearlyReport.hotels[0].lodging ?? 0, yearlyReport.hotels[0].misc ?? 0, yearlyReport.hotels[0].spaceRental ?? 0 ]
                                    : [yearlyReport.hotels[0][selectedRevenue] ?? 0]
                            : [],
                        sourceNames: getSalesProcessTypeOfRevenue(intl).filter(x => x.value !== "totalValue" && (selectedRevenue === "totalValue" || x.value === selectedRevenue)).map(x => x.label),
                        backgroundColor: revenueColors
                    },
                ],
                labels: (getSalesProcessTypeOfRevenue(intl).filter(x => x.value !== "totalValue" && (selectedRevenue === "totalValue" || x.value === selectedRevenue)).map(x => x.label) ?? '-')
            };

            funnelData = totalStageMetrics.map(stage => ({
                id: (stage.deals.numberOfDeals - stage.deals.numberOfLostDeals) + " Deals\n" + this.formatAmountNumber(stage.revenueMetrics[selectedRevenue], "EUR") + "\n" + stage.deals.conversionRate + " %\n",
                value: stage.revenueMetrics[selectedRevenue],
                label: stage.stageName 
            }))

            chartData = {
                datasets: [
                    {
                        label: intl.formatMessage({ id: "SalesProcess.RevenueDistribution" }),
                        data: Object.values(yearlyReport.deals.lostDealsReasons),
                        backgroundColor: colors
                    },
                ],
                labels: Object.keys(yearlyReport.deals.lostDealsReasons)
            };

            yearlyReport.hotels.forEach((hotel) => {
                hotel.stagesTypes.forEach((stage) => {
                    stage.sourceTypes.forEach((source) => {
                        const sourceTypeId = source.sourceTypeId;
                        if (!groupedSources[sourceTypeId]) {
                            groupedSources[sourceTypeId] = {
                                totalDeals: 0,
                                totalWonDeals: 0,
                                totalValue: 0,
                            };
                        }

                        groupedSources[sourceTypeId].totalDeals += source.deals.numberOfDeals;
                        groupedSources[sourceTypeId].totalWonDeals += source.deals.numberOfWonDeals;
                        groupedSources[sourceTypeId].totalValue += source.revenueMetrics.totalValue;
                        groupedSources[sourceTypeId].sourceName = source.sourceName;
                    });
                });
            });
        }

        this.setState({
            block: false,
            totalStageMetrics: totalStageMetrics??[],
            funnelData,
            groupedSources,
            pieDataRevenue,
            chartData,
            segmentChart
        }, () => this.props.updateYearlyReport(yearlyReport));
        return;
    }

    segmentChartCalc = (hotels) => {
        var chart = [
            ["Location", "Parent", "Total"],
            ["Segment", null, 0]
        ];
        
        if (hotels?.length > 0) {
            const { segmentList, subSegmentList } = this.props;

            hotels.forEach(hotel => {
                hotel.segments.forEach(segment => {

                    segment.subSegment.forEach(subSegment => {
                        const segmentName = segment.name ? segmentList?.find(el => el.hotelId === hotel.hotelId && el.code === segment.name)?.description ?? segment.name : 'N/A';
                        const subSegmentName = `${subSegment.name ? subSegmentList?.find(el => el.hotelId === hotel.hotelId && el.code === subSegment.name)?.description ?? subSegment.name : 'N/A'} - ${segmentName}`;

                        const segmentId = `Segment-${segmentName}`;

                        const segmentIndex = chart.findIndex(data => data[0].v === segmentId);

                        if (segmentIndex !== -1) {
                            const subSegmentIndex = chart.findIndex(data => data[0] === subSegmentName && data[1] === segmentId);

                            if (subSegmentIndex !== -1) {
                                chart[subSegmentIndex][2] += subSegment.value ?? 0;
                            }
                            else {
                                chart.push([subSegmentName, segmentId, subSegment.value ?? 0]);
                            }

                            chart[segmentIndex][2] += subSegment.value ?? 0;
                        }
                        else {
                            chart.push([{ v: segmentId, f: segmentName }, "Segment", subSegment.value ?? 0]);
                            chart.push([subSegmentName, segmentId, subSegment.value ?? 0]);
                        }
                    })
                })
            })
        }

        return chart;
    }

    handleHotelListChange = (combo, e) => {
        this.setState({ hotelList: e ? e?.map(cmb => cmb.value) : [] });
    }

    handleSearch = (e) => {
        e.preventDefault();
            this.setState({ block: true, selectedRevenue: this.state.preSelectedRevenue }, () => this.getYearlyReport());
    }

    downLoadFormatAmountNumber = (value, currency) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value).replaceAll(",", "");
    };

    downloadData = () => {
        const { totalStageMetrics, selectedRevenue, groupedSources, pieDataRevenue } = this.state;
        const { yearlyReport } = this.props;

        let csv = `Deal Type,Deals,Value (€)\n`;

        csv += `Lost Deals,${yearlyReport?.deals?.numberOfLostDeals ?? '0'},${this.downLoadFormatAmountNumber(yearlyReport?.deals?.proposalValueLost ?? 0, "EUR") }\n`;
        csv += `Won Deals,${yearlyReport?.deals?.numberOfWonDeals ?? '0'},${this.downLoadFormatAmountNumber(yearlyReport?.deals?.proposalValueWon ?? 0, "EUR")}\n`;
        csv += `Negotiation Deals,${yearlyReport?.deals?.numberOfDeals ?? '0'},${this.downLoadFormatAmountNumber((yearlyReport?.deals?.proposalValueTotal ?? 0) - (yearlyReport?.deals?.proposalValueLost ?? 0) + (yearlyReport?.deals?.proposalValueWon ?? 0), "EUR")}\n`;
        csv += `Deals,${yearlyReport?.deals?.numberOfDeals - yearlyReport?.deals?.numberOfWonDeals - yearlyReport?.deals?.numberOfLostDeals ?? '0'},${this.downLoadFormatAmountNumber(yearlyReport?.deals?.proposalValueTotal ?? 0, "EUR")}\n\n`;

        csv += '\nHotel';
        totalStageMetrics.forEach(stageMetric => {
            csv += `,${stageMetric.stageName + ' #'?? '-' }`
            csv += `,${stageMetric.stageName + ' ' + "EUR" ?? '-' }`
            csv += `,${stageMetric.stageName + ' Nr' ?? '-' }`
            csv += `,${stageMetric.stageName + ' %' ?? '-' }`
        });
        csv += '\n';

        yearlyReport.hotels.forEach(hotel => {
            csv += `${global.hotelList.find(({ value }) => value === hotel.hotelId)?.label ?? '-'}`;
            hotel.stagesTypes.forEach(stageMetric => {
                csv += `,${stageMetric.numberOfLodging ?? '0'}`;
                csv += `,${this.downLoadFormatAmountNumber(stageMetric.totalValue??0, "EUR")}`;
                csv += `,${stageMetric.numberOfDeals ?? '0'}`;
                csv += `,${stageMetric.conversionRate ?? '0'}%`;
            });
            csv += '\n';
        });
        csv += '\n';

        csv += '\nStage,Number of Deals,Revenue,Number of Deals,Conversion Rate (%)\n';
        totalStageMetrics.forEach(stage => {
            csv += `${stage.stageName ?? '-'},${stage.revenueMetrics.numberOfLodging ?? 0},${this.downLoadFormatAmountNumber(stage.revenueMetrics[selectedRevenue] ?? 0, "EUR")},${stage.deals.numberOfDeals ?? 0},${stage.deals.conversionRate ?? 0}%\n`;
        });
        csv += '\n';

        csv += '\nSource,Deals,Won Deals,Totals\n';
        groupedSources && Object.entries(groupedSources).forEach((sourceData) => {
            csv += `${sourceData[1]?.sourceName},${sourceData[1]?.totalDeals},${sourceData[1]?.totalWonDeals},${this.downLoadFormatAmountNumber(sourceData[1]?.totalValue ?? 0, "EUR")}\n`;
        })
        csv += '\n';

        csv += '\nCancel Reasons\n';
        csv += 'Reason, Total\n';
        yearlyReport.deals.lostDealsReasons && Object.entries(yearlyReport.deals.lostDealsReasons).forEach(([key, value]) => {
            csv += `${key}, ${value}\n`;
        }); 
        csv += '\n';

        csv += '\nRevenue Distribution\n';
        csv += 'Hotel, Total\n';
        yearlyReport.hotels.forEach((hotel) => {
            csv += `${global.hotelList?.find(({ value }) => value === hotel.hotelId)?.label ?? '-'}, ${this.downLoadFormatAmountNumber(hotel.totalValue ?? 0, "EUR")}\n`;
        })
        csv += '\n';

        csv += '\nRevenue by Type Distribution\n';
        csv += 'Type, Total\n';
        pieDataRevenue && pieDataRevenue.datasets[0].data.forEach((type, idx) => {
            csv += `${pieDataRevenue.datasets[0].sourceNames[idx]}, ${this.downLoadFormatAmountNumber(type ?? 0, "EUR")}\n`;
        })
        csv += '\n'; 


        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Sales_Report.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    handleTypeOfRevenueChange = (combo) => {
        this.setState({ preSelectedRevenue: combo.value });
    }

    calcDiff = (stageProp, budgetValue, hotelStagesTypes, isAmount) => {
        const definitiveStage = [...this.state.totalStageMetrics].find(el => el.isDef);
        
        if (definitiveStage) {
            let value = 0;

            if (hotelStagesTypes) {//por hotel
                const stage = hotelStagesTypes.find(({ stageTypeId }) => definitiveStage.stageTypeId === stageTypeId);
                
                if(stage)
                    value = stage[stageProp] - budgetValue;
            }
            else { //total
                value = definitiveStage.revenueMetrics[stageProp] - budgetValue;
            }

            if (isAmount) {
                return <span className={`${value >= 0 ? 'text-success' : 'text-danger'}`}><FormatAmountNumber value={Math.round(value * 100) / 100} hideDecimals={true} currency={"EUR"} /></span>
            }
            else return <span className={`${value >= 0 ? 'text-success' : 'text-danger'}`}>{Math.round(value * 1) / 1}</span>
        }

        return '-';
    }

    formatAmountNumber = (value, currency) => {
        return new Intl.NumberFormat(this.props.intl.locale, { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
    }

    render() {
        const { error, block, totalStageMetrics, hotelList, funnelData, selectedRevenue, preSelectedRevenue, groupedSources, pieDataRevenue, chartData, segmentChart } = this.state;
        const { icon, intl, yearlyReport, toggleTab, startDate, endDate } = this.props;
        const optionsPieCancel = {
            responsive: true,
            maintainAspectRatio: false,
            cutout: '60%',
            plugins: {
                datalabels: {
                    display: false,
                },
                legend: {
                    display: true,
                    position: 'left',
                    labels: {
                        usePointStyle: true,
                        boxWidth: 2,
                        generateLabels: (chart) => {
                            const data = chart.data;
                            const dataset = data.datasets[0];
                            return data.labels.map((label, index) => {
                                const value = dataset.data[index];
                                return {
                                    text: ` ${label} - ${value}`,
                                    fillStyle: dataset.backgroundColor[index],
                                    strokeStyle: dataset.backgroundColor[index],
                                    hidden: false,
                                    lineWidth: 10,
                                    pointStyle: 'rect',
                                };
                            });
                        },
                    },
                },
            }
        };

        const optionsPieRevenue = {
            responsive: true,
            maintainAspectRatio: false,
            cutout: '60%',
            plugins: {
                datalabels: {
                    display: false,
                },
                legend: {
                    display: true,
                    position: 'left',
                    labels: {
                        usePointStyle: true,
                        boxWidth: 2,
                        generateLabels: (chart) => {
                            const data = chart.data;
                            const dataset = data.datasets[0];
                            return data.labels.map((label, index) => {
                                const value = dataset.data[index];
                                return {
                                    text: `${label} - ${this.formatAmountNumber(value, "EUR")}`,
                                    fillStyle: dataset.backgroundColor[index],
                                    strokeStyle: dataset.backgroundColor[index],
                                    hidden: false,
                                    lineWidth: 10,
                                    pointStyle: 'rect', 
                                };
                            });
                        }
                    }
                }
            }
        };
        
        return (
            <div className="border-0 h-100 pb-2">
                <ErrorAlert error={error}/>
                <BlockUi tag="div" blocking={block}>
                    <div className='mb-4'>
                        <Row>
                            <Col>
                                <h4 className='m-0'>
                                    <i className={`${icon} mr-2`} />
                                    <FormattedMessage id="SalesProcess.ExecutiveReport" />
                                </h4>
                            </Col>
                            <Col className="pr-2">
                                <CommonHelper help={''} id={'ExecutiveReportHelp'} />
                            </Col>

                        </Row>
                    </div>
                    <div>
                        <Form onSubmit={this.handleSearch}>
                            <Row>
                                <Col className='col-3'>
                                    <div className='title-sm'>
                                        <i className="icon-icon-hotel mr-2"/>
                                        <FormattedMessage id="SalesProcess.Hotels"/>
                                    </div>
                                    <div>
                                        <SalesProcessHotelCombo
                                            isMulti={true}
                                            isClearable={true}
                                            name='hotelIds'
                                            placeholder={'Hotel'}
                                            value={hotelList}
                                            onChangeFunc={this.handleHotelListChange}
                                        />
                                    </div>
                                </Col>
                                <Col className='col-3'>
                                    <div className='title-sm'>
                                        <i className="fas fa-dollar-sign mr-2"></i>
                                        <FormattedMessage id="SalesProcess.TypeOfRevenue"/>
                                    </div>
                                    <div>
                                        <CustomSelect
                                            options={getSalesProcessTypeOfRevenue(intl)}
                                            value={getSalesProcessTypeOfRevenue(intl).find(({ value }) => value === preSelectedRevenue)}
                                            isSearchable={true}
                                            onChange={e => this.handleTypeOfRevenueChange(e)}
                                            placeholder={intl.formatMessage({ id: "SalesProcess.TypeOfRevenue" })}
                                        />
                                    </div>
                                </Col>
                                <Col className={`col-3`}>
                                    <div className='title-sm'>
                                        <i className="far fa-calendar-alt mr-2" />
                                        <FormattedMessage id="SalesProcess.Dates" />
                                    </div>
                                    <div>
                                        <CustomTwoMonthDateRangePicker
                                            startDate={startDate}
                                            endDate={endDate}
                                            required={true}
                                            handleDates={this.props.handleDates}
                                        />
                                    </div>
                                </Col>
                                <Col className='col-3 d-flex align-items-center justify-content-end'>
                                    <div className="mr-2">
                                        <ExportToPDFButton name="executiveReportYearlyPdf" fromPage='executiveReport' />
                                    </div>
                                    <Button className="btn btn-host btn-sm" onClick={this.downloadData}>
                                        <i className="fas fa-file-download"/>
                                    </Button>
                                    <Button className="btn btn-host btn-sm ml-2" type="submit">
                                        <i className='fas fa-search'/>
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        {yearlyReport ?
                            <Row id='executiveReportYearlyPdf' className='mt-4' style={{ fontSize: '0.9em' }}>
                                <Col className='col-12'>
                                    <Row>
                                        <Col className='col-3'>
                                            <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px' }} onClick={_ => toggleTab("SalesDealList", hotelList)}>
                                                <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                    <i className={`fas fa-briefcase mr-2`}/>
                                                    <FormattedMessage id='SalesProcess.TotalDeals'/>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                    <div>
                                                        {yearlyReport?.deals?.numberOfDeals??'0'}
                                                    </div>
                                                    <div>
                                                        <FormatAmountNumber
                                                            value={yearlyReport?.deals?.proposalValueTotal ?? '0'}
                                                            currency={"EUR"}
                                                            hideDecimals={true}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                        <Col className='col-3'>
                                            <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px', color: '#0665ff' }} onClick={_ => toggleTab("SalesDealList", hotelList, null, null, null, null, ["WaitingHotel", "WaitingClient"])}>
                                                <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                    <i className={`fas fa-people-arrows mr-2`}/>
                                                    <FormattedMessage id='SalesProcess.NegotiationDeals'/>
                                                </div>
                                                {(() => {
                                                    const number = (yearlyReport?.deals?.numberOfDeals??0) - ((yearlyReport?.deals?.numberOfLostDeals??0) + (yearlyReport?.deals?.numberOfWonDeals??0));

                                                    const lost = yearlyReport?.deals?.proposalValueLost??0;
                                                    const won = yearlyReport?.deals?.proposalValueWon??0;
                                                    const total = yearlyReport?.deals?.proposalValueTotal??0;
                                                    const wonAndLost = won + lost;
                                                    const value = total - wonAndLost;

                                                    return (
                                                        <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                            <div>
                                                                {number}
                                                            </div>
                                                            <div>
                                                                <FormatAmountNumber
                                                                    value={value}
                                                                    currency={"EUR"}
                                                                    hideDecimals={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                })()}
                                            </Card>
                                        </Col>
                                        <Col className='col-3'>
                                            <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px', color: '#F38375' }} onClick={_ => toggleTab("SalesDealList", hotelList, null, null, null, null, "Cancelled")}>
                                                <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                    <i className={`far fa-thumbs-down mr-2`}/>
                                                    <FormattedMessage id='SalesProcess.LostDeals'/>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                    <div>
                                                        {yearlyReport?.deals?.numberOfLostDeals??'0'}
                                                    </div>
                                                    <div>
                                                        <FormatAmountNumber
                                                            value={yearlyReport?.deals?.proposalValueLost ?? '0'}
                                                            currency={"EUR"}
                                                            hideDecimals={true}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                        <Col className='col-3'>
                                            <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px', color: '#36ad51' }} onClick={_ => toggleTab("SalesDealList", hotelList, null, null, null, null, "Finished")}>
                                                <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                    <i className={`fas fa-trophy mr-2`}/>
                                                    <FormattedMessage id='SalesProcess.WonDeals'/>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                    <div>
                                                        {yearlyReport?.deals?.numberOfWonDeals??'0'}
                                                    </div>
                                                    <div>
                                                        <FormatAmountNumber
                                                            value={yearlyReport?.deals?.proposalValueWon ?? '0'}
                                                            currency={"EUR"}
                                                            hideDecimals={true}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className='col-12 mt-3'>
                                    <Card className='shadow bg-white border-0 p-3 h-100 overflow-auto scrollableDiv' style={{ borderRadius: '5px', display: 'grid' }}>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='text-muted'>
                                                <i className="icon-icon-hotel mr-2"/>
                                                <FormattedMessage id="SalesProcess.GroupMetrics"/>
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            <div className='d-flex align-items-center justify-content-between px-3 py-1' style={{ borderRadius: '4px', fontWeight: '600' }}>
                                                <div className='w-100' style={{ minWidth: '300px' }}>
                                                    <div className='text-truncate'>
                                                        <FormattedMessage id="SalesProcess.Hotel"/>
                                                    </div>
                                                </div>
                                                {totalStageMetrics.map((stage, key) =>
                                                    <div key={key} className='w-100 text-truncate' style={{  borderLeft: '1px solid #a5a5a5', minWidth: '250px' }}>
                                                        <div className='text-truncate text-center'>
                                                            {stage.stageName??'-'}
                                                        </div>
                                                        <div className="mt-1 d-flex align-items-center justify-content-between text-muted" style={{ fontWeight: '400' }}>
                                                            <div className='text-center' style={{ width: "20%" }}>#</div>
                                                            <div className='text-center' style={{ width: "40%" }}>€</div>
                                                            <div className='text-center' style={{ width: "20%" }}>Deals</div>
                                                            <div className='text-center' style={{ width: "20%" }}>%</div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className='w-100 text-truncate' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '200px' }}>
                                                    <div className='text-truncate text-center'>
                                                        <FormattedMessage id="SalesProcess.Budget" />
                                                    </div>
                                                    <div className="mt-1 d-flex align-items-center justify-content-between text-muted" style={{ fontWeight: '400' }}>
                                                        <div className='text-center w-100'>#</div>
                                                        <div className='text-center w-100'>€</div>
                                                    </div>
                                                </div>
                                                <div className='w-100 text-truncate' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '200px' }}>
                                                    <div className='text-truncate text-center'>
                                                        <FormattedMessage id="SalesProcess.Difference" />
                                                    </div>
                                                    <div className="mt-1 d-flex align-items-center justify-content-between text-muted" style={{ fontWeight: '400' }}>
                                                        <div className='text-center w-100'>#</div>
                                                        <div className='text-center w-100'>€</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                {yearlyReport.hotels?.map((hotel, key) =>
                                                    <div style={{ borderTop: key ? '1px solid lightgrey' : '' }} key={key} className='py-2 px-3 my-1 table-row-exec-report'>
                                                        <div className='w-100 text-truncate d-flex align-items-center justify-content-between' style={{ minWidth: '300px' }}>
                                                            <div className='text-truncate d-flex align-items-center'>
                                                                <div className='mr-2'>
                                                                    <div style={{
                                                                        backgroundColor: hotel.color??'#0665ff',
                                                                        aspectRatio: '1/1',
                                                                        height: '10px'
                                                                    }}/>
                                                                </div>
                                                                <div className='text-truncate cursor-pointer hrefStyle' onClick={_ => toggleTab("SalesProcessExecutiveReportMonthly", hotel.hotelId)}>
                                                                    {global.hotelList?.find(({value}) => value === hotel.hotelId)?.label?? hotel.hotelId??'-'}
                                                                </div>
                                                            </div>
                                                            <div className='text-muted d-flex align-items-center mr-4'>
                                                                <div className='mr-4'>{hotel.numberOfDeals}</div>
                                                                <div>{`${hotel.dealShare}%`}</div>
                                                            </div>
                                                        </div>
                                                        {totalStageMetrics.map((s, key) => {
                                                            const stage = hotel.stagesTypes.find(({ stageTypeId }) => s.stageTypeId === stageTypeId);
                                                            return (
                                                                <div key={key} className='w-100 text-truncate' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '250px' }}>
                                                                    <div className='d-flex align-items-center justify-content-between'>
                                                                        <div className='text-center text-truncate' style={{ width: "20%", fontSize: "0.9em" }}>
                                                                            <span>
                                                                                {stage?.numberOfLodging??'0'}
                                                                            </span>
                                                                        </div>
                                                                        <div className='text-center text-truncate' style={{ width: "40%", fontSize: "0.9em" }}>
                                                                            <FormatAmountNumber value={stage ? stage[selectedRevenue] ? stage[selectedRevenue] : '0' : '0'} currency={"EUR"} hideDecimals={true} />
                                                                        </div>
                                                                        <div className='text-center text-truncate' style={{ width: "20%", fontSize: "0.9em" }}>
                                                                            <span className='cursor-pointer hrefStyle' onClick={_ => toggleTab("SalesDealList", hotel.hotelId, s.stageTypeId)}>
                                                                                {stage?.numberOfDeals??'0'}
                                                                            </span>
                                                                        </div>
                                                                        <div className='text-center text-truncate' style={{ width: "20%", fontSize: "0.9em" }}>
                                                                            {stage?.conversionRate??'0'}%
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        )}
                                                        <div className='w-100 text-truncate' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '200px' }}>
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <div className='text-center text-truncate w-100'>
                                                                    {hotel.totalBudget.numberOfLodging ?? '-'}
                                                                </div>
                                                                <div className='text-center text-truncate w-100'>
                                                                    <FormatAmountNumber value={hotel.totalBudget[selectedRevenue]} hideDecimals={true} currency={"EUR"}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='w-100 text-truncate' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '200px' }}>
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <div className='text-center text-truncate w-100'>
                                                                    {this.calcDiff("numberOfLodging", hotel.totalBudget.numberOfLodging, hotel.stagesTypes, false)}
                                                                </div>
                                                                <div className='text-center text-truncate w-100'>
                                                                    {this.calcDiff(selectedRevenue, hotel.totalBudget[selectedRevenue], hotel.stagesTypes, true)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                <div style={{ borderTop: '1px solid lightgrey' }} className='py-2 px-3 my-1 table-row-exec-report'>
                                                    <div className='w-100 text-truncate d-flex align-items-center justify-content-between' style={{ minWidth: '300px' }}>
                                                        <div className='text-truncate d-flex align-items-center'>
                                                            <FormattedMessage id="SalesProcess.Total"/>
                                                        </div>
                                                    </div>
                                                    {totalStageMetrics.map((stage, key) =>
                                                        <div key={key} className='w-100 text-truncate' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '250px' }}>
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <div className='text-center text-truncate' style={{ width: "20%", fontSize: "0.9em" }}>
                                                                    <span>
                                                                        {stage.revenueMetrics.numberOfLodging??0}
                                                                    </span>
                                                                </div>
                                                                <div className='text-center text-truncate' style={{ width: "40%", fontSize: "0.9em" }}>
                                                                    <FormatAmountNumber value={stage.revenueMetrics[selectedRevenue] ?? 0} currency={"EUR"} hideDecimals={true} />
                                                                </div>
                                                                <div className='text-center text-truncate' style={{ width: "20%", fontSize: "0.9em" }}>
                                                                    <div className='cursor-pointer hrefStyle' onClick={_ => toggleTab("SalesDealList", hotelList, stage.stageTypeId)}>
                                                                        {stage.deals.numberOfDeals??0}
                                                                    </div>
                                                                </div>
                                                                <div className='text-center text-truncate' style={{ width: "20%", fontSize: "0.9em" }}>
                                                                    <div>{stage.deals.conversionRate??0}%</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className='w-100 text-truncate' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '200px' }}>
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <div className='text-center text-truncate w-100'>
                                                                {yearlyReport.hotels.reduce((acc, cur) => acc + (cur.totalBudget.numberOfLodging ?? 0), 0)}
                                                            </div>
                                                            <div className='text-center text-truncate w-100'>
                                                                <FormatAmountNumber value={yearlyReport.hotels.reduce((acc, cur) => acc + (cur.totalBudget[selectedRevenue] ?? 0), 0)} hideDecimals={true} currency={"EUR"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='w-100 text-truncate' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '200px' }}>
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <div className='text-center text-truncate w-100'>
                                                                {this.calcDiff("numberOfLodging", yearlyReport.hotels.reduce((acc, cur) => acc + (cur.totalBudget.numberOfLodging ?? 0), 0), null, false)}
                                                            </div>
                                                            <div className='text-center text-truncate w-100'>
                                                                {this.calcDiff(selectedRevenue, yearlyReport.hotels.reduce((acc, cur) => acc + (cur.totalBudget[selectedRevenue] ?? 0), 0), null, true)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col className='col-4 mt-4'>
                                    <Card className='shadow bg-white border-0 p-3 h-100 overflow-auto scrollableDiv' style={{ borderRadius: '5px' }}>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='text-muted'>
                                                <i className={`fas fa-code-branch mr-2`} />
                                                <FormattedMessage id="SalesProcess.SourceData" />
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            <div className='d-flex align-items-center justify-content-between px-3 py-1' style={{ borderRadius: '4px', fontWeight: '600' }}>
                                                <div className='w-100' style={{ minWidth: '100px' }}>
                                                    <div className='text-truncate'>
                                                        <FormattedMessage id="SalesProcess.Source" />
                                                    </div>
                                                </div>
                                                <div className='w-100' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '50px' }} >
                                                    <div className='text-truncate text-center'>
                                                        <FormattedMessage id="SalesProcess.TotalDeals" />
                                                    </div>
                                                </div>
                                                <div className='w-100' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '50px' }}>
                                                    <div className='text-truncate text-center'>
                                                        <FormattedMessage id="SalesProcess.WonDeals" />
                                                    </div>
                                                </div>
                                                <div className='w-100' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '50px' }}>
                                                    <div className='text-truncate text-center'>
                                                        <FormattedMessage id="SalesProcess.TotalValue" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                {groupedSources && Object.entries(groupedSources).map(([sourceTypeId, sourceData], key) => (
                                                    <div style={{ borderTop: key ? '1px solid lightgrey' : '' }} key={key} className='py-2 px-3 my-1 table-row-exec-report'>
                                                        <div className='w-100 text-truncate' style={{ minWidth: '100px' }}>
                                                            {sourceData.sourceName}
                                                        </div>

                                                        <div className='w-100 text-truncate text-center cursor-pointer hrefStyle' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '50px' }} onClick={_ => toggleTab("SalesDealList", hotelList, null, null, null, null, null, null, sourceTypeId, startDate, endDate)}>
                                                            {sourceData.totalDeals ?? '0'}
                                                        </div>

                                                        <div className='w-100 text-truncate text-center cursor-pointer hrefStyle' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '50px' }} onClick={_ => toggleTab("SalesDealList", hotelList, null, null, null, null, "Finished", null, sourceTypeId, startDate, endDate)}>
                                                            {sourceData.totalWonDeals ?? '0'}
                                                        </div>

                                                        <div className='w-100 text-truncate text-center' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '50px' }}>
                                                            <FormatAmountNumber value={sourceData.totalValue ?? '0'} currency={"EUR"} hideDecimals={true} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col className='col-8 mt-4'>
                                    <Card className='shadow bg-white border-0 p-3 h-100' style={{ borderRadius: '5px' }}>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='text-muted'>
                                                <i className="fas fa-chart-line mr-2" />
                                                <FormattedMessage id="SalesProcess.ConversionperStage" />
                                            </div>
                                        </div>
                                        {funnelData && funnelData.length > 0 ?
                                            <div>
                                                <CustomFunnel
                                                    data={funnelData}
                                                />
                                            </div>
                                            :
                                            <div className='d-flex align-items-center justify-content-center mt-3' style={{ height: '100%' }}>
                                                <FormattedMessage id="SalesProcess.NoDealsDesc" />
                                            </div>
                                        }
                                    </Card>
                                </Col>

                                <Col className='col-4 mt-4'>
                                    <Card className='shadow bg-white border-0 p-3 h-100' style={{ borderRadius: '5px' }}>
                                        <div className='d-flex align-items-center justify-content-between mb-4'>
                                            <div className='text-muted'>
                                                <i className="fas fa-dollar-sign mr-2"></i>
                                                <FormattedMessage id="SalesProcess.CancelReasons" />
                                            </div>
                                        </div>
                                        {chartData && chartData.datasets[0].data.length > 0 ?
                                            <div>
                                                <Doughnut
                                                    data={chartData}
                                                    height={250}
                                                    options={optionsPieCancel}
                                                />
                                            </div>
                                            :
                                            <div className='d-flex align-items-center justify-content-center mt-3' style={{ height: '100%' }}>
                                                <FormattedMessage id="SalesProcess.NoCancelReasons" />
                                            </div>
                                        }
                                    </Card>
                                </Col>
                                <Col className='col-4 mt-4'>
                                    <Card className='shadow bg-white border-0 p-3 h-100' style={{ borderRadius: '5px' }}>
                                        <div className='d-flex align-items-center justify-content-between mb-4'>
                                            <div className='text-muted'>
                                                <i className="fas fa-dollar-sign mr-2"></i>
                                                <FormattedMessage id="SalesProcess.TypeDistribution" />
                                            </div>
                                        </div>
                                        {pieDataRevenue && pieDataRevenue.datasets[0].data.length > 0 ?
                                            <div>
                                                <Doughnut
                                                    data={pieDataRevenue}
                                                    height={250}
                                                    options={optionsPieRevenue}
                                                />
                                            </div>
                                            :
                                            <div className='d-flex align-items-center justify-content-center mt-3' style={{ height: '100%' }}>
                                                <FormattedMessage id="SalesProcess.NoDealsDesc" />
                                            </div>
                                        }
                                    </Card>
                                </Col>
                                <Col className='col-4 mt-4'>
                                    <Card className='shadow bg-white border-0 p-3 h-100' style={{ borderRadius: '5px' }}>
                                        <div className='d-flex align-items-center justify-content-between mb-4'>
                                            <div className='text-muted'>
                                                <i className="fas fa-dollar-sign mr-2"></i>
                                                <FormattedMessage id="SalesProcess.SegmentsDistribution" />
                                            </div>
                                        </div>

                                        {segmentChart?.length > 0 ?
                                            <Chart
                                                chartType="TreeMap"
                                                width="100%"
                                                height="250px"
                                                data={segmentChart}
                                                options={{
                                                    minColor: "#D6EDFE",
                                                    midColor: "#75AEFC",
                                                    maxColor: "#0867FA",
                                                    headerHeight: 20,
                                                    fontColor: "black",
                                                    showScale: true,
                                                    headerColor: '#D6EDFE',
                                                    fontFamily: "Poppins, sans-serif",
                                                    generateTooltip: (row, size, value) => {
                                                        return (
                                                            '<div style="background: white; padding: 5px; font-size: 0.8em" class="shadow">  Total: ' + parseFloat(size).toFixed(2) + '</div>'
                                                        )
                                                    },
                                                }}
                                            />
                                            :
                                            <div className='d-flex align-items-center justify-content-center mt-3' style={{ height: '100%' }}>
                                                <FormattedMessage id="SalesProcess.NoSegments" />
                                            </div>
                                        }
                                    </Card>
                                </Col> 
                            </Row>
                        :''}
                    </div>
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(SalesProcessExecutiveReportYearly);