import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Card, Col, Row } from 'reactstrap';
import { getAPI, postAPI } from "../../Base/API";
import { StyledCard } from "../../Base/CommonUIComponents";
import { CustomTable } from '../../Base/CustomTable';
import { TopicDetails } from './TopicDetails';

export class TopicManagement extends Component {

    constructor(props) {
        super(props);
        this.getHotels = this.getTopics.bind(this);
        this.state = {
            block: true,
            topicList: [],
            topic: null,
            modal: false
        };
    }

    componentDidMount() {
        this.getTopics();
    }

    getTopics() {       

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ topicList: data.response });
            }
            this.setState({ error: errorMessage, block: false });
        }, '/api/gms/DataQuality/v1/DataQualityTopic');
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            topic: null,
        }));
    }
    
    updateTable = (topic) => {
        const { topicList } = this.state;
        this.setState({ topicList: [], block: true });

        const index = topicList && topicList.indexOf(topicList.find(t => t.id === topic.id));

        if (index === -1) {
            topicList.push(topic);
        }
        else {
            topicList[index] = topic;
        }

        this.setState({ topicList, block: false});
    }

    render() {
        const { block, error, topicList, currentPage, resultsPerPage } = this.state;

        const columns = [
            {
                dataField: "code",
                text: this.props.intl.formatMessage({
                    id: "TopicManagement.code"
                }),
                sort: true
            },
            {
                dataField: "description",
                text: this.props.intl.formatMessage({
                    id: "TopicManagement.description"
                }),
                sort: true
            }
        ];

        const selectRow = {
            mode: "radio",
            hideSelectColumn: true,
            bgColor: "#f8f9fa",
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ topic: row, modal: true });
            }
        };

        return (
            <StyledCard block={block} error={error} icon={'fas fa-stream'} title={'TopicManagement.title'}>
                <Row className="mb-2">
                    <Col className="text-right">
                        <Button className="btn btn-sm btn-host" onClick={this.toggleModal}>
                            <i className="fas fa-plus"></i>
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col className="h-100">
                        <Card className="border-0">
                            <CustomTable
                                data={topicList ? topicList : []}
                                columns={columns}
                                page={currentPage}
                                sizePerPage={resultsPerPage}
                                totalSize={topicList && topicList.length}
                                shadow={false}
                                search={false}
                                selectRow={selectRow}
                                remote={false}
                            />
                        </Card>
                    </Col>
                </Row>

                {this.state.modal ?
                    <TopicDetails
                        modal={this.state.modal}
                        toggleModal={this.toggleModal}
                        topic={this.state.topic}
                        updateTable={this.updateTable}
                    />
                : ''}
            </StyledCard>
        );
    }
}
export default injectIntl(TopicManagement)
