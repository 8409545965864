import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function EditorText(props) {
    const defaultToolbar = {
        options: ['inline', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'history'],
        inline: { inDropdown: true },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: true },
        history: { inDropdown: true },
        fontFamily: { options: [ 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'Roboto', 'Poppins', 'Playfair Display', 'Nunito' ],}
    };
    const toolbar = props.customToolbar ? props.customToolbar : defaultToolbar;

    const getValue = () => {
        if (props?.editorState) return props?.getCurrentContent().getPlainText();
        return "";
    };

    return (
        <div className='coolTextEditor'>
            <Editor
                editorState={props.editorState}
                onEditorStateChange={props.onEditorStateChange}
                toolbar={toolbar}
                stripPastedStyles={true}
                placeholder={props.placeholder ?? <FormattedMessage id="Editor.Placeholder" />}
                readOnly={props.disabled}
                toolbarHidden={props.disabled}
            />
            {props.required ?
                <input
                    autoComplete="off"
                    style={{ opacity: 0, width: "95%", height: 0, position: 'absolute' }}
                    required
                    value={getValue()}
                />
            :''}
        </div>
    )
};
