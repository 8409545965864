import moment from 'moment-timezone';
import React, { Component } from 'react';
import { DateRangePicker } from 'react-dates';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, CustomInput, Input, Popover, PopoverBody, Row } from 'reactstrap';

class SalesDealDetailProposalBulkUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            dates: [],
            typeOptions: [
                {
                    value: 'define',
                    label: <FormattedMessage id="SalesProcess.Define" />
                },
                {
                    value: 'modify',
                    label: <FormattedMessage id="SalesProcess.Modify" />
                }
            ],
            catList: this.props.catList??[],
            type: 'define',
            value: null
        };
    }

    isOutsideRange = (day) => {
        const { fromDate, toDate } = this.props;

        if(day.isBefore(moment(fromDate), 'date'))
            return true;

        if(day.isSameOrAfter(moment(toDate), 'date'))
            return true;

        return false;
    }

    void = () => {}

    isDayBlocked = (day) => {
        const { dates } = this.state;
        const val = dates.find(date => date.isSame(day, 'date')) ? true : false;
        return val;
    }

    renderDayContents = (day) => {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer'
                }}
                onClick={e => this.onDayClick(day)}
            >
                {day.format('D')}
            </div>
        );
    }

    onDayClick = (day) => {
        const { dates, startDate } = this.state;

        if(this.isOutsideRange(day))
            return;
        
        const current = dates.findIndex((el) => el.isSame(day, 'date'));

        if(current > -1) {
            dates.splice(current, 1);
            this.setState({ dates });
        }
        else if(startDate !== null) {
            if(day.isBefore(startDate, 'date')){
                this.setState({ startDate: day });
            }
            else{
                const endDate = day.clone();
                const diff = endDate.diff(startDate, 'days');

                for (var i = 0; i <= diff; i++) {
                    const day = startDate.clone().add(i, 'days');

                    if(!dates.find((d) => d.isSame(day, 'date')))
                        dates.push(day);
                };

                this.setState({ dates, startDate: null, endDate: null });
            }
        }
        else {
            this.setState({ startDate: day });
        }
    }

    handleCheckBox = (e, key) => {
        const { catList } = this.state;
        const newCatList = [...catList];

        newCatList[key].active = !newCatList[key].active;

        this.setState({ catList: newCatList });
    }

    handleType = (type) => {
        this.setState({ type });
    }

    onChange = (e) => {
        const { value } = e.target;
        const { type } = this.state;
        const parsed = (type === 'define' ? Math.max(0, parseInt(value) || 0) : parseInt(value)) || 0;

        this.setState({ value: parsed });
    }

    submitBulk = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { dates, value, catList, type } = this.state;
        const { toggleModal, handleDetailsChangeBulk } = this.props;
        handleDetailsChangeBulk(value, catList.filter(({active}) => active), dates, type);
        toggleModal();
    }

    render() {
        const { toggleModal, modal, fromDate, toDate, intl } = this.props;
        const { dates, startDate, endDate, typeOptions, catList, focusedInput, type, value } = this.state;
        
        const isDisabled = !dates || dates.length === 0 || !catList || catList.length === 0 || catList.filter(({active}) => active).length === 0 || !value;

        return (
            <Popover toggle={toggleModal} isOpen={modal} style={{ width: '75rem' }} data-trigger="click" trigger="click" placement={"left"} target={"bulkUpdate"}>
                <PopoverBody>
                    <div style={{ position: 'absolute', zIndex: '100', left: '1rem', cursor: 'pointer', top: '0.8rem' }} onClick={toggleModal}>
                        <i className="fas fa-times"/>
                    </div>
                    <form className='px-2' onSubmit={this.submitBulk}>
                        <Row>
                            <Col id="MicroMassCalendar" className="col-8">
                                {fromDate && toDate ?
                                    <DateRangePicker
                                        startDate={startDate ? moment(startDate).locale(moment.locale()) : startDate}
                                        startDateId="startDate"
                                        initialVisibleMonth={() => moment(fromDate)}
                                        isOutsideRange={this.isOutsideRange}
                                        endDate={endDate ? moment(endDate).locale(moment.locale()) : endDate}
                                        endDateId="endDate"
                                        focusedInput={focusedInput || "startDate"}
                                        small={true}
                                        onFocusChange={focusedInput => this.setState({ focusedInput })}
                                        numberOfMonths={2}
                                        minimumNights={0}
                                        isDayBlocked={e => this.isDayBlocked(e)}
                                        renderDayContents={this.renderDayContents}
                                        onDatesChange={this.void}
                                    />
                                :''}
                            </Col>
                            <Col className={"col-4"} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingBottom: '20px' }}>
                                <div className='mt-3' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    {typeOptions.map((option, key) =>
                                        <Button
                                            key={key}
                                            className="btn btn-inventory shadow"
                                            style={{ padding: '5px 10px', fontSize: '12px', lineHeight: '1.5', opacity: '1' }}
                                            onClick={e => this.handleType(option.value)}
                                            disabled={type === option.value}
                                        >
                                            {option.label}
                                        </Button>
                                    )}
                                </div>
                                <div className="mt-2">
                                    <div className='text-muted'>
                                        <FormattedMessage id="SalesProcess.Categories"/>
                                    </div>
                                    <Row className="veryCoolScrollBar" style={{ maxHeight: '180px', overflow: 'auto' }}>
                                        {catList.map((c, key) =>
                                            <Col key={key} className="col-6 pt-2" style={{ display: 'flex' }}>
                                                <CustomInput
                                                    type='checkbox'
                                                    id={`CheckBox-${key}`}
                                                    name={`CheckBox-${key}`}
                                                    checked={c.active}
                                                    onChange={e => this.handleCheckBox(e, key)}
                                                    style={{ 'cursor': 'pointer' }}
                                                />
                                                <div>
                                                    {c.name}
                                                </div>
                                            </Col>
                                        )}
                                    </Row>
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div>
                                        <Input
                                            type="number"
                                            max='999999999'
                                            onChange={this.onChange}
                                            value={value}
                                            placeholder={intl.formatMessage({ id: "SalesProcess.Value" })}
                                        />
                                    </div>
                                    <Button disabled={isDisabled} className="btn btn-host btn-sm text-white shadow" style={{ paddingBottom: '0.35rem' }}>
                                        <FormattedMessage id="SalesProcess.Apply"/>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </PopoverBody>
            </Popover>
        );
    }
}

export default injectIntl(SalesDealDetailProposalBulkUpdate);
