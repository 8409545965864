import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Input, Modal, ModalBody, Row } from 'reactstrap';
import { getAPI, postAPI } from '../../Base/API';

class StripoCustomConfig extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            stripoCfgData: []
        };
        this.form = React.createRef();
    }

    componentDidMount() {
        this.getConfig();
    }

    getConfig = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (!(data.errors && data.errors.length > 0) && data.data && data.data.length  >0) {
                    
                    this.setState({ stripoCfgData: data.data, block: false });
                }
            } else 
                this.setState({ block: false });
        }, `/api/gms/Template/v1/stripo/config`);
    }

    save = () => {

        this.setState({ block: true });

        const body = {
       
        };
        

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (!(data.errors && data.errors.length > 0) && data.data && data.data.length > 0) {

                    this.setState({ stripoCfgData: data, block: false });
                }
            }
            this.setState({ block: false });
        }, `/api/gms/Template/v1/stripo/config`, this.state.stripoCfgData);
    }

    addArrayEl = (key) => {

        let newColor = this.state.stripoCfgData;    
        newColor[key].config = [...newColor[key].config, "#000000"];
        
        this.setState(prevState => ({

            stripoCfgData: newColor
        }));

    }

    deleteArrayEL = (key, idx) => {

        let newEl= this.state.stripoCfgData
        newEl[key].config.splice(idx,1);


        this.setState(prevState => ({
            stripoCfgData: newEl
        }));
    }
    

    deleteEL = (key) => {

        let newEl = this.state.stripoCfgData
        newEl.splice(key, 1);

        this.setState(prevState => ({

            stripoCfgData: newEl
        }));
    }

    addEL = (type) => {
       if (type === "Color") {
            this.setState(prevState => ({
                stripoCfgData: [
                    ...prevState.stripoCfgData,
                    {
                    "id": null,
                    "type": "Color",
                    "config": []
                    }
                ]
            }));
        }  else {
            this.setState(prevState => ({
                stripoCfgData: [
                    ...prevState.stripoCfgData,
                    {
                        "id": null,
                        "type": "Font",
                        "config": ["","",""]
                    }
                ]
            }));
        }


    }

    changeArrayEl = (key, idx, e) => {
        let newFont = this.state.stripoCfgData
        newFont[key].config[idx] = e.target.value;


        this.setState(prevState => ({
            stripoCfgData: newFont
        }));

    }

    render() {
        const { toggleModal, modal } = this.props;
        const {  stripoCfgData} = this.state;

        return (
            <Modal isOpen={modal} toggle={toggleModal} className="modal-md">
                <Button onClick={toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody className='p-4'>
                    <Row className="mb-2">
                        <Col>
                                <h4><FormattedMessage id="stripo.cfgParam" /></h4>
                        </Col>
                            <Col className="text-right">
                                <Button className="btn-host" onClick={_ => this.save()}>
                                    <i className="fas fa-save" />
                                </Button>
                        </Col>
                    </Row>
                    <hr />
                        <Row className='mt-1'>
                            <Col className=''>
                               <b> Color Pallete</b>
                        </Col>
                        <Col className="text-right mr-0">
                            <Button className="btn-host" onClick={_ => this.addEL("Color")}>
                                <i className="fas fa-plus" />
                                </Button>
                        </Col>
                    </Row>
                    
                    {stripoCfgData && stripoCfgData.map((el, key) =>
                        el.type === 'Color' ?
                        <Row className='mt-1'>
                            <Col>
                                <Row>
                                    {el.config && el.config?.map((e, k) =>
                                        <Col className="col-1">
                                            <span >
                                                <Input type="color" value={e} onChange={ev => this.changeArrayEl(key, k, ev)} ></Input>
                                                <i className="fas fa-trash" style={{ position: "absolute", right: 10, top: -5 }} onClick={_ => this.deleteArrayEL(key, k)} />
                                            </span>
                                            
                                        </Col>
                                    )}
                                    <Col className="col-1 text-right">
                                        <Button className="btn-host mr-2"><i className="fas fa-trash" onClick={_ => this.deleteEL(key)} /></Button>
                                            <Button className="btn-host"><i className="fas fa-plus" onClick={_ => this.addArrayEl(key)} /></Button>
                                    </Col>
                                </Row>
                            </Col>
                            </Row>
                            :''
                    )
                    }
                    
                    <Row className='mt-4'>
                        <Col className=''>
                            <b> Font</b>
                        </Col>
                        <Col className="col-1 text-right">
                            <Button className="btn-host" onClick={_ => this.addEL("Font")}><i className="fas fa-plus" /></Button>
                        </Col>
                    </Row>
                    {stripoCfgData && stripoCfgData.map((el, key) =>

                     el.type === 'Font' ? 
                        <Row className='mt-1'>
                            <Col>
                                        <Row>
                                        <Col className="col-2">
                                            <Input type="text" value={el.config[0]} onChange={ev => this.changeArrayEl(key,0 ,ev)} ></Input>
                                        </Col>
                                        <Col className="col-4">
                                            <Input type="text" value={el.config[1]} onChange={ev => this.changeArrayEl(key, 1, ev)}></Input>
                                        </Col>
                                        <Col className="col-5">
                                            <Input type="text" value={el.config[2]} onChange={ev => this.changeArrayEl(key, 2, ev)}></Input>
                                    </Col>
                                    <Col className="col-1">
                                            <Button className="btn-host mr-2"><i className="fas fa-trash" onClick={_ => this.deleteEL(key)} /></Button>
                                    </Col>
                                        </Row>
                                         
                            </Col>
                        </Row>
                         : ''
                    )
                    }
                </ModalBody>
            </Modal>
        )
    }
}

export default injectIntl(StripoCustomConfig);