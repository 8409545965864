import React, { Component } from 'react'

export default class GenericTextArea extends Component {
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.value !== this.props.value){
            this.updateHeight(nextProps.value);
        }
    }

    componentDidMount() {
        this.updateHeight(this.props.value);
    }

    //function to update  textarea height
    updateHeight = (value) => {
        const textarea = document.getElementById(this.props.name);
        
        if(!textarea) return;

        if(!value){
            textarea.style.height = "50px";
            return;
        }
        textarea.value = value;
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";
    }

    //update textarea height to allways show the content
    onChange = (e) => {
        this.props.onChange(e);
        this.updateHeight(e?.target?.value);
    }

    render() {
        return (
            <div className='px-1 py-2' style={{ boxShadow: this.props.noShadow ? '' : '0px 3px 15px #6f73883b', borderRadius: '5px', background: this.props.backgroundColor??'transparent' }}>
                <textarea
                    {...this.props}
                    onChange={this.onChange}
                    id={this.props.name}
                />
            </div>
        )
    }
}