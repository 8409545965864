import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { HandleUIError } from './EventGA';
import { createBrowserHistory } from "history";

export default class ErrorBoundary extends  Component {
    constructor(props) {
      super(props);
        this.state = { hasError: this.props.hasError || false };
    }
  
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        HandleUIError(error, errorInfo?.componentStack);
    }
  
    render() {
        const history = createBrowserHistory();
        if (this.state.hasError || this.props.hasError) {
            return(
                <div className='overflow-hidden' style={{ height: '100vh' }}>
                    <div className='d-flex align-items-center justify-content-center flex-column h-100'>
                        <div className='my-4'>
                            <i style={{ fontSize: '15em', color: '#0667ff' }} className="far fa-dizzy"/>
                        </div>
                        <h1>
                            OOOPS...
                        </h1>
                        <h6>
                            <FormattedMessage id="generic.SomethingWentWrong"/>
                        </h6>
                        <div className='w-100 d-flex align-items-center justify-content-center mt-2'>
                            <div className='mr-2'>
                                <Link to={process.env.REACT_APP_SUPPORT_URL} target="_blank">
                                    <Button className="btn-sm btn-host p-2 px-3" style={{ backgroundColor: '#0667ff03', color: '#165C7D' }}>
                                        <i className="mr-1 fas fa-headset"></i>
                                        <FormattedMessage id="generic.ContactSupport"/>
                                    </Button>
                                </Link>
                            </div>
                            <div className='ml-2'>
                                <Button className="btn-sm btn-host p-2 px-3" onClick={_ => {history.push('/');window.location.reload();}}>
                                    <FormattedMessage id="generic.home"/>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
  
        return this.props.children;
    }
}