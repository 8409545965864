import React, { Component } from 'react';
import { Card, Col, Row, Button, } from 'reactstrap';
import moment from 'moment';
import { FormattedNumber } from 'react-intl';

export class CashVoucherCard extends Component {

    render() {
        const { el, icon } = this.props;
        const isEmitVoucherDisabled = !el.active || !(el.sold < el.maxQuantity) || !el.channels.some(c => c.channelConfigId === 2) || moment().diff(moment(el.saleValidTo), 'days') > 0;

        return (
            <Card className="shadow h-100 py-3" body>
                <Row className="h-100">
                    <Col className="d-flex flex-column justify-content-between">
                        <Row>
                            <Col className="text-ellipsis mt-1">
                                <i className={`${icon} mr-2`} /><b title={el.code }> {el.code}</b>
                            </Col>
                            <Col className="col-4 pl-0">
                                <div className="text-right" style={{ color: '#6F7388', fontSize: '0.7em' }} >Price</div>
                                <h5 className="m-0 float-right">
                                    <b>
                                        <FormattedNumber
                                            value={el.cardAmount}
                                            maximumFractionDigits={el.cardAmount && el.cardAmount % 1 == 0 ? 0 : 2}
                                            style="currency"
                                        />
                                    </b>
                                </h5>
                            </Col>
                        </Row>

                        <div className="my-4" style={{ fontSize: '12px' }} >{el.description}</div>

                        <Row className="align-items-center pt-1">
                            <Col>{el.sold} / {el.maxQuantity}</Col>
                            <Col>
                                <Button className="btn-host btn-sm float-right" disabled={isEmitVoucherDisabled} onClick={this.props.emitVoucher}>
                                    <i className="fas fa-shopping-basket" />
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        );
    }
}


export class RebateVoucherCard extends Component {

    render() {
        const { el, icon } = this.props;
        const isEmitVoucherDisabled = !el.active || !(el.sold < el.maxQuantity) || !el.channels.some(c => c.channelConfigId === 2) || moment().diff(moment(el.saleValidTo), 'days') > 0;

        return (
            <Card className="shadow h-100 py-3" body>
                <Row className="h-100">
                    <Col className="d-flex flex-column justify-content-between">
                        <div>
                            <Row>
                                <Col className="text-ellipsis mt-1">
                                    <i className={`${icon} mr-2`} /> <b title={el.code}>{el.code}</b>
                                </Col>
                                <Col className="col-4 pl-0">
                                    <div className="text-right" style={{ color: '#6F7388', fontSize: '0.8em'}} >Price</div>
                                    <h5 className=" float-right">
                                        <b>
                                            <FormattedNumber
                                                value={el.cardAmount}
                                                maximumFractionDigits={el.cardAmount && el.cardAmount % 1 == 0 ? 0 : 2}
                                                style="currency"
                                            />
                                        </b>
                                    </h5>
                                </Col>
                            </Row>

                            <div className="my-3" style={{ fontSize: '12px' }}>
                                {el.voucherRebate && el.voucherRebate.length > 0 ?
                                    el.voucherRebate.map((rebate, key) =>
                                        <div key={key}>
                                            - {rebate.code}
                                        </div>
                                    )
                                    :
                                    <div className="my-4" style={{ fontSize: '12px' }} >{el.description}</div>
                                }
                            </div>
                        </div>

                        <Row className="align-items-center">
                            <Col>{el.sold} / {el.maxQuantity}</Col>
                            <Col>
                                <Button className="btn-host btn-sm float-right" disabled={isEmitVoucherDisabled} onClick={this.props.emitVoucher}>
                                    <i className="fas fa-shopping-basket" />
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        );
    }
}