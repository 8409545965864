import React, { Component } from 'react'
import { StyledCard, KebabMenu } from '../../Base/CommonUIComponents'
import { Col, Row, Card, CardBody, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { deleteAPI, postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import moment from 'moment';
import ConfirmActionModal from '../../Base/ConfirmActionModal';
import TwilioCreateTemplate from './TwilioCreateTemplate';
import TwilioPreviewModal from './TwilioPreviewModal';

class TwilioTemplates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            deleteModal: false,
            createModal: false,
            previewModal: false
        };
    }

    deleteTemplates = (sid) => {
        this.setState({ block: true },
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                }

                handleNotification(data, <FormattedMessage id="TwilioMessaging.TemplateDeleted" />, <FormattedMessage id="generic.success" />);
                this.setState({ block: false, deleteModal: false }, this.props.getTemplates);
            }, `/api/gms/Twilio/v1/deleteTemplate/${sid}`))
    }

    createTemplates = (e, name, lang, type, body) => {
        e.preventDefault();
        this.setState({ block: true }, 
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }

                    if (data.response) {
                        handleNotification(data, <FormattedMessage id="TwilioMessaging.TemplateCreated" />, <FormattedMessage id="generic.success" />);
                        this.setState({ createModal: false, block: false }, this.props.getTemplates);
                        return;
                    }

                    this.setState({ block: false });
                }
            }, `/api/gms/Twilio/v1/postCreateTemplate?friendlyName=${name}&language=${lang}&type=${type}&body=${body}`))
    }

    toggleDeleteModal = (sid) => {
        this.setState({ deleteModal: !this.state.deleteModal, selectedTemplate: sid });
    }

    togglePreviewModal = (sid) => {
        this.setState({ previewModal: !this.state.previewModal, selectedTemplate: sid });
    }

    toggleCreateModal = () => {
        this.setState({ createModal: !this.state.createModal });
    }

    render() {
        const { page, templates } = this.props;
        const { block, error, selectedTemplate, deleteModal, createModal, previewModal } = this.state;

        return (
            <>
                <StyledCard block={block} error={error}>
                    <Row className="mb-4 py-1">
                        <Col>
                            <h5> <i className={page?.icon} /> {page?.name} </h5>
                        </Col>
                        <Col className='col-2 text-right'>
                            <Button onClick={this.toggleCreateModal} className="btn btn-sm btn-host">
                                <i className="fas fa-plus"></i>
                            </Button>
                        </Col>
                    </Row>
                    <div className='mt-2'>
                        {templates && templates.contents && templates.contents.length > 0 ?
                            <div>
                                {templates.contents.map((template, key) =>
                                    <Card key={key} className="border-0 shadow mt-2">
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="TwilioMessaging.UserName" />
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <b>{template.friendly_name}</b>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="TwilioMessaging.SID" />
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            {template.sid}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className="col-1">
                                                    <div>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="TwilioMessaging.Language" />
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            {template.language}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="TwilioMessaging.DateCreation" />
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            {moment(template.date_created).format('DD MMMM YYYY, HH:mm a')}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className='col-2 d-flex align-items-center justify-content-end'>
                                                    <KebabMenu
                                                        deleteFunction={_ => this.toggleDeleteModal(template)}
                                                        previewFunction={_ => this.togglePreviewModal(template)}
                                                    />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                )}
                            </div>
                            : ''
                        }
                    </div>
                    {deleteModal ?
                        <ConfirmActionModal
                            modal={deleteModal}
                            toggleModal={_ => this.toggleDeleteModal()}
                            actionFunction={() => this.deleteTemplates(selectedTemplate?.sid)}
                            text={<FormattedMessage id="TwilioMessaging.ConfirmDeleteTemplate" />}
                            block={block}
                        />
                        : ''}
                    {createModal ?
                        <TwilioCreateTemplate
                            modal={createModal}
                            toggleModal={this.toggleCreateModal}
                            createTemplate={this.createTemplates}
                            block={block}
                        />
                        : ''}

                    {previewModal ?
                        <TwilioPreviewModal
                            modal={previewModal}
                            toggleModal={this.togglePreviewModal}
                            block={block}
                            template={selectedTemplate}
                        />
                        : ''}
                </StyledCard>
            </>
        )
    }
}
export default injectIntl(TwilioTemplates);

