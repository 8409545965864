import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Col, Row, BreadcrumbItem, Breadcrumb } from 'reactstrap';
import { getFrequency, getSenderChannel, getCustomMarketingType, getConvertedDateToHotelTimezone, getLicenseModules } from '../../Base/ReferenceDataFunctions';
import { handleNotification } from "../../Base/Notification";
import { BlankCard } from '../../Base/CommonUIComponents';
import { postAPI, getAPI } from "../../Base/API";
import CustomStep1 from './CustomStep1';
import CustomStep2 from './CustomStep2';
import CustomStep3Filters from './CustomStep3Filters';
import CustomStep3Campaigns from './CustomStep3Campaigns';
import { Link } from "react-router-dom";
import moment from 'moment';

class CustomCampaignDetails extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            hasEgoi: false,
            hasSendGrid: false,
            hasTwillio: false,
            campaign: {
                consentType: "Mailings",
                templates: []
            },
            steps: [
                { step: 1, active: true },
                { step: 2, active: false },
                { step: 3, active: false }
            ],
            filters: {
                nationalities: [],
                contactType: null,
                startBirthDate: null,
                endBirthDate: null,
                tags: [],
                excludedTags: [],
                loyaltyCards: [],
                stayFrom: null,
                stayTo: null,
                profileType: [],
                hotelId: null,
                hasOtaEmail: null,
                hasLoyaltyCard: null,
                hasVipCode: null
            },
            profiles: null,
            alreadySentAndExists: false,
            collectMetricsForGoogleAnalytics: false,
            invalidCampaignName: false,
            newFiltersAfterTarget: false
        };
    }

    componentDidMount() {
        window.setTimeout(
            () => {
                this.setState({
                    hasEgoi: global.modules && global.modules.some(m => m === 'Egoi'),
                    hasSendGrid: global.modules && global.modules.some(m => m === 'SendGrid'),
                    hasTwillio: global.modules && global.modules.some(m => m === 'TwilioMessaging')
                });
            },
            global.modules ? 0 : 2500
        );

        if (this.props.match.params.id) {
            this.getCampaign();
        }
    }

    getCampaign = () => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.response && data.response.length > 0) {
                    const customCampaign = Object.assign(this.state.filters, data.response[0].customCampaign);

                    const campaign = data.response[0];
                    campaign.scheduleDay = getConvertedDateToHotelTimezone(campaign.scheduleDay);

                    const collectMetricsForGoogleAnalytics = campaign.campaignName !== null && campaign.campaignName !== undefined;
                    const alreadySentAndExists = moment().isAfter(campaign.scheduleDay) && collectMetricsForGoogleAnalytics;
                    if (campaign.templates && campaign.templates.length > 0) {
                        campaign.templates.forEach(tmp => {
                            if (!tmp.countries) {
                                tmp.countries = [];
                            }
                        })
                    }

                    this.setState({
                        campaign: campaign,
                        filters: customCampaign,
                        error: errorMessage, block: false, alreadySentAndExists, collectMetricsForGoogleAnalytics
                    });

                    return;
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/marketingsend/${this.props.match.params.id}`);
    }

    saveCampaign = (e) => {
        e.preventDefault();

        var campaign = { ...this.state.campaign };

        if (campaign.marketingType !== 'CustomLinked') {
            const consentTypeForm = document.getElementById("consentTypeForm");
            consentTypeForm.setCustomValidity("");

            if (!this.form.current.reportValidity()) {
                return;
            }
        }

        campaign.customCampaign = { ...this.state.filters };

        if (campaign.scheduleDay) {
            const minutes = moment(campaign.scheduleDay).format("mm");
            if (minutes !== "00" && minutes !== "30") {
                const data = {
                    response: null,
                    errors: [
                        {
                            code: <FormattedMessage id="generic.SomethingWentWrong" />,
                            message: <FormattedMessage id="CampaignDetails.InvalidMinutes" />
                        }
                    ]
                }
                handleNotification(data);
                return;
            }

            const timezone = global.hotelTimezone ? moment.tz(global.hotelTimezone).format('Z') : '+00:00';

            campaign.scheduleDay = campaign.scheduleDay ? moment(campaign.scheduleDay).format(`YYYY-MM-DD HH:mm:ss${timezone}`) : '';
        }

        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="CampaignDetails.CampaignSaved" />, <FormattedMessage id="generic.success" />);
                    this.props.history.push({ pathname: `/CustomCampaignDetails/${data.response[0].id}` });

                    const customCampaign = Object.assign(this.state.filters, data.response[0].customCampaign);
                    if (!customCampaign.tags) {
                        customCampaign.tags = [];
                    }
                    if (!customCampaign.excludedTags) {
                        customCampaign.excludedTags = [];
                    }
                    if (!customCampaign.loyaltyCards) {
                        customCampaign.loyaltyCards = [];
                    }

                    this.setState({ campaign: data.response[0], filters: customCampaign, error: errorMessage, block: false });
                }
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/marketingsend`, JSON.stringify({ request: campaign }));
    }

    nextStep = (val) => {
        var stateCopy = Object.assign({}, this.state);
        stateCopy.steps = stateCopy.steps.slice();
        for (var i = 0; i < stateCopy.steps.length; i++) {
            if (stateCopy.steps[i].step === val) {
                stateCopy.steps[i] = Object.assign({}, stateCopy.steps[i]);
                stateCopy.steps[i].active = true;
            }
            else {
                stateCopy.steps[i] = Object.assign({}, stateCopy.steps[i]);
                stateCopy.steps[i].active = false;
            }
        }
        this.setState(stateCopy);
    }

    movePos = (val) => {
        this.nextStep(this.state.steps.find(el => el.active === true).step + val);
    }

    moveToPos = (val) => {
        this.nextStep(val);
    }


    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            campaign: {
                ...prevState.campaign,
                [name]: value
            }
        }))
    }

    handleSwitch = (name, combo) => {
        this.setState(prevState => ({
            [name]: combo && combo.value,
            invalidCampaignName: false,
            campaign: {
                ...prevState.campaign,
                campaignName: null
            }
        }));
    }


    handleLoyaltyCard = (e) => {
        const { filters } = this.state;
        const { value } = e.target;

        filters.hasLoyaltyCard = value;
        filters.loyaltyCards = [];

        this.setState({ filters });
    }

    handleVipCodes = (e) => {
        const { filters } = this.state;
        const { value } = e.target;

        filters.hasVipCodes = value;
        filters.vipCodes = [];

        this.setState({ filters });
    }

    handleSelect = (name, combo) => {
        this.setState(prevState => ({
            campaign: {
                ...prevState.campaign,
                [name]: combo ? combo.value : null
            }
        }));
    }

    handleSurveySelect = (name, combo) => {
        this.setState(prevState => ({
            campaign: {
                ...prevState.campaign,
                [name]: combo ? combo.value : null,
                surveyId: null,
                propertyId: null
            }
        }));
    }

    //Clear campaign data after change campaign type
    handleCampaignType = (marketingType) => {
        if (marketingType !== this.state.campaign.marketingType) {
            this.setState(prevState => ({
                campaign: {
                    id: prevState.campaign.id,
                    name: prevState.campaign.name,
                    active: prevState.campaign.active,
                    campaignName: prevState.campaign.campaignName,
                    templates: [],
                    consentType: "Mailings",
                    marketingType: marketingType
                },
                filters: {
                    nationalities: [],
                    contactType: null,
                    startBirthDate: null,
                    endBirthDate: null,
                    tags: [],
                    tagParametersFilterType: null,
                    excludedTags: [],
                    loyaltyCards: [],
                    stayFrom: null,
                    stayTo: null,
                    profileType: [],
                    hotelId: null,
                    linkMarketingSendId: null,
                    linkCampaignCriteria: null,
                    linkCampaignUrls: null,
                    formId: null,
                    listId: null,
                    hasOtaEmail: null,
                    hasVipCodes: null,
                    vipCodes: []
                },
            }));
        }
    }

    isStep1Disabled = () => {
        let isDisabled = true;
        const { campaign, filters, invalidCampaignName } = this.state;

        if (/*this.state.steps[0].active === true &&*/ campaign.name && campaign.marketingType && campaign.senderChannel && campaign.senderId && !invalidCampaignName) {
            isDisabled = false;
        }

        return isDisabled;
    }

    isStep2Disabled = () => {
        let isDisabled = true;
        const { campaign, filters, invalidCampaignName } = this.state;
        
        if (campaign.scheduleDay && campaign.templates && campaign.templates.length > 0) {
            if (campaign.marketingType === 'CustomInquiry') {
                if ((campaign.surveyProvider === "ReviewPro" && campaign.surveyId && campaign.propertyId) || campaign.surveyId) {
                    isDisabled = false;
                }
            }
            else if (campaign.marketingType === 'CustomAB' && campaign.sampleSize) {
                isDisabled = false;
            }
            else if (campaign.templates.some(t => t.isDefault === true)) {
                isDisabled = false;
            }
        }
        return isDisabled;
    }

    isStep3Disabled = () => {
        let isDisabled = true;
        const { campaign, filters, invalidCampaignName } = this.state;

        //if (this.state.steps[2].active === true) {
            if (campaign.marketingType === 'CustomLinked') {
                if (filters.linkMarketingSendId && filters.linkCampaignCriteria) {
                    if (filters.linkCampaignCriteria === 'Clicked' || filters.linkCampaignCriteria === 'HasForm' || filters.linkCampaignCriteria === 'HasNoForm') {
                        if (filters.linkCampaignCriteria === 'Clicked' && filters.linkCampaignUrls && filters.linkCampaignUrls.length > 0) {
                            isDisabled = false;
                        }
                        else if (filters.formId) {
                            isDisabled = false;
                        }
                    }
                    else {
                        isDisabled = false;
                    }
                }
                else {
                    isDisabled = false;
                }
            }
            else {
                if ((filters.latitude || filters.longitude || filters.distanceComparisonType || filters.distance) && (!filters.latitude || !filters.longitude || !filters.distanceComparisonType || !filters.distance)) {
                    isDisabled = true;
                }
                else isDisabled = false;
            }
        //}
        return isDisabled;
    }

    isDisabled = () => {
        
        let isDisabled = true;

        if (this.state.steps[0].active === true) {
            isDisabled = this.isStep1Disabled();
        }
        if (this.state.steps[1].active === true) {
            isDisabled = this.isStep2Disabled();
        }
        if (this.state.steps[2].active === true) {
            isDisabled = this.isStep3Disabled();
        }

        return isDisabled
    }

    //#region Handle filters
    handleNationalities = (combo) => {
        const { selectedCampaignTarget } = this.state;
        let { nationalities } = this.state.filters;
        nationalities = combo !== null ? combo.map(opt => opt.value) : [];
        this.setState({ filters: { ...this.state.filters, nationalities }, newFiltersAfterTarget: selectedCampaignTarget !== null && selectedCampaignTarget !== undefined });
    }

    handleSelectFilters = (name, combo) => {
        const { selectedCampaignTarget } = this.state;

        this.setState({
            filters: {
                ...this.state.filters,
                [name]: combo ? combo.value : null
            },
            newFiltersAfterTarget: selectedCampaignTarget !== null && selectedCampaignTarget !== undefined
        });
    };

    handleChangeMultiSelect = (name, combo) => {
        const { selectedCampaignTarget } = this.state;

        var targetValue = null;

        if (Array.isArray(combo)) {
            targetValue = combo.map(el => el.value);
        }

        this.setState({
            filters: {
                ...this.state.filters,
                [name]: targetValue
            },
            newFiltersAfterTarget: selectedCampaignTarget !== null && selectedCampaignTarget !== undefined
        });
    };

    handleChangeLatLong = (latitude, longitude) => {
        const { selectedCampaignTarget } = this.state;

        this.setState({
            filters: {
                ...this.state.filters,
                latitude, longitude
            },
            newFiltersAfterTarget: selectedCampaignTarget !== null && selectedCampaignTarget !== undefined
        });
    };

    handleChangeFilters = (evt) => {
        const { selectedCampaignTarget } = this.state;

        const { name, value } = evt.target;

        this.setState({
            filters: {
                ...this.state.filters,
                [name]: value
            },
            newFiltersAfterTarget: selectedCampaignTarget !== null && selectedCampaignTarget !== undefined
        });
    }

    handleChangeFiltersArray = (name, combo) => {
        const { filters, selectedCampaignTarget } = this.state;
        filters[name] = combo ? combo.map(cmb => cmb.value) : [];
        this.setState({ filters, newFiltersAfterTarget: selectedCampaignTarget !== null && selectedCampaignTarget !== undefined });
    }

    handleTag = (tag) => {
        const { selectedCampaignTarget } = this.state;
        let { tags } = this.state.filters;
        const index = tags && tags.indexOf(tags.find(el => el.id === tag.id));

        if (index === -1) {
            tags.push({ id: tag.id, min: 1 });
        }
        else {
            tags = tags.filter(el => el.id !== tag.id);
        }
        this.setState({ filters: { ...this.state.filters, tags }, newFiltersAfterTarget: selectedCampaignTarget !== null && selectedCampaignTarget !== undefined });
    }


    handleTagType = (evt) => {
        const { selectedCampaignTarget, filters } = this.state;
        filters['tagParametersFilterType'] = evt.target.name;
        this.setState({ filters, newFiltersAfterTarget: selectedCampaignTarget !== null && selectedCampaignTarget !== undefined });
    }

    handleExcludedTag = (tag) => {
        const { selectedCampaignTarget } = this.state;
        let { excludedTags } = this.state.filters;
        const index = excludedTags && excludedTags.indexOf(excludedTags.find(el => el.id === tag.id));

        if (index === -1) {
            excludedTags.push({ id: tag.id });
        }
        else {
            excludedTags = excludedTags.filter(el => el.id !== tag.id);
        }

        this.setState({ filters: { ...this.state.filters, excludedTags }, newFiltersAfterTarget: selectedCampaignTarget !== null && selectedCampaignTarget !== undefined });
    }

    handleLinkMarketing = (campaignId) => {
        const { selectedCampaignTarget } = this.state;

        this.setState({
            filters: {
                ...this.state.filters,
                linkMarketingSendId: campaignId,
                linkCampaignCriteria: null,
                linkCampaignUrls: null,
                formId: null,
                listId: null
            },
            bookingsFromDate: null,
            bookingsToDate: null,
            staysFromDate: null,
            staysToDate: null,
            newFiltersAfterTarget: selectedCampaignTarget !== null && selectedCampaignTarget !== undefined
        });
    }

    cleanFormData = () => {
        const { selectedCampaignTarget } = this.state;

        this.setState({
            filters: {
                ...this.state.filters,
                formId: null,
                listId: null
            },
            newFiltersAfterTarget: selectedCampaignTarget !== null && selectedCampaignTarget !== undefined
        });
    }

    changeTagMin = (e, tag) => {
        if (e.target && parseInt(e.target.value) > -1) {
            const { selectedCampaignTarget } = this.state;

            let { tags } = this.state.filters;
            const index = tags && tags.findIndex(el => el.id === tag.id);

            if (index > -1) {
                tags[index].min = e.target.value;
            }

            this.setState({ filters: { ...this.state.filters, tags }, newFiltersAfterTarget: selectedCampaignTarget !== null && selectedCampaignTarget !== undefined });
        }
    }

    selectType = (fieldValue, field) => {
        const { filters, selectedCampaignTarget } = this.state;

        const filter = filters[field].find(ftr => ftr === fieldValue.value);
        if (filter) {
            const idx = filters[field].indexOf(filter);
            filters[field].splice(idx, 1);
        }
        else {
            filters[field].push(fieldValue.value);
        }
        this.setState({ filters, newFiltersAfterTarget: selectedCampaignTarget !== null && selectedCampaignTarget !== undefined });
    }


    handleLinkCampCriteria = (combo) => {
        const { selectedCampaignTarget } = this.state;

        this.setState({
            filters: {
                ...this.state.filters,
                linkCampaignCriteria: combo ? combo.value : null,
                formId: null,
                listId: null
            },
            newFiltersAfterTarget: selectedCampaignTarget !== null && selectedCampaignTarget !== undefined
        });        
    }


    //Usado no filtro de datas das reservas e estadias no CustomStep3Campaigns
    handleDates = (startDate, endDate, startFieldName, endFieldName) => {
        const { selectedCampaignTarget } = this.state;

        this.setState({
            filters: {
                ...this.state.filters,
                [startFieldName]: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
                [endFieldName]: endDate ? moment(endDate).format("YYYY-MM-DD") : null
            },
            newFiltersAfterTarget: selectedCampaignTarget !== null && selectedCampaignTarget !== undefined
        });
    }

    handleDateFilter = (name, date) => {
        const { selectedCampaignTarget } = this.state;

        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                [name]: date ? moment(date).format("YYYY-MM-DD") : null
            },
            newFiltersAfterTarget: selectedCampaignTarget !== null && selectedCampaignTarget !== undefined
        }));
    }

    clearDatesFilter = (bookingFromName, bookingToName, stayFromName, stayToName) => {
        const { selectedCampaignTarget } = this.state;

        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                [bookingFromName]: null,
                [bookingToName]: null,
                [stayFromName]: null,
                [stayToName]: null
            },
            newFiltersAfterTarget: selectedCampaignTarget !== null && selectedCampaignTarget !== undefined
        }));
    }

    //#endregion

    setProfiles = (profiles) => {
        this.setState({ profiles: profiles });
    }
    
    handleStatusSelect = (name, combo) => {
        this.setState(prevState => ({
            campaign: {
                ...prevState.campaign,
                [name]: combo && combo.value
            }
        }))
    }

    handleText = (evt) => {
        if (evt && evt.target) {
            const { campaign } = this.state;
            const { name, value } = evt.target;
            campaign[name] = value;
            this.setState({ campaign });
        }
    }

    handleDate = (name, date) => {
        this.setState(prevState => ({
            campaign: {
                ...prevState.campaign,
                [name]: date ? moment(date).format("YYYY-MM-DD") : null
            }
        }));
    }

    isDisabledSelectHotelIds = () => {
        const { filters } = this.state;

        return filters.stayFrom == null && filters.stayTo == null && filters.bookingFromDate == null && filters.bookingToDate == null && filters.noStayFrom == null && filters.noStayTo == null && filters.noBookingFromDate == null && filters.noBookingToDate == null;
    }

    handleOtaEmail = (hasOtaEmail) => {
        const { filters } = this.state;
        filters.hasOtaEmail = hasOtaEmail;
        this.setState({ filters });
    }
    
    applyCampaignTarget = (params, campaignTargetDetails) => {
        const parsed = JSON.parse(params);
        const { filters } = this.state;

        Object.keys(parsed).forEach(field => {
            if(!parsed[field] && Array.isArray(filters[field]) && filters[field].length === 0)
                filters[field] = [];
            else
                filters[field] = parsed[field] === undefined ? null : parsed[field];
        });

        this.setState({
            filters,
            campaign: {
                ...this.state.campaign,
                bookingsFromDate: parsed.bookingsFromDate,
                bookingsToDate: parsed.bookingsToDate,
                staysFromDate: parsed.staysFromDate,
                staysToDate: parsed.staysToDate,
                consentType: parsed.consentType
            },
            selectedCampaignTarget: campaignTargetDetails,
            newFiltersAfterTarget: false
        });
    }

    validateCampaignName = (evt) => {
        evt.preventDefault();
        const { campaign } = this.state;
        const regex = /^[A-Za-z\d]{1,}$/i;

        if (campaign.campaignName && (!regex.test(campaign.campaignName))) {
            this.setState({ invalidCampaignName: true });
        }
        else {
            this.setState({ invalidCampaignName: false });
        }
    }

    render() {
        const { block, error, campaign, steps, profiles, alreadySentAndExists, collectMetricsForGoogleAnalytics, invalidCampaignName, selectedCampaignTarget, newFiltersAfterTarget } = this.state;

        const marketingType = getCustomMarketingType();
        const senderChannel = getSenderChannel();
        const frequency = getFrequency();

        return (
            <BlankCard block={block} error={error}>
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/CustomCampaign"><FormattedMessage id="NavMenu.customsCampaign"/></Link></BreadcrumbItem>
                        <BreadcrumbItem active><FormattedMessage id="CampaignDetails.CampaignDetails"/></BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Row className="mb-2">
                    <Col className="col-1 text-left d-flex align-items-center">
                        {steps[0].active === false ?
                            <Button className="btn- btn-host btn-small mr-4" style={{ 'flex': '1' }} onClick={this.movePos.bind(this, -1)}>
                                <i className="fas fa-angle-left" />
                            </Button>
                        : ''}
                    </Col>
                    <Col className="col-2"/>
                    <Col className="py-1 mb-4">
                        <ul className="steps">
                            <li className={'step ' + (steps[0].active === true ? ' step-active' : '')} style={{ cursor: 'pointer' }} onClick={this.moveToPos.bind(this, 1)}  >
                                <div className="step-content">
                                    <span className="step-text small">
                                        <FormattedMessage id="CampaignDetails.Step1" />
                                    </span>
                                    <span className="step-circle" />
                                </div>
                            </li>
                            <li className={'step ' + (steps[1].active === true ? ' step-active' : '')} style={{ cursor: (this.isStep1Disabled()) ? 'default' : 'pointer' }} onClick={(this.isStep1Disabled()) ? null : this.moveToPos.bind(this, 2) } >
                                <div className="step-content">
                                    <span className="step-text small">
                                        <FormattedMessage id="CampaignDetails.Step2" />
                                    </span>
                                    <span className="step-circle"/>
                                </div>
                            </li>
                            <li className={'step ' + (steps[2].active === true ? ' step-active' : '')} style={{ cursor: (this.isStep1Disabled() || this.isStep2Disabled()) ? 'default' : 'pointer' }} onClick={(this.isStep1Disabled() || this.isStep2Disabled()) ? null : this.moveToPos.bind(this, 3)} >
                                <div className="step-content">
                                    <span className="step-text small">
                                        <FormattedMessage id="CampaignDetails.Step3" />
                                    </span>
                                    <span className="step-circle"/>
                                </div>
                            </li>
                        </ul>
                    </Col>
                    <Col className="col-2 d-flex align-items-center small">
                        <div>
                            {profiles !== null ?
                                <div>
                                    <i className="icon-icon-search mr-1" />
                                    <b> <FormattedMessage id="CustomCampaignDetails.NrProfiles" />: </b> {profiles}
                                </div>
                                : ''}
                            {selectedCampaignTarget ?
                                <div>
                                    <div>
                                        <i className="fas fa-hat-wizard mr-1" /> <b>{selectedCampaignTarget.name}: </b> {selectedCampaignTarget.targetLastRunDetails?.totalCount}
                                    </div>
                                    {
                                        newFiltersAfterTarget ?
                                            <div className="text-warning">
                                                <i className="fas fa-exclamation-triangle mr-1" />
                                                <b>
                                                    <FormattedMessage id="CustomCampaignDetails.NewFiltersChangeTargetCount" />
                                                </b>
                                            </div>
                                            :
                                            <div />
                                    }
                                </div>
                            : ''}
                        </div>
                    </Col>
                    <Col className="col-1 text-right d-flex align-items-center">
                        {steps[2].active === true ?
                            <Button className="btn- btn-host ml-4 btn-small" style={{ 'flex': '1' }} onClick={(e) => this.saveCampaign(e)} disabled={this.isDisabled()}>
                                <i className="fas fa-save" />
                            </Button>
                            :
                            <Button className="btn- btn-host ml-4 btn-small" style={{ 'flex': '1' }} onClick={this.movePos.bind(this, 1)} disabled={this.isDisabled()}>
                                <i className="fas fa-angle-right" />
                            </Button>
                        }
                    </Col>
                </Row>

                {steps[0].active === true ?
                    <CustomStep1
                        marketingType={marketingType}
                        senderChannel={senderChannel}
                        handleChange={this.handleChange}
                        campaign={campaign}
                        handleStatusSelect={this.handleStatusSelect}
                        handleCampaignType={this.handleCampaignType}
                        hasEgoi={this.state.hasEgoi}
                        hasSendGrid={this.state.hasSendGrid}                        
                        hasTwillio={this.state.hasTwillio}                        
                        alreadySentAndExists={alreadySentAndExists}                        
                        collectMetricsForGoogleAnalytics={collectMetricsForGoogleAnalytics}                        
                        invalidCampaignName={invalidCampaignName}                        
                        handleSwitch={this.handleSwitch}                       
                        validateCampaignName={this.validateCampaignName}                       
                    />
                : ''}

                {steps[1].active === true ?
                    <CustomStep2
                        frequency={frequency}
                        handleSelect={this.handleSelect}
                        handleSurveySelect={this.handleSurveySelect}
                        handleChange={this.handleChange}
                        handleText={this.handleText}
                        campaign={campaign}
                        hasReviewProModule={global.modules?.some(mdl => "ReviewPro" === mdl)}
                        hasProfileNowModule={global.modules?.some(mdl => "ProfileNowForms" === mdl)}
                        hasTypeFormModule={global.modules?.some(mdl => "Typeform" === mdl)}
                        hasEgoi={this.state.hasEgoi}
                        hasSendGrid={this.state.hasSendGrid}
                        hasTwillio={this.state.hasTwillio}
                    />
                : ''}

                {steps[2].active === true ?
                    campaign.marketingType !== 'CustomLinked' ?
                        <CustomStep3Filters
                            filters={this.state.filters}
                            handleTag={this.handleTag}
                            handleExcludedTag={this.handleExcludedTag}
                            selectType={this.selectType}
                            handleDates={this.handleDates}
                            handleSelect={this.handleSelectFilters}
                            handleChangeFiltersArray={this.handleChangeFiltersArray}
                            handleNationalities={this.handleNationalities}
                            handleChangeLatLong={this.handleChangeLatLong}
                            handleChangeFilters={this.handleChangeFilters}
                            handleChange={this.handleChange}
                            changeTagMin={this.changeTagMin}
                            handleTagType={this.handleTagType}
                            setProfiles={this.setProfiles}
                            campaign={campaign}
                            form={this.form}
                            isDisabledSelectHotelIds={this.isDisabledSelectHotelIds}
                            handleLoyaltyCard={this.handleLoyaltyCard}
                            handleOtaEmail={this.handleOtaEmail}
                            handleVipCodes={this.handleVipCodes}
                            applyCampaignTarget={this.applyCampaignTarget}
                        />
                    :
                        <CustomStep3Campaigns
                            filters={this.state.filters}
                            handleChange={this.handleChange}
                            handleMultiSelect={this.handleChangeMultiSelect}
                            handleLinkMarketing={this.handleLinkMarketing}
                            handleLinkCampCriteria={this.handleLinkCampCriteria}
                            handleDates={this.handleDates}
                            clearDatesFilter={this.clearDatesFilter}
                            cleanFormData={this.cleanFormData}
                            handleDate={this.handleDateFilter}
                            campaign={campaign}
                            handleSelectFilters={this.handleSelectFilters}
                            handleChangeFiltersArray={this.handleChangeFiltersArray}
                            isDisabledSelectHotelIds={this.isDisabledSelectHotelIds}
                        />
                : ''}
            </BlankCard>
        );
    }
}
export default injectIntl(CustomCampaignDetails)