import React, { Component } from 'react';
import { StyledCard } from '../../../../Base/CommonUIComponents';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI, getMultiPartAPI, postAPI } from '../../../../Base/API';
import { handleNotification } from '../../../../Base/Notification';
import { Card } from 'reactstrap';
import SpeechBubble from '../../../../Marketing/WhatsApp/SpeechBubble';
import { isValidPhoneNumber } from 'libphonenumber-js';
import InfiniteScroll from 'react-infinite-scroller';
import BlockUi from 'react-block-ui';
import moment from 'moment';
import SendMessage from './SendMessage';
import { getTextOfMessageForReplyBox } from '../../../WhatsApp/CommonFunctions';

export class WhatsApp extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: true,
            pageSize: 20,
            pageIndex: 0,
            totalItems: 0,
            messages: [],
            repliedMessages: [],
            phoneId: null,
            replyTo: null,
            hasMore: false,
            hasMoreThan24h: false,
            loadedMessages: 0
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.profile?.contacts !== nextProps.profile?.contacts) {
            this.validateProfileContact(this.props.profile);
        }

        if (this.props.profileId !== nextProps.profileId) {
            this.getMessages(nextProps.profileId, 0, true);
        }
    }

    componentDidMount() {
        if (this.props.profileId) {
            this.getMessages(this.props.profileId, 0);
            this.validateProfileContact(this.props.profile);

            window.setInterval(_ => this.getNewMessages(this.props.profileId), 60000);
        }

        this.getPhones();
    }

    validateProfileContact = (profile) => {
        if (profile) {
            let mainContact = profile.contacts?.find(el => el.main && el.type === 'Mobile')?.contact;

            if (mainContact) {
                var phone = mainContact.replace(/ /g, '');
                var isValid = phone ? isValidPhoneNumber(phone) : false;

                if (!isValid && phone?.charAt(0) !== "+") { //When there prefix but missing "+"
                    const newPhone = "+" + phone;

                    isValid = isValidPhoneNumber(newPhone);
                };

                if (isValid) {
                    this.setState({ mainContact: phone })
                }
            }
        }
    }

    getPhones = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data)
                }

                let phoneId = null;

                if (data.response && data.response.length > 0) {
                    phoneId = data.response[0].data[0].id;
                }

                this.setState({ error: errorMessage, phoneId });
            }
            else {
                this.setState({ error: errorMessage });
            }
        }, `/api/gms/WhatsApp/v1/phoneNumber`)
    }

    getMessages = (profileId, pageIndex, loadNewChat) => {
        if (this.state.hasMore || this.state.messages.length === 0 || loadNewChat) {
            this.setState({ block: true, hasMore: false });

            getAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    let loadedMessages = this.state.loadedMessages;
                    let repliedMessages = [];
                    let messages = [];

                    if (data.response && data.response.length > 0) {
                        repliedMessages = data.response[0].originalRepliedMessages
                            .map(el => el.body?.charAt(0) === '{' || el.body?.charAt(0) === '[' ? ({ ...el, body: JSON.parse(el.body) }) : el);                            

                        messages = this.messagesStructure(data.response[0].messages, data.response[0].reactionMessages, data.response[0].originalRepliedMessages);

                        if (!loadNewChat) {
                            repliedMessages = repliedMessages.concat([...this.state.repliedMessages]);
                            messages = messages.concat([...this.state.messages]);
                        }

                        loadedMessages += data.response[0].messages?.length;
                    }

                    const hasMore = data.totalItems > loadedMessages;

                    this.setState({
                        messages,
                        repliedMessages,
                        loadedMessages,
                        pageIndex: pageIndex || this.state.pageIndex
                    }, () => {
                        window.setTimeout(
                            () => {
                                if (pageIndex === 0) {
                                    let hasMoreThan24h = true;
                                    const lastUserMsg = [...messages].reverse().find(({ userStart }) => userStart);
                                    const chatWindow = document.getElementById("chatWindow");

                                    if (chatWindow) {
                                        chatWindow.scrollTo(0, chatWindow.scrollHeight);
                                    }

                                    if (lastUserMsg && lastUserMsg.sent) {
                                        const lastMessage = moment.unix(lastUserMsg.sent).format("YYYY-MM-DD HH:mm");

                                        moment().diff(lastMessage, 'minutes')
                                        hasMoreThan24h = moment().diff(lastMessage, 'hours') >= 24;
                                    }

                                    this.setState({ hasMore, hasMoreThan24h, block: false }); 
                                }
                                else {
                                    this.setState({ hasMore, block: false });
                                }
                            },
                            2000
                        );
                    })
                }
                else this.setState({ block: false });
            }, `/api/gms/WhatsApp/v1/profileMessages/${profileId}?pageSize=${this.state.pageSize}&pageIndex=${pageIndex}`)
        }
    }

    getNewMessages = (profileId) => {
        const { messages } = this.state;
        const from = messages.length > 0 ? moment.unix(messages[messages.length - 1].sent).format("YYYY-MM-DDTHH:mm:ss") : moment().format("YYYY-MM-DDTHH:mm:ss");

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                console.error(errorMessage);
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                let loadedMessages = this.state.loadedMessages;
                let repliedMessages = [];
                let messages = [];

                if (data.response && data.response.length > 0) {
                    const newMessages = data.response[0].messages.filter(resp => !this.state.messages.some(msg => resp.id === msg.id));
                    const newOriginalRepliedMessages = data.response[0].originalRepliedMessages
                        .filter(resp => !this.state.repliedMessages.some(msg => resp.id === msg.id))
                        .map(el => el.body?.charAt(0) === '{' || el.body?.charAt(0) === '[' ? ({ ...el, body: JSON.parse(el.body) }) : el);

                    if (newMessages.length > 0) {
                        messages = this.handleMessages(newMessages, data.response[0].reactionMessages, newOriginalRepliedMessages);

                        repliedMessages = [...this.state.repliedMessages].concat(newOriginalRepliedMessages);
                        messages = this.handleDataTag([...this.state.messages].concat(messages).filter(msg => !msg.timestamp));

                        loadedMessages += newMessages.length;

                        this.setState({
                            messages,
                            repliedMessages,
                            loadedMessages,
                        }, _ => {
                            const chatWindow = document.getElementById("chatWindow");
                            if (chatWindow) {
                                chatWindow.scrollTo(0, chatWindow.scrollHeight);
                            }
                        });
                    }
                }
            }        
        }, `/api/gms/WhatsApp/v1/profileMessages/${profileId}?pageSize=${this.state.pageSize}&pageIndex=0&from=${from}`);
    }
    
    getMediaDetails = (mediaId, idx, block) => {
        if (mediaId && !this.state.messages[idx]?.media?.preview) {
            this.setState({ [block]: true });

            const messages = [...this.state.messages];

            getMultiPartAPI(result => {
                const { data, error } = result;

                if (error) {
                    messages[idx].media.notFound = true;

                    this.setState({ messages, [block]: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        messages[idx].media.notFound = true;
                        this.setState({ messages, [block]: false });
                    }
                    else {
                        const reader = new FileReader();
                        reader.readAsDataURL(data);

                        reader.onloadend = () => {

                            messages[idx].media.preview = reader.result;

                            this.setState({ messages, [block]: false });
                        }
                    }
                }
                else {
                    this.setState({ [block]: false });
                }
            }, `api/gms/WhatsApp/v1/${mediaId}/media`);
        }
    }

    //Return message with all info (reactions, media and replied message) and add date badges to chat
    messagesStructure = (profileMessages, reactions, originalRepliedMessages) => {
        const messages = this.handleMessages(profileMessages, reactions, originalRepliedMessages);

        return this.handleDataTag(messages);
    }

    handleMessages = (profileMessages, reactions, originalRepliedMessages) => {
        return profileMessages.reverse().map((msg) => {
            if (msg.type !== 'Reaction') {
                //Reactions
                if (reactions.some(el => el.replyMessageId === msg.messageId)) {
                    msg.reaction = reactions?.find(el => el.replyMessageId === msg.messageId);
                }

                //Message
                if (msg.body?.charAt(0) === '{' || msg.body?.charAt(0) === '[') {
                    msg.body = JSON.parse(msg.body);
                }

                msg.repliedMessage = this.getRepliedMessage(msg.replyMessageId, originalRepliedMessages);
                msg.components = msg.type === "Template" ? null : this.transformMsgIntoComp(msg.body, msg.type);
                msg.media = this.getMedia(msg);

                return msg;
                //messages.push(msg);
            }
        });
    }

    handleDataTag = (messages) => {
        const messagesWithData = messages.reduce((acc, curr, idx) => {
            if (idx > 0) {
                const sent = moment.unix(curr.sent).format("YYYY-MM-DD");
                const prev = moment.unix(messages[idx - 1].sent).format("YYYY-MM-DD");

                if ((curr.sent && messages[idx - 1].sent) && sent !== prev) {
                    acc.push({ timestamp: sent });
                }
            }
            else {
                acc.push({ timestamp: moment.unix(curr.sent).format("YYYY-MM-DD") });
            }

            acc.push(curr);
            return acc;
        }, []);

        return messagesWithData;
    }

    sendMessage = (e, reaction, media, template, location, replyTo, newMessage) => {
        if(e) e.preventDefault();
        this.setState({ block: true });

        let endpoint = "message";

        const body = {
            profileId: parseInt(this.props.profileId),
            recipientPhoneNumber: this.state.mainContact
        };

        if (reaction) {
            body.type = 'reaction';
            body.reaction = {
                messageId: reaction.messageId,
                emoji: reaction.emoji
            }
        }
        else if (media) {
            body.type = media.type;
            body.media = media;
        }
        else if (template) {
            const payload = JSON.parse(JSON.stringify(template));

            const index = payload.fullTemplate.components?.findIndex(el => el.type === 'HEADER');

            if (index !== -1) {
                delete payload.fullTemplate.components[index].media
            }

            body.type = "template";
            body.template = payload;
        }
        else if (location) {
            body.type = "location";
            body.location = {
                latitude: location.latitude,
                longitude: location.longitude,
                name: location.name,
                address: location.address
            };
        }
        else if (replyTo || this.state.replyTo) { //keep before body.type = "text" and after other message types
            endpoint = "messageReply";

            replyTo = replyTo || this.state.replyTo;
            newMessage = newMessage || this.state.newMessage;

            body.replyMessageId = replyTo.id;
            body.message = newMessage;
        }
        else if (newMessage || this.state.newMessage) {
            newMessage = newMessage || this.state.newMessage;

            body.type = "text";
            body.message = { "message": newMessage };
        }

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else {
                    handleNotification(data, <FormattedMessage id="WhatsApp.MessageSaved" />, <FormattedMessage id="generic.success" />);

                    this.updateChat(errorMessage, reaction, media, template, location, replyTo, newMessage);
                }
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/WhatsApp/v1/${this.state.phoneId}/${endpoint}`, JSON.stringify( body ));
    }

    //Prepare new message structure and add it to chat
    updateChat = (errorMessage, reaction, media, template, location, replyTo, newMessage) => {
        const messagesList = [...this.state.messages];
        const repliedMessagesList = [].concat([...this.state.repliedMessages]);

        if (reaction) {
            messagesList[reaction.messageIndex].reaction = {
                ...messagesList[reaction.messageIndex].reaction,
                body: reaction.emoji
            }
        }
        else {
            const currentDate = moment();

            const newMsg = {
                sent: currentDate.format("X"),
                userStart: false
            }


            if (template) {
                const header = template.fullTemplate.components?.find(el => el.type === 'HEADER');

                newMsg.type = "Template";
                newMsg.body = template.fullTemplate;
                newMsg.media = header.media;

            }
            else if (location) {
                newMsg.type = "Location";
                newMsg.components = [Object.assign({ type: 'HEADER', format: "LOCATION" }, location)];
            }
            else if (media) {
                newMsg.type = media.type.charAt(0) + media.type.slice(1).toLowerCase();

                newMsg.media = {
                    preview: media.preview,
                    caption: media.caption,
                    type: media.type
                }
                newMsg.components = [{
                    type: 'HEADER',
                    format: media.type.toUpperCase(),
                    mediaId: media.mediaId
                }];
            }
            else {
                replyTo = replyTo || this.state.replyTo;
                newMessage = newMessage || this.state.newMessage;

                newMsg.type = "Text";
                newMsg.components = [{ type: 'BODY', text: newMessage }];


                if (replyTo) { //keep before body.type = "text" and after other message types
                    const repliedMsg = JSON.parse(JSON.stringify(replyTo));
                    repliedMsg.messageId = repliedMsg.id;

                    delete repliedMsg.id;
                    delete repliedMsg.message;

                    repliedMessagesList.push(repliedMsg);

                    newMsg.replyMessageId = repliedMsg.messageId;
                    newMsg.repliedMessage = this.getRepliedMessage(repliedMsg.messageId, [repliedMsg]);
                }
            }



            const lastMessage = messagesList[messagesList.length - 1];

            //Caso a nova mensagem seja enviada num dia diferente, adicionar badge da data
            if (lastMessage) {
                const prev = moment.unix(lastMessage.sent).format("YYYY-MM-DD");

                if (currentDate.format("YYYY-MM-DD") !== prev) {
                    messagesList.push({ timestamp: currentDate.format("YYYY-MM-DD") });
                }
            }


            //Add text message to chat
            messagesList.push(newMsg);
        }

        this.setState({
            block: false,
            error: errorMessage,
            newMessage: '', selectedTemplate: null, replyTo: null,
            messages: messagesList, repliedMessage: repliedMessagesList
        }, () => {
            const chatWindow = document.getElementById("chatWindow");

            if (chatWindow) {
                chatWindow.scroll({ top: chatWindow.scrollHeight, behavior: "smooth" });
            }
        });
    }

    //Mark message as read (previous messages are also marked as read)
    markAsRead = (phoneId, messageId, messageIndex) => {
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                    return;
                }
                else {
                    const { messages } = this.state;
                    const date = moment().format("X");

                    for (var i = messageIndex; i >= 0; i--) {
                        if (messages[i].read) {
                            break;
                        }

                        messages[i].read = date;
                    }

                    this.setState({ block: false, messages });
                }
            }
            else this.setState({ block: false });
        }, `/api/gms/WhatsApp/v1/${phoneId}/message/${messageId}`)
    }

    //Tranform messages (except template msg) into components to be rendered by SpeechBubble
    transformMsgIntoComp = (body, type) => {
        let component = {};

        if (Array.isArray(body)) {
            return { type: 'CONTACT', contacts: body };
        }
        else if (typeof body === 'object') {
            if (type !== 'Template') {
                component.type = 'HEADER';

                if (body.mime_type === "image/jpeg" || body.mime_type === "image/png" || body.mime_type === "image/webp" || type === 'Image') {
                    component.format = 'IMAGE';
                }
                else if (body.mime_type === "video/mp4" || body.mime_type === "video/3gpp" || type === 'Video') {
                    component.format = 'VIDEO';
                }
                else if (body.latitude || type === 'Location') {
                    component.format = 'LOCATION';
                    component.latitude = body.latitude;
                    component.longitude = body.longitude;
                    component.name = body.name;
                    component.address = body.address;
                    component.url = body.url;
                }
                else if (body.mime_type === "audio/aac" || body.mime_type === "audio/mp4" || body.mime_type === "audio/amr" || body.mime_type === "audio/mpeg" || body.mime_type === "audio/ogg; codecs=opus" || type === 'Audio') {
                    component.format = 'AUDIO';
                }
                else {
                    component.format = 'DOCUMENT';
                }
            }
        }
        else {
            component.type = 'BODY';
            component.text = body;
        }

        return [component];
    }

    //If message is a reply, it returns info about original message
    getRepliedMessage = (replyMessageId, originalRepliedMessages) => {
        const msg = originalRepliedMessages?.find(el => el.messageId === replyMessageId);

        if (msg) {
            return {
                message: getTextOfMessageForReplyBox(msg),
                from: <b className="text-host">
                    {msg.userStart ? <span> {this.props.profile?.firstName} {this.props.profile?.lastName}</span> : <span> <FormattedMessage id="WhatsApp.Me" /> ({msg.phoneNumberId}) </span>}
                </b>
            }
        }

        return null;
    }

    //Save data in state from guest message we want to reply to
    replyMessage = (message) => {
        this.setState({
            replyTo: {
                id: message.messageId,
                type: message.type,
                body: message.body,
                userStart: message.userStart,
                message: getTextOfMessageForReplyBox(message),
            }
        })
    }

    //Get base info from media (don't get preview)
    getMedia = (msg) => {
        let media = null;

        if (msg.type === "Template") {
            const header = msg.body.components?.find(el => el.type === 'HEADER');

            if (header && (header.format === "DOCUMENT" || header.format === "VIDEO" || header.format === "IMAGE")) {
                media = { type: header.format.charAt(0) + header.format.slice(1).toLowerCase(), id: header.mediaId };
            }

        }
        else if (typeof msg.body === 'object' && msg.type !== 'Location') {
            if (msg.body.mime_type) {
                media = { type: msg.body.mime_type, id: msg.body.id, fileName: msg.body.filename, caption: msg.body.caption };
            }
            else {
                media = { type: msg.type, id: msg.body.mediaId, caption: msg.body.caption };
            }
        }

        return media;
    }

    //Add emoji to message text
    selectEmoji = (emoji) => {
        this.setState(prevState => ({
            newMessage: (prevState.newMessage || '') + emoji
        }));
    }

    //Resend failed message
    resendMessage = (message) => {
        let media = null, location = null, replyTo = null, newMessage = null;

        if (message.type === "Text") {
            replyTo = this.getRepliedMessage(message.replyMessageId, this.state.repliedMessages);

            newMessage = message.body;
        }
        else if (message.type === "Location") {
            location = message.body;
        }
        else {
            media = message.media;
        }

        this.sendMessage(null, null, media, null, location, replyTo, newMessage);
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        });
    }

    render() {
        const { block, error, messages, replyTo, hasMoreThan24h } = this.state;

        return (
            <StyledCard error={error} block={block}>
                <Card className="border-0 shadow p-0" body>
                    <div style={{ height: "80vh", background: 'url("backgroundWhatsApp.png") center center repeat fixed', width: '100%' }}>
                        <div id="chatWindow" className='verticalScroll w-100' style={{ height: "75vh", overflow: 'auto', paddingBottom: (replyTo ? '4rem' : '2rem') }}>
                            {messages && messages.length > 0 ?
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={() => this.getMessages(this.props.profileId, this.state.pageIndex + 1)}
                                    hasMore={this.state.hasMore}
                                    loader={<div style={{ width: '100%' }} key="loader"><BlockUi tag="div" blocking={true} /></div>}
                                    useWindow={false}
                                    isReverse
                                >
                                    {messages.map((msg, idx) => {
                                        const block = `blockChatMedia-${idx}`;
                                        const isTemplate = msg.type === "Template";

                                        return <div key={idx}>
                                            {msg.timestamp ?
                                                <div className="text-center my-4">
                                                    <span style={{ width: 'max-content', backgroundColor: '#d3eaf2', color: '#546168', padding: '5px 13px', borderRadius: '10px', fontSize: '11px' }}>
                                                        {/*moment(msg.timestamp).fromNow() + " , " + moment(msg.timestamp).format("YYYY-MM-DD HH:mm")*/ moment(msg.timestamp).format("DD MMMM YYYY")}
                                                    </span>
                                                </div>
                                                :
                                                <SpeechBubble
                                                    //Messages
                                                    components={isTemplate ? msg.body?.components : msg.components}
                                                    messageId={msg.messageId}
                                                    repliedMessage={msg.repliedMessage}
                                                    replyMessage={() => this.replyMessage(msg)}
                                                    status={{ sent: (msg.sent ? moment.unix(msg.sent) : null), delivered: (msg.delivered ? moment.unix(msg.delivered) : null), read: (msg.read ? moment.unix(msg.read) : null), failure: (msg.failure ? moment.unix(msg.failure) : null) }}
                                                    resendMessage={() => this.resendMessage(msg)}
                                                    //Aux
                                                    messageIndex={idx}
                                                    isAnOldMessage={moment().diff(moment.unix(msg.sent), 'days') > 30}
                                                    fromUser={msg.userStart}
                                                    hasMoreThan24h={hasMoreThan24h}
                                                    isTemplate={isTemplate}
                                                    //Media
                                                    getMediaDetails={() => this.getMediaDetails(msg.media?.id, idx, block)}
                                                    blockMedia={this.state[block]}
                                                    currentMedia={msg.media}
                                                    fromWhatsAppChat={true}
                                                    loadImage={true}
                                                    //Reactions
                                                    reaction={msg.reaction}
                                                    sendReaction={this.sendMessage}
                                                    //MarkAsRead
                                                    markAsRead={() => this.markAsRead(msg.phoneNumberId, msg.messageId, idx)}
                                                    //Style
                                                    maxWidth={isTemplate ? "30%" : "40%"}
                                                    minMediaWidth="300px"
                                                />
                                            }
                                        </div>
                                    })}
                                </InfiniteScroll>
                                : ''}
                        </div>
                    </div>



                    <SendMessage
                        mainContact={this.state.mainContact}
                        sendMessage={this.sendMessage}
                        replyTo={replyTo}
                        hasMessages={messages && messages.length > 0}
                        handleChange={this.handleChange}
                        newMessage={this.state.newMessage}
                        removeReplyTo={() => this.setState({ replyTo: null })}
                        selectEmoji={this.selectEmoji}
                        hasMoreThan24h={hasMoreThan24h}
                    />
                </Card>
            </StyledCard>
        );
    }
}
export default injectIntl(WhatsApp);