import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Badge, Button, Input, Modal, ModalBody } from 'reactstrap';
import { getTagGroupIcons } from '../Base/ReferenceDataFunctions';
import { handleNotification } from '../Base/Notification';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI, putAPI, deleteAPI } from '../Base/API';
import { BlankCard } from '../Base/CommonUIComponents';
import { CustomTable } from '../Base/CustomTable';
import DragAndDrop from "../Base/DragAndDrop";
import { ErrorAlert } from "../Base/ErrorAlert";
import BlockUi from 'react-block-ui';

import Authorization from '../Base/Authorization';
import { Profile } from '../Profile/Profile';

class ProfileInterests extends Component {
    ProfileInterests = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            interests: this.props.interests ? this.props.interests : [],
            tagList: [],
            addTag: false,
            removeTag: false,
            modal: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.interests !== nextProps.interests) {
            this.setState({ interests: nextProps.interests})
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        this.getTags();
    }

    getTags = () => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    const list = [...data.response];

                    list.forEach(tagGroup => {
                        tagGroup.tag && tagGroup.tag.forEach(tag => {
                            if (this.state.interests?.some(i => i.tags?.some(t => t.tagId === tag.id))) {
                                tag.visible = false;
                            }
                            else {
                                tag.visible = true;
                            }
                        })
                    })
                    this.setState({ tagList: list });
                }
                this.setState({ block: false });
            }
        }, `/api/gms/Profile/v1/tag/groupDetails?tagType=all`)
    }

    addTag = (e) => {
        this.setState({ block: true });

        const tag = JSON.parse(e.getData('addTag'));
        const tagGroup = JSON.parse(e.getData('addTagGroup'));
        const body = { tagId: tag ? tag.id : '' }

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false, addTag: undefined });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="ProfileInterests.TagAdded" />, <FormattedMessage id="generic.success" />);

                    const newTag = { id: null, tagId: tag.id, tagGroupId: tag.tagGroupId, code: tag.code, frequency: null };

                    if (data.response && data.response[0]) {
                        newTag.id = data.response[0].id;
                        newTag.frequency = data.response[0].frequency;
                    }

                    this.updateInterestList(newTag);
                }
            }

            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/profile/tag/${this.props.profileId}`, JSON.stringify({ request: body }));
    }

    handleRemoveTag = (e) => {
        //Quando houver apenas uma tag, ela é eliminada
        //Caso haja mais do que uma tag, apresentar uma modal com todas as tags repetidas para o utilizador escolher qual quer eliminar
        const tagGroupId = e.getData('tagGroupId'); 
        const tagId = e.getData('tagId');

        if (this.state.repeatedTags.length === 1) {
            this.removeTag(this.state.repeatedTags[0].id, tagGroupId, this.props.profileId, tagId)
        }
        else {
            this.toggleModal();
        }
    }

    removeTag = (relationId, tagGroupId, profileId, tagId) => {
        this.setState({ block: true });
                                
        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false, addTag: undefined });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="ProfileInterests.TagRemoved" />, <FormattedMessage id="generic.success" />);

                    var profileInterests = [...this.state.interests];
                    const index = profileInterests && profileInterests.findIndex(el => el.tagGroupId == tagGroupId);

                    if (index > -1) {
                        const tagIndex = profileInterests[index].tags.findIndex(el => el.tagId == tagId);

                        if (profileInterests[index].tags[tagIndex].allTags.length > 1) {
                            const tagToRemove = profileInterests[index].tags[tagIndex].allTags.findIndex(t => t.id === relationId)

                            profileInterests[index].tags[tagIndex].frequency -= profileInterests[index].tags[tagIndex].allTags[tagToRemove].frequency;
                            profileInterests[index].tags[tagIndex].allTags.splice(tagToRemove, 1);
                        }
                        else {
                            if (profileInterests[index].tags.length > 1) {
                                profileInterests[index].tags.splice(tagIndex, 1);
                            }
                            else {
                                profileInterests.splice(index, 1);
                            }
                        }

                    }

                    this.updateTagList(profileInterests);
                    this.setState({ interests: profileInterests });
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/profile/tag/${profileId}/${relationId}`);
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    updateInterestList = (tag) => {
        const profileInterests = [...this.state.interests];
        const groupIndex = profileInterests.findIndex(el => el.tagGroupId === tag.tagGroupId);
        
        if (groupIndex > -1) {
            const tagIndex = profileInterests[groupIndex].tags.findIndex(tg => tg.tagId === tag.tagId);

            if (tagIndex > -1) {
                profileInterests[groupIndex].tags[tagIndex].frequency = profileInterests[groupIndex].tags[tagIndex].frequency + tag.frequency;
                profileInterests[groupIndex].tags[tagIndex].allTags.push(tag);
            }
            else {
                profileInterests[groupIndex].tags.push({ code: tag.code, tagId: tag.tagId, frequency: tag.frequency, allTags: [tag] });
            }
        }
        else {
            profileInterests.push({ tagGroupId: tag.tagGroupId, tagGroupCode: tag.tagGroupCode, tags: [{ code: tag.description, tagId: tag.tagId, frequency: tag.frequency, allTags: [tag] }] });
        }

        this.updateTagList(profileInterests);
        this.setState({ interests: profileInterests });
    }

    updateTagList = (interests) => {
        const tags = [...this.state.tagList];

        tags && tags.forEach(tg => {
            tg.tag && tg.tag.forEach(tag => {
                if (interests.find(i => i.tags && i.tags.find(t => t.tagId == tag.id))) {
                    tag.visible = false;
                }
                else {
                    tag.visible = true;
                }
            })
        })

        this.setState({ tagList: tags })
    }
    
    searchTag = (event) => {
        const { name, value } = event.target;
        const tags = [...this.state.tagList];

        if (value) {
            tags && tags.forEach(tg => {
                tg.tag && tg.tag.forEach(tag => {
                    if (tag.code.toLowerCase().includes(value.toLowerCase())) {
                        tag.visible = true;
                    }
                    else {
                        tag.visible = false
                    }
                })
            })
        }
        else {
            tags && tags.forEach(tg => {
                tg.tag && tg.tag.forEach(tag => {
                    if (this.state.interests.find(i => i.tags && i.tags.find(t => t.tagId === tag.id))) {
                        tag.visible = false;
                    }
                    else {
                        tag.visible = true;
                    }
                })
            })
        }

        this.setState({
            tagList: tags
        })
    }

    resetAddTag = () => { this.setState({ addTag: false, removeTag: false }) };

    render() {
        const { interests, tagList, addTag, removeTag } = this.state;
        const { canEditProfile } = this.props;

        const columns = [
            {
                dataField: 'description',
                text: this.props.intl.formatMessage({ id: "ProfileDetails.Tag" }),
                sort: true
            },
            {
                dataField: 'hotelName',
                text: this.props.intl.formatMessage({ id: "generic.Hotel" }),
                sort: true
            },
            {
                dataField: 'profileName',
                text: this.props.intl.formatMessage({ id: "ProfileDetails.Profile" }),
                sort: true
            },
            {
                dataField: 'frequency',
                text: this.props.intl.formatMessage({ id: "ProfileDetails.Frequency" }),
                sort: true
            },
            {
                dataField: 'delete',
                text: this.props.intl.formatMessage({ id: "ProfileDetails.Delete" }),
                formatter: (cell, row) => <i style={{ color: 'red' }} className="fas fa-trash-alt pointer" onClick={() => this.removeTag(row.id, row.tagGroupId, row.profileId, row.tagId)} />,
                style: { textAlign: 'center' },
                headerStyle: { textAlign: 'center' }
            }
        ]

        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                    <div className="m-2 p-2">
                        <Row className="mb-2">
                            <Col>
                                <h5><i className="icon-icon-interests mr-2"> </i><FormattedMessage id="ProfileDetails.Interests" /></h5>
                            </Col>
                        </Row>
                        <div className="container-fluid content-row p-0">
                            <Row>
                                <Col className="col-8">
                                    <Card className="h-100 border-0 shadow">
                                        <CardBody>
                                            <Row className="mb-2">
                                                <Col className="col-6"><h5><FormattedMessage id="ProfileInterests.IdentifiedTags" /></h5></Col>
                                                <Authorization
                                                    perform="profileInterests:add"
                                                    yes={() => canEditProfile && (
                                                        <Col className="col-6">
                                                            {
                                                                !removeTag ?
                                                                    <DragAndDrop handleDrop={this.addTag} msg={'Drop here to add'}>
                                                                        <div style={{ height: 30, minWidth: 300, whiteSpace: 'break-spaces' }}>
                                                                        </div>
                                                                    </DragAndDrop>
                                                                    :
                                                                    <div/>
                                                            }
                                                        </Col>
                                                    )}
                                                    no={() => <div></div>}
                                                />
                                            </Row>

                                            <div className="verticalScroll" style={{ height: '70vh', overflowX: 'hidden' }}>
                                                {interests && interests.map((tagGroup, key) =>
                                                    <div className="mb-2" key={key}>
                                                        <Row className="mb-2">
                                                            <Col>
                                                                <b>{tagGroup.tagGroupCode}</b>
                                                                {tagGroup.tagGroupCode && getTagGroupIcons().find(group => group.group.toLowerCase() === tagGroup.tagGroupCode.toLowerCase()) ?
                                                                    <i className={'ml-2 ' + getTagGroupIcons().find(group => group.group.toLowerCase() === tagGroup.tagGroupCode.toLowerCase()).icon} />
                                                                    : ''}
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-2" >
                                                            <Col>
                                                                {tagGroup.tags && tagGroup.tags.map((tag, index) =>
                                                                    <Badge className="mr-2 p-2 mb-2" color="primary" style={{ cursor: 'pointer' }} key={index}
                                                                        draggable="true"
                                                                        onDragStart={(event) => {
                                                                            event.dataTransfer.setData("tagGroupId", tagGroup.tagGroupId);
                                                                            event.dataTransfer.setData("tagId", tag.tagId);
                                                                            //event.dataTransfer.setData("allTags", tag.allTags);
                                                                            this.setState({ removeTag: true, repeatedTags: tag.allTags });
                                                                        }}
                                                                        onDragEnd={this.resetAddTag}
                                                                    >
                                                                        {tag.code}  {tag.frequency > 0 ? <span className="bg-white text-host ml-2 px-1" style={{ borderRadius: '20px' }}>{tag.frequency} </span> : ''}
                                                                    </Badge>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )}

                                                {interests == null || interests.length == 0 ? <h6><FormattedMessage id="ProfileDetails.NoInterestsAvailable" /></h6> : ''}
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card className="h-100  border-0 shadow">
                                        <CardBody>
                                            <Row className="mb-4">
                                                <Col className="col-6"><h5 className="mb-0 mt-1"><FormattedMessage id="ProfileInterests.TagList" /></h5></Col>
                                                <Col className="col-6 align-items-center">
                                                    <div className="bg-light-grey d-flex align-items-center justify-content-end px-3 p-1 invisible-input shadow" style={{ borderRadius: '20px', width: 'fit-content' }}>
                                                        <i className="icon-icon-search mr-2" />
                                                        <input
                                                            style={{ width: '85%' }}
                                                            type="text"
                                                            onChange={this.searchTag}
                                                            placeholder={this.props.intl.formatMessage({ id: "ProfileInterests.SearchTag" })}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="verticalScroll" style={{ height: '65vh', overflowX: 'hidden' }}>
                                                {tagList && tagList.map((tagGroup, key) =>
                                                    <div className="mb-2" key={key}>
                                                        <Row>
                                                            <Col>
                                                                <b> {tagGroup.code}</b>
                                                                {getTagGroupIcons().find(group => group.group.toLowerCase() === tagGroup.code.toLowerCase()) ?
                                                                    <i className={'ml-2 ' + getTagGroupIcons().find(group => group.group.toLowerCase() === tagGroup.code.toLowerCase()).icon} />
                                                                : ''}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                {tagGroup.tag && tagGroup.tag.map((tag, index) =>
                                                                    <Badge className="mr-2 p-2 mb-2" color="primary"
                                                                        style={tag.visible ? { cursor: 'pointer' } : { display: 'none' }}
                                                                        key={index}
                                                                        draggable="true"
                                                                        onDragStart={(event) => {
                                                                            event.dataTransfer.setData("addTag", JSON.stringify(tag));
                                                                            event.dataTransfer.setData("addTagGroup", JSON.stringify(tagGroup));
                                                                            this.setState({ addTag: true });
                                                                        }}
                                                                        onDragEnd={this.resetAddTag}
                                                                    >
                                                                        {tag.code}
                                                                    </Badge>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )}
                                            </div>

                                            <Authorization
                                                perform="profileInterests:delete"
                                                yes={() => canEditProfile && (
                                                    <Row className="justify-content-center mt-3 px-3">
                                                        {!addTag ?
                                                            <DragAndDrop handleDrop={this.handleRemoveTag} msg={'Drop here to remove'}>
                                                                <div style={{ height: 30, minWidth: 280, whiteSpace: 'break-spaces' }}>
                                                                </div>
                                                            </DragAndDrop>
                                                            : <div />}
                                                    </Row>
                                                )}
                                                no={() => <div></div>}
                                            />

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </BlockUi>

                {this.state.modal ?
                    <Modal isOpen={this.state.modal} fade={false} className="modal-md" >
                        <Button onClick={this.toggleModal} className="closeModal">
                            <i className="fas fa-times fa-sm"></i>
                        </Button>
                        <ModalBody className="pb-0">
                            <BlankCard block={this.state.block} error={this.state.error}>
                                <h5 className="mb-4"><FormattedMessage id="ProfileDetails.ChooseTagsYouWantToRemove" /></h5>

                                <CustomTable
                                    data={this.state.repeatedTags ? this.state.repeatedTags : []}
                                    columns={columns}
                                    showTotal={true}
                                    remote={false}
                                />
                            </BlankCard>
                        </ModalBody>
                    </Modal>
                : ''}
            </div>
        );
    }
}
export default injectIntl(ProfileInterests)