import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { BlankCard, StyledModal } from "../../Base/CommonUIComponents";
import { Button, Col, Row, Input, FormGroup, Form, Label } from "reactstrap";
import { postAPI } from "../../Base/API";
import { handleNotification } from "../../Base/Notification";
import CustomSelect from "../../Base/CustomSelect";
import moment from 'moment';

class RefundModal extends Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            redeem: {
                notes: null,
                quantity: 0,
                amount: 0
            },
            maxAmount: 0,
            maxQuantity: 0
        }
    }

    componentDidMount() {
        const { redeem } = this.state;
        redeem.rebateId = this.props.redeem.rebateId;
        redeem.usageHotel = this.props.redeem.usageHotel;
        redeem.businessDate = moment().format("YYYY-MM-DD");

        this.setState({
            redeem,
            minAmount: this.props.redeem.amount * -1,
            maxAmount: this.props.redeem.amount,
            minQuantity: this.props.maxQuantity ? this.props.maxQuantity * -1 : this.props.redeem.quantity * -1,
            maxQuantity: this.props.maxQuantity ? this.props.maxQuantity : this.props.redeem.quantity
        });
    }


    saveRedeem = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const { voucher } = this.props;
        const { redeem, minAmount, maxAmount, minQuantity, maxQuantity } = this.state;

        const req = {
            request: [redeem]
        };

        req.request[0].id = null;
        req.request[0].voucherId = voucher.id;

        if (voucher.voucherType.isPaymentMode) {
            const amount = document.getElementById('amount');
            if (redeem.amount < minAmount || redeem.amount > maxAmount) {
                amount.setCustomValidity(this.props.intl.formatMessage({ id: "AddRedeem.AmountOutOfBounds" }));
            }
            else {
                amount.setCustomValidity("");
            }
            if (req.request[0].amount > 0) {
                req.request[0].amount *= -1;
            }
        }
        else {
            const quantity = document.getElementById('quantity');
            if (redeem.quantity < minQuantity || redeem.quantity > maxQuantity) {
                quantity.setCustomValidity(this.props.intl.formatMessage({ id: "AddRedeem.QuantityOutOfBounds" }));
            }
            else {
                quantity.setCustomValidity("");
            }
            if (req.request[0].quantity > 0) {
                req.request[0].quantity *= -1;
            }
        }

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="AddRedeem.RefundCreatedSuccessfully" />, <FormattedMessage id="generic.success" />);
                    const serviceRefund = voucher.voucherType.isPaymentMode === false ? true : false;

                    this.setState({ block: false }, () => {
                        this.props.toggleModal();
                        this.props.getVoucherDetails()
                    });
                }
            }
        }, '/api/Voucher/Redeem/Redeem/v1', req);
    }

    getRebates = () => {
        let arr = [];
        this.props.voucher && this.props.voucher.voucherType.voucherRebates && this.props.voucher.voucherType.voucherRebates.map((el, k) => arr.push({ value: el.id, label: el.code }))
        return arr;
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState(prevState => ({
            redeem: {
                ...prevState.redeem,
                [name]: value
            }
        }));
    }

    handleChangeCombo = (combo, name) => {
        this.setState(prevState => ({
            redeem: {
                ...prevState.redeem,
                [name]: combo ? combo.value : null
            }
        }));
    }

    render() {
        const { modal, toggleModal, voucher } = this.props;
        const { block, error, redeem, minAmount, maxAmount, minQuantity, maxQuantity } = this.state;

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="lg">
                <BlankCard block={block} error={error}>
                    <Form onSubmit={this.saveRedeem}>
                        <Row className="align-items-center mb-3">
                            <Col>
                                <h5><FormattedMessage id="AddRedeem.IssueRefound" /> </h5>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host" type="submit">
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>

                        {voucher?.voucherType.isPaymentMode === true ?
                            <FormGroup row>
                                <Label sm={2}><FormattedMessage id="VoucherDetails.Amount" /></Label>
                                <Col sm={10}>
                                    <Input
                                        type="number"
                                        min={minAmount}
                                        step="0.01"
                                        max={maxAmount}
                                        required
                                        id="amount"
                                        name="amount"
                                        value={redeem.amount || ''}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </FormGroup>
                            :
                            <FormGroup row>
                                <Label sm={2}><FormattedMessage id="AddRedeem.Rebates" /></Label>
                                <Col sm={4}>
                                    <CustomSelect
                                        placeholder=""
                                        required
                                        name="rebateId"
                                        options={(e) => this.handleChangeCombo(e)}
                                        value={this.getRebates().find(el => el.value === redeem.rebateId)}
                                        isDisabled
                                    />
                                </Col>
                                <Label sm={2}><FormattedMessage id="VoucherDetails.Quantity" /></Label>
                                <Col sm={4}>
                                    <Input
                                        type="number"
                                        min={minQuantity} max={maxQuantity}
                                        required
                                        id="quantity"
                                        name="quantity"
                                        value={redeem.quantity || ''}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </FormGroup>
                        }
                        <FormGroup row>
                            <Label sm={2}><FormattedMessage id="VoucherDetails.Notes" /></Label>
                            <Col sm={10}>
                                <Input
                                    type="textarea"
                                    minLength="10" maxLength="200"
                                    name="notes"
                                    value={redeem.notes || ''}
                                    onChange={this.handleChange}
                                    required
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                </BlankCard>
            </StyledModal>
        );
    }
}

export default injectIntl(RefundModal);