import React, { Component } from 'react';
import { DateRangePicker } from 'react-dates';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Badge, Button, Col, Row, FormGroup, Label, NavLink, Nav, NavItem, TabContent, TabPane, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getAPI } from '../../Base/API';
import { SelectHotel, StyledCard } from '../../Base/CommonUIComponents';
import CustomSelect from '../../Base/CustomSelect';
import { handleNotification } from '../../Base/Notification';
import moment from "moment";
import { getConsentTypeList, getProfileType } from '../../Base/ReferenceDataFunctions';
import countryList from 'react-select-country-list';
import { CustomTable } from '../../Base/CustomTable';
import { Link } from 'react-router-dom';

class CustomExport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            surveys: [],
            products: [],
            tagTab: 0,
            selectedParams: {
                nationalities: null,
                startBirthDate: null,
                endBirthDate: null,
                tags: [],
                profileType: null,
                consentType: null,
                hotelId:null,
                checkinDate: null,
                checkoutDate: null,
                requiredFieldsOptions:[
                    { label: this.props.intl.formatMessage({ id: 'ExportProfiles.Email' }), field: 'mustHaveEmail', isSelected: false },
                    { label: this.props.intl.formatMessage({ id: 'ExportProfiles.Loyalty' }), field: 'mustHaveLoyalty', isSelected: false },
                    { label: this.props.intl.formatMessage({ id: 'ExportProfiles.Cellphone' }), field: 'mustHaveMobile', isSelected: false }
                ]
            },
            selectedExport: {},
            Profiles:null,
            currPage: 1,
            Profilestotal_items:0,
        };
    }

    componentDidMount() {
        this.getTags();
    }

    getSurveys = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                var list = [];

                data.response && data.response.forEach(survey => {
                    list.push({ value: survey.id, label: survey.name });
                })
                
                this.setState({ surveys: list });
            }
            this.getProducts();
        }, '/api/gms/ReviewPro/v1/Surveys');
    }

    getProducts = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    var list = [];

                    data.response.forEach(el => { list.push({ label: el.name, value: el.id.toString() }) });

                    this.setState({ products: list });
                }
            }
            this.setState({ block: false });
        }, '/api/gms/ReviewPro/v1/Lodgings');
    }

    getTags = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    this.setState({ tagList: data.response });
                }
            }
            this.getSurveys()
        }, `/api/gms/Profile/v1/tag/groupDetails?tagType=all`)
    }

    handleHotelSelect = (name, combo) => {
        this.setState(prevState => ({
            selectedParams: {
                ...prevState.selectedParams,
                [name]: combo ? combo.value : null
            }
        }));
    }
    
    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            selectedParams: {
                ...prevState.selectedParams,
                [name]: value
            }
        }))
    }
    
    handleTag = (tag) => {
        let { tags } = this.state.selectedParams;
        const index = tags && tags.indexOf(tags.find(el => el === tag.id));

        if (index === -1) {
            tags.push(tag.id);
        }
        else {
            tags = tags.filter(el => el !== tag.id);
        }
        this.setState({ selectedParams: { ...this.state.selectedParams, tags } });
    }
    
    handleDates = (startDate, endDate, startFieldName, endFieldName) => {
        this.setState({
            selectedParams: {
                ...this.state.selectedParams,
                [startFieldName]: moment(startDate).format("YYYY-MM-DD"),
                [endFieldName]: moment(endDate).format("YYYY-MM-DD")
            }
        });
    }
    
    handleNationalities = (combo) => {
        let { nationalities } = this.state.selectedParams;
        nationalities = combo !== null ? combo.value : null;
        this.setState({ selectedParams: { ...this.state.selectedParams, nationalities } });
    }

    handleChangeSelect = (combo, name, name2) => {
        this.setState({
            selectedExport: {
                ...this.state.selectedExport,
                [name]: combo ? combo.value : null,
                [name2]: combo ? combo.label : null
            }
        });
    };

    ExportProfiles = (save, IsNewPage) => {
        const { selectedExport, selectedParams, Profiles, currPage } = this.state;
        const { nationalities, startBirthDate, endBirthDate, tags, profileType, consentType, hotelId, checkinDate, checkoutDate } = selectedParams;
        const { surveyId, propertyId } = selectedExport;

        const hasLoyaltyCard = selectedParams.requiredFieldsOptions.find((field) => field.field === 'mustHaveLoyalty').isSelected;
        const mustHaveEmail = selectedParams.requiredFieldsOptions.find((field) => field.field === 'mustHaveEmail').isSelected;
        const mustHaveMobile = selectedParams.requiredFieldsOptions.find((field) => field.field === 'mustHaveMobile').isSelected;

        const QSFilters = `${startBirthDate ? `&birthFrom=${startBirthDate}` : ''}${endBirthDate ? `&birthTo=${endBirthDate}` : ''
        }${save ? `&export=${true}` : ''}${tags.length > 0 ? `&tagIds=${tags}` : ''}${profileType ? `&profileType=${profileType}` : ''
        }${nationalities ? `&country=${nationalities}` : ''}${consentType ? `&consentType=${consentType}` : ''
        }${hasLoyaltyCard ? `&mustHaveLoyaltyCard=${hasLoyaltyCard}` : ''}${hotelId ? `&hotelIds=${[hotelId]}` : ''}${checkinDate ? `&checkinDate=${checkinDate}` : ''
        }${checkoutDate ? `&checkoutDate=${checkoutDate}` : ''}${mustHaveEmail ? `&mustHaveEmail=${mustHaveEmail}` : ''
        }${mustHaveMobile ? `&mustHaveMobile=${mustHaveMobile}` : ''}&pageIndex=${IsNewPage ? currPage - 1 : 0}`;

        this.setState({ block: true, Roibacktotal_items:0 });

        if(!save && Profiles){
            this.setState({ Profiles: [] });
        }

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            
            if (error){
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data){
                if(data.errors && data.errors.length > 0){
                    data.errors.forEach((error) => {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    })
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if(!save){
                    this.setState({Profiles: data.response, Profilestotal_items:data.totalItems});
                }
                else{
                    handleNotification(data, `${Profiles.length} ${this.props.intl.formatMessage({ id: "Profile.ImportSuccess" })}`);
                }
            }
            this.setState({ error: errorMessage, block: false });

        }, `/api/gms/ReviewPro/v1/export/${surveyId}/reviewPro/${propertyId}?pageSize=10${QSFilters}`);
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        this.setState({
            currPage:page
        }, () => { this.ExportProfiles(false, true) });
    }
    
    handleRequiredField = (field) => {
        const requiredFieldsOptions = this.state.selectedParams.requiredFieldsOptions;

        let index = requiredFieldsOptions.findIndex(el => el.field === field);

        if (index > -1) {
            requiredFieldsOptions[index].isSelected = !requiredFieldsOptions[index].isSelected;

            this.setState({ requiredFieldsOptions });
        }
    }

    render() {
        const { selectedParams, selectedExport, surveys, products, tagList, block, Profiles, currPage, Profilestotal_items, error } = this.state;

        const Badges = ({ text, isSelected, onClickFunction, key }) => {
            return (
                <Badge className="p-2 my-1 mr-2" key={key}
                    color={isSelected ? "primary" : "secondary"}
                    onClick={onClickFunction}
                    style={{ cursor: 'pointer' }}
                >
                    {text}
                </Badge>
            );
        };

        const ConsentOptions = getConsentTypeList().map((consent, key) =>{
            return({
                value:consent.value,
                label:consent.label
            })
        });

        const columns = [
            {
                dataField: 'firstName',
                text: this.props.intl.formatMessage({ id: "Profile.ImportName" })
            },
            {
                dataField: 'lastName',
                text: this.props.intl.formatMessage({ id: "Profile.ImportLastName" })
            },
            {
                dataField: 'email',
                text: 'E-mail'
            },
            {
                dataField: 'country',
                text: this.props.intl.formatMessage({ id: "Profile.ImportCountry" })
            },
            {
                dataField: 'language',
                text: this.props.intl.formatMessage({ id: "Profile.Importlanguage" })
            },
            {
                dataField: 'checkin',
                text: 'Check-in',
                formatter:
                    (cell, row, idx) => {
                        return(
                            <div key={idx}>
                                {moment(cell).format('DD-MM-YYYY')}
                            </div>
                        )
                    }
            },
            {
                dataField: 'checkout',
                text: 'Check-out',
                formatter:
                    (cell, row, idx) => {
                        return(
                            <div key={idx}>
                                {moment(cell).format('DD-MM-YYYY')}
                            </div>
                        )
                    }
            },
            {
                dataField: 'room',
                text: this.props.intl.formatMessage({ id: "Profile.Importroom" })
            }
        ];

        return (
            <StyledCard block={block} error={error} >
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/ReviewProMenu"> ReviewPro Config</Link></BreadcrumbItem>
                        <BreadcrumbItem active> <FormattedMessage id="ReviewProMenu.CustomExport" /> </BreadcrumbItem>
                    </Breadcrumb>
                </div>

                <div className='mt-4'>
                    <Row className='mt-3 mb-2'>
                        <Col><h5><FormattedMessage id="ReviewProExportProfiles.SurveySelection"/></h5></Col>
                        <Col className="text-right">
                            <Button disabled={!selectedExport.surveyId || !selectedExport.propertyId || !selectedParams.checkinDate || !selectedParams.checkoutDate}
                            onClick={() => this.ExportProfiles()} className="btn-sm btn-host">
                                <i className="fas fa-search"/>
                            </Button>
                            {Profiles && Profiles.length > 0 &&
                                <Button onClick={() => this.ExportProfiles(true)} className="btn-sm btn-host ml-2"><i className="fas fa-save"/></Button>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label><FormattedMessage id="ReviewProExportProfiles.Survey"/> * </Label>
                                <CustomSelect
                                    options={surveys}
                                    required
                                    isClearable
                                    isSearchable
                                    placeholder={""}
                                    onChange={(e) => this.handleChangeSelect(e, "surveyId", "surveyName")}
                                    value={selectedExport.surveyId ? surveys.find(c => c.value === selectedExport.surveyId) : ''}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="LargeInput">
                                <Label><FormattedMessage id="ReviewProExportProfiles.Product"/> * </Label>
                                <CustomSelect
                                    options={products}
                                    isClearable
                                    isSearchable
                                    placeholder={""}
                                    onChange={(e) => this.handleChangeSelect(e, "propertyId", "propetyName")}
                                    value={selectedExport.propertyId ? products.find(c => c.value === selectedExport.propertyId) : ''}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <div className='my-2'>
                    <Row><Col><h5><FormattedMessage id="ReviewProExportProfiles.ParamSelection"/></h5></Col></Row>
                    <Row className='mt-2'>
                        <Col className="LargeInput">
                            <Label><FormattedMessage id="CampaignDetails.Consent"/></Label>
                            <CustomSelect
                                options={ConsentOptions}
                                isClearable
                                isSearchable
                                placeholder={""}
                                onChange={(e) => this.handleChange({ target: { name: 'consentType', value: e ? e.value : e } })}
                            />
                        </Col>
                        <Col className="pr-2 LargeInput">
                            <Label>Hotel</Label>
                            <SelectHotel
                                name={'hotelId'}
                                icon={"fas fa-hotel"}
                                onChangeFunc={this.handleHotelSelect}
                                placeholder={''}
                                value={selectedParams.hotelId}
                            />
                        </Col>
                        <Col>
                            <Label><FormattedMessage id="CustomCampaign.ProfileType"/></Label>
                            <CustomSelect
                                options={getProfileType(this.props.intl)}
                                isClearable
                                isSearchable
                                placeholder={""}
                                onChange={(e) => this.handleChange({ target: { name: 'profileType', value: e ? e.value : e } })}
                            />
                        </Col>
                        <Col className="LargeInput">
                            <Label><FormattedMessage id="CustomCampaign.Nationality"/></Label>
                            {/* <CustomSelect
                                options={countryList().getData()}
                                required
                                isSearchable
                                placeholder={""}
                                onChange={(combo) => handleTemplates(null, combo, item)}
                                value={selectedParams.nationalities ? countryList().getData().filter(opt => selectedParams.nationalities === true) : ''}
                                isMulti
                            /> */}
                            <CustomSelect
                                icon={"fas fa-globe"}
                                options={countryList().getData()}
                                required={true}
                                isClearable
                                value={selectedParams.nationalities ? countryList().getData().filter(opt => selectedParams.nationalities === opt.value) : ''}
                                isSearchable
                                placeholder={''}
                                onChange={this.handleNationalities.bind(this)}
                            />
                        </Col>
                    </Row>
                    <Row className="my-2">
                        <Col>
                            <b><FormattedMessage id="ExportProfiles.RequiredFields"/></b>
                            <div>
                                {selectedParams.requiredFieldsOptions.map((field, key) =>
                                    <Badge className="p-2 my-1 cursor-pointer mr-2" color={field.isSelected ? "host" : "secondary"} onClick={() => this.handleRequiredField(field.field)} key={key} >
                                        {field.label}
                                    </Badge>
                                )}
                            </div>
                        </Col>
                        <Col>
                            <Label><FormattedMessage id="ReviewProExportProfiles.BirthDate"/></Label>
                            <div className='SmallDatePicker'>
                                <DateRangePicker
                                    startDate={selectedParams.startBirthDate ? moment(selectedParams.startBirthDate) : null}
                                    isOutsideRange={day => false}
                                    endDate={selectedParams.endBirthDate ? moment(selectedParams.endBirthDate) : null}
                                    onDatesChange={({ startDate, endDate }) => this.handleDates(startDate, endDate, 'startBirthDate', 'endBirthDate')}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    small={true}
                                    numberOfMonths={2}
                                    showDefaultInputIcon={true}
                                />
                            </div>
                        </Col>
                        <Col>
                            <Label>Check-in - Check-out *</Label>
                            <div className='SmallDatePicker'>
                                <DateRangePicker
                                    startDate={selectedParams.checkinDate ? moment(selectedParams.checkinDate) : null}
                                    isOutsideRange={day => false}
                                    endDate={selectedParams.checkoutDate ? moment(selectedParams.checkoutDate) : null}
                                    onDatesChange={({ startDate, endDate }) => this.handleDates(startDate, endDate, 'checkinDate', 'checkoutDate')}
                                    focusedInput={this.state.focusedInput2}
                                    onFocusChange={focusedInput2 => this.setState({ focusedInput2 })}
                                    small={true}
                                    numberOfMonths={2}
                                    showDefaultInputIcon={true}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <Label>Tags</Label>
                                <Nav tabs className="border-0">
                                    {tagList && tagList.map((el, k) =>
                                        <NavItem key={k}>
                                            <NavLink style={{cursor: 'pointer'}} className={this.state.tagTab === k ? 'tab-border-clever text-clever border-0 ' : 'border-0'}
                                            onClick={() => { this.setState({ tagTab :  k }); }}>
                                                <b >{el.code}</b>
                                            </NavLink>
                                        </NavItem>
                                    )}    
                                </Nav>
                                <TabContent activeTab={this.state.tagTab} className="h-100">
                                    {tagList && tagList.map((el, k) =>
                                        <TabPane tabId={k} className="border-0 my-0 p-0 " key={k}>
                                            {el.tag && el.tag.map((tag, k1) =>
                                                <span key={k1}>
                                                    <Badges
                                                        isSelected={this.state.selectedParams.tags && this.state.selectedParams.tags.find(t => t === tag.id) !== undefined ? true : false}
                                                        onClickFunction={() => this.handleTag(tag)}
                                                        text={tag.code}
                                                    />
                                                </span>
                                            )}
                                        </TabPane>
                                    )}
                                </TabContent>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='mt-4'>
                    <CustomTable
                        data={Profiles ? Profiles : []}
                        columns={columns}
                        page={currPage}
                        totalSize={Profilestotal_items}
                        onTableChange={this.handleTableChange}
                        shadow={false}
                        showTotal={Profiles && Profiles.length > 0}
                        remote={true}
                        sizePerPage={10}
                    />
                </div>
            </StyledCard>
        );
    }
}

export default injectIntl(CustomExport)