import React, { Component } from 'react'
import { Col, Row, TabContent, TabPane } from 'reactstrap';
import { TwilioSideBar } from '../../Base/SideBar';
import TwilioDashboard from './TwilioDashboard';
import { StyledCard } from '../../Base/CommonUIComponents';
import { FormattedMessage } from 'react-intl';
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import TwilioBilling from './TwilioBilling';
import TwilioPhoneNumbers from './TwilioPhoneNumbers';
import TwilioTestSms from './TwilioTestSms';
import TwilioTemplates from './TwilioTemplates';
import TwilioMessageLog from './TwilioMessageLog';
import TwilioServices from './TwilioServices';

export default class MessageConfig extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: true,
            error: null,
            twilioConfig: null,
            activeMenu: 'Dashboard',
            activeTab: null,
            menus: [
                { tag: 'Dashboard', icon: 'fas fa-tachometer-alt mr-2', name: 'Dashboard', isSelectable: true },
                { tag: 'Billing', icon: 'fas fa-wallet mr-2', name: <FormattedMessage id="TwilioMessaging.Billing" />, isSelectable: true },
                { tag: 'PhoneNumbers', icon: "fas fa-phone mr-2", name: <FormattedMessage id="TwilioMessaging.Numbers" />, isSelectable: true },
                { tag: 'Services', icon: "fas fa-headset mr-2", name: <FormattedMessage id="TwilioMessaging.Services" />, isSelectable: true },
                { tag: 'TestSms', icon: "fas fa-sms mr-2", name: <FormattedMessage id="TwilioMessaging.TestSms" />, isSelectable: true },
                { tag: 'Templates', icon: "fas fa-envelope-square mr-2", name: <FormattedMessage id="TwilioMessaging.Templates" />, isSelectable: true },
                { tag: 'MessageLog', icon: "fas fa-comments mr-2", name: <FormattedMessage id="TwilioMessaging.MessageLog" />, isSelectable: true },
            ]
        }
    }

    componentDidMount() {
        this.getSubAccount();
    }

    getSubAccount = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }

                if(data.response && data.response.length > 0){
                    this.setState({ twilioConfig: data.response[0], block: false }, this.getPhoneNumbers);
                    return;
                }

                this.setState({ block: false });
            }
        }, `/api/gms/Twilio/v1/subaccount`);
    }

    getPhoneNumbers = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }

                if (data.response && data.response.length > 0) {
                    var phoneNumbersList = [];
                    data.response.forEach((x, idx) => {
                        phoneNumbersList.push({value: idx, label: x.phoneNumber})
                    })
                    this.setState({ twilioPhones: data.response, block: false, phoneNumbersList }, this.getServices);
                    return;
                }

                this.setState({ block: false });
            }
        }, `/api/gms/Twilio/v1/subaccount/phonenumber`);
    }

    getServices = () => {

        this.setState({ block: true },
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }

                    if (data.response) {
                        var servicesList = [];
                        data.response.services && data.response.services.forEach(x => {
                            servicesList.push({ value: x.sid, label: x.friendly_name })
                        })
                        this.setState({ services: data.response, servicesList, block: false }, this.getTemplates);
                        return;
                    }

                    this.setState({ block: false });
                }
            }, `/api/gms/Twilio/v1/service`));
    }

    getTemplates = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }

                if (data.response) {
                    var templateList = [];
                    data.response.contents && data.response.contents.forEach((x) => {
                        templateList.push({ value: x.sid, label: x.friendly_name })
                    })
                    this.setState({ templates: data.response, templateList, block: false });
                    return;
                }

                this.setState({ block: false });
            }
        }, `/api/gms/Twilio/v1/template`);
    }
    
    toggleMenu = (menu, isSelectable) => {
        if (this.state.activeMenu !== menu) {
            const activeTab = isSelectable ? null : this.state.activeTab;

            this.setState({
                activeMenu: menu,
                activeTab
            });
        }
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }
    
    render() {
        const { block, error, activeMenu, activeTab, menus, twilioConfig, twilioPhones, phoneNumbersList, services, servicesList, templates, templateList } = this.state;

        const currentMenu = menus.find(p => p.tag === activeMenu);

        return (
            <StyledCard error={error}>
                <Row>
                    <Col className="col-3">
                        <TwilioSideBar
                            activeMenu={activeMenu}
                            activeTab={activeTab}
                            block={block}
                            menus={menus}
                            name={twilioConfig?.name || <FormattedMessage id="NavMenu.MessageConfig"/>}
                            status={twilioConfig?.status}
                            toggleMenu={this.toggleMenu}
                            toggleTab={this.toggleTab}
                        />
                    </Col>
                    <Col className="col-9 pl-0">
                        <TabContent activeTab={currentMenu.submenus ? activeTab : activeMenu} className='border-0 verticalScroll' style={{ minHeight: '80vh', maxHeight: '88vh', overflowX: 'hidden', height: '100%' }}>
                            <TabPane tabId='Dashboard' className='border-0 py-0'>
                                <TwilioDashboard
                                    page={currentMenu}
                                />
                            </TabPane>
                            <TabPane tabId='Billing' className='border-0 py-0'>
                                <TwilioBilling
                                    page={currentMenu}
                                />
                            </TabPane>
                            <TabPane tabId='PhoneNumbers' className='border-0 py-0'>
                                <TwilioPhoneNumbers
                                    page={currentMenu}
                                    twilioPhones={twilioPhones}
                                    getPhoneNumbers={this.getPhoneNumbers}
                                />
                            </TabPane>
                            <TabPane tabId='TestSms' className='border-0 py-0'>
                                <TwilioTestSms
                                    page={currentMenu}
                                    twilioPhones={phoneNumbersList}
                                    services={servicesList}
                                    templates={templateList}
                                />
                            </TabPane>
                            <TabPane tabId='Templates' className='border-0 py-0'>
                                <TwilioTemplates
                                    page={currentMenu}
                                    twilioPhones={twilioPhones}
                                    templates={templates}
                                    getPhoneNumbers={this.getPhoneNumbers}
                                    getTemplates={this.getTemplates}
                                />
                            </TabPane>
                            <TabPane tabId='MessageLog' className='border-0 py-0'>
                                <TwilioMessageLog
                                    page={currentMenu}
                                />
                            </TabPane>
                            <TabPane tabId='Services' className='border-0 py-0'>
                                <TwilioServices
                                    page={currentMenu}
                                    services={services}
                                />
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </StyledCard>
        )
    }
}
