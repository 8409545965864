import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, ButtonGroup, Col, Form, Row, UncontrolledTooltip } from 'reactstrap';
import { getAPI } from '../../Base/API';
import { ActiveInactiveStatusBadge, StyledCard } from '../../Base/CommonUIComponents';
import CustomSelect from '../../Base/CustomSelect';
import { getRunPeriodList, getWeekDays } from '../../Base/ReferenceDataFunctions';
import moment from 'moment-timezone';
import { CSVLink } from 'react-csv';

export class AllDQTemplates extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            hotelGroupId: null,
            active: null,
            templateList: [],
            hotelGroupOptions: []
        };
    }

    componentDidMount() {
        this.getHotelGroups();
    }
    
    getHotelGroups = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                const hotelGroupOptions = data.response.map((item) => ({ value: item.hotelGroupId, label: item.name }));

                this.setState({ hotelGroupOptions })
            }
        }, '/api/gms/Hotel/v1/hotelgroup/list?pageSize=1000&pageIndex=0');
    }

    submitForm = (e) => {
        if(e)
            e.preventDefault();
        this.setState({ block: true }, () => this.getTemplates());
    }

    getTemplates = () => {
        const { hotelGroupId, active }= this.state;
        
        let params = `?pageIndex=0&pageSize=10000`;

        if(hotelGroupId !== null)
            params += `&hotelGroupId=${hotelGroupId}`;

        if(active !== null)
            params += `&active=${active}`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ templateList: data.response, totalItems: data.totalItems });
            }
            this.setState({ error: errorMessage, block: false });
        },'/api/gms/Monitor/v1/GetAllTemplates' + params)
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }
    
    getScheduleIcon = (template, templateIdx) => {
        const id = `schedule-${templateIdx}`;
        const runPeriod = template.schedule && template.schedule.period && getRunPeriodList().find(o => o.value === template.schedule.period);

        var weekDaysRunning = [];
        if (template.schedule && template.schedule.period === 'Weekly') {
            template.schedule.weekDays.map((day, i) => {
                if (day) {
                    weekDaysRunning.push(getWeekDays(this.props.intl)[i])
                }
            })
        }

        return <>
            <i className="fas fa-stopwatch text-muted mx-3 px-1" id={id} />

            <UncontrolledTooltip target={id} placement="bottom">
                {template.schedule.period === 'Never' ?
                    <span> {runPeriod?.label} {this.props.intl.formatMessage({ id: "DataQualityTemplateDetail.Runs" }).toLowerCase()} </span>
                    : <span> <FormattedMessage id="DataQualityTemplateDetail.Runs" /> {runPeriod?.label}</span>}

                {template.schedule.period === 'Weekly' ?
                    <span> <FormattedMessage id="DataQualityTemplateDetail.On" /> {weekDaysRunning.join(', ')} </span>
                    : template.schedule.period === 'Monthly' ?
                        <span> <FormattedMessage id="DataQualityTemplateDetail.OnDay" /> {template.schedule.day} </span>
                        : ''}
            </UncontrolledTooltip>
        </>
    }

    getCsvData = () => {
        const { templateList } = this.state;
        const titles = [ 'hotelGroupId', 'templateId', 'templateName', 'templateDescription', 'templateActive', 'lastRun', 'nextRun', 'ruleId', 'ruleName', 'ruleDescription', 'ruleActive', 'field', 'entity' ];
        const csvData = [titles];

        if(templateList)
            templateList.flatMap(t => {
                return t.rules.map((r) => {
                    const d = {
                        hotelGroupId: t.hotelGroupId,
                        templateId: t.id,
                        templateName: t.name,
                        templateDescription: t.description,
                        templateActive: t.active,
                        lastRun: t.lastRun,
                        nextRun: r.nextRun,
                        ruleId: r.id,
                        ruleName: r.name,
                        ruleDescription: r.description,
                        ruleActive: r.active,
                        field: r.field,
                        entity: r.entity
                    }
                    return d;
                })
            }).forEach(t => csvData.push(Object.values(t)));

        return csvData;
    }

    sortDataQualityTemplates = (a, b) => {
        // Sort by 'active' in descending order
        if (a.active === b.active) {
          // If 'active' is the same, sort by 'order' in ascending order
          if (a.order === null && b.order === null) {
            return 0; // Both have null order
          } else if (a.order === null) {
            return 1; // 'a' has null order, so it goes after 'b'
          } else if (b.order === null) {
            return -1; // 'b' has null order, so it goes after 'a'
          } else {
            return a.order - b.order;
          }
        } else {
          return b.active - a.active; // Sort 'active' in descending order
        }
    }
    
    render() {
        const { block, error, hotelGroupId, active, templateList, pageSize, pageIndex, hotelGroupOptions } = this.state;

        const statusOptions = [
            {
                value: true,
                label: <FormattedMessage id="generic.active" />
            },
            {
                value: false,
                label: <FormattedMessage id="generic.inactive" />
            }
        ];
        

        return (
            <StyledCard block={block} error={error}>
                <Row className="mb-2">
                    <Col>
                        <h5>
                            <i className="fas fa-file-alt mr-2"/>
                            <FormattedMessage id="DataQualityTemplateDashboard.Title"/>
                        </h5>
                    </Col>
                </Row>
                <Form onSubmit={this.submitForm}>
                    <Row className="mb-2">
                        <Col className='col-3'>
                            <CustomSelect
                                options={hotelGroupOptions}
                                isClearable={false}
                                required={true}
                                value={hotelGroupId ? hotelGroupOptions.filter(el => el.value === hotelGroupId) : null}
                                placeholder={<FormattedMessage id="generic.HotelGroup" />}
                                onChange={this.handleChangeSelect.bind(this, 'hotelGroupId')}
                                icon="icon-icon-hotel"
                            />
                        </Col>
                        <Col className='col-3'>
                            <CustomSelect
                                placeholder={<FormattedMessage id="RuleDashboard.SelectAStatus"/>}
                                options={statusOptions}
                                onChange={this.handleChangeSelect.bind(this, 'active')}
                                value={active !== null ? statusOptions.find(v => v.value === active) : null}
                                isClearable
                                isSearchable
                            />
                        </Col>
                        <Col className="text-right">
                            <CSVLink className="btn btn-sm btn-host mr-2" data={this.getCsvData()} filename={"DataQualityTemplates.csv"}>
                                <i className="fas fa-file-download" />
                            </CSVLink>
                            <Button className="btn btn-host shadow btn-sm" type="submit">
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <div>
                    <Row>
                        <Col className="h-100">
                            {templateList.sort(this.sortDataQualityTemplates).map((t, k) => {
                                const hotelGroupName = hotelGroupOptions?.find(({value}) => value === t.hotelGroupId)?.label;
                                return (
                                    <div key={k}>
                                        {k > 0 ? <hr/> :''}
                                        <div className='mt-3'>
                                            <Row>
                                                <Col title={hotelGroupName} className='col-2 text-truncate'>
                                                    <b>{hotelGroupName}</b>
                                                </Col>
                                                <Col title={t.description}>
                                                    <b>{t.name}</b>
                                                </Col>
                                                <Col>
                                                    <b><FormattedMessage id="RuleDashboard.LastRun"/>:</b>
                                                    <span className='ml-1'>
                                                        {t.run?.runDate ?
                                                            moment(t.run?.runDate).format('YYYY-MM-DD')
                                                        : '-'}
                                                    </span>
                                                </Col>
                                                <Col className='col-1 text-center text-muted'>
                                                    {t.order !== null ? 
                                                        <b>{t.order}</b>
                                                    :''}
                                                </Col>
                                                <Col className='d-flex align-items-center justify-content-end col-2'>
                                                    {t.schedule ?
                                                        this.getScheduleIcon(t, k)
                                                    :''}
                                                    <ActiveInactiveStatusBadge status={t.active}/>
                                                </Col>
                                            </Row>
                                            {t.rules.map((r, key) =>
                                                <Row key={key} className='mt-2 px-2'>
                                                    <Col>
                                                        <span className='text-muted mr-1'><FormattedMessage id="DataQualityTemplateDetail.Name"/>:</span>
                                                        {r.name || '-'}
                                                    </Col>
                                                    <Col>
                                                        <span className='text-muted mr-1'><FormattedMessage id="DataQualityTemplateDetail.Field"/>:</span>
                                                        {r.field || '-'}
                                                    </Col>
                                                    <Col>
                                                        <span className='text-muted mr-1'><FormattedMessage id="DataQualityTemplateDetail.Entity"/>:</span>
                                                        {r.entity || '-'}
                                                    </Col>
                                                    {/* <Col>
                                                        <span className='text-muted mr-1'><FormattedMessage id="DataQualityTemplateDetail.ComparisonType"/>:</span>
                                                        {r.comparisonType && getComparationList().find(o => o.value === r.comparisonType) != undefined ? getComparationList().find(o => o.value === r.comparisonType).label : ''}
                                                    </Col> */}
                                                    <Col className='col-1'>
                                                        <ActiveInactiveStatusBadge status={r.active}/>
                                                    </Col>
                                                </Row>
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </Col>
                    </Row>
                </div>
            </StyledCard>
        );
    }
}
export default injectIntl(AllDQTemplates)
