import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, CardBody, Row, Col, Button, Badge } from 'reactstrap';
import { handleNotification } from '../../Base/Notification';
import { getAPI } from '../../Base/API';

import moment from 'moment';
import CustomSelect from '../../Base/CustomSelect';
import ConfigActivities from './ConfigActivities';
import ConfigConditions from './ConfigConditions';
import CampaignTargetModal from '../CustomCampaigns/CampaignTargetModal';
import BlockUi from 'react-block-ui';

class ConfigElementMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedNode: null,
            eventTypeOptions: [{
                value: 'reserve',
                label: <FormattedMessage id="CustomerJourney.reservationElement" />
            }, {
                value: 'campaign',
                label: <FormattedMessage id="CustomerJourney.campaignElement" />
            }, {
                value: 'loyalty',
                label: <FormattedMessage id="CustomerJourney.loyaltyElement" />
            }],

            reserveSubTypeOptions: [{
                value: 'checkIn',
                label: <FormattedMessage id="CustomerJourney.CheckIn" />
            },
            {
                value: 'checkOut',
                label: <FormattedMessage id="CustomerJourney.CheckOut" />
            },
            {
                value: 'creation',
                label: <FormattedMessage id="CustomerJourney.Creation" />
            },
            {
                value: 'cancellation',
                label: <FormattedMessage id="CustomerJourney.Cancellation" />
            }],
            
            campaignSubTypeOptions: [{
                value: 'open',
                label: <FormattedMessage id="CustomerJourney.Open" />
            },
            {
                value: 'click',
                label: <FormattedMessage id="CustomerJourney.Click" />
            },
            {
                value: 'send',
                label: <FormattedMessage id="CustomerJourney.Send" />
            }],

            loyaltySubTypeOptions: [{
                value: 'emission',
                label: <FormattedMessage id="CustomerJourney.Emission" />
            }],
            campaignTargetsModal: false,
            campaignTargets: null,
            selectedCampaignTarget: null,
            error: null,
            block: false
        }
    }

    componentDidMount() {
        this.updateSelectedNode();
    }
      
    componentDidUpdate(prevProps) {
        if (this.props.selectedNodeId !== prevProps.selectedNodeId) {
            this.updateSelectedNode();
        }
    }
      
    updateSelectedNode() {
        const { selectedNodeId, nodes } = this.props;
        if (selectedNodeId && nodes) {
            const selectedNode = nodes.find(node => node.id === selectedNodeId);
            this.setState({ selectedNode }, () => {
                if (this.state.selectedNode && this.state.selectedNode.type === 'input') {
                    this.getTargets();
                }
            });
        }
    }

    getTargets = () => {
        const { selectedNode } = this.state;

        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    if(data.response){
                        const selectedCampaignTarget = selectedNode && selectedNode.metaData && data.response.find(t => t.id === selectedNode.metaData.campaignTargetsId)
                        this.setState({ selectedCampaignTarget, campaignTargets: data.response ?? null, block: false });
                        return
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/Marketing/v1/CampaignTargets?excludeInactive=true`);
        });
    }

    handleSelect = (selectedOption, field) => {
        const { nodes } = this.props;
        let selectedNode = nodes && nodes.find(node => node.id === this.state.selectedNode?.id);
    
        if (!selectedNode) return;
    
        if (!selectedNode.metaData) {
            selectedNode.metaData = {};
        }
        
        if(field === 'type' && selectedOption && selectedOption.value !== 'send' && selectedNode.metaData.campaignTargetsId){
            this.setState({ selectedCampaignTarget: null });
            delete selectedNode.metaData.campaignTargetsId;
        }
        selectedNode.metaData[field] = selectedOption.value;
        
        this.handleNodesChange(selectedNode);
    }

    handleTypeChange = (option) => {
        const { nodes } = this.props;
        let selectedNode = nodes && nodes.find(node => node.id === this.state.selectedNode?.id);
    
        if (!selectedNode || !option) return;

        selectedNode.typeUnchanged = `input-${option.value}`;
        if(selectedNode.metaData && selectedNode.metaData.type){
            selectedNode.metaData.type = null;
        }

        if(option.value !== 'campaign' && selectedNode.metaData && selectedNode.metaData.campaignTargetsId){
            this.setState({ selectedCampaignTarget: null });
            delete selectedNode.metaData.campaignTargetsId;
        }
        
        this.handleNodesChange(selectedNode);
    }

    handleNumberOfConditionsChange = (value) => {
        const { nodes } = this.props;
        let selectedNode = nodes && nodes.find(node => node.id === this.state.selectedNode?.id);
    
        if (!selectedNode) return;
    
        if (!selectedNode.data) {
            selectedNode.data = {};
        }
        if(!selectedNode.metaData){
            selectedNode.metaData = {};
        }

        selectedNode.data.numberOfConditions = value;
        selectedNode.metaData.numberOfConditions = value;
        
        this.handleNodesChange(selectedNode);
    }

    handleNodesChange = (updatedNode) => {
        let { nodes } = this.props;
        const nodeIndex = nodes.findIndex(node => node.id === updatedNode.id);
    
        if (nodeIndex !== -1) {
            nodes[nodeIndex] = updatedNode;
            this.props.updateNodesList(nodes)
            this.setState({ selectedNode: updatedNode });
        }
    }

    toggleTargetsModal = () => {
        this.setState(prevState => ({ campaignTargetsModal: !prevState.campaignTargetsModal }));
    }

    applyCampaignTarget = (params, campaignTargetDetails) => {
        const { selectedNode } = this.state;
        if(!selectedNode) return;

        if(!selectedNode.metaData){
            selectedNode.metaData = {};
        }
        if(campaignTargetDetails){
            selectedNode.metaData.campaignTargetsId = campaignTargetDetails.id;

            this.setState({ campaignTargetsModal: false, selectedCampaignTarget: campaignTargetDetails}, () => this.handleNodesChange(selectedNode));
        }
    }

    render() {
        const { selectedNode, eventTypeOptions, reserveSubTypeOptions, campaignSubTypeOptions, loyaltySubTypeOptions, campaignTargetsModal, selectedCampaignTarget, block } = this.state;
        const { nodes, edges, loyaltyCardsOptions } = this.props;

        let type = selectedNode ? selectedNode.typeUnchanged : null;
        const primaryType = type && type.split('-') ? type.split('-')[0] : null;
        const secondaryType = type && type.split('-') ? type.split('-')[1] : null;
        const tertiaryType = type &&  type.split('-') && type.split('-').length === 3 ? type.split('-')[2] : null;
        const metaData = selectedNode && selectedNode.metaData;
        
        return (
            <Card className='h-100'>
                <BlockUi tag="div" blocking={block}>
                    <CardBody>
                        <Row className='d-flex align-items-start justify-content-between'>
                            {selectedNode ?
                                <Col className='col-8 d-flex align-items-center'>
                                    <div className={selectedNode.type === 'default-condition' ? 'configNodeStyleCondition': ''} style={this.props.getNodeStyle(selectedNode.typeUnchanged)}>
                                        <div className={selectedNode.type === 'default-condition' ? 'cofigIconStyleCondition': ''}>
                                            {this.props.getNodeIcon(selectedNode.typeUnchanged)}
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <h5 className='ml-3 mb-0'>
                                            {type ?
                                                primaryType === 'input' ?
                                                    secondaryType === 'reserve' ?
                                                        <FormattedMessage id={`CustomerJourney.reservationElement`} />
                                                    :
                                                        <FormattedMessage id={`CustomerJourney.${secondaryType}Element`} />
                                                :
                                                    secondaryType !== 'condition' ?
                                                        <FormattedMessage id={`CustomerJourney.${secondaryType}Element`} />
                                                    :
                                                        tertiaryType ?
                                                            <FormattedMessage id={`CustomerJourney.${tertiaryType}Element`} />
                                                        :
                                                            ''
                                            : 
                                                ''
                                            }
                                        </h5>
                                        <h6 className='ml-3 mt-1 text-muted'>
                                            {primaryType && primaryType === 'input' ?  
                                                <FormattedMessage id="CustomerJourney.EventSource" />
                                            :
                                                secondaryType !== 'condition' ?
                                                    <FormattedMessage id="CustomerJourney.Action" />
                                                :
                                                    <FormattedMessage id="CustomerJourney.Condition" />
                                            }
                                        </h6>
                                    </div>
                                </Col>
                            :
                                <Col>
                                    <FormattedMessage id="CustomerJourney.ConfigureElement" />
                                </Col>
                            }

                            <Col className='text-right'>
                                <i className="fas fa-times cursor-pointer" onClick={() => this.props.toggleConfigureNode(nodes)} />
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            { primaryType && primaryType === 'input' ?
                                <Col className='col-12'>
                                    <Row>
                                        <Col className='col-12 d-flex align-items-center justify-content-between'>
                                            <div>
                                                <FormattedMessage id="CustomerJourney.ConfigureType" />
                                            </div>
                                            {secondaryType && secondaryType === 'campaign' && metaData && metaData.type === 'send' &&
                                                <Button className="btn btn-host btn-sm" id="CampaignTargets" onClick={this.toggleTargetsModal}>
                                                    <i className="fas fa-hat-wizard" />
                                                </Button>
                                            }
                                        </Col>
                                        <hr style={{ width: '95%' }} />
                                    </Row>
                                    <Row>
                                        <Col className='col-12 d-flex flex-column'>
                                            <FormattedMessage id="CustomerJourney.SelectType" />
                                            <CustomSelect 
                                                isSearchable
                                                placeholder={''}
                                                options={eventTypeOptions}
                                                value={secondaryType ? eventTypeOptions.find(option => option.value === secondaryType) : ''}
                                                onChange={(value) => this.handleTypeChange(value)}
                                                className='pt-1'
                                            />
                                        </Col>
                                        <Col className='col-12 d-flex flex-column mt-3'>
                                            <FormattedMessage id="CustomerJourney.SelectSubType" />
                                            <CustomSelect 
                                                isSearchable
                                                placeholder={''}
                                                options={secondaryType === 'reserve' ? reserveSubTypeOptions : secondaryType === 'campaign' ? campaignSubTypeOptions : secondaryType === 'loyalty' ? loyaltySubTypeOptions : []}
                                                value={
                                                    selectedNode && selectedNode.metaData && selectedNode.metaData.type ? 
                                                        secondaryType === 'reserve' ? 
                                                            reserveSubTypeOptions.find(option => option.value === selectedNode.metaData.type) 
                                                        : 
                                                            secondaryType === 'campaign' ? 
                                                                campaignSubTypeOptions.find(option => option.value === selectedNode.metaData.type) 
                                                            : 
                                                                loyaltySubTypeOptions.find(option => option.value === selectedNode.metaData.type) 
                                                    : ''}
                                                onChange={(value) => this.handleSelect(value, 'type')}
                                                isDisabled={secondaryType === 'event'}
                                                className='pt-1'
                                            />
                                        </Col>
                                        {selectedCampaignTarget ?
                                            <Col className='col-12 d-flex flex-column mt-3'>
                                                <FormattedMessage id="CustomerJourney.SelectedCampaignTarget" />
                                                <Card className='mt-1' style={{ backgroundColor: 'white', boxShadow: '0px 3px 15px #6f73883b' }}>
                                                    <CardBody className='p-3'>
                                                        <Row>
                                                            <Col className='col-9'>
                                                                <div>
                                                                    <b>{selectedCampaignTarget.name}</b>
                                                                </div>
                                                                <div className='text-muted' style={{ fontSize: '0.8em' }}>
                                                                    {selectedCampaignTarget.lastRun ?
                                                                        moment(selectedCampaignTarget.lastRun).format('DD/MM/YYYY HH:mm:ss')
                                                                    :
                                                                        <FormattedMessage id="CampaignTargets.NotRun"/>
                                                                    }
                                                                </div>
                                                            </Col>
                                                            <Col className='col-3 text-right'>
                                                                <Badge className='ml-2' color={selectedCampaignTarget.active ? 'primary' : 'secondary'}>
                                                                    {selectedCampaignTarget.active ?
                                                                        <FormattedMessage id="CampaignTargets.Active"/>
                                                                    :
                                                                        <FormattedMessage id="CampaignTargets.Inactive"/>
                                                                    }
                                                                </Badge>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        : <div />}
                                    </Row>
                                </Col>
                            :
                                secondaryType && secondaryType === 'condition' ?
                                    <Col className='d-flex flex-column'>
                                        <ConfigConditions
                                            selectedNode={selectedNode}
                                            nodes={nodes}
                                            edges={edges}
                                            secondaryType={secondaryType}
                                            conditionType={tertiaryType}
                                            loyaltyCardsOptions={loyaltyCardsOptions}
                                            handleSelect={this.handleSelect}
                                            handleNumberOfConditionsChange={this.handleNumberOfConditionsChange}
                                            handleNodesChange={this.handleNodesChange}
                                            changeEdges={this.props.changeEdges}
                                        />
                                        
                                    </Col>
                                :
                                    <Col className='d-flex flex-column'>
                                        <ConfigActivities
                                            selectedNode={selectedNode}
                                            nodes={nodes}
                                            secondaryType={secondaryType}
                                            handleSelect={this.handleSelect}
                                            handleNodesChange={this.handleNodesChange}
                                        />
                                    </Col>
                            }
                        </Row>
                    </CardBody>
                </BlockUi>
                {campaignTargetsModal ?
                    <CampaignTargetModal
                        isOpen={campaignTargetsModal}
                        toggle={this.toggleTargetsModal}
                        applyCampaignTarget={this.applyCampaignTarget}
                        campaignTargets={this.state.campaignTargets ?? null}
                    />
                :<div />}
            </Card>
        )
    }
}

export default injectIntl(ConfigElementMenu);