import React, { Component } from 'react'

export default class WhatsAppMessage extends Component {


    render() {
        const { big, children, classNames, height } = this.props;

        return (
            <div style={{ height: height || (big ? '80vh' : '350px') }}>
                <div className={'whatsAppBackground verticalScroll py-4 ' + classNames} style={{ height: (height || (big ? '80vh' : '350px')), background: big ? 'url("backgroundWhatsApp.png") center center repeat fixed' : 'url("backgroundWhatsApp.png") center center / cover repeat' }}>
                    {children}
                </div>
            </div>
        )
    }
}
